/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';

import '@base/components/sidebar-menu/styles.scss';

import Meeting from './meeting';
import Purchase from './purchase';
import Inventory from './inventory';
import Invoice from './invoice';
import Order from './order';
import Product from './product';
import Desk from '@demo-page/desk/desktop/theme1/settings/settings';
import ReportSettings from './report';
import { Button } from '@base/components/form';
import Activity from './activity';

const NAVS = [
  {
    value: 'purchase',
    label: 'Purchase',
  },
  {
    value: 'meeting',
    label: 'Meeting',
  },
  {
    value: 'inventory',
    label: 'Inventory',
  },
  {
    value: 'invoice',
    label: 'Invoice',
  },
  {
    value: 'order',
    label: 'Order',
  },
  {
    value: 'product',
    label: 'Product',
  },
  {
    value: 'helpdesk',
    label: 'Desk',
  },
  {
    value: 'report',
    label: 'Report',
  },
  {
    value: 'activity',
    label: 'Activity',
  },
];

const Preferences = () => {
  const [nav, setNav] = useState(NAVS[0]);
  const [hideSideNav, setSideNav] = useState(false);

  return (
    <>
      <div className={classnames('page-container-wrap', { 'hide-sidebar': hideSideNav })}>
        <div className="page-menu-wrap">
          <div className="page-menu-nav-sidebar">
            <ul className="page-menu-sidebar-nav">
              {NAVS.map((item, index) => (
                <li className="page-menu-nav-item" key={index}>
                  <button
                    className={classnames('btn page-menu-nav-link', {
                      active: item.value == nav.value,
                    })}
                    onClick={(e) => {
                      setNav(item);
                    }}
                  >
                    {Icon(item.value)}
                    <span className="mg-l-5">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="page-container-content">
          <div className="page-container-content-header">
            <Button
              color=""
              className="btn-toggle-sidebar tx-gray-400"
              icon="ChevronLeft"
              onClick={() => setSideNav(!hideSideNav)}
            />
            <h1 className="mg-x-10 mg-b-0 tx-16 tx-uppercase tx-normal">
              Preferences &gt; {nav.label}
            </h1>
          </div>
          {nav.value != 'helpdesk' && (
            <div className="pd-20 scroll-box">
              {nav.value == 'purchase' && <Purchase />}
              {nav.value == 'meeting' && <Meeting />}
              {nav.value == 'inventory' && <Inventory />}
              {nav.value == 'invoice' && <Invoice />}
              {nav.value == 'order' && <Order />}
              {nav.value == 'product' && <Product />}
              {nav.value == 'report' && <ReportSettings />}
              {nav.value == 'activity' && <Activity />}
            </div>
          )}
          {nav.value == 'helpdesk' && <Desk />}
        </div>
      </div>
    </>
  );
};

export default Preferences;
