import { ChevronDown, Folder, X } from 'react-feather';

const NewProductGroup = () => {
  return (
    <div
      id="productGroupModal"
      className="modal fade right product-right-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">New Product Group</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a href="#kr" className="nav-link pd-x-10-f active" data-toggle="tab">
                  Korean
                </a>
              </li>
              <li className="nav-item">
                <a href="#eng" className="nav-link pd-x-10-f" data-toggle="tab">
                  English
                </a>
              </li>
              <li className="nav-item">
                <a href="#vn" className="nav-link pd-x-10-f" data-toggle="tab">
                  Vietnamese
                </a>
              </li>
              <li className="nav-item dropdown">
                <a role="button" className="nav-link pd-x-10-f" data-toggle="dropdown">
                  Others
                  <ChevronDown className="wd-16 ht-16 tx-color-03" />
                </a>
                <div className="dropdown-menu">
                  <a href="javascript:;" className="dropdown-item">
                    日本語
                  </a>
                </div>
              </li>
            </ul>
            <div className="tab-content bd bd-gray-300 bd-t-0 mg-b-20 pd-10">
              <div className="tab-pane fade show active">
                <label className="tx-color-03">Group Name</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <label className="tx-color-03">Parent Group</label>
              <div className="input-group">
                {/*  before selecting a group
                                <input type="text" className="form-control" placeholder="Type or click to select a product group" /> 
                            */}
                <div className="form-control bd-r-0 pd-y-1-f d-flex align-items-center justify-content-between">
                  <span>Group AAA</span>
                  <button type="button" className="btn" aria-label="삭제">
                    <X />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn bd-gray-300 tx-gray-300 bd-l-0"
                    aria-label="모그룹 선택 팝업 열기"
                  >
                    <Folder />
                    <span className="sr-only">모그룹 선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="tx-color-03">Description</label>
              <textarea rows={5} cols={10} className="form-control"></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductGroup;
