import { useState } from 'react';

import { Button, Input, Select as SelectHook } from '@base/components/form';
import { ChevronDown, X, Plus, User } from 'react-feather';

const ActEvent = (props: any) => {
  const [showApproval, setShowApproval] = useState<any>();

  return (
    <div className="overflow-auto pd-15">
      {props.value == 1 && (
        <>
          <div className="form-group">
            <label className="form-item-title d-block">Registration</label>
            <div className="d-flex align-items-center mg-b-10">
              Ticket
              <div className="custom-control custom-radio d-inline-block mg-l-10">
                <input
                  type="radio"
                  id="registrationRadio1"
                  name="registrationRadio"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="registrationRadio1">
                  Free
                </label>
              </div>
              <div className="custom-control custom-radio d-inline-block mg-l-10">
                <input
                  type="radio"
                  id="registrationRadio2"
                  name="registrationRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="registrationRadio2">
                  Purchased
                </label>
              </div>
            </div>
            <div className="custom-control custom-checkbox mg-b-10">
              <input
                type="checkbox"
                className="custom-control-input"
                name="registrationCheck"
                id="registrationCheck1"
              />
              <label className="custom-control-label" htmlFor="registrationCheck1">
                Auto Confirm Registration
              </label>
            </div>
            <div className="custom-control custom-checkbox mg-b-10">
              <input
                type="checkbox"
                className="custom-control-input"
                name="registrationCheck"
                id="registrationCheck2"
              />
              <label className="custom-control-label" htmlFor="registrationCheck2">
                Create Lead for Event Registration
              </label>
            </div>
            <div className="d-flex align-items-center">
              <div className="custom-control custom-checkbox mg-r-10">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="registrationCheck"
                  id="registrationCheck3"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="registrationCheck3">
                  Questions
                </label>
              </div>
              <div className="input-group wd-300" data-toggle="dropdown">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    <ChevronDown />
                  </button>
                </div>
              </div>
              <div className="dropdown-menu wd-300">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newEventQuestion"
                >
                  <Plus className="mg-r-5" />
                  Add new
                </button>
                <div className="dropdown-divider"></div>
              </div>
            </div>
          </div>
          <label className="form-group-title">Site and Survey</label>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-item-title">Event Site</label>
                <SelectHook className="wd-300-f" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-item-title">Survey</label>
                <SelectHook className="wd-300-f" />
              </div>
            </div>
          </div>
          <hr />
          <div className="form-group">
            <label className="form-item-title">Sponsor</label>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="replyTrackingSwitch"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="replyTrackingSwitch"></label>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Marketing Resource</label>
            <div className="table-responsive bd rounded-top">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Type
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Name
                    </th>
                    <th scope="col" className="bd-t-0-f wd-70">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td align="center">
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="bd bd-t-0 rounded-bottom pd-10">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select a source"
                />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    <ChevronDown />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Approval</label>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="eventApprovalSwitch"
                onClick={() => setShowApproval(!showApproval)}
              />
              <label className="custom-control-label" htmlFor="eventApprovalSwitch"></label>
            </div>
          </div>
          {showApproval && (
            <div className="form-group">
              <label className="form-item-title">Assigned Rep</label>
              <div className="input-group">
                <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                  <div className="form-control-tag">
                    <span className="d-block avatar avatar-xs mg-r-10">
                      <img
                        src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                        alt="MSR"
                        className="img rounded-circle"
                      />
                    </span>
                    <span className="form-control-tag-name">MSR</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      aria-label="삭제"
                    >
                      <X />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 mn-wd-250"
                    placeholder="Type or click to select an user"
                  />
                </div>
                <div className="input-group-append">
                  <button type="button" className="btn" aria-label="open modal to select an user">
                    <User />
                    <span className="sr-only">담당자 선택 팝업 열기</span>
                  </button>
                </div>
              </div>
              <div className="custom-control custom-checkbox mg-t-5">
                <input type="checkbox" className="custom-control-input" id="roiCheck" />
                <label className="custom-control-label" htmlFor="roiCheck">
                  My work listing for assigned user/group
                </label>
              </div>
            </div>
          )}
          <div className="form-group">
            <label className="form-item-title">Conversion Process</label>
            <ol className="conversion-process">
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Lead Capture</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Created</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">MQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">SQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Account/Contact Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch2"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch2"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Opportunity</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Opprtunity Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Sales</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Make Payment</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch4"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch4"></label>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </>
      )}
      {props.value == 2 && (
        <>
          <div className="form-group-title mg-t-0-f">Location</div>
          <div className="form-group">
            <label className="form-item-title d-block">Location Name</label>
            <div className="row">
              <div className="col-lg-6">
                <div className="dropdown">
                  <div className="input-group" data-toggle="dropdown">
                    <Input type="text" value="Location AAA" />
                    <div className="input-group-append">
                      <Button color="" className="input-group-btn" icon="ChevronDown" />
                    </div>
                  </div>
                  <div className="dropdown-menu wd-100p">
                    <Button
                      color=""
                      className="dropdown-item"
                      icon="Plus"
                      iconClass="mg-r-5"
                      name="Add New Location"
                    />
                    <div className="dropdown-divider"></div>
                    <Button color="" className="dropdown-item" name="Location AAA" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="locationOption"
                    id="locationOption1"
                  />
                  <label className="custom-control-label" htmlFor="locationOption1">
                    Internal
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="locationOption"
                    id="locationOption2"
                  />
                  <label className="custom-control-label" htmlFor="locationOption2">
                    External
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Address</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Building</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Room</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Cost</label>
            <div>&nbsp;</div>
          </div>
          <hr />
          <div className="form-group">
            <label className="form-item-title d-block">No. of Attendees</label>
            <div className="row">
              <div className="col-lg-6">
                <div className="input-group mg-b-10">
                  <div className="input-group-prepend">
                    <span className="input-group-text lh-1">Minimum</span>
                  </div>
                  <input type="number" className="form-control" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-group mg-b-10">
                  <div className="input-group-prepend">
                    <span className="input-group-text lh-1">Maximum</span>
                  </div>
                  <input type="number" className="form-control" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text lh-1">Webcast</span>
                  </div>
                  <input type="number" className="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group-title">Offer and Display</div>
          <div className="form-group">
            <label className="form-item-title">Pre-Event</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Check-in</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Event Display</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Check-out</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Post-Event</label>
            <div>&nbsp;</div>
          </div>
          <hr />
          <div className="form-group">
            <label className="form-item-title">Event Team Members</label>
            <div className="table-responsive bd rounded-top">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Name
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Mobile
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Email
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Member Type
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Role
                    </th>
                    <th scope="col" className="bd-t-0-f wd-70">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="bd bd-t-0 rounded-bottom pd-10">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select an user"
                />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    <User />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group-title">Finalcial</div>
          <div className="form-group">
            <label className="form-item-title">Budget allocated</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Target Revenue</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Eotal Registration fee</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Revenue from Sposorship</label>
            <div>&nbsp;</div>
          </div>
          <hr />
          <div className="form-group">
            <label className="form-item-title">Expenses to include in Marketing Investment</label>
            <div className="table-responsive bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 basic-table">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Expense Name
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Expense
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Miscellaneous Cost</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text lh-1">Total Exepenses</span>
              </div>
              <input type="number" className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Key Performance Indicator</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon mg-l-auto rounded-0"
                icon="Plus"
              />
            </div>
            <div className="d-flex align-items-center pd-10 bd rounded">
              Click through rate <Input type="number" className="wd-100 mg-x-10" />%
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Return on Marketing Investment</label>
            <SelectHook
              width={250}
              options={[
                { value: 1, label: 'Gross Profit' },
                { value: 2, label: 'Customer Lifetime Value' },
              ]}
              defaultValue={{ value: 1, label: 'Gross Profit' }}
            />
            <div className="custom-control custom-checkbox mg-t-5">
              <input type="checkbox" className="custom-control-input" id="roiCheck" />
              <label className="custom-control-label" htmlFor="roiCheck">
                Add Sponsorship Revenue to Return
              </label>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ActEvent;
