import { useState } from 'react';

import { Button, Input, RadioGroup, Select, Select as SelectHook } from '@base/components/form';
import { Clock, CornerDownRight } from 'react-feather';
import { UserAutoComplete } from '@base/containers';

const ActivityDM = (props: any) => {
  const SEND_OPTION = [
    {
      label: 'One time',
      value: 1,
    },
    {
      label: 'Frequently often campaign open',
      value: 2,
    },
  ];
  const [sendOption, setSendOption] = useState(SEND_OPTION);
  const [selectedSendOption, setSelectedSendOption] = useState(SEND_OPTION[0]);
  //change
  const handleSendOptionChange = (newOption: any) => {
    setSelectedSendOption(newOption);
  };

  const SCHEDULE_OPTION = [
    {
      label: 'Send Now',
      value: 1,
    },
    {
      label: 'Schedule',
      value: 2,
    },
  ];
  const [schedule, setSchedule] = useState(SCHEDULE_OPTION);
  const [selectedSchedule, setSelectedSchedule] = useState(SCHEDULE_OPTION[0]);
  //change
  const handleScheduleChange = (newOption: any) => {
    setSelectedSchedule(newOption);
  };

  const DM_TYPE = [
    { value: 1, label: 'Envelope' },
    { value: 2, label: 'Package' },
    { value: 3, label: 'Postcard' },
    { value: 4, label: 'Selef-Mailer' },
  ];
  const [dmType, setDmType] = useState(DM_TYPE);
  const [selectedType, setSelectedType] = useState(DM_TYPE[0]);
  //change
  const handleTypeChange = (newOption: any) => {
    setSelectedType(newOption);
  };

  return (
    <div className="overflow-auto pd-15">
      {props.value == 1 && (
        <>
          <div className="form-group">
            <label className="form-item-title d-block">Send Schedule</label>
            <RadioGroup
              value={selectedSendOption.value}
              options={sendOption}
              onChange={handleSendOptionChange}
            />
            {selectedSendOption.value === 1 && (
              <RadioGroup
                value={selectedSchedule.value}
                options={schedule}
                onChange={handleScheduleChange}
              />
            )}
            {selectedSchedule.value === 2 && (
              <div className="form-group">
                <label className="form-item-title">Date</label>
                <div className="input-group wd-300">
                  <input type="text" className="form-control" />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Clock />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="form-item-title">Assign to</label>
            <UserAutoComplete showAvatar />
          </div>
          <div className="form-group">
            <label className="form-item-title">Choose a Type</label>
            <div className="d-flex flex-wrap bd rounded">
              <div className="pd-15 bd-r">
                <Select
                  width={200}
                  options={dmType}
                  value={selectedType}
                  onChange={handleTypeChange}
                  className="mg-r-10"
                />
              </div>
              <div className="d-flex flex-grow-1 flex-wrap align-items-center justify-content-center pd-15 bg-light">
                <div className="card" style={{ width: '180px' }}>
                  <div className="ht-200">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFJzXkOdSrSUvrR5ugTyiAZK_iNHzQe4HC3w&usqp=CAU"
                      className="img-fit-cover"
                      alt=""
                    />
                  </div>
                  <div className="pd-x-15 pd-y-10 bd-t text-truncate">Name 1</div>
                </div>
              </div>
            </div>
          </div>
          {selectedType.value !== 3 && (
            <>
              <div className="form-group-title">Choose Marketing Collecterals</div>
              <div className="form-group">
                <div className="d-flex align-items-center mg-b-5">
                  <label className="form-item-title mg-b-0">Print Ads</label>
                  <Button
                    color="primary"
                    size="xs"
                    outline
                    className="btn-icon mg-l-auto rounded-0"
                    icon="Plus"
                    data-toggle="modal"
                    data-target="#selectCollateral"
                  />
                </div>
                <div className="d-flex flex-wrap pd-15 bd rounded bg-light">
                  <div className="card mg-r-10" style={{ width: '180px' }}>
                    <div className="ht-200">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyruOyiPaLr5xtimBZVHk9JvjnxjVdydLyOw&usqp=CAU"
                        className="img-fit-cover"
                        alt=""
                      />
                    </div>
                    <div className="pd-x-15 pd-y-10 bd-t text-truncate">Name 1</div>
                  </div>
                  <div className="card mg-r-10" style={{ width: '180px' }}>
                    <div className="ht-200">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqsWqsbjEmifNN1ZQuzy4fjAMG7w7hExUUDQ&usqp=CAU"
                        className="img-fit-cover"
                        alt=""
                      />
                    </div>
                    <div className="pd-x-15 pd-y-10 bd-t text-truncate">Name 2</div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center mg-b-5">
                  <label className="form-item-title mg-b-0">Promotional Item</label>
                  <Button
                    color="primary"
                    size="xs"
                    outline
                    className="btn-icon mg-l-auto rounded-0"
                    icon="Plus"
                  />
                </div>
                <div className="d-flex flex-wrap pd-15 bd rounded bg-light">
                  <div className="card mg-r-10" style={{ width: '180px' }}>
                    <div className="ht-200">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqkhWEGmm586ZYX_NY38dar0KWcC76mJWczw&usqp=CAU"
                        className="img-fit-cover"
                        alt=""
                      />
                    </div>
                    <div className="pd-x-15 pd-y-10 bd-t text-truncate">Name 1</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {props.value == 2 && (
        <>
          <div className="form-group">
            <label className="form-item-title">Expenses to include in Marketing Investment</label>
            <div className="table-responsive bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 basic-table">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Expense Name
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Expense
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Return on Marketing Investment</label>
            <SelectHook
              width={250}
              options={[
                { value: 1, label: 'Gross Profit' },
                { value: 2, label: 'Customer Lifetime Value' },
              ]}
              defaultValue={{ value: 1, label: 'Gross Profit' }}
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Key Performance Indicator</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon mg-l-auto rounded-0"
                icon="Plus"
              />
            </div>
            <div className="d-flex align-items-center pd-10 bd rounded">
              Click through rate <Input type="number" className="wd-100 mg-x-10" />%
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Conversion Process</label>
            <ol className="conversion-process">
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">MQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">SQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Account/Contact Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch2"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch2"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Opportunity</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Opprtunity Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Sales</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Make Payment</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch4"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch4"></label>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div className="form-group-title">Links</div>
          <div className="card">
            <div className="card-header">Stick CTAs in Printed Ads</div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-item-title">CTA Name</label>
                <div>CTA 1</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">URL</label>
                <div>&nbsp;</div>
              </div>
              <div className="form-group">
                <label className="form-item-title d-block">Personalized QR Code and URL</label>
                <div className="d-flex">
                  <CornerDownRight strokeWidth={1} />
                  <div className="wd-100p">
                    <div className="card bg-light mg-b-15">
                      <div className="card-header pd-y-10">Link</div>
                      <div className="card-body">
                        <div className="form-group">
                          <label className="form-item-title">Landing Page/Site</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Page Title</label>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ActivityDM;
