import Icon, { SvgIcons } from '@base/assets/icons/svg-icons';
import { Download, UploadCloud, X } from 'react-feather';

const AttachmentsList = () => {
  return (
    <>
      <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-5 bg-light rounded">
        <div
          className="media align-items-center flex-grow-1"
          style={{ maxWidth: 'calc(100% - 72px' }}
        >
          <span className="file-type-icon">
            <SvgIcons type="doc" />
          </span>
          <div className="media-body mg-l-10 text-truncate">
            계약서계약서계약서계약서계약서계약서계약서계약서계약서계약서.doc
            <small className="d-block text-muted text-truncate">
              (18.94 KB) 2019/11/02 12:00:12
            </small>
          </div>
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Download"
          >
            <Download /> <span className="sr-only">내려받기</span>
          </button>
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
          >
            <X className="tx-danger" />
            <span className="sr-only">삭제</span>
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-5 bg-light rounded">
        <div
          className="media align-items-center flex-grow-1"
          style={{ maxWidth: 'calc(100% - 72px' }}
        >
          <span className="file-type-icon">
            <SvgIcons type="txt" />
          </span>
          <div className="media-body mg-l-10 text-truncate">
            sample_list.txt
            <small className="d-block text-muted text-truncate">
              (18.94 KB) 2019/11/02 12:00:12
            </small>
          </div>
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Download"
          >
            <Download /> <span className="sr-only">내려받기</span>
          </button>
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
          >
            <X className="tx-danger" />
            <span className="sr-only">삭제</span>
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
        <UploadCloud size="20" className="mg-r-10" />
        Drag &amp; Drop Files
      </div>
      <button type="button" className="btn btn-link">
        <span className="mg-r-5">{Icon('file_zip')}</span>Download all
      </button>
    </>
  );
};

export default AttachmentsList;
