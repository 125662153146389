import { Select } from '@base/components/form';
import React, { useEffect, useState } from 'react';

const OPTIONS = [
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'closed',
    label: 'Closed',
  },
];

const Stage: React.FC<any> = (props: any) => {
  const { className, value, onChange } = props;

  //state
  const [selectedValue, setSelectedValue] = useState<any>(null);

  //init data
  useEffect(() => {
    if (value) {
      //single object
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(OPTIONS[0]);
    }
  }, [value]);

  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption);
    //callback
    onChange && onChange(newOption.value);
  };

  return (
    <Select
      options={OPTIONS}
      value={selectedValue}
      onChange={handleValueChange}
      className={className}
    />
  );
};

export default Stage;
