import { Edit2, Plus, Zap, Link } from 'react-feather';

type propTypes = {
  showEdit: any;
  setShowEdit: Function;
  showDetail: any;
  setShowDetail: Function;
};

const Desk = (props: propTypes) => {
  return (
    <div className="d-flex flex-column flex-grow-1 pd-20 overflow-auto">
      <div className="stage-wrap">
        <div className="stage-item stage-open">
          <div data-han-tooltip="New">
            <div className="text-truncate">New</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="stage-item" style={{ width: '470px' }}>
          <div data-han-tooltip="Assign Tickets">
            <div className="text-truncate">Assign Tickets</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="stage-item">
          <div data-han-tooltip="Open">
            <div className="text-truncate">Open</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="stage-item" style={{ width: '470px' }}>
          <div data-han-tooltip="Resolved">
            <div className="text-truncate">Resolved</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="stage-item stage-closed">
          <div data-han-tooltip="Closed">
            <div className="text-truncate">Closed</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="pos-relative flex-grow-1">
        <div className="diagram-item-wrap" style={{ top: '5px' }}>
          <div className="diagram-item diagram-trigger">
            <div className="diagram-item-header">
              <span className="diagram-item-name">
                <Zap size={14} className="mg-r-5" />
                Record Created
              </span>
            </div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '50px' }}>
          <div className="diagram-item diagram-action han-tooltip--top" data-han-tooltip="New">
            <div className="diagram-item-name">New</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward" style={{ top: '15px', width: '650px' }}>
            <div className="diagram-direction-text">Assigned</div>
          </div>
          <div className="diagram-direction-split-choice" style={{ top: '18px' }}>
            <div className="diagram-direction-text"></div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '80px', left: '260px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Assignment Queue"
          >
            <div className="diagram-item-name">Assignment Queue</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward" style={{ top: '13px', width: '390px' }}>
            <div className="diagram-direction-text">Assigned</div>
          </div>
          <div className="diagram-direction-split-choice" style={{ top: '15px' }}>
            <div className="diagram-direction-text"></div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '110px', left: '520px' }}>
          <div
            className="diagram-item diagram-action manual han-tooltip--top"
            data-han-tooltip="Unassigned"
          >
            <div className="diagram-item-name">Unassigned</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward" style={{ top: '13px' }}>
            <div className="diagram-direction-text">Assigned</div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '50px', left: '780px' }}>
          <div className="diagram-item diagram-action han-tooltip--top" data-han-tooltip="Open">
            <div className="diagram-item-name">Open</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward" style={{ width: '650px' }}>
            <div className="diagram-direction-text">Closed</div>
          </div>
          <div className="diagram-direction-split-choice" style={{ height: '125px' }}>
            <div className="diagram-direction-text">Resolved</div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '170px', left: '1040px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Send by Email"
          >
            <div className="diagram-item-name">Send by Email</div>
            {!props.showEdit && (
              <>
                <div className="diagram-item-status-wrap">
                  <span className="diagram-item-status">Sent</span>
                  <button type="button" className="btn" data-toggle="dropdown">
                    <Plus />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right diagram-item-status-detail">
                    <div className="diagram-item-status">Opened</div>
                    <div className="diagram-item-status">Scheduled</div>
                  </div>
                </div>
              </>
            )}
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward">
            <div className="diagram-direction-text"></div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '170px', left: '1300px' }}>
          <div className="diagram-item diagram-site han-tooltip--top" data-han-tooltip="Desk Site">
            <div className="diagram-item-name">Desk Site</div>
            {!props.showEdit && (
              <button
                type="button"
                className="btn btn-link site-link"
                data-toggle="modal"
                data-target="#siteLinksModal"
              >
                <Link />
              </button>
            )}
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-join" style={{ height: '120px', top: '-80px' }}></div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '70px', left: '1560px' }}>
          <div className="diagram-item diagram-status han-tooltip--top" data-han-tooltip="Closed">
            <div className="diagram-item-name">Closed</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#newClosedStatus"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="diagram-item-wrap" style={{ top: '120px', left: '1560px' }}>
          <div className="diagram-item diagram-status han-tooltip--top" data-han-tooltip="Canceled">
            <div className="diagram-item-name">Canceled</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#newClosedStatus"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="diagram-item-wrap" style={{ top: '170px', left: '1560px' }}>
          <div className="diagram-item diagram-status han-tooltip--top" data-han-tooltip="Deleted">
            <div className="diagram-item-name">Deleted</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#newClosedStatus"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="diagram-item-wrap" style={{ top: '220px', left: '1560px' }}>
          <div className="diagram-item diagram-status han-tooltip--top" data-han-tooltip="Merged">
            <div className="diagram-item-name">Merged</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#newClosedStatus"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="diagram-item-wrap" style={{ top: '270px', left: '1560px' }}>
          <div className="diagram-item diagram-status han-tooltip--top" data-han-tooltip="Denied">
            <div className="diagram-item-name">Denied</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#newClosedStatus"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desk;
