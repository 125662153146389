import { useState } from 'react';

const StorageMap = () => {
  const [showStorageDetail, setShowStorageDetail] = useState<any>();

  return (
    <>
      <ul className="nav nav-line bd-1">
        <li className="nav-item">
          <button type="button" className="btn nav-link pd-x-10-f active" data-toggle="tab">
            Building 1
          </button>
        </li>
      </ul>
      <ul className="nav nav-line bd-1">
        <li className="nav-item">
          <button type="button" className="btn nav-link pd-x-10-f active" data-toggle="tab">
            Floor 1
          </button>
        </li>
        <li className="nav-item">
          <button type="button" className="btn nav-link pd-x-10-f" data-toggle="tab">
            Floor 2
          </button>
        </li>
      </ul>
      <ul className="nav nav-line bd-1">
        <li className="nav-item">
          <button type="button" className="btn nav-link pd-x-10-f active" data-toggle="tab">
            Storage zone 1
          </button>
        </li>
        <li className="nav-item">
          <button type="button" className="btn nav-link pd-x-10-f" data-toggle="tab">
            Storage zone 2
          </button>
        </li>
      </ul>
      <ul className="nav nav-line bd-1">
        <li className="nav-item">
          <button type="button" className="btn nav-link pd-x-10-f active" data-toggle="tab">
            Row 1
          </button>
        </li>
        <li className="nav-item">
          <button type="button" className="btn nav-link pd-x-10-f" data-toggle="tab">
            Row 2
          </button>
        </li>
      </ul>
      <div className="pd-x-15 pd-y-10 text-right">
        <span className="d-inline-block wd-15 ht-15 mg-x-10 rounded bg-danger"></span>Occupied
        <span className="d-inline-block wd-15 ht-15 mg-x-10 rounded bg-gray-200"></span>Empty
      </div>
      <div className="d-flex flex-grow-1 pd-15 overflow-auto">
        <div className="wd-80 flex-shrink-0">
          <div>Rack</div>
          <div>Level</div>
        </div>
        <div className="flex-1 overflow-x-auto">
          <div className="d-flex mg-l-20">
            <div className="wd-25 mg-2 text-center">1</div>
            <div className="wd-25 mg-2 text-center">2</div>
            <div className="wd-25 mg-2 text-center">3</div>
            <div className="wd-25 mg-2 text-center">4</div>
            <div className="wd-25 mg-2 text-center">5</div>
            <div className="wd-25 mg-2 text-center">6</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="wd-20 flex-shrink-0">A</div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button
                type="button"
                className="btn btn-view-storage-info"
                onMouseOver={() => setShowStorageDetail(true)}
                onMouseOut={() => setShowStorageDetail(false)}
                onFocus={() => setShowStorageDetail(true)}
                onBlur={() => setShowStorageDetail(false)}
              >
                <span className="sr-only">View detail</span>
              </button>
              {showStorageDetail && (
                <div className="card shadow-sm card-storage-detail">
                  <table className="table mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col">Bin Name</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Batch No</th>
                        <th scope="col">Stock on Hand</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>B01</td>
                        <td>Item 1</td>
                        <td>Ite-red-sma</td>
                        <td>Ite-20210431</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        <td>B02</td>
                        <td>Item 1</td>
                        <td>Ite-red-sma</td>
                        <td>Ite-20210701</td>
                        <td>500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="wd-20 flex-shrink-0">B</div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="wd-20 flex-shrink-0">C</div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-danger">
              <span className="sr-only">Occupied</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
            <div className="pos-relative flex-shrink-0 wd-25 ht-25 mg-2 rounded bg-gray-200">
              <span className="sr-only">Empty</span>
              <button type="button" className="btn btn-view-storage-info">
                <span className="sr-only">View detail</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorageMap;
