import { Button, Checkbox, Switch } from '@base/components/form';
import NewGoal from './modal/newGoal';
import SelectMetricName from './modal/selectMetricName';
const GoalMetric = () => {
  return (
    <>
      <div className="page-container-wrap">
        <div className="page-container-content">
          <div className="page-container-content-header">
            <h1 className="mg-b-0 tx-16 tx-uppercase tx-normal mg-l-10 mg-r-10">Goal Metric</h1>
            <Button
              color="primary"
              className="mg-l-auto"
              icon="Plus"
              iconClass="mg-r-5"
              name="Add"
              data-toggle="modal"
              data-target="#newGoal"
            />
          </div>
          <div className="scroll-box ht-100v pd-20">
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                <thead>
                  <tr>
                    <th scope="col" className="wd-30">
                      <Checkbox />
                    </th>
                    <th scope="col" className="sorting_asc">
                      Goal Name
                    </th>
                    <th scope="col" className="sorting">
                      Period
                    </th>
                    <th scope="col" className="sorting">
                      Type
                    </th>
                    <th scope="col" className="wd-80">
                      Active
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <Button
                        color="link"
                        name="ABCD"
                        className="pd-0"
                        data-toggle="modal"
                        data-target="#newGoal"
                      />
                    </td>
                    <td>Weekly</td>
                    <td>Team Goals</td>
                    <td>
                      <Switch />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NewGoal />
      <SelectMetricName />
    </>
  );
};

export default GoalMetric;
