import { Button, Checkbox } from '@base/components/form';

const SelectAgreement = () => {
  return (
    <div id="selAgreement" className="modal fade">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title h6">Add Terms Agreement</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body">
            <Checkbox label="Agreement Name 4 (Required)" />
            <Checkbox label="Agreement Name 5 (Optional)" />
            <Checkbox label="Agreement Name 6 (Required)" />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectAgreement;
