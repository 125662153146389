import { X } from 'react-feather';

const ReduceUser = () => {
  return (
    <div id="reduceUser" className="modal fade right">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Reduce a User</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive bd rounded">
              <table className="table mg-b-0 bd-t-0">
                <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Purchased</th>
                    <th scope="col">Reduce</th>
                    <th scope="col">User</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AAA</td>
                    <td>5</td>
                    <td>
                      <input type="number" min="0" className="form-control" />
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-toggle="modal"
              data-target="#terminationConfirm"
            >
              Reduce
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReduceUser;
