import React from 'react';
import Icon from '@base/assets/icons/svg-icons';

const BasicInfo = () => {
  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <span className="view-aside-section-header-title">Summary</span>
      </div>
      <div className="view-aside-section-body">
        <div className="form-group">
          <label className="form-item-title">Supply Method</label>
          <div>Purchased</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Active</label>
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="active-01" defaultChecked />
            <label className="custom-control-label" htmlFor="active-01"></label>
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Product ID</label>
          <div>Prod-0000001</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Product Group</label>
          <div>Group 1</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Base Unit</label>
          <div>Bottle</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Cost of Goods</label>
          <div>30%</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Created by</label>
          <div className="media align-items-center">
            <div className="avatar avatar-sm">
              <img
                src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                className="rounded-circle"
                alt="MSR"
              />
            </div>
            <div className="media-body mg-l-10">
              <div>MSR</div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Attributes</label>
          <div>Size, Color</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Sales Start Date</label>
          <div>2021/08/05</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Sales End Date</label>
          <div>No End Date</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Description</label>
          <div>&nbsp;</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Returnable</label>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="returnable-01"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="returnable-01"></label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
