import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  X,
  Check,
  CheckCircle,
  Circle,
  ArrowLeft,
  Copy,
  Edit2,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';

import TaskTimeline from './timeline';

import Attachments from '../../../component/desktop/attachments';
import AssignedRepList from '../../../component/desktop/assigned-rep-list';
import Userlist from '../../../component/desktop/userlist';
import ProductList from '../../../component/desktop/productlist';
import Chat from '../../../component/desktop/chat';
import Comment from '../../../component/desktop/comment';
import MeetingWrite from './meeting-write';
import { Button, Input } from '@base/components/form';

const TABS = [
  {
    value: 1,
    label: 'Agenda',
  },
  {
    value: 2,
    label: 'Prepare Questions',
  },
  {
    value: 3,
    label: 'Meeting Note',
  },
  {
    value: 4,
    label: 'Timeline',
  },
  {
    value: 5,
    label: 'Note',
  },
  {
    value: 6,
    label: 'TeamChannel',
  },
];
const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const MeetingView = () => {
  const [tab, setTab] = useState(TABS[0]);

  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [hideAsideRight, setHideAsideRight] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  const [agenda, setAgenda] = useState('');
  const [agendas, setAgendas] = useState<string[]>([]);
  const addAgenda = (e: any) => {
    e.preventDefault();
    if (agenda !== '') {
      setAgendas((currentArray) => [agenda, ...currentArray]);
      setAgenda('');
    } else {
      return;
    }
  };

  const [question, setQuestion] = useState('');
  const [questions, setQuestions] = useState<string[]>([]);
  const addQuestion = (e: any) => {
    e.preventDefault();
    if (question !== '') {
      setQuestions((currentArray) => [question, ...currentArray]);
      setQuestion('');
    } else {
      return;
    }
  };

  const [topic, setTopic] = useState('');
  const [topics, setTopics] = useState<string[]>([]);
  const addTopic = (e: any) => {
    e.preventDefault();
    if (topic !== '') {
      setTopics((currentArray) => [topic, ...currentArray]);
      setTopic('');
    } else {
      return;
    }
  };

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/activity/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Activity
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title">Meeting Subject</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <button type="button" className="btn btn-info">
                Send
              </button>
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Copy />
                    </span>
                    Clone
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="dropdown">
                        <button type="button" data-han-tooltip="More" className="btn btn-white btn-icon rounded-circle han-tooltip" data-toggle="dropdown">
                            <span className="span-icon"><MoreHorizontal /></span>
                            <span className="sr-only">더보기</span>
                        </button>
                    </div> */}
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newMeetingModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
            <li className="process-item done">
              <button type="button" className="btn process-item-btn">
                <Check />
              </button>
              <div className="process-item-text mg-t-10">Research</div>
            </li>
            <li className="process-item done">
              <button type="button" className="btn process-item-btn">
                <Check />
              </button>
              <div className="process-item-text mg-t-10">Agenda</div>
            </li>
            <li className="process-item done">
              <button type="button" className="btn process-item-btn">
                <Check />
              </button>
              <div className="process-item-text mg-t-10">Prepare Questions</div>
            </li>
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Meeting Note</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>Report Meeting</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Follow up</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Closed</div>
            </li>
          </ol>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title d-block">Priority</label>
                    <span className="d-inline-block pd-x-15 pd-y-3 bg-orange tx-white rounded-30">
                      High
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Customer</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Purpose</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Meeting Date</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Expected Duration</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Meeting Note Template</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Marketing Collateral</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Regarding</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Response</label>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {/* Agenda */}
              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="table-responsive bg-white bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Title
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Time
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Speaker
                          </th>
                          <th scope="col" className="wd-70 bd-t-0-f">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="react-editable pos-relative">
                              <div className="input-edit pd-8 pd-r-30">
                                <span className="input-edit-item">Lead management</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="react-editable pos-relative">
                              <div className="input-edit pd-8 pd-r-30">
                                <span className="input-edit-item">13:00 ~ 13:15</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="react-editable pos-relative">
                              <div className="input-edit pd-8 pd-r-30">
                                <div className="media align-items-center">
                                  <div className="avatar avatar-xs">
                                    <span className="avatar-initial rounded-circle bg-gray-600">
                                      S
                                    </span>
                                  </div>
                                  <div className="media-body pd-l-10">Socrates</div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button type="button" className="btn btn-icon pd-y-0">
                              <X className="tx-danger" />
                            </button>
                          </td>
                        </tr>
                        {agendas.map((item) => (
                          <tr>
                            <td>
                              <div className="react-editable pos-relative">
                                <div className="input-edit pd-8 pd-r-30">
                                  <span className="input-edit-item">{item}</span>
                                </div>
                              </div>
                              {/* <input type="text" className="form-control" /> */}
                            </td>
                            <td>
                              <div className="input-edit pd-8 pd-r-30" contentEditable></div>
                              {/*  Edit
                              <div className="input-group input-date-group dropdown">
                                <input type="text" className="form-control bd-r-0" />
                                <div className="input-group-append">
                                  <span className="input-group-text bg-white tx-gray-300">
                                    <Clock />
                                  </span>
                                </div>
                              </div> 
                              */}
                            </td>
                            <td>
                              <div className="react-editable pos-relative">
                                <div className="input-edit pd-8 pd-r-30" contentEditable></div>
                              </div>
                              {/*  Edit
                                <div className="input-group">
                                  <input type="text" className="form-control bd-r-0" />
                                  <div className="input-group-append">
                                    <button type="button" className="btn" aria-label="open selecting user modal">
                                      <User />
                                      <span className="sr-only">발표자 선택창 열기</span>
                                    </button>
                                  </div>
                                </div>
                              */}
                            </td>
                            <td>
                              <button type="button" className="btn btn-icon pd-y-0">
                                <X className="tx-danger" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <form onSubmit={addAgenda}>
                    <div className="d-flex align-items-center pd-10 bg-white bd bd-t-0 rounded-bottom">
                      <input
                        type="text"
                        className="form-control mg-r-5"
                        placeholder="Enter the title"
                        value={agenda}
                        onChange={(e) => setAgenda(e.target.value)}
                      />
                      <button type="submit" className="btn btn-primary text-nowrap">
                        <Plus className="mg-r-5" />
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {/* Prepare Questions */}
              {tab.value == 2 && (
                <div className="pd-20">
                  {questions.map((item) => (
                    <div className="card mg-b-15">
                      <div className="card-header flex-nowrap pd-10">
                        {/* Edit
                          <input type="text" className="form-control" defaultValue={item} /> 
                        */}
                        <div className="input-edit pd-8 pd-r-30">
                          <span className="input-edit-item">{item}</span>
                        </div>
                        <button type="button" className="btn btn-icon mg-l-auto pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </div>
                      <div className="card-body pd-10">
                        <div className="pd-8 pd-r-30" contentEditable></div>
                        {/* Edit 
                          <textarea cols="10" rows="3" className="form-control"></textarea> 
                          */}
                      </div>
                    </div>
                  ))}

                  <form onSubmit={addQuestion}>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control mg-r-5"
                        placeholder="Enter the question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                      />
                      <button type="submit" className="btn btn-primary text-nowrap">
                        <Plus className="mg-r-5" />
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {/* Meeting Note */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="card mg-b-15">
                    <div className="card-header flex-nowrap pd-10">
                      {/* Edit
                        <input type="text" className="form-control" defaultValue="Topic 1" /> 
                      */}
                      <div className="react-editable pos-relative">
                        <div className="input-edit pd-8 pd-r-30">
                          <span className="input-edit-item">Topic 1</span>
                        </div>
                      </div>
                      <button type="button" className="btn btn-icon mg-l-auto pd-y-0">
                        <X className="tx-danger" />
                      </button>
                    </div>
                    <div className="card-body pd-10">
                      <div className="react-editable pos-relative">
                        <div className="input-edit pd-8 pd-r-30" contentEditable></div>
                      </div>
                      {/* Edit 
                        <textarea cols="10" rows="3" className="form-control"></textarea> 
                      */}
                    </div>
                  </div>
                  {topics.map((item) => (
                    <div className="card mg-b-15">
                      <div className="card-header flex-nowrap pd-10">
                        <div className="react-editable pos-relative">
                          <div className="input-edit pd-8 pd-r-30">
                            <span className="input-edit-item">{item}</span>
                          </div>
                        </div>
                        <button type="button" className="btn btn-icon mg-l-auto pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </div>
                      <div className="card-body pd-10">
                        <div className="react-editable pos-relative">
                          <div className="input-edit pd-8 pd-r-30" contentEditable></div>
                        </div>
                        {/* Edit 
                          <textarea cols="10" rows="3" className="form-control"></textarea> 
                        */}
                      </div>
                    </div>
                  ))}
                  <form onSubmit={addTopic}>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control mg-r-5"
                        placeholder="Enter the topic"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                      />
                      <button type="submit" className="btn btn-primary text-nowrap">
                        <Plus className="mg-r-5" />
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {tab.value == 4 && <TaskTimeline />}
              {tab.value == 5 && <Comment />}
              {tab.value == 6 && <Chat />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Attendees */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Assemble Attendees</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#activityAttendees"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="activityAttendees" className="view-aside-section-body collapse show">
                  <div className="form-group">
                    <label className="form-item-title d-block">Build a Team</label>
                    <div className="d-flex align-items-start mg-b-3 pd-10 bg-light rounded">
                      <div>
                        <span className="badge bg-teal tx-white tx-13">Leader</span>
                        <div className="d-flex flex-wrap">
                          <div className="d-inline-flex align-items-center mg-r-5 mg-t-5 pd-x-10 pd-y-5 bg-white bd rounded-30">
                            <span className="d-block avatar avatar-xs mg-r-10">
                              <span className="avatar-initial rounded-circle">M</span>
                            </span>
                            <span className="form-control-tag-name">MSR</span>
                            <Button
                              type="button"
                              icon="X"
                              color=""
                              className="btn-icon mg-l-5 pd-0 form-control-tag-remove"
                              data-han-tooltip="Delete"
                              aria-label="삭제"
                            />
                          </div>
                          <div className="d-inline-flex align-items-center mg-r-5 mg-t-5 pd-x-10 pd-y-5 bg-white bd rounded-30">
                            <span className="d-block avatar avatar-xs mg-r-10">
                              <span className="avatar-initial rounded-circle">M</span>
                            </span>
                            <span className="form-control-tag-name">MSM</span>
                            <Button
                              type="button"
                              icon="X"
                              color=""
                              className="btn-icon mg-l-5 pd-0 form-control-tag-remove"
                              data-han-tooltip="Delete"
                              aria-label="삭제"
                            />
                          </div>
                          <input
                            type="text"
                            placeholder="Type to add a member"
                            className="bg-transparent bd-0 rounded-0"
                          />
                        </div>
                      </div>
                      <Button
                        type="button"
                        icon="X"
                        color="link"
                        className="btn-icon mg-l-auto pd-0 tx-danger form-control-tag-remove"
                        data-han-tooltip="Delete"
                        aria-label="삭제"
                      />
                    </div>
                    {/* added new role */}
                    <div className="d-flex align-items-start mg-b-3 pd-10 bg-light rounded">
                      <div>
                        <span className="badge bg-teal tx-white tx-13">Member</span>
                        <div className="d-flex flex-wrap">
                          <input
                            type="text"
                            placeholder="Type to add a member"
                            className="bg-transparent bd-0 rounded-0"
                          />
                        </div>
                      </div>
                      <Button
                        type="button"
                        icon="X"
                        color="link"
                        className="btn-icon mg-l-auto pd-0 tx-danger form-control-tag-remove"
                        data-han-tooltip="Delete"
                        aria-label="삭제"
                      />
                    </div>
                    <Input rightIcon={<ChevronDown />} placeholder="Select to add a role" />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Identify Contact</label>
                    <div className="d-flex align-items-center mg-b-3 pd-10 bg-light rounded">
                      <div className="media mx-wd-100p flex-grow-1">
                        <div className="avatar avatar-sm">
                          <img
                            src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                            className="rounded-circle"
                            alt="Allan Rey Palban"
                          />
                        </div>
                        <div className="media-body mg-l-10">
                          <span className="badge bg-teal tx-white tx-13">Leader</span>
                          <div>
                            <a href="" target="_blank">
                              Allan Rey Palban<span className="tx-color-03">(Manager)</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <Button
                        color="link"
                        icon="X"
                        className="btn-icon mg-l-5 pd-0 tx-danger"
                        data-han-tooltip="Delete"
                      />
                    </div>
                    <div className="d-flex align-items-center mg-b-3 pd-10 bg-light rounded">
                      <div className="media mx-wd-100p flex-grow-1">
                        <div className="avatar avatar-sm">
                          <img
                            src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                            className="rounded-circle"
                            alt="Allan Rey Palban"
                          />
                        </div>
                        <div className="media-body mg-l-10">
                          <span className="badge bg-teal tx-white tx-13">Member</span>
                          <div>
                            <a href="" target="_blank">
                              Situmay<span className="tx-color-03">(Manager)</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <Button
                        color="link"
                        icon="X"
                        className="btn-icon mg-l-5 pd-0 tx-danger"
                        data-han-tooltip="Delete"
                      />
                    </div>
                    <Input
                      rightIcon={Icon('m_customer')}
                      placeholder="Type or click to select a contact"
                    />
                  </div>
                </div>
              </div>

              {/* Location */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Location</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#activityLocation"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="activityLocation" className="view-aside-section-body collapse show">
                  <div className="form-group">
                    <label className="form-item-title">Location Name</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Address</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Building</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Room</label>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              {/* Attachment */}
              <Attachments />

              {/* Product List */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Product</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerProduct"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerProduct" className="view-aside-section-body collapse show">
                  <ProductList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MeetingWrite />
    </>
  );
};

export default MeetingView;
