import { useState } from 'react';
import classnames from 'classnames';

import { COLORS } from '@base/config/constant';
import {
  Trash,
  ChevronDown,
  Plus,
  X,
  Edit2,
  CheckCircle,
  Circle,
  ArrowLeft,
  Printer,
  Copy,
  Check,
  Minus,
} from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "SalesCss/quote.scss"

import Timeline from './timeline';
import Attachments from '../../../../component/desktop/attachments';
import AssignedRep from '../../../../component/desktop/assigned-rep';
import Userlist from '../../../../component/desktop/userlist';
import Notelist from '../../../../component/desktop/comment';
import SalesCommission from '@demo-page/sales-order/desktop/theme1/view/sales-commission';
import CommissionShare from '@demo-page/sales-order/desktop/theme1/modal/commissionShare';

const TABS = [
  {
    value: 1,
    label: 'Details',
  },
  {
    value: 2,
    label: 'Product Detail',
  },
  {
    value: 3,
    label: 'Timeline',
  },
  {
    value: 4,
    label: 'Note',
  },
  {
    value: 5,
    label: 'Sales Commission',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [hideAsideRight, setHideAsideRight] = useState(false);
  const [showEditDetail, SetShowEditDetail] = useState(false);
  const [showEditDetail2, SetShowEditDetail2] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a href="/quote/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Quote
            <ChevronDown className="mg-l-5" />
          </button>
          <div className="dropdown-menu nav-sub">
            <div className="dropdown-item">
              <a role="button">Dashboard</a>
            </div>
            <div className="dropdown-item">
              <a role="button">Opportunity</a>
            </div>
            <div className="dropdown-item active">
              <a href="/crm/demo-page/ui/invoice/desktop/theme1/list">Quote</a>
              <button
                type="button"
                className="btn btn-xs btn-set-default btn-icon"
                aria-label="Set as default"
              >
                {/* Add'active' class if this menu is currently set by default */}
                {Icon('set_default')}
                <span className="sr-only">기본 메뉴로 설정</span>
              </button>
            </div>
          </div>
        </div>

        <h1 className="view-title">Quote-1630977508</h1>

        <div className="button-group d-flex flex-shrink-0 mg-l-auto">
          <div className="process-buttons mg-r-15">
            <div className="dropdown">
              <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                More
                <ChevronDown className="mg-l-5" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">{Icon('convert')}</span>Convert
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Printer />
                  </span>
                  Print
                </button>
                <button type="button" data-han-tooltip="Copy" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Copy />
                  </span>
                  Copy
                </button>
                <button type="button" data-han-tooltip="Delete" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div className="btn-group mg-l-5">
            <button
              type="button"
              className="btn btn-primary rounded-30"
              data-toggle="modal"
              data-target="#newQuote"
            >
              <Plus />
              New
            </button>
          </div>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLORS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* process */}
      <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
        <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
          <li className="process-item current">
            <button type="button" className="btn process-item-btn">
              <CheckCircle />
            </button>
            <div className="process-item-text mg-t-10">Draft</div>
            <div className="mg-t-5">
              <span className="tx-color-03 mg-r-5">Status</span>
              <span>Request approval</span>
            </div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Approving</div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Sent</div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Won</div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Close</div>
          </li>
        </ol>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 190px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          {/* collapse left aside */}
          {!hideAsideLeft && (
            <button
              type="button"
              className="btn btn-hide-aside-left"
              onClick={() => setHideAsideLeft(true)}
            >
              <LeftCollapse />
              <span className="sr-only">좌측 사이드 패널 접기</span>
            </button>
          )}
          <div className="view-aside-inner scroll-box">
            <div className="view-aside-section pd-15">
              <div className="d-flex pd-10">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                  Total Amount
                </div>
                <div className="mg-l-auto  tx-15 tx-semibold">￦100,000</div>
              </div>
            </div>

            {/* Summary */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Summary</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#basicInfoSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="basicInfoSection" className="view-aside-section-body collapse show">
                <div className="form-group">
                  <label className="form-item-title">Process</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Owner</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-xs">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <span className="user-name">MSR</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Valid until</label>
                  <div>2021-05-30</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Quote Date</label>
                  <div>2021-05-30</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Expriation Date</label>
                  <div>2021-05-30</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Quote Template</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Email Template</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Create Invocie</label>
                  <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" id="invoiceSwitch1" />
                    <label className="custom-control-label" htmlFor="invoiceSwitch1"></label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Use Price list</label>
                  <div>Template 2</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames('col-6 view-content', {
            'col-9': hideAsideLeft || hideAsideRight,
            'col-12': hideAsideLeft && hideAsideRight,
          })}
        >
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map(
                  (item, index) =>
                    item.value < 3 && (
                      <li
                        key={index}
                        className={classnames('nav-item', {
                          active: item.value == tab.value,
                        })}
                      >
                        <button
                          type="button"
                          className="btn btn-xs btn-move-tab"
                          aria-label="Drag-and-drop to reorder"
                        >
                          {Icon('move')}
                          <span className="sr-only">드래그앤드롭으로 이동</span>
                        </button>
                        <button
                          type="button"
                          className="btn nav-link"
                          onClick={(e) => {
                            setTab(item);
                          }}
                        >
                          {item.label}
                        </button>
                      </li>
                    ),
                )}
                <li
                  className={classnames('nav-item dropdown pd-0-f', {
                    active: tab.value > 2,
                  })}
                >
                  <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                    <Plus className="mg-r-5" />
                    {tab.value > 2 ? tab.label : 'More'}
                  </button>
                  <div className="dropdown-menu dropdown-menu-right bg-white">
                    {TABS.map(
                      (item) =>
                        item.value > 2 && (
                          <div className="dropdown-item">
                            <button
                              type="button"
                              className="btn btn-xs btn-move-tab"
                              aria-label="Drag-and-drop to reorder"
                            >
                              {Icon('move')}
                              <span className="sr-only">드래그앤드롭으로 이동</span>
                            </button>
                            <button
                              type="button"
                              className={classnames('btn', {
                                active: item.value == tab.value,
                              })}
                              onClick={(e) => {
                                setTab(item);
                              }}
                            >
                              {item.label}
                            </button>
                          </div>
                        ),
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="view-body scroll-box">
            {/* show left aside */}
            {hideAsideLeft && (
              <button
                type="button"
                className="btn btn-show-aside-left"
                onClick={() => setHideAsideLeft(false)}
              >
                <RightCollapse />
                <span className="sr-only">좌측 사이드 패널 열기</span>
              </button>
            )}

            {/* show right aside */}
            {hideAsideRight && (
              <button
                type="button"
                className="btn btn-show-aside-right"
                onClick={() => setHideAsideRight(false)}
              >
                <LeftCollapse />
                <span className="sr-only">우측 사이드 패널 열기</span>
              </button>
            )}

            {tab.value == 1 && (
              <div className="pd-20">
                <div className="form-row">
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Address</label>
                    <div className="mg-b-5">
                      <span className="badge badge-dark mg-r-5 tx-normal tx-13">Bill to</span>
                      Bay Area, San Francisco, CA
                    </div>
                    <div>
                      <span className="badge badge-dark mg-r-5 tx-normal tx-13">Ship to</span>
                      Bay Area, San Francisco, CA
                    </div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Terms &amp; Conditions</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Customer Notes</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Description</label>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            )}
            {tab.value == 2 && (
              <div className="pd-15">
                <div className="d-flex flex-wrap align-items-center mg-b-10">
                  <div className="dropdown mg-l-auto">
                    <button type="button" className="btn btn-link" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      Order Detail Template
                      <ChevronDown className="mg-l-5" />
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        One-Time Item
                      </button>
                      <button type="button" className="dropdown-item">
                        Subscription Item
                      </button>
                    </div>
                  </div>
                </div>
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">One-Time Item</span>
                    {!showEditDetail && (
                      <button
                        type="button"
                        className="btn btn-link mg-l-auto"
                        onClick={() => SetShowEditDetail(true)}
                      >
                        <Edit2 className="mg-r-5" />
                        Edit
                      </button>
                    )}
                    {showEditDetail && (
                      <button type="button" className="btn btn-link mg-l-auto">
                        <Plus className="mg-r-5" />
                        Item Table
                      </button>
                    )}
                  </div>
                  <div className="pd-10 bg-white bd rounded">
                    {showEditDetail && (
                      <div className="d-flex flex-wrap align-items-center pd-x-10 pd-y-5 bd bd-b-0 rounded-top sel-temp-item-table">
                        <SelectHook
                          className="wd-200-f"
                          options={[{ value: 1, label: 'Template 1' }]}
                        />
                        <button type="button" className="btn btn-link">
                          Preview
                        </button>
                        <button type="button" className="btn mg-l-auto">
                          <Minus className="mg-r-5 tx-danger" />
                          <span className="tx-danger">Delete</span>
                        </button>
                      </div>
                    )}
                    <div
                      className={classnames(
                        'table-responsive bg-white bd',
                        { 'mg-b-0': showEditDetail },
                        { 'mg-b-10 rounded': !showEditDetail },
                      )}
                    >
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Item&amp;Description
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Price
                            </th>
                            <th scope="col" className="wd-150 bd-t-0-f">
                              Quantity
                            </th>
                            <th scope="col" className="wd-150 bd-t-0-f">
                              Amount
                            </th>
                            {showEditDetail && (
                              <th scope="col" className="wd-70 bd-t-0-f">
                                Delete
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>Box</td>
                            <td>￦100,000</td>
                            <td>
                              {!showEditDetail && '100'}
                              {showEditDetail && (
                                <input
                                  type="number"
                                  className="form-control wd-100"
                                  defaultValue="100"
                                />
                              )}
                            </td>
                            <td>￦10,000,000</td>
                            {showEditDetail && (
                              <td>
                                <button
                                  type="button"
                                  data-han-tooltip="Delete"
                                  className="btn btn-link btn-icon pd-y-0 han-tooltip--top"
                                >
                                  <X className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {showEditDetail && (
                      <div className="mg-b-10 pd-12 bd bd-t-0 rounded-bottom">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type or click to select an item"
                          />
                          <div className="input-group-append">
                            <button type="button" className="btn">
                              {Icon('product')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {showEditDetail && (
                      <div className="d-flex flex-wrap align-items-center pd-x-10 pd-y-5 bd bd-b-0 rounded-top sel-temp-item-table">
                        <SelectHook
                          className="wd-200-f"
                          options={[{ value: 1, label: 'Template 1' }]}
                        />
                        <button type="button" className="btn btn-link">
                          Preview
                        </button>
                        <button type="button" className="btn mg-l-auto">
                          <Minus className="mg-r-5 tx-danger" />
                          <span className="tx-danger">Delete</span>
                        </button>
                      </div>
                    )}
                    <div
                      className={classnames(
                        'table-responsive bg-white bd',
                        { 'mg-b-0': showEditDetail },
                        { 'mg-b-10 rounded': !showEditDetail },
                      )}
                    >
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Item
                            </th>
                            <th scope="col" className="wd-150 bd-t-0-f">
                              Quantity
                            </th>
                            <th scope="col" className="wd-150 bd-t-0-f">
                              Amount
                            </th>
                            {showEditDetail && (
                              <th scope="col" className="wd-70 bd-t-0-f">
                                Delete
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              {!showEditDetail && '100'}
                              {showEditDetail && (
                                <input type="number" className="form-control" defaultValue="100" />
                              )}
                            </td>
                            <td>￦10,000,000</td>
                            {showEditDetail && (
                              <td>
                                <button
                                  type="button"
                                  data-han-tooltip="Delete"
                                  className="btn btn-link btn-icon pd-y-0 han-tooltip--top"
                                >
                                  <X className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {showEditDetail && (
                      <div className="mg-b-10 pd-12 bd bd-t-0 rounded-bottom">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type or click to select an item"
                          />
                          <div className="input-group-append">
                            <button type="button" className="btn">
                              {Icon('product')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {showEditDetail && (
                      <div className="pd-5 bd bd-dashed rounded">
                        <div className="d-flex flex-wrap align-items-center">
                          <SelectHook
                            className="wd-200-f"
                            options={[{ value: 1, label: 'Template 1' }]}
                          />
                          <button type="button" className="btn btn-link">
                            Preview
                          </button>
                        </div>
                        <div className="row mg-x-0 mg-t-10 pd-y-15 pd-x-5 bg-light rounded-5">
                          <div className="col-lg-6 offset-lg-6">
                            <ul className="list-unstyled mg-b-0 lh-10 pd-r-10">
                              <li className="d-flex justify-content-between">
                                <span>Sub-Total</span>
                                <span>₩ 9,090,000</span>
                              </li>
                              <li className="d-flex justify-content-between">
                                <span>Shipping Charges</span>
                                <span>₩ 4,000</span>
                              </li>
                              <li className="d-flex justify-content-between">
                                <span>Tax (5%)</span>
                                <span>₩ 454,500</span>
                              </li>
                              <li className="d-flex justify-content-between">
                                <span>Round Off</span>
                                <span>₩ 0</span>
                              </li>
                              <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                                <strong>Total Amount</strong>
                                <strong>₩ 9,548,500</strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                    {!showEditDetail && (
                      <div className="row mg-0 pd-y-15 pd-x-5 bg-light rounded-5">
                        <div className="col-lg-6 offset-lg-6">
                          <ul className="list-unstyled mg-b-0 lh-10 pd-r-10">
                            <li className="d-flex flex-wrap justify-content-between">
                              <span>Sub-Total</span>
                              <span>₩ 9,090,000</span>
                            </li>
                            <li className="d-flex flex-wrap justify-content-between">
                              <span>Shipping Charges</span>
                              <span>₩ 4,000</span>
                            </li>
                            <li className="d-flex flex-wrap justify-content-between">
                              <span>Tax (5%)</span>
                              <span>₩ 454,500</span>
                            </li>
                            <li className="d-flex flex-wrap justify-content-between">
                              <span>Round Off</span>
                              <span>₩ 0</span>
                            </li>
                            <li className="d-flex flex-wrap justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                              <strong>Total Amount</strong>
                              <strong>₩ 9,548,500</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                  {showEditDetail && (
                    <div className="mg-t-10 text-right">
                      <button
                        type="button"
                        className="btn btn-light mg-r-10"
                        onClick={() => SetShowEditDetail(false)}
                      >
                        <X className="ma-r-5" /> Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-success mg-r-10"
                        onClick={() => SetShowEditDetail(false)}
                      >
                        <Check className="ma-r-5" /> Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => SetShowEditDetail(false)}
                      >
                        <Minus className="ma-r-5" /> Delete
                      </button>
                    </div>
                  )}
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">
                      Subscription Item - Monthly
                    </span>
                    {!showEditDetail2 && (
                      <button
                        type="button"
                        className="btn btn-link mg-l-auto"
                        onClick={() => SetShowEditDetail2(true)}
                      >
                        <Edit2 className="mg-r-5" />
                        Edit
                      </button>
                    )}
                  </div>
                  <div className="pd-10 bg-white bd rounded">
                    {showEditDetail2 && (
                      <div className="d-flex flex-wrap align-items-center mg-b-10">
                        <SelectHook
                          className="wd-200-f"
                          options={[{ value: 1, label: 'Template 1' }]}
                        />
                        <button type="button" className="btn btn-link">
                          Preview
                        </button>
                      </div>
                    )}
                    <div className="table-responsive mg-b-10 bg-white bd rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Item&amp;Description
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Frequency(unit)
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Price per a user
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Users
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Amount
                            </th>
                            {showEditDetail2 && (
                              <th scope="col" className="wd-70 bd-t-0-f">
                                Delete
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>Monthly</td>
                            <td>&nbsp;</td>
                            <td>
                              {!showEditDetail2 && '10'}
                              {showEditDetail2 && (
                                <input type="number" className="form-control" defaultValue="10" />
                              )}
                            </td>
                            <td>&nbsp;</td>
                            {showEditDetail2 && (
                              <td>
                                <button
                                  type="button"
                                  data-han-tooltip="Delete"
                                  className="btn btn-link btn-icon pd-y-0 han-tooltip--top"
                                >
                                  <X className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row mg-0 pd-y-15 pd-x-5 bg-light rounded-5">
                      <div className="col-lg-6 offset-lg-6">
                        <ul className="list-unstyled mg-b-0 lh-10 pd-r-10">
                          <li className="d-flex flex-wrap justify-content-between">
                            <span>Sub-Total</span>
                            <span>₩ 9,090,000</span>
                          </li>
                          <li className="d-flex flex-wrap justify-content-between">
                            <span>Shipping Charges</span>
                            <span>₩ 4,000</span>
                          </li>
                          <li className="d-flex flex-wrap justify-content-between">
                            <span>Tax (5%)</span>
                            <span>₩ 454,500</span>
                          </li>
                          <li className="d-flex flex-wrap justify-content-between">
                            <span>Round Off</span>
                            <span>₩ 0</span>
                          </li>
                          <li className="d-flex flex-wrap justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                            <strong>Total Amount</strong>
                            <strong>₩ 9,548,500</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {showEditDetail2 && (
                    <div className="mg-t-10 text-right">
                      <button
                        type="button"
                        className="btn btn-light mg-r-10"
                        onClick={() => SetShowEditDetail2(false)}
                      >
                        <X className="ma-r-5" /> Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-success mg-r-10"
                        onClick={() => SetShowEditDetail2(false)}
                      >
                        <Check className="ma-r-5" /> Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => SetShowEditDetail2(false)}
                      >
                        <Minus className="ma-r-5" /> Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {tab.value == 3 && <Timeline />}
            {tab.value == 4 && <Notelist />}
            {tab.value == 5 && <SalesCommission />}
          </div>
        </div>

        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner">
            {/* collapse right aside */}
            {!hideAsideRight && (
              <button
                type="button"
                className="btn btn-hide-aside-right"
                onClick={() => setHideAsideRight(true)}
              >
                <RightCollapse />
                <span className="sr-only">우측 사이드 패널 접기</span>
              </button>
            )}

            {/* Contact */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Contact</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#customerContact"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="customerContact" className="view-aside-section-body collapse show">
                <Userlist />
              </div>
            </div>

            {/* Assigned Rep. */}
            <AssignedRep />

            {/* Attachment */}
            <Attachments />
          </div>
        </div>
      </div>
      <CommissionShare />
    </div>
  );
};

export default ViewNew;
