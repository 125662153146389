import { Button, RadioGroup } from '@base/components/form';
import { useState } from 'react';

const ModalSurvey = () => {
  const OPTIONS = [
    { value: 'satisfied', label: 'Satisfied' },
    { value: 'dissatisfied', label: 'Dissatisfied' },
  ];
  const [option, setOption] = useState(OPTIONS);
  const [selectedOption, setSelectedOption] = useState(option[0].value);
  //change
  const handleTermChange = (newOption: any) => {
    setSelectedOption(newOption.value);
  };

  return (
    <div className="modal fade right" id="modalSurvey">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Survey</h1>
            <Button close data-dismiss="modal"></Button>
          </div>
          <div className="modal-body">
            <p>Customer satisfaction survey</p>
            <div className="form-group">
              <label className="form-item-title">
                Are you satisfied with the service that you have received?
              </label>
              <RadioGroup options={option} value={selectedOption} onChange={handleTermChange} />
            </div>
            <div className="form-group">
              <label className="form-item-title">Why you are satisfied/not satisfied?​</label>
              <textarea cols={10} rows={3} className="form-control"></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" data-dismiss="modal" name="Close" />
            <Button color="success" name="Submit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSurvey;
