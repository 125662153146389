import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  ChevronsLeft,
  ArrowLeft,
  Award,
  ArrowUp,
  ArrowDown,
  Plus,
} from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'Insight',
  },
  {
    value: 2,
    label: 'Views',
  },
  {
    value: 3,
    label: 'Link Map',
  },
  {
    value: 4,
    label: 'A/B Test',
  },
  {
    value: 5,
    label: 'Related',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewLadingPage = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);
  const [checked, setChecked] = useState(true);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Online Digital Contents
            <ChevronDown className="mg-l-5" />
          </button>
        </div>

        <h1 className="view-title mg-l-10 lh-1">Landing Page 1</h1>

        <div className="button-group d-flex flex-shrink-0 mg-l-auto">
          <div className="process-buttons mg-r-15">
            <div className="dropdown">
              <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                More
                <ChevronDown className="mg-l-5" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">{Icon('cta')}</span>
                  Generate CTA
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <button type="button" className="btn btn-primary mg-l-5 rounded-30">
            <Plus size="14" />
            New
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner">
            {/* collapse left aside */}
            {!hideAsideLeft && (
              <button
                type="button"
                className="btn btn-hide-aside-left"
                onClick={() => setHideAsideLeft(true)}
              >
                <LeftCollapse />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>
            )}
            <div className="view-aside-section pd-15">
              <div className="d-flex align-items-center justify-content-center">
                <figure className="img-thumbnail">
                  <img src="https://via.placeholder.com/300x300" alt="" className="img-fit-cover" />
                </figure>
              </div>
              <div className="form-group">
                <label className="form-item-title">Stage</label>
                <div>&nbsp;</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Publish Date</label>
                <div>2021/09/01</div>
              </div>
            </div>

            {/* Basic Info */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Summary</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#basicInfoSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="basicInfoSection" className="view-aside-section-body collapse show">
                <div className="form-group">
                  <label className="form-item-title">Landing Page Type</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Language</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">A/B Test</label>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="abtest-active-01"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <label className="custom-control-label" htmlFor="abtest-active-01">
                      Active
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Owner</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>MSR</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Approval</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm">
                      <img
                        src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                        className="rounded-circle"
                        alt="KDH"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>KDH</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Created on</label>
                  <div>2021/09/01</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Last updated</label>
                  <div>2021/09/01</div>
                </div>
              </div>
            </div>

            {/* Options */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Options</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0 collapsed"
                  data-toggle="collapse"
                  data-target="#optionsSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="optionsSection" className="view-aside-section-body collapse">
                <div className="form-group-title mg-t-0-f">Header Meta Tags</div>
                <div className="form-group">
                  <label className="form-item-title">Meta Keyword</label>
                  <div>2021/09/01</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Meta Description</label>
                  <div>2021/09/01</div>
                </div>
                <hr />
                <div className="form-group">
                  <label className="form-item-title">Publish or Schedlue</label>
                  <div>Unpublished</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Implementing Cost</label>
                  <div className="table-responsive bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Cost Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Amount
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th scope="row" className="bd-b-0-f">
                            Total Cost
                          </th>
                          <td colSpan={2}>&nbsp;</td>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('nav-item', {
                      active: item.value == tab.value,
                    })}
                  >
                    <button
                      type="button"
                      className="btn btn-xs btn-move-tab"
                      aria-label="Drag-and-drop to reorder"
                    >
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <button
                      type="button"
                      className="btn nav-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="view-body">
            {/* show left aside */}
            {hideAsideLeft && (
              <button
                type="button"
                className="btn btn-show-aside-left"
                onClick={() => setHideAsideLeft(false)}
              >
                <RightCollapse />
                <span className="sr-only">좌측 사이드 패널 열기</span>
              </button>
            )}
            {/* Insight */}
            {tab.value == 1 && (
              <div className="pd-20">
                <div className="d-flex align-items-center justify-content-end mg-b-10">
                  Date Range{' '}
                  <SelectHook
                    className="wd-200-f mg-l-10"
                    options={[{ value: 1, label: 'This month' }]}
                  />
                </div>
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Traffic by source</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#insightTraffic"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="insightTraffic" className="card card-body collapse show">
                    <div className="row no-gutters">
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Page Metrics</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#insightPageMetrics"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="insightPageMetrics" className="collapse show">
                    <div className="row row-xs">
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <div className="card card-body">
                          <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                            Page Views
                          </div>
                          <span className="h3 tx-normal">500</span>
                          <div className="tx-12 tx-color-03">
                            <span className="tx-success">
                              <ArrowUp size="12px" />
                              10%
                            </span>{' '}
                            than last month
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <div className="card card-body">
                          <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                            Form Submission
                          </div>
                          <span className="h3 tx-normal">10</span>
                          <div className="tx-12 tx-color-03">
                            <span className="tx-success">
                              <ArrowUp size="12px" />
                              10%
                            </span>{' '}
                            than last month
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                          <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                            Embedded CTA Clicks
                          </div>
                          <span className="h3 tx-normal">200</span>
                          <div className="tx-12 tx-color-03">
                            <span className="tx-danger">
                              <ArrowDown size="12px" />
                              10%
                            </span>{' '}
                            than last month
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3 mg-t-10 mg-xl-t-0">
                        <div className="card card-body">
                          <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                            Bounce Rate
                          </div>
                          <span className="h3 tx-normal">20%</span>
                          <div className="tx-12 tx-color-03">
                            <span className="tx-danger">
                              <ArrowDown size="12px" />
                              10%
                            </span>{' '}
                            than last month
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Views */}
            {tab.value == 2 && (
              <div className="pd-20">
                <div className="d-flex flex-wrap align-items-center justify-content-end">
                  <div className="d-flex align-items-center mg-b-10">
                    Source{' '}
                    <SelectHook
                      className="wd-200-f mg-l-10"
                      options={[{ value: 1, label: 'Campaign' }]}
                    />
                  </div>
                  <div className="d-flex align-items-center mg-l-15 mg-b-10">
                    Date Range{' '}
                    <SelectHook
                      className="wd-200-f mg-l-10"
                      options={[{ value: 1, label: 'This month' }]}
                    />
                  </div>
                  <div className="d-flex align-items-center mg-l-15 mg-b-10">
                    Frequency{' '}
                    <SelectHook
                      className="wd-150-f mg-l-10"
                      options={[{ value: 1, label: 'Daily' }]}
                    />
                  </div>
                </div>
                <div className="mg-t-10 mg-b-20">&nbsp;</div>
                <div className="table-responsive bg-white bd rounded">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Source
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Views
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Submission
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          CTA Clicks
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Bounce rate
                        </th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th scope="row" className="bd-b-0-f">
                          All
                        </th>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td>Campaign 1</td>
                        <td>300</td>
                        <td>&nbsp;</td>
                        <td>100</td>
                        <td>10%</td>
                      </tr>
                      <tr>
                        <td>Campaign 2</td>
                        <td>200</td>
                        <td>50</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* A/B Test */}
            {tab.value == 4 && (
              <div className="pd-20">
                {checked && (
                  <>
                    <div className="form-group">
                      <label className="form-item-title">Title</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="view-content-section">
                      <div className="view-content-section-header">
                        <button type="button" className="view-aside-section-move mg-r-5">
                          {Icon('move')}
                          <span className="sr-only">드래그앤드롭으로 이동</span>
                        </button>
                        <span className="view-aside-section-header-title">Settings</span>
                        <button
                          type="button"
                          className="btn btn-link link-03 collapsed mg-l-auto"
                          data-toggle="collapse"
                          aria-label="collapse"
                          data-target="#abTestSettings"
                        >
                          <ChevronDown />
                          <span className="sr-only">내용 접기</span>
                        </button>
                      </div>
                      <div id="abTestSettings" className="collapse">
                        <div className="d-flex">
                          <ul className="nav nav-tabs flex-grow-1">
                            <li className="nav-item">
                              <button
                                type="button"
                                className="nav-link active"
                                data-toggle="tab"
                                data-target="#a"
                              >
                                A
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                type="button"
                                className="nav-link"
                                data-toggle="tab"
                                data-target="#b"
                              >
                                B
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content bg-white bd bd-gray-300 bd-t-0 mg-b-20 pd-20">
                          <div id="a" className="tab-pane fade show active">
                            &nbsp;
                          </div>
                          <div id="b" className="tab-pane fade">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="view-content-section">
                      <div className="view-content-section-header">
                        <button type="button" className="view-aside-section-move mg-r-5">
                          {Icon('move')}
                          <span className="sr-only">드래그앤드롭으로 이동</span>
                        </button>
                        <span className="view-aside-section-header-title">Result</span>
                        <button
                          type="button"
                          className="btn btn-link link-03 mg-l-auto"
                          data-toggle="collapse"
                          aria-label="collapse"
                          data-target="#abTestResult"
                        >
                          <ChevronDown />
                          <span className="sr-only">내용 접기</span>
                        </button>
                      </div>
                      <div id="abTestResult" className="card collapse show">
                        <div className="table-responsive rounded">
                          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                            <thead>
                              <tr>
                                <th scope="col" className="bd-t-0-f">
                                  Campaign
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Channel
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  A
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  B
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="tx-center">
                                  <div style={{ width: '300px' }}>
                                    {/* <HorizontalBar
                                      width={300}
                                      height={150}
                                      data={data3}
                                      options={{
                                        maintainAspectRatio: false,
                                        legend: false,
                                        scales: {
                                          xAxes: [
                                            { ticks: { beginAtZero: true, min: 0, max: 100 } },
                                          ],
                                        },
                                      }}
                                    /> */}
                                  </div>
                                  <div className="d-inline-block mg-t-10 pd-x-10 pd-y-3 bg-orange rounded-30 tx-white">
                                    <Award size="16" className="mg-r-5" />
                                    Winner
                                  </div>
                                </td>
                                <td>
                                  <div style={{ width: '300px' }}>
                                    {/* <HorizontalBar
                                      width={300}
                                      height={150}
                                      data={data4}
                                      options={{
                                        maintainAspectRatio: false,
                                        legend: false,
                                        scales: {
                                          xAxes: [
                                            { ticks: { beginAtZero: true, min: 0, max: 100 } },
                                          ],
                                        },
                                      }}
                                    /> */}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {/* Related */}
            {tab.value == 5 && (
              <div className="pd-20">
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">
                      Click to Action linked with this Landing Page
                    </span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#ctaRelated1"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="ctaRelated1" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              CTA Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              CTA Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Link Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Created on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Stage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Form embedded</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#ctaRelated2"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="ctaRelated2" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Form Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Form Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Content Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Created on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Stage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">
                      Site linked with this Landing Page
                    </span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#ctaRelated3"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="ctaRelated3" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Site Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Site Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Link Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Created on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Stage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Campaign</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#ctaRelated3"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="ctaRelated3" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Campaign Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Activity
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Owner
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty posted
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Start date
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              End date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLadingPage;
