import { Button, Input, RadioGroup, Select, Switch } from '@base/components/form';
import { useState } from 'react';
import { ChevronUp, X } from 'react-feather';

const NewVendor = () => {
  const VENDOR_TYPES = [
    {
      label: 'Contact',
      value: 'contact',
    },
    {
      label: 'Account',
      value: 'account',
    },
  ];

  const [options, setOptions] = useState(VENDOR_TYPES);
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
  };

  return (
    <div
      id="newVendor"
      className="modal fade right over-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">New Vendor</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="text-right">
              <span className="tx-danger">*</span> is required field.
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Vendor Type <span className="tx-danger">*</span>
              </label>
              <RadioGroup value={selectedValue} options={options} onChange={handleValueChange} />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Vendor Name <span className="tx-danger">*</span>
              </label>
              <Input />
            </div>
            <div className="form-group">
              <label className="form-item-title">Preferred</label>
              <Switch />
            </div>
            <div className="form-group">
              <label className="form-item-title">Veondor Product Name</label>
              <Input />
            </div>
            <div className="form-group">
              <label className="form-item-title">Linked Barcode</label>
              <Input />
            </div>
            <div className="form-group">
              <label className="form-item-title">Delivery Lead Time</label>
              <div className="d-flex align-items-center">
                <Input />
                <Select
                  className="mg-l-5 wd-200-f"
                  defaultValue={[{ value: 'dd', label: 'days' }]}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Purchase Unit <span className="tx-danger">*</span>
              </label>
              <Select className="wd-200-f" />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Purchase Price <span className="tx-danger">*</span>
              </label>
              <Input type="currency" currencySymbol="￦" />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Minimum Quantity <span className="tx-danger">*</span>
              </label>
              <Input />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Payment Term <span className="tx-danger">*</span>
              </label>
              <Select />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Tax <span className="tx-danger">*</span>
              </label>
              <div className="wd-200">
                <Input rightIcon="%" />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Cancel
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewVendor;
