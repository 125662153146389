import { useState } from 'react';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';
import { ChevronDown, ChevronUp, Clock, Minus, Plus, User, X } from 'react-feather';
import SelectHook from '@base/components/form/select-hook';

const MeetingWrite = () => {
  const [input, setInput] = useState('');
  const [addNew, setAddNew] = useState(false);

  return (
    <>
      <div
        id="newMeetingModal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">New Meeting</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-item-title">Subject</label>
                <input type="text" className="form-control form-control-lg" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Attendees</label>
                <div className="dropdown">
                  <div className="input-group" data-toggle="dropdown">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">John</span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">담당자 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                  <div className="dropdown-menu wd-100p">
                    <button type="button" className="dropdown-item">
                      Assign to me
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Priority</label>
                <SelectHook className="wd-300-f" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Customer</label>
                <div className="input-group">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="d-block avatar avatar-xs mg-r-10">
                        <span className="avatar-initial rounded-circle">S</span>
                      </span>
                      <span className="form-control-tag-name">Socrates</span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250"
                      placeholder="Type or click to select a customer"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a customer"
                    >
                      {Icons('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Location</label>
                <div>map</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Marketing Collateral</label>
                <div className="wd-50p">
                  <SelectHook />
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Purpose</label>
                <div className="wd-50p">
                  <SelectHook />
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Expected Duration</label>
                <div className="d-flex align-items-center">
                  <input type="number" className="form-control wd-150 mg-r-5" />
                  <div className="input-group dropdown wd-150">
                    <input type="text" className="form-control" defaultValue="hour(s)" />
                    <div className="input-group-append">
                      <button type="button" className="btn">
                        <ChevronDown />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Meeting Date</label>
                <div className="row row-xs">
                  <div className="col-6">
                    <label>Start</label>
                    <div className="input-group input-date-group dropdown">
                      <input type="text" className="form-control" />
                      <div className="input-group-append">
                        <span className="input-group-text bg-white tx-gray-300">
                          <Clock />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <label>End</label>
                    <div className="input-group input-date-group dropdown">
                      <input type="text" className="form-control" />
                      <div className="input-group-append">
                        <span className="input-group-text bg-white tx-gray-300">
                          <Clock />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Template</label>
                <SelectHook />
              </div>
              <div className="form-group">
                <label className="form-item-title">Agenda</label>
                <div className="table-responsive bd rounded-top">
                  <table className="table mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Time
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Speaker
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Title
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="input-group input-date-group dropdown">
                            <input type="text" className="form-control" />
                            <div className="input-group-append">
                              <span className="input-group-text bg-white tx-gray-300">
                                <Clock />
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-group">
                            <input type="text" className="form-control" />
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn"
                                aria-label="open selecting user modal"
                              >
                                <User />
                                <span className="sr-only">발표자 선택창 열기</span>
                              </button>
                            </div>
                          </div>
                        </td>
                        <td>
                          <input type="text" className="form-control" />
                        </td>
                        <td>
                          <button type="button" className="btn btn-icon mg-l-auto">
                            <X className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </td>
                      </tr>
                      {addNew && (
                        <tr>
                          <td>
                            <div className="input-group input-date-group dropdown">
                              <input type="text" className="form-control" />
                              <div className="input-group-append">
                                <span className="input-group-text bg-white tx-gray-300">
                                  <Clock />
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <input type="text" className="form-control" />
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn"
                                  aria-label="open selecting user modal"
                                >
                                  <User />
                                  <span className="sr-only">발표자 선택창 열기</span>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <input type="text" className="form-control" defaultValue={input} />
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-icon mg-l-auto"
                              onClick={() => setAddNew(false)}
                            >
                              <X className="tx-danger" />
                              <span className="sr-only">삭제</span>
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                  <input
                    type="text"
                    className="form-control mg-r-10"
                    placeholder="Enter the agenda"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary flex-shrink-0"
                    onClick={() => setAddNew(true)}
                  >
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Cancel
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingWrite;
