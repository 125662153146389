import { Button, Input, Select } from '@base/components/form';
import { ChevronUp, Settings, X } from 'react-feather';
import noImg from '@base/assets/img/notfound.png';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

const NewPR = () => {
  return (
    <div id="newPR" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Purchase Request</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body">
            <div className="text-right">
              <span className="tx-danger">*</span> is required field.
            </div>
            <hr />
            <div className="form-row">
              <div className="col-lg-6 form-group">
                <label className="form-item-title">
                  PR ID
                  <span className="tx-danger">*</span>
                </label>
                <Input rightIcon={<Settings />} />
              </div>
              <div className="col-lg-6 form-group">
                <label className="form-item-title">
                  Reason
                  <span className="tx-danger">*</span>
                </label>
                <Select />
              </div>
              <div className="col-lg-12 form-group">
                <label className="form-item-title">Description</label>
                <Input />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Product Details</label>
              <div className="table-responsive bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Image
                      </th>
                      <th scope="col" className="sorting bd-t-0-f">
                        Item
                      </th>
                      <th scope="col" className="sorting bd-t-0-f">
                        Vendor
                      </th>
                      <th scope="col" className="sorting bd-t-0-f">
                        Quantity
                      </th>
                      <th scope="col" className="sorting bd-t-0-f">
                        Estimated Amount
                      </th>
                      <th scope="col" className="sorting bd-t-0-f">
                        Required Date
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="avatar avatar-sm bg-gray-200 rounded">
                          <img src={noImg} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="wd-200">
                          <Input rightIcon={Icon('product')} value="Item 1" />
                        </div>
                      </td>
                      <td>Vendor 1</td>
                      <td>
                        <Input />
                      </td>
                      <td>
                        <Input type="currency" currencySymbol="$" />
                      </td>
                      <td>
                        <div className="wd-150">
                          <DatePicker
                            selected={new Date()}
                            onChange={() => {
                              console.log();
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pd-10 bd bd-t-0 rounded-bottom">
                <Input rightIcon={Icon('product')} placeholder="Type or click to select an item" />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Cancel
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPR;
