import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  CheckCircle,
  Circle,
  ArrowLeft,
  AlertTriangle,
  X,
  Clock,
  BookOpen,
  Mail,
  Upload,
} from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { COLORS } from '@base/config/constant';

// import "CrmCss/desktop/theme1/theme1.scss"

import AssignedRep from '../../../../component/desktop/assigned-rep';
import Notelist from '../../../../component/desktop/notelist';
// import Activity from '../../../../component/desktop/activity';
import Timeline from '../timeline';
import MemberList from '../memberlist';
import Test from './modal/test';
import NewCampaign from './modal/newCampaign';
import NewAgenda from './modal/newAgenda';
import NewSpeaker from './modal/newSpeaker';
import NewSponsor from './modal/newSponsor';
import NewTicket from './modal/newTicket';
import AttendeeDetail from './modal/attendeeDetail';
import EventAgenda from './view/event/agenda';
import EventConfiguration from './view/event/configuration';
import EventSite from './view/event/eventSite';
import EventTicket from './view/event/ticket';
import EventCampaignActivity from './view/event/campaignActivity';

import '../marketing.scss';

const TABS = [
  {
    value: 1,
    label: 'Agenda',
  },
  {
    value: 2,
    label: 'Configuration',
  },
  {
    value: 3,
    label: 'Event Site',
  },
  {
    value: 4,
    label: 'Ticket',
  },
  {
    value: 5,
    label: 'Attendees',
  },
  {
    value: 6,
    label: 'Campaign Activity',
  },
  {
    value: 7,
    label: 'Survey',
  },
  {
    value: 8,
    label: 'Timeline',
  },
  {
    value: 9,
    label: 'Activity',
  },
  {
    value: 10,
    label: 'Note',
  },
];

const BeforeLaunch = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a href="/campaign/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Campaign
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">Campaign 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newCampaignModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b">
          <div className="d-flex pd-20 bg-light rounded">
            <span className="tx-semibold">Business Process</span>
            <ol className="list-unstyled process-wrap text-nowrap">
              <li className="process-item current">
                <button type="button" className="btn process-item-btn">
                  <CheckCircle />
                </button>
                <div className="process-item-text mg-t-10">Planning</div>
                <div className="mg-t-5">
                  <span className="tx-color-03 mg-r-5">Status</span>
                  <span>Draft</span>
                </div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Organizing</div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Approval</div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Campaign Activity to promote</div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Launch</div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Post Event</div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Closed</div>
              </li>
            </ol>
          </div>
          <div className="mg-t-10 pd-20 bg-light rounded">
            <div className="d-flex">
              <span className="tx-semibold mg-r-10">Journey Process</span>
              Campaign Activity to Promote
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto pd-0 collapsed"
                data-toggle="collapse"
                data-target="#journeyProcess"
              >
                <ChevronDown />
              </button>
            </div>
            <div id="journeyProcess" className="collapse">
              &nbsp;
            </div>
          </div>
        </div>

        <div className="row no-gutters flex-nowrap flex-grow-1 overflow-auto">
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Registration</label>
                    <div>Free Ticket</div>
                    <div>Auto Confirm Registration</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Sponsor</label>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="sponsorSwitch"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="sponsorSwitch"></label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Approval</label>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="approvalSwitch"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="approvalSwitch"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="pd-20 bg-white bd-b text-center">
              <p>
                <AlertTriangle size={16} className="mg-r-5 tx-danger" />
                The configuration is not completed.
              </p>
              <button type="button" className="btn btn-lg btn-primary">
                Continue Configuration
              </button>
            </div>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}
              {/* Agenda */}
              {tab.value == 1 && (
                <div className="pd-20">
                  <EventAgenda />
                </div>
              )}

              {/* Configuration */}
              {tab.value == 2 && (
                <div className="pd-20">
                  <EventConfiguration />
                </div>
              )}

              {/* Event Site */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <EventSite />
                </div>
              )}

              {/* Ticket */}
              {tab.value == 4 && (
                <div className="pd-20">
                  <EventTicket />
                </div>
              )}

              {/* Attendees */}
              {tab.value == 5 && (
                <div className="pd-20">
                  <div className="mg-b-10 tx-right">
                    <button type="button" className="btn btn-link">
                      <Upload className="mg-r-5" />
                      Import
                    </button>
                  </div>
                  <div className="table-responsive bg-white bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Registered on
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Contact
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Company
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Email
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Phone
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Status
                          </th>
                          <th scope="col" className="bd-t-0-f wd-70">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>
                            <div className="media align-items-center">
                              <span className="avatar avatar-xs">
                                <img
                                  src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                                  className="rounded-circle"
                                />
                              </span>
                              <div className="media-body mg-l-10">
                                <a href="#attendeeDetail" data-toggle="modal">
                                  Allan Rey Palban
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media align-items-center">
                              <div className="avatar avatar-xs">
                                <img
                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                                  className="rounded-circle"
                                />
                              </div>
                              <div className="media-body mg-l-5 text-truncate">Adventure Works</div>
                            </div>
                          </td>
                          <td>
                            <a href="mailto:sales@domain.com">sales@domain.com</a>
                          </td>
                          <td>
                            <a href="tel:1 518-445-5696">+1 518-445-5696</a>
                          </td>
                          <td>
                            <SelectHook
                              className="wd-150-f"
                              options={[
                                { value: 1, label: 'Confirm' },
                                { value: 2, label: 'Attended' },
                                { value: 3, label: 'Cancel' },
                              ]}
                            />
                          </td>
                          <td align="center">
                            <button type="button" className="btn btn-icon pd-0">
                              <X className="tx-danger" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="bg-white bd bd-t-0 rounded-bottom pd-10">
                    <div className="input-group" data-toggle="dropdown">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to select a user"
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn">
                          <ChevronDown />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Campaign Activity */}
              {tab.value == 6 && (
                <div className="pd-20">
                  <EventCampaignActivity />
                </div>
              )}

              {tab.value == 8 && <Timeline />}

              {/* {tab.value == 9 && <Activity />} */}

              {tab.value == 10 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}
              {/* Members */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Members</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#members"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="members" className="view-aside-section-body collapse show">
                  <MemberList />
                </div>
              </div>

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Speaker */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Speaker</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#speaker"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="speaker" className="view-aside-section-body collapse show">
                  <div className="media mg-b-3 pd-10 bg-light rounded">
                    <div className="avatar avatar-xs">
                      <img
                        src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                        alt="Allan Rey Palban"
                        className="rounded-circle"
                      />
                    </div>
                    <div className="media-body mg-l-10" style={{ maxWidth: 'calc(100% - 36px)' }}>
                      <div className="d-flex align-items-center">
                        <span className="text-truncate">Allan Rey Palban</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto han-tooltip--top"
                          data-han-tooltip="Send Email"
                        >
                          <Mail className="tx-teal" />
                          <span className="sr-only">이메일 보내기</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link btn-icon tx-orange op-8 han-tooltip--top"
                          data-han-tooltip="Send SMS"
                        >
                          {Icon('sms')}
                          <span className="sr-only">SMS 보내기</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link btn-icon han-tooltip--top"
                          data-han-tooltip="Delete"
                        >
                          <X className="tx-danger" />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <div className="d-flex align-items-center tx-color-03">
                        <Clock size={14} className="mg-r-5" />
                        10 SEP FRI 09:00 ~ 10:30
                      </div>
                      <div className="d-flex align-items-center flex-wrap">
                        <strong className="flex-grow-1 mg-r-10">
                          <BookOpen size={14} className="mg-r-5 tx-primary" />
                          Title 2
                        </strong>
                        ＄1,000
                      </div>
                    </div>
                  </div>
                  <div className="media mg-b-3 pd-10 bg-light rounded">
                    <div className="avatar avatar-xs">
                      <img
                        src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                        alt="Situmay"
                        className="rounded-circle"
                      />
                    </div>
                    <div className="media-body mg-l-10" style={{ maxWidth: 'calc(100% - 36px)' }}>
                      <div className="d-flex align-items-center">
                        <span className="text-truncate">Situmay</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto han-tooltip--top"
                          data-han-tooltip="Send Email"
                        >
                          <Mail className="tx-teal" />
                          <span className="sr-only">이메일 보내기</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link btn-icon tx-orange op-8 han-tooltip--top"
                          data-han-tooltip="Send SMS"
                        >
                          {Icon('sms')}
                          <span className="sr-only">SMS 보내기</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link btn-icon han-tooltip--top"
                          data-han-tooltip="Delete"
                        >
                          <X className="tx-danger" />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <div className="d-flex align-items-center tx-color-03">
                        <Clock size={14} className="mg-r-5" />
                        10 SEP FRI 10:30 ~ 12:00
                      </div>
                      <div className="d-flex align-items-center flex-wrap">
                        <strong className="flex-grow-1 mg-r-10">
                          <BookOpen size={14} className="mg-r-5 tx-primary" />
                          Title 3
                        </strong>
                        ＄1,000
                      </div>
                    </div>
                  </div>
                  <div className="dropdown">
                    <div className="input-group" data-toggle="dropdown">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to add a speaker"
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn">
                          {Icon('m_customer')}
                        </button>
                      </div>
                    </div>
                    <div className="dropdown-menu wd-100p">
                      <button
                        type="button"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#newSpeaker"
                      >
                        <span className="dropdown-item-icon">
                          <Plus />
                        </span>
                        Add new
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Sponsor */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Sponsor</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#sponsor"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="sponsor" className="view-aside-section-body collapse show">
                  <div className="mg-b-3 pd-10 bg-light rounded">
                    <div className="d-flex">
                      <span className="flex-grow-1 mg-r-10 tx-13 tx-color-03">Topic A</span>
                      <button
                        type="button"
                        className="btn btn-link btn-icon pd-y-0 han-tooltip--top"
                        data-han-tooltip="Delete"
                      >
                        <X className="tx-danger" />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <div className="d-flex flex-wrap mg-y-5">
                      <div className="flex-grow-1 mg-r-10">
                        <span className="badge bg-teal mg-r-5 tx-normal tx-white tx-13">
                          Monetary
                        </span>
                        Sponsor 111
                      </div>
                      ＄1,000
                    </div>
                  </div>
                  <div className="mg-b-3 pd-10 bg-light rounded">
                    <div className="d-flex">
                      <span className="flex-grow-1 mg-r-10 tx-13 tx-color-03">Topic B</span>
                      <button
                        type="button"
                        className="btn btn-link btn-icon pd-y-0 han-tooltip--top"
                        data-han-tooltip="Delete"
                      >
                        <X className="tx-danger" />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <div className="d-flex flex-wrap mg-y-5">
                      <div className="flex-grow-1 mg-r-10">
                        <span className="badge bg-indigo mg-r-5 tx-normal tx-white tx-13">
                          Service
                        </span>
                        Sponsor 222
                      </div>
                      ＄1,000
                    </div>
                  </div>
                  <div className="mg-b-3 pd-10 bg-light rounded">
                    <div className="d-flex">
                      <span className="flex-grow-1 mg-r-10 tx-13 tx-color-03">Topic C</span>
                      <button
                        type="button"
                        className="btn btn-link btn-icon pd-y-0 han-tooltip--top"
                        data-han-tooltip="Delete"
                      >
                        <X className="tx-danger" />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <div className="d-flex flex-wrap mg-y-5">
                      <div className="flex-grow-1 mg-r-10">
                        <span className="badge bg-pink mg-r-5 tx-normal tx-white tx-13">
                          Equipment
                        </span>
                        Sponsor 333
                      </div>
                      ＄1,000
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    data-toggle="modal"
                    data-target="#newSponsor"
                  >
                    <Plus className="mg-r-5" />
                    Add new
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Test />
      <NewCampaign />
      <NewAgenda />
      <NewSpeaker />
      <NewSponsor />
      <NewTicket />
      <AttendeeDetail />
    </>
  );
};

export default BeforeLaunch;
