const LeadScoring = () => {
  return (
    <div className="pd-20">
      <div className="table-responsive bg-white bd rounded">
        <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="sorting_desc bd-t-0-f">
                Created on
              </th>
              <th scope="col" className="sorting bd-t-0-f">
                Source
              </th>
              <th scope="col" className="sorting bd-t-0-f">
                Activity
              </th>
              <th scope="col" className="sorting bd-t-0-f">
                Behavior
              </th>
              <th scope="col" className="sorting bd-t-0-f">
                Field
              </th>
              <th scope="col" className="sorting bd-t-0-f">
                Value
              </th>
              <th scope="col" className="sorting bd-t-0-f">
                Score
              </th>
              <th scope="col" className="sorting bd-t-0-f">
                Total Score
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="sorting-cell">2022-05-17</td>
              <td>Activity</td>
              <td>Email</td>
              <td>Open</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default LeadScoring;
