import { Button } from '@base/components/form';
import CollectTargetManual from '../collectTargetManual';
import CollectTargetBulk from '../collectTargetBulk';
import CollectTargetCustomer from '../collectTargetCustomer';
import CollectTargetLeadCapture from '../collectTargetLeadCapture';

const CollectTargetAccounts = () => {
  return (
    <>
      <div
        id="collectTargetAccounts"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Collect Target Accounts</h1>
              <Button close data-dismiss="modal" />
            </div>
            {/* <CollectTargetManual /> */}
            {/* <CollectTargetBulk /> */}
            {/* <CollectTargetCustomer /> */}
            <CollectTargetLeadCapture />
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectTargetAccounts;
