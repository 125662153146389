import { useState } from 'react';
import { ChevronDown, MapPin, Phone } from 'react-feather';
import { Button, Input, RadioGroup } from '@base/components/form';

const Delivery = (props: any) => {
  const { direction = 'to' } = props;

  const DELEVERY_TYPES = [
    {
      label: 'Company',
      value: 'company',
    },
    {
      label: 'Warehouse',
      value: 'warehouse',
    },
  ];

  const [options, setOptions] = useState(DELEVERY_TYPES);
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
  };

  const [selectedAddr, setSelectedAddr] = useState<string>();

  return (
    <div className="col-lg-12 form-group">
      <label className="form-item-title">Delevery {direction === 'to' ? 'to' : 'from'}</label>
      <div className="row row-xs">
        <div className="col-lg-6">
          <RadioGroup value={selectedValue} options={options} onChange={handleValueChange} />
        </div>
        <div className="col-lg-6">
          <div className="dropdown">
            <div className="input-group" data-toggle="dropdown">
              <Input value={selectedAddr} placeholder="Select an address" />
              <div className="input-group-append">
                <span className="input-group-text">
                  <ChevronDown />
                </span>
              </div>
            </div>
            <div className="dropdown-menu wd-100p">
              <Button
                color=""
                className="dropdown-item"
                name="Company 1"
                onClick={() => setSelectedAddr('Company 1')}
              />
            </div>
          </div>
        </div>
        {selectedAddr && (
          <>
            <div className="col-lg-6 mg-t-10">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text tx-color-03">
                    <MapPin />
                  </span>
                </div>
                <Input value="Street 52, Seoul,16812, South Korea" />
              </div>
            </div>
            <div className="col-lg-6 mg-t-10">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text tx-color-03">
                    <Phone />
                  </span>
                </div>
                <Input value="0701112232" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Delivery;
