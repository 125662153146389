import { ChevronDown, ThumbsUp } from 'react-feather';
import noImg from '@base/assets/img/notfound.png';
import ProductDetailView from '../containers/product-detail/view';
import { Button } from '@base/components/form';

const RFT = () => {
  return (
    <div className="pd-20">
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button
            color=""
            className="view-aside-section-move mg-r-5"
            icon="move"
            iconType="custom"
            data-han-tooltip="move"
          />
          <span className="view-aside-section-header-title">To Vendor</span>
          <Button color="link" className="mg-l-auto" icon="Edit2" iconClass="mg-r-5" name="Edit" />
          <Button
            color="link"
            className="mg-l-5"
            icon="preview"
            iconType="custom"
            iconClass="mg-r-5"
            name="Preview"
          />
        </div>
        <ProductDetailView />
        <div className="form-group">
          <label className="form-item-title">Terms &amp; Conditions</label>
          <div>
            Please use the following communication for your payment : PB/2022/05/09 Payment terms: 7
            Days
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Vendor Note</label>
          <div>Please use the following communication for your payment : PB/2022/05/09</div>
        </div>
      </div>
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button
            color=""
            className="view-aside-section-move mg-r-5"
            icon="move"
            iconType="custom"
            data-han-tooltip="move"
          />
          <span className="view-aside-section-header-title">
            Response <strong className="tx-success">3</strong>/3
          </span>
        </div>
        <div className="bg-white bd rounded">
          <ul className="list-unstyled vender-pd-list">
            {/* Rejected */}
            <li className="rejected">
              <div className="d-flex flex-wrap">
                Vendor 1
                <div className="flex-grow-1 mg-l-10">
                  <div className="d-flex align-items-center">
                    <span className="mg-r-10 tx-color-03">Contact:</span>Marianne Audrey
                  </div>
                </div>
                <span className="label">Rejected</span>
              </div>
            </li>
            {/* Cheapest */}
            <li className="cheapest-vendor">
              <span className="label">Cheapest</span>
              <div className="d-flex flex-wrap align-items-center">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="vendorRadio"
                    id="vendorRadio2"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="vendorRadio2">
                    Vendor 2
                  </label>
                </div>
                <div className="flex-grow-1 mg-l-10">
                  <div className="d-flex align-items-center">
                    <span className="mg-r-10 tx-color-03">Contact:</span>Owen Bongcaras
                    <a href="" target="_blank" className="mg-l-10">
                      RFQ-00001
                    </a>
                    <div className="mg-l-auto">
                      <strong>Total: ￦1,078,000</strong>
                      <button
                        type="button"
                        className="btn btn-link link-03 pd-y-0 collapsed"
                        data-toggle="collapse"
                        data-target="#vendor2PD"
                      >
                        <ChevronDown />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="vendor2PD" className="collapse fade mg-20">
                <div className="mg-b-10 text-right">
                  <Button
                    color="link"
                    className="mg-l-5"
                    icon="preview"
                    iconType="custom"
                    iconClass="mg-r-5"
                    name="Preview"
                  />
                </div>
                <div className="table-responsive bd rounded">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Item</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="avatar avatar-sm bg-gray-200 rounded">
                            <img src={noImg} alt="" />
                          </div>
                        </td>
                        <td>AAA</td>
                        <td>100</td>
                        <td>￦9,800</td>
                        <td>￦980,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row row-xs mg-y-10">
                  <div className="col-md-6">
                    <ul className="list-unstyled lh-7 mg-b-0">
                      <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                        <span>Items : 100</span>
                        <span> ￦980,000</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                        <span>Discount</span>
                        <span> ￦0</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
                      <li className="d-flex justify-content-between">
                        <span>Sub-Total</span>
                        <span>￦980,000</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <span>Tax</span>
                        <span>￦98,000</span>
                      </li>
                      <li className="d-flex align-items-center">
                        <span>Adjustment</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <span>Round Off</span>
                        <span>&nbsp;</span>
                      </li>
                      <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                        <strong>Total</strong>
                        <strong>￦1,078,000</strong>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-group mg-t-10">
                  <label className="form-item-title">Selection criteria</label>
                  <div></div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Terms &amp; Conditions</label>
                  <div>
                    Please use the following communication for your payment : PB/2022/05/09 Payment
                    terms: 7 Days
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Vendor Note</label>
                  <div>Please use the following communication for your payment : PB/2022/05/09</div>
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex flex-wrap align-items-center">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="vendorRadio"
                    id="vendorRadio3"
                  />
                  <label className="custom-control-label" htmlFor="vendorRadio3">
                    <span
                      data-han-tooltip="Preferred"
                      className="preferred-vendor han-tooltip mg-r-5"
                    >
                      <ThumbsUp size="16" />
                      <span className="sr-only">선호 업체</span>
                    </span>
                    Vendor 3
                  </label>
                </div>
                <div className="d-flex align-items-center flex-grow-1 mg-l-10">
                  <span className="mg-r-10 tx-color-03">Contact:</span>Kirby Avendula
                  <a href="" target="_blank" className="mg-l-10">
                    RFQ-00002
                  </a>
                  <div className="mg-l-auto">
                    <strong>Total: 1,155,000</strong>
                    <button
                      type="button"
                      className="btn btn-link link-03 pd-y-0 collapsed"
                      data-toggle="collapse"
                      data-target="#vendor3PD"
                    >
                      <ChevronDown />
                    </button>
                  </div>
                </div>
              </div>
              <div id="vendor3PD" className="collapse fade mg-20">
                <div className="mg-b-10 text-right">
                  <Button
                    color="link"
                    className="mg-l-5"
                    icon="preview"
                    iconType="custom"
                    iconClass="mg-r-5"
                    name="Preview"
                  />
                </div>
                <div className="table-responsive bd rounded">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Item</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="avatar avatar-sm bg-gray-200 rounded">
                            <img src={noImg} alt="" />
                          </div>
                        </td>
                        <td>AAA</td>
                        <td>100</td>
                        <td>￦10,500</td>
                        <td>￦1,050,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row row-xs mg-y-10">
                  <div className="col-md-6">
                    <ul className="list-unstyled lh-7 mg-b-0">
                      <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                        <span>Items : 100</span>
                        <span> ￦1,050,000</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                        <span>Discount</span>
                        <span> ￦0</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
                      <li className="d-flex justify-content-between">
                        <span>Sub-Total</span>
                        <span>￦1,050,000</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <span>Tax</span>
                        <span>￦105,000</span>
                      </li>
                      <li className="d-flex align-items-center">
                        <span>Adjustment</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <span>Round Off</span>
                        <span>&nbsp;</span>
                      </li>
                      <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                        <strong>Total</strong>
                        <strong>￦1,155,000</strong>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="form-group mg-t-10">
                  <label className="form-item-title">Selection criteria</label>
                  <div></div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Terms &amp; Conditions</label>
                  <div>
                    Please use the following communication for your payment : PB/2022/05/09 Payment
                    terms: 7 Days
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Vendor Note</label>
                  <div>Please use the following communication for your payment : PB/2022/05/09</div>
                </div>
              </div>
            </li>
            {/* buttons */}
            <li className="btns">
              <Button color="primary" name="Accept" className="mg-r-5" />
              <Button color="info" name="Convert to PO" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RFT;
