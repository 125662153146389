/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classNames from 'classnames';
import { Copy, Plus, Settings, X } from 'react-feather';
import { Input, Select as SelectHook, Switch } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';

const WriteItemStep2 = () => {
  const [showMoreInfo, setShowMoreInfo] = useState<any>(false);

  return (
    <div className="table-responsive">
      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
        <thead>
          <tr>
            <th scope="col" className="bd-t-0-f">
              Item Name
            </th>
            <th scope="col" className="bd-t-0-f">
              Item ID
            </th>
            <th scope="col" className="bd-t-0-f">
              Unit Name
            </th>
            <th scope="col" className="bd-t-0-f">
              Unit Quantity
            </th>
            <th scope="col" className="bd-t-0-f">
              Base Price
            </th>
            <th scope="col" className="bd-t-0-f">
              Best Cost
            </th>
            <th scope="col" className="bd-t-0-f">
              Best Price
            </th>
            <th scope="col" className="bd-t-0-f">
              Active
            </th>
            <th scope="col" className="bd-t-0-f">
              Subscription Template
            </th>
            <th scope="col" className="bd-t-0-f">
              More Info
            </th>
            <th scope="col" className="bd-t-0-f">
              Delete
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Juice-Bottle/Mint</td>
            <td>
              <div className="input-group wd-200">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn" aria-label="open code generation settings">
                    <Settings />
                  </button>
                </div>
              </div>
            </td>
            <td>Bottle</td>
            <td>1</td>
            <td>
              <div className="input-group wd-200">
                <div className="input-group-prepend">
                  <span className="input-group-text">＄</span>
                </div>
                <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
              </div>
              <button type="button" className="btn btn-link text-nowrap">
                <Plus className="mg-r-5" />
                Add price
              </button>
              <button type="button" className="btn btn-link text-nowrap">
                <Copy className="mg-r-5" />
                Copy All
              </button>
            </td>
            <td>
              <div className="d-flex">
                <div className="input-group wd-200 mg-r-5">
                  <div className="input-group-prepend">
                    <span className="input-group-text">＄</span>
                  </div>
                  <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
                </div>
                <button
                  type="button"
                  className="btn btn-white text-nowrap"
                  data-toggle="modal"
                  data-target="#productDetailCostModal"
                >
                  <Plus className="mg-r-5" />
                  <span className="sr-only">추가</span> Detail Cost
                </button>
              </div>
              <button type="button" className="btn btn-link text-nowrap">
                <Copy className="mg-r-5" />
                Copy All
              </button>
            </td>
            <td>
              <div className="input-group wd-200">
                <div className="input-group-prepend">
                  <span className="input-group-text">＄</span>
                </div>
                <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
              </div>
              <button type="button" className="btn btn-link text-nowrap">
                <Copy className="mg-r-5" />
                Copy All
              </button>
            </td>
            <td>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="productItemActiveSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="productItemActiveSwitch1"></label>
              </div>
            </td>
            <td>
              <div className="input-group mg-r-10">
                <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                  <div className="form-control-tag">
                    <span className="form-control-tag-name">Monthly 1</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      aria-label="삭제"
                    >
                      <X />
                    </button>
                  </div>
                  <div className="form-control-tag">
                    <span className="form-control-tag-name">Monthly 2</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      aria-label="삭제"
                    >
                      <X />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                    placeholder="Type or click to select a template"
                  />
                </div>
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open modal to select a template"
                    data-toggle="modal"
                    data-target="#selSubscriptionTempModal"
                  >
                    {Icon('subscription_temp')}
                    <span className="sr-only">템플릿 선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </td>
            <td>
              <button
                type="button"
                className={classNames('btn btn-link tx-nowrap', showMoreInfo ? 'opened' : '')}
                onClick={() => setShowMoreInfo(!showMoreInfo)}
              >
                <Plus className="mg-r-5" />
                {showMoreInfo ? 'Close' : 'Add'}
              </button>
            </td>
            <td>
              <button type="button" className="btn btn-icon tx-danger">
                <X />
              </button>
            </td>
          </tr>
          {showMoreInfo && (
            <tr>
              <td colSpan={11} className="more-info-cell">
                <div id="itemMoreInfo1" className="product-item-more-info">
                  <div className="form-col">
                    <div className="form-group">
                      <label className="form-item-title">Dimensisions</label>
                      <div className="input-group wd-300">
                        <div className="form-control form-control-lg d-flex align-items-center">
                          <input
                            type="number"
                            className="wd-100p bd-0 pd-0 text-center"
                            autoFocus
                          />
                          <span className="mg-x-5 tx-color-03">x</span>
                          <input type="number" className="wd-100p bd-0 pd-0 text-center" />
                          <span className="mg-x-5 tx-color-03">x</span>
                          <input type="number" className="wd-100p bd-0 pd-0 text-center" />
                        </div>
                        <div className="input-group-append">
                          <span className="input-group-text">cm</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <label className="form-item-title">Weight</label>
                      <div className="input-group wd-200">
                        <input type="number" className="form-control form-control-lg" />
                        <div className="input-group-append">
                          <span className="input-group-text">kg</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <label className="form-item-title">Original Barcode</label>
                      <input type="number" className="form-control form-control-lg" />
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <label className="form-item-title">Vendor</label>
                      <CustomerAutoComplete
                        placement="right"
                        single={false}
                        showAvatar={true}
                        visible={true}
                        placeholder="Type or click to select a vendor"
                        category=""
                        value={[]}
                        onChange={() => null}
                      />
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <label className="form-item-title">Manufaturer</label>
                      <CustomerAutoComplete
                        placement="right"
                        single={false}
                        showAvatar={true}
                        visible={true}
                        placeholder="Type or click to select a manufaturer"
                        category=""
                        value={[]}
                        onChange={() => null}
                      />
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <label className="form-item-title">Warranty Period</label>
                      <div className="d-flex align-items-center wd-300 mx-wd-100p">
                        <input type="number" className="form-control mg-r-5" />
                        <SelectHook options={[{ value: 1, label: 'year(s)' }]} />
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <label className="form-item-title">Returnable Item</label>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="productReturnableItemSwitch1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="productReturnableItemSwitch1"
                        >
                          Returnable
                        </label>
                      </div>
                      <div className="mg-t-5 tx-orange">
                        반품 가능 상품인 경우 자동으로 체크됩니다.
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Sales Comission</label>
                    <Switch title="Comissionable" />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Sales Comission Type</label>
                    <div>Residual(Subscription)</div>
                  </div>
                  <div className="form-col">
                    <div className="form-group">
                      <label className="form-item-title">Description</label>
                      <Input type="text" />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link mg-l-auto"
                    onClick={() => setShowMoreInfo(false)}
                  >
                    <X className="mg-r-5" />
                    Close more info
                  </button>
                </div>
              </td>
            </tr>
          )}
          <tr>
            <td>Juice-Bottle/Cherry</td>
            <td>
              <div className="input-group wd-200">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn" aria-label="open code generation settings">
                    <Settings />
                  </button>
                </div>
              </div>
            </td>
            <td>Bottle</td>
            <td>1</td>
            <td>
              <div className="input-group wd-200">
                <div className="input-group-prepend">
                  <span className="input-group-text">＄</span>
                </div>
                <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
              </div>
            </td>
            <td>
              <div className="d-flex">
                <div className="input-group wd-200 mg-r-5">
                  <div className="input-group-prepend">
                    <span className="input-group-text">＄</span>
                  </div>
                  <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
                </div>
                <button
                  type="button"
                  className="btn btn-white text-nowrap"
                  data-toggle="modal"
                  data-target="#productDetailCostModal"
                >
                  <Plus className="mg-r-5" />
                  <span className="sr-only">추가</span> Detail Cost
                </button>
              </div>
            </td>
            <td>
              <div className="input-group wd-200">
                <div className="input-group-prepend">
                  <span className="input-group-text">＄</span>
                </div>
                <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
              </div>
            </td>
            <td>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="productItemActiveSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="productItemActiveSwitch1"></label>
              </div>
            </td>
            <td>
              <div className="input-group mg-r-10">
                <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                  <div className="form-control-tag">
                    <span className="form-control-tag-name">Yearly 1</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      aria-label="삭제"
                    >
                      <X />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                    placeholder="Type or click to select a template"
                  />
                </div>
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open modal to select a template"
                    data-toggle="modal"
                    data-target="#selSubscriptionTempModal"
                  >
                    {Icon('subscription_temp')}
                    <span className="sr-only">템플릿 선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </td>
            <td>
              <button type="button" className="btn btn-link tx-nowrap">
                <Plus className="mg-r-5" />
                Add
              </button>
            </td>
            <td>
              <button type="button" className="btn btn-icon tx-danger">
                <X />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WriteItemStep2;
