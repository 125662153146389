import { Input, Select } from '@base/components/form';
import React, { useEffect, useState } from 'react';
import { Clock } from 'react-feather';
import TriggerType from '../trigger-type';

const OPTIONS = [
  { value: 1, label: 'Wait until Trigger' },
  { value: 2, label: 'Wait until Date Time' },
  { value: 3, label: 'Wait until And-Join' },
  { value: 4, label: 'Wait by Duration' },
  { value: 5, label: 'Set Schedule by Attribute' },
];

const WaitType: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  //state
  const [selectedValue, setSelectedValue] = useState<any>(OPTIONS[0]);

  //init data
  useEffect(() => {
    if (value) {
      //single object
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(OPTIONS[0]);
    }
  }, [value]);

  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption);
    //callback
    onChange && onChange(newOption.value);
  };

  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Wait Type</label>
        <Select
          options={OPTIONS}
          value={selectedValue}
          onChange={handleValueChange}
          className="wd-300-f"
        />
      </div>
      {selectedValue.value == 1 && (
        <>
          <div className="form-group">
            <label className="form-item-title">Trigger Name</label>
            <Select />
          </div>
          <TriggerType />
        </>
      )}
      {selectedValue.value == 2 && (
        <div className="form-group">
          <label className="form-item-title">Date/Time</label>
          <div className="input-group wd-300">
            <Input />
            <div className="input-group-append">
              <span className="input-group-text">
                <Clock />
              </span>
            </div>
          </div>
        </div>
      )}
      {selectedValue.value == 4 && (
        <div className="form-group">
          <label className="form-item-title">Duration</label>
          <div className="d-flex align-items-center">
            Wait
            <input type="text" className="form-control wd-100 mg-x-10" />
            <Select
              className="wd-150-f"
              options={[
                { value: 1, label: 'hour(s)' },
                { value: 2, label: 'day(s)' },
                { value: 3, label: 'week(s)' },
                { value: 4, label: 'month(s)' },
              ]}
              defaultValue={{ value: 1, label: 'hour(s)' }}
            />
          </div>
        </div>
      )}
      {selectedValue.value == 5 && (
        <div className="form-group">
          <label className="form-item-title">Schedule</label>
          <div className="d-flex flex-wrap align-items-center">
            Wait until <Input type="number" className="wd-80 mg-x-10" />
            <Select className="wd-100-f mg-x-10" defaultValue={[{ value: 1, label: 'day(s)' }]} />
            <Select className="wd-150-f mg-x-10" defaultValue={[{ value: 1, label: 'after' }]} />
            <Select
              className="wd-200-f mg-x-10"
              defaultValue={[{ value: 1, label: 'Select Attribute' }]}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WaitType;
