import { ChevronUp, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';

const NewContact = () => {
  return (
    <div id="newContactModal" className="modal over-modal fade right">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="h6 modal-title">New Customer</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">
                Name
                <span className="tx-danger" title="필수 입력">
                  *
                </span>
              </label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Account Name</label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Email
                <span className="tx-danger" title="필수 입력">
                  *
                </span>
              </label>
              <input type="email" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Phone Number
                <span className="tx-danger" title="필수 입력">
                  *
                </span>
              </label>
              <input type="tel" className="form-control" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Add
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                aria-label="toggle dropdown"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">저장 옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <button type="button" className="dropdown-item">
                  Add and add new
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewContact;
