/* eslint-disable prettier/prettier */
import Icon from '@base/assets/icons/svg-icons';
import {
  Button,
  Checkbox,
  FormIcon,
  Input,
  RadioGroup,
  Select,
  Switch,
  TimeRangePicker,
} from '@base/components/form';
import { CheckBox } from '@base/config/write-field/components';
import { UserAutoComplete } from '@base/containers';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import { useState } from 'react';
import { ChevronLeft, ChevronRight, ChevronsRight, Plus, X } from 'react-feather';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "DeskCss/desk.scss"

import '../style.scss';

import CustomerList from './modal/customerList';
import NewChannel from './modal/newChannel';
import NewAssignmentGroup from './modal/newGroup';

const TABS = [
  {
    value: 1,
    label: 'General',
  },
  {
    value: 2,
    label: 'Categories',
  },
  {
    value: 3,
    label: 'Tags',
  },
  {
    value: 4,
    label: 'Assignment User/Group',
  },
  {
    value: 5,
    label: 'Channels',
  },
];

const Settings = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [showTicketDetail, setShowTicketDetail] = useState(false);
  const [showArticleDetail, setShowArticleDetail] = useState(false);
  const [showGroupDetail, setShowGroupDetail] = useState(false);
  const [assignmentType, setAssignmentType] = useState<any>({
    user: true,
    group: false,
  });

  const BH_OPTIONS = [
    { value: 1, label: '24/7' },
    { value: 2, label: '24/6' },
    { value: 3, label: '24/5' },
    { value: 4, label: 'Custom' },
  ];
  const [BHOptions, setBHOptions] = useState(BH_OPTIONS);
  const [selectedBHOption, setSelectedBHOption] = useState(BH_OPTIONS[3]);
  //change
  const handleBHOptionChange = (newOption: any) => {
    setSelectedBHOption(newOption);
  };

  const [crrValue, setCrrValue] = useState<any>({
    startTime: '09:00',
    endTime: '18:00',
  });

  return (
    <>
      {/* tabs */}
      <div className="bg-white bd-b">
        <ul className="nav nav-line flex-grow-1 mg-x-15 bd-0">
          {TABS.map((item, index) => (
            <li className="nav-item" key={index}>
              <button
                type="button"
                className={`btn nav-link pd-y-15-f ${item.value == tab.value ? 'active' : ''}`}
                onClick={(e) => {
                  setTab(item);
                }}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="pd-20 scroll-box">
        {tab.value == 1 && (
          <div className="row row-xs">
            <div className="col-xl-6">
              {/* Desk Hours */}
              <div className="card mg-b-15">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Desk Hours</div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label className="form-item-title">Business Hours</label>
                    <RadioGroup
                      value={selectedBHOption.value}
                      options={BHOptions}
                      onChange={handleBHOptionChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Working Days</label>
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      name="Sun"
                      className="rounded-30 mg-r-5 mg-b-1"
                    />
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      name="Mon"
                      className="rounded-30 mg-r-5 mg-b-1 active"
                    />
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      name="Tue"
                      className="rounded-30 mg-r-5 mg-b-1 active"
                    />
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      name="Wed"
                      className="rounded-30 mg-r-5 mg-b-1 active"
                    />
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      name="Thu"
                      className="rounded-30 mg-r-5 mg-b-1 active"
                    />
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      name="Fri"
                      className="rounded-30 mg-r-5 mg-b-1 active"
                    />
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      name="Sat"
                      className="rounded-30 mg-r-5 mg-b-1"
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">First day of work week</label>
                    <Select width={200} defaultValue={{ value: 'mon', label: 'Monday' }} />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Working Hours</label>
                    <ul className="list-group">
                      <li className="list-group-item d-flex flex-wrap align-items-center bg-white">
                        <div className="wd-80">Mon</div>
                        <TimeRangePicker customLabelStart=" " customLabelEnd=" " value={crrValue} />
                        <span className="mg-x-10">8 hrs</span>
                        <Button
                          color="link"
                          icon="Copy"
                          iconClass="mg-r-5"
                          name="Copy"
                          className="mg-l-auto"
                        />
                      </li>
                      <li className="list-group-item d-flex flex-wrap align-items-center bg-white">
                        <div className="wd-80">Tue</div>
                        <TimeRangePicker customLabelStart=" " customLabelEnd=" " value={crrValue} />
                        <span className="mg-x-10">8 hrs</span>
                      </li>
                      <li className="list-group-item d-flex flex-wrap align-items-center bg-white">
                        <div className="wd-80">Wed</div>
                        <TimeRangePicker customLabelStart=" " customLabelEnd=" " value={crrValue} />
                        <span className="mg-x-10">8 hrs</span>
                      </li>
                      <li className="list-group-item d-flex flex-wrap align-items-center bg-white">
                        <div className="wd-80">Thu</div>
                        <TimeRangePicker customLabelStart=" " customLabelEnd=" " value={crrValue} />
                        <span className="mg-x-10">8 hrs</span>
                      </li>
                      <li className="list-group-item d-flex flex-wrap align-items-center bg-white">
                        <div className="wd-80">Fri</div>
                        <TimeRangePicker customLabelStart=" " customLabelEnd=" " value={crrValue} />
                        <span className="mg-x-10">8 hrs</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* SLA */}
              <div className="card mg-b-15">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Service Level Agreement(SLA)</div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="text-nowrap">
                          Name
                        </th>
                        <th scope="col" className="wd-70 text-nowrap">
                          Default
                        </th>
                        <th scope="col" className="wd-70 text-nowrap">
                          Customers
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Premium SLA</td>
                        <td className="text-center">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="slaDefaultRadio1"
                              name="slaDefaultRadio"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="slaDefaultRadio1"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-0"
                            data-toggle="modal"
                            data-target="#customerListModal"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Standard SLA</td>
                        <td className="text-center">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="slaDefaultRadio1"
                              name="slaDefaultRadio"
                              className="custom-control-input"
                              defaultChecked
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="slaDefaultRadio1"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-0"
                            data-toggle="modal"
                            data-target="#customerListModal"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              {/* Priority */}
              <div className="card mg-b-15">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Priority</div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">&nbsp;</th>
                        <th scope="col" className="wd-70">
                          Active
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="badge badge-priority priority-urgent">Urgent</span>
                        </td>
                        <td>
                          <Switch value={true} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="badge badge-priority priority-high">High</span>
                        </td>
                        <td>
                          <Switch value={true} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="badge badge-priority priority-medium">Medium</span>
                        </td>
                        <td>
                          <Switch value={true} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="badge badge-priority priority-low">Low</span>
                        </td>
                        <td>
                          <Switch value={true} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Ticket Classification */}
              <div className="card mg-b-15">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">
                    Ticket Classification
                    <br />
                    <small className="mg-t-5 tx-color-03 tx-normal">
                      These Attribute-Values are used for Assignment Rule and Ticket creation
                    </small>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete">
                    <thead>
                      <tr>
                        <th scope="col">Classification</th>
                        <th scope="col">Values</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Region</td>
                        <td>
                          <div className="d-flex align-items">
                            <Input type="text" placeholder="Seperate with , or click enter" />
                            <Button
                              color=""
                              icon="X"
                              iconClass="tx-danger"
                              className="btn-icon btn-delete-row han-tooltip--left"
                              data-han-tooltip="삭제"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Language</td>
                        <td>
                          <div className="d-flex align-items">
                            <Input type="text" placeholder="Seperate with , or click enter" />
                            <Button
                              color=""
                              icon="X"
                              iconClass="tx-danger"
                              className="btn-icon btn-delete-row han-tooltip--left"
                              data-han-tooltip="삭제"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card-footer">
                  <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12">
              {/* Time to 1st Respond by Priority */}
              <div className="card mg-b-15">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Time to 1st Respond by Priority</div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="tx-nowrap">
                          Priority
                        </th>
                        <th scope="col" className="tx-nowrap">
                          Standard SLA
                        </th>
                        <th scope="col" className="tx-nowrap">
                          Premium SLA
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align-middle">
                          <span className="badge badge-priority priority-urgent">Urgent</span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Input type="number" className="wd-100 mg-r-5" />
                            <Select
                              className="wd-150-f mg-r-5"
                              options={[
                                { value: 1, label: 'mins' },
                                { value: 2, label: 'hrs' },
                                { value: 3, label: 'days' },
                              ]}
                              defaultValue={{ value: 1, label: 'hrs' }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Input type="number" className="wd-100 mg-r-5" />
                            <Select
                              className="wd-150-f mg-r-5"
                              options={[
                                { value: 1, label: 'mins' },
                                { value: 2, label: 'hrs' },
                                { value: 3, label: 'days' },
                              ]}
                              defaultValue={{ value: 1, label: 'hrs' }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span className="badge badge-priority priority-high">High</span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Input type="number" className="wd-100 mg-r-5" />
                            <Select
                              className="wd-150-f mg-r-5"
                              options={[
                                { value: 1, label: 'mins' },
                                { value: 2, label: 'hrs' },
                                { value: 3, label: 'days' },
                              ]}
                              defaultValue={{ value: 1, label: 'hrs' }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Input type="number" className="wd-100 mg-r-5" />
                            <Select
                              className="wd-150-f mg-r-5"
                              options={[
                                { value: 1, label: 'mins' },
                                { value: 2, label: 'hrs' },
                                { value: 3, label: 'days' },
                              ]}
                              defaultValue={{ value: 1, label: 'hrs' }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span className="badge badge-priority priority-medium">Medium</span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Input type="number" className="wd-100 mg-r-5" />
                            <Select
                              className="wd-150-f mg-r-5"
                              options={[
                                { value: 1, label: 'mins' },
                                { value: 2, label: 'hrs' },
                                { value: 3, label: 'days' },
                              ]}
                              defaultValue={{ value: 1, label: 'hrs' }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Input type="number" className="wd-100 mg-r-5" />
                            <Select
                              className="wd-150-f mg-r-5"
                              options={[
                                { value: 1, label: 'mins' },
                                { value: 2, label: 'hrs' },
                                { value: 3, label: 'days' },
                              ]}
                              defaultValue={{ value: 1, label: 'hrs' }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <span className="badge badge-priority priority-low">Low</span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Input type="number" className="wd-100 mg-r-5" />
                            <Select
                              className="wd-150-f mg-r-5"
                              options={[
                                { value: 1, label: 'mins' },
                                { value: 2, label: 'hrs' },
                                { value: 3, label: 'days' },
                              ]}
                              defaultValue={{ value: 1, label: 'hrs' }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Input type="number" className="wd-100 mg-r-5" />
                            <Select
                              className="wd-150-f mg-r-5"
                              options={[
                                { value: 1, label: 'mins' },
                                { value: 2, label: 'hrs' },
                                { value: 3, label: 'days' },
                              ]}
                              defaultValue={{ value: 1, label: 'hrs' }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              {/* Time to Resolve by SLA */}
              <div className="card mg-b-15">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Time to Resolve by SLA</div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-70 tx-nowrap">
                          Standard SLA
                        </th>
                        <th scope="col" className="wd-70 tx-nowrap">
                          Premium SLA
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Followed by Category</td>
                        <td>
                          <div className="d-flex align-items-center">
                            -
                            <Input type="number" rightIcon="%" className="wd-120 mg-l-5" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              {/* Auto Close Ticket */}
              <div className="card mg-b-15">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Auto Close Ticket</div>
                </div>
                <div className="card-body">
                  <div className="d-flex flex-wrap align-items-center">
                    Close the Resolved Ticket after
                    <Input type="number" className="wd-100 mg-x-5" />
                    hours
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card mg-b-15">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Satisfaction Survey Tempate of Desk Site</div>
                </div>
                <div className="card-body pd-0-f overflow-auto"></div>
                <div className="card-footer">
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control mg-r-5"
                      placeholder="Enter or select a tempalte"
                    />
                    <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
                      <Plus className="mg-r-5" />
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {tab.value == 2 && (
          <>
            <div className="mg-b-10 text-right">
              <Button color="primary" icon="Plus" name="Add Issue" />
            </div>
            <div className="card table-responsive">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete">
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap">
                      Issue
                    </th>
                    <th scope="col" className="text-nowrap">
                      Product
                    </th>
                    <th scope="col" className="text-nowrap">
                      Priority
                    </th>
                    <th scope="col" className="text-nowrap">
                      Average Time to Resolve
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input type="text" />
                    </td>
                    <td>
                      <div className="d-flex">
                        <ProductAutoComplete />
                        <Button
                          color="link"
                          icon="Plus"
                          className="btn-icon han-tooltip--right"
                          data-han-tooltip="추가"
                        />
                      </div>
                    </td>
                    <td>
                      <Select className="wd-150-f" options={[{ value: 1, label: 'Urgent' }]} />
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <Input type="number" className="wd-100 mg-r-5" />
                        <Select
                          className="wd-150-f"
                          options={[{ value: 1, label: 'hrs' }]}
                          defaultValue={{ value: 1, label: 'hrs' }}
                        />
                        <Button
                          color=""
                          icon="X"
                          iconClass="tx-danger"
                          className="btn-icon btn-delete-row han-tooltip--left"
                          data-han-tooltip="삭제"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        {tab.value == 3 && (
          <>
            {!showTicketDetail && !showArticleDetail && (
              <>
                <div className="mg-b-10 text-right">
                  <Button color="primary" icon="Plus" name="Add Tags" />
                </div>
                <div className="card table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30 text-nowrap">
                          <CheckBox />
                        </th>
                        <th scope="col" className="text-nowrap">
                          Tag
                        </th>
                        <th scope="col" className="text-nowrap">
                          Linked Tickets
                        </th>
                        <th scope="col" className="text-nowrap">
                          Linked Articles
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <CheckBox />
                        </td>
                        <td>Billing</td>
                        <td>
                          <Button
                            color="link"
                            name="60"
                            className="pd-0"
                            onClick={() => setShowTicketDetail(true)}
                          />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Button
                              color="link"
                              name="10"
                              className="pd-0"
                              onClick={() => setShowArticleDetail(true)}
                            />
                            <Button
                              color="link"
                              icon="Edit2"
                              className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                              data-han-tooltip="수정"
                            />
                            <Button
                              color=""
                              icon="X"
                              iconClass="tx-danger"
                              className="btn-icon btn-delete-row han-tooltip--left pd-y-0"
                              data-han-tooltip="삭제"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {showTicketDetail && (
              <div className="card">
                <div className="card-header d-flex align-items-center pd-10">
                  <Button
                    color="link"
                    icon="ArrowLeft"
                    className="btn-icon mg-r-10"
                    onClick={() => setShowTicketDetail(false)}
                  />
                  Billing
                  <div className="input-group wd-300 mg-l-auto">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FormIcon icon="Search" className="tx-gray-400" />
                      </span>
                    </div>
                    <Input type="text" />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30 text-nowrap">
                          <CheckBox />
                        </th>
                        <th scope="col" className="text-nowrap">
                          Priority
                        </th>
                        <th scope="col" className="text-nowrap">
                          Customer
                        </th>
                        <th scope="col" className="text-nowrap">
                          Subject
                        </th>
                        <th scope="col" className="text-nowrap">
                          Stage
                        </th>
                        <th scope="col" className="text-nowrap">
                          Status
                        </th>
                        <th scope="col" className="text-nowrap">
                          Assigned Group
                        </th>
                        <th scope="col" className="text-nowrap">
                          Assigned Rep
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <CheckBox />
                        </td>
                        <td>
                          <span className="badge badge-priority priority-urgent">Urgent</span>
                        </td>
                        <td>Customer 1</td>
                        <td>Subject Subject</td>
                        <td>New</td>
                        <td>New</td>
                        <td>Billing</td>
                        <td>MSR</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card-footer pd-y-6-f pagination">
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="move to first"
                  >
                    {Icon('move_first')}
                    <span className="sr-only">처음으로</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="previous"
                  >
                    <ChevronLeft />
                    <span className="sr-only">이전</span>
                  </button>
                  <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
                    <ChevronRight />
                    <span className="sr-only">다음</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="move to last"
                  >
                    {Icon('move_last')}
                    <span className="sr-only">마지막으로</span>
                  </button>
                  <input type="number" className="paging-input" defaultValue="1" />/ 1
                </div>
              </div>
            )}
            {showArticleDetail && (
              <div className="card">
                <div className="card-header d-flex align-items-center pd-10">
                  <Button
                    color="link"
                    icon="ArrowLeft"
                    className="btn-icon mg-r-10"
                    onClick={() => setShowArticleDetail(false)}
                  />
                  Billing
                  <div className="input-group wd-300 mg-l-auto">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FormIcon icon="Search" className="tx-gray-400" />
                      </span>
                    </div>
                    <Input type="text" />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30 text-nowrap">
                          <CheckBox />
                        </th>
                        <th scope="col" className="text-nowrap">
                          Category/Folder
                        </th>
                        <th scope="col" className="text-nowrap">
                          Subject
                        </th>
                        <th scope="col" className="text-nowrap">
                          Viewed
                        </th>
                        <th scope="col" className="text-nowrap">
                          Inserted
                        </th>
                        <th scope="col" className="text-nowrap">
                          Helpful
                        </th>
                        <th scope="col" className="text-nowrap">
                          Not Helpful
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <CheckBox />
                        </td>
                        <td>Category/Folder1/Folder2</td>
                        <td>Subject Subject</td>
                        <td>2</td>
                        <td>MSR</td>
                        <td>2022-08-03</td>
                        <td>2022-08-03</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card-footer pd-y-6-f pagination">
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="move to first"
                  >
                    {Icon('move_first')}
                    <span className="sr-only">처음으로</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="previous"
                  >
                    <ChevronLeft />
                    <span className="sr-only">이전</span>
                  </button>
                  <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
                    <ChevronsRight />
                    <span className="sr-only">다음</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="move to last"
                  >
                    {Icon('move_last')}
                    <span className="sr-only">마지막으로</span>
                  </button>
                  <input type="number" className="paging-input" defaultValue="1" />/ 1
                </div>
              </div>
            )}
          </>
        )}

        {tab.value == 4 && (
          <>
            {!showGroupDetail && (
              <>
                <div className="d-flex">
                  <ul className="nav nav-tabs flex-grow-1">
                    <li className="nav-item">
                      <Button
                        color=""
                        className={`nav-link rounded-0 ${
                          assignmentType.user ? 'active tx-semibold' : ''
                        }`}
                        name="User"
                        onClick={() => setAssignmentType({ user: true })}
                      />
                    </li>
                    <li className="nav-item">
                      <Button
                        color=""
                        className={`nav-link rounded-0 ${
                          assignmentType.group ? 'active tx-semibold' : ''
                        }`}
                        name="Group"
                        onClick={() => setAssignmentType({ group: true })}
                      />
                    </li>
                  </ul>
                  {assignmentType.group && (
                    <div className="mg-l-auto bd-b">
                      <Button
                        color="primary"
                        icon="Plus"
                        name="Add"
                        data-toggle="modal"
                        data-target="#newAssignmentGroup"
                      />
                    </div>
                  )}
                </div>
                <div className="tab-content bg-white bd bd-gray-200 bd-t-0">
                  <div className="table-responsive">
                    {assignmentType.user && (
                      <table className="table table-bordered mg-b-0 bd-0 hover-delete">
                        <thead>
                          <tr>
                            <th scope="col" className="text-nowrap">
                              User Type
                            </th>
                            <th scope="col" className="text-nowrap">
                              User Name
                            </th>
                            <th scope="col" className="text-nowrap">
                              Email
                            </th>
                            <th scope="col" className="text-nowrap">
                              Mobile
                            </th>
                            <th scope="col" className="text-nowrap">
                              Active
                            </th>
                            <th scope="col" className="text-nowrap">
                              Notification
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>User</td>
                            <td>
                              <Button color="link" name="MSR" className="pd-0" />
                            </td>
                            <td>123@mail.com</td>
                            <td>0701110000</td>
                            <td>
                              <Switch value={true} />
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <Checkbox label="Email" className="mg-r-10" checked />
                                <Checkbox label="SMS" className="mg-r-10" checked />
                                <Button
                                  color=""
                                  icon="X"
                                  iconClass="tx-danger"
                                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                                  data-han-tooltip="삭제"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {assignmentType.group && (
                      <table className="table table-bordered mg-b-0 bd-0 hover-delete">
                        <thead>
                          <tr>
                            <th scope="col" className="text-nowrap">
                              Group Name
                            </th>
                            <th scope="col" className="text-nowrap">
                              Descriptions
                            </th>
                            <th scope="col" className="text-nowrap">
                              Reps
                            </th>
                            <th scope="col" className="text-nowrap">
                              Active
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Button
                                color="link"
                                name="Customer Support"
                                className="pd-0"
                                onClick={() => setShowGroupDetail(true)}
                              />
                            </td>
                            <td>Descriptions</td>
                            <td>6</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <Switch value={true} />
                                <Button
                                  color=""
                                  icon="X"
                                  iconClass="tx-danger"
                                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                                  data-han-tooltip="삭제"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                {assignmentType.user && <Button color="link" icon="Plus" name="Add user" />}
              </>
            )}
            {showGroupDetail && (
              <div className="card">
                <div className="card-header d-flex align-items-center pd-10">
                  <Button
                    color="link"
                    icon="ArrowLeft"
                    className="btn-icon mg-r-10"
                    onClick={() => setShowGroupDetail(false)}
                  />
                  <h2 className="h6 mg-0">Customer Support</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div className="bd rounded pd-10">Description Description Description</div>
                  </div>
                  <div className="table-responsive bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete">
                      <thead>
                        <tr>
                          <th scope="col">User Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Capacity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="d-inline-block avatar avatar-xs mg-r-5">
                              <img
                                src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                                className="rounded-circle"
                                alt=""
                              />
                            </span>
                            Allan Rey Palban
                          </td>
                          <td>Pope@mail.com</td>
                          <td>0701111111</td>
                          <td>
                            <div className="d-flex align-items-center">
                              33
                              <Button
                                color=""
                                icon="X"
                                iconClass="tx-danger"
                                className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                                data-han-tooltip="삭제"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <UserAutoComplete showAvatar single />
                          </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Input type="number" rightIcon="%" className="wd-120" />
                              <Button
                                color=""
                                icon="X"
                                iconClass="tx-danger"
                                className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                                data-han-tooltip="삭제"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pd-10 bd bd-t-0 rounded-bottom">
                    <span>Total 3</span>
                    <span>100%</span>
                  </div>
                  <Button color="link" icon="Plus" name="Add line" />
                </div>
              </div>
            )}
          </>
        )}

        {tab.value == 5 && (
          <>
            <div className="mg-b-10 text-right">
              <Button
                color="primary"
                icon="Plus"
                name="Add Channel"
                data-toggle="modal"
                data-target="#newChannel"
              />
            </div>
            <div className="card table-responsive">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete">
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap">
                      Type
                    </th>
                    <th scope="col" className="text-nowrap">
                      Channel Name
                    </th>
                    <th scope="col" className="text-nowrap">
                      Descriptions
                    </th>
                    <th scope="col" className="wd-100 text-nowrap">
                      Default
                    </th>
                    <th scope="col" className="wd-150 text-nowrap">
                      Active
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Desk From</td>
                    <td>Request</td>
                    <td>Request</td>
                    <td>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="defaultChannel1"
                          name="defaultChannel"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="defaultChannel1"></label>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <Switch value={true} />
                        <Button
                          color="link"
                          icon="Edit2"
                          className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                          data-han-tooltip="수정"
                        />
                        <Button
                          color=""
                          icon="X"
                          iconClass="tx-danger"
                          className="btn-icon btn-delete-row han-tooltip--left pd-y-0"
                          data-han-tooltip="삭제"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      <CustomerList />
      <NewAssignmentGroup />
      <NewChannel />
    </>
  );
};

export default Settings;
