import { Select } from '@base/components/form';
import React, { useEffect, useState } from 'react';
import { User, X } from 'react-feather';

const OPTIONS = [
  { value: 'user', label: 'User/Group' },
  { value: 'rule', label: 'Assignment Queue' },
];

const AssignmentType: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  //state
  const [selectedValue, setSelectedValue] = useState<any>(OPTIONS[0]);

  //init data
  useEffect(() => {
    if (value) {
      //single object
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(OPTIONS[0]);
    }
  }, [value]);

  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption);
    //callback
    onChange && onChange(newOption.value);
  };

  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Assigned to</label>
        <Select
          options={OPTIONS}
          value={selectedValue}
          onChange={handleValueChange}
          className="wd-300-f"
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          {selectedValue.value === 'user' ? 'Assigned Rep.' : 'Action Name'}
        </label>
        {selectedValue.value === 'user' ? (
          <div className="input-group">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
              <div className="form-control-tag">
                <span className="d-block avatar avatar-xs mg-r-10">
                  <img
                    src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                    alt="John"
                    className="img rounded-circle"
                  />
                </span>
                <span className="form-control-tag-name">
                  John<span className="tx-gray-500">(Sales)</span>
                </span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                placeholder="Type or click to select an user or group"
              />
            </div>
            <div className="input-group-append">
              <button
                type="button"
                className="btn"
                aria-label="open modal to select an user or group"
              >
                <User />
                <span className="sr-only">담당자 선택 팝업 열기</span>
              </button>
            </div>
          </div>
        ) : (
          <Select />
        )}
        <div className="custom-control custom-checkbox mg-t-10">
          <input type="checkbox" className="custom-control-input" id="sendByEmail" />
          <label className="custom-control-label" htmlFor="sendByEmail">
            Send by Email
          </label>
        </div>
      </div>
    </>
  );
};

export default AssignmentType;
