import { useState } from 'react';
import classnames from 'classnames';
import { X, ChevronDown, Check, Edit2 } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import noImg from '@base/assets/img/notfound.png';

const ProductDetail = () => {
  const [showEdit, setShowEdit] = useState<any>();

  return (
    <div className="pd-20">
      {!showEdit && (
        <div className="text-right">
          <button type="button" className="btn btn-link" onClick={() => setShowEdit(true)}>
            <Edit2 className="mg-r-5" />
            Edit
          </button>
        </div>
      )}
      <div className="table-responsive bg-white bd rounded-top">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="colgroup" colSpan={9} className="bd-t-0-f bd-b-d-f">
                Onetime Payment
              </th>
            </tr>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Image
              </th>
              <th scope="col" className="bd-t-0-f">
                Item
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit
              </th>
              <th scope="col" className="bd-t-0-f">
                Attribute
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit Price
              </th>
              <th scope="col" className="bd-t-0-f">
                Qty Ordered
              </th>
              <th scope="col" className="bd-t-0-f">
                Volume Discount
              </th>
              <th scope="col" className="bd-t-0-f">
                Amount
              </th>
              {showEdit && (
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src={noImg} alt="" />
                </div>
              </td>
              <td>
                <a href="" target="_blank" title="새창으로 열림">
                  AAA
                </a>
              </td>
              <td>Box</td>
              <td>&nbsp;</td>
              <td>￦100,000</td>
              <td>
                {showEdit ? (
                  <input type="number" className="form-control wd-150" defaultValue="100" />
                ) : (
                  '100'
                )}
              </td>
              <td>0</td>
              <td>￦10,000,000</td>
              {showEdit && (
                <td>
                  <button type="button" className="btn btn-icon">
                    <X className="tx-danger" />
                  </button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-responsive bg-white bd">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="colgroup" colSpan={10} className="bd-t-0-f bd-b-d-f">
                Subscription
              </th>
            </tr>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Image
              </th>
              <th scope="col" className="bd-t-0-f">
                Item
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit Price
              </th>
              <th scope="col" className="bd-t-0-f">
                Term
              </th>
              <th scope="col" className="bd-t-0-f">
                Term Discount
              </th>
              <th scope="col" className="bd-t-0-f">
                Qty Ordered
              </th>
              <th scope="col" className="bd-t-0-f">
                Amount
              </th>
              {showEdit && (
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src={noImg} alt="" />
                </div>
              </td>
              <td>
                <a href="" target="_blank" title="새창으로 열림">
                  AAA
                </a>
              </td>
              <td>Box</td>
              <td>￦100,000</td>
              <td>{showEdit ? <SelectHook className="wd-150-f" /> : ''}</td>
              <td>&nbsp;</td>
              <td>
                {showEdit ? (
                  <input type="number" className="form-control wd-150" defaultValue="100" />
                ) : (
                  '100'
                )}
              </td>
              <td>￦10,000,000</td>
              {showEdit && (
                <td>
                  <button type="button" className="btn btn-icon">
                    <X className="tx-danger" />
                  </button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-responsive bg-white bd rounded-top">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="colgroup" colSpan={8} className="bd-t-0-f bd-b-d-f">
                Prepaid
              </th>
            </tr>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Image
              </th>
              <th scope="col" className="bd-t-0-f">
                Item
              </th>
              <th scope="col" className="bd-t-0-f">
                Prepaid Type
              </th>
              <th scope="col" className="bd-t-0-f">
                Prepaid Amount
              </th>
              <th scope="col" className="bd-t-0-f">
                Prepaid Discount
              </th>
              <th scope="col" className="bd-t-0-f">
                Qty Ordered
              </th>
              <th scope="col" className="bd-t-0-f">
                Amount
              </th>
              {showEdit && (
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src={noImg} alt="" />
                </div>
              </td>
              <td>
                <a href="" target="_blank" title="새창으로 열림">
                  AAA
                </a>
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>￦100,000</td>
              <td>
                {showEdit ? (
                  <input type="number" className="form-control wd-150" defaultValue="100" />
                ) : (
                  '100'
                )}
              </td>
              <td>￦10,000,000</td>
              {showEdit && (
                <td>
                  <button type="button" className="btn btn-icon">
                    <X className="tx-danger" />
                  </button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      {showEdit && (
        <>
          <div className="pd-10 bd bd-t-0 rounded-bottom">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type or click to select an item"
              />
              <div className="input-group-append">
                <button type="button" className="btn">
                  {Icon('product')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="row row-xs mg-y-10">
        <div className="col-md-6">
          <ul className="list-unstyled lh-7 mg-b-0">
            <li className="d-flex flex-wrap justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
              <span>Items : 100</span>
              <span> ₩ 10,000,000</span>
            </li>
            <li className="d-flex flex-wrap justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
              <span>Total Discount</span>
              {showEdit && (
                <button className="mg-l-auto btn btn-sm btn-link link-03 pd-y-0">Change</button>
              )}
              <div className="mg-l-5 tx-danger">- ₩ 500,000</div>
            </li>
          </ul>
          <div className="rounded bd bg-gray-100">
            <div className="d-flex flex-wrap pd-x-15 pd-y-10">
              Loyalty
              <button
                type="button"
                className={classnames('btn btn-link link-03 mg-l-auto pd-0', {
                  collapsed: !showEdit,
                })}
                data-toggle="collapse"
                data-target="#loyaltyDetail"
              >
                <span className="tx-danger">-₩410,000</span>
                <ChevronDown className="mg-l-5" />
              </button>
            </div>
            <div
              id="loyaltyDetail"
              className={classnames('collapse mg-10 bd bd-dashed rounded', { show: showEdit })}
            >
              <ul className="list-unstyled mg-b-0 lh-7 mg-b-0">
                <li className="d-flex flex-wrap justify-content-between pd-10">
                  <div>
                    <label className="d-block mg-b-0 tx-color-03 tx-13">Point(s)</label>
                    {showEdit && <div className="tx-info">100,000 (₩10,000)</div>}
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <div className="d-flex align-items-center tx-danger">
                      - ₩
                      {showEdit ? (
                        <input
                          type="number"
                          className="form-control wd-150 mg-l-5 text-right tx-danger"
                          value="10000"
                        />
                      ) : (
                        '10,000'
                      )}
                    </div>
                  </div>
                </li>
                <li className="d-flex flex-wrap justify-content-between pd-10 bd bd-dashed bd-x-0 bd-b-0">
                  <label className="d-block mg-b-0 tx-color-03 tx-13">Coupon(s)</label>
                  {showEdit && (
                    <button
                      type="button"
                      className="mg-l-auto btn btn-sm btn-link link-03 pd-y-0"
                      data-toggle="modal"
                    >
                      Change
                    </button>
                  )}
                  <div className="tx-danger">- ₩ 100,000</div>
                </li>
                <li className="d-flex flex-wrap justify-content-between pd-10 bd bd-dashed bd-x-0 bd-b-0">
                  <div>
                    <label className="d-block mg-b-0 tx-color-03 tx-13">Stamp(s) Reward</label>
                    {showEdit && <div className="tx-info">3% Off</div>}
                  </div>
                  <div className="text-right">
                    <div className="text-right tx-danger">- ₩300,000</div>
                    {showEdit && (
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notUseStampRewardCheck"
                        />
                        <label className="custom-control-label" htmlFor="notUseStampRewardCheck">
                          Do not use.
                        </label>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
            <li className="d-flex flex-wrap justify-content-between">
              <span>Sub-Total</span>
              <span>₩ 9,090,000</span>
            </li>
            <li className="d-flex flex-wrap justify-content-between">
              <span>Shipping Charges</span>
              <span>₩ 4,000</span>
            </li>
            <li className="d-flex flex-wrap justify-content-between">
              <span>Tax (5%)</span>
              <span>₩ 454,500</span>
            </li>
            <li className="d-flex flex-wrap justify-content-between">
              <span>Round Off</span>
              <span>₩ 0</span>
            </li>
            <li className="d-flex flex-wrap justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
              <strong>Total Amount</strong>
              <strong>₩ 9,548,500</strong>
            </li>
          </ul>
        </div>
      </div>
      {showEdit && (
        <>
          <div className="mg-t-10 text-right">
            <button
              type="button"
              className="btn btn-light mg-r-10"
              onClick={() => setShowEdit(false)}
            >
              <X className="ma-r-5" /> Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={() => setShowEdit(false)}>
              <Check className="ma-r-5" /> Edit
            </button>
          </div>
        </>
      )}
      <div className="form-group">
        <label className="form-item-title">Terms &amp; Conditions</label>
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Customer Notes</label>
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
      </div>
    </div>
  );
};

export default ProductDetail;
