import { ChevronLeft, ChevronRight } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const TradeAgreement = () => {
  return (
    <div className="pd-20">
      <div className="form-row">
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Owner</label>
          <div className="media align-items-center">
            <div className="avatar avatar-xs">
              <img
                src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                className="rounded-circle"
              />
            </div>
            <div className="media-body mg-l-10">MSR</div>
          </div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Start on</label>
          <div>2021/09/01</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">End on</label>
          <div>2022/08/31</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Price List</label>
          <div>2021/09/01</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Active</label>
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="customSwitch1" />
            <label className="custom-control-label" htmlFor="customSwitch1"></label>
          </div>
        </div>
      </div>
      <div className="table-responsive bg-white bd rounded mg-b-20">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="colgroup" colSpan={4} className="bd-t-0-f bd-b-0-f text-center">
                Items to Order
              </th>
              <th scope="colgroup" colSpan={2} className="bd-t-0-f bd-b-0-f text-center">
                Volume Discount
              </th>
            </tr>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Unit</th>
              <th scope="col">Attribute</th>
              <th scope="col">Base Unit Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Discount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="" target="_blank" title="새창으로 열림">
                  <span className="d-inline-block avatar avatar-xs mg-r-5">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-zuZfEsT3-L7FkxQskS2kKV-EmFuc40-F9w&amp;usqp=CAU"
                      alt=""
                      className="rounded"
                    />
                  </span>
                  Item 1
                </a>
              </td>
              <td>Pallet</td>
              <td>S</td>
              <td>￦10,000</td>
              <td>1 ~ 5</td>
              <td>3%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="form-group">
        <label className="form-item-title">Threshold discount</label>
        <div className="table-responsive bg-white bd rounded">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Sub Total Amount
                </th>
                <th scope="col" className="bd-t-0-f">
                  Discount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>￦500,000 ~ ￦1,000,000</td>
                <td>3%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="form-row">
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Payment Term</label>
          <div>5 days</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Tax</label>
          <div>10%</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Delivery to</label>
          <div>address 12345</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Delivery Method</label>
          <div>Domestic</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Courier</label>
          <div>Postal Service</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Terms and Conditions</label>
          <div>&nbsp;</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">
            SO Template
            <button type="button" className="btn btn-link">
              Preview
            </button>
          </label>
          <div>Template 1</div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">
            Email Template
            <button type="button" className="btn btn-link">
              Preview
            </button>
          </label>
          <div>Template 1</div>
        </div>
      </div>
    </div>
  );
};

export default TradeAgreement;
