import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  X,
  ChevronsLeft,
  ChevronsRight,
  ArrowLeft,
  ThumbsUp,
  ThumbsDown,
  Eye,
} from 'react-feather';
//import Icon from 'Crm/services/Icons';
//import { Icons } from 'Crm/services/MenuIcons';
import Icon from '@base/assets/icons/svg-icons';

//import "CrmCss/desktop/theme1/theme1.scss"
//import "DeskCss/desk.scss"

import Userlist from '../../../../component/desktop/userlist';
import Timeline from './view/timeline';

const TABS = [
  {
    value: 1,
    label: 'Timeline',
  },
  {
    value: 2,
    label: 'Segments',
  },
  {
    value: 3,
    label: 'Campaign',
  },
  {
    value: 4,
    label: 'Unsubscribers',
  },
  {
    value: 5,
    label: 'Bounced',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  return (
    <div className="view-wrap">
      <div className="d-flex align-items-center mn-ht-50 pd-x-10 bg-white bd-b">
        <a
          href="/crm/demo-page/ui/marketing-list/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Marketing List
            <ChevronDown className="mg-l-5" />
          </button>
          <div className="dropdown-menu nav-sub">
            <div className="dropdown-item">
              <a role="button">Account</a>
            </div>
            <div className="dropdown-item">
              <a role="button">Contact</a>
            </div>
            <div className="dropdown-item">
              <a role="button">Segment</a>
            </div>
            <div className="dropdown-item active">
              <a href="/crm/demo-page/ui/marketing-list/desktop/theme1/list">Marketing List</a>
              <button
                type="button"
                className="btn btn-xs btn-set-default btn-icon"
                aria-label="Set as default"
              >
                {Icon('set_default')}
                <span className="sr-only">기본 메뉴로 설정</span>
              </button>
            </div>
            <div className="dropdown-item">
              <a href="/crm/demo-page/ui/buyer-persona/desktop/theme1/list">Buyer Persona</a>
            </div>
            <div className="dropdown-item">
              <a href="/crm/demo-page/ui/icp/desktop/theme1/list">Ideal Customer Profile</a>
            </div>
            <div className="dropdown-item">
              <a href="/crm/demo-page/ui/blocklist/desktop/theme1/list">Blocklist</a>
            </div>
          </div>
        </div>

        <h1 className="view-title mg-l-10">List 1</h1>

        <div className="button-group flex-shrink-0 mg-l-auto">
          <button
            type="button"
            data-han-tooltip="Delete"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">
              <Trash />
            </span>
            <span className="sr-only">삭제</span>
          </button>
          <button type="button" className="btn btn-primary mg-l-5 rounded-30">
            <Plus size="14" />
            New
          </button>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-section pd-15">
            <div className="media mg-b-5">
              <div className="wd-40 ht-40 rounded bg-info tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                {Icon('m_customer')}
              </div>
              <div className="media-body mg-l-10">
                <div className="tx-12 tx-uppercase tx-info tx-spacing-1 tx-nowrap">Contacts</div>
                <span className="h5 tx-spacing--2 lh-1 mg-b-5">20</span>
              </div>
            </div>
            <div className="media mg-b-5">
              <div className="wd-40 ht-40 rounded bg-gray-500 tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                {Icon('unsubscribe')}
              </div>
              <div className="media-body mg-l-10">
                <div className="tx-12 tx-uppercase tx-gray-500 tx-spacing-1 tx-nowrap">
                  Unsubscribers
                </div>
                <span className="h5 tx-spacing--2 lh-1 mg-b-5">2</span>
              </div>
            </div>
            <div className="media mg-b-5">
              <div className="wd-40 ht-40 rounded bg-success tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                {Icon('campaign')}
              </div>
              <div className="media-body mg-l-10">
                <div className="tx-12 tx-uppercase tx-success tx-spacing-1 tx-nowrap">Campaign</div>
                <span className="h5 tx-spacing--2 lh-1 mg-b-5">3</span>
              </div>
            </div>
            <div className="media">
              <div className="wd-40 ht-40 rounded bg-danger tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                {Icon('bounced')}
              </div>
              <div className="media-body mg-l-10">
                <div className="tx-12 tx-uppercase tx-danger tx-spacing-1 tx-nowrap">Bounced</div>
                <span className="h5 tx-spacing--2 lh-1 mg-b-5">1</span>
              </div>
            </div>
          </div>

          {/* Basic Info */}
          <div className="view-aside-section">
            <div className="view-aside-section-header">
              <span className="view-aside-section-header-title">Basic Info</span>
            </div>
            <div id="basicInfoSection" className="view-aside-section-body collapse show">
              <div className="form-group">
                <label className="form-item-title">Type</label>
                <div>Static</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Purpose</label>
                <div>&nbsp;</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Target</label>
                <div>Account</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Cost</label>
                <div>￦1,000,000</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Owner</label>
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <span className="avatar-initial rounded-circle">J</span>
                  </div>
                  <div className="media-body mg-l-10">John Doe</div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Last used on</label>
                <div>2020/11/17</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Active</label>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="marketingListSwitch1"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="marketingListSwitch1"></label>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames('col-6 view-content', {
            'col-9': hideAsideLeft || hideAsideRight,
            'col-12': hideAsideLeft && hideAsideRight,
          })}
        >
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* collapse button */}
            <button
              type="button"
              className={classnames(
                'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                { hidden: hideAsideLeft },
              )}
              onClick={() => setHideAsideLeft(!hideAsideLeft)}
            >
              <ChevronsLeft />
              <span className="sr-only">좌측 사이드 패널 접기</span>
            </button>

            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map(
                  (item) =>
                    item.value < 4 && (
                      <li
                        className={classnames('nav-item', {
                          active: item.value == tab.value,
                        })}
                      >
                        <button
                          type="button"
                          className="btn btn-xs btn-move-tab"
                          aria-label="Drag-and-drop to reorder"
                        >
                          {Icon('move')}
                          <span className="sr-only">드래그앤드롭으로 이동</span>
                        </button>
                        <button
                          type="button"
                          className="btn nav-link"
                          onClick={(e) => {
                            setTab(item);
                          }}
                        >
                          {item.label}
                        </button>
                      </li>
                    ),
                )}
                <li
                  className={classnames('nav-item dropdown pd-0-f', {
                    active: tab.value > 3,
                  })}
                >
                  <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                    <Plus className="mg-r-5" />
                    {tab.value > 3 ? tab.label : 'More'}
                  </button>
                  <div className="dropdown-menu dropdown-menu-right bg-white">
                    {TABS.map(
                      (item) =>
                        item.value > 3 && (
                          <div className="dropdown-item">
                            <button
                              type="button"
                              className="btn btn-xs btn-move-tab"
                              aria-label="Drag-and-drop to reorder"
                            >
                              {Icon('move')}
                              <span className="sr-only">드래그앤드롭으로 이동</span>
                            </button>
                            <button
                              type="button"
                              className={classnames('btn', {
                                active: item.value == tab.value,
                              })}
                              onClick={(e) => {
                                setTab(item);
                              }}
                            >
                              {item.label}
                            </button>
                          </div>
                        ),
                    )}
                  </div>
                </li>
              </ul>
            </div>

            {/* collapse button */}
            <button
              type="button"
              className={classnames(
                'btn btn-purple rounded-0 mg-l-10 bd-r-0 btn-collapse-aside-right',
                { hidden: hideAsideRight },
              )}
              onClick={() => setHideAsideRight(!hideAsideRight)}
            >
              <ChevronsRight />
              <span className="sr-only">우측 사이드 패널 접기</span>
            </button>
          </div>

          <div className="view-body">{tab.value == 1 && <Timeline />}</div>
        </div>

        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
          style={{ transformOrigin: 'left' }}
        >
          {/* Contact */}
          <div className="view-aside-section">
            <div className="view-aside-section-header">
              <button type="button" className="view-aside-section-move mg-r-5">
                {Icon('move')}
                <span className="sr-only">드래그앤드롭으로 이동</span>
              </button>
              <span className="view-aside-section-header-title">Contact</span>
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto pd-0"
                data-toggle="collapse"
                data-target="#customerContact"
              >
                <ChevronDown />
              </button>
            </div>
            <div id="customerContact" className="view-aside-section-body collapse show">
              <Userlist />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNew;
