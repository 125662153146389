import { X } from 'react-feather';

const Instruction = () => {
  return (
    <>
      <div
        id="newInstructionModal"
        className="modal fade right"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title">Instruction</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body">editor</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-success">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instruction;
