/* eslint-disable prettier/prettier */
import React from 'react';
import { ChevronDown, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
import './loyalty.scss';

const List = () => {
  return (
    <div className="d-flex pd-10">
      <button type="button" className="btn btn-link" data-toggle="modal" data-target="#viewLoyalty">
        view
      </button>

      <div
        id="viewLoyalty"
        className="modal fade right"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title">Loyalty 1</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body pd-0-f">
              <div className="modal-view-section">
                <div className="modal-view-section-header">
                  <button type="button" className="modal-view-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="modal-view-section-header-title">Basic Info</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#basicInfoSection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="basicInfoSection" className="modal-view-section-body collapse show">
                  <div className="form-group">
                    <label className="form-item-title">Loyalty Type</label>
                    <div>Point</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Customer Notice</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Time Period</label>
                    <div>2021/09/01 ~ 2021/09/02</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Active</label>
                    <div className="custom-control custom-switch">
                      <input type="checkbox" className="custom-control-input" id="loyaltyActive1" />
                      <label className="custom-control-label" htmlFor="loyaltyActive1"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-view-section">
                <div className="modal-view-section-header">
                  <button type="button" className="modal-view-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="modal-view-section-header-title">Event rule</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#eventRuleSection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="eventRuleSection" className="modal-view-section-body collapse show">
                  <div className="table-responsive bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Target
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Earn points
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>All Customers</td>
                          <td>5%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
