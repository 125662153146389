import React, { useState } from 'react';

import { X, Plus } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const UnitDetail = () => {
  const [input, setInput] = useState<any>('');
  const [addNew, setAddNew] = useState<any>('');

  return (
    <div
      id="unitDetailModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Detail</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-0-f">
            <div className="table-responsive">
              <table className="table dataTable table-bordered mg-b-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap bd-t-0-f">
                      Base Unit
                    </th>
                    <th scope="col" className="text-nowrap bd-t-0-f">
                      Unit Name
                    </th>
                    <th scope="col" className="text-nowrap bd-t-0-f">
                      Unit Quantity
                    </th>
                    <th scope="col" className="text-nowrap bd-t-0-f">
                      Related Products
                    </th>
                    <th scope="col" className="wd-70 bd-t-0-f">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={3}>Bottle</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-xs mg-r-5 pd-0 tx-color-03"
                        aria-label="Drag-and-drop to reorder"
                        style={{ cursor: 'grab' }}
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      Bottle
                    </td>
                    <td>1</td>
                    <td>Product 1, Product 2</td>
                    <td align="center">
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        type="button"
                        className="btn btn-xs mg-r-5 pd-0 tx-color-03"
                        aria-label="Drag-and-drop to reorder"
                        style={{ cursor: 'grab' }}
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      Pack
                    </td>
                    <td>8</td>
                    <td>&nbsp;</td>
                    <td align="center">
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                  {addNew && (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-xs mg-r-5 pd-0 tx-color-03"
                            aria-label="Drag-and-drop to reorder"
                            style={{ cursor: 'grab' }}
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <input type="text" className="form-control" defaultValue={input} />
                        </div>
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>&nbsp;</td>
                      <td align="center">
                        <button
                          type="button"
                          className="btn btn-icon pd-0"
                          onClick={() => setAddNew(false)}
                        >
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex align-items-center pd-10 bd-b">
              <input
                type="text"
                className="form-control mg-r-5"
                placeholder="Unit Name"
                value={input}
                onInput={(e: any) => setInput(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-primary flex-shrink-0"
                onClick={() => setAddNew(true)}
              >
                <Plus className="mg-r-5" />
                Add
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitDetail;
