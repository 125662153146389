import { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';

// import { PopoverBody, Popover, Button } from 'Crm/components/Bootstraps';

import { Button, Input, Select as SelectHook } from '@base/components/form';
import {
  ArrowUp,
  ChevronDown,
  Copy,
  CornerDownRight,
  HelpCircle,
  Image,
  List,
  Monitor,
  Move,
  PlayCircle,
  RotateCcw,
  RotateCw,
  Trash,
  Type,
  UploadCloud,
  X,
  Edit2,
} from 'react-feather';

const DESIGNTABS = [
  {
    value: 1,
    label: 'Designer',
  },
  {
    value: 2,
    label: 'HTML',
  },
  {
    value: 3,
    label: 'Preview',
  },
];

const ActivityEmail = (props: any) => {
  const [designTab, setDesignTab] = useState(DESIGNTABS[0]);
  const [showSetABTest, setShowSetABTest] = useState<any>(true);

  const [senderType, setSenderType] = useState<any>('single');
  const handleSenderTypeChange = (e: any) => {
    const { value } = e.target;
    setSenderType(value);
  };

  const [popoverOpen, setPopoverOpen] = useState<any>(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div
      className={classnames('overflow-auto', {
        'pd-15': props.value != 3,
      })}
    >
      {props.value == 1 && (
        <>
          <div className="form-group">
            <label className="form-item-title d-block">Send Schedule</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="sendScheduleRadio1"
                name="sendScheduleRadio"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="sendScheduleRadio1">
                Send now
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                id="sendScheduleRadio2"
                name="sendScheduleRadio"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="sendScheduleRadio2">
                Schedule
              </label>
            </div>
            <div className="d-flex align-items-center mg-t-10">
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="sendBatch" />
                <label className="custom-control-label" htmlFor="sendBatch">
                  Send in Batches
                </label>
              </div>
              : interval <input type="number" className="form-control wd-100 mg-x-10" />
              Minute(s)
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Expenses to include in Marketing Investment</label>
            <div className="table-responsive bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 basic-table">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Expense Name
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Expense
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Return on Marketing Investment</label>
            <SelectHook
              className="wd-300-f"
              options={[
                { value: 1, label: 'Gross Profit' },
                { value: 2, label: 'Customer Lifetime Value' },
              ]}
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Key Performance Indicator</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon mg-l-auto rounded-0"
                icon="Plus"
              />
            </div>
            <div className="d-flex align-items-center pd-10 bd rounded">
              Click through rate <Input type="number" className="wd-100 mg-x-10" />%
            </div>
            {/* <div className="bd rounded-top">
              <div className="d-flex align-items-center pd-10">
                Click through rate
                <input type="number" className="form-control wd-100 mg-x-10" />%
                <button type="button" className="btn btn-icon mg-l-auto pd-y-0">
                  <X className="tx-danger" />
                </button>
              </div>
              <div className="d-flex align-items-center pd-10 bd-t">
                Email response rate <input type="number" className="form-control wd-100 mg-x-10" />%
                <button type="button" className="btn btn-icon mg-l-auto pd-y-0">
                  <X className="tx-danger" />
                </button>
              </div>
            </div>
            <div className="input-group bd bd-t-0 rounded-bottom">
              <input
                type="text"
                className="form-control form-control-lg bd-0"
                placeholder="Type or click to select a indicator"
              />
              <div className="input-group-append">
                <button type="button" className="btn bd-0">
                  <ChevronDown />
                  <span className="sr-only">선택 팝업 열기</span>
                </button>
              </div>
            </div> */}
          </div>
        </>
      )}
      {props.value == 2 && (
        <>
          <div className="form-group">
            <label className="form-item-title d-block">Sender</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="senderTypeOption"
                id="senderTypeOption1"
                value="single"
                checked={senderType === 'single'}
                onChange={(e) => handleSenderTypeChange(e)}
              />
              <label className="custom-control-label" htmlFor="senderTypeOption1">
                Single address
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="senderTypeOption"
                id="senderTypeOption2"
                value="crm"
                checked={senderType === 'crm'}
                onChange={(e) => handleSenderTypeChange(e)}
              />
              <label className="custom-control-label" htmlFor="senderTypeOption2">
                CRM owner
              </label>
            </div>
          </div>
          {senderType === 'single' && (
            <div className="form-group">
              <label className="form-item-title">Sender Name</label>
              <SelectHook className="wd-400-f" />
            </div>
          )}
          {senderType === 'crm' && (
            <>
              <div className="form-group">
                <label className="form-item-title">Role</label>
                <SelectHook className="wd-300-f" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Email Address</label>
                <input type="email" className="form-control" />
              </div>
            </>
          )}
          <div className="form-group">
            <label className="form-item-title">Reply Tracking</label>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="replyTrackingSwitch"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="replyTrackingSwitch"></label>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Reply-to</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="replyToOption"
                id="replyToOption1"
                value="single"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="replyToOption1">
                Single address
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="replyToOption"
                id="replyToOption2"
                value="crm"
              />
              <label className="custom-control-label" htmlFor="replyToOption2">
                CRM owner
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="form-group">
              <label className="form-item-title">Attachment</label>
              <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
                <UploadCloud size="20" className="mg-r-10" />
                Drag &amp; Drop Files
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-group">
              <label className="form-item-title">Foot</label>
              <textarea rows={5} cols={10} className="form-control"></textarea>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">
              Allow A/B Test
              <button type="button" id="abTest" className="mg-l-10 btn btn-link pd-0">
                <HelpCircle />
              </button>
              {/* <Popover placement="top" isOpen={popoverOpen} target="abTest" toggle={toggle}>
                <PopoverBody>
                  <p className="wd-300 pd-10">
                    If Checked, recipients will be mailed only once for the whole campaign.
                    <br />
                    This let you send different mailings to randomly selected recipients and test
                    the effectiveness of the mailings without causing duplicate messages.
                  </p>
                </PopoverBody>
              </Popover> */}
            </label>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="emailABTestSwitch"
                checked={showSetABTest}
                onChange={() => setShowSetABTest(!showSetABTest)}
              />
              <label className="custom-control-label" htmlFor="emailABTestSwitch"></label>
            </div>
          </div>
          {showSetABTest && (
            <>
              <div className="form-group">
                <label className="form-item-title d-block">Specific Context</label>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="abTestOption1"
                    id="abTestOption1-1"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="abTestOption1-1">
                    On Content
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="abTestOption1"
                    id="abTestOption1-2"
                  />
                  <label className="custom-control-label" htmlFor="abTestOption1-2">
                    On Subject
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title d-block">Winning Strategy</label>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="abTestOption2"
                    id="abTestOption2-1"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="abTestOption2-1">
                    Best open rate
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="abTestOption2"
                    id="abTestOption2-2"
                  />
                  <label className="custom-control-label" htmlFor="abTestOption2-2">
                    Best click through rate
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title d-block">Breakdown for each variant</label>
                <ul className="list-unstyled mx-wd-400">
                  <li className="d-flex align-items-center mg-b-10">
                    Variant A
                    <input
                      type="number"
                      className="form-control wd-100 mg-l-auto mg-r-10"
                      defaultValue="10"
                    />
                    %
                  </li>
                  <li className="d-flex align-items-center mg-b-10">
                    Variant B <strong className="mg-l-auto mg-r-10 tx-orange">10</strong>%
                  </li>
                  <li className="d-flex align-items-center mg-b-10">
                    Remaining <strong className="mg-l-auto mg-r-10 tx-danger">80</strong>%
                  </li>
                  <li className="d-flex align-items-center">
                    Test duration
                    <input type="number" className="form-control wd-100 mg-x-10" />
                    <SelectHook className="wd-150-f" options={[{ value: 1, label: 'day(s)' }]} />
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      )}
      {props.value == 3 && (
        <div className="row row-xs mg-0 ht-100p">
          <div className="col-lg-6 d-flex flex-column ht-100p">
            <ul className="nav nav-line bd-1">
              <li className="nav-item">
                <button
                  type="button"
                  data-target="#myTemp"
                  className="btn nav-link pd-x-10-f active"
                  data-toggle="tab"
                >
                  My
                </button>
              </li>
              <li className="nav-item mg-l-0-f">
                <button
                  type="button"
                  data-target="#tempType"
                  className="btn nav-link pd-x-10-f"
                  data-toggle="tab"
                >
                  Type
                </button>
              </li>
            </ul>
            <div className="tab-content pd-x-10 overflow-auto">
              <div id="myTemp" className="tab-pane fade show active">
                <div className="card-columns temp-columns">
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">Blank</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">My 1</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">My 2</div>
                    <button type="button" className="btn btn-select active">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">My 3</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                </div>
              </div>
              <div id="tempType" className="tab-pane fade">
                <SelectHook
                  className="wd-200-f mg-t-10"
                  options={[{ value: 1, label: 'All(20)' }]}
                />
                <div className="card-columns temp-columns">
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">AAA</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">BBB</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex flex-column ht-100p">
            <div className="pd-y-9">Preview</div>
            <div className="flex-grow-1 overflow-auto mg-b-10 pd-10 bd"></div>
          </div>
        </div>
      )}
      {props.value == 4 && (
        <div className="d-flex flex-column ht-100p">
          <div className="form-row">
            <div className="form-col col-lg-6 form-group">
              <label className="form-item-title">Title</label>
              {showSetABTest ? (
                <div className="row row-xs">
                  <div className="col-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text lh-1">A</span>
                      </div>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text lh-1">B</span>
                      </div>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              ) : (
                <input type="text" className="form-control" />
              )}
            </div>
            <div className="form-col col-lg-6 form-group">
              <label className="form-item-title">A/B Test</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="landingpageABSwitch"
                  checked={showSetABTest}
                  onChange={() => setShowSetABTest(!showSetABTest)}
                />
                <label className="custom-control-label" htmlFor="landingpageABSwitch"></label>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 bd rounded overflow-auto">
            <div className="row no-gutters ht-100p overflow-auto">
              <div
                className={classnames(
                  'd-flex flex-column ht-100p',
                  { 'col-lg-7 col-xl-8': designTab.value == 1 },
                  { 'col-12': designTab.value != 1 },
                )}
              >
                <div className="d-flex">
                  {/* tabs */}
                  <ul className="nav nav-line bd-1 flex-grow-1">
                    {DESIGNTABS.map((item, index) => (
                      <li className="nav-item" key={index}>
                        <button
                          type="button"
                          className={classnames('btn nav-link pd-x-10-f', {
                            active: item.value == designTab.value,
                          })}
                          onClick={(e) => {
                            setDesignTab(item);
                          }}
                        >
                          {item.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                  {showSetABTest && (
                    <ul className="nav nav-tabs align-self-end pd-r-10">
                      <li className="nav-item">
                        <button type="button" className="nav-link active" data-toggle="tab">
                          A
                        </button>
                      </li>
                      <li className="nav-item">
                        <button type="button" className="nav-link" data-toggle="tab">
                          B
                        </button>
                      </li>
                    </ul>
                  )}
                  {designTab.value == 1 && (
                    <div className="btn-group bd-b">
                      <button
                        type="button"
                        data-han-tooltip="Undo"
                        className="btn btn-icon han-tooltip"
                      >
                        <RotateCcw />
                        <span className="sr-only">실행취소</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Redo"
                        className="btn btn-icon han-tooltip"
                      >
                        <RotateCw />
                        <span className="sr-only">재실행</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Clear Canvas"
                        className="btn btn-icon han-tooltip"
                      >
                        <Trash />
                        <span className="sr-only">초기화</span>
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex-grow-1 overflow-auto">
                  {designTab.value == 1 && (
                    <>
                      <div
                        className="design-ele design-ele-img selected"
                        style={{ margin: '40px 15px' }}
                      >
                        <div className="design-ele-label">Section 1</div>
                        <div className="design-ele-toolbar">
                          <button
                            type="button"
                            data-han-tooltip="Parent"
                            className="btn btn-sm btn-icon han-tooltip"
                          >
                            <ArrowUp />
                            <span className="sr-only">상위</span>
                          </button>
                          <button
                            type="button"
                            data-han-tooltip="Move"
                            className="btn btn-sm btn-icon han-tooltip"
                          >
                            <Move />
                            <span className="sr-only">이동</span>
                          </button>
                          <button
                            type="button"
                            data-han-tooltip="Copy"
                            className="btn btn-sm btn-icon han-tooltip"
                          >
                            <Copy />
                            <span className="sr-only">복사</span>
                          </button>
                          <button
                            type="button"
                            data-han-tooltip="Remove"
                            className="btn btn-sm btn-icon han-tooltip"
                          >
                            <Trash />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="design-ele-cont-initial tx-gray-400">
                          <Image size={40} strokeWidth={1.5} />
                          <div>Select an image</div>
                        </div>
                      </div>
                    </>
                  )}
                  {designTab.value == 2 && <div></div>}
                  {designTab.value == 3 && (
                    <div className="d-flex ht-100p">
                      <div className="wd-80 flex-shrink-0 design-preview-btns overflow-auto">
                        <button type="button" className="btn wd-100p ht-80 bd-b">
                          <div>Desktop</div>
                          <Monitor />
                        </button>
                        <button type="button" className="btn wd-100p ht-80 bd-b active">
                          <div>Tablet</div>
                          {Icon('tablet')}
                        </button>
                        <button type="button" className="btn wd-100p ht-80">
                          <div>Mobile</div>
                          {Icon('smartphone')}
                        </button>
                      </div>
                      <div className="flex-grow-1 overflow-auto pd-20 bd-l">
                        {/* Tablet */}
                        <div
                          className="shadow"
                          style={{
                            width: '828px',
                            height: '1084px',
                            margin: '0 auto',
                            padding: '30px',
                            borderRadius: '15px',
                            border: '1px solid #cccccc',
                          }}
                        >
                          <div className="ht-100p overflow-auto bd"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {designTab.value == 1 && (
                <div className="col-lg-5 col-xl-4 d-flex flex-column ht-100p bd-l">
                  <ul className="nav nav-line bd-1">
                    <li className="nav-item">
                      <button
                        type="button"
                        data-target="#designToolbox"
                        className="btn nav-link pd-x-10-f active"
                        data-toggle="tab"
                      >
                        Toolbox
                      </button>
                    </li>
                    <li className="nav-item mg-l-0-f">
                      <button
                        type="button"
                        data-target="#designProperties"
                        className="btn nav-link pd-x-10-f"
                        data-toggle="tab"
                      >
                        Properties
                      </button>
                    </li>
                    <li className="nav-item mg-l-0-f">
                      <button
                        type="button"
                        data-target="#designStyle"
                        className="btn nav-link pd-x-10-f"
                        data-toggle="tab"
                      >
                        Styles
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content flex-grow-1 overflow-auto">
                    <div id="designToolbox" className="tab-pane fade show active">
                      <button
                        type="button"
                        className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                        data-toggle="collapse"
                        data-target="#designLayout"
                      >
                        Layout
                        <ChevronDown className="mg-l-auto" />
                      </button>
                      <div id="designLayout" className="collapse fade show bd-b">
                        <div className="design-toolbox pd-5">
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('column1')}
                            <div className="ele-name">1 Column</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('column2')}
                            <div className="ele-name">2 Columns</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('column3')}
                            <div className="ele-name">3 Columns</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('column2_2')}
                            <div className="ele-name">3:7 Columns</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('column2_3')}
                            <div className="ele-name">7:3 Columns</div>
                          </button>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                        data-toggle="collapse"
                        data-target="#designElement"
                      >
                        Elements
                        <ChevronDown className="mg-l-auto" />
                      </button>
                      <div id="designElement" className="collapse fade show">
                        <div className="design-toolbox pd-5">
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('heading')}
                            <div className="ele-name">Heading</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            <Type />
                            <div className="ele-name">Text</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            <Image />
                            <div className="ele-name">Image</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            <PlayCircle />
                            <div className="ele-name">Video</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('button')}
                            <div className="ele-name">Button</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('divider')}
                            <div className="ele-name">Divider</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('table')}
                            <div className="ele-name">Table</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            <List />
                            <div className="ele-name">List</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('cta')}
                            <div className="ele-name">CTA</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('personalize_code')}
                            <div className="ele-name">Personalize</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('form')}
                            <div className="ele-name">Form</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('event')}
                            <div className="ele-name">Event</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('survey')}
                            <div className="ele-name">Survey</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('option_set')}
                            <div className="ele-name">Option Set</div>
                          </button>
                          <button type="button" className="btn btn-white shadow-sm">
                            {Icon('social_share')}
                            <div className="ele-name">Social Share</div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="designProperties" className="tab-pane fade">
                      {/* image type property */}
                      <button
                        type="button"
                        className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                        data-toggle="collapse"
                        data-target="#designIMG"
                      >
                        Image
                        <ChevronDown className="mg-l-auto" />
                      </button>
                      <div id="designIMG" className="collapse fade show pd-15">
                        <div className="form-group">
                          <label className="form-item-title">Source</label>
                          <div className="input-group">
                            <input type="text" className="form-control" />
                            <div className="input-group-append">
                              <button type="button" className="btn">
                                <Image />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Alt Text</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                    </div>
                    <div id="designStyle" className="tab-pane fade"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {props.value == 5 && (
        <>
          <div className="form-group-title mg-t-0-f">CTA embedded</div>
          <div className="form-group">
            <label className="form-item-title d-block">Preview</label>
            <img src="assets/images/cta-sample-2.png" alt="" style={{ width: '300px' }} />
          </div>
          <div className="form-group">
            <label className="form-item-title">Type</label>
            <div>Image</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">CTA Name</label>
            <div>CTA 1</div>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Personalized URL</label>
            <div className="d-flex">
              <CornerDownRight strokeWidth={1} />
              <div className="wd-100p">
                <div className="card bg-light mg-b-15">
                  <div className="card-header pd-y-10">Link</div>
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-item-title">Landing Page/Site</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Type</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Page Title</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group-title mg-t-0">Embedded CTA on this page</div>
                    <div className="form-group">
                      <label className="form-item-title">Type</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Name</label>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <CornerDownRight strokeWidth={1} />
                  <div className="wd-100p">
                    <div className="card bg-light">
                      <div className="card-header pd-y-10">Link</div>
                      <div className="card-body">
                        <div className="form-group">
                          <label className="form-item-title">Offer Site</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Page Title</label>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {props.value == 6 && (
        <>
          <ol className="conversion-process">
            <li>
              <div className="d-flex flex-column align-items-center">
                <div className="tx-semibold">MQL</div>
                <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="conversionSwitch1" />
                  <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex flex-column align-items-center">
                <div className="tx-semibold">SQL</div>
                <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                  Account/Contact Created
                </div>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="conversionSwitch2" />
                  <label className="custom-control-label" htmlFor="conversionSwitch2"></label>
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex flex-column align-items-center">
                <div className="tx-semibold">Opportunity</div>
                <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                  Opprtunity Created
                </div>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="conversionSwitch3" />
                  <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                </div>
              </div>
            </li>
            <li>
              <div className="d-flex flex-column align-items-center">
                <div className="tx-semibold">Sales</div>
                <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Make Payment</div>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="conversionSwitch4" />
                  <label className="custom-control-label" htmlFor="conversionSwitch4"></label>
                </div>
              </div>
            </li>
          </ol>
        </>
      )}
      {props.value == 7 && (
        <>
          <div className="d-flex align-items-center mg-b-10">
            <span className="tx-semibold">Business Process</span>
            <SelectHook
              className="wd-200-f mg-l-10"
              options={[{ value: 1, label: 'Campaign - General' }]}
            />
            <button type="button" className="btn btn-link mg-l-auto">
              <Edit2 className="mg-r-5" />
              Edit
            </button>
          </div>
          <div className="card card-body"></div>
          <div className="d-flex align-items-center mg-t-30 mg-b-10">
            <span className="tx-semibold">Journey Process</span>
            <SelectHook className="wd-200-f mg-l-10" />
            <button type="button" className="btn btn-link mg-l-auto">
              <Edit2 className="mg-r-5" />
              Edit
            </button>
          </div>
          <div className="card card-body"></div>
        </>
      )}
    </div>
  );
};

export default ActivityEmail;
