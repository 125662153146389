import { Button } from '@base/components/form';
import ProductDetailView from '../containers/product-detail/view';
const PurchaseReturn = () => {
  return (
    <div className="pd-20">
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button color="link" className="mg-l-auto" icon="Edit2" iconClass="mg-r-5" name="Edit" />
          <Button
            color="link"
            className="mg-l-5"
            icon="preview"
            iconType="custom"
            iconClass="mg-r-5"
            name="Preview"
          />
        </div>
        <ProductDetailView />
        <div className="form-group">
          <label className="form-item-title">Vendor Note</label>
          <div>Please use the following communication for your payment : PB/2022/05/09</div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseReturn;
