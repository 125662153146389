import { Plus } from 'react-feather';

const Timeline = () => {
  return (
    <div className="pd-15">
      <div className="new-timeline-group" style={{ maxHeight: '283px', overflow: 'auto' }}>
        <div className="new-timeline-header mg-t-0-f">
          <div className="new-timeline-label">2021-03-17</div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Plus />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  Event <strong>created</strong> by MSR
                </span>
                <span className="mg-l-auto tx-color-03">09:42</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
