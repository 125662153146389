import { useState } from 'react';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';
import { Calendar, ChevronDown, ChevronUp, Plus, User, X } from 'react-feather';
import SelectHook from '@base/components/form/select-hook';

import KnowledgeBaseList from './kb-list';

const TaskWrite = () => {
  const [taskType, setTaskType] = useState('manual');
  const handleTaskTypeChange = (e: any) => {
    const { value } = e.target;
    setTaskType(value);
  };

  const [showTemplateOption, setShowTemplateOption] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);

  return (
    <>
      <div id="newTaskModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Create Task</h1>
              <button type="button" className="close" data-dismiss="modal" data-han-tooltip="close">
                <X />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-item-title">Task Type</label>
                <div>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="taskTypeRadio1"
                      name="taskTypeRadio"
                      className="custom-control-input"
                      value="manual"
                      checked={taskType === 'manual'}
                      onChange={(e) => handleTaskTypeChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="taskTypeRadio1">
                      Manual
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="taskTypeRadio2"
                      name="taskTypeRadio"
                      className="custom-control-input"
                      value="checklist"
                      checked={taskType === 'checklist'}
                      onChange={(e) => handleTaskTypeChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="taskTypeRadio2">
                      Check list
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block">
                    <input
                      type="radio"
                      id="taskTypeRadio3"
                      name="taskTypeRadio"
                      className="custom-control-input"
                      value="sequence"
                      checked={taskType === 'sequence'}
                      onChange={(e) => handleTaskTypeChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="taskTypeRadio3">
                      Sequence
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Subject</label>
                <input type="text" className="form-control form-control-lg" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Assigned Rep</label>
                <div className="dropdown">
                  <div className="input-group" data-toggle="dropdown">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">John</span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          data-han-tooltip="delete"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        data-han-tooltip="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">담당자 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                  <div className="dropdown-menu wd-100p">
                    <button type="button" className="dropdown-item">
                      Assign to me
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Priority</label>
                <SelectHook className="wd-300-f" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Customer</label>
                <div className="input-group">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="d-block avatar avatar-xs mg-r-10">
                        <span className="avatar-initial rounded-circle">S</span>
                      </span>
                      <span className="form-control-tag-name">Socrates</span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        data-han-tooltip="delete"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250"
                      placeholder="Type or click to select a customer"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      data-han-tooltip="open modal to select a customer"
                    >
                      {Icons('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Purpose</label>
                <div className="wd-50p">
                  <SelectHook />
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Expected Duration</label>
                <div className="d-flex align-items-center">
                  <input type="number" className="form-control wd-150 mg-r-5" />
                  <div className="input-group dropdown wd-150">
                    <input type="text" className="form-control" defaultValue="day(s)" />
                    <div className="input-group-append">
                      <button type="button" className="btn">
                        <ChevronDown />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Due Date</label>
                <div className="wd-300">{/* <CalendarHook /> */}</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Template</label>
                <div className="wd-50p">
                  <SelectHook
                    options={[
                      { value: 1, label: 'No Template' },
                      { value: 2, label: 'Template 1' },
                      { value: 3, label: 'Template 2' },
                    ]}
                  />
                </div>
              </div>
              {/* Task type : Manual */}
              {taskType === 'manual' && (
                <div className="form-group">
                  <label className="form-item-title">Detail</label>
                  <div>Editor</div>
                </div>
              )}

              {/* Task type : Check list */}
              {taskType === 'checklist' && (
                <div className="form-group">
                  <label className="form-item-title">Check list</label>
                  <div className="table-responsive bd rounded-top">
                    <table className="table mg-b-0">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Title
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Description
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Duration
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Instruntion
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type="text" className="form-control mn-wd-200" />
                          </td>
                          <td>
                            <input type="text" className="form-control mn-wd-200" />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <input type="number" className="form-control wd-100 mg-r-5" />
                              <div className="input-group dropdown wd-150">
                                <input type="text" className="form-control" defaultValue="day(s)" />
                                <div className="input-group-append">
                                  <button type="button" className="btn">
                                    <ChevronDown />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-link"
                              data-toggle="modal"
                              data-target="#newInstructionModal"
                            >
                              Detail
                            </button>
                          </td>
                          <td>
                            <button type="button" className="btn tx-danger">
                              <X />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                    <input
                      type="text"
                      className="form-control mg-r-5"
                      placeholder="Enter the title"
                    />
                    <button type="button" className="btn btn-primary flex-shrink-0">
                      <Plus className="mg-r-5" />
                      Add
                    </button>
                  </div>
                </div>
              )}

              {/* Task type : Sequence */}
              {taskType === 'sequence' && (
                <div className="form-group">
                  <label className="form-item-title">Sequence</label>
                  <div className="table-responsive bd rounded">
                    <table className="table mg-b-0">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Sequence
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Title
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Description
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Duration
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Instruntion
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>#1</td>
                          <td>
                            <input type="text" className="form-control mn-wd-200" />
                          </td>
                          <td>
                            <input type="text" className="form-control mn-wd-200" />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <input type="number" className="form-control wd-100 mg-r-5" />
                              <div className="input-group dropdown wd-150">
                                <input type="text" className="form-control" defaultValue="day(s)" />
                                <div className="input-group-append">
                                  <button type="button" className="btn">
                                    <ChevronDown />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-link"
                              data-toggle="modal"
                              data-target="#newInstructionModal"
                            >
                              Detail
                            </button>
                          </td>
                          <td>
                            <button type="button" className="btn tx-danger">
                              <X />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                    <input
                      type="text"
                      className="form-control mg-r-5"
                      placeholder="Enter the title"
                    />
                    <button type="button" className="btn btn-primary flex-shrink-0">
                      <Plus className="mg-r-5" />
                      Add
                    </button>
                  </div>
                </div>
              )}

              <div className="form-group">
                <label className="form-item-title">Tags</label>
                <div className="form-control form-control-lg d-flex flex-wrap pd-0-f form-control-tags">
                  <div className="form-control-tag">
                    <span className="form-control-tag-name">postmaster</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      data-han-tooltip="delete"
                    >
                      <X />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 mn-wd-250 rounded"
                    placeholder="Type or click to select a tag"
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-item-title">Knowledge Base</label>
                <div className="text-right">
                  <button type="button" className="btn btn-link pd-x-0">
                    <span className="tx-danger">Delete all</span>
                  </button>
                </div>
                <ul className="list-group task-kb-list">
                  <li className="list-group-item pd-r-0">
                    <div className="d-flex align-items-center">
                      How to assess admin page?
                      <div className="mg-l-auto">
                        <span className="tag-item">postmaster</span>
                        <span className="tag-item">access</span>
                        <button type="button" className="btn pd-y-0" data-han-tooltip="delete">
                          <X className="tx-danger" />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item pd-r-0">
                    <div className="d-flex align-items-center">
                      How to add an user?
                      <div className="mg-l-auto">
                        <span className="tag-item">postmaster</span>
                        <button type="button" className="btn pd-y-0" data-han-tooltip="delete">
                          <X className="tx-danger" />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="input-group mg-t-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type or click to select an article"
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#KBlistModal"
                    >
                      {Icons('helpdesk')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="taskTemplateCheck"
                  checked={showTemplateOption}
                  onChange={() => setShowTemplateOption(!showTemplateOption)}
                />
                <label className="custom-control-label" htmlFor="taskTemplateCheck">
                  Save as a template
                </label>
              </div>
              {showTemplateOption && (
                <div className="mg-t-10 mg-l-25 pd-x-20 pd-y-10 rounded bg-light">
                  <div className="form-group">
                    <label className="form-item-title">Task Template Name</label>
                    <input type="text" className="form-control" />
                  </div>

                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              )}
              <hr className="invisible mg-y-10" />

              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="repeatTaskSwitch"
                  checked={showRepeat}
                  onChange={() => setShowRepeat(!showRepeat)}
                />
                <label className="custom-control-label" htmlFor="repeatTaskSwitch">
                  Repeat
                </label>
              </div>
              {showRepeat && (
                <div className="mg-t-10 mg-l-25 pd-x-20 pd-y-10 rounded bg-light">
                  <div className="form-group">
                    <label className="form-item-title">Type</label>
                    <div className="wd-300 mg-b-10">
                      <SelectHook
                        options={[
                          { value: 1, label: 'Daily' },
                          { value: 2, label: 'Weekly' },
                          { value: 3, label: 'Monthly' },
                          { value: 4, label: 'Yearly' },
                        ]}
                      />
                    </div>

                    {/* for weekly */}
                    <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                      <input type="checkbox" className="custom-control-input" id="weekDayCheck1" />
                      <label className="custom-control-label" htmlFor="weekDayCheck1">
                        Sunday
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                      <input type="checkbox" className="custom-control-input" id="weekDayCheck2" />
                      <label className="custom-control-label" htmlFor="weekDayCheck2">
                        Monday
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                      <input type="checkbox" className="custom-control-input" id="weekDayCheck3" />
                      <label className="custom-control-label" htmlFor="weekDayCheck3">
                        Tuesday
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                      <input type="checkbox" className="custom-control-input" id="weekDayCheck4" />
                      <label className="custom-control-label" htmlFor="weekDayCheck4">
                        Wednesday
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                      <input type="checkbox" className="custom-control-input" id="weekDayCheck5" />
                      <label className="custom-control-label" htmlFor="weekDayCheck5">
                        Thursday
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                      <input type="checkbox" className="custom-control-input" id="weekDayCheck6" />
                      <label className="custom-control-label" htmlFor="weekDayCheck6">
                        Friday
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                      <input type="checkbox" className="custom-control-input" id="weekDayCheck7" />
                      <label className="custom-control-label" htmlFor="weekDayCheck7">
                        Saturday
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Frequency</label>
                    <div className="input-group wd-200">
                      <input type="number" className="form-control" min="0" />
                      <div className="input-group-append">
                        <span className="input-group-text lh-1">time(s)</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">End Date</label>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="endDateRadio1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="endDateRadio1">
                        No end date
                      </label>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="custom-control custom-radio d-inline-block mg-r-10">
                        <input type="radio" className="custom-control-input" id="endDateRadio1" />
                        <label className="custom-control-label" htmlFor="endDateRadio1">
                          End by
                        </label>
                      </div>
                      <div className="input-group input-date-group wd-300">
                        <input type="text" className="form-control" />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <Calendar />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Cancel
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <KnowledgeBaseList />
    </>
  );
};

export default TaskWrite;
