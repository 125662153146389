import { ChevronUp, X } from 'react-feather';

import Write from '../write';

const NewAdjustment = () => {
  return (
    <div id="newAdjustment" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content rounded-0">
          <div className="modal-header flex-column pd-0">
            <div className="d-flex wd-100p pd-15">
              <h1 className="modal-title">Create Adjustment</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <Write />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light mg-l-auto" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAdjustment;
