import React, { useState } from 'react';

import { Calendar, ChevronDown, Minus, Plus, Settings, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "../../../../scss/desktop/theme1/theme1.scss";
import '../style.scss';

const Write = () => {
  const [discountWay, setDiscountWay] = useState<any>('line');
  const handleDiscountWayChange = (e: any) => {
    const { value } = e.target;
    setDiscountWay(value);
  };

  const [discountType, setDiscountType] = useState<any>('simple');
  const handleDiscountTypeChange = (e: any) => {
    const { value } = e.target;
    setDiscountType(value);
  };

  const [discountMethod, setDiscountMethod] = useState<any>({
    percentage: true,
    amount: false,
  });
  const [allCustomer, setAllCustomer] = useState<any>(true);
  const [specificCustomer, setSpecificCustomer] = useState<any>();

  return (
    <div className="form-row">
      <div className="form-col col-12 form-group">
        <label className="form-item-title d-block">Discount way in Order Detail</label>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="discountWayRadio1"
            name="discountWayRadio"
            className="custom-control-input"
            value="line"
            checked={discountWay === 'line'}
            onChange={(e) => handleDiscountWayChange(e)}
          />
          <label className="custom-control-label" htmlFor="discountWayRadio1">
            Line
          </label>
        </div>
        <div className="custom-control custom-radio d-inline-block">
          <input
            type="radio"
            id="discountWayRadio2"
            name="discountWayRadio"
            className="custom-control-input"
            value="subtotal"
            checked={discountWay === 'subtotal'}
            onChange={(e) => handleDiscountWayChange(e)}
          />
          <label className="custom-control-label" htmlFor="discountWayRadio2">
            Sub Total
          </label>
        </div>
      </div>

      <div className="form-col col-12 form-group">
        <label className="form-item-title d-block">Discount Type</label>

        {discountWay === 'line' && (
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="discountWayVolumeDiscount1"
              name="discountWayVolumeDiscount"
              className="custom-control-input"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="discountWayVolumeDiscount1">
              Volume Discount
            </label>
          </div>
        )}

        {discountWay === 'subtotal' && (
          <>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="discountWaySubtotalDiscount1"
                name="discountWaySubtotalDiscount"
                className="custom-control-input"
                value="simple"
                checked={discountType === 'simple'}
                onChange={(e) => handleDiscountTypeChange(e)}
              />
              <label className="custom-control-label" htmlFor="discountWaySubtotalDiscount1">
                Simple Discount
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                id="discountWaySubtotalDiscount2"
                name="discountWaySubtotalDiscount"
                className="custom-control-input"
                value="threshold"
                checked={discountType === 'threshold'}
                onChange={(e) => handleDiscountTypeChange(e)}
              />
              <label className="custom-control-label" htmlFor="discountWaySubtotalDiscount2">
                Treshold Discount
              </label>
            </div>
          </>
        )}
      </div>

      {discountWay === 'subtotal' && (
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Discount Name</label>
          <input type="text" className="form-control" />
        </div>
      )}

      <div className="form-col col-lg-6 form-group">
        <label className="d-block form-item-title">Discount ID</label>
        <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <input type="text" className="form-control" />
          <div className="input-group-append">
            <button
              type="button"
              className="btn"
              aria-label="open generator of code modal"
              data-toggle="modal"
              data-target="#productGenCodeModal"
            >
              <Settings />
              <span className="sr-only">코드 생성 설정 열기</span>
            </button>
          </div>
        </div>
      </div>

      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title d-block">Start Date</label>
        <div className="input-group input-date-group wd-300">
          <input type="text" className="form-control" />
          <div className="input-group-append">
            <span className="input-group-text">
              <Calendar />
            </span>
          </div>
        </div>
      </div>

      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title d-block">End Date</label>
        <div className="input-group input-date-group wd-300">
          <input type="text" className="form-control" />
          <div className="input-group-append">
            <span className="input-group-text">
              <Calendar />
            </span>
          </div>
        </div>
        <div className="custom-control custom-switch mg-t-5">
          <input type="checkbox" className="custom-control-input" id="no-end-date" />
          <label className="custom-control-label" htmlFor="no-end-date">
            No End Date
          </label>
        </div>
      </div>

      {discountWay === 'line' && (
        <div className="form-col col-12 form-group">
          <label className="form-item-title d-block">Discount Detail</label>
          <div className="table-responsive bd rounded-top">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Item Name
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Unit
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Quantity
                  </th>
                  <th scope="col" colSpan={2} className="bd-t-0-f">
                    Discount
                  </th>
                  <th scope="col" className="wd-70 bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}>
                    <div className="input-group wd-250">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to select an item"
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open selecting item modal"
                        >
                          {Icon('product')}
                        </button>
                      </div>
                    </div>
                  </td>
                  <td rowSpan={2}>
                    <input type="text" className="form-control wd-100" />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <input type="number" className="form-control wd-150" />
                      <span className="mg-x-5">~</span>
                      <input type="number" className="form-control wd-150" />
                      <button type="button" className="btn btn-icon pd-0">
                        <Minus className="tx-danger" />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                  </td>
                  <td rowSpan={2}>
                    <div className="dropdown">
                      <div className="input-group wd-150" data-toggle="dropdown">
                        <input
                          type="text"
                          className="form-control"
                          value={discountMethod.percentage ? 'Percentage' : 'Amount'}
                        />
                        <div className="input-group-append">
                          <button type="button" className="btn">
                            <ChevronDown />
                          </button>
                        </div>
                      </div>
                      <div className="dropdown-menu">
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => setDiscountMethod({ percentage: true })}
                        >
                          Percentage
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => setDiscountMethod({ amount: true })}
                        >
                          Amount
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="input-group wd-150">
                      {discountMethod.amount && (
                        <div className="input-group-prepend">
                          <span className="input-group-text lh-1">＄</span>
                        </div>
                      )}
                      <input type="number" className="form-control" />
                      {discountMethod.percentage && (
                        <div className="input-group-append">
                          <span className="input-group-text lh-1">%</span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td rowSpan={2} align="center">
                    <button type="button" className="btn btn-icon pd-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <input type="number" className="form-control wd-150" />
                      <span className="mg-x-5">~</span>
                      <input type="number" className="form-control wd-150" />
                      <button type="button" className="btn btn-icon pd-0">
                        <Minus className="tx-danger" />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <button type="button" className="btn btn-link">
                      <Plus className="mg-r-5" />
                      Add
                    </button>
                  </td>
                  <td>
                    <div className="input-group wd-150">
                      {discountMethod.amount && (
                        <div className="input-group-prepend">
                          <span className="input-group-text lh-1">＄</span>
                        </div>
                      )}
                      <input type="number" className="form-control" />
                      {discountMethod.percentage && (
                        <div className="input-group-append">
                          <span className="input-group-text lh-1">%</span>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-10 bd bd-t-0 rounded-bottom">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type or click to select an item"
              />
              <div className="input-group-append">
                <button type="button" className="btn" aria-label="open selecting item modal">
                  {Icon('product')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {discountWay === 'subtotal' && (
        <div className="form-col col-12 form-group">
          <label className="form-item-title d-block">Amount</label>
          <div className="table-responsive bd rounded">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Amount
                  </th>
                  <th scope="col" colSpan={2} className="bd-t-0-f">
                    Discount
                  </th>
                  <th scope="col" className="wd-70 bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>
                    <div className="dropdown">
                      <div className="input-group wd-150" data-toggle="dropdown">
                        <input
                          type="text"
                          className="form-control"
                          value={discountMethod.percentage ? 'Percentage' : 'Amount'}
                        />
                        <div className="input-group-append">
                          <button type="button" className="btn">
                            <ChevronDown />
                          </button>
                        </div>
                      </div>
                      <div className="dropdown-menu">
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => setDiscountMethod({ percentage: true })}
                        >
                          Percentage
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => setDiscountMethod({ amount: true })}
                        >
                          Amount
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="input-group wd-150">
                      {discountMethod.amount && (
                        <div className="input-group-prepend">
                          <span className="input-group-text lh-1">＄</span>
                        </div>
                      )}
                      <input type="number" className="form-control" />
                      {discountMethod.percentage && (
                        <div className="input-group-append">
                          <span className="input-group-text lh-1">%</span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td align="center">
                    <button type="button" className="btn btn-icon pd-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {discountType === 'threshold' && (
            <button type="button" className="btn btn-link">
              <Plus className="mg-r-5" />
              Add
            </button>
          )}
        </div>
      )}

      <div className="form-col col-12 form-group">
        <label className="form-item-title d-block">Customer</label>
        {allCustomer && (
          <div className="d-inline-block pd-x-15 pd-y-5 bg-gray-100 bd bd-gray-200 rounded-20">
            All Customers
            <button
              type="button"
              className="btn bt-icon mg-l-10 pd-0"
              onClick={() => setAllCustomer(false)}
            >
              <X />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        )}
        {!allCustomer && (
          <>
            <div className="table-responsive bd rounded-top">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Name
                    </th>
                    <th scope="col" className="wd-70 bd-t-0-f">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {specificCustomer && (
                    <tr>
                      <td>
                        <span className="d-inline-block avatar avatar-sm mg-r-5">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                            className="rounded-circle"
                            alt=""
                          />
                        </span>
                        Adventure Works
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-icon pd-0"
                          onClick={() => setSpecificCustomer(false)}
                        >
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pd-10 bd bd-t-0 rounded-bottom">
              <div className="dropdown">
                <div className="input-group" data-toggle="dropdown">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type or click to select a customer"
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a customer"
                    >
                      {Icon('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
                <ul className="dropdown-menu wd-100p">
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => setAllCustomer(true)}
                    >
                      All Customers
                    </button>
                  </li>
                  <li className="dropdown-divider"></li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => setSpecificCustomer(true)}
                    >
                      <span className="d-inline-block avatar avatar-sm mg-r-5">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                          className="rounded-circle"
                          alt=""
                        />
                      </span>
                      Adventure Works
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Write;
