import { useState } from 'react';
import classnames from 'classnames';

import { ArrowRight, CornerDownRight, Settings, UploadCloud, User, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'Basic Info',
  },
  {
    value: 2,
    label: 'Links',
  },
];

const NewPromotionalItem = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [showGuidanceSettings, setShowGuidanceSettings] = useState(false);

  return (
    <div
      id="newPromotionalItemModal"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">New Promotional ITem</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body d-flex flex-column overflow-hidden pd-0">
            {/* steps */}
            <ul className="steps steps-justified steps-tab">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  className={classnames('step-item', {
                    complete: item.value < tab.value,
                    active: item.value == tab.value,
                    disabed: item.value > tab.value,
                  })}
                >
                  <button
                    type="button"
                    className="btn step-link rounded-0"
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    <span className="step-number">{item.value}</span>
                    <span className="step-title">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
            {tab.value == 1 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="form-group">
                  <label className="form-item-title">Item Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Item Type</label>
                  <SelectHook className="wd-300-f" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Sample Image</label>
                  <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
                    <UploadCloud size="20" className="mg-r-10" />
                    Drag &amp; Drop Files
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0xJV22ugqnu5lwu_NoaL1CeW2SZCx4YbuLQ&amp;usqp=CAU"
                            alt=""
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">Product 111</span>
                        <button type="button" className="btn pd-0 form-control-tag-remove">
                          <X />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a product"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a product"
                      >
                        {Icon('product')}
                        <span className="sr-only">상품 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <textarea rows={3} cols={10} className="form-control"></textarea>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Assigned Rep</label>
                  <ul className="list-group">
                    <li className="list-group-item bg-white">
                      <div className="row row-xs align-items-center">
                        <div className="col-lg-2">Planning</div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-radio d-inline-block mg-r-10">
                            <input
                              type="radio"
                              id="PMCAssignRadio1-1"
                              name="PMCAssignRadio1"
                              className="custom-control-input"
                              defaultChecked
                            />
                            <label className="custom-control-label" htmlFor="PMCAssignRadio1-1">
                              In house
                            </label>
                          </div>
                          <div className="custom-control custom-radio d-inline-block">
                            <input
                              type="radio"
                              id="PMCAssignRadio1-2"
                              name="PMCAssignRadio1"
                              className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="PMCAssignRadio1-2">
                              External
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="input-group">
                            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                              <div className="form-control-tag">
                                <span className="d-block avatar avatar-xs mg-r-10">
                                  <img
                                    src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                                    alt="John"
                                    className="img rounded-circle"
                                  />
                                </span>
                                <span className="form-control-tag-name">John</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <input
                                type="text"
                                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                                placeholder="Type or click to select an user"
                              />
                            </div>
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn"
                                aria-label="open modal to select an user"
                              >
                                <User />
                                <span className="sr-only">담당자 선택 팝업 열기</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item bg-white">
                      <div className="row row-xs align-items-center">
                        <div className="col-lg-2">Design</div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-radio d-inline-block mg-r-10">
                            <input
                              type="radio"
                              id="PMCAssignRadio3-1"
                              name="PMCAssignRadio3"
                              className="custom-control-input"
                              defaultChecked
                            />
                            <label className="custom-control-label" htmlFor="PMCAssignRadio3-1">
                              In house
                            </label>
                          </div>
                          <div className="custom-control custom-radio d-inline-block">
                            <input
                              type="radio"
                              id="PMCAssignRadio3-2"
                              name="PMCAssignRadio3"
                              className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="PMCAssignRadio3-2">
                              External
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="input-group">
                            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                              <div className="form-control-tag">
                                <span className="d-block avatar avatar-xs mg-r-10">
                                  <img
                                    src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                                    alt="John"
                                    className="img rounded-circle"
                                  />
                                </span>
                                <span className="form-control-tag-name">John</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <input
                                type="text"
                                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                                placeholder="Type or click to select an user"
                              />
                            </div>
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn"
                                aria-label="open modal to select an user"
                              >
                                <User />
                                <span className="sr-only">담당자 선택 팝업 열기</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item bg-white">
                      <div className="row row-xs align-items-center">
                        <div className="col-lg-2">Confirm</div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-radio d-inline-block mg-r-10">
                            <input
                              type="radio"
                              id="PMCAssignRadio4-1"
                              name="PMCAssignRadio4"
                              className="custom-control-input"
                              defaultChecked
                            />
                            <label className="custom-control-label" htmlFor="PMCAssignRadio4-1">
                              In house
                            </label>
                          </div>
                          <div className="custom-control custom-radio d-inline-block">
                            <input
                              type="radio"
                              id="PMCAssignRadio4-2"
                              name="PMCAssignRadio4"
                              className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="PMCAssignRadio4-2">
                              External
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="input-group">
                            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                              <div className="form-control-tag">
                                <span className="d-block avatar avatar-xs mg-r-10">
                                  <img
                                    src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                                    alt="John"
                                    className="img rounded-circle"
                                  />
                                </span>
                                <span className="form-control-tag-name">John</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <input
                                type="text"
                                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                                placeholder="Type or click to select an user"
                              />
                            </div>
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn"
                                aria-label="open modal to select an user"
                              >
                                <User />
                                <span className="sr-only">담당자 선택 팝업 열기</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item bg-white">
                      <div className="row row-xs align-items-center">
                        <div className="col-lg-2">Order</div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-radio d-inline-block mg-r-10">
                            <input
                              type="radio"
                              id="PMCAssignRadio2-1"
                              name="PMCAssignRadio2"
                              className="custom-control-input"
                              defaultChecked
                            />
                            <label className="custom-control-label" htmlFor="PMCAssignRadio2-1">
                              In house
                            </label>
                          </div>
                          <div className="custom-control custom-radio d-inline-block">
                            <input
                              type="radio"
                              id="PMCAssignRadio2-2"
                              name="PMCAssignRadio2"
                              className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="PMCAssignRadio2-2">
                              External
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="input-group">
                            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                              <div className="form-control-tag">
                                <span className="d-block avatar avatar-xs mg-r-10">
                                  <img
                                    src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                                    alt="John"
                                    className="img rounded-circle"
                                  />
                                </span>
                                <span className="form-control-tag-name">John</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <input
                                type="text"
                                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                                placeholder="Type or click to select an user"
                              />
                            </div>
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn"
                                aria-label="open modal to select an user"
                              >
                                <User />
                                <span className="sr-only">담당자 선택 팝업 열기</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Timeline</label>
                  <div className="table-responsive bd rounded">
                    <table className="table mg-b-0">
                      <tbody>
                        <tr>
                          <th scope="row" className="bd-t-0-f tx-center">
                            Step
                          </th>
                          <td className="bd-t-0-f tx-center">
                            Planning <ArrowRight className="float-right tx-teal op-3" />
                          </td>
                          <td className="bd-t-0-f tx-center">
                            Design <ArrowRight className="float-right tx-teal op-3" />
                          </td>
                          <td className="bd-t-0-f tx-center">
                            Confirm <ArrowRight className="float-right tx-teal op-3" />
                          </td>
                          <td className="bd-t-0-f tx-center">Order</td>
                        </tr>
                        <tr>
                          <th scope="row" className="align-middle">
                            Days
                          </th>
                          <td>
                            <input type="number" className="form-control" />
                          </td>
                          <td>
                            <input type="number" className="form-control" />
                          </td>
                          <td>
                            <input type="number" className="form-control" />
                          </td>
                          <td>
                            <input type="number" className="form-control" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {tab.value == 2 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px)' }}>
                <div className="mg-b-10 tx-semibold">Dedicated Link (Setup Tracking for ROI)</div>
                <div className="form-group-title mg-t-0">CTA embedded</div>
                <div className="form-group">
                  <label className="form-item-title">CTA Name</label>
                  <SelectHook className="wd-300-f" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">URL</label>
                  <div>https://hanbiro.com</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title d-block">QR Code</label>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsip6Dsj3eSrw44sOjmaU1eMpLQNb0MfQXgw&usqp=CAU"
                    alt=""
                    className="img-thumbnail wd-150"
                  />
                </div>
                <div className="d-flex">
                  <CornerDownRight strokeWidth={1} />
                  <div className="wd-100p">
                    <div className="card bg-light mg-b-15">
                      <div className="card-header pd-y-10">Link</div>
                      <div className="card-body">
                        <div className="form-group">
                          <label className="form-item-title">Landing Page/Site</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Page Title</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group-title mg-t-0">Embedded CTA on this page</div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Name</label>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <CornerDownRight strokeWidth={1} />
                      <div className="wd-100p">
                        <div className="card bg-light">
                          <div className="card-header pd-y-10">Link</div>
                          <div className="card-body">
                            <div className="form-group">
                              <label className="form-item-title">Offer Site</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Type</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Page Title</label>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            {tab.value == 1 && (
              <button type="button" className="btn btn-primary">
                Next
              </button>
            )}
            {tab.value == 2 && (
              <button type="button" className="btn btn-success">
                Save
              </button>
            )}
          </div>
        </div>
      </div>
      ;
    </div>
  );
};

export default NewPromotionalItem;
