import { useState } from 'react';

import { Button, Input, Select, Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { Plus, Minus, Clock, Image } from 'react-feather';
import { UserAutoComplete } from '@base/containers';

const ActPaidSocial = (props: any) => {
  const [addSite, setAddSite] = useState<any>();

  const [sendSchedule, setSendSchedule] = useState<any>('now');
  const handleSendScheduleChange = (e: any) => {
    const { value } = e.target;
    setSendSchedule(value);
  };

  const [expenseOption, setExpenseOption] = useState<any>('enter');
  const handleOptionChange = (e: any) => {
    const { value } = e.target;
    setExpenseOption(value);
  };

  return (
    <>
      {props.value == 1 && (
        <div className="d-flex overflow-auto">
          <div className="flex-grow-1">
            <div className="pd-10 bg-light bd-b bd-r text-nowrap">Social Media</div>
            <div className="mg-t-30 pd-10">
              <SelectHook
                className="wd-150-f mg-b-10"
                options={[{ value: 1, label: 'Facebook' }]}
              />
              {addSite && (
                <div className="d-flex align-items-center mg-b-10">
                  <SelectHook className="wd-150-f mg-r-10" />
                  <button type="button" className="btn btn-icon" onClick={() => setAddSite(false)}>
                    <Minus className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              )}
              <button type="button" className="btn btn-link" onClick={() => setAddSite(true)}>
                <Plus className="mg-r-5" />
                Add
              </button>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="pd-10 bg-light bd-b bd-r text-nowrap">Destination</div>
            <div className="d-flex">
              <div className="d-flex flex-column align-items-center">
                <div className="pd-10 text-nowrap">Shorten URL</div>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-icon mg-b-10"
                  data-toggle="modal"
                  data-target="#newShortenUrlSocial"
                >
                  <Plus />
                  <span className="Create Shorten URL"></span>
                </button>
                {addSite && (
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-icon"
                    data-toggle="modal"
                    data-target="#newShortenUrlSocial"
                  >
                    <Plus />
                    <span className="Create Shorten URL"></span>
                  </button>
                )}
              </div>
              <div className="d-flex flex-column">
                <div className="pd-10 text-nowrap">Landing Page/Site</div>
                <div className="d-flex flex-grow-1 align-items-center pd-10">
                  <SelectHook />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="pd-10 bg-light bd-b bd-r text-nowrap">Sales Conversion</div>
            <div className="d-flex">
              <div>
                <div className="pd-10 text-nowrap">CTA</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">CTA Clicked</div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-icon"
                    data-toggle="modal"
                    data-target="#selectCTA"
                  >
                    <Plus />
                  </button>
                </div>
              </div>
              <div>
                <div className="pd-10 text-nowrap">Lead Capture</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Created</div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-icon"
                    data-toggle="modal"
                    data-target="#newLeadCapture"
                  >
                    <Plus />
                  </button>
                </div>
              </div>
              <div>
                <div className="pd-10 text-nowrap">MQL</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </div>
              <div>
                <div className="pd-10 text-nowrap">Opportunity</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Opportunity Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </div>
              <div>
                <div className="pd-10 text-nowrap">Sales</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Make Payment</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.value == 2 && (
        <div className="d-flex flex-wrap overflow-auto overflow-lg-hidden">
          <div className="wd-100p wd-lg-50p ht-lg-100p overflow-lg-auto pd-15 bd-r">
            <div className="form-group">
              <label className="form-item-title d-block">Post on</label>
              <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialCheck1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialCheck1">
                  <span className="social-icon">{Icon('facebook')}</span>Hanbiro
                </label>
              </div>
              <div className="custom-control custom-checkbox d-inline-block">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialCheck2"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialCheck2">
                  <span className="social-icon">{Icon('twitter')}</span>Hanbiro
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Message</label>
              <div className="card">
                <div contentEditable="true" className="social-content-editor pd-10"></div>
                <div className="pd-x-10 bd-t">
                  <button type="button" className="btn btn-icon" data-han-tooltip="Insert image">
                    <Image />
                    <span className="sr-only">Insert image</span>
                  </button>
                  <button type="button" className="btn btn-icon" data-han-tooltip="Insert CTA">
                    {Icon('cta')}
                    <span className="sr-only">Insert CTA</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title d-block">Send Schedule</label>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="sendScheduleRadio1"
                  name="sendScheduleRadio"
                  className="custom-control-input"
                  value="now"
                  checked={sendSchedule === 'now'}
                  onChange={(e) => handleSendScheduleChange(e)}
                />
                <label className="custom-control-label" htmlFor="sendScheduleRadio1">
                  Send Now
                </label>
              </div>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="sendScheduleRadio2"
                  name="sendScheduleRadio"
                  className="custom-control-input"
                  value="schedule"
                  checked={sendSchedule === 'schedule'}
                  onChange={(e) => handleSendScheduleChange(e)}
                />
                <label className="custom-control-label" htmlFor="sendScheduleRadio2">
                  Schedule
                </label>
              </div>
            </div>
            {sendSchedule === 'schedule' && (
              <div className="form-group">
                <label className="form-item-title">Send Date</label>
                <div className="input-group wd-300">
                  <input type="text" className="form-control" />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Clock />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="wd-100p wd-lg-50p ht-lg-100p overflow-lg-auto pd-15">
            <div className="mg-b-10">Preview</div>
            <div className="card mg-b-10">
              <div className="card-header pd-10">
                <span className="social-icon">{Icon('facebook')}</span>Facebook
              </div>
              <div className="card-body pd-10 social-content"></div>
            </div>
            <div className="card">
              <div className="card-header pd-10">
                <span className="social-icon">{Icon('twitter')}</span>Twitter
              </div>
              <div className="card-body pd-10 social-content"></div>
            </div>
          </div>
        </div>
      )}
      {props.value == 3 && (
        <div className="overflow-auto pd-15">
          <div className="form-group">
            <label className="form-item-title d-block">
              Expenses to include in Marketing Investment
            </label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="expenseOption"
                id="expenseOption1"
                value="enter"
                checked={expenseOption === 'enter'}
                onChange={(e) => handleOptionChange(e)}
              />
              <label className="custom-control-label" htmlFor="expenseOption1">
                Enter the amount you paid for this ads
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="expenseOption"
                id="expenseOption2"
                value="allocate"
                checked={expenseOption === 'allocate'}
                onChange={(e) => handleOptionChange(e)}
              />
              <label className="custom-control-label" htmlFor="expenseOption2">
                Allocate for overhead to implement content
              </label>
            </div>
            <div className="d-flex justify-content-end mg-y-10">
              {expenseOption === 'enter' ? (
                <Select width={150} defaultValue={{ value: 1, label: 'Currency' }} />
              ) : (
                <Button
                  color="primary"
                  size="sm"
                  outline
                  icon="Plus"
                  className="btn-icon rounded-0"
                />
              )}
            </div>
            <div className="table-responsive bd rounded">
              <table className="table table-bordered mg-0 bd-0 basic-table">
                {expenseOption === 'enter' ? (
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Naver</th>
                      <th scope="col">Google</th>
                      <th scope="col">Site Name</th>
                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Sum</th>
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr>
                      <th scope="col">Expense Name</th>
                      <th scope="col">Period</th>
                      <th scope="col">Total Expense</th>
                      <th scope="col" className="wd-150">
                        Allocation
                      </th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                )}
                {expenseOption === 'enter' ? (
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>This Campaign</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        <Input type="number" rightIcon="%" />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
          {expenseOption === 'enter' && (
            <>
              <div className="form-group">
                <label className="form-item-title">Assign to</label>
                <UserAutoComplete showAvatar />
              </div>
              <div className="form-group">
                <label className="form-item-title">Frequency</label>
                <Select width={150} defaultValue={{ value: 1, label: 'Monthly' }} />
              </div>
            </>
          )}
          <div className="form-group">
            <label className="form-item-title">Return on Marketing Investment</label>
            <Select
              width={400}
              options={[
                { value: 1, label: 'Gross Profit' },
                { value: 2, label: 'Customer Lifetime Value' },
                { value: 3, label: 'Sales Growth' },
                { value: 4, label: 'Sales Growth - Average Organic Sales Growth' },
              ]}
              defaultValue={{ value: 1, label: 'Gross Profit' }}
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Key Performance Indicator</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon mg-l-auto rounded-0"
                icon="Plus"
              />
            </div>
            <div className="d-flex align-items-center pd-10 bd rounded">
              Click through rate <Input type="number" className="wd-100 mg-x-10" />%
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActPaidSocial;
