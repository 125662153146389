import { useState } from 'react';
import classnames from 'classnames';

import { ChevronDown, Star, RefreshCw, Search, Settings } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import '../../marketing.scss';

import EmailModal from './modal/email';
import DigitalContentsModal from './modal/digitalContents';
import BroadcastContentsModal from './modal/broadcastContents';
import PrintMediaContentsModal from './modal/printMediaContens';
import DigitalOOHModal from './modal/digitalOOH';
import PrintAdsContentsModal from './modal/printAdsContents';
import PrintedOOHModal from './modal/printedOOH';
import PromotionalItemModal from './modal/promotionalItem';
import FaxModal from './modal/fax';
import SMSModal from './modal/sms';
import ReferralRewardModal from './modal/referralReward';
import SurveyModal from './modal/survey';
import SocialModal from './modal/social';
import EventModal from './modal/event';

const TABS = [
  {
    value: 1,
    label: 'All',
  },
  {
    value: 2,
    label: 'Active',
  },
  {
    value: 3,
    label: 'Prepare',
  },
  {
    value: 4,
    label: 'Online Service Map',
  },
  {
    value: 5,
    label: 'Offline Service Map',
  },
];

const ChannelList = () => {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Marketing Channel</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
          </div>
          <div className="button-group mg-l-auto">
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items align-self-end">
            {/* tabs */}
            <ul className="nav nav-tabs list-tabs bd-b-0">
              {TABS.map((item, index) => (
                <li className="nav-item" key={index}>
                  <button
                    type="button"
                    className={classnames('btn nav-link', {
                      active: item.value == tab.value,
                    })}
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="input-group input-group-flush list-search">
            {' '}
            {/* under 992px device : add 'is-mini' class and add 'expand' class when this is clicked */}
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <input type="search" className="form-control" />
          </div>
        </div>

        <div className="list-body">
          {(tab.value == 1 || tab.value == 2 || tab.value == 3) && (
            <div className="table-responsive">
              <table className="table table-bordered dataTable no-sorting">
                <thead>
                  <tr>
                    <th scope="col">Line</th>
                    <th scope="col">Marketing Contents</th>
                    <th scope="col">Campaign Activity</th>
                    <th scope="col">Distribution/Platform</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={4}>Online</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mg-r-5">Email</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#marketingChannelEmailModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                    </td>
                    <td>Email</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td rowSpan={3}>
                      <div className="d-flex align-items-center mg-b-5 tx-nowrap">
                        <span className="mg-r-5">Digital Contents</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#digitalContensModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                      <div className="card mg-b-15 pd-x-15 pd-y-10">
                        <div className="tx-color-03">Owned Content</div>
                        <ul className="item-list">
                          <li>Blog</li>
                          <li>Website</li>
                          <li>Video</li>
                        </ul>
                        <div className="mg-t-15 tx-color-03 tx-nowrap">In CRM</div>
                        <ul className="item-list">
                          <li>Landing Page</li>
                          <li>Offer Site</li>
                        </ul>
                      </div>
                      <div className="d-flex align-items-center mg-b-5 tx-nowrap">
                        <span className="mg-r-5">Social</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#socialModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                    </td>
                    <td>
                      <ul className="item-list">
                        <li>Paid Search Engine</li>
                        <li>SEO</li>
                      </ul>
                    </td>
                    <td>
                      <div className="tx-color-03">Search Engine</div>
                      <span className="label-item">{Icon('google')}Google</span>
                      <span className="label-item">{Icon('duckduckgo')}DuckDuckGo</span>
                      <span className="label-item">{Icon('naver')}Naver</span>
                      <span className="label-item">{Icon('daum')}Daum</span>
                      <span className="label-item">{Icon('nate')}Nate</span>
                      <span className="label-item">{Icon('yahoo')}Yahoo</span>
                      <span className="label-item">{Icon('zum')}Zum</span>
                      <span className="label-item">{Icon('bing')}Bing</span>
                      <span className="label-item">{Icon('baidu')}Baidu</span>
                      <span className="label-item">New Engine Name</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="item-list">
                        <li>Paid Influencer</li>
                        <li>Free Back Link</li>
                      </ul>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="item-list">
                        <li>Paid Social</li>
                        <li>Organic Social</li>
                      </ul>
                    </td>
                    <td>
                      <div className="tx-color-03">Social Media</div>
                      <span className="label-item">{Icon('facebook')}Facebook</span>
                      <span className="label-item">{Icon('twitter')}Twitter</span>
                      <span className="label-item">{Icon('youtube')}Youtube</span>
                      <span className="label-item">{Icon('instgram')}Instgram</span>
                      <span className="label-item">{Icon('tiktok')}Tiktok</span>
                      <span className="label-item">{Icon('pinterest')}Pinterest</span>
                      <span className="label-item">{Icon('google_plus')}Google Plus</span>
                      <span className="label-item">{Icon('reddit')}Reddit</span>
                      <span className="label-item">{Icon('vimeo')}Vimeo</span>
                      <span className="label-item">{Icon('blogger')}Blogger</span>
                      <span className="label-item">{Icon('flickr')}Flickr</span>
                      <span className="label-item">{Icon('linkedin')}LinkedIn</span>
                      <span className="label-item">{Icon('kakao_story')}Kakao Story</span>
                      <span className="label-item">{Icon('naver_band')}Naver Band</span>
                      <span className="label-item">New Media Name</span>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={10}>Offline</td>
                    <td>
                      <div className="d-flex align-items-center mg-b-5 tx-nowrap">
                        <span className="mg-r-5">Boradcast Contents</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#broadcastContentsModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                      <div className="card pd-x-15 pd-y-10">
                        <ul className="item-list">
                          <li>Video</li>
                          <li>Audio</li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div className="tx-color-03 tx-nowrap">Boradcast Ads</div>
                      <ul className="item-list">
                        <li>TV</li>
                        <li>Radio</li>
                      </ul>
                    </td>
                    <td>
                      <div className="tx-color-03 tx-nowrap">Boradcast Media</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center mg-b-10 tx-nowrap">
                        <span className="mg-r-5">Print Media Contents</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#printMediaContentsModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="tx-color-03 tx-nowrap">Print Media Ads</div>
                      <ul className="item-list">
                        <li>News Paper</li>
                        <li>Magazine</li>
                      </ul>
                    </td>
                    <td>
                      <div className="tx-color-03 tx-nowrap">Print Media</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center mg-b-5 tx-nowrap">
                        <span className="mg-r-5">Digital OOH</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#digitalOOHModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                      <div className="card pd-x-15 pd-y-10">
                        <ul className="item-list">
                          <li>Image</li>
                          <li>Video</li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div className="tx-color-03 tx-nowrap">Boradcast Ads</div>
                      <ul className="item-list">
                        <li>
                          <span
                            className="badge badge-info han-tooltip--left mg-r-5"
                            data-han-tooltip="Digital"
                          >
                            D
                          </span>
                          Billboard
                        </li>
                        <li>
                          <span
                            className="badge badge-info han-tooltip--left mg-r-5"
                            data-han-tooltip="Digital"
                          >
                            D
                          </span>
                          Poster
                        </li>
                        <li>
                          <span
                            className="badge badge-info han-tooltip--left mg-r-5"
                            data-han-tooltip="Digital"
                          >
                            D
                          </span>
                          Transportation
                        </li>
                      </ul>
                    </td>
                    <td>
                      <div className="tx-color-03 tx-nowrap">Distribution Places</div>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>
                      <div className="d-flex align-items-center mg-b-5 tx-nowrap">
                        <span className="mg-r-5">Print Ads</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#printAdsContentsModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                      <div className="card pd-x-15 pd-y-10">
                        <ul className="item-list">
                          <li>Brochure</li>
                          <li>Catalog</li>
                          <li>Flyer</li>
                          <li>Leaflet</li>
                          <li>Pamphlet</li>
                          <li>Postcard</li>
                          <li>Book</li>
                        </ul>
                      </div>
                    </td>
                    <td className="tx-nowrap">Print Ads Distribution</td>
                    <td>
                      <div className="tx-color-03 tx-nowrap">Distribution Methods</div>
                    </td>
                  </tr>
                  <tr>
                    <td>DM</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center mg-b-5 tx-nowrap">
                        <span className="mg-r-5">Printed OOH</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#printedOOHModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                      <div className="card pd-x-15 pd-y-10">
                        <ul className="item-list">
                          <li>
                            <span
                              className="badge bg-indigo tx-white han-tooltip--left mg-r-5"
                              data-han-tooltip="Print"
                            >
                              P
                            </span>
                            Billboard
                          </li>
                          <li>
                            <span
                              className="badge bg-indigo tx-white han-tooltip--left mg-r-5"
                              data-han-tooltip="Print"
                            >
                              P
                            </span>
                            Poster
                          </li>
                          <li>
                            <span
                              className="badge bg-indigo tx-white han-tooltip--left mg-r-5"
                              data-han-tooltip="Print"
                            >
                              P
                            </span>
                            Transportation
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td className="tx-nowrap">Printed OOH adjustment</td>
                    <td>
                      <div className="tx-color-03 tx-nowrap">Distribution Places</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mg-r-5">Promotional Item</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#promotionalItemModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                    </td>
                    <td>
                      <ul className="item-list">
                        <li>DM</li>
                        <li>Meeting</li>
                        <li>Event</li>
                      </ul>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mg-r-5">Fax</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#marketingChannelFaxModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                    </td>
                    <td>Fax</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mg-r-5">SMS</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#marketingChannelSMSModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                    </td>
                    <td>SMS</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Call</td>
                    <td>Call</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>On/Offline</td>
                    <td>
                      <ul className="item-list">
                        <li>Event</li>
                        <li>Print Ads</li>
                        <li>Printed OOH</li>
                        <li>Digital OOH</li>
                      </ul>
                    </td>
                    <td>
                      <div className="d-flex align-items-center mg-b-5 tx-nowrap">
                        <span className="mg-r-5">Event</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#eventModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                      <div className="card pd-x-15 pd-y-10">
                        <ul className="item-list">
                          <li>Webinar</li>
                          <li>Ticketing</li>
                          <li>Conference</li>
                          <li>Tradeshow</li>
                          <li>Sponsoring</li>
                          <li>Seminar</li>
                        </ul>
                      </div>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>Find Ideal Customer</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Referral Site</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mg-r-5">Referral Reward</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#referralRewardModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                    </td>
                    <td>
                      <ul className="item-list">
                        <li>Email</li>
                        <li>SMS</li>
                        <li>Receipt</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mg-r-5">Survey</span>
                        <button
                          type="button"
                          className="btn btn-link btn-icon mg-l-auto pd-0"
                          data-toggle="modal"
                          data-target="#surveyModal"
                        >
                          <Settings />
                          <span className="sr-only">설정</span>
                        </button>
                      </div>
                    </td>
                    <td>Survey</td>
                    <td>
                      <ul className="item-list">
                        <li>Website</li>
                        <li>Email</li>
                        <li>SMS</li>
                        <li>QR Code</li>
                        <li>Social</li>
                        <li>On Spot(Mobile)</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {tab.value == 4 && (
            <div className="bd rounded-bottom pos-relative ht-100p contents-source-map">
              <div
                className="bd-b bd-r tx-center"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '500px',
                  height: '35px',
                  lineHeight: '35px',
                }}
              >
                Awareness
              </div>
              <div style={{ position: 'absolute', top: '135px', left: '20px' }}>
                <span className="tx-semibold">Marketing Channels</span>
                <ul className="list-unstyled bg-white bd rounded">
                  <li className="mg-y-10 pd-x-15">Search Engine</li>
                  <li className="mg-y-10 pd-x-15">External Site</li>
                  <li className="mg-y-10 pd-x-15">Social Media</li>
                </ul>
              </div>
              <div
                className="direction direction-blue"
                style={{ top: '202px', left: '146px', width: '74px' }}
              ></div>
              <div
                className="pd-x-15 pd-y-5 rounded"
                style={{
                  position: 'absolute',
                  top: '188px',
                  left: '220px',
                  backgroundColor: '#03a9f4',
                  color: '#fff',
                }}
              >
                CTA
              </div>

              <div
                className="direction"
                style={{ top: '112px', left: '318px', width: '30px', transform: 'rotate(180deg)' }}
              ></div>
              <div style={{ position: 'absolute', top: '210px', left: '150px', color: '#03a9f4' }}>
                Embedded
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '202px',
                  left: '276px',
                  width: '40px',
                  borderTop: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '112px',
                  left: '316px',
                  height: '273px',
                  borderLeft: '2px solid #596882',
                }}
              ></div>
              <div style={{ position: 'absolute', top: '210px', left: '293px', color: '#03a9f4' }}>
                Or
              </div>
              <div
                className="direction"
                style={{ top: '323px', left: '318px', width: '30px', transform: 'rotate(180deg)' }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '383px',
                  left: '318px',
                  width: '167px',
                  borderTop: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '202px',
                  left: '484px',
                  height: '183px',
                  borderLeft: '2px solid #596882',
                }}
              ></div>

              <div style={{ position: 'absolute', top: '55px', left: '350px' }}>
                <span className="tx-semibold">Internal</span>
                <ul className="list-unstyled bg-white bd rounded">
                  <li className="mg-y-10 pd-x-15">Landing Page</li>
                  <li className="mg-y-10 pd-x-15">Site</li>
                </ul>
              </div>
              <div
                className="pd-x-15 pd-y-5 rounded"
                style={{
                  position: 'absolute',
                  top: '188px',
                  left: '380px',
                  backgroundColor: '#03a9f4',
                  color: '#fff',
                }}
              >
                CTA
              </div>

              <div
                className="direction direction-blue"
                style={{ top: '169px', left: '390px', width: '36px', transform: 'rotate(90deg)' }}
              ></div>
              <div
                className="direction direction-blue"
                style={{ top: '236px', left: '390px', width: '36px', transform: 'rotate(-90deg)' }}
              ></div>
              <div
                className="direction"
                style={{ top: '202px', left: '436px', width: '102px', transform: 'rotate(180deg)' }}
              ></div>
              <div style={{ position: 'absolute', top: '178px', left: '468px' }}>Link</div>

              <div style={{ position: 'absolute', top: '265px', left: '350px' }}>
                <span className="tx-semibold">External</span>
                <ul className="list-unstyled bg-white bd rounded">
                  <li className="mg-y-10 pd-x-15">Blog</li>
                  <li className="mg-y-10 pd-x-15">Website</li>
                </ul>
              </div>
              <div
                className="bd-b bd-r tx-center"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '500px',
                  width: '260px',
                  height: '35px',
                  lineHeight: '35px',
                }}
              >
                Consideration
              </div>
              <div style={{ position: 'absolute', top: '168px', left: '540px' }}>
                <span className="tx-semibold" style={{ color: '#74b000' }}>
                  Sign Up Page
                </span>
                <div
                  className="pd-x-15 pd-y-5 rounded tx-center"
                  style={{ backgroundColor: '#b7d6a3', color: '#4c6b36' }}
                >
                  Forms
                </div>
              </div>

              <div
                className="direction direction-purple"
                style={{ top: '145px', left: '564px', width: '34px', transform: 'rotate(90deg)' }}
              ></div>
              <div
                className="tx-purple"
                style={{ position: 'absolute', top: '80px', left: '540px' }}
              >
                CRM Database
                <br />
                Create Lead
              </div>

              <div
                className="direction"
                style={{ top: '202px', left: '623px', width: '195px', transform: 'rotate(180deg)' }}
              ></div>
              <div style={{ position: 'absolute', top: '178px', left: '688px' }}>Link</div>
              <div
                style={{
                  position: 'absolute',
                  top: '202px',
                  left: '655px',
                  height: '112px',
                  borderLeft: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '312px',
                  left: '655px',
                  width: '31px',
                  borderTop: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '202px',
                  left: '795px',
                  height: '112px',
                  borderLeft: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '312px',
                  left: '769px',
                  width: '28px',
                  borderTop: '2px solid #596882',
                }}
              ></div>

              <div
                className="direction direction-blue"
                style={{ top: '248px', left: '553px', width: '58px', transform: 'rotate(-90deg)' }}
              ></div>
              <div style={{ position: 'absolute', top: '240px', left: '593px', color: '#03a9f4' }}>
                Send
              </div>
              <div style={{ position: 'absolute', top: '313px', left: '640px', color: '#03a9f4' }}>
                Link
              </div>
              <div
                className="direction direction-blue"
                style={{ top: '332px', left: '616px', width: '66px', transform: 'rotate(-180deg)' }}
              ></div>

              <ul
                className="list-unstyled rounded pos-absolute"
                style={{ top: '280px', left: '550px', border: '1px solid #03a9f4' }}
              >
                <li className="mg-y-10 pd-x-15" style={{ color: '#03a9f4' }}>
                  Email
                </li>
                <li className="mg-y-10 pd-x-15" style={{ color: '#03a9f4' }}>
                  SMS
                </li>
              </ul>

              <ul
                className="list-unstyled bg-white bd rounded pos-absolute"
                style={{ top: '302px', left: '685px' }}
              >
                <li className="mg-y-10 pd-x-15">Offer Site</li>
              </ul>
              <div
                className="tx-purple"
                style={{ position: 'absolute', top: '360px', left: '690px' }}
              >
                Download
                <br />
                Coupon
                <br />
                DM
                <br />
                Event
              </div>

              <div
                className="bd-b tx-center"
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  left: '760px',
                  height: '35px',
                  lineHeight: '35px',
                }}
              >
                Sales Conversion
              </div>
              <div
                className="pd-x-15 pd-y-5 bg-white bd rounded"
                style={{ position: 'absolute', top: '188px', left: '820px' }}
              >
                Make Payment
              </div>
            </div>
          )}

          {tab.value == 5 && (
            <div className="bd rounded-bottom pos-relative ht-100p contents-source-map">
              <div
                className="bd-b bd-r tx-center"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '500px',
                  height: '35px',
                  lineHeight: '35px',
                }}
              >
                Awareness
              </div>
              <div style={{ position: 'absolute', top: '75px', left: '20px' }}>
                <span className="tx-semibold">Marketing Channels</span>
                <ul className="list-unstyled bg-white bd rounded">
                  <li className="mg-y-10 pd-x-15">Broadcast Media</li>
                  <li className="mg-y-10 pd-x-15">Print Media</li>
                  <li className="mg-y-10 pd-x-15">Digital OOH</li>
                  <li className="mg-y-10 pd-x-15">Fax</li>
                  <li className="mg-y-10 pd-x-15 pd-t-10 bd-t">Print Ads</li>
                  <li className="mg-y-10 pd-x-15">Printed OOH</li>
                  <li className="mg-y-10 pd-x-15">Promotional Items</li>
                </ul>
              </div>
              <div
                className="direction direction-blue"
                style={{ top: '202px', left: '168px', width: '74px' }}
              ></div>
              <div
                className="pd-x-15 pd-y-5 rounded"
                style={{
                  position: 'absolute',
                  top: '188px',
                  left: '240px',
                  backgroundColor: '#03a9f4',
                  color: '#fff',
                }}
              >
                CTA
              </div>

              <div
                className="direction"
                style={{ top: '112px', left: '318px', width: '30px', transform: 'rotate(180deg)' }}
              ></div>
              <div style={{ position: 'absolute', top: '175px', left: '170px', color: '#03a9f4' }}>
                Embedded
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '202px',
                  left: '296px',
                  width: '20px',
                  borderTop: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '112px',
                  left: '316px',
                  height: '175px',
                  borderLeft: '2px solid #596882',
                }}
              ></div>
              <div style={{ position: 'absolute', top: '225px', left: '243px', color: '#03a9f4' }}>
                QR Code
                <br />
                URL
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '285px',
                  left: '318px',
                  width: '167px',
                  borderTop: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '202px',
                  left: '484px',
                  height: '85px',
                  borderLeft: '2px solid #596882',
                }}
              ></div>

              <div style={{ position: 'absolute', top: '55px', left: '350px' }}>
                <span className="tx-semibold">Internal</span>
                <ul className="list-unstyled bg-white bd rounded">
                  <li className="mg-y-10 pd-x-15">Landing Page</li>
                  <li className="mg-y-10 pd-x-15">Site</li>
                </ul>
              </div>
              <div
                className="pd-x-15 pd-y-5 rounded"
                style={{
                  position: 'absolute',
                  top: '188px',
                  left: '380px',
                  backgroundColor: '#03a9f4',
                  color: '#fff',
                }}
              >
                CTA
              </div>

              <div
                className="direction direction-blue"
                style={{ top: '169px', left: '390px', width: '36px', transform: 'rotate(90deg)' }}
              ></div>
              <div
                className="direction"
                style={{ top: '202px', left: '436px', width: '102px', transform: 'rotate(180deg)' }}
              ></div>

              <div
                className="bd-b bd-r tx-center"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '500px',
                  width: '260px',
                  height: '35px',
                  lineHeight: '35px',
                }}
              >
                Consideration
              </div>
              <div style={{ position: 'absolute', top: '168px', left: '540px' }}>
                <span className="tx-semibold" style={{ color: '#74b000' }}>
                  Sign Up Page
                </span>
                <div
                  className="pd-x-15 pd-y-5 rounded tx-center"
                  style={{ backgroundColor: '#b7d6a3', color: '#4c6b36' }}
                >
                  Forms
                </div>
              </div>

              <div
                className="direction direction-purple"
                style={{ top: '145px', left: '564px', width: '34px', transform: 'rotate(90deg)' }}
              ></div>
              <div
                className="tx-purple"
                style={{ position: 'absolute', top: '80px', left: '540px' }}
              >
                CRM Database
                <br />
                Create Lead
              </div>

              <div
                className="direction"
                style={{ top: '202px', left: '623px', width: '195px', transform: 'rotate(180deg)' }}
              ></div>
              <div style={{ position: 'absolute', top: '178px', left: '688px' }}>Link</div>
              <div
                style={{
                  position: 'absolute',
                  top: '202px',
                  left: '655px',
                  height: '112px',
                  borderLeft: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '312px',
                  left: '655px',
                  width: '31px',
                  borderTop: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '202px',
                  left: '795px',
                  height: '112px',
                  borderLeft: '2px solid #596882',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '312px',
                  left: '769px',
                  width: '28px',
                  borderTop: '2px solid #596882',
                }}
              ></div>

              <div
                className="direction direction-blue"
                style={{ top: '248px', left: '553px', width: '58px', transform: 'rotate(-90deg)' }}
              ></div>
              <div style={{ position: 'absolute', top: '240px', left: '593px', color: '#03a9f4' }}>
                Send
              </div>
              <div style={{ position: 'absolute', top: '313px', left: '640px', color: '#03a9f4' }}>
                Link
              </div>
              <div
                className="direction direction-blue"
                style={{ top: '332px', left: '616px', width: '66px', transform: 'rotate(-180deg)' }}
              ></div>

              <ul
                className="list-unstyled rounded pos-absolute"
                style={{ top: '280px', left: '550px', border: '1px solid #03a9f4' }}
              >
                <li className="mg-y-10 pd-x-15" style={{ color: '#03a9f4' }}>
                  Email
                </li>
                <li className="mg-y-10 pd-x-15" style={{ color: '#03a9f4' }}>
                  SMS
                </li>
              </ul>

              <ul
                className="list-unstyled bg-white bd rounded pos-absolute"
                style={{ top: '302px', left: '685px' }}
              >
                <li className="mg-y-10 pd-x-15">Offer Site</li>
              </ul>
              <div
                className="tx-purple"
                style={{ position: 'absolute', top: '360px', left: '690px' }}
              >
                Download
                <br />
                Coupon
                <br />
                DM
                <br />
                Event
              </div>

              <div
                className="bd-b tx-center"
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  left: '760px',
                  height: '35px',
                  lineHeight: '35px',
                }}
              >
                Sales Conversion
              </div>
              <div
                className="pd-x-15 pd-y-5 bg-white bd rounded"
                style={{ position: 'absolute', top: '188px', left: '820px' }}
              >
                Make Payment
              </div>
            </div>
          )}
        </div>
      </div>

      <EmailModal />
      <DigitalContentsModal />
      <BroadcastContentsModal />
      <PrintMediaContentsModal />
      <DigitalOOHModal />
      <PrintAdsContentsModal />
      <PrintedOOHModal />
      <PromotionalItemModal />
      <FaxModal />
      <SMSModal />
      <ReferralRewardModal />
      <SurveyModal />
      <SocialModal />
      <EventModal />
    </>
  );
};

export default ChannelList;
