/* eslint-disable prettier/prettier */
import { Button, Checkbox } from '@base/components/form';
import { useState } from 'react';
import SmsInfo from './modal/smsInfo';

const Activity = () => {
  const [emailAddress, setEmailAddress] = useState<any>({
    email: true,
    doomain: false,
  });
  const [smsType, setSmsType] = useState<any>({
    company: true,
    individual: false,
  });

  return (
    <>
      <div className="card-columns settings-columns">
        {/* Email Terms */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Email</div>
          </div>
          <div className="card-body">
            <p className="tx-info">Vora Desk App에서 이메일 발송 </p>
            <ul className="list-unstyled">
              <li className="mg-b-15">
                <span className="tx-semibold">Business Process에 따라 메일 자동 발송 시</span>
                <br />
                From : desk@*.vora.net
                <br />
                Reply-to : 담당자의 Email 주소 자동 적용
              </li>
              <li className="mg-b-15">
                <span className="tx-semibold">메일 직접 발송 시</span>
                <br />
                From : desk@*.vora.net
                <br />
                Reply-to : 메일 작성/담당자의 Email 주소 자동 적용
              </li>
              <li className="mg-b-15">
                <span className="tx-semibold">
                  타사 Email App을 통해 수/발신 시 해당 메일을 아래와 같이 사용 시 Vora Desk에
                  Activity 이력으로 저장 가능
                </span>
                <br />
                발신 시: BCC에 desk@*.vora.net
                <br />
                입력 수신 시: Froward로 desk@*.vora.net으로 보내기
              </li>
            </ul>
            <div className="form-group">
              <label className="form-item-title">
                Excluded Email
                <br />
                <span className="tx-orange">
                  From/To 주소가 아래의 이메일 주소/도메인 정보와 일치하면 Activity-Email 이력으로
                  저장 되지 않습니다.
                </span>
              </label>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <Button
                    color=""
                    className={`nav-link rounded-0 ${
                      emailAddress.email ? 'active tx-semibold' : ''
                    }`}
                    name="Email Address"
                    onClick={() => setEmailAddress({ email: true })}
                  />
                </li>
                <li className="nav-item">
                  <Button
                    color=""
                    className={`nav-link rounded-0 ${
                      emailAddress.domain ? 'active tx-semibold' : ''
                    }`}
                    name="Domain"
                    onClick={() => setEmailAddress({ domain: true })}
                  />
                </li>
              </ul>
              <div className="table-responsive bd bd-t-0 rounded">
                <table className="table table-bordered mg-b-0 bd-0 hover-delete">
                  <thead>
                    <tr>
                      <th scope="col" className="text-nowrap">
                        {`${emailAddress.email ? 'Email Address' : 'Domain'}`}
                      </th>
                      <th scope="col" className="text-nowrap">
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle">
                        {`${emailAddress.email ? '123@' : ''} mail.com`}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          spam
                          <Button
                            color=""
                            icon="X"
                            iconClass="tx-danger"
                            className="btn-icon btn-delete-row han-tooltip--top mg-l-auto"
                            data-han-tooltip="삭제"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button
                color="primary"
                icon="Plus"
                iconClass="mg-r-5"
                name="Add"
                className="mg-t-10"
              />
            </div>
          </div>
        </div>

        {/* SMS  */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">SMS</div>
            <Button
              color="link link-03"
              icon="Info"
              className="btn-icon pd-y-0 lh-1"
              data-toggle="modal"
              data-target="#smsInfo"
            />
          </div>
          <div className="card-body">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Button
                  color=""
                  className={`nav-link rounded-0 ${smsType.company ? 'active tx-semibold' : ''}`}
                  name="Company Phone Number"
                  onClick={() => setSmsType({ company: true })}
                />
              </li>
              <li className="nav-item">
                <Button
                  color=""
                  className={`nav-link rounded-0 ${smsType.individual ? 'active tx-semibold' : ''}`}
                  name="Personal Mobile Number"
                  onClick={() => setSmsType({ individual: true })}
                />
              </li>
            </ul>

            <div className="table-responsive bd bd-t-0 rounded">
              <table className="table table-bordered mg-b-0 bd-0 hover-delete">
                <thead>
                  <tr>
                    <th scope="col" className="wd-30">
                      <Checkbox />
                    </th>
                    {smsType.individual && (
                      <th scope="col" className="text-nowrap">
                        User Name
                      </th>
                    )}
                    <th scope="col" className="text-nowrap">
                      Phone Number
                    </th>
                    {smsType.company && (
                      <>
                        <th scope="col" className="text-nowrap">
                          Description
                        </th>
                        <th scope="col" className="text-nowrap">
                          Default
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align-middle">
                      <Checkbox />
                    </td>
                    {smsType.individual && <td>MSR</td>}
                    <td className="align-middle">{`${
                      smsType.company ? '1544-4755' : '010-4804-9674'
                    }`}</td>
                    {smsType.company && (
                      <>
                        <td>
                          <div className="d-flex align-items-center">
                            Main
                            <Button
                              color=""
                              icon="X"
                              iconClass="tx-danger"
                              className="btn-icon btn-delete-row han-tooltip--top mg-l-auto"
                              data-han-tooltip="삭제"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio1"
                              name="customRadio"
                              className="custom-control-input"
                              defaultChecked
                            />
                            <label className="custom-control-label" htmlFor="customRadio1"></label>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SmsInfo />
    </>
  );
};

export default Activity;
