import Icon from '@base/assets/icons/svg-icons';
import { Button, Input, Select } from '@base/components/form';
import { useState } from 'react';
import { ChevronLeft, ChevronRight, Search } from 'react-feather';
import './site.scss';

import ModalSurvey from './modal/survey';
import NewTicket from './modal/newTicket';

const TABS = [
  {
    value: 1,
    label: 'Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'All Tickets',
  },
];

const QuoteSite = () => {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <div className="d-flex flex-column ht-100p">
      <div className="d-flex flex-wrap align-items-center pd-x-20 pd-y-15 bd-b">
        <h1 className="mg-0 tx-24">Subject Subject Subject Subject Subject</h1>
        <div className="d-flex align-items-center mg-l-auto">
          <Button
            color=""
            name="Survey"
            className="bg-orange tx-white rounded-30"
            data-toggle="modal"
            data-target="#modalSurvey"
          />
          <Button color="info" name=" Close ticket" className="mg-l-5 rounded-30" />
          <Button
            color="primary"
            name="New"
            icon="Plus"
            className="mg-l-5 rounded-30"
            data-toggle="modal"
            data-target="#newTicket"
          />
          <div className="dropdown">
            <Button
              name="More"
              className="mg-l-5 rounded-30"
              icon="ChevronDown"
              isIconsEnd
              data-toggle="dropdown"
            />
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                icon="X"
                iconClass="dropdown-item-icon"
                name="Cancel Ticket"
              />
              <Button
                className="dropdown-item"
                icon="Printer"
                iconClass="dropdown-item-icon"
                name="Print"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 pd-20 scroll-box">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <h2 className="mg-b-20 tx-20">Summary</h2>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Ticket No : </label>
              <span>00000001</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Customer : </label>
              <span>Customer 1</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Contact : </label>
              <span>Contact 1</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Status : </label>
              <span className="badge badge-info tx-normal tx-13">New</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Category : </label>
              <span>Product/Error</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Assigned Rep : </label>
              <span>MSR</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Created on : </label>
              <span>2022-08-08 15:36:56</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">1st Responded on : </label>
              <span>2022-08-08 15:36:56</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Resolved on : </label>
              <span>2022-08-08 15:36:56</span>
            </div>
            <div className="form-group">
              <label className="form-item-title mg-r-10">Closed on : </label>
              <span>2022-08-08 15:36:56</span>
            </div>
          </div>
          <div className="col-md-8 col-lg-9">
            <ul className="nav nav-tabs flex-grow-1">
              {TABS.map((item) => (
                <li className="nav-item">
                  <button
                    type="button"
                    className={`nav-link ${item.value == tab.value ? 'active' : ''}`}
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
            {tab.value == 1 && (
              <>
                <div className="tab-content bg-white bd bd-gray-200 bd-t-0 pd-20">
                  Ticket No, Status, Customer, Category, Assigned RepTicket No, Status, Customer,
                  Category, Assigned Rep
                </div>

                <div className="card mg-t-10">
                  <div className="card-header d-flex align-items-center">
                    <span className="avatar avatar-sm flex-shrink-0">
                      <img
                        src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                        className="rounded-circle"
                      />
                    </span>
                    <div className="mg-l-10">
                      MSR
                      <div className="tx-color-03 tx-12">2022-08-10 16:30:05</div>
                    </div>
                    <Button
                      color="primary"
                      size="sm"
                      outline
                      name="Feedback"
                      className="mg-l-auto rounded-30"
                    />
                  </div>
                  <div className="card-body">
                    Ticket No, Status, Customer, Category, Assigned Rep
                  </div>
                </div>

                <div className="card mg-t-10">
                  <div className="card-header d-flex align-items-center">
                    <span className="avatar avatar-sm flex-shrink-0">
                      <img
                        src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                        className="rounded-circle"
                      />
                    </span>
                    <div className="mg-l-10">
                      Feedback from Contact 1
                      <div className="tx-color-03 tx-12">2022-08-10 16:30:05</div>
                    </div>
                  </div>
                  <div className="card-body">
                    Ticket No, Status, Customer, Category, Assigned Rep
                  </div>
                </div>
              </>
            )}
            {tab.value == 2 && (
              <div className="tab-content bg-white bd bd-gray-200 bd-t-0">&nbsp;</div>
            )}
            {tab.value == 3 && (
              <>
                <div className="tab-content bg-white bd bd-gray-200 bd-t-0">
                  <div className="d-flex flex-wrap pd-5 bd-b">
                    <Button color="" icon="ChevronDown" isIconsEnd name="All Tickets" />
                    <Button color="" icon="ChevronDown" isIconsEnd name="All" />
                    <Button color="" icon="ChevronDown" isIconsEnd name="Filters" />
                    <div className="d-flex mg-l-auto">
                      <Input rightIcon={<Search />} className="wd-300" />
                      <Button color="white" icon="RefreshCw" className="btn-icon mg-l-5" />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered dataTable mg-b-0 bd-0">
                      <thead>
                        <tr>
                          <th scope="col" className="sorting tx-nowrap">
                            Ticket No
                          </th>
                          <th scope="col" className="sorting tx-nowrap">
                            Subject
                          </th>
                          <th scope="col" className="sorting tx-nowrap">
                            Status
                          </th>
                          <th scope="col" className="sorting tx-nowrap">
                            Assigned Rep
                          </th>
                          <th scope="col" className="sorting tx-nowrap">
                            Created on
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0000001</td>
                          <td>
                            <Button
                              color="link"
                              name="Subject Subject Subject Subject"
                              className="pd-0"
                            />
                          </td>
                          <td>
                            <span className="badge badge-info tx-normal tx-13">New</span>
                          </td>
                          <td>Assigned Rep</td>
                          <td>2022-08-10 13:22:46</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pd-y-6-f pd-r-20 pagination align-items-center justify-content-end bd-t">
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="move to first"
                    >
                      {Icon('move_first')}
                      <span className="sr-only">처음으로</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="previous"
                    >
                      <ChevronLeft />
                      <span className="sr-only">이전</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="next"
                    >
                      <ChevronRight />
                      <span className="sr-only">다음</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="move to last"
                    >
                      {Icon('move_last')}
                      <span className="sr-only">마지막으로</span>
                    </button>
                    <input type="number" className="paging-input" defaultValue="1" />/ 1
                    <Select
                      width={150}
                      defaultValue={{ value: 5, label: '5 per page' }}
                      className="mg-l-10"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ModalSurvey />
      <NewTicket />
    </div>
  );
};

export default QuoteSite;
