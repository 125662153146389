import { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Settings,
  Menu,
  RotateCcw,
  X,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import '../marketing.scss';
import Pagination from '@demo-page/component/desktop/pagination-new';
import { Checkbox } from '@base/components/form';

const TargetAccountList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Target Account</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
          </div>
          <div className="button-group mg-l-auto">
            <button
              type="button"
              className="btn btn-primary btn-icon"
              data-toggle="modal"
              data-target="#"
            >
              <Plus />
              <span className="sr-only">Add</span>
            </button>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                All
                <ChevronDown />
              </button>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                All
                <ChevronDown />
              </button>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filters
                <ChevronDown />
              </button>
            </div>
            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_asc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Account
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item">
                    Created on
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* for table list column settings */}
          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {listType.list && (
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered dataTable mg-b-0 bd-0">
                <thead>
                  <tr>
                    <th scope="col" rowSpan={2} className="tb-sel-cell align-middle">
                      <Checkbox />
                    </th>
                    <th scope="col" rowSpan={2} className="sorting_asc align-middle">
                      Account
                    </th>
                    <th scope="col" rowSpan={2} className="sorting align-middle">
                      ABM Tier
                    </th>
                    <th scope="col" rowSpan={2} className="sorting align-middle">
                      Contact
                    </th>
                    <th scope="colgroup" colSpan={3} className="pd-y-5-f text-center">
                      Team Member assigned
                    </th>
                    <th scope="col" rowSpan={2} className="sorting align-middle">
                      Collection Method
                    </th>
                    <th scope="col" rowSpan={2} className="sorting align-middle">
                      Product
                    </th>
                    <th scope="col" rowSpan={2} className="sorting align-middle">
                      Pipeline
                    </th>
                    <th scope="col" rowSpan={2} className="sorting align-middle">
                      Score
                    </th>
                    <th scope="col" rowSpan={2} className="sorting align-middle">
                      Launched on
                    </th>
                    <th scope="col" rowSpan={2} className="sorting align-middle">
                      Created on
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" className="sorting pd-y-5-f">
                      Collect/Profile
                    </th>
                    <th scope="col" className="sorting pd-y-5-f">
                      Producer
                    </th>
                    <th scope="col" className="sorting pd-y-5-f">
                      Sales
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/target-account/desktop/theme1/view">AAA</a>
                    </td>
                    <td>Tier 1</td>
                    <td>2</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                      <span className="score-cold">20</span>
                    </td>
                    <td>2021/11/18</td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/target-account/desktop/theme1/view">BBB</a>
                    </td>
                    <td>Tier 1</td>
                    <td>2</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                      <span className="score-warm">60</span>
                    </td>
                    <td>2021/11/18</td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/target-account/desktop/theme1/view">CCC</a>
                    </td>
                    <td>Tier 1</td>
                    <td>2</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                      <span className="score-hot">80</span>
                    </td>
                    <td>2021/11/18</td>
                    <td>2021/11/18</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <Checkbox />
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/target-account/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        AAA
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">ABM Tier:</span>
                        <span className="grid-list-item-col-content">Tier 1</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Contact:</span>
                        <span className="grid-list-item-col-content">2</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Collect/Profile Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Producer Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Sales Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Collection Method:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Product:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Pipeline:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Score:</span>
                        <span className="grid-list-item-col-content">
                          <span className="score-cold">20</span>
                        </span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Launched on:</span>
                        <span className="grid-list-item-col-content">2021/11/18</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Created on:</span>
                        <span className="grid-list-item-col-content">2021/11/18</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <Checkbox />
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/target-account/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        BBB
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">ABM Tier:</span>
                        <span className="grid-list-item-col-content">Tier 1</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Contact:</span>
                        <span className="grid-list-item-col-content">2</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Collect/Profile Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Producer Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Sales Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Collection Method:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Product:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Pipeline:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Score:</span>
                        <span className="grid-list-item-col-content">
                          <span className="score-warm">60</span>
                        </span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Launched on:</span>
                        <span className="grid-list-item-col-content">2021/11/18</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Created on:</span>
                        <span className="grid-list-item-col-content">2021/11/18</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <Checkbox />
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/target-account/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        CCC
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">ABM Tier:</span>
                        <span className="grid-list-item-col-content">Tier 1</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Contact:</span>
                        <span className="grid-list-item-col-content">2</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Collect/Profile Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Producer Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Sales Rep:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Collection Method:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Product:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Pipeline:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Score:</span>
                        <span className="grid-list-item-col-content">
                          <span className="score-hot">80</span>
                        </span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Launched on:</span>
                        <span className="grid-list-item-col-content">2021/11/18</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Created on:</span>
                        <span className="grid-list-item-col-content">2021/11/18</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>
    </>
  );
};

export default TargetAccountList;
