import { Button } from '@base/components/form';
import NewLeadScoringRule from './modal/newLeadScoringRule';

const LeadScoringRules = () => {
  return (
    <div className="page-container-wrap">
      <div className="page-container-content">
        <div className="page-container-content-header">
          <h1 className="mg-b-0 tx-16 tx-uppercase tx-normal mg-l-10 mg-r-10">Lead Scoring Rule</h1>
          <Button
            color="primary"
            className="mg-l-auto"
            icon="Plus"
            iconClass="mg-r-5"
            name="Add"
            data-toggle="modal"
            data-target="#newLeadScoringRule"
          />
        </div>
        <div className="scroll-box pd-20">
          <div className="table-responsive bg-white bd rounded">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col" className="sorting_asc bd-t-0-f">
                    Lead Scoring Rule Name
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Description
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    MQL Value
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    SQL Value
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Owner
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Created On
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="sorting-cell">
                    <Button
                      color="link"
                      name="Rule 1"
                      className="pd-0"
                      data-toggle="modal"
                      data-target="#newLeadScoringRule"
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <NewLeadScoringRule />
    </div>
  );
};

export default LeadScoringRules;
