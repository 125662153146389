import { Button, Checkbox } from '@base/components/form';

const SelectCollateral = () => {
  return (
    <div
      id="selectCollateral"
      className="modal over-modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Print Ads</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body overflow-auto">
            <div className="d-flex flex-wrap">
              <div className="card mg-r-10" style={{ width: '180px' }}>
                <div
                  className="d-flex align-items-center justify-content-center overflow-hidden bg-light"
                  style={{ width: '180px', height: '200px' }}
                >
                  <img
                    src="https://placehold.co/180x200/?text=Preview&font=railway"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="pd-x-15 pd-y-10 bd-t">
                  <Checkbox
                    label="Name 1"
                    labelClassName="mx-wd-100p"
                    spanClassName="d-block text-truncate"
                  />
                </div>
              </div>
              <div className="card mg-r-10" style={{ width: '180px' }}>
                <div
                  className="d-flex align-items-center justify-content-center overflow-hidden bg-light"
                  style={{ width: '180px', height: '200px' }}
                >
                  <img
                    src="https://placehold.co/180x200/?text=Preview&font=railway"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="pd-x-15 pd-y-10 bd-t">
                  <Checkbox
                    label="Name 1"
                    labelClassName="mx-wd-100p"
                    spanClassName="d-block text-truncate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCollateral;
