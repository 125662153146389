import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  Repeat,
  Eye,
  Clock,
  Check,
  Circle,
} from 'react-feather';
import { Button, Select } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import TargetAccountCollected from './view/targetAccountCollected';
import MarketingContent from './view/marketingContent';
import LeadScoringRule from './view/leadScoringRule';
import ReactApexChart from 'react-apexcharts';

const TABS = [
  {
    value: 1,
    label: 'Overview',
  },
  {
    value: 2,
    label: 'Funnel Map',
  },
  {
    value: 3,
    label: 'Page Views',
  },
  {
    value: 4,
    label: 'Contact Details',
  },
  {
    value: 5,
    label: 'Email Interation',
  },
  {
    value: 6,
    label: 'ROI',
  },
];

const TABS2 = [
  {
    value: 1,
    label: 'Target Accounts Collected',
  },
  {
    value: 2,
    label: 'Marketing Content',
  },
  {
    value: 3,
    label: 'Journey Process',
  },
  {
    value: 4,
    label: 'Lead Scoring Rule',
  },
  {
    value: 5,
    label: 'Details',
  },
];

const ABMView = () => {
  const [tab, setTab] = useState<any>(TABS[0]);
  const [tab2, setTab2] = useState<any>(false);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [interactions, SetInteractions] = useState<any>({
    opened: true,
    clicked: false,
    replied: false,
    bounced: false,
  });

  const [toggle, setToggle] = useState<any>(false);
  const toggleFunction = () => {
    if (toggle) {
      setTab(TABS[0]);
      setTab2(false);
    } else {
      setTab2(TABS[0]);
      setTab(false);
    }
    setToggle(!toggle);
  };

  return (
    <>
      <div className="view-wrap">
        <div className="view-header bg-white">
          <a href="/event/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Account Based Marketing
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">AAA</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newCampaignModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>
        </div>

        <div className="row no-gutters flex-nowrap flex-grow-1 overflow-auto">
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Insight</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#insightSection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="insightSection" className="view-aside-section-body collapse fade show">
                  <div className="card mg-b-15 pd-15">
                    <div className="d-flex mg-b-15">
                      <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                        Total Accounts
                      </div>
                      <div className="mg-l-auto tx-15 tx-semibold">20</div>
                    </div>
                    <ReactApexChart
                      type="donut"
                      height={150}
                      series={[12, 8]}
                      options={{
                        theme: {
                          mode: 'light',
                        },
                        labels: ['Engaged', 'Not Engaged'],
                        colors: ['rgba(0,227,150, .8)', 'rgba(221,221,221, 1)'],
                        plotOptions: {
                          pie: {
                            donut: {
                              size: '88%',
                              labels: {
                                show: true,
                                value: {
                                  fontSize: '1.25rem',
                                  fontWeight: 600,
                                  offsetY: -1,
                                },
                                total: {
                                  show: true,
                                  fontSize: '0.875rem',
                                  label: 'Total',
                                },
                              },
                            },
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                      }}
                    />
                  </div>
                  <div className="card pd-15">
                    <div className="d-flex mg-b-15">
                      <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">
                        Total Contacts
                      </div>
                      <div className="mg-l-auto tx-15 tx-semibold">40</div>
                    </div>
                    <ReactApexChart
                      type="donut"
                      height={150}
                      series={[20, 20]}
                      options={{
                        theme: {
                          mode: 'light',
                        },
                        labels: ['Engaged', 'Not Engaged'],
                        colors: ['rgba(0,227,150, .8)', 'rgba(221,221,221, 1)'],
                        plotOptions: {
                          pie: {
                            donut: {
                              size: '88%',
                              labels: {
                                show: true,
                                value: {
                                  fontSize: '1.25rem',
                                  fontWeight: 600,
                                  offsetY: -1,
                                },
                                total: {
                                  show: true,
                                  fontSize: '0.875rem',
                                  label: 'Total',
                                },
                              },
                            },
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">KPI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="d-flex align-items-center justify-content-between mg-b-5">
                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                      Click through rate
                    </span>
                    <span className="tx-12 tx-color-04">83% goal reached</span>
                  </div>
                  <div className="d-flex justify-content-between mg-b-5">
                    <span className="h5 tx-semibold mg-b-0">50%</span>
                    <span className="tx-rubik tx-color-03 mg-b-0">60%</span>
                  </div>
                  <div className="progress ht-4 mg-b-0 op-5">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={60}
                      style={{ width: '83%' }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">ROI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="h3 mg-b-15 tx-center tx-primary">413%</div>
                  <div className="d-flex">
                    <div className="pd-x-15">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Recipients
                      </div>
                      <div>100</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Sales
                      </div>
                      <div>20</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Gross Profit
                      </div>
                      <div>＄500.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {!toggle && (
                    <>
                      {TABS.map(
                        (item, index) =>
                          item.value < 3 && (
                            <li
                              key={index}
                              className={classnames('nav-item', {
                                active: item.value == tab.value,
                              })}
                            >
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className="btn nav-link"
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </li>
                          ),
                      )}
                      <li
                        className={classnames('nav-item dropdown pd-0-f', {
                          active: tab.value > 2,
                        })}
                      >
                        <button
                          type="button"
                          className="btn nav-link pd-x-20-f"
                          data-toggle="dropdown"
                        >
                          <Plus className="mg-r-5" />
                          {tab.value > 2 ? tab.label : 'More'}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right bg-white">
                          {TABS.map(
                            (item, index) =>
                              item.value > 2 && (
                                <div className="dropdown-item" key={index}>
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-move-tab"
                                    aria-label="Drag-and-drop to reorder"
                                  >
                                    {Icon('move')}
                                    <span className="sr-only">드래그앤드롭으로 이동</span>
                                  </button>
                                  <button
                                    type="button"
                                    className={classnames('btn', {
                                      active: item.value == tab.value,
                                    })}
                                    onClick={(e) => {
                                      setTab(item);
                                    }}
                                  >
                                    {item.label}
                                  </button>
                                </div>
                              ),
                          )}
                        </div>
                      </li>
                    </>
                  )}
                  {toggle && (
                    <>
                      {TABS2.map(
                        (item, index) =>
                          item.value < 3 && (
                            <li
                              key={index}
                              className={classnames('nav-item', {
                                active: item.value == tab2.value,
                              })}
                            >
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className="btn nav-link"
                                onClick={(e) => {
                                  setTab2(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </li>
                          ),
                      )}
                      <li
                        className={classnames('nav-item dropdown pd-0-f', {
                          active: tab2.value > 2,
                        })}
                      >
                        <button
                          type="button"
                          className="btn nav-link pd-x-20-f"
                          data-toggle="dropdown"
                        >
                          <Plus className="mg-r-5" />
                          {tab2.value > 2 ? tab2.label : 'More'}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right bg-white">
                          {TABS2.map(
                            (item, index) =>
                              item.value > 2 && (
                                <div className="dropdown-item" key={index}>
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-move-tab"
                                    aria-label="Drag-and-drop to reorder"
                                  >
                                    {Icon('move')}
                                    <span className="sr-only">드래그앤드롭으로 이동</span>
                                  </button>
                                  <button
                                    type="button"
                                    className={classnames('btn', {
                                      active: item.value == tab2.value,
                                    })}
                                    onClick={(e) => {
                                      setTab2(item);
                                    }}
                                  >
                                    {item.label}
                                  </button>
                                </div>
                              ),
                          )}
                        </div>
                      </li>
                    </>
                  )}
                  <li className="mg-l-auto mg-r-10">
                    <button
                      type="button"
                      className="btn btn-white"
                      onClick={() => toggleFunction()}
                    >
                      <Repeat className="mg-r-5 " />
                      Tabs Campaign {!toggle ? 'Open' : 'Result'}
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}
              {/* Overview */}
              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="row">
                    <div className="col-xl-6">
                      <span className="mg-b-10 tx-semibold">Conversion Funnel</span>
                      <div className="d-flex">
                        <div className="wd-250 mg-r-20"></div>
                        <ul className="list-unstyled mg-0 pd-0">
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#6599e3' }}
                            ></span>
                            Target Accounts Collected
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#95cf6a' }}
                            ></span>
                            Target Accounts Launched
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#f9b521' }}
                            ></span>
                            MQL
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#f6704e' }}
                            ></span>
                            SQL
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#3ab6b1' }}
                            ></span>
                            Opportunity
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#9574e2' }}
                            ></span>
                            Purchase
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="bg-white bd rounded">
                        <div className="row no-gutters">
                          <div className="col-6 bd-r">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-info tx-info rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  <Clock />
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Average Time per conversion
                                  </div>
                                  <div className="tx-20">
                                    5 <small>months</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-success tx-success rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  {Icon('revenue')}
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Revenue Generated
                                  </div>
                                  <div className="tx-20">＄500.00</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 bd-t bd-r">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-purple tx-purple rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  <Eye />
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Total Page Views
                                  </div>
                                  <div className="tx-20">
                                    500<small className="mg-l-10 tx-color-03">(2 / Account)</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 bd-t">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-gray-500 tx-gray-500 rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  {Icon('buying_role')}
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Missing any Buying Role
                                  </div>
                                  <div className="tx-20">
                                    4
                                    <small className="mg-l-10 tx-color-03">
                                      (20% of Target Accounts)
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 bd-t bd-r">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-primary tx-primary rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  {Icon('purchase')}
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Accounts Purchased
                                  </div>
                                  <div className="tx-20">
                                    10
                                    <small className="mg-l-10 tx-color-03">(Total: 20)</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 bd-t">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-danger tx-danger rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  {Icon('dicision_maker')}
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Missing Decision Maker
                                  </div>
                                  <div className="tx-20">
                                    4
                                    <small className="mg-l-10 tx-color-03">
                                      (20% of Target Accounts)
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mg-t-20">
                    <div className="col-xl-4">
                      <div className="view-content-section">
                        <div className="view-content-section-header">
                          <span className="view-aside-section-header-title">Email</span>
                        </div>
                        <div className="card card-body">
                          <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                            <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">
                              Email step
                            </div>
                            <div className="mg-l-auto  tx-15 tx-semibold">4</div>
                          </div>
                          <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                            <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                              Total sent
                            </div>
                            <div className="mg-l-auto  tx-15 tx-semibold">149</div>
                          </div>
                          <ReactApexChart
                            type="donut"
                            height="150px"
                            series={[100, 1, 250]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Opened', 'Bounced', 'Unopened'],
                              colors: [
                                'rgba(0,227,150, .8)',
                                'rgba(220,53,69, .8)',
                                'rgba(221,221,221, 1)',
                              ],
                              plotOptions: {
                                pie: {
                                  donut: {
                                    size: '88%',
                                    labels: {
                                      show: true,
                                      value: {
                                        fontSize: '1.25rem',
                                        fontWeight: 600,
                                        offsetY: -1,
                                      },
                                      total: {
                                        show: true,
                                        fontSize: '0.875rem',
                                        label: 'Total',
                                      },
                                    },
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                            }}
                          />
                          <div className="d-flex align-items-center mg-b-5">
                            Opened
                            <span
                              className="flex-grow-1 mg-x-10 text-right"
                              style={{ color: '#53b3b8' }}
                            >
                              60
                            </span>
                            <div className="progress wd-100">
                              <div
                                className="progress-bar progress-bar-striped progress-bar-animated wd-40p"
                                role="progressbar"
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                style={{ backgroundColor: '#53b3b8' }}
                              >
                                40%
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mg-b-5">
                            Clicked
                            <span className="flex-grow-1 mg-x-10 text-right tx-purple">15</span>
                            <div className="progress wd-100">
                              <div
                                className="progress-bar progress-bar-striped bg-purple progress-bar-animated wd-10p"
                                role="progressbar"
                                aria-valuenow={10}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                              <span className="lh-1">10%</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            Replied
                            <span className="flex-grow-1 mg-x-10 text-right tx-primary">7</span>
                            <div className="progress wd-100">
                              <div
                                className="progress-bar progress-bar-striped progress-bar-animated wd-5p"
                                role="progressbar"
                                aria-valuenow={5}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                              <span className="lh-1">5%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="view-content-section">
                            <div className="view-content-section-header">
                              <span className="view-aside-section-header-title">
                                Page views per Target Accounts
                              </span>
                            </div>
                            <div className="card">
                              <div className="table-responsive">
                                <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="bd-t-0-f sorting wd-70">
                                        No
                                      </th>
                                      <th scope="col" className="bd-t-0-f sorting">
                                        Account
                                      </th>
                                      <th scope="col" className="bd-t-0-f sorting wd-100">
                                        Page views
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>AAA</td>
                                      <td>20</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="card-footer pd-y-6-f pagination">
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="move to first"
                                >
                                  {Icon('move_first')}
                                  <span className="sr-only">처음으로</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="previous"
                                >
                                  <ChevronLeft />
                                  <span className="sr-only">이전</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="next"
                                >
                                  <ChevronRight />
                                  <span className="sr-only">다음</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="move to last"
                                >
                                  {Icon('move_last')}
                                  <span className="sr-only">마지막으로</span>
                                </button>
                                <input type="number" className="paging-input" defaultValue="1" />/ 1
                              </div>
                            </div>
                          </div>

                          <div className="view-content-section">
                            <div className="view-content-section-header">
                              <span className="view-aside-section-header-title">
                                Total Page views
                              </span>
                            </div>
                            <div className="card">
                              <div className="table-responsive">
                                <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="bd-t-0-f sorting wd-70">
                                        No
                                      </th>
                                      <th scope="col" className="bd-t-0-f sorting">
                                        Page Name
                                      </th>
                                      <th scope="col" className="bd-t-0-f sorting wd-100">
                                        Views
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>aa.html</td>
                                      <td>10</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="card-footer pd-y-6-f pagination">
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="move to first"
                                >
                                  {Icon('move_first')}
                                  <span className="sr-only">처음으로</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="previous"
                                >
                                  <ChevronLeft />
                                  <span className="sr-only">이전</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="next"
                                >
                                  <ChevronRight />
                                  <span className="sr-only">다음</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="move to last"
                                >
                                  {Icon('move_last')}
                                  <span className="sr-only">마지막으로</span>
                                </button>
                                <input type="number" className="paging-input" defaultValue="1" />/ 1
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="view-content-section">
                            <div className="view-content-section-header">
                              <span className="view-aside-section-header-title">Download</span>
                            </div>
                            <div className="card">
                              <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">
                                  Total Download
                                </div>
                                <div className="mg-l-auto  tx-15 tx-semibold">30</div>
                              </div>
                              <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                                  Account Download
                                </div>
                                <div className="mg-l-auto  tx-15 tx-semibold">10</div>
                              </div>
                              <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-info">
                                  Contact Download
                                </div>
                                <div className="mg-l-auto  tx-15 tx-semibold">20</div>
                              </div>
                              <div className="table-responsive">
                                <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="bd-t-0-f sorting wd-70">
                                        No
                                      </th>
                                      <th scope="col" className="bd-t-0-f sorting">
                                        Files
                                      </th>
                                      <th scope="col" className="bd-t-0-f sorting wd-100">
                                        Download
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>A.pdf</td>
                                      <td>20</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="card-footer pd-y-6-f pagination">
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="move to first"
                                >
                                  {Icon('move_first')}
                                  <span className="sr-only">처음으로</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="previous"
                                >
                                  <ChevronLeft />
                                  <span className="sr-only">이전</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="next"
                                >
                                  <ChevronRight />
                                  <span className="sr-only">다음</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="move to last"
                                >
                                  {Icon('move_last')}
                                  <span className="sr-only">마지막으로</span>
                                </button>
                                <input type="number" className="paging-input" defaultValue="1" />/ 1
                              </div>
                            </div>
                          </div>

                          <div className="view-content-section">
                            <div className="view-content-section-header">
                              <span className="view-aside-section-header-title">Lead Score</span>
                            </div>
                            <div className="card">
                              <div className="table-responsive">
                                <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="bd-t-0-f sorting wd-70">
                                        No
                                      </th>
                                      <th scope="col" className="bd-t-0-f sorting">
                                        Account
                                      </th>
                                      <th scope="col" className="bd-t-0-f sorting wd-100">
                                        Score
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>aa.html</td>
                                      <td>20</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="card-footer pd-y-6-f pagination">
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="move to first"
                                >
                                  {Icon('move_first')}
                                  <span className="sr-only">처음으로</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="previous"
                                >
                                  <ChevronLeft />
                                  <span className="sr-only">이전</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="next"
                                >
                                  <ChevronRight />
                                  <span className="sr-only">다음</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link link-02 btn-icon"
                                  aria-label="move to last"
                                >
                                  {Icon('move_last')}
                                  <span className="sr-only">마지막으로</span>
                                </button>
                                <input type="number" className="paging-input" defaultValue="1" />/ 1
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="view-content-section mg-t-20">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">
                        Accounts Purchased &amp; Conversions(Sales)
                      </span>
                      <div className="d-flex align-items-center mg-l-auto mg-b-5">
                        Frequency
                        <Select defaultValue={{ value: 'm', label: 'Month' }} className="mg-l-10" />
                      </div>
                    </div>
                    <div className="card">
                      <div className="pd-15 bd-b">
                        <ReactApexChart
                          type="line"
                          height={300}
                          series={[
                            {
                              name: 'Tier 1',
                              data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                            },
                            {
                              name: 'Tier 2',
                              data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                            },
                            {
                              name: 'Tier 3',
                              data: [0, 10, 10, 15, 20, 45, 30, 51, 80],
                            },
                          ]}
                          options={{
                            chart: {
                              zoom: {
                                enabled: false,
                              },
                            },
                            stroke: {
                              curve: 'straight',
                            },
                            xaxis: {
                              categories: [
                                '30m',
                                '1h',
                                '1h 30m',
                                '2h',
                                '2h 30m',
                                '3h',
                                '3h 30m',
                                '3h',
                                '3h 30m',
                              ],
                            },
                          }}
                        />
                      </div>
                      <div className="table-responsive">
                        <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f sorting">
                                ABM Tier
                              </th>
                              <th scope="col" className="bd-t-0-f sorting">
                                Collected
                              </th>
                              <th scope="col" className="bd-t-0-f sorting">
                                Launched
                              </th>
                              <th scope="col" className="bd-t-0-f sorting">
                                MQL
                              </th>
                              <th scope="col" className="bd-t-0-f sorting">
                                SQL
                              </th>
                              <th scope="col" className="bd-t-0-f sorting">
                                Opportunity
                              </th>
                              <th scope="col" className="bd-t-0-f sorting">
                                Purchased
                              </th>
                              <th scope="col" className="bd-t-0-f sorting">
                                Sales
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Tier 1</td>
                              <td>300</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>100</td>
                              <td>10</td>
                              <td>＄500</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer pd-y-6-f pagination">
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to first"
                        >
                          {Icon('move_first')}
                          <span className="sr-only">처음으로</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="previous"
                        >
                          <ChevronLeft />
                          <span className="sr-only">이전</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="next"
                        >
                          <ChevronRight />
                          <span className="sr-only">다음</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to last"
                        >
                          {Icon('move_last')}
                          <span className="sr-only">마지막으로</span>
                        </button>
                        <input type="number" className="paging-input" defaultValue="1" />/ 1
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Funnel Map */}
              {tab.value == 2 && (
                <div className="pd-20">
                  <div className="card ht-200"></div>
                  <div className="card mg-t-20">
                    <div className="table-responsive">
                      <table className="table table-bordered mg-b-0 bd-0 basic-table">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              ABM Tier
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Collected
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Launched
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              MQL
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              SQL
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Opportunity
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Sales
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td rowSpan={2}>Tier 1</td>
                            <td className="text-center">20</td>
                            <td className="text-center">15</td>
                            <td className="text-center">&nbsp;</td>
                            <td className="text-center">&nbsp;</td>
                            <td className="text-center">&nbsp;</td>
                            <td className="text-center">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="pd-5-f bg-success-light text-center">Dropped</td>
                            <td className="pd-5-f bg-success-light text-center">20%, 5</td>
                            <td className="pd-5-f bg-success-light text-center">&nbsp;</td>
                            <td className="pd-5-f bg-success-light text-center">&nbsp;</td>
                            <td className="pd-5-f bg-success-light text-center">&nbsp;</td>
                            <td className="pd-5-f bg-success-light text-center">&nbsp;</td>
                          </tr>
                          <tr>
                            <td rowSpan={2}>Tier 2</td>
                            <td className="text-center">50</td>
                            <td className="text-center">30</td>
                            <td className="text-center">&nbsp;</td>
                            <td className="text-center">&nbsp;</td>
                            <td className="text-center">&nbsp;</td>
                            <td className="text-center">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="pd-5-f bg-success-light text-center">Dropped</td>
                            <td className="pd-5-f bg-success-light text-center">40%, 20</td>
                            <td className="pd-5-f bg-success-light text-center">&nbsp;</td>
                            <td className="pd-5-f bg-success-light text-center">&nbsp;</td>
                            <td className="pd-5-f bg-success-light text-center">&nbsp;</td>
                            <td className="pd-5-f bg-success-light text-center">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer pd-y-6-f pagination">
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to first"
                      >
                        {Icon('move_first')}
                        <span className="sr-only">처음으로</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="previous"
                      >
                        <ChevronLeft />
                        <span className="sr-only">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="next"
                      >
                        <ChevronRight />
                        <span className="sr-only">다음</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to last"
                      >
                        {Icon('move_last')}
                        <span className="sr-only">마지막으로</span>
                      </button>
                      <input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}

              {/* Page views */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-bordered mg-b-0 bd-0 basic-table">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Page
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Page views
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unique Page views
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Avg. Time on Page
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              No. of Account
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Tier 1
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Tier 2
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Tier 3
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>A</td>
                            <td>100 (50%)</td>
                            <td>150 (100%)</td>
                            <td>00:20:00</td>
                            <td>Total: 170 (100%)</td>
                            <td>20 (100%)</td>
                            <td>50 (100%)</td>
                            <td>100 (100%)</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer pd-y-6-f pagination">
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to first"
                      >
                        {Icon('move_first')}
                        <span className="sr-only">처음으로</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="previous"
                      >
                        <ChevronLeft />
                        <span className="sr-only">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="next"
                      >
                        <ChevronRight />
                        <span className="sr-only">다음</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to last"
                      >
                        {Icon('move_last')}
                        <span className="sr-only">마지막으로</span>
                      </button>
                      <input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}

              {/* Contact Details */}
              {tab.value == 4 && (
                <div className="pd-20">
                  <div className="mg-b-10 text-right">
                    <Check className="tx-success" size={16} />: View
                  </div>
                  <div className="card">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Accounts
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Contacts
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Page 1
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Page 2
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Opportunity
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Sales
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>
                            <div className="dropdown dropright">
                              <button
                                type="button"
                                className="btn btn-link pd-0"
                                data-toggle="dropdown"
                              >
                                <span className="d-inline-block avatar avatar-sm mg-r-5">
                                  <img
                                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                                    className="rounded-circle"
                                    alt=""
                                  />
                                </span>
                                Allan Rey Palban
                              </button>
                              <div className="dropdown-menu pd-0">
                                <div className="dropdown-menu-header pd-10 bg-light bd-b tx-semibold">
                                  Allan Rey Palban
                                </div>
                                <div className="pd-20">
                                  <div className="timeline-group dropdown-timeline">
                                    <div className="timeline-label">2021/12/03</div>
                                    <div className="timeline-item">
                                      <div className="timeline-time">10:31 am</div>
                                      <div className="timeline-body">
                                        <p>Page 1 viewed</p>
                                      </div>
                                    </div>
                                    <div className="timeline-item">
                                      <div className="timeline-time">10:35 am</div>
                                      <div className="timeline-body">
                                        <p>Page 2 viewed</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <Check className="tx-success" size={16} />
                          </td>
                          <td>
                            <Check className="tx-success" size={16} />
                          </td>
                          <td>
                            <Circle className="tx-primary" size={16} />
                          </td>
                          <td>＄200</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="card-footer pd-y-6-f pagination">
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to first"
                      >
                        {Icon('move_first')}
                        <span className="sr-only">처음으로</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="previous"
                      >
                        <ChevronLeft />
                        <span className="sr-only">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="next"
                      >
                        <ChevronRight />
                        <span className="sr-only">다음</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to last"
                      >
                        {Icon('move_last')}
                        <span className="sr-only">마지막으로</span>
                      </button>
                      <input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}

              {/* Email Interactions */}
              {tab.value == 5 && (
                <div className="pd-20">
                  <div className="card card-body">
                    <ReactApexChart
                      type="line"
                      height={300}
                      series={[
                        {
                          name: 'Opened',
                          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                        },
                        {
                          name: 'Clicked',
                          data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                        },
                        {
                          name: 'Replied',
                          data: [0, 10, 10, 15, 20, 45, 30, 51, 80],
                        },
                      ]}
                      options={{
                        chart: {
                          zoom: {
                            enabled: false,
                          },
                        },
                        colors: [
                          'rgba(0,227,150, .8)',
                          'rgba(0,143,251, .8)',
                          'rgba(119, 93, 208,.8)',
                        ],
                        stroke: {
                          curve: 'straight',
                        },
                        title: {
                          text: 'Interactions',
                          align: 'left',
                        },
                        xaxis: {
                          categories: [
                            '30m',
                            '1h',
                            '1h 30m',
                            '2h',
                            '2h 30m',
                            '3h',
                            '3h 30m',
                            '3h',
                            '3h 30m',
                          ],
                        },
                      }}
                    />
                  </div>
                  <div className="mg-t-30 mg-b-10">
                    <button
                      type="button"
                      className="btn rounded-30 mg-r-5"
                      style={{
                        border: '1px solid rgba(0,227,150, .8)',
                        backgroundColor: interactions.opened
                          ? 'rgba(0,227,150, .8)'
                          : 'transparent',
                        color: interactions.opened ? '#fff' : 'rgb(0,227,150)',
                      }}
                      onClick={() => SetInteractions({ opened: true })}
                    >
                      Opened
                    </button>
                    <button
                      type="button"
                      className="btn rounded-30 mg-r-5"
                      style={{
                        border: '1px solid rgba(0,143,251,.8)',
                        backgroundColor: interactions.clicked
                          ? 'rgba(0,143,251,.8)'
                          : 'transparent',
                        color: interactions.clicked ? '#fff' : 'rgb(0,143,251)',
                      }}
                      onClick={() => SetInteractions({ clicked: true })}
                    >
                      Clicked
                    </button>
                    <button
                      type="button"
                      className="btn rounded-30 mg-r-5"
                      style={{
                        border: '1px solid rgba(119, 93, 208,.7)',
                        backgroundColor: interactions.replied
                          ? 'rgba(119, 93, 208,.7)'
                          : 'transparent',
                        color: interactions.replied ? '#fff' : 'rgb(119, 93, 208)',
                      }}
                      onClick={() => SetInteractions({ replied: true })}
                    >
                      Replied
                    </button>
                    <button
                      type="button"
                      className="btn rounded-30 mg-r-5"
                      style={{
                        border: '1px solid rgba(250,197,87,.8)',
                        backgroundColor: interactions.bounced
                          ? 'rgba(250,197,87,.8)'
                          : 'transparent',
                        color: interactions.bounced ? '#fff' : 'rgb(250,197,87)',
                      }}
                      onClick={() => SetInteractions({ bounced: true })}
                    >
                      Bounced
                    </button>
                  </div>
                  {(interactions.opened || interactions.clicked || interactions.replied) && (
                    <div className="card">
                      <div className="table-responsive rounded">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Recipients
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Email used
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Timestamp
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer pd-y-6-f pagination">
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to first"
                        >
                          {Icon('move_first')}
                          <span className="sr-only">처음으로</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="previous"
                        >
                          <ChevronLeft />
                          <span className="sr-only">이전</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="next"
                        >
                          <ChevronRight />
                          <span className="sr-only">다음</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to last"
                        >
                          {Icon('move_last')}
                          <span className="sr-only">마지막으로</span>
                        </button>
                        <input type="number" className="paging-input" defaultValue="1" />/ 1
                      </div>
                    </div>
                  )}
                  {interactions.bounced && (
                    <div className="card">
                      <div className="table-responsive rounded">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Recipients
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Email used
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Blocked reason
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Timestamp
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer pd-y-6-f pagination">
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to first"
                        >
                          {Icon('move_first')}
                          <span className="sr-only">처음으로</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="previous"
                        >
                          <ChevronLeft />
                          <span className="sr-only">이전</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="next"
                        >
                          <ChevronRight />
                          <span className="sr-only">다음</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to last"
                        >
                          {Icon('move_last')}
                          <span className="sr-only">마지막으로</span>
                        </button>
                        <input type="number" className="paging-input" defaultValue="1" />/ 1
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Target Account Collected */}
              {tab2.value == 1 && <TargetAccountCollected />}

              {/* Marketing Content */}
              {tab2.value == 2 && <MarketingContent />}

              {/* Journey Process */}
              {tab2.value == 3 && <div className="pd-20"></div>}

              {/* Lead Scoring Rule */}
              {tab2.value == 4 && <LeadScoringRule />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ABMView;
