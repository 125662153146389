import React, { useState } from 'react';

import { Calendar, X, Plus, User, Check, ChevronDown, UserCheck } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "DeskCss/desk.scss"

const WriteTicket = () => {
  const [showTagInput, setShowTagInput] = useState<any>();

  return (
    <>
      <div className="tx-right">
        <span className="tx-danger">*</span> is required.
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Subject<span className="tx-danger">*</span>
        </label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Customer</label>
        <div className="dropdown">
          <div className="input-group" data-toggle="dropdown">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
              <div className="form-control-tag">
                <span className="d-block avatar avatar-xs mg-r-10">
                  <span className="avatar-initial rounded-circle">L</span>
                </span>
                <span className="form-control-tag-name">Lee Tamin</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                placeholder="Type or click to select a customer"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open modal to select a customer">
                {Icon('m_customer')}
                <span className="sr-only">고객 선택 팝업 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button
              type="button"
              data-target="#newContactModal"
              data-toggle="modal"
              className="dropdown-item"
            >
              <Plus className="mg-r-5" />
              Add new customer
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              Lee Tamin
            </button>
            <button type="button" className="dropdown-item">
              Lee Suji
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Priority<span className="tx-danger">*</span>
        </label>
        <SelectHook
          className="wd-200-f"
          options={[
            { value: 1, label: 'Lower' },
            { value: 2, label: 'Low' },
            { value: 3, label: 'Medium' },
            { value: 4, label: 'High' },
            { value: 5, label: 'Urgent' },
          ]}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Category<span className="tx-danger">*</span>
        </label>
        <SelectHook />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Process<span className="tx-danger">*</span>
        </label>
        <SelectHook />
      </div>
      <div className="form-group">
        <label className="form-item-title">Assigned Rep</label>
        <div className="dropdown">
          <div className="input-group" data-toggle="dropdown">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
              <div className="form-control-tag">
                <span className="d-block avatar avatar-xs mg-r-10">
                  <img
                    src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                    alt="MSM"
                    className="img rounded-circle"
                  />
                </span>
                <span className="form-control-tag-name">MSM</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                placeholder="Type or click to select an user"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open modal to select an user">
                <User />
                <span className="sr-only">담당자 선택 팝업 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button type="button" className="dropdown-item">
              <UserCheck className="mg-r-5" />
              Assign to me
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              MSM
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">CC</label>
        <div className="dropdown">
          <div className="input-group" data-toggle="dropdown">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
              <div className="form-control-tag">
                <span className="d-block avatar avatar-xs mg-r-10">
                  <img
                    src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                    alt="MSR"
                    className="img rounded-circle"
                  />
                </span>
                <span className="form-control-tag-name">MSR</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                placeholder="Type or click to select an user"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open modal to select an user">
                <User />
                <span className="sr-only">담당자 선택 팝업 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button type="button" className="dropdown-item">
              <UserCheck className="mg-r-5" />
              CC me
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              KDH
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Due Date<span className="tx-danger">*</span>
        </label>
        <div className="wd-300">
          <DatePicker
            selected={new Date()}
            onChange={() => {
              console.log();
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Tag</label>
        <div className="dropdown">
          <div className="input-group" data-toggle="dropdown">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f form-control-tags">
              <span className="form-control-tag">
                <span className="form-control-tag-name">email</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </span>
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 rounded rounded"
                placeholder="Type or click to select a tag"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="toggle dropdown to select a tag">
                <ChevronDown />
                <span className="sr-only">태그 선택 옵션 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            {!showTagInput && (
              <button type="button" className="dropdown-item" onClick={() => setShowTagInput(true)}>
                <Plus className="mg-r-5" />
                Add new Tag
              </button>
            )}
            {showTagInput && (
              <div className="d-flex align-items-center pd-l-20 pd-r-10">
                <input type="text" className="form-control" />
                <button type="button" className="btn btn-sm btn-icon">
                  <Check className="tx-success" />
                  <span className="sr-only">추가</span>
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-link link-03 btn-icon"
                  onClick={() => setShowTagInput(false)}
                >
                  <X />
                  <span className="sr-only">취소</span>
                </button>
              </div>
            )}
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              Problem
            </button>
            <button type="button" className="dropdown-item">
              Refund
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Channel</label>
        <SelectHook />
      </div>
      <div className="form-group">
        <label className="form-item-title">Product</label>
        <div className="input-group">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="form-control-tag-name">Product 111</span>
              <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                <X />
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250 rounded"
              placeholder="Type or click to select a product"
            />
          </div>
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open modal to select a product">
              {Icon('product')}
              <span className="sr-only">상품 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Policy</label>
        <SelectHook />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Content<span className="tx-danger">*</span>
        </label>
        <div>Editor area</div>
      </div>
    </>
  );
};

export default WriteTicket;
