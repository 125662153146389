import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ChevronLeft,
  ChevronRight,
  ArrowLeft,
  Repeat,
  Copy,
  Edit2,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
import '../style.scss';

import Attachments from '../../../../component/desktop/attachments';
import AssignedRep from '../../../../component/desktop/assigned-rep';
import Userlist from '../../../../component/desktop/userlist';

import ViewBasicInfo from './view/basicInfo';
import ViewDetailInfo from './view/detailInfo';
import ViewTransaction from './view/transaction';
import ViewStockMovement from './view/stockMovement';
import ViewInventory from './view/inventory';
import ViewPurchase from '../view-new/purchase';
import ViewUsed from './view/used';

import NewProduct from '../modal/newProduct';
import NewItem from '../modal/newItem';
import NewComponent from '../modal/newComponent';
import NewUnit from '../modal/newUnit';
import NewPriceList from '../modal/newPriceList';
import NewDiscount from '../modal/newDiscount';
import NewTradeAgreement from '../modal/newTradeAgreement';
import NewProductImg from '../modal/newProductImage';

const TABS = [
  {
    value: 1,
    label: 'Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Purchase',
  },
  {
    value: 4,
    label: 'Inventory',
  },
  {
    value: 5,
    label: 'Stock Movement',
  },
  {
    value: 6,
    label: 'Transaction',
  },
  {
    value: 7,
    label: 'Where Used Tab',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const View = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/customer/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Components
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub"></div>
          </div>
          <h1 className="view-title">Component 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Copy />
                    </span>
                    Clone
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#adjustStockModal"
                  >
                    <span className="dropdown-item-icon">{Icon('adjust_stock')}</span>Adjust Stock
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#transferOrderModal"
                  >
                    <span className="dropdown-item-icon">
                      <Repeat />
                    </span>
                    Transfer Order
                  </button>
                </div>
              </div>
            </div>
            <div className="btn-group dropdown mg-l-5">
              <button
                type="button"
                className="btn btn-primary pd-x-10"
                data-toggle="modal"
                data-target="#newComponent"
              >
                <Plus />
                <span className="sr-only">추가</span>
              </button>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <ChevronDown />
                <span className="sr-only">추가 옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newProduct"
                >
                  Product
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newItem"
                >
                  Item
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newComponent"
                >
                  Component
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newUnit"
                >
                  Unit
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newPriceList"
                >
                  Price List
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newDiscount"
                >
                  Discount
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newTradeAgreement"
                >
                  Trade Agreement
                </button>
              </div>
            </div>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}
              <div className="view-aside-section pd-15">
                <div className="mg-b-10 text-right">
                  <button
                    type="button"
                    className="btn btn-link pd-y-0"
                    data-toggle="modal"
                    data-target="#productImageModal"
                  >
                    <Edit2 className="mg-r-5" />
                    Edit
                  </button>
                </div>
                <div className="view-product-pic mg-b-20">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxk47d99qf_o3faJPuqsQ7sFrwfCIBhrd-VA&usqp=CAU"
                    className="rounded"
                    alt=""
                  />
                </div>
                <div className="view-product-pic-thumb-list-wrap mg-b-20">
                  <button type="button" className="btn btn-xs btn-white btn-icon">
                    <ChevronLeft />
                    <span className="sr-only">이전</span>
                  </button>
                  <ul className="view-product-pic-thumb-list">
                    <li className="view-product-pic-thumb-list-item active">
                      <button type="button" className="view-product-pic-thumb">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxk47d99qf_o3faJPuqsQ7sFrwfCIBhrd-VA&usqp=CAU"
                          className="rounded"
                          alt=""
                        />
                      </button>
                    </li>
                    <li className="view-product-pic-thumb-list-item">
                      <button type="button" className="view-product-pic-thumb">
                        <img
                          src="https://d2d8wwwkmhfcva.cloudfront.net/400x/d2lnr5mha7bycj.cloudfront.net/product-image/file/large_1df3f7ef-8e7d-4596-9535-3eed0811a47b.jpg"
                          className="rounded"
                          alt=""
                        />
                      </button>
                    </li>
                    <li className="view-product-pic-thumb-list-item">
                      <button type="button" className="view-product-pic-thumb">
                        <img
                          src="https://d2d8wwwkmhfcva.cloudfront.net/400x/d2lnr5mha7bycj.cloudfront.net/product-image/file/large_8875b734-de1b-461d-b7cf-569823a0e7ef.png"
                          className="rounded"
                          alt=""
                        />
                      </button>
                    </li>
                  </ul>
                  <button type="button" className="btn btn-xs btn-white btn-icon">
                    <ChevronRight />
                    <span className="sr-only">다음</span>
                  </button>
                </div>
              </div>

              {/* Basic Info */}
              <ViewBasicInfo />
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}
              {tab.value == 1 && <ViewDetailInfo />}
              {tab.value == 3 && <ViewPurchase />}
              {tab.value == 4 && <ViewInventory />}
              {tab.value == 5 && <ViewStockMovement />}
              {tab.value == 6 && <ViewTransaction />}
              {tab.value == 7 && <ViewUsed />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}
              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Customer */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Related Customer</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerContact"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerContact" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Vendor */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Related Vendor</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerVendor"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerVendor" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <NewProduct />
      <NewItem />
      <NewComponent />
      <NewUnit />
      <NewPriceList />
      <NewDiscount />
      <NewTradeAgreement />
      <NewProductImg />
    </>
  );
};

export default View;
