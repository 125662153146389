import { Input, Select as SelectHook } from '@base/components/form';
import Stage from './containers/stage';
import AutomaticStatus from './containers/automaticStatus';

const StepCriteria = () => {
  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Step Type</label>
        <div>Rule Criteria</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Step Name</label>
        <Input />
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Stage</label>
        <Stage className="wd-300-f" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Rule Criteria Name</label>
        <SelectHook />
      </div>
      <AutomaticStatus />
    </>
  );
};

export default StepCriteria;
