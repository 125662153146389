import { ChevronDown, ChevronUp, Plus, Settings, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const NewInvComponent = () => {
  return (
    <div id="newInvComponent" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Component</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="text-right">
              <span className="tx-danger">*</span>is required
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Component Name<span className="tx-danger">*</span>
              </label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Component Category</label>
              <div>Inventory</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Component Type<span className="tx-danger">*</span>
              </label>
              <SelectHook
                options={[
                  { value: 1, label: 'Part' },
                  { value: 2, label: 'Raw Material' },
                  { value: 3, label: 'Intermediate' },
                  { value: 4, label: 'Phantom Item' },
                ]}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Component Group<span className="tx-danger">*</span>
              </label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">
                Component ID<span className="tx-danger">*</span>
              </label>
              <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open generator of code modal"
                    data-toggle="modal"
                    data-target="#productGenCodeModal"
                  >
                    <Settings />
                    <span className="sr-only">코드 생성 설정 열기</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title d-block">Supply Method</label>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="supplyMethodRadio1"
                  name="supplyMethodRadio"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="supplyMethodRadio1">
                  Purchased
                </label>
              </div>
              <div className="mg-t-10">
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input type="checkbox" className="custom-control-input" id="supplyMethodCheck1" />
                  <label className="custom-control-label" htmlFor="supplyMethodCheck1">
                    Can be sold
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input type="checkbox" className="custom-control-input" id="supplyMethodCheck2" />
                  <label className="custom-control-label" htmlFor="supplyMethodCheck2">
                    Service Part
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input type="checkbox" className="custom-control-input" id="supplyMethodCheck3" />
                  <label className="custom-control-label" htmlFor="supplyMethodCheck3">
                    MRO
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="componentActiveSwitch"
                />
                <label className="custom-control-label" htmlFor="componentActiveSwitch"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Base Unit<span className="tx-danger">*</span>
              </label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Attributes<span className="tx-danger">*</span>
              </label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="productAttribute" />
                <label className="custom-control-label" htmlFor="productAttribute">
                  Create Item with Attributes
                </label>
              </div>
              <div className="row row-xs mg-t-10">
                <div className="col-6">
                  <label>Attribute</label>
                  <SelectHook />
                </div>
                <div className="col-6">
                  <label>value</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Cost of Goods</label>
              <div className="d-flex align-items-center">
                Approximately
                <div className="input-group wd-100 mg-x-10">
                  <input type="number" className="form-control" />
                  <div className="input-group-append">
                    <span className="input-group-text lh-1">%</span>
                  </div>
                </div>
                of Base Price
              </div>
              <div className="mg-t-5 tx-orange">
                The Cost to physically produce/purchase/service/delivery
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light mg-l-auto" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInvComponent;
