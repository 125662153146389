import { NavLink } from 'react-router-dom';
import demoLink from './publicRoute';
import { CardCollapse } from '@base/components/form';

const demoPage = () => {
  //console.log(demoLink);
  const renderMenu = (menu: string, header: any) => {
    return (
      <div className="col-sm-3 col-md-4 mg-b-10">
        <CardCollapse headerTitle={header} defaultOpen={false}>
          <div className="pd-10">
            {demoLink.map(
              (page) =>
                page.menu === menu && (
                  <div className="mg-l-10 mg-t-5" key={page.path}>
                    <NavLink to={page.path}>{page.title}</NavLink>
                  </div>
                ),
            )}
          </div>
        </CardCollapse>
      </div>
    );
  };

  return (
    <div style={{ height: 'calc(100vh - 65px)', overflowY: 'auto', overflowX: 'hidden' }}>
      <div className="pd-10 row">
        {renderMenu('customer', { title: 'Customer', icon: 'm_customer', iconType: 'main' })}
        {renderMenu('activity', { title: 'Activity', icon: 'activity', iconType: 'main' })}
        {renderMenu('product', { title: 'Product', icon: 'product', iconType: 'main' })}
        {renderMenu('purchase', { title: 'Purchase', icon: 'purchase', iconType: 'main' })}
        {renderMenu('order', { title: 'Order', icon: 'order', iconType: 'main' })}
        {renderMenu('invoice', { title: 'Invoice', icon: 'invoice', iconType: 'main' })}
        {renderMenu('inventory', { title: 'Inventory', icon: 'inventory', iconType: 'main' })}
        {renderMenu('desk', { title: 'Desk', icon: 'helpdesk', iconType: 'main' })}
        {renderMenu('marketing', { title: 'Marketing', icon: 'marketing', iconType: 'main' })}
        {renderMenu('sales', { title: 'Sales', icon: 'sales', iconType: 'main' })}
        {renderMenu('settings', { title: 'Settings', icon: 'Settings', iconType: 'feather' })}
        {renderMenu('base', { title: 'Base Components', icon: 'Award', iconType: 'feather' })}
        {renderMenu('site', { title: 'Site', icon: 'Link', iconType: 'feather' })}
        {renderMenu('report', { title: 'Report', icon: 'BarChart2', iconType: 'feather' })}
      </div>
    </div>
    // <div style={{ height: 'calc(100vh - 65px)', overflowY: 'auto' }}>
    //     {demoLink.map((page) => (
    //       <div className="mg-l-10 mg-t-5" key={page.path}>
    //         <NavLink to={page.path}>{page.title}</NavLink>
    //       </div>
    //     ))}
    //   </div>
  );
};

export default demoPage;

/*
{demoLink.map((page) => (
  <NavLink key={page.path} to={page.path}>
    {page.title}
  </NavLink>
))}
*/
