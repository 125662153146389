import { ChevronUp, Image, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const NewSpeaker = () => {
  return (
    <div
      id="newSpeaker"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Speaker</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="d-flex align-items-start">
              <div className="d-flex align-items-center justify-content-center">
                <button className="d-flex flex-column align-items-center justify-content-center wd-150 ht-150 bd bd-dashed rounded bg-light tx-center tx-color-03">
                  <Image size="40" strokeWidth="1" />
                  Drag &amp; Drop or Browse
                </button>
              </div>
              <div className="flex-grow-1 mg-l-15">
                <div className="form-group">
                  <label className="form-item-title">Speaker Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Speaker Type</label>
                  <SelectHook
                    options={[
                      { value: 1, label: 'Internal' },
                      { value: 2, label: 'External' },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Company Name</label>
              <div className="input-group">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    {Icon('m_customer_account')}
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Email</label>
              <input type="email" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Mobile</label>
              <input type="tel" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Speaker Cost</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text lh-1">＄</span>
                </div>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Biography</label>
              <div>???</div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSpeaker;
