/* eslint-disable prettier/prettier */
import { Button, Checkbox, Input, RadioGroup, Select, Switch } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { useState } from 'react';
import { Folder, Plus, Trash } from 'react-feather';

// import "CrmCss/desktop/theme1/theme1.scss";

const Product = () => {
  const DEMENSION_OPTIONS = [
    { value: 1, label: 'cm' },
    { value: 2, label: 'in' },
  ];
  const [dimensionOptions, setDimensionOptions] = useState(DEMENSION_OPTIONS);
  const [selectedDimensionOption, setSelectedDimensionOption] = useState(DEMENSION_OPTIONS[0]);
  //change
  const handleDimensionOptionChange = (newOption: any) => {
    setSelectedDimensionOption(newOption);
  };

  const WEIGHT_OPTIONS = [
    { value: 1, label: 'kg' },
    { value: 2, label: 'lb' },
  ];
  const [weightOptions, setWeightOptions] = useState(WEIGHT_OPTIONS);
  const [selectedWeightOption, setSelectedWeightOption] = useState(WEIGHT_OPTIONS[0]);
  //change
  const handleWeightOptionChange = (newOption: any) => {
    setSelectedWeightOption(newOption);
  };

  const [showNoti, setShowNoti] = useState(true);
  const [showRename, setShowRename] = useState(false);
  const [showSubRename, setShowSubRename] = useState(false);
  const [addRoot, setAddRoot] = useState(false);
  const [addSub, setAddSub] = useState(false);

  return (
    <>
      <div className="card-columns settings-columns">
        {/* Product Group */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Product Group</div>
          </div>
          <div className="card-body">
            <ul className="list-unstyled">
              <li>
                <div className="d-flex">
                  <div className={`d-flex align-items-center ${showRename ? 'flex-grow-1' : ''}`}>
                    <Button
                      color="link link-02"
                      className="mg-r-10 pd-0"
                      icon="ChevronDown"
                      data-toggle="collapse"
                      data-target="#sub1"
                    />
                    <Folder
                      color="orange"
                      fill="rgba(253,126,20,.3)"
                      size={20}
                      className="mg-r-10"
                    />
                    <div
                      contentEditable={showRename}
                      className={`${
                        showRename ? 'flex-grow-1 pd-10 bd bd-dashed rounded' : 'text-truncate'
                      }`}
                    >
                      Group 1
                    </div>
                    {showRename && (
                      <>
                        <Button
                          color=""
                          size="sm"
                          icon="Check"
                          iconClass="tx-success"
                          className="btn-icon"
                          data-han-tooltip="OK"
                        />
                        <Button
                          color="link link-03"
                          size="sm"
                          icon="X"
                          className="btn-icon"
                          data-han-tooltip="Cancel"
                          onClick={() => setShowRename(false)}
                        />
                      </>
                    )}
                  </div>
                  {!showRename && (
                    <div className="mg-l-auto">
                      <Button
                        color="link"
                        size="xs"
                        className="btn-icon"
                        icon="Plus"
                        data-han-tooltip="Add Group"
                        onClick={() => setAddSub(true)}
                      />
                      <Button
                        color="link link-03"
                        size="xs"
                        className="btn-icon"
                        icon="Minus"
                        iconClass="tx-danger"
                        data-han-tooltip="Delete"
                      />
                      <Button
                        color="link link-03"
                        size="xs"
                        className="btn-icon"
                        icon="Edit2"
                        data-han-tooltip="Rename"
                        onClick={() => setShowRename(true)}
                      />
                      <Button
                        color="link link-03"
                        size="xs"
                        className="btn-icon"
                        icon="move"
                        iconType="custom"
                        data-han-tooltip="Move"
                      />
                    </div>
                  )}
                </div>
                <ul id="sub1" className="collapse show list-unstyled mg-y-5 mg-l-50">
                  <li>
                    <div className="d-flex">
                      <div
                        className={`d-flex align-items-center ${
                          showSubRename ? 'flex-grow-1' : ''
                        }`}
                      >
                        <Folder
                          color="orange"
                          fill="rgba(253,126,20,.3)"
                          size={20}
                          className="mg-r-10"
                        />
                        <div
                          contentEditable={showSubRename}
                          className={`${
                            showSubRename
                              ? 'flex-grow-1 pd-10 bd bd-dashed rounded'
                              : 'text-truncate'
                          }`}
                        >
                          Group 1-1
                        </div>
                        {showSubRename && (
                          <>
                            <Button
                              color=""
                              size="sm"
                              icon="Check"
                              iconClass="tx-success"
                              className="btn-icon"
                              data-han-tooltip="OK"
                            />
                            <Button
                              color="link link-03"
                              size="sm"
                              icon="X"
                              className="btn-icon"
                              data-han-tooltip="Cancel"
                              onClick={() => setShowSubRename(false)}
                            />
                          </>
                        )}
                      </div>
                      {!showSubRename && (
                        <div className="mg-l-auto">
                          <Button
                            color="link"
                            size="xs"
                            className="btn-icon"
                            icon="Plus"
                            data-han-tooltip="Add Group"
                          />
                          <Button
                            color="link link-03"
                            size="xs"
                            className="btn-icon"
                            icon="Minus"
                            iconClass="tx-danger"
                            data-han-tooltip="Delete"
                          />
                          <Button
                            color="link link-03"
                            size="xs"
                            className="btn-icon"
                            icon="Edit2"
                            data-han-tooltip="Rename"
                            onClick={() => setShowSubRename(true)}
                          />
                          <Button
                            color="link link-03"
                            size="xs"
                            className="btn-icon"
                            icon="move"
                            iconType="custom"
                            data-han-tooltip="Move"
                          />
                        </div>
                      )}
                    </div>
                  </li>
                  {addSub && (
                    <li>
                      <div className="d-flex align-items-center mg-y-5">
                        <Folder
                          color="orange"
                          fill="rgba(253,126,20,.3)"
                          size={20}
                          className="mg-r-10"
                        />
                        <div className="message-input-container flex-grow-1">
                          <div className="message-input_inner">
                            <div className="message-input" contentEditable></div>
                            <div className="message-input-placeholder" tabIndex={-1}>
                              Group Name
                            </div>
                          </div>
                        </div>
                        <Button
                          color=""
                          size="sm"
                          icon="Check"
                          iconClass="tx-success"
                          className="btn-icon"
                          data-han-tooltip="OK"
                        />
                        <Button
                          color="link link-03"
                          size="sm"
                          icon="X"
                          className="btn-icon"
                          data-han-tooltip="Cancel"
                          onClick={() => setAddSub(false)}
                        />
                      </div>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <Button
                      color="link link-02"
                      className="mg-r-5 pd-0 collapsed"
                      icon="ChevronDown"
                      data-toggle="collapse"
                    />
                    <Folder
                      color="orange"
                      fill="rgba(253,126,20,.3)"
                      size={20}
                      className="mg-r-10"
                    />
                    <div className="text-truncate">Group 2</div>
                  </div>
                  <div className="mg-l-auto">
                    <Button
                      color="link"
                      size="xs"
                      className="btn-icon"
                      icon="Plus"
                      data-han-tooltip="Add Group"
                    />
                    <Button
                      color="link link-03"
                      size="xs"
                      className="btn-icon"
                      icon="Minus"
                      iconClass="tx-danger"
                      data-han-tooltip="Delete"
                    />
                    <Button
                      color="link link-03"
                      size="xs"
                      className="btn-icon"
                      icon="Edit2"
                      data-han-tooltip="Rename"
                    />
                    <Button
                      color="link link-03"
                      size="xs"
                      className="btn-icon"
                      icon="move"
                      iconType="custom"
                      data-han-tooltip="Move"
                    />
                  </div>
                </div>
              </li>
              {addRoot && (
                <li>
                  <div className="d-flex align-items-center mg-y-5">
                    <Button
                      color="link link-02"
                      className="mg-r-5 pd-0 collapsed"
                      icon="ChevronDown"
                      data-toggle="collapse"
                    />
                    <Folder
                      color="orange"
                      fill="rgba(253,126,20,.3)"
                      size={20}
                      className="mg-r-10"
                    />
                    <div className="message-input-container flex-grow-1">
                      <div className="message-input_inner">
                        <div className="message-input" contentEditable></div>
                        <div className="message-input-placeholder" tabIndex={-1}>
                          Group Name
                        </div>
                      </div>
                    </div>
                    <Button
                      color=""
                      size="sm"
                      icon="Check"
                      iconClass="tx-success"
                      className="btn-icon"
                      data-han-tooltip="OK"
                    />
                    <Button
                      color="link link-03"
                      size="sm"
                      icon="X"
                      className="btn-icon"
                      data-han-tooltip="Cancel"
                      onClick={() => setAddRoot(false)}
                    />
                  </div>
                </li>
              )}
            </ul>
            <Button
              color="link"
              size="sm"
              icon="Plus"
              iconClass="mg-r-5"
              name="Add"
              className="pd-x-0"
              onClick={() => setAddRoot(true)}
            />
          </div>
        </div>

        {/* Item Measurement */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Item Measurement</div>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label className="form-item-titme">Default Dimension</label>
              <RadioGroup
                value={selectedDimensionOption.value}
                options={dimensionOptions}
                onChange={handleDimensionOptionChange}
              />
            </div>
            <div className="form-group">
              <label className="form-item-titme">Default Weight</label>
              <RadioGroup
                value={selectedWeightOption.value}
                options={weightOptions}
                onChange={handleWeightOptionChange}
              />
            </div>
          </div>
        </div>

        {/* Cost of Goods */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">
              Cost of Goods
              <br />
              <small className="mg-t-5 tx-color-03 tx-normal">
                The Cost to physically produce/purchase/service/delivery
              </small>
            </div>
          </div>
          <div className="card-body d-flex align-items-center">
            Approximately
            <Input rightIcon="%" className="wd-100 mg-x-10" />
            Of Base Price
          </div>
        </div>

        {/* Notification */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">
              Notification
              <br />
              <small className="mg-t-5 tx-color-03 tx-normal">
                Send Notification to Assigned Reps
              </small>
            </div>
            <Switch
              value={showNoti}
              onChange={() => setShowNoti(!showNoti)}
              className="mg-l-auto"
            />
          </div>
          {showNoti && (
            <div className="table-responsive">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Notification
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Email
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      SMS
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Notification
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Notify to
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Checkbox label="Send notification on Sales End Date" />
                      <div className="d-flex align-items-center mg-t-5 mg-l-30">
                        <Input type="number" rightIcon="day(s)" className="wd-150 mg-r-10" /> before
                      </div>
                    </td>
                    <td className="text-center">
                      <Checkbox />
                    </td>
                    <td className="text-center">
                      <Checkbox />
                    </td>
                    <td className="text-center">
                      <Checkbox />
                    </td>
                    <td>
                      <UserAutoComplete showAvatar />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Checkbox label="Send notification on Experation Date(Best Before Date)" />
                      <div className="d-flex align-items-center mg-t-5 mg-l-30">
                        <Input type="number" rightIcon="day(s)" className="wd-150 mg-r-10" /> before
                      </div>
                    </td>
                    <td className="text-center">
                      <Checkbox />
                    </td>
                    <td className="text-center">
                      <Checkbox />
                    </td>
                    <td className="text-center">
                      <Checkbox />
                    </td>
                    <td>
                      <UserAutoComplete showAvatar />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Attributes */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Attributes</div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Attributes
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Value
                  </th>
                  <th scope="col" className="wd-70 bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Input type="type" value="Color" />
                  </td>
                  <td>
                    <Input type="type" value="Bl, Wh" />
                  </td>
                  <td>
                    <Button color="link" icon="X" iconClass="tx-danger" className="btn-icon pd-0" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer d-flex align-items-center">
            <Input placeholder="Enter the attribute" />
            <Button
              color="primary"
              icon="Plus"
              iconClass="mg-r-5"
              className="flex-shrink-0 mg-l-10"
              name="Add"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
