/* eslint-disable prettier/prettier */
import { useState } from 'react';
import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  X,
  Plus,
  User,
  Settings,
  Menu,
  RotateCcw,
} from 'react-feather';

import Pagination from '../../../../component/desktop/pagination-new';
import { Button } from '@base/components/form';

const ProList = () => {
  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Base Cost</h1>
          <button
            type="button"
            className="btn btn-white btn-icon mg-l-auto"
            data-han-tooltip="reload"
          >
            <RefreshCw />
            <span className="sr-only">새로고침</span>
          </button>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Product Group 1
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">Product Group 1</button>
                <button className="dropdown-item">Product Group 2</button>
                <button className="dropdown-item">Product Group 3</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Date by: ?
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">?</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filter: ?
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">?</button>
              </div>
            </div>
          </div>

          {/* Search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Item" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="dropdown mg-l-5">
            <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
              {Icon('column_settings')}
              <span className="sr-only">항목 설정 열기</span>
            </button>
            <div className="dropdown-menu column-settings">
              <div className="column-settings-title">Column Settings</div>
              <ul className="list-group">
                <li className="list-group-item">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="columnCheck2"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="columnCheck2">
                      Product
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon handler"
                    aria-label="change the order"
                  >
                    <Menu />
                    <span className="드래그하여 순서 변경"></span>
                  </button>
                </li>
              </ul>
              <div className="column-settings-actions">
                Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                <button type="button" className="btn btn-light mg-l-auto">
                  Close
                </button>
                <button type="button" className="btn btn-primary mg-l-5">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="list-body">
          <div className="table-responsive bg-white bd rounded">
            <table className="table table-bordered dataTable mg-b-0 bd-0">
              <thead>
                <tr>
                  <th scope="col" className="sorting">
                    Product
                  </th>
                  <th scope="col" className="sorting">
                    Item
                  </th>
                  <th scope="col" className="sorting">
                    Attribute
                  </th>
                  <th scope="col" className="sorting">
                    Base Price
                  </th>
                  <th scope="col" className="sorting">
                    Cost of Goods
                  </th>
                  <th scope="col" className="sorting_desc">
                    Last Modified on
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Product 1</td>
                  <td>Item 1</td>
                  <td>&nbsp;</td>
                  <td>6,000</td>
                  <td>
                    1,800(30%)
                    <Button
                      color="link"
                      size="xs"
                      icon="m_activity_history"
                      iconType="custom"
                      data-han-tooltip="History"
                      className="btn-icon han-tooltip--top"
                    />
                    <Button
                      color="link"
                      size="xs"
                      icon="Edit2"
                      data-han-tooltip="Modify"
                      className="btn-icon han-tooltip--top"
                    />
                  </td>
                  <td className="sorting-cell">2022-07-07</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Pagination />
        </div>
      </div>
    </>
  );
};

export default ProList;
