import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ChevronsRight,
  CheckCircle,
  Circle,
  ArrowLeft,
  AlertTriangle,
  X,
  CornerDownRight,
  ChevronLeft,
  Check,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { COLORS } from '@base/config/constant';

// import "CrmCss/desktop/theme1/theme1.scss"

import Attachments from '../../../../component/attachmentslist';
import MemberList from '../memberlist';
import Notelist from '../../../../component/desktop/comment';
import Timeline from '../timeline';
import Test from './modal/test';
import NewCampaign from './modal/newCampaign';

import '../marketing.scss';

const TABS = [
  {
    value: 1,
    label: ' Recipients',
  },
  {
    value: 2,
    label: 'Email Content',
  },
  {
    value: 3,
    label: 'Configuration',
  },
  {
    value: 4,
    label: 'Links',
  },
  {
    value: 5,
    label: 'Timeline',
  },
  {
    value: 6,
    label: 'Activities',
  },
  {
    value: 7,
    label: 'Note',
  },
];

const Unfinished = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [showSetABTest, setShowSetABTest] = useState<any>(true);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  const [submitApproval, setSubmitApproval] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a href="/campaign/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Campaign
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">Campaign 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              {!submitApproval && (
                <>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => setSubmitApproval(true)}
                  >
                    Submit for Approval
                  </button>
                  <button
                    type="button"
                    className="btn btn-info mg-l-5"
                    data-toggle="modal"
                    data-target="#testModal"
                  >
                    Test
                  </button>
                </>
              )}
              {submitApproval && (
                <>
                  <button type="button" className="btn btn-info">
                    Send now
                  </button>
                </>
              )}
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newCampaignModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b">
          <div className="d-flex pd-15 bg-light rounded">
            <span className="tx-semibold">Business Process</span>
            <ol className="list-unstyled process-wrap text-nowrap">
              <li className="process-item done">
                <button type="button" className="btn process-item-btn">
                  <Check />
                </button>
                <div className="process-item-text mg-t-10">Planning</div>
              </li>
              <li className="process-item current">
                <button type="button" className="btn process-item-btn">
                  <CheckCircle />
                </button>
                <div className="process-item-text mg-t-10">Coordination</div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Customer Journey</div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Approval</div>
              </li>
              <li className="process-item">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Campaign Open</div>
              </li>
            </ol>
          </div>
          <div className="mg-t-10 pd-15 bg-light rounded">
            <div className="d-flex">
              <span className="tx-semibold mg-r-10">Journey Process</span>
              Process 1
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto pd-0 collapsed"
                data-toggle="collapse"
                data-target="#journeyProcess"
              >
                <ChevronDown />
              </button>
            </div>
            <div id="journeyProcess" className="collapse">
              &nbsp;
            </div>
          </div>
        </div>

        <div className="row no-gutters flex-nowrap flex-grow-1 overflow-auto">
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Sender</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/10/1"
                          className="rounded-circle"
                          alt="KDH"
                        />
                      </div>
                      <div className="media-body mg-l-10">KDH</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Reply Tracking</label>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="replyTrackingSwitch"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="replyTrackingSwitch"></label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Reply-to</label>
                    <div>sales@hanbiro.com</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Allow A/B Test</label>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="allowABTestSwitch"
                        checked={showSetABTest}
                        onChange={() => setShowSetABTest(!showSetABTest)}
                      />
                      <label className="custom-control-label" htmlFor="allowABTestSwitch"></label>
                    </div>
                  </div>
                  {showSetABTest && (
                    <>
                      <div className="form-group">
                        <label className="form-item-title">Specific Context</label>
                        <div>On Content</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Winning Strategy</label>
                        <div>Best Click through rate</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Breakdown for each variant</label>
                        <ul className="list-unstyled">
                          <li className="d-flex align-items-center mg-b-10">
                            Variant A <strong className="mg-l-auto mg-r-10 tx-info">10</strong>%
                          </li>
                          <li className="d-flex align-items-center mg-b-10">
                            Variant B <strong className="mg-l-auto mg-r-10 tx-orange">10</strong>%
                          </li>
                          <li className="d-flex align-items-center mg-b-10">
                            Remaining <strong className="mg-l-auto mg-r-10 tx-danger">80</strong>%
                          </li>
                          <li className="d-flex align-items-center">Test duration 5 hours</li>
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="pd-15 bg-white bd-b text-center">
              <p>
                <AlertTriangle size={16} className="mg-r-5 tx-danger" />
                The configuration is not completed.
              </p>
              <button type="button" className="btn btn-lg btn-primary">
                Continue Configuration
              </button>
            </div>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="table-responsive bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Source
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Source Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Account
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Email
                          </th>
                          <th scope="col" className="wd-70 bd-t-0-f">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Contact</td>
                          <td>Group A</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>
                            <button type="button" className="btn btn-icon pd-0">
                              <X className="tx-danger" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {tab.value == 2 && <></>}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="form-group">
                    <label className="form-item-title">Send Schedule</label>
                    <div>
                      Send now
                      <br />
                      Send in Batches: interval 5minute(s)
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">
                      Expenses to include in Marketing Investment
                    </label>
                    <div className="table-responsive bg-white bd rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Expense Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Expense
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Return on Marketing Investment</label>
                    <div>Gross Profile</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Key Performance Indicator</label>
                    <div>Click through rate 50%</div>
                  </div>
                </div>
              )}
              {tab.value == 4 && (
                <div className="pd-20">
                  <div className="form-group-title mg-t-0-f">CTA embedded</div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Preview</label>
                    <img src="assets/images/cta-sample-2.png" alt="" style={{ width: '300px' }} />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Type</label>
                    <div>Image</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">CTA Name</label>
                    <div>CTA 1</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Personalized URL</label>
                    <div className="d-flex">
                      <CornerDownRight strokeWidth={1} />
                      <div className="wd-100p">
                        <div className="card bg-light mg-b-15">
                          <div className="card-header pd-y-10">Link</div>
                          <div className="card-body">
                            <div className="form-group">
                              <label className="form-item-title">Landing Page/Site</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Type</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Page Title</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group-title mg-t-0">Embedded CTA on this page</div>
                            <div className="form-group">
                              <label className="form-item-title">Type</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Name</label>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <CornerDownRight strokeWidth={1} />
                          <div className="wd-100p">
                            <div className="card bg-light">
                              <div className="card-header pd-y-10">Link</div>
                              <div className="card-body">
                                <div className="form-group">
                                  <label className="form-item-title">Offer Site</label>
                                  <div>&nbsp;</div>
                                </div>
                                <div className="form-group">
                                  <label className="form-item-title">Type</label>
                                  <div>&nbsp;</div>
                                </div>
                                <div className="form-group">
                                  <label className="form-item-title">Page Title</label>
                                  <div>&nbsp;</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tab.value == 5 && <Timeline />}
              {tab.value == 6 && (
                <div className="pd-20">
                  <div className="text-right mg-b-10">
                    <button type="button" className="btn btn-primary rounded-30">
                      <Plus className="mg-r-5" />
                      Activity
                    </button>
                  </div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f sorting">
                              Subject
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Type
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Distribution
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer pd-y-6-f pagination">
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to first"
                      >
                        {Icon('move_first')}
                        <span className="sr-only">처음으로</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="previous"
                      >
                        <ChevronLeft />
                        <span className="sr-only">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="next"
                      >
                        <ChevronsRight />
                        <span className="sr-only">다음</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to last"
                      >
                        {Icon('move_last')}
                        <span className="sr-only">마지막으로</span>
                      </button>
                      <input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}
              {tab.value == 7 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Members */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Members</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#members"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="members" className="view-aside-section-body collapse show">
                  <MemberList />
                </div>
              </div>

              {/* Offer */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Offer</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#offer"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="offer" className="view-aside-section-body collapse show">
                  <Attachments />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Test />
      <NewCampaign />
    </>
  );
};

export default Unfinished;
