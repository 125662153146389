import { AlertTriangle, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const SwitchItem = () => {
  return (
    <div
      id="switchItem"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Switch Item</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <div className="d-flex align-items-center mg-b-10">
                <label className="form-item-title">Switch from</label>
                <button type="button" className="btn btn-info mg-l-auto">
                  <span className="mg-r-5">{Icon('scan_barcode')}</span>Scan Barcode
                </button>
              </div>
              <div className="table-responsive bg-white bd rounded mg-t-10">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f wd-60">
                        Image
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Items
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Unit Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Unit Qty
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Switchable Qty
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Qty to Swich
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="avatar avatar-sm bg-gray-200 rounded">
                          <img src="assets/images/notfound.png" alt="" />
                        </div>
                      </td>
                      <td>Item 1</td>
                      <td>Bottle</td>
                      <td>1</td>
                      <td>
                        2 Box
                        <br />
                        (200 Bottles)
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control d-inline-block wd-100 mg-r-10"
                        />
                        Box
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="text-right">
                <strong className="tx-danger">100</strong>bottles to switch
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center mg-b-10">
                <label className="form-item-title">Switch to</label>
                <button type="button" className="btn btn-info mg-l-auto">
                  <span className="mg-r-5">{Icon('scan_barcode')}</span>Scan Barcode
                </button>
              </div>
              <div className="table-responsive bg-white bd rounded-top mg-t-10">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f wd-60">
                        Image
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Items
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Unit Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Unit Qty
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Qty Switched
                      </th>
                      <th scope="col" className="bd-t-0-f wd-70">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="avatar avatar-sm bg-gray-200 rounded">
                          <img src="assets/images/notfound.png" alt="" />
                        </div>
                      </td>
                      <td>Item 1</td>
                      <td>Bottle</td>
                      <td>1</td>
                      <td>
                        <input
                          type="number"
                          className="form-control d-inline-block wd-100 mg-r-10"
                        />
                        Bottle
                      </td>
                      <td align="center">
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pd-10 bd bd-t-0 rounded-bottom">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type or click to select an item"
                  />
                  <div className="input-group-append">
                    <button type="button" className="btn">
                      {Icon('product')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="text-right">
                <strong className="tx-danger">50</strong>bottle left to switch
              </div>
            </div>
            <div className="alert alert-warning pd-10 lh-1">
              <AlertTriangle size={16} className="mg-r-10" />
              Left Qty must be <strong>0</strong> to switch
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Switch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchItem;
