import { X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import { Select as SelectHook } from '@base/components/form';

const Overview = () => {
  return (
    <div className="row no-gutters">
      <div className="col-4 bd-r">
        <div className="d-flex flex-column align-items-center pd-x-15 pd-y-20 bd-b">
          <div className="avatar-persona avatar-bg-06 mg-b-10">{Icon('avatar06')}</div>
          <h1 className="h5 mg-b-0">Startup Sam</h1>
        </div>
        <div className="pd-15">
          <div className="form-group">
            <label className="form-item-title">Industry</label>
            <div>Health And Social Care</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Subsidiary</label>
            <div>Partly – owned subsidiaries</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Company Size</label>
            <div>Small (&lt;50 people)</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Annual Revenue</label>
            <div>￦1,000,000,000</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Age(years in business)</label>
            <div>2021</div>
          </div>
        </div>
      </div>
      <div className="col-8">
        <div className="pd-15">
          <div className="form-group">
            <label className="form-item-title">Related Account</label>
            <div>AAA</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Location</label>
            <div>Global</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Purchasing Process</label>
            <div>Automation</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Website Visits</label>
            <div>Direct</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Response to Marketing</label>
            <div>Yes</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Usage</label>
            <div>Light</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
