import { X } from 'react-feather';

const Preview = () => {
  return (
    <div id="pagePreview" className="modal fade right">
      <div className="modal-dialog full-modal">
        <div className="modal-content rounded-0 overflow-hidden">
          <div className="modal-header">
            <h1 className="h6 modal-title">Preview</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto pd-0-f">&nbsp;</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
