/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { ChevronDown, Edit2, Image, Plus, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

import NewTax from './modal/newTax';
import NewCashImage from './modal/newCashImage';

import './style.scss';

const PriceDiscount = () => {
  const [showShipping, setShowShipping] = useState(false);
  const [showTotalDiscount, setShowTotalDiscount] = useState(false);
  const [showManualDiscount, setShowManualDiscount] = useState(false);

  return (
    <>
      <div className="card-columns settings-columns">
        <div className="card">
          <div className="card-header bg-light">
            <span className="card-header-title">Payment Methods</span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col">Payment Method</th>
                  <th scope="col" className="wd-40">
                    Use
                  </th>
                  <th scope="col" className="wd-80">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cash Won</td>
                  <td className="text-center">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="methodCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="methodCheck1"></label>
                    </div>
                  </td>
                  <td className="text-center">&nbsp;</td>
                </tr>
                <tr>
                  <td>Credit Sales Cash</td>
                  <td className="text-center">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="methodCheck2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="methodCheck2"></label>
                    </div>
                  </td>
                  <td className="text-center">&nbsp;</td>
                </tr>
                <tr>
                  <td>Credit Card</td>
                  <td className="text-center">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="methodCheck3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="methodCheck3"></label>
                    </div>
                  </td>
                  <td className="text-center">&nbsp;</td>
                </tr>
                <tr>
                  <td>QR</td>
                  <td className="text-center">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="methodCheck4"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="methodCheck4"></label>
                    </div>
                  </td>
                  <td className="text-center">&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to add a currency"
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn" aria-label="toggle dropdown">
                          <ChevronDown />
                          <span className="sr-only">통화 선택 옵션 열기</span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="card">
          <div className="card-header bg-light">
            <span className="card-header-title">Pay With</span>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col">Payment Method</th>
                  <th scope="col" className="wd-40">
                    Priority
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cash Won</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="methodRadio"
                        id="methodRadio1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="methodRadio1"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Credit Sales Cash</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="methodRadio"
                        id="methodRadio2"
                      />
                      <label className="custom-control-label" htmlFor="methodRadio2"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Credit Card</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="methodRadio"
                        id="methodRadio3"
                      />
                      <label className="custom-control-label" htmlFor="methodRadio3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>QR</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="methodRadio"
                        id="methodRadio4"
                      />
                      <label className="custom-control-label" htmlFor="methodRadio4"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <span className="card-header-title">
              Round Off
              <br />
              <small className="tx-color-03">
                Total amount is rounded off to the nearest whole number.
              </small>
            </span>
            <div className="custom-control custom-switch mg-l-auto">
              <input
                id="roundOffActive"
                type="checkbox"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="roundOffActive"></label>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header bg-light">
            <span className="card-header-title">Shipping Charges</span>
            <div className="custom-control custom-switch mg-l-auto">
              <input
                id="shippingActive"
                type="checkbox"
                className="custom-control-input"
                checked={showShipping}
                onChange={() => setShowShipping(!showShipping)}
              />
              <label className="custom-control-label" htmlFor="shippingActive"></label>
            </div>
          </div>
          {showShipping && (
            <>
              <div className="table-responsive">
                <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                  <thead>
                    <tr>
                      <th scope="col">Shipping Name</th>
                      <th scope="col">Price</th>
                      <th scope="col" className="wd-40">
                        Default
                      </th>
                      <th scope="col" className="wd-40">
                        Use
                      </th>
                      <th scope="col" className="wd-80">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CJ</td>
                      <td>
                        <div className="d-flex">
                          <SelectHook
                            className="wd-100-f mg-r-5"
                            options={[{ value: 1, label: '￦' }]}
                          />
                          <input
                            type="number"
                            className="form-control form-control-lg wd-100"
                            defaultValue="4000"
                          />
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="shippingDefaultRadio1"
                            name="shippingDefaultRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="shippingDefaultRadio1"
                          ></label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="shippingCheck1"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="shippingCheck1"></label>
                        </div>
                      </td>
                      <td className="text-center">
                        <button type="button" className="btn btn-icon">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="card-footer">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type or click to add a shipping name"
                  />
                  <div className="input-group-append">
                    <button type="button" className="btn" aria-label="toggle dropdown">
                      <ChevronDown />
                      <span className="sr-only">택배사 선택 옵션 열기</span>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="card">
          <div className="card-header bg-light">
            <span className="card-header-title">Tax</span>
            <button
              type="button"
              className="btn btn-sm btn-primary mg-l-auto"
              data-toggle="modal"
              data-target="#addTaxModal"
            >
              <Plus className="mg-r-5" />
              Add
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col">Tax Name</th>
                  <th scope="col">Percentage</th>
                  <th scope="col">Customer</th>
                  <th scope="col" className="wd-40">
                    Default
                  </th>
                  <th scope="col" className="wd-40">
                    Use
                  </th>
                  <th scope="col" className="wd-100">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={6} className="tx-center tx-color-03 bd-t-0">
                    No data
                  </td>
                </tr>
                <tr>
                  <td>VAT</td>
                  <td>10%</td>
                  <td>
                    <div className="dropdown">
                      <button type="button" className="btn" data-toggle="dropdown">
                        <span className="avatar avatar-xs d-inline-block">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                            className="rounded"
                            alt="Adventure Works"
                          />
                        </span>
                        <span className="mg-x-5">Adventure Works</span>
                        <ChevronDown />
                      </button>
                      <div className="dropdown-menu">
                        <div className="d-flex align-items-center pd-10">
                          <span className="avatar avatar-xs d-inline-block">
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                              className="rounded"
                              alt="Adventure Works"
                            />
                          </span>
                          <span className="mg-l-5">Adventure Works</span>
                        </div>
                        <div className="d-flex align-items-center pd-10">
                          <span className="avatar avatar-xs d-inline-block">
                            <span className="avatar-initial rounded">
                              <Image />
                            </span>
                          </span>
                          <span className="mg-l-5">A Datum Corporat</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="taxRadio"
                        id="taxRadio1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="taxRadio1"></label>
                    </div>
                  </td>
                  <td>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="useTaxCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="useTaxCheck1"></label>
                    </div>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link btn-icon"
                      data-han-tooltip="edit"
                      data-toggle="modal"
                      data-target="#addTaxModal"
                    >
                      <Edit2 />
                      <span className="sr-only">편집</span>
                    </button>
                    <button type="button" className="btn btn-icon" data-han-tooltip="delete">
                      <X className="tx-danger" />
                      <span className="sr-only">삭제</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="card">
          <div className="card-header bg-light">
            <span className="card-header-title">Discount</span>
            <button type="button" className="btn btn-sm btn-primary mg-l-auto">
              <Plus className="mg-r-5" />
              Add
            </button>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label className="form-item-title d-block">Discount Type</label>
              <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                <input id="discountType1" type="checkbox" className="custom-control-input" />
                <label className="custom-control-label" htmlFor="discountType1">
                  Volume
                </label>
              </div>
              <div className="custom-control custom-checkbox d-inline-block">
                <input
                  id="discountType2"
                  type="checkbox"
                  className="custom-control-input"
                  checked={showTotalDiscount}
                  onChange={() => setShowTotalDiscount(!showTotalDiscount)}
                />
                <label className="custom-control-label" htmlFor="discountType2">
                  Total
                </label>
              </div>
            </div>
            {showTotalDiscount && (
              <div className="form-group">
                <label className="form-item-title d-block">Total Discount Type</label>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    id="discountTotalType1"
                    type="checkbox"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="discountTotalType1">
                    Simple
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block">
                  <input
                    id="discountTotalType2"
                    type="checkbox"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="discountTotalType2">
                    Threshold
                  </label>
                </div>
              </div>
            )}

            <div className="form-group">
              <label className="form-item-title">Maximum</label>
              <div className="input-group wd-200">
                <input type="number" className="form-control form-control-lg" />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="form-item-title">Manual Input Discount</label>
              <div className="custom-control custom-switch">
                <input
                  id="discountManualActive"
                  type="checkbox"
                  className="custom-control-input"
                  checked={showManualDiscount}
                  onChange={() => setShowManualDiscount(!showManualDiscount)}
                />
                <label className="custom-control-label" htmlFor="discountManualActive">
                  Use
                </label>
              </div>
              {showManualDiscount && (
                <div className="custom-control custom-checkbox mg-l-35 mg-t-10">
                  <input id="manualDiscountUse" type="checkbox" className="custom-control-input" />
                  <label className="custom-control-label" htmlFor="manualDiscountUse">
                    Can be used with other dicounts
                  </label>
                </div>
              )}
            </div>

            <div className="form-group">
              <label className="form-item-title d-block">Loaylty</label>
              <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                <input id="loyaltyUse1" type="checkbox" className="custom-control-input" />
                <label className="custom-control-label" htmlFor="loyaltyUse1">
                  Point
                </label>
              </div>
              <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                <input id="loyaltyUse2" type="checkbox" className="custom-control-input" />
                <label className="custom-control-label" htmlFor="loyaltyUse2">
                  Coupon
                </label>
              </div>
              <div className="custom-control custom-checkbox d-inline-block">
                <input id="loyaltyUse3" type="checkbox" className="custom-control-input" />
                <label className="custom-control-label" htmlFor="loyaltyUse3">
                  Stamp
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header h6 bg-light">
            <span className="card-header-title">Cash Images</span>
            <button
              type="button"
              className="btn btn-sm btn-primary mg-l-auto"
              data-toggle="modal"
              data-target="#addCashImageModal"
            >
              <Plus className="mg-r-5" />
              Add
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col">Value</th>
                  <th scope="col">Image</th>
                  <th scope="col" className="wd-100">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1 USD</td>
                  <td>
                    <div className="cash-image-frame">
                      <img src="assets/images/cash-1dollar.jpg" alt="" />
                    </div>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-icon btn-link"
                      data-han-tooltip="edit"
                      data-toggle="modal"
                      data-target="#addCashImageModal"
                    >
                      <Edit2 />
                      <span className="sr-only">편집</span>
                    </button>
                    <button type="button" className="btn btn-icon" data-han-tooltip="delete">
                      <X className="tx-danger" />
                      <span className="sr-only">삭제</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>10 USD</td>
                  <td>
                    <div className="cash-image-frame">
                      <img src="assets/images/cash-10dollar.jpg" alt="" />
                    </div>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-icon btn-link"
                      data-han-tooltip="edit"
                      data-toggle="modal"
                      data-target="#addCashImageModal"
                    >
                      <Edit2 />
                      <span className="sr-only">편집</span>
                    </button>
                    <button type="button" className="btn btn-icon" data-han-tooltip="delete">
                      <X className="tx-danger" />
                      <span className="sr-only">삭제</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <NewTax />
      <NewCashImage />
    </>
  );
};

export default PriceDiscount;
