import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  ChevronsLeft,
  ArrowLeft,
  Plus,
  MoreHorizontal,
  Copy,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

//import "CrmCss/desktop/theme1/theme1.scss";
//import "CustomerCss/customer.scss";

import NewICP from './write';

const TABS = [
  {
    value: 1,
    label: 'Detail',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const View = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Ideal Customer Profile
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10 lh-1">Startup Sam</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="dropdown">
              <button
                type="button"
                data-han-tooltip="More"
                className="btn btn-white btn-icon rounded-circle han-tooltip"
                data-toggle="dropdown"
              >
                <span className="span-icon">
                  <MoreHorizontal />
                </span>
                <span className="sr-only">더보기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Copy />
                  </span>
                  Clone
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newICPModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-section pd-15">
              <div className="d-flex align-items-center justify-content-center">
                <div className="avatar-persona avatar-bg-06">{Icon('avatar06')}</div>
              </div>
            </div>
            {/* Summary */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <span className="view-aside-section-header-title">Summary</span>
              </div>
              <div id="basicInfoSection" className="view-aside-section-body collapse show">
                <div className="form-group">
                  <label className="form-item-title">Industry</label>
                  <div>Health And Social Care</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Subsidiary</label>
                  <div>Partly – owned subsidiaries</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Company Size</label>
                  <div>Small (&lt;50 people)</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Annual Revenue</label>
                  <div>￦1,000,000,000</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Age(years in business)</label>
                  <div>2021</div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                  { hidden: hideAsideLeft },
                )}
                onClick={() => setHideAsideLeft(!hideAsideLeft)}
              >
                <ChevronsLeft />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>

              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map((item, index) => (
                    <li
                      key={index}
                      className={classnames('nav-item', {
                        active: item.value == tab.value,
                      })}
                    >
                      <button
                        type="button"
                        className="btn btn-xs btn-move-tab"
                        aria-label="Drag-and-drop to reorder"
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <button
                        type="button"
                        className="btn nav-link"
                        onClick={(e) => {
                          setTab(item);
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* Details */}
              {tab.value == 1 && (
                <div className="pd-15">
                  <div className="form-group">
                    <label className="form-item-title">Related Account</label>
                    <div>AAA</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Location</label>
                    <div>Global</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Purchasing Process</label>
                    <div>Automation</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Website Visits</label>
                    <div>Direct</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Response to Marketing</label>
                    <div>Yes</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Usage</label>
                    <div>Light</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <NewICP />
    </>
  );
};

export default View;
