import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  ChevronsLeft,
  ArrowLeft,
  Plus,
  Circle,
  CheckCircle,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"

import Notelist from '../../../../../component/desktop/notelist';

const TABS = [
  {
    value: 1,
    label: 'Preview',
  },
  {
    value: 2,
    label: 'Note',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewSms = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Templates
            <ChevronDown className="mg-l-5" />
          </button>
        </div>

        <h1 className="view-title mg-l-10 lh-1">Template 1</h1>

        <div className="button-group flex-shrink-0 mg-l-auto">
          <button
            type="button"
            data-han-tooltip="Delete"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">
              <Trash />
            </span>
            <span className="sr-only">삭제</span>
          </button>
          <button type="button" className="btn btn-primary mg-l-5 rounded-30">
            <Plus size="14" />
            New
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-section pd-15">
            <div className="form-group">
              <label className="form-item-title">Message Type</label>
              <div>MMS</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Template Type</label>
              <div>Follow up</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Language</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Product</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Owner</label>
              <div className="media align-items-center">
                <div className="avatar avatar-sm">
                  <img
                    src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                    className="rounded-circle"
                    alt="MSR"
                  />
                </div>
                <div className="media-body mg-l-10">
                  <div>MSR</div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Approval</label>
              <div className="media align-items-center">
                <div className="avatar avatar-sm">
                  <img
                    src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                    className="rounded-circle"
                    alt="KDH"
                  />
                </div>
                <div className="media-body mg-l-10">
                  <div>KDH</div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Stage</label>
              <div>Prepare</div>
            </div>
          </div>
        </div>

        <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* collapse button */}
            <button
              type="button"
              className={classnames(
                'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                { hidden: hideAsideLeft },
              )}
              onClick={() => setHideAsideLeft(!hideAsideLeft)}
            >
              <ChevronsLeft />
              <span className="sr-only">좌측 사이드 패널 접기</span>
            </button>

            <div className="flex-grow-1">
              <div className="d-flex mg-b-10">
                {/* process */}
                <ol className="list-unstyled flex-grow-1 process-wrap text-nowrap">
                  <li className="process-item current">
                    <button type="button" className="btn process-item-btn">
                      <CheckCircle />
                    </button>
                    <div className="process-item-text mg-t-10">Prepare</div>
                    <div className="mg-t-5">
                      <span className="tx-color-03 mg-r-5">Step</span>
                      <span>Approval</span>
                    </div>
                  </li>
                  <li className="process-item">
                    <button type="button" className="btn process-item-btn">
                      <Circle />
                    </button>
                    <div className="process-item-text mg-t-10">Active</div>
                  </li>
                </ol>

                <div className="dropdown mg-r-10">
                  <button type="button" className="btn btn-outline-primary" data-toggle="dropdown">
                    On hold
                    <ChevronDown />
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item active">
                      On hold
                    </button>
                    <button type="button" className="dropdown-item">
                      Needs Review
                    </button>
                    <button type="button" className="dropdown-item">
                      Reject
                    </button>
                    <button type="button" className="dropdown-item">
                      Approve and Finish
                    </button>
                  </div>
                </div>
              </div>

              {/* tabs */}
              <div className="view-tabs">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map((item, index) => (
                    <li
                      key={index}
                      className={classnames('nav-item', {
                        active: item.value == tab.value,
                      })}
                    >
                      <button
                        type="button"
                        className="btn btn-xs btn-move-tab"
                        aria-label="Drag-and-drop to reorder"
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <button
                        type="button"
                        className="btn nav-link"
                        onClick={(e) => {
                          setTab(item);
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="view-body">
            {tab.value == 1 && <div className="pd-15"></div>}
            {tab.value == 2 && <Notelist />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSms;
