import { useState } from 'react';
import { ThumbsUp, Settings } from 'react-feather';
import { Button, Checkbox, Input, Select } from '@base/components/form';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';
import { DatePicker } from '@base/components/date-picker';
import Delivery from './containers/delivery/write';
import ProductDetail from './containers/product-detail/write';

// import "PurchaseCss/purchase.scss"

const RFTWrite = () => {
  const [showAddNewVendor, setShowAddNewVendor] = useState<any>();

  return (
    <>
      <div className="text-right">
        <span className="tx-danger">*</span>is required field.
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label className="form-item-title">
            RFT ID <span className="tx-danger">*</span>
          </label>
          <Input rightIcon={<Settings />} />
        </div>
        <div className="form-group col-12">
          <label className="form-item-title">
            Vendor <span className="tx-danger">*</span>
          </label>
          <div className="table-responsive bd rounded-top">
            <table className="table mg-b-0">
              <thead>
                <tr>
                  <th scope="col" className="wd-30 bd-t-0-f">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="vendorCheckAll"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="vendorCheckAll"></label>
                    </div>
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Vendor Name
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Contact
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="vendorCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="vendorCheck1"></label>
                    </div>
                  </td>
                  <td>Vendor 1</td>
                  <td>
                    <CustomerAutoComplete
                      placement="right"
                      single={false}
                      showAvatar={true}
                      visible={true}
                      placeholder="Type or click to select a contact"
                      category=""
                      value={[]}
                      onChange={() => null}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="vendorCheck2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="vendorCheck2"></label>
                    </div>
                  </td>
                  <td>Vendor 2</td>
                  <td>
                    <CustomerAutoComplete
                      placement="right"
                      single={false}
                      showAvatar={true}
                      visible={true}
                      placeholder="Type or click to select a contact"
                      category=""
                      value={[]}
                      onChange={() => null}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="vendorCheck3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="vendorCheck3"></label>
                    </div>
                  </td>
                  <td>
                    <span
                      data-han-tooltip="Preferred"
                      className="preferred-vendor han-tooltip--top mg-r-5"
                    >
                      <ThumbsUp size="16" />
                      <span className="sr-only">선호 업체</span>
                    </span>
                    Vendor 3
                  </td>
                  <td>
                    <CustomerAutoComplete
                      placement="right"
                      single={false}
                      showAvatar={true}
                      visible={true}
                      placeholder="Type or click to select a contact"
                      category=""
                      value={[]}
                      onChange={() => null}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-10 bd bd-t-0 rounded-bottom">
            <div className="dropdown">
              <div className="input-group" data-toggle="dropdown">
                <Input placeholder="Select Vendor" />
                <div className="input-group-append">
                  <Button color="" className="input-group-btn" icon="m_customer" iconType="main" />
                </div>
              </div>
              <div className="dropdown-menu wd-100p">
                <Button
                  color=""
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newVendor"
                  name="Add New Vendor"
                  icon="Plus"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">
            RFT Date <span className="tx-danger">*</span>
          </label>
          <div className="wd-150">
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">
            Valid Until <span className="tx-danger">*</span>
          </label>
          <div className="wd-150">
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">Payment Terms</label>
          <Select
            addLabel="Configure Terms"
            defaultValue={[{ value: 1, label: 'Due end of the month' }]}
          />
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">Expected Delivery Date</label>
          <div className="wd-150">
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
        </div>
        <Delivery />
        <div className="col-lg-6 form-group">
          <label className="form-item-title">Description</label>
          <Input />
        </div>
        <ProductDetail />
        <div className="col-6 form-group">
          <label className="form-item-title">Terms and conditions</label>
          <Select defaultValue={[{ value: 1, label: 'Due on Receipt' }]} />
        </div>
        <div className="col-6 form-group">
          <label className="form-item-title">Vendor Note</label>
          <Input />
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">RFQ Time line &amp; Review Process</label>
          <textarea rows={3} cols={10} className="form-control"></textarea>
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">Submission requirements</label>
          <textarea rows={3} cols={10} className="form-control"></textarea>
        </div>
        <div className="form-group col-12">
          <label className="form-item-title">Selection Criteria</label>
          <ul className="list-unstyled">
            <li className="d-flex align-items-center flex-wrap mg-b-10">
              <Checkbox />
              <Input className="wd-300-f" />
              <Button color="link" icon="Plus" className="btn-icon" tooltip="Add" />
            </li>
            <li className="d-flex align-items-center flex-wrap mg-b-10">
              <Checkbox />
              <Input className="wd-300-f" />
              <Button color="link" icon="Plus" className="btn-icon" tooltip="Add" />
              <Button
                color="link"
                icon="Minus"
                iconClass="tx-danger"
                className="btn-icon"
                tooltip="Delete"
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default RFTWrite;
