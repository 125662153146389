/* eslint-disable prettier/prettier */
import { ChevronLeft, ChevronRight } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Members = () => {
	return (
		<div className="card">
			<div className="table-responsive rounded">
				<table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
					<thead>
						<tr>
							<th scope="col" className="bd-t-0-f">
								Full name
							</th>
							<th scope="col" className="bd-t-0-f">
								Email
							</th>
							<th scope="col" className="bd-t-0-f">
								Company name
							</th>
							<th scope="col" className="bd-t-0-f">
								Mobile
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<span className="d-inline-block avatar avatar-sm mg-r-5">
									<img
										src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
										className="rounded-circle"
										alt=""
									/>
								</span>
								Allan Rey Palban
							</td>
							<td>
								<a href="mailto:sales@domain.com">sales@domain.com</a>
							</td>
							<td>Adventure works</td>
							<td>
								<a href="tel:1 000-0000-0000">+1 000-0000-0000</a>
							</td>
						</tr>
						<tr>
							<td>
								<span className="d-inline-block avatar avatar-sm mg-r-5">
									<img
										src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
										className="rounded-circle"
										alt=""
									/>
								</span>
								Situmay
							</td>
							<td>
								<a href="mailto:sales2@domain.com">sales2@domain.com</a>
							</td>
							<td>Adventure works</td>
							<td>
								<a href="tel:1 000-1111-0000">+1 000-0000-0000</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="card-footer pd-y-6-f pagination">
				<button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to first">
					{Icon('move_first')}
					<span className="sr-only">처음으로</span>
				</button>
				<button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
					<ChevronLeft />
					<span className="sr-only">이전</span>
				</button>
				<button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
					<ChevronRight />
					<span className="sr-only">다음</span>
				</button>
				<button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to last">
					{Icon('move_last')}
					<span className="sr-only">마지막으로</span>
				</button>
				<input type="number" className="paging-input" defaultValue="1" />/ 1
			</div>
		</div>
	);
};

export default Members;
