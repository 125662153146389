import { Input, Select } from '@base/components/form';
import React, { useEffect, useState } from 'react';

const OPTIONS = [
  { value: 'next', label: 'Move to Next Step/Status' },
  { value: 'other', label: 'Move to Other Step/Status' },
];

const WaitAction: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  //state
  const [selectedValue, setSelectedValue] = useState<any>(OPTIONS[0]);

  //init data
  useEffect(() => {
    if (value) {
      //single object
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(OPTIONS[0]);
    }
  }, [value]);

  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption);
    //callback
    onChange && onChange(newOption.value);
  };

  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Action Name</label>
        <Select
          options={OPTIONS}
          value={selectedValue}
          onChange={handleValueChange}
          className="wd-300-f"
        />
      </div>
      {selectedValue.value === 'other' && (
        <div className="form-group">
          <label className="form-item-title">Step/Status Name</label>
          <Input />
        </div>
      )}
    </>
  );
};

export default WaitAction;
