import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ArrowLeft,
  DollarSign,
  Eye,
  Mail,
  ChevronLeft,
  ChevronRight,
  Check,
  Circle,
  Award,
} from 'react-feather';

import { Select as SelectHook } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { COLORS } from '@base/config/constant';

import ConfirmFactors from './modal/confirmFactors';
import NewCampaign from './modal/newCampaign';
import EventQuestion from './modal/newEventQuestion';
import ROI from './view/roi';
import Related from './view/related';
import ReactApexChart from 'react-apexcharts';

const TABS = [
  {
    value: 1,
    label: 'Overview',
  },
  {
    value: 2,
    label: 'Link Map',
  },
  {
    value: 3,
    label: 'Open Timeline',
  },
  {
    value: 4,
    label: 'Interactions',
  },
  {
    value: 5,
    label: 'Recipient Detail',
  },
  {
    value: 6,
    label: 'ROI',
  },
  {
    value: 7,
    label: 'Journey Process',
  },
  {
    value: 8,
    label: 'Related',
  },
  {
    value: 9,
    label: 'Configuration',
  },
  {
    value: 10,
    label: 'A/B Test Results',
  },
];

const EmailView = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();

  const [interactions, SetInteractions] = useState<any>({
    opened: true,
    clicked: false,
    replied: false,
    bounced: false,
  });

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a href="/campaign/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Campaign
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">Campaign 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newCampaignModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="row no-gutters flex-nowrap flex-grow-1 overflow-auto">
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Insight</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#insightSection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="insightSection" className="view-aside-section-body collapse fade show">
                  <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                    <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">Sent</div>
                    <div className="mg-l-auto  tx-15 tx-semibold">2021-05-30</div>
                  </div>
                  <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                    <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">
                      Recipients
                    </div>
                    <div className="mg-l-auto  tx-15 tx-semibold">150</div>
                  </div>
                  <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                    <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                      Total sent
                    </div>
                    <div className="mg-l-auto  tx-15 tx-semibold">149</div>
                  </div>
                  <div className="mg-y-20">
                    <ReactApexChart
                      type="donut"
                      height={150}
                      series={[100, 1, 250]}
                      options={{
                        theme: {
                          mode: 'light',
                        },
                        labels: ['Opened', 'Bounced', 'Unopened'],
                        colors: [
                          'rgba(0,227,150, .8)',
                          'rgba(220,53,69, .8)',
                          'rgba(221,221,221, 1)',
                        ],
                        plotOptions: {
                          pie: {
                            donut: {
                              size: '88%',
                              labels: {
                                show: true,
                                value: {
                                  fontSize: '1.25rem',
                                  fontWeight: 600,
                                  offsetY: -1,
                                },
                                total: {
                                  show: true,
                                  fontSize: '0.875rem',
                                  label: 'Total',
                                },
                              },
                            },
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        responsive: [
                          {
                            breakpoint: 1000,
                            options: {
                              legend: {
                                position: 'bottom',
                              },
                            },
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center mg-b-5">
                    Opened
                    <span className="flex-grow-1 mg-x-10 text-right" style={{ color: '#53b3b8' }}>
                      60
                    </span>
                    <div className="progress wd-100">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated wd-40p"
                        role="progressbar"
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ backgroundColor: '#53b3b8' }}
                      >
                        40%
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mg-b-5">
                    Clicked
                    <span className="flex-grow-1 mg-x-10 text-right tx-purple">15</span>
                    <div className="progress wd-100">
                      <div
                        className="progress-bar progress-bar-striped bg-purple progress-bar-animated wd-10p"
                        role="progressbar"
                        aria-valuenow={10}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                      <span className="lh-1">10%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    Replied
                    <span className="flex-grow-1 mg-x-10 text-right tx-primary">7</span>
                    <div className="progress wd-100">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated wd-5p"
                        role="progressbar"
                        aria-valuenow={5}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                      <span className="lh-1">5%</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">KPI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="d-flex align-items-center justify-content-between mg-b-5">
                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                      Click through rate
                    </span>
                    <span className="tx-12 tx-color-04">83% goal reached</span>
                  </div>
                  <div className="d-flex justify-content-between mg-b-5">
                    <span className="h5 tx-semibold mg-b-0">50%</span>
                    <span className="tx-rubik tx-color-03 mg-b-0">60%</span>
                  </div>
                  <div className="progress ht-4 mg-b-0 op-5">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={60}
                      style={{ width: '83%' }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">ROI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="h3 mg-b-15 tx-center tx-primary">413%</div>
                  <div className="d-flex">
                    <div className="pd-x-15">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Recipients
                      </div>
                      <div>100</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Sales
                      </div>
                      <div>20</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Gross Profit
                      </div>
                      <div>＄500.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 4 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 3,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 3 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 3 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}
              {/* Overview */}
              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="d-flex align-items-center justify-content-end mg-b-10">
                    Date Range{' '}
                    <SelectHook
                      className="wd-200-f mg-l-10"
                      options={[{ value: 1, label: 'This month' }]}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <span className="mg-b-10 tx-semibold">Conversion Funnel</span>
                      <div className="d-flex">
                        <div className="wd-250 mg-r-20"></div>
                        <ul className="list-unstyled mg-0 pd-0">
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#6599e3' }}
                            ></span>
                            Sent
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#95cf6a' }}
                            ></span>
                            Clicked
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#f9b521' }}
                            ></span>
                            MQL
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#f6704e' }}
                            ></span>
                            SQL
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#3ab6b1' }}
                            ></span>
                            Opportunity
                          </li>
                          <li>
                            <span
                              className="d-inline-block wd-10 ht-10 mg-r-10"
                              style={{ backgroundColor: '#9574e2' }}
                            ></span>
                            Purchase
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="bg-white bd rounded">
                        <div className="row no-gutters">
                          <div className="col-6 bd-r">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-info tx-info rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  <DollarSign />
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Average Cost per conversion
                                  </div>
                                  <div className="tx-20">＄5.00</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-success tx-success rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  {Icon('revenue')}
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Revenue Generated
                                  </div>
                                  <div className="tx-20">＄500.00</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 bd-t bd-r">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-purple tx-purple rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  <Eye />
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Total Page Views
                                  </div>
                                  <div className="tx-20">500</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 bd-t">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-gray-500 tx-gray-500 rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  <Mail />
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Unopened
                                  </div>
                                  <div className="tx-20">5</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 bd-t bd-r">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-danger tx-danger rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  {Icon('unsubscribe')}
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Unsubscribe
                                  </div>
                                  <div className="tx-20">5</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 bd-t">
                            <div className="pd-20">
                              <div className="media">
                                <div className="wd-45 ht-45 bd bd-2 bd-primary tx-primary rounded-circle align-items-center justify-content-center op-6 d-none d-sm-flex">
                                  {Icon('click')}
                                </div>
                                <div className="media-body mg-l-10">
                                  <div className="tx-12 tx-spacing-1 tx-uppercase tx-rubik lh-3">
                                    Click through rate
                                  </div>
                                  <div className="tx-20">50%</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-body mg-t-20">
                    <ReactApexChart
                      type="line"
                      height={300}
                      series={[
                        {
                          name: 'Open',
                          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                        },
                        {
                          name: 'Clicked',
                          data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                        },
                      ]}
                      options={{
                        chart: {
                          zoom: {
                            enabled: false,
                          },
                        },
                        colors: ['rgba(0,227,150, .8)', 'rgba(0,143,251, 1)'],
                        stroke: {
                          curve: 'straight',
                        },
                        title: {
                          text: 'Responses over time',
                          align: 'left',
                        },
                        xaxis: {
                          categories: [
                            '30m',
                            '1h',
                            '1h 30m',
                            '2h',
                            '2h 30m',
                            '3h',
                            '3h 30m',
                            '3h',
                            '3h 30m',
                          ],
                        },
                      }}
                    />
                  </div>
                </div>
              )}

              {tab.value == 2 && <></>}

              {/* Open Timeline */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="ht-300"></div>
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Timestamp</span>
                    </div>
                    <div className="card">
                      <div className="table-responsive rounded">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Timestamp
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Recipients
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer pd-y-6-f pagination">
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to first"
                        >
                          {Icon('move_first')}
                          <span className="sr-only">처음으로</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="previous"
                        >
                          <ChevronLeft />
                          <span className="sr-only">이전</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="next"
                        >
                          <ChevronRight />
                          <span className="sr-only">다음</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to last"
                        >
                          {Icon('move_last')}
                          <span className="sr-only">마지막으로</span>
                        </button>
                        <input type="number" className="paging-input" defaultValue="1" />/ 1
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Interactions */}
              {tab.value == 4 && (
                <div className="pd-20">
                  <div className="card card-body">
                    <ReactApexChart
                      type="line"
                      height={300}
                      series={[
                        {
                          name: 'Opened',
                          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                        },
                        {
                          name: 'Clicked',
                          data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                        },
                        {
                          name: 'Replied',
                          data: [0, 10, 10, 15, 20, 45, 30, 51, 80],
                        },
                      ]}
                      options={{
                        chart: {
                          zoom: {
                            enabled: false,
                          },
                        },
                        colors: [
                          'rgba(0,227,150, .8)',
                          'rgba(0,143,251, .8)',
                          'rgba(119, 93, 208,.8)',
                        ],
                        stroke: {
                          curve: 'straight',
                        },
                        title: {
                          text: 'Interactions',
                          align: 'left',
                        },
                        xaxis: {
                          categories: [
                            '30m',
                            '1h',
                            '1h 30m',
                            '2h',
                            '2h 30m',
                            '3h',
                            '3h 30m',
                            '3h',
                            '3h 30m',
                          ],
                        },
                      }}
                    />
                  </div>
                  <div className="mg-t-30 mg-b-10">
                    <button
                      type="button"
                      className="btn rounded-30 mg-r-5"
                      style={{
                        border: '1px solid rgba(0,227,150, .8)',
                        backgroundColor: interactions.opened
                          ? 'rgba(0,227,150, .8)'
                          : 'transparent',
                        color: interactions.opened ? '#fff' : 'rgb(0,227,150)',
                      }}
                      onClick={() => SetInteractions({ opened: true })}
                    >
                      Opened
                    </button>
                    <button
                      type="button"
                      className="btn rounded-30 mg-r-5"
                      style={{
                        border: '1px solid rgba(0,143,251,.8)',
                        backgroundColor: interactions.clicked
                          ? 'rgba(0,143,251,.8)'
                          : 'transparent',
                        color: interactions.clicked ? '#fff' : 'rgb(0,143,251)',
                      }}
                      onClick={() => SetInteractions({ clicked: true })}
                    >
                      Clicked
                    </button>
                    <button
                      type="button"
                      className="btn rounded-30 mg-r-5"
                      style={{
                        border: '1px solid rgba(119, 93, 208,.7)',
                        backgroundColor: interactions.replied
                          ? 'rgba(119, 93, 208,.7)'
                          : 'transparent',
                        color: interactions.replied ? '#fff' : 'rgb(119, 93, 208)',
                      }}
                      onClick={() => SetInteractions({ replied: true })}
                    >
                      Replied
                    </button>
                    <button
                      type="button"
                      className="btn rounded-30 mg-r-5"
                      style={{
                        border: '1px solid rgba(250,197,87,.8)',
                        backgroundColor: interactions.bounced
                          ? 'rgba(250,197,87,.8)'
                          : 'transparent',
                        color: interactions.bounced ? '#fff' : 'rgb(250,197,87)',
                      }}
                      onClick={() => SetInteractions({ bounced: true })}
                    >
                      Bounced
                    </button>
                  </div>
                  {(interactions.opened || interactions.clicked || interactions.replied) && (
                    <div className="card">
                      <div className="table-responsive rounded">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Recipients
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Email used
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Timestamp
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer pd-y-6-f pagination">
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to first"
                        >
                          {Icon('move_first')}
                          <span className="sr-only">처음으로</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="previous"
                        >
                          <ChevronLeft />
                          <span className="sr-only">이전</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="next"
                        >
                          <ChevronRight />
                          <span className="sr-only">다음</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to last"
                        >
                          {Icon('move_last')}
                          <span className="sr-only">마지막으로</span>
                        </button>
                        <input type="number" className="paging-input" defaultValue="1" />/ 1
                      </div>
                    </div>
                  )}
                  {interactions.bounced && (
                    <div className="card">
                      <div className="table-responsive rounded">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Recipients
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Email used
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Blocked reason
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Timestamp
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer pd-y-6-f pagination">
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to first"
                        >
                          {Icon('move_first')}
                          <span className="sr-only">처음으로</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="previous"
                        >
                          <ChevronLeft />
                          <span className="sr-only">이전</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="next"
                        >
                          <ChevronRight />
                          <span className="sr-only">다음</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to last"
                        >
                          {Icon('move_last')}
                          <span className="sr-only">마지막으로</span>
                        </button>
                        <input type="number" className="paging-input" defaultValue="1" />/ 1
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Recipient Detail */}
              {tab.value == 5 && (
                <div className="pd-20">
                  <div className="mg-b-10 text-right">
                    <Check className="tx-success" size={16} />: View
                  </div>
                  <div className="card">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Recipients
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Page 1
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Page 2
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Opportunity
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Sales
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="dropdown dropright">
                              <button
                                type="button"
                                className="btn btn-link pd-0"
                                data-toggle="dropdown"
                              >
                                <span className="d-inline-block avatar avatar-sm mg-r-5">
                                  <img
                                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                                    className="rounded-circle"
                                    alt=""
                                  />
                                </span>
                                Allan Rey Palban
                              </button>
                              <div className="dropdown-menu pd-0">
                                <div className="dropdown-menu-header pd-10 bg-light bd-b tx-semibold">
                                  Allan Rey Palban
                                </div>
                                <div className="pd-20">
                                  <div className="timeline-group dropdown-timeline">
                                    <div className="timeline-label">2021/12/03</div>
                                    <div className="timeline-item">
                                      <div className="timeline-time">10:30 am</div>
                                      <div className="timeline-body">
                                        <p>CTA 1 clicked</p>
                                      </div>
                                    </div>
                                    <div className="timeline-item">
                                      <div className="timeline-time">10:31 am</div>
                                      <div className="timeline-body">
                                        <p>Page 1 viewed</p>
                                      </div>
                                    </div>
                                    <div className="timeline-item">
                                      <div className="timeline-time">10:35 am</div>
                                      <div className="timeline-body">
                                        <p>CTA 2 clicked</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <Check className="tx-success" size={16} />
                          </td>
                          <td>
                            <Check className="tx-success" size={16} />
                          </td>
                          <td>
                            <Circle className="tx-primary" size={16} />
                          </td>
                          <td>＄200</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="card-footer pd-y-6-f pagination">
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to first"
                      >
                        {Icon('move_first')}
                        <span className="sr-only">처음으로</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="previous"
                      >
                        <ChevronLeft />
                        <span className="sr-only">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="next"
                      >
                        <ChevronRight />
                        <span className="sr-only">다음</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to last"
                      >
                        {Icon('move_last')}
                        <span className="sr-only">마지막으로</span>
                      </button>
                      <input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}

              {/* ROI */}
              {tab.value == 6 && (
                <div className="pd-20">
                  <ROI />
                </div>
              )}

              {/* Related */}
              {tab.value == 8 && (
                <div className="pd-20">
                  <Related />
                </div>
              )}

              {/* A/B Test */}
              {tab.value == 10 && (
                <div className="pd-20">
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Settings</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 collapsed mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#abTestSettings"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="abTestSettings" className="collapse">
                      <div className="d-flex">
                        <ul className="nav nav-tabs flex-grow-1">
                          <li className="nav-item">
                            <button
                              type="button"
                              className="nav-link active"
                              data-toggle="tab"
                              data-target="#a"
                            >
                              A
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              type="button"
                              className="nav-link"
                              data-toggle="tab"
                              data-target="#b"
                            >
                              B
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content bg-white bd bd-gray-300 bd-t-0 mg-b-20 pd-20">
                        <div id="a" className="tab-pane fade show active">
                          &nbsp;
                        </div>
                        <div id="b" className="tab-pane fade">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Result</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#abTestResult"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="abTestResult" className="card card-body collapse show">
                      <div className="row">
                        <div className="col-lg-6 d-flex flex-column align-items-center">
                          <div className="pd-y-10">A</div>
                          <div style={{ width: '300px' }}>
                            {/* <HorizontalBar
                              width={300}
                              height={150}
                              data={data5}
                              options={{
                                maintainAspectRatio: false,
                                legend: false,
                                scales: {
                                  xAxes: [{ ticks: { beginAtZero: true, min: 0, max: 100 } }],
                                },
                              }}
                            /> */}
                          </div>
                          <div className="d-inline-block mg-t-10 pd-x-10 pd-y-3 bg-orange rounded-30 tx-white">
                            <Award size="16" className="mg-r-5" />
                            Winner
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex flex-column align-items-center">
                          <div className="pd-y-10">B</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmFactors />
      <NewCampaign />
      <EventQuestion />
    </>
  );
};

export default EmailView;
