/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  List,
  Grid,
  Columns,
  Settings,
  Menu,
} from 'react-feather';

import Pagination from '../../../../component/desktop/pagination';

const SCList = () => {
  const [state, setState] = useState<any>({
    showall: true,
    payslip: false,
    commission: false,
  });

  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Sales Commission Expense</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Dashboard
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/product/desktop/theme1/list">Dashboard</a>
              </div>
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Invoices
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/invoice/desktop/theme1/list">Invoices</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Unpaid Invoices</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Credit Note</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Debit Note</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Payments Received</a>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/sales-commission/desktop/theme1/list">
                  Sales Commission Expense
                </a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Purchase Bill
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a role="button">Purchase Bill</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Vendor Credit</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Vendor Debit</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Payments Made</a>
              </div>
            </div>
          </div>
          <div className="button-group mg-l-auto">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>
        <div className="list-filter-bar">
          <span className="items-no mg-r-10">Total: 100</span>

          <div className="list-filter-items">
            {/* for table list column settings */}
            {listType.list && (
              <div className="dropdown list-filter-item">
                <button type="button" className="btn btn-icon" data-toggle="dropdown">
                  {Icon('column_settings')}
                  <span className="sr-only">항목 설정 열기</span>
                </button>
                <div className="dropdown-menu column-settings">
                  <div className="column-settings-title">Column Settings</div>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="orderColumnCheck1"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="orderColumnCheck1">
                          Invoice ID
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                  </ul>
                  <div className="column-settings-actions">
                    Max : <strong className="mg-x-5 tx-primary">1</strong> / 7
                    <button type="button" className="btn btn-light mg-l-auto">
                      Close
                    </button>
                    <button type="button" className="btn btn-primary mg-l-5">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Group By */}
            <div className="dropdown list-filter-item">
              <button type="button" className="btn" data-toggle="dropdown">
                Group By:{' '}
                {state.showall ? 'All' : state.payslip ? 'Report in Pay Slip' : 'Commission Bill'}
                <ChevronDown className="mg-l-5" />
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: state.showall })}
                  onClick={() => setState({ showall: true })}
                >
                  All
                </button>
                <div className="dropdown-divider"></div>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: state.payslip })}
                  onClick={() => setState({ payslip: true })}
                >
                  Report in Pay Slip
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: state.commission })}
                  onClick={() => setState({ commission: true })}
                >
                  Commission Bill
                </button>
              </div>
            </div>

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Created on
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item">
                    Amount
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* Search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <input type="search" className="form-control" />
          </div>
        </div>

        <div className="list-body">
          {listType.list && (
            <div className="table-responsive">
              {/* Group by: All */}
              {state.showall && (
                <table className="table dataTable">
                  <thead>
                    <tr>
                      <th scope="col" className="sorting">
                        Amount
                      </th>
                      <th scope="col" className="sorting_desc">
                        Created on
                      </th>
                      <th scope="col" className="sorting">
                        Position
                      </th>
                      <th scope="col" className="sorting">
                        Source
                      </th>
                      <th scope="col" className="sorting">
                        Base Total
                      </th>
                      <th scope="col" className="sorting">
                        Margin
                      </th>
                      <th scope="col" className="sorting">
                        Level
                      </th>
                      <th scope="col" className="sorting">
                        Rate(%)
                      </th>
                      <th scope="col" className="sorting">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a href="/sales-commission/desktop/theme1/view-new" className="link-02">
                          $300
                        </a>
                      </td>
                      <td className="sorting-cell">&nbsp;</td>
                      <td>Employee</td>
                      <td>SO</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Manager</td>
                      <td>3</td>
                      <td>New</td>
                    </tr>
                    <tr>
                      <td>
                        <a href="/sales-commission/desktop/theme1/view-new" className="link-02">
                          $1,000
                        </a>
                      </td>
                      <td className="sorting-cell">&nbsp;</td>
                      <td>Agent</td>
                      <td>Invoice</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Agent</td>
                      <td>10</td>
                      <td>Bill Created</td>
                    </tr>
                  </tbody>
                </table>
              )}

              {/* Group by: Report in Pay Slip */}
              {state.payslip && (
                <table className="table dataTable">
                  <thead>
                    <tr>
                      <th scope="col" className="sorting">
                        Employee
                      </th>
                      <th scope="col" className="sorting_desc">
                        Created on
                      </th>
                      <th scope="col" className="sorting">
                        Source
                      </th>
                      <th scope="col" className="sorting">
                        Sales Man
                      </th>
                      <th scope="col" className="sorting">
                        Product
                      </th>
                      <th scope="col" className="sorting">
                        Base Total
                      </th>
                      <th scope="col" className="sorting">
                        Margin
                      </th>
                      <th scope="col" className="sorting">
                        Level
                      </th>
                      <th scope="col" className="sorting">
                        Rate(%)
                      </th>
                      <th scope="col" className="sorting">
                        Amount
                      </th>
                      <th scope="col" className="sorting">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ncrm/user/photo/10/1"
                              className="rounded-circle"
                              alt="KDH"
                            />
                          </div>
                          <div className="media-body mg-l-10">
                            <a href="/sales-commission/desktop/theme1/view-new" className="link-02">
                              KDH
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="sorting-cell">&nbsp;</td>
                      <td>SO</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Manager</td>
                      <td>3</td>
                      <td>$300</td>
                      <td>New</td>
                    </tr>
                  </tbody>
                </table>
              )}

              {/* Group by: Commission Bill */}
              {state.commission && (
                <table className="table dataTable">
                  <thead>
                    <tr>
                      <th scope="col" className="sorting">
                        Agent
                      </th>
                      <th scope="col" className="sorting_desc">
                        Bill Created
                      </th>
                      <th scope="col" className="sorting">
                        Source
                      </th>
                      <th scope="col" className="sorting">
                        Sales Man
                      </th>
                      <th scope="col" className="sorting">
                        Date Paid
                      </th>
                      <th scope="col" className="sorting">
                        Level
                      </th>
                      <th scope="col" className="sorting">
                        Rate(%)
                      </th>
                      <th scope="col" className="sorting">
                        Amount
                      </th>
                      <th scope="col" className="sorting">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a href="/sales-commission/desktop/theme1/view-new" className="link-02">
                          AAA
                        </a>
                      </td>
                      <td className="sorting-cell">&nbsp;</td>
                      <td>Invoice</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Agent</td>
                      <td>10</td>
                      <td>$1,000</td>
                      <td>Bill Created</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          )}

          {listType.grid && (
            <div className="row row-xs">
              {/* Group by: All */}
              {state.showall && (
                <>
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/sales-commission/desktop/theme1/view-new"
                            className="grid-list-item-title link-02"
                          >
                            $300
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Position:</span>
                            <span className="grid-list-item-col-content">Employee</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Source:</span>
                            <span className="grid-list-item-col-content">SO</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Base Total:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Margin:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Level:</span>
                            <span className="grid-list-item-col-content">Manager</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Rate(%):</span>
                            <span className="grid-list-item-col-content">3</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Status:</span>
                            <span className="grid-list-item-col-content">New</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/sales-commission/desktop/theme1/view-new"
                            className="grid-list-item-title link-02"
                          >
                            $1,000
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Position:</span>
                            <span className="grid-list-item-col-content">Agent</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Source:</span>
                            <span className="grid-list-item-col-content">Invoice</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Base Total:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Margin:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Level:</span>
                            <span className="grid-list-item-col-content">Agent</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Rate(%):</span>
                            <span className="grid-list-item-col-content">10</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Status:</span>
                            <span className="grid-list-item-col-content">Bill Created</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Group by: Report in Pay Slip */}
              {state.payslip && (
                <>
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/sales-commission/desktop/theme1/view-new"
                            className="grid-list-item-title link-02"
                          >
                            KDH
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Source:</span>
                            <span className="grid-list-item-col-content">SO</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Sales Man:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Product:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Base Total:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Margin:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Level:</span>
                            <span className="grid-list-item-col-content">Manager</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Rate(%):</span>
                            <span className="grid-list-item-col-content">3</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Amoun:</span>
                            <span className="grid-list-item-col-content">$300</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Status:</span>
                            <span className="grid-list-item-col-content">New</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Group by: Commission Bill */}
              {state.commission && (
                <>
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/sales-commission/desktop/theme1/view-new"
                            className="grid-list-item-title link-02"
                          >
                            AAA
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Bill Created:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Source:</span>
                            <span className="grid-list-item-col-content">Invoice</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Sales Man:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Date Paid:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Level:</span>
                            <span className="grid-list-item-col-content">Manager</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Rate(%):</span>
                            <span className="grid-list-item-col-content">3</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Amoun:</span>
                            <span className="grid-list-item-col-content">$1,000</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Status:</span>
                            <span className="grid-list-item-col-content">Bill Created</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>
    </>
  );
};

export default SCList;
