// import PhoneTheme1List from "./Pages/Customer/Phone/theme1/list";
// import PhoneTheme1View from "./Pages/Customer/Phone/theme1/view";
// import PhoneTheme1Write from "./Pages/Customer/Phone/theme1/write";
// import PhoneTheme1Some from "./Pages/Customer/Phone/theme1/some";
//
// import PhoneTheme2List from "./Pages/Customer/Phone/Theme2/list";
// import PhoneTheme2View from "./Pages/Customer/Phone/Theme2/view";
// import PhoneTheme2Write from "./Pages/Customer/Phone/Theme2/write";
// import PhoneTheme2Some from "./Pages/Customer/Phone/Theme2/some";

import React from 'react';

const DemoPage = React.lazy(() => import('./index'));

/** ======================== CUSTOMER ================================ */
const DesktopTheme1AccountList = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/account-list'),
);
const DesktopTheme1AccountView = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/account-view'),
);
const DesktopTheme1ContactList = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/contact-list'),
);
const DesktopTheme1ContactView = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/contact-view'),
);
const DesktopTheme1ViewNew = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/view-new'),
);
const SplitModeSideBar = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/splitmode/Sidebar'),
);

const DesktopTheme1SegmentList = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/segment/list'),
);
const DesktopTheme1SegmentView = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/segment/view'),
);

const DesktopTheme1MarketingList = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/marketing-list/list'),
);
const DesktopTheme1MarketingWrite = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/marketing-list/write'),
);
const DesktopTheme1MarketingViewNew = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/marketing-list/view-new'),
);

const DesktopTheme1BuyerPersonaList = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/buyer-persona/list'),
);
const DesktopTheme1BuyerPersonaView = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/buyer-persona/view'),
);

const DesktopTheme1ICPList = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/icp/list'),
);
const DesktopTheme1ICPView = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/icp/view'),
);

const DesktopTheme1BlocklistList = React.lazy(
  () => import('@demo-page/customer/desktop/theme1/blocklist/list'),
);

// const TabletTheme1List = React.lazy(() => import('@demo-page/customer/tablet/theme1/list'));
// const TabletTheme1View = React.lazy(() => import('@demo-page/customer/tablet/theme1/view'));

/** ======================== ACITVITY ================================ */
const DesktopTheme1MyWorkDashboard = React.lazy(
  () => import('@demo-page/mywork/desktop/theme1/dashboard'),
);
const DesktopTheme1ActivityList = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/list'),
);
const DesktopTheme1ActivityTaskViewNew = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/task-view-new'),
);
const DesktopTheme1ActivityMeetingViewNew = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/meeting-view-new'),
);
const DesktopTheme1ActivityEmailView = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/email-view'),
);
const DesktopTheme1ActivityCallView = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/call-view'),
);
const DesktopTheme1ActivitySMSView = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/sms-view'),
);
const DesktopTheme1ActivityFaxView = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/fax-view'),
);
const DesktopTheme1ActivityDMView = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/dm-view'),
);
const DesktopTheme1ActivityProductionTaskList = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/production-task-list'),
);
const DesktopTheme1ActivityProductionTaskView = React.lazy(
  () => import('@demo-page/activity/desktop/theme1/production-task-view'),
);
const DesktopTheme1ActivityComparison = React.lazy(
  () => import('@demo-page/mywork/desktop/theme1/activityComparison'),
);

// const TabletTheme1ActivityList = React.lazy(() => import('./Pages/Activity/Tablet/theme1/list'));
// const TabletTheme1ActivityView = React.lazy(() => import('./Pages/Activity/Tablet/theme1/view'));
// const TabletTheme1TaskView = React.lazy(() => import('./Pages/Activity/Tablet/theme1/task-view'));
// const TabletTheme1MeetingView = React.lazy(
//   () => import('./Pages/Activity/Tablet/theme1/meeting-view'),
// );

/** ======================== PRODUCT - ITEM ================================ */

const DesktopTheme1ProductList = React.lazy(() => import('@demo-page/product/desktop/theme1/list'));
const DesktopTheme1ProductWrite = React.lazy(
  () => import('@demo-page/product/desktop/theme1/write'),
);
// const DesktopTheme1ProductView = React.lazy(() => import('@demo-page/product/desktop/theme1/view'));
const DesktopTheme1ProductViewNew = React.lazy(
  () => import('@demo-page/product/desktop/theme1/view-new'),
);

// const TabletTheme1ProductList = React.lazy(() => import('./Pages/Product/Tablet/theme1/list'));
// const TabletTheme1ProductView = React.lazy(() => import('./Pages/Product/Tablet/theme1/view'));

const DesktopTheme1UnitList = React.lazy(
  () => import('@demo-page/product/desktop/theme1/unit/list'),
);
// const DesktopTheme1UnitWrite = React.lazy(
//   () => import('@demo-page/product/desktop/theme1/unit/write'),
// );
// const DesktopTheme1UnitView = React.lazy(
//   () => import('@demo-page/product/desktop/theme1/unit/view'),
// );
const DesktopTheme1UnitViewNew = React.lazy(
  () => import('@demo-page/product/desktop/theme1/unit/view-new'),
);

// const TabletTheme1UnitList = React.lazy(() => import('./Pages/Product/Tablet/theme1/Unit/list'));
// const TabletTheme1UnitWrite = React.lazy(() => import('./Pages/Product/Tablet/theme1/Unit/write'));
// const TabletTheme1UnitView = React.lazy(() => import('./Pages/Product/Tablet/theme1/Unit/view'));

const DesktopTheme1ItemList = React.lazy(
  () => import('@demo-page/product/desktop/theme1/item/list'),
);
// const DesktopTheme1ItemWrite = React.lazy(
//   () => import('@demo-page/product/desktop/theme1/item/write'),
// );
// const DesktopTheme1ItemView = React.lazy(
//   () => import('@demo-page/product/desktop/theme1/item/view'),
// );
const DesktopTheme1ItemNewView = React.lazy(
  () => import('@demo-page/product/desktop/theme1/item/view-new'),
);

// const TabletTheme1ItemList = React.lazy(() => import('./Pages/Product/Tablet/theme1/Item/list'));
// const TabletTheme1ItemView = React.lazy(() => import('./Pages/Product/Tablet/theme1/Item/view'));

const DesktopTheme1DiscountList = React.lazy(
  () => import('@demo-page/product/desktop/theme1/discount/list'),
);
// const DesktopTheme1DiscountListOld = React.lazy(
//   () => import('@demo-page/product/desktop/theme1/discount/list-old'),
// );
const DesktopTheme1DiscountWrite = React.lazy(
  () => import('@demo-page/product/desktop/theme1/discount/write'),
);
// const DesktopTheme1DiscountView = React.lazy(
//   () => import('@demo-page/product/desktop/theme1/discount/view'),
// );
const DesktopTheme1DiscountViewNew = React.lazy(
  () => import('@demo-page/product/desktop/theme1/discount/view-new'),
);

// const TabletTheme1DiscountList = React.lazy(
//   () => import('./Pages/Product/Tablet/theme1/discount/list'),
// );
// const TabletTheme1DiscountView = React.lazy(
//   () => import('./Pages/Product/Tablet/theme1/discount/view'),
// );

const DesktopTheme1PricelistWrite = React.lazy(
  () => import('@demo-page/product/desktop/theme1/pricelist/write'),
);
const DesktopTheme1PricelistList = React.lazy(
  () => import('@demo-page/product/desktop/theme1/pricelist/list'),
);
const DesktopTheme1PricelistView = React.lazy(
  () => import('@demo-page/product/desktop/theme1/pricelist/view'),
);

const DesktopTheme1TradeAgreementView = React.lazy(
  () => import('@demo-page/product/desktop/theme1/trade-agreement/view'),
);
const DesktopTheme1TradeAgreementList = React.lazy(
  () => import('@demo-page/product/desktop/theme1/trade-agreement/list'),
);

const DesktopTheme1ComponentList = React.lazy(
  () => import('@demo-page/product/desktop/theme1/component/list'),
);
const DesktopTheme1ComponentView = React.lazy(
  () => import('@demo-page/product/desktop/theme1/component/view'),
);
const DesktopTheme1BaseCostList = React.lazy(
  () => import('@demo-page/product/desktop/theme1/base-cost/list'),
);

/** ======================== SELLING : NOT USE ================================ */

// const DesktopTheme1SellingList = React.lazy(() => import('@demo-page/selling/desktop/theme1/list'));
// const DesktopTheme1SellingView = React.lazy(() => import('@demo-page/selling/desktop/theme1/view'));
// const DesktopTheme1POSCashImage = React.lazy(
//   () => import('@demo-page/selling/desktop/theme1/pos-cash-image'),
// );

// const TabletTheme1SellingList = React.lazy(() => import('./Pages/Selling/Tablet/theme1/list'));
// const TabletTheme1SellingDashboard = React.lazy(
//   () => import('./Pages/Selling/Tablet/theme1/dashboard'),
// );

const DesktopTheme1PurchaseList = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/list'),
);
const DesktopTheme1PurchaseView = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/view'),
);
const DesktopTheme1PurchaseWrite = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/pr-write'),
);
const DesktopTheme1PurchaseRequestView = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/pr-view'),
);
const DesktopTheme1RFQWrite = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/rfq-write'),
);
const DesktopTheme1RFQView = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/rfq-view'),
);
const DesktopTheme1RFTWrite = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/rft-write'),
);
const DesktopTheme1RFTView = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/rft-view'),
);
const DesktopTheme1POWrite = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/po-write'),
);
const DesktopTheme1POView = React.lazy(() => import('@demo-page/purchase/desktop/theme1/po-view'));
const DesktopTheme1PurchaseReturnView = React.lazy(
  () => import('@demo-page/purchase/desktop/theme1/purchase-return-view'),
);

/** ======================== SALES ORDER ================================ */
const DesktopTheme1SellingDashboard = React.lazy(
  () => import('@demo-page/selling/desktop/theme1/dashboard'),
);
const DesktopTheme1SalesOrderList = React.lazy(
  () => import('@demo-page/sales-order/desktop/theme1/list'),
);
const DesktopTheme1SalesOrderViewNew = React.lazy(
  () => import('@demo-page/sales-order/desktop/theme1/view'),
);
const DesktopTheme1SalesReturnList = React.lazy(
  () => import('@demo-page/sales-order/desktop/theme1/sales-return/list'),
);
const DesktopTheme1SalesReturnView = React.lazy(
  () => import('@demo-page/sales-order/desktop/theme1/sales-return/view'),
);
const DesktopTheme1ReplacementOrderList = React.lazy(
  () => import('@demo-page/sales-order/desktop/theme1/replacement-order/list'),
);
const DesktopTheme1ReplacementOrderView = React.lazy(
  () => import('@demo-page/sales-order/desktop/theme1/replacement-order/view'),
);
const DesktopTheme1SalesOrderSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/order'),
);
const DesktopTheme1SalesReport = React.lazy(
  () => import('@demo-page/sales-report/desktop/theme1/list'),
);

const DesktopTheme1InvoiceList = React.lazy(() => import('@demo-page/invoice/desktop/theme1/list'));
const DesktopTheme1InvoiceWrite = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/write'),
);
const DesktopTheme1InvoiceViewNew = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/view-new'),
);

const DesktopTheme1SalesCommissionList = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/sales-commision/list'),
);
const DesktopTheme1SalesCommissionViewNew = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/sales-commision/view-new'),
);

const DesktopTheme1UnpaidInvoiceView = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/unpaid-invoice/view'),
);

const DesktopTheme1CreditNoteView = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/credit-note/view'),
);

const DesktopTheme1DebitNoteView = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/debit-note/view'),
);

const DesktopTheme1PaymentReceivedView = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/payment-received/view'),
);

const DesktopTheme1PurchaseBillView = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/purchase-bill/view'),
);

const DesktopTheme1PaymentMadeView = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/payment-made/view'),
);

const DesktopTheme1VendorCreditView = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/vendor-credit/view'),
);

const DesktopTheme1VendorDebitView = React.lazy(
  () => import('@demo-page/invoice/desktop/theme1/vendor-debit/view'),
);

const DesktopTheme1WarehouseList = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/warehouse/list'),
);
// const DesktopTheme1WarehouseWrite = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/warehouse/write'),
// );
// const DesktopTheme1WarehouseView = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/warehouse/view'),
// );
const DesktopTheme1WarehouseViewNew = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/warehouse/view-new'),
);

const DesktopTheme1InventoryItemsList = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/items/list'),
);
// const DesktopTheme1InventoryItemsView = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/items/view'),
// );
const DesktopTheme1InventoryItemsViewNew = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/items/view-new'),
);
const DesktopTheme1InventoryItemsComponentView = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/items/component-view'),
);

const DesktopTheme1AdjustmentList = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/adjustment/list'),
);
// const DesktopTheme1AdjustmentWrite = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/adjustment/write'),
// );
// const DesktopTheme1AdjustmentView = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/adjustment/view'),
// );
const DesktopTheme1AdjustmentViewNew = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/adjustment/view-new'),
);

const DesktopTheme1TransferOrderList = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/transfer-order/list'),
);
// const DesktopTheme1TransferOrderWrite = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/transfer-order/write'),
// );
// const DesktopTheme1TransferOrderView = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/transfer-order/view'),
// );
const DesktopTheme1TransferOrderViewNew = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/transfer-order/view-new'),
);
const DesktopTheme1TransferOrderViewNew2 = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/transfer-order/view-new2'),
);

const DesktopTheme1ReceiptList = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/receipt/list'),
);
// const DesktopTheme1ReceiptView = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/receipt/view'),
// );
const DesktopTheme1ReceiptViewNew = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/receipt/view-new'),
);

const DesktopTheme1ShipmentList = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/shipment/list'),
);
// const DesktopTheme1ShipmentView = React.lazy(
//   () => import('@demo-page/inventory/desktop/theme1/shipment/view'),
// );
const DesktopTheme1ShipmentViewNew = React.lazy(
  () => import('@demo-page/inventory/desktop/theme1/shipment/view-new'),
);

const DesktopTheme1DeskDashboard = React.lazy(
  () => import('@demo-page/desk/desktop/theme1/dashboard'),
);
const DesktopTheme1TicketList = React.lazy(
  () => import('@demo-page/desk/desktop/theme1/ticket/list'),
);
// const DesktopTheme1TicketWrite = React.lazy(
//   () => import('@demo-page/desk/desktop/theme1/ticket/write'),
// );
// const DesktopTheme1TicketView = React.lazy(
//   () => import('@demo-page/desk/desktop/theme1/ticket/view'),
// );
const DesktopTheme1TicketViewNew = React.lazy(
  () => import('@demo-page/desk/desktop/theme1/ticket/view-new'),
);
const DesktopTheme1KnowledgeBaseList = React.lazy(
  () => import('@demo-page/desk/desktop/theme1/knowledge-base/list'),
);
// const DesktopTheme1KnowledgeBaseView = React.lazy(
//   () => import('@demo-page/desk/desktop/theme1/knowledge-base/view'),
// );
const DesktopTheme1KnowledgeBaseViewNew = React.lazy(
  () => import('@demo-page/desk/desktop/theme1/knowledge-base/view-new'),
);
const DesktopTheme1DeskSettings = React.lazy(
  () => import('@demo-page/desk/desktop/theme1/settings/settings'),
);

// const DesktopTheme1EventList = React.lazy(() => import("@demo-page/marketing/desktop/theme1/event/list"));
// const DesktopTheme1EventView = React.lazy(() => import("@demo-page/marketing/desktop/theme1/event/view"));
// const DesktopTheme1EventViewNew = React.lazy(() => import("@demo-page/marketing/desktop/theme1/event/view-new"));
// const DesktopTheme1EventSettings = React.lazy(() => import("@demo-page/settings/desktop/theme1/event"));

// const DesktopTheme1CampaignReferralRewardList = React.lazy(() => import("@demo-page/marketing/desktop/theme1/campaign/referral-reward-list"));
// const DesktopTheme1CampaignReferralRewardWrite = React.lazy(() => import("@demo-page/marketing/desktop/theme1/campaign/referral-reward-write"));

const DesktopTheme1MarketingFormBuilder = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/form-builder'),
);

const DomEditor = React.lazy(() => import('@demo-page/marketing/desktop/theme1/dom-editor'));
const UploadBlockStorage = React.lazy(() => import('@demo-page/component/desktop/demo-tui-editor'));

const DesktopTheme1MarketingLeadList = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/lead/list'),
);
const DesktopTheme1MarketingLeadView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/lead/view'),
);
const DesktopTheme1MarketingChannelsList = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/marketing-resource/marketing-channels/list'),
);
const DesktopTheme1TemplatesList = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/marketing-resource/templates/list'),
);
const DesktopTheme1TemplateEmailView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/marketing-resource/templates/view-email'),
);
const DesktopTheme1TemplateSMSView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/marketing-resource/templates/view-sms'),
);
const DesktopTheme1OnlineDigitalContentsList = React.lazy(
  () =>
    import('@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/list'),
);
const DesktopTheme1CTAView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-cta'
    ),
);
const DesktopTheme1LandingPageView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-landing-page'
    ),
);
const DesktopTheme1LandingPageView2 = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-landing-page-unpublished'
    ),
);
const DesktopTheme1FormView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-form'
    ),
);
const DesktopTheme1OfferSiteView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-offer-site'
    ),
);
const DesktopTheme1ReferralSiteView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-referral-reward-site'
    ),
);
const DesktopTheme1ReferralSiteView2 = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-referral-reward-site-unpublished'
    ),
);
const DesktopTheme1EventSiteView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-event-site'
    ),
);
const DesktopTheme1EventSiteView2 = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-event-site-unpublished'
    ),
);
const DesktopTheme1OfferDownloadFileView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-offer-download-file'
    ),
);
const DesktopTheme1ImageView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/online-digital-contents/view-image'
    ),
);
const DesktopTheme1OfflineDigitalContentsList = React.lazy(
  () =>
    import('@demo-page/marketing/desktop/theme1/marketing-resource/offline-digital-contents/list'),
);
const DesktopTheme1BroadContentView2 = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/offline-digital-contents/view-broadcast-contents-inactive'
    ),
);
const DesktopTheme1BroadContentView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/offline-digital-contents/view-broadcast-contents'
    ),
);
const DesktopTheme1PrintMediaContentView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/offline-digital-contents/view-print-media-contents'
    ),
);
const DesktopTheme1DigitalOOHView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/offline-digital-contents/view-digital-ooh'
    ),
);
const DesktopTheme1FaxView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/offline-digital-contents/view-fax'
    ),
);
const DesktopTheme1MarketingCollateralList = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/marketing-resource/marketing-collateral/list'),
);
const DesktopTheme1PrintAdsView2 = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/marketing-collateral/view-print-ads-inactive'
    ),
);
const DesktopTheme1PrintAdsView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/marketing-collateral/view-print-ads'
    ),
);
const DesktopTheme1PrintedOOHView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/marketing-collateral/view-printed-ooh'
    ),
);
const DesktopTheme1PromotionalItemView = React.lazy(
  () =>
    import(
      '@demo-page/marketing/desktop/theme1/marketing-resource/marketing-collateral/view-promotional-item'
    ),
);

const DesktopTheme1CampaignList = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/list'),
);
const DesktopTheme1CampaignEmailView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/email-view'),
);
const DesktopTheme1CampaignEmailView2 = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/email-view-unfinished'),
);
const DesktopTheme1CampaignEventView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/event-view'),
);
const DesktopTheme1CampaignEventView2 = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/event-view-before-launched'),
);
const DesktopTheme1CampaignPaidMediaView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/paid-media-view'),
);
const DesktopTheme1CampaignPaidSocialView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/paid-social-view'),
);
const DesktopTheme1CampaignSocialView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/social-view'),
);
const DesktopTheme1CampaignReferralRewardView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/referral-reward-view'),
);
const DesktopTheme1CampaignDMView2 = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/dm-view-unfinished'),
);
const DesktopTheme1CampaignDMView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/dm-view'),
);
const DesktopTheme1CampaignPADView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/print-ads-view-unfinished'),
);
const DesktopTheme1CampaignPADView2 = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/print-ads-view'),
);
const DesktopTheme1CampaignOrganicLinkView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/campaign/organic-link-view'),
);
const DesktopTheme1LoyaltyList = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/loyalty/list'),
);
const DesktopTheme1LoyaltyView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/loyalty/view-new'),
);
const DesktopTheme1ABMList = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/account-based-marketing/list'),
);
const DesktopTheme1ABMView = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/account-based-marketing/view'),
);
const DesktopTheme1ABMViewAfterOpen = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/account-based-marketing/view-after-open'),
);
const DesktopTheme1ABMViewResult = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/account-based-marketing/view-result'),
);
const DesktopTheme1TargetAccountList = React.lazy(
  () => import('@demo-page/marketing/desktop/theme1/target-account/list'),
);

const DesktopTheme1QuoteViewNew = React.lazy(
  () => import('@demo-page/sales/desktop/theme1/quote/view-new'),
);

const DesktopTheme1OpportunityList = React.lazy(
  () => import('@demo-page/sales/desktop/theme1/opportunity/list'),
);
const DesktopTheme1OpportunityView = React.lazy(
  () => import('@demo-page/sales/desktop/theme1/opportunity/view'),
);
const DesktopTheme1GeneralSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/general'),
);
const DesktopTheme1AssignmentRuleSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/assignment-rule'),
);
const DesktopTheme1SalesCommissionSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/sales-commission'),
);
// const DesktopTheme1PriceDiscountSettings = React.lazy(
//   () => import('@demo-page/settings/desktop/theme1/price-discount'),
// );
const DesktopTheme1MeetingSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/meeting'),
);
const DesktopTheme1InventorySettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/inventory'),
);
const DesktopTheme1InvoiceSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/invoice'),
);
const DesktopTheme1BillingLicenseSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/billing-licenses'),
);
const DesktopTheme1RatingRuleSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/rating-rule'),
);
const DesktopTheme1ProfileSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/customer-profile'),
);
// const DesktopTheme1FormatSettings = React.lazy(
//   () => import('@demo-page/settings/desktop/theme1/format'),
// );
const DesktopTheme1TemplateSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/templates'),
);
const DesktopTheme1ProcessSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/process'),
);
const DesktopTheme1DiagramNewSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/diagram-new'),
);
const DesktopTheme1LeadScoringRuleSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/lead-scoring-rule'),
);
const DesktopTheme1PreferencesSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/preferences'),
);
const DesktopTheme1GoalMetricSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/goal-metric'),
);
const DesktopTheme1KPISettings = React.lazy(() => import('@demo-page/settings/desktop/theme1/kpi'));
const DesktopTheme1SalesTeamSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/sales-team'),
);
const DesktopTheme1MembershipSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/membership'),
);
const DesktopTheme1JourneyProcessSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/journey-process'),
);
const DesktopTheme1JourneyProcessViewSettings = React.lazy(
  () => import('@demo-page/settings/desktop/theme1/journey-process-view'),
);

// Site
const DesktopTheme1VendorSite = React.lazy(() => import('@demo-page/site/vendor'));
const DesktopTheme1QuoteSite = React.lazy(() => import('@demo-page/site/quote'));
const DesktopTheme1DeskSite = React.lazy(() => import('@demo-page/site/desk'));

// Report
const DesktopTheme1ReportList = React.lazy(() => import('@demo-page/report/desktop/theme1/list'));
const DesktopTheme1ReportView = React.lazy(() => import('@demo-page/report/desktop/theme1/view'));

// const ProductSplitModeSideBar = React.lazy(() => import('./Pages/Product/splitmode/Sidebar'));
// const ProductSplitModeView = React.lazy(() => import('./Pages/Product/splitmode/View'));

/*
const QuoteSite = React.lazy(() => import('./Pages/Site/quote'));
const VendorSite = React.lazy(() => import('./Pages/Site/vendor'));

const KPI = React.lazy(() => import('@demo-page/settings/desktop/theme1/kpi'));

const ThuysPage = React.lazy(() => import('./Pages/Thuy/page1'));

const Demo = React.lazy(() => import('./Pages/Demo'));
*/

export default [
  {
    path: '/demo-page',
    component: DemoPage,
  },

  /** ======================== CUSTOMER ================================ */
  {
    path: '/customer/desktop/theme1/account-list',
    title: 'Account Desktop Theme1 List',
    component: DesktopTheme1AccountList,
    menu: 'customer',
  },
  {
    path: '/customer/desktop/theme1/account-view',
    title: 'Account Desktop Theme1 View',
    component: DesktopTheme1AccountView,
    menu: 'customer',
  },
  {
    path: '/customer/desktop/theme1/split-view',
    title: 'Split Mode',
    component: SplitModeSideBar,
    // components: {
    //   sidebar: SplitModeSideBar,
    //   // main: SplitModeView,
    // },
    menu: 'customer',
  },
  {
    path: '/customer/desktop/theme1/contact-list',
    title: 'Contact Desktop Theme1 List',
    component: DesktopTheme1ContactList,
    menu: 'customer',
  },
  {
    path: '/customer/desktop/theme1/contact-view',
    title: 'Contact Desktop Theme1 View',
    component: DesktopTheme1ContactView,
    menu: 'customer',
  },
  {
    path: '/segment/desktop/theme1/list',
    title: 'Segment Desktop Theme1 List',
    component: DesktopTheme1SegmentList,
    menu: 'customer',
  },
  {
    path: '/segment/desktop/theme1/view',
    title: 'Segment Desktop Theme1 View',
    component: DesktopTheme1SegmentView,
    menu: 'customer',
  },
  {
    path: '/customer/desktop/theme1/view-new',
    title: 'Customer Desktop Theme1 View New',
    component: DesktopTheme1ViewNew,
    menu: 'customer',
  },
  {
    path: '/marketing-list/desktop/theme1/list',
    title: 'Customer Marketing List Desktop Theme1 List',
    component: DesktopTheme1MarketingList,
    menu: 'customer',
  },
  // {
  //     path: '/marketing-list/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Customer Marketing List Desktop Theme1 View',
  //     component: DesktopTheme1MarketingView
  // },
  {
    path: '/marketing-list/desktop/theme1/view-new',
    title: 'Customer Marketing List Desktop Theme1 View New',
    component: DesktopTheme1MarketingViewNew,
    menu: 'customer',
  },
  {
    path: '/marketing-list/desktop/theme1/write',
    title: 'Customer Marketing List Desktop Theme1 Write',
    component: DesktopTheme1MarketingWrite,
    menu: 'customer',
  },
  {
    path: '/buyer-persona/desktop/theme1/list',
    title: 'Customer Buyer Persona Desktop Theme1 List',
    component: DesktopTheme1BuyerPersonaList,
    menu: 'customer',
  },
  {
    path: '/buyer-persona/desktop/theme1/view',
    title: 'Customer Buyer Persona Desktop Theme1 View',
    component: DesktopTheme1BuyerPersonaView,
    menu: 'customer',
  },
  {
    path: '/icp/desktop/theme1/list',
    title: 'Customer Ideal Customer Profile Desktop Theme1 List',
    component: DesktopTheme1ICPList,
    menu: 'customer',
  },
  {
    path: '/icp/desktop/theme1/view',
    title: 'Customer Ideal Customer Profile Desktop Theme1 View',
    component: DesktopTheme1ICPView,
    menu: 'customer',
  },
  {
    path: '/blocklist/desktop/theme1/list',
    title: 'Customer Blocklist Desktop Theme1 List',
    component: DesktopTheme1BlocklistList,
    menu: 'customer',
  },
  // NOT USE
  // {
  //   path: '/customer/phone/theme1/list',
  //   title: 'CustomerPhoneTheme1List',
  //   component: PhoneTheme1List
  // },
  // {
  //   path: '/customer/phone/theme1/view',
  //   title: 'CustomerPhoneTheme1View',
  //   component: PhoneTheme1View
  // },
  // {
  //   path: '/customer/phone/theme1/write',
  //   title: 'CustomerPhoneTheme1Write',
  //   component: PhoneTheme1Write
  // },
  // {
  //   path: '/customer/phone/theme1/some',
  //   title: 'CustomerPhoneTheme1Some',
  //   component: PhoneTheme1Some
  // },
  // {
  //   path: '/customer/phone/theme2/list',
  //   title: 'CustomerPhoneTheme2List',
  //   component: PhoneTheme2List
  // },
  // {
  //   path: '/customer/phone/theme2/view',
  //   title: 'CustomerPhoneTheme2View',
  //   component: PhoneTheme2View
  // },
  // {
  //   path: '/customer/phone/theme2/write',
  //   title: 'CustomerPhoneTheme2Write',
  //   //component: PhoneTheme2Write
  // },
  // {
  //   path: '/customer/phone/theme2/some',
  //   title: 'CustomerPhoneTheme2Some',
  //   //component: PhoneTheme2Some
  // },
  // {
  //   path: '/customer/tablet/theme1/list',
  //   title: 'Customer Tablet Theme1 List',
  //   //component: TabletTheme1List
  // },
  // {
  //   path: '/customer/tablet/theme1/view',
  //   title: 'Customer Tablet Theme1 View',
  //   //component: TabletTheme1View
  // },

  /** ======================= ACTIVITY ================================= */
  {
    path: '/mywork/desktop/theme1/dashboard',
    title: 'My Work Desktop Theme1 Dashboard',
    component: DesktopTheme1MyWorkDashboard,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/list',
    title: 'Activity Desktop Theme1 List',
    component: DesktopTheme1ActivityList,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/task-view-new',
    title: 'Activity Desktop Theme1 Task View New',
    component: DesktopTheme1ActivityTaskViewNew,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/meeting-view-new',
    title: 'Activity Desktop Theme1 Meeting View New',
    component: DesktopTheme1ActivityMeetingViewNew,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/email-view',
    title: 'Activity Desktop Theme1 Email View',
    component: DesktopTheme1ActivityEmailView,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/call-view',
    title: 'Activity Desktop Theme1 Call View',
    component: DesktopTheme1ActivityCallView,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/sms-view',
    title: 'Activity Desktop Theme1 SMS View',
    component: DesktopTheme1ActivitySMSView,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/fax-view',
    title: 'Activity Desktop Theme1 Fax View',
    component: DesktopTheme1ActivityFaxView,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/dm-view',
    title: 'Activity Desktop Theme1 DM View',
    component: DesktopTheme1ActivityDMView,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/production-task-list',
    title: 'Activity Desktop Theme1 Production Task List',
    component: DesktopTheme1ActivityProductionTaskList,
    menu: 'activity',
  },
  {
    path: '/activity/desktop/theme1/production-task-view',
    title: 'Activity Desktop Theme1 Production Task View',
    component: DesktopTheme1ActivityProductionTaskView,
    menu: 'activity',
  },
  {
    path: '/mywork/desktop/theme1/activity-comparison',
    title: 'Activity Comparison Desktop Theme1',
    component: DesktopTheme1ActivityComparison,
    menu: 'activity',
  },
  // {
  //     path: '/activity/tablet/theme1/list',
  //     auth: true,
  //     icon: null,
  //     title: 'Activity Tablet Theme1 List',
  //     component: TabletTheme1ActivityList
  // },
  // {
  //     path: '/activity/tablet/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Activity Tablet Theme1 View',
  //     component: TabletTheme1ActivityView
  // },
  // {
  //     path: '/activity/tablet/theme1/task-view',
  //     auth: true,
  //     icon: null,
  //     title: 'Activity Tablet Theme1 Task View',
  //     component: TabletTheme1TaskView
  // },
  // {
  //     path: '/activity/tablet/theme1/meeting-view',
  //     auth: true,
  //     icon: null,
  //     title: 'Activity Tablet Theme1 Meeting View',
  //     component: TabletTheme1MeetingView
  // },

  /** ======================== PRODUCT ================================ */
  {
    path: '/product/desktop/theme1/list',
    title: 'Product Desktop Theme1 List',
    component: DesktopTheme1ProductList,
    menu: 'product',
  },
  {
    path: '/product/desktop/theme1/write',
    title: 'Product Desktop Theme1 Write',
    component: DesktopTheme1ProductWrite,
    menu: 'product',
  },
  // {
  //     path: '/product/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Product Desktop Theme1 View',
  //     component: DesktopTheme1ProductView
  // },
  {
    path: '/product/desktop/theme1/view-new',
    title: 'Product Desktop Theme1 View New',
    component: DesktopTheme1ProductViewNew,
    menu: 'product',
  },
  // {
  //     path: '/product/tablet/theme1/list',
  //     auth: true,
  //     icon: null,
  //     title: 'Product Tablet Theme1 List',
  //     component: TabletTheme1ProductList
  // },
  // {
  //     path: '/product/tablet/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Product Tablet Theme1 View',
  //     component: TabletTheme1ProductView
  // },
  // {
  //     path: '/product/desktop/theme1/split-view/:n',
  //     uri: '/product/desktop/theme1/split-view/111',
  //     auth: true,
  //     icon: null,
  //     title: 'Split Mode',
  //     routerKey: 'n',

  //     components: {
  //         sidebar: ProductSplitModeSideBar,
  //         main: ProductSplitModeView,
  //     },
  // },
  {
    path: '/unit/desktop/theme1/list',
    title: 'Unit Desktop Theme1 List',
    component: DesktopTheme1UnitList,
    menu: 'product',
  },
  // {
  //     path: '/unit/desktop/theme1/write',
  //     auth: true,
  //     icon: null,
  //     title: 'Unit Desktop Theme1 Write',
  //     component: DesktopTheme1UnitWrite
  // },
  // {
  //     path: '/unit/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Unit Desktop Theme1 View',
  //     component: DesktopTheme1UnitView
  // },
  {
    path: '/unit/desktop/theme1/view-new',
    title: 'Unit Desktop Theme1 View New',
    component: DesktopTheme1UnitViewNew,
    menu: 'product',
  },
  // {
  //     path: '/unit/tablet/theme1/list',
  //     auth: true,
  //     icon: null,
  //     title: 'Unit Tablet Theme1 List',
  //     component: TabletTheme1UnitList
  // },
  // {
  //     path: '/unit/tablet/theme1/write',
  //     auth: true,
  //     icon: null,
  //     title: 'Unit Tablet Theme1 Write',
  //     component: TabletTheme1UnitWrite
  // },
  // {
  //     path: '/unit/tablet/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Unit Tablet Theme1 View',
  //     component: TabletTheme1UnitView
  // },
  {
    path: '/item/desktop/theme1/list',
    title: 'Item Desktop Theme1 List',
    component: DesktopTheme1ItemList,
    menu: 'product',
  },
  // {
  //     path: '/item/desktop/theme1/write',
  //     auth: true,
  //     icon: null,
  //     title: 'Item Desktop Theme1 Write',
  //     component: DesktopTheme1ItemWrite
  // },
  // {
  //     path: '/item/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Item Desktop Theme1 View',
  //     component: DesktopTheme1ItemView
  // },
  {
    path: '/item/desktop/theme1/view-new',
    title: 'Item Desktop Theme1 View New',
    component: DesktopTheme1ItemNewView,
    menu: 'product',
  },
  // {
  //     path: '/item/tablet/theme1/list',
  //     auth: true,
  //     icon: null,
  //     title: 'Item Tablet Theme1 List',
  //     component: TabletTheme1ItemList
  // },
  // {
  //     path: '/item/tablet/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Item Tablet Theme1 View',
  //     component: TabletTheme1ItemView
  // },
  {
    path: '/discount/desktop/theme1/list',
    title: 'Discount Desktop Theme1 List',
    component: DesktopTheme1DiscountList,
    menu: 'product',
  },
  // {
  //     path: '/discount/desktop/theme1/list-old',
  //     auth: true,
  //     icon: null,
  //     title: 'Discount Desktop Theme1 List Old',
  //     component: DesktopTheme1DiscountListOld
  // },
  {
    path: '/discount/desktop/theme1/write',
    title: 'DiscountDesktopTheme1Write',
    component: DesktopTheme1DiscountWrite,
    menu: 'product',
  },
  // {
  //     path: '/discount/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Discount Desktop Theme1 View',
  //     component: DesktopTheme1DiscountView
  // },
  {
    path: '/discount/desktop/theme1/view-new',
    title: 'Discount Desktop Theme1 View New',
    component: DesktopTheme1DiscountViewNew,
    menu: 'product',
  },
  // {
  //     path: '/discount/tablet/theme1/list',
  //     auth: true,
  //     icon: null,
  //     title: 'Discount Tablet Theme1 List',
  //     component: TabletTheme1DiscountList
  // },
  // {
  //     path: '/discount/tablet/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Discount Tablet Theme1 View',
  //     component: TabletTheme1DiscountView
  // },
  {
    path: '/pricelist/desktop/theme1/list',
    title: 'Pricelist Desktop Theme1 List',
    component: DesktopTheme1PricelistList,
    menu: 'product',
  },
  {
    path: '/pricelist/desktop/theme1/write',
    title: 'Pricelist Desktop Theme1 Write',
    component: DesktopTheme1PricelistWrite,
    menu: 'product',
  },
  {
    path: '/pricelist/desktop/theme1/view',
    title: 'Pricelist Desktop Theme1 View',
    component: DesktopTheme1PricelistView,
    menu: 'product',
  },
  {
    path: '/trade-agreement/desktop/theme1/list',
    title: 'Trade Agreement Desktop Theme1 List',
    component: DesktopTheme1TradeAgreementList,
    menu: 'product',
  },
  {
    path: '/trade-agreement/desktop/theme1/view',
    title: 'Trade Agreement Desktop Theme1 View',
    component: DesktopTheme1TradeAgreementView,
    menu: 'product',
  },
  {
    path: '/component/desktop/theme1/list',
    title: 'Component Desktop Theme1 List',
    component: DesktopTheme1ComponentList,
    menu: 'product',
  },
  {
    path: '/component/desktop/theme1/view',
    title: 'Component Desktop Theme1 View',
    component: DesktopTheme1ComponentView,
    menu: 'product',
  },
  {
    path: '/base-cost/desktop/theme1/list',
    title: 'Base Cost Desktop Theme1 List',
    component: DesktopTheme1BaseCostList,
    menu: 'product',
  },

  /** ================================= SELLING : NOT USE =========================== */
  // {
  //   path: '/selling/desktop/theme1/list',
  //   title: 'Selling Desktop Theme1 List',
  //   component: DesktopTheme1SellingList,
  //   menu: 'selling',
  // },
  // {
  //   path: '/selling/desktop/theme1/view',
  //   title: 'Selling Desktop Theme1 View',
  //   component: DesktopTheme1SellingView,
  //   menu: 'selling',
  // },
  // {
  //   path: '/selling/desktop/theme1/pos-cash-image',
  //   title: 'POS Cash Image',
  //   component: DesktopTheme1POSCashImage,
  //   menu: 'selling',
  // },

  // {
  //     path: '/selling/tablet/theme1/list',
  //     auth: true,
  //     icon: null,
  //     title: 'Selling Tablet Theme1 List',
  //     component: TabletTheme1SellingList
  // },
  // {
  //     path: '/selling/tablet/theme1/dashboard',
  //     auth: true,
  //     icon: null,
  //     title: 'Selling Tablet Theme1 Dashboard',
  //     component: TabletTheme1SellingDashboard
  // },

  /** ================================= PURCHASE =========================== */
  {
    path: '/purchase/desktop/theme1/list',
    title: 'Purchase Desktop Theme1 List',
    component: DesktopTheme1PurchaseList,
    menu: 'purchase',
  },
  {
    path: '/purchase/desktop/theme1/view',
    title: 'Purchase Desktop Theme1 View',
    component: DesktopTheme1PurchaseView,
    menu: 'purchase',
  },
  // {
  //   path: '/purchase/desktop/theme1/pr-write',
  //   title: 'Purchase Request Desktop Theme1 Write',
  //   component: DesktopTheme1PurchaseWrite,
  //   menu: 'purchase',
  // },
  {
    path: '/purchase/desktop/theme1/pr-view',
    title: 'PR Desktop Theme1 View',
    component: DesktopTheme1PurchaseRequestView,
    menu: 'purchase',
  },
  // {
  //   path: '/purchase/desktop/theme1/rfq-write',
  //   title: 'RFQ Desktop Theme1 Write',
  //   component: DesktopTheme1RFQWrite,
  //   menu: 'purchase',
  // },
  {
    path: '/purchase/desktop/theme1/rfq-view',
    title: 'RFQ Desktop Theme1 View',
    component: DesktopTheme1RFQView,
    menu: 'purchase',
  },
  // {
  //   path: '/purchase/desktop/theme1/rft-write',
  //   title: 'RFT Desktop Theme1 Write',
  //   component: DesktopTheme1RFTWrite,
  //   menu: 'purchase',
  // },
  {
    path: '/purchase/desktop/theme1/rft-view',
    title: 'RFT Desktop Theme1 View',
    component: DesktopTheme1RFTView,
    menu: 'purchase',
  },
  // {
  //   path: '/purchase/desktop/theme1/po-write',
  //   title: 'PO Desktop Theme1 Write',
  //   component: DesktopTheme1POWrite,
  //   menu: 'purchase',
  // },
  {
    path: '/purchase/desktop/theme1/po-view',
    title: 'PO Desktop Theme1 View',
    component: DesktopTheme1POView,
    menu: 'purchase',
  },
  {
    path: '/purchase/desktop/theme1/return-view',
    title: 'Purchase Return Desktop Theme1 View',
    component: DesktopTheme1PurchaseReturnView,
    menu: 'purchase',
  },

  /** ================================= ORDER =========================== */
  {
    path: '/sales-order/desktop/theme1/list',
    title: 'Sales Order Desktop Theme1 List',
    component: DesktopTheme1SalesOrderList,
    menu: 'order',
  },
  {
    path: '/sales-order/desktop/theme1/view-new',
    title: 'Sales Order Desktop Theme1 View New',
    component: DesktopTheme1SalesOrderViewNew,
    menu: 'order',
  },
  {
    path: '/sales-return/desktop/theme1/list',
    title: 'Sales Return Desktop Theme1 List',
    component: DesktopTheme1SalesReturnList,
    menu: 'order',
  },
  {
    path: '/sales-return/desktop/theme1/view',
    title: 'Sales Return Desktop Theme1 View',
    component: DesktopTheme1SalesReturnView,
  },
  {
    path: '/replacement-order/desktop/theme1/list',
    title: 'Replacement Order Desktop Theme1 List',
    component: DesktopTheme1ReplacementOrderList,
    menu: 'order',
  },
  {
    path: '/replacement-order/desktop/theme1/view',
    title: 'Replacement Order Desktop Theme1 View',
    component: DesktopTheme1ReplacementOrderView,
    menu: 'order',
  },
  {
    path: '/pos/desktop/theme1/dashboard',
    title: 'Poin of Sale Desktop Theme1',
    component: DesktopTheme1SellingDashboard,
    menu: 'order',
  },
  {
    path: '/sales-report/desktop/theme1/list',
    title: 'Sales Report Desktop Theme1',
    component: DesktopTheme1SalesReport,
    menu: 'order',
  },

  /** ================================= INVOICE =========================== */
  {
    path: '/invoice/desktop/theme1/list',
    title: 'Invoice Desktop Theme1 List',
    component: DesktopTheme1InvoiceList,
    menu: 'invoice',
  },
  {
    path: '/invoice/desktop/theme1/write',
    menu: 'invoice',
    title: 'Invoice Desktop Theme1 Write',
    component: DesktopTheme1InvoiceWrite,
  },
  {
    path: '/invoice/desktop/theme1/view-new',
    menu: 'invoice',
    title: 'Invoice Desktop Theme1 View New',
    component: DesktopTheme1InvoiceViewNew,
  },
  {
    path: '/unpaid-invoice/desktop/theme1/view',
    menu: 'invoice',
    title: 'Unpaid Invoice Desktop Theme1 View',
    component: DesktopTheme1UnpaidInvoiceView,
  },
  {
    path: '/credit-note/desktop/theme1/view',
    menu: 'invoice',
    title: 'Credit Note Desktop Theme1 View',
    component: DesktopTheme1CreditNoteView,
  },
  {
    path: '/debit-note/desktop/theme1/view',
    menu: 'invoice',
    title: 'Debit Note Desktop Theme1 View',
    component: DesktopTheme1DebitNoteView,
  },
  {
    path: '/payment-received/desktop/theme1/view',
    menu: 'invoice',
    title: 'Payment Received Desktop Theme1 View',
    component: DesktopTheme1PaymentReceivedView,
  },
  {
    path: '/purchase-bill/desktop/theme1/view',
    menu: 'invoice',
    title: 'Purchase Bill Desktop Theme1 View',
    component: DesktopTheme1PurchaseBillView,
  },
  {
    path: '/payment-made/desktop/theme1/view',
    menu: 'invoice',
    title: 'Payment Made Desktop Theme1 View',
    component: DesktopTheme1PaymentMadeView,
  },
  {
    path: '/vendor-credit/desktop/theme1/view',
    menu: 'invoice',
    title: 'Vendor Credit Desktop Theme1 View',
    component: DesktopTheme1VendorCreditView,
  },
  {
    path: '/vendor-debit/desktop/theme1/view',
    menu: 'invoice',
    title: 'Vendor Debit Desktop Theme1 View',
    component: DesktopTheme1VendorDebitView,
  },
  {
    path: '/sales-commission/desktop/theme1/list',
    menu: 'invoice',
    title: 'Sales Commission Desktop Theme1 List',
    component: DesktopTheme1SalesCommissionList,
  },
  {
    path: '/sales-commission/desktop/theme1/view-new',
    amenu: 'invoice',
    title: 'Sales Commission Desktop Theme1 View New',
    component: DesktopTheme1SalesCommissionViewNew,
  },

  /** ================================= INVENTORY =========================== */
  {
    path: '/warehouse/desktop/theme1/list',
    menu: 'inventory',
    title: 'Warehouse Desktop Theme1 List',
    component: DesktopTheme1WarehouseList,
  },
  // {
  //     path: '/warehouse/desktop/theme1/write',
  //     auth: true,
  //     icon: null,
  //     title: 'Warehouse Desktop Theme1 Write',
  //     component: DesktopTheme1WarehouseWrite
  // },
  // {
  //     path: '/warehouse/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Warehouse Desktop Theme1 View',
  //     component: DesktopTheme1WarehouseView
  // },
  {
    path: '/warehouse/desktop/theme1/view-new',
    menu: 'inventory',
    title: 'Warehouse Desktop Theme1 View New',
    component: DesktopTheme1WarehouseViewNew,
  },
  {
    path: '/inventory-items/desktop/theme1/list',
    menu: 'inventory',
    title: 'Inventory Items Desktop Theme1 List',
    component: DesktopTheme1InventoryItemsList,
  },
  // {
  //     path: '/inventory-items/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Inventory Items Desktop Theme1 View',
  //     component: DesktopTheme1InventoryItemsView
  // },
  {
    path: '/inventory-items/desktop/theme1/view-new',
    menu: 'inventory',
    title: 'Inventory Items Desktop Theme1 View New',
    component: DesktopTheme1InventoryItemsViewNew,
  },
  {
    path: '/inventory-items/desktop/theme1/component-view',
    menu: 'inventory',
    title: 'Inventory Items Desktop Theme1 Component View',
    component: DesktopTheme1InventoryItemsComponentView,
  },
  {
    path: '/adjustment/desktop/theme1/list',
    menu: 'inventory',
    title: 'Adjustment Desktop Theme1 List',
    component: DesktopTheme1AdjustmentList,
  },
  // {
  //     path: '/adjustment/desktop/theme1/write',
  //     auth: true,
  //     icon: null,
  //     title: 'Adjustment Desktop Theme1 Write',
  //     component: DesktopTheme1AdjustmentWrite
  // },
  // {
  //     path: '/adjustment/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Adjustment Desktop Theme1 View',
  //     component: DesktopTheme1AdjustmentView
  // },
  {
    path: '/adjustment/desktop/theme1/view-new',
    menu: 'inventory',
    title: 'Adjustment Desktop Theme1 View New',
    component: DesktopTheme1AdjustmentViewNew,
  },
  {
    path: '/transfer-order/desktop/theme1/list',
    menu: 'inventory',
    title: 'Stock Transfer Desktop Theme1 List',
    component: DesktopTheme1TransferOrderList,
  },
  // {
  //     path: '/transfer-order/desktop/theme1/write',
  //     auth: true,
  //     icon: null,
  //     title: 'Transfer Order Desktop Theme1 Write',
  //     component: DesktopTheme1TransferOrderWrite
  // },
  // {
  //     path: '/transfer-order/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Transfer Order Desktop Theme1 View',
  //     component: DesktopTheme1TransferOrderView
  // },
  {
    path: '/transfer-order/desktop/theme1/view-new',
    menu: 'inventory',
    title: 'Stock Transfer Desktop Theme1 View New External',
    component: DesktopTheme1TransferOrderViewNew,
  },
  {
    path: '/transfer-order/desktop/theme1/view-new2',
    menu: 'inventory',
    title: 'Stock Transfer Desktop Theme1 View New Internal',
    component: DesktopTheme1TransferOrderViewNew2,
  },
  {
    path: '/receipt/desktop/theme1/list',
    menu: 'inventory',
    title: 'Receipt Desktop Theme1 List',
    component: DesktopTheme1ReceiptList,
  },
  // {
  //     path: '/receipt/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Receipt Desktop Theme1 View',
  //     component: DesktopTheme1ReceiptView
  // },
  {
    path: '/receipt/desktop/theme1/view-new',
    menu: 'inventory',
    title: 'Receipt Desktop Theme1 View New',
    component: DesktopTheme1ReceiptViewNew,
  },
  // {
  //     path: '/receipt/desktop/theme1/todo',
  //     auth: true,
  //     icon: null,
  //     title: 'Receipt Desktop Theme1 Todo',
  //     component: DesktopTheme1ReceiptTodo
  // },
  {
    path: '/shipment/desktop/theme1/list',
    menu: 'inventory',
    title: 'Shipment Desktop Theme1 List',
    component: DesktopTheme1ShipmentList,
  },
  // {
  //     path: '/shipment/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Shipment Desktop Theme1 View',
  //     component: DesktopTheme1ShipmentView
  // },
  {
    path: '/shipment/desktop/theme1/view-new',
    menu: 'inventory',
    title: 'Shipment Desktop Theme1 View New',
    component: DesktopTheme1ShipmentViewNew,
  },
  // {
  //     path: '/shipment/desktop/theme1/todo',
  //     auth: true,
  //     icon: null,
  //     title: 'Shipment Desktop Theme1 Todo',
  //     component: DesktopTheme1ShipmentTodo
  // },

  /** ================================= DESK =========================== */
  {
    path: '/desk/desktop/theme1/dashboard',
    menu: 'desk',
    title: 'Desk Desktop Theme1 Dashboard',
    component: DesktopTheme1DeskDashboard,
  },
  {
    path: '/ticket/desktop/theme1/list',
    menu: 'desk',
    title: 'Ticket Desktop Theme1 List',
    component: DesktopTheme1TicketList,
  },
  // {
  //   path: '/ticket/desktop/theme1/write',
  //   menu: 'desk',
  //   title: 'Ticket Desktop Theme1 Write',
  //   component: DesktopTheme1TicketWrite,
  // },
  // {
  //     path: '/ticket/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Ticket Desktop Theme1 View',
  //     component: DesktopTheme1TicketView
  // },
  {
    path: '/ticket/desktop/theme1/view-new',
    menu: 'desk',
    title: 'Ticket Desktop Theme1 View New',
    component: DesktopTheme1TicketViewNew,
  },
  {
    path: '/knowledge-base/desktop/theme1/list',
    menu: 'desk',
    title: 'Knowledge Base Desktop Theme1 List',
    component: DesktopTheme1KnowledgeBaseList,
  },
  // {
  //   path: '/knowledge-base/desktop/theme1/write',
  //   menu: 'desk',
  //   title: 'Knowledge Base Desktop Theme1 Write',
  //   component: DesktopTheme1KnowledgeBaseWrite,
  // },
  {
    path: '/knowledge-base/desktop/theme1/view-new',
    menu: 'desk',
    title: 'Knowledge Base Desktop Theme1 View New',
    component: DesktopTheme1KnowledgeBaseViewNew,
  },
  // {
  //   path: '/desk/desktop/theme1/settings',
  //   menu: 'desk',
  //   title: 'Desk Desktop Theme1 Settings',
  //   component: DesktopTheme1DeskSettings,
  // },

  /** ================================= MARKETING =========================== */
  // {
  //     path: '/event/desktop/theme1/list',
  //     auth: true,
  //     icon: null,
  //     title: 'Marketing Event Desktop Theme1 List',
  //     component: DesktopTheme1EventList
  // },
  // {
  //     path: '/event/desktop/theme1/view',
  //     auth: true,
  //     icon: null,
  //     title: 'Marketing Event Desktop Theme1 View',
  //     component: DesktopTheme1EventView
  // },
  // {
  //     path: '/event/desktop/theme1/view-new',
  //     auth: true,
  //     icon: null,
  //     title: 'Marketing Event Desktop Theme1 View New',
  //     component: DesktopTheme1EventViewNew
  // },
  // {
  //     path: '/event/desktop/theme1/settings',
  //     auth: true,
  //     icon: null,
  //     title: 'Marketing Event Desktop Theme1 Settings',
  //     component: DesktopTheme1EventSettings
  // },
  // {
  //     path: '/referral-reward/desktop/theme1/list',
  //     auth: true,
  //     icon: null,
  //     title: 'Marketing Campaign Referral Reward Desktop Theme1 List',
  //     component: DesktopTheme1CampaignReferralRewardList
  // },
  // {
  //     path: '/referral-reward/desktop/theme1/write',
  //     auth: true,
  //     icon: null,
  //     title: 'Marketing Campaign Referral Reward Desktop Theme1 Write',
  //     component: DesktopTheme1CampaignReferralRewardWrite
  // },

  {
    path: '/lead/desktop/theme1/list',
    menu: 'marketing',
    title: 'Lead Desktop Theme1 List',
    component: DesktopTheme1MarketingLeadList,
  },
  {
    path: '/lead/desktop/theme1/view',
    menu: 'marketing',
    title: 'Lead Desktop Theme1 View',
    component: DesktopTheme1MarketingLeadView,
  },
  {
    path: '/marketing-channels/desktop/theme1/list',
    menu: 'marketing',
    title: 'Marketing Channels Desktop Theme1 List',
    component: DesktopTheme1MarketingChannelsList,
  },
  {
    path: '/templates/desktop/theme1/list',
    menu: 'marketing',
    title: 'Templates Desktop Theme1 List',
    component: DesktopTheme1TemplatesList,
  },
  {
    path: '/templates/desktop/theme1/email-view',
    menu: 'marketing',
    title: 'Templates Email Desktop Theme1 View',
    component: DesktopTheme1TemplateEmailView,
  },
  {
    path: '/templates/desktop/theme1/sms-view',
    menu: 'marketing',
    title: 'Templates SMS Desktop Theme1 View',
    component: DesktopTheme1TemplateSMSView,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/list',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 List',
    component: DesktopTheme1OnlineDigitalContentsList,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/cta-view',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 CTA View',
    component: DesktopTheme1CTAView,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/landing-page-view-unpublished',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Landing Page View : Unpublished',
    component: DesktopTheme1LandingPageView2,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/landing-page-view',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Landing Page View',
    component: DesktopTheme1LandingPageView,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/form-view',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Form View',
    component: DesktopTheme1FormView,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/offer-site-view',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Offer Site View',
    component: DesktopTheme1OfferSiteView,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/referral-site-view-unpublished',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Referral Reward Site View : Unpublished',
    component: DesktopTheme1ReferralSiteView2,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/referral-site-view',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Referral Reward Site View',
    component: DesktopTheme1ReferralSiteView,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/event-site-view-unpublished',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Event Site View : Unpublished',
    component: DesktopTheme1EventSiteView2,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/event-site-view',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Event Site View',
    component: DesktopTheme1EventSiteView,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/offer-file-view',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Offer Download File View',
    component: DesktopTheme1OfferDownloadFileView,
  },
  {
    path: '/online-ditigal-contents/desktop/theme1/image-view',
    menu: 'marketing',
    title: 'Online Digital Contents Desktop Theme1 Image View',
    component: DesktopTheme1ImageView,
  },
  {
    path: '/offline-ditigal-contents/desktop/theme1/list',
    menu: 'marketing',
    title: 'Offline Digital Contents Desktop Theme1 List',
    component: DesktopTheme1OfflineDigitalContentsList,
  },
  {
    path: '/offline-ditigal-contents/desktop/theme1/broadcast-content-view-inactive',
    menu: 'marketing',
    title: 'Offline Digital Contents Desktop Theme1 Broad Content View : Before active',
    component: DesktopTheme1BroadContentView2,
  },
  {
    path: '/offline-ditigal-contents/desktop/theme1/broadcast-content-view',
    menu: 'marketing',
    title: 'Offline Digital Contents Desktop Theme1 Broad Content View',
    component: DesktopTheme1BroadContentView,
  },
  {
    path: '/offline-ditigal-contents/desktop/theme1/print-media-content-view',
    menu: 'marketing',
    title: 'Offline Digital Contents Desktop Theme1 Print Media Content View',
    component: DesktopTheme1PrintMediaContentView,
  },
  {
    path: '/offline-ditigal-contents/desktop/theme1/digital-ooh-view',
    menu: 'marketing',
    title: 'Offline Digital Contents Desktop Theme1 Digital OOH View',
    component: DesktopTheme1DigitalOOHView,
  },
  {
    path: '/offline-ditigal-contents/desktop/theme1/fax-view',
    menu: 'marketing',
    title: 'Offline Digital Contents Desktop Theme1 Fax View',
    component: DesktopTheme1FaxView,
  },
  {
    path: '/marketing-collateral/desktop/theme1/list',
    menu: 'marketing',
    title: 'Marketing Collateral Desktop Theme1 List',
    component: DesktopTheme1MarketingCollateralList,
  },
  {
    path: '/marketing-collateral/desktop/theme1/print-ads-view-inactive',
    menu: 'marketing',
    title: 'Marketing Collateral Desktop Theme1 Print Ads View : Before active',
    component: DesktopTheme1PrintAdsView2,
  },
  {
    path: '/marketing-collateral/desktop/theme1/print-ads-view',
    menu: 'marketing',
    title: 'Marketing Collateral Desktop Theme1 Print Ads View',
    component: DesktopTheme1PrintAdsView,
  },
  {
    path: '/marketing-collateral/desktop/theme1/printed-ooh-view',
    menu: 'marketing',
    title: 'Marketing Collateral Desktop Theme1 Printed OOH View',
    component: DesktopTheme1PrintedOOHView,
  },
  {
    path: '/marketing-collateral/desktop/theme1/promotional-item-view',
    menu: 'marketing',
    title: 'Marketing Collateral Desktop Theme1 Promotional Item View',
    component: DesktopTheme1PromotionalItemView,
  },
  {
    path: '/campaign/desktop/theme1/list',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 List',
    component: DesktopTheme1CampaignList,
  },
  {
    path: '/campaign/desktop/theme1/email-view-unfinished',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Email View : Before finish',
    component: DesktopTheme1CampaignEmailView2,
  },
  {
    path: '/campaign/desktop/theme1/email-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Email View',
    component: DesktopTheme1CampaignEmailView,
  },
  {
    path: '/campaign/desktop/theme1/event-view-unlaunched',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Event View: Before launched',
    component: DesktopTheme1CampaignEventView2,
  },
  {
    path: '/campaign/desktop/theme1/event-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Event View',
    component: DesktopTheme1CampaignEventView,
  },
  {
    path: '/campaign/desktop/theme1/paid-media-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Paid Search/Site View',
    component: DesktopTheme1CampaignPaidMediaView,
  },
  {
    path: '/campaign/desktop/theme1/paid-social-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Paid Social View',
    component: DesktopTheme1CampaignPaidSocialView,
  },
  {
    path: '/campaign/desktop/theme1/social-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Social View',
    component: DesktopTheme1CampaignSocialView,
  },
  {
    path: '/campaign/desktop/theme1/referral-reward-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Referral Reward View',
    component: DesktopTheme1CampaignReferralRewardView,
  },
  {
    path: '/campaign/desktop/theme1/dm-view-unlaunched',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 DM View: Before launched',
    component: DesktopTheme1CampaignDMView2,
  },
  {
    path: '/campaign/desktop/theme1/dm-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 DM View',
    component: DesktopTheme1CampaignDMView,
  },
  {
    path: '/campaign/desktop/theme1/print-ads-view-unlaunched',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Print Ads Distribute View: Before launched',
    component: DesktopTheme1CampaignPADView,
  },
  {
    path: '/campaign/desktop/theme1/print-ads-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Print Ads Distribute View',
    component: DesktopTheme1CampaignPADView2,
  },
  {
    path: '/campaign/desktop/theme1/organic-link-view',
    menu: 'marketing',
    title: 'Campaign Desktop Theme1 Organic Link View',
    component: DesktopTheme1CampaignOrganicLinkView,
  },
  {
    path: '/loyalty/desktop/theme1/list',
    menu: 'marketing',
    title: 'Loyalty Desktop Theme1 List',
    component: DesktopTheme1LoyaltyList,
  },
  {
    path: '/loyalty/desktop/theme1/view',
    menu: 'marketing',
    title: 'Loyalty Desktop Theme1 New View',
    component: DesktopTheme1LoyaltyView,
  },
  {
    path: '/abm/desktop/theme1/list',
    menu: 'marketing',
    title: 'Account Based Marketing Desktop Theme1 List',
    component: DesktopTheme1ABMList,
  },
  {
    path: '/abm/desktop/theme1/view',
    menu: 'marketing',
    title: 'Account Based Marketing Desktop Theme1 View',
    component: DesktopTheme1ABMView,
  },
  {
    path: '/abm/desktop/theme1/view-after-open',
    menu: 'marketing',
    title: 'Account Based Marketing Desktop Theme1 View - After Open',
    component: DesktopTheme1ABMViewAfterOpen,
  },
  {
    path: '/abm/desktop/theme1/view-result',
    menu: 'marketing',
    title: 'Account Based Marketing Desktop Theme1 View - Result',
    component: DesktopTheme1ABMViewResult,
  },
  {
    path: '/target-account/desktop/theme1/list',
    menu: 'marketing',
    title: 'Target Account Desktop Theme1 List',
    component: DesktopTheme1TargetAccountList,
  },

  /** ================================= SALES =========================== */
  {
    path: '/quote/desktop/theme1/view-new',
    menu: 'sales',
    title: 'Quote Desktop Theme1 View New',
    component: DesktopTheme1QuoteViewNew,
  },
  {
    path: '/opportunity/desktop/theme1/list',
    menu: 'sales',
    title: 'Opportunity Desktop Theme1 List',
    component: DesktopTheme1OpportunityList,
  },
  {
    path: '/opportunity/desktop/theme1/view',
    menu: 'sales',
    title: 'Opportunity Desktop Theme1 View',
    component: DesktopTheme1OpportunityView,
  },

  /** ================================= SETTINGS =========================== */
  {
    path: '/settings/desktop/theme1/general',
    menu: 'settings',
    title: 'General Desktop Theme1 Settings',
    component: DesktopTheme1GeneralSettings,
  },
  // {
  //   path: '/settings/desktop/theme1/price-discount',
  //   menu: 'settings',
  //   title: 'Price and Discount Settings',
  //   component: DesktopTheme1PriceDiscountSettings,
  // },
  // {
  //   path: '/settings/desktop/theme1/diagram',
  //   menu: 'settings',
  //   title: 'Diagram Settings',
  //   component: DesktopTheme1DiagramSettings,
  // },
  {
    path: '/settings/desktop/theme1/assignment-rule',
    menu: 'settings',
    title: 'Assignment Rule Desktop Theme1 Settings',
    component: DesktopTheme1AssignmentRuleSettings,
  },
  {
    path: '/settings/desktop/theme1/sales-commission',
    menu: 'settings',
    title: 'Sales Commission Desktop Theme1 Settings',
    component: DesktopTheme1SalesCommissionSettings,
  },
  // {
  //   path: '/settings/desktop/theme1/meeting',
  //   menu: 'settings',
  //   title: 'Meeting Desktop Theme1 Settings',
  //   component: DesktopTheme1MeetingSettings,
  // },
  // {
  //   path: '/settings/desktop/theme1/inventory',
  //   menu: 'settings',
  //   title: 'Inventory Desktop Theme1 Settings',
  //   component: DesktopTheme1InventorySettings,
  // },
  // {
  //   path: '/settings/desktop/theme1/invoice',
  //   menu: 'settings',
  //   title: 'Invoice Desktop Theme1 Settings',
  //   component: DesktopTheme1InvoiceSettings,
  // },
  // {
  //   path: '/settings/desktop/theme1/order',
  //   auth: true,
  //   icon: null,
  //   title: 'Order Desktop Theme1 Settings',
  //   component: DesktopTheme1SalesOrderSettings,
  //   menu: 'settings',
  // },
  {
    path: '/settings/desktop/theme1/billing-licenses',
    menu: 'settings',
    title: 'Billing License Desktop Theme1 Settings',
    component: DesktopTheme1BillingLicenseSettings,
  },
  {
    path: '/settings/desktop/theme1/rating-rule',
    menu: 'settings',
    title: 'Rating Rule Desktop Theme1 Settings',
    component: DesktopTheme1RatingRuleSettings,
  },
  {
    path: '/settings/desktop/theme1/profile',
    menu: 'settings',
    title: 'Profile Desktop Theme1 Settings',
    component: DesktopTheme1ProfileSettings,
  },
  // {
  //   path: '/settings/desktop/theme1/format',
  //   menu: 'settings',
  //   title: 'Format Desktop Theme1 Settings',
  //   component: DesktopTheme1FormatSettings,
  // },
  {
    path: '/settings/desktop/theme1/template',
    menu: 'settings',
    title: 'Template Desktop Theme1 Settings',
    component: DesktopTheme1TemplateSettings,
  },
  {
    path: '/settings/desktop/theme1/process',
    menu: 'settings',
    title: 'Process Desktop Theme1 Settings',
    component: DesktopTheme1ProcessSettings,
  },
  {
    path: '/settings/desktop/theme1/process-view',
    menu: 'settings',
    // title: 'Process View',
    component: DesktopTheme1DiagramNewSettings,
  },
  {
    path: '/settings/desktop/theme1/lead-scoring-rule',
    menu: 'settings',
    title: 'Lead Scoring Rule Desktop Theme1 Settings',
    component: DesktopTheme1LeadScoringRuleSettings,
  },
  {
    path: '/settings/desktop/theme1/preferences',
    menu: 'settings',
    title: 'Preferences Desktop Theme1 Settings',
    component: DesktopTheme1PreferencesSettings,
  },
  {
    path: '/settings/desktop/theme1/goal-metric',
    menu: 'settings',
    title: 'Goal Metric Desktop Theme1 Settings',
    component: DesktopTheme1GoalMetricSettings,
  },
  {
    path: '/settings/desktop/theme1/kpi',
    menu: 'settings',
    title: 'Key Performance Indicator Desktop Theme1 Settings',
    component: DesktopTheme1KPISettings,
  },
  {
    path: '/settings/desktop/theme1/sales-team',
    menu: 'settings',
    title: 'Sales Team Desktop Theme1 Settings',
    component: DesktopTheme1SalesTeamSettings,
  },
  {
    path: '/settings/desktop/theme1/membership',
    menu: 'settings',
    title: 'Membership Sign up Desktop Theme1 Settings',
    component: DesktopTheme1MembershipSettings,
  },
  {
    path: '/settings/desktop/theme1/journey-process',
    menu: 'settings',
    title: 'Journey Process Desktop Theme1 Settings',
    component: DesktopTheme1JourneyProcessSettings,
  },
  {
    path: '/settings/desktop/theme1/journey-process-view',
    menu: 'settings',
    // title: 'Journey Process View Desktop Theme1 Settings',
    component: DesktopTheme1JourneyProcessViewSettings,
  },

  /** ================================= Site =========================== */
  {
    path: '/site/vendor',
    menu: 'site',
    title: 'Vendor Site',
    component: DesktopTheme1VendorSite,
  },
  {
    path: '/site/quote',
    menu: 'site',
    title: 'Quote Site',
    component: DesktopTheme1QuoteSite,
  },
  {
    path: '/site/desk',
    menu: 'site',
    title: 'Desk Site',
    component: DesktopTheme1DeskSite,
  },
  {
    path: '/report/desktop/theme1/list',
    menu: 'report',
    title: 'Report List',
    component: DesktopTheme1ReportList,
  },
  {
    path: '/report/desktop/theme1/view',
    menu: 'report',
    title: 'Report View',
    component: DesktopTheme1ReportView,
  },

  /** ================================= WEB FORM BUILDER =========================== */
  {
    path: '/base/desktop/theme1/site-builder',
    menu: 'base',
    title: 'Web Site Builder',
    component: DesktopTheme1MarketingFormBuilder,
  },

  {
    path: '/base/desktop/theme1/dom-editor',
    menu: 'base',
    title: 'Dom Editor',
    component: DomEditor,
  },
  {
    path: '/base/desktop/theme1/upload-bs',
    menu: 'base',
    title: 'Upload Block Storage',
    component: UploadBlockStorage,
  },
  {
    path: '/base/desktop/theme1/tui-editor',
    menu: 'base',
    title: 'Tui-Editor',
    component: UploadBlockStorage,
  },

  /** ================================= NOT DONE YET =========================== */
  // {
  //   path: '/site/quote',
  //   auth: true,
  //   icon: null,
  //   title: 'Quote Site',
  //   component: QuoteSite,
  // },
  // {
  //   path: '/site/vendor',
  //   auth: true,
  //   icon: null,
  //   title: 'Vendor Site',
  //   component: VendorSite,
  // },
  // {
  //   path: '/settings/desktop/theme1/kpi',
  //   auth: true,
  //   icon: null,
  //   title: 'KPI',
  //   component: KPI,
  // },
  // {
  //   path: '/thuy/thuys-page-1',
  //   title: 'Page demo 1----',
  //   component: ThuysPage,
  // },
  // {
  //   path: '/demo',
  //   title: 'Demo component',
  //   component: Demo,
  // },
];
