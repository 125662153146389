/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Settings,
  Menu,
  RotateCcw,
  X,
} from 'react-feather';

// import SellingListDefault from './list/list-default';
// import SellingGridDefault from './list/grid-default';

import Pagination from '../../../component/desktop/pagination-new';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "OrderCss/selling.scss"

const SelList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="dropdown mg-l-auto">
          <button
            type="button"
            className="btn btn-white btn-icon"
            aria-label="select a list type"
            data-toggle="dropdown"
          >
            {listType.list ? <List /> : <Grid />}
            <ChevronDown />
            <span className="sr-only">목록 타입 변경</span>
          </button>
          <div className="dropdown-menu">
            <a
              role="button"
              className={classnames('dropdown-item', { active: listType.list })}
              onClick={() => setListType({ list: true })}
            >
              <List className="mg-r-5" />
              List
            </a>
            <a
              role="button"
              className={classnames('dropdown-item', { active: listType.grid })}
              onClick={() => setListType({ grid: true })}
            >
              <Grid className="mg-r-5" />
              Grid
            </a>
          </div>
        </div>
        <button type="button" className="btn btn-white btn-icon mg-l-5" aria-label="reload">
          <RefreshCw />
          <span className="sr-only">새로고침</span>
        </button>
        <button type="button" className="btn btn-white btn-icon mg-l-5" aria-label="split view">
          <Columns />
          <span className="sr-only">분할 보기</span>
        </button>
      </div>

      <div className="list-filter-bar pd-x-0-f">
        <div className="list-filter-items">
          <div className="dropdown list-filter-item">
            <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
              All PoS
              <ChevronDown />
            </button>
            <div className="dropdown-menu"></div>
          </div>

          <div className="dropdown list-filter-item">
            <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
              Created This Month
              <ChevronDown />
            </button>
            <div className="dropdown-menu wd-300"></div>
          </div>

          <div className="dropdown list-filter-item">
            <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
              Filters
              <ChevronDown />
            </button>
            <div className="dropdown-menu wd-300"></div>
          </div>

          {/* for grid */}
          {listType.grid && (
            <div className="dropdown with-sort list-filter-item">
              <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                {/* Add 'current-sorting' class when sorting is applied */}
                {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
              </button>
              <button
                type="button"
                className="btn btn-filter pd-l-0"
                data-toggle="dropdown"
                aria-label="정렬 옵션 열기"
              >
                Sorty by: PoS ID
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a role="button" className="dropdown-item active">
                  Order Name
                </a>
              </div>
            </div>
          )}
        </div>

        {/* search */}
        <div className="input-group input-group-flush list-search">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <Search />
            </span>
          </div>
          <div className="d-flex form-control pd-0">
            <input type="search" className="wd-100p bd-0" />
            <button
              type="button"
              className="btn btn-link link-03 btn-icon han-tooltip--left"
              data-han-tooltip="Advanced Search"
              onClick={() => setShowAdvSearch(!showAdvSearch)}
            >
              {showAdvSearch ? <X /> : <ChevronDown />}
            </button>
          </div>
          {showAdvSearch && (
            <div className="dropdown-menu show wd-100p pd-0">
              <div className="pd-10 bg-light bd-b">Advanced Search</div>
              <div className="pd-10"></div>
              <div className="pd-10  bd-t text-center">
                <button type="button" className="btn btn-secondary mg-r-10">
                  <RotateCcw className="mg-r-5" />
                  Reset
                </button>
                <button type="button" className="btn btn-primary">
                  <Search className="mg-r-5" />
                  Search
                </button>
              </div>
            </div>
          )}
        </div>

        {listType.list && (
          <div className="dropup mg-l-5">
            <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
              {Icon('column_settings')}
              <span className="sr-only">항목 설정 열기</span>
            </button>
            <div className="dropdown-menu column-settings">
              <div className="column-settings-title">Column Settings</div>
              <ul className="list-group">
                <li className="list-group-item">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="columnCheck1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="columnCheck1">
                      Name
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon handler"
                    aria-label="change the order"
                  >
                    <Menu />
                    <span className="드래그하여 순서 변경"></span>
                  </button>
                </li>
              </ul>
              <div className="column-settings-actions">
                Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                <button type="button" className="btn btn-light mg-l-auto">
                  Close
                </button>
                <button type="button" className="btn btn-primary mg-l-5">
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {listType.list && (
        <div className="table-responsive">
          <table className="table dataTable">
            <thead>
              <tr>
                <th scope="col" className="tb-sel-cell">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="sellingCheckAll" />
                    <label className="custom-control-label" htmlFor="sellingCheckAll"></label>
                  </div>
                </th>
                <th scope="col" className="sorting_asc">
                  PoS ID
                </th>
                <th scope="col" className="sorting">
                  Customer
                </th>
                <th scope="col" className="sorting">
                  Point
                </th>
                <th scope="col" className="sorting">
                  Item
                </th>
                <th scope="col" className="sorting">
                  Amount
                </th>
                <th scope="col" className="sorting">
                  Payment
                </th>
                <th scope="col" className="sorting">
                  Sold on
                </th>
                <th scope="col" className="sorting">
                  Cashier
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="sellingCheck3" />
                    <label className="custom-control-label" htmlFor="sellingCheck3"></label>
                  </div>
                </td>
                <td>
                  <a href="/crm/demo-page/ui/selling/desktop/theme1/view" className="link-02">
                    POS-00003
                  </a>
                </td>
                <td>
                  <div className="media align-items-center">
                    <div className="avatar avatar-xs">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                        className="rounded"
                        alt="Adventure Works"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      Adventure Works<span className="badge badge-secondary mg-l-5">+ 1</span>
                    </div>
                  </div>
                </td>
                <td>A Point</td>
                <td>
                  Item A-1<span className="badge badge-secondary mg-l-5">+ 1</span>
                </td>
                <td>$3,000</td>
                <td>Partial Paid</td>
                <td>2021.5.30 11:20</td>
                <td>MSR</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {listType.grid && (
        <div className="row row-xs">
          <div className="col-lg-6 d-flex">
            <div className="grid-list-item without-pic">
              <div className="grid-list-item-sel">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="sellingCheck3" />
                  <label className="custom-control-label" htmlFor="sellingCheck3"></label>
                </div>
              </div>
              <div className="grid-list-item-detail">
                <div className="grid-list-item-detail-header">
                  <a
                    href="/crm/demo-page/ui/selling/desktop/theme1/view"
                    className="grid-list-item-title link-02"
                  >
                    POS-00003
                  </a>
                </div>
                <div className="grid-list-item-detail-body">
                  <div className="grid-list-item-col">
                    <span className="grid-list-item-col-title">Customer:</span>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                          className="rounded"
                          alt="Adventure Works"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        Adventure Works<span className="badge badge-secondary mg-l-5">+ 1</span>
                      </div>
                    </div>
                  </div>
                  <div className="grid-list-item-col">
                    <span className="grid-list-item-col-title">Point:</span>
                    <span className="grid-list-item-col-content">A Point</span>
                  </div>
                  <div className="grid-list-item-col">
                    <span className="grid-list-item-col-title">Item:</span>
                    <span className="grid-list-item-col-content">
                      Item A-1<span className="badge badge-secondary mg-l-5">+ 1</span>
                    </span>
                  </div>
                  <div className="grid-list-item-col">
                    <span className="grid-list-item-col-title">Amount:</span>
                    <span className="grid-list-item-col-content">$3,000</span>
                  </div>
                  <div className="grid-list-item-col">
                    <span className="grid-list-item-col-title">Payment:</span>
                    <span className="grid-list-item-col-content">Partial Paid</span>
                  </div>
                  <div className="grid-list-item-col">
                    <span className="grid-list-item-col-title">Sold on:</span>
                    <span className="grid-list-item-col-content">2021.5.30 11:20</span>
                  </div>
                  <div className="grid-list-item-col">
                    <span className="grid-list-item-col-title">Cashier:</span>
                    <div className="grid-list-item-col-content">MSR</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* pagination */}
      <Pagination />
    </>
  );
};

export default SelList;
