import { Button, Input } from '@base/components/form';
import { Email, Phone } from '@customer/customer/components';
import { useState } from 'react';

const NewContact = () => {
  const [showAddContact, setShowAddContact] = useState(false);
  return (
    <>
      <div
        id="newContactModal"
        className="modal fade right"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title">Add New Contact</h1>
              <Button close data-dismiss="modal" tooltip="닫기" />
            </div>
            <div className="modal-body overflow-auto">
              <div className="form-group">
                <label className="form-item-title">Name</label>
                <Input />
              </div>
              <div className="form-group">
                <label className="form-item-title">Email</label>
                <Email />
              </div>
              <div className="form-group">
                <label className="form-item-title">Telephone</label>
                <Phone />
              </div>
              {showAddContact && (
                <>
                  <hr className="hr-dotted" />
                  <div className="form-group">
                    <label className="form-item-title">Name</label>
                    <Input />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Email</label>
                    <Email />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Telephone</label>
                    <Phone />
                  </div>
                  <Button
                    color="link"
                    size="sm"
                    icon="Minus"
                    name="Delete"
                    className="mg-r-10 pd-x-0 tx-danger"
                    onClick={() => setShowAddContact(false)}
                  />
                </>
              )}
              <Button
                color="link"
                size="sm"
                icon="Plus"
                name="Add"
                className="pd-x-0"
                onClick={() => setShowAddContact(true)}
              />
            </div>
            <div className="modal-footer">
              <Button color="light" size="lg" name="Close" data-dismiss="modal" />
              <Button color="success" size="lg" name="Add" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewContact;
