import { X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const NewClosedStatus = () => {
  return (
    <div
      id="newClosedStatus"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Closed Status</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="d-block form-item-title">Status Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Description</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Property</label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Button Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Button View</label>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="buttonViewRadio1"
                  name="buttonViewRadio"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="buttonViewRadio1">
                  Single
                </label>
              </div>
              <div className="custom-control custom-radio d-inline-block">
                <input
                  type="radio"
                  id="buttonViewRadio2"
                  name="buttonViewRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="buttonViewRadio2">
                  More box
                </label>
              </div>
              <div className="custom-control custom-checkbox mg-t-10">
                <input type="checkbox" id="buttonViewcheckbox" className="custom-control-input" />
                <label className="custom-control-label" htmlFor="buttonViewcheckbox">
                  Possible to jump from any Step/Status.
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewClosedStatus;
