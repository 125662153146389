import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  CheckCircle,
  Circle,
  ArrowLeft,
  Printer,
  Plus,
  Repeat,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import noImg from '@base/assets/img/notfound.png';

import '../style.scss';

import { COLORS } from '@base/config/constant';
import { Select as SelectHook } from '@base/components/form';

import Attachments from '../../../../component/desktop/attachments';
import Notelist from '../../../../component/desktop/comment';
import AssignedRep from '../../../../component/desktop/assigned-rep';
import Userlist from '../../../../component/desktop/userlist';

import Timeline from '../timeline';
import SwitchItem from './modal/switchItem';

const TABS = [
  {
    value: 1,
    label: 'Items to Receipt',
  },
  {
    value: 2,
    label: 'Receive',
  },
  {
    value: 3,
    label: 'Allocate',
  },
  {
    value: 4,
    label: 'Switch',
  },
  {
    value: 5,
    label: 'Timeline',
  },
  {
    value: 6,
    label: 'Note',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLORS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/receipt/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Receipt
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/warehouse/desktop/theme1/list">Warehouse</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/inventory-items/desktop/theme1/list">Inventory Items</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/adjustment/desktop/theme1/list">Adjustment</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/transfer-order/desktop/theme1/list">Stock Transfer</a>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/receipt/desktop/theme1/list">Receipt</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/shipment/desktop/theme1/list">Shipment</a>
              </div>
            </div>
          </div>
          <h1 className="view-title">REC-00001</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">{Icon('export_pdf')}</span>Export to PDF
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Printer />
                    </span>
                    Print
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Stage 1</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>New</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 2</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 3</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 4</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 5</div>
            </li>
          </ol>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Vendor</label>
                    <div>Vendor 1</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Reference</label>
                    <a href="" target="_blank" title="새창으로 열림">
                      Item 1 Po
                    </a>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Reference ID</label>
                    <a href="" target="_blank" title="새창으로 열림">
                      PO-000001
                    </a>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Destination Warehouse</label>
                    <div>Seoul 1</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Courier</label>
                    <div>Postal Service</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Tracking No</label>
                    <div>30012233450</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Scheduled On</label>
                    <div>2021/06/04</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Received on</label>
                    <div>2021/06/04</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Received by</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <span className="user-name">MSR</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Allocated on</label>
                    <div>2021/06/04</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Allocated by</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/10/1"
                          className="rounded-circle"
                          alt="KDH"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <span className="user-name">KDH</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="table-responsive mg-b-10 bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Image
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Item
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Unit Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Unit Qty
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Ordered
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Received
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Rejected
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Allocated
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Switchted
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="avatar avatar-sm bg-gray-200 rounded">
                              <img src={noImg} alt="" />
                            </div>
                          </td>
                          <td>
                            <a href="" target="_blank" title="새창으로 열림">
                              Item 1
                            </a>
                          </td>
                          <td>Bottle</td>
                          <td>12</td>
                          <td>100</td>
                          <td>
                            2 Box
                            <br />
                            (100 Bottles)
                          </td>
                          <td>95</td>
                          <td>5</td>
                          <td>95</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* Receive */}
              {tab.value == 2 && (
                <div className="pd-20">
                  <div className="d-flex justify-content-end mg-b-20">
                    <SelectHook
                      className="wd-300-f"
                      options={[{ value: 1, label: 'Wait to Receive' }]}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Received by</label>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">
                          <span className="user-name">MSR</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Received on</label>
                      <div>2021/06/04</div>
                    </div>
                  </div>
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Items to Receive</span>
                      <button type="button" className="btn btn-info mg-l-auto">
                        <span className="mg-r-5">{Icon('scan_barcode')}</span>Scan Barcode
                      </button>
                    </div>
                    <div className="table-responsive bg-white bd rounded mg-t-10 mg-b-20">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Image
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Items
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Qty
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty to Receive
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty to Received
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty to Passed
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty to Failed
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Reason to Fail
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="avatar avatar-sm bg-gray-200 rounded">
                                <img src={noImg} alt="" />
                              </div>
                            </td>
                            <td>
                              <a href="" target="_blank" title="새창으로 열림">
                                Item 1
                              </a>
                            </td>
                            <td>Bottle</td>
                            <td>1</td>
                            <td>
                              2 Box
                              <br />
                              (100 Bottles)
                            </td>
                            <td>
                              <input type="number" className="form-control wd-80" />
                            </td>
                            <td>
                              <input type="number" className="form-control wd-80" />
                            </td>
                            <td>
                              <input type="number" className="form-control wd-80" />
                            </td>
                            <td>
                              <SelectHook className="wd-200-f" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Check List</label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="receivingItemCheck1"
                        />
                        <label className="custom-control-label" htmlFor="receivingItemCheck1">
                          Check the received item quantity
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="receivingItemCheck2"
                        />
                        <label className="custom-control-label" htmlFor="receivingItemCheck2">
                          Locate received items to Inspect Zone
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="receivingItemCheck3"
                        />
                        <label className="custom-control-label" htmlFor="receivingItemCheck3">
                          Take and uploade photos of the Failed items
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="receivingItemCheck4"
                        />
                        <label className="custom-control-label" htmlFor="receivingItemCheck4">
                          Locate received items to Put Away Zone
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Allocate */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="d-flex justify-content-end mg-b-20">
                    <div className="btn-group">
                      <button type="button" className="btn btn-primary">
                        {Icon('gen_barcode')}
                        <span className="mg-x-10">Generate Barcode</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle-split"
                        data-toggle="dropdown"
                      >
                        <ChevronDown />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <button type="button" className="dropdown-item">
                          <span className="dropdown-item-icon">
                            <Printer />
                          </span>
                          Print Barcode Label
                        </button>
                      </div>
                    </div>
                    <SelectHook
                      className="wd-300-f mg-l-5"
                      options={[{ value: 1, label: 'Wait to Accept' }]}
                    />
                  </div>

                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Allocated by</label>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">
                          <span className="user-name">MSR</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Allocated on</label>
                      <div>2021/06/04</div>
                    </div>
                  </div>
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Items to Allocate</span>
                      <button type="button" className="btn btn-info mg-l-auto">
                        <span className="mg-r-5">{Icon('scan_barcode')}</span>Scan Barcode
                      </button>
                    </div>
                    <div className="table-responsive bg-white bd rounded mg-t-10 mg-b-20">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Image
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Items
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Qty
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty to Allocate
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              SKU
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Traceability No
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Location
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              QTY Allocated
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="avatar avatar-sm bg-gray-200 rounded">
                                <img src={noImg} alt="" />
                              </div>
                            </td>
                            <td>
                              <a
                                href=""
                                target="_blank"
                                title="새창으로 열림"
                                className="align-middle"
                              >
                                Item 1
                              </a>
                              <button
                                type="button"
                                className="btn btn-icon pd-y-0 han-tooltip--right"
                                data-han-tooltip="Switch item"
                                data-toggle="modal"
                                data-target="#switchItem"
                              >
                                <Repeat className="tx-success" />
                              </button>
                            </td>
                            <td>Box</td>
                            <td>12</td>
                            <td>100</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <SelectHook
                                  className="wd-200-f"
                                  options={[
                                    { value: 1, label: 'Ite-red-sma' },
                                    { value: 2, label: 'Ite-red-smb' },
                                  ]}
                                />
                                <button type="button" className="btn btn-link btn-icon">
                                  <Plus />
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <SelectHook
                                  className="wd-200-f"
                                  options={[
                                    { value: 1, label: '5-5-5-5' },
                                    { value: 2, label: '5-5-5-4' },
                                    { value: 2, label: '5-5-5-3' },
                                  ]}
                                />
                                <button type="button" className="btn btn-link btn-icon">
                                  <Plus />
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <SelectHook
                                  className="wd-200-f"
                                  options={[{ value: 1, label: 'B1-F2-SA-01-A-03' }]}
                                />
                                <button type="button" className="btn btn-link btn-icon">
                                  <Plus />
                                </button>
                              </div>
                            </td>
                            <td>95</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="form-group">
                      <label className="form-item-title">Check List</label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="allocateItemCheck1"
                        />
                        <label className="custom-control-label" htmlFor="allocateItemCheck1">
                          Update SKU for the allocated Items
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="allocateItemCheck2"
                        />
                        <label className="custom-control-label" htmlFor="allocateItemCheck2">
                          Generate Traceability No for the allocated Items
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="allocateItemCheck3"
                        />
                        <label className="custom-control-label" htmlFor="allocateItemCheck3">
                          Re-generate Barcode allocated Items
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Switch */}
              {tab.value == 4 && (
                <div className="pd-20">
                  <div className="card card-body mg-b-20">
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">Switched by</label>
                        <div className="media align-items-center">
                          <span className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                              className="rounded-circle"
                            />
                          </span>
                          <div className="mg-l-10">MSR</div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-item-title mg-r-10">Switched on </label>
                        <div>2021/06/30</div>
                      </div>
                      <div className="form-group col-12">
                        <label className="form-item-title">Switch from</label>
                        <div className="table-responsive bg-white bd rounded">
                          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                            <thead>
                              <tr>
                                <th scope="col" className="bd-t-0-f wd-60">
                                  Image
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Items
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Unit Name
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Unit Qty
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Switchable Qty
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Qty to Swich
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="avatar avatar-sm bg-gray-200 rounded">
                                    <img src={noImg} alt="" />
                                  </div>
                                </td>
                                <td>Item 1</td>
                                <td>Bottle</td>
                                <td>1</td>
                                <td>
                                  2 Box
                                  <br />
                                  (200 Bottles)
                                </td>
                                <td>
                                  1 Box
                                  <br />
                                  (100 Bottles)
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="form-group col-12">
                        <label className="form-item-title">Switch to</label>
                        <div className="table-responsive bg-white bd rounded">
                          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                            <thead>
                              <tr>
                                <th scope="col" className="bd-t-0-f wd-60">
                                  Image
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Items
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Unit Name
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Unit Qty
                                </th>
                                <th scope="col" className="bd-t-0-f">
                                  Qty Swiched
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="avatar avatar-sm bg-gray-200 rounded">
                                    <img src={noImg} alt="" />
                                  </div>
                                </td>
                                <td>Item 1</td>
                                <td>Bottle</td>
                                <td>1</td>
                                <td>60 Bottle</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="avatar avatar-sm bg-gray-200 rounded">
                                    <img src={noImg} alt="" />
                                  </div>
                                </td>
                                <td>Item 2</td>
                                <td>Pack</td>
                                <td>8</td>
                                <td>5 Pack</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Timeline */}
              {tab.value == 5 && <Timeline />}

              {/* Note */}
              {tab.value == 6 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Contact */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Contact</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#activityCustomer"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="activityCustomer" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <SwitchItem />
    </>
  );
};

export default ViewNew;
