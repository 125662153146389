/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Upload,
  Download,
  MoreHorizontal,
  X,
  RotateCcw,
  Settings,
  Menu,
  Calendar,
} from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

//import 'CrmCss/desktop/theme1/theme1.scss';

import Pagination from '../../../component/desktop/pagination-new';
import NewAccount from './modal/newAccount';
import NewContact from './modal/newContact';
import NewSegment from './segment/write';
import NewMarketingList from './marketing-list/write';
import NewPersona from './buyer-persona/write';
import NewICP from './icp/write';
import MergeAccount from './modal/mergeAccount';

const AccountList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <div>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            data-han-tooltip="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Account</h1>
          <div className="dropdown mg-l-5">
            <button type="button" className="btn btn-xs btn-white btn-icon" data-toggle="dropdown">
              <ChevronDown />
              <span className="sr-only">변경할 메뉴 열기</span>
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item active">
                <a href="/customer/desktop/theme1/account-list">Account</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  data-han-tooltip="Set as default"
                >
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/customer/desktop/theme1/contact-list">Contact</a>
              </div>
              <div className="dropdown-item">
                <a href="/segment/desktop/theme1/list">Segment</a>
              </div>
              <div className="dropdown-item">
                <a href="/marketing-list/desktop/theme1/list">Marketing List</a>
              </div>
              <div className="dropdown-item">
                <a href="/buyer-persona/desktop/theme1/list">Buyer Persona</a>
              </div>
              <div className="dropdown-item">
                <a href="/icp/desktop/theme1/list">Ideal Customer Profile</a>
              </div>
              <div className="dropdown-item">
                <a href="/blocklist/desktop/theme1/list">Blocklist</a>
              </div>
            </div>
          </div>
          <div className="btn-group dropdown mg-l-auto">
            <button
              type="button"
              className="btn btn-primary pd-x-10"
              data-toggle="modal"
              data-target="#newAccount"
            >
              <Plus />
              <span className="sr-only">추가</span>
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle-split pd-x-5"
              data-toggle="dropdown"
            >
              <ChevronDown />
              <span className="sr-only">추가 옵션 열기</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newAccount"
              >
                Account
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newContact"
              >
                Contact
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newSegment"
              >
                Segment
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newMarketingList"
              >
                Marketing List
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newBuyerPersonaModal"
              >
                Buyer Persona
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newICPModal"
              >
                Ideal Customer Profile
              </button>
            </div>
          </div>
          <div className="button-group">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="More"
                data-toggle="dropdown"
              >
                <MoreHorizontal />
                <ChevronDown />
                <span className="sr-only">선택 옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item" data-toggle="modal" data-target="#mergeAccount">
                  <span className="dropdown-item-icon">{Icon('merge')}</span>Merge
                </button>
                <button className="dropdown-item">
                  <Upload className="mg-r-5" />
                  Import
                </button>
                <button className="dropdown-item">
                  <Download className="mg-r-5" />
                  Export
                </button>
              </div>
            </div>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="List type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" data-han-tooltip="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" data-han-tooltip="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                All Accounts
                <ChevronDown />
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item active">All Accounts</button>
                <button className="dropdown-item">My Accounts</button>
                <button className="dropdown-item">My Group Accounts</button>
                <button className="dropdown-item">Unassigned Accounts</button>
                <button className="dropdown-item">Deleted Accounts</button>
                <button className="dropdown-item">All Customers</button>
                <button className="dropdown-item">Overdue Customers</button>
                <button className="dropdown-item">Closed Lost Customers</button>
                <button className="dropdown-item">Expired Customers</button>
                <button className="dropdown-item">All Potentials</button>
                <button className="dropdown-item">Deferred Potentials</button>
                <button className="dropdown-item">Lost Potentials</button>
                <button className="dropdown-item">All Vendors</button>
                <button className="dropdown-item">All Sales Agents</button>
                <button className="dropdown-item">All Partners</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Created This Month
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300">
                <button className="dropdown-item">All</button>
                <div className="dropdown-divider"></div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold"
                  data-toggle="collapse"
                  data-target="#dateBy1"
                >
                  <span className="mg-r-5">Created This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <div id="dateBy1" className="collapse show">
                  <button type="button" className="dropdown-item">
                    This Year
                  </button>
                  <button type="button" className="dropdown-item">
                    This Quarter
                  </button>
                  <button type="button" className="dropdown-item active">
                    This Month
                  </button>
                  <button type="button" className="dropdown-item">
                    This Week
                  </button>
                  <button type="button" className="dropdown-item">
                    Today
                  </button>
                  <button
                    type="button"
                    className="dropdown-item btn d-flex align-items-center"
                    data-toggle="collapse"
                    data-target="#dateBy1-custom"
                  >
                    <span className="mg-r-5">Custom</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                    <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy2"
                >
                  <span className="mg-r-5">Updated This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <div id="dateBy2" className="collapse">
                  <button type="button" className="dropdown-item">
                    This Year
                  </button>
                  <button type="button" className="dropdown-item">
                    This Quarter
                  </button>
                  <button type="button" className="dropdown-item">
                    This Month
                  </button>
                  <button type="button" className="dropdown-item">
                    This Week
                  </button>
                  <button type="button" className="dropdown-item">
                    Today
                  </button>
                  <button
                    type="button"
                    className="dropdown-item btn d-flex align-items-center"
                    data-toggle="collapse"
                    data-target="#dateBy1-custom"
                  >
                    <span className="mg-r-5">Custom</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                    <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy3"
                >
                  <span className="mg-r-5">Converted This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <div id="dateBy3" className="collapse">
                  <button type="button" className="dropdown-item">
                    This Year
                  </button>
                  <button type="button" className="dropdown-item">
                    This Quarter
                  </button>
                  <button type="button" className="dropdown-item">
                    This Month
                  </button>
                  <button type="button" className="dropdown-item">
                    This Week
                  </button>
                  <button type="button" className="dropdown-item">
                    Today
                  </button>
                  <button
                    type="button"
                    className="dropdown-item btn d-flex align-items-center"
                    data-toggle="collapse"
                    data-target="#dateBy1-custom"
                  >
                    <span className="mg-r-5">Custom</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                    <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filters
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300">
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Industry</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter2"
                >
                  <span className="mg-r-5">Lead Source</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter3"
                >
                  <span className="mg-r-5">Customer Group</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter4"
                >
                  <span className="mg-r-5">Assigned Rep</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter5"
                >
                  <span className="mg-r-5">Product</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter6"
                >
                  <span className="mg-r-5">Ranking</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter7"
                >
                  <span className="mg-r-5">Owner</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter8"
                >
                  <span className="mg-r-5">Receivables</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold"
                  data-toggle="collapse"
                  data-target="#filter9"
                >
                  <span className="mg-r-5">Remaining Credit</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <div id="filter9" className="collapse show input-group pd-x-15 pd-y-5">
                  <div className="input-group-prepend">
                    <span className="input-group-text lh-1">＄</span>
                  </div>
                  <div className="form-control d-flex align-items-center">
                    <input type="number" className="wd-100p bd-0" /> ~{' '}
                    <input type="number" className="wd-100p bd-0" />
                  </div>
                </div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter10"
                >
                  <span className="mg-r-5">Trade Agreement</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter11"
                >
                  <span className="mg-r-5">Purchase Agreement</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
              </div>
            </div>

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_asc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
                </button>
                <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                  Sorty by: Name
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item">Customer Type</button>
                </div>
              </div>
            )}
          </div>

          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name, Email, Telephone" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10">
                  <div className="form-group">
                    <label className="form-item-title">Industry</label>
                    <SelectHook />
                  </div>
                </div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>
          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {listType.list && (
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered dataTable mg-b-0 bd-0">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="accountCheckAll"
                        />
                        <label className="custom-control-label" htmlFor="accountCheckAll"></label>
                      </div>
                    </th>
                    <th scope="col" className="sorting_asc">
                      Name
                    </th>
                    <th scope="col" className="sorting">
                      Industry
                    </th>
                    <th scope="col" className="sorting">
                      Customer Type
                    </th>
                    <th scope="col" className="sorting">
                      Email
                    </th>
                    <th scope="col" className="sorting">
                      Telephone
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="accountCheck1"
                        />
                        <label className="custom-control-label" htmlFor="accountCheck1"></label>
                      </div>
                    </td>
                    <td className="sorting-cell">
                      <a href="/customer/desktop/theme1/account-view">
                        <span className="d-inline-block avatar avatar-sm mg-r-5">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                            className="rounded-circle"
                            alt=""
                          />
                        </span>
                        Adventure Works
                      </a>
                    </td>
                    <td>&nbsp;</td>
                    <td>Customer</td>
                    <td>
                      <a href="mailto:adventure@aw.com">adventureworks@domain.com</a>
                    </td>
                    <td>
                      <a href="tel:+1 518-445-5696">+1 518-445-5696</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* grid list */}
          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="accountCheck1" />
                      <label className="custom-control-label" htmlFor="accountCheck1"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-pic">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                      alt=""
                      className="rounded"
                    />
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/customer/desktop/theme1/account-view"
                        className="grid-list-item-title link-02"
                      >
                        Adventure Works
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Industry:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Customer Type:</span>
                        <span className="grid-list-item-col-content">Customer</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Email:</span>
                        <a href="mailto:adventure@aw.com">adventureworks@domain.com</a>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Telephone:</span>
                        <a href="tel:+1 518-445-5696">+1 518-445-5696</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Pagination />
        </div>
      </div>

      <NewAccount />
      <NewContact />
      <NewSegment />
      <NewMarketingList />
      <NewPersona />
      <NewICP />
      <MergeAccount />
    </div>
  );
};

export default AccountList;
