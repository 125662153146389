import { Plus, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const Event = () => {
  return (
    <div
      id="eventModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Event</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="eventSwitch" />
                <label className="custom-control-label" htmlFor="eventSwitch"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing Status</label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="eventSwitch2" />
                <label className="custom-control-label" htmlFor="eventSwitch2">
                  Available for Sending emails
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="eventSwitch7" />
                <label className="custom-control-label" htmlFor="eventSwitch7">
                  Available for Event Site
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing Event Site</label>
              <div className="table-responsive bg-white bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Pages
                      </th>
                      <th scope="col" className="wd-150 bd-t-0-f">
                        Created on
                      </th>
                      <th scope="col" className="wd-150 bd-t-0-f">
                        Status
                      </th>
                      <th scope="col" className="wd-70 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Home</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Agenda</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Speaker</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Ticket</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Registration</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                <input
                  type="text"
                  className="form-control mg-r-5"
                  placeholder="Enter the page name"
                />
                <button type="button" className="btn btn-primary flex-shrink-0">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-lg btn-success">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
