import { ChevronUp, Minus, Plus, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import { Input, Select as SelectHook } from '@base/components/form';

const newVendorProduct = () => {
  return (
    <div id="newBOM" className="modal right fade">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0 overflow-hidden">
          <div className="modal-header">
            <h1 className="h6 modal-title">Creat Bill of Materials</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">BoM Name</label>
              <Input type="text" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Components</label>
              <div className="table-responsive bd rounded-top">
                <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f sorting">
                        Component Name
                      </th>
                      <th scope="col" className="bd-t-0-f sorting">
                        Type
                      </th>
                      <th scope="col" className="bd-t-0-f sorting">
                        Sypply Method
                      </th>
                      <th scope="col" className="bd-t-0-f sorting">
                        Unit
                      </th>
                      <th scope="col" className="bd-t-0-f sorting">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Component 1</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pd-10 bd bd-t-0 rounded-bottom">
                <Input
                  type="text"
                  rightIcon={Icon('product')}
                  placeholder="Type or click to select an component"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-item-title">Created by</label>
                  <Input type="text" rightIcon={Icon('m_customer')} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <Input type="text" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Related Item</label>
              <div className="input-group">
                <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                  <div className="form-control-tag">
                    <span className="d-block avatar avatar-xs mg-r-10">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxk47d99qf_o3faJPuqsQ7sFrwfCIBhrd-VA&amp;usqp=CAU"
                        alt="Item 1"
                        className="img rounded"
                      />
                    </span>
                    <span className="form-control-tag-name">Item 1</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      aria-label="삭제"
                    >
                      <X />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 mn-wd-250 rounded"
                    placeholder="Type or click to select an item"
                  />
                </div>
                <div className="input-group-append">
                  <button type="button" className="btn" aria-label="open modal to select an item">
                    {Icon('product')}
                    <span className="sr-only">아이템 선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default newVendorProduct;
