/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';

import {
	ChevronDown,
	Star,
	RefreshCw,
	Search,
	Plus,
	List,
	Grid,
	Columns,
	Settings,
	Menu,
	Image,
	Upload,
	Download,
	MoreHorizontal,
	RotateCcw,
	X
} from 'react-feather';

// import "../../../../scss/desktop/theme1/theme1.scss";
import Pagination from '../../../../component/desktop/pagination-new';
import UnitDetail from '../modal/unitDetail';
import NewUnit from '../modal/newUnit';

const UnitList = () => {
	const [listType, setListType] = useState<any>({
		list: true,
		grid: false,
	});

	const [showAdvSearch, setShowAdvSearch] = useState<any>();

	return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Unit</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Products
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/product/desktop/theme1/list">Products</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Items</a>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/unist/desktop/theme1/list">Units</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a role="button">Recycle Bin</a>
              </div>
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Price List
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/unit/desktop/theme1/list">Price List</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Items</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/discount/desktop/theme1/list">Discount</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Trade Agreement</a>
              </div>
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Inventory
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a role="button">Warehouse</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Stock Ajustment</a>
              </div>
            </div>
          </div>
          <div className="btn-group dropdown mg-l-auto">
            <a href="#newUnit" className="btn btn-primary pd-x-10" data-toggle="modal">
              <Plus />
              <span className="sr-only">추가</span>
            </a>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle-split pd-x-5"
              data-toggle="dropdown"
            >
              <ChevronDown />
              <span className="sr-only">추가 옵션 열기</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <a href="/crm/demo-page/ui/product/desktop/theme1/write" className="dropdown-item">
                Product
              </a>
              <a href="#newItem" className="dropdown-item" data-toggle="modal">
                Item
              </a>
              <a href="#" className="dropdown-item" data-toggle="modal">
                Component
              </a>
              <a href="#newUnit" className="dropdown-item" data-toggle="modal">
                Unit
              </a>
              <a href="" className="dropdown-item">
                Price List
              </a>
              <a href="" className="dropdown-item">
                Discount
              </a>
              <a href="" className="dropdown-item">
                Trade Agreement
              </a>
            </div>
          </div>
          <div className="button-group">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="More"
                data-toggle="dropdown"
              >
                <MoreHorizontal />
                <ChevronDown />
                <span className="sr-only">선택 옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item">
                  <Upload className="mg-r-5" />
                  Import
                </button>
                <button className="dropdown-item">
                  <Download className="mg-r-5" />
                  Export
                </button>
              </div>
            </div>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                <List />
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <button
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <span className="dropdown-item-icon">
                    <List />
                  </span>
                  List
                </button>
                <button
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <span className="dropdown-item-icon">
                    <Grid />
                  </span>
                  Grid
                </button>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Group by: ?
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">?</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Date by: ?
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">?</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filter: ?
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">?</button>
              </div>
            </div>

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_asc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Unit Name
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item active">
                    Base Unit
                  </a>
                  <a role="button" className="dropdown-item">
                    Unit Name
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* Search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* for table list column settings */}
          {listType.list && (
            <div className="dropdown mg-l-5">
              <button type="button" className="btn btn-icon btn-white" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="unitColumnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="unitColumnCheck1">
                        Base Unit
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="unitColumnCheck2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="unitColumnCheck2">
                        Unit Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="unitColumnCheck3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="unitColumnCheck3">
                        Status
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="unitColumnCheck4"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="unitColumnCheck4">
                        Related Product
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">10</strong> / 10
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {/* table list */}
          {listType.list && (
            <div className="table-responsive">
              <table className="table dataTable">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="unitCheckAll" />
                        <label className="custom-control-label" htmlFor="unitCheckAll"></label>
                      </div>
                    </th>
                    <th scope="col" className="sorting">
                      Base Unit
                    </th>
                    <th scope="col" className="sorting_asc">
                      Unit Name
                    </th>
                    <th scope="col" className="sorting">
                      Active
                    </th>
                    <th scope="col" className="sorting">
                      Related Product
                    </th>
                    <th scope="col" className="sorting">
                      Detail
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="unitCheck1" />
                        <label className="custom-control-label" htmlFor="unitCheck1"></label>
                      </div>
                    </td>
                    <td>Bottle</td>
                    <td className="sorting-cell">
                      <a href="/crm/demo-page/ui/unit/desktop/theme1/view-new">Bottle</a>
                      <span className="badge badge-secondary mg-l-5">+ 2</span>
                    </td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="unitStatusSwitch1"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="unitStatusSwitch1"></label>
                      </div>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button type="button" className="btn btn-avatar" data-toggle="dropdown">
                          <span className="mg-r-5">Product 1</span>
                          <span className="badge badge-secondary mg-r-5">+ 1</span>
                          <ChevronDown />
                        </button>
                        <div className="dropdown-menu pd-0">
                          <div className="dropdown-item-list">Product 1</div>
                          <div className="dropdown-item-list">Product 2</div>
                          <div className="dropdown-footer">Total: 2</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link"
                        data-toggle="modal"
                        data-target="#unitDetailModal"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="unitCheck2" />
                        <label className="custom-control-label" htmlFor="unitCheck2"></label>
                      </div>
                    </td>
                    <td>Kit</td>
                    <td className="sorting-cell">
                      <a href="/crm/demo-page/ui/unit/desktop/theme1/view-new">Kit</a>
                      <span className="badge badge-secondary mg-l-5">+ 2</span>
                    </td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="unitStatusSwitch1"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="unitStatusSwitch1"></label>
                      </div>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button type="button" className="btn btn-avatar" data-toggle="dropdown">
                          <span className="mg-r-5">Product 1</span>
                          <span className="badge badge-secondary mg-r-5">+ 1</span>
                          <ChevronDown />
                        </button>
                        <div className="dropdown-menu pd-0">
                          <div className="dropdown-item-list">Product 1</div>
                          <div className="dropdown-item-list">Product 2</div>
                          <div className="dropdown-footer">Total: 2</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link"
                        data-toggle="modal"
                        data-target="#unitDetailModal"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* grid list */}
          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="unitCheck1" />
                      <label className="custom-control-label" htmlFor="unitCheck1"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/crm/demo-page/ui/product/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        Bottle
                      </a>
                      <span className="badge badge-secondary mg-l-5">+ 2</span>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Base Unit:</span>
                        <span className="grid-list-item-col-content">Bottle</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Related Product:</span>
                        <div className="dropdown">
                          <button type="button" className="btn btn-avatar" data-toggle="dropdown">
                            <span className="mg-r-5">Product 1</span>
                            <span className="badge badge-secondary mg-r-5">+ 1</span>
                            <ChevronDown />
                          </button>
                          <div className="dropdown-menu pd-0">
                            <div className="dropdown-item-list">Product 1</div>
                            <div className="dropdown-item-list">Product 2</div>
                            <div className="dropdown-footer">Total: 2</div>
                          </div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Detail:</span>
                        <button
                          type="button"
                          className="btn btn-link"
                          data-toggle="modal"
                          data-target="#unitDetailModal"
                        >
                          View
                        </button>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Active:</span>
                        <div className="grid-list-item-col-content">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="unitStatusSwitch1"
                              defaultChecked
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="unitStatusSwitch1"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="unitCheck2" />
                      <label className="custom-control-label" htmlFor="unitCheck2"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/crm/demo-page/ui/product/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        Kit
                      </a>
                      <span className="badge badge-secondary mg-l-5">+ 2</span>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Base Unit:</span>
                        <span className="grid-list-item-col-content">Kit</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Related Product:</span>
                        <div className="dropdown">
                          <button type="button" className="btn btn-avatar" data-toggle="dropdown">
                            <span className="mg-r-5">Product 1</span>
                            <span className="badge badge-secondary mg-r-5">+ 1</span>
                            <ChevronDown />
                          </button>
                          <div className="dropdown-menu pd-0">
                            <div className="dropdown-item-list">Product 1</div>
                            <div className="dropdown-item-list">Product 2</div>
                            <div className="dropdown-footer">Total: 2</div>
                          </div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Detail:</span>
                        <button
                          type="button"
                          className="btn btn-link"
                          data-toggle="modal"
                          data-target="#unitDetailModal"
                        >
                          View
                        </button>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Active:</span>
                        <div className="grid-list-item-col-content">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="unitStatusSwitch2"
                              defaultChecked
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="unitStatusSwitch2"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>

      <UnitDetail />
      <NewUnit />
    </>
  );
};

export default UnitList;
