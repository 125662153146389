import React, { useState } from 'react';
import { ArrowLeft, Database, Folder, Minus, Plus, X } from 'react-feather';

const KB = () => {
  const [showKnowledgeBase, setShowKnowledgeBase] = useState<any>();

  return (
    <div id="deskReplyKnowledgeBaseModal" className="modal fade right over-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Knowledge Base</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <span aria-hidden="true">
                <X />
              </span>
            </button>
          </div>
          <div className="modal-body">
            {!showKnowledgeBase && (
              <>
                {/* list */}
                <div className="input-group" data-toggle="dropdown">
                  <input
                    type="search"
                    className="form-control bd-r-0"
                    placeholder="Search Knowledge Base"
                  />
                  <span className="input-group-append">
                    <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                      <Database />
                    </button>
                  </span>

                  {/* tree */}
                  <div className="dropdown-menu wd-100p">
                    <div className="row mg-0">
                      <div className="col-6">
                        <ul className="kb-tree list-unstyled mg-b-0">
                          <li>
                            <button type="button" className="btn btn-cate pd-0">
                              <Minus className="btn btn-white mg-r-5 pd-x-6" />
                              <div className="text-truncate">Category 1</div>
                            </button>
                            <ul className="list-unstyled mg-b-0 mg-l-30">
                              <li className="mg-t-5">
                                <button type="button" className="btn pd-0">
                                  <Folder className="mg-r-5 tx-primary" />
                                  <div className="text-truncate">Folder 1</div>
                                </button>
                              </li>
                              <li className="mg-t-5">
                                <button type="button" className="btn pd-0">
                                  <Folder className="mg-r-5 tx-primary" />
                                  <div className="text-truncate">Folder 2</div>
                                </button>
                              </li>
                            </ul>
                          </li>
                          <li className="mg-t-5">
                            <button type="button" className="btn btn-cate pd-0">
                              <Plus className="btn btn-white mg-r-5 pd-x-6" />
                              <div className="text-truncate">Category 2</div>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="col-6 bd-l">
                        <div className="mg-t-5 tx-13 tx-color-03">Articles (2)</div>
                        <div className="d-flex flex-wrap align-items-center pd-y-5">
                          How to assess admin page?
                          <button type="button" className="btn btn-sm btn-primary mg-l-auto">
                            Insert
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary mg-l-5"
                            onClick={() => setShowKnowledgeBase(true)}
                          >
                            View
                          </button>
                        </div>
                        <div className="d-flex flex-wrap align-items-center pd-y-5 bd-t">
                          How to assess admin page?
                          <button type="button" className="btn btn-sm btn-primary mg-l-auto">
                            Insert
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary mg-l-5"
                            onClick={() => setShowKnowledgeBase(true)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mg-t-20 mg-b-10 tx-13 tx-color-03">2 Results Found</div>
                <div className="list-group">
                  <div className="list-group-item d-flex flex-wrap align-items-center">
                    How to assess admin page?
                    <button type="button" className="btn btn-sm btn-primary mg-l-auto">
                      Insert
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary mg-l-5"
                      onClick={() => setShowKnowledgeBase(true)}
                    >
                      View
                    </button>
                  </div>
                  <div className="list-group-item d-flex flex-wrap align-items-center">
                    How to find my password?
                    <button type="button" className="btn btn-sm btn-primary mg-l-auto">
                      Insert
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary mg-l-5"
                      onClick={() => setShowKnowledgeBase(true)}
                    >
                      View
                    </button>
                  </div>
                </div>
              </>
            )}

            {showKnowledgeBase && (
              <>
                {/* view */}
                <div className="d-flex align-items-center mg-b-15">
                  <button
                    type="button"
                    className="btn btn-link btn-icon"
                    aria-label="목록 보기"
                    onClick={() => setShowKnowledgeBase(false)}
                  >
                    <ArrowLeft />
                  </button>
                  <button type="button" className="btn btn-primary mg-l-auto">
                    Insert
                  </button>
                </div>
                <div className="card">
                  <div className="card-header flex-column align-items-start">
                    <span className="tx-16">Way to access the menu</span>
                    <span className="tx-13 tx-color-03">2020/12/21 created by MSR</span>
                  </div>
                  <div className="card-body">
                    Typically, your package should reach you within 2-9 days of placing an order. In
                    case you want your order to reach you within three days, then you select the
                    fast delivery option before placing your order.
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KB;
