import { Check } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Timeline = () => {
  return (
    <>
      <div className="new-timeline-group pd-20">
        <div className="new-timeline-header mg-t-0-f">
          <div className="new-timeline-label">2021-03-17</div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Check />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  Confirmed <strong className="mg-x-5">Transfer Oder TO-000001</strong> by MSR
                </span>
                <span className="mg-l-auto tx-color-03">15:28</span>
              </div>
            </div>
          </div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">{Icon('product')}</div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  Items <strong className="mg-x-5">picked</strong> by MSR
                </span>
                <span className="mg-l-auto tx-color-03">15:28</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;
