/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

import { ChevronDown } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';

const TYPES = [
	{
		value: 1,
		label: 'Static',
	},
	{
		value: 2,
		label: 'Update automatically',
	},
	{
		value: 3,
		label: 'Update manually',
	},
];

const Summary = () => {
	const [segmentType, setSegmentType] = useState(TYPES[0]);

	return (
		<div>
			<div className="form-group">
				<label className="form-item-title">Segment Name</label>
				<input type="text" className="form-control" />
			</div>
			<div className="form-group">
				<label className="form-item-title">Segment Type</label>
				<div className="row">
					<div className="col-lg-6 mg-b-10 mg-lg-b-0">
						<div className="input-group wd-300" data-toggle="dropdown">
							<input type="text" className="form-control" value={segmentType.label} />
							<div className="input-group-append">
								<button type="button" className="btn">
									<ChevronDown />
								</button>
							</div>
						</div>
						<div className="dropdown-menu wd-300">
							{TYPES.map((item, index) => (
								<button
									key={index}
									type="button"
									className="dropdown-item"
									onClick={(e) => {
										setSegmentType(item);
									}}
								>
									{item.label}
								</button>
							))}
						</div>
					</div>

					{segmentType.value == 2 && (
						<div className="col-lg-6">
							<div className="d-flex align-items-center">
								Every
								<input type="number" className="form-control wd-100 mg-x-10" />
								<SelectHook
									className="wd-150-f"
									options={[
										{ value: 1, label: 'day(s)' },
										{ value: 2, label: 'month(s)' },
										{ value: 3, label: 'year(s)' },
									]}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="form-group">
				<label className="form-item-title">Active</label>
				<div className="custom-control custom-switch">
					<input type="checkbox" className="custom-control-input" id="segmentActiveSwitch" />
					<label className="custom-control-label" htmlFor="segmentActiveSwitch"></label>
				</div>
			</div>
			<div className="form-group">
				<label className="form-item-title">Description</label>
				<textarea rows={3} cols={10} className="form-control"></textarea>
			</div>
		</div>
	);
};

export default Summary;
