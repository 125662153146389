import { useState } from 'react';
import classnames from 'classnames';

import { CheckCircle, Circle } from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "PurchaseCss/purchase.scss"

import PurchaseViewTimeline from './timeline';
import Attachments from '../../../component/desktop/attachments';
import Notelist from '../../../component/desktop/notelist';
import PurchaseHeader from './view/header';
import NewPR from './modal/newPR';
import NewPO from './modal/newPO';
import NewRFQ from './modal/newRFQ';
import NewRFT from './modal/newRFT';
import AddVendor from './modal/addVendor';
import NewVendor from './modal/newVendor';
import AssignedRep from '@demo-page/component/desktop/assigned-rep';
import PR from './view/pr';

const TABS = [
  {
    value: 1,
    label: 'Product Detail',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Note',
  },
];

const PRViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  const [openRight, setOpenRight] = useState<boolean>(false);

  return (
    <>
      <div className="view-wrap overflow-y-hidden">
        <PurchaseHeader title="PR-000001" groupBy="Purchase Request" setOpenRight={setOpenRight} />

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Purchase Request</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>New</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Request for Quote</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Purchase Order</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Received</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Payment</div>
            </li>
          </ol>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">From</label>
                    <div>Manual</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Reason</label>
                    <div>Lorem ipsum dolor sit amet</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Request by</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <span className="user-name">MSR</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Requested on</label>
                    <div>2021-06-24</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map((item, index) => (
                    <li
                      key={index}
                      className={classnames('nav-item', {
                        active: item.value == tab.value,
                      })}
                    >
                      <button
                        type="button"
                        className="btn btn-xs btn-move-tab"
                        aria-label="Drag-and-drop to reorder"
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <button
                        type="button"
                        className="btn nav-link"
                        onClick={(e) => {
                          setTab(item);
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="view-body overflow-auto">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show right aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}
              {tab.value == 1 && <PR setOpenRight={setOpenRight} />}
              {tab.value == 2 && <PurchaseViewTimeline />}
              {tab.value == 3 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>
      <NewPR />
      <NewPO openRight={openRight} />
      <NewRFQ />
      <NewRFT />
      <AddVendor />
      <NewVendor />
    </>
  );
};

export default PRViewNew;
