import { ChevronUp, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const NewSponsor = () => {
  return (
    <div
      id="newSponsor"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Sponsor</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="form-group">
              <label className="form-item-title">Sponsor Topic</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Sponsor Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Sponsor Amount</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text lh-1">＄</span>
                </div>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Sponsor Type</label>
              <SelectHook
                options={[
                  { value: 1, label: 'Monetary' },
                  { value: 2, label: 'Service' },
                  { value: 3, label: 'Equipment' },
                ]}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <textarea cols={10} rows={3} className="form-control"></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSponsor;
