import { useState } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, Plus, ArrowLeft, Check, CheckCircle } from 'react-feather';

import { Button, FormIcon, Input, Select } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { COLORS } from '@base/config/constant';
import NewABM from './modal/newABM';
import ProductList from '@demo-page/component/desktop/productlist';
import { Avatar } from '@base/components';
import CollectTargetAccounts from './modal/collectTarget';
import TargetAccountCollected from './view/targetAccountCollected';
import MarketingContent from './view/marketingContent';
import LeadScoringRule from './view/leadScoringRule';

const TABS = [
  {
    value: 1,
    label: 'Target Accounts Collected',
  },
  {
    value: 2,
    label: 'Marketing Content',
  },
  {
    value: 3,
    label: 'Journey Process',
  },
  {
    value: 4,
    label: 'Lead Scoring Rule',
  },
  {
    value: 5,
    label: 'Details',
  },
];

const ABMView = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a href="abm/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Account Based Marketing
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">AAA</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <Button
              color="primary"
              className="mg-l-5 rounded-30"
              icon="Plus"
              name="New"
              data-toggle="modal"
              data-target="#newABMModal"
            />
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="pd-10 bg-white bd-b">
          {/* process */}
          <div className="d-flex align-items-center pd-15 bg-light rounded">
            <ol className="list-unstyled process-wrap text-nowrap">
              <li className="process-item done">
                <button type="button" className="btn process-item-btn">
                  <Check />
                </button>
                <div className="process-item-text mg-t-10">Planning</div>
              </li>
              <li className="process-item done">
                <button type="button" className="btn process-item-btn">
                  <Check />
                </button>
                <div className="process-item-text mg-t-10">Marketing Content</div>
              </li>
              <li className="process-item done">
                <button type="button" className="btn process-item-btn">
                  <Check />
                </button>
                <div className="process-item-text mg-t-10">Journey Process</div>
              </li>
              <li className="process-item done">
                <button type="button" className="btn process-item-btn">
                  <Check />
                </button>
                <div className="process-item-text mg-t-10">Approval</div>
              </li>
              <li className="process-item current">
                <button type="button" className="btn process-item-btn">
                  <CheckCircle />
                </button>
                <div className="process-item-text mg-t-10">Campaign Open</div>
              </li>
            </ol>
            <Button size="sm" color="white" name="View detail process" />
          </div>
        </div>

        <div className="row no-gutters flex-nowrap flex-grow-1 overflow-auto">
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <Button
                    icon="move"
                    iconType="custom"
                    className="view-aside-section-move mg-r-5"
                  />
                  <span className="view-aside-section-header-title">Summary</span>
                  <Button
                    color="link link-03"
                    className="mg-l-auto pd-0"
                    icon="ChevronDown"
                    data-toggle="collapse"
                    data-target="#abmSummary"
                  />
                </div>
                <div id="abmSummary" className="view-aside-section-body collapse fade show">
                  <div className="form-group">
                    <label className="form-item-title">Objective</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Account Collection Methods</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group-title">Campaign Date</div>
                  <div className="form-group">
                    <label className="form-item-title">Start Date</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">End Date</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Time Zone</label>
                    <div>&nbsp;</div>
                  </div>
                  <hr />
                  <div className="form-group">
                    <label className="form-item-title">Campaign Process</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Target Account Process</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <Button
                    icon="move"
                    iconType="custom"
                    className="view-aside-section-move mg-r-5"
                  />
                  <span className="view-aside-section-header-title">Product</span>
                  <Button
                    color="link link-03"
                    className="mg-l-auto pd-0"
                    icon="ChevronDown"
                    data-toggle="collapse"
                    data-target="#abmProduct"
                  />
                </div>
                <div id="abmProduct" className="view-aside-section-body collapse fade show">
                  <ProductList />
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item, index) =>
                      item.value < 3 && (
                        <li
                          key={index}
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* Target Accounts Collected */}
              {tab.value == 1 && <TargetAccountCollected />}

              {/* Marketing Content */}
              {tab.value == 2 && <MarketingContent />}

              {/* Journey Process */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="d-flex algin-items-center form-group">
                    <Select />
                    <Button color="link" name="View" className="mg-l-10" />
                  </div>
                  <div className="d-flex bg-white bd rounded">
                    <div className="flex-grow-1">
                      <div className="d-flex bd-b">
                        <div className="d-flex flex-column justify-content-center align-items-center pd-15 bd-r tx-color-03">
                          Marketing content
                          <Button
                            color="primary"
                            size="xs"
                            icon="Plus"
                            className="btn-icon mg-t-10 rounded-0"
                            outline
                          />
                        </div>
                        <div className="d-flex flex-wrap align-items-center pd-15">
                          <div className="card flex-shrink-0 bd-primary-light wd-120 mg-r-10">
                            <div className="pd-5 text-center bg-primary-light bd-b text-truncate">
                              Email
                            </div>
                            <div className="d-flex align-items-center justify-content-center pd-10 tx-gray-500">
                              <FormIcon icon="Mail" size={24} />
                            </div>
                            <div className="pd-x-5 pd-b-5 text-center text-truncate">Name</div>
                          </div>
                          <div className="card flex-shrink-0 bd-indigo-light wd-120 mg-r-10">
                            <div className="pd-5 text-center bg-indigo-light bd-b text-truncate">
                              Landing Page
                            </div>
                            <div className="d-flex align-items-center justify-content-center pd-10 tx-gray-500">
                              <FormIcon icon="Layout" size={24} />
                            </div>
                            <div className="pd-x-5 pd-b-5 text-center text-truncate">Name</div>
                          </div>
                          <div className="card flex-shrink-0 bd-pink-light wd-120 mg-r-10">
                            <div className="pd-5 text-center bg-pink-light bd-b text-truncate">
                              Download
                            </div>
                            <div className="d-flex align-items-center justify-content-center pd-10 tx-gray-500">
                              <FormIcon icon="Download" size={24} />
                            </div>
                            <div className="pd-x-5 pd-b-5 text-center text-truncate">Name</div>
                          </div>
                        </div>
                      </div>
                      <div className="pd-10 bd-b">
                        <label className="d-block tx-color-03">Target</label>
                        <div className="d-flex flex-wrap">
                          <div className="d-flex align-items-center mg-r-10 mg-b-5 pd-x-10 pd-y-5 bd rounded-30">
                            <Avatar width={25} height={25} alt="John Doe" />
                            <div className="mg-l-10">John Doe</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bd-l" style={{ width: '242px' }}>
                      <div className="pd-10 bd-b text-center tx-color-03">Action to Customer</div>
                      <div className="d-flex flex-wrap pd-l-15 pd-r-5 pd-y-15 bd-b">
                        <div className="card flex-shrink-0 bd-primary-light wd-100 mg-r-10 mg-b-10">
                          <div className="pd-5 text-center bg-primary-light bd-b text-truncate">
                            Email
                          </div>
                          <div className="d-flex align-items-center justify-content-center pd-20 tx-gray-500">
                            <FormIcon icon="Mail" size={24} />
                          </div>
                        </div>
                        <div className="card flex-shrink-0 bd-primary-light wd-100 mg-r-10 mg-b-10">
                          <div className="pd-5 text-center bg-primary-light bd-b text-truncate">
                            SMS
                          </div>
                          <div className="d-flex align-items-center justify-content-center pd-20 tx-gray-500">
                            <FormIcon icon="sms" iconType="custom" />
                          </div>
                        </div>
                        <div className="card flex-shrink-0 bd-primary-light wd-100 mg-r-10 mg-b-10">
                          <div className="pd-5 text-center bg-primary-light bd-b text-truncate">
                            DM
                          </div>
                          <div className="d-flex align-items-center justify-content-center pd-20 tx-gray-500">
                            <FormIcon icon="post" iconType="custom" />
                          </div>
                        </div>
                        <div className="card flex-shrink-0 bd-primary-light wd-100 mg-r-10 mg-b-10">
                          <div className="pd-5 text-center bg-primary-light bd-b text-truncate">
                            Fax
                          </div>
                          <div className="d-flex align-items-center justify-content-center pd-20 tx-gray-500">
                            <FormIcon icon="fax" iconType="custom" />
                          </div>
                        </div>
                        <div className="card flex-shrink-0 bd-primary-light wd-100 mg-r-10 mg-b-10">
                          <div className="pd-5 text-center bg-primary-light bd-b text-truncate">
                            Call
                          </div>
                          <div className="d-flex align-items-center justify-content-center pd-20 tx-gray-500">
                            <FormIcon icon="Phone" size={24} />
                          </div>
                        </div>
                        <div className="card flex-shrink-0 bd-primary-light wd-100 mg-r-10 mg-b-10">
                          <div className="pd-5 text-center bg-primary-light bd-b text-truncate">
                            Meeting
                          </div>
                          <div className="d-flex align-items-center justify-content-center pd-20 tx-gray-500">
                            <FormIcon icon="Users" size={24} />
                          </div>
                        </div>
                      </div>
                      <div className="pd-10 bd-b text-center tx-color-03">
                        Landing Page/Offer Site
                      </div>
                      <div className="d-flex flex-wrap pd-l-15 pd-r-5 pd-y-15">
                        <div className="card flex-shrink-0 bd-indigo-light wd-100 mg-r-10 mg-b-10">
                          <div className="pd-5 text-center bg-indigo-light bd-b text-truncate">
                            Landing Page
                          </div>
                          <div className="d-flex align-items-center justify-content-center pd-20 tx-gray-500">
                            <FormIcon icon="Layout" size={24} />
                          </div>
                        </div>
                        <div className="card flex-shrink-0 bd-pink-light wd-100 mg-r-10 mg-b-10">
                          <div className="pd-5 text-center bg-pink-light bd-b text-truncate">
                            Download
                          </div>
                          <div className="d-flex align-items-center justify-content-center pd-20 tx-gray-500">
                            <FormIcon icon="Download" size={24} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Lead Scoring Rule */}
              {tab.value == 4 && <LeadScoringRule />}
            </div>
          </div>
        </div>
      </div>

      <NewABM />
      <CollectTargetAccounts />
    </>
  );
};

export default ABMView;
