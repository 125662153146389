import { Mail, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const TargetMemberList = () => {
  return (
    <div
      id="targetMemberListModal"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Members</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto">
            <div className="d-flex flex-wrap align-items-center pd-5 bd-b">
              <div className="media mx-wd-100p">
                <div className="avatar avatar-xs">
                  <img
                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                    className="rounded-circle"
                  />
                </div>
                <div className="media-body mg-l-5 text-truncate">Allan Rey Palban</div>
              </div>
              <span className="mg-l-auto">
                <Mail className="tx-teal mg-r-5" size={16} />
                allan@hanbiro.com
              </span>
              <span className="mg-l-10">
                <span className="wd-16 ht-16 mg-r-5 tx-orange op-8">{Icon('sms')}</span>
                010-1234-5678
              </span>
              <button
                type="button"
                className="btn btn-link btn-icon mg-l-10"
                data-han-tooltip="Delete"
              >
                <X className="tx-danger" />
                <span className="sr-only">삭제</span>
              </button>
            </div>
            <div className="d-flex flex-wrap align-items-center pd-5 bd-b">
              <div className="media mx-wd-100p">
                <div className="avatar avatar-xs">
                  <img
                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                    className="rounded-circle"
                  />
                </div>
                <div className="media-body mg-l-5 text-truncate">Situmay</div>
              </div>
              <span className="mg-l-auto">
                <Mail className="tx-teal mg-r-5" size={16} />
                situmay@hanbiro.com
              </span>
              <span className="mg-l-10">
                <span className="wd-16 ht-16 mg-r-5 tx-orange op-8">{Icon('sms')}</span>
                010-9876-5432
              </span>
              <button
                type="button"
                className="btn btn-link btn-icon mg-l-10"
                data-han-tooltip="Delete"
              >
                <X className="tx-danger" />
                <span className="sr-only">삭제</span>
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetMemberList;
