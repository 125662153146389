const EventSite = () => {
  return (
    <>
      <div className="table-responsive bg-white bd rounded-top">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Type
              </th>
              <th scope="col" className="bd-t-0-f">
                Name
              </th>
              <th scope="col" className="bd-t-0-f">
                Views
              </th>
              <th scope="col" className="bd-t-0-f">
                Stage
              </th>
              <th scope="col" className="bd-t-0-f wd-100">
                Created on
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EventSite;
