import { useState } from 'react';
import { X, Settings, User } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

// import "OrderCss/sales_order.scss"

const SalesReturnWrite = () => {
  const [returnType, setReturnType] = useState<any>('refund');

  const handleReturnTypeChange = (e: any) => {
    const { value } = e.target;
    setReturnType(value);
  };

  const [showOrderEmail, setShowOrderEmail] = useState<any>();

  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Return Name</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Customer</label>
        <div className="input-group">
          {/* before selecting a customer
                <input type="text" className="form-control" placeholder="Type or click to select a customer" /> 
                */}
          <div className="form-control pd-y-1-f d-flex align-items-center">
            <div className="avatar avatar-xs mg-r-10 flex-shrink-0">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                className="rounded"
                alt="Adventure Works"
              />
            </div>
            <span className="text-truncate">Adventure Works</span>
            <button type="button" className="btn mg-l-auto" aria-label="delete">
              <X />
              <span className="sr-only">삭제</span>
            </button>
          </div>
          <div className="input-group-append">
            <button
              type="button"
              className="btn mg-l-auto"
              aria-label="open modal to select a customer"
            >
              {Icon('m_customer')}
              <span className="sr-only">고객 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Return ID</label>
        <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <input type="text" className="form-control" defaultValue="RAM-000001" />
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open generator of Order ID modal">
              <Settings />
              <span className="sr-only">ID 생성 설정 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Reference ID</label>
        <div className="wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <SelectHook />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Returned on</label>
        <div className="wd-300">
          <DatePicker
            selected={new Date()}
            onChange={() => {
              console.log();
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Return Type</label>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="refundTypeRadio1"
            name="refundTypeRadio"
            className="custom-control-input"
            value="refund"
            checked={returnType === 'refund'}
            onChange={(e) => handleReturnTypeChange(e)}
          />
          <label className="custom-control-label" htmlFor="refundTypeRadio1">
            Refund
          </label>
        </div>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="refundTypeRadio2"
            name="refundTypeRadio"
            className="custom-control-input"
            value="exchange"
            checked={returnType === 'exchange'}
            onChange={(e) => handleReturnTypeChange(e)}
          />
          <label className="custom-control-label" htmlFor="refundTypeRadio2">
            Exchange
          </label>
        </div>
      </div>
      {returnType === 'refund' && (
        <div className="form-group">
          <label className="form-item-title d-block">Refund Method</label>
          <div className="custom-control custom-radio d-inline-block mg-r-10">
            <input
              type="radio"
              id="refundMethodRadio1"
              name="refundMethodRadio"
              className="custom-control-input"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="refundMethodRadio1">
              Credit Note
            </label>
          </div>
          <div className="custom-control custom-radio d-inline-block mg-r-10">
            <input
              type="radio"
              id="refundMethodRadio2"
              name="refundMethodRadio"
              className="custom-control-input"
            />
            <label className="custom-control-label" htmlFor="refundMethodRadio2">
              Cash/Credit Card
            </label>
          </div>
        </div>
      )}
      <div className="form-group">
        <label className="form-item-title">Reason</label>
        <div className="wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <SelectHook />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Return Warehouse</label>
        <div className="wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <SelectHook />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Assigned Rep.</label>
        <div className="input-group">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="d-block avatar avatar-xs mg-r-10">
                <img
                  src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                  alt="John"
                  className="img rounded-circle"
                />
              </span>
              <span className="form-control-tag-name">
                John<span className="tx-gray-500">(Sales)</span>
              </span>
              <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                <X />
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250"
              placeholder="Type or click to select a user"
            />
          </div>
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open modal to select a user">
              <User />
              <span className="sr-only">담당자 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Return Process</label>
        <div className="wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <SelectHook />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Return Template</label>
        <div className="d-flex align-items-center">
          <SelectHook className="wd-200-f" />
          <button type="button" className="btn btn-link">
            Preview
          </button>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Sales Return Details</label>
        <div className="table-responsive bg-white bd rounded-top">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Image
                </th>
                <th scope="col" className="bd-t-0-f">
                  Item
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit Qty
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit Price
                </th>
                <th scope="col" className="bd-t-0-f">
                  Qty Returnable
                </th>
                <th scope="col" className="bd-t-0-f">
                  Qty Returned
                </th>
                <th scope="col" className="bd-t-0-f">
                  Restock
                </th>
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="avatar avatar-sm bg-gray-200 rounded">
                    <img src="assets/images/notfound.png" alt="" />
                  </div>
                </td>
                <td>AAA</td>
                <td>Box</td>
                <td>1</td>
                <td>￦100,000</td>
                <td>35</td>
                <td>
                  <input type="number" className="form-control wd-150" defaultValue="35" />
                </td>
                <td>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="restockCheck1" />
                    <label className="custom-control-label" htmlFor="restockCheck1"></label>
                  </div>
                </td>
                <td>
                  <button type="button" className="btn btn-icon">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pd-10 bd bd-t-0 rounded-bottom">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select an item"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                {Icon('product')}
              </button>
            </div>
          </div>
        </div>
        <div className="row row-xs mg-t-10">
          <div className="col-md-6">
            <ul className="list-unstyled lh-7 mg-b-0">
              <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                <span>Items : 35</span>
                <span> ₩ 3,500,000</span>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
              <li className="d-flex justify-content-between">
                <span>Sub-Total</span>
                <span>₩ 3,500,000</span>
              </li>
              <li className="d-flex justify-content-between">
                <span>Tax (10%)</span>
                <span>₩ 350,000</span>
              </li>
              <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                <strong>Total Amount</strong>
                <strong>₩ 3,850,000</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="orderEmailSwitch1"
          onChange={() => setShowOrderEmail(!showOrderEmail)}
        />
        <label className="custom-control-label" htmlFor="orderEmailSwitch1">
          Send Create Sales Order Email{' '}
        </label>
      </div>
      {showOrderEmail && (
        <div className="mg-t-5 mg-l-30">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="emailCheck1"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="emailCheck1">
              sales@mail.com
            </label>
          </div>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="emailCheck2"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="emailCheck2">
              sales@mail.com
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesReturnWrite;
