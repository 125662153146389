import { useState } from 'react';
import classnames from 'classnames';

import { Plus, X } from 'react-feather';

const TABS = [
  {
    value: 1,
    label: 'Firmographic',
  },
  {
    value: 2,
    label: 'Geographic',
  },
  {
    value: 3,
    label: 'Behavior',
  },
];

const NewAccountProfile = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [showAdditional, setShowAdditional] = useState(false);
  const [showAdditional2, setShowAdditional2] = useState(false);

  return (
    <div id="newAccountProfile" className="modal fade right">
      <div className="modal-dialog wd-auto">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">New Profile</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="tx-color-03">Profile Name</label>
              <input type="text" className="form-control" />
            </div>

            {/* tabs */}
            <nav className="nav nav-line mg-b-15-f bd-0">
              {TABS.map((item, index) => (
                <button
                  key={index}
                  className={classnames('btn nav-link', {
                    active: item.value == tab.value,
                  })}
                  onClick={(e) => {
                    setTab(item);
                  }}
                >
                  {item.label}
                </button>
              ))}
            </nav>
            {tab.value == 1 && (
              <>
                <ul className="list-unstyled mg-b-0 bd rounded">
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Industry
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="firmographicSwitch1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="firmographicSwitch1"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Subsidiary
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="firmographicSwitch2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="firmographicSwitch2"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Compony Size
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="firmographicSwitch3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="firmographicSwitch3"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Annual Revenue
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="firmographicSwitch4"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="firmographicSwitch4"></label>
                    </div>
                  </li>
                  <li
                    className={classnames('d-flex align-items-center pd-12', {
                      'bd-b': showAdditional,
                    })}
                  >
                    Age (years in business)
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="firmographicSwitch5"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="firmographicSwitch5"></label>
                    </div>
                  </li>
                  {showAdditional && (
                    <>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Business type and Customer type
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch6"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch6"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Investors
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch7"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch7"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Product / Services
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch8"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch8"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Revenue Growth rate
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch9"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch9"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Corporate Anniversary
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch10"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch10"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Decision
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch11"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch11"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Ownership Status
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch12"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch12"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Employee Growth
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch13"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch13"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Profits or Losses
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch14"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch14"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Increase or decrease in revenue
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch15"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch15"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        No. of potential customer
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch16"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch16"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        No. of customer
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch17"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch17"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Market Size
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch18"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch18"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Market Share
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch19"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch19"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Trends
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch20"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch20"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Property
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch21"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch21"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Residency
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch22"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch22"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Technology
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch23"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch23"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12">
                        Average sales cycle (How long take to close?)
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="firmographicSwitch24"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="firmographicSwitch24"
                          ></label>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowAdditional(!showAdditional)}
                >
                  {!showAdditional && (
                    <>
                      <Plus className="mg-r-5" />
                      Additional
                    </>
                  )}
                  {showAdditional && (
                    <>
                      <X className="mg-r-5" />
                      Cancel
                    </>
                  )}
                </button>
              </>
            )}

            {tab.value == 2 && (
              <>
                <ul className="list-unstyled mg-b-0 bd rounded">
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Related Account
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="geographicSwitch1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="geographicSwitch1"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Location
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="geographicSwitch2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="geographicSwitch2"></label>
                    </div>
                  </li>
                  <li
                    className={classnames('d-flex align-items-center pd-12', {
                      'bd-b': showAdditional2,
                    })}
                  >
                    Purchasing Process
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="geographicSwitch3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="geographicSwitch3"></label>
                    </div>
                  </li>
                </ul>
              </>
            )}

            {tab.value == 3 && (
              <>
                <ul className="list-unstyled mg-b-0 bd rounded">
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Website Visits
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="behaviorSwitch1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="behaviorSwitch1"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Response to Marketing
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="behaviorSwitch2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="behaviorSwitch2"></label>
                    </div>
                  </li>
                  <li
                    className={classnames('d-flex align-items-center pd-12', {
                      'bd-b': showAdditional2,
                    })}
                  >
                    Usage
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="behaviorSwitch3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="behaviorSwitch3"></label>
                    </div>
                  </li>
                  {showAdditional2 && (
                    <>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        User Status
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch4"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch4"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Purchasing Methods
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch5"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch5"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Attitude to Product or Service
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch6"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch6"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Purchasing Behavior
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch7"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch7"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12">
                        Adopter(Status
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch8"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch8"></label>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowAdditional2(!showAdditional2)}
                >
                  {!showAdditional2 && (
                    <>
                      <Plus className="mg-r-5" />
                      Additional
                    </>
                  )}
                  {showAdditional2 && (
                    <>
                      <X className="mg-r-5" />
                      Cancel
                    </>
                  )}
                </button>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAccountProfile;
