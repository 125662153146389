const Shipment = () => {
  return (
    <>
      <div className="pd-20">
        <div className="form-group">
          <label className="form-item-title mg-r-10">Shipment ID</label>
          <a href="" target="_blank" title="새창으로 열림">
            SHI-000001
          </a>
        </div>
        <div className="table-responsive bg-white bd rounded-top">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Image
                </th>
                <th scope="col" className="bd-t-0-f">
                  Item
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit Qty
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit Price
                </th>
                <th scope="col" className="bd-t-0-f">
                  QTY Picked
                </th>
                <th scope="col" className="bd-t-0-f">
                  QTY Packed
                </th>
                <th scope="col" className="bd-t-0-f">
                  QTY Shipped
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="avatar avatar-sm bg-gray-200 rounded">
                    <img src="assets/images/notfound.png" alt="" />
                  </div>
                </td>
                <td>
                  <a href="" target="_blank" title="새창으로 열림">
                    Bottle/Mint/Small
                  </a>
                </td>
                <td>Bottle</td>
                <td>1</td>
                <td>￦1,500</td>
                <td>5</td>
                <td>0</td>
                <td>5</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Shipment;
