import React, { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  X,
  List,
  Grid,
  Columns,
  Settings,
  Menu,
  RotateCcw,
  Plus,
  Trello,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss";
import './style.scss';

import { PRList, RFTList, RFQList, POList, PurchaseReturnList } from './list/list-group';
import { PRGrid, RFTGrid, RFQGrid, POGrid, PurchaseReturnGrid } from './list/grid-group';

import NewPR from './modal/newPR';
import NewPO from './modal/newPO';
import NewPurchaseReturn from './modal/newPurchaseReturn';

import Pagination from '../../../component/desktop/pagination-new';
import { Checkbox } from '@base/components/form';

const PurchaseList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
    kanban: false,
  });

  const GROUPBY = [
    { value: 'all', label: 'All' },
    { value: 'pr', label: 'PR' },
    { value: 'rft', label: 'RFT' },
    { value: 'rfq', label: 'RFQ' },
    { value: 'po', label: 'PO' },
    { value: 'return', label: 'Purchase Return' },
  ];
  const [groupBy, setGroupBy] = useState(GROUPBY[0]);

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Purchase</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
          </div>
          <div className="btn-group dropdown mg-l-auto">
            <button
              type="button"
              className="btn btn-primary pd-x-10"
              data-toggle="modal"
              data-target="#newPR"
            >
              <Plus />
              <span className="sr-only">추가</span>
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle-split pd-x-5"
              data-toggle="dropdown"
            >
              <ChevronDown />
              <span className="sr-only">추가 옵션 열기</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newPR"
              >
                Purchase Request
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newPO"
              >
                Purchase Order
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newPurchaseReturn"
              >
                Purchase Return
              </button>
            </div>
          </div>
          <div className="button-group">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.kanban && <Trello />}
                {listType.list && <List />}
                {listType.grid && <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.kanban })}
                  onClick={() => setListType({ kanban: true })}
                >
                  <Trello className="mg-r-5" />
                  Kanban
                </button>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                {groupBy.label}
                <ChevronDown />
              </button>
              <div className="dropdown-menu">
                {GROUPBY.map((item, index) => (
                  <button
                    key={index}
                    type="button"
                    className={classnames('dropdown-item', {
                      active: item.value == groupBy.value,
                    })}
                    onClick={(e) => {
                      setGroupBy(item);
                    }}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                All
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300"></div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filters
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300"></div>
            </div>

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: ID
                  <ChevronDown />
                </button>
              </div>
            )}
          </div>

          {/* search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="ID" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        ID
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {listType.list && (
            <>
              {groupBy.value === 'all' && (
                <div className="table-responsive">
                  <table className="table dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="tb-sel-cell">
                          <Checkbox />
                        </th>
                        <th scope="col" className="sorting_desc">
                          Purchase ID
                        </th>
                        <th scope="col" className="sorting">
                          Stage
                        </th>
                        <th scope="col" className="sorting">
                          Status
                        </th>
                        <th scope="col" className="sorting">
                          Vendor
                        </th>
                        <th scope="col" className="sorting">
                          Item
                        </th>
                        <th scope="col" className="sorting">
                          Quantity
                        </th>
                        <th scope="col" className="sorting">
                          Total Amount
                        </th>
                        <th scope="col" className="sorting">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tb-sel-cell">
                          <Checkbox />
                        </td>
                        <td className="sorting-cell">
                          <a href="/purchase/desktop/theme1/view">P-000001</a>
                        </td>
                        <td>Open</td>
                        <td>New</td>
                        <td>Vendor 1</td>
                        <td>Item 1</td>
                        <td>100</td>
                        <td>$ 2,000</td>
                        <td>2022/04/27</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {groupBy.value === 'pr' && <PRList />}
              {groupBy.value === 'rft' && <RFTList />}
              {groupBy.value === 'rfq' && <RFQList />}
              {groupBy.value === 'po' && <POList />}
              {groupBy.value === 'return' && <PurchaseReturnList />}
            </>
          )}

          {listType.grid && (
            <>
              {groupBy.value === 'all' && (
                <div className="row row-xs">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="receiptCheck1"
                          />
                          <label className="custom-control-label" htmlFor="receiptCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/purchase/desktop/theme1/view"
                            className="grid-list-item-title link-02"
                          >
                            P-000001
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Stage :</span>
                            <span className="grid-list-item-col-content">Open</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Status :</span>
                            <span className="grid-list-item-col-content">New</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Vendor :</span>
                            <span className="grid-list-item-col-content">Vendor 1</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Item :</span>
                            <span className="grid-list-item-col-content">Item 1</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Quntity :</span>
                            <span className="grid-list-item-col-content">100</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Total Amount :</span>
                            <span className="grid-list-item-col-content">$ 2,000</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on :</span>
                            <span className="grid-list-item-col-content">2022/04/27</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {groupBy.value === 'pr' && <PRGrid />}
              {groupBy.value === 'rft' && <RFTGrid />}
              {groupBy.value === 'rfq' && <RFQGrid />}
              {groupBy.value === 'po' && <POGrid />}
              {groupBy.value === 'return' && <PurchaseReturnGrid />}
            </>
          )}

          {listType.kanban && (
            <div className="d-flex ht-100p">
              <div className="wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="PR (3)" className="tx-bold tx-uppercase" />
                </div>
                <div className="d-flex mg-b-10 pd-10 bg-white rounded shadow-sm">
                  <Checkbox />
                  <div className="flex-grow-1" style={{ maxWidth: 'calc(100% - 24px)' }}>
                    <a
                      href="/purchase/desktop/theme1/view"
                      className="mg-r-10 link-02 tx-bold text-truncate"
                    >
                      PR-000001
                    </a>
                    <ul className="mg-t-10 list-unstyled mg-0">
                      <li>Purchase Id: P-00000001</li>
                      <li>Status : New</li>
                      <li>Item : Item 1</li>
                      <li>Quantity : 100</li>
                      <li>Estimate Amount : $ 2,000</li>
                      <li>Required Date : 2022/04/28</li>
                      <li>Requested by : MSR</li>
                      <li>Vendor : Vendor 1</li>
                      <li>From : Sales Order</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="RFT(5)" className="tx-bold tx-uppercase" />
                </div>
                <div className="d-flex mg-b-10 pd-10 bg-white rounded shadow-sm">
                  <Checkbox />
                  <div className="flex-grow-1" style={{ maxWidth: 'calc(100% - 24px)' }}>
                    <a
                      href="/purchase/desktop/theme1/view"
                      className="mg-r-10 link-02 tx-bold text-truncate"
                    >
                      RFT-000002
                    </a>
                    <ul className="mg-t-10 list-unstyled mg-0">
                      <li>Purchase Id: P-00000001</li>
                      <li>Status : New</li>
                      <li>Vendor : Vendor 1</li>
                      <li>Item : Item 1</li>
                      <li>Quantity : 100</li>
                      <li>Estimate Amount : $ 2,000</li>
                      <li>Valid Until : 2022/04/28</li>
                      <li>
                        Response : <span className="text-success">1</span>/3
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="RFQ(5)" className="tx-bold tx-uppercase" />
                </div>
                <div className="d-flex mg-b-10 pd-10 bg-white rounded shadow-sm">
                  <Checkbox />
                  <div className="flex-grow-1" style={{ maxWidth: 'calc(100% - 24px)' }}>
                    <a
                      href="/purchase/desktop/theme1/view"
                      className="mg-r-10 link-02 tx-bold text-truncate"
                    >
                      RFQ-000003
                    </a>
                    <ul className="mg-t-10 list-unstyled mg-0">
                      <li>Purchase Id: P-00000001</li>
                      <li>Related RFT : RFT-0000007</li>
                      <li>Status : New</li>
                      <li>Vendor : Vendor 1</li>
                      <li>Item : Item 1</li>
                      <li>Quantity : 100</li>
                      <li>Total Amount : $ 1,900</li>
                      <li>Valid Until : 2022/04/28</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="PO(3)" className="tx-bold tx-uppercase" />
                </div>
                <div className="d-flex mg-b-10 pd-10 bg-white rounded shadow-sm">
                  <Checkbox />
                  <div className="flex-grow-1" style={{ maxWidth: 'calc(100% - 24px)' }}>
                    <a
                      href="/purchase/desktop/theme1/view"
                      className="mg-r-10 link-02 tx-bold text-truncate"
                    >
                      PO-000004
                    </a>
                    <ul className="mg-t-10 list-unstyled mg-0">
                      <li>Purchase Id: P-00000001</li>
                      <li>Subject : Vendor 1 Purchase Order</li>
                      <li>Stage : Open Purchase Order</li>
                      <li>Status : New</li>
                      <li>Vendor : Vendor 1</li>
                      <li>Item : Item 1</li>
                      <li>Quantity : 100</li>
                      <li>Total Amount : $ 1,900</li>
                      <li>PO Date : 2022/04/28</li>
                      <li>Received Goods : 100</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          {!listType.kanban && <Pagination />}
        </div>
      </div>

      <NewPR />
      <NewPO />
      <NewPurchaseReturn />
    </>
  );
};

export default PurchaseList;
