import { ChevronUp, Minus, Plus, X } from 'react-feather';

const NewProUnit = () => {
  return (
    <div
      id="newUnitModal"
      className="modal fade right product-right-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">New Base Unit</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="d-block form-item-title">Unit Type</label>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="productUnitTypeRadio1"
                  name="productUnitTypeRadio"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="productUnitTypeRadio1">
                  General
                </label>
              </div>
              <div className="custom-control custom-radio d-inline-block">
                <input
                  type="radio"
                  id="productUnitTypeRadio2"
                  name="productUnitTypeRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="productUnitTypeRadio2">
                  Subscription
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Base Unit</label>
              <input type="text" className="form-control mg-b-5" />
              <button type="button" className="btn btn-link">
                Create Unit Name
              </button>
            </div>
            <div className="form-group">
              <label className="form-item-title">Unit Name</label>
              <div className="table-responsive bd rounded">
                <table className="table mg-b-0">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Quantity
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Bottle</td>
                      <td>1</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn tx-danger">
                          <Minus />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button type="button" className="btn btn-link">
                <Plus className="mg-r-5" />
                Add a unit name
              </button>
              <div className="mg-t-5 tx-orange">
                Base Unit은 자동으로 Unit Quantit량 1로 책정되어 Unit Name으로 생성됩니다.
                수정하거나 삭제할 수 없습니다.
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Add
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                aria-label="toggle dropdown"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">저장 옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <button type="button" className="dropdown-item">
                  Add and apply
                </button>
                <button type="button" className="dropdown-item">
                  Add and add new
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProUnit;
