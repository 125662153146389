import { ExternalLink, Download, Paperclip } from 'react-feather';

const Timeline = () => {
  return (
    <>
      <div className="new-timeline-group pd-20">
        <div className="new-timeline-header mg-t-0-f">
          <div className="new-timeline-label">2021-03-17</div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Paperclip />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  MSR uploaded a file <strong className="mg-x-5">계약서.doc</strong>
                </span>
                <span className="mg-l-auto tx-color-03">09:42</span>
              </div>
              <div className="new-timeline-footer">
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    <Download className="mg-r-5" />
                    Download all
                  </button>
                  <button type="button" className="btn btn-link">
                    View detail
                    <ExternalLink className="mg-l-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;
