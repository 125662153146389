import Icon from '@base/assets/icons/svg-icons';
import { X, Globe, Download, MessageCircle } from 'react-feather';

const SiteLinks = () => {
  return (
    <div
      id="siteLinksModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Site Links</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto">
            <ul className="site-links-list">
              <li>
                <div className="d-flex align-items-center">
                  <div className="card bd-primary">
                    <div className="card-header bg-primary tx-white">
                      <Globe />
                      Sign
                    </div>
                    <div className="card-body">&nbsp;</div>
                  </div>
                  <div className="site-direction">Confirmed</div>
                  <div className="diagram-item diagram-status">
                    <div className="diagram-item-name">Won</div>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="card bd-purple">
                    <div className="card-header bg-purple tx-white">
                      {Icon('form')}
                      Feedback
                    </div>
                    <div className="card-body">&nbsp;</div>
                  </div>
                  <div className="site-direction">Feedback</div>
                  <div className="diagram-item diagram-action">
                    <div className="diagram-item-name">Feedback</div>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-inline-block pd-x-20 pd-y-10 rounded-30 bg-orange tx-white">
                  <Download size={16} className="mg-r-5" />
                  Download
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="card bd-primary">
                    <div className="card-header bg-primary tx-white">
                      <MessageCircle />
                      Team channel
                    </div>
                    <div className="card-body">&nbsp;</div>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="card bd-purple">
                    <div className="card-header bg-purple tx-white">
                      {Icon('form')}
                      Reject
                    </div>
                    <div className="card-body">&nbsp;</div>
                  </div>
                  <div className="site-direction">Rejected</div>
                  <div className="diagram-item diagram-status">
                    <div className="diagram-item-name">Lost</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="modal-footer d-flex">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteLinks;
