import { ChevronUp, X } from 'react-feather';
import { Button, Input, Select } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import Icon from '@base/assets/icons/svg-icons';
import { Address, Email, Phone, Website } from '@customer/customer/components';
import { useState } from 'react';

const NewLead = () => {
  const [showAddContact, setShowAddContact] = useState(false);
  return (
    <>
      <div id="newLeadModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">New Lead</h1>
              <Button close data-dismiss="modal" tooltip="닫기" />
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-item-title">Title</label>
                <Input />
              </div>
              <div className="form-group-title">Contact</div>
              <div className="form-group">
                <label className="form-item-title">Name</label>
                <Input />
              </div>
              <div className="form-group">
                <label className="form-item-title">Email</label>
                <Email />
              </div>
              <div className="form-group">
                <label className="form-item-title">Telephone</label>
                <Phone />
              </div>
              {showAddContact && (
                <>
                  <hr className="hr-dotted" />
                  <div className="form-group">
                    <label className="form-item-title">Name</label>
                    <Input />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Email</label>
                    <Email />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Telephone</label>
                    <Phone />
                  </div>
                  <Button
                    color="link"
                    size="sm"
                    icon="Minus"
                    name="Delete"
                    className="mg-r-10 pd-x-0 tx-danger"
                    onClick={() => setShowAddContact(false)}
                  />
                </>
              )}
              <Button
                color="link"
                size="sm"
                icon="Plus"
                name="Add"
                className="pd-x-0"
                onClick={() => setShowAddContact(true)}
              />
              <div className="form-group-title">Company</div>
              <div className="form-group">
                <label className="form-item-title">Name</label>
                <Input />
              </div>
              <div className="form-group">
                <label className="form-item-title">Website</label>
                <Website />
              </div>
              <div className="form-group">
                <label className="form-item-title">Industry</label>
                <Select className="wd-300-f" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Address</label>
                <Address />
              </div>
              <div className="form-group-title">Lead Process</div>
              <div className="form-group">
                <label className="form-item-title">Name</label>
                <Select />
              </div>
              <div className="form-group">
                <label className="form-item-title">Owner</label>
                <UserAutoComplete single={true} />
              </div>
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <Input />
              </div>
              <div className="form-group-title">Lead Source</div>
              <div className="form-group">
                <label className="form-item-title">Campaign</label>
                <Select />
              </div>
              <div className="form-group">
                <label className="form-item-title">Campaign Activity</label>
                <Select />
              </div>
              <div className="form-group">
                <label className="form-item-title">Distribution</label>
                <Select />
              </div>
              <hr />
              <div className="form-group">
                <label className="form-item-title">Collection Method</label>
                <Select />
              </div>
              <div className="form-group">
                <label className="form-item-title">Assigned Rep</label>
                <UserAutoComplete single={true} />
              </div>
              <div className="form-group">
                <label className="form-item-title">Product</label>
                <div className="input-group">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="form-control-tag-name">Product A</span>
                      <button type="button" className="btn pd-0 form-control-tag-remove">
                        <X />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250"
                      placeholder="Type or click to select a product"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a product"
                    >
                      {Icon('product')}
                      <span className="sr-only">상품 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
                Close
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLead;
