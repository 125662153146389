import { X, Plus, ChevronUp } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

const NewProductVendor = () => {
  return (
    <div
      id="newVendorModal"
      className="modal fade right product-right-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">New Vendor</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f">
            <div className="form-group">
              <label className="form-item-title">Vendor Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Preferred</label>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="preferredVendor" />
                <label className="custom-control-label" htmlFor="preferredVendor"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Vendor Product Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Linked Barcode</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Delivery Lead Time</label>
              <div className="d-flex">
                <input type="number" className="form-control form-control-lg wd-150 mg-r-5" />
                <SelectHook className="wd-150-f" options={[{ value: 1, label: 'days' }]} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Minimum Quantity</label>
              <div className="d-flex">
                <input type="number" className="form-control form-control-lg wd-150 mg-r-5" />
                <SelectHook className="wd-150-f" options={[{ value: 1, label: 'box' }]} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Purchase Unit Price</label>
              <div className="input-group wd-200">
                <div className="input-group-prepend">
                  <span className="input-group-text bd-gray-400 bd-r-0 lh-1">￦</span>
                </div>
                <input type="number" className="form-control bd-l-0" />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Vendor Price List</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="vendorPricelistSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="vendorPricelistSwitch1"></label>
              </div>
              <div className="table-responsive bd rounded mg-t-10">
                <table className="table table-bordered mg-b-0 bd-0">
                  <thead>
                    <tr className="bg-light">
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Quantity
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Discount
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <input type="number" className="form-control wd-100" />
                          <span className="mg-x-5">~</span>
                          <input type="number" className="form-control wd-100" />
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <input type="number" className="form-control" />
                          <div className="input-group-append">
                            <span className="input-group-text lh-1">%</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button type="button" className="btn btn-link btn-icon pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button type="button" className="btn btn-link">
                <Plus className="mg-r-t" />
                Add a row
              </button>
            </div>
            <div className="form-group">
              <label className="form-item-title">Payment Term</label>
              <SelectHook options={[{ value: 1, label: '10 days' }]} />
            </div>
            <div className="form-group">
              <label className="form-item-title">Tax</label>
              <div className="input-group wd-150">
                <input type="number" className="form-control" />
                <div className="input-group-append">
                  <span className="input-group-text lh-1">%</span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Validity </label>
              <div className="d-flex align-items-center">
                <DatePicker
                  selected={new Date()}
                  onChange={() => {
                    console.log();
                  }}
                />

                <span className="mg-x-5">~</span>
                <DatePicker
                  selected={new Date()}
                  onChange={() => {
                    console.log();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group">
              <button type="button" className="btn btn-success">
                Add
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#">
                  Add and Apply
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductVendor;
