import React from 'react';
import { X, User, Plus, Minus, HelpCircle } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "InventoryCss/inventory.scss";

const Write = () => {
  return (
    <div className="ht-100p overflow-auto">
      <div className="tx-right">
        <span className="tx-danger">*</span> is required.
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Warehouse Name<span className="tx-danger">*</span>
        </label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Warehouse Simple Name<span className="tx-danger">*</span>
        </label>
        <input type="text" className="form-control wd-200" />
        <div className="mg-t-10 tx-orange">
          바코드 생성을 위해 알파벳 숫자 이용 e.g. B01, 중복 등록 불가
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Priority<span className="tx-danger">*</span>
        </label>
        <SelectHook className="wd-200-f" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Assigned Rep.</label>
        <div className="input-group">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="d-block avatar avatar-xs mg-r-10">
                <img
                  src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                  alt="John"
                  className="img rounded-circle"
                />
              </span>
              <span className="form-control-tag-name">
                John<span className="tx-gray-500">(Sales)</span>
              </span>
              <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                <X />
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250 rounded"
              placeholder="Type or click to select a user"
            />
          </div>
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open modal to select a user">
              <User />
              <span className="sr-only">담당자 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Active</label>
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id="warehouseActive" />
          <label className="custom-control-label" htmlFor="warehouseActive"></label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Address</label>
      </div>
      <div className="form-group">
        <label className="form-item-title">Phone</label>
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Locations<span className="tx-danger">*</span>
        </label>
        <div className="tx-orange">Warehouse 생성 후 Location 세부 정보 입력 가능합니다.</div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            Building
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck2"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="customCheck2">
            Floor
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck3"
            defaultChecked
            disabled
          />
          <label className="custom-control-label" htmlFor="customCheck3">
            Storage Zone<span className="tx-danger">*</span>
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck4"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="customCheck4">
            Row
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck5"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="customCheck5">
            Rack
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck6"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="customCheck6">
            Level
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck7"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="customCheck7">
            Bin
          </label>
        </div>
        <div className="table-responsive mg-t-10">
          <table className="table table-bordered mg-b-0 warehouse-locations-tb">
            <thead>
              <tr>
                <th scope="colgroup">Building</th>
                <th scope="colgroup">Floor</th>
                <th scope="colgroup" colSpan={8}>
                  Storage Zone
                </th>
              </tr>
              <tr>
                <th scope="col">
                  Name<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  Name<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  Name<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  Priority<span className="tx-danger">*</span>
                  <span
                    className="tx-color-03"
                    data-han-tooltip="동일한 아이템 재고가 2개 이상의 위치에 있을 경우, 우선 순위에 따라 자동 할당됩니다."
                  >
                    <HelpCircle size="16" />
                  </span>
                </th>
                <th scope="col">
                  Replen Only<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  No. of Row<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  No. of Rack<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  No. of Level<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  No. of Bin<span className="tx-danger">*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column wd-150">
                      <label className="mg-b-5 tx-color-03">Name</label>
                      <input type="text" className="form-control mg-b-10" />
                      <label className="mg-b-5 tx-color-03">
                        Simple Name
                        <span
                          className="tx-color-03 mg-l-5 han-tooltip--right"
                          data-han-tooltip="바코드 생성을 위해 알파벳 숫자 이용 e.g. B01, 중복 등록 불가"
                        >
                          <HelpCircle size="16" />
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="알파벳, 숫자만 가능"
                      />
                    </div>
                    <button type="button" className="btn btn-link btn-icon mg-l-10">
                      <Plus />
                      <span className="sr-only">추가</span>
                    </button>
                    <button type="button" className="btn btn-icon">
                      <Minus className="tx-danger" />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </td>
                <td rowSpan={2}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column wd-150">
                      <label className="mg-b-5 tx-color-03">Name</label>
                      <input type="text" className="form-control mg-b-10" />
                      <label className="mg-b-5 tx-color-03">
                        Simple Name
                        <span
                          className="tx-color-03 mg-l-5 han-tooltip--right"
                          data-han-tooltip="바코드 생성을 위해 알파벳 숫자 이용 e.g. B01, 중복 등록 불가"
                        >
                          <HelpCircle size="16" />
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="알파벳, 숫자만 가능"
                      />
                    </div>
                    <button type="button" className="btn btn-link btn-icon mg-l-10">
                      <Plus />
                      <span className="sr-only">추가</span>
                    </button>
                    <button type="button" className="btn btn-icon">
                      <Minus className="tx-danger" />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column wd-150">
                      <label className="mg-b-5 tx-color-03">Name</label>
                      <input type="text" className="form-control mg-b-10" />
                      <label className="mg-b-5 tx-color-03">
                        Simple Name
                        <span
                          className="tx-color-03 mg-l-5 han-tooltip--right"
                          data-han-tooltip="바코드 생성을 위해 알파벳 숫자 이용 e.g. B01, 중복 등록 불가"
                        >
                          <HelpCircle size="16" />
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="알파벳, 숫자만 가능"
                      />
                    </div>
                    <button type="button" className="btn btn-link btn-icon mg-l-10">
                      <Plus />
                      <span className="sr-only">추가</span>
                    </button>
                    <button type="button" className="btn btn-icon">
                      <Minus className="tx-danger" />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </td>
                <td>
                  <SelectHook className="wd-100-f" />
                </td>
                <td>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="customSwitch1"></label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio1-1"
                      name="customRadio1"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="customRadio1-1">
                      Whole Storage Zone
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio1-2"
                      name="customRadio1"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="customRadio1-2">
                      Set by Sublocation
                    </label>
                  </div>
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column wd-150">
                      <label className="mg-b-5 tx-color-03">Name</label>
                      <input type="text" className="form-control mg-b-10" />
                      <label className="mg-b-5 tx-color-03">
                        Simple Name
                        <span
                          className="tx-color-03 mg-l-5 han-tooltip--right"
                          data-han-tooltip="바코드 생성을 위해 알파벳 숫자 이용 e.g. B01, 중복 등록 불가"
                        >
                          <HelpCircle size="16" />
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="알파벳, 숫자만 가능"
                      />
                    </div>
                    <button type="button" className="btn btn-link btn-icon mg-l-10">
                      <Plus />
                      <span className="sr-only">추가</span>
                    </button>
                    <button type="button" className="btn btn-icon">
                      <Minus className="tx-danger" />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </td>
                <td>
                  <SelectHook className="wd-100-f" />
                </td>
                <td>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch2"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="customSwitch2"></label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio2-1"
                      name="customRadio2"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="customRadio2-1">
                      Whole Storage Zone
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio2-2"
                      name="customRadio2"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="customRadio2-2">
                      Set by Sublocation
                    </label>
                  </div>
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Write;
