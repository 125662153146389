import React, { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';

import {
  ChevronDown,
  Star,
  Plus,
  Calendar,
  Inbox,
  Check,
  ArrowRight,
  X,
  User,
} from 'react-feather';

// import { Doughnut } from 'react-chartjs-2';

import ActivityList from './modal/activityList';

// let originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
// Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
//   draw: function () {
//     originalDoughnutDraw.apply(this, arguments);

//     let {chart} = this.chart;
//     let {ctx} = chart;
//     let {height} = chart;
//     var color = chart.config.data.datasets.backgroundColor;;

//     let fontSize = (height / 114).toFixed(2);
//     ctx.font = fontSize + 'em Verdana';
//     ctx.textAlign = 'center';
//     ctx.textBaseline = 'middle';

//     let {text} = chart.config.data,
//       textX = (chart.chartArea.left + chart.chartArea.right) / 2,
//       textY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

//     ctx.fillStyle = color;
//     ctx.fillText(text, textX, textY);
//   },
// });

const Dashboard = () => {
  const [chartActivityType, setChartActivityType] = useState<any>({
    my: true,
    group: false,
  });

  const [activityType, setActivityType] = useState<any>({
    my: true,
    group: false,
  });

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            data-han-tooltip="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Dashboard</h1>
          <div className="dropdown mg-l-5">
            <button type="button" className="btn btn-xs btn-white btn-icon" data-toggle="dropdown">
              <ChevronDown />
              <span className="sr-only">변경할 메뉴 열기</span>
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/mywork/desktop/theme1/dashboard">Dashboard</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  data-han-tooltip="Set as default"
                >
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/activity/desktop/theme1/list">Activity</a>
              </div>
            </div>
          </div>
          <div className="dropdown mg-l-auto">
            <button
              type="button"
              className="btn btn-primary pd-x-10"
              data-toggle="modal"
              data-target="#newAccount"
            >
              <Plus />
              <span className="sr-only">추가</span>
              <ChevronDown />
            </button>
          </div>
        </div>

        <div className="list-body">
          <div className="row">
            <div className="col-lg-8">
              <div className="row row-xs mg-b-20">
                <div className="col-md-6 col-xl-3 d-flex">
                  <div className="d-flex flex-grow-1 rounded-10 pd-10 bg-primary tx-white">
                    <div className="d-flex justify-content-center align-items-center pd-10 rounded-10 activity-dashboard-icon">
                      <Inbox />
                    </div>
                    <div className="d-flex flex-grow-1 flex-column justify-content-center mg-l-15">
                      <button
                        type="button"
                        className="btn pd-0 text-left text-white tx-24 tx-semibold lh-1"
                        data-toggle="modal"
                        data-target="#activityListModal"
                      >
                        12
                      </button>
                      <span className="tx-12">New Activities</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3 mg-t-10 mg-md-t-0 d-flex">
                  <div className="d-flex flex-grow-1 rounded-10 pd-10 bg-info tx-white">
                    <div className="d-flex justify-content-center align-items-center pd-10 rounded-10 activity-dashboard-icon">
                      {Icons('activity')}
                    </div>
                    <div className="d-flex flex-grow-1 flex-column justify-content-center mg-l-15">
                      <button
                        type="button"
                        className="btn pd-0 text-left text-white tx-24 tx-semibold lh-1"
                        data-toggle="modal"
                        data-target="#activityListModal"
                      >
                        5
                      </button>
                      <span className="tx-12">Open Activities</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3 mg-t-10 mg-xl-t-0 d-flex">
                  <div className="d-flex flex-grow-1 rounded-10 pd-10 bg-purple tx-white">
                    <div className="d-flex justify-content-center align-items-center pd-10 rounded-10 activity-dashboard-icon">
                      <Check />
                    </div>
                    <div className="d-flex flex-grow-1 flex-column justify-content-center mg-l-15">
                      <button
                        type="button"
                        className="btn pd-0 text-left text-white tx-24 tx-semibold lh-1"
                        data-toggle="modal"
                        data-target="#activityListModal"
                      >
                        5
                      </button>
                      <span className="tx-12">Close Activities</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3 mg-t-10 mg-xl-t-0 d-flex">
                  <div className="d-flex flex-grow-1 rounded-10 pd-10 bg-pink tx-white">
                    <div className="d-flex justify-content-center align-items-center pd-10 rounded-10 activity-dashboard-icon">
                      <Calendar />
                    </div>
                    <div className="d-flex flex-grow-1 flex-column justify-content-center mg-l-15">
                      <button
                        type="button"
                        className="btn pd-0 text-left text-white tx-24 tx-semibold lh-1"
                        data-toggle="modal"
                        data-target="#activityListModal"
                      >
                        17
                      </button>
                      <span className="tx-12">Overdue Activities</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mg-b-20">
                <div className="col-xl-5">
                  {/* Upcoming Activities */}
                  <div className="dashboard-section ht-100p">
                    <div className="dashboard-section-header">
                      <span className="dashboard-section-title">Today Activities</span>
                      <button
                        type="button"
                        className="btn btn-link mg-l-auto pd-0 tx-12"
                        data-toggle="modal"
                        data-target="#activityListModal"
                      >
                        View All
                      </button>
                    </div>
                    <div
                      className="dashboard-section-body overflow-y-auto"
                      style={{ maxHeight: '250px' }}
                    >
                      <div className="d-flex align-items-center pd-10 bg-light rounded-10 hover-link">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt=""
                          />
                        </div>
                        <div
                          className="flex-grow-1 mg-l-15"
                          style={{ maxWidth: 'calc(100% - 99px)' }}
                        >
                          <div className="text-truncate tx-semibold">Activity Name 1</div>
                          <div className="tx-color-03">09:00 am - 11:00 am</div>
                        </div>
                        <a
                          href=""
                          target="_blank"
                          className="btn btn-link flex-shrink-0"
                          title="새창으로 열림"
                        >
                          <ArrowRight />
                          <span className="sr-only">보러 가기</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center pd-10 bg-light rounded-10 hover-link mg-t-10">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                            className="rounded-circle"
                            alt=""
                          />
                        </div>
                        <div
                          className="flex-grow-1 mg-l-15"
                          style={{ maxWidth: 'calc(100% - 99px)' }}
                        >
                          <div className="text-truncate tx-semibold">Meeting with Boss</div>
                          <div className="tx-color-03">12:00 pm - 01:00 pm</div>
                        </div>
                        <a
                          href=""
                          target="_blank"
                          className="btn btn-link flex-shrink-0"
                          title="새창으로 열림"
                        >
                          <ArrowRight />
                          <span className="sr-only">보러 가기</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center pd-10 bg-light rounded-10 hover-link mg-t-10">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                            className="rounded-circle"
                            alt=""
                          />
                        </div>
                        <div
                          className="flex-grow-1 mg-l-15"
                          style={{ maxWidth: 'calc(100% - 99px)' }}
                        >
                          <div className="text-truncate tx-semibold">Activity Name 2</div>
                          <div className="tx-color-03">09:00 am - 11:00 am</div>
                        </div>
                        <a
                          href=""
                          target="_blank"
                          className="btn btn-link flex-shrink-0"
                          title="새창으로 열림"
                        >
                          <ArrowRight />
                          <span className="sr-only">보러 가기</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 mg-t-20 mg-xl-t-0">
                  {/* Activities */}
                  <div className="dashboard-section ht-100p">
                    <div className="dashboard-section-header">
                      <span className="dashboard-section-title">Activities</span>
                      <button
                        type="button"
                        className="btn btn-link mg-l-auto pd-0 tx-12"
                        data-toggle="modal"
                        data-target="#activityListModal"
                      >
                        View All
                      </button>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="nav nav-tabs bd-0 dashboard-tabs">
                        <button
                          type="button"
                          className={classnames('btn nav-link', { active: activityType.my })}
                          onClick={() => setActivityType({ my: true })}
                        >
                          My Activities
                        </button>
                        <button
                          type="button"
                          className={classnames('btn nav-link', { active: activityType.group })}
                          onClick={() => setActivityType({ group: true })}
                        >
                          Group Activities
                        </button>
                      </div>
                      <div className="dropdown mg-l-auto">
                        <button type="button" className="btn pd-0" data-toggle="dropdown">
                          Sort by: Date
                          <ChevronDown className="mg-l-5 " />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                          <button type="button" className="dropdown-item">
                            Priority
                          </button>
                          <button type="button" className="dropdown-item">
                            Due Date
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="dashboard-section-body overflow-y-auto pd-y-0-f"
                      style={{ maxHeight: '205px' }}
                    >
                      {activityType.my && (
                        <>
                          <div className="d-flex flex-column pd-y-12">
                            <div className="d-flex align-items-center">
                              <span className="badge badge-danger">Urgent</span>
                              <span
                                className="mg-l-auto pd-x-10 pd-y-3 rounded-30 lh-1 tx-13"
                                style={{
                                  backgroundColor: 'rgba(247, 114, 109, .3)',
                                  color: '#f7726d',
                                }}
                              >
                                Status 1
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <a
                                href=""
                                target="_blank"
                                className="mg-r-10 link-02 text-truncate"
                                title="새창으로 열림"
                              >
                                Pricing page design
                              </a>
                              <span className="mg-l-auto text-nowrap tx-13 tx-color-03">
                                2021-12-23
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-column pd-y-12 bd-t">
                            <div className="d-flex align-items-center">
                              <span className="badge bg-orange tx-white">High</span>
                              <span
                                className="mg-l-auto pd-x-10 pd-y-3 rounded-30 lh-1 tx-13"
                                style={{
                                  backgroundColor: 'rgba(249, 175, 40, .3)',
                                  color: '#ffa100',
                                }}
                              >
                                Status 2
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <a
                                href=""
                                target="_blank"
                                className="mg-r-10 link-02 text-truncate"
                                title="새창으로 열림"
                              >
                                Fix buttons
                              </a>
                              <span className="mg-l-auto text-nowrap tx-13 tx-color-03">
                                2021-12-23
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-column pd-y-12 bd-t">
                            <div className="d-flex align-items-center">
                              <span className="badge badge-info">Normal</span>
                              <span
                                className="mg-l-auto pd-x-10 pd-y-3 rounded-30 lh-1 tx-13"
                                style={{
                                  backgroundColor: 'rgba(106, 204, 0, .3)',
                                  color: '#6acc00',
                                }}
                              >
                                Status 3
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <a
                                href=""
                                target="_blank"
                                className="mg-r-10 link-02 text-truncate"
                                title="새창으로 열림"
                              >
                                Contact form
                              </a>
                              <span className="mg-l-auto text-nowrap tx-13 tx-color-03">
                                2021-12-23
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {activityType.group && (
                        <>
                          <div className="d-flex pd-y-12">
                            <div className="avatar flex-shrink-0">
                              <img
                                src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                                className="rounded-circle"
                                alt=""
                              />
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mg-l-10">
                              <div className="d-flex align-items-center">
                                <span className="badge badge-danger">Urgent</span>
                                <span
                                  className="dashboard-group-name-label mg-l-5"
                                  style={{ color: '#f90' }}
                                >
                                  Group 1
                                </span>
                                <span
                                  className="mg-l-auto pd-x-10 pd-y-3 rounded-30 lh-1 tx-13"
                                  style={{
                                    backgroundColor: 'rgba(247, 114, 109, .3)',
                                    color: '#f7726d',
                                  }}
                                >
                                  Status 1
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href=""
                                  target="_blank"
                                  className="mg-r-10 link-02 text-truncate"
                                  title="새창으로 열림"
                                >
                                  Pricing page design
                                </a>
                                <span className="mg-l-auto text-nowrap tx-13 tx-color-03">
                                  2021-12-23
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex pd-y-12 bd-t">
                            <div className="avatar flex-shrink-0">
                              <img
                                src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                                className="rounded-circle"
                                alt=""
                              />
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mg-l-10">
                              <div className="d-flex align-items-center">
                                <span className="badge bg-orange tx-white">High</span>
                                <span
                                  className="dashboard-group-name-label mg-l-5"
                                  style={{ color: '#f60' }}
                                >
                                  Group 2
                                </span>
                                <span
                                  className="mg-l-auto pd-x-10 pd-y-3 rounded-30 lh-1 tx-13"
                                  style={{
                                    backgroundColor: 'rgba(249, 175, 40, .3)',
                                    color: '#ffa100',
                                  }}
                                >
                                  Status 2
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href=""
                                  target="_blank"
                                  className="mg-r-10 link-02 text-truncate"
                                  title="새창으로 열림"
                                >
                                  Fix buttons
                                </a>
                                <span className="mg-l-auto text-nowrap tx-13 tx-color-03">
                                  2021-12-23
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex pd-y-12 bd-t">
                            <div className="avatar flex-shrink-0">
                              <img
                                src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                                className="rounded-circle"
                                alt=""
                              />
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mg-l-10">
                              <div className="d-flex align-items-center">
                                <span className="badge badge-info">Normal</span>
                                <span
                                  className="dashboard-group-name-label mg-l-5"
                                  style={{ color: '#9000ff' }}
                                >
                                  Group 3
                                </span>
                                <span
                                  className="mg-l-auto pd-x-10 pd-y-3 rounded-30 lh-1 tx-13"
                                  style={{
                                    backgroundColor: 'rgba(106, 204, 0, .3)',
                                    color: '#6acc00',
                                  }}
                                >
                                  Status 3
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href=""
                                  target="_blank"
                                  className="mg-r-10 link-02 text-truncate"
                                  title="새창으로 열림"
                                >
                                  Contact form
                                </a>
                                <span className="mg-l-auto text-nowrap tx-13 tx-color-03">
                                  2021-12-23
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Activities Progress Rate */}
              <div className="dashboard-section">
                <div className="dashboard-section-header">
                  <span className="dashboard-section-title">Activities Count</span>
                </div>
                <div className="d-flex align-items-center">
                  <div className="nav nav-tabs bd-b-0 dashboard-tabs">
                    <button
                      type="button"
                      className={classnames('btn nav-link', { active: chartActivityType.my })}
                      onClick={() => setChartActivityType({ my: true })}
                    >
                      My Activities
                    </button>
                    <button
                      type="button"
                      className={classnames('btn nav-link', { active: chartActivityType.group })}
                      onClick={() => setChartActivityType({ group: true })}
                    >
                      Group Activities
                    </button>
                  </div>
                  <div className="dropdown mg-l-auto">
                    <button type="button" className="btn pd-0" data-toggle="dropdown">
                      Today
                      <ChevronDown className="mg-l-5 " />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <button type="button" className="dropdown-item">
                        This Week
                      </button>
                      <button type="button" className="dropdown-item">
                        This Month
                      </button>
                    </div>
                  </div>
                </div>
                <div className="dashboard-section-body">
                  {chartActivityType.my && (
                    <>
                      <div className="row no-gutters">
                        <div className="col-md-6 col-lg-4 pd-y-10 bd-b bd-lg-b-0">
                          {/* <Doughnut
                            data={data}
                            options={{
                              legend: {
                                labels: { usePointStyle: true, poitStyle: 'circle' },
                                position: 'right',
                              },
                              cutoutPercentage: 80,
                            }}
                          /> */}
                        </div>
                        <div className="col-md-6 col-lg-4 pd-y-10 bd-b bd-lg-b-0 bd-md-l">
                          {/* <Doughnut
                            data={data2}
                            options={{
                              legend: {
                                labels: { usePointStyle: true, poitStyle: 'circle' },
                                position: 'right',
                              },
                              cutoutPercentage: 80,
                            }}
                          /> */}
                        </div>
                        <div className="col-md-6 col-lg-4 pd-y-10 bd-lg-l">
                          {/* <Doughnut
                            data={data3}
                            options={{
                              legend: {
                                labels: { usePointStyle: true, poitStyle: 'circle' },
                                position: 'right',
                              },
                              cutoutPercentage: 80,
                            }}
                          /> */}
                        </div>
                      </div>
                    </>
                  )}
                  {chartActivityType.group && (
                    <>
                      <div className="input-group mg-b-10">
                        <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                          <div className="form-control-tag">
                            {/* <span className="d-block avatar avatar-xs mg-r-10">
                              <img src="https://global3.hanbiro.com/ncrm/user/photo/1/1" alt="John" className="img rounded-circle" />
                            </span>
                            <span className="form-control-tag-name">John</span> */}
                            <span className="form-control-tag-name">All</span>
                            <button
                              type="button"
                              className="btn pd-0 form-control-tag-remove"
                              aria-label="삭제"
                            >
                              <X />
                            </button>
                          </div>
                          <input
                            type="text"
                            className="bd-0 flex-grow-1 mn-wd-250"
                            placeholder="Type or click to select an user"
                          />
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a user"
                          >
                            <User />
                            <span className="sr-only">담당자 선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive rounded">
                        <table className="table table-bordered bg-white bd-0 group-act-count-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bg-light text-nowrap">
                                Name
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                Task
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                Meeting
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                Call
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                SMS
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                Fax
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                DM
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                Email
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                Avg. Close
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                Avg. Close Time
                              </th>
                              <th scope="col" className="bg-light text-nowrap">
                                Avg. Duration
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="avg-count">
                              <th scope="row">Average</th>
                              <td>23</td>
                              <td>6</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>4:41:15</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <th scope="row">MSM</th>
                              <td>20</td>
                              <td>10</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <th scope="row">MSR</th>
                              <td>20</td>
                              <td>10</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dashboard-section mg-b-20">
                <div className="dashboard-section-body">Calendar</div>
              </div>

              {/* Assigned Rep */}
              <div className="dashboard-section">
                <div className="dashboard-section-header">
                  <span className="dashboard-section-title">Assigned Rep</span>
                </div>
                <div
                  className="dashboard-section-body pd-y-0-f overflow-auto"
                  style={{ maxHeight: '250px' }}
                >
                  <div className="d-flex pd-y-10">
                    <div className="wd-90">
                      <div className="img-group img-group-mutual justify-content-start">
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                          className="img rounded-circle"
                          alt=""
                        />
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                          className="img rounded-circle"
                          alt=""
                        />
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                          className="img rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="text-center tx-color-03">5 Members</div>
                    </div>
                    <div className="d-flex flex-column justify-content-around mg-l-10">
                      <div className="tx-16">Group 1</div>
                      <div className="d-flex align-items-center" style={{ color: '#f90' }}>
                        <i className="fas fa-circle mg-r-5 tx-10"></i>4 Activities
                      </div>
                    </div>
                  </div>
                  <div className="d-flex pd-y-10 bd-t">
                    <div className="wd-90">
                      <div className="img-group img-group-mutual justify-content-start">
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                          className="img rounded-circle"
                          alt=""
                        />
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                          className="img rounded-circle"
                          alt=""
                        />
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                          className="img rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="text-center tx-color-03">12 Members</div>
                    </div>
                    <div className="d-flex flex-column justify-content-around mg-l-10">
                      <div className="tx-16">Group 2</div>
                      <div className="d-flex align-items-center" style={{ color: '#f60' }}>
                        <i className="fas fa-circle mg-r-5 tx-10"></i>7 Activities
                      </div>
                    </div>
                  </div>
                  <div className="d-flex pd-y-10 bd-t">
                    <div className="wd-90">
                      <div className="img-group img-group-mutual justify-content-start">
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                          className="img rounded-circle"
                          alt=""
                        />
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                          className="img rounded-circle"
                          alt=""
                        />
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                          className="img rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="text-center tx-color-03">9 Members</div>
                    </div>
                    <div className="d-flex flex-column justify-content-around mg-l-10">
                      <div className="tx-16">Group 3</div>
                      <div className="d-flex align-items-center" style={{ color: '#9000ff' }}>
                        <i className="fas fa-circle mg-r-5 tx-10"></i>5 Activities
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ActivityList />
    </>
  );
};

export default Dashboard;
