import { Edit2, X, Plus, Zap, Link } from 'react-feather';
import { Checkbox } from '@base/components/form';

type propTypes = {
  showEdit: any;
  setShowEdit: Function;
  showDetail: any;
  setShowDetail: Function;
};

const PurchaseOrder = (props: propTypes) => {
  return (
    <div className="d-flex flex-column flex-grow-1 pd-20 overflow-auto">
      <div className="stage-wrap">
        <div className="stage-item stage-open">
          <div data-han-tooltip="Open">
            <div className="text-truncate">Open Purchase Order</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-xs btn-outline-primary btn-icon btn-add-stage"
                  data-han-tooltip="Add new Stage"
                  data-toggle="modal"
                  data-target="#addProcessStage"
                >
                  <Plus />
                  <span className="sr-only">Add</span>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="stage-item">
          <div data-han-tooltip="Approval">
            <div className="text-truncate">Approval</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                  <span className="sr-only">Delete</span>
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-outline-primary btn-icon btn-add-stage"
                  data-han-tooltip="Add new Stage"
                  data-toggle="modal"
                  data-target="#addProcessStage"
                >
                  <Plus />
                  <span className="sr-only">Add</span>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="stage-item">
          <div data-han-tooltip="Send by Email">
            <div className="text-truncate">Send by Email</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                  <span className="sr-only">Delete</span>
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-outline-primary btn-icon btn-add-stage"
                  data-han-tooltip="Add new Stage"
                  data-toggle="modal"
                  data-target="#addProcessStage"
                >
                  <Plus />
                  <span className="sr-only">Add</span>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="stage-item" style={{ width: '660px' }}>
          <div data-han-tooltip="Received Invoice and Goods">
            <div className="text-truncate">Received Invoice and Goods</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                  <span className="sr-only">Delete</span>
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-outline-primary btn-icon btn-add-stage"
                  data-han-tooltip="Add new Stage"
                  data-toggle="modal"
                  data-target="#addProcessStage"
                >
                  <Plus />
                  <span className="sr-only">Add</span>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="stage-item">
          <div data-han-tooltip="Check Items Purchased">
            <div className="text-truncate">Check Items Purchased</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                  <span className="sr-only">Delete</span>
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-outline-primary btn-icon btn-add-stage"
                  data-han-tooltip="Add new Stage"
                  data-toggle="modal"
                  data-target="#addProcessStage"
                >
                  <Plus />
                  <span className="sr-only">Add</span>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="stage-item stage-closed">
          <div data-han-tooltip="Closed">
            <div className="text-truncate">Closed</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="pos-relative flex-grow-1">
        <div className="diagram-item-wrap" style={{ top: '5px' }}>
          <div className="diagram-item diagram-trigger">
            <div className="diagram-item-header">
              <span className="diagram-item-name">
                <Zap size={14} className="mg-r-5" />
                Record Created
              </span>
            </div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '130px' }}>
          <div
            className="diagram-item automatic diagram-action han-tooltip--top"
            data-han-tooltip="New PO"
          >
            <div className="diagram-item-name">New PO</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-other-process han-tooltip--top"
                  data-han-tooltip="Other Process"
                  style={{
                    left: '50%',
                    bottom: '100%',
                    marginBottom: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add other process</span>
                </button>
              </>
            )}
          </div>
          <div className={`diagram-direction-forward ${props.showEdit ? 'is-edit' : ''}`}>
            {!props.showEdit && <div className="diagram-direction-text">Submitted</div>}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-add btn-split han-tooltip--top"
                  data-toggle="dropdown"
                  data-han-tooltip="Split"
                  style={{ left: '50%', top: '50%', marginLeft: '-11px' }}
                >
                  <Plus />
                  <span className="sr-only">Add split step/status</span>
                </button>
                <div className="dropdown-menu pd-t-0">
                  <div className="dropdown-header bd-b tx-bold tx-inverse">Split</div>
                  <button type="button" className="dropdown-item">
                    Choice(Or)
                  </button>
                  <button type="button" className="dropdown-item">
                    Parallel(And)
                  </button>
                </div>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-forward han-tooltip--right"
                  data-han-tooltip="Forward"
                  style={{
                    left: '100%',
                    top: '50%',
                    marginTop: '-12px',
                    marginLeft: '15px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add forward step/status</span>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '130px', left: '260px' }}>
          <div
            className="diagram-item manual diagram-action han-tooltip--top"
            data-han-tooltip="Approval"
          >
            <div className="diagram-item-name">Approval</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-other-process han-tooltip--top"
                  data-han-tooltip="Other Process"
                  style={{
                    left: '50%',
                    bottom: '100%',
                    marginBottom: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add other process</span>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-down btn-backward"
                  data-han-tooltip="Backward"
                  style={{
                    left: '50%',
                    top: '100%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add backward step/status</span>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-down btn-middle"
                  data-han-tooltip="Middle"
                  style={{
                    left: '100%',
                    top: '100%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add middle step/status</span>
                </button>
              </>
            )}
          </div>
          <div className={`diagram-direction-forward ${props.showEdit ? 'is-edit' : ''}`}>
            {!props.showEdit && <div className="diagram-direction-text">Approved</div>}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-add btn-split han-tooltip--top"
                  data-toggle="dropdown"
                  data-han-tooltip="Split"
                  style={{ left: '50%', top: '50%', marginLeft: '-11px' }}
                >
                  <Plus />
                  <span className="sr-only">Add split step/status</span>
                </button>
                <div className="dropdown-menu pd-t-0">
                  <div className="dropdown-header bd-b tx-bold tx-inverse">Split</div>
                  <button type="button" className="dropdown-item">
                    Choice(Or)
                  </button>
                  <button type="button" className="dropdown-item">
                    Parallel(And)
                  </button>
                </div>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-forward han-tooltip--right"
                  data-han-tooltip="Forward"
                  style={{
                    left: '100%',
                    top: '50%',
                    marginTop: '-12px',
                    marginLeft: '15px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add forward step/status</span>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '130px', left: '520px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Send by Email"
          >
            <div className="diagram-item-name">Send by Email</div>
            {!props.showEdit && (
              <>
                <div className="diagram-item-status-wrap">
                  <span className="diagram-item-status">Sent</span>
                  <button type="button" className="btn" data-toggle="dropdown">
                    <Plus />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right diagram-item-status-detail">
                    <div className="diagram-item-status">Opened</div>
                    <div className="diagram-item-status">Scheduled</div>
                  </div>
                </div>
              </>
            )}
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-other-process han-tooltip--top"
                  data-han-tooltip="Other Process"
                  style={{
                    left: '50%',
                    bottom: '100%',
                    marginBottom: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add other process</span>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-down btn-backward"
                  data-han-tooltip="Backward"
                  style={{
                    left: '50%',
                    top: '100%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add backward step/status</span>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-down btn-middle"
                  data-han-tooltip="Middle"
                  style={{
                    left: '100%',
                    top: '100%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add middle step/status</span>
                </button>
              </>
            )}
          </div>
          <div className={`diagram-direction-forward ${props.showEdit ? 'is-edit' : ''}`}>
            {!props.showEdit && <div className="diagram-direction-text">Clicked</div>}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-add btn-split han-tooltip--top"
                  data-toggle="dropdown"
                  data-han-tooltip="Split"
                  style={{ left: '50%', top: '50%', marginLeft: '-11px' }}
                >
                  <Plus />
                  <span className="sr-only">Add split step/status</span>
                </button>
                <div className="dropdown-menu pd-t-0">
                  <div className="dropdown-header bd-b tx-bold tx-inverse">Split</div>
                  <button type="button" className="dropdown-item">
                    Choice(Or)
                  </button>
                  <button type="button" className="dropdown-item">
                    Parallel(And)
                  </button>
                </div>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-forward han-tooltip--right"
                  data-han-tooltip="Forward"
                  style={{
                    left: '100%',
                    top: '50%',
                    marginTop: '-12px',
                    marginLeft: '15px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add forward step/status</span>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '130px', left: '780px' }}>
          <div className="diagram-item diagram-site han-tooltip--top" data-han-tooltip="PO Site">
            <div className="diagram-item-name">PO Site</div>
            {!props.showEdit && (
              <button
                type="button"
                className="btn btn-link site-link"
                data-toggle="modal"
                data-target="#siteLinksModal"
              >
                <Link />
              </button>
            )}
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-other-process han-tooltip--top"
                  data-han-tooltip="Other Process"
                  style={{
                    left: '50%',
                    bottom: '100%',
                    marginBottom: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add other process</span>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-down btn-backward"
                  data-han-tooltip="Backward"
                  style={{
                    left: '50%',
                    top: '100%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add backward step/status</span>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-down btn-middle"
                  data-han-tooltip="Middle"
                  style={{
                    left: '100%',
                    top: '100%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add middle step/status</span>
                </button>
              </>
            )}
          </div>
          <div className={`diagram-direction-forward ${props.showEdit ? 'is-edit' : ''}`}>
            {!props.showEdit && <div className="diagram-direction-text">Signed</div>}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-add btn-split han-tooltip--top"
                  data-toggle="dropdown"
                  data-han-tooltip="Split"
                  style={{ left: '50%', top: '50%', marginLeft: '-11px' }}
                >
                  <Plus />
                  <span className="sr-only">Add split step/status</span>
                </button>
                <div className="dropdown-menu pd-t-0">
                  <div className="dropdown-header bd-b tx-bold tx-inverse">Split</div>
                  <button type="button" className="dropdown-item">
                    Choice(Or)
                  </button>
                  <button type="button" className="dropdown-item">
                    Parallel(And)
                  </button>
                </div>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-forward han-tooltip--right"
                  data-han-tooltip="Forward"
                  style={{
                    left: '100%',
                    top: '50%',
                    marginTop: '-12px',
                    marginLeft: '15px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add forward step/status</span>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '130px', left: '1040px' }}>
          <div className="diagram-item diagram-action han-tooltip--top" data-han-tooltip="Received">
            <div className="diagram-item-name">Received</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-down btn-middle"
                  data-han-tooltip="Middle"
                  style={{
                    left: '100%',
                    top: '100%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add middle step/status</span>
                </button>
              </>
            )}
          </div>
          <div className={`diagram-direction-forward ${props.showEdit ? 'is-edit' : ''}`}>
            {!props.showEdit && <div className="diagram-direction-text"></div>}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-add btn-split han-tooltip--top"
                  data-toggle="dropdown"
                  data-han-tooltip="Split"
                  style={{ left: '50%', top: '50%', marginLeft: '-11px' }}
                >
                  <Plus />
                  <span className="sr-only">Add split step/status</span>
                </button>
                <div className="dropdown-menu pd-t-0">
                  <div className="dropdown-header bd-b tx-bold tx-inverse">Split</div>
                  <button type="button" className="dropdown-item">
                    Choice(Or)
                  </button>
                  <button type="button" className="dropdown-item">
                    Parallel(And)
                  </button>
                </div>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-forward han-tooltip--right"
                  data-han-tooltip="Forward"
                  style={{
                    left: '100%',
                    top: '50%',
                    marginTop: '-12px',
                    marginLeft: '15px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add forward step/status</span>
                </button>
              </>
            )}
          </div>
          <div
            className={`diagram-direction-up diagram-direction-other-process ${
              props.showEdit ? 'is-edit' : ''
            }`}
          ></div>
          <div
            className={`diagram-direction-up diagram-direction-other-process ${
              props.showEdit ? 'is-edit' : ''
            }`}
            style={{ marginBottom: '40px' }}
          >
            {props.showEdit && (
              <button
                type="button"
                data-toggle="modal"
                data-target="#selStepTypeModal"
                className="btn btn-add btn-down btn-other-process"
                data-han-tooltip="Otherprocess"
                style={{
                  left: '0',
                  bottom: '100%',
                  marginBottom: '16px',
                  marginLeft: '-16px',
                }}
              >
                <Plus />
                <span className="sr-only">Add otherprocess step/status</span>
              </button>
            )}
          </div>

          {(props.showDetail || props.showEdit) && (
            <>
              <div
                className={`diagram-direction-vertical diagram-direction-backward ${
                  props.showEdit ? 'is-edit' : ''
                }`}
              >
                {!props.showEdit && <div className="diagram-direction-text">??</div>}
                {props.showEdit && (
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#selStepTypeModal"
                    className="btn btn-add btn-down btn-backward"
                    data-han-tooltip="Backward"
                    style={{
                      left: '50%',
                      top: '100%',
                      marginTop: '16px',
                      marginLeft: '-14px',
                    }}
                  >
                    <Plus />
                    <span className="sr-only">Add backward step/status</span>
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        <div className="diagram-item-wrap" style={{ top: '37px', left: '1160px' }}>
          <div
            className="diagram-item diagram-other-process han-tooltip--top"
            data-han-tooltip="Receipt Process"
          >
            <div className="diagram-item-name">Receipt Process</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '77px', left: '1160px' }}>
          <div
            className="diagram-item diagram-other-process han-tooltip--top"
            data-han-tooltip="Purchase Bill Process"
          >
            <div className="diagram-item-name">Purchase Bill Process</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
          </div>
        </div>

        {(props.showDetail || props.showEdit) && (
          <>
            <div className="diagram-item-wrap" style={{ top: '355px', left: '1040px' }}>
              <div
                className="diagram-item diagram-checklist han-tooltip--top not-forward"
                data-han-tooltip="Step A"
              >
                <div className="diagram-item-name">Step A</div>
                <div className="checklist-no">
                  Checklist:{' '}
                  <button type="button" className="btn" data-toggle="dropdown">
                    5
                  </button>
                  <div className="dropdown-menu dropdown-menu-right diagram-item-status-detail">
                    <Checkbox label="Checklist Name 1" />
                    <Checkbox label="Checklist Name 2" />
                  </div>
                </div>
                {props.showEdit && (
                  <div className="diagram-item-actions">
                    <button
                      type="button"
                      className="btn btn-xs btn-link link-03 btn-icon"
                      data-han-tooltip="Edit"
                      data-toggle="modal"
                      data-target="#editStepModal"
                    >
                      <Edit2 />
                    </button>
                    <button
                      type="button"
                      className="btn btn-xs btn-link link-03 btn-icon"
                      data-han-tooltip="Delete"
                    >
                      <X />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className="diagram-item-wrap" style={{ top: '110px', left: '1300px' }}>
          <div
            className="diagram-item diagram-wait han-tooltip--top"
            data-han-tooltip="Received into Stock"
          >
            <div className="diagram-item-name">Received into Stock</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
          </div>
          <div className={`diagram-direction-forward ${props.showEdit ? 'is-edit' : ''}`}>
            {!props.showEdit && <div className="diagram-direction-text"></div>}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-add btn-split han-tooltip--top"
                  data-toggle="dropdown"
                  data-han-tooltip="Split"
                  style={{ left: '50%', top: '50%', marginLeft: '-11px' }}
                >
                  <Plus />
                  <span className="sr-only">Add split step/status</span>
                </button>
                <div className="dropdown-menu pd-t-0">
                  <div className="dropdown-header bd-b tx-bold tx-inverse">Split</div>
                  <button type="button" className="dropdown-item">
                    Choice(Or)
                  </button>
                  <button type="button" className="dropdown-item">
                    Parallel(And)
                  </button>
                </div>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-forward han-tooltip--right"
                  data-han-tooltip="Forward"
                  style={{
                    left: '100%',
                    top: '50%',
                    marginTop: '-12px',
                    marginLeft: '15px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add forward step/status</span>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '110px', left: '1560px' }}>
          <div
            className="diagram-item diagram-criteria han-tooltip--top"
            data-han-tooltip="Check Items"
          >
            <div className="criteria-shape"></div>
            <div className="diagram-item-name">Check Items</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Delete"
                >
                  <X />
                </button>
              </div>
            )}
          </div>
          <div className={`diagram-direction-forward ${props.showEdit ? 'is-edit' : ''}`}>
            {!props.showEdit && <div className="diagram-direction-text"></div>}
            {props.showEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-add btn-split han-tooltip--top"
                  data-toggle="dropdown"
                  data-han-tooltip="Split"
                  style={{ left: '50%', top: '50%', marginLeft: '-11px' }}
                >
                  <Plus />
                  <span className="sr-only">Add split step/status</span>
                </button>
                <div className="dropdown-menu pd-t-0">
                  <div className="dropdown-header bd-b tx-bold tx-inverse">Split</div>
                  <button type="button" className="dropdown-item">
                    Choice(Or)
                  </button>
                  <button type="button" className="dropdown-item">
                    Parallel(And)
                  </button>
                </div>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#selStepTypeModal"
                  className="btn btn-add btn-forward han-tooltip--right"
                  data-han-tooltip="Forward"
                  style={{
                    left: '100%',
                    top: '50%',
                    marginTop: '-12px',
                    marginLeft: '15px',
                  }}
                >
                  <Plus />
                  <span className="sr-only">Add forward step/status</span>
                </button>
              </>
            )}
          </div>

          {(props.showDetail || props.showEdit) && (
            <>
              <div
                className={`diagram-direction-vertical diagram-direction-backward ${
                  props.showEdit ? 'is-edit' : ''
                }`}
              >
                {!props.showEdit && <div className="diagram-direction-text">??</div>}
                {props.showEdit && (
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#selStepTypeModal"
                    className="btn btn-add btn-down btn-backward"
                    data-han-tooltip="Backward"
                    style={{
                      left: '50%',
                      top: '100%',
                      marginTop: '16px',
                      marginLeft: '-14px',
                    }}
                  >
                    <Plus />
                    <span className="sr-only">Add backward step/status</span>
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        {(props.showDetail || props.showEdit) && (
          <>
            <div className="diagram-item-wrap" style={{ top: '375px', left: '1560px' }}>
              <div
                className="diagram-item diagram-simple han-tooltip--top"
                data-han-tooltip="Simple action"
              >
                <div className="diagram-item-name">Simple action</div>
                {props.showEdit && (
                  <div className="diagram-item-actions">
                    <button
                      type="button"
                      className="btn btn-xs btn-link link-03 btn-icon"
                      data-han-tooltip="Edit"
                      data-toggle="modal"
                      data-target="#editStepModal"
                    >
                      <Edit2 />
                    </button>
                    <button
                      type="button"
                      className="btn btn-xs btn-link link-03 btn-icon"
                      data-han-tooltip="Delete"
                    >
                      <X />
                    </button>
                  </div>
                )}
              </div>
              <div className="diagram-direction-back"></div>
              <div className="diagram-direction-back with-split" style={{ height: '50px' }}></div>
              <div
                className="diagram-direction-forward for-simple-action"
                style={{ height: '50px' }}
              ></div>
            </div>
          </>
        )}

        <div className="close-status-wrap" style={{ top: '140px', left: '1820px' }}>
          <div className="diagram-item-wrap">
            <div
              className="diagram-item diagram-status han-tooltip--top"
              data-han-tooltip="Completed"
            >
              <div className="diagram-item-name">Completed</div>
              {props.showEdit && (
                <div className="diagram-item-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#newClosedStatus"
                  >
                    <Edit2 />
                  </button>
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Delete"
                  >
                    <X />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="diagram-item-wrap">
            <div
              className="diagram-item diagram-status han-tooltip--top"
              data-han-tooltip="Canceled"
            >
              <div className="diagram-item-name">Canceled</div>
              {props.showEdit && (
                <div className="diagram-item-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#newClosedStatus"
                  >
                    <Edit2 />
                  </button>
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Delete"
                  >
                    <X />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="diagram-item-wrap">
            <div
              className="diagram-item diagram-status han-tooltip--top"
              data-han-tooltip="Deleted"
            >
              <div className="diagram-item-name">Deleted</div>
              {props.showEdit && (
                <div className="diagram-item-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#newClosedStatus"
                  >
                    <Edit2 />
                  </button>
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Delete"
                  >
                    <X />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="diagram-item-wrap">
            <div className="diagram-item diagram-status han-tooltip--top" data-han-tooltip="Merged">
              <div className="diagram-item-name">Merged</div>
              {props.showEdit && (
                <div className="diagram-item-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#newClosedStatus"
                  >
                    <Edit2 />
                  </button>
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Delete"
                  >
                    <X />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {props.showEdit && (
          <button
            type="button"
            className="btn btn-xs btn-outline-primary btn-icon mg-t-10"
            data-toggle="modal"
            data-target="#newClosedStatus"
            style={{ position: 'absolute', top: '355px', left: '1870px' }}
          >
            <Plus />
            <span className="sr-only">추가</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default PurchaseOrder;
