import React, { useState } from 'react';
import classnames from 'classnames';

import { Grid, List, Calendar, Trello } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Post = () => {
  const [postListType, setPostListType] = useState<any>({
    grid: true,
    calendar: false,
    list: false,
    kanban: false,
  });

  return (
    <div className="d-flex flex-column align-items-start ht-100p pd-x-20">
      <div className="btn-group mg-y-20">
        <button
          type="button"
          className={classnames('btn btn-sm btn-white', { active: postListType.grid })}
          onClick={() => setPostListType({ grid: true })}
        >
          <Grid />
        </button>
        <button
          type="button"
          className={classnames('btn btn-sm btn-white', { active: postListType.list })}
          onClick={() => setPostListType({ list: true })}
        >
          <List />
        </button>
        <button
          type="button"
          className={classnames('btn btn-sm btn-white', { active: postListType.calendar })}
          onClick={() => setPostListType({ calendar: true })}
        >
          <Calendar />
        </button>
        <button
          type="button"
          className={classnames('btn btn-sm btn-white', { active: postListType.kanban })}
          onClick={() => setPostListType({ kanban: true })}
        >
          <Trello />
        </button>
      </div>
      {!postListType.kanban && (
        <div className="row row-xs wd-100p flex-grow-1 overflow-hidden">
          <div className="col-8 ht-100p scroll-box">
            {postListType.grid && (
              <>
                <div
                  className="card btn-white cursor-pointer mg-b-10"
                  data-toggle="modal"
                  data-target="#postDetails"
                >
                  <div className="card-header">
                    <span className="social-icon">{Icon('facebook')}</span>
                    <strong>Hanbiro</strong>
                    <span className="mg-l-auto tx-13 tx-color-03">2022-02-10 10:00</span>
                  </div>
                  <div className="card-body">
                    Message
                    <img
                      src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="card btn-white cursor-pointer mg-b-10"
                  data-toggle="modal"
                  data-target="#postDetails"
                >
                  <div className="card-header">
                    <span className="social-icon">{Icon('twitter')}</span>
                    <strong>Hanbiro</strong>
                    <span className="mg-l-auto tx-13 tx-color-03">2022-02-10 10:00</span>
                  </div>
                  <div className="card-body">
                    Message
                    <img
                      src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </>
            )}
            {postListType.list && (
              <div className="table-responsive card mg-b-20">
                <table className="table table-bordered table-hover mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Networks
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Content
                      </th>
                      <th scope="col" className="wd-150 bd-t-0-f">
                        Post On
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr data-toggle="modal" data-target="#postDetails">
                      <td>
                        <span className="social-icon">{Icon('facebook')}</span>
                        <strong>Hanbiro</strong>
                      </td>
                      <td>
                        <div className="media">
                          <div className="avatar">
                            <img
                              src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                              className="rounded"
                            />
                          </div>
                          <div className="media-body mg-l-10 text-truncate">Message</div>
                        </div>
                      </td>
                      <td>2022-02-10 10:00</td>
                    </tr>
                    <tr data-toggle="modal" data-target="#postDetails">
                      <td>
                        <span className="social-icon">{Icon('twitter')}</span>
                        <strong>Hanbiro</strong>
                      </td>
                      <td>
                        <div className="media">
                          <div className="avatar">
                            <img
                              src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                              className="rounded"
                            />
                          </div>
                          <div className="media-body mg-l-10 text-truncate">Message</div>
                        </div>
                      </td>
                      <td>2022-02-10 10:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {postListType.calendar && <div className="card">calendar</div>}
          </div>
          <div className="col-4 ht-100p overflow-auto">
            <div className="card pd-15">
              <div className="mg-b-10 tx-13 tx-uppercase tx-color-03 tx-spacing-1">Stage</div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialStageCheckAll"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialStageCheckAll">
                  All <span className="tx-color-03">(20)</span>
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialStageCheck1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialStageCheck1">
                  Draft <span className="tx-color-03">(5)</span>
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialStageCheck2"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialStageCheck2">
                  Scheduled <span className="tx-color-03">(5)</span>
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialStageCheck3"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialStageCheck3">
                  Posted <span className="tx-color-03">(20)</span>
                </label>
              </div>

              <div className="mg-t-20 mg-b-10 tx-13 tx-uppercase tx-color-03 tx-spacing-1">
                Social Account
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialCheckAll"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialCheckAll">
                  All
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialCheck1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialCheck1">
                  <span className="social-icon">{Icon('facebook')}</span>Hanbiro
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialCheck2"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialCheck2">
                  <span className="social-icon">{Icon('twitter')}</span>Hanbiro
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      {postListType.kanban && (
        <>
          <div className="form-group">
            <label className="form-item-title d-block">Social Account</label>
            <div className="custom-control custom-checkbox d-inline-block mg-r-10">
              <input
                type="checkbox"
                className="custom-control-input"
                id="socialCheckAll"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="socialCheckAll">
                All
              </label>
            </div>
            <div className="custom-control custom-checkbox d-inline-block mg-r-10">
              <input
                type="checkbox"
                className="custom-control-input"
                id="socialCheck1"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="socialCheck1">
                <span className="social-icon">{Icon('facebook')}</span>Hanbiro
              </label>
            </div>
            <div className="custom-control custom-checkbox d-inline-block mg-r-10">
              <input
                type="checkbox"
                className="custom-control-input"
                id="socialCheck2"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="socialCheck2">
                <span className="social-icon">{Icon('twitter')}</span>Hanbiro
              </label>
            </div>
          </div>
          <div className="social-kanban">
            <div className="social-kanban-item">
              <div className="social-kanban-item-title">Draft</div>
              <div className="social-kanban-item-body">
                <div
                  className="card btn-white cursor-pointer mg-b-10 shadow-sm"
                  data-toggle="modal"
                  data-target="#postDetails"
                >
                  <div className="card-header">
                    <span className="social-icon">{Icon('facebook')}</span>
                    <strong>Hanbiro</strong>
                    <span className="mg-l-auto tx-13 tx-color-03">2022-02-10 10:00</span>
                  </div>
                  <div className="card-body">
                    Message
                    <img
                      src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="social-kanban-item">
              <div className="social-kanban-item-title">Scheduled</div>
              <div className="social-kanban-item-body">
                <div
                  className="card btn-white cursor-pointer mg-b-10 shadow-sm"
                  data-toggle="modal"
                  data-target="#postDetails"
                >
                  <div className="card-header">
                    <span className="social-icon">{Icon('twitter')}</span>
                    <strong>Hanbiro</strong>
                    <span className="mg-l-auto tx-13 tx-color-03">2022-02-10 10:00</span>
                  </div>
                  <div className="card-body">
                    Message
                    <img
                      src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="social-kanban-item">
              <div className="social-kanban-item-title">Posted</div>
              <div className="social-kanban-item-body"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Post;
