import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  Columns,
  Settings,
  Menu,
  Minus,
  Folder,
  MoreVertical,
  Edit2,
  Info,
  Check,
  X,
  ChevronLeft,
  RotateCcw,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import '../style.scss';

import Pagination from '../../../../component/desktop/pagination-new';
import NewCategory from './modal/newCategory';
import EditCategory from './modal/editCategory';
import NewFolder from './modal/newFolder';
import EditFolder from './modal/editFolder';
import NewArticle from './modal/newArticle';
import { Switch } from '@base/components/form';

const KBList = () => {
  const [showFolder, setShowFolder] = useState<any>(true);
  const [showEditCate, setShowEditCate] = useState<any>();
  const [showEditFolder, setShowEditFolder] = useState<any>();
  const [showTreeList, setShowTreeList] = useState<any>(true);
  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Knowledge Base</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/ticket/desktop/theme1/list">Ticket</a>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/knowledge-base/desktop/theme1/list">Knowledge Base</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/desk/desktop/theme1/settings">Setting</a>
              </div>
            </div>
          </div>
          <div className="button-group mg-l-auto">
            <button
              type="button"
              data-toggle="modal"
              data-target="#newArticleModal"
              className="btn btn-primary btn-icon"
              aria-label="add"
            >
              <Plus />
              <span className="sr-only">Add</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          {/* advanced search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Subject" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* column settings */}
          <div className="dropup mg-l-5">
            <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
              {Icon('column_settings')}
              <span className="sr-only">항목 설정 열기</span>
            </button>
            <div className="dropdown-menu column-settings">
              <div className="column-settings-title">Column Settings</div>
              <ul className="list-group">
                <li className="list-group-item">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="columnCheck1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="columnCheck1">
                      Subject
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon handler"
                    aria-label="change the order"
                  >
                    <Menu />
                    <span className="드래그하여 순서 변경"></span>
                  </button>
                </li>
              </ul>
              <div className="column-settings-actions">
                Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                <button type="button" className="btn btn-light mg-l-auto">
                  Close
                </button>
                <button type="button" className="btn btn-primary mg-l-5">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="list-body">
          <div className="desk-knowledgebase-list">
            {/* tree */}
            <div className={classnames('tree-list', { collapsed: !showTreeList })}>
              <div className="tree-list-header">
                <span className="text-truncate">Categories and Folders</span>
              </div>
              <div className="tree-list-body">
                <div className="alert alert-info mg-b-10 pd-10 tx-12">
                  <Info size="14" className="mg-r-5" />
                  Drag&amp;Drop으로 폴더를 이동할 수 있습니다.
                </div>
                <ul className="kb-tree list-unstyled mg-b-0">
                  <li>
                    <div className="tree-btn-wrap">
                      <button
                        type="button"
                        className={classnames('btn btn-cate pd-5', {
                          'mx-wd-100p-f': showEditCate,
                        })}
                        onClick={() => setShowFolder(!showFolder)}
                      >
                        {showFolder ? (
                          <Minus className="btn btn-white mg-r-5 pd-x-6" />
                        ) : (
                          <Plus className="btn btn-white mg-r-5 pd-x-6" />
                        )}
                        {!showEditCate && (
                          <div className="text-truncate">
                            Category 11111111111111111111111111111111(10)
                          </div>
                        )}
                      </button>
                      {showEditCate && (
                        <div className="d-flex align-items-center" style={{ zIndex: 1 }}>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Category 11111111111111111111111111111111"
                          />
                          <button type="button" className="btn btn-sm btn-icon">
                            <Check className="tx-success" />
                            <span className="sr-only">추가</span>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-link link-03 btn-icon"
                            onClick={() => setShowEditCate(false)}
                          >
                            <X />
                            <span className="sr-only">취소</span>
                          </button>
                        </div>
                      )}
                      {!showEditCate && (
                        <div className="tree-btn-actions">
                          <button
                            type="button"
                            className="btn btn-xs btn-link btn-icon han-tooltip--botttom"
                            data-han-tooltip="Add new folder"
                            data-toggle="modal"
                            data-target="#newFolderModal"
                          >
                            <Plus />
                          </button>
                          <button
                            type="button"
                            className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                            data-han-tooltip="Rename"
                            onClick={() => setShowEditCate(true)}
                          >
                            <Edit2 />
                          </button>
                          <button
                            type="button"
                            className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                            data-han-tooltip="Edit"
                            data-toggle="modal"
                            data-target="#editCategoryModal"
                          >
                            <Settings />
                          </button>
                          <button
                            type="button"
                            className="btn btn-xs btn-link link-03 btn-icon btn-move han-tooltip--bottom"
                            data-han-tooltip="Move"
                          >
                            {Icon('move')}
                          </button>
                        </div>
                      )}
                    </div>
                    {showFolder && (
                      <ul className="list-unstyled mg-b-0 mg-l-25">
                        <li>
                          <div className="tree-btn-wrap">
                            <button
                              type="button"
                              className={classnames('btn btn-folder active pd-5', {
                                'mx-wd-100p-f': showEditFolder,
                              })}
                            >
                              <Folder className="mg-r-5 tx-primary" />
                              {!showEditFolder && (
                                <div className="text-truncate">
                                  Folder 111111111111111111111111111111111(10)
                                </div>
                              )}
                              {showEditFolder && (
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="Folder 111111111111111111111111111111111"
                                  />
                                  <button type="button" className="btn btn-sm btn-icon">
                                    <Check className="tx-success" />
                                    <span className="sr-only">추가</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-link link-03 btn-icon"
                                    onClick={() => setShowEditFolder(false)}
                                  >
                                    <X />
                                    <span className="sr-only">취소</span>
                                  </button>
                                </div>
                              )}
                            </button>
                            {!showEditFolder && (
                              <div className="tree-btn-actions">
                                <button
                                  type="button"
                                  className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                                  data-han-tooltip="Rename"
                                  onClick={() => setShowEditFolder(true)}
                                >
                                  <Edit2 />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                                  data-han-tooltip="Edit"
                                  data-toggle="modal"
                                  data-target="#editFolderModal"
                                >
                                  <Settings />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-xs btn-link link-03 btn-icon btn-move han-tooltip--bottom"
                                  data-han-tooltip="Move"
                                >
                                  {Icon('move')}
                                </button>
                              </div>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="tree-btn-wrap">
                            <button type="button" className="btn btn-folder pd-5">
                              <Folder className="mg-r-5 tx-primary" />
                              <div className="text-truncate">Folder 2(10)</div>
                            </button>
                            <div className="tree-btn-actions">
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                                data-han-tooltip="Rename"
                              >
                                <Edit2 />
                              </button>
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                                data-han-tooltip="Edit"
                              >
                                <Settings />
                              </button>
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-03 btn-icon btn-move han-tooltip--bottom"
                                data-han-tooltip="Move"
                              >
                                {Icon('move')}
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <div className="tree-btn-wrap">
                      <button type="button" className="btn btn-cate pd-5">
                        <Plus className="btn btn-white mg-r-5 pd-x-6" />
                        <div className="text-truncate">Category 2(10)</div>
                      </button>
                      <div className="tree-btn-actions">
                        <button
                          type="button"
                          className="btn btn-xs btn-link btn-icon han-tooltip--left"
                          data-han-tooltip="Add new folder"
                          data-toggle="modal"
                          data-target="#newFolderModal"
                        >
                          <Plus />
                        </button>
                        <button
                          type="button"
                          className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                          data-han-tooltip="Rename"
                        >
                          <Edit2 />
                        </button>
                        <button
                          type="button"
                          className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                          data-han-tooltip="Edit"
                        >
                          <Settings />
                        </button>
                        <button
                          type="button"
                          className="btn btn-xs btn-link link-03 btn-icon btn-move han-tooltip--bottom"
                          data-han-tooltip="Move"
                        >
                          {Icon('move')}
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="mg-t-5">
                    <button
                      type="button"
                      className="btn btn-xs btn-outline-primary btn-icon btn-add-cate mg-l-5 han-tooltip--right"
                      data-han-tooltip="Add new category"
                      data-toggle="modal"
                      data-target="#newCategoryModal"
                    >
                      <Plus />
                      <span className="sr-only">Add new category</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <button
              type="button"
              className="btn pd-5 bd-r"
              onClick={() => setShowTreeList(!showTreeList)}
            >
              <ChevronLeft />
            </button>

            {/* article list */}
            <div className="article-list">
              <div className="table-responsive">
                <table className="table dataTable desk-knowledgebase-tb bd-0">
                  <thead>
                    <tr>
                      <th scope="col" className="wd-30">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="kbCheckAll" />
                          <label className="custom-control-label" htmlFor="kbCheckAll"></label>
                        </div>
                      </th>
                      <th scope="col" className="sorting">
                        Subject
                      </th>
                      <th scope="col" className="sorting_desc">
                        Created on
                      </th>
                      <th scope="col" className="sorting">
                        Available for
                      </th>
                      <th scope="col" className="sorting">
                        Display to
                      </th>
                      <th scope="col" className="sorting">
                        Viewed
                      </th>
                      <th scope="col" className="sorting">
                        Inserted
                      </th>
                      <th scope="col">Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="kbCheck1" />
                          <label className="custom-control-label" htmlFor="kbCheck1"></label>
                        </div>
                      </td>
                      <td>
                        <Link to="/knowledge-base/desktop/theme1/view-new">
                          How to assess admin page?
                        </Link>
                      </td>
                      <td className="sorting-cell">2020/12/01</td>
                      <td>CS</td>
                      <td>Public</td>
                      <td>5</td>
                      <td>0</td>
                      <td>
                        <Switch />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* pagination */}
              <div className="pd-x-20">
                <Pagination />
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewArticle />
      <NewCategory />
      <EditCategory />
      <NewFolder />
      <EditFolder />
    </>
  );
};

export default KBList;
