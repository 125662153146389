/* eslint-disable prettier/prettier */
import { Button } from '@base/components/form';
import { Plus, Trash } from 'react-feather';

// import "CrmCss/desktop/theme1/theme1.scss";

const Meeting = () => {
  return (
    <>
      <div className="card-columns settings-columns">
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Meeting Purpose</div>
          </div>
          <div className="card-body pd-0">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex align-items-center pd-12 bd-b">
                Introductory Client Meeting
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex align-items-center pd-12 bd-b">
                Business Consulting Sesseion
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex align-items-center pd-12">
                Proposal Meeting
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
            </ul>
          </div>
          <div className="card-footer">
            <div className="d-flex">
              <input type="text" className="form-control mg-r-5" placeholder="input the purpose" />
              <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
                <Plus className="mg-r-5" />
                Add
              </button>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Check list of Process</div>
          </div>
          <div className="card-body pd-0">
            <div className="pd-y-15 pd-x-20 bg-light bd-b">Research</div>
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex align-items-center pd-12 bd-b">
                Website
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex align-items-center pd-12 bd-b">
                Follow on Social Media
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex align-items-center pd-12 bd-b">
                Any recent press articles
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex align-items-center pd-12 bd-b">
                Update CRM Profiles
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex align-items-center pd-12 bd-b">
                Their Competitors
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex align-items-center pd-12 bd-b">
                Attendees
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex pd-y-12 pd-x-20 bd-b">
                <input type="text" className="form-control mg-r-5" placeholder="input the value" />
                <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </li>
            </ul>
            <div className="pd-y-15 pd-x-20 bg-light bd-b">Prepare Questions</div>
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex align-items-center pd-12 bd-b">
                Budget range
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex align-items-center pd-12 bd-b">
                Next step and when
                <Button
                  color="link"
                  size="sm"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                  data-han-tooltip="Delete"
                />
              </li>
              <li className="d-flex pd-y-12 pd-x-20">
                <input
                  type="text"
                  className="form-control mg-r-5"
                  placeholder="input the type name"
                />
                <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Meeting;
