import React, { useState } from 'react';
import { Calendar, Trash, User, Plus, Settings } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

// import "PurchaseCss/purchase.scss"

const PRWrite = () => {
  const [showAddNewItem, setShowAddNewItem] = useState<any>(false);

  return (
    <div className="overflow-auto">
      <div className="form-row">
        <div className="form-group col-6">
          <label className="form-item-title">Purchase Request ID</label>
          <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
            <input type="text" className="form-control" defaultValue="RFQ-001" />
            <div className="input-group-append">
              <button
                type="button"
                className="btn"
                aria-label="open generator of Purchase Request ID modal"
              >
                <Settings />
                <span className="sr-only">ID 생성 설정 열기</span>
              </button>
            </div>
          </div>
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">Request Date</label>
          <div className="input-group input-date-group wd-300">
            <input type="text" className="form-control" />
            <div className="input-group-append">
              <span className="input-group-text">
                <Calendar />
              </span>
            </div>
          </div>
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">Requested by</label>
          <div className="input-group wd-300">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0">
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                placeholder="Type or click to select an user"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open modal to select an user">
                <User />
                <span className="sr-only">신청자 선택 팝업 열기</span>
              </button>
            </div>
          </div>
        </div>
        <div className="form-group col-12">
          <label className="form-item-title">Description</label>
          <textarea rows={3} cols={10} className="form-control"></textarea>
        </div>
        <div className="form-group col-12">
          <label className="form-item-title">Reason for Purchase</label>
          <input type="text" className="form-control" />
        </div>
        <div className="form-group col-12">
          <label className="form-item-title">Product Details</label>
          <div className="d-flex align-items-center mg-b-10">
            <SelectHook className="wd-200-f" options={[{ value: 1, label: 'Select a template' }]} />
            <button type="button" className="btn btn-link">
              Preview
            </button>
          </div>
          <div className="bd rounded">
            <table className="table mg-b-0">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Item</th>
                  <th scope="col">Required Quantity</th>
                  <th scope="col">Required Date</th>
                  <th scope="col">Estimated Cost</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="avatar avatar-sm bg-gray-200 rounded">
                      <img src="assets/images/notfound.png" alt="" />
                    </div>
                  </td>
                  <td>AAA</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>
                    <div className="input-group input-date-group wd-150">
                      <input type="text" className="form-control" />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <Calendar />
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text bd-gray-400 bd-r-0 lh-1">￦</span>
                      </div>
                      <input type="number" className="form-control bd-l-0" />
                    </div>
                  </td>
                  <td>
                    <button type="button" className="btn btn-link link-03 btn-icon">
                      <Trash />
                    </button>
                  </td>
                </tr>
                {showAddNewItem && (
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <input type="text" className="form-control" />
                    </td>
                    <td>
                      <input type="number" className="form-control" />
                    </td>
                    <td>
                      <div className="input-group input-date-group wd-150">
                        <input type="text" className="form-control" />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <Calendar />
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text bd-gray-400 bd-r-0 lh-1">￦</span>
                        </div>
                        <input type="number" className="form-control bd-l-0" defaultValue="9800" />
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn btn-link link-03 btn-icon">
                        <Trash />
                      </button>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={6}>
                    <div className="dropdown">
                      <div className="input-group">
                        <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                          <input
                            type="text"
                            className="bd-0 flex-grow-1 mn-wd-250 rounded"
                            placeholder="Type or click to select an item"
                          />
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select an item"
                          >
                            {Icon('product')}
                            <span className="sr-only">아이템 선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <div className="dropdown-menu wd-100p show">
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => setShowAddNewItem(true)}
                        >
                          <Plus className="mg-r-5" />
                          Add new item
                        </button>
                        <div className="dropdown-divider"></div>
                        <button type="button" className="dropdown-item">
                          AAA
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PRWrite;
