import { DatePicker } from '@base/components/date-picker';
import { Button, Input, RadioGroup, Select, Switch } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { useState } from 'react';
import { ChevronUp } from 'react-feather';
import AddMetric from './addMetric';
const NewGoal = () => {
  const GOAL_TYPES = [
    { value: 'user', label: 'User Goal' },
    { value: 'team', label: 'Team Goal' },
  ];
  const [goalType, setGoalType] = useState(GOAL_TYPES);
  const [selectedType, setSelectedType] = useState(goalType[0].value);
  //change
  const handleTypeChange = (newOption: any) => {
    setSelectedType(newOption.value);
  };

  const periodOptions = [
    { value: 'w', label: 'Weekily' },
    { value: 'm', label: 'Monthly' },
    { value: 'q', label: 'Quarterly' },
    { value: 'y', label: 'Yearly' },
    { value: 'c', label: 'Custom Period' },
  ];
  const [period, setPeriod] = useState(periodOptions[4]);
  //change
  const handlePeriodChange = (newOption: any) => {
    setPeriod(newOption);
  };

  const [user, setUser] = useState(null);
  const [group, setGroup] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const [editMetricItem, setEditMetricItem] = useState(null);

  return (
    <>
      <div id="newGoal" className="modal fade right">
        <div className="modal-dialog wd-auto">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title h6">Create Goal</h1>
              <Button close data-dismiss="modal" />
            </div>
            <div className="modal-body pd-b-15-f overflow-auto">
              <div className="form-group">
                <label className="form-item-title">Goal Name</label>
                <Input />
              </div>
              <div className="form-group">
                <label className="form-item-title">Goal Period</label>
                <Select
                  options={periodOptions}
                  defaultValue={period}
                  onChange={handlePeriodChange}
                  className="mg-b-10"
                />
                {period.value === 'c' && (
                  <div className="row row-xs">
                    <div className="col-lg-6">
                      From
                      <DatePicker
                        selected={new Date()}
                        onChange={() => {
                          console.log();
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      To
                      <DatePicker
                        selected={new Date()}
                        onChange={() => {
                          console.log();
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="form-item-title">Goal Type</label>
                <RadioGroup options={goalType} value={selectedType} onChange={handleTypeChange} />
              </div>
              <div className="form-group">
                <label className="form-item-title">
                  {selectedType === 'user' ? 'User' : 'Team'}
                </label>
                <UserAutoComplete single showAvatar />
              </div>

              <div className="form-group">
                <label className="form-item-title">Metrics</label>
                <div className="table-responsive bd rounded">
                  <table className="table table-bordered bd-0 mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Name
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Type
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Number of Sales</td>
                        <td>Count</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Button
                  color="link"
                  icon="Plus"
                  name="Add Goal Metric"
                  data-toggle="modal"
                  data-target="#addMetric"
                />
              </div>
              <div className="form-group">
                <label className="form-item-title">Active</label>
                <Switch />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Close
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddMetric goalType={selectedType} />
    </>
  );
};
export default NewGoal;
