import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  X,
  ChevronsLeft,
  ChevronsRight,
  ArrowLeft,
  Edit2,
  Minus,
  Check,
  MoreHorizontal,
  Copy,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"

import Userlist from '../../../../component/desktop/userlist';
import Attachments from '../../../../component/desktop/attachments';
import Timeline from '../timeline';
import NewTradeAgreement from '../modal/newTradeAgreement';

const TABS = [
  {
    value: 1,
    label: 'Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const View = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLOS[0]);
  const [showEdit, setShowEdit] = useState<any>();
  const [showEdit2, setShowEdit2] = useState<any>();

  const [discountMethod, setDiscountMethod] = useState<any>({
    percentage: true,
    amount: false,
  });

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/ticket/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Trade Agreement
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/ticket/desktop/theme1/list">Trade Agreement</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
            </div>
          </div>

          <h1 className="view-title mg-l-10">TA 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="dropdown">
              <button
                type="button"
                data-han-tooltip="More"
                className="btn btn-white btn-icon rounded-circle han-tooltip"
                data-toggle="dropdown"
              >
                <span className="span-icon">
                  <MoreHorizontal />
                </span>
                <span className="sr-only">더보기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Copy />
                  </span>
                  Clone
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
            <div className="btn-group dropdown mg-l-5">
              <a href="#newTradeAgreement" className="btn btn-primary pd-x-10" data-toggle="modal">
                <Plus />
                <span className="sr-only">추가</span>
              </a>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <ChevronDown />
                <span className="sr-only">추가 옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a href="" className="dropdown-item">
                  Price List
                </a>
                <a href="" className="dropdown-item">
                  Discount
                </a>
                <a href="#newTradeAgreement" className="dropdown-item" data-toggle="modal">
                  Trade Agreement
                </a>
              </div>
            </div>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={
                'btn btn-change-header-bg shadow-sm han-tooltip--left bg-￦{headerColor.value}'
              }
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <span className="view-aside-section-header-title">Summary</span>
              </div>
              <div className="view-aside-section-body">
                <div className="form-group">
                  <label className="form-item-title">Trade Agreement ID</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Owner</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>MSR</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Active</label>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="taActiveSwitch"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="taActiveSwitch"></label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Price List</label>
                  <div>Base Price</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Start Date</label>
                  <div>2021/02/23</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">End Date</label>
                  <div>2021/02/23</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Payment Terms</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Tax</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Delivery Lead Tim</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Delivery Method</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Courier</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Terms and Conditions</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                  { hidden: hideAsideLeft },
                )}
                onClick={() => setHideAsideLeft(!hideAsideLeft)}
              >
                <ChevronsLeft />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>

              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map((item, index) => (
                    <li
                      key={index}
                      className={classnames('nav-item', {
                        active: item.value == tab.value,
                      })}
                    >
                      <button
                        type="button"
                        className="btn btn-xs btn-move-tab"
                        aria-label="Drag-and-drop to reorder"
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <button
                        type="button"
                        className="btn nav-link"
                        onClick={(e) => {
                          setTab(item);
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-l-10 bd-r-0 btn-collapse-aside-right',
                  { hidden: hideAsideRight },
                )}
                onClick={() => setHideAsideRight(!hideAsideRight)}
              >
                <ChevronsRight />
                <span className="sr-only">우측 사이드 패널 접기</span>
              </button>
            </div>

            <div className="view-body">
              {tab.value == 1 && (
                <div className="pd-15">
                  {/* Product Details */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Product Details</span>
                      {!showEdit && (
                        <button
                          type="button"
                          className="btn btn-link mg-l-auto"
                          onClick={() => setShowEdit(true)}
                        >
                          <Edit2 className="mg-r-5" />
                          Edit
                        </button>
                      )}
                    </div>
                    <div className="table-responsive bg-white bd rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th rowSpan={2} scope="col" className="bd-t-0-f">
                              Product
                            </th>
                            <th rowSpan={2} scope="col" className="bd-t-0-f">
                              Item
                            </th>
                            <th rowSpan={2} scope="col" className="bd-t-0-f">
                              Unit
                            </th>
                            <th rowSpan={2} scope="col" className="bd-t-0-f">
                              Price
                            </th>
                            <th colSpan={showEdit ? 4 : 3} scope="colgroup" className="bd-t-0-f">
                              Volume Discount
                            </th>
                            {showEdit && (
                              <th rowSpan={2} scope="col" className="bd-t-0-f">
                                Delete
                              </th>
                            )}
                          </tr>
                          <tr>
                            <th scope="col">Quantity</th>
                            <th scope="col" colSpan={showEdit ? 2 : 0}>
                              Discount
                            </th>
                            <th scope="col">Unit Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td rowSpan={3}>
                              {showEdit ? (
                                <div className="input-group wd-200">
                                  <div className="form-control pd-y-1-f d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="wd-100p bd-0"
                                      defaultValue="Product A"
                                    />
                                    <button type="button" className="btn pd-0">
                                      <X />
                                      <span className="sr-only">삭제</span>
                                    </button>
                                  </div>
                                  <div className="input-group-append">
                                    <button
                                      type="button"
                                      className="btn"
                                      aria-label="open modal to select a product"
                                    >
                                      {Icon('product')}
                                      <span className="sr-only">선택 팝업 열기</span>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                'Product A'
                              )}
                            </td>
                            <td rowSpan={2}>
                              {showEdit ? (
                                <div className="input-group wd-200">
                                  <div className="form-control pd-y-1-f d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="wd-100p bd-0"
                                      defaultValue="Item A-1"
                                    />
                                    <button type="button" className="btn pd-0">
                                      <X />
                                      <span className="sr-only">삭제</span>
                                    </button>
                                  </div>
                                  <div className="input-group-append">
                                    <button
                                      type="button"
                                      className="btn"
                                      aria-label="open modal to select an item"
                                    >
                                      {Icon('product')}
                                      <span className="sr-only">선택 팝업 열기</span>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <span className="d-inline-block avatar avatar-xs mg-r-10">
                                    <img
                                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaBCbGjXSHrF4LQT5LEhmmizdNb7wR1OkQsg&usqp=CAU"
                                      className="rounded"
                                    />
                                  </span>
                                  <a href="" target="_blank" title="새창으로 열림">
                                    Item A-1
                                  </a>
                                </>
                              )}
                            </td>
                            <td rowSpan={2}>
                              {showEdit ? (
                                <input type="number" className="form-control wd-100" />
                              ) : (
                                ''
                              )}
                            </td>
                            <td rowSpan={2}>
                              {showEdit ? (
                                <div className="input-group wd-150">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text lh-1">￦</span>
                                  </div>
                                  <input type="text" className="form-control" defaultValue="1000" />
                                </div>
                              ) : (
                                '￦1,000'
                              )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {showEdit ? (
                                  <input
                                    type="number"
                                    className="form-control wd-100"
                                    defaultValue="1"
                                  />
                                ) : (
                                  '1'
                                )}
                                <span className="mg-x-5">~</span>
                                {showEdit ? (
                                  <input
                                    type="number"
                                    className="form-control wd-100"
                                    defaultValue="100"
                                  />
                                ) : (
                                  '100'
                                )}
                                {showEdit && (
                                  <>
                                    <button type="button" className="btn btn-link">
                                      <Plus />
                                      <span className="sr-only">추가</span>
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                            {showEdit && (
                              <td rowSpan={3}>
                                <div className="dropdown">
                                  <div className="input-group wd-150" data-toggle="dropdown">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={discountMethod.percentage ? 'Percentage' : 'Amount'}
                                    />
                                    <div className="input-group-append">
                                      <button type="button" className="btn">
                                        <ChevronDown />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="dropdown-menu">
                                    <button
                                      type="button"
                                      className="dropdown-item"
                                      onClick={() => setDiscountMethod({ percentage: true })}
                                    >
                                      Percentage
                                    </button>
                                    <button
                                      type="button"
                                      className="dropdown-item"
                                      onClick={() => setDiscountMethod({ amount: true })}
                                    >
                                      Amount
                                    </button>
                                  </div>
                                </div>
                              </td>
                            )}
                            <td>
                              {showEdit ? (
                                <div className="input-group wd-150">
                                  {discountMethod.amount && (
                                    <div className="input-group-prepend">
                                      <span className="input-group-text lh-1">＄</span>
                                    </div>
                                  )}
                                  <input type="number" className="form-control" defaultValue={5} />
                                  {discountMethod.percentage && (
                                    <div className="input-group-append">
                                      <span className="input-group-text lh-1">%</span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                '5%'
                              )}
                            </td>
                            <td>
                              {showEdit ? (
                                <div className="input-group wd-150">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text lh-1">￦</span>
                                  </div>
                                  <input type="text" className="form-control" defaultValue="1000" />
                                </div>
                              ) : (
                                '￦1,000'
                              )}
                            </td>
                            {showEdit && (
                              <td rowSpan={2}>
                                <button type="button" className="btn btn-link btn-icon pd-y-0">
                                  <X className="tx-danger" />
                                </button>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                {showEdit ? (
                                  <input
                                    type="number"
                                    className="form-control wd-100"
                                    defaultValue="101"
                                  />
                                ) : (
                                  '101'
                                )}
                                <span className="mg-x-5">~</span>
                                {showEdit ? (
                                  <input
                                    type="number"
                                    className="form-control wd-100"
                                    defaultValue="200"
                                  />
                                ) : (
                                  '200'
                                )}
                                {showEdit && (
                                  <>
                                    <button type="button" className="btn btn-link">
                                      <Plus />
                                      <span className="sr-only">추가</span>
                                    </button>
                                    <button type="button" className="btn tx-danger">
                                      <Minus />
                                      <span className="sr-only">삭제</span>
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                            <td>
                              {showEdit ? (
                                <div className="input-group wd-150">
                                  {discountMethod.amount && (
                                    <div className="input-group-prepend">
                                      <span className="input-group-text lh-1">＄</span>
                                    </div>
                                  )}
                                  <input type="number" className="form-control" defaultValue={7} />
                                  {discountMethod.percentage && (
                                    <div className="input-group-append">
                                      <span className="input-group-text lh-1">%</span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                '7%'
                              )}
                            </td>
                            <td>
                              {showEdit ? (
                                <div className="input-group wd-150">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text lh-1">￦</span>
                                  </div>
                                  <input type="text" className="form-control" defaultValue="1000" />
                                </div>
                              ) : (
                                '￦1,000'
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {showEdit ? (
                                <div className="input-group wd-200">
                                  <div className="form-control pd-y-1-f d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="wd-100p bd-0"
                                      defaultValue="Item A-2"
                                    />
                                    <button type="button" className="btn pd-0">
                                      <X />
                                      <span className="sr-only">삭제</span>
                                    </button>
                                  </div>
                                  <div className="input-group-append">
                                    <button
                                      type="button"
                                      className="btn"
                                      aria-label="open modal to select an item"
                                    >
                                      {Icon('product')}
                                      <span className="sr-only">선택 팝업 열기</span>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <span className="d-inline-block avatar avatar-xs mg-r-10">
                                    <img
                                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjlVQothoBRBbaN6EyELYYFfYjTg8xsDy63Q&usqp=CAU"
                                      className="rounded"
                                    />
                                  </span>
                                  <a href="" target="_blank" title="새창으로 열림">
                                    Item A-2
                                  </a>
                                </>
                              )}
                            </td>
                            <td>
                              {showEdit ? (
                                <input type="number" className="form-control wd-100" />
                              ) : (
                                ''
                              )}
                            </td>
                            <td>
                              {showEdit ? (
                                <div className="input-group wd-150">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text lh-1">￦</span>
                                  </div>
                                  <input type="text" className="form-control" defaultValue="1000" />
                                </div>
                              ) : (
                                '￦1,000'
                              )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {showEdit ? (
                                  <input
                                    type="number"
                                    className="form-control wd-100"
                                    defaultValue="1"
                                  />
                                ) : (
                                  '1'
                                )}
                                <span className="mg-x-5">~</span>
                                {showEdit ? (
                                  <input
                                    type="number"
                                    className="form-control wd-100"
                                    defaultValue="100"
                                  />
                                ) : (
                                  '100'
                                )}
                                {showEdit && (
                                  <>
                                    <button type="button" className="btn btn-link">
                                      <Plus />
                                      <span className="sr-only">추가</span>
                                    </button>
                                    <button type="button" className="btn tx-danger">
                                      <Minus />
                                      <span className="sr-only">삭제</span>
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                            <td>
                              {showEdit ? (
                                <div className="input-group wd-150">
                                  {discountMethod.amount && (
                                    <div className="input-group-prepend">
                                      <span className="input-group-text lh-1">＄</span>
                                    </div>
                                  )}
                                  <input type="number" className="form-control" defaultValue={5} />
                                  {discountMethod.percentage && (
                                    <div className="input-group-append">
                                      <span className="input-group-text lh-1">%</span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                '5%'
                              )}
                            </td>
                            <td>
                              {showEdit ? (
                                <div className="input-group wd-150">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text lh-1">￦</span>
                                  </div>
                                  <input type="text" className="form-control" defaultValue="1000" />
                                </div>
                              ) : (
                                '￦1,000'
                              )}
                            </td>
                            {showEdit && (
                              <td>
                                <button type="button" className="btn btn-link btn-icon pd-y-0">
                                  <X className="tx-danger" />
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {showEdit && (
                      <>
                        <div className="mg-b-10 pd-12 bg-white bd bd-t-0 rounded-bottom">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type or click to select a product"
                            />
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn"
                                aria-label="open modal to select a product"
                              >
                                {Icon('product')}
                                <span className="sr-only">선택 팝업 열기</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mg-t-10 text-right">
                          <button
                            type="button"
                            className="btn btn-light mg-r-10"
                            onClick={() => setShowEdit(false)}
                          >
                            <X className="ma-r-5" /> Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-success mg-r-10"
                            onClick={() => setShowEdit(false)}
                          >
                            <Check className="ma-r-5" /> Edit
                          </button>
                        </div>
                      </>
                    )}
                  </div>

                  {/* Threshold Discount */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Threshold Discount</span>
                      {!showEdit2 && (
                        <button
                          type="button"
                          className="btn btn-link mg-l-auto"
                          onClick={() => setShowEdit2(true)}
                        >
                          <Edit2 className="mg-r-5" />
                          Edit
                        </button>
                      )}
                    </div>
                    <div className="table-responsive bg-white bd rounded-top">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Amount
                            </th>
                            <th scope="col" className="bd-t-0-f" colSpan={showEdit2 ? 2 : 0}>
                              Discount
                            </th>
                            {showEdit2 && (
                              <th scope="col" className="wd-70 bd-t-0-f">
                                Delete
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {showEdit2 ? (
                                <div className="input-group wd-200">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text lh-1">￦</span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="1000000"
                                  />
                                </div>
                              ) : (
                                '￦1,000,000'
                              )}
                            </td>
                            {showEdit2 && (
                              <td>
                                <div className="dropdown">
                                  <div className="input-group wd-150" data-toggle="dropdown">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={discountMethod.percentage ? 'Percentage' : 'Amount'}
                                    />
                                    <div className="input-group-append">
                                      <button type="button" className="btn">
                                        <ChevronDown />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="dropdown-menu">
                                    <button
                                      type="button"
                                      className="dropdown-item"
                                      onClick={() => setDiscountMethod({ percentage: true })}
                                    >
                                      Percentage
                                    </button>
                                    <button
                                      type="button"
                                      className="dropdown-item"
                                      onClick={() => setDiscountMethod({ amount: true })}
                                    >
                                      Amount
                                    </button>
                                  </div>
                                </div>
                              </td>
                            )}
                            <td>
                              {showEdit2 ? (
                                <div className="input-group wd-150">
                                  {discountMethod.amount && (
                                    <div className="input-group-prepend">
                                      <span className="input-group-text lh-1">＄</span>
                                    </div>
                                  )}
                                  <input type="number" className="form-control" />
                                  {discountMethod.percentage && (
                                    <div className="input-group-append">
                                      <span className="input-group-text lh-1">%</span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                '5%'
                              )}
                            </td>
                            {showEdit2 && (
                              <td>
                                <button type="button" className="btn btn-link btn-icon pd-y-0">
                                  <X className="tx-danger" />
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {showEdit2 && (
                      <>
                        <button type="button" className="btn btn-link">
                          <Plus className="mg-r-5" />
                          Add
                        </button>
                        <div className="mg-t-10 text-right">
                          <button
                            type="button"
                            className="btn btn-light mg-r-10"
                            onClick={() => setShowEdit2(false)}
                          >
                            <X className="ma-r-5" /> Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => setShowEdit2(false)}
                          >
                            <Check className="ma-r-5" /> Edit
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {tab.value == 2 && <Timeline />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            {/* Customer */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Customer</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#customerContact"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="customerContact" className="view-aside-section-body collapse show">
                <Userlist />
              </div>
            </div>

            {/* Attachments */}
            <Attachments />
          </div>
        </div>
      </div>

      <NewTradeAgreement />
    </>
  );
};

export default View;
