/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  ChevronDown,
  Plus,
  Printer,
  Settings,
  Trash,
  User,
  X,
} from 'react-feather';
import { Button, Select as SelectHook, Switch } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss";

const Invoice = () => {
  const [showReminders, setShowReminders] = useState(true);
  const [showPDApprochingReminders, setShowPDApprochingReminders] = useState(true);
  const [showOverdueReminders, setShowOverdueReminders] = useState(true);
  const [showUnpaidReminders, setShowUnpaidReminders] = useState(true);
  const [showLateFeeReminders, setShowLateFeeReminders] = useState(true);
  const [showInvoiceMailAuto, setShowInvoiceMailAuto] = useState(true);
  const [showThankyouMail, setShowThankyouMail] = useState(true);
  const [showCreditNoteMail, setShowCreditNoteMail] = useState(true);
  const [showRefundCompletedMail, setShowRefundCompletedMail] = useState(true);
  const [showDebitNoteMail, setShowDebitNoteMail] = useState(true);
  const [showPurchaseMailAuto, setShowPurchaseMailAuto] = useState(true);
  const [showPaymentMadeMail, setShowPaymentMadeMail] = useState(true);
  const [showVendorCreditMail, setShowVendorCreditMail] = useState(true);
  const [showRefundReceivedMail, setShowRefundReceivedMail] = useState(true);
  const [showVendorDebitNoteMail, setShowVendorDebitNoteMail] = useState(true);
  const [showLateFee, setShowLateFee] = useState(true);
  const [showLateFeeAuto, setShowLateFeeAuto] = useState(true);
  const [showLateFeeRecurring, setShowLateFeeRecurring] = useState(true);
  const [showFeetype, setShowFeetype] = useState('percentage');

  const handleFeetype = (e: any) => {
    const { value } = e.target;
    setShowFeetype(value);
  };

  return (
    <div className="card-columns settings-columns">
      {/* Reminders */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Reminders
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Send payment reminder to customer automatiaclly. invoices that have exceeded the set
              number of days after auto-send settings will not be sent.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="autoRemindersActive"
              type="checkbox"
              className="custom-control-input"
              checked={showReminders}
              onChange={() => {
                setShowReminders(!showReminders);
              }}
            />
            <label className="custom-control-label" htmlFor="autoRemindersActive">
              Auto-Send
            </label>
          </div>
        </div>
        {showReminders && (
          <div className="card-body pd-0">
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Payment Date Approching Reminder</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="paymentApprochingSwitch1"
                    checked={showPDApprochingReminders}
                    onChange={() => setShowPDApprochingReminders(!showPDApprochingReminders)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="paymentApprochingSwitch1"
                  ></label>
                </div>
              </div>
              {showPDApprochingReminders && (
                <>
                  <div className="d-flex flex-wrap mg-y-10 pd-10 bg-light rounded">
                    <div>
                      <div className="d-flex align-items-center">
                        Send Reminder
                        <input type="number" className="form-control wd-100 mg-x-5" />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'days' }]}
                        />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'before' }]}
                        />
                        the Due Date
                      </div>
                      <div className="d-flex align-items-center mg-t-5">
                        Reminder Template{' '}
                        <button type="button" className="btn btn-link">
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap mg-y-10 pd-10 bg-light rounded">
                    <div>
                      <div className="d-flex align-items-center">
                        Send Reminder
                        <input type="number" className="form-control wd-100 mg-x-5" />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'days' }]}
                        />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'before' }]}
                        />
                        the Due Date
                      </div>
                      <div className="d-flex align-items-center mg-t-5">
                        Reminder Template{' '}
                        <button type="button" className="btn btn-link">
                          Edit
                        </button>
                      </div>
                    </div>
                    <button type="button" className="btn btn-icon mg-l-auto">
                      <X className="tx-danger" />
                    </button>
                  </div>
                  <button type="button" className="btn btn-sm btn-link">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </>
              )}
            </div>
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Overdue Reminder</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="overdueReminderSwitch1"
                    checked={showOverdueReminders}
                    onChange={() => setShowOverdueReminders(!showOverdueReminders)}
                  />
                  <label className="custom-control-label" htmlFor="overdueReminderSwitch1"></label>
                </div>
              </div>
              {showOverdueReminders && (
                <>
                  <div className="d-flex flex-wrap mg-y-10 pd-10 bg-light rounded">
                    <div>
                      <div className="d-flex align-items-center">
                        Send Reminder
                        <input type="number" className="form-control wd-100 mg-x-5" />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'days' }]}
                        />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'before' }]}
                        />
                        the Due Date
                      </div>
                      <div className="d-flex align-items-center mg-t-5">
                        Reminder Template{' '}
                        <button type="button" className="btn btn-link">
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn btn-sm btn-link">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </>
              )}
            </div>
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Unpaid Invoice Reminder</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="unpaidReminderSwitch1"
                    checked={showUnpaidReminders}
                    onChange={() => setShowUnpaidReminders(!showUnpaidReminders)}
                  />
                  <label className="custom-control-label" htmlFor="unpaidReminderSwitch1"></label>
                </div>
              </div>
              {showUnpaidReminders && (
                <>
                  <div className="d-flex flex-wrap mg-y-10 pd-10 bg-light rounded">
                    <div>
                      <div className="d-flex align-items-center">
                        Send Reminder
                        <input type="number" className="form-control wd-100 mg-x-5" />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'days' }]}
                        />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'before' }]}
                        />
                        the Due Date
                      </div>
                      <div className="d-flex align-items-center mg-t-5">
                        Reminder Template{' '}
                        <button type="button" className="btn btn-link">
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn btn-sm btn-link">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </>
              )}
            </div>
            <div className="pd-15">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Applying Late Fee Reminder </span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="lateFeeReminderSwitch1"
                    checked={showLateFeeReminders}
                    onChange={() => setShowLateFeeReminders(!showLateFeeReminders)}
                  />
                  <label className="custom-control-label" htmlFor="lateFeeReminderSwitch1"></label>
                </div>
              </div>
              {showLateFeeReminders && (
                <>
                  <div className="d-flex flex-wrap mg-y-10 pd-10 bg-light rounded">
                    <div>
                      <div className="d-flex align-items-center">
                        Send Reminder
                        <input type="number" className="form-control wd-100 mg-x-5" />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'days' }]}
                        />
                        <SelectHook
                          className="wd-100-f mg-r-5"
                          options={[{ value: 1, label: 'before' }]}
                        />
                        the Due Date
                      </div>
                      <div className="d-flex align-items-center mg-t-5">
                        Reminder Template{' '}
                        <button type="button" className="btn btn-link">
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn btn-sm btn-link">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Invoice Emails */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Invoice Emails
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Send email to customer automatiaclly.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="autoInvoiceMailActive"
              type="checkbox"
              className="custom-control-input"
              checked={showInvoiceMailAuto}
              onChange={() => {
                setShowInvoiceMailAuto(!showInvoiceMailAuto);
              }}
            />
            <label className="custom-control-label" htmlFor="autoInvoiceMailActive">
              Auto-Send
            </label>
          </div>
        </div>
        {showInvoiceMailAuto && (
          <div className="card-body pd-0">
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Send Payment Thank you Email </span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="sendmailSwitch1"
                    checked={showThankyouMail}
                    onChange={() => {
                      setShowThankyouMail(!showThankyouMail);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="sendmailSwitch1"></label>
                </div>
              </div>
              {showThankyouMail && (
                <div className="d-flex align-items-center mg-t-10">
                  Reminder Template{' '}
                  <button type="button" className="btn btn-link">
                    Edit
                  </button>
                </div>
              )}
            </div>
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Send Create Credit Note Email</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="sendmailSwitch2"
                    checked={showCreditNoteMail}
                    onChange={() => {
                      setShowCreditNoteMail(!showCreditNoteMail);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="sendmailSwitch2"></label>
                </div>
              </div>
              {showCreditNoteMail && (
                <div className="d-flex align-items-center mg-t-10">
                  Reminder Template{' '}
                  <button type="button" className="btn btn-link">
                    Edit
                  </button>
                </div>
              )}
            </div>
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Send Refund Completed Email </span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="sendmailSwitch3"
                    checked={showRefundCompletedMail}
                    onChange={() => {
                      setShowRefundCompletedMail(!showRefundCompletedMail);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="sendmailSwitch3"></label>
                </div>
              </div>
              {showRefundCompletedMail && (
                <div className="d-flex align-items-center mg-t-10">
                  Reminder Template{' '}
                  <button type="button" className="btn btn-link">
                    Edit
                  </button>
                </div>
              )}
            </div>
            <div className="pd-15">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Send Create Debit Note Email</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="sendmailSwitch4"
                    checked={showDebitNoteMail}
                    onChange={() => {
                      setShowDebitNoteMail(!showDebitNoteMail);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="sendmailSwitch4"></label>
                </div>
              </div>
              {showDebitNoteMail && (
                <div className="d-flex align-items-center mg-t-10">
                  Reminder Template{' '}
                  <button type="button" className="btn btn-link">
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Purchase Bill Emails */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Purchase Bill Emails
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Send email to customer automatiaclly.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="autoPurchaseMailActive"
              type="checkbox"
              className="custom-control-input"
              checked={showPurchaseMailAuto}
              onChange={() => {
                setShowPurchaseMailAuto(!showPurchaseMailAuto);
              }}
            />
            <label className="custom-control-label" htmlFor="autoPurchaseMailActive">
              Auto-Send
            </label>
          </div>
        </div>
        {showPurchaseMailAuto && (
          <div className="card-body pd-0">
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Send Payment Made Email</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="sendPurchaseEmailSwitch1"
                    checked={showPaymentMadeMail}
                    onChange={() => {
                      setShowPaymentMadeMail(!showPaymentMadeMail);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="sendPurchaseEmailSwitch1"
                  ></label>
                </div>
              </div>
              {showPaymentMadeMail && (
                <div className="d-flex align-items-center mg-t-10">
                  Reminder Template{' '}
                  <button type="button" className="btn btn-link">
                    Edit
                  </button>
                </div>
              )}
            </div>
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Send Create Vendor Credit Email</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="sendPurchaseEmailSwitch2"
                    checked={showVendorCreditMail}
                    onChange={() => {
                      setShowVendorCreditMail(!showVendorCreditMail);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="sendPurchaseEmailSwitch2"
                  ></label>
                </div>
              </div>
              {showVendorCreditMail && (
                <div className="d-flex align-items-center mg-t-10">
                  Reminder Template{' '}
                  <button type="button" className="btn btn-link">
                    Edit
                  </button>
                </div>
              )}
            </div>
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Send Refund Received Email</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="sendPurchaseEmailSwitch3"
                    checked={showRefundReceivedMail}
                    onChange={() => {
                      setShowRefundReceivedMail(!showRefundReceivedMail);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="sendPurchaseEmailSwitch3"
                  ></label>
                </div>
              </div>
              {showRefundReceivedMail && (
                <div className="d-flex align-items-center mg-t-10">
                  Reminder Template{' '}
                  <button type="button" className="btn btn-link">
                    Edit
                  </button>
                </div>
              )}
            </div>
            <div className="pd-15">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Send Create Vendor Debit Note Email </span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="sendPurchaseEmailSwitch4"
                    checked={showVendorDebitNoteMail}
                    onChange={() => {
                      setShowVendorDebitNoteMail(!showVendorDebitNoteMail);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="sendPurchaseEmailSwitch4"
                  ></label>
                </div>
              </div>
              {showVendorDebitNoteMail && (
                <div className="d-flex align-items-center mg-t-10">
                  Reminder Template{' '}
                  <button type="button" className="btn btn-link">
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Auto-Create Subscription Invoices */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Auto-Create Subscription Invoices
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Subscription Invoices are automatically created based on a Billing Plan
            </small>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center">
            Auto-Create
            <input type="number" className="form-control wd-100 mg-x-5" />
            <SelectHook className="wd-100-f mg-r-5" options={[{ value: 1, label: 'days' }]} />
            before the Invoice Date
          </div>
        </div>
      </div>

      {/* Default Invocie Split Type */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Default Invocie Split Type
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              The invoice split screen is showes the as default ty pe,users can select the type.{' '}
            </small>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label className="form-item-title">Default Type</label>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="invoiceSplitType1"
                name="invoiceSplitType"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="invoiceSplitType1">
                Percentage
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="invoiceSplitType2"
                name="invoiceSplitType"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="invoiceSplitType2">
                Per Item
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Payment Term */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Invoice Payment Term
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Invoice due date is set automatically when selecting the invoice date by the payment
              trem
            </small>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center">
            Net
            <input type="number" className="form-control wd-100 mg-x-5" />
            <SelectHook className="wd-100-f mg-r-5" options={[{ value: 1, label: 'days' }]} />
          </div>
        </div>
      </div>

      {/* Unpaid Invoice */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Unpaid Invoice
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Invoice status is automatically change based on the settings
            </small>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center">
            Change status to Unpaid
            <input type="number" className="form-control wd-100 mg-x-5" />
            <SelectHook className="wd-100-f mg-r-5" options={[{ value: 1, label: 'days' }]} />
            after the Due Date
          </div>
          <div className="d-flex align-items-center mg-t-10">
            Change status to Uncollectible
            <input type="number" className="form-control wd-100 mg-x-5" />
            <SelectHook className="wd-100-f mg-r-5" options={[{ value: 1, label: 'days' }]} />
            after the Due Date
          </div>
        </div>
      </div>

      {/* Late Fee */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Late Fee
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Charge a late fee to the unpaid invoice. it not applied to the invoices created before
              the feature is activated.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="lateFeeActive"
              type="checkbox"
              className="custom-control-input"
              checked={showLateFee}
              onChange={() => {
                setShowLateFee(!showLateFee);
              }}
            />
            <label className="custom-control-label" htmlFor="lateFeeActive"></label>
          </div>
        </div>
        {showLateFee && (
          <div className="card-body pd-0">
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Auto-Charge</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="lateFeeAutoChargeSwitch"
                    checked={showLateFeeAuto}
                    onChange={() => {
                      setShowLateFeeAuto(!showLateFeeAuto);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="lateFeeAutoChargeSwitch"></label>
                </div>
              </div>
              {showLateFeeAuto && (
                <div className="d-flex align-items-center mg-t-10">
                  <input type="number" className="form-control wd-100 mg-x-5" />
                  <SelectHook className="wd-100-f mg-r-5" options={[{ value: 1, label: 'days' }]} />
                  after the Due Date
                </div>
              )}
            </div>
            <div className="pd-15 bd-b">
              <div className="tx-semibold">Fee Type</div>
              <span className="mg-t-5 tx-orange">
                Invoice status is automatically change based on the settings
              </span>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="lateFeeTypeRadio"
                    id="lateFeeTypeRadio1"
                    value="percentage"
                    checked={showFeetype === 'percentage'}
                    onChange={(e) => handleFeetype(e)}
                  />
                  <label className="custom-control-label" htmlFor="lateFeeTypeRadio1">
                    Percentage
                  </label>
                </div>
                {showFeetype === 'percentage' && (
                  <div className="input-group wd-150 mg-l-10">
                    <input type="number" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text lh-1">%</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="lateFeeTypeRadio"
                    id="lateFeeTypeRadio2"
                    value="flat"
                    checked={showFeetype === 'flat'}
                    onChange={(e) => handleFeetype(e)}
                  />
                  <label className="custom-control-label" htmlFor="lateFeeTypeRadio2">
                    Flat Fee
                  </label>
                </div>
                {showFeetype === 'flat' && (
                  <div className="input-group wd-200 mg-l-10">
                    <div className="input-group-prepend">
                      <span className="input-group-text lh-1">￦</span>
                    </div>
                    <input type="number" className="form-control" />
                  </div>
                )}
              </div>
            </div>
            <div className="pd-15 bd-b">
              <div className="tx-semibold">Calculation Type</div>
              <span className="mg-t-5 tx-orange">
                Late fee will be calculated based on the amount of invoice or unpaid balance due.
              </span>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="lateFeeCalculationTypeRadio"
                    id="lateFeeCalculationTypeRadio1"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="lateFeeCalculationTypeRadio1">
                    Invoice Amonut
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="lateFeeCalculationTypeRadio"
                    id="lateFeeCalculationTypeRadio2"
                  />
                  <label className="custom-control-label" htmlFor="lateFeeCalculationTypeRadio2">
                    {' '}
                    Balance Due
                  </label>
                </div>
              </div>
            </div>
            <div className="pd-15 bd-b">
              <div className="d-flex alitn-items-center">
                <span className="tx-semibold">Recurring</span>
                <div className="custom-control custom-switch mg-l-auto">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="lateFeeRecurringSwitch"
                    checked={showLateFeeRecurring}
                    onChange={() => {
                      setShowLateFeeRecurring(!showLateFeeRecurring);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="lateFeeRecurringSwitch"></label>
                </div>
              </div>
              <span className="mg-t-5 tx-orange">
                The recurring late fee will be added by every certain period automatically.
              </span>
              {showLateFeeRecurring && (
                <div className="d-flex align-items-center mg-t-10">
                  Every
                  <input type="number" className="form-control wd-100 mg-x-5" />
                  <SelectHook className="wd-100-f mg-r-5" options={[{ value: 1, label: 'days' }]} />
                </div>
              )}
            </div>
            <div className="pd-15">
              <div className="tx-semibold">Excluded Customer</div>
              <div className="input-group mg-t-10">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to add an user"
                />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    {Icon('m_customer')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Default Payment Mode */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Default Payment Mode</div>
        </div>
        <div className="card-body pd-0">
          <ul className="list-unstyled mg-b-0">
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="paymentMode1"
                  name="paymentMode"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="paymentMode1">
                  Bank Transfer
                </label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="paymentMode2"
                  name="paymentMode"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="paymentMode2">
                  Credit Card
                </label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="paymentMode3"
                  name="paymentMode"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="paymentMode3">
                  Check
                </label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="paymentMode4"
                  name="paymentMode"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="paymentMode4">
                  Cash
                </label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="paymentMode5"
                  name="paymentMode"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="paymentMode5">
                  New Mode
                </label>
              </div>
              <button type="button" className="btn btn-icon mg-l-auto pd-y-0">
                <X className="tx-danger" />
              </button>
            </li>
          </ul>
        </div>
        <div className="card-footer d-flex">
          <input type="text" className="form-control mg-r-5" placeholder="Enter the Payment Mode" />
          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* Default Credit/Debit Reason */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Default Credit/Debit Reason</div>
        </div>
        <div className="card-body pd-0 ">
          <ul className="list-unstyled mg-b-0">
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="creditReason1"
                  name="creditReason"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="creditReason1">
                  Damaged or Expired Items
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="creditReasonActive1"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="creditReasonActive1"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="creditReason2"
                  name="creditReason"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="creditReason2">
                  Amount Overcharged
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="creditReasonActive2"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="creditReasonActive2"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="creditReason3"
                  name="creditReason"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="creditReason3">
                  Order Cancellation
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="creditReasonActive3"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="creditReasonActive3"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="creditReason4"
                  name="creditReason"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="creditReason4">
                  Item Dissatisfaction
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="creditReasonActive4"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="creditReasonActive4"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="creditReason5"
                  name="creditReason"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="creditReason5">
                  Delivery Issue
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="creditReasonActive5"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="creditReasonActive5"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="creditReason6"
                  name="creditReason"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="creditReason6">
                  Other
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="creditReasonActive6"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="creditReasonActive6"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="creditReason7"
                  name="creditReason"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="creditReason7">
                  New Reason
                </label>
              </div>
              <Button
                color="link"
                size="sm"
                icon="X"
                iconClass="tx-danger"
                className="btn-icon pd-y-0 han-tooltip--top mg-l-auto"
                data-han-tooltip="Delete"
              />
              <Switch className="mg-l-5" />
            </li>
          </ul>
        </div>
        <div className="card-footer d-flex">
          <input type="text" className="form-control mg-r-5" placeholder="Enter the Reason" />
          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* Default Sending Mode */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Default Sending Mode</div>
        </div>
        <div className="card-body pd-0 ">
          <ul className="list-unstyled mg-b-0">
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="sendingMode1"
                  name="sendingMode"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="sendingMode1">
                  Send Email
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="sendingModeActive1"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="sendingModeActive1"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="sendingMode2"
                  name="sendingMode"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="sendingMode2">
                  Send by Post
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="sendingModeActive2"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="sendingModeActive2"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="sendingMode3"
                  name="sendingMode"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="sendingMode3">
                  Nerver
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="sendingModeActive3"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="sendingModeActive3"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="sendingMode4"
                  name="sendingMode"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="sendingMode4">
                  New Mode
                </label>
              </div>
              <Button
                color="link"
                size="sm"
                icon="X"
                iconClass="tx-danger"
                className="btn-icon pd-y-0 han-tooltip--top mg-l-auto"
                data-han-tooltip="Delete"
              />
              <Switch className="mg-l-5" />
            </li>
          </ul>
        </div>
        <div className="card-footer d-flex">
          <input type="text" className="form-control mg-r-5" placeholder="Enter the Sending Mode" />
          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* Default Receipt Type */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Default Receipt Type</div>
        </div>
        <div className="card-body pd-0 ">
          <ul className="list-unstyled mg-b-0">
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="receiptType1"
                  name="receiptType"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="receiptType1">
                  계산서
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="receiptTypeActive1"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="receiptTypeActive1"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="receiptType2"
                  name="receiptType"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="receiptType2">
                  세금 계산서
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="receiptTypeActive2"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="receiptTypeActive2"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="receiptType3"
                  name="receiptType"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="receiptType3">
                  현금영수증
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="receiptTypeActive3"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="receiptTypeActive3"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="receiptType4"
                  name="receiptType"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="receiptType4">
                  간이영수증
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="receiptTypeActive4"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="receiptTypeActive4"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12 bd-b">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="receiptType5"
                  name="receiptType"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="receiptType5">
                  신용카드매출전표
                </label>
              </div>
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  id="receiptTypeActive5"
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="receiptTypeActive5"></label>
              </div>
            </li>
            <li className="d-flex align-items-center pd-12">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="receiptType6"
                  name="receiptType"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="receiptType6">
                  New Type
                </label>
              </div>
              <Button
                color="link"
                size="sm"
                icon="X"
                iconClass="tx-danger"
                className="btn-icon pd-y-0 han-tooltip--top mg-l-auto"
                data-han-tooltip="Delete"
              />
              <Switch className="mg-l-5" />
            </li>
          </ul>
        </div>
        <div className="card-footer d-flex">
          <input type="text" className="form-control mg-r-5" placeholder="Enter the Receipt Type" />
          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
