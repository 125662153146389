import React, { useState } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, ChevronsLeft, ArrowLeft, ArrowUp } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'Insight',
  },
  {
    value: 2,
    label: 'Views',
  },
  {
    value: 3,
    label: 'Link Map',
  },
  {
    value: 4,
    label: 'Related',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewReward = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Online Digital Contents
            <ChevronDown className="mg-l-5" />
          </button>
        </div>

        <h1 className="view-title mg-l-10 lh-1">Referral Reward Site</h1>

        <div className="button-group flex-shrink-0 mg-l-auto">
          <button
            type="button"
            data-han-tooltip="Delete"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">
              <Trash />
            </span>
            <span className="sr-only">삭제</span>
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="d-flex flex-column" style={{ height: 'calc(100% - 50px)' }}>
        <div className="d-flex align-items-start bg-white bd-b pd-t-8 pd-x-8">
          {/* tabs */}
          <div className="view-tabs flex-grow-1">
            <ul className="nav nav-tabs flex-grow-1 bd-0">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  className={classnames('nav-item', {
                    active: item.value == tab.value,
                  })}
                >
                  <button
                    type="button"
                    className="btn btn-xs btn-move-tab"
                    aria-label="Drag-and-drop to reorder"
                  >
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <button
                    type="button"
                    className="btn nav-link"
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="view-body flex-grow-1 overflow-auto">
          {tab.value == 1 && (
            <div className="pd-15">
              <div className="d-flex align-items-center justify-content-end mg-b-10">
                Date Range{' '}
                <SelectHook
                  className="wd-200-f mg-l-10"
                  options={[{ value: 1, label: 'This month' }]}
                />
              </div>
              <div className="view-content-section">
                <div className="view-content-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Traffic by source</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto"
                    data-toggle="collapse"
                    aria-label="collapse"
                    data-target="#insightTraffic"
                  >
                    <ChevronDown />
                    <span className="sr-only">내용 접기</span>
                  </button>
                </div>
                <div id="insightTraffic" className="card card-body collapse show">
                  <div className="row no-gutters">
                    <div className="col-6"></div>
                    <div className="col-6"></div>
                  </div>
                </div>
              </div>

              <div className="view-content-section">
                <div className="view-content-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Page Metrics</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto"
                    data-toggle="collapse"
                    aria-label="collapse"
                    data-target="#insightPageMetrics"
                  >
                    <ChevronDown />
                    <span className="sr-only">내용 접기</span>
                  </button>
                </div>
                <div id="insightPageMetrics" className="collapse show">
                  <div className="table-responsive bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Pages
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Page Views
                          </th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th scope="row" className="bd-b-0-f">
                            Total
                          </th>
                          <td>
                            <span className="h3 mg-r-10 tx-normal">500</span>
                            <span className="tx-12 tx-color-03">
                              <span className="tx-success">
                                <ArrowUp size="12px" />
                                10%
                              </span>{' '}
                              than last month
                            </span>
                          </td>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>Referral</td>
                          <td>
                            <span className="mg-r-10 tx-18">200</span>
                            <span className="tx-12 tx-color-03">
                              <span className="tx-success">
                                <ArrowUp size="12px" />
                                10%
                              </span>{' '}
                              than last month
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Invitee Sign up</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Invitee Reward</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Your Referred Stats</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Transaction</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {tab.value == 2 && (
            <div className="pd-15">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="d-flex align-items-center mg-b-10">
                  Source{' '}
                  <SelectHook
                    className="wd-200-f mg-l-10"
                    options={[{ value: 1, label: 'Campaign' }]}
                  />
                </div>
                <div className="d-flex align-items-center mg-l-15 mg-b-10">
                  Date Range{' '}
                  <SelectHook
                    className="wd-200-f mg-l-10"
                    options={[{ value: 1, label: 'This month' }]}
                  />
                </div>
                <div className="d-flex align-items-center mg-l-15 mg-b-10">
                  Frequency{' '}
                  <SelectHook
                    className="wd-150-f mg-l-10"
                    options={[{ value: 1, label: 'Daily' }]}
                  />
                </div>
              </div>
              <div className="mg-t-10 mg-b-20"></div>
              <div className="table-responsive bg-white bd rounded">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Source
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Total Views
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Referral
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Invitee Sign up
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Invitee Reward
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Your Referred Stats
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Transaction
                      </th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th scope="row" className="bd-b-0-f">
                        All
                      </th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr>
                      <td>Campaign 1</td>
                      <td>300</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Campaign 2</td>
                      <td>200</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {tab.value == 4 && (
            <div className="pd-15">
              <div className="view-content-section">
                <div className="view-content-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Campaign</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto"
                    data-toggle="collapse"
                    aria-label="collapse"
                    data-target="#offerRelated2"
                  >
                    <ChevronDown />
                    <span className="sr-only">내용 접기</span>
                  </button>
                </div>
                <div id="offerRelated2" className="card collapse show">
                  <div className="table-responsive rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Campaign Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Activity
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Owner
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Created on
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Start Date
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            End Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewReward;
