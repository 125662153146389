const LeadDetails = () => {
  return (
    <div className="pd-20">
      <div className="card card-body">
        <div className="form-group">
          <label className="form-item-title">Lead Process</label>
          <div>Process 1</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Lead Scoring Rule</label>
          <div>Rule 1</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Description</label>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed convallis, leo id venenatis
            viverra, mi arcu posuere augue, nec aliquam odio mi a tellus.
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeadDetails;
