import { useState } from 'react';
import Icon from '@base/assets/icons/svg-icons';
import { X, Check, Edit2 } from 'react-feather';

const ReplacementDetail = () => {
  const [showEdit, setShowEdit] = useState<any>();

  return (
    <div className="pd-20">
      {!showEdit && (
        <div className="text-right">
          <button type="button" className="btn btn-link" onClick={() => setShowEdit(true)}>
            <Edit2 className="mg-r-5" />
            Edit
          </button>
        </div>
      )}
      <div className="table-responsive bg-white bd rounded-top">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Image
              </th>
              <th scope="col" className="bd-t-0-f">
                Item
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit Qty
              </th>
              <th scope="col" className="bd-t-0-f">
                Attributes
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit Price
              </th>
              <th scope="col" className="bd-t-0-f">
                Qty Replaced
              </th>
              {showEdit && (
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src="assets/images/notfound.png" alt="" />
                </div>
              </td>
              <td>AAA</td>
              <td>Box</td>
              <td>1</td>
              <td>S,Apple</td>
              <td>￦100,000</td>
              <td>
                {showEdit ? (
                  <input type="number" className="form-control wd-150" defaultValue="35" />
                ) : (
                  '35'
                )}
              </td>
              {showEdit && (
                <td>
                  <button type="button" className="btn btn-icon">
                    <X className="tx-danger" />
                  </button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      {showEdit && (
        <div className="pd-10 bd bd-t-0 rounded-bottom">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select an item"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                {Icon('product')}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="row row-xs mg-t-10">
        <div className="col-md-6">
          <ul className="list-unstyled lh-7 mg-b-0">
            <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
              <span>Items : 35</span>
              <span> ₩ 3,500,000</span>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
            <li className="d-flex justify-content-between">
              <span>Sub-Total</span>
              <span>₩ 3,500,000</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>Tax (10%)</span>
              <span>₩ 350,000</span>
            </li>
            <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
              <strong>Total Amount</strong>
              <strong>₩ 3,850,000</strong>
            </li>
          </ul>
        </div>
      </div>
      {showEdit && (
        <>
          <div className="mg-t-10 text-right">
            <button
              type="button"
              className="btn btn-light mg-r-10"
              onClick={() => setShowEdit(false)}
            >
              <X className="ma-r-5" /> Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={() => setShowEdit(false)}>
              <Check className="ma-r-5" /> Edit
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ReplacementDetail;
