import { useState } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, ArrowLeft, ArrowUp, ArrowDown, Plus } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'Insight',
  },
  {
    value: 2,
    label: 'Submissions',
  },
  {
    value: 3,
    label: 'Related',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewForm = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Online Digital Contents
            <ChevronDown className="mg-l-5" />
          </button>
        </div>

        <h1 className="view-title mg-l-10 lh-1">Form 1</h1>

        <div className="button-group d-flex flex-shrink-0 mg-l-auto">
          <div className="process-buttons mg-r-15">
            <div className="dropdown">
              <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                More
                <ChevronDown className="mg-l-5" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">{Icon('publish')}</span>
                  Publish on the external site
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <button type="button" className="btn btn-primary mg-l-5 rounded-30">
            <Plus size="14" />
            New
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner">
            {/* collapse left aside */}
            {!hideAsideLeft && (
              <button
                type="button"
                className="btn btn-hide-aside-left"
                onClick={() => setHideAsideLeft(true)}
              >
                <LeftCollapse />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>
            )}
            <div className="view-aside-section pd-15">
              <div className="d-flex align-items-center justify-content-center">
                <figure className="img-thumbnail">
                  <img src="https://via.placeholder.com/300x300" alt="" className="img-fit-cover" />
                </figure>
              </div>
              <div className="form-group">
                <label className="form-item-title">Stage</label>
                <div>&nbsp;</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Enable Date</label>
                <div>2021/09/01</div>
              </div>
            </div>

            {/* Basic Info */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Summary</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#basicInfoSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="basicInfoSection" className="view-aside-section-body collapse show">
                <div className="form-group">
                  <label className="form-item-title">Form Type</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Content Type</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Language</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Created on</label>
                  <div>2021/09/01</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Last updated</label>
                  <div>2021/09/01</div>
                </div>
              </div>
            </div>

            {/* Submission settings */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Submission settings</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0 collapsed"
                  data-toggle="collapse"
                  data-target="#submissionSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="submissionSection" className="view-aside-section-body collapse">
                <div className="form-group">
                  <label className="form-item-title">
                    What should display after a visitor submits this form
                  </label>
                  <div>Link(Landing Page)</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Submission behavior</label>
                  <div>Create Lead</div>
                </div>
              </div>
            </div>

            {/* Pop-up settings */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Pop-up settings</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0 collapsed"
                  data-toggle="collapse"
                  data-target="#popupSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="popupSection" className="view-aside-section-body collapse">
                <div className="form-group">
                  <label className="form-item-title">Type</label>
                  <div>Form on the Pop-up</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">
                    Pop-up form should appear on the page when you select the following option
                  </label>
                  <div>On scrolled halfway down the page</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('nav-item', {
                      active: item.value == tab.value,
                    })}
                  >
                    <button
                      type="button"
                      className="btn btn-xs btn-move-tab"
                      aria-label="Drag-and-drop to reorder"
                    >
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <button
                      type="button"
                      className="btn nav-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="view-body">
            {/* show left aside */}
            {hideAsideLeft && (
              <button
                type="button"
                className="btn btn-show-aside-left"
                onClick={() => setHideAsideLeft(false)}
              >
                <RightCollapse />
                <span className="sr-only">좌측 사이드 패널 열기</span>
              </button>
            )}
            {/* Insight */}
            {tab.value == 1 && (
              <div className="pd-20">
                <div className="d-flex align-items-center justify-content-end mg-b-10">
                  Date Range{' '}
                  <SelectHook
                    className="wd-200-f mg-l-10"
                    options={[{ value: 1, label: 'This month' }]}
                  />
                </div>
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Traffic by source</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#insightTraffic"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="insightTraffic" className="card card-body collapse show">
                    <div className="row no-gutters">
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Page Metrics</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#insightPageMetrics"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="insightPageMetrics" className="collapse show">
                    <div className="row row-xs">
                      <div className="col-sm-6 col-lg-4">
                        <div className="card card-body">
                          <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                            Views
                          </div>
                          <span className="h3 tx-normal">500</span>
                          <div className="tx-12 tx-color-03">
                            <span className="tx-success">
                              <ArrowUp size="12px" />
                              10%
                            </span>{' '}
                            than last month
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="card card-body">
                          <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                            Conversion rate
                          </div>
                          <span className="h3 tx-normal">10%</span>
                          <div className="tx-12 tx-color-03">
                            <span className="tx-success">
                              <ArrowUp size="12px" />
                              10%
                            </span>{' '}
                            than last month
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                          <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                            Submission
                          </div>
                          <span className="h3 tx-normal">50</span>
                          <div className="tx-12 tx-color-03">
                            <span className="tx-danger">
                              <ArrowDown size="12px" />
                              10%
                            </span>{' '}
                            than last month
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Submission */}
            {tab.value == 2 && (
              <div className="pd-20">
                <div className="d-flex flex-wrap align-items-center justify-content-end">
                  <div className="d-flex align-items-center mg-b-10">
                    Source{' '}
                    <SelectHook
                      className="wd-200-f mg-l-10"
                      options={[{ value: 1, label: 'Campaign' }]}
                    />
                  </div>
                  <div className="d-flex align-items-center mg-l-15 mg-b-10">
                    Date Range{' '}
                    <SelectHook
                      className="wd-200-f mg-l-10"
                      options={[{ value: 1, label: 'This month' }]}
                    />
                  </div>
                  <div className="d-flex align-items-center mg-l-15 mg-b-10">
                    Frequency{' '}
                    <SelectHook
                      className="wd-150-f mg-l-10"
                      options={[{ value: 1, label: 'Daily' }]}
                    />
                  </div>
                </div>
                <div className="mg-t-10 mg-b-20"></div>
                <div className="table-responsive bg-white bd rounded">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Source
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Views
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Submission
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          CTA Clicks
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Bounce rate
                        </th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th scope="row" className="bd-b-0-f">
                          All
                        </th>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td>Campaign 1</td>
                        <td>300</td>
                        <td>&nbsp;</td>
                        <td>100</td>
                        <td>10%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* Related */}
            {tab.value == 3 && (
              <div className="pd-20">
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">
                      Click to Action linked with this Form
                    </span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#ctaRelated1"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="ctaRelated1" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              CTA Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              CTA Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Link Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Created on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Stage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">
                      Landing Page embedded in this Form
                    </span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#ctaRelated2"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="ctaRelated2" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Landing Page Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Product
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Created on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Stage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">External Site</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#ctaRelated3"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="ctaRelated3" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Site Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Link Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Created on
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewForm;
