/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Settings,
  Menu,
  X,
  MoreHorizontal,
  Upload,
  Download,
  RotateCcw,
  Calendar,
} from 'react-feather';

// import "CrmCss/desktop/theme1/theme1.scss"

import Pagination from '../../../../component/desktop/pagination-new';
import NewProduct from '../modal/newProduct';
import NewItem from '../modal/newItem';
import NewComponent from '../modal/newComponent';
import NewUnit from '../modal/newUnit';
import NewPriceList from '../modal/newPriceList';
import NewDiscount from '../modal/newDiscount';
import NewTradeAgreement from '../modal/newTradeAgreement';

const ComList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Components</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
          </div>
          <div className="btn-group dropdown mg-l-auto">
            <button
              type="button"
              className="btn btn-primary pd-x-10"
              data-toggle="modal"
              data-target="#newComponent"
            >
              <Plus />
              <span className="sr-only">추가</span>
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle-split pd-x-5"
              data-toggle="dropdown"
            >
              <ChevronDown />
              <span className="sr-only">추가 옵션 열기</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newProduct"
              >
                Product
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newItem"
              >
                Item
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newComponent"
              >
                Component
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newUnit"
              >
                Unit
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newPriceList"
              >
                Price List
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newDiscount"
              >
                Discount
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newTradeAgreement"
              >
                Trade Agreement
              </button>
            </div>
          </div>
          <div className="button-group mg-l-5">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="More"
                data-toggle="dropdown"
              >
                <MoreHorizontal />
                <ChevronDown />
                <span className="sr-only">선택 옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item">
                  <Upload className="mg-r-5" />
                  Import
                </button>
                <button className="dropdown-item">
                  <Download className="mg-r-5" />
                  Export
                </button>
              </div>
            </div>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                All Components
                <ChevronDown />
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item active">All Components</button>
                <button className="dropdown-item">My Components</button>
                <button className="dropdown-item">My Group Components</button>
                <button className="dropdown-item">Unassigned Components</button>
                <button className="dropdown-item">Purchased Components</button>
                <button className="dropdown-item">Produced Components</button>
                <button className="dropdown-item">Can be Sold Components</button>
                <button className="dropdown-item">Deleted Components</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Created This Month
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300">
                <button className="dropdown-item">All</button>
                <div className="dropdown-divider"></div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold"
                  data-toggle="collapse"
                  data-target="#dateBy1"
                >
                  <span className="mg-r-5">Created This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <div id="dateBy1" className="collapse show">
                  <button type="button" className="dropdown-item">
                    This Year
                  </button>
                  <button type="button" className="dropdown-item">
                    This Quarter
                  </button>
                  <button type="button" className="dropdown-item active">
                    This Month
                  </button>
                  <button type="button" className="dropdown-item">
                    This Week
                  </button>
                  <button type="button" className="dropdown-item">
                    Today
                  </button>
                  <button
                    type="button"
                    className="dropdown-item btn d-flex align-items-center"
                    data-toggle="collapse"
                    data-target="#dateBy1-custom"
                  >
                    <span className="mg-r-5">Custom</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                    <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy2"
                >
                  <span className="mg-r-5">Updated This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy3"
                >
                  <span className="mg-r-5">Sales Start Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy3"
                >
                  <span className="mg-r-5">Sales End This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy3"
                >
                  <span className="mg-r-5">Manufactured Date</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy3"
                >
                  <span className="mg-r-5">Expired Date</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filters
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Base Unit</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Attribute</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Component Group</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Component Type</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Assigned Rep</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Active</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Rate</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
              </div>
            </div>

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_asc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by:
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item">
                    Component Name
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* Search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* for table list column settings */}
          {listType.list && (
            <div className="dropdown list-filter-item mg-l-5">
              <button type="button" className="btn btn-icon btn-white" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="pricelistColumnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="pricelistColumnCheck1">
                        Type
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">10</strong> / 10
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {/* list */}
          {listType.list && (
            <div className="table-responsive">
              <table className="table dataTable">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="componentCheckAll"
                        />
                        <label className="custom-control-label" htmlFor="componentCheckAll"></label>
                      </div>
                    </th>
                    <th scope="col">Image</th>
                    <th scope="col" className="sorting">
                      Component Name
                    </th>
                    <th scope="col" className="sorting">
                      Component Type
                    </th>
                    <th scope="col" className="sorting">
                      Unit Name
                    </th>
                    <th scope="col" className="sorting">
                      Attribute
                    </th>
                    <th scope="col" className="sorting">
                      Linked Barcode
                    </th>
                    <th scope="col" className="sorting">
                      Dimension
                    </th>
                    <th scope="col">Active</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="componentCheck1"
                        />
                        <label className="custom-control-label" htmlFor="componentCheck1"></label>
                      </div>
                    </td>
                    <td>
                      <div className="d-inline-block avatar avatar-sm mg-r-10 bg-gray-200 rounded">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi82dqG39u-evEOo9ai2yPJ_p1lPP3WUU5TQ&usqp=CAU"
                          alt=""
                        />
                      </div>
                    </td>
                    <td>
                      <a href="/component/desktop/theme1/view">Component 1</a>
                    </td>
                    <td>Part</td>
                    <td>EA</td>
                    <td>Small</td>
                    <td>A12930495</td>
                    <td>10x16.5x23 cm</td>
                    <td>
                      <div className="grid-list-item-col-content">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="pricelistStatusSwitch1"
                            defaultChecked
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="pricelistStatusSwitch1"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* grid list */}
          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="componentCheck1"
                      />
                      <label className="custom-control-label" htmlFor="componentCheck1"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-pic">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi82dqG39u-evEOo9ai2yPJ_p1lPP3WUU5TQ&usqp=CAU"
                      alt=""
                    />
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/component/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        Component 1
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Component Type:</span>
                        <span className="grid-list-item-col-content">Part</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Unit Name:</span>
                        <span className="grid-list-item-col-content">EA</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Attribute:</span>
                        <span className="grid-list-item-col-content">Small</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Linked Barcode:</span>
                        <span className="grid-list-item-col-content">A12930495</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Dimension:</span>
                        <span className="grid-list-item-col-content">10x16.5x23 cm</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Active:</span>
                        <div className="grid-list-item-col-content">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="pricelistStatusSwitch1"
                              defaultChecked
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="pricelistStatusSwitch1"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>

      <NewProduct />
      <NewItem />
      <NewComponent />
      <NewUnit />
      <NewPriceList />
      <NewDiscount />
      <NewTradeAgreement />
    </>
  );
};

export default ComList;
