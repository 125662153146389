/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';

import { Button, Checkbox, Input, Select as SelectHook } from '@base/components/form';

import { ChevronDown, Plus, X } from 'react-feather';
import AssignmentType from './containers/assignment-type';

const ACTIVITYTYPES = [
  { value: 'call', label: 'Call' },
  { value: 'task', label: 'Task' },
  { value: 'meeting', label: 'Meeting' },
  { value: 'dm', label: 'DM' },
  { value: 'sms', label: 'SMS' },
  { value: 'email', label: 'Email' },
  { value: 'fax', label: 'Fax' },
];

const EditStep = () => {
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [activityType, setActivityType] = useState(ACTIVITYTYPES[0]);
  const [activityType2, setActivityType2] = useState(ACTIVITYTYPES[0]);
  const [automationSleeping, setAutomationSleeping] = useState(false);
  const [addAutomationSleeping, setAddAutomationSleeping] = useState(false);
  const [automationExcute, setAutomationExcute] = useState(false);
  const [showAutomationExcuteActivity, setShowAutomationExcuteActivity] = useState(false);
  const [addAutomationExcuteActivity, setAddAutomationExcuteActivity] = useState(false);

  const [actionType, setActionType] = useState('manual');
  const handleActionTypeChange = (e: any) => {
    const { value } = e.target;
    setActionType(value);
  };

  const [scheduleType, setScheduleType] = useState<any>({
    immediately: true,
    delay: false,
  });

  return (
    <div
      id="editStepModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Edit Step</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Step type</label>
              <div>Action</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Step Name</label>
              <Input defaultValue="New" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <Input />
            </div>
            <div className="form-group">
              <label className="form-item-title">Stage</label>
              <SelectHook className="wd-300-f" options={[{ value: 1, label: 'Open' }]} />
            </div>
            <div className="form-group">
              <label className="form-item-title">Action</label>
              <SelectHook className="wd-300-f" defaultValue={[{ value: 1, label: 'New-Quote' }]} />
            </div>
            <div className="form-group">
              <label className="form-item-title">Template</label>
              <div className="d-flex align-items-center">
                <SelectHook
                  className="wd-300-f"
                  defaultValue={[{ value: 1, label: 'Template 1' }]}
                />
                <button type="button" className="btn btn-link">
                  preview
                </button>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Content</label>
              <SelectHook />
            </div>
            <AssignmentType />
            <div className="form-group">
              <label className="form-item-title">Due</label>
              <div className="d-flex align-items-center">
                <Input type="number" className="wd-100 mg-r-10" />
                <SelectHook
                  className="wd-150-f"
                  options={[
                    { value: 1, label: 'hour(s)' },
                    { value: 2, label: 'day(s)' },
                    { value: 3, label: 'week(s)' },
                    { value: 4, label: 'month(s)' },
                  ]}
                  defaultValue={[{ value: 1, label: 'hour(s)' }]}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="form-item-title">Buttons/Status</label>
              <div className="table-responsive bd rounded">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Name
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        View
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Event
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Status
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Direction
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Next Step
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Property
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Button Property
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>-</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    {showAddBtn && (
                      <tr>
                        <td>
                          <input type="text" className="form-control wd-200" />
                        </td>
                        <td>
                          <SelectHook
                            className="wd-150-f"
                            defaultValue={[{ value: 1, label: 'Single' }]}
                          />
                        </td>
                        <td>
                          <SelectHook
                            className="wd-150-f"
                            defaultValue={[{ value: 1, label: 'Click' }]}
                          />
                        </td>
                        <td>
                          <Input className="wd-200" />
                        </td>
                        <td>
                          <SelectHook
                            className="wd-150-f"
                            defaultValue={[{ value: 1, label: 'Forward' }]}
                          />
                        </td>
                        <td>
                          <SelectHook className="wd-150-f" />
                        </td>
                        <td>
                          <SelectHook
                            className="wd-150-f"
                            defaultValue={[{ value: 1, label: 'None' }]}
                          />
                        </td>
                        <td>
                          <SelectHook
                            className="wd-150-f"
                            defaultValue={[{ value: 1, label: 'Todo Close' }]}
                          />
                        </td>
                        <td>
                          <Button
                            size="xs"
                            icon="X"
                            color=""
                            iconClass="tx-danger"
                            className="btn-icon pd-0"
                            onClick={() => setShowAddBtn(false)}
                            data-han-tooltip="Delete"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Button
                name="Add a button"
                color="link"
                icon="Plus"
                className="mg-t-10"
                onClick={() => setShowAddBtn(true)}
              />
            </div>

            {/* Automatic
                    <div className="form-group">
                        <label className="form-item-title">Status</label>
                        <div className="table-responsive bd rounded">
                            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                                <thead>
                                    <tr>
                                        <th scope="col" className="bd-t-0-f text-nowrap">Name</th>
                                        <th scope="col" className="bd-t-0-f text-nowrap">Event</th>
                                        <th scope="col" className="bd-t-0-f text-nowrap">Direction</th>
                                        <th scope="col" className="bd-t-0-f text-nowrap">Next Step</th>
                                        <th scope="col" className="bd-t-0-f text-nowrap">Property</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="text" className="form-control" defaultValue="Neeeds" />
                                        </td>
                                        <td>
                                            <SelectHook 
                                                options = {[
                                                    {value: 1, label: "Criteria Result"}
                                                ]}
                                            />
                                        </td>
                                        <td>
                                            <SelectHook
                                                options = {[
                                                    {value: 1, label: "Forward"}
                                                ]}
                                            />
                                        </td>
                                        <td>
                                            <SelectHook
                                                options = {[
                                                    {value: 1, label: "Purchase Completed"}
                                                ]}
                                            />
                                        </td>
                                        <td>
                                            <SelectHook />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}

            <div className="form-group">
              <label className="form-item-title">Automation</label>
              <Checkbox
                label="Sleeping"
                onChange={() => setAutomationSleeping(!automationSleeping)}
              />
              {automationSleeping && (
                <>
                  <div className="d-flex flex-wrap align-items-center mg-l-25">
                    If this Step is sleeping for
                    <Input type="number" className="wd-100 mg-x-10" />
                    <SelectHook
                      className="wd-150-f mg-r-10"
                      options={[
                        { value: 1, label: 'month(s)' },
                        { value: 2, label: 'hour(s)' },
                        { value: 3, label: 'day(s)' },
                        { value: 4, label: 'week(s)' },
                      ]}
                      defaultValue={[{ value: 1, label: 'month(s)' }]}
                    />
                  </div>
                  <div className="card mg-y-10 mg-l-25">
                    <div className="card-header pd-y-10 bg-light">
                      Staying Status
                      <SelectHook
                        className="wd-150-f mg-l-10"
                        options={[
                          { value: 1, label: 'All' },
                          { value: 2, label: 'Status 1' },
                          { value: 3, label: 'Status 2' },
                        ]}
                        defaultValue={[{ value: 1, label: 'All' }]}
                      />
                    </div>
                    <div className="card-body">
                      <label className="form-item-title">Execute</label>
                      <div className="d-flex align-items-center mg-b-5">
                        <Checkbox label="Notification" />
                        <SelectHook
                          className="wd-200-f mg-x-5"
                          options={[
                            { value: 1, label: 'Notification 1' },
                            { value: 2, label: 'Notification 2' },
                          ]}
                          defaultValue={[{ value: 1, label: 'Notification 1' }]}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <Checkbox label="Change Step" />
                        <SelectHook
                          className="wd-200-f mg-x-5"
                          defaultValue={[{ value: 1, label: 'Step 1' }]}
                        />
                      </div>
                    </div>
                  </div>
                  {addAutomationSleeping && (
                    <div className="card mg-y-10 mg-l-25">
                      <div className="card-header pd-y-10 bg-light">
                        Staying Status
                        <SelectHook
                          className="wd-150-f mg-l-10"
                          options={[
                            { value: 1, label: 'All' },
                            { value: 2, label: 'Status 1' },
                            { value: 3, label: 'Status 2' },
                          ]}
                          defaultValue={[{ value: 1, label: 'All' }]}
                        />
                        <Button
                          size="xs"
                          color=""
                          icon="X"
                          iconClass="tx-danger"
                          className="btn-icon mg-l-auto"
                          onClick={() => setAddAutomationSleeping(false)}
                          data-han-tooltip="Delete"
                        />
                      </div>
                      <div className="card-body">
                        <label className="form-item-title">Execute</label>
                        <div className="d-flex align-items-center mg-b-5">
                          <Checkbox label="Notification" />
                          <SelectHook
                            className="wd-200-f mg-x-5"
                            defaultValue={[{ value: 1, label: 'Notification 1' }]}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <Checkbox label="Change Step" />
                          <SelectHook
                            className="wd-200-f mg-x-5"
                            defaultValue={[{ value: 1, label: 'Step 1' }]}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <Button
                    name="Add"
                    icon="Plus"
                    color="link"
                    className="mg-l-25"
                    onClick={() => setAddAutomationSleeping(true)}
                  />
                </>
              )}

              <Checkbox
                label="Activity"
                className=" mg-t-10"
                onChange={() => setAutomationExcute(!automationExcute)}
              />
              {automationExcute && (
                <>
                  <div className="card mg-y-10 mg-l-25">
                    <div className="card-header pd-y-10 bg-light">
                      Trigger: <strong>Before</strong> This Step
                    </div>
                    <div className="card-body">
                      <Checkbox
                        label="Activity"
                        className=" mg-t-10"
                        onChange={() =>
                          setShowAutomationExcuteActivity(!showAutomationExcuteActivity)
                        }
                      />
                      {showAutomationExcuteActivity && (
                        <>
                          <div className="mg-y-10 mg-l-25 pd-10 bd rounded">
                            <div className="form-group">
                              <label className="form-item-title">Activity Type</label>
                              <div className="dropdown wd-200">
                                <div className="input-group" data-toggle="dropdown">
                                  <div className="form-control">{activityType.label}</div>
                                  <div className="input-group-append">
                                    <button type="button" className="btn">
                                      <ChevronDown />
                                      <span className="sr-only">선택 옵션 열기</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="dropdown-menu wd-100p">
                                  {ACTIVITYTYPES.map((item, index) => (
                                    <button
                                      key={index}
                                      className={classnames('dropdown-item', {
                                        active: item.value == activityType.value,
                                      })}
                                      onClick={(e) => {
                                        setActivityType(item);
                                        setActionType('manual');
                                      }}
                                    >
                                      {item.label}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {(activityType.value === 'sms' || activityType.value === 'email') && (
                              <div className="form-group">
                                <label className="form-item-title d-block">Action Type</label>
                                <div className="custom-control custom-radio d-inline-block mg-r-10">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="actionTypeOption"
                                    id="actionTypeOption1"
                                    value="manual"
                                    checked={actionType === 'manual'}
                                    onChange={(e) => handleActionTypeChange(e)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="actionTypeOption1"
                                  >
                                    Manual
                                  </label>
                                </div>
                                <div className="custom-control custom-radio d-inline-block mg-r-10">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="actionTypeOption"
                                    id="actionTypeOption2"
                                    value="automatic"
                                    checked={actionType === 'automatic'}
                                    onChange={(e) => handleActionTypeChange(e)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="actionTypeOption2"
                                  >
                                    Automatic
                                  </label>
                                </div>
                              </div>
                            )}
                            {activityType.value !== 'fax' && (
                              <>
                                <div className="form-group">
                                  <label className="form-item-title">Template</label>
                                  <SelectHook className="wd-200-f" />
                                </div>
                                {actionType !== 'automatic' && (
                                  <>
                                    <AssignmentType />
                                    <div className="form-group">
                                      <label className="form-item-title">Due</label>
                                      <div className="d-flex align-items-center">
                                        <input
                                          type="number"
                                          className="form-control wd-100 mg-r-10"
                                        />
                                        <SelectHook
                                          className="wd-150-f"
                                          options={[
                                            { value: 1, label: 'hour(s)' },
                                            { value: 2, label: 'day(s)' },
                                            { value: 3, label: 'week(s)' },
                                            { value: 4, label: 'month(s)' },
                                          ]}
                                          defaultValue={[{ value: 1, label: 'hour(s)' }]}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            {activityType.value === 'fax' && (
                              <div className="form-group">
                                <label className="form-item-title">Content</label>
                                <SelectHook />
                              </div>
                            )}
                            {(activityType.value === 'fax' || actionType === 'automatic') && (
                              <>
                                <div className="form-group">
                                  <label className="form-item-title">Send Schedule</label>
                                  <div className="dropdown wd-200">
                                    <div className="input-group" data-toggle="dropdown">
                                      <div className="form-control">
                                        {scheduleType.immediately ? 'Immediately' : 'Delay'}
                                      </div>
                                      <div className="input-group-append">
                                        <button type="button" className="btn">
                                          <ChevronDown />
                                          <span className="sr-only">선택 옵션 열기</span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="dropdown-menu wd-100p">
                                      <button
                                        type="button"
                                        className={classnames('dropdown-item', {
                                          active: scheduleType.immediately,
                                        })}
                                        onClick={() => setScheduleType({ immediately: true })}
                                      >
                                        Immediately
                                      </button>
                                      <button
                                        type="button"
                                        className={classnames('dropdown-item', {
                                          active: scheduleType.delay,
                                        })}
                                        onClick={() => setScheduleType({ delay: true })}
                                      >
                                        Delay
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {scheduleType.delay && (
                                  <div className="form-group">
                                    <label className="form-item-title">Delay</label>
                                    <div className="d-flex align-items-center">
                                      After{' '}
                                      <input
                                        type="number"
                                        className="form-control wd-100 mg-x-10"
                                      />
                                      <SelectHook
                                        className="wd-150-f"
                                        options={[
                                          { value: 1, label: 'hour(s)' },
                                          { value: 2, label: 'day(s)' },
                                        ]}
                                        defaultValue={[{ value: 1, label: 'hour(s)' }]}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          {addAutomationExcuteActivity && (
                            <div className="mg-y-10 mg-l-25 pd-10 bd rounded">
                              <div className="text-right">
                                <button
                                  type="button"
                                  className="btn btn-icon"
                                  onClick={() => setAddAutomationExcuteActivity(false)}
                                >
                                  <X className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Activity Type</label>
                                <div className="dropdown wd-200">
                                  <div className="input-group" data-toggle="dropdown">
                                    <div className="form-control">{activityType2.label}</div>
                                    <div className="input-group-append">
                                      <button type="button" className="btn">
                                        <ChevronDown />
                                        <span className="sr-only">선택 옵션 열기</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="dropdown-menu wd-100p">
                                    {ACTIVITYTYPES.map((item, index) => (
                                      <button
                                        key={index}
                                        className={classnames('dropdown-item', {
                                          active: item.value == activityType2.value,
                                        })}
                                        onClick={(e) => {
                                          setActivityType2(item);
                                          setActionType('manual');
                                        }}
                                      >
                                        {item.label}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              {(activityType2.value === 'sms' ||
                                activityType.value === 'email') && (
                                <div className="form-group">
                                  <label className="form-item-title d-block">Action Type</label>
                                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      name="actionTypeOption"
                                      id="actionTypeOption1"
                                      value="manual"
                                      checked={actionType === 'manual'}
                                      onChange={(e) => handleActionTypeChange(e)}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="actionTypeOption1"
                                    >
                                      Manual
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      name="actionTypeOption"
                                      id="actionTypeOption2"
                                      value="automatic"
                                      checked={actionType === 'automatic'}
                                      onChange={(e) => handleActionTypeChange(e)}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="actionTypeOption2"
                                    >
                                      Automatic
                                    </label>
                                  </div>
                                </div>
                              )}
                              {activityType2.value !== 'fax' && (
                                <>
                                  <div className="form-group">
                                    <label className="form-item-title">Template</label>
                                    <SelectHook className="wd-200-f" />
                                  </div>
                                  {actionType !== 'automatic' && (
                                    <>
                                      <AssignmentType />
                                      <div className="form-group">
                                        <label className="form-item-title">Due</label>
                                        <div className="d-flex align-items-center">
                                          <Input type="number" className="wd-100 mg-r-10" />
                                          <SelectHook
                                            className="wd-150-f"
                                            options={[
                                              { value: 1, label: 'hour(s)' },
                                              { value: 2, label: 'day(s)' },
                                              { value: 3, label: 'week(s)' },
                                              { value: 4, label: 'month(s)' },
                                            ]}
                                            defaultValue={[{ value: 1, label: 'hour(s)' }]}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                              {activityType2.value === 'fax' && (
                                <div className="form-group">
                                  <label className="form-item-title">Content</label>
                                  <SelectHook />
                                </div>
                              )}
                              {(activityType2.value === 'fax' || actionType === 'automatic') && (
                                <>
                                  <div className="form-group">
                                    <label className="form-item-title">Send Schedule</label>
                                    <div className="dropdown wd-200">
                                      <div className="input-group" data-toggle="dropdown">
                                        <div className="form-control">
                                          {scheduleType.immediately ? 'Immediately' : 'Delay'}
                                        </div>
                                        <div className="input-group-append">
                                          <button type="button" className="btn">
                                            <ChevronDown />
                                            <span className="sr-only">선택 옵션 열기</span>
                                          </button>
                                        </div>
                                      </div>
                                      <div className="dropdown-menu wd-100p">
                                        <button
                                          type="button"
                                          className={classnames('dropdown-item', {
                                            active: scheduleType.immediately,
                                          })}
                                          onClick={() => setScheduleType({ immediately: true })}
                                        >
                                          Immediately
                                        </button>
                                        <button
                                          type="button"
                                          className={classnames('dropdown-item', {
                                            active: scheduleType.delay,
                                          })}
                                          onClick={() => setScheduleType({ delay: true })}
                                        >
                                          Delay
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  {scheduleType.delay && (
                                    <div className="form-group">
                                      <label className="form-item-title">Delay</label>
                                      <div className="d-flex align-items-center">
                                        After <Input type="number" className="wd-100 mg-x-10" />
                                        <SelectHook
                                          className="wd-100-f"
                                          options={[
                                            { value: 1, label: 'hour(s)' },
                                            { value: 2, label: 'day(s)' },
                                          ]}
                                          defaultValue={[{ value: 1, label: 'hour(s)' }]}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}

                          <Button
                            name="Add"
                            icon="Plus"
                            color="link"
                            onClick={() => setAddAutomationExcuteActivity(true)}
                          />
                        </>
                      )}
                      <div className="d-flex align-items-center mg-t-5">
                        <Checkbox label="Notification" />
                        <SelectHook
                          className="wd-200-f mg-x-5"
                          defaultValue={[{ value: 1, label: 'Notification 1' }]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card mg-y-10 mg-l-25">
                    <div className="card-header pd-y-10 bg-light">
                      Trigger: <strong>After</strong> This Step
                    </div>
                    <div className="card-body">
                      <Checkbox label="Activity" />
                      {/* Activity 체크 시 상단과 동일 */}
                      <div className="d-flex align-items-center mg-t-5">
                        <Checkbox label="Notification" />
                        <SelectHook
                          className="wd-200-f mg-x-5"
                          defaultValue={[{ value: 1, label: 'Notification 1' }]}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <Button name="Cancel" color="light" data-dismiss="modal" />
            <Button name="Edit" color="success" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStep;
