import { Plus, X } from 'react-feather';

const BroadcastContents = () => {
  return (
    <div
      id="broadcastContentsModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Broadcast Contents</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="d-flex align-items-center mg-b-10">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="broadcastContentSwitch"
                />
                <label className="custom-control-label" htmlFor="broadcastContentSwitch">
                  Video
                </label>
              </div>
              <span className="mg-l-auto tx-semibold">Total: 5</span>
            </div>
            <div className="d-flex align-items-center mg-b-20">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="broadcastContentSwitch2"
                />
                <label className="custom-control-label" htmlFor="broadcastContentSwitch2">
                  Audio
                </label>
              </div>
              <span className="mg-l-auto tx-semibold">Total: 5</span>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing Broadcast Contents</label>
              <div className="mg-b-10 text-right tx-success">Active : 10</div>
              <div className="table-responsive bg-white bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        File Type
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Number Implemented
                      </th>
                      <th scope="col" className="wd-70 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Video</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Audio</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                <input type="text" className="form-control mg-r-5" placeholder="Enter the type" />
                <button type="button" className="btn btn-primary flex-shrink-0">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </div>
              <div className="mg-t-10">
                File Location: marketing Resources/Offline Digital Contents
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing Distribution/Platform</label>
              <div className="table-responsive bg-white bd rounded">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        File Type
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Company Activity
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Broadcast Media
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Video</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Audio</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-lg btn-success">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastContents;
