import { X } from 'react-feather';

const InstructionView = () => {
  return (
    <>
      <div
        id="instructionViewModal"
        className="modal fade right"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title">Instruction</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <div>&nbsp;</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Duration</label>
                <div>3hours (2021-04-09 11:00)</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Instruction</label>
                <div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi faucibus pharetra
                  dui vitae auctor. Donec condimentum ante vel velit porta, et sagittis mi
                  elementum. Nunc vehicula dapibus nisl. Duis bibendum fringilla pulvinar. Phasellus
                  quis lacus quis leo vehicula iaculis rhoncus a sem. Interdum et malesuada fames ac
                  ante ipsum primis in faucibus. Proin ut libero porta, tincidunt eros eget, sodales
                  sapien. Nulla nibh risus, laoreet sit amet metus in, finibus molestie lectus. Orci
                  varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructionView;
