import { Input } from '@base/components/form';
import AssignmentType from './containers/assignment-type';
import Due from './containers/due';
import ManualStatus from './containers/manualStatus';

const StepStatus = () => {
  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Step Type</label>
        <div>Status</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Status Name</label>
        <Input />
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Property</label>
        <Input />
      </div>
      <AssignmentType />
      <Due />
      <ManualStatus />
    </>
  );
};

export default StepStatus;
