/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Menu,
  RotateCcw,
  X,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import Pagination from '../../../../component/desktop/pagination-new';

//import "CrmCss/desktop/theme1/theme1.scss";

const MList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Marketing List</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item">
                <a role="button">Account</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Contact</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Segment</a>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/marketing-list/desktop/theme1/list">Marketing List</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/buyer-persona/desktop/theme1/list">Buyer Persona</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/icp/desktop/theme1/list">Ideal Customer Profile</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/blocklist/desktop/theme1/list">Blocklist</a>
              </div>
            </div>
          </div>
          <div className="button-group mg-l-auto">
            <a
              href="/crm/demo-page/ui/marketing-list/desktop/theme1/write"
              className="btn btn-primary btn-icon"
              aria-label="add"
            >
              <Plus />
              <span className="sr-only">Add</span>
            </a>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Group by: ?
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">?</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Date by: ?
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">?</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filter: ?
                <ChevronDown />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item">?</button>
              </div>
            </div>

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Name
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item active">
                    List Name
                  </a>
                  <a role="button" className="dropdown-item">
                    Type
                  </a>
                </div>
              </div>
            )}
          </div>

          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* for table list column settings */}
          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {listType.list && (
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered dataTable mg-b-0 bd-0">
                <thead>
                  <tr>
                    <th scope="col" className="wd-30">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="marketingListCheckAll"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="marketingListCheckAll"
                        ></label>
                      </div>
                    </th>
                    <th scope="col" className="sorting_desc">
                      List Name
                    </th>
                    <th scope="col" className="sorting">
                      Type
                    </th>
                    <th scope="col" className="sorting">
                      Members
                    </th>
                    <th scope="col" className="sorting">
                      Purpose
                    </th>
                    <th scope="col" className="sorting">
                      Owner
                    </th>
                    <th scope="col" className="sorting">
                      Due Date
                    </th>
                    <th scope="col" className="sorting">
                      Created on
                    </th>
                    <th scope="col" className="sorting">
                      Last used on
                    </th>
                    <th scope="col" className="sorting">
                      Active
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="marketingListCheck1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="marketingListCheck1"
                        ></label>
                      </div>
                    </td>
                    <td className="sorting-cell">
                      <Link
                        to="/crm/demo-page/ui/marketing-list/desktop/theme1/view"
                        className="link-02"
                      >
                        List 1
                      </Link>
                    </td>
                    <td>Static</td>
                    <td>50</td>
                    <td>&nbsp;</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2020/11/17</td>
                    <td>2020/11/17</td>
                    <td>2020/11/17</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="marketingListSwitch1"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="marketingListSwitch1"
                        ></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* grid list */}
          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="marketingListCheck1"
                      />
                      <label className="custom-control-label" htmlFor="marketingListCheck1"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header is-editable">
                      <a
                        href="/crm/demo-page/ui/marketing-list/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        List Name 1
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Type:</span>
                        <span className="grid-list-item-col-content">Static</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Members:</span>
                        <span className="grid-list-item-col-content">50</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Purpose:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Owner:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">MSR</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Due Date:</span>
                        <span className="grid-list-item-col-content">2020/11/17</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Created on:</span>
                        <span className="grid-list-item-col-content">2020/11/17</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Last used on:</span>
                        <span className="grid-list-item-col-content">2020/11/17</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Active:</span>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="marketingListSwitch1"
                            defaultChecked
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="marketingListSwitch1"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>
    </>
  );
};

export default MList;
