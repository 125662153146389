import { Button, Checkbox, Input, RadioGroup, Select, Switch } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import classNames from 'classnames';
import { useState } from 'react';
import { ChevronUp, User } from 'react-feather';

const TABS = [
  { value: 1, label: 'Team Members' },
  { value: 2, label: 'Sales Commission' },
  { value: 3, label: 'Goals' },
  { value: 4, label: 'Report' },
];

const NewSalesTeam = () => {
  const [tab, setTab] = useState(TABS[0]);

  const GOALS_OPTIONS = [
    { value: 'team', label: 'Team Goals' },
    { value: 'user', label: 'User Goals' },
  ];
  const [goalsOption, setGoalsOption] = useState(GOALS_OPTIONS);
  const [selectedGoal, setSelectedGoal] = useState(goalsOption[0].value);
  //change
  const handleGoalChange = (newOption: any) => {
    setSelectedGoal(newOption.value);
  };

  return (
    <>
      <div id="newSalesTeam" className="modal fade right">
        <div className="modal-dialog wd-auto">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title h6">Create Sales Team</h1>
              <Button close data-dismiss="modal" />
            </div>
            <div className="modal-body pd-b-15-f overflow-auto">
              <div className="form-group">
                <label>Sales Team name</label>
                <Input type="text" />
              </div>
              <div className="form-group">
                <label>Team Leader</label>
                <UserAutoComplete showAvatar />
              </div>
              <div className="form-group">
                <label>Description</label>
                <Input type="text" />
              </div>
              <div className="form-group">
                <label>Price List</label>
                <Select />
              </div>
              <div className="form-group">
                <label>Email Alias</label>
                <Input type="mail" />
              </div>
              <div className="form-group">
                <label>Assignment</label>
                <Checkbox label="Capacity per user" />
                <div className="mg-l-30">
                  <div className="tx-color-03">It will be working to set</div>
                  <Checkbox label="Load Balance by Capacity Team on Assignment Rule" />
                </div>
              </div>
              <div className="form-group">
                <label>Product</label>
                <div className="table-responsive bd rounded">
                  <table className="table table-bordered mg-0 bd-0 basic-table">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="d-flex align-items-center">
                            Product
                            <Button
                              color="primary"
                              size="xs"
                              outline
                              icon="Plus"
                              className="btn-icon mg-l-auto rounded-0"
                            />
                          </div>
                        </th>
                        <th scope="col">Opportunity Process</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>
                          <Select />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Button color="link" name="Add Product" icon="Plus" />
              </div>
              <ul className="nav nav-tabs mg-b-10">
                {TABS.map((item, index) => (
                  <li key={index} className={`nav-item ${item.value > 1 ? ' mg-l-1-f' : ''}`}>
                    <button
                      type="button"
                      className={classNames('nav-link pd-x-10-f', {
                        active: item.value == tab.value,
                      })}
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
              {tab.value === 1 && (
                <div className="form-group">
                  <div className="mg-b-5 text-right">
                    <Button
                      color="primary"
                      size="xs"
                      outline
                      icon="Plus"
                      className="btn-icon rounded-0"
                    />
                  </div>
                  <div className="table-responsive bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 basic-table">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            User ID
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Active
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Period
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="pd-3-f">&nbsp;</td>
                          <td className="pd-3-f">&nbsp;</td>
                          <td className="pd-3-f">
                            <Switch />
                          </td>
                          <td className="pd-3-f">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {tab.value === 3 && (
                <>
                  <RadioGroup
                    options={goalsOption}
                    value={selectedGoal}
                    onChange={handleGoalChange}
                  />
                  <div className="form-group">
                    <label className="form-item-title">Goal Name</label>
                    <Select />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Goal Period</label>
                    <div>&nbsp;</div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Close
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewSalesTeam;
