import { Button, Input } from '@base/components/form';
import { Website } from '@customer/customer/components';
import { useState } from 'react';

const NewCompetitor = () => {
  const [showAddCompetitor, setShowAddCompetitor] = useState(false);
  return (
    <>
      <div
        id="newCompetitorModal"
        className="modal fade right"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title">Add New Competitor</h1>
              <Button close data-dismiss="modal" tooltip="닫기" />
            </div>
            <div className="modal-body overflow-auto">
              <div className="form-group">
                <label className="form-item-title">Name</label>
                <Input />
              </div>
              <div className="form-group">
                <label className="form-item-title">Website</label>
                <Website />
              </div>
              {showAddCompetitor && (
                <>
                  <hr className="hr-dotted" />
                  <div className="form-group">
                    <label className="form-item-title">Name</label>
                    <Input />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Website</label>
                    <Website />
                  </div>
                  <Button
                    color="link"
                    size="sm"
                    icon="Minus"
                    name="Delete"
                    className="mg-r-10 pd-x-0 tx-danger"
                    onClick={() => setShowAddCompetitor(false)}
                  />
                </>
              )}
              <Button
                color="link"
                size="sm"
                icon="Plus"
                name="Add"
                className="pd-x-0"
                onClick={() => setShowAddCompetitor(true)}
              />
            </div>
            <div className="modal-footer">
              <Button color="light" size="lg" name="Close" data-dismiss="modal" />
              <Button color="success" size="lg" name="Add" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCompetitor;
