import DomEditor from '@base/components/dom-editor';
import TaskSequenceForm from '@activity/containers/sequence-form';
import TaskKnowledgeForm from '@desk/knowledge-base/components/knowledge-base-inserted';
import { useState } from 'react';

const DomBuilder = () => {
  const [isPreviewing, setIsPreviewing] = useState(false);
  return (
    <div className="pd-5">
      <DomEditor onPreview={(val: boolean) => setIsPreviewing(val)}>
        {/* sequence + checklist */}
        <TaskSequenceForm
          className="pd-10"
          mode={isPreviewing ? 'r' : 'w'}
          //value
          //onChange
        />
        {/* knowledge */}
        {/* <TaskKnowledgeForm value={[]} onChange={(vals: any) => console.log('vals', vals)} /> */}
      </DomEditor>
    </div>
  );
};

export default DomBuilder;
