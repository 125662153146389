import { useState } from 'react';
import classnames from 'classnames';

import { Plus, X } from 'react-feather';

const TABS = [
  {
    value: 1,
    label: 'Demographic',
  },
  {
    value: 2,
    label: 'Geographic',
  },
  {
    value: 3,
    label: 'Behavior',
  },
  {
    value: 4,
    label: 'Psychographic',
  },
];

const NewContactProfile = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [showAdditional, setShowAdditional] = useState(false);
  const [showAdditional2, setShowAdditional2] = useState(false);
  const [showAdditional3, setShowAdditional3] = useState(false);
  const [showAdditional4, setShowAdditional4] = useState(false);

  return (
    <div id="newContactProfile" className="modal fade right">
      <div className="modal-dialog wd-auto">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">New Profile</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="tx-color-03">Profile Name</label>
              <input type="text" className="form-control" />
            </div>

            {/* tabs */}
            <nav className="nav nav-line mg-b-15-f bd-0">
              {TABS.map((item, index) => (
                <button
                  key={index}
                  className={classnames('btn nav-link', {
                    active: item.value == tab.value,
                  })}
                  onClick={(e) => {
                    setTab(item);
                  }}
                >
                  {item.label}
                </button>
              ))}
            </nav>
            {tab.value == 1 && (
              <>
                <ul className="list-unstyled mg-b-0 bd rounded">
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Age
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="demographicSwitch1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="demographicSwitch1"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Birthday
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="demographicSwitch2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="demographicSwitch2"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Gender
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="demographicSwitch3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="demographicSwitch3"></label>
                    </div>
                  </li>
                  <li
                    className={classnames('d-flex align-items-center pd-12', {
                      'bd-b': showAdditional,
                    })}
                  >
                    Occupation
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="demographicSwitch4"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="demographicSwitch4"></label>
                    </div>
                  </li>
                  {showAdditional && (
                    <>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Marital Status
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="demographicSwitch5"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="demographicSwitch5"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Family Life-stage
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="demographicSwitch6"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="demographicSwitch6"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Family size
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="demographicSwitch7"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="demographicSwitch7"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Education attainment
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="demographicSwitch8"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="demographicSwitch8"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Home Ownership
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="demographicSwitch9"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="demographicSwitch9"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Nationality
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="demographicSwitch10"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="demographicSwitch10"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Religion
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="demographicSwitch11"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="demographicSwitch11"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12">
                        Social Circles
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="demographicSwitch12"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="demographicSwitch12"
                          ></label>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowAdditional(!showAdditional)}
                >
                  {!showAdditional && (
                    <>
                      <Plus className="mg-r-5" />
                      Additional
                    </>
                  )}
                  {showAdditional && (
                    <>
                      <X className="mg-r-5" />
                      Cancel
                    </>
                  )}
                </button>
              </>
            )}

            {tab.value == 2 && (
              <>
                <ul className="list-unstyled mg-b-0 bd rounded">
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Countrie
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="geographicSwitch1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="geographicSwitch1"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Cities
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="geographicSwitch2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="geographicSwitch2"></label>
                    </div>
                  </li>
                  <li
                    className={classnames('d-flex align-items-center pd-12', {
                      'bd-b': showAdditional2,
                    })}
                  >
                    Regions
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="geographicSwitch3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="geographicSwitch3"></label>
                    </div>
                  </li>
                  {showAdditional2 && (
                    <>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Political
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="geographicSwitch4"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="geographicSwitch4"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Currency
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="geographicSwitch5"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="geographicSwitch5"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Language
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="geographicSwitch6"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="geographicSwitch6"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Nations
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="geographicSwitch7"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="geographicSwitch7"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Neighborhoods
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="geographicSwitch8"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="geographicSwitch8"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Climate zone
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="geographicSwitch9"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="geographicSwitch9"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12">
                        Population density
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="geographicSwitch10"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="geographicSwitch10"
                          ></label>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowAdditional2(!showAdditional2)}
                >
                  {!showAdditional2 && (
                    <>
                      <Plus className="mg-r-5" />
                      Additional
                    </>
                  )}
                  {showAdditional2 && (
                    <>
                      <X className="mg-r-5" />
                      Cancel
                    </>
                  )}
                </button>
              </>
            )}

            {tab.value == 3 && (
              <>
                <ul className="list-unstyled mg-b-0 bd rounded">
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Loyalty Status
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="behaviorSwitch1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="behaviorSwitch1"></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Usage
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="behaviorSwitch2"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="behaviorSwitch2"></label>
                    </div>
                  </li>
                  <li
                    className={classnames('d-flex align-items-center pd-12', {
                      'bd-b': showAdditional3,
                    })}
                  >
                    Product reviews / feedback
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="behaviorSwitch3"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="behaviorSwitch3"></label>
                    </div>
                  </li>
                  {showAdditional3 && (
                    <>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Benefit-Sought
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch4"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch4"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        User Status
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch5"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch5"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Adopter Status
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch6"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch6"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Attitude to Product or Service
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch7"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch7"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Purchasing Behavior
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch8"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch8"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Spending habits
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch9"
                          />
                          <label className="custom-control-label" htmlFor="behaviorSwitch9"></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Brand Interactions
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch10"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="behaviorSwitch10"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Interest Based
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch11"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="behaviorSwitch11"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Universal Occasions
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch12"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="behaviorSwitch12"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Recurring-personal occasion
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch13"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="behaviorSwitch13"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Rare-personal occasion
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch14"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="behaviorSwitch14"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12">
                        Customer Satisfaction
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="behaviorSwitch15"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="behaviorSwitch15"
                          ></label>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowAdditional3(!showAdditional3)}
                >
                  {!showAdditional3 && (
                    <>
                      <Plus className="mg-r-5" />
                      Additional
                    </>
                  )}
                  {showAdditional3 && (
                    <>
                      <X className="mg-r-5" />
                      Cancel
                    </>
                  )}
                </button>
              </>
            )}

            {tab.value == 4 && (
              <>
                <ul className="list-unstyled mg-b-0 bd rounded">
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Work
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch1"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch1"
                      ></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Hobbies
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch2"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch2"
                      ></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Social events
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch3"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch3"
                      ></label>
                    </div>
                  </li>

                  <li className="d-flex align-items-center pd-12 bd-b">
                    Vacations
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch4"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch4"
                      ></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Club memberships
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch5"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch5"
                      ></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Community
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch6"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch6"
                      ></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Entertainment choices
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch7"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch7"
                      ></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Soprts
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch8"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch8"
                      ></label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-12 bd-b">
                    Shopping
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch9"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch9"
                      ></label>
                    </div>
                  </li>
                  <li
                    className={classnames('d-flex align-items-center pd-12', {
                      'bd-b': showAdditional4,
                    })}
                  >
                    Interests
                    <div className="custom-control custom-switch mg-l-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="psychographicSwitch10"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="psychographicSwitch10"
                      ></label>
                    </div>
                  </li>
                  {showAdditional4 && (
                    <>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Openness
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="psychographicSwitch11"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="psychographicSwitch11"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Conscientiousness
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="psychographicSwitch12"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="psychographicSwitch12"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Extraversion
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="psychographicSwitch13"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="psychographicSwitch13"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Agreeableness
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="psychographicSwitch14"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="psychographicSwitch14"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12 bd-b">
                        Neuroticism
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="psychographicSwitch15"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="psychographicSwitch15"
                          ></label>
                        </div>
                      </li>
                      <li className="d-flex align-items-center pd-12">
                        Opinions
                        <div className="custom-control custom-switch mg-l-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="psychographicSwitch16"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="psychographicSwitch16"
                          ></label>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowAdditional4(!showAdditional4)}
                >
                  {!showAdditional4 && (
                    <>
                      <Plus className="mg-r-5" />
                      Additional
                    </>
                  )}
                  {showAdditional4 && (
                    <>
                      <X className="mg-r-5" />
                      Cancel
                    </>
                  )}
                </button>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewContactProfile;
