import { ChevronDown, X } from 'react-feather';

const RatingGrade = () => {
  return (
    <div id="gradeLangInputModal" className="modal fade right">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Language</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a className="nav-link pd-x-10-f active" data-toggle="tab" role="tab">
                  English
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pd-x-10-f" data-toggle="tab" role="tab">
                  Korean
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pd-x-10-f" data-toggle="tab" role="tab">
                  Vietnamese
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pd-x-10-f" data-toggle="tab" role="tab">
                  Others
                  <ChevronDown size="16" />
                </a>
              </li>
            </ul>
            <div className="tab-content bd bd-t-0 pd-10">
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success" data-dismiss="modal">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingGrade;
