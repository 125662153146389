/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';

import {
	ChevronDown,
	Star,
	RefreshCw,
	Search,
	Plus,
	List,
	Grid,
	Columns,
	Settings,
	Menu,
	RotateCcw,
	X,
} from 'react-feather';

import NewSalesOrder from '../modal/newSalesOrder';
import NewSalesReturn from '../modal/newSalesReturn';
import NewReplacementOrder from '../modal/newReplacementOrder';
import Pagination from '../../../../component/desktop/pagination-new';

// import "OrderCss/sales_order.scss"

const RepOrderList = () => {
	const [listType, setListType] = useState<any>({
		list: true,
		grid: false,
	});

	const [showAdvSearch, setShowAdvSearch] = useState<any>();

	return (
		<>
			<div className="list-wrap">
				<div className="list-header">
					<button
						type="button"
						className="btn btn-xs btn-icon btn-favorite"
						aria-label="즐겨찾기 추가"
					>
						<Star size="18" />
					</button>
					<h1 className="list-header-title">Replacement Order</h1>
					<div className="dropdown mg-l-5">
						<button
							type="button"
							className="btn btn-xs btn-white btn-icon"
							aria-label="변경할 메뉴 열기"
							data-toggle="dropdown"
						>
							<ChevronDown />
						</button>
						<div className="dropdown-menu nav-sub">
							<div className="dropdown-item">
								<a href="">Dashboard</a>
							</div>
							<div className="dropdown-item">
								<a href="">Order Process</a>
							</div>
							<div className="dropdown-item">
								<a href="">Sales Order</a>
								<button
									type="button"
									className="btn btn-xs btn-set-default btn-icon"
									aria-label="Set as default"
								>
									{/* Add'active' class if this menu is currently set by default */}
									{Icon('set_default')}
									<span className="sr-only">기본 메뉴로 설정</span>
								</button>
							</div>
							<div className="dropdown-item">
								<a href="">Point of Sale</a>
							</div>
							<div className="dropdown-item">
								<a href="">Sales Report</a>
							</div>
						</div>
					</div>
					<div className="btn-group dropdown mg-l-auto">
						<button
							type="button"
							className="btn btn-primary pd-x-10"
							data-toggle="modal"
							data-target="#newReplacement"
						>
							<Plus />
							<span className="sr-only">추가</span>
						</button>
						<button
							type="button"
							className="btn btn-primary dropdown-toggle-split pd-x-5"
							data-toggle="dropdown"
						>
							<ChevronDown />
							<span className="sr-only">추가 옵션 열기</span>
						</button>
						<div className="dropdown-menu dropdown-menu-right">
							<button
								type="button"
								className="dropdown-item"
								data-toggle="modal"
								data-target="#newSalesOrder"
							>
								Slaes Order
							</button>
							<button
								type="button"
								className="dropdown-item"
								data-toggle="modal"
								data-target="#newSalesReturn"
							>
								Slaes Return
							</button>
							<button
								type="button"
								className="dropdown-item"
								data-toggle="modal"
								data-target="#newReplacement"
							>
								Replacement
							</button>
						</div>
					</div>
					<div className="button-group">
						<div className="dropdown">
							<button
								type="button"
								className="btn btn-white btn-icon"
								aria-label="select a list type"
								data-toggle="dropdown"
							>
								{listType.list ? <List /> : <Grid />}
								<ChevronDown />
								<span className="sr-only">목록 타입 변경</span>
							</button>
							<div className="dropdown-menu">
								<a
									role="button"
									className={classnames('dropdown-item', { active: listType.list })}
									onClick={() => setListType({ list: true })}
								>
									<List className="mg-r-5" />
									List
								</a>
								<a
									role="button"
									className={classnames('dropdown-item', { active: listType.grid })}
									onClick={() => setListType({ grid: true })}
								>
									<Grid className="mg-r-5" />
									Grid
								</a>
							</div>
						</div>
						<button type="button" className="btn btn-white btn-icon" aria-label="reload">
							<RefreshCw />
							<span className="sr-only">새로고침</span>
						</button>
						<button type="button" className="btn btn-white btn-icon" aria-label="split view">
							<Columns />
							<span className="sr-only">분할 보기</span>
						</button>
					</div>
				</div>

				<div className="list-filter-bar">
					<div className="list-filter-items">
						<div className="dropdown list-filter-item">
							<button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
								All Replacement Order
								<ChevronDown />
							</button>
							<div className="dropdown-menu"></div>
						</div>

						<div className="dropdown list-filter-item">
							<button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
								Created This Month
								<ChevronDown />
							</button>
							<div className="dropdown-menu wd-300"></div>
						</div>

						<div className="dropdown list-filter-item">
							<button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
								Filters
								<ChevronDown />
							</button>
							<div className="dropdown-menu wd-300"></div>
						</div>

						{/* for grid */}
						{listType.grid && (
							<div className="dropdown with-sort list-filter-item">
								<button type="button" className="btn btn-sort pd-r-0 current-sorting">
									{/* Add 'current-sorting' class when sorting is applied */}
									{Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
									<span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
								</button>
								<button
									type="button"
									className="btn btn-filter pd-l-0"
									data-toggle="dropdown"
									aria-label="정렬 옵션 열기"
								>
									Sorty by: Replacement Name
									<ChevronDown />
								</button>
								<div className="dropdown-menu dropdown-menu-right">
									<a role="button" className="dropdown-item active">
										Replacement Name
									</a>
								</div>
							</div>
						)}
					</div>

					{/* search */}
					<div className="input-group input-group-flush list-search">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<Search />
							</span>
						</div>
						<div className="d-flex form-control pd-0">
							<input type="search" className="wd-100p bd-0" placeholder="Replacement Name" />
							<button
								type="button"
								className="btn btn-link link-03 btn-icon han-tooltip--left"
								data-han-tooltip="Advanced Search"
								onClick={() => setShowAdvSearch(!showAdvSearch)}
							>
								{showAdvSearch ? <X /> : <ChevronDown />}
							</button>
						</div>
						{showAdvSearch && (
							<div className="dropdown-menu show wd-100p pd-0">
								<div className="pd-10 bg-light bd-b">Advanced Search</div>
								<div className="pd-10"></div>
								<div className="pd-10  bd-t text-center">
									<button type="button" className="btn btn-secondary mg-r-10">
										<RotateCcw className="mg-r-5" />
										Reset
									</button>
									<button type="button" className="btn btn-primary">
										<Search className="mg-r-5" />
										Search
									</button>
								</div>
							</div>
						)}
					</div>

					{listType.list && (
						<div className="dropup mg-l-5">
							<button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
								<Settings />
								<span className="sr-only">항목 설정 열기</span>
							</button>
							<div className="dropdown-menu column-settings">
								<div className="column-settings-title">Column Settings</div>
								<ul className="list-group">
									<li className="list-group-item">
										<div className="custom-control custom-checkbox">
											<input
												type="checkbox"
												className="custom-control-input"
												id="columnCheck1"
												defaultChecked
											/>
											<label className="custom-control-label" htmlFor="columnCheck1">
												Name
											</label>
										</div>
										<button
											type="button"
											className="btn btn-link link-02 btn-icon handler"
											aria-label="change the order"
										>
											<Menu />
											<span className="드래그하여 순서 변경"></span>
										</button>
									</li>
								</ul>
								<div className="column-settings-actions">
									Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
									<button type="button" className="btn btn-light mg-l-auto">
										Close
									</button>
									<button type="button" className="btn btn-primary mg-l-5">
										Save
									</button>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="list-body">
					{listType.list && (
						<div className="table-responsive">
							<table className="table dataTable">
								<thead>
									<tr>
										<th scope="col" className="tb-sel-cell">
											<div className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="replacementOrderCheckAll"
												/>
												<label
													className="custom-control-label"
													htmlFor="replacementOrderCheckAll"
												></label>
											</div>
										</th>
										<th scope="col" className="sorting_desc">
											Replacement Name
										</th>
										<th scope="col" className="sorting">
											Customer
										</th>
										<th scope="col" className="sorting">
											Reference Id
										</th>
										<th scope="col" className="sorting">
											Replaced on
										</th>
										<th scope="col" className="sorting">
											Item
										</th>
										<th scope="col" className="sorting">
											Qty Replaced
										</th>
										<th scope="col" className="sorting">
											Stage
										</th>
										<th scope="col" className="sorting">
											Status
										</th>
										<th scope="col" className="sorting">
											Assigned Rep.
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<div className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="replacementOrderCheck3"
												/>
												<label
													className="custom-control-label"
													htmlFor="replacementOrderCheck3"
												></label>
											</div>
										</td>
										<td className="sorting-cell">
											<a href="/crm/demo-page/ui/replacement-order/desktop/theme1/view">
												Replacement Order 3
											</a>
										</td>
										<td>
											<div className="media align-items-center">
												<div className="avatar avatar-xs">
													<img
														src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
														className="rounded"
														alt="Adventure Works"
													/>
												</div>
												<div className="media-body mg-l-10">Adventure Works</div>
											</div>
										</td>
										<td>SO-000001</td>
										<td>2021.5.30</td>
										<td>
											<div className="dropdown">
												<button type="button" className="btn" data-toggle="dropdown">
													<span className="mg-r-5">Item 1</span>
													<span className="badge badge-secondary mg-r-5">+ 2</span>
													<ChevronDown />
												</button>
												<div className="dropdown-menu pd-0">
													<div className="dropdown-item-list">Item 1</div>
													<div className="dropdown-item-list">Item 2</div>
													<div className="dropdown-item-list">Item 3</div>
													<div className="dropdown-footer">Total: 3</div>
												</div>
											</div>
										</td>
										<td>10</td>
										<td>Active</td>
										<td>New</td>
										<td>
											<div className="media align-items-center">
												<div className="avatar avatar-xs">
													<img
														src="https://n.hanbiro.com/ncrm/user/photo/10/1"
														className="rounded-circle"
														alt="KDH"
													/>
												</div>
												<div className="media-body mg-l-10">KDH</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}

					{listType.grid && (
						<div className="row row-xs">
							<div className="col-lg-6 d-flex">
								<div className="grid-list-item without-pic">
									<div className="grid-list-item-sel">
										<div className="custom-control custom-checkbox">
											<input
												type="checkbox"
												className="custom-control-input"
												id="replacementOrderCheck3"
											/>
											<label className="custom-control-label" htmlFor="replacementOrderCheck3"></label>
										</div>
									</div>
									<div className="grid-list-item-detail">
										<div className="grid-list-item-detail-header">
											<a
												href="/crm/demo-page/ui/replacement-order/desktop/theme1/view"
												className="grid-list-item-title link-02"
											>
												Replacement Order 3
											</a>
										</div>
										<div className="grid-list-item-detail-body">
											<div className="grid-list-item-col">
												<span className="grid-list-item-col-title">Customer:</span>
												<div className="media align-items-center">
													<div className="avatar avatar-xs">
														<img
															src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
															className="rounded"
															alt="Adventure Works"
														/>
													</div>
													<div className="media-body mg-l-10">Adventure Works</div>
												</div>
											</div>
											<div className="grid-list-item-col">
												<span className="grid-list-item-col-title">Reference ID:</span>
												<span className="grid-list-item-col-content">SO-000001</span>
											</div>
											<div className="grid-list-item-col">
												<span className="grid-list-item-col-title">Replaced on:</span>
												<span className="grid-list-item-col-content">2021.5.30</span>
											</div>
											<div className="grid-list-item-col">
												<span className="grid-list-item-col-title">Item:</span>
												<div className="dropdown">
													<button type="button" className="btn" data-toggle="dropdown">
														<span className="mg-r-5">Item 1</span>
														<span className="badge badge-secondary mg-r-5">+ 2</span>
														<ChevronDown />
													</button>
													<div className="dropdown-menu pd-0">
														<div className="dropdown-item-list">Item 1</div>
														<div className="dropdown-item-list">Item 2</div>
														<div className="dropdown-item-list">Item 3</div>
														<div className="dropdown-footer">Total: 3</div>
													</div>
												</div>
											</div>
											<div className="grid-list-item-col">
												<span className="grid-list-item-col-title">Qty Replaced :</span>
												<span className="grid-list-item-col-content">10</span>
											</div>
											<div className="grid-list-item-col">
												<span className="grid-list-item-col-title">Stage:</span>
												<span className="grid-list-item-col-content">Active</span>
											</div>
											<div className="grid-list-item-col">
												<span className="grid-list-item-col-title">Status:</span>
												<span className="grid-list-item-col-content">New</span>
											</div>
											<div className="grid-list-item-col">
												<span className="grid-list-item-col-title">Assigned Rep.:</span>
												<div className="media align-items-center">
													<div className="avatar avatar-xs">
														<img
															src="https://n.hanbiro.com/ncrm/user/photo/10/1"
															className="rounded-circle"
															alt="KDH"
														/>
													</div>
													<div className="media-body mg-l-10">KDH</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{/* pagination */}
					<Pagination />
				</div>
			</div>

			<NewSalesOrder />
			<NewSalesReturn />
			<NewReplacementOrder />
		</>
	);
};

export default RepOrderList;
