import { Button, Input, RadioGroup } from '@base/components/form';
import { useState } from 'react';
import CommissionRate from '../component/commission-rate';
import PlanPerItem from '../container/plan-per-item';

const NewSalesCommission = () => {
  const COMMISSION_TYPES = [
    {
      label: 'A Sale',
      value: 'sale',
    },
    {
      label: 'Residual(Subscription)',
      value: 'residual',
    },
  ];

  const [options, setOptions] = useState(COMMISSION_TYPES);
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
  };

  const COMMISSION_BASES = [
    {
      label: 'Order Fulfilled',
      value: 'order',
    },
    {
      label: 'Invoice Completed',
      value: 'invoice',
    },
  ];

  const [bases, setBases] = useState(COMMISSION_BASES);
  const [selectedBase, setSelectedBase] = useState(bases[0].value);

  //change
  const handleBaseChange = (newOption: any) => {
    setSelectedBase(newOption.value);
  };

  const COMMISSION_PRODUCTS = [
    {
      label: 'All Product',
      value: 'all',
    },
    {
      label: 'Per Item',
      value: 'item',
    },
  ];

  const [items, setItems] = useState(COMMISSION_PRODUCTS);
  const [selectedItem, setSelectedItem] = useState(items[0].value);

  //change
  const handleItemChange = (newOption: any) => {
    setSelectedItem(newOption.value);
  };

  const PAY_COMMISSION = [
    {
      label: 'Pay Commission on first sales only',
      value: 'first',
    },
    {
      label: 'Pay Commission on all sales (per Receivable Amount)',
      value: 'all',
    },
  ];

  const [payCommission, setPayCommission] = useState(PAY_COMMISSION);
  const [selectedPayCommission, setSelectedPayCommission] = useState(payCommission[0].value);

  //change
  const handlePayCommissionChange = (newOption: any) => {
    setSelectedPayCommission(newOption.value);
  };

  const COMMISSION_SUB_PRODUCTS = [
    {
      label: 'All Subscription Product',
      value: 'all',
    },
    {
      label: 'Per Subscription Item',
      value: 'item',
    },
  ];

  const [subItems, setSubItems] = useState(COMMISSION_SUB_PRODUCTS);
  const [selectedSubItem, setSelectedSubItem] = useState(subItems[0].value);

  //change
  const handleSubItemChange = (newOption: any) => {
    setSelectedSubItem(newOption.value);
  };

  return (
    <div id="newSalesCommission" className="modal fade right" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Sales Commission Plan</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="form-group">
              <label className="form-item-title">Sales Commission Plan Name</label>
              <Input />
            </div>
            <div className="form-group">
              <label className="form-item-title">Sales Commission Type</label>
              <RadioGroup value={selectedValue} options={options} onChange={handleValueChange} />
            </div>
            <div className="form-group">
              <label className="form-item-title">Pay Commission based on</label>
              <RadioGroup value={selectedBase} options={bases} onChange={handleBaseChange} />
            </div>
            <div className="form-group-title">Commission Rate</div>
            {selectedValue === 'sale' && (
              <>
                <div className="form-group">
                  <label className="form-item-title">Commission Plan</label>
                  <RadioGroup value={selectedItem} options={items} onChange={handleItemChange} />
                  {selectedItem === 'all' && <CommissionRate />}
                </div>
                {selectedItem !== 'all' && <PlanPerItem />}
              </>
            )}
            {selectedValue === 'residual' && (
              <>
                <div className="form-group">
                  <label className="form-item-title">Commission Plan of Subscription Item</label>
                  <RadioGroup
                    value={selectedPayCommission}
                    options={payCommission}
                    onChange={handlePayCommissionChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Commission Plan</label>
                  <RadioGroup
                    value={selectedSubItem}
                    options={subItems}
                    onChange={handleSubItemChange}
                  />
                  {selectedSubItem === 'all' && <CommissionRate />}
                </div>
                {selectedSubItem !== 'all' && <PlanPerItem />}
              </>
            )}
          </div>
          <div className="modal-footer">
            <Button color="light" name="Close" data-dismiss="modal" />
            <Button color="success" name="Save" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSalesCommission;
