import { Button, Input, Select } from '@base/components/form';
import { useState } from 'react';

const ReturnMaterial = () => {
  const REASONS = [
    {
      label: 'Theft',
      value: 1,
    },
    {
      label: 'Misplaced',
      value: 2,
    },
    {
      label: 'Damaged',
      value: 3,
    },
    {
      label: 'Other',
      value: 4,
    },
  ];
  const [selectedValue, setSelectedValue] = useState(REASONS[0].value);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
  };

  return (
    <div id="returnMaterial" className="modal right fade">
      <div className="modal-dialog">
        <div className="modal-content rounded-0 overflow-hidden">
          <div className="modal-header">
            <h1 className="h6 modal-title">Return Material</h1>
            <Button close />
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Component</label>
              <div className="input-group">
                <Input type="text" placeholder="Type or click to add a component" />
                <div className="input-group-append">
                  <Button color="" className="input-group-btn" icon="product" iconType="main" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6">
                <label className="form-item-title">Qty</label>
                <div>&nbsp;</div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Unit</label>
                <div>&nbsp;</div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Return Reason</label>
              <div className="d-flex flex-wrap align-items-center">
                <Select
                  width={150}
                  options={REASONS}
                  onChange={handleValueChange}
                  className="mg-r-10"
                />
                {selectedValue === 4 && <Input type="text" className="wd-200" />}
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Comment</label>
              <Input type="text" />
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" name="Close" data-dismiss="modal" />
            <Button color="success" name="Return this into Inventory" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnMaterial;
