import { Checkbox } from '@base/components/form';

export const PRGrid = () => {
  return (
    <div className="row row-xs">
      <div className="col-lg-6 d-flex">
        <div className="grid-list-item">
          <div className="grid-list-item-sel">
            <Checkbox />
          </div>
          <div className="grid-list-item-detail">
            <div className="grid-list-item-detail-header">
              <a href="/purchase/desktop/theme1/pr-view" className="grid-list-item-title link-02">
                P-000001
              </a>
            </div>
            <div className="grid-list-item-detail-body">
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Status :</span>
                <span className="grid-list-item-col-content">New</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Item :</span>
                <span className="grid-list-item-col-content">Item 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Quntity :</span>
                <span className="grid-list-item-col-content">100</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Required Date :</span>
                <span className="grid-list-item-col-content">2022/04/27</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Vendor :</span>
                <span className="grid-list-item-col-content">Vendor 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Estimated Amount :</span>
                <span className="grid-list-item-col-content">$ 2,000</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">From :</span>
                <span className="grid-list-item-col-content">Manual</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RFTGrid = () => {
  return (
    <div className="row row-xs">
      <div className="col-lg-6 d-flex">
        <div className="grid-list-item">
          <div className="grid-list-item-sel">
            <Checkbox />
          </div>
          <div className="grid-list-item-detail">
            <div className="grid-list-item-detail-header">
              <a href="/purchase/desktop/theme1/rft-view" className="grid-list-item-title link-02">
                RTF-000001
              </a>
            </div>
            <div className="grid-list-item-detail-body">
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Status :</span>
                <span className="grid-list-item-col-content">New</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Item :</span>
                <span className="grid-list-item-col-content">Item 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Quntity :</span>
                <span className="grid-list-item-col-content">100</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Valid Until :</span>
                <span className="grid-list-item-col-content">2022/04/27</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Vendor :</span>
                <span className="grid-list-item-col-content">Vendor 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Total Amount :</span>
                <span className="grid-list-item-col-content">$ 2,000</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Response :</span>
                <span className="grid-list-item-col-content">
                  <span className="tx-success">0</span>/4
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RFQGrid = () => {
  return (
    <div className="row row-xs">
      <div className="col-lg-6 d-flex">
        <div className="grid-list-item">
          <div className="grid-list-item-sel">
            <Checkbox />
          </div>
          <div className="grid-list-item-detail">
            <div className="grid-list-item-detail-header">
              <a href="/purchase/desktop/theme1/rfq-view" className="grid-list-item-title link-02">
                RFQ-000001
              </a>
            </div>
            <div className="grid-list-item-detail-body">
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Status :</span>
                <span className="grid-list-item-col-content">New</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Item :</span>
                <span className="grid-list-item-col-content">Item 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Quntity :</span>
                <span className="grid-list-item-col-content">100</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Total Amount :</span>
                <span className="grid-list-item-col-content">$ 2,000</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Vendor :</span>
                <span className="grid-list-item-col-content">Vendor 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Valid Until :</span>
                <span className="grid-list-item-col-content">2022/04/27</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Related RFT :</span>
                <span className="grid-list-item-col-content">RFT-0000001</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const POGrid = () => {
  return (
    <div className="row row-xs">
      <div className="col-lg-6 d-flex">
        <div className="grid-list-item">
          <div className="grid-list-item-sel">
            <Checkbox />
          </div>
          <div className="grid-list-item-detail">
            <div className="grid-list-item-detail-header">
              <a href="/purchase/desktop/theme1/po-view" className="grid-list-item-title link-02">
                Purchase Order 1
              </a>
            </div>
            <div className="grid-list-item-detail-body">
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Stage :</span>
                <span className="grid-list-item-col-content">New</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Status :</span>
                <span className="grid-list-item-col-content">New</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Vendor :</span>
                <span className="grid-list-item-col-content">Vendor 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Item :</span>
                <span className="grid-list-item-col-content">Item 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Quntity :</span>
                <span className="grid-list-item-col-content">100</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Total Amount :</span>
                <span className="grid-list-item-col-content">$ 2,000</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">PO Date :</span>
                <span className="grid-list-item-col-content">2022/04/27</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Received Goods :</span>
                <span className="grid-list-item-col-content">100</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PurchaseReturnGrid = () => {
  return (
    <div className="row row-xs">
      <div className="col-lg-6 d-flex">
        <div className="grid-list-item">
          <div className="grid-list-item-sel">
            <Checkbox />
          </div>
          <div className="grid-list-item-detail">
            <div className="grid-list-item-detail-header">
              <a
                href="/purchase/desktop/theme1/return-view"
                className="grid-list-item-title link-02"
              >
                Purchase Return1
              </a>
            </div>
            <div className="grid-list-item-detail-body">
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Status :</span>
                <span className="grid-list-item-col-content">New</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Vendor :</span>
                <span className="grid-list-item-col-content">Vendor 1</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Reference ID :</span>
                <span className="grid-list-item-col-content">PO-000001</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Return Date :</span>
                <span className="grid-list-item-col-content">2022/05/15</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Return Type :</span>
                <span className="grid-list-item-col-content">Refund</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title">Reason :</span>
                <span className="grid-list-item-col-content">Demaged Item</span>
              </div>
              <div className="grid-list-item-col">
                <span className="grid-list-item-col-title"> Qty Returned :</span>
                <span className="grid-list-item-col-content">10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
