import { AlertTriangle, X } from 'react-feather';

const TerminationConfirm = () => {
  return (
    <div id="terminationConfirm" className="modal fade">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title h6">Confirm</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body text-center">
            <p>Check users...</p>
            <span className="loader"></span>

            {/* if user was not deleted */}
            <div>
              <AlertTriangle className="tx-danger" />
              <br />
              Reduce again after deleting user.
              <br />
              <a href="javascript:;" className="btn btn-white mg-t-10">
                Go to delete users
              </a>
            </div>

            {/* reduce done */}
            <p className="text-left">
              Refund amounts come from most recent invoice <strong>Invoice ID</strong>.<br />
              Prorated refund <strong className="tx-danger">$1,000</strong>.<br />
              We will reduce the refund amount in next invoice.
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerminationConfirm;
