import { useState } from 'react';
import classnames from 'classnames';
import { COLORS } from '@base/config/constant';

import { ChevronDown, Plus, CheckCircle, Circle, ArrowLeft, Mail, Printer } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

import OrderViewTimeline from './timeline';
import Attachments from '../../../component/desktop/attachments';
import Notelist from '../../../component/desktop/comment';
import Userlist from '../../../component/desktop/userlist';
import AssignedRep from '../../../component/desktop/assigned-rep';
import NewSalesOrder from './modal/newSalesOrder';
import NewSalesReturn from './modal/newSalesReturn';
import NewReplacementOrder from './modal/newReplacementOrder';
import ProductDetail from './view/productDetail';
import Shipment from './view/shipment';
import SalesCommission from './view/sales-commission';
import CommissionShare from './modal/commissionShare';

const TABS = [
  {
    value: 1,
    label: 'Product Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Shipment',
  },
  {
    value: 4,
    label: 'Note',
  },
  {
    value: 5,
    label: 'Sales Commission',
  },
];

const SOViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLORS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/sales-order/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Sale Order
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title">Sales Order 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  Completed
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu">
                  <button type="button" className="dropdown-item active">
                    Completed
                  </button>
                  <button type="button" className="dropdown-item">
                    Canceled
                  </button>
                </div>
              </div>
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">{Icon('preview')}</span>Preview
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Mail />
                    </span>
                    Send Email
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">{Icon('export_pdf')}</span>Export to PDF
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Printer />
                    </span>
                    Print
                  </button>
                </div>
              </div>
            </div>
            <div className="btn-group dropdown mg-l-5">
              <button
                type="button"
                className="btn btn-primary pd-x-10"
                data-toggle="modal"
                data-target="#newSalesOrder"
              >
                <Plus />
                <span className="sr-only">추가</span>
              </button>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <ChevronDown />
                <span className="sr-only">추가 옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newSalesOrder"
                >
                  Slaes Order
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newSalesReturn"
                >
                  Slaes Return
                </button>
                <button type="button" className="dropdown-item" data-toggle="modal" data-target="#">
                  Credit Note
                </button>
              </div>
            </div>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Active</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>New</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Approving</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Shipping</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Payment</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Returning</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Done</div>
            </li>
          </ol>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Customer</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                          className="rounded"
                          alt="Adventure Works"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <a href="" target="_blank" title="새창으로 열림">
                          Adventure Works
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Bill to</label>
                    <div>Bay Area, San Francisco, CA</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Ship to</label>
                    <div>Bay Area, San Francisco, CA</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Reference Id</label>
                    <a href="" target="_blank" title="새창으로 열림">
                      TA-000001
                    </a>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Ordered on</label>
                    <div>2021-05-30</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Payment Term</label>
                    <div>7 days</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Expected Shipment Date</label>
                    <div>2021-06-30</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Assigned Rep</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <span className="user-name">MSR</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Order Process</label>
                    <div>Process 1</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Order Template</label>
                    <div>Template 1</div>

                    {/* Edit */}
                    <div className="d-flex align-items-center">
                      <SelectHook
                        options={[
                          { value: 1, label: 'Template 1' },
                          { value: 2, label: 'Template 2' },
                        ]}
                      />
                      <button type="button" className="btn btn-link">
                        Preview
                      </button>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Owner</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <span className="user-name">MSR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show right aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && <ProductDetail />}
              {tab.value == 2 && <OrderViewTimeline />}
              {tab.value == 3 && <Shipment />}
              {tab.value == 4 && <Notelist />}
              {tab.value == 5 && <SalesCommission />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Invoice */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Invoice</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#orderInvoice"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="orderInvoice" className="view-aside-section-body collapse show">
                  <div className="pd-10">
                    <div className="d-flex flex-wrap align-items-center">
                      <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                        Inv-000001
                      </a>
                      <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                      <span className="mg-l-auto">￦10,000,000</span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <span className="mg-r-10 tx-color-03 tx-13">Balance Due : </span>
                      <strong className="mg-l-auto tx-danger">￦9,548,500</strong>
                    </div>
                  </div>
                  <div className="pd-10 bd bd-dotted bd-x-0 bd-b-0">
                    <div className="d-flex flex-wrap align-items-center">
                      <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                        Inv-000002
                      </a>
                      <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                      <span className="mg-l-auto">￦10,000,000</span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <span className="mg-r-10 tx-color-03 tx-13">Balance Due : </span>
                      <strong className="mg-l-auto tx-danger">￦9,548,500</strong>
                    </div>
                  </div>
                </div>
              </div>

              {/* Sales Return */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Sales Return</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#orderReturn"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="orderReturn" className="view-aside-section-body collapse show">
                  <div className="pd-10">
                    <div className="d-flex flex-wrap align-items-center">
                      <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                        RAM-000001
                      </a>
                      <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                      <span className="mg-l-auto">
                        <span className="mg-r-5 tx-color-03 tx-13">Qty:</span>
                        <strong>10</strong>
                      </span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <span className="mg-r-10 tx-color-03 tx-13">Return Type :</span>
                      <span className="mg-l-auto">Refund</span>
                    </div>
                  </div>
                  <div className="pd-10 bd bd-dotted bd-x-0 bd-b-0">
                    <div className="d-flex flex-wrap align-items-center">
                      <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                        RAM-000002
                      </a>
                      <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                      <span className="mg-l-auto">
                        <span className="mg-r-5 tx-color-03 tx-13">Qty:</span>
                        <strong>10</strong>
                      </span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <span className="mg-r-10 tx-color-03 tx-13">Return Type :</span>
                      <span className="mg-l-auto">Refund</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Replacement Order */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Replacement Order</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#orderReplacement"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="orderReplacement" className="view-aside-section-body collapse show">
                  <div className="pd-10">
                    <div className="d-flex flex-wrap align-items-center">
                      <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                        REP-000001
                      </a>
                      <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                      <span className="mg-l-auto">
                        <span className="mg-r-5 tx-color-03 tx-13">Qty:</span>
                        <strong>10</strong>
                      </span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <span className="mg-r-10 tx-color-03 tx-13">Reference ID : </span>
                      <a href="" target="_blank" title="새창으로 열림" className="mg-l-auto">
                        SO-000001
                      </a>
                    </div>
                  </div>
                  <div className="pd-10 bd bd-dotted bd-x-0 bd-b-0">
                    <div className="d-flex flex-wrap align-items-center">
                      <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                        REP-000002
                      </a>
                      <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                      <span className="mg-l-auto">
                        <span className="mg-r-5 tx-color-03 tx-13">Qty:</span>
                        <strong>10</strong>
                      </span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <span className="mg-r-10 tx-color-03 tx-13">Reference ID : </span>
                      <a href="" target="_blank" title="새창으로 열림" className="mg-l-auto">
                        SO-000001
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Credit Note */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Credit Note</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#orderCredit"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="orderCredit" className="view-aside-section-body collapse show">
                  <div className="pd-10">
                    <div className="d-flex flex-wrap align-items-center">
                      <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                        CN-0000001
                      </a>
                      <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                      <strong className="mg-l-auto">￦155,500</strong>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <span className="mg-r-10 tx-color-03 tx-13">Remaining Credit : </span>
                      <span className="mg-l-auto">￦155,500</span>
                    </div>
                  </div>
                  <div className="pd-10 bd bd-dotted bd-x-0 bd-b-0">
                    <div className="d-flex flex-wrap align-items-center">
                      <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                        CN-000002
                      </a>
                      <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                      <strong className="mg-l-auto">￦155,500</strong>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      <span className="mg-r-10 tx-color-03 tx-13">Remaining Credit : </span>
                      <span className="mg-l-auto">￦155,500</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Customer */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Contact</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#orderContact"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="orderContact" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <NewSalesOrder />
      <NewSalesReturn />
      <NewReplacementOrder />
      <CommissionShare />
    </>
  );
};

export default SOViewNew;
