import { Button, Input } from '@base/components/form';

const DestinationPage = () => {
  return (
    <div
      id="destinationPage"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Destination Page</h1>
            <Button close data-dismiss="modal" aria-label="닫기" />
          </div>
          <div className="modal-body overflow-auto">
            <ol className="pd-l-20">
              <li className="mg-b-15">
                <div className="d-flex align-items-center">
                  Add your website <Input type="url" className="wd-200 mg-l-10" />
                </div>
              </li>
              <li className="mg-b-15">
                Embedding code in you pages
                <div className="d-flex align-items-center mg-t-10">
                  <Input type="text" />
                  <Button
                    color="link"
                    size="sm"
                    icon="Copy"
                    iconClass="mg-r-5"
                    name="Copy the code"
                    className="flex-shrink-0"
                  />
                </div>
              </li>
              <li className="mg-b-15">Paste the code into the &lt;body&gt; of your pages</li>
              <li>Code Validation</li>
            </ol>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationPage;
