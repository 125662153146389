/* eslint-disable prettier/prettier */
import { ChevronDown, Clock, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';

import './style.scss';

const Format = () => {
  return (
    <div className="card-columns settings-columns">
      {/* Number of Decimal */}
      <div className="card">
        <div className="card-header h6 bg-light">Number</div>
        <div className="card-body">
          <div className="form-row">
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Decimal Symbol</label>
              <SelectHook className="wd-150-f" options={[{ value: 1, label: '.' }]} />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">No. of Decimal</label>
              <input type="number" className="form-control wd-100" defaultValue="2" />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Digit Grouping Symbol</label>
              <SelectHook className="wd-150-f" options={[{ value: 1, label: ',' }]} />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Digit Group</label>
              <SelectHook className="wd-150-f" options={[{ value: 1, label: '123,456,789' }]} />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Negative Number Format</label>
              <SelectHook className="wd-150-f" options={[{ value: 1, label: '-123456789' }]} />
            </div>
            <div className="form-col col-12 form-group">
              <label className="form-item-title">Preview</label>
              <div className="pd-15 bg-light bd bd-dashed rounded">
                <div className="row">
                  <div className="col-md-6">Positive: 123,456,789.00</div>
                  <div className="col-md-6">Negative: -123,456,789.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Currency */}
      <div className="card">
        <div className="card-header h6 bg-light">Currency</div>
        <div className="card-body">
          <div className="form-row">
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Currency Format</label>
              <SelectHook className="wd-200-f" options={[{ value: 1, label: '￦123456789' }]} />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Negative Currency Format</label>
              <SelectHook className="wd-200-f" options={[{ value: 1, label: '-￦123456789' }]} />
            </div>
          </div>
          <div className="table-responsive bd rounded-top">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Currency
                  </th>
                  <th scope="col" className="wd-40 bd-t-0-f">
                    Default
                  </th>
                  <th scope="col" className="wd-80 bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>￦(KRW) : South Korea Won</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="defaultCurrency"
                        id="defaultCurrency1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="defaultCurrency1"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <button type="button" className="btn btn-icon pd-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-10 bd bd-t-0 rounded-bottom">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type or click to add a currency"
              />
              <div className="input-group-append">
                <button type="button" className="btn" aria-label="toggle dropdown">
                  <ChevronDown />
                  <span className="sr-only">통화 선택 옵션 열기</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Time Format */}
      <div className="card">
        <div className="card-header h6 bg-light">Time</div>
        <div className="card-body">
          <div className="form-row">
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Time Format</label>
              <SelectHook className="wd-150-f" options={[{ value: 1, label: 'hh:mm tt' }]} />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Time Seperator</label>
              <SelectHook className="wd-150-f" options={[{ value: 1, label: ':' }]} />
            </div>
          </div>
          <div className="alert bd">
            What the notations mean:
            <br />
            h = hour m = minute t = am or pm
            <br />
            h/H = 12/24 hour
            <br />
            hh, mm =display leading zero
            <br />
            h, m = do not display leading zero
          </div>
          <div className="form-group">
            <label className="form-item-title">Preview</label>
            <div className="pd-15 bg-light bd bd-dashed rounded">13:18</div>
          </div>
        </div>
      </div>

      {/* Date Format */}
      <div className="card">
        <div className="card-header h6 bg-light">Date</div>
        <div className="card-body">
          <div className="form-row">
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">First Day of Week</label>
              <SelectHook
                className="wd-150-f"
                options={[
                  { value: 1, label: 'Sunday' },
                  { value: 2, label: 'Monday' },
                ]}
              />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">First Week of Year</label>
              <SelectHook
                className="wd-200-f"
                options={[{ value: 1, label: 'First day of the year' }]}
              />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Date Format</label>
              <SelectHook
                className="wd-150-f"
                options={[
                  { value: 1, label: 'yyyy/MM/dd' },
                  { value: 2, label: 'MM/dd/yyyy' },
                  { value: 3, label: 'dd/MM/yyyy' },
                ]}
              />
            </div>
            <div className="form-col col-md-6 form-group">
              <label className="form-item-title">Date Separator</label>
              <SelectHook
                className="wd-150-f"
                options={[
                  { value: 1, label: '/' },
                  { value: 2, label: '-' },
                  { value: 3, label: '.' },
                ]}
              />
            </div>
            <div className="form-col col-md-12 form-group">
              <label className="form-item-title">Preview</label>
              <div className="pd-15 bg-light bd bd-dashed rounded">2021/11/23</div>
            </div>
          </div>
        </div>
      </div>

      {/* Country and region calling code */}
      <div className="card">
        <div className="card-header h6 bg-light">Country/region code</div>
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Country Code
                </th>
                <th scope="col" className="wd-40 bd-t-0-f">
                  Default
                </th>
                <th scope="col" className="wd-80 bd-t-0-f">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>South Korea +81</td>
                <td className="text-center">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="defaultCountryCode"
                      id="defaultCountryCode1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="defaultCountryCode1"></label>
                  </div>
                </td>
                <td className="text-center">
                  <button type="button" className="btn btn-icon pd-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to add a country code"
            />
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="toggle dropdown">
                <ChevronDown />
                <span className="sr-only">타임존 선택 옵션 열기</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Time zone */}
      <div className="card">
        <div className="card-header h6 bg-light">Time zone</div>
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Time Zone
                </th>
                <th scope="col" className="wd-40 bd-t-0-f">
                  Default
                </th>
                <th scope="col" className="wd-80 bd-t-0-f">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>[GMT+09:00] Asia/Seoul</td>
                <td className="text-center">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="defaultTimezone"
                      id="defaultTimezone1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="defaultTimezone1"></label>
                  </div>
                </td>
                <td className="text-center">
                  <button type="button" className="btn btn-icon pd-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to add a time zone"
            />
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="toggle dropdown">
                <ChevronDown />
                <span className="sr-only">타임존 선택 옵션 열기</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Calendar */}
      <div className="card">
        <div className="card-header h6 bg-light">Calendar</div>
        <div className="card-body">
          <div className="form-group">
            When a two-digit year is entered, interpret it as a year between:
            <input
              type="number"
              className="form-control wd-100 mg-x-5 d-inline-block"
              defaultValue="1930"
              readOnly
            />{' '}
            and{' '}
            <input
              type="number"
              className="form-control wd-100 mg-l-5 d-inline-block"
              defaultValue="2029"
            />
          </div>
          <div className="form-group">
            <label className="form-item-title">First day of week</label>
            <SelectHook
              className="wd-150-f"
              options={[
                { value: 1, label: 'Sunday' },
                { value: 2, label: 'Monday' },
              ]}
            />
          </div>
        </div>
      </div>

      {/* Business Hours */}
      <div className="card">
        <div className="card-header h6 bg-light">Business Hours</div>
        <div className="card-body">
          <div className="row row-xs">
            <div className="col-lg-6 form-group">
              <label className="form-item-title">Start at</label>
              <div className="input-group">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    <Clock />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 form-group">
              <label className="form-item-title">End at</label>
              <div className="input-group">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    <Clock />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fiscal Period */}
      <div className="card">
        <div className="card-header h6 bg-light">Fiscal Period</div>
        <div className="card-body">
          <div className="d-flex align-items-center mg-b-10">
            <span className="flex-shrink-0 mg-r-10">Quarter 1</span>
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
            <span className="flex-shrink-0 mg-x-15">~</span>
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
          <div className="d-flex align-items-center mg-b-10">
            <span className="flex-shrink-0 mg-r-10">Quarter 2</span>
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
            <span className="flex-shrink-0 mg-x-15">~</span>
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
          <div className="d-flex align-items-center mg-b-10">
            <span className="flex-shrink-0 mg-r-10">Quarter 3</span>
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
            <span className="flex-shrink-0 mg-x-15">~</span>
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
          <div className="d-flex align-items-center mg-b-10">
            <span className="flex-shrink-0 mg-r-10">Quarter 4</span>
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
            <span className="flex-shrink-0 mg-x-15">~</span>
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
          <div className="pd-15 bg-light bd bd-dashed rounded">Fiscal Year: FY 2021</div>
        </div>
      </div>
    </div>
  );
};

export default Format;
