import { ChevronDown, ChevronLeft, ChevronRight } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import { Button, Input, Switch, Checkbox, RadioGroup, Select } from '@base/components/form';
import { TextViewField } from '@base/containers/view-field';
import PhoneViewField from '@base/containers/view-field/phone';
import EmailViewField from '@base/containers/view-field/email';
import DataSourceViewField from '@base/containers/view-field/data-source';
import { useState } from 'react';

const Marketing = () => {
  const CONSENT_TYPES = [
    {
      label: 'Opted-out',
      value: 'optedout',
    },
    {
      label: 'Opted-in',
      value: 'optedin',
    },
  ];

  const [options, setOptions] = useState(CONSENT_TYPES);
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
  };

  return (
    <div className="pd-20">
      {/* Lead Process History */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Lead Process History</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#processHistory"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="processHistory" className="card collapse show">
          <div className="table-responsive">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Stage
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Pipeline
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Start
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    End
                  </th>
                  <th scope="col" className="bd-t-0-f sorting" style={{ width: '180px' }}>
                    Convert to Opprtunity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>Raw Lead</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>2022-05-17</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      {/* Last Touch */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Last Touch</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#lastTouch"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="lastTouch" className="card collapse show">
          <div className="table-responsive">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Created On
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Source
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Activity
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Behavior
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2022-05-17</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      {/* Identify Contact Properties */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Identify Contact Properties</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#contactProperties"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="contactProperties" className="card collapse show">
          <div className="table-responsive">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Contact Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Buying Role
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Telephone
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Email
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Job Position
                  </th>
                  <th scope="col" className="wd-30 bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <TextViewField />
                  </td>
                  <td>
                    <DataSourceViewField />
                  </td>
                  <td>
                    <PhoneViewField
                      {...{
                        keyName: 'phones',
                        menuSource: '',
                        menuSourceId: '',
                        value: [],
                        onSave: (params: any) => {},
                      }}
                    />
                  </td>
                  <td>
                    <EmailViewField
                      {...{
                        keyName: 'emails',
                        menuSource: '',
                        menuSourceId: '',
                        value: [],
                        onSave: (params: any) => {},
                      }}
                    />
                  </td>
                  <td>
                    <TextViewField />
                  </td>
                  <td>
                    <Button color="link" icon="X" iconClass="tx-danger" className="btn-icon" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex align-items-center pd-10 bd-t">
            <Input placeholder="Contact Name" />
            <Button color="primary" icon="Plus" name="Add" className="flex-shrink-0 mg-l-10" />
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      {/* Contact Method */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Contact Method</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#contactMethod"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="contactMethod" className="card card-body collapse show">
          <div className="row">
            <div className="form-group col-12 d-flex align-items-center pd-b-15 bd-b">
              <span className="mg-r-10 tx-color-03">Preferred</span>
              <Select
                className="wd-300-f"
                options={[
                  { value: 'email', label: 'Email' },
                  { value: 'bemail', label: 'Bulk Email' },
                  { value: 'telephone', label: 'Telephone' },
                  { value: 'sms', label: 'SMS' },
                ]}
              />
            </div>
            <div className="form-group col-md-6 d-flex flex-wrap align-items-center justify-content-between">
              <span className="tx-color-03">Email</span>
              <Switch title="Allow" />
            </div>
            <div className="form-group col-md-6 d-flex flex-wrap align-items-center justify-content-between bd-md-l">
              <span className="tx-color-03">Bulk Email</span>
              <Switch title="Allow" />
            </div>
            <div className="form-group col-md-6 d-flex flex-wrap align-items-center justify-content-between">
              <span className="tx-color-03">Telephone</span>
              <Switch title="Allow" />
            </div>
            <div className="form-group col-md-6 d-flex flex-wrap align-items-center justify-content-between bd-md-l">
              <span className="tx-color-03">SMS</span>
              <Switch title="Allow" />
            </div>
          </div>
        </div>
      </div>

      {/* Consent */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Consent</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#consent"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="consent" className="card card-body collapse show">
          <RadioGroup value={selectedValue} options={options} onChange={handleValueChange} />
        </div>
      </div>

      {/* Idntify Pain Point */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Idntify Pain Point</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#painPoint"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="painPoint" className="list-group collapse show">
          <div className="list-group-item bg-white d-flex align-items-center">
            <Checkbox label="Reduce Cost" />
            <Button
              color="link"
              icon="X"
              iconClass="tx-danger"
              className="btn-icon mg-l-auto"
              data-han-tooltip="Delete"
            />
          </div>
          <div className="list-group-item bg-white d-flex align-items-center">
            <Checkbox label="Save Time" />
            <Button
              color="link"
              icon="X"
              iconClass="tx-danger"
              className="btn-icon mg-l-auto"
              data-han-tooltip="Delete"
            />
          </div>
          <div className="list-group-item bg-white d-flex align-items-center">
            <Checkbox label="Streamline their existing process" />
            <Button
              color="link"
              icon="X"
              iconClass="tx-danger"
              className="btn-icon mg-l-auto"
              data-han-tooltip="Delete"
            />
          </div>
          <div className="list-group-item bg-white d-flex align-items-center">
            <Checkbox label="Grow their Sales" />
            <Button
              color="link"
              icon="X"
              iconClass="tx-danger"
              className="btn-icon mg-l-auto"
              data-han-tooltip="Delete"
            />
          </div>
          <div className="list-group-item bg-white d-flex align-items-center d-flex">
            <Input placeholder="Pain Point" />
            <Button color="primary" icon="Plus" name="Add" className="flex-shrink-0 mg-l-10" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
