import { useState } from 'react';
import { ChevronDown, ChevronUp, Plus, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const NewTaskTemp = () => {
  const [taskType, setTaskType] = useState('manual');
  const handleTaskTypeChange = (e: any) => {
    const { value } = e.target;
    setTaskType(value);
  };

  return (
    <div id="newTaskTemp" className="modal fade">
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title h6">Creat Task Template</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Template Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Task Type</label>
              <div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="taskTypeRadio1"
                    name="taskTypeRadio"
                    className="custom-control-input"
                    value="manual"
                    checked={taskType === 'manual'}
                    onChange={(e) => handleTaskTypeChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="taskTypeRadio1">
                    Manual
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="taskTypeRadio2"
                    name="taskTypeRadio"
                    className="custom-control-input"
                    value="checklist"
                    checked={taskType === 'checklist'}
                    onChange={(e) => handleTaskTypeChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="taskTypeRadio2">
                    Check list
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="taskTypeRadio3"
                    name="taskTypeRadio"
                    className="custom-control-input"
                    value="sequence"
                    checked={taskType === 'sequence'}
                    onChange={(e) => handleTaskTypeChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="taskTypeRadio3">
                    Sequence
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Subject</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Purpose</label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="form-item-title">Priority</label>
              <SelectHook />
            </div>

            {/* Task type : Manual */}
            {taskType === 'manual' && (
              <div className="form-group">
                <label className="form-item-title">Detail</label>
                <div>Editor</div>
              </div>
            )}

            {/* Task type : Check list */}
            {taskType === 'checklist' && (
              <div className="form-group">
                <label className="form-item-title">Check list</label>
                <div className="table-responsive bd rounded-top">
                  <table className="table mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Title
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Description
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Duration
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Instruntion
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input type="text" className="form-control mn-wd-200" />
                        </td>
                        <td>
                          <input type="text" className="form-control mn-wd-200" />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <input type="number" className="form-control wd-100 mg-r-5" />
                            <div className="input-group dropdown wd-150">
                              <input type="text" className="form-control" defaultValue="day(s)" />
                              <div className="input-group-append">
                                <button type="button" className="btn">
                                  <ChevronDown />
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link"
                            data-toggle="modal"
                            data-target="#newInstructionModal"
                          >
                            Detail
                          </button>
                        </td>
                        <td>
                          <button type="button" className="btn tx-danger">
                            <X />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                  <input
                    type="text"
                    className="form-control mg-r-5"
                    placeholder="Enter the title"
                  />
                  <button type="button" className="btn btn-primary flex-shrink-0">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </div>
              </div>
            )}

            {/* Task type : Sequence */}
            {taskType === 'sequence' && (
              <div className="form-group">
                <label className="form-item-title">Sequence</label>
                <div className="table-responsive bd rounded">
                  <table className="table mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Sequence
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Title
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Description
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Duration
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Instruntion
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#1</td>
                        <td>
                          <input type="text" className="form-control mn-wd-200" />
                        </td>
                        <td>
                          <input type="text" className="form-control mn-wd-200" />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <input type="number" className="form-control wd-100 mg-r-5" />
                            <div className="input-group dropdown wd-150">
                              <input type="text" className="form-control" defaultValue="day(s)" />
                              <div className="input-group-append">
                                <button type="button" className="btn">
                                  <ChevronDown />
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link"
                            data-toggle="modal"
                            data-target="#newInstructionModal"
                          >
                            Detail
                          </button>
                        </td>
                        <td>
                          <button type="button" className="btn tx-danger">
                            <X />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                  <input
                    type="text"
                    className="form-control mg-r-5"
                    placeholder="Enter the title"
                  />
                  <button type="button" className="btn btn-primary flex-shrink-0">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="form-item-title">Knowledge Base</label>
              <div className="input-group mg-t-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select an article"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    data-toggle="modal"
                    data-target="#KBlistModal"
                  >
                    {Icon('helpdesk')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTaskTemp;
