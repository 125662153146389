import { Upload, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';

const NewCashImage = () => {
  return (
    <div
      id="addCashImageModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">New Cash Image</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="d-block form-item-title">Value</label>
              <div className="d-flex">
                <SelectHook className="wd-100-f mg-r-5" options={[{ value: 1, label: '￦' }]} />
                <input type="number" className="form-control form-control-lg wd-150" />
              </div>
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Image</label>
              <button type="button" className="btn btn-outline-primary">
                <Upload className="mg-r-5" />
                Upload image
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCashImage;
