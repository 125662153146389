import { Input, Select as SelectHook } from '@base/components/form';
import AssignmentType from './containers/assignment-type';
import Stage from './containers/stage';
import ManualStatus from './containers/manualStatus';
import Due from './containers/due';

const StepAction = () => {
  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Step Type</label>
        <div>Action</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Step Name</label>
        <Input />
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Stage</label>
        <Stage className="wd-300-f" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Select Action</label>
        <SelectHook
          className="wd-300-f"
          options={[
            { value: 1, label: 'Email' },
            { value: 2, label: 'Meeting' },
            { value: 3, label: 'Task' },
          ]}
          defaultValue={[{ value: 1, label: 'Email' }]}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Template</label>
        <div className="d-flex align-items-center">
          <SelectHook className="wd-300-f" defaultValue={[{ value: 1, label: 'Template 1' }]} />
          <button type="button" className="btn btn-link">
            preview
          </button>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Content</label>
        <div className="d-flex align-items-center">
          <SelectHook />
        </div>
      </div>
      <AssignmentType />
      <Due />
      <ManualStatus />
    </>
  );
};

export default StepAction;
