import { X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const PreviewTemp = () => {
  return (
    <div id="previewTemp" className="modal fade">
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title h6">Template 1</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Task Type</label>
              <div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="taskTypeRadio1"
                    name="taskTypeRadio"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="taskTypeRadio1">
                    Manual
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="taskTypeRadio2"
                    name="taskTypeRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="taskTypeRadio2">
                    Check list
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="taskTypeRadio3"
                    name="taskTypeRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="taskTypeRadio3">
                    Sequence
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input type="text" className="form-control" defaultValue="Subject 1" />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              />
            </div>
            <div className="form-group">
              <label>Purpose</label>
              <SelectHook options={[{ value: 1, label: 'Product Info' }]} />
            </div>
            <div className="form-group">
              <label>Priority</label>
              <SelectHook options={[{ value: 1, label: 'Normal' }]} />
            </div>
            <div className="form-group">
              <label className="form-item-title">Detail</label>
              {/* Editor */}
              <textarea cols={10} rows={3} className="form-control">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut semper purus.
                Nullam ultrices mauris quis ipsum imperdiet feugiat. Praesent suscipit viverra
                volutpat. Phasellus tincidunt eleifend scelerisque.
              </textarea>
            </div>
            <div className="form-group">
              <label className="form-item-title">Knowledge Base</label>
              <ul className="list-group task-kb-list">
                <li className="list-group-item">
                  Lorem ipsum dolor sit amet?
                  <button type="button" className="btn pd-y-0 float-right" aria-label="delete">
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </li>
              </ul>
              <div className="input-group mg-t-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select an article"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    data-toggle="modal"
                    data-target="#KBlistModal"
                  >
                    {Icon('helpdesk')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewTemp;
