import { CheckCircle, Circle } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Process = () => {
  return (
    <>
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Business Process</span>
        </div>
        <div className="card card-body">
          <ol className="list-unstyled process-wrap text-nowrap">
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Planning</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>Draft</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Organizing</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Approval</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Campaign Activity to promote</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Launch</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Post Event</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Closed</div>
            </li>
          </ol>
        </div>
      </div>
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Journey Process</span>
        </div>
        <div className="card card-body">&nbsp;</div>
      </div>
    </>
  );
};

export default Process;
