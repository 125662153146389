import { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronUp,
  X,
  Monitor,
  ChevronDown,
  RotateCcw,
  RotateCw,
  Type,
  Image,
  PlayCircle,
  List,
  ArrowUp,
  Move,
  Copy,
  Trash,
  MessageCircle,
  Clock,
  Map,
} from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'Select a Sample',
  },
  {
    value: 2,
    label: 'Design',
  },
];

const DESIGNTABS = [
  {
    value: 1,
    label: 'Designer',
  },
  {
    value: 2,
    label: 'HTML',
  },
  {
    value: 3,
    label: 'Preview',
  },
];

const EditPage = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [designTab, setDesignTab] = useState(DESIGNTABS[0]);

  return (
    <div
      id="editPageModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog full-modal">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Edit Page</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body d-flex flex-column overflow-hidden pd-0-f">
            {/* steps */}
            <ul className="steps steps-justified steps-tab">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  className={classnames('step-item', {
                    complete: item.value < tab.value,
                    active: item.value == tab.value,
                    disabed: item.value > tab.value,
                  })}
                >
                  <button
                    type="button"
                    className="btn step-link rounded-0"
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    <span className="step-number">{item.value}</span>
                    <span className="step-title">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
            {tab.value == 1 && (
              <div
                className="row row-xs mg-0 overflow-auto"
                style={{ height: 'calc(100% - 50px)' }}
              >
                <div className="col-lg-6 d-flex flex-column ht-100p">
                  <ul className="nav nav-line bd-1">
                    <li className="nav-item">
                      <button
                        type="button"
                        data-target="#myLandingPage"
                        className="btn nav-link pd-x-10-f active"
                        data-toggle="tab"
                      >
                        My
                      </button>
                    </li>
                    <li className="nav-item mg-l-0-f">
                      <button
                        type="button"
                        data-target="#sampleLandingPage"
                        className="btn nav-link pd-x-10-f"
                        data-toggle="tab"
                      >
                        Sample
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pd-x-10 overflow-auto">
                    <div id="myLandingPage" className="tab-pane fade show active">
                      <div className="card-columns temp-columns">
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">Blank</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 1</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 2</div>
                          <button type="button" className="btn btn-select active">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 3</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="sampleLandingPage" className="tab-pane fade">
                      <SelectHook
                        className="wd-200-f mg-t-10"
                        options={[{ value: 1, label: 'All(20)' }]}
                      />
                      <div className="card-columns temp-columns">
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">AAA</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">BBB</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex flex-column ht-100p">
                  <div className="pd-y-9">Preview</div>
                  <div className="flex-grow-1 overflow-auto mg-b-10 pd-10 bd"></div>
                </div>
              </div>
            )}
            {tab.value == 2 && (
              <div className="d-flex flex-column pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="flex-grow-1 mg-t-0-f bd rounded overflow-auto">
                  <div className="row no-gutters ht-100p overflow-auto">
                    <div
                      className={classnames(
                        'd-flex flex-column ht-100p',
                        { 'col-lg-7 col-xl-8': designTab.value == 1 },
                        { 'col-12': designTab.value != 1 },
                      )}
                    >
                      <div className="d-flex">
                        {/* tabs */}
                        <ul className="nav nav-line bd-1 flex-grow-1">
                          {DESIGNTABS.map((item, index) => (
                            <li className="nav-item" key={index}>
                              <button
                                type="button"
                                className={classnames('btn nav-link pd-x-10-f', {
                                  active: item.value == designTab.value,
                                })}
                                onClick={(e) => {
                                  setDesignTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </li>
                          ))}
                        </ul>
                        {designTab.value == 1 && (
                          <div className="btn-group bd-b">
                            <button
                              type="button"
                              data-han-tooltip="Undo"
                              className="btn btn-icon han-tooltip"
                            >
                              <RotateCcw />
                              <span className="sr-only">실행취소</span>
                            </button>
                            <button
                              type="button"
                              data-han-tooltip="Redo"
                              className="btn btn-icon han-tooltip"
                            >
                              <RotateCw />
                              <span className="sr-only">재실행</span>
                            </button>
                            <button
                              type="button"
                              data-han-tooltip="Clear Canvas"
                              className="btn btn-icon han-tooltip"
                            >
                              <Trash />
                              <span className="sr-only">초기화</span>
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="flex-grow-1 overflow-auto">
                        {designTab.value == 1 && <></>}
                        {designTab.value == 2 && <div></div>}
                        {designTab.value == 3 && (
                          <div className="d-flex ht-100p">
                            <div className="wd-80 flex-shrink-0 design-preview-btns overflow-auto">
                              <button type="button" className="btn wd-100p ht-80 bd-b">
                                <div>Desktop</div>
                                <Monitor />
                              </button>
                              <button type="button" className="btn wd-100p ht-80 bd-b active">
                                <div>Tablet</div>
                                {Icon('tablet')}
                              </button>
                              <button type="button" className="btn wd-100p ht-80">
                                <div>Mobile</div>
                                {Icon('smartphone')}
                              </button>
                            </div>
                            <div className="flex-grow-1 overflow-auto pd-20 bd-l">
                              {/* Tablet */}
                              <div
                                className="shadow"
                                style={{
                                  width: '828px',
                                  height: '1084px',
                                  margin: '0 auto',
                                  padding: '30px',
                                  borderRadius: '15px',
                                  border: '1px solid #cccccc',
                                }}
                              >
                                <div className="ht-100p overflow-auto bd"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {designTab.value == 1 && (
                      <div className="col-lg-5 col-xl-4 d-flex flex-column ht-100p bd-l">
                        <ul className="nav nav-line bd-1">
                          <li className="nav-item">
                            <button
                              type="button"
                              data-target="#designToolbox"
                              className="btn nav-link pd-x-10-f active"
                              data-toggle="tab"
                            >
                              Toolbox
                            </button>
                          </li>
                          <li className="nav-item mg-l-0-f">
                            <button
                              type="button"
                              data-target="#designProperties"
                              className="btn nav-link pd-x-10-f"
                              data-toggle="tab"
                            >
                              Properties
                            </button>
                          </li>
                          <li className="nav-item mg-l-0-f">
                            <button
                              type="button"
                              data-target="#designStyle"
                              className="btn nav-link pd-x-10-f"
                              data-toggle="tab"
                            >
                              Styles
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content flex-grow-1 overflow-auto">
                          <div id="designToolbox" className="tab-pane fade show active">
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designExtra"
                            >
                              Extra
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designExtra" className="collapse fade show bd-b">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('slider')}
                                  <div className="ele-name">Slider</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('tab')}
                                  <div className="ele-name">Tabs</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('navigation_bar')}
                                  <div className="ele-name">Navigation</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('typed')}
                                  <div className="ele-name">Typed</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <MessageCircle />
                                  <div className="ele-name">Tooltip</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Clock />
                                  <div className="ele-name">Countdown</div>
                                </button>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designLayout"
                            >
                              Layout
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designLayout" className="collapse fade show bd-b">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column1')}
                                  <div className="ele-name">1 Column</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2')}
                                  <div className="ele-name">2 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column3')}
                                  <div className="ele-name">3 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2_2')}
                                  <div className="ele-name">3:7 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2_3')}
                                  <div className="ele-name">7:3 Columns</div>
                                </button>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designElement"
                            >
                              Elements
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designElement" className="collapse fade show">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('heading')}
                                  <div className="ele-name">Heading</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Type />
                                  <div className="ele-name">Text</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Image />
                                  <div className="ele-name">Image</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <PlayCircle />
                                  <div className="ele-name">Video</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Map />
                                  <div className="ele-name">Map</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('button')}
                                  <div className="ele-name">Button</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('divider')}
                                  <div className="ele-name">Divider</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('table')}
                                  <div className="ele-name">Table</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <List />
                                  <div className="ele-name">List</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('cta')}
                                  <div className="ele-name">CTA</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('personalize_code')}
                                  <div className="ele-name">Personalize</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('form')}
                                  <div className="ele-name">Form</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('event')}
                                  <div className="ele-name">Event</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('survey')}
                                  <div className="ele-name">Survey</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('option_set')}
                                  <div className="ele-name">Option Set</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('social_share')}
                                  <div className="ele-name">Social Share</div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="designProperties" className="tab-pane fade">
                            {/* image type property */}
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designIMG"
                            >
                              Image
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designIMG" className="collapse fade show pd-15">
                              <div className="form-group">
                                <label className="form-item-title d-block">Type</label>
                                <div className="btn-group img-type-list">
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image only"
                                  >
                                    <Image />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image on top"
                                  >
                                    {Icon('img_on_top')}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image on bottom"
                                  >
                                    {Icon('img_on_bottom')}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image on left"
                                  >
                                    {Icon('img_on_left')}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image on right"
                                  >
                                    {Icon('img_on_right')}
                                  </button>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Source</label>
                                <SelectHook />
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Alt Text</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div id="designStyle" className="tab-pane fade"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            {tab.value == 1 && (
              <>
                <button type="button" className="btn btn-white">
                  Skip
                </button>
                <button type="button" className="btn btn-primary">
                  Select
                </button>
              </>
            )}
            {tab.value == 2 && (
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Finish
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPage;
