import React, { useState } from 'react';

// import { PopoverBody, Popover, Button } from 'Crm/components/Bootstraps';
import { X } from 'react-feather';

const ContactDetail = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div className="pd-15">
      <div className="form-row">
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title d-block">Tags</label>
          <div className="tag-item">
            Tag 1
            <button type="button" className="btn-tag-remove">
              <X />
              <span className="sr-only">삭제</span>
            </button>
          </div>
          <div className="tag-item">
            Tag 2
            <button type="button" className="btn-tag-remove">
              <X />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Address</label>
          <div>
            <span className="badge badge-dark mg-r-5">Company</span>Park Way Cupertino, CA 95014
          </div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Ranking</label>
          <div className="d-flex align-items-center">
            <div
              className="wd-30 ht-30 mg-r-5 pd-0 bd-0 rounded-circle text-center lh-12"
              style={{ backgroundColor: '#bc9546' }}
            >
              <span className="tx-white tx-semibold">G</span>
            </div>
            Gold
            <button type="button" id="rankingScore" className="mg-l-auto btn btn-link pd-0">
              (Score: 50)
            </button>
            {/* <Popover placement="bottom" isOpen={popoverOpen} target="rankingScore" toggle={toggle}>
              <PopoverBody>
                <ul className="list-unstyled mg-y-10">
                  <li className="d-flex align-items-center justify-content-between pd-x-10">
                    Monthly Payment: <span className="mg-l-10">20</span>
                  </li>
                  <li className="d-flex align-items-center justify-content-between pd-x-10 mg-t-5">
                    Length of Business Relationship: <span className="mg-l-10">15</span>
                  </li>
                  <li className="d-flex align-items-center justify-content-between pd-x-10 mg-t-5">
                    Total Payments (Past): <span className="mg-l-10">15</span>
                  </li>
                </ul>
              </PopoverBody>
            </Popover> */}
          </div>
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Service Level Agreement</label>
          <div>Standard SLA</div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
