import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  X,
  Menu,
  ChevronsLeft,
  ChevronsRight,
  Edit2,
  ArrowLeft,
  Check,
  Mail,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "InvoiceCss/invoice.scss"

import Attachments from '../../../../component/desktop/attachments';
import Userlist from '../../../../component/desktop/userlist';

import Timeline from '../timeline';
import SendEmail from './modal/send-email';

const TABS = [
  {
    value: 1,
    label: 'Payment Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const View = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/unpaid-invoice/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Payment Made
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">PAM-000001</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <button
              type="button"
              data-han-tooltip="Delete"
              className="btn btn-link link-01 btn-icon han-tooltip"
            >
              <span className="span-icon">
                <Trash />
              </span>
              <span className="sr-only">삭제</span>
            </button>
            <div className="dropdown">
              <button
                type="button"
                data-han-tooltip="More"
                className="btn btn-link link-01 btn-icon han-tooltip"
                data-toggle="dropdown"
              >
                <span className="span-icon">
                  <Menu />
                </span>
                <span className="sr-only">더보기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right"></div>
            </div>
            <button type="button" className="btn btn-primary mg-l-5 rounded-30">
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-section pd-15">
              <div className="form-group">
                <label className="form-item-title">Created on</label>
                <div>2021/04/28</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Owner</label>
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <img
                      src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                      className="rounded-circle"
                      alt="MSR"
                    />
                  </div>
                  <div className="media-body mg-l-10">
                    <span className="user-name">MSR</span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Address</label>
                <div className="mg-b-5">
                  <span className="badge badge-dark mg-r-5 tx-normal tx-13">Bill to</span>
                  Bay Area, San Francisco, CA
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Payment Template</label>
                <div>Template 1</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Email Template</label>
                <div>Template 2</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Vendor Notes</label>
                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                  { hidden: hideAsideLeft },
                )}
                onClick={() => setHideAsideLeft(!hideAsideLeft)}
              >
                <ChevronsLeft />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>

              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map((item, index) => (
                    <li
                      key={index}
                      className={classnames('nav-item', {
                        active: item.value == tab.value,
                      })}
                    >
                      <button
                        type="button"
                        className="btn btn-xs btn-move-tab"
                        aria-label="Drag-and-drop to reorder"
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <button
                        type="button"
                        className="btn nav-link"
                        onClick={(e) => {
                          setTab(item);
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-l-10 bd-r-0 btn-collapse-aside-right',
                  { hidden: hideAsideRight },
                )}
                onClick={() => setHideAsideRight(!hideAsideRight)}
              >
                <ChevronsRight />
                <span className="sr-only">우측 사이드 패널 접기</span>
              </button>
            </div>

            <div className="view-body">
              {tab.value == 1 && (
                <div className="pd-15">
                  <div className="mg-b-10 text-right">
                    <button type="button" className="btn btn-info rounded-30">
                      <span className="mg-r-5">{Icon('preview')}</span>Preview
                    </button>
                  </div>
                  <div className="table-responsive mg-b-10 bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            ID
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Bill Amount
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Amount Made
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Payment Mode
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>INV-000001</td>
                          <td>￦150,000</td>
                          <td>￦150,000</td>
                          <td>Credit Card</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {tab.value == 2 && <Timeline />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            {/* Customer */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Customer</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#customerContact"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="customerContact" className="view-aside-section-body collapse show">
                <Userlist />
              </div>
            </div>

            {/* Attachment */}
            <Attachments />
          </div>
        </div>
      </div>

      <SendEmail />
    </>
  );
};

export default View;
