import { Button, Input, Select } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { Address } from '@customer/customer/components';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import { ChevronUp, X } from 'react-feather';

const NewOpportunity = () => {
  return (
    <div id="newOpportunity" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Create Opportunity</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Title</label>
              <Input />
            </div>
            <div className="form-group-title">Contact</div>
            <div className="form-group">
              <label className="form-item-title">Name</label>
              <Input />
            </div>
            <div className="form-group">
              <label className="form-item-title">Email</label>
              <Input type="email" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Telephone</label>
              <Input type="tel" />
            </div>
            <div className="form-group-title">Account</div>
            <div className="form-group">
              <label className="form-item-title">Account Name</label>
              <Input />
            </div>
            <div className="form-group">
              <label className="form-item-title">Website</label>
              <Input type="url" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Industry</label>
              <Select />
            </div>
            <div className="form-group">
              <label className="form-item-title">Address</label>
              <Address />
            </div>
            <hr />
            <div className="form-group">
              <label className="form-item-title">Type</label>
              <Select
                options={[
                  { value: 1, label: 'New' },
                  { value: 2, label: 'Existing Customer' },
                  { value: 3, label: 'Win back' },
                ]}
                width={180}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Sale Owner</label>
              <UserAutoComplete single showAvatar />
            </div>
            <div className="form-group">
              <label className="form-item-title">Referrer</label>
              <div className="d-flex align-items-center">
                <Select
                  options={[
                    { value: 1, label: 'Agent' },
                    { value: 2, label: 'Customer' },
                    { value: 3, label: 'Internal User' },
                    { value: 4, label: 'Broker' },
                    { value: 5, label: 'Affiliate' },
                  ]}
                  width={150}
                  className="mg-r-5"
                />
                <CustomerAutoComplete value={''} onChange single showAvatar />
              </div>
              <Button color="link pd-x-0" size="sm" icon="Plus" iconClass="mg-r-5" name="Add" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Product</label>
              <ProductAutoComplete />
            </div>
            <div className="form-group">
              <label className="form-item-title">Opportunity Process</label>
              <Select />
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <Input />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Cancel
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOpportunity;
