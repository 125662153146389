import { Select } from '@base/components/form';
import React, { useEffect, useState } from 'react';

const OPTIONS = [
  { value: 'field', label: 'Field Updated' },
  { value: 'property', label: 'Process Property Updated' },
  { value: 'process', label: 'Process Step/Status Changed' },
];

const TriggerType: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  //state
  const [selectedValue, setSelectedValue] = useState<any>(OPTIONS[0]);

  //init data
  useEffect(() => {
    if (value) {
      //single object
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(OPTIONS[0]);
    }
  }, [value]);

  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption);
    //callback
    onChange && onChange(newOption.value);
  };

  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Trigger Type</label>
        <Select
          options={OPTIONS}
          value={selectedValue}
          onChange={handleValueChange}
          className="wd-300-f"
        />
      </div>
      {selectedValue.value === 'field' && (
        <div className="form-group">
          <label className="form-item-title">Field</label>
          <div className="pd-10 bd rounded">
            <div className="row">
              <div className="col-md-6 mg-b-10">
                <label>Module</label>
                <Select />
              </div>
              <div className="col-md-6 mg-b-10 mg-md-b-0">
                <label>Table</label>
                <Select />
              </div>
              <div className="col-md-6 mg-b-10 mg-md-b-0">
                <label>Field</label>
                <Select />
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedValue.value === 'property' && (
        <>
          <div className="form-group">
            <label className="form-item-title">Related Other Process</label>
            <div className="table-responsive bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f text-nowrap">
                      Process Name
                    </th>
                    <th scope="col" className="bd-t-0-f text-nowrap">
                      Property
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <Select />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      {selectedValue.value === 'process' && (
        <>
          <div className="form-group">
            <label className="form-item-title">Related Other Process</label>
            <div className="pd-10 bd rounded">
              <div className="row">
                <div className="col-md-6 mg-b-10">
                  <label>Process Type</label>
                  <Select options={[{ value: 'bp', label: 'Business Process' }]} />
                </div>
                <div className="col-md-6 mg-b-10 mg-md-b-0">
                  <label>Module</label>
                  <Select />
                </div>
                <div className="col-md-6 mg-b-10 mg-md-b-0">
                  <label>Process Name</label>
                  <Select />
                </div>
                <div className="col-md-6 mg-b-10 mg-md-b-0">
                  <label>Property</label>
                  <Select />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TriggerType;
