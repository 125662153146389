import { useState } from 'react';
import { User, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';

const LateFee = () => {
  const [feeType, setFeeType] = useState<any>('percentage');
  const handleFeeTypeChange = (e: any) => {
    const { value } = e.target;
    setFeeType(value);
  };

  return (
    <div
      id="applyLateFeeModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Apply Late Fee</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Fee Type</label>
              <div className="d-flex align-items-center mg-b-5">
                <div className="custom-control custom-radio mg-r-10">
                  <input
                    type="radio"
                    id="feeTypeRadio1"
                    name="feeTypeRadio"
                    className="custom-control-input"
                    value="percentage"
                    checked={feeType === 'percentage'}
                    onChange={(e) => handleFeeTypeChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="feeTypeRadio1">
                    Percentage
                  </label>
                </div>
                {feeType === 'percentage' && (
                  <div className="input-group wd-150">
                    <input type="number" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text lh-1">%</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center">
                <div className="custom-control custom-radio mg-r-10">
                  <input
                    type="radio"
                    id="feeTypeRadio2"
                    name="feeTypeRadio"
                    className="custom-control-input"
                    value="flat"
                    checked={feeType === 'flat'}
                    onChange={(e) => handleFeeTypeChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="feeTypeRadio2">
                    Flat Fee
                  </label>
                </div>
                {feeType === 'flat' && (
                  <div className="input-group wd-150">
                    <div className="input-group-prepend">
                      <span className="input-group-text lh-1">￦</span>
                    </div>
                    <input type="number" className="form-control" />
                  </div>
                )}
              </div>
            </div>
            {feeType === 'percentage' && (
              <div className="form-group">
                <label className="form-item-title">Calculation Type</label>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="calculationTypeRadio1"
                    name="calculationTypeRadio"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="calculationTypeRadio1">
                    Invocie Amonut
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="calculationTypeRadio2"
                    name="calculationTypeRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="calculationTypeRadio2">
                    Balance Due
                  </label>
                </div>
                <div className="mg-t-10 lh-1 tx-orange">
                  Late fee will be calculated based on the amount of invoice or unpaid balance due.
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="form-item-title">Recurring</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="recurringSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="recurringSwitch1"></label>
              </div>
              <div className="d-flex align-items-center">
                Every
                <input type="number" className="form-control wd-100 mg-x-10" />
                <SelectHook className="wd-100-f" options={[{ value: 1, label: 'day(s)' }]} />
              </div>
              <div className="mg-t-10 lh-1 tx-orange">
                The recurring late fee will be added by every day(s) or week(s) automatically.
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Send Late Fee Reminder</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="reminderSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="reminderSwitch1"></label>
              </div>
              <ul className="list-unstyled">
                <li className="mg-t-10">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="customCheck1">
                      sales@mail.com
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-light mg-r-5" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LateFee;
