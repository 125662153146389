import { Button, Checkbox, Input } from '@base/components/form';
import { ChevronUp } from 'react-feather';
const NewAssignmentRule = () => {
  return (
    <div id="newAssignmentRule" className="modal right fade">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Creat Assignment Rule</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="form-group">
              <label>Rule Name</label>
              <Input />
            </div>
            <div className="form-group">
              <label>Description</label>
              <Input />
            </div>
            <div className="form-group">
              <label className="d-block">Module</label>
              <Checkbox label="Sales" className="d-inline-block mg-r-10" />
              <Checkbox label="Desk" className="d-inline-block" />
            </div>
            <div className="form-group">
              <label>Criteria</label>
              <ul className="list-group">
                <li className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white">
                  Product
                  <Input className="wd-250" />
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white">
                  Region
                  <Input className="wd-250" />
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white">
                  Language
                  <Input className="wd-250" />
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white">
                  Category
                  <Input className="wd-250" />
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white">
                  Tag
                  <Input className="wd-250" />
                </li>
              </ul>
            </div>
            <div className="form-group">
              <label>Assign To</label>
              <div className="table-responsive bd rounded">
                <table className="table table-bordered bd-0 mg-b-0">
                  <thead>
                    <tr>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Group Name
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        User Name
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Capacity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button
                color="link"
                icon="Plus"
                iconClass="mg-r-5"
                name="Add"
                className="pd-x-0"
                data-toggle="modal"
                data-target="#newAssignmentTo"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewAssignmentRule;
