import { DatePicker } from '@base/components/date-picker';
import { Button, Input, RadioGroup, Select } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { useState } from 'react';
import { ChevronUp, Settings } from 'react-feather';
import noImg from '@base/assets/img/notfound.png';
import Icon from '@base/assets/icons/svg-icons';
import Delivery from '../containers/delivery/write';

const NewPurchaseReturn = () => {
  const RETURN_TYPES = [
    {
      label: 'Refund',
      value: 'refund',
    },
    {
      label: 'Exchange',
      value: 'exchange',
    },
  ];

  const [options, setOptions] = useState(RETURN_TYPES);
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
  };

  const REFUND_METHOD = [
    {
      label: 'Credit Note',
      value: 'note',
    },
    {
      label: 'Cash/Credit Card',
      value: 'card',
    },
  ];

  const [methods, setMethods] = useState(REFUND_METHOD);
  const [selectedMethod, setSelectedMethod] = useState(methods[0].value);

  //change
  const handleMethodChange = (newOption: any) => {
    setSelectedMethod(newOption.value);
  };

  return (
    <div
      id="newPurchaseReturn"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Create Purchase Return</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body">
            <div className="text-right">
              <span className="tx-danger">*</span> is required field.
            </div>
            <div className="form-row">
              <div className="form-group col-12">
                <label className="form-item-title">
                  Subject<span className="tx-danger">*</span>
                </label>
                <Input />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Vendor Name</label>
                <div>Vendor 1</div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">
                  Return ID<span className="tx-danger">*</span>
                </label>
                <Input rightIcon={<Settings />} value="RMA-000001" />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Reference ID</label>
                <div>PO-000001</div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">
                  Return Date<span className="tx-danger">*</span>
                </label>
                <DatePicker
                  selected={new Date()}
                  onChange={() => {
                    console.log();
                  }}
                />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Return Type</label>
                <RadioGroup value={selectedValue} options={options} onChange={handleValueChange} />
              </div>
              {selectedValue === 'refund' && (
                <>
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Refund Method</label>
                    <RadioGroup
                      value={selectedMethod}
                      options={methods}
                      onChange={handleMethodChange}
                    />
                  </div>
                </>
              )}
              <Delivery direction="from" />
              <div className="form-group col-lg-6">
                <label className="form-item-title">
                  Reason<span className="tx-danger">*</span>
                </label>
                <Select />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">
                  Assigned Rep<span className="tx-danger">*</span>
                </label>
                <UserAutoComplete single showAvatar />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">
                  Return Process<span className="tx-danger">*</span>
                </label>
                <Select />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Description</label>
                <Input />
              </div>
              <div className="form-group col-12">
                <label className="form-item-title">
                  Product Details<span className="tx-danger">*</span>
                </label>
                <div className="d-flex align-items-center mg-b-10">
                  <Select
                    defaultValue={[{ value: 0, label: 'Select a template' }]}
                    className="wd-200-f"
                  />
                  <Button color="link" name="Preview" />
                </div>
                <div className="table-responsive bd rounded-top">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Image
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Item
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Purchase Unit
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Purchase Price
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Qty Returnable
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Qty Returned
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="avatar avatar-sm bg-gray-200 rounded">
                            <img src={noImg} alt="" />
                          </div>
                        </td>
                        <td>Bottle/Mint/Small</td>
                        <td>Bottle</td>
                        <td>1,500</td>
                        <td>30</td>
                        <td>
                          <Input />
                        </td>
                        <td>
                          <Button
                            color="link"
                            icon="X"
                            iconClass="tx-danger"
                            className="btn btn-link link-03 btn-icon"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row row-xs mg-y-10">
                  <div className="col-md-6">
                    <ul className="list-unstyled lh-7 mg-b-0">
                      <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                        <span>Items : 0</span>
                        <span> ₩ 0</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
                      <li className="d-flex justify-content-between">
                        <span>Sub-Total</span>
                        <span>￦0</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <span>Tax</span>
                        <span>￦0</span>
                      </li>
                      <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                        <strong>Total</strong>
                        <strong>￦0</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Vendor Note</label>
                <Input />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Cancel
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPurchaseReturn;
