/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';

import {
	ChevronDown,
	Star,
	RefreshCw,
	Search,
	Plus,
	List,
	Grid,
	Columns,
	Settings,
	Menu,
} from 'react-feather';

import Pagination from '../../../component/desktop/pagination';

// import "InvoiceCss/invoice.scss"

const InvList = () => {
	const [listType, setListType] = useState<any>({
		list: true,
		grid: false,
	});

	return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Invoices</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Dashboard
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a role="button">Dashboard</a>
              </div>
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Invoices
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/invoice/desktop/theme1/list">Invoices</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a role="button">Unpaid Invoices</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Credit Note</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Debit Note</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Payments Received</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/sales-commission/desktop/theme1/list">
                  Sales Commission Expense
                </a>
              </div>
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Purchase Bill
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a role="button">Purchase Bill</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Vendor Credit</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Vendor Debit</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Payments Made</a>
              </div>
            </div>
          </div>
          <div className="button-group mg-l-auto">
            <a
              href="/crm/demo-page/ui/salesorder/desktop/theme1/write"
              className="btn btn-primary btn-icon"
              aria-label="add"
            >
              <Plus />
              <span className="sr-only">Add</span>
            </a>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>
        <div className="list-filter-bar">
          <span className="items-no mg-r-10">Total: 100</span>

          <div className="list-filter-items">
            {/* for table list column settings */}
            {listType.list && (
              <div className="dropdown list-filter-item">
                <button type="button" className="btn btn-icon" data-toggle="dropdown">
                  {Icon('column_settings')}
                  <span className="sr-only">항목 설정 열기</span>
                </button>
                <div className="dropdown-menu column-settings">
                  <div className="column-settings-title">Column Settings</div>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="orderColumnCheck1"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="orderColumnCheck1">
                          Invoice ID
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                  </ul>
                  <div className="column-settings-actions">
                    Max : <strong className="mg-x-5 tx-primary">1</strong> / 7
                    <button type="button" className="btn btn-light mg-l-auto">
                      Close
                    </button>
                    <button type="button" className="btn btn-primary mg-l-5">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Created Date
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item">
                    Customer
                  </a>
                  <a role="button" className="dropdown-item">
                    Invoice ID
                  </a>
                  <a role="button" className="dropdown-item">
                    Sales Order ID
                  </a>
                  <a role="button" className="dropdown-item">
                    Invoice Date
                  </a>
                  <a role="button" className="dropdown-item">
                    Status
                  </a>
                  <a role="button" className="dropdown-item">
                    Due Date
                  </a>
                  <a role="button" className="dropdown-item">
                    Amount
                  </a>
                  <a role="button" className="dropdown-item">
                    Balance Due
                  </a>
                  <a role="button" className="dropdown-item">
                    Assigned Rep.
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* Search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <input type="search" className="form-control" />
          </div>
        </div>

        <div className="list-body">
          {listType.list && (
            <div className="table-responsive">
              <table className="table dataTable">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="invoiceCheckAll"
                        />
                        <label className="custom-control-label" htmlFor="invoiceCheckAll"></label>
                      </div>
                    </th>
                    <th scope="col" className="sorting">
                      Invoice ID
                    </th>
                    <th scope="col" className="sorting">
                      Customer
                    </th>
                    <th scope="col" className="sorting">
                      Sales Order ID
                    </th>
                    <th scope="col" className="sorting">
                      Invoice Date
                    </th>
                    <th scope="col" className="sorting">
                      Status
                    </th>
                    <th scope="col" className="sorting">
                      Due Date
                    </th>
                    <th scope="col" className="sorting">
                      Amount
                    </th>
                    <th scope="col" className="sorting">
                      Balance Due
                    </th>
                    <th scope="col" className="sorting">
                      Assigned Rep.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="invoiceCheck3"
                        />
                        <label className="custom-control-label" htmlFor="invoiceCheck3"></label>
                      </div>
                    </td>
                    <td>
                      <a href="/crm/demo-page/ui/invoice/desktop/theme1/view" className="link-02">
                        Inv-000003
                      </a>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                            className="rounded"
                            alt="Adventure Works"
                          />
                        </div>
                        <div className="media-body mg-l-10">Adventure Works</div>
                      </div>
                    </td>
                    <td>SO-000001</td>
                    <td>2021.5.30</td>
                    <td>New</td>
                    <td>2021.6.30</td>
                    <td>￦1,000,000</td>
                    <td>￦1,000,000</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="https://n.hanbiro.com/ncrm/user/photo/10/1"
                            className="rounded-circle"
                            alt="KDH"
                          />
                        </div>
                        <div className="media-body mg-l-10">KDH</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="invoiceCheck3" />
                      <label className="custom-control-label" htmlFor="invoiceCheck3"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/crm/demo-page/ui/invoice/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        Inv-000003
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Customer:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                              className="rounded"
                              alt="Adventure Works"
                            />
                          </div>
                          <div className="media-body mg-l-10">Adventure Works</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Sales Order ID:</span>
                        <span className="grid-list-item-col-content">SO-000001</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Invoice Date:</span>
                        <span className="grid-list-item-col-content">2021.5.30</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Status:</span>
                        <span className="grid-list-item-col-content">New</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Due Date:</span>
                        <span className="grid-list-item-col-content">2021.6.30</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Amount:</span>
                        <span className="grid-list-item-col-content">￦1,000,000</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Balance Due:</span>
                        <span className="grid-list-item-col-content">￦1,000,000</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Assigned Rep.:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ncrm/user/photo/10/1"
                              className="rounded-circle"
                              alt="KDH"
                            />
                          </div>
                          <div className="media-body mg-l-10">KDH</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>
    </>
  );
};

export default InvList;
