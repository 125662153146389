import { Mail, Phone, X } from 'react-feather';
import { Button } from '@base/components/form';

const UserList = () => {
  return (
    <>
      <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-3">
        <div
          className="media align-items-center flex-grow-1"
          style={{ maxWidth: 'calc(100% - 108px' }}
        >
          <div className="avatar avatar-xs">
            <img
              src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
              className="rounded-circle"
            />
          </div>
          <div className="media-body mg-l-5 text-truncate">Situmay</div>
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-link btn-icon mg-l-auto han-tooltip--top"
            data-han-tooltip="Send Email"
          >
            <Mail className="tx-teal" />
            <span className="sr-only">이메일 보내기</span>
          </button>
          <button
            type="button"
            className="btn btn-link btn-icon op-8 han-tooltip--top"
            data-han-tooltip="CRM Call"
          >
            <Phone />
            <span className="sr-only">CRM Call</span>
          </button>
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
          >
            <X className="tx-danger" />
            <span className="sr-only">삭제</span>
          </button>
        </div>
      </div>
      <Button
        color="link"
        size="sm"
        icon="Plus"
        iconClass="mg-r-5"
        name="Add new contact"
        data-toggle="modal"
        data-target="#newContactModal"
      />
    </>
  );
};

export default UserList;
