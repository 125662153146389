import { X } from 'react-feather';

const ApplyPurchaseBill = () => {
  return (
    <div
      id="applyPurchaseBillModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Apply to Purchase Bill</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="media align-items-center mg-b-15">
              <div className="avatar avatar-lg">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                  className="rounded"
                  alt=""
                />
              </div>
              <div className="media-body mg-l-10">
                <h2 className="tx-16">Adventure Works</h2>
                <strong className="d-inline-block tx-info">Credit Amount: ￦417,000</strong>
                <strong className="d-inline-block mg-l-10 pd-l-10 bd-l tx-success">
                  Available Amount: ￦417,000
                </strong>
              </div>
            </div>
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Vendor Credit ID
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Credit Date
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Status
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Remainded Credit
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Apply
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>VCN-000001</td>
                    <td>2020/11/23</td>
                    <td>Approved</td>
                    <td>￦50,000</td>
                    <td>
                      <button type="button" className="btn btn-link">
                        Apply
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>VCN-000008</td>
                    <td>2020/11/23</td>
                    <td>Partial Applied</td>
                    <td>￦40,000</td>
                    <td>
                      <button type="button" className="btn btn-link">
                        Apply
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-light mg-r-5" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyPurchaseBill;
