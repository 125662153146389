import { Download, Edit2, MessageSquare, Paperclip } from 'react-feather';

const Timeline = () => {
  return (
    <div className="new-timeline-group mg-15">
      <div className="new-timeline-header">
        <div className="new-timeline-label">2021-03-17</div>
      </div>
      <div className="new-timeline-item">
        <div className="new-timeline-icon">
          <Edit2 />
        </div>
        <div className="new-timeline-body">
          <div className="card pd-15">
            <div className="new-timeline-body-header">
              <span>
                Expected Duration was updated from <strong className="mg-x-5">1 hour</strong> to{' '}
                <strong className="mg-x-5">1 day</strong> by MSR
              </span>
              <span className="mg-l-auto tx-color-03">15:28</span>
            </div>
          </div>
        </div>
      </div>
      <div className="new-timeline-item">
        <div className="new-timeline-icon">
          <MessageSquare />
        </div>
        <div className="new-timeline-body">
          <div className="card pd-15">
            <div className="new-timeline-body-header">
              <span>
                KDH noted{' '}
                <strong className="mg-x-5">&quot; The demo location is changed. &quot;</strong>
              </span>
              <span className="mg-l-auto tx-color-03">10:00</span>
            </div>
          </div>
        </div>
      </div>
      <div className="new-timeline-item">
        <div className="new-timeline-icon">
          <Paperclip />
        </div>
        <div className="new-timeline-body">
          <div className="card pd-15">
            <div className="new-timeline-body-header">
              <span>
                MSR uploaded a file <strong className="mg-x-5">report.doc</strong>
              </span>
              <span className="mg-l-auto tx-color-03">09:42</span>
            </div>
            <div className="new-timeline-footer">
              <div className="mg-l-auto">
                <button type="button" className="btn btn-link">
                  <Download className="mg-r-5" />
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
