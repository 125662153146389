/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';
import {
	ChevronDown,
	ChevronLeft,
	Settings,
	Star,
	Minus,
	Plus,
	User,
	X,
	ArrowRight,
	Search,
	Trash,
	Image,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "OrderCss/selling.scss";

import SalesDetails from './list';

const Dashboard = () => {
	const [showOrder, setShowOrder] = useState<any>(false);
	const toggle = () => {
		setShowOrder(!showOrder);
	};

	const [orderStatus, setOrderStatus] = useState<any>({
		new: true,
		orders: false,
	});

	const [listTab, setListTab] = useState<any>({
		pos: true,
		sales: false,
	});

	return (
		<>
			<div className="list-wrap">
				<div className="list-header">
					<button
						type="button"
						className="btn btn-xs btn-icon btn-favorite"
						aria-label="즐겨찾기 추가"
					>
						<Star size="18" />
					</button>
					<h1 className="list-header-title">Dashboard</h1>
					<div className="dropdown mg-l-5">
						<button
							type="button"
							className="btn btn-xs btn-white btn-icon"
							aria-label="변경할 메뉴 열기"
							data-toggle="dropdown"
						>
							<ChevronDown />
						</button>
						<div className="dropdown-menu nav-sub">
							<div className="dropdown-item active">
								<a href="">Dashboard</a>
								<button
									type="button"
									className="btn btn-xs btn-set-default btn-icon"
									aria-label="Set as default"
								>
									{/* Add'active' class if this menu is currently set by default */}
									{Icon('set_default')}
									<span className="sr-only">기본 메뉴로 설정</span>
								</button>
							</div>
							<div className="dropdown-item">
								<a href="">Order Process</a>
							</div>
							<div className="dropdown-item">
								<a href="">Sales Order</a>
							</div>
							<div className="dropdown-item">
								<a href="">Point of Sale</a>
							</div>
							<div className="dropdown-item">
								<a href="">Sales Report</a>
							</div>
						</div>
					</div>
				</div>

				<div className="list-body">
					<h2 className="point-section-title">Point of Sale</h2>
					<div className="point-list-wrap">
						<ul className="point-list">
							<li>
								<div className="point-list-pic-wrap">
									<div className="point-list-pic rounded">
										<img
											src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQMUitBDchzXwDimVC3orY6WDMPTsuD4_o3w&usqp=CAU"
											alt=""
										/>
									</div>
									<button
										type="button"
										className="btn btn-white btn-icon btn-point-settings"
										aria-label="settings"
									>
										<Settings />
										<span className="sr-only">PoS 설정</span>
									</button>
								</div>
								<div className="point-list-name">A Point</div>
								<div className="point-list-action">
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#sellingFloorModal"
									>
										Open
									</button>
								</div>
							</li>
							<li>
								<div className="point-list-pic-wrap">
									<div className="point-list-pic rounded">
										<img
											src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS209BlEbh_jojJyy2BP8DttDLzhlcQVkVMKg&amp;usqp=CAU"
											alt=""
										/>
									</div>
									<button
										type="button"
										className="btn btn-white btn-icon btn-point-settings"
										aria-label="settings"
									>
										<Settings />
										<span className="sr-only">PoS 설정</span>
									</button>
								</div>
								<div className="point-list-name">B Point</div>
								<div className="point-list-action">
									<button
										type="button"
										className="btn btn-info"
										data-toggle="modal"
										data-target="#sellingFloorModal"
									>
										Resume
									</button>
									<button type="button" className="btn btn-dark">
										Close
									</button>
								</div>
							</li>
							<li>
								<div className="point-list-pic-wrap">
									<div className="point-list-pic rounded">
										<img src="assets/images/notfound.png" alt="" />
									</div>
									<button
										type="button"
										className="btn btn-white btn-icon btn-point-settings"
										aria-label="settings"
									>
										<Settings />
										<span className="sr-only">PoS 설정</span>
									</button>
								</div>
								<div className="point-list-name">C Point</div>
								<div className="point-list-action">
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#sellingFloorModal"
									>
										Open
									</button>
								</div>
							</li>
							<li>
								<button
									type="button"
									className="btn-add-point btn-icon"
									aria-label="add"
									data-toggle="modal"
									data-target="#pointSettingsModal"
								>
									<Plus />
									<span className="sr-only">PoS 추가</span>
								</button>
							</li>
						</ul>
					</div>
					<hr />

					<nav className="nav nav-line bd-0 mg-b-10">
						<button
							type="button"
							className={classnames('btn nav-link pd-x-10-f', { 'active tx-bold': listTab.pos })}
							onClick={(e) => {
								setListTab({ pos: true });
							}}
						>
							Point of Sale
						</button>
						<button
							type="button"
							className={classnames('btn nav-link pd-x-10-f', { 'active tx-bold': listTab.sales })}
							onClick={(e) => {
								setListTab({ sales: true });
							}}
						>
							Sales Detail
						</button>
					</nav>

					{/* Latest log */}
					{listTab.pos && (
						<div className="table-responsive">
							<table className="table table-bordered dataTable pos-summary-tb">
								<thead>
									<tr>
										<th scope="col" className="sorting">
											Point Name
										</th>
										<th scope="col" className="sorting">
											Assigned Rep
										</th>
										<th scope="col" className="sorting">
											Opening
										</th>
										<th scope="col" className="sorting">
											Closing
										</th>
										<th scope="col" className="sorting">
											Status
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{/* This is not a link  */} B Point</td>
										<td>MSR</td>
										<td>09:00</td>
										<td>&nbsp;</td>
										<td>Opened</td>
									</tr>
									<tr>
										<td>{/* This is not a link  */} A Point</td>
										<td>KDH</td>
										<td>09:00</td>
										<td>15:00</td>
										<td>Closed</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}

					{listTab.sales && <SalesDetails />}
				</div>
			</div>

			{/* floor & order */}
			<div
				id="sellingFloorModal"
				className="modal fade right"
				tabIndex={-1}
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog full-modal floor-order-modal">
					<div className="modal-content rounded-0">
						<div className="modal-header">
							<h1 className="modal-title">A Point</h1>
							<button type="button" className="close" data-dismiss="modal" aria-label="닫기">
								<X />
							</button>
						</div>
						<div className="modal-body pd-0-f">
							<div className={classnames('section-floor-order', { step1: showOrder })}>
								{/* floor */}
								<div className="floor-wrap">
									<div className="floor-header">
										<ul className="nav nav-line" id="myTab5" role="tablist">
											<li className="nav-item">
												<a
													className="nav-link tx-sans active"
													data-toggle="tab"
													href="#"
													role="tab"
												>
													1st Floor
												</a>
											</li>
											<li className="nav-item">
												<a className="nav-link tx-sans" data-toggle="tab" href="#" role="tab">
													2nd Floor
												</a>
											</li>
											<li className="nav-item">
												<a className="nav-link tx-sans" data-toggle="tab" href="#" role="tab">
													Rooftop
												</a>
											</li>
										</ul>
									</div>
									<div className="rooms-layout">
										<div
											className="room-wrap"
											style={{ top: '10px', left: '0', width: '345px', height: '290px' }}
										>
											<div className="room-header tx-sans">A Room</div>
											<div className="room-body">
												{/* empty table */}
												<div
													className="rnd-table empty-table table-circle"
													style={{
														position: 'absolute',
														left: '10px',
														top: '20px',
														width: '100px',
														height: '100px',
														backgroundColor: 'rgb(218, 68, 84)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														A-1<small>0 / 4</small>
													</div>
													<button type="button" className="btn" aria-label="Order" onClick={toggle}>
														<span className="sr-only">주문</span>
													</button>
												</div>

												{/* seated table */}
												<div
													className="rnd-table table-circle"
													style={{
														position: 'absolute',
														left: '120px',
														top: '20px',
														width: '100px',
														height: '100px',
														backgroundColor: 'rgb(218, 68, 84)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														A-2<small>3 / 4</small>
													</div>
													<button
														type="button"
														className="btn"
														aria-label="View Detail"
														data-toggle="modal"
														data-target="#sellingOrderDetailModal"
													>
														<span className="sr-only">상세보기</span>
													</button>
												</div>
												<div
													className="rnd-table table-circle"
													style={{
														position: 'absolute',
														left: '230px',
														top: '20px',
														width: '100px',
														height: '100px',
														backgroundColor: 'rgb(218, 68, 84)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														A-3<small>2 / 4</small>
													</div>
													<button
														type="button"
														className="btn"
														aria-label="View Detail"
														data-toggle="modal"
														data-target="#sellingOrderDetailModal"
													>
														<span className="sr-only">상세보기</span>
													</button>
												</div>

												{/* empty table */}
												<div
													className="rnd-table empty-table table-square"
													style={{
														position: 'absolute',
														left: '10px',
														top: '135px',
														width: '320px',
														height: '100px',
														backgroundColor: 'rgb(218, 68, 84)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														A-4<small>0 / 8</small>
													</div>
													<button type="button" className="btn" aria-label="Order" onClick={toggle}>
														<span className="sr-only">주문</span>
													</button>
												</div>
											</div>
										</div>
										<div
											className="room-wrap"
											style={{ top: '10px', left: '355px', width: '455px', height: '290px' }}
										>
											<div className="room-header tx-sans">B Room</div>
											<div className="room-body">
												{/* seated table */}
												<div
													className="rnd-table table-square"
													style={{
														position: 'absolute',
														left: '10px',
														top: '20px',
														width: '100px',
														height: '210px',
														backgroundColor: 'rgb(240, 166, 47)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														B-1<small>6 / 6</small>
													</div>
													<button
														type="button"
														className="btn"
														aria-label="View Detail"
														data-toggle="modal"
														data-target="#sellingOrderDetailModal"
													>
														<span className="sr-only">상세보기</span>
													</button>
												</div>

												{/* empty table */}
												<div
													className="rnd-table empty-table table-square"
													style={{
														position: 'absolute',
														left: '120px',
														top: '20px',
														width: '100px',
														height: '210px',
														backgroundColor: 'rgb(240, 166, 47)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														B-2<small>0 / 6</small>
													</div>
													<button type="button" className="btn" aria-label="Order" onClick={toggle}>
														<span className="sr-only">주문</span>
													</button>
												</div>
												<div
													className="rnd-table empty-table table-square"
													style={{
														position: 'absolute',
														left: '230px',
														top: '20px',
														width: '100px',
														height: '210px',
														backgroundColor: 'rgb(240, 166, 47)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														B-3<small>0 / 6</small>
													</div>
													<button type="button" className="btn" aria-label="Order" onClick={toggle}>
														<span className="sr-only">주문</span>
													</button>
												</div>

												{/* seated table */}
												<div
													className="rnd-table table-square"
													style={{
														position: 'absolute',
														left: '340px',
														top: '20px',
														width: '100px',
														height: '100px',
														backgroundColor: 'rgb(34, 168, 74)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														B-4<small>1 / 2</small>
													</div>
													<button
														type="button"
														className="btn"
														aria-label="View Detail"
														data-toggle="modal"
														data-target="#sellingOrderDetailModal"
													>
														<span className="sr-only">상세보기</span>
													</button>
												</div>
												<div
													className="rnd-table table-square"
													style={{
														position: 'absolute',
														left: '340px',
														top: '130px',
														width: '100px',
														height: '100px',
														backgroundColor: 'rgb(34, 168, 74)',
														color: '#fff',
													}}
												>
													<div className="point-table">
														B-5<small>2 / 2</small>
													</div>
													<button
														type="button"
														className="btn"
														aria-label="View Detail"
														data-toggle="modal"
														data-target="#sellingOrderDetailModal"
													>
														<span className="sr-only">상세보기</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>

								{/* order */}
								<div className="order-wrap">
									<div className="order-header">
										<button
											type="button"
											className="btn bd-b rounded-0 text-nowrap"
											aria-label="go back"
											onClick={toggle}
										>
											<ChevronLeft className="mg-r-5" />
											Floor
										</button>
										<ul className="pos-tabs">
											<li className={classnames('pos-tabs-item', { active: orderStatus.new })}>
												<a
													role="button"
													className="pos-tabs-link"
													onClick={() => setOrderStatus({ new: true })}
												>
													New
												</a>
											</li>
											<li className={classnames('pos-tabs-item', { active: orderStatus.orders })}>
												<a
													role="button"
													className="pos-tabs-link"
													onClick={() => setOrderStatus({ orders: true })}
												>
													5 Orders
												</a>
											</li>
										</ul>
										{!orderStatus.new && (
											<button
												className="btn btn-primary rounded-0"
												onClick={() => setOrderStatus({ new: true })}
											>
												<Plus />
											</button>
										)}
									</div>
									<div className="order-body">
										{orderStatus.new && (
											<div className="row row-xs ht-100p">
												<div className="col-lg-6 ht-100p selling-write-section">
													<div className="card product-items-list ht-100p">
														<div className="card-header bg-light">Select Product Items</div>
														<div className="card-body flex-grow-1 overflow-auto"></div>
														<div className="card-footer text-right d-lg-none">
															<button type="button" className="btn btn-primary">
																Next
																<ArrowRight className="mg-l-5" />
															</button>
														</div>
													</div>
												</div>
												<div className="col-lg-6 flex-shrink-0 ht-100p selling-write-section">
													<div className="card payment-detail ht-100p">
														<div className="card-header bg-light">Order details</div>
														<div className="card-body"></div>
														<div className="card-footer d-flex justify-content-between"></div>
													</div>
												</div>
											</div>
										)}

										{orderStatus.orders && (
											<>
												<div className="d-flex mg-b-15">
													<div className="input-group wd-350 mg-l-auto">
														<input type="search" className="form-control form-control-lg" />
														<div className="input-group-append">
															<span className="input-group-text tx-gray-400">
																<Search />
															</span>
														</div>
													</div>
												</div>
												<div className="table-responsive bd rounded">
													<table className="table table-hover orders-list-tb">
														<thead>
															<tr className="bg-light">
																<th scope="col">Date</th>
																<th scope="col">Selling ID</th>
																<th scope="col">Customer</th>
																<th scope="col">Amount</th>
																<th scope="col">Status</th>
																<th scope="col">Table</th>
																<th scope="col">Delete</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>2021-06-15 10:14 AM</td>
																<td>SELL-00001</td>
																<td>
																	<div className="media align-items-center">
																		<div className="avatar avatar-xs">
																			<span className="avatar-initial rounded">
																				<Image />
																			</span>
																		</div>
																		<div className="media-body mg-l-10">Socrates Itumay</div>
																	</div>
																</td>
																<td>$300</td>
																<td>Unpaid</td>
																<td>1st Floor A-2</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-link link-03 btn-icon"
																		data-toggle="modal"
																		data-target="#confirmRemoveOrder"
																	>
																		<Trash />
																	</button>
																</td>
															</tr>
															<tr>
																<td>2021-06-15 10:14 AM</td>
																<td>SELL-00002</td>
																<td>
																	<div className="media align-items-center">
																		<div className="avatar avatar-xs">
																			<span className="avatar-initial rounded">
																				<Image />
																			</span>
																		</div>
																		<div className="media-body mg-l-10">Socrates Itumay</div>
																	</div>
																</td>
																<td>$300</td>
																<td>Unpaid</td>
																<td>1st Floor A-3</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-link link-03 btn-icon"
																		data-toggle="modal"
																		data-target="#confirmRemoveOrder"
																	>
																		<Trash />
																	</button>
																</td>
															</tr>
															<tr>
																<td>2021-06-15 10:14 AM</td>
																<td>SELL-00004</td>
																<td>
																	<div className="media align-items-center">
																		<div className="avatar avatar-xs">
																			<span className="avatar-initial rounded">
																				<Image />
																			</span>
																		</div>
																		<div className="media-body mg-l-10">Socrates Itumay</div>
																	</div>
																</td>
																<td>$300</td>
																<td>Unpaid</td>
																<td>1st Floor B-1</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-link link-03 btn-icon"
																		data-toggle="modal"
																		data-target="#confirmRemoveOrder"
																	>
																		<Trash />
																	</button>
																</td>
															</tr>
															<tr>
																<td>2021-06-15 10:14 AM</td>
																<td>SELL-00005</td>
																<td>
																	<div className="media align-items-center">
																		<div className="avatar avatar-xs">
																			<span className="avatar-initial rounded">
																				<Image />
																			</span>
																		</div>
																		<div className="media-body mg-l-10">Socrates Itumay</div>
																	</div>
																</td>
																<td>$300</td>
																<td>Unpaid</td>
																<td>1st Floor B-4</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-link link-03 btn-icon"
																		data-toggle="modal"
																		data-target="#confirmRemoveOrder"
																	>
																		<Trash />
																	</button>
																</td>
															</tr>
															<tr>
																<td>2021-06-15 10:14 AM</td>
																<td>SELL-00003</td>
																<td>
																	<div className="media align-items-center">
																		<div className="avatar avatar-xs">
																			<span className="avatar-initial rounded">
																				<Image />
																			</span>
																		</div>
																		<div className="media-body mg-l-10">Socrates Itumay</div>
																	</div>
																</td>
																<td>$300</td>
																<td>Unpaid</td>
																<td>1st Floor B-5</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-link link-03 btn-icon"
																		data-toggle="modal"
																		data-target="#confirmRemoveOrder"
																	>
																		<Trash />
																	</button>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* simple order detail modal */}
			<div
				id="sellingOrderDetailModal"
				className="modal fade effect-flip-horizontal"
				tabIndex={-1}
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content rounded-0">
						<div className="modal-header">
							<h1 className="modal-title">A-2</h1>
							<button type="button" className="close" data-dismiss="modal" aria-label="닫기">
								<X />
							</button>
						</div>
						<div className="modal-body">
							<div className="d-flex align-items-center pd-x-15 pd-y-10 bg-light rounded">
								<div className="d-flex align-items-center bg-white pd-x-10 pd-y-5 rounded-30">
									<User size="14" className="tx-color-03 mg-r-5" />3
								</div>
								<div className="mg-l-auto">
									<span className="mg-r-5 tx-color-03">Status:</span>
									<span>Unpaid</span>
								</div>
							</div>
							<div className="mg-t-30 tx-sans tx-color-03 tx-uppercase">Orders</div>
							<ul className="selling-order-list">
								<li className="selling-order-list-item">
									<div className="avatar mg-r-5">
										<img
											src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7yXSy--Tqj9Xu3kGwzhNscFOkJgHjwTBaaA&usqp=CAU"
											className="rounded"
											alt=""
										/>
									</div>
									<span>Grilled Salmon</span>
									<div className="d-flex mg-l-auto">
										<button type="button" className="btn btn-white pd-x-10" aria-label="minus">
											<Minus />
											<span className="sr-only">빼기</span>
										</button>
										<input
											type="number"
											className="form-control bd-0 text-center"
											style={{ width: '60px' }}
											defaultValue="2"
										/>
										<button type="button" className="btn btn-white pd-x-10" aria-label="plus">
											<Plus />
											<span className="sr-only">더하기</span>
										</button>
									</div>
									<span className="order-item-price">$45.00</span>
									<button
										type="button"
										className="btn btn-link link-03 pd-x-10"
										aria-label="delete"
									>
										<X />
										<span className="sr-only">Delete</span>
									</button>
								</li>
								<li className="selling-order-list-item">
									<div className="avatar mg-r-5">
										<img
											src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQjA4GKI7SG079Iy81NANtvzLf9amVeS_FtA&usqp=CAU"
											className="rounded"
											alt=""
										/>
									</div>
									<span>Beef Steak</span>
									<div className="d-flex mg-l-auto">
										<button type="button" className="btn btn-white pd-x-10" aria-label="minus">
											<Minus />
											<span className="sr-only">빼기</span>
										</button>
										<input
											type="number"
											className="form-control bd-0 text-center"
											style={{ width: '60px' }}
											defaultValue="1"
										/>
										<button type="button" className="btn btn-white pd-x-10" aria-label="plus">
											<Plus />
											<span className="sr-only">더하기</span>
										</button>
									</div>
									<span className="order-item-price">$28.50</span>
									<button
										type="button"
										className="btn btn-link link-03 pd-x-10"
										aria-label="delete"
									>
										<X />
										<span className="sr-only">Delete</span>
									</button>
								</li>
								<li className="selling-order-list-item">
									<div className="avatar mg-r-5">
										<img
											src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgqOFUGKm-Ibmfk-vaLxPv8TDx8yDTROSSbA&usqp=CAU"
											className="rounded"
											alt=""
										/>
									</div>
									<span>Citrus Salad</span>
									<div className="d-flex mg-l-auto">
										<button type="button" className="btn btn-white pd-x-10" aria-label="minus">
											<Minus />
											<span className="sr-only">빼기</span>
										</button>
										<input
											type="number"
											className="form-control bd-0 text-center"
											style={{ width: '60px' }}
											defaultValue="1"
										/>
										<button type="button" className="btn btn-white pd-x-10" aria-label="plus">
											<Plus />
											<span className="sr-only">더하기</span>
										</button>
									</div>
									<span className="order-item-price">$8.50</span>
									<button
										type="button"
										className="btn btn-link link-03 pd-x-10"
										aria-label="delete"
									>
										<X />
										<span className="sr-only">Delete</span>
									</button>
								</li>
							</ul>
							<div className="order-total">
								<div className="order-total-label">Sub Total</div>
								<div className="order-total-amount">$82.00</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
								Close
							</button>
							<button
								type="button"
								className="btn btn-lg btn-outline-primary"
								data-dismiss="modal"
								onClick={toggle}
							>
								Add Order
							</button>
							<button type="button" className="btn btn-lg btn-primary">
								Payment
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* confirm to delete the order */}
			<div
				id="confirmRemoveOrder"
				className="modal fade effect-scale"
				tabIndex={-1}
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-sm modal-dialog-centered">
					<div className="modal-content rounded-0">
						<div className="modal-body">Are you sure you want to delete this order?</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
								Close
							</button>
							<button type="button" className="btn btn-lg btn-danger">
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
