import { Button } from '@base/components/form';
import { useState } from 'react';
import { ChevronUp, X } from 'react-feather';
import POWrite from '../po-write';

const NewPO = (props: any) => {
  return (
    <div
      id="newPO"
      className={`modal fade ${props.openRight ? 'right' : ''}`}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog modal-lg ${
          props.openRight ? '' : 'modal-dialog-centered modal-dialog-scrollable'
        }`}
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Purchase Order</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className={`modal-body ${props.openRight ? 'overflow-auto pd-b-15-f' : ''}`}>
            <POWrite />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Cancel
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPO;
