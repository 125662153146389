import React, { useEffect, useRef, useState } from 'react';
import { Editor, EditorProps } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import TinymceEditor from '@base/components/form/tinymce-editor';
import siteBuilderPlugin from '../tui-editor/plugins/site-builder';

interface DemoTuiEditorProps {}
const DemoTuiEditor: React.FC<DemoTuiEditorProps> = (props: DemoTuiEditorProps) => {
  const defaultHtml = '<sitebuilder> <div>content site builder</div> </sitebuilder>';
  console.log('defaultHtml', defaultHtml);
  const [content, setContent] = useState<string | undefined>(defaultHtml);
  const editorRef = useRef<any>(null);
  const handleOnChange = (editorType: any) => {
    console.log(editorType);
    let newValue = editorRef?.current?.getInstance() && editorRef.current.getInstance().getHTML();
    console.log(newValue);
  };
  const customHtmlRender = {
    latex(node: any) {
      return { type: 'html', content: 'demo' };
    },
  };
  useEffect(() => {
    if (editorRef) {
      const editorObj: any = editorRef.current.getInstance();
      // editorObj.setHTML(defaultHtml);
    }
  }, [editorRef]);

  return (
    <>
      <h2>Tui Editor</h2>
      <hr />
      <Editor
        initialValue={content != '' ? content : ' '}
        ref={editorRef}
        onChange={(editorType: any) => {
          handleOnChange(editorType);
        }}
        usageStatistics={false}
        useCommandShortcut={true}
        previewStyle={'vertical'}
        initialEditType={'wysiwyg'}
        autofocus={false}
        plugins={[siteBuilderPlugin]}
        // customHTMLRenderer={{
        //   htmlBlock: {
        //     sitebuilder(node, { entering }) {
        //       console.log('htmlBlock => bigg', entering);
        //       return {
        //         type: entering ? 'openTag' : 'closeTag',
        //         tagName: 'div',
        //         classNames: [`heading`],
        //       };
        //     },
        //   },
        // }}
        // {...initEditorProps}
      />
      <h2>Tinymce Editor</h2>
      <hr />
      <TinymceEditor initialValue={defaultHtml} />
    </>
  );
};

export default DemoTuiEditor;
