import { Plus, X } from 'react-feather';

const Sms = () => {
  return (
    <div
      id="marketingChannelSMSModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">SMS</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="marketingChannelSMSSwitch"
                />
                <label className="custom-control-label" htmlFor="marketingChannelSMSSwitch"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Marketing Resources</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="marketingChannelSMSSwitch2"
                />
                <label className="custom-control-label" htmlFor="marketingChannelSMSSwitch2">
                  Available for Sending SMS
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="marketingChannelSMSSwitch3"
                />
                <label className="custom-control-label" htmlFor="marketingChannelSMSSwitch3">
                  SMS Template
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing Sending SMS</label>
              <div className="d-flex align-items-center mg-b-10">
                Available message No.
                <input type="number" className="form-control wd-200 mg-x-10" />
                <button type="button" className="btn btn-sm btn-link">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </div>
              <div className="d-flex align-items-center mg-b-10">
                The balance left
                <input type="number" className="form-control wd-200 mg-x-10" />
              </div>
              <div className="d-flex align-items-center">
                Used this month
                <input type="number" className="form-control wd-200 mg-x-10" />
                <button type="button" className="btn btn-sm btn-link">
                  History
                </button>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing SMS Templates</label>
              <div className="mg-b-10 text-right tx-success">Active EFax Image : 0</div>
              <div className="table-responsive bg-white bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Type
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Number Implemented
                      </th>
                      <th scope="col" className="wd-70 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>General</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Subscription</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Survey</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Referral Reward</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Thank you</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Follow up</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                <input type="text" className="form-control mg-r-5" placeholder="Enter the type" />
                <button type="button" className="btn btn-primary flex-shrink-0">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </div>
              <div className="mg-t-10">File Location: Marketing Resources/Templates/SMS</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Expenses to include in Marketing Investment</label>
              <div className="table-responsive bg-white bd rounded">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Expense Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Avg. Expense
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Per unit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>SMS Sending</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>1 message</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-lg btn-success">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sms;
