import { useState } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, ArrowLeft, Copy, Plus } from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import noImg from '@base/assets/img/notfound.png';

import '../style.scss';

import { COLORS } from '@base/config/constant';

import Attachments from '../../../../component/desktop/attachments';
import Notelist from '../../../../component/desktop/comment';

import OrderViewTimeline from './timeline';
import NewTransfer from './modal/newTransfer';

const TABS = [
  {
    value: 1,
    label: 'Items to Transfer',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Receipt Info',
  },
  {
    value: 4,
    label: 'Note',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLORS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/transfer-order/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Stock Transfer
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/warehouse/desktop/theme1/list">Warehouse</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/inventory-items/desktop/theme1/list">Inventory Items</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/adjustment/desktop/theme1/list">Adjustment</a>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/transfer-order/desktop/theme1/list">Stock Transfer</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/receipt/desktop/theme1/list">Receipt</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/shipment/desktop/theme1/list">Shipment</a>
              </div>
            </div>
          </div>
          <h1 className="view-title">Transfer 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Copy />
                    </span>
                    Clone
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newTransfer"
            >
              <Plus className="mg-r-5" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              {/* Summary */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Summary</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#basicInfoSection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="basicInfoSection" className="view-aside-section-body collapse show">
                  <div className="form-group">
                    <label className="form-item-title">Transfer ID</label>
                    <div>TO-00001</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Scheduled on</label>
                    <div>2020/09/22 </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Reason</label>
                    <div>Inventory Revaluation</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Transfer Type</label>
                    <div>Internal</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Warehouse</label>
                    <div>Seoul1</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Owner</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/10/1"
                          className="rounded-circle"
                          alt="KDH"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <span className="user-name">KDH</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {/* Items to Transfer */}
              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="table-responsive mg-b-10 bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Image
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Item
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            From
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            SKU
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Traceability No
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            To
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Qty Transfered
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="avatar avatar-sm bg-gray-200 rounded">
                              <img src={noImg} alt="" />
                            </div>
                          </td>
                          <td>Item 1</td>
                          <td>B1-01-A-01-A-02-A</td>
                          <td>Bot-Min-Sma</td>
                          <td>20210431</td>
                          <td>Wh1-B1-F2-A-03-01-05</td>
                          <td>30</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* Timeline */}
              {tab.value == 2 && <OrderViewTimeline />}

              {/* Receipt Info */}
              {tab.value == 3 && (
                <div className="form-row mg-0 pd-20">
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Carrier</label>
                    <div>Postal Service</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Tracking No</label>
                    <div>30211223445</div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Received by</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <span className="user-name">MSR</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Received on</label>
                    <div>2020/09/22 </div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Work ID</label>
                    <div>RDC-000001</div>
                  </div>
                </div>
              )}

              {/* Note */}
              {tab.value == 4 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <NewTransfer />
    </>
  );
};

export default ViewNew;
