import { useState } from 'react';
import Icon from '@base/assets/icons/svg-icons';
import { Check, Edit2, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const Inventory = () => {
  const [checked, setChecked] = useState<any>(true);
  const [showEdit, SetShowEdit] = useState<any>();

  return (
    <div className="pd-20">
      <div className="form-row">
        <div className="form-group col-lg-6">
          <label className="form-item-title">Stock on Hand</label>
          <div>300</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">SKU</label>
          <div>JUI-MIN-S</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">Replenishment Point</label>
          <div>100</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">Batch Stock Reserve Method</label>
          <div>FEFO</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">Traceability</label>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="traceabilitySwitch"
              defaultChecked
              onChange={(e) => setChecked(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="traceabilitySwitch"></label>
          </div>
        </div>
        {checked && (
          <>
            <div className="form-group col-lg-6">
              <label className="form-item-title">Tracking Type</label>
              <div>Lot Batch Tracking Based on Expiry Date </div>
            </div>
            <div className="form-group col-lg-6">
              <label className="form-item-title">Manufacturer Date</label>
              <div>2020/09/22</div>
            </div>
            <div className="form-group col-lg-6">
              <label className="form-item-title">Expertaion Date</label>
              <div>2020/09/22</div>
            </div>
            <div className="form-group col-lg-6">
              <label className="form-item-title">Lot(Batch) No</label>
              <div>BT-20220701</div>
            </div>
          </>
        )}
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Total Stocks</span>
        </div>
        <div className="card pd-15">
          <div className="row row-sm">
            <div className="col-6 col-sm-4 col-md-3 col-lg">
              <div className="h4 tx-normal tx-rubik mg-b-10">1,500</div>
              <div className="progress ht-2 mg-b-10">
                <div
                  className="progress-bar bg-df-2"
                  role="progressbar"
                  aria-valuenow={100}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '100%' }}
                ></div>
              </div>
              <div className="h6 tx-uppercase tx-spacing-1 tx-12 tx-color-02 mg-b-2">
                Stock on Hand
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg">
              <div className="h4 tx-normal tx-rubik mg-b-10">500</div>
              <div className="progress ht-2 mg-b-10">
                <div
                  className="progress-bar bg-df-2"
                  role="progressbar"
                  aria-valuenow={33}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '33%' }}
                ></div>
              </div>
              <div className="h6 tx-uppercase tx-spacing-1 tx-12 tx-orange mg-b-2">
                Reserved Stock
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg">
              <div className="h4 tx-normal tx-rubik mg-b-10">500</div>
              <div className="progress ht-2 mg-b-10">
                <div
                  className="progress-bar bg-df-2"
                  role="progressbar"
                  aria-valuenow={33}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '33%' }}
                ></div>
              </div>
              <div className="h6 tx-uppercase tx-spacing-1 tx-12 tx-purple mg-b-2">
                Backorder Stock
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg">
              <div className="h4 tx-normal tx-rubik mg-b-10">0</div>
              <div className="progress ht-2 mg-b-10">
                <div
                  className="progress-bar bg-df-2"
                  role="progressbar"
                  aria-valuenow={0}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '0' }}
                ></div>
              </div>
              <div className="h6 tx-uppercase tx-spacing-1 tx-12 tx-color-03 mg-b-2">
                Awaiting Stock
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg">
              <div className="h4 tx-normal tx-rubik mg-b-10">1,000</div>
              <div className="progress ht-2 mg-b-10">
                <div
                  className="progress-bar bg-df-2"
                  role="progressbar"
                  aria-valuenow={33}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '33%' }}
                ></div>
              </div>
              <div className="h6 tx-uppercase tx-spacing-1 tx-12 tx-success mg-b-2">
                Available Stock
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Stock Locations</span>
          {!showEdit && (
            <button
              type="button"
              className="btn btn-link mg-l-auto"
              onClick={() => SetShowEdit(true)}
            >
              <Edit2 className="mg-r-5" />
              Edit
            </button>
          )}
        </div>
        <div className="table-responsive bg-white bd rounded">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="sorting bd-t-0-f">
                  Warehouse
                </th>
                <th scope="col" className="sorting bd-t-0-f">
                  SKU
                </th>
                <th scope="col" className="sorting bd-t-0-f">
                  Stock on Hand
                </th>
                <th scope="col" className="sorting bd-t-0-f">
                  Locations
                </th>
                <th scope="col" className="sorting bd-t-0-f">
                  Traceability No
                </th>
                <th scope="col" className="sorting bd-t-0-f">
                  Qty Allocated
                </th>
                {showEdit && (
                  <th scope="col" className="sorting bd-t-0-f">
                    Delete
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2}>
                  {showEdit ? (
                    <SelectHook className="wd-200-f" options={[{ value: 1, label: 'Seoul 1' }]} />
                  ) : (
                    'Seoul 1'
                  )}
                </td>
                <td rowSpan={2}>
                  {showEdit ? (
                    <SelectHook
                      className="wd-200-f"
                      options={[{ value: 1, label: 'Jui-min-sma-1' }]}
                    />
                  ) : (
                    'Jui-min-sma-1'
                  )}
                </td>
                <td rowSpan={2}>
                  {showEdit ? (
                    <input type="number" className="form-control" defaultValue="1,000" />
                  ) : (
                    '1,000'
                  )}
                </td>
                <td>
                  {showEdit ? (
                    <SelectHook
                      className="wd-200-f"
                      options={[{ value: 1, label: 'B01-F2-ZA-A-02-03-A' }]}
                    />
                  ) : (
                    'B01-F2-ZA-A-02-03-A'
                  )}
                </td>
                <td>
                  {showEdit ? (
                    <SelectHook
                      className="wd-200-f"
                      options={[{ value: 1, label: 'BT-20220701' }]}
                    />
                  ) : (
                    'BT-20220701'
                  )}
                </td>
                <td>
                  {showEdit ? (
                    <input type="number" className="form-control" defaultValue="300" />
                  ) : (
                    '300'
                  )}
                </td>
                {showEdit && (
                  <td>
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                )}
              </tr>
              <tr>
                <td>
                  {showEdit ? (
                    <SelectHook
                      className="wd-200-f"
                      options={[{ value: 1, label: 'B01-F2-ZA-A-02-03-B' }]}
                    />
                  ) : (
                    'B01-F2-ZA-A-02-03-B'
                  )}
                </td>
                <td>
                  {showEdit ? (
                    <SelectHook
                      className="wd-200-f"
                      options={[{ value: 1, label: 'BT-20220701' }]}
                    />
                  ) : (
                    'BT-20220701'
                  )}
                </td>
                <td>
                  {showEdit ? (
                    <input type="number" className="form-control" defaultValue="300" />
                  ) : (
                    '300'
                  )}
                </td>
                {showEdit && (
                  <td>
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        {showEdit && (
          <>
            <div className="mg-b-10 pd-12 bg-white bd bd-t-0 rounded-bottom">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select a warehouse"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open modal to select warehouse"
                    data-toggle="modal"
                    data-target="#selWarehouseModal"
                  >
                    {Icon('warehouse')}
                    <span className="sr-only">창고 선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="mg-t-10 text-right">
              <button
                type="button"
                className="btn btn-light mg-r-10"
                onClick={() => SetShowEdit(false)}
              >
                <X className="ma-r-5" /> Cancel
              </button>
              <button
                type="button"
                className="btn btn-success mg-r-10"
                onClick={() => SetShowEdit(false)}
              >
                <Check className="ma-r-5" /> Edit
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Inventory;
