/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';
import '@base/components/sidebar-menu/styles.scss';

import AccountProfile from './modal/newAccountProfile';
import ContactProfile from './modal/newContactProfile';
import { Button, Switch } from '@base/components/form';

const NAVS = [
  {
    value: 'm_customer_account',
    label: 'Account',
  },
  {
    value: 'm_customer',
    label: 'Contact',
  },
];

const CustomerProfile = () => {
  const [nav, setNav] = useState(NAVS[0]);
  const [hideSideNav, setSideNav] = useState(false);

  return (
    <div className={classnames('page-container-wrap', { 'hide-sidebar': hideSideNav })}>
      <div className="page-menu-wrap">
        <div className="page-menu-nav-sidebar">
          <ul className="page-menu-sidebar-nav">
            {NAVS.map((item, index) => (
              <li className="page-menu-nav-item" key={index}>
                <button
                  className={classnames('btn page-menu-nav-link', {
                    active: item.value == nav.value,
                  })}
                  onClick={(e) => {
                    setNav(item);
                  }}
                >
                  {Icon(item.value)}
                  <span className="mg-l-5">{item.label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="page-container-content">
        <div className="page-container-content-header">
          <Button
            color=""
            className="btn-toggle-sidebar tx-gray-400"
            icon="ChevronLeft"
            onClick={() => setSideNav(!hideSideNav)}
          />
          <h1 className="mg-b-0 tx-16 tx-uppercase tx-normal mg-l-10 mg-r-10">
            Profile &gt; {nav.label}
          </h1>
          <div className="d-flex align-items-center mg-l-auto">
            <Switch title="Collect" />
            <Button
              color="primary"
              className="mg-l-15"
              icon="Plus"
              name="Add"
              data-toggle="modal"
              data-target={
                nav.value == 'm_customer_account' ? '#newAccountProfile' : '#newContactProfile'
              }
            />
          </div>
        </div>
        <div className="scroll-box ht-100v pd-20">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col" className="wd-70">
                      Default
                    </th>
                    <th scope="col" className="wd-100">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Button
                        color="link"
                        className="pd-0"
                        name="Profile 1"
                        data-toggle="modal"
                        data-target={
                          nav.value == 'm_customer_account'
                            ? '#newAccountProfile'
                            : '#newContactProfile'
                        }
                      />
                    </td>
                    <td>Type 1</td>
                    <td className="text-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="cateDefaultRadio1"
                          name="cateDefaultRadio"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="cateDefaultRadio1"></label>
                      </div>
                    </td>
                    <td>
                      <Button color="" icon="X" iconClass="tx-danger" className="btn-icon pd-y-0" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <AccountProfile />
      <ContactProfile />
    </div>
  );
};

export default CustomerProfile;
