import { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';
import { ChevronDown, Minus, Plus, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';

const TABS = [
  {
    value: 1,
    label: 'Firmographic',
  },
  {
    value: 2,
    label: 'Geographic',
  },
  {
    value: 3,
    label: 'Behavior',
  },
  {
    value: 4,
    label: 'Demographic',
  },
  {
    value: 5,
    label: 'Geographic',
  },
  {
    value: 6,
    label: 'Psychographic',
  },
  {
    value: 7,
    label: 'Behavior',
  },
];

const newCustomerProfile = () => {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <div id="newCustomerProfile" className="modal fade right">
      <div className="modal-dialog">
        <div className="modal-content rounded-0 overflow-hidden">
          <div className="modal-header">
            <h1 className="h6 modal-title">Edit the Profile</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body customer-profile-reg">
            <div className="input-group mg-b-10">
              <input
                type="text"
                className="form-control"
                placeholder="Type or click to select a category"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <ChevronDown />
                </span>
              </div>
            </div>
            {/* tabs */}
            <nav className="nav nav-line bd-1">
              {TABS.map((item, index) => (
                <button
                  key={index}
                  className={classnames('btn nav-link', {
                    active: item.value == tab.value,
                  })}
                  onClick={(e) => {
                    setTab(item);
                  }}
                >
                  {item.label}
                </button>
              ))}
            </nav>

            {/* Account */}
            {tab.value == 1 && (
              <>
                <div className="customer-profile-items bd bd-t-0 rounded-bottom">
                  <ul className="list-unstyled mg-b-0">
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem1"
                      >
                        Industry
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem1" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio1">
                            Administrative Services
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio2">
                            Architecture and Engineering
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio3">
                            Arts, Entertainment, Sports and Media
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio4">
                            Business and Finance
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio5">
                            Cleaning and Maintenance Services
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio6">
                            Community and Social Services
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio7"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio7">
                            Computation and Mathematics
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio8"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio8">
                            Construction and Extraction
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio9"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio9">
                            Education and Libraries
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio10"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio10">
                            Farming, Fishing and Forestry
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio11"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio11">
                            Food and Restaurants
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio12"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio12">
                            Government Employees (Global)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio13"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="industryRadio13">
                            Healthcare and Medical Services
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio14"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio14">
                            IT and Technical Services
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio15"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio15">
                            Installation and Repair Services
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio16"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio16">
                            Legal Services
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio17"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio17">
                            Life, Physical and Social Sciences
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio18"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio18">
                            ManagementMilitary (Global)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio19"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio19">
                            Production
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio20"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio20">
                            Protective Services
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio21"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio21">
                            Sales
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio22"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio22">
                            Transportation and Moving
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="industryRadio"
                            id="industryRadio23"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="industryRadio23">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem2"
                      >
                        Subsidiary
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem2" className="collapse pd-10">
                        <SelectHook
                          options={[
                            { value: 1, label: 'Wholly – owned subsidiaries' },
                            { value: 2, label: 'Partly – owned subsidiaries' },
                          ]}
                        />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem3"
                      >
                        Company Size
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem3" className="collapse pd-10">
                        <SelectHook
                          options={[
                            { value: 1, label: 'Micro (<10 people)' },
                            { value: 2, label: 'Small (<50 people)' },
                            { value: 3, label: 'Medium (<250 people)' },
                            { value: 4, label: 'Large (>=250 people)' },
                          ]}
                        />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem4"
                      >
                        Annual Revenue
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem4" className="collapse pd-10">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">￦</span>
                          </div>
                          <input
                            type="number"
                            className="form-control form-control-lg wd-200"
                            defaultValue="1000000000"
                          />
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem5"
                      >
                        Age(years in business)
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem5" className="collapse pd-10">
                        <DatePicker
                          selected={new Date()}
                          onChange={() => {
                            console.log();
                          }}
                        />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem6"
                      >
                        Business type and Customer type
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem6" className="collapse pd-10">
                        <SelectHook
                          options={[
                            { value: 1, label: 'B2C' },
                            { value: 2, label: 'B2B' },
                            { value: 3, label: 'B2G' },
                            { value: 4, label: 'Non-Profit' },
                            { value: 5, label: 'Public' },
                            { value: 6, label: 'Private' },
                          ]}
                        />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem7"
                      >
                        Investors
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem7" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem8"
                      >
                        Product / Services
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem8" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem9"
                      >
                        Revenue Growth rate
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem9" className="collapse pd-10">
                        <div className="input-group wd-150">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <span className="input-group-text lh-1">%</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem10"
                      >
                        Corporate Anniversary
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem10" className="collapse pd-10">
                        <DatePicker
                          selected={new Date()}
                          onChange={() => {
                            console.log();
                          }}
                        />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem11"
                      >
                        Decision
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem11" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="decisionCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="decisionCheck1">
                            Headquater
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="decisionCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="decisionCheck2">
                            Subsidiary
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="decisionCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="decisionCheck3">
                            Branch
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="decisionCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="decisionCheck4">
                            Singe locations
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="decisionCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="decisionCheck5">
                            Ultimate parent company
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="decisionCheck6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="decisionCheck6">
                            Domestic parent company
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem12"
                      >
                        Ownership Status
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem12" className="collapse pd-10">
                        <SelectHook
                          options={[
                            { value: 1, label: 'Corporations' },
                            { value: 2, label: 'Sole proprietorship/' },
                            { value: 3, label: 'Partnership' },
                            { value: 4, label: 'An individual firm' },
                            { value: 5, label: 'limited liability corporation(LLC)' },
                            { value: 6, label: 'public limited company(PLC)' },
                            { value: 7, label: 'Non profit corporation' },
                            { value: 8, label: 'Cooperative' },
                          ]}
                        />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem13"
                      >
                        Employee Growth
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem13" className="collapse pd-10">
                        <div className="input-group wd-150">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <span className="input-group-text lh-1">%</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem14"
                      >
                        Profits or Losses
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem14" className="collapse pd-10">
                        <div className="d-flex flex-wrap align-items-center">
                          <SelectHook
                            className="wd-100-f"
                            options={[
                              { value: 1, label: 'Profits' },
                              { value: 2, label: 'Losses' },
                            ]}
                          />
                          <div className="input-group wd-200 mg-l-10">
                            <div className="input-group-prepend">
                              <span className="input-group-text">￦</span>
                            </div>
                            <input type="number" className="form-control form-control-lg" />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem15"
                      >
                        Increase or decrease in revenue
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem15" className="collapse pd-10">
                        <div className="d-flex flex-wrap align-items-center">
                          <SelectHook
                            className="wd-100-f"
                            options={[
                              { value: 1, label: 'Increase' },
                              { value: 2, label: 'Decrease' },
                            ]}
                          />
                          <div className="input-group wd-200 mg-l-10">
                            <div className="input-group-prepend">
                              <span className="input-group-text">￦</span>
                            </div>
                            <input type="number" className="form-control form-control-lg" />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem16"
                      >
                        No. of potential customer
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem16" className="collapse pd-10">
                        <div className="d-flex align-items-center">
                          <input
                            type="number"
                            className="form-control form-control-lg wd-100 mg-r-10"
                          />{' '}
                          ~
                          <input
                            type="number"
                            className="form-control form-control-lg wd-100 mg-l-10"
                          />
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem17"
                      >
                        No. of customer
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem17" className="collapse pd-10">
                        <div className="d-flex align-items-center">
                          <input
                            type="number"
                            className="form-control form-control-lg wd-100 mg-r-10"
                          />{' '}
                          ~
                          <input
                            type="number"
                            className="form-control form-control-lg wd-100 mg-l-10"
                          />
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem18"
                      >
                        Market Size
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem18" className="collapse pd-10">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">￦</span>
                          </div>
                          <input type="number" className="form-control form-control-lg" />
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem19"
                      >
                        Market Share
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem19" className="collapse pd-10">
                        <div className="input-group wd-150">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <span className="input-group-text lh-1">%</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem20"
                      >
                        Trends
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem20" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="trendCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="trendCheck1">
                            Growth
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="trendCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="trendCheck2">
                            Decline
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="trendCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="trendCheck3">
                            Stability
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem21"
                      >
                        Property
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem21" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="propertyCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="propertyCheck1">
                            Lease(Plant,Equipment,Office)
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="propertyCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="propertyCheck2">
                            Own(Plant,Equipment,Office)
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem22"
                      >
                        Residency
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem22" className="collapse pd-10">
                        <SelectHook
                          options={[
                            { value: 1, label: 'Less than 1 year' },
                            { value: 2, label: '1-2 years' },
                            { value: 3, label: '2-4 years' },
                            { value: 4, label: '4-6 years' },
                            { value: 5, label: '6-8 years' },
                            { value: 6, label: '8-10 years' },
                            { value: 7, label: 'More than 10 years' },
                          ]}
                        />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem22"
                      >
                        Technology
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem22" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="technologyCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="technologyCheck1">
                            High tech
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="technologyCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="technologyCheck2">
                            Middle tech
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="technologyCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="technologyCheck3">
                            Low tech
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#firmoItem23"
                      >
                        Average sales cycle(How long take to close?)
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="firmoItem23" className="collapse pd-10">
                        <div className="d-flex align-items-center">
                          <input type="number" className="form-control form-control-lg wd-200" />
                          <SelectHook
                            className="wd-100-f mg-l-10"
                            options={[
                              { value: 1, label: 'day(s)' },
                              { value: 2, label: 'month(s)' },
                              { value: 3, label: 'year(s)' },
                            ]}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {tab.value == 2 && (
              <>
                <div className="customer-profile-items bd bd-t-0 rounded-bottom">
                  <ul className="list-unstyled mg-b-0">
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#geoItem1"
                      >
                        Related Account
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="geoItem1" className="collapse pd-10">
                        <div className="input-group">
                          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                            <div className="form-control-tag">
                              <span className="d-block avatar avatar-xs mg-r-10">
                                <span className="avatar-initial rounded-circle">A</span>
                              </span>
                              <span className="form-control-tag-name">AAA</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <input
                              type="text"
                              className="bd-0 flex-grow-1 mn-wd-250 rounded"
                              placeholder="Type or click to select a customer"
                            />
                          </div>
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open modal to select a customer"
                            >
                              {Icon('m_customer')}
                              <span className="sr-only">고객 선택 팝업 열기</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#geoItem2"
                      >
                        Location
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="geoItem2" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="geoLocationRadio"
                            id="geoLocationRadio2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="geoLocationRadio2">
                            Global Urban
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="geoLocationRadio"
                            id="geoLocationRadio3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="geoLocationRadio3">
                            Suburban
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="geoLocationRadio"
                            id="geoLocationRadio4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="geoLocationRadio4">
                            Rural
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="geoLocationRadio"
                            id="geoLocationRadio1"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="geoLocationRadio1">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#geoItem3"
                      >
                        Purchasing Process
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="geoItem3" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="geoPurchasingRadio"
                            id="geoPurchasingRadio2"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="geoPurchasingRadio2">
                            Automation
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="geoPurchasingRadio"
                            id="geoPurchasingRadio3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="geoPurchasingRadio3">
                            Manual
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="geoPurchasingRadio"
                            id="geoPurchasingRadio1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="geoPurchasingRadio1">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {tab.value == 3 && (
              <>
                <div className="customer-profile-items bd bd-t-0 rounded-bottom">
                  <ul className="list-unstyled mg-b-0">
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#behaviorItem1"
                      >
                        Website Visits
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="behaviorItem1" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio2">
                            Direct
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio3">
                            Search
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio4">
                            Blog
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio5">
                            Social
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio6">
                            Referral
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio6">
                            Email
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio7"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio7">
                            Advertising
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio8"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio8">
                            QR code
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            name="behavVisitRadio"
                            id="behavVisitRadio1"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="behavVisitRadio1">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#behaviorItem2"
                      >
                        Response to Marketing
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="behaviorItem2" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="responseRadio1"
                            name="responseRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="responseRadio1">
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="responseRadio2"
                            name="responseRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="responseRadio1">
                            No
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#behaviorItem3"
                      >
                        Usage
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="behaviorItem3" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio1"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio1">
                            Heavy
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio2"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio2">
                            Medium
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio3"
                            name="usageRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="usageRadio3">
                            Light
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio4"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio4">
                            Non-user
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio5"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio5">
                            Targeted post-purchase
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio6"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio6">
                            Notice when upgraded or phased out
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio7"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio7">
                            Aiding in retention
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#behaviorItem4"
                      >
                        User Status
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="behaviorItem4" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="userStatusRadio2"
                            name="userStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="userStatusRadio2">
                            Prospects
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="userStatusRadio3"
                            name="userStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="userStatusRadio3">
                            First-time buyers
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="userStatusRadio4"
                            name="userStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="userStatusRadio4">
                            Regular
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="userStatusRadio5"
                            name="userStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="userStatusRadio5">
                            Returning
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="userStatusRadio6"
                            name="userStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="userStatusRadio6">
                            Defectors (ex-customers who have switched to a competitor)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="userStatusRadio1"
                            name="userStatusRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="userStatusRadio1">
                            Non-users
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#behaviorItem5"
                      >
                        Purchasing Methods
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="behaviorItem5" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingMethodsRadio2"
                            name="purchasingMethodsRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingMethodsRadio2">
                            Frequently
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingMethodsRadio3"
                            name="purchasingMethodsRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingMethodsRadio3">
                            Predictive
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingMethodsRadio4"
                            name="purchasingMethodsRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingMethodsRadio4">
                            speculation
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingMethodsRadio5"
                            name="purchasingMethodsRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingMethodsRadio5">
                            Long-term contract
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingMethodsRadio6"
                            name="purchasingMethodsRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingMethodsRadio6">
                            Bulk Others
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingMethodsRadio1"
                            name="purchasingMethodsRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="purchasingMethodsRadio1">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#behaviorItem6"
                      >
                        Attitude to Product or Service
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="behaviorItem6" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio2"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio2">
                            Enthusiastic
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio3"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio3">
                            Positive
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio4"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio4">
                            Indifferent
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio5"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio5">
                            Negative
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio6"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio6">
                            Hostile competitor
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio1"
                            name="attitudeRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio1">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#behaviorItem7"
                      >
                        Purchasing behavior
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="behaviorItem7" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio1"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio1">
                            Price conscious(the lowest)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio2"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio2">
                            Smart
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio3"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio3">
                            Risk-averse(cautious)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio4"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio4">
                            Risk-averse(return policy)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio5"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio5">
                            Needs-proof(popular)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio6"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio6">
                            Needs-proof(claims of her peers)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio7"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio7">
                            I will get it later
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio8"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio8">
                            Persuadable
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio9"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio9">
                            Meticulous
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio10"
                            name="purchasingRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio10">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#behaviorItem8"
                      >
                        Adopter Status
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="behaviorItem8" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="adopterRadio1"
                            name="adopterRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="adopterRadio1">
                            Early adopter
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="adopterRadio2"
                            name="adopterRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="adopterRadio2">
                            Late adopter
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="adopterRadio3"
                            name="adopterRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="adopterRadio3">
                            Laggard
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="adopterRadio4"
                            name="adopterRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="adopterRadio4">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {/* Contact */}
            {tab.value == 4 && (
              <>
                <div className="customer-profile-items bd bd-t-0 rounded-bottom">
                  <ul className="list-unstyled mg-b-0">
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem1"
                      >
                        Age
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem1" className="collapse pd-10">
                        <div className="d-flex align-items-center">
                          <SelectHook
                            className="wd-150-f mg-r-10"
                            options={[
                              { value: 1, label: 'about' },
                              { value: 2, label: 'exactly' },
                            ]}
                          />
                          <input
                            type="number"
                            className="form-control form-control-lg wd-100"
                            defaultValue="28"
                          />
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem2"
                      >
                        Birthday
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem2" className="collapse pd-10">
                        <DatePicker
                          selected={new Date()}
                          onChange={() => {
                            console.log();
                          }}
                        />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem3"
                      >
                        Gender
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem3" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="genderRadio2"
                            name="genderRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="genderRadio2">
                            Female
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="genderRadio3"
                            name="genderRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="genderRadio3">
                            Male
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="genderRadio1"
                            name="genderRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="genderRadio1">
                            Others
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="genderRadio4"
                            name="genderRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="genderRadio4">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem4"
                      >
                        Occupation
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem4" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio1"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio1">
                            Management
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio2"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio2">
                            Business and financial operations
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio3"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio3">
                            Professional and related
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio4"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio4">
                            Architecture and engineering
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio5"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio5">
                            Life, physical, and social science
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio6"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio6">
                            Community and social service
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio7"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio7">
                            Legal
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio8"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio8">
                            Education, training, and library
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio9"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio9">
                            Arts, design, entertainment, sports, and media
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio10"
                            name="occupationRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio10">
                            Healthcare practitioners and technical
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio11"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio11">
                            Healthcare support
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio12"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio12">
                            Protective service
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio13"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio13">
                            Food preparation and serving related
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio14"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio14">
                            Building and grounds cleaning and maintenance
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio15"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio15">
                            Personal care and service
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio16"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio16">
                            Sales and related
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio17"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio17">
                            Office and administrative support
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio18"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio18">
                            Farming, fishing, and forestry
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio19"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio19">
                            Construction and extraction
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio20"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio20">
                            Installation, maintenance, and repair
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio21"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio21">
                            Production
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio22"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio22">
                            Transportation and material moving
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio23"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio23">
                            Others
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="occupationRadio24"
                            name="occupationRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="occupationRadio24">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem5"
                      >
                        Marital Status
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem5" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="maritalStatusRadio1"
                            name="maritalStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="maritalStatusRadio1">
                            Single
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="maritalStatusRadio2"
                            name="maritalStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="maritalStatusRadio2">
                            Married
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="maritalStatusRadio3"
                            name="maritalStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="maritalStatusRadio3">
                            Divorced
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="maritalStatusRadio4"
                            name="maritalStatusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="maritalStatusRadio4">
                            Widowed
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="maritalStatusRadio5"
                            name="maritalStatusRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="maritalStatusRadio5">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem6"
                      >
                        Family Life-stage
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem6" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio1"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio1">
                            Beginning families (married couple without children)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio2"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio2">
                            Child bearing families
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio3"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio3">
                            Families with preschool children
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio4"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio4">
                            Families with school children (child 6–13)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio5"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio5">
                            Families with teenagers (oldest child 13–20)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio6"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio6">
                            Families as launching centers (first child gone to last child’s leaving
                            home)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio7"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio7">
                            Families in the middle years
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio8"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio8">
                            Aging families (retirement)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio9"
                            name="familyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familyRadio9">
                            Others
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familyRadio10"
                            name="familyRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="familyRadio10">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem7"
                      >
                        Family Size
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem7" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familySizeRadio1"
                            name="familySizeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familySizeRadio1">
                            Single-person households
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familySizeRadio2"
                            name="familySizeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familySizeRadio2">
                            Two-person households
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familySizeRadio3"
                            name="familySizeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familySizeRadio3">
                            Three-person households
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familySizeRadio4"
                            name="familySizeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familySizeRadio4">
                            Four-person households
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familySizeRadio5"
                            name="familySizeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="familySizeRadio5">
                            Others
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="familySizeRadio5"
                            name="familySizeRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="familySizeRadio5">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem8"
                      >
                        Education attainment
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem8" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="eduRadio1"
                            name="eduRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="eduRadio1">
                            Elementary school degree
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="eduRadio2"
                            name="eduRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="eduRadio2">
                            High school degree
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="eduRadio3"
                            name="eduRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="eduRadio3">
                            College degree
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="eduRadio4"
                            name="eduRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="eduRadio4">
                            Bachelors degree
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="eduRadio5"
                            name="eduRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="eduRadio5">
                            Graduate degree
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="eduRadio6"
                            name="eduRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="eduRadio6">
                            Others
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="eduRadio7"
                            name="eduRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="eduRadio7">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem9"
                      >
                        Home Ownership
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem9" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="homeOwnershipRadio1"
                            name="homeOwnershipRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="homeOwnershipRadio1">
                            Renting
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="homeOwnershipRadio2"
                            name="homeOwnershipRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="homeOwnershipRadio2">
                            Won home with mortgage
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="homeOwnershipRadio3"
                            name="homeOwnershipRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="homeOwnershipRadio3">
                            Home owned outright
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="homeOwnershipRadio4"
                            name="homeOwnershipRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="homeOwnershipRadio4">
                            Others
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="homeOwnershipRadio5"
                            name="homeOwnershipRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="homeOwnershipRadio5">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem10"
                      >
                        Nationality
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem10" className="collapse pd-10">
                        <SelectHook />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem11"
                      >
                        Religion
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem11" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio1"
                            name="religionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="religionRadio1">
                            Catholic
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio2"
                            name="religionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="religionRadio2">
                            Protestant
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio3"
                            name="religionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="religionRadio3">
                            Greek Orthodox
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio4"
                            name="religionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="religionRadio4">
                            Muslim
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio5"
                            name="religionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="religionRadio5">
                            Jewish
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio6"
                            name="religionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="religionRadio6">
                            Buddhist
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio7"
                            name="religionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="religionRadio7">
                            Hindu
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio8"
                            name="religionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="religionRadio8">
                            Others
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="religionRadio9"
                            name="religionRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="religionRadio9">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#demoItem12"
                      >
                        Social Circles
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="demoItem12" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {tab.value == 5 && (
              <>
                <div className="customer-profile-items bd bd-t-0 rounded-bottom">
                  <ul className="list-unstyled mg-b-0">
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem1"
                      >
                        Countrie
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem1" className="collapse pd-10">
                        <SelectHook />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem2"
                      >
                        Cities
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem2" className="collapse pd-10">
                        <SelectHook />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem3"
                      >
                        Regions
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem3" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="regionCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="regionCheck4">
                            Centeral Business District
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="regionCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="regionCheck1">
                            Urban
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="regionCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="regionCheck2">
                            Suburban
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="regionCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="regionCheck3">
                            Rural
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="regionCheck5"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="regionCheck5">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem4"
                      >
                        Political
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem4" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="positicalCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="positicalCheck1">
                            Liberal
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="positicalCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="positicalCheck2">
                            Moderate
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="positicalCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="positicalCheck3">
                            Conservative
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="positicalCheck4"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="positicalCheck4">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem5"
                      >
                        Currency
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem5" className="collapse pd-10">
                        <SelectHook />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem6"
                      >
                        Language
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem6" className="collapse pd-10">
                        <SelectHook />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem7"
                      >
                        Nations
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem7" className="collapse pd-10">
                        <SelectHook />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem8"
                      >
                        Neighborhoods
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem8" className="collapse pd-10">
                        <SelectHook />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem9"
                      >
                        Climate zone
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem9" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="climateCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="climateCheck1">
                            Tropical
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="climateCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="climateCheck2">
                            Dry
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="climateCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="climateCheck3">
                            Temperate
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="climateCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="climateCheck4">
                            Continental
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="climateCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="climateCheck5">
                            Polar
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="climateCheck5"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="climateCheck5">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactGeoItem10"
                      >
                        Population density
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactGeoItem10" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="populationCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="populationCheck1">
                            High
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="populationCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="populationCheck2">
                            Middle
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="populationCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="populationCheck3">
                            Low
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="populationCheck3"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="populationCheck3">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {tab.value == 6 && (
              <>
                <div className="customer-profile-items bd bd-t-0 rounded-bottom">
                  <ul className="list-unstyled mg-b-0">
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem1"
                      >
                        Work
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem1" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem2"
                      >
                        Hobbies
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem2" className="collapse pd-10">
                        <SelectHook />
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem3"
                      >
                        Social events
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem3" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem4"
                      >
                        Vacations
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem4" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem5"
                      >
                        Club memberships
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem5" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem6"
                      >
                        Community
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem6" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem7"
                      >
                        Entertainment choices
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem7" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem8"
                      >
                        Sports
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem8" className="collapse pd-10">
                        <div className="input-group bg-white">
                          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Tennis</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Football</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Cricket</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Hockey</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Basketball</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Baseball</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Rugby</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Golf</span>
                              <button
                                type="button"
                                className="btn pd-0 form-control-tag-remove"
                                aria-label="삭제"
                              >
                                <X />
                              </button>
                            </div>
                            <input
                              type="text"
                              className="bd-0 flex-grow-1 mn-wd-250 rounded"
                              placeholder="Type or click to select"
                            />
                          </div>
                          <div className="input-group-append">
                            <button type="button" className="btn" aria-label="open modal to select">
                              <ChevronDown />
                              <span className="sr-only">선택 옵션 열기</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem9"
                      >
                        Shopping
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem9" className="collapse pd-10">
                        <div className="d-flex">
                          <input type="text" className="form-control" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem10"
                      >
                        Interests
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem10" className="collapse pd-10">
                        <div className="dropdown">
                          <div className="input-group bg-white">
                            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Family</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Home</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Job</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Fashion</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Food</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Travel</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Music</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Fitness</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Reading</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Community</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-name">Recreation</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <input
                                type="text"
                                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                                placeholder="Type or click to select"
                              />
                            </div>
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn"
                                aria-label="open modal to select"
                              >
                                <ChevronDown />
                                <span className="sr-only">선택 옵션 열기</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem11"
                      >
                        Openness
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem11" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opennessCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opennessCheck1">
                            Appreciation for a variety of experience
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opennessCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opennessCheck2">
                            Untradisional
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opennessCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opennessCheck3">
                            Open to feelings
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opennessCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opennessCheck4">
                            Open to new and different ideas
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opennessCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opennessCheck5">
                            Open to various values and beliefs
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem12"
                      >
                        Conscientiousness
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem12" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="conscientiousnessCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="conscientiousnessCheck1">
                            Planning ahead rather than being spentaneous
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="conscientiousnessCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="conscientiousnessCheck2">
                            Be careful
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="conscientiousnessCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="conscientiousnessCheck3">
                            On-time appointments
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="conscientiousnessCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="conscientiousnessCheck4">
                            Follow rules
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="conscientiousnessCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="conscientiousnessCheck5">
                            Hardworking
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="conscientiousnessCheck6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="conscientiousnessCheck6">
                            Orderly
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="conscientiousnessCheck7"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="conscientiousnessCheck7">
                            Dutiful
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="conscientiousnessCheck8"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="conscientiousnessCheck8">
                            Self-disciplined
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem13"
                      >
                        Extraversion
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem13" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="extraversionCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="extraversionCheck1">
                            Being sociable
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="extraversionCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="extraversionCheck2">
                            Energetic
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="extraversionCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="extraversionCheck3">
                            Talkative
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="extraversionCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="extraversionCheck4">
                            Warm
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="extraversionCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="extraversionCheck5">
                            Active
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="extraversionCheck6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="extraversionCheck6">
                            Excitement-seeking
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="extraversionCheck7"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="extraversionCheck7">
                            Assertive
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem14"
                      >
                        Agreeableness
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem14" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck1">
                            Being kind
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck2">
                            Sympathetic
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck3">
                            Happy to help
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck4">
                            Agree and go along with others
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck5">
                            Forgiving
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck6">
                            Trusting
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck7"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck7">
                            Straightforward
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck8"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck8">
                            Modest
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="agreeablenessCheck9"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="agreeablenessCheck9">
                            Tenderminded
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem15"
                      >
                        Neuroticism
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem15" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="neuroticismCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="neuroticismCheck1">
                            Inclined to worry
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="neuroticismCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="neuroticismCheck2">
                            Be vulnerable
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="neuroticismCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="neuroticismCheck3">
                            Temperamental
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="neuroticismCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="neuroticismCheck4">
                            Negative emotions such as anger worry sadness
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="neuroticismCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="neuroticismCheck5">
                            Depressed
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="neuroticismCheck6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="neuroticismCheck6">
                            Selfconsciousness
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="neuroticismCheck7"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="neuroticismCheck7">
                            Vulnerable
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactPsychoItem16"
                      >
                        Opinions
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactPsychoItem16" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck1">
                            Themselves
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck2">
                            Social issues
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck3">
                            Politics(Conservative/Progressive)
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck4">
                            Business
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck5">
                            Education
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck6">
                            Religion
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck7"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck7">
                            Culture
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck8"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck8">
                            Climat change inaction
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck9"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck9">
                            Brand Preferences
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck10"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck10">
                            Price sensitivity
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck11"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck11">
                            Environment-Friendly
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck12"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck12">
                            Information Sources
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck13"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck13">
                            Service Preference
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck14"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck14">
                            Buy based on trends
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck15"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck15">
                            Spontaneous
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck16"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck16">
                            Influenced by peers
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="opinionCheck17"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="opinionCheck17">
                            Relationships
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {tab.value == 7 && (
              <>
                <div className="customer-profile-items bd bd-t-0 rounded-bottom">
                  <ul className="list-unstyled mg-b-0">
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem1"
                      >
                        Loyalty Status
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem1" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="loyaltyRadio1"
                            name="loyaltyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="loyaltyRadio1">
                            Hard-Core Split
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="loyaltyRadio2"
                            name="loyaltyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="loyaltyRadio2">
                            Shifting
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="loyaltyRadio3"
                            name="loyaltyRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="loyaltyRadio3">
                            Switchers
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="loyaltyRadio4"
                            name="loyaltyRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="loyaltyRadio4">
                            Non-loyalty
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem2"
                      >
                        Usage
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem2" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio1"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio1">
                            Heavy
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio2"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio2">
                            Medium
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio3"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio3">
                            Light
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio4"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio4">
                            Non-user
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio5"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio5">
                            Targeted post-purchase
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio6"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio6">
                            Notice when upgraded or phased out
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio7"
                            name="usageRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="usageRadio7">
                            Aiding in retention
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="usageRadio8"
                            name="usageRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="usageRadio8">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem3"
                      >
                        Product reviews/feedback
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem3" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="feedbackRadio1"
                            name="feedbackRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="feedbackRadio1">
                            Many
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="feedbackRadio2"
                            name="feedbackRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="feedbackRadio2">
                            Several
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="feedbackRadio3"
                            name="feedbackRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="feedbackRadio3">
                            Not review
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem4"
                      >
                        Benefit-Sought
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem4" className="collapse pd-10">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck1"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck1">
                            Product look/style
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck2"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck2">
                            Quality product
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck3"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck3">
                            Innovative product
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck4"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck4">
                            Variety/choice
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck5"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck5">
                            Country of origin of product
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck6"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck6">
                            Environmentally friendly
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck7"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck7">
                            Trustworthy brand
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck8"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck8">
                            A familiar brand
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck9"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck9">
                            Reliability
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck10"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck10">
                            Good store layout
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck11"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck11">
                            Good service
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck12"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck12">
                            Easy online shopping
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck13"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck13">
                            Convenience
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck14"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck14">
                            Low prices
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck14"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck14">
                            Good-value prices
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="benefitCheck14"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="benefitCheck14">
                            Good online reviews
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem5"
                      >
                        User Status
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem5" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="statusRadio1"
                            name="statusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="statusRadio1">
                            Prospects
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="statusRadio2"
                            name="statusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="statusRadio2">
                            First-time user
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="statusRadio3"
                            name="statusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="statusRadio3">
                            Regular
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="statusRadio4"
                            name="statusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="statusRadio4">
                            Returning
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="statusRadio5"
                            name="statusRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="statusRadio5">
                            Defectors (ex-customers who have switched to a competitor)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="statusRadio6"
                            name="statusRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="statusRadio6">
                            Non-users
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem6"
                      >
                        Adopter Status
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem6" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="adopterRadio1"
                            name="adopterRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="adopterRadio1">
                            Early adopter
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="adopterRadio2"
                            name="adopterRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="adopterRadio2">
                            Late adopter
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="adopterRadio3"
                            name="adopterRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="adopterRadio3">
                            Laggard
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="adopterRadio4"
                            name="adopterRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="adopterRadio4">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem7"
                      >
                        Attitude to Product or Service
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem7" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio1"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio1">
                            Enthusiast
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio2"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio2">
                            Positive
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio3"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio3">
                            Indifferent
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio4"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio4">
                            Negative
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio5"
                            name="attitudeRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio5">
                            Hostile
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="attitudeRadio6"
                            name="attitudeRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="attitudeRadio6">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem8"
                      >
                        Purchasing Behavior
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem8" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio1"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio1">
                            Price conscious(the lowest)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio2"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio2">
                            Smart
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio3"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio3">
                            Risk-averse(cautious)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio4"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio4">
                            Risk-averse(return policy)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio5"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio5">
                            Needs-proof(popular)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio6"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio6">
                            Needs-proof(claims of her peers)
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio7"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio7">
                            I ll get it later
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio8"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio8">
                            Persuadable
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio9"
                            name="purchasingRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio9">
                            Meticulous
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="purchasingRadio10"
                            name="purchasingRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="purchasingRadio10">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem9"
                      >
                        Spending habits
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem9" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio1"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio1">
                            Impulsive
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio2"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio2">
                            Bargain
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio3"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio3">
                            Online
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio4"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio4">
                            Cautious
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio5"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio5">
                            Convenience
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio6"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio6">
                            Without a Plan
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio7"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio7">
                            Without Keeping Track
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio8"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio8">
                            Feel Better
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="spendingHabitRadio9"
                            name="spendingHabitRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="spendingHabitRadio9">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem10"
                      >
                        Brand Interactions
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem10" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="interactionRadio1"
                            name="interactionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="interactionRadio1">
                            Hight
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="interactionRadio2"
                            name="interactionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="interactionRadio2">
                            Middle
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="interactionRadio3"
                            name="interactionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="interactionRadio3">
                            Low
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="interactionRadio4"
                            name="interactionRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="interactionRadio4">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem11"
                      >
                        Interest Based
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem11" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="interestRadio1"
                            name="interestRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="interestRadio1">
                            Cross-sell offers
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="interestRadio2"
                            name="interestRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="interestRadio2">
                            Upsell offers
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="interestRadio3"
                            name="interestRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="interestRadio3">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem12"
                      >
                        Universal Occasions
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem12" className="collapse pd-10">
                        <div className="d-flex mg-b-5">
                          <input type="text" className="form-control" defaultValue="Holidays" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Seasonal events"
                          />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem13"
                      >
                        Recurring-personal occasion
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem13" className="collapse pd-10">
                        <div className="d-flex mg-b-5">
                          <input type="text" className="form-control" defaultValue="Birthdays" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="d-flex mg-b-5">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Anniversaries"
                          />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="d-flex mg-b-5">
                          <input type="text" className="form-control" defaultValue="Vacation" />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Morning coffee"
                          />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="bd-b">
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem14"
                      >
                        Rare-personal occasion
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem14" className="collapse pd-10">
                        <div className="d-flex mg-b-5">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Attending a friend's wedding"
                          />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button
                        className="btn d-flex align-items-center wd-100p pd-10 bg-light rounded-0 collapsed"
                        data-toggle="collapse"
                        data-target="#contactBehaviorItem15"
                      >
                        Customer Satisfaction
                        <ChevronDown className="mg-l-auto tx-color-03" />
                        <span className="sr-only">내용 접기/펴기</span>
                      </button>
                      <div id="contactBehaviorItem15" className="collapse pd-10">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="satisfactionRadio1"
                            name="satisfactionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="satisfactionRadio1">
                            Up-sell
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="satisfactionRadio2"
                            name="satisfactionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="satisfactionRadio2">
                            Cross-sell
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="satisfactionRadio3"
                            name="satisfactionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="satisfactionRadio3">
                            Opportunity
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="satisfactionRadio4"
                            name="satisfactionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="satisfactionRadio4">
                            Loyalty program
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="satisfactionRadio5"
                            name="satisfactionRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="satisfactionRadio5">
                            Retention campaign
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="satisfactionRadio6"
                            name="satisfactionRadio"
                            className="custom-control-input"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="satisfactionRadio6">
                            None
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

            <div className="card profile-added-cate mg-t-15">
              <div className="card-header bd-b-0-f pd-b-0 tx-semibold">Added Categories</div>
              <div className="card-body">
                {/* Account */}
                <div className="form-group">
                  <label className="form-item-title d-block">Firmographic</label>
                  <div className="form-control-tag">
                    Industry
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                  <div className="form-control-tag">
                    Subsidiary
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                  <div className="form-control-tag">
                    Company Size
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                  <div className="form-control-tag">
                    Annual Revenue
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                  <div className="form-control-tag">
                    Age(years in business)
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title d-block">Geographic</label>
                  <div className="form-control-tag">
                    Related Account
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                  <div className="form-control-tag">
                    Purchasing Process
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title d-block">Behavior</label>
                  <div className="form-control-tag">
                    Response to Marketing
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                  <div className="form-control-tag">
                    Usage
                    <button type="button" className="btn pd-0 form-control-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </div>

                {/* Contact */}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default newCustomerProfile;
