import { X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import AssignmentType from './containers/assignment-type';

const EditProcess = () => {
  const triggerOptions = [
    { value: 1, label: 'Record Created' },
    { value: 2, label: 'Invoked by Other Process' },
  ];

  return (
    <div
      id="editProcess"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Edit Process</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="d-block form-item-title">Module</label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Process Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Description</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Product</label>
              <div className="input-group" data-toggle="dropdown">
                <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                  <div className="form-control-tag">
                    <span className="form-control-tag-name">Product AAA</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      aria-label="삭제"
                    >
                      <X />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 mn-wd-250 rounded"
                    placeholder="Type or click to select a product"
                  />
                </div>
                <div className="input-group-append">
                  <button type="button" className="btn" aria-label="open modal to select a product">
                    {Icon('product')}
                    <span className="sr-only">선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
            <AssignmentType />
            <div className="form-group">
              <label className="form-item-title">Trigger</label>
              <SelectHook options={triggerOptions} defaultValue={triggerOptions[0]} />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProcess;
