import { X } from 'react-feather';
//import { Icons } from 'Crm/services/MenuIcons';
import Icons from '@base/assets/icons/svg-icons';

import { Select as SelectHook } from '@base/components/form';
import { defaultInputRanges } from 'react-date-range';

const BasicInfo = () => {
  return (
    <div className="pd-20">
      <div className="form-group">
        <label className="form-item-title">Persona Name</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <textarea rows={3} cols={10} className="form-control"></textarea>
      </div>
      <div className="form-group">
        <label className="form-item-title">Persona Type</label>
        <SelectHook
          className="wd-200-f"
          options={[
            { value: 1, label: 'Fictional' },
            { value: 2, label: 'Real Buyer' },
          ]}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Product</label>
        <div className="input-group">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="d-block avatar avatar-xs mg-r-10">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0xJV22ugqnu5lwu_NoaL1CeW2SZCx4YbuLQ&amp;usqp=CAU"
                  alt=""
                  className="img rounded-circle"
                />
              </span>
              <span className="form-control-tag-name">Product 111</span>
              <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                <X />
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250"
              placeholder="Type or click to select a product"
            />
          </div>
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open modal to select a product">
              {Icons('product')}
              <span className="sr-only">상품 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
