const Attendees = () => {
  return (
    <>
      <div className="table-responsive bg-white bd rounded-top">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f wd-100">
                Registered on
              </th>
              <th scope="col" className="bd-t-0-f">
                Contact
              </th>
              <th scope="col" className="bd-t-0-f">
                Company
              </th>
              <th scope="col" className="bd-t-0-f">
                Email
              </th>
              <th scope="col" className="bd-t-0-f">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>
                <button
                  type="button"
                  className="btn btn-link pd-0"
                  data-toggle="modal"
                  data-target="#attendeeDetail"
                >
                  <span className="d-inline-block avatar avatar-sm mg-r-5">
                    <img
                      src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                      className="rounded-circle"
                      alt=""
                    />
                  </span>
                  Allan Rey Palban
                </button>
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Attendees;
