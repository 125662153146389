import cn from 'classnames';
import { HelpCircle } from 'react-feather';

const HelpButton = (props: any) => {
  const { className, title, placement } = props;
  return (
    <button
      type="button"
      className={cn('btn btn-link btn-icon pd-y-0 link-03 lh-0', className)}
      data-toggle="tooltip"
      data-placement={placement}
      data-han-tooltip={title}
      {...props}
    >
      <HelpCircle />
    </button>
  );
};
export default HelpButton;
