import { useState } from 'react';
import { Database, FileText, User, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

import TicketKnowledgeBase from './knowledgeBase';

const Reply = () => {
  const [replyEmail, setReplyEmail] = useState<boolean>(true);
  const [replySMS, setReplySMS] = useState<boolean>();
  const [replyCall, setReplyCall] = useState<boolean>();
  const [replyFax, setReplyFax] = useState<boolean>();
  const [activeReply, setActiveReply] = useState({
    email: true,
    sms: false,
    call: false,
    fax: false,
  });

  return (
    <>
      <div id="replyModal" className="modal fade right">
        <div className="modal-dialog full-modal">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="h6 modal-title">Reply</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-item-title d-block">Type of Reply</label>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="replyEmail"
                    checked={replyEmail}
                    onChange={() => setReplyEmail(!replyEmail)}
                  />
                  <label className="custom-control-label" htmlFor="replyEmail">
                    Email
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="replySMS"
                    onChange={() => setReplySMS(!replySMS)}
                  />
                  <label className="custom-control-label" htmlFor="replySMS">
                    SMS
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="replyCall"
                    onChange={() => setReplyCall(!replyCall)}
                  />
                  <label className="custom-control-label" htmlFor="replyCall">
                    Call
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="replyFax"
                    onChange={() => setReplyFax(!replyFax)}
                  />
                  <label className="custom-control-label" htmlFor="replyFax">
                    Fax
                  </label>
                </div>
              </div>
              <nav className="nav nav-tabs">
                {replyEmail && (
                  <button
                    type="button"
                    className={`nav-link ${activeReply.email && 'active'}`}
                    onClick={() =>
                      setActiveReply({ email: true, sms: false, call: false, fax: false })
                    }
                  >
                    Email
                  </button>
                )}
                {replySMS && (
                  <button
                    type="button"
                    className={`nav-link ${activeReply.sms && 'active'}`}
                    onClick={() =>
                      setActiveReply({ email: false, sms: true, call: false, fax: false })
                    }
                  >
                    SMS
                  </button>
                )}
                {replyCall && (
                  <button
                    type="button"
                    className={`nav-link ${activeReply.call && 'active'}`}
                    onClick={() =>
                      setActiveReply({ email: false, sms: false, call: true, fax: false })
                    }
                  >
                    Call
                  </button>
                )}
                {replyFax && (
                  <button
                    type="button"
                    className={`nav-link ${activeReply.fax && 'active'}`}
                    onClick={() =>
                      setActiveReply({ email: false, sms: false, call: false, fax: true })
                    }
                  >
                    Fax
                  </button>
                )}
              </nav>
              <div className="pd-20 bd bd-t-0 rounded">

                <div className="form-group d-flex align-items-center">
                  <label className="form-item-title wd-150">Subject</label>
                  <div className="flex-fill">
                    <input type="text" className="form-control" />
                  </div>
                </div>

                <div className="form-group d-flex align-items-center">
                  <label className="form-item-title wd-150">From</label>
                  <div className="flex-fill">
                    <input type="text" className="form-control" />
                  </div>
                </div>

                <div className="form-group d-flex align-items-center">
                  <label className="form-item-title wd-150">To</label>
                  <div className="dropdown flex-fill">
                    <div className="input-group">
                      <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                        {/* <span className="form-control-tag">
                          <span className="form-control-tag-name">
                            Employee 1 &lt;sales@mail.com&gt;
                          </span>
                          <button
                            type="button"
                            className="btn pd-0 form-control-tag-remove"
                            aria-label="삭제"
                          >
                            <X />
                          </button>
                        </span> */}
                        <input
                          type="text"
                          className="wd-200 bd-0 rounded"
                          placeholder="Type or click to select an user"
                        />
                      </div>
                      <span className="input-group-append">
                        <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                          <User />
                        </button>
                      </span>
                    </div>
                    <div className="dropdown-menu wd-100p"></div>
                  </div>
                </div>

                {activeReply.email && (
                  <div className="form-group d-flex align-items-center">
                    <label className="form-item-title wd-150">CC</label>
                    <div className="dropdown flex-fill">
                      <div className="input-group">
                        <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                          <span className="form-control-tag">
                            <span className="form-control-tag-name">
                              Employee 1 &lt;sales@mail.com&gt;
                            </span>
                            <button
                              type="button"
                              className="btn pd-0 form-control-tag-remove"
                              aria-label="삭제"
                            >
                              <X />
                            </button>
                          </span>
                          <input
                            type="text"
                            className="wd-200 bd-0 rounded"
                            placeholder="Type or click to select an user"
                          />
                        </div>
                        <span className="input-group-append">
                          <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                            <User />
                          </button>
                        </span>
                      </div>
                      <div className="dropdown-menu wd-100p"></div>
                    </div>
                  </div>
                )}

                {!activeReply.fax && (
                  <div className="form-group d-flex align-items-center">
                    <label className="form-item-title wd-150">Message</label>
                    <div className="flex-fill">
                      {!activeReply.call &&
                        <div className="d-flex align-items-center mg-b-10">
                          <button
                            type="button"
                            className="btn btn-outline-secondary mg-r-5"
                            data-toggle="modal"
                            data-target="#deskReplyKnowledgeBaseModal"
                          >
                            <Database className="mg-r-5" />
                            Knowledge Base
                          </button>
                          <button type="button" className="btn btn-outline-secondary mg-r-5">
                            <FileText className="mg-r-5" />
                            Canned Responses
                          </button>
                          <SelectHook
                            className="wd-300-f"
                            options={[{ value: 0, label: 'Select a template' }]}
                          />
                          <button type="button" className="btn btn-link">
                            Preview
                          </button>
                        </div>
                      }
                      <div>Editor area</div>
                    </div>
                  </div>
                )}
              
                {(activeReply.email || activeReply.fax) && (
                  <div className="form-group d-flex align-items-center">
                    <label className="form-item-title wd-150">
                      {activeReply.fax ? 'Image' : 'Attached'} File
                    </label>
                    <div className="flex-fill"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-light mg-r-5" data-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <TicketKnowledgeBase />
    </>
  );
};

export default Reply;
