import { X } from 'react-feather';

const ViewDiscountDetail = () => {
  return (
    <div
      id="discountDetailModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Discount Detail</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-0-f">
            <div className="table-responsive">
              <table className="table mg-b-0">
                <thead>
                  <tr className="bg-light">
                    <th scope="col" className="bd-t-0-f">
                      Quantity
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Discount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1-100</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>101-200</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDiscountDetail;
