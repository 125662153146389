import { Input } from '@base/components/form';
import { CustomerAutoComplete } from '@customer/customer/config/write-field/components';
import { Search, X } from 'react-feather';

const CustomerList = () => {
  return (
    <div id="customerListModal" className="modal fade right">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="h6 modal-title">Customers</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <ul className="list-group">
              <li className="list-group-item pd-0">
                <div className="input-group">
                  <Input type="text" className="bd-0" />
                  <div className="input-group-append">
                    <span className="input-group-text bd-0">
                      <Search />
                    </span>
                  </div>
                </div>
              </li>
              <li className="list-group-item d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bg-white">
                <div className="media align-items-center">
                  <span className="avatar avatar-sm">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;amp;usqp=CAU&amp;size=l"
                      alt="Adventure Works"
                      className="rounded-circle"
                    />
                  </span>
                  <div className="media-body mg-l-10">Adventure Works</div>
                </div>
                <button type="button" className="btn mg-l-auto pd-0" data-han-tooltip="Delete">
                  <X className="tx-danger" />
                </button>
              </li>
              <li className="list-group-item d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bg-white">
                <div className="media align-items-center">
                  <span className="avatar avatar-sm">
                    <img
                      src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                      alt="Allan Rey Palban"
                      className="rounded-circle"
                    />
                  </span>
                  <div className="media-body mg-l-10">Allan Rey Palban</div>
                </div>
                <button type="button" className="btn mg-l-auto pd-0" data-han-tooltip="Delete">
                  <X className="tx-danger" />
                </button>
              </li>
            </ul>
            <CustomerAutoComplete
              single
              showAvatar
              className="wd-100p mg-t-5"
              value={[]}
              onChange={() => null}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
