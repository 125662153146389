import { useState } from 'react';

import { Download, X } from 'react-feather';

const NewShortenURL = () => {
  const [urlType, setUrlType] = useState('domain');
  const handleUrlTypeChange = (e: any) => {
    const { value } = e.target;
    setUrlType(value);
  };

  return (
    <div
      id="newShortenUrl"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Shorten URL</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto">
            <div className="form-group">
              <label className="form-item-title d-block">
                URL displayed in Search Engine/External Site
              </label>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="urlTypeRadio1"
                  name="urlTypeRadio"
                  className="custom-control-input"
                  value="domain"
                  checked={urlType === 'domain'}
                  onChange={(e) => handleUrlTypeChange(e)}
                />
                <label className="custom-control-label" htmlFor="urlTypeRadio1">
                  yourdomain/file
                </label>
              </div>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="urlTypeRadio2"
                  name="urlTypeRadio"
                  className="custom-control-input"
                  value="voranet"
                  checked={urlType === 'voranet'}
                  onChange={(e) => handleUrlTypeChange(e)}
                />
                <label className="custom-control-label" htmlFor="urlTypeRadio2">
                  vora.net/
                </label>
              </div>
            </div>
            <ol className="pd-l-20">
              {urlType === 'domain' && (
                <>
                  <li className="mg-b-10">
                    <div className="d-flex align-items-center text-nowrap">
                      Enter your domain
                      <input type="url" className="form-control mg-l-10" />
                    </div>
                  </li>
                  <li className="mg-b-10">
                    <div className="d-flex align-items-center text-nowrap">
                      Enter real URL
                      <input type="url" className="form-control mg-l-10" />
                    </div>
                  </li>
                  <li className="mg-b-10">
                    Redirection file download
                    <button type="button" className="btn btn-sm btn-white mg-l-10">
                      <Download className="mg-r-5" />
                      Download
                    </button>
                  </li>
                  <li className="mg-b-10">
                    Upload the downloaded file to your home directory by FTP
                  </li>
                </>
              )}
              {urlType === 'voranet' && (
                <li className="mg-b-10">
                  <div className="d-flex align-items-center text-nowrap">
                    Enter website URL
                    <input type="url" className="form-control mg-l-10" />
                  </div>
                </li>
              )}
              <li className="mg-b-10">
                <button type="button" className="btn btn-link pd-0">
                  Test
                </button>
              </li>
              <li>Use this url() in Search Engine/External Site</li>
            </ol>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewShortenURL;
