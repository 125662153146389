import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  Check,
} from 'react-feather';

import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { COLORS } from '@base/config/constant';

import ConfirmFactors from './modal/confirmFactors';
import NewCampaign from './modal/newCampaign';
import EventQuestion from './modal/newEventQuestion';
import ROI from './view/roi';
import Related from './view/related';
import ReactApexChart from 'react-apexcharts';

const TABS = [
  {
    value: 1,
    label: 'Link Map',
  },
  {
    value: 2,
    label: 'Open Timeline',
  },
  {
    value: 3,
    label: 'Invitee Detail',
  },
  {
    value: 4,
    label: 'Related',
  },
  {
    value: 5,
    label: 'ROI',
  },
  {
    value: 6,
    label: 'Journey Process',
  },
  {
    value: 7,
    label: 'Configuration',
  },
];

const EmailView = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a href="/campaign/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Campaign
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">Referral Reward Campaign 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newCampaignModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>
        </div>

        <div className="row no-gutters flex-nowrap flex-grow-1 overflow-auto">
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Insight</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#insightSection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="insightSection" className="view-aside-section-body collapse fade show">
                  <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                    <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">Sent</div>
                    <div className="mg-l-auto  tx-15 tx-semibold">2021-05-30</div>
                  </div>
                  <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                    <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">
                      Recipients
                    </div>
                    <div className="mg-l-auto  tx-15 tx-semibold">150</div>
                  </div>
                  <div className="d-flex pd-10 bd bd-x-0 bd-t-0 bd-dashed">
                    <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                      Total sent
                    </div>
                    <div className="mg-l-auto  tx-15 tx-semibold">149</div>
                  </div>
                  <div className="mg-y-20">
                    <ReactApexChart
                      type="donut"
                      height={150}
                      series={[60, 1, 150]}
                      options={{
                        theme: {
                          mode: 'light',
                        },
                        labels: ['Opened', 'Bounced', 'Unopened'],
                        colors: [
                          'rgba(0,227,150, .8)',
                          'rgba(220,53,69, .8)',
                          'rgba(221,221,221, 1)',
                        ],
                        plotOptions: {
                          pie: {
                            donut: {
                              size: '88%',
                              labels: {
                                show: true,
                                value: {
                                  fontSize: '1.25rem',
                                  fontWeight: 600,
                                  offsetY: -1,
                                },
                                total: {
                                  show: true,
                                  fontSize: '0.875rem',
                                  label: 'Total',
                                },
                              },
                            },
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center mg-b-5">
                    Opened
                    <span className="flex-grow-1 mg-x-10 text-right" style={{ color: '#53b3b8' }}>
                      60
                    </span>
                    <div className="progress wd-100">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated wd-40p"
                        role="progressbar"
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ backgroundColor: '#53b3b8' }}
                      >
                        40%
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mg-b-5">
                    Send to Invitee
                    <span className="flex-grow-1 mg-x-10 text-right tx-purple">15</span>
                    <div className="progress wd-100">
                      <div
                        className="progress-bar progress-bar-striped bg-purple progress-bar-animated wd-10p"
                        role="progressbar"
                        aria-valuenow={10}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                      <span className="lh-1">10%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    Invitee : Submission
                    <span className="flex-grow-1 mg-x-10 text-right tx-primary">7</span>
                    <div className="progress wd-100">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated wd-5p"
                        role="progressbar"
                        aria-valuenow={5}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                      <span className="lh-1">5%</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">KPI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="d-flex align-items-center justify-content-between mg-b-5">
                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                      Click through rate
                    </span>
                    <span className="tx-12 tx-color-04">83% goal reached</span>
                  </div>
                  <div className="d-flex justify-content-between mg-b-5">
                    <span className="h5 tx-semibold mg-b-0">50%</span>
                    <span className="tx-rubik tx-color-03 mg-b-0">60%</span>
                  </div>
                  <div className="progress ht-4 mg-b-0 op-5">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={60}
                      style={{ width: '83%' }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">ROI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="h3 mg-b-15 tx-center tx-primary">413%</div>
                  <div className="d-flex">
                    <div className="pd-x-15">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Recipients
                      </div>
                      <div>100</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Sales
                      </div>
                      <div>20</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Gross Profit
                      </div>
                      <div>＄500.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="card ht-200"></div>
                  <div className="card mg-t-20">
                    <div className="table-responsive">
                      <table className="table table-bordered mg-b-0 bd-0 basic-table">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Channel
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Referrer Open Referral Page
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Referrer Send to Invitee
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Invitee Submission
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Conversion Process
                            </th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <td>Dropped</td>
                            <td>
                              <div className="d-flex justify-content-around">
                                <span>30%</span>
                                <span>300</span>
                              </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr>
                            <td>Email(1,000)</td>
                            <td>
                              <div className="d-flex justify-content-around">
                                <span>70%</span>
                                <span>700</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-around">
                                <span>50%</span>
                                <span>350</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-around">
                                <span>0%</span>
                                <span>100</span>
                              </div>
                            </td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>SMS(1,000)</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer pd-y-6-f pagination">
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to first"
                      >
                        {Icon('move_first')}
                        <span className="sr-only">처음으로</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="previous"
                      >
                        <ChevronLeft />
                        <span className="sr-only">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="next"
                      >
                        <ChevronRight />
                        <span className="sr-only">다음</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to last"
                      >
                        {Icon('move_last')}
                        <span className="sr-only">마지막으로</span>
                      </button>
                      <input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}

              {/* Open Timeline */}
              {tab.value == 2 && (
                <div className="pd-20">
                  <div className="ht-300"></div>
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Timestamp</span>
                    </div>
                    <div className="card">
                      <div className="table-responsive rounded">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Timestamp
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Recipients
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer pd-y-6-f pagination">
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to first"
                        >
                          {Icon('move_first')}
                          <span className="sr-only">처음으로</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="previous"
                        >
                          <ChevronLeft />
                          <span className="sr-only">이전</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="next"
                        >
                          <ChevronRight />
                          <span className="sr-only">다음</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link link-02 btn-icon"
                          aria-label="move to last"
                        >
                          {Icon('move_last')}
                          <span className="sr-only">마지막으로</span>
                        </button>
                        <input type="number" className="paging-input" defaultValue="1" />/ 1
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Invitee Detail */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="mg-b-10 text-right">
                    <Check className="tx-success" size={16} />: View
                  </div>
                  <div className="card">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Invitee
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Send to Invitee
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Invitee Submission
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Sales
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="dropdown dropright">
                              <button
                                type="button"
                                className="btn btn-link pd-0"
                                data-toggle="dropdown"
                              >
                                <span className="d-inline-block avatar avatar-sm mg-r-5">
                                  <img
                                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                                    className="rounded-circle"
                                    alt=""
                                  />
                                </span>
                                Allan Rey Palban
                              </button>
                              <div className="dropdown-menu pd-0">
                                <div className="dropdown-menu-header pd-10 bg-light bd-b tx-semibold">
                                  Allan Rey Palban
                                </div>
                                <div className="pd-20">
                                  <div className="timeline-group dropdown-timeline">
                                    <div className="timeline-label">2021/12/03</div>
                                    <div className="timeline-item">
                                      <div className="timeline-time">10:30 am</div>
                                      <div className="timeline-body">
                                        <p>CTA 1 clicked</p>
                                      </div>
                                    </div>
                                    <div className="timeline-item">
                                      <div className="timeline-time">10:31 am</div>
                                      <div className="timeline-body">
                                        <p>Page 1 viewed</p>
                                      </div>
                                    </div>
                                    <div className="timeline-item">
                                      <div className="timeline-time">10:35 am</div>
                                      <div className="timeline-body">
                                        <p>CTA 2 clicked</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>Email or Mobile</td>
                          <td>
                            <Check className="tx-success" size={16} />
                          </td>
                          <td>＄200</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="card-footer pd-y-6-f pagination">
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to first"
                      >
                        {Icon('move_first')}
                        <span className="sr-only">처음으로</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="previous"
                      >
                        <ChevronLeft />
                        <span className="sr-only">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="next"
                      >
                        <ChevronRight />
                        <span className="sr-only">다음</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to last"
                      >
                        {Icon('move_last')}
                        <span className="sr-only">마지막으로</span>
                      </button>
                      <input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}

              {/* Related */}
              {tab.value == 4 && (
                <div className="pd-20">
                  <Related />
                </div>
              )}

              {/* ROI */}
              {tab.value == 5 && (
                <div className="pd-20">
                  <ROI />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmFactors />
      <NewCampaign />
      <EventQuestion />
    </>
  );
};

export default EmailView;
