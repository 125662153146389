import { X } from 'react-feather';

const Test = () => {
  return (
    <div id="testModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Test</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Email Address</label>
              <input type="email" className="form-control" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" className="btn btn-success">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;
