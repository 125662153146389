import Icon from '@base/assets/icons/svg-icons';
import { ChevronDown, ChevronLeft, ChevronRight } from 'react-feather';

const RelatedCustomer = () => {
  return (
    <div className="pd-15">
      {/* Customers */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Customers</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#customerList"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="customerList" className="card collapse show">
          <div className="table-responsive">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Category
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Customer Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Customer Group
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Rating
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Ordered Items
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Trade Agreement
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Recent Transaction
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="wd-16 ht-16 mg-r-5 tx-color-03">{Icon('m_customer')}</span>
                    Contact
                  </td>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                      Woman Peacoat
                    </a>
                  </td>
                  <td>Woman Peacoat</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <span className="wd-16 ht-16 mg-r-5 tx-color-03">
                      {Icon('m_customer_account')}
                    </span>
                    Account
                  </td>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                      Woman Peacoat
                    </a>
                  </td>
                  <td>Woman Peacoat</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      {/* Potentials */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Potentials</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#potentialsList"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="potentialsList" className="card collapse show">
          <div className="table-responsive">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Category
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Customer Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Customer Group
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Probability
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Stage
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="wd-16 ht-16 mg-r-5 tx-color-03">{Icon('m_customer')}</span>
                    Contact
                  </td>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                      Woman Peacoat
                    </a>
                  </td>
                  <td>Woman Peacoat</td>
                  <td>
                    <div className="progress">
                      <div
                        className="progress-bar bg-success progress-bar-striped progress-bar-animated wd-75p"
                        role="progressbar"
                        aria-valuenow={75}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        75%
                      </div>
                    </div>
                  </td>
                  <td>Estimateing Price Quote</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedCustomer;
