import { useState } from 'react';

import { Button, Input, Select as SelectHook } from '@base/components/form';
import { Clock } from 'react-feather';
// import { icons } from "antd/lib/image/PreviewGroup";

const ActivityReferralReward = (props: any) => {
  const [sendingActivity, setSendingActiviry] = useState<any>('email');
  const handleSendingActiviryChange = (e: any) => {
    const { value } = e.target;
    setSendingActiviry(value);
  };
  const [smsSchedule, setSmsSchedule] = useState<any>('now');
  const handleSmsScheduleChange = (e: any) => {
    const { value } = e.target;
    setSmsSchedule(value);
  };

  return (
    <div className="overflow-auto pd-15">
      {props.value == 1 && (
        <>
          <div className="form-group">
            <label className="form-item-title d-block">Sending Activity</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="sendingActivityRadio1"
                name="sendingActivityRadio"
                className="custom-control-input"
                value="email"
                checked={sendingActivity === 'email'}
                onChange={(e) => handleSendingActiviryChange(e)}
              />
              <label className="custom-control-label" htmlFor="sendingActivityRadio1">
                Email
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                id="sendingActivityRadio2"
                name="sendingActivityRadio"
                className="custom-control-input"
                value="sms"
                checked={sendingActivity === 'sms'}
                onChange={(e) => handleSendingActiviryChange(e)}
              />
              <label className="custom-control-label" htmlFor="sendingActivityRadio2">
                SMS
              </label>
            </div>
          </div>
          {sendingActivity === 'sms' && (
            <div className="form-group">
              <label className="form-item-title d-block">SMS Type</label>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="smsTypeRadio1"
                  name="smsTypeRadio"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="smsTypeRadio1">
                  SMS
                </label>
              </div>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="smsTypeRadio2"
                  name="smsTypeRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="smsTypeRadio2">
                  LMS
                </label>
              </div>
              <div className="custom-control custom-radio d-inline-block">
                <input
                  type="radio"
                  id="smsTypeRadio3"
                  name="smsTypeRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="smsTypeRadio3">
                  MMS
                </label>
              </div>
            </div>
          )}
          <div className="form-group">
            <label className="form-item-title d-block">Send Schedule</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="smsScheduleRadio1"
                name="smsScheduleRadio"
                className="custom-control-input"
                value="now"
                checked={smsSchedule === 'now'}
                onChange={(e) => handleSmsScheduleChange(e)}
              />
              <label className="custom-control-label" htmlFor="smsScheduleRadio1">
                Send Now
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                id="smsScheduleRadio2"
                name="smsScheduleRadio"
                className="custom-control-input"
                value="schedule"
                checked={smsSchedule === 'schedule'}
                onChange={(e) => handleSmsScheduleChange(e)}
              />
              <label className="custom-control-label" htmlFor="smsScheduleRadio2">
                Schedule
              </label>
            </div>
          </div>
          {smsSchedule === 'schedule' && (
            <>
              <div className="form-group">
                <label className="form-item-title">Date</label>
                <div className="input-group wd-300">
                  <input type="text" className="form-control" />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Clock />
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="form-group">
            <label className="form-item-title d-block">Invitee Action</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="inviteeActionRadio1"
                name="inviteeActionRadio"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="inviteeActionRadio1">
                Sign up
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="inviteeActionRadio2"
                name="inviteeActionRadio"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="inviteeActionRadio2">
                Purchased
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                id="inviteeActionRadio3"
                name="inviteeActionRadio"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="inviteeActionRadio3">
                Sign up → Purchased
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Conversion Process</label>
            <ol className="conversion-process">
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Lead Capture</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Created</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">MQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">SQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Account/Contact Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch2"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch2"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Opportunity</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Opprtunity Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Sales</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Make Payment</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch4"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch4"></label>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Rewarding</label>
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="rewardingSwitch1" />
              <label className="custom-control-label" htmlFor="rewardingSwitch1">
                Referrer Reward
              </label>
            </div>
            <div className="mg-l-40 mg-y-5">
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="rewardingRadio1"
                  name="rewardingRadio"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="rewardingRadio1">
                  Loyalty
                </label>
              </div>
              <button type="button" className="btn btn-link">
                Check
              </button>
            </div>
            <div className="mg-l-40 mg-y-5">
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="rewardingRadio2"
                  name="rewardingRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="rewardingRadio2">
                  Coupon
                </label>
              </div>
              <input type="number" className="form-control d-inline-block wd-300" />
            </div>
            <div className="mg-l-40 mg-y-5">
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="rewardingRadio3"
                  name="rewardingRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="rewardingRadio3">
                  Other
                </label>
              </div>
              <input type="text" className="form-control d-inline-block wd-300" />
            </div>
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="rewardingSwitch2" />
              <label className="custom-control-label" htmlFor="rewardingSwitch2">
                Invitee Reward
              </label>
            </div>
            <div className="mg-l-40 mg-y-5">
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="inviteeRewardRadio1"
                  name="inviteeRewardRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="inviteeRewardRadio1">
                  Coupon
                </label>
              </div>
              <input type="number" className="form-control d-inline-block wd-300" />
            </div>
            <div className="mg-l-40 mg-y-5">
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="inviteeRewardRadio2"
                  name="inviteeRewardRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="inviteeRewardRadio2">
                  Other
                </label>
              </div>
              <input type="text" className="form-control d-inline-block wd-300" />
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Return on Marketing Investment</label>
            <SelectHook
              width={250}
              options={[
                { value: 1, label: 'Gross Profit' },
                { value: 2, label: 'Customer Lifetime Value' },
              ]}
              defaultValue={{ value: 1, label: 'Gross Profit' }}
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Key Performance Indicator</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon mg-l-auto rounded-0"
                icon="Plus"
              />
            </div>
            <div className="d-flex align-items-center pd-10 bd rounded">
              Click through rate <Input type="number" className="wd-100 mg-x-10" />%
            </div>
          </div>
        </>
      )}
      {props.value == 2 && <></>}
    </div>
  );
};

export default ActivityReferralReward;
