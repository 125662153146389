import noImg from '@base/assets/img/notfound.png';
import { Button, Checkbox } from '@base/components/form';

const PR = (props: any) => {
  return (
    <div className="pd-20">
      <div className="text-right">
        <Button color="link" icon="Edit2" iconClass="mg-r-5" name="Edit" />
      </div>
      <div className="table-responsive bg-white bd rounded">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f">
                <Checkbox />
              </th>
              <th scope="col" className="bd-t-0-f">
                Image
              </th>
              <th scope="col" className="bd-t-0-f">
                Item
              </th>
              <th scope="col" className="bd-t-0-f">
                Required Quantity
              </th>
              <th scope="col" className="bd-t-0-f">
                Required Date
              </th>
              <th scope="col" className="bd-t-0-f">
                Estimated Cost
              </th>
              <th scope="col" className="bd-t-0-f">
                Vendor
              </th>
              <th scope="col" className="bd-t-0-f">
                Create
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Checkbox />
              </td>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src={noImg} alt="" />
                </div>
              </td>
              <td>Item AAA</td>
              <td>100</td>
              <td>2021-08-01</td>
              <td>￦1,000,000</td>
              <td>Vendor 1, Vendor 2, Vendor 3</td>
              <td>
                <Button
                  color="primary"
                  name="Add Vendor"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#addVendor"
                />
                <Button
                  color="info"
                  name="RFQ"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newRFQ"
                />
                <Button
                  color="info"
                  name="RFT"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newRFT"
                />
                <Button
                  color="info"
                  name="PO"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newPO"
                  onClick={() => props.setOpenRight(true)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox />
              </td>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src={noImg} alt="" />
                </div>
              </td>
              <td>Item BBB</td>
              <td>100</td>
              <td>2021-08-01</td>
              <td>￦1,000,000</td>
              <td>Vendor 3</td>
              <td>
                <Button
                  color="primary"
                  name="Add Vendor"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#addVendor"
                />
                <Button
                  color="info"
                  name="RFQ"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newRFQ"
                />
                <Button
                  color="info"
                  name="RFT"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newRFT"
                />
                <Button
                  color="info"
                  name="PO"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newPO"
                  onClick={() => props.setOpenRight(true)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox />
              </td>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src={noImg} alt="" />
                </div>
              </td>
              <td>Item CCC</td>
              <td>100</td>
              <td>2021-08-01</td>
              <td>￦1,000,000</td>
              <td>-</td>
              <td>
                <Button
                  color="primary"
                  name="Add Vendor"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#addVendor"
                />
                <Button
                  color="info"
                  name="RFQ"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newRFQ"
                />
                <Button
                  color="info"
                  name="RFT"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newRFT"
                />
                <Button
                  color="info"
                  name="PO"
                  className="mg-r-5"
                  data-toggle="modal"
                  data-target="#newPO"
                  onClick={() => props.setOpenRight(true)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mg-t-10">
        <Button
          color="primary"
          name="Add Vendor"
          className="mg-r-5"
          data-toggle="modal"
          data-target="#addVendor"
        />
        <Button
          color="info"
          name="RFQ"
          className="mg-r-5"
          data-toggle="modal"
          data-target="#newRFQ"
        />
        <Button
          color="info"
          name="RFT"
          className="mg-r-5"
          data-toggle="modal"
          data-target="#newRFT"
        />
        <Button
          color="info"
          name="PO"
          className="mg-r-5"
          data-toggle="modal"
          data-target="#newPO"
          onClick={() => props.setOpenRight(true)}
        />
      </div>
    </div>
  );
};

export default PR;
