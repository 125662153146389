import { useState } from 'react';

import {
  Button,
  Input,
  RadioGroup,
  Select,
  Select as SelectHook,
  Switch,
} from '@base/components/form';
import { ArrowDown, Clock, CornerDownRight } from 'react-feather';
import { UserAutoComplete } from '@base/containers';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';

const ActivityPAD = (props: any) => {
  const METHOD_TYPE = [
    { value: 1, label: 'Door to Door' },
    { value: 2, label: 'Hand to Hand' },
    { value: 3, label: 'Business to business' },
    { value: 4, label: 'Newspaper Insertion' },
  ];
  const [methodType, setMethodType] = useState(METHOD_TYPE);
  const [selectedMethodType, setSelectedMethodType] = useState(METHOD_TYPE[0]);
  //change
  const handleTypeChange = (newOption: any) => {
    setSelectedMethodType(newOption);
  };

  const HOST_OPTION = [
    {
      label: 'Service Provider',
      value: 1,
    },
    {
      label: 'Employee',
      value: 2,
    },
  ];
  const [hostOption, setHostOption] = useState(HOST_OPTION);
  const [selectedHostOption, setSelectedHostOption] = useState(HOST_OPTION[0]);
  //change
  const handleHostOptionChange = (newOption: any) => {
    setSelectedHostOption(newOption);
  };

  const SCHEDULE_OPTION = [
    {
      label: 'Today',
      value: 1,
    },
    {
      label: 'Schedule',
      value: 2,
    },
  ];
  const [scheduleOption, setScheduleOption] = useState(SCHEDULE_OPTION);
  const [selectedScheduleOption, setSelectedScheduleOption] = useState(SCHEDULE_OPTION[0]);
  //change
  const handleHostScheduleChange = (newOption: any) => {
    setSelectedScheduleOption(newOption);
  };

  return (
    <div className="overflow-auto pd-15">
      <div className="row">
        {props.value == 1 && (
          <>
            <div className="form-group col-lg-6">
              <label className="form-item-title d-block">Distribution Methods</label>
              <Select
                width={200}
                value={selectedMethodType}
                options={methodType}
                onChange={handleTypeChange}
              />
            </div>
            {selectedMethodType.value !== 4 && (
              <>
                <div className="form-group col-lg-6">
                  <label className="form-item-title">Distribution Host</label>
                  <Select
                    width={200}
                    value={selectedHostOption}
                    options={hostOption}
                    onChange={handleHostOptionChange}
                  />
                </div>
                {selectedHostOption.value === 1 && (
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Provider Name</label>
                    <Select width={300} />
                  </div>
                )}
                {selectedHostOption.value === 2 && (
                  <div className="form-group col-lg-6">
                    <label className="form-item-title">Assign to</label>
                    <UserAutoComplete showAvatar />
                  </div>
                )}
              </>
            )}
            {selectedMethodType.value === 4 && (
              <div className="form-group col-lg-6">
                <label className="form-item-title">Newspaper Name</label>
                <Input />
              </div>
            )}
            {selectedMethodType.value === 2 && (
              <div className="form-group col-lg-6">
                <label className="form-item-title">Distribution Schedule</label>
                <div className="d-flex align-items-center">
                  <Input type="text" rightIcon={<Clock />} />
                  <span className="mg-x-10">~</span>
                  <Input type="text" rightIcon={<Clock />} />
                </div>
              </div>
            )}
            <div className="form-group col-lg-6">
              <label className="form-item-title">Location</label>
              {/* <Select
                isMulti
                noOptionsMessage={() => 'No locations found.'}
                isClearable
                addLabel="Add new location"
                options={[{ value: 1, label: 'Location 1' }]}
              /> */}
              <div className="dropdown">
                <div className="input-group" data-toggle="dropdown">
                  <Input type="text" placeholder="Type or click to select a location" />
                  <div className="input-group-append">
                    <Button color="" className="input-group-btn" icon="ChevronDown" />
                  </div>
                </div>
                <div className="dropdown-menu wd-100p">
                  <Button
                    color=""
                    className="dropdown-item"
                    icon="Plus"
                    iconClass="mg-r-5"
                    name="Add new location"
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-6">
              <label className="form-item-title">Target Audience</label>
              <CustomerAutoComplete value={''} onChange={console.log()} showAvatar />
            </div>
            {selectedMethodType.value !== 2 && (
              <div className="form-group col-lg-6">
                <label className="form-item-title">Distribution Schedule</label>
                <RadioGroup
                  value={selectedScheduleOption.value}
                  options={scheduleOption}
                  onChange={handleHostScheduleChange}
                />
              </div>
            )}
            {selectedScheduleOption.value === 2 && (
              <div className="form-group col-lg-6">
                <label className="form-item-title">Distribution Date</label>
                <Input type="text" className="wd-200" rightIcon={<Clock />} />
              </div>
            )}
          </>
        )}
      </div>
      {props.value == 2 && (
        <div className="d-flex flex-wrap">
          <div className="card mg-r-10" style={{ width: '180px' }}>
            <div className="pd-x-15 pd-y-10 bd-b text-truncate">Name 1</div>
            <div className="ht-200">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyruOyiPaLr5xtimBZVHk9JvjnxjVdydLyOw&usqp=CAU"
                className="img-fit-cover"
                alt=""
              />
            </div>
            <div className="pd-x-15 pd-y-10 bd-t text-truncate">Numbers: </div>
          </div>
          <div className="card mg-r-10" style={{ width: '180px' }}>
            <div className="pd-x-15 pd-y-10 bd-b text-truncate">Name 2</div>
            <div className="ht-200">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqsWqsbjEmifNN1ZQuzy4fjAMG7w7hExUUDQ&usqp=CAU"
                className="img-fit-cover"
                alt=""
              />
            </div>
            <div className="pd-x-15 pd-y-10 bd-t text-truncate">Numbers: </div>
          </div>
        </div>
      )}
      {props.value == 3 && (
        <>
          <Switch title="Generate QR Code" />
          <div className="d-flex flex-column align-items-center">
            <div className="pd-10 bd-primary-light bg-primary-light rounded tx-primary">
              Generate
            </div>
            <ArrowDown strokeWidth={1} className="mg-y-10 tx-color-03" />
            <div className="pd-10 bd-indigo-light bg-indigo-light rounded tx-indigo">Print</div>
            <ArrowDown strokeWidth={1} className="mg-y-10 tx-color-03" />
            Stick
            <div className="tx-color-03">
              Stick a QR Code on your Print Ads. If there is a original QR Code, stick the new one
              on old one.
            </div>
          </div>
        </>
      )}
      {props.value == 4 && (
        <>
          <div className="form-group">
            <label className="form-item-title">Expenses to include in Marketing Investment</label>
            <div className="table-responsive bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 basic-table">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Expense Name
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Expense
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Return on Marketing Investment</label>
            <SelectHook
              width={250}
              options={[
                { value: 1, label: 'Gross Profit' },
                { value: 2, label: 'Customer Lifetime Value' },
              ]}
              defaultValue={{ value: 1, label: 'Gross Profit' }}
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Key Performance Indicator</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon mg-l-auto rounded-0"
                icon="Plus"
              />
            </div>
            <div className="d-flex align-items-center pd-10 bd rounded">
              Click through rate <Input type="number" className="wd-100 mg-x-10" />%
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Conversion Process</label>
            <ol className="conversion-process">
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">MQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">SQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Account/Contact Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch2"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch2"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Opportunity</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Opprtunity Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Sales</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Make Payment</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch4"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch4"></label>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div className="form-group-title">Links</div>
          <div className="card">
            <div className="card-header">Stick CTAs in Printed Ads</div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-item-title">CTA Name</label>
                <div>CTA 1</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">URL</label>
                <div>&nbsp;</div>
              </div>
              <div className="form-group">
                <label className="form-item-title d-block">Personalized QR Code and URL</label>
                <div className="d-flex">
                  <CornerDownRight strokeWidth={1} />
                  <div className="wd-100p">
                    <div className="card bg-light mg-b-15">
                      <div className="card-header pd-y-10">Link</div>
                      <div className="card-body">
                        <div className="form-group">
                          <label className="form-item-title">Landing Page/Site</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Page Title</label>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ActivityPAD;
