import { useState } from 'react';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';
import { ChevronUp, Clock, User, X } from 'react-feather';
import SelectHook from '@base/components/form/select-hook';

const SmsWrite = () => {
  const [smsDirection, setSmsDirection] = useState('incoming');
  const handleSmsDirectionChange = (e: any) => {
    const { value } = e.target;
    setSmsDirection(value);
  };

  const [smsSchedule, setSmsSchedule] = useState('now');
  const handleSmsScheduleChange = (e: any) => {
    const { value } = e.target;
    setSmsSchedule(value);
  };

  return (
    <>
      <div id="newSMSModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Create SMS</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-item-title d-block">Direction</label>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="smsDirectionRadio1"
                    name="smsDirectionRadio"
                    className="custom-control-input"
                    value="incoming"
                    checked={smsDirection === 'incoming'}
                    onChange={(e) => handleSmsDirectionChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="smsDirectionRadio1">
                    Incoming
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="smsDirectionRadio2"
                    name="smsDirectionRadio"
                    className="custom-control-input"
                    value="outgoing"
                    checked={smsDirection === 'outgoing'}
                    onChange={(e) => handleSmsDirectionChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="smsDirectionRadio2">
                    Outgoing
                  </label>
                </div>
              </div>

              {smsDirection == 'outgoing' && (
                <div className="form-group">
                  <label className="form-item-title d-block">SMS Schedule</label>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="smsScheduleRadio1"
                      name="smsScheduleRadio"
                      className="custom-control-input"
                      value="now"
                      checked={smsSchedule === 'now'}
                      onChange={(e) => handleSmsScheduleChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="smsScheduleRadio1">
                      Send Now
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="smsScheduleRadio2"
                      name="smsScheduleRadio"
                      className="custom-control-input"
                      value="schedule"
                      checked={smsSchedule === 'schedule'}
                      onChange={(e) => handleSmsScheduleChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="smsScheduleRadio2">
                      Schedule
                    </label>
                  </div>
                </div>
              )}

              {(smsDirection === 'incoming' || smsSchedule === 'schedule') && (
                <>
                  <div className="form-group">
                    <label className="form-item-title">Date</label>
                    <div className="input-group wd-300">
                      <input type="text" className="form-control" />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <Clock />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="form-group">
                <label className="form-item-title">Subject</label>
                <input type="text" className="form-control form-control-lg" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Priority</label>
                <SelectHook className="wd-300-f" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Purpose</label>
                <div className="wd-50p">
                  <SelectHook />
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title d-block">SMS/LMS</label>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="smsTypeRadio1"
                    name="smsTypeRadio"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="smsTypeRadio1">
                    SMS - For short messages, up to 80 bytes can be sent (40 Korean characters, 80
                    English characters).
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="smsTypeRadio2"
                    name="smsTypeRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="smsTypeRadio2">
                    LMS - For long messages, up to 2,000 bytes can be sent (1,000 Korean characters,
                    2,000 English characters).
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Template</label>
                <SelectHook />
              </div>
              <div className="form-group">
                <label className="form-item-title">Content</label>
                <div>Editor</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">From</label>
                <div className="dropdown">
                  <div className="input-group" data-toggle="dropdown">
                    {/*  before selecting an user 
										<input type="text" className="form-control" placeholder="Type or click to select an user /> 
									*/}
                    <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="text-truncate">John</span>
                      </div>
                      <button type="button" className="btn" aria-label="delete">
                        <X />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select an user"
                      >
                        <User />
                        <span className="sr-only">담당자 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                  <div className="dropdown-menu wd-100p">
                    <button type="button" className="dropdown-item">
                      Assign to me
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">To</label>
                <div className="input-group">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="d-block avatar avatar-xs mg-r-10">
                        <span className="avatar-initial rounded-circle">S</span>
                      </span>
                      <span className="form-control-tag-name">Socrates</span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250 rounded"
                      placeholder="Type or click to select a receiver"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a customer"
                    >
                      {Icons('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <input type="text" className="form-control form-control-lg" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Cancel
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsWrite;
