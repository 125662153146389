/* eslint-disable prettier/prettier */
import { Button, Input, Switch } from '@base/components/form';
import { useState } from 'react';
import { Plus, Trash } from 'react-feather';

// import "CrmCss/desktop/theme1/theme1.scss";

const Purchase = () => {
  const [showAddTerms, setShowAddTerms] = useState(false);
  const [showAddReason, setShowAddReason] = useState(false);

  return (
    <>
      <div className="card-columns settings-columns">
        {/* Payment Terms */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">
              Payment Terms
              <br />
              <small className="mg-t-5 tx-color-03 tx-normal">
                Invoice/Bill due date is set automatically when selecting the invoice date based on
                the payment trem
              </small>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Payment Term
                  </th>
                  <th scope="col" className="wd-40 bd-t-0-f">
                    Default
                  </th>
                  <th scope="col" className="wd-40 bd-t-0-f">
                    Use
                  </th>
                  {showAddTerms && (
                    <th scope="col" className="wd-80 bd-t-0-f">
                      Delete
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Input type="number" rightIcon="day(s)" value={3} className="wd-200" />
                  </td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="termsDefault1"
                        name="termsDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="termsDefault1"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddTerms && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>
                    <Input type="number" rightIcon="day(s)" value={5} className="wd-200" />
                  </td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="termsDefault2"
                        name="termsDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="termsDefault2"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddTerms && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>
                    <Input type="number" rightIcon="day(s)" value={7} className="wd-200" />
                  </td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="termsDefault3"
                        name="termsDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="termsDefault3"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddTerms && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Due on Receipt</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="termsDefault4"
                        name="termsDefault"
                        className="custom-control-input"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="termsDefault4"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddTerms && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Due End of the Month</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="termsDefault5"
                        name="termsDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="termsDefault5"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddTerms && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Due End of Next Month</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="termsDefault6"
                        name="termsDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="termsDefault6"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddTerms && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Custom</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="termsDefault7"
                        name="termsDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="termsDefault7"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddTerms && <td className="text-center">&nbsp;</td>}
                </tr>
                {showAddTerms && (
                  <tr>
                    <td>
                      <Input type="number" rightIcon="day(s)" className="wd-200" />
                    </td>
                    <td className="text-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="termsDefault8"
                          name="termsDefault"
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="termsDefault8"></label>
                      </div>
                    </td>
                    <td className="text-center">
                      <Switch />
                    </td>
                    <td className="text-center">
                      <Button
                        color="link"
                        icon="X"
                        iconClass="tx-danger"
                        className="btn-icon"
                        onClick={() => setShowAddTerms(false)}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="card-footer">
            <Button
              color="link"
              icon="Plus"
              iconClass="mg-r-5"
              name="Add"
              className="pd-x-0"
              onClick={() => setShowAddTerms(true)}
            />
          </div>
        </div>
        {/* Reason For Purchase  */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Reason For Purchase</div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Reason
                  </th>
                  <th scope="col" className="wd-40 bd-t-0-f">
                    Default
                  </th>
                  <th scope="col" className="wd-40 bd-t-0-f">
                    Use
                  </th>
                  {showAddReason && (
                    <th scope="col" className="wd-80 bd-t-0-f">
                      Delete
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Out of Stock</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="reasonDefault1"
                        name="reasonDefault"
                        className="custom-control-input"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="reasonDefault1"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddReason && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Replacement</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="reasonDefault2"
                        name="reasonDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="reasonDefault2"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddReason && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Out of order</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="reasonDefault3"
                        name="reasonDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="reasonDefault3"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddReason && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Switching Equipmen</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="reasonDefault4"
                        name="reasonDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="reasonDefault4"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddReason && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Purchase office supplies</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="reasonDefault5"
                        name="reasonDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="reasonDefault5"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddReason && <td className="text-center">&nbsp;</td>}
                </tr>
                <tr>
                  <td>Other</td>
                  <td className="text-center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="reasonDefault6"
                        name="reasonDefault"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="reasonDefault6"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <Switch />
                  </td>
                  {showAddReason && <td className="text-center">&nbsp;</td>}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer d-flex align-items-center">
            <Input placeholder="Enter the reason" />
            <Button
              color="primary"
              icon="Plus"
              iconClass="mg-r-5"
              className="flex-shrink-0 mg-l-10"
              name="Add"
            />
          </div>
        </div>
        {/* Valid Until */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">
              Valid Until
              <br />
              <small className="mg-t-5 tx-color-03 tx-normal">
                Set the default valid until based on a rfq date.
              </small>
            </div>
          </div>
          <div className="card-body d-flex align-items-center">
            <Input rightIcon="day(s)" className="wd-200 mg-r-10" />
            after the RFQ Date
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchase;
