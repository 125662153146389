import GrapesTS from '@base/components/grapes-ts';

const Builder = () => {
  const grapesHeight = 'calc(100vh - 70px)';
  return (
    <div className="pd-5">
      <GrapesTS height={grapesHeight} storageId={'demo-gts'} />
    </div>
  );
};

export default Builder;
