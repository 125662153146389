import { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';

import { Button, Input, Select, Select as SelectHook } from '@base/components/form';
import {
  ArrowUp,
  ChevronDown,
  Copy,
  Image,
  List,
  Monitor,
  Move,
  PlayCircle,
  RotateCcw,
  RotateCw,
  Trash,
  Type,
} from 'react-feather';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';

const TABS = [
  {
    value: 1,
    label: 'Properties',
  },
  {
    value: 2,
    label: 'Select a Template',
  },
  {
    value: 3,
    label: 'Design',
  },
];

const DESIGNTABS = [
  {
    value: 1,
    label: 'Designer',
  },
  {
    value: 2,
    label: 'HTML',
  },
  {
    value: 3,
    label: 'Preview',
  },
];

const NewEmailTemp = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [designTab, setDesignTab] = useState(DESIGNTABS[0]);

  return (
    <div id="newEmailTemp" className="modal fade">
      <div className="modal-dialog modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title h6">Creat Direct Mail Template</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body d-flex flex-column overflow-hidden pd-0">
            {/* steps */}
            <ul className="steps steps-justified steps-tab">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  className={classnames('step-item', {
                    complete: item.value < tab.value,
                    active: item.value == tab.value,
                    // disabled: item.value > tab.value
                  })}
                >
                  <button
                    type="button"
                    className="btn step-link rounded-0"
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    <span className="step-number">{item.value}</span>
                    <span className="step-title">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
            {tab.value == 1 && (
              <div className="pd-15">
                <div className="form-group">
                  <label className="form-item-title">Name</label>
                  <Input type="text" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Type</label>
                  <div className="dropdown wd-300">
                    <div className="input-group" data-toggle="dropdown">
                      <Input type="text" />
                      <div className="input-group-append">
                        <Button color="white" icon="ChevronDown" />
                      </div>
                    </div>
                    <div className="dropdown-menu wd-100p">
                      <Button color="" className="dropdown-item" icon="Plus" name="Add new type" />
                      <div className="dropdown-divider"></div>
                      <Button color="" className="dropdown-item" name="General" />
                      <Button color="" className="dropdown-item" name="Survey" />
                      <Button color="" className="dropdown-item" name="Thank you" />
                      <Button color="" className="dropdown-item" name="Follow up" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Language</label>
                  <Select defaultValue={{ value: 1, label: 'Default Language' }} width={300} />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <ProductAutoComplete />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <Input type="text" />
                </div>
              </div>
            )}
            {tab.value == 2 && (
              <div className="row row-xs mg-0 ht-100p">
                <div className="col-lg-6 d-flex flex-column ht-100p">
                  <ul className="nav nav-line bd-1">
                    <li className="nav-item">
                      <button
                        type="button"
                        data-target="#myTemp"
                        className="btn nav-link pd-x-10-f active"
                        data-toggle="tab"
                      >
                        My
                      </button>
                    </li>
                    <li className="nav-item mg-l-0-f">
                      <button
                        type="button"
                        data-target="#tempType"
                        className="btn nav-link pd-x-10-f"
                        data-toggle="tab"
                      >
                        Type
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pd-x-10 overflow-auto">
                    <div id="myTemp" className="tab-pane fade show active">
                      <div className="card-columns temp-columns">
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">Blank</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 1</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 2</div>
                          <button type="button" className="btn btn-select active">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 3</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="tempType" className="tab-pane fade">
                      <SelectHook
                        className="wd-200-f mg-t-10"
                        options={[{ value: 1, label: 'All(20)' }]}
                      />
                      <div className="card-columns temp-columns">
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">AAA</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">BBB</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex flex-column ht-100p">
                  <div className="pd-y-9">Preview</div>
                  <div className="flex-grow-1 overflow-auto mg-b-10 pd-10 bd"></div>
                </div>
              </div>
            )}
            {tab.value == 3 && (
              <div
                className="d-flex flex-column ht-100p pd-15"
                style={{ maxHeight: 'calc(100% - 50px)' }}
              >
                <div className="form-group">
                  <label className="form-item-title">Subject</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="flex-grow-1 bd rounded overflow-auto">
                  <div className="row no-gutters ht-100p overflow-auto">
                    <div
                      className={classnames(
                        'd-flex flex-column ht-100p',
                        { 'col-lg-7 col-xl-8': designTab.value == 1 },
                        { 'col-12': designTab.value != 1 },
                      )}
                    >
                      <div className="d-flex">
                        {/* tabs */}
                        <ul className="nav nav-line bd-1 flex-grow-1">
                          {DESIGNTABS.map((item, index) => (
                            <li className="nav-item" key={index}>
                              <button
                                type="button"
                                className={classnames('btn nav-link pd-x-10-f', {
                                  active: item.value == designTab.value,
                                })}
                                onClick={(e) => {
                                  setDesignTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </li>
                          ))}
                        </ul>
                        {designTab.value == 1 && (
                          <div className="btn-group bd-b">
                            <button
                              type="button"
                              data-han-tooltip="Undo"
                              className="btn btn-icon han-tooltip"
                            >
                              <RotateCcw />
                              <span className="sr-only">실행취소</span>
                            </button>
                            <button
                              type="button"
                              data-han-tooltip="Redo"
                              className="btn btn-icon han-tooltip"
                            >
                              <RotateCw />
                              <span className="sr-only">재실행</span>
                            </button>
                            <button
                              type="button"
                              data-han-tooltip="Clear Canvas"
                              className="btn btn-icon han-tooltip"
                            >
                              <Trash />
                              <span className="sr-only">초기화</span>
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="flex-grow-1 overflow-auto">
                        {designTab.value == 1 && (
                          <>
                            <div
                              className="design-ele design-ele-img selected"
                              style={{ margin: '40px 15px' }}
                            >
                              <div className="design-ele-label">Section 1</div>
                              <div className="design-ele-toolbar">
                                <button
                                  type="button"
                                  data-han-tooltip="Parent"
                                  className="btn btn-sm btn-icon han-tooltip"
                                >
                                  <ArrowUp />
                                  <span className="sr-only">상위</span>
                                </button>
                                <button
                                  type="button"
                                  data-han-tooltip="Move"
                                  className="btn btn-sm btn-icon han-tooltip"
                                >
                                  <Move />
                                  <span className="sr-only">이동</span>
                                </button>
                                <button
                                  type="button"
                                  data-han-tooltip="Copy"
                                  className="btn btn-sm btn-icon han-tooltip"
                                >
                                  <Copy />
                                  <span className="sr-only">복사</span>
                                </button>
                                <button
                                  type="button"
                                  data-han-tooltip="Remove"
                                  className="btn btn-sm btn-icon han-tooltip"
                                >
                                  <Trash />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                              <div className="design-ele-cont-initial tx-gray-400">
                                <Image size={40} strokeWidth={1.5} />
                                <div>Select an image</div>
                              </div>
                            </div>
                          </>
                        )}
                        {designTab.value == 2 && <div></div>}
                        {designTab.value == 3 && (
                          <div className="d-flex ht-100p">
                            <div className="wd-80 flex-shrink-0 design-preview-btns overflow-auto">
                              <button type="button" className="btn wd-100p ht-80 bd-b">
                                <div>Desktop</div>
                                <Monitor />
                              </button>
                              <button type="button" className="btn wd-100p ht-80 bd-b active">
                                <div>Tablet</div>
                                {Icon('tablet')}
                              </button>
                              <button type="button" className="btn wd-100p ht-80">
                                <div>Mobile</div>
                                {Icon('smartphone')}
                              </button>
                            </div>
                            <div className="flex-grow-1 overflow-auto pd-20 bd-l">
                              {/* Tablet */}
                              <div
                                className="shadow"
                                style={{
                                  width: '828px',
                                  height: '1084px',
                                  margin: '0 auto',
                                  padding: '30px',
                                  borderRadius: '15px',
                                  border: '1px solid #cccccc',
                                }}
                              >
                                <div className="ht-100p overflow-auto bd"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {designTab.value == 1 && (
                      <div className="col-lg-5 col-xl-4 d-flex flex-column ht-100p bd-l">
                        <ul className="nav nav-line bd-1">
                          <li className="nav-item">
                            <button
                              type="button"
                              data-target="#designToolbox"
                              className="btn nav-link pd-x-10-f active"
                              data-toggle="tab"
                            >
                              Toolbox
                            </button>
                          </li>
                          <li className="nav-item mg-l-0-f">
                            <button
                              type="button"
                              data-target="#designProperties"
                              className="btn nav-link pd-x-10-f"
                              data-toggle="tab"
                            >
                              Properties
                            </button>
                          </li>
                          <li className="nav-item mg-l-0-f">
                            <button
                              type="button"
                              data-target="#designStyle"
                              className="btn nav-link pd-x-10-f"
                              data-toggle="tab"
                            >
                              Styles
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content flex-grow-1 overflow-auto">
                          <div id="designToolbox" className="tab-pane fade show active">
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designLayout"
                            >
                              Layout
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designLayout" className="collapse fade show bd-b">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column1')}
                                  <div className="ele-name">1 Column</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2')}
                                  <div className="ele-name">2 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column3')}
                                  <div className="ele-name">3 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2_2')}
                                  <div className="ele-name">3:7 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2_3')}
                                  <div className="ele-name">7:3 Columns</div>
                                </button>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designElement"
                            >
                              Elements
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designElement" className="collapse fade show">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('heading')}
                                  <div className="ele-name">Heading</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Type />
                                  <div className="ele-name">Text</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Image />
                                  <div className="ele-name">Image</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <PlayCircle />
                                  <div className="ele-name">Video</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('button')}
                                  <div className="ele-name">Button</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('divider')}
                                  <div className="ele-name">Divider</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('table')}
                                  <div className="ele-name">Table</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <List />
                                  <div className="ele-name">List</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('cta')}
                                  <div className="ele-name">CTA</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('personalize_code')}
                                  <div className="ele-name">Personalize</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('form')}
                                  <div className="ele-name">Form</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('event')}
                                  <div className="ele-name">Event</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('survey')}
                                  <div className="ele-name">Survey</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('option_set')}
                                  <div className="ele-name">Option Set</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('social_share')}
                                  <div className="ele-name">Social Share</div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="designProperties" className="tab-pane fade">
                            {/* image type property */}
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designIMG"
                            >
                              Image
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designIMG" className="collapse fade show pd-15">
                              <div className="form-group">
                                <label className="form-item-title">Source</label>
                                <div className="input-group">
                                  <input type="text" className="form-control" />
                                  <div className="input-group-append">
                                    <button type="button" className="btn">
                                      <Image />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Alt Text</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div id="designStyle" className="tab-pane fade"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save and {`${tab.value !== 3 ? 'next' : 'finish'}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEmailTemp;
