import { Plus, X } from 'react-feather';

const NewProductDetailCost = () => {
  return (
    <div
      id="productDetailCostModal"
      className="modal over-modal fade right product-right-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">New Detail Cost Price</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive bd rounded-top">
              <table className="table mg-b-0 detail-cost-list">
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap bd-t-0-f">
                      Cost Field Name
                    </th>
                    <th scope="col" className="text-nowrap bd-t-0-f">
                      Amount
                    </th>
                    <th scope="col" className="text-nowrap bd-t-0-f">
                      Use as Default
                    </th>
                    <th scope="col" className="text-nowrap bd-t-0-f">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Total Cost Price</th>
                    <td colSpan={3} className="text-right"></td>
                  </tr>
                </tfoot>
                <tbody>
                  <tr>
                    <td>
                      <input type="text" className="form-control" />
                    </td>
                    <td>
                      <div className="input-group wd-200">
                        <div className="input-group-prepend">
                          <span className="input-group-text">＄</span>
                        </div>
                        <input
                          type="number"
                          className="form-control form-control-lg wd-100 mg-r-5"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="productDetailCoseUseDetailCheck1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="productDetailCoseUseDetailCheck1"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn tx-danger">
                        <X />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" className="form-control" />
                    </td>
                    <td>
                      <div className="input-group wd-200">
                        <div className="input-group-prepend">
                          <span className="input-group-text">＄</span>
                        </div>
                        <input
                          type="number"
                          className="form-control form-control-lg wd-100 mg-r-5"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="productDetailCoseUseDetailCheck2"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="productDetailCoseUseDetailCheck2"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn tx-danger">
                        <X />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="d-flex pd-10 bd bd-t-0 rounded-bottom">
              <input type="text" className="form-control mg-r-5" placeholder="Cost Field Name" />
              <button type="button" className="btn btn-primary flex-shrink-0">
                <Plus className="mg-r-5" />
                Add
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductDetailCost;
