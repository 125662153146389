import { Button, Switch } from '@base/components/form';
const AssignmentRuleList = (props: any) => {
  return (
    <div className="table-responsive bg-white bd rounded">
      <table className="table table-bordered dataTable mg-b-0 bd-0">
        <thead>
          <tr>
            <th scope="col" className="sorting_asc bd-t-0-f">
              Rule Name
            </th>
            <th scope="col" className="sorting bd-t-0-f">
              Description
            </th>
            <th scope="col" className="sorting bd-t-0-f">
              Created by
            </th>
            <th scope="col" className="sorting bd-t-0-f">
              Created On
            </th>
            <th scope="col" className="wd-100">
              Active
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="sorting-cell">
              <Button
                color="link"
                name="Rule 1"
                className="pd-0"
                onClick={() => props.setShowDetail(true)}
              />
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <Switch />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AssignmentRuleList;
