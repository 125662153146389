import { useState } from 'react';

import {
  Calendar,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CreditCard,
  Mail,
  Printer,
  X,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import { Select as SelectHook } from '@base/components/form';

const DebitList = () => {
  const [showActions, setShowActions] = useState(false);

  return (
    <div id="debitList" className="modal right fade">
      <div className="modal-dialog full-modal">
        <div className="modal-content rounded-0 overflow-hidden">
          <div className="modal-header">
            <h1 className="h6 modal-title">Debit Detail</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body d-flex flex-column pd-b-15-f overflow-hidden">
            <div className="d-flex align-items-center mg-b-10">
              {/* filter */}
              <div className="dropdown">
                <button type="button" className="btn pd-l-0" data-toggle="dropdown">
                  All Debit
                  <ChevronDown />
                </button>
                <div className="dropdown-menu">
                  <button type="button" className="dropdown-item">
                    All Debit
                  </button>
                  <button type="button" className="dropdown-item">
                    My Debit
                  </button>
                  <button type="button" className="dropdown-item">
                    My Group Debit
                  </button>
                  <button type="button" className="dropdown-item">
                    Debit per Invoice
                  </button>
                  <button type="button" className="dropdown-item">
                    Debit per POS
                  </button>
                  <button type="button" className="dropdown-item">
                    Debit per Debit Note
                  </button>
                </div>
              </div>

              <div className="dropdown">
                <button type="button" className="btn" data-toggle="dropdown">
                  Created This Month
                  <ChevronDown />
                </button>
                <div className="dropdown-menu wd-300">
                  <button className="dropdown-item">All</button>
                  <div className="dropdown-divider"></div>
                  <button
                    type="button"
                    className="btn d-flex wd-100p align-items-center tx-12 tx-semibold"
                    data-toggle="collapse"
                    data-target="#dateBy1"
                  >
                    <span className="mg-r-5">Created This Month</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy1" className="collapse show">
                    <button type="button" className="dropdown-item">
                      This Year
                    </button>
                    <button type="button" className="dropdown-item">
                      This Quarter
                    </button>
                    <button type="button" className="dropdown-item active">
                      This Month
                    </button>
                    <button type="button" className="dropdown-item">
                      This Week
                    </button>
                    <button type="button" className="dropdown-item">
                      Today
                    </button>
                    <button
                      type="button"
                      className="dropdown-item btn d-flex align-items-center"
                      data-toggle="collapse"
                      data-target="#dateBy1-custom"
                    >
                      <span className="mg-r-5">Custom</span>
                      <ChevronDown className="mg-l-auto" />
                    </button>
                    <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                      <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <Calendar />
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                    data-toggle="collapse"
                    data-target="#dateBy2"
                  >
                    <span className="mg-r-5">Updated This Month</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy2" className="collapse">
                    <button type="button" className="dropdown-item">
                      This Year
                    </button>
                    <button type="button" className="dropdown-item">
                      This Quarter
                    </button>
                    <button type="button" className="dropdown-item">
                      This Month
                    </button>
                    <button type="button" className="dropdown-item">
                      This Week
                    </button>
                    <button type="button" className="dropdown-item">
                      Today
                    </button>
                    <button
                      type="button"
                      className="dropdown-item btn d-flex align-items-center"
                      data-toggle="collapse"
                      data-target="#dateBy1-custom"
                    >
                      <span className="mg-r-5">Custom</span>
                      <ChevronDown className="mg-l-auto" />
                    </button>
                    <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                      <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <Calendar />
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                    data-toggle="collapse"
                    data-target="#dateBy3"
                  >
                    <span className="mg-r-5">Converted This Month</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy3" className="collapse">
                    <button type="button" className="dropdown-item">
                      This Year
                    </button>
                    <button type="button" className="dropdown-item">
                      This Quarter
                    </button>
                    <button type="button" className="dropdown-item">
                      This Month
                    </button>
                    <button type="button" className="dropdown-item">
                      This Week
                    </button>
                    <button type="button" className="dropdown-item">
                      Today
                    </button>
                    <button
                      type="button"
                      className="dropdown-item btn d-flex align-items-center"
                      data-toggle="collapse"
                      data-target="#dateBy1-custom"
                    >
                      <span className="mg-r-5">Custom</span>
                      <ChevronDown className="mg-l-auto" />
                    </button>
                    <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                      <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <Calendar />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* actions */}
              {showActions && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary rounded-30 mg-l-auto"
                    data-toggle="modal"
                    data-target="#registerPayment"
                  >
                    <CreditCard className="mg-r-5" />
                    Register Payment
                  </button>
                  <button type="button" className="btn">
                    <Mail className="mg-r-5" />
                    Send Email
                  </button>
                  <button type="button" className="btn">
                    <span className="mg-r-5">{Icon('export_pdf')}</span>Export to PDF
                  </button>
                  <button type="button" className="btn">
                    <Printer className="mg-r-5" />
                    Print
                  </button>
                </>
              )}
            </div>

            <div className="flex-grow-1 overflow-auto">
              <div className="table-responsive bd rounded">
                <table className="table table-bordered dataTable mg-b-0 bd-0">
                  <thead>
                    <tr>
                      <th scope="col" className="wd-30 bg-light bd-t-0-f">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="debitCheckAll"
                          />
                          <label className="custom-control-label" htmlFor="debitCheckAll"></label>
                        </div>
                      </th>
                      <th scope="col" className="sorting_asc bg-light bd-t-0-f text-nowrap">
                        ID
                      </th>
                      <th scope="col" className="sorting bg-light bd-t-0-f text-nowrap">
                        Total Amount
                      </th>
                      <th scope="col" className="sorting bg-light bd-t-0-f text-nowrap">
                        Date
                      </th>
                      <th scope="col" className="sorting bg-light bd-t-0-f text-nowrap">
                        Amount Received{' '}
                      </th>
                      <th scope="col" className="sorting bg-light bd-t-0-f text-nowrap">
                        Balance Due
                      </th>
                      <th scope="col" className="sorting bg-light bd-t-0-f text-nowrap">
                        Reference ID
                      </th>
                      <th scope="col" className="sorting bg-light bd-t-0-f text-nowrap">
                        Assigned Rep
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="debitCheck1"
                            onChange={() => setShowActions(!showActions)}
                          />
                          <label className="custom-control-label" htmlFor="debitCheck1"></label>
                        </div>
                      </td>
                      <td className="sorting-cell">
                        <a href="" target="_blank" title="새창으로 열기">
                          INV-000001
                        </a>
                      </td>
                      <td>￦155,500</td>
                      <td>2022-01-30</td>
                      <td>&nbsp;</td>
                      <td>￦100,000</td>
                      <td>
                        <a href="" target="_blank" title="새창으로 열기">
                          SO-000001
                        </a>
                      </td>
                      <td>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">MSR</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="pagination flex-grow-1 align-items-center justify-content-center">
              <button
                type="button"
                className="btn btn-link link-02 btn-icon"
                aria-label="move to first"
              >
                {Icon('move_first')}
                <span className="sr-only">처음으로</span>
              </button>
              <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
                <ChevronLeft />
                <span className="sr-only">이전</span>
              </button>
              <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
                <ChevronRight />
                <span className="sr-only">다음</span>
              </button>
              <button
                type="button"
                className="btn btn-link link-02 btn-icon"
                aria-label="move to last"
              >
                {Icon('move_last')}
                <span className="sr-only">마지막으로</span>
              </button>
              <input type="number" className="paging-input" defaultValue="1" />
              / 1
              <SelectHook className="wd-80-f mg-l-20" options={[{ value: 10, label: '10' }]} />
            </div>
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebitList;
