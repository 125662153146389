import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ArrowLeft,
  Copy,
  Paperclip,
  Image,
  Clock,
  Edit2,
  X,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { COLORS } from '@base/config/constant';

import Attachments from '../../../component/desktop/attachments';
import AssignedRep from '../../../component/desktop/assigned-rep';
import Userlist from '../../../component/desktop/userlist';
import ProductList from '../../../component/desktop/productlist';
import { Button, Input } from '@base/components/form';
import NewFinishedItem from './modal/newFinishedItem';
import ReturnMaterial from './modal/returnMaterial';

const TABS = [
  {
    value: 1,
    label: 'Components',
  },
  {
    value: 2,
    label: 'Work Instruction',
  },
  {
    value: 3,
    label: 'Finished Item',
  },
  {
    value: 4,
    label: 'Time Tracking',
  },
];

const SmsView = () => {
  const [tab, setTab] = useState(TABS[0]);

  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [hideAsideRight, setHideAsideRight] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/activity/desktop/theme1/production-task-list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Production Task
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title">Activity Subject 1 Activity Subject 1 Activity Subject 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info" data-toggle="dropdown">
                  Confirm
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu">
                  <Button color="" className="dropdown-item" name="Confirm" />
                  <Button color="" className="dropdown-item" name="Start Working" />
                  <Button color="" className="dropdown-item" name="Pause" />
                  <Button color="" className="dropdown-item" name="Finish" />
                  <Button
                    color=""
                    className="dropdown-item"
                    name="Return Material"
                    data-toggle="modal"
                    data-target="#returnMaterial"
                  />
                </div>
              </div>
              <div className="dropdown mg-l-5">
                <button type="button" className="btn btn-info" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Copy />
                    </span>
                    Clone
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newSMSModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Bill of Materials</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Quantity to produce</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Planned Date</label>
                    <div>2021/07/01 - 2021/07/11</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Planned Date</label>
                    <div>2021/07/11</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Expected Duration</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Real Duration</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Source</label>
                    <div>
                      <a href="" target="_blank">
                        SO-000001
                      </a>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Consumed Materials</span>
                    </div>
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Component
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Qty
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Unit
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Stock
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                To Consume
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Reserved
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Received
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Consumed
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Return
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Receipt-Received
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <Button
                                  color="link"
                                  icon="X"
                                  iconClass="tx-danger"
                                  className="btn-icon pd-y-0"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="pd-10">
                        <div className="input-group">
                          <Input type="text" placeholder="Type or click to add a component" />
                          <div className="input-group-append">
                            <Button
                              color=""
                              className="input-group-btn"
                              icon="product"
                              iconType="main"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer pd-y-6-f pagination">
                        <Button
                          color="link link-02"
                          className="btn-icon"
                          icon="move_first"
                          iconType="custom"
                          data-han-tooltip="move to first"
                        />
                        <Button
                          color="link link-02"
                          className="btn-icon"
                          icon="ChevronLeft"
                          data-han-tooltip="previous"
                        />
                        <Button
                          color="link link-02"
                          className="btn-icon"
                          icon="ChevronRight"
                          data-han-tooltip="next"
                        />
                        <Button
                          color="link link-02"
                          className="btn-icon"
                          icon="move_last"
                          iconType="custom"
                          data-han-tooltip="move to last"
                        />
                        <Input type="number" className="paging-input" defaultValue="1" />/ 1
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tab.value == 2 && (
                <div className="pd-20">
                  <div className="message-input-container">
                    <div className="message-input_inner">
                      <div className="message-input ht-100-f" contentEditable="true"></div>
                      <div className="message-input-placeholder" tabIndex={-1}>
                        Enter a message
                      </div>
                    </div>
                    <div className="message-input-buttons">
                      <button className="btn btn-link link-03">
                        <Paperclip />
                        <span className="sr-only">첨부파일 / Attachment</span>
                      </button>
                      <button className="btn btn-link link-03">
                        <Image />
                        <span className="sr-only">이미지 삽입 / Insert image</span>
                      </button>
                      <button type="button" className="btn btn-primary mg-l-auto">
                        Post
                      </button>
                    </div>
                  </div>
                  <div className="timeline-group note-timeline mg-t-20">
                    <div className="timeline-label">2021/07/01</div>
                    <div className="timeline-item">
                      <div className="timeline-icon">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="timeline-body">
                        <div className="card">
                          <div className="d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bd-b">
                            <span>
                              MSR
                              <span className="mg-l-10 tx-color-03">
                                <Clock size="16" className="mg-r-5" />
                                13:00
                              </span>
                            </span>
                            <div className="mg-l-auto">
                              <button
                                type="button"
                                className="btn btn-link btn-icon"
                                data-han-tooltip="Edit"
                              >
                                <Edit2 />
                                <span className="sr-only">수정</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-link btn-icon"
                                data-han-tooltip="Delete"
                              >
                                <X className="tx-danger" />
                                <span className="sr-only">삭제</span>
                              </button>
                            </div>
                          </div>
                          <div className="pd-15">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            <ul className="list-unstyled mg-b-0">
                              <li>
                                <Paperclip size="16" className="tx-color-03" />
                                <button type="button" className="btn btn-link pd-y-2">
                                  abc.doc
                                </button>
                              </li>
                              <li>
                                <Paperclip size="16" className="tx-color-03" />
                                <button type="button" className="btn btn-link pd-y-2">
                                  def.pdf
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="mg-b-10 text-right">
                    <Button
                      color="primary"
                      className="rounded-30"
                      icon="Plus"
                      iconClass="mg-r-5"
                      name="New"
                      data-toggle="modal"
                      data-target="#newFinishedItem"
                    />
                  </div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f sorting">
                              Item
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Lot/Serial Number
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Qty
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Unit
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Inspection
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              SKU
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Scrap
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Inventory
                            </th>
                            <th scope="col" className="bd-t-0-f wd-70">
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                              <Button
                                color="link"
                                icon="X"
                                iconClass="tx-danger"
                                className="btn-icon pd-y-0"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer pd-y-6-f pagination">
                      <Button
                        color="link link-02"
                        className="btn-icon"
                        icon="move_first"
                        iconType="custom"
                        data-han-tooltip="move to first"
                      />
                      <Button
                        color="link link-02"
                        className="btn-icon"
                        icon="ChevronLeft"
                        data-han-tooltip="previous"
                      />
                      <Button
                        color="link link-02"
                        className="btn-icon"
                        icon="ChevronRight"
                        data-han-tooltip="next"
                      />
                      <Button
                        color="link link-02"
                        className="btn-icon"
                        icon="move_last"
                        iconType="custom"
                        data-han-tooltip="move to last"
                      />
                      <Input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}
              {tab.value == 4 && (
                <div className="pd-20">
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f sorting">
                              Start Date
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              End Date
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Duration
                            </th>
                            <th scope="col" className="bd-t-0-f sorting">
                              Assigned Rep.
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer pd-y-6-f pagination">
                      <Button
                        color="link link-02"
                        className="btn-icon"
                        icon="move_first"
                        iconType="custom"
                        data-han-tooltip="move to first"
                      />
                      <Button
                        color="link link-02"
                        className="btn-icon"
                        icon="ChevronLeft"
                        data-han-tooltip="previous"
                      />
                      <Button
                        color="link link-02"
                        className="btn-icon"
                        icon="ChevronRight"
                        data-han-tooltip="next"
                      />
                      <Button
                        color="link link-02"
                        className="btn-icon"
                        icon="move_last"
                        iconType="custom"
                        data-han-tooltip="move to last"
                      />
                      <Input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Customer */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Customer</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#activityCustomer"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="activityCustomer" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Attachment */}
              <Attachments />

              {/* Product List */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Product</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerProduct"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerProduct" className="view-aside-section-body collapse show">
                  <ProductList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewFinishedItem />
      <ReturnMaterial />
    </>
  );
};

export default SmsView;
