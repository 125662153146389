import { Button, Input, Select } from '@base/components/form';
import { useState } from 'react';
import { UserAutoComplete } from '@base/containers';
import { Settings } from 'react-feather';

const TABS = [
  {
    value: 1,
    label: 'Summary',
  },
  {
    value: 2,
    label: 'Inspection',
  },
  {
    value: 3,
    label: 'Lot/Serial Number',
  },
];

const NewFinishedItem = () => {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <div id="newFinishedItem" className="modal right fade">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0 overflow-hidden">
          <div className="modal-header flex-column pd-0">
            <div className="d-flex wd-100p pd-15">
              <h1 className="h6 modal-title">Register Finished Item</h1>
              <Button close />
            </div>
            <ul className="steps steps-tab steps-justified wd-100p bd-t">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  className={`step-item ${item.value == tab.value ? 'active' : ''} ${
                    item.value < tab.value ? 'complete' : ''
                  }`}
                >
                  <div
                    className="step-link"
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    <span className="step-number">{item.value}</span>
                    <span className="step-title tx-transform-none">{item.label}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            {tab.value == 1 && (
              <>
                <div className="form-group">
                  <label className="form-item-title">Item Name</label>
                  <Input type="text" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Quantity</label>
                  <div className="d-flex align-items-center">
                    <Input type="number" className="wd-150 mg-r-10" />
                    <Select width={150} />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Consumed Component</label>
                  <div className="table-responsive bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 basic-table">
                      <thead>
                        <tr>
                          <th scope="col">Component</th>
                          <th scope="col">Quantity</th>
                          <th scope="col" className="wd-150">
                            Consumed
                          </th>
                          <th scope="col">Unit</th>
                          <th scope="col" className="wd-70">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="input-group">
                              <Input type="text" placeholder="Type or click to add a component" />
                              <div className="input-group-append">
                                <Button
                                  color=""
                                  className="input-group-btn"
                                  icon="product"
                                  iconType="main"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <Select />
                          </td>
                          <td>
                            <Input rightIcon="%" />
                          </td>
                          <td>&nbsp;</td>
                          <td>
                            <Button
                              color="link"
                              icon="X"
                              iconClass="tx-danger"
                              className="btn-icon pd-y-0"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pd-10 bd bd-t-0 rounded-bottom">
                    <div className="input-group">
                      <Input type="text" placeholder="Type or click to add a component" />
                      <div className="input-group-append">
                        <Button
                          color=""
                          className="input-group-btn"
                          icon="product"
                          iconType="main"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {tab.value == 2 && (
              <>
                <div className="form-group">
                  <label className="form-item-title">Assigned Rep.</label>
                  <UserAutoComplete showAvatar />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Comment</label>
                  <Input type="text" />
                </div>
              </>
            )}
            {tab.value == 3 && (
              <>
                <div className="form-group">
                  <label className="form-item-title">Lot/Serial Number</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">SKU</label>
                  <Input type="text" rightIcon={<Settings />} className="wd-300" disabled />
                </div>
                <Button
                  color="link"
                  icon="Printer"
                  iconClass="mg-r-5"
                  className="pd-x-0"
                  name="Print Barcode"
                />
              </>
            )}
          </div>
          <div className="modal-footer">
            {tab.value != 1 && (
              <Button
                color="link link-03"
                icon="ArrowLeft"
                iconClass="mg-r-5"
                name="Previous"
                className="mg-r-auto"
              />
            )}
            <Button color="light" name="Close" data-dismiss="modal" />
            {tab.value == 1 && (
              <Button color="primary" icon="ArrowRight" isIconsEnd name="Next" className="" />
            )}
            {tab.value == 2 && <Button color="primary" isIconsEnd name="Register" className="" />}
            {tab.value == 3 && (
              <div className="btn-group dropup">
                <Button color="success" name="Completion" />
                <Button
                  color="success"
                  className="dropdown-toggle-split"
                  icon="ChevronUp"
                  data-toggle="dropdown"
                />
                <div className="dropdown-menu dropdown-menu-right">
                  <Button color="" className="dropdown-item" name="Completion and Create New" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFinishedItem;
