import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  X,
  Mail,
  ArrowLeft,
  CornerUpLeft,
  User,
  MessageCircle,
  CheckCircle,
  Circle,
  Pause,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "DeskCss/desk.scss"

import Attachments from '../../../../component/desktop/attachments';
import AssignedRep from '../../../../component/desktop/assigned-rep';
import Userlist from '../../../../component/desktop/userlist';
import Comment from '../../../../component/desktop/comment';
import Chat from '../../../../component/desktop/chat';
import KnowledgeBase from '../../../../component/desktop/knowledgebase';
import TicketTimline from './view/timeline';
import TicketReply from './modal/reply';
import NewTicket from './modal/newTicket';
import NewContact from './modal/newContact';
import RealDuration from './modal/realDuration';

const TABS = [
  {
    value: 1,
    label: 'Content',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Note',
  },
  {
    value: 4,
    label: 'TeamChannel',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/ticket/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Ticket
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/ticket/desktop/theme1/list">Ticket</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/knowledge-base/desktop/theme1/list">Knowledge Base</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/desk/desktop/theme1/settings">Setting</a>
              </div>
            </div>
          </div>

          <h1 className="view-title">How to access the menus?</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <button
                type="button"
                className="btn btn-info"
                data-toggle="modal"
                data-target="#realDuration"
              >
                Done
              </button>
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#replyModal"
                  >
                    <span className="dropdown-item-icon">
                      <CornerUpLeft />
                    </span>
                    Reply
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#replyModal"
                  >
                    <span className="dropdown-item-icon">
                      <Pause />
                    </span>
                    Oh Hold
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newTicketModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Stage 1</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>New</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 2</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 3</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 4</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 5</div>
            </li>
          </ol>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Ticket ID</label>
                    <div>TIC-0000001</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Category</label>
                    <div>Question</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Priority</label>
                    <span className="d-inline-block pd-x-10 pd-t-2 pd-b-4 bg-danger rounded-30 lh-1 tx-white">
                      Urgent
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Due Date</label>
                    <div>2020/12/25</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Duration</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Real Duration</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Policy</label>
                    <div>First response due: 2022/01/22 17:00</div>
                    <div>Resolution due: 2022/01/23 13:00</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Channel</label>
                    <div>Email</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Process</label>
                    <div>Process 1</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Tag</label>
                    <div className="tag-item">
                      email
                      <button type="button" className="btn-tag-remove">
                        <X />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && (
                <div className="pd-20">
                  Hello!
                  <br />
                  How to access the menus?
                </div>
              )}
              {tab.value == 2 && <TicketTimline />}
              {tab.value == 3 && <Comment />}
              {tab.value == 4 && <Chat />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Contact */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Contact</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerContact"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerContact" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* KnowledgeBase */}
              <KnowledgeBase />

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* CC */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">CC</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#CC"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="CC" className="view-aside-section-body collapse show">
                  <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-3">
                    <div
                      className="media align-items-center flex-grow-1"
                      style={{ maxWidth: 'calc(100% - 108px' }}
                    >
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/11/cn/1"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="media-body mg-l-5 text-truncate">KTS</div>
                    </div>
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        className="btn btn-link btn-icon han-tooltip--top"
                        data-han-tooltip="Send Email"
                      >
                        <Mail className="tx-teal" />
                        <span className="sr-only">이메일 보내기</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link btn-icon han-tooltip--top"
                        data-han-tooltip="Chat"
                      >
                        <MessageCircle className="tx-indigo" />{' '}
                        <span className="sr-only">채팅하기</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link btn-icon han-tooltip--top"
                        data-han-tooltip="Delete"
                      >
                        <X className="tx-danger" />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                  </div>
                  <div className="dropdown">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to add an user"
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn">
                          <User />
                        </button>
                      </div>
                    </div>
                    <div className="dropdown-menu wd-100p bg-white">
                      <button type="button" className="dropdown-item">
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://themepixels.me/demo/dashforge1.1/assets/img/img14.jpg"
                              alt=""
                              className="rounded-circle"
                            />
                          </div>
                          <div className="media-body mg-l-5 text-truncate">John Doe</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <TicketReply />
      <NewTicket />
      <NewContact />
      <RealDuration />
    </>
  );
};

export default ViewNew;
