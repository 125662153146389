/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Edit2, X, Check, ArrowLeft } from 'react-feather';

import NewProcessStage from './diagram/newProcessStage';
import NewClosedStatus from './diagram/newClosedStatus';
import SelStepType from './diagram/selStepType';
import EditProcess from './diagram/editProcess';
import EditStep from './diagram/editStep';
import SiteLinks from './diagram/modal/siteLinks';
import SiteButtons from './diagram/modal/siteButtons';
import PurchaseOrder from './diagram/process/PurchaseOrder';
import PurchaseRequest from './diagram/process/PurchaseRequest';
import Desk from './diagram/process/Desk';

import './diagram-new.scss';

const NewDiagram = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  type RouteState = {
    state: {
      name: string;
    };
  };

  const { state } = useLocation() as RouteState;

  return (
    <>
      <div className="d-flex flex-column ht-100p">
        <div className="process-header">
          <a
            href="/settings/desktop/theme1/process"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
          </a>
          <h1 className="process-name pd-x-15 bd-l">Purchase Process</h1>
          {!showEdit ? (
            <>
              <nav className="nav nav-line mg-l-auto mg-r-30 mg-t-15 bd-0">
                <button
                  className={`btn nav-link ${!showDetail ? 'active' : ''}`}
                  onClick={() => setShowDetail(false)}
                >
                  Simple view
                </button>
                <button
                  className={`btn nav-link ${showDetail ? 'active' : ''}`}
                  onClick={() => setShowDetail(true)}
                >
                  Detail view
                </button>
              </nav>
              <button type="button" className="btn btn-primary" onClick={() => setShowEdit(true)}>
                <Edit2 className="mg-r-5" />
                Edit
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon"
                data-han-tooltip="Edit"
                data-toggle="modal"
                data-target="#editProcess"
              >
                <Edit2 />
                <span className="sr-only">Edit</span>
              </button>
              <button
                type="button"
                className="btn btn-light mg-l-auto"
                onClick={() => setShowEdit(false)}
              >
                <X className="mg-r-5" />
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success mg-l-10"
                onClick={() => setShowEdit(false)}
              >
                <Check className="mg-r-5" />
                Save
              </button>
            </>
          )}
        </div>
        {state.name === 'po' && (
          <PurchaseOrder
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            showDetail={showDetail}
            setShowDetail={setShowDetail}
          />
        )}
        {state.name === 'pr' && (
          <PurchaseRequest
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            showDetail={showDetail}
            setShowDetail={setShowDetail}
          />
        )}
        {state.name === 'desk' && (
          <Desk
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            showDetail={showDetail}
            setShowDetail={setShowDetail}
          />
        )}
      </div>

      <NewProcessStage />
      <NewClosedStatus />
      <SelStepType />
      <EditProcess />
      <EditStep />
      <SiteLinks />
      <SiteButtons />
    </>
  );
};

export default NewDiagram;
