import { Input, Select as SelectHook } from '@base/components/form';
import SiteType from './containers/site-type';
import Stage from './containers/stage';
import SiteStatus from './containers/siteStatus';

const StepSite = () => {
  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Step Type</label>
        <div>Site</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Step Name</label>
        <Input />
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Stage</label>
        <Stage className="wd-300-f" />
      </div>
      <SiteType />
      <div className="form-group">
        <label className="form-item-title">Quote Site Type</label>
        <SelectHook className="wd-300-f" />
      </div>
      <SiteStatus />
    </>
  );
};

export default StepSite;
