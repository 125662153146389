import BasicTable from '@base/components/table/basic';
import React, { useMemo } from 'react';
import { ChevronDown } from 'react-feather';

const ManualStatus: React.FC<any> = (props: any) => {
  const columnData = [
    {
      accessor: 'name',
      Header: 'Button Name',
    },
    {
      accessor: 'view',
      Header: 'View',
    },
    {
      accessor: 'event',
      Header: 'Event',
    },
    {
      accessor: 'status',
      Header: 'Status',
    },
    {
      accessor: 'direction',
      Header: 'Direction',
    },
    {
      accessor: 'next_step',
      Header: 'Next Step',
    },
    {
      accessor: 'property',
      Header: 'Property',
    },
    {
      accessor: 'sequence',
      Header: 'Sequence',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const data = useMemo(
    () => [
      {
        name: '',
        view: '',
        event: '',
        status: '',
        direction: 'Forward',
        next_step: '',
        property: '',
        sequence: '0',
      },
    ],
    [],
  );

  return (
    <>
      <button
        type="button"
        className="btn btn-link pd-x-0 collapsed"
        data-toggle="collapse"
        data-target="#manualBtnsList"
      >
        Buttons/Status
        <ChevronDown className="mg-l-5" />
      </button>
      <div id="manualBtnsList" className="card collapse">
        <BasicTable columns={columns} data={data} className="table-bordered bd-0 basic-table" />
      </div>
    </>
  );
};

export default ManualStatus;
