/* eslint-disable prettier/prettier */
import { Edit2 } from 'react-feather';

const Profile = () => {
  return (
    <div className="pd-20">
      <div className="mg-b-15 text-right">
        <button
          type="button"
          className="btn btn-link"
          data-toggle="modal"
          data-target="#newCustomerProfile"
        >
          <Edit2 className="mg-r-5" />
          Edit
        </button>
      </div>

      <div className="card-columns">
        {/* Account */}
        <div className="card">
          <div className="card-header bd-b-0-f pd-b-0 tx-semibold">Firmographic</div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Industry</div>
                <div className="mg-l-auto">Health And Social Care</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Subsidiary</div>
                <div className="mg-l-auto">Partly – owned subsidiaries</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Company Size</div>
                <div className="mg-l-auto">Small (&lt;50 people)</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Annual Revenue</div>
                <div className="mg-l-auto">￦1,000,000,000</div>
              </li>
              <li className="d-flex flex-wrap">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Age(years in business)</div>
                <div className="mg-l-auto">2021</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header bd-b-0-f pd-b-0 tx-semibold">Geographic</div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Related Account</div>
                <div className="mg-l-auto">AAA</div>
              </li>
              <li className="d-flex flex-wrap">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Purchasing Process</div>
                <div className="mg-l-auto">Automation</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header bd-b-0-f pd-b-0 tx-semibold">Behavior</div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Response to Marketing</div>
                <div className="mg-l-auto">Yes</div>
              </li>
              <li className="d-flex flex-wrap">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Usage</div>
                <div className="mg-l-auto">Light</div>
              </li>
            </ul>
          </div>
        </div>

        {/* Contact */}
        <div className="card">
          <div className="card-header bd-b-0-f pd-b-0 tx-semibold">Demographic</div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Age</div>
                <div className="mg-l-auto">28</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Birthday</div>
                <div className="mg-l-auto">1994-01-26</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Gender</div>
                <div className="mg-l-auto">Female</div>
              </li>
              <li className="d-flex flex-wrap">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Occupation</div>
                <div className="mg-l-auto">Healthcare practitioners and technical</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header bd-b-0-f pd-b-0 tx-semibold">Geographic</div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Countries</div>
                <div className="mg-l-auto">Engleland</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Cities</div>
                <div className="mg-l-auto">London</div>
              </li>
              <li className="d-flex flex-wrap">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Regions</div>
                <div className="mg-l-auto">Barnet</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header bd-b-0-f pd-b-0 tx-semibold">Behavior</div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Loyalty Status</div>
                <div className="d-flex align-items-center mg-l-auto">
                  <div
                    className="wd-30 ht-30 mg-r-5 pd-0 bd-0 rounded-circle text-center lh-12"
                    style={{ backgroundColor: '#bc9546' }}
                  >
                    <span className="tx-white tx-semibold">G</span>
                  </div>
                  Gold
                </div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Usage</div>
                <div className="mg-l-auto">Heavy</div>
              </li>
              <li className="d-flex flex-wrap">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">
                  Product reviews/Feedback
                </div>
                <div className="mg-l-auto">Several</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header bd-b-0-f pd-b-0 tx-semibold">Psychographic</div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Work</div>
                <div className="mg-l-auto">Self-employed</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Social events</div>
                <div className="mg-l-auto">Holiday Parties</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Vacations</div>
                <div className="mg-l-auto">Travel to Korea</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Club Memberships</div>
                <div className="mg-l-auto">Golf Club</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Community</div>
                <div className="mg-l-auto">Heavy User</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Entertainment Choices</div>
                <div className="mg-l-auto">going to the cinema</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Sports</div>
                <div className="mg-l-auto">NBA fan</div>
              </li>
              <li className="d-flex flex-wrap mg-b-10">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Shopping</div>
                <div className="mg-l-auto">Hipster</div>
              </li>
              <li className="d-flex flex-wrap">
                <div className="wd-150 mg-r-10 text-nowrap tx-color-03">Interests</div>
                <div className="mg-l-auto">Sports Car</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
