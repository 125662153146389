import {
  Calendar,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ExternalLink,
  Mail,
  Printer,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Statement = () => {
  return (
    <div className="pd-15">
      <div className="text-right">
        <button type="button" className="btn btn-link">
          <span className="mg-r-5">{Icon('export_pdf')}</span>Export to PDF
        </button>
        <button type="button" className="btn btn-link">
          <Printer className="mg-r-5" />
          Print
        </button>
        <button type="button" className="btn btn-link">
          <Mail className="mg-r-5" />
          Send
        </button>
      </div>

      <div className="card card-body mg-y-10">
        <div className="row row-sm">
          <div className="d-flex flex-column justify-content-between col-6 col-sm-4 col-md">
            <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-03 mg-b-10">
              Opening Balance
            </div>
            <div className="tx-rubik tx-bold mg-r-5 lh-1">￦0</div>
          </div>
          <div className="d-flex flex-column justify-content-between col-6 col-sm-4 col-md">
            <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-03 mg-b-10">
              Invoice Amount
            </div>
            <div className="tx-rubik tx-bold mg-r-5 lh-1">￦210,000</div>
          </div>
          <div className="d-flex flex-column justify-content-between col-6 col-sm-4 col-md mg-t-20 mg-sm-t-0">
            <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-03 mg-b-10">POS Amount</div>
            <div className="tx-rubik tx-bold mg-r-5 lh-1">￦210,000</div>
          </div>
          <div className="d-flex flex-column justify-content-between col-6 col-sm-4 col-md-3 col-xl mg-t-20 mg-md-t-0">
            <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-03 mg-b-10">
              Amount Received
            </div>
            <div className="tx-rubik tx-bold mg-r-5 lh-1">￦40,000</div>
          </div>
          <div className="d-flex flex-column justify-content-between col-6 col-sm-4 col-md mg-t-20 mg-md-t-0">
            <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-03 mg-b-10">Balance Due</div>
            <div className="tx-rubik tx-bold mg-r-5 lh-1">￦270,000</div>
          </div>
          <div className="d-flex flex-column justify-content-between col-6 col-sm-4 col-md mg-t-20 mg-md-t-0">
            <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-03 mg-b-10">
              Unused Credits
            </div>
            <div className="tx-rubik tx-bold tx-danger mg-r-5 lh-1">￦0</div>
          </div>
        </div>
      </div>

      <button type="button" className="btn pd-l-0" data-toggle="dropdown">
        Statement Period : All
        <ChevronDown className="mg-l-5" />
      </button>
      <div className="dropdown-menu">
        <button className="dropdown-item">All</button>
        <div className="dropdown-divider"></div>
        <button type="button" className="dropdown-item">
          This Year
        </button>
        <button type="button" className="dropdown-item">
          This Quarter
        </button>
        <button type="button" className="dropdown-item active">
          This Month
        </button>
        <button type="button" className="dropdown-item">
          This Week
        </button>
        <button type="button" className="dropdown-item">
          Today
        </button>
        <button
          type="button"
          className="dropdown-item btn d-flex align-items-center"
          data-toggle="collapse"
          data-target="#dateBy1-custom"
        >
          <span className="mg-r-5">Custom</span>
          <ChevronDown className="mg-l-auto" />
        </button>
        <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
          <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
          <div className="input-group-append">
            <span className="input-group-text">
              <Calendar />
            </span>
          </div>
        </div>
      </div>

      <div className="table-responsive bg-white bd rounded">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f">
                ID
              </th>
              <th scope="col" className="bd-t-0-f">
                Transactions
              </th>
              <th scope="col" className="bd-t-0-f">
                Date
              </th>
              <th scope="col" className="bd-t-0-f">
                Details
              </th>
              <th scope="col" className="bd-t-0-f">
                Amount
              </th>
              <th scope="col" className="bd-t-0-f">
                Payments
              </th>
              <th scope="col" className="bd-t-0-f">
                Balance Due
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>INV-0000059</td>
              <td>Invoice</td>
              <td>2021/03/03</td>
              <td>Due on 2021/03/10</td>
              <td>￦10,000</td>
              <td>&nbsp;</td>
              <td>￦10,000</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex mg-t-10 pd-10 rounded bg-secondary">
        <dl className="d-flex mg-l-auto mg-b-0">
          <dt className="mg-r-10 tx-white">Balance Due</dt>
          <dd className="mg-b-0 tx-white tx-semibold">￦270,000</dd>
        </dl>
      </div>
    </div>
  );
};

export default Statement;
