import Icon from '@base/assets/icons/svg-icons';

const PickAvatar = () => {
  return (
    <>
      <ul className="list-unstyled avatar-list">
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-01 active">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar01')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-02">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar02')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-03">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar03')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-04">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar04')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-05">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar05')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-06">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar06')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-01">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar07')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-02">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar08')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-03">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar09')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-04">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar10')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-05">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar11')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-06">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar12')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-01">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar13')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-02">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar14')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-03">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar15')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-04">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar16')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-05">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar17')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-06">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar18')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-01">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar19')}
          </button>
        </li>
        <li className="avatar-list-item">
          <button type="button" className="btn avatar-persona circle__box avatar-bg-02">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
            {Icon('avatar20')}
          </button>
        </li>
      </ul>
    </>
  );
};

export default PickAvatar;
