/* eslint-disable prettier/prettier */
import { Button } from '@base/components/form';
import NewSalesCommission from './modal/newSalesCommission';

const SalesCommission = () => {
  return (
    <div className="page-container-wrap">
      <div className="page-container-content">
        <div className="page-container-content-header">
          <h1 className="mg-b-0 tx-16 tx-uppercase tx-normal mg-l-10 mg-r-10">
            Sales Commission Plan
          </h1>
          <Button
            color="primary"
            className="mg-l-auto"
            icon="Plus"
            iconClass="mg-r-5"
            name="Add"
            data-toggle="modal"
            data-target="#newSalesCommission"
          />
        </div>
        <div className="scroll-box pd-20">
          <div className="table-responsive bg-white bd rounded">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col" className="sorting_asc bd-t-0-f">
                    Plan Name
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Type
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Pay Confirm
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Rate
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    %/Amount
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Based on
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Item
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Created On
                  </th>
                  <th scope="col" className="sorting bd-t-0-f">
                    Mode
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="sorting-cell">
                    <Button
                      color="link"
                      name="Plan 1"
                      className="pd-0"
                      data-toggle="modal"
                      data-target="#newSalesCommission"
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <NewSalesCommission />
    </div>
  );
};

export default SalesCommission;
