import { ChevronUp, X } from 'react-feather';

import WriteTicket from '../write';

const NewTicket = () => {
  return (
    <div id="newTicketModal" className="modal fade">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="h6 modal-title">Create Ticket</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <WriteTicket />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                aria-label="toggle dropdown"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">저장 옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <button type="button" className="dropdown-item">
                  Save and add new
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTicket;
