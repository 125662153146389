/* eslint-disable prettier/prettier */
import { Button, RadioGroup, Select, Switch } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { useState } from 'react';

const MODEOPTIONS = [
  { value: 'portrait', label: 'Portrait' },
  { value: 'landscape', label: 'Landscape' },
];

const GRIDOPTIONS = [
  { value: 'show', label: 'Show' },
  { value: 'never', label: 'Never' },
];

const ReportSettings = () => {
  const [mode, setMode] = useState(MODEOPTIONS);
  const [selectedMode, setSelectedMode] = useState(mode[0].value);
  //change
  const handleModeChange = (newOption: any) => {
    setSelectedMode(newOption.value);
  };

  const [grid, setGrid] = useState(GRIDOPTIONS);
  const [selectedGrid, setSelectedGrid] = useState(grid[0].value);
  //change
  const handleGridChange = (newOption: any) => {
    setSelectedGrid(newOption.value);
  };
  return (
    <div className="row row-xs">
      <div className="col-lg-6">
        {/* Date Rage */}
        <div className="card mg-b-15">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">
              Date Rage
              <br />
              <small className="mg-t-5 tx-color-03 tx-normal">
                The data in a chart will be displayed based on the date range.
              </small>
            </div>
          </div>
          <div className="card-body">
            <Select width={300} defaultValue={{ value: 1, label: 'This Month' }} />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        {/* Reporting Cycle */}
        <div className="card mg-b-15">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">
              Reporting Cycle
              <br />
              <small className="mg-t-5 tx-color-03 tx-normal">
                The report will be generate and sent based on the schedule.
              </small>
            </div>
          </div>
          <div className="card-body d-flex align-items-center">
            <Select width={150} defaultValue={{ value: 'm', label: 'Monthly' }} />
            <div className="input-group flex-nowrap wd-150 mg-x-10">
              <div className="input-group-prepend">
                <span className="input-group-text bd-0">Every</span>
              </div>
              <Select width={135} defaultValue={{ value: 1, label: '1st' }} />
            </div>
            <Select width={150} defaultValue={{ value: 1, label: '08:00' }} />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        {/* Display Mode */}
        <div className="card mg-b-15">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Display Mode</div>
          </div>
          <div className="card-body">
            <RadioGroup options={mode} value={selectedMode} onChange={handleModeChange} />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        {/* Display Grid */}
        <div className="card mg-b-15">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Display Grid</div>
          </div>
          <div className="card-body">
            <RadioGroup options={grid} value={selectedGrid} onChange={handleGridChange} />
          </div>
        </div>
      </div>

      <div className="col-12">
        {/* Recipient Group */}
        <div className="card mg-b-15">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Recipient Group</div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete">
              <thead>
                <tr>
                  <th scope="col" className="tx-nowrap">
                    Group Name
                  </th>
                  <th scope="col" className="tx-nowrap">
                    Recipients
                  </th>
                  <th scope="col" className="tx-nowrap">
                    Active
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">Recipient Group 1 </td>
                  <td>
                    <UserAutoComplete showAvatar />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Switch />
                      <Button
                        color=""
                        icon="X"
                        iconClass="tx-danger"
                        className="btn-icon btn-delete-row han-tooltip--left"
                        data-han-tooltip="삭제"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSettings;
