import BasicTable from '@base/components/table/basic';
import React, { useMemo } from 'react';
import { ChevronDown, Plus } from 'react-feather';

const SiteStatus: React.FC<any> = (props: any) => {
  const columnData = [
    {
      accessor: 'name',
      Header: 'Page/Form Name',
    },
    {
      accessor: 'cta',
      Header: 'CTA Name',
    },
    {
      accessor: 'event',
      Header: 'Event',
    },
    {
      accessor: 'status',
      Header: 'Status',
    },
    {
      accessor: 'direction',
      Header: 'Direction',
    },
    {
      accessor: 'next_step',
      Header: 'Next Step',
    },
    {
      accessor: 'property',
      Header: 'Property',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const data = useMemo(
    () => [
      {
        name: 'Quote Site',
        cta: '',
        event: '',
        status: 'Visited',
        direction: 'Incoming',
        next_step: '',
        property: '',
      },
      {
        name: 'Sign',
        cta: 'CTA6',
        event: 'Click',
        status: 'Confirmed',
        direction: 'Forward',
        next_step: 'Won',
        property: 'Won',
      },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex align-items-center">
        <button
          type="button"
          className="btn btn-link pd-x-0 collapsed"
          data-toggle="collapse"
          data-target="#SiteStatus"
        >
          Status
          <ChevronDown className="mg-l-5" />
        </button>
        <button
          type="button"
          className="btn btn-link mg-l-auto"
          data-toggle="modal"
          data-target="#siteButtonsModal"
        >
          <Plus className="mg-r-5" />
          Add
        </button>
      </div>
      <div id="SiteStatus" className="card collapse">
        <BasicTable columns={columns} data={data} className="table-bordered bd-0 basic-table" />
      </div>
    </>
  );
};

export default SiteStatus;
