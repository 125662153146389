import { Button } from '@base/components/form';

const RunReport = () => {
  return (
    <>
      <div className="d-flex align-items-center mg-b-10">
        <div className="dropdown">
          <Button color="link link-02" icon="ChevronDown" isIconsEnd name="All" />
        </div>
        <div className="dropdown mg-l-auto">
          <Button color="white" icon="ChevronDown" isIconsEnd name="Export as" />
        </div>
      </div>
      <div className="table-responsive bg-white bd rounded">
        <table className="table table-bordered dataTable mg-b-0 bd-0">
          <thead>
            <tr>
              <th scope="col" className="sorting_asc bd-t-0-f">
                Module
              </th>
              <th scope="col">Assigned on</th>
              <th scope="col" className="sorting bd-t-0-f">
                User/Group
              </th>
              <th scope="col" className="sorting bd-t-0-f">
                No. assigned
              </th>
              <th scope="col">%Assigned</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="sorting-cell">&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RunReport;
