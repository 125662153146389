import React, { useState, useEffect } from 'react';

import { ChevronUp, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';
// import { HanTimeRange } from 'Crm/components';

// const formatServerToClient = (time: any) => {
//   let timeNew = time ? time : '00:00';
//   if (timeNew == '00:00') {
//     return {
//       hour: '00',
//       minute: '00',
//       clock: 'AM',
//     };
//   }
//   if (timeNew == '24:00') {
//     return {
//       hour: '12',
//       minute: '00',
//       clock: 'PM',
//     };
//   }

//   let now = moment(timeNew, 'HH:mm').format('h:mm:A');
//   const [hour, minute, clock] = now.split(':');
//   return {
//     hour,
//     minute,
//     clock,
//   };
// };

// const formatClientToServer = ({ hour, minute, clock }) => {
//   if (hour == '00' && minute == '00') {
//     return '00:00';
//   }
//   let now = moment(`${hour}:${minute}:${clock}`, 'hh:mm:A').format('HH:mm');
//   return now;
// };

// function HanTimeRangeCustom({
//   value = {
//     startTime: '',
//     endTime: '',
//   },
//   onChange = () => { },
// }) {
//   const [valuePicker, setValuePicker] = useState({
//     start: {
//       hour: '',
//       minute: '',
//       clock: 'AM',
//     },
//     end: {
//       hour: '',
//       minute: '',
//       clock: 'AM',
//     },
//   });

//   useEffect(() => {
//     setValuePicker((valuePicker) => ({
//       ...valuePicker,
//       start: formatServerToClient(value.startTime ? value.startTime : '00:00'),
//       end: formatServerToClient(value.endTime ? value.endTime : '00:00'),
//     }));
//   }, [value.startTime, value.endTime]);

//   // const onHandleChange = ({ start, end }) => {
//   //   onChange({
//   //     startTime: formatClientToServer({
//   //       ...start,
//   //       hour: start.hour == 0 ? '00' : start.hour,
//   //       minute: start.minute == 0 ? '00' : start.minute,
//   //     }),
//   //     endTime: formatClientToServer({
//   //       ...end,
//   //       hour: end.hour == 0 ? '00' : end.hour,
//   //       minute: end.minute == 0 ? '00' : end.minute,
//   //     }),
//   //   });
//   // };

//   return <HanTimeRange value={valuePicker} onChange={onHandleChange} />;
// }

const NewAgenda = () => {
  const [data, setData] = useState<any>({
    startTime: '',
    endTime: '',
  });

  // const onChangeTime = ({ startTime, endTime }) => {
  //   setData((data) => ({ ...data, startTime, endTime }));
  // };

  return (
    <div
      id="newAgenda"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Agenda</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="form-group">
              <label className="form-item-title">Session Title</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Date</label>
              <DatePicker
                selected={new Date()}
                onChange={() => {
                  console.log();
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Time</label>
              <div className="input-group input-date-group dropdown">
                {/* <HanTimeRangeCustom
                  value={{
                    startTime: data.startTime,
                    endTime: data.endTime,
                  }}
                  onChange={onChangeTime}
                /> */}
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Duration</label>
              <div className="d-flex align-items-center">
                <input type="number" className="form-control wd-80 mg-r-5" />
                <SelectHook
                  className="wd-100-f"
                  options={[
                    { value: 1, label: 'min' },
                    { value: 2, label: 'hr' },
                  ]}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Speaker</label>
              <div className="input-group">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    {Icon('m_customer')}
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Session Type</label>
              <SelectHook
                options={[
                  { value: 1, label: 'Training' },
                  { value: 2, label: 'General' },
                  { value: 3, label: 'Education' },
                ]}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Location</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Building</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Room</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
                <button type="button" className="dropdown-item">
                  Save and Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAgenda;
