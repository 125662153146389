import { useState } from 'react';

import { X, ChevronDown, Plus, UploadCloud, Check } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const NewOffer = () => {
  const [showFolderInput, setShowFolderInput] = useState(false);

  return (
    <div
      id="newOfferDownloadFileModal"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">New Offer Download File</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">File Upload</label>
              <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
                <UploadCloud size="20" className="mg-r-10" />
                Drag &amp; Drop Files
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Folder</label>
              <div className="dropdown wd-300">
                <div className="input-group" data-toggle="dropdown">
                  <input type="text" className="form-control" placeholder="Select a folder" />
                  <div className="input-group-append">
                    <button type="button" className="btn">
                      <ChevronDown />
                    </button>
                  </div>
                </div>
                <div className="dropdown-menu wd-100p">
                  {!showFolderInput && (
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => setShowFolderInput(true)}
                    >
                      <Plus className="mg-r-5" />
                      Add new folder
                    </button>
                  )}
                  {showFolderInput && (
                    <div className="d-flex align-items-center pd-l-20 pd-r-10">
                      <input type="text" className="form-control" />
                      <button type="button" className="btn btn-sm btn-icon">
                        <Check className="tx-success" />
                        <span className="sr-only">추가</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-link link-03 btn-icon"
                        onClick={() => setShowFolderInput(false)}
                      >
                        <X />
                        <span className="sr-only">취소</span>
                      </button>
                    </div>
                  )}
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item">General</button>
                  <button className="dropdown-item">Offer</button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">File Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Content Type</label>
              <SelectHook className="wd-300-f" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Language</label>
              <SelectHook className="wd-200-f" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Downloadable</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="offerFileDownloadableSwitch"
                />
                <label
                  className="custom-control-label"
                  htmlFor="offerFileDownloadableSwitch"
                ></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">File Size</label>
              <div>&nbsp;</div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOffer;
