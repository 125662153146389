import { X, Search } from 'react-feather';

const KbList = () => {
  return (
    <>
      <div
        id="KBlistModal"
        className="modal over-modal fade right"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title">Add Knowledge Base</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body pd-0-f overflow-hidden">
              <div className="d-flex flex-column ht-100p overflow-hidden">
                <div className="input-group bd-b">
                  <input type="text" className="form-control form-control-lg rounded-0 bd-0" />
                  <div className="input-group-append">
                    <span className="input-group-text bd-0">
                      <Search />
                    </span>
                  </div>
                </div>
                <div className="row no-gutters flex-grow-1 overflow-hidden">
                  <div className="col-lg-4 ht-100p overflow-auto bd-lg-r">
                    <div className="pd-10">Tree</div>
                  </div>
                  <div className="col-lg-8 ht-100p overflow-auto">
                    <div className="pd-10">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="kbCheckAll" />
                        <label className="custom-control-label" htmlFor="kbCheckAll">
                          All
                        </label>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="kbCheck1" />
                          <label className="custom-control-label" htmlFor="kbCheck1">
                            How to assess admin page?
                          </label>
                        </div>
                        <div className="mg-l-auto">
                          <span className="tag-item">postmaster</span>
                          <span className="tag-item">access</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-success" data-dismiss="modal">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KbList;
