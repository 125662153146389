import { Edit2, UploadCloud, X } from 'react-feather';

const NewProductImage = () => {
  return (
    <div
      id="productImageModal"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Product Image</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title d-block">Main Image</label>
              <div className="product-img-wrap">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxk47d99qf_o3faJPuqsQ7sFrwfCIBhrd-VA&amp;usqp=CAU"
                  className="rounded"
                  alt=""
                />
                <div className="product-img-actions">
                  <button
                    type="button"
                    data-han-tooltip="Edit"
                    className="btn han-tooltip han-tooltip--top"
                  >
                    <Edit2 />
                  </button>
                  <button
                    type="button"
                    data-han-tooltip="Delete"
                    className="btn han-tooltip han-tooltip--top"
                  >
                    <X />
                  </button>
                </div>
              </div>

              {/* no image
                        <button type="button" className="btn btn-upload-pic">
                            <UploadCloud />
                            <div>Drag &amp; drop<br />or<br />Browse</div>
                        </button> */}
            </div>
            <div className="form-group">
              <label className="form-item-title d-block">Additional Image</label>
              <ul className="product-img-upload-list">
                <li>
                  <div className="product-img-wrap">
                    <img
                      src="https://d2d8wwwkmhfcva.cloudfront.net/400x/d2lnr5mha7bycj.cloudfront.net/product-image/file/large_1df3f7ef-8e7d-4596-9535-3eed0811a47b.jpg"
                      className="rounded"
                      alt=""
                    />
                    <div className="product-img-actions">
                      <button
                        type="button"
                        data-han-tooltip="Edit"
                        className="btn han-tooltip han-tooltip--top"
                      >
                        <Edit2 />
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Delete"
                        className="btn han-tooltip han-tooltip--top"
                      >
                        <X />
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="product-img-wrap">
                    <img
                      src="https://d2d8wwwkmhfcva.cloudfront.net/400x/d2lnr5mha7bycj.cloudfront.net/product-image/file/large_8875b734-de1b-461d-b7cf-569823a0e7ef.png"
                      className="rounded"
                      alt=""
                    />
                    <div className="product-img-actions">
                      <button
                        type="button"
                        data-han-tooltip="Edit"
                        className="btn han-tooltip han-tooltip--top"
                      >
                        <Edit2 />
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Delete"
                        className="btn han-tooltip han-tooltip--top"
                      >
                        <X />
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <button type="button" className="btn btn-upload-pic">
                    <UploadCloud />
                    <div>
                      Drag &amp; drop
                      <br />
                      or
                      <br />
                      Browse
                    </div>
                  </button>
                </li>
              </ul>
              <div className="tx-orange">You can change the order by drag and drop.</div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductImage;
