/* eslint-disable prettier/prettier */
import { Button } from '@base/components/form';
import classNames from 'classnames';
import { useState } from 'react';
import { ArrowLeft } from 'react-feather';
import './diagram-new.scss';

const JourneyProcessView = () => {
  const TABS = [
    { value: 1, label: 'Build' },
    { value: 2, label: 'General' },
    { value: 3, label: 'Insight' },
    { value: 4, label: 'Related' },
  ];
  const [tab, setTab] = useState(TABS[0]);
  return (
    <>
      <div className="d-flex flex-column ht-100p">
        <div className="process-header">
          <a
            href="/settings/desktop/theme1/journey-process"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
          </a>
          <h1 className="process-name pd-x-15 bd-l">Process 1</h1>
        </div>
        <ul className="nav nav-line bg-white bd-1">
          {TABS.map((item, index) => (
            <li key={index} className="nav-item">
              <Button
                color=""
                className={classNames('nav-link pd-15-f', {
                  active: item.value == tab.value,
                })}
                name={item.label}
                onClick={() => {
                  setTab(item);
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default JourneyProcessView;
