import { ChevronUp, Image, Settings, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const NewAccount = () => {
  return (
    <div id="newAccount" className="modal fade">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="h6 modal-title">Creat Account</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body customer-profile-reg">
            <div className="d-flex align-items-start">
              <div className="d-flex align-items-center justify-content-center">
                <button className="d-flex flex-column align-items-center justify-content-center wd-150 ht-150 bd bd-dashed rounded bg-light tx-center tx-color-03">
                  <Image size="40" strokeWidth="1" />
                  Drag &amp; Drop or Browse
                </button>
              </div>
              <div className="flex-grow-1 mg-l-15">
                <div className="form-group">
                  <label className="form-item-title">ID</label>
                  <div className="input-group">
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                      <button type="button" className="btn">
                        <Settings />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Customer Type</label>
                  <SelectHook
                    options={[
                      { value: 1, label: 'Customer' },
                      { value: 2, label: 'Potential' },
                      { value: 3, label: 'Sales Agent' },
                      { value: 4, label: 'Vendor' },
                      { value: 5, label: 'Partner' },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Industry</label>
              <SelectHook className="wd-300-f" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Email</label>
              <input type="email" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Telephone</label>
              <input type="tel" className="form-control" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAccount;
