import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  ChevronsLeft,
  ArrowLeft,
  Plus,
  MoreHorizontal,
  Copy,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import 'CrmCss/desktop/theme1/theme1.scss';
// import 'CustomerCss/customer.scss';

import Members from './components/members';
import NewSegment from './write';

const TABS = [
  {
    value: 1,
    label: 'Criteria',
  },
  {
    value: 2,
    label: 'Members',
  },
  {
    value: 3,
    label: 'Insight',
  },
  {
    value: 4,
    label: 'Related',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const View = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Segment
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10 lh-1">Segment 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="dropdown">
              <button
                type="button"
                data-han-tooltip="More"
                className="btn btn-white btn-icon rounded-circle han-tooltip"
                data-toggle="dropdown"
              >
                <span className="span-icon">
                  <MoreHorizontal />
                </span>
                <span className="sr-only">더보기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Copy />
                  </span>
                  Clone
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newSegment"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            {/* Summary */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <span className="view-aside-section-header-title">Summary</span>
              </div>
              <div id="basicInfoSection" className="view-aside-section-body collapse show">
                <div className="form-group">
                  <label className="form-item-title">Segment Type</label>
                  <div>Static</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Active</label>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="segmentActiveSwitch"
                    />
                    <label className="custom-control-label" htmlFor="segmentActiveSwitch"></label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Owner</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>MSR</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Created on</label>
                  <div>2021/09/01</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                  { hidden: hideAsideLeft },
                )}
                onClick={() => setHideAsideLeft(!hideAsideLeft)}
              >
                <ChevronsLeft />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>

              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map((item, index) => (
                    <li
                      key={index}
                      className={classnames('nav-item', {
                        active: item.value == tab.value,
                      })}
                    >
                      <button
                        type="button"
                        className="btn btn-xs btn-move-tab"
                        aria-label="Drag-and-drop to reorder"
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <button
                        type="button"
                        className="btn nav-link"
                        onClick={(e) => {
                          setTab(item);
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* Criteria */}
              {tab.value == 1 && (
                <div className="pd-15">
                  <div className="pd-15 bg-white bd-3 bd-primary bd-l rounded shadow-sm">
                    <div className="d-flex align-items-center mg-b-10">
                      <span className="tx-semibold">Contact</span>
                    </div>
                    <div className="d-flex flex-wrap">
                      <div className="flex-grow-1">
                        <ul className="criteria-list view-criteria-list">
                          <li>Birthday Equals any day July any year</li>
                          <li>
                            <span className="d-inline-block mg-r-5 pd-x-10 pd-y-3 bg-light rounded-30">
                              And
                            </span>
                            Gender Is in Male
                          </li>
                          <li>
                            <span className="d-inline-block mg-r-5 pd-x-10 pd-y-3 bg-light rounded-30">
                              And
                            </span>
                            Status Is in Active
                          </li>
                        </ul>
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center wd-150 mg-l-10 pd-y-10 bg-light rounded">
                        <strong className="tx-20">150</strong>members
                      </div>
                    </div>
                  </div>
                  <div className="add-criteria-block">
                    <span className="d-inline-block mg-r-5 pd-x-10 pd-y-5 bg-white bd rounded-30">
                      And
                    </span>
                  </div>
                  <div className="pd-15 bg-white bd-3 bd-indigo bd-l rounded shadow-sm">
                    <div className="d-flex align-items-center mg-b-10">
                      <span className="tx-semibold">Account</span>
                    </div>
                    <div className="d-flex flex-wrap">
                      <div className="flex-grow-1">
                        <ul className="criteria-list view-criteria-list">
                          <li>Annual Revenue Is grater than ＄1,000,000</li>
                        </ul>
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center wd-150 mg-l-10 pd-y-10 bg-light rounded">
                        <strong className="tx-20">200</strong>members
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mg-t-20">
                    <div className="mg-l-auto pd-t-5 bd-2 bd-t bd-success tx-success">
                      Final<strong className="mg-x-10 tx-24">100</strong>members
                    </div>
                  </div>
                </div>
              )}

              {/* Members */}
              {tab.value == 2 && (
                <div className="pd-15">
                  <Members />
                </div>
              )}

              {/* Insight */}
              {tab.value == 3 && <div className="pd-15"></div>}

              {/* Related */}
              {tab.value == 4 && (
                <div className="pd-15">
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Campaign</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#ctaRelated3"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="ctaRelated3" className="card collapse show">
                      <div className="table-responsive rounded">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Campaign Name
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Activity
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Owner
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Start date
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                End date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <NewSegment />
    </>
  );
};

export default View;
