import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ArrowLeft,
  Repeat,
  MoreHorizontal,
  Copy,
  Edit2,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
import '../style.scss';

import Attachments from '../../../../component/desktop/attachments';
import AssignedRep from '../../../../component/desktop/assigned-rep';
import Userlist from '../../../../component/desktop/userlist';
import Notelist from '../../../../component/desktop/notelist';
import Timeline from '../timeline';

import ViewBasicInfo from './view-new/basicInfo';
import ViewDetailInfo from './view-new/detailInfo';
import ViewPricing from './view-new/pricingList';
import ViewAssoiatedItems from './view-new/assoiatedItems';
import ViewTransaction from '../view-new/transaction';
import ViewStockMovement from '../view-new/stockMovement';
import ViewInventory from './view-new/inventory';
import ViewSubscription from './view-new/subscription';
import ViewPurchase from '../view-new/purchase';
import AdjustmentStock from './modal/adjustment';
import TransferOrder from './modal/transfer-order';

import NewItem from '../modal/newItem';
import NewAttribute from '../modal/newProductItemAttribute';
import NewDetailCost from '../modal/newProducDetailCost';
import NewProductImg from '../modal/newProductImage';

const TABS = [
  {
    value: 1,
    label: 'Detail',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Transaction',
  },
  {
    value: 4,
    label: 'Assoiated Items',
  },
  {
    value: 5,
    label: 'Stock Movement',
  },
  {
    value: 6,
    label: 'Subscription',
  },
  {
    value: 7,
    label: 'Inventory',
  },
  {
    value: 8,
    label: 'Purchase',
  },
  {
    value: 9,
    label: 'Note',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className="view-header">
          <a
            href="/crm/demo-page/ui/customer/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Items
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub"></div>
          </div>
          <h1 className="view-title">Juice-Mint/L</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Copy />
                    </span>
                    Clone
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#adjustStockModal"
                  >
                    <span className="dropdown-item-icon">{Icon('adjust_stock')}</span>Adjust Stock
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#transferOrderModal"
                  >
                    <span className="dropdown-item-icon">
                      <Repeat />
                    </span>
                    Transfer Order
                  </button>
                </div>
              </div>
            </div>
            <div className="btn-group dropdown mg-l-5">
              <button
                className="btn btn-primary pd-x-10"
                data-toggle="modal"
                data-target="#newItem"
              >
                <Plus />
                <span className="sr-only">추가</span>
              </button>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <ChevronDown />
                <span className="sr-only">추가 옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a href="/crm/demo-page/ui/product/desktop/theme1/write" className="dropdown-item">
                  Product
                </a>
                <a href="#newItem" className="dropdown-item" data-toggle="modal">
                  Item
                </a>
                <a href="" className="dropdown-item">
                  Component
                </a>
                <a href="" className="dropdown-item">
                  Unit
                </a>
                <a href="" className="dropdown-item">
                  Price List
                </a>
                <a href="" className="dropdown-item">
                  Discount
                </a>
                <a href="" className="dropdown-item">
                  Trade Agreement
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}
              <div className="view-aside-section pd-20">
                <div className="mg-b-10 text-right">
                  <button
                    type="button"
                    className="btn btn-link pd-y-0"
                    data-toggle="modal"
                    data-target="#productImageModal"
                  >
                    <Edit2 className="mg-r-5" />
                    Edit
                  </button>
                </div>
                <div className="view-product-pic mg-b-20">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxk47d99qf_o3faJPuqsQ7sFrwfCIBhrd-VA&usqp=CAU"
                    className="rounded"
                    alt=""
                  />
                </div>
                <div className="view-product-pic-thumb-list-wrap mg-b-20">
                  <button type="button" className="btn btn-xs btn-white btn-icon">
                    <ChevronLeft />
                    <span className="sr-only">이전</span>
                  </button>
                  <ul className="view-product-pic-thumb-list">
                    <li className="view-product-pic-thumb-list-item active">
                      <button type="button" className="view-product-pic-thumb">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxk47d99qf_o3faJPuqsQ7sFrwfCIBhrd-VA&usqp=CAU"
                          className="rounded"
                          alt=""
                        />
                      </button>
                    </li>
                    <li className="view-product-pic-thumb-list-item">
                      <button type="button" className="view-product-pic-thumb">
                        <img
                          src="https://d2d8wwwkmhfcva.cloudfront.net/400x/d2lnr5mha7bycj.cloudfront.net/product-image/file/large_1df3f7ef-8e7d-4596-9535-3eed0811a47b.jpg"
                          className="rounded"
                          alt=""
                        />
                      </button>
                    </li>
                    <li className="view-product-pic-thumb-list-item">
                      <button type="button" className="view-product-pic-thumb">
                        <img
                          src="https://d2d8wwwkmhfcva.cloudfront.net/400x/d2lnr5mha7bycj.cloudfront.net/product-image/file/large_8875b734-de1b-461d-b7cf-569823a0e7ef.png"
                          className="rounded"
                          alt=""
                        />
                      </button>
                    </li>
                  </ul>
                  <button type="button" className="btn btn-xs btn-white btn-icon">
                    <ChevronRight />
                    <span className="sr-only">다음</span>
                  </button>
                </div>

                <div className="d-flex pd-10">
                  <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">Base Unit</div>
                  <div className="mg-l-auto tx-15 tx-semibold">Bottle</div>
                </div>
                <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                  <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">Unit Name</div>
                  <div className="mg-l-auto tx-15 tx-semibold">Bottle</div>
                </div>
                <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                  <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                    Unit Quantity
                  </div>
                  <div className="mg-l-auto tx-15 tx-semibold">1</div>
                </div>
                <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                  <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-orange">Base Price</div>
                  <div className="mg-l-auto tx-15 tx-semibold">￦4,000</div>
                </div>
                <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                  <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-info">
                    Base Cost Price
                  </div>
                  <div className="mg-l-auto tx-15 tx-semibold">￦2,000</div>
                </div>
                <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                  <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-danger">Best Price</div>
                  <div className="mg-l-auto tx-15 tx-semibold">￦3,000</div>
                </div>
              </div>

              {/* Basic Info */}
              <ViewBasicInfo />
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show right aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && <ViewDetailInfo />}
              {tab.value == 2 && <Timeline />}
              {tab.value == 3 && <ViewTransaction />}
              {tab.value == 4 && <ViewAssoiatedItems />}
              {tab.value == 5 && <ViewStockMovement />}
              {tab.value == 6 && <ViewSubscription />}
              {tab.value == 7 && <ViewInventory />}
              {tab.value == 8 && <ViewPurchase />}
              {tab.value == 9 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="vies-aside-inner scroll-box">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}
              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Pricing */}
              <ViewPricing />

              {/* Customer */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Related Customer</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerContact"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerContact" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <AdjustmentStock />
      <TransferOrder />

      <NewItem />
      <NewAttribute />
      <NewDetailCost />
      <NewProductImg />
    </>
  );
};

export default ViewNew;
