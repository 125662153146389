/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import { Check, Plus } from 'react-feather';
import { Button, Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import '@base/components/sidebar-menu/styles.scss';

import RatingGradeLangInput from './modal/ratingGradeLangInput';
import RatingCustomerList from './modal/ratingCustomerList';

const NAVS = [
  {
    value: '1',
    label: 'Rating Grades',
  },
  {
    value: '2',
    label: 'Set Conditions',
  },
  {
    value: '3',
    label: 'Confirm Data',
  },
  {
    value: '4',
    label: 'Apply Values',
  },
];

const RatingRules = () => {
  const [nav, setNav] = useState(NAVS[0]);
  const [hideSideNav, setSideNav] = useState(false);

  return (
    <div className={classnames('page-container-wrap', { 'hide-sidebar': hideSideNav })}>
      <div className="page-menu-wrap">
        <div className="page-menu-nav-sidebar">
          <ul className="page-menu-sidebar-nav">
            {NAVS.map((item, index) => (
              <li className="page-menu-nav-item" key={index}>
                <button
                  className={classnames('btn page-menu-nav-link', {
                    active: item.value == nav.value,
                  })}
                  onClick={(e) => {
                    setNav(item);
                  }}
                >
                  {Icon(item.value)}
                  <span className="mg-l-5">{item.label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="page-container-content">
        <div className="page-container-content-header">
          <Button
            color=""
            className="btn-toggle-sidebar tx-gray-400"
            icon="ChevronLeft"
            onClick={() => setSideNav(!hideSideNav)}
          />
          <h1 className="mg-x-10 mg-b-0 tx-16 tx-uppercase tx-normal">
            Rating Rule &gt; {nav.label}
          </h1>
        </div>
        <div className="scroll-box ht-100v pd-20">
          {nav.value === '1' && (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Name
                      </th>
                      <th scope="col" className="wd-80 bd-t-0-f">
                        Color
                      </th>
                      <th scope="col" className="wd-100 bd-t-0-f">
                        Score
                      </th>
                      <th scope="col" className="wd-100 bd-t-0-f">
                        Language
                      </th>
                      <th scope="col" className="wd-80 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="text" className="form-control" defaultValue="Bronze" />
                      </td>
                      <td>
                        {/* color picker */}
                        <button
                          type="button"
                          className="btn wd-35 ht-35 pd-0 bd-0 rounded-circle"
                          style={{ backgroundColor: '#9c7255' }}
                        >
                          <span className="tx-white tx-semibold">B</span>
                        </button>
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link pd-y-0"
                          data-toggle="modal"
                          data-target="#gradeLangInputModal"
                        >
                          Add
                        </button>
                      </td>
                      <td>
                        <Button color="link" className="btn-icon" icon="X" iconClass="tx-danger" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input type="text" className="form-control" defaultValue="Silver" />
                      </td>
                      <td>
                        {/* color picker */}
                        <button
                          type="button"
                          className="btn wd-35 ht-35 pd-0 bd-0 rounded-circle"
                          style={{ backgroundColor: '#a5a5a5' }}
                        >
                          <span className="tx-white tx-semibold">S</span>
                        </button>
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="20" />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link pd-y-0"
                          data-toggle="modal"
                          data-target="#gradeLangInputModal"
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <Button color="link" className="btn-icon" icon="X" iconClass="tx-danger" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input type="text" className="form-control" defaultValue="Gold" />
                      </td>
                      <td>
                        {/* color picker */}
                        <button
                          type="button"
                          className="btn wd-35 ht-35 pd-0 bd-0 rounded-circle"
                          style={{ backgroundColor: '#bc9546' }}
                        >
                          <span className="tx-white tx-semibold">G</span>
                        </button>
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="45" />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link pd-y-0"
                          data-toggle="modal"
                          data-target="#gradeLangInputModal"
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <Button color="link" className="btn-icon" icon="X" iconClass="tx-danger" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input type="text" className="form-control" defaultValue="VIP" />
                      </td>
                      <td>
                        {/* color picker */}
                        <button
                          type="button"
                          className="btn wd-35 ht-35 pd-0 bd-0 rounded-circle"
                          style={{ backgroundColor: '#e34c0b' }}
                        >
                          <span className="tx-white tx-semibold">V</span>
                        </button>
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="80" />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link pd-y-0"
                          data-toggle="modal"
                          data-target="#gradeLangInputModal"
                        >
                          Add
                        </button>
                      </td>
                      <td>
                        <Button color="link" className="btn-icon" icon="X" iconClass="tx-danger" />
                      </td>
                    </tr>
                    {/* new grade */}
                    <tr>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>
                        {/* color picker */}
                        <button
                          type="button"
                          className="btn wd-35 ht-35 pd-0 bd-0 rounded-circle bg-gray-300"
                        >
                          <span className="tx-white tx-semibold"></span>
                        </button>
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link pd-y-0"
                          data-toggle="modal"
                          data-target="#gradeLangInputModal"
                        >
                          Add
                        </button>
                      </td>
                      <td>
                        <Button color="link" className="btn-icon" icon="X" iconClass="tx-danger" />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control mg-r-5"
                            placeholder="Enter the grade name"
                          />
                          <button type="button" className="btn btn-primary flex-shrink-0">
                            <Plus className="mg-r-5" />
                            Add
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="card-footer d-flex">
                <button type="button" className="btn btn-success mg-l-auto">
                  Save
                </button>
              </div>
            </div>
          )}
          {nav.value === '2' && (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Condition
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Unit
                      </th>
                      <th scope="col" className="wd-150 bd-t-0-f">
                        Point Value (100)
                      </th>
                      <th scope="col" className="wd-80 bd-t-0-f">
                        Use
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Monthly Payment</td>
                      <td>
                        <SelectHook />
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="gradeConditionUseSwitch1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="gradeConditionUseSwitch1"
                          ></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <SelectHook
                            className="wd-150-f mg-r-5"
                            options={[{ value: 1, label: 'Past 6 months' }]}
                          />
                          Products Purchased
                        </div>
                      </td>
                      <td>
                        <SelectHook />
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="gradeConditionUseSwitch2"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="gradeConditionUseSwitch2"
                          ></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Length of Business Relationship</td>
                      <td>
                        <SelectHook />
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="gradeConditionUseSwitch3"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="gradeConditionUseSwitch3"
                          ></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Transactions</td>
                      <td>
                        <SelectHook />
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="gradeConditionUseSwitch4"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="gradeConditionUseSwitch4"
                          ></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <SelectHook
                            className="wd-100-f mg-r-5"
                            options={[{ value: 1, label: '2010' }]}
                          />
                          <span className="mg-r-5">~</span>
                          <SelectHook
                            className="wd-100-f mg-r-5"
                            options={[{ value: 1, label: '2015' }]}
                          />
                          Yearly Payment
                        </div>
                      </td>
                      <td>
                        <SelectHook />
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="gradeConditionUseSwitch5"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="gradeConditionUseSwitch5"
                          ></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <SelectHook
                            className="wd-100-f mg-r-5"
                            options={[{ value: 1, label: '2021' }]}
                          />
                          <span className="mg-r-5">~</span>
                          <SelectHook
                            className="wd-100-f mg-r-5"
                            options={[{ value: 1, label: '2021' }]}
                          />
                          Yearly Profit
                        </div>
                      </td>
                      <td>
                        <SelectHook />
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="gradeConditionUseSwitch6"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="gradeConditionUseSwitch6"
                          ></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Payments (Past)</td>
                      <td>
                        <SelectHook />
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="gradeConditionUseSwitch7"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="gradeConditionUseSwitch7"
                          ></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Profit (Past)</td>
                      <td>
                        <SelectHook />
                      </td>
                      <td>
                        <input type="number" className="form-control" defaultValue="0" />
                      </td>
                      <td>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="gradeConditionUseSwitch8"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="gradeConditionUseSwitch8"
                          ></label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="card-footer d-flex">
                <button type="button" className="btn btn-success mg-l-auto">
                  Save
                </button>
              </div>
            </div>
          )}
          {nav.value === '3' && (
            <div className="table-responsive-sm bd rounded bg-white">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Condition
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Applicable Customers
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Non-Applicable Customers
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Monthly Payment</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link pd-0"
                        data-toggle="modal"
                        data-target="#ratingCustomerListModal"
                      >
                        804
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link pd-0"
                        data-toggle="modal"
                        data-target="#ratingCustomerListModal"
                      >
                        24,009
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Length of Business Relationship</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link pd-0"
                        data-toggle="modal"
                        data-target="#ratingCustomerListModal"
                      >
                        24,813
                      </button>
                    </td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {nav.value === '4' && (
            <>
              <div className="card mg-b-10">
                <div className="card-header">Set Rating by Condition</div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0">
                    <tbody>
                      <tr>
                        <td rowSpan={2}>Monthly Payment [30]</td>
                        <td colSpan={4}>
                          {/* This is a sample. please use the slider you're using */}
                          <input type="range" className="form-control-range" />
                        </td>
                        <td rowSpan={3}>
                          <button type="button" className="btn">
                            <Check className="tx-success" />
                            <span className="sr-only">적용</span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="input-group wd-250">
                            <div className="d-flex align-items-center form-control">
                              <input type="number" className="wd-100p bd-0" />~
                              <input type="number" className="wd-100p bd-0" />
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text lh-1">Won</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-group wd-250">
                            <div className="d-flex align-items-center form-control">
                              <input type="number" className="wd-100p bd-0" />~
                              <input type="number" className="wd-100p bd-0" />
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text lh-1">Won</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-group wd-250">
                            <div className="d-flex align-items-center form-control">
                              <input type="number" className="wd-100p bd-0" />~
                              <input type="number" className="wd-100p bd-0" />
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text lh-1">Won</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-group wd-250">
                            <div className="d-flex align-items-center form-control">
                              <input type="number" className="wd-100p bd-0" />~
                              <input type="number" className="wd-100p bd-0" />
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text lh-1">Won</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <th scope="row">Range</th>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-y-0"
                            data-toggle="modal"
                            data-target="#ratingCustomerListModal"
                          >
                            10
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-y-0"
                            data-toggle="modal"
                            data-target="#ratingCustomerListModal"
                          >
                            10
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-y-0"
                            data-toggle="modal"
                            data-target="#ratingCustomerListModal"
                          >
                            10
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-y-0"
                            data-toggle="modal"
                            data-target="#ratingCustomerListModal"
                          >
                            10
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>Length of Business Relationship [30]</td>
                        <td colSpan={4}>
                          {/* This is a sample. please use the slider you're using */}
                          <input type="range" className="form-control-range" />
                        </td>
                        <td rowSpan={3}>
                          <button type="button" className="btn">
                            <Check className="tx-success" />
                            <span className="sr-only">적용</span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="input-group wd-250">
                            <div className="d-flex align-items-center form-control">
                              <input type="number" className="wd-100p bd-0" />~
                              <input type="number" className="wd-100p bd-0" />
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text lh-1">Won</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-group wd-250">
                            <div className="d-flex align-items-center form-control">
                              <input type="number" className="wd-100p bd-0" />~
                              <input type="number" className="wd-100p bd-0" />
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text lh-1">Won</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-group wd-250">
                            <div className="d-flex align-items-center form-control">
                              <input type="number" className="wd-100p bd-0" />~
                              <input type="number" className="wd-100p bd-0" />
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text lh-1">Won</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-group wd-250">
                            <div className="d-flex align-items-center form-control">
                              <input type="number" className="wd-100p bd-0" />~
                              <input type="number" className="wd-100p bd-0" />
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text lh-1">Won</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <th scope="row">Range</th>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-y-0"
                            data-toggle="modal"
                            data-target="#ratingCustomerListModal"
                          >
                            10
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-y-0"
                            data-toggle="modal"
                            data-target="#ratingCustomerListModal"
                          >
                            10
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-y-0"
                            data-toggle="modal"
                            data-target="#ratingCustomerListModal"
                          >
                            10
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link pd-y-0"
                            data-toggle="modal"
                            data-target="#ratingCustomerListModal"
                          >
                            10
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card-footer d-flex">
                  <button type="button" className="btn btn-white mg-l-auto">
                    Reset
                  </button>
                  <button type="button" className="btn btn-success mg-l-5">
                    Save
                  </button>
                </div>
              </div>
              <div className="card">
                <div className="card-header">Set Points Per Grade</div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0">
                    <tbody>
                      <tr>
                        <th scope="row" className="bd-t-0-f">
                          Name
                        </th>
                        <td className="bd-t-0-f">Bronze</td>
                        <td className="bd-t-0-f">Silver</td>
                        <td className="bd-t-0-f">Gold</td>
                        <td className="bd-t-0-f">Vip</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <th scope="row" rowSpan={2}>
                          Score
                        </th>
                        <td colSpan={4}>
                          {/* This is a sample. please use the slider you're using */}
                          <input type="range" className="form-control-range" />
                        </td>
                        <td rowSpan={2}>
                          <button type="button" className="btn">
                            <Check className="tx-success" />
                            <span className="sr-only">적용</span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="number" className="form-control" />
                        </td>
                        <td>
                          <input type="number" className="form-control" />
                        </td>
                        <td>
                          <input type="number" className="form-control" />
                        </td>
                        <td>
                          <input type="number" className="form-control" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card-footer d-flex">
                  <button type="button" className="btn btn-success mg-l-auto">
                    Apply
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <RatingGradeLangInput />
        <RatingCustomerList />
      </div>
    </div>
  );
};

export default RatingRules;
