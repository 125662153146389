import { Settings } from 'react-feather';
import { Button, FormIcon, Input, Select } from '@base/components/form';

import './style.scss';
import { DatePicker } from '@base/components/date-picker';
import ProductDetail from './containers/product-detail/write';
import Delivery from './containers/delivery/write';

const RFQWrite = () => {
  return (
    <div>
      <div className="text-right">
        <span className="tx-danger">*</span>is required.
      </div>
      <div className="form-row">
        <div className="form-group col-6">
          <label className="form-item-title">
            RFQ ID<span className="tx-danger">*</span>
          </label>
          <Input rightIcon={<Settings />} />
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">
            Vendor Name<span className="tx-danger">*</span>
          </label>
          <div className="dropdown">
            <div className="input-group" data-toggle="dropdown">
              <Input placeholder="Select Vendor" />
              <div className="input-group-append">
                <Button color="" className="input-group-btn" icon="m_customer" iconType="main" />
              </div>
            </div>
            <div className="dropdown-menu wd-100p">
              <Button
                color=""
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newVendor"
                name="Add New Vendor"
                icon="Plus"
              />
            </div>
          </div>
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">
            Contact<span className="tx-danger">*</span>
          </label>
          <Input rightIcon={<FormIcon icon="m_customer" iconType="main" />} />
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">
            Valid Until<span className="tx-danger">*</span>
          </label>
          <div className="wd-150">
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">Payment Terms</label>
          <Select
            addLabel="Configure Terms"
            defaultValue={[{ value: 1, label: 'Due end of the month' }]}
          />
        </div>
        <div className="form-group col-6">
          <label className="form-item-title">Expected Delivery Date</label>
          <div className="wd-150">
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
        </div>
        <Delivery />
        <div className="col-lg-6 form-group">
          <label className="form-item-title">Description</label>
          <Input />
        </div>
        <ProductDetail />
        <div className="col-6 form-group">
          <label className="form-item-title">Terms and conditions</label>
          <Select defaultValue={[{ value: 1, label: 'Due on Receipt' }]} />
        </div>
        <div className="col-6 form-group">
          <label className="form-item-title">Vendor Note</label>
          <Input />
        </div>
      </div>
    </div>
  );
};

export default RFQWrite;
