import { Button, Checkbox, Input, Select, Switch } from '@base/components/form';
import { useState } from 'react';
import { ChevronUp } from 'react-feather';
const NewJourneyProcess = () => {
  const TYPES = [
    { value: 1, label: 'Single Campaign' },
    { value: 2, label: 'Transactional' },
    { value: 3, label: 'Lead Nurturing' },
    { value: 4, label: 'ABM Nurturing' },
    { value: 5, label: 'Drip Marketing' },
  ];
  const [type, setType] = useState(TYPES);
  const [selectedType, setSelectedType] = useState(TYPES[0]);
  const handleTypeChange = (newOption: any) => {
    setSelectedType(newOption);
  };

  return (
    <div id="newJourneyProcess" className="modal right fade">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Creat Journey Process</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="form-group">
              <label>Journey Process Name</label>
              <Input type="text" />
            </div>
            <div className="form-group">
              <label>Type</label>
              <Select options={type} value={selectedType} onChange={handleTypeChange} />
            </div>
            {selectedType.value !== 1 && selectedType.value !== 2 && (
              <>
                <div className="form-group">
                  <label>Lead Scoring</label>
                  <Switch />
                </div>
                <div className="form-group">
                  <label>Scoring Rule Name</label>
                  <Select />
                </div>
              </>
            )}
            <div className="form-group">
              <label>Description</label>
              <Input type="text" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewJourneyProcess;
