import { useState } from 'react';

import { Button, Input, Select, Select as SelectHook } from '@base/components/form';
import { Plus, Minus } from 'react-feather';
import { UserAutoComplete } from '@base/containers';

const ActOrganicLink = (props: any) => {
  const [addSite, setAddSite] = useState<any>();
  return (
    <>
      {props.value == 1 && (
        <div className="d-flex overflow-auto">
          <div className="flex-grow-1">
            <div className="pd-10 bg-light bd-b bd-r text-nowrap">Paid Search/Site</div>
            <div className="mg-t-30 pd-10">
              <SelectHook className="wd-150-f mg-b-10" options={[{ value: 1, label: 'Naver' }]} />
              {addSite && (
                <div className="d-flex align-items-center mg-b-10">
                  <SelectHook className="wd-150-f mg-r-10" />
                  <button type="button" className="btn btn-icon" onClick={() => setAddSite(false)}>
                    <Minus className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              )}
              <button type="button" className="btn btn-link" onClick={() => setAddSite(true)}>
                <Plus className="mg-r-5" />
                Add
              </button>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="pd-10 bg-light bd-b bd-r text-nowrap">Destination Page</div>
            <div className="d-flex flex-column align-items-center mg-t-40">
              <button
                type="button"
                className="btn btn-outline-primary btn-icon mg-b-10"
                data-toggle="modal"
                data-target="#destinationPage"
              >
                <Plus />
                <span className="Create Shorten URL"></span>
              </button>
              {addSite && (
                <button
                  type="button"
                  className="btn btn-outline-primary btn-icon"
                  data-toggle="modal"
                  data-target="#destinationPage"
                >
                  <Plus />
                  <span className="Create Shorten URL"></span>
                </button>
              )}
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="pd-10 bg-light bd-b bd-r text-nowrap">Consideration</div>
            <div className="d-flex">
              <div>
                <div className="pd-10 text-nowrap">CTA</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">CTA Clicked</div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-icon"
                    data-toggle="modal"
                    data-target="#selectCTA"
                  >
                    <Plus />
                  </button>
                </div>
              </div>
              <div>
                <div className="pd-10 text-nowrap">Lead Capture</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Created</div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-icon"
                    data-toggle="modal"
                    data-target="#newLeadCapture"
                  >
                    <Plus />
                  </button>
                </div>
              </div>
              <div>
                <div className="pd-10 text-nowrap">MQL</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </div>
              <div>
                <div className="pd-10 text-nowrap">SQL</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">&nbsp;</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch2"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch2"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="pd-10 bg-light bd-b bd-r text-nowrap">Sales Conversion</div>
            <div className="d-flex">
              <div>
                <div className="pd-10 text-nowrap">Opportunity</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Opportunity Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </div>
              <div>
                <div className="pd-10 text-nowrap">Sales</div>
                <div className="pd-10 text-center">
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Make Payment</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.value == 2 && (
        <div className="overflow-auto pd-15">
          <div className="form-group">
            <label className="form-item-title">Return on Marketing Investment</label>
            <SelectHook
              width={400}
              options={[
                { value: 1, label: 'Gross Profit' },
                { value: 2, label: 'Customer Lifetime Value' },
                { value: 3, label: 'Sales Growth' },
                { value: 4, label: 'Sales Growth - Average Organic Sales Growth' },
              ]}
              defaultValue={{ value: 1, label: 'Gross Profit' }}
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Key Performance Indicator</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon mg-l-auto rounded-0"
                icon="Plus"
              />
            </div>
            <div className="d-flex align-items-center pd-10 bd rounded">
              Click through rate <Input type="number" className="wd-100 mg-x-10" />%
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Time Span</label>
            <div className="row row-xs">
              <div className="col-6">
                From
                <Input />
              </div>
              <div className="col-6">
                To
                <Select defaultValue={{ value: 1, label: 'when I cancel' }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActOrganicLink;
