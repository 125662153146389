import { Button, RadioGroup, Select } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { useState } from 'react';

const RevenueSettings = () => {
  const UPDATE_TYPES = [
    {
      label: 'These factors are updated automatically when you use CRM',
      value: 'automatic',
    },
    {
      label: 'You can update these factors manually',
      value: 'manual',
    },
  ];

  const [options, setOptions] = useState(UPDATE_TYPES);
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
  };

  return (
    <div
      id="revenueSettings"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header flex-column pd-0">
            <div className="d-flex wd-100p pd-15">
              <h1 className="modal-title">Settings</h1>
              <Button close data-dismiss="modal" />
            </div>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <RadioGroup
              isVertical
              value={selectedValue}
              options={options}
              onChange={handleValueChange}
            />
            {selectedValue === 'manual' && (
              <>
                <div className="form-group">
                  <label className="form-item-title">Assign to</label>
                  <UserAutoComplete />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Frequency</label>
                  <div className="d-flex align-items-center">
                    <Select
                      width={150}
                      options={[
                        { value: 'm', label: 'Monthly' },
                        { value: 'f', label: 'Fiscal Period' },
                        { value: 'd', label: 'Daily' },
                        { value: 'w', label: 'Weekly' },
                      ]}
                      defaultValue={{ value: 'm', label: 'Monthly' }}
                      className="mg-r-10"
                    />
                    <Select width={150} defaultValue={{ value: 'dd', label: 'day' }} />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light mg-l-auto" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueSettings;
