import { Select } from '@base/components/form';
import { User, X } from 'react-feather';

const EditCate = () => {
  return (
    <div id="editCategoryModal" className="modal fade right">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="h6 modal-title">Edit Category</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">
                Name
                <span className="tx-danger" title="필수 입력">
                  *
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue="Category 11111111111111111111111111111111"
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <textarea className="form-control"></textarea>
            </div>
            <div className="form-group">
              <label className="form-item-title">Available for</label>
              <Select
                options={[
                  { value: 1, label: 'All' },
                  { value: 2, label: 'My' },
                  { value: 3, label: 'My Group' },
                  { value: 4, label: 'Choose a group' },
                ]}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="kbSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="kbSwitch1"></label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-danger">
              Delete
            </button>
            <button type="button" className="btn btn-success">
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCate;
