import { Settings, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

const VendorCredit = () => {
  return (
    <div
      id="vendorCreditModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Vendor Credit</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Vendor Credit ID</label>
              <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    <Settings />
                    <span className="sr-only">ID 생성 설정 열기</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Reason</label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="form-item-title">Vendor Credit Date</label>
              <DatePicker
                selected={new Date()}
                onChange={() => {
                  console.log();
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Items</label>
              <div className="table-responsive bg-white bd rounded">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Image
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Item
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Unit
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Unit Price
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Quantity
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Amount
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="avatar avatar-sm bg-gray-200 rounded">
                          <img src="assets/images/notfound.png" alt="" />
                        </div>
                      </td>
                      <td>Item 1</td>
                      <td>Bottle</td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="text" className="form-control" defaultValue="12000" />
                        </div>
                      </td>
                      <td>
                        <input type="text" className="form-control" defaultValue="30" />
                      </td>
                      <td>￦360,000</td>
                      <td>
                        <button type="button" className="btn btn-link btn-icon pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mg-b-10 pd-12 bg-white bd bd-t-0 rounded-bottom">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type or click to select a product"
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a product"
                    >
                      {Icon('product')}
                      <span className="sr-only">선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row row-xs mg-x--5 mg-t-10">
                <div className="col-md-6">
                  <ul className="list-unstyled lh-7 mg-b-0">
                    <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                      <span>Items : 30</span>
                      <span> ₩ 360,000</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-unstyled mg-b-10 lh-10 pd-15 rounded bg-white bd">
                    <li className="d-flex flex-wrap justify-content-between">
                      <span>Sub-Total</span>
                      <span className="mg-l-auto">₩ 360,000</span>
                    </li>
                    <li className="d-flex flex-wrap justify-content-between">
                      <span>Tax (10%)</span>
                      <span className="mg-l-auto">₩ 36,000</span>
                    </li>
                    <li className="d-flex flex-wrap justify-content-between">
                      <span>Shipping Charges</span>
                      <div className="input-group wd-150 mg-l-auto">
                        <div className="input-group-prepend">
                          <span className="input-group-text lh-1">￦</span>
                        </div>
                        <input type="text" className="form-control" defaultValue="3000" />
                      </div>
                    </li>
                    <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                      <strong>Total Amount</strong>
                      <strong>₩417,000</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Vendor Note</label>
              <textarea rows={3} cols={10} className="form-control"></textarea>
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <textarea rows={3} cols={10} className="form-control"></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-light mg-r-5" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorCredit;
