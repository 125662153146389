/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

import Icon from '@base/assets/icons/svg-icons';

import { ChevronDown, Star, Plus } from 'react-feather';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "DeskCss/desk.scss";

import TicketList from './modal/ticketList';
import Dashboard from './dashboard/dashboard';
import Report from './dashboard/report';

const TicketDashboard = () => {
	const [showReport, setShowReport] = useState<any>();

	return (
		<>
			<div className="list-wrap">
				<div className="list-header">
					<button
						type="button"
						className="btn btn-xs btn-icon btn-favorite"
						data-han-tooltip="즐겨찾기 추가"
					>
						<Star size="18" />
					</button>
					<h1 className="list-header-title">Dashboard</h1>
					<div className="dropdown mg-l-5">
						<button type="button" className="btn btn-xs btn-white btn-icon" data-toggle="dropdown">
							<ChevronDown />
							<span className="sr-only">변경할 메뉴 열기</span>
						</button>
						<div className="dropdown-menu nav-sub">
							<div className="dropdown-item active">
								<a href="/crm/demo-page/ui/mywork/desktop/theme1/dashboard">Dashboard</a>
								<button
									type="button"
									className="btn btn-xs btn-set-default btn-icon"
									data-han-tooltip="Set as default"
								>
									{Icon('set_default')}
									<span className="sr-only">기본 메뉴로 설정</span>
								</button>
							</div>
						</div>
					</div>
					<div className="dropdown mg-l-auto">
						<button
							type="button"
							className="btn btn-primary pd-x-10"
							data-toggle="modal"
							data-target="#"
						>
							<Plus />
							<span className="sr-only">추가</span>
						</button>
						<button
							type="button"
							className="btn btn-info mg-l-5"
							onClick={() => setShowReport(!showReport)}
						>
							{showReport ? 'Dashboard' : 'Report'}
						</button>
					</div>
				</div>

				<div className="list-body">{!showReport ? <Dashboard /> : <Report />}</div>
			</div>

			<TicketList />
		</>
	);
};

export default TicketDashboard;
