import { ChevronDown, ChevronLeft, ChevronRight, Search, Calendar } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import { Switch } from '@base/components/form';

const Transaction = () => {
  return (
    <div className="pd-20">
      <div className="d-flex align-items-center justify-content-end mg-b-10">
        <div className="dropdown list-filter-item flex-shrink-0">
          <button type="button" className="btn btn-white mg-r-10" data-toggle="dropdown">
            All
            <ChevronDown />
          </button>
          <div className="dropdown-menu wd-300">
            <button className="dropdown-item">All</button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              This Year
            </button>
            <button type="button" className="dropdown-item">
              This Quarter
            </button>
            <button type="button" className="dropdown-item active">
              This Month
            </button>
            <button type="button" className="dropdown-item">
              This Week
            </button>
            <button type="button" className="dropdown-item">
              Today
            </button>
            <button
              type="button"
              className="dropdown-item btn d-flex align-items-center"
              data-toggle="collapse"
              data-target="#dateBy-custom"
            >
              <span className="mg-r-5">Custom</span>
              <ChevronDown className="mg-l-auto" />
            </button>
            <div id="dateBy-custom" className="collapse show input-group pd-x-25 pd-y-5">
              <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
              <div className="input-group-append">
                <span className="input-group-text">
                  <Calendar />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="input-group wd-300">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <Search />{' '}
            </span>
          </div>
          <input
            type="search"
            className="form-control"
            placeholder="Enter an item name to search"
          />
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">Bill of Materails</div>
        <div className="card">
          <div className="table-responsive rounded">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    BoM Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Related Item
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Created on
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Active
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                      Bom 1
                    </a>
                  </td>
                  <td>Item 1</td>
                  <td>2020/09/22</td>
                  <td>
                    <Switch />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
