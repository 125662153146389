import Icon from '@base/assets/icons/svg-icons';

const LinkMap = () => {
  return (
    <div className="pd-20">
      <div className="table-responsive bg-white bd rounded">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Media
              </th>
              <th scope="col" className="bd-t-0-f">
                Visits
              </th>
              <th scope="col" className="bd-t-0-f">
                Lead Capture
              </th>
              <th scope="col" className="bd-t-0-f">
                MQL
              </th>
              <th scope="col" className="bd-t-0-f">
                Opportunity
              </th>
              <th scope="col" className="bd-t-0-f">
                Sales
              </th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td>Dropped</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td>
                <span className="social-icon">{Icon('facebook')}</span>Hanbiro
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                <span className="social-icon">{Icon('twitter')}</span>Hanbiro
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LinkMap;
