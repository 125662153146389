import { useState } from 'react';
import { ChevronDown, ChevronUp, Image, Settings, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';

const RegisterPayment = () => {
  const [showPaymentEmail, setShowPaymentEmail] = useState<any>();

  return (
    <div id="registerPayment" className="modal over-modal fade">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="h6 modal-title">Register Payment</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="mg-b-10 text-right">
              <span className="tx-danger">*</span>is required.
            </div>
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label className="form-item-title">Customer</label>
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                      className="rounded"
                      alt="Adventure Works"
                    />
                  </div>
                  <div className="media-body mg-l-10">Adventure Works</div>
                </div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">
                  Paid on<span className="tx-danger">*</span>
                </label>
                <DatePicker
                  selected={new Date()}
                  onChange={() => {
                    console.log();
                  }}
                />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">
                  Payment ID<span className="tx-danger">*</span>
                </label>
                <div className="input-group">
                  <input type="text" className="form-control" defaultValue="PAR-000001" />
                  <div className="input-group-append">
                    <button type="button" className="btn">
                      <Settings />
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">
                  Pay with<span className="tx-danger">*</span>
                </label>
                <SelectHook />
              </div>
              <div className="form-group col-12">
                <div className="d-flex align-items-center">
                  <label className="form-item-title">Payment Details</label>
                  <div className="custom-control custom-checkbox mg-l-auto">
                    <input type="checkbox" className="custom-control-input" id="fullAmountCheck" />
                    <label className="custom-control-label" htmlFor="fullAmountCheck">
                      Received Full Amount
                    </label>
                  </div>
                </div>
                <div className="table-responsive bd rounded">
                  <table className="table table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          ID
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Toral Amount
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Balance Due
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Amount Received
                        </th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <td colSpan={3} className="text-right tx-16 tx-bold">
                          Total Amount Received
                        </td>
                        <td className="text-right tx-16 tx-bold">￦100,000</td>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td>INV-000001</td>
                        <td>￦100,000</td>
                        <td>￦100,000</td>
                        <td>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text lh-1">￦</span>
                            </div>
                            <input type="number" className="form-control" />
                          </div>
                          <button type="button" className="btn">
                            <span className="tx-success">Pay in Full</span>
                          </button>
                          <button type="button" className="btn">
                            <span className="tx-danger">Clear</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Customer Note</label>
                <input type="text" className="form-control" placeholder="Visible to customer" />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Description</label>
                <input type="text" className="form-control" placeholder="Not visible to customer" />
              </div>
            </div>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="paymentEmailSwitch"
                onChange={() => setShowPaymentEmail(!showPaymentEmail)}
              />
              <label className="custom-control-label" htmlFor="paymentEmailSwitch">
                Send Payment Thank you Email
              </label>
            </div>
            {showPaymentEmail && (
              <div className="mg-t-5 mg-l-30">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="emailCheck1"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="emailCheck1">
                    sales@mail.com
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Regieter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPayment;
