import { X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

const NewBillingPlan = () => {
  return (
    <div id="newBillingPlan" className="modal fade right">
      <div className="modal-dialog wd-auto">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">New Billing Plan</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="tx-color-03">Plan Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="tx-color-03">Billing Cycle</label>
              <SelectHook
                options={[
                  { value: 1, label: 'Monthly' },
                  { value: 2, label: '6 Monthly' },
                  { value: 3, label: 'Annually' },
                ]}
              />
              <div className="mg-t-10">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="billingcycleRadio1"
                    name="billingcycleRadio"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="billingcycleRadio1">
                    Auto renews until canceled.
                  </label>
                </div>
                <div className="custom-control custom-radio with-input">
                  <input
                    type="radio"
                    id="billingcycleRadio2"
                    name="billingcycleRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="billingcycleRadio2">
                    Expires after{' '}
                    <input type="number" className="form-control d-inline-block wd-100 mg-x-10" />
                    of billing cycles.
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="tx-color-03">Start on</label>
              <DatePicker
                selected={new Date()}
                onChange={() => {
                  console.log();
                }}
              />
            </div>
            <div className="form-group">
              <label className="tx-color-03">End on</label>
              <div>06/27/2021</div>
              <span className="tx-color-03">
                It will be changed into the date the first purchased.
              </span>
            </div>
            <div className="form-group">
              <label className="tx-color-03">Invoice Date</label>
              <div>01/27/2021</div>
            </div>
            <div className="form-group">
              <label className="tx-color-03">Billing Date</label>
              <div>01/27/2021</div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBillingPlan;
