// import "../../../scss/desktop/theme1/theme1.scss";
import './style.scss';

import ProductWriteBasicInfo from './write/writeBasicInfo';
import ProductNewGroup from './modal/newProductGroup';
import ProductNewUnit from './modal/newProductUnit';
import ProductGenCode from './modal/newProductCode';

const Write = () => {
  return (
    <>
      {/* Summary */}
      <ProductWriteBasicInfo />

      <ProductNewGroup />
      <ProductNewUnit />
      <ProductGenCode />
    </>
  );
};

export default Write;
