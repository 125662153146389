import React, { useState } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, ChevronsLeft, ArrowLeft, Edit2 } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"

import Timeline from '../timeline';

const TABS = [
  {
    value: 1,
    label: 'Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const View = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Loyalty
            <ChevronDown className="mg-l-5" />
          </button>
          <div className="dropdown-menu nav-sub">
            <div className="dropdown-item">
              <a href="/crm/demo-page/ui/warehouse/desktop/theme1/list">Warehouse</a>
            </div>
            <div className="dropdown-item">
              <a href="">Marketing</a>
            </div>
            <div className="dropdown-item active">
              <a href="">Loyalty</a>
              <button
                type="button"
                className="btn btn-xs btn-set-default btn-icon"
                aria-label="Set as default"
              >
                {/* Add'active' class if this menu is currently set by default */}
                {Icon('set_default')}
                <span className="sr-only">기본 메뉴로 설정</span>
              </button>
            </div>
            <div className="dropdown-item">
              <a href="">Marketing Channel</a>
            </div>
          </div>
        </div>

        <h1 className="view-title mg-l-10 lh-1">Loyalty 1</h1>

        <div className="button-group flex-shrink-0 mg-l-auto">
          <button
            type="button"
            data-han-tooltip="Delete"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">
              <Trash />
            </span>
            <span className="sr-only">삭제</span>
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-section pd-15">
            <div className="form-group">
              <label className="form-item-title">Loaylty Type</label>
              <div>Point</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Customer Notice</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Time Period</label>
              <div>2021/09/01 ~ 2021/09/02</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="active-01"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="active-01"></label>
              </div>
            </div>
          </div>
        </div>

        <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* collapse button */}
            <button
              type="button"
              className={classnames(
                'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                { hidden: hideAsideLeft },
              )}
              onClick={() => setHideAsideLeft(!hideAsideLeft)}
            >
              <ChevronsLeft />
              <span className="sr-only">좌측 사이드 패널 접기</span>
            </button>

            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('nav-item', {
                      active: item.value == tab.value,
                    })}
                  >
                    <button
                      type="button"
                      className="btn btn-xs btn-move-tab"
                      aria-label="Drag-and-drop to reorder"
                    >
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <button
                      type="button"
                      className="btn nav-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="view-body">
            {tab.value == 1 && (
              <div className="pd-15">
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Event Rule</span>
                    <button type="button" className="btn btn-link mg-l-auto">
                      <Edit2 className="mg-r-5" />
                      Edit
                    </button>
                  </div>
                  <div
                    id="eventRule"
                    className="table-responsive bg-white bd rounded collapse show"
                  >
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Targets
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Earn points
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>All Customers</td>
                          <td>5%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Spending Rule</span>
                  </div>
                  <div id="spendingRule" className="card pd-15 collapse show">
                    ￦10,000 discount per 1,000 points
                    <hr />
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">Minimum order value</label>
                        <div>Cant be used under ￦100</div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">
                          Maximum redeemable amount per purchase
                        </label>
                        <div>50,000</div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">Expiration Date</label>
                        <div>2021/09/02</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Others</span>
                  </div>
                  <div id="loyaltyOthers" className="card pd-15 collapse show">
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">Excluded fees</label>
                        <div>Tax</div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">Time</label>
                        <div>Always</div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">Excluded Items</label>
                        <div>Item AAA</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {tab.value == 2 && <Timeline />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
