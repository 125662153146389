import { ChevronDown, Minus, Plus, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const TargetProfile = () => {
  return (
    <div className="pd-20">
      <div className="form-group">
        <label className="form-item-title">Age</label>
        <input type="range" className="form-control-range wd-80p" id="formControlRange" />
        {/* This is just a sample. Use this range slider http://themepixels.me/demo/dashforge1.1/components/form-rangeslider.html */}
      </div>
      <div className="form-group">
        <label className="form-item-title">Generational</label>
        <SelectHook
          className="wd-200-f"
          options={[
            { value: 1, label: 'Gen.Z (0~21)' },
            { value: 2, label: 'Millenilas' },
            { value: 3, label: 'Gen X (38-53)' },
            { value: 4, label: 'Baby Boomers' },
            { value: 5, label: 'Silent Gen' },
          ]}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Gender</label>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="genderCheck2" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="genderCheck2">
            Female
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="genderCheck3" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="genderCheck3">
            Male
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Occupation</label>
        <SelectHook />
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Marital Status</label>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="maritalStatusCheck1" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="maritalStatusCheck1">
            Single
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="maritalStatusCheck2" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="maritalStatusCheck2">
            Married
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="maritalStatusCheck3" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="maritalStatusCheck3">
            Divorced
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="maritalStatusCheck4" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="maritalStatusCheck4">
            Widowed
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Family Life-stage</label>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck1" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck1">
            Beginning families (married couple without children)
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck2" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck2">
            Child bearing families
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck3" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck3">
            Families with preschool children
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck4" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck4">
            Families with school children (child 6–13)
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck5" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck5">
            Families with teenagers (oldest child 13–20)
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck6" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck6">
            Families as launching centers (first child gone to last child’s leaving home)
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck7" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck7">
            Families in the middle years
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck8" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck8">
            Aging families (retirement)
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="familyCheck9" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="familyCheck9">
            Others
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Family size</label>
        <div className="d-flex align-items-center">
          <input type="number" className="form-control form-control-lg wd-100 mg-r-10" />
          number of dependants
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Annual Income</label>
        <div className="d-flex align-items-center">
          about
          <SelectHook className="wd-100-f mg-x-10" options={[{ value: 1, label: '￦' }]} />
          <input type="number" className="form-control form-control-lg wd-200" />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Educational Attainment</label>
        <input type="range" className="form-control-range" id="formControlRange" />
        {/* This is just a sample. Use this range slider http://themepixels.me/demo/dashforge1.1/components/form-rangeslider.html */}
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Home Ownership</label>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="homeOwnershipCheck1" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="homeOwnershipCheck1">
            Renting
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="homeOwnershipCheck2" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="homeOwnershipCheck2">
            Won home with mortgage
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="homeOwnershipCheck3" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="homeOwnershipCheck3">
            Home owned outright
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="homeOwnershipCheck4" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="homeOwnershipCheck4">
            Others
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Nationality</label>
        <div className="input-group bg-white">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="form-control-tag-name">Korean</span>
              <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                <X />
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250 rounded"
              placeholder="Type or click to select a language"
            />
          </div>
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open modal to select a language">
              <ChevronDown />
              <span className="sr-only">선택 옵션 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Religion</label>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="religionCheck1" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="religionCheck1">
            Catholic
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="religionCheck2" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="religionCheck2">
            Protestant
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="religionCheck3" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="religionCheck3">
            Greek Orthodox
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="religionCheck4" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="religionCheck4">
            Muslim
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="religionCheck5" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="religionCheck5">
            Jewish
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="religionCheck6" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="religionCheck6">
            Buddhist
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="religionCheck7" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="religionCheck7">
            Hindu
          </label>
        </div>
        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
          <input type="checkbox" id="religionCheck8" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="religionCheck8">
            Others
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Social Circles</label>
        <div className="d-flex">
          <input type="text" className="form-control" />
          <button type="button" className="btn btn-link btn-icon">
            <Plus />
            <span className="sr-only">추가</span>
          </button>
          <button type="button" className="btn btn-icon">
            <Minus className="tx-danger" />
            <span className="sr-only">삭제</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TargetProfile;
