import { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';

import { Button, Input, Select as SelectHook } from '@base/components/form';
import {
  ArrowUp,
  ChevronDown,
  Copy,
  CornerDownRight,
  Image,
  Move,
  RotateCcw,
  RotateCw,
  Trash,
  X,
  Clock,
} from 'react-feather';

const DESIGNTABS = [
  {
    value: 1,
    label: 'Designer',
  },
  {
    value: 2,
    label: 'Preview',
  },
];

const ActivityEmail = (props: any) => {
  const [designTab, setDesignTab] = useState(DESIGNTABS[0]);

  const [senderType, setSenderType] = useState<any>('single');
  const handleSenderTypeChange = (e: any) => {
    const { value } = e.target;
    setSenderType(value);
  };

  const [smsSchedule, setSmsSchedule] = useState<any>('now');
  const handleSmsScheduleChange = (e: any) => {
    const { value } = e.target;
    setSmsSchedule(value);
  };

  const [popoverOpen, setPopoverOpen] = useState<any>(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div
      className={classnames('overflow-auto', {
        'pd-15': props.value != 2 && props.value != 3,
      })}
    >
      {props.value == 1 && (
        <>
          <div className="form-group">
            <label className="form-item-title d-block">Send Schedule</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="smsScheduleRadio1"
                name="smsScheduleRadio"
                className="custom-control-input"
                value="now"
                checked={smsSchedule === 'now'}
                onChange={(e) => handleSmsScheduleChange(e)}
              />
              <label className="custom-control-label" htmlFor="smsScheduleRadio1">
                Send Now
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                id="smsScheduleRadio2"
                name="smsScheduleRadio"
                className="custom-control-input"
                value="schedule"
                checked={smsSchedule === 'schedule'}
                onChange={(e) => handleSmsScheduleChange(e)}
              />
              <label className="custom-control-label" htmlFor="smsScheduleRadio2">
                Schedule
              </label>
            </div>
          </div>
          {smsSchedule === 'schedule' && (
            <>
              <div className="form-group">
                <label className="form-item-title">Date</label>
                <div className="input-group wd-300">
                  <input type="text" className="form-control" />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Clock />
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="form-group">
            <label className="form-item-title d-block">SMS Type</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="smsTypeRadio1"
                name="smsTypeRadio"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="smsTypeRadio1">
                SMS
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="smsTypeRadio2"
                name="smsTypeRadio"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="smsTypeRadio2">
                LMS
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                id="smsTypeRadio3"
                name="smsTypeRadio"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="smsTypeRadio3">
                MMS
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Expenses to include in Marketing Investment</label>
            <div className="table-responsive bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 basic-table">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Expense Name
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Expense
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Return on Marketing Investment</label>
            <SelectHook
              className="wd-300-f"
              options={[
                { value: 1, label: 'Gross Profit' },
                { value: 2, label: 'Customer Lifetime Value' },
              ]}
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Key Performance Indicator</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon mg-l-auto rounded-0"
                icon="Plus"
              />
            </div>
            <div className="d-flex align-items-center pd-10 bd rounded">
              Click through rate <Input type="number" className="wd-100 mg-x-10" />%
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Conversion Process</label>
            <ol className="conversion-process">
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">MQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch1"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch1"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">SQL</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Account/Contact Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch2"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch2"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Opportunity</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">
                    Opprtunity Created
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch3"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch3"></label>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <div className="tx-semibold">Sales</div>
                  <div className="mg-y-10 pd-15 bg-purple tx-white bd rounded">Make Payment</div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="conversionSwitch4"
                    />
                    <label className="custom-control-label" htmlFor="conversionSwitch4"></label>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </>
      )}
      {props.value == 2 && (
        <div className="row row-xs mg-0 ht-100p">
          <div className="col-lg-6 d-flex flex-column ht-100p">
            <ul className="nav nav-line bd-1">
              <li className="nav-item">
                <button
                  type="button"
                  data-target="#myTemp"
                  className="btn nav-link pd-x-10-f active"
                  data-toggle="tab"
                >
                  My
                </button>
              </li>
              <li className="nav-item mg-l-0-f">
                <button
                  type="button"
                  data-target="#tempType"
                  className="btn nav-link pd-x-10-f"
                  data-toggle="tab"
                >
                  Type
                </button>
              </li>
            </ul>
            <div className="tab-content pd-x-10 overflow-auto">
              <div id="myTemp" className="tab-pane fade show active">
                <div className="card-columns temp-columns">
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">Blank</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">My 1</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">My 2</div>
                    <button type="button" className="btn btn-select active">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">My 3</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                </div>
              </div>
              <div id="tempType" className="tab-pane fade">
                <SelectHook
                  className="wd-200-f mg-t-10"
                  options={[{ value: 1, label: 'All(20)' }]}
                />
                <div className="card-columns temp-columns">
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">AAA</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                  <div className="card shadow-sm">
                    <div className="card-body ht-150 overflow-hidden tx-center"></div>
                    <div className="card-footer">BBB</div>
                    <button type="button" className="btn btn-select">
                      <span className="sr-only">선택</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex flex-column ht-100p">
            <div className="pd-y-9">Preview</div>
            <div className="flex-grow-1 overflow-auto mg-b-10 pd-10 bd"></div>
          </div>
        </div>
      )}
      {props.value == 3 && (
        <div className="row no-gutters ht-100p overflow-auto">
          <div
            className={classnames(
              'd-flex flex-column ht-100p',
              { 'col-lg-7 col-xl-8': designTab.value == 1 },
              { 'col-12': designTab.value != 1 },
            )}
          >
            <div className="d-flex">
              {/* tabs */}
              <ul className="nav nav-line bd-1 flex-grow-1">
                {DESIGNTABS.map((item, index) => (
                  <li className="nav-item" key={index}>
                    <button
                      type="button"
                      className={classnames('btn nav-link pd-x-10-f', {
                        active: item.value == designTab.value,
                      })}
                      onClick={(e) => {
                        setDesignTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
              {designTab.value == 1 && (
                <div className="btn-group bd-b">
                  <button
                    type="button"
                    data-han-tooltip="Undo"
                    className="btn btn-icon han-tooltip"
                  >
                    <RotateCcw />
                    <span className="sr-only">실행취소</span>
                  </button>
                  <button
                    type="button"
                    data-han-tooltip="Redo"
                    className="btn btn-icon han-tooltip"
                  >
                    <RotateCw />
                    <span className="sr-only">재실행</span>
                  </button>
                  <button
                    type="button"
                    data-han-tooltip="Clear Canvas"
                    className="btn btn-icon han-tooltip"
                  >
                    <Trash />
                    <span className="sr-only">초기화</span>
                  </button>
                </div>
              )}
            </div>
            <div className="flex-grow-1 overflow-auto">
              {designTab.value == 1 && (
                <>
                  <div
                    className="design-ele design-ele-img selected"
                    style={{ margin: '40px 15px' }}
                  >
                    <div className="design-ele-label">Section 1</div>
                    <div className="design-ele-toolbar">
                      <button
                        type="button"
                        data-han-tooltip="Parent"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <ArrowUp />
                        <span className="sr-only">상위</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Move"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Move />
                        <span className="sr-only">이동</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Copy"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Copy />
                        <span className="sr-only">복사</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Remove"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Trash />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <div className="design-ele-cont-initial tx-gray-400">
                      <Image size={40} strokeWidth={1.5} />
                      <div>Select an image</div>
                    </div>
                  </div>
                </>
              )}
              {designTab.value == 2 && (
                <div className="d-flex ht-100p">
                  <div className="flex-grow-1 overflow-auto pd-20">
                    {/* mobile */}
                    <div
                      className="shadow"
                      style={{
                        width: '360px',
                        height: '800px',
                        margin: '0 auto',
                        padding: '30px',
                        borderRadius: '15px',
                        border: '1px solid #cccccc',
                      }}
                    >
                      <div className="ht-100p overflow-auto bd"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {designTab.value == 1 && (
            <div className="col-lg-5 col-xl-4 d-flex flex-column ht-100p bd-l">
              <ul className="nav nav-line bd-1">
                <li className="nav-item">
                  <button
                    type="button"
                    data-target="#designToolbox"
                    className="btn nav-link pd-x-10-f active"
                    data-toggle="tab"
                  >
                    Toolbox
                  </button>
                </li>
                <li className="nav-item mg-l-0-f">
                  <button
                    type="button"
                    data-target="#designProperties"
                    className="btn nav-link pd-x-10-f"
                    data-toggle="tab"
                  >
                    Properties
                  </button>
                </li>
              </ul>
              <div className="tab-content flex-grow-1 overflow-auto">
                <div id="designToolbox" className="tab-pane fade show active">
                  <button
                    type="button"
                    className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                    data-toggle="collapse"
                    data-target="#designElement"
                  >
                    Elements
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="designElement" className="collapse fade show">
                    <div className="design-toolbox pd-5">
                      <button type="button" className="btn btn-white shadow-sm">
                        <Image />
                        <div className="ele-name">Image</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('cta')}
                        <div className="ele-name">CTA</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div id="designProperties" className="tab-pane fade">
                  {/* image type property */}
                  <button
                    type="button"
                    className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                    data-toggle="collapse"
                    data-target="#designIMG"
                  >
                    Image
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="designIMG" className="collapse fade show pd-15">
                    <div className="form-group">
                      <label className="form-item-title">Source</label>
                      <div className="input-group">
                        <input type="text" className="form-control" />
                        <div className="input-group-append">
                          <button type="button" className="btn">
                            <Image />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Alt Text</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {props.value == 4 && (
        <>
          <div className="form-group-title mg-t-0-f">Embedded CTA in SMS Message</div>
          <div className="form-group">
            <label className="form-item-title">CTA Name</label>
            <div>CTA 1</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">URL</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Personalized URL</label>
            <div className="d-flex">
              <CornerDownRight strokeWidth={1} />
              <div className="wd-100p">
                <div className="card bg-light mg-b-15">
                  <div className="card-header pd-y-10">Link</div>
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-item-title">Landing Page/Site</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Type</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Page Title</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group-title mg-t-0">Embedded CTA on this page</div>
                    <div className="form-group">
                      <label className="form-item-title">Type</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Name</label>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <CornerDownRight strokeWidth={1} />
                  <div className="wd-100p">
                    <div className="card bg-light">
                      <div className="card-header pd-y-10">Link</div>
                      <div className="card-body">
                        <div className="form-group">
                          <label className="form-item-title">Offer Site</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Page Title</label>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ActivityEmail;
