import { Input, Select as SelectHook } from '@base/components/form';
import Stage from './containers/stage';
import WaitAction from './containers/wait-action';
import AutomaticStatus from './containers/automaticStatus';
import WaitType from './containers/wait-type';

const StepWait = () => {
  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Step type</label>
        <div>Wait</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Step Name</label>
        <Input />
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <Input />
      </div>
      <div className="form-group">
        <label className="form-item-title">Stage</label>
        <Stage className="wd-300-f" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Wait Name</label>
        <SelectHook className="wd-300-f" addLabel="Add New Wait" onAdd />
      </div>
      <WaitType />
      <WaitAction />
      <AutomaticStatus />
    </>
  );
};

export default StepWait;
