import { Checkbox } from '@base/components/form';
import { useState } from 'react';
import { X, Globe } from 'react-feather';

const SiteButtons = () => {
  const [showBtn, setShowBtn] = useState(false);
  const handleChange = () => {
    setShowBtn(!showBtn);
  };
  return (
    <div
      id="siteButtonsModal"
      className="modal over-modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Quote Site</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto">
            <ul className="site-links-list btns-list">
              <li>
                <div className="d-flex align-items-center">
                  <Checkbox
                    label="CTA 1"
                    onChange={handleChange}
                    checked={showBtn}
                    className="mg-r-10"
                  />
                  {showBtn && (
                    <>
                      <div className="card bd-primary">
                        <div className="card-header bg-primary tx-white">
                          <Globe />
                          Sign
                        </div>
                        <div className="card-body">
                          <Checkbox label="CTA 6" />
                          <Checkbox label="CTA 7" />
                        </div>
                      </div>
                      <div className="site-direction">
                        <Checkbox label="CTA8" />
                      </div>
                    </>
                  )}
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <Checkbox label="CTA 2" className="mg-r-10" />
                  {/* <div className="card bd-purple">
                    <div className="card-header bg-purple tx-white">
                      {Icon('form')}
                      Feedback
                    </div>
                    <div className="card-body">&nbsp;</div>
                  </div> */}
                </div>
              </li>
              <li>
                <Checkbox label="CTA 3" className="mg-r-10" />
                {/* <div className="d-inline-block pd-x-20 pd-y-10 rounded-30 bg-orange tx-white">
                  <Download size={16} className="mg-r-5" />
                  Download
                </div> */}
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <Checkbox label="CTA 4" className="mg-r-10" />
                  {/* <div className="card bd-primary">
                    <div className="card-header bg-primary tx-white">
                      <MessageCircle />
                      Team channel
                    </div>
                    <div className="card-body">&nbsp;</div>
                  </div> */}
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <Checkbox label="CTA 5" className="mg-r-10" />
                  {/* <div className="card bd-purple">
                    <div className="card-header bg-purple tx-white">
                      {Icon('form')}
                      Reject
                    </div>
                    <div className="card-body">&nbsp;</div>
                  </div> */}
                </div>
              </li>
            </ul>
          </div>
          <div className="modal-footer d-flex">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteButtons;
