import { Button, RadioGroup, Select } from '@base/components/form';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import { useEffect, useState } from 'react';
import HelpButton from '../component/help-button';

import {
  COUNT_TYPE,
  AMOUNT_TYPE,
  RATIO_TYPE,
  countOptions,
  amountOptions,
  ratioOptions,
} from '../config/metric';

const SelectMetricName = (props: any) => {
  const { metricType } = props;

  const [nameSelected, setNameSelected] = useState('');

  const initialType = () => {
    if (metricType === COUNT_TYPE) {
      setNameSelected('create_lead');
    } else if (metricType === AMOUNT_TYPE) {
      setNameSelected('sales_revenue');
    } else {
      setNameSelected('lead_to_customer_conversion_ratio');
    }
  };

  useEffect(() => {
    initialType();
  }, [metricType]);

  const handleChangeName = (e: { target: { value: any } }) => {
    setNameSelected(e.target.value);
  };

  const LEAD_OPTIONS = [
    { value: 'inbound', label: 'Inbound' },
    { value: 'outbound', label: 'Outbound' },
  ];
  const [leadOption, setLeadOption] = useState(LEAD_OPTIONS);
  const [selectedLeadOption, setSelectedLeadOption] = useState(leadOption[0].value);
  //change
  const handleOptionChange = (newOption: any) => {
    setSelectedLeadOption(newOption.value);
  };

  const renderCountOptions = () => {
    return countOptions.map((item) => {
      return (
        <>
          {!item.tooltip && renderItem(item)}
          {nameSelected === 'create_lead' && item.value === 'create_lead' && (
            <div className="mg-l-25 mg-t-10">
              <RadioGroup
                options={leadOption}
                value={selectedLeadOption}
                onChange={handleOptionChange}
              />
            </div>
          )}
          {nameSelected === 'number_of_sales_per_product' &&
            item.value === 'number_of_sales_per_product' && (
              <div className="mg-l-25 mg-y-10">
                <ProductAutoComplete />
              </div>
            )}
          {nameSelected === 'activities' && item.value === 'activities' && (
            <div className="mg-l-25 mg-y-10">
              <Select
                options={[
                  { value: 1, label: 'Meeting' },
                  { value: 2, label: 'Email Sent' },
                  { value: 3, label: 'Call Outgoing' },
                  { value: 4, label: 'Call Incoming' },
                  { value: 5, label: 'SMS Sent' },
                ]}
              />
            </div>
          )}

          {!!item.tooltip && renderItemWithTooltip(item)}
        </>
      );
    });
  };

  const renderItem = (item: {
    value: string;
    title: string;
    className?: string;
    tooltip?: string;
  }) => {
    return (
      <div className="custom-control custom-radio mg-b-10">
        <input
          type="radio"
          id={item.value}
          value={item.value}
          checked={item.value === nameSelected}
          name="metricName"
          className="custom-control-input"
          onChange={handleChangeName}
        />
        <label className="custom-control-label" htmlFor={item.value}>
          {item.title}
        </label>
      </div>
    );
  };
  const renderItemWithTooltip = (item: {
    value: string;
    title: string;
    tooltip: string;
    className?: string;
  }) => {
    return (
      <div className="d-flex align-items-center mg-b-10">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id={item.value}
            value={item.value}
            checked={item.value === nameSelected}
            name="metricName"
            className="custom-control-input"
            onChange={handleChangeName}
          />
          <label className="custom-control-label" htmlFor={item.value}>
            {item.title}
          </label>
        </div>
        <HelpButton title={item.tooltip} />
      </div>
    );
  };
  const renderAmountOptions = () => {
    return amountOptions.map((item, index) => {
      return (
        <>
          {!item.tooltip && renderItem(item)}
          {nameSelected === 'sales_revenue' && item.value === 'sales_revenue' && (
            <div className="mg-l-25 mg-y-10">
              <Select
                options={[
                  { value: 1, label: 'Product Base' },
                  { value: 2, label: 'Number of Units Sold × Average Price' },
                  {
                    value: 3,
                    label: 'Number of Customers × Average Price of Services',
                  },
                ]}
              />
            </div>
          )}
          {!!item.tooltip && renderItemWithTooltip(item)}
        </>
      );
    });
  };
  const renderRatioOptions = () => {
    return ratioOptions.map((item, index) => {
      return (
        <>
          {!item.tooltip && renderItem(item)}
          {!!item.tooltip && renderItemWithTooltip(item)}
        </>
      );
    });
  };
  return (
    <div id="selectMetricName" className="modal fade over-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title h6">Select Metric Name</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body">
            {/* Count */}
            {metricType === COUNT_TYPE && renderCountOptions()}
            {/* Amount */}
            {metricType === AMOUNT_TYPE && renderAmountOptions()}
            {/* Rate / Ratio */}
            {metricType === RATIO_TYPE && renderRatioOptions()}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectMetricName;
