/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Settings,
  Menu,
  RotateCcw,
  X,
} from 'react-feather';
import Pagination from '@demo-page/component/desktop/pagination-new';
import { Button, Checkbox } from '@base/components/form';
import NewOpportunity from './modal/newOpportunity';

const OpportunityList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Opportunity</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub"></div>
          </div>
          <div className="button-group mg-l-auto">
            <button
              type="button"
              className="btn btn-primary pd-x-10"
              data-toggle="modal"
              data-target="#newOpportunity"
            >
              <Plus />
              <span className="sr-only">추가</span>
            </button>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                All
                <ChevronDown />
              </button>
              <div className="dropdown-menu">
                <Button color="" className="dropdown-item active" name="All" />
                <Button color="" className="dropdown-item" name="My Open" />
                <Button color="" className="dropdown-item" name="Closed" />
                <Button color="" className="dropdown-item" name="Lost" />
                <Button color="" className="dropdown-item" name="Recent" />
                <Button color="" className="dropdown-item" name="Won" />
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                All
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300"></div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filters
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300"></div>
            </div>

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Title
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item active">
                    Title
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Order Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {listType.list && (
            <div className="table-responsive">
              <table className="table dataTable">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="salesorderCheckAll"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="salesorderCheckAll"
                        ></label>
                      </div>
                    </th>
                    <th scope="col" className="sorting_desc">
                      Title
                    </th>
                    <th scope="col" className="sorting">
                      Account
                    </th>
                    <th scope="col" className="sorting">
                      Contact
                    </th>
                    <th scope="col" className="sorting">
                      Process
                    </th>
                    <th scope="col" className="sorting">
                      Stage
                    </th>
                    <th scope="col" className="sorting">
                      Amount
                    </th>
                    <th scope="col" className="sorting">
                      Probability
                    </th>
                    <th scope="col" className="sorting">
                      Weighted Amount
                    </th>
                    <th scope="col" className="sorting">
                      Closed on
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/opportunity/desktop/theme1/view">Opportunity 1</a>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-sm">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                            className="rounded-circle"
                            alt="Adventure Works"
                          />
                        </div>
                        <div className="media-body mg-l-10">Adventure Works</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-sm">
                          <img
                            src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                            className="rounded-circle"
                            alt="Allan Rey Palban"
                          />
                        </div>
                        <div className="media-body mg-l-10">Allan Rey Palban</div>
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <Checkbox />
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a href="/opportunity/desktop/theme1/view">Opportunity 1</a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Account:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                              className="rounded-circle"
                              alt="Adventure Works"
                            />
                          </div>
                          <div className="media-body mg-l-10">Adventure Works</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Contact:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                              className="rounded-circle"
                              alt="Allan Rey Palban"
                            />
                          </div>
                          <div className="media-body mg-l-10">Allan Rey Palban</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Process:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Stage:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Amount:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Probability:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Weighted Amount:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Closed on:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>
      <NewOpportunity />
    </>
  );
};

export default OpportunityList;
