/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  Checkbox,
  FormIcon,
  Input,
  RadioGroup,
  Select,
  Switch,
} from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import '@base/components/sidebar-menu/styles.scss';
import { Folder } from 'react-feather';
import SelectAgreement from './modal/selectAgreement';
import PreviewAgreement from './modal/previewAgreement';

const NAVS = [
  {
    value: 'Settings',
    label: 'Consent Settings',
  },
  {
    value: 'form',
    label: 'Consent Form',
  },
  {
    value: 'Edit3',
    label: 'Sign up Form',
  },
  {
    value: 'Link',
    label: 'Sending links of Mobile App and Sign up',
  },
  {
    value: 'Globe',
    label: 'Web Sign in',
  },
];

const MembershipSignUp = () => {
  const [nav, setNav] = useState(NAVS[0]);
  const [hideSideNav, setSideNav] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [showMore2, setShowMore2] = useState(true);
  const [addTerms, setAddTerms] = useState(false);
  const [showAuthentication, setShowAuthentication] = useState(true);
  const [showFullConsent, setShowFullConsent] = useState(true);
  const [showTemp, setShowTemp] = useState(false);

  const TERMS_OPTIONS = [
    { value: 'required', label: 'Required' },
    { value: 'optional', label: 'Optional' },
  ];
  const [termsOption, setTermsOption] = useState(TERMS_OPTIONS);
  const [selectedTerm, setSelectedTerm] = useState(termsOption[0].value);
  //change
  const handleTermChange = (newOption: any) => {
    setSelectedTerm(newOption.value);
  };

  return (
    <div className={classnames('page-container-wrap', { 'hide-sidebar': hideSideNav })}>
      <div className="page-menu-wrap">
        <div className="page-menu-nav-sidebar">
          <ul className="page-menu-sidebar-nav">
            {NAVS.map((item, index) => (
              <li className="page-menu-nav-item" key={index}>
                <button
                  className={classnames('btn page-menu-nav-link', {
                    active: item.value == nav.value,
                  })}
                  onClick={(e) => {
                    setNav(item);
                  }}
                >
                  <FormIcon
                    icon={item.value}
                    iconType={`${item.value === 'form' ? 'custom' : 'feather'}`}
                  />
                  <span className="mg-l-5">{item.label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="page-container-content">
        <div className="page-container-content-header">
          <Button
            color=""
            className="btn-toggle-sidebar tx-gray-400"
            icon="ChevronLeft"
            onClick={() => setSideNav(!hideSideNav)}
          />
          <h1 className="mg-x-10 mg-b-0 tx-16 tx-uppercase tx-normal">
            Membership &gt; {nav.label}
          </h1>
        </div>
        <div className="scroll-box ht-100v pd-20">
          {nav.value === 'Settings' && (
            <>
              <div className="card mg-b-10">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">
                    Opt in Settings
                    <br />
                    <small className="mg-t-5 tx-color-03 tx-normal">
                      Opt in means that a user will first need to check a box, or take a
                      confirmative action, to consent. Opt out means that a user must uncheck a box
                      to remove their consent.
                    </small>
                  </div>
                </div>
                <div className="card-body pd-b-10">
                  <div className="custom-control custom-radio d-inline-block mg-r-20">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="customRadio"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="customRadio1">
                      Enable(Opt in) <FormIcon icon="CheckSquare" className="mg-l-5" />
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="customRadio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="customRadio2">
                      Disable(Opt out) <FormIcon icon="Square" className="mg-l-5" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header h6 bg-light">Terms of Agreement</div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Agreement Name</label>
                      <Input type="text" className="mg-b-10" />
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Required</label>
                      <RadioGroup
                        options={termsOption}
                        value={selectedTerm}
                        onChange={handleTermChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-flex align-items-center">
                      <label className="form-item-title">More Details</label>
                      <Switch
                        noLabel
                        title="Use"
                        className="mg-l-auto"
                        value={showMore}
                        onChange={() => setShowMore(!showMore)}
                      />
                    </div>
                    {showMore && <textarea rows={3} cols={10} className="form-control"></textarea>}
                  </div>
                </div>
                {addTerms && (
                  <div className="card-body bd-t">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">Agreement Name</label>
                        <Input type="text" className="mg-b-10" />
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-item-title">Required</label>
                        <RadioGroup
                          options={termsOption}
                          value={selectedTerm}
                          onChange={handleTermChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex align-items-center">
                        <label className="form-item-title">More Details</label>
                        <Switch
                          noLabel
                          title="Use"
                          className="mg-l-auto"
                          value={showMore2}
                          onChange={() => setShowMore2(!showMore2)}
                        />
                      </div>
                      {showMore2 && (
                        <textarea rows={3} cols={10} className="form-control"></textarea>
                      )}
                    </div>
                    <Button
                      size="sm"
                      color="link"
                      icon="Minus"
                      iconClass="mg-r-5"
                      className="tx-danger mg-l-auto"
                      name="Delete"
                      onClick={() => setAddTerms(false)}
                    />
                  </div>
                )}
                <div className="card-footer">
                  <Button
                    size="sm"
                    color="link"
                    icon="Plus"
                    iconClass="mg-r-5"
                    name="Add"
                    onClick={() => setAddTerms(true)}
                  />
                </div>
              </div>
            </>
          )}
          {nav.value === 'form' && (
            <div className="card-columns settings-columns">
              <div className="card">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Authentication</div>
                  <Switch
                    noLabel
                    className="mg-l-auto"
                    value={showAuthentication}
                    onChange={() => setShowAuthentication(!showAuthentication)}
                  />
                </div>
                {showAuthentication && (
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-item-title">Type</label>
                      <Select
                        width={300}
                        options={[
                          { value: 1, label: 'Confirmation' },
                          { value: 2, label: 'Verification' },
                          { value: 3, label: '휴대폰 본인 인증(Korea only)' },
                        ]}
                        defaultValue={{ value: 1, label: 'Confirmation' }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Sending Method</label>
                      <ul className="list-group">
                        <li className="list-group-item">
                          <div className="d-flex flex-wrap align-items-center">
                            <span className="tx-color-03">Web Browser</span>
                            <div className="d-flex flex-wrap align-items-center mg-l-auto">
                              <Checkbox label="Email" checked className="d-inline-block mg-x-15" />
                              <Checkbox label="SMS" className="d-inline-block mg-x-15" />
                              <div className="d-flex align-items-center">
                                <span className="tx-color-03">Default</span>
                                <Select
                                  noLabel
                                  width={150}
                                  options={[{ value: 'email', label: 'Email' }]}
                                  defaultValue={[{ value: 'email', label: 'Email' }]}
                                  className="mg-l-10"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="d-flex flex-wrap align-items-center">
                            <span className="tx-color-03">Mobile</span>
                            <div className="d-flex flex-wrap align-items-center mg-l-auto">
                              <Checkbox label="Email" checked className="d-inline-block mg-x-15" />
                              <Checkbox label="SMS" checked className="d-inline-block mg-x-15" />
                              <div className="d-flex align-items-center">
                                <span className="tx-color-03">Default</span>
                                <Select
                                  noLabel
                                  width={150}
                                  className="mg-l-10"
                                  options={[
                                    { value: 'email', label: 'Email' },
                                    { value: 'sms', label: 'SMS' },
                                  ]}
                                  defaultValue={[{ value: 'email', label: 'Email' }]}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="card">
                <div className="card-header h6 bg-light">
                  <div className="card-header-title">Terms of Agreement</div>
                  <Button
                    color="link"
                    icon="preview"
                    iconType="custom"
                    isSpanTitle
                    classSpan="mg-l-5"
                    name="Preview"
                    className="mg-l-auto pd-0"
                    data-toggle="modal"
                    data-target="#previewAgreement"
                  />
                </div>
                <div className="d-flex align-items-center pd-15">
                  <Checkbox
                    label="Full Consent(including Optional)"
                    checked={showFullConsent}
                    onChange={() => setShowFullConsent(!showFullConsent)}
                  />
                </div>
                <div className="d-flex align-items-center pd-15 bd-t">
                  Agreement Name 1 (Required)
                  <Button
                    color="link"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon mg-l-auto pd-0"
                    data-han-tooltip="Delete"
                  />
                </div>
                <div className="d-flex align-items-center pd-15 bd-t">
                  Agreement Name 2 (Required)
                  <Button
                    color="link"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon mg-l-auto pd-0"
                    data-han-tooltip="Delete"
                  />
                </div>
                <div className="d-flex align-items-center pd-15 bd-t">
                  Agreement Name 3 (Optional)
                  <Button
                    color="link"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon mg-l-auto pd-0"
                    data-han-tooltip="Delete"
                  />
                </div>
                <div className="card-footer">
                  <div className="input-group">
                    <Input type="text" placeholder="Type or click to add a terms agreement" />
                    <div className="input-group-append">
                      <Button
                        color=""
                        className="input-group-btn"
                        icon="Folder"
                        data-toggle="modal"
                        data-target="#selAgreement"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header h6 bg-light">Sign Up Form</div>
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <Select width={250} />
                    <Button color="link" className="mg-l-10 pd-0" name="Preview" />
                  </div>
                </div>
              </div>
              {showAuthentication && (
                <div className="card">
                  <div className="card-header h6 bg-light">Send to Confirm</div>
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-item-title">Email/SMS Template</label>
                      <div className="d-flex align-items-center">
                        <Select width={250} />
                        <Button color="link" className="mg-l-10 pd-0" name="Preview" />
                      </div>
                    </div>
                    <Button color="link" className="pd-x-0" name="Generate Personalised SUS" />
                  </div>
                </div>
              )}
              <div className="card">
                <div className="card-header h6 bg-light">Thank you Page</div>
                <div className="card-body">
                  <div className="form-group">
                    <label className="form-item-title">Landing Page</label>
                    <Select />
                  </div>
                  <Checkbox
                    label="Email/SMS Template"
                    checked={showTemp}
                    onChange={() => setShowTemp(!showTemp)}
                  />
                  {showTemp && (
                    <div className="d-flex align-items-center mg-l-30 mg-t-5">
                      <Select width={250} />
                      <Button color="link" className="mg-l-10 pd-0" name="Preview" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <SelectAgreement />
      <PreviewAgreement showFullConsent={showFullConsent} />
    </div>
  );
};

export default MembershipSignUp;
