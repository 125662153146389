import { ChevronDown, Edit2, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Configuration = () => {
  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Marketng Resource</label>
        <div className="table-responsive bg-white bd rounded-top">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Type
                </th>
                <th scope="col" className="bd-t-0-f">
                  Name
                </th>
                <th scope="col" className="bd-t-0-f wd-70">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td align="center">
                  <button type="button" className="btn btn-icon pd-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="bg-white bd bd-t-0 rounded-bottom pd-10">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select a resource"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                <ChevronDown />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title d-flex">
          Conversion Process
          <button type="button" className="btn btn-sm btn-link mg-l-auto">
            <Edit2 className="mg-r-5" />
            Edit
          </button>
        </label>
        <ol className="conversion-process">
          <li>
            <div className="d-flex flex-column align-items-center">
              Lead Capture
              <div className="pd-15 bg-purple tx-white bd rounded">Lead Created</div>
            </div>
          </li>
          <li>
            <div className="d-flex flex-column align-items-center">
              MQL
              <div className="pd-15 bg-purple tx-white bd rounded">Lead Scoring</div>
            </div>
          </li>
          <li>
            <div className="d-flex flex-column align-items-center">
              SQL
              <div className="pd-15 bg-purple tx-white bd rounded">Account/Contact Created</div>
            </div>
          </li>
          <li>
            <div className="d-flex flex-column align-items-center">
              Opportunity
              <div className="pd-15 bg-purple tx-white bd rounded">Opprtunity Created</div>
            </div>
          </li>
        </ol>
      </div>
      <div className="form-group">
        <label className="form-item-title">Location</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">No. of Attendees</label>
        <ul className="list-unstyled">
          <li>Minimum 10</li>
          <li>Maximum 100</li>
          <li>Webcast 200</li>
        </ul>
      </div>
      <div className="form-group-title">Offer and Display</div>
      <div className="form-group">
        <label className="form-item-title">Pre-Event</label>
      </div>
      <div className="form-group">
        <label className="form-item-title">Check-in</label>
      </div>
      <div className="form-group">
        <label className="form-item-title">Event Display</label>
      </div>
      <div className="form-group">
        <label className="form-item-title">Check-out</label>
      </div>
      <div className="form-group">
        <label className="form-item-title">Post-Event</label>
      </div>
      <hr />
      <div className="form-group">
        <label className="form-item-title">Evenet Team Members</label>
        <div className="table-responsive bg-white bd rounded-top">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Name
                </th>
                <th scope="col" className="bd-t-0-f">
                  Mobile
                </th>
                <th scope="col" className="bd-t-0-f">
                  Email
                </th>
                <th scope="col" className="bd-t-0-f">
                  Member Type
                </th>
                <th scope="col" className="bd-t-0-f">
                  Role
                </th>
                <th scope="col" className="bd-t-0-f wd-70">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Security</td>
                <td align="center">
                  <button type="button" className="btn btn-icon pd-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="bg-white bd bd-t-0 rounded-bottom pd-10">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select a member"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                {Icon('m_customer')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group-title">Finalcial</div>
      <div className="form-group">
        <label className="form-item-title">Budget allocated</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Target Revenue</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Eotal Registration fee</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Revenue from Sposorship</label>
        <div>&nbsp;</div>
      </div>
      <hr />
      <div className="form-group">
        <label className="form-item-title">Expenses to include in Marketing Investment</label>
        <div className="table-responsive bg-white bd rounded-top">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Expense Name
                </th>
                <th scope="col" className="bd-t-0-f">
                  Expense
                </th>
                <th scope="col" className="bd-t-0-f">
                  Description
                </th>
                <th scope="col" className="bd-t-0-f wd-70">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td align="center">
                  <button type="button" className="btn btn-icon pd-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="bg-white bd bd-t-0 rounded-bottom pd-10">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select an expense"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                <ChevronDown />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Miscellaneous Cost</label>
        <div>Total Exepenses: ＄1,000</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Key Performance Indicator</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Return on Marketing Investment</label>
        <div>Ticket Revenue</div>
      </div>
    </>
  );
};

export default Configuration;
