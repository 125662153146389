import { X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const NewTask = () => {
  return (
    <div id="addTaxModal" className="modal fade right" role="dialog" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">New Tax</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="d-block form-item-title">Tax Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Percentage</label>
              <div className="input-group wd-150">
                <input type="number" className="form-control" />
                <div className="input-group-append">
                  <span className="input-group-text lh-1">%</span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Customer</label>
              <div className="input-group">
                <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                  <div className="form-control-tag">
                    <span className="d-block avatar avatar-xs mg-r-10">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                        className="rounded-circle"
                        alt="Adventure Works"
                      />
                    </span>
                    <span className="form-control-tag-name">Adventure Works</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      aria-label="삭제"
                    >
                      <X />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 mn-wd-250 rounded"
                    placeholder="Type or click to select a customer"
                  />
                </div>
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open modal to select a customer"
                  >
                    {Icon('m_customer')}
                    <span className="sr-only">고객 선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTask;
