import { Avatar } from '@base/components';
import { Button } from '@base/components/form';
import { Calendar } from 'react-feather';

const OpportunityDevelop = () => {
  return (
    <div className="pd-20">
      <label className="tx-color-03">Product : </label>
      <span>Product 1</span>
      <div className="d-flex">
        <div className="card card-body mg-r-10">
          <div className="card-title tx-semibold">Customer Need Analysis</div>
          <textarea rows={3} cols={10} className="form-control">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget fermentum tortor.
          </textarea>
        </div>
        <div className="card card-body mg-r-10">
          <div className="card-title tx-semibold">Value Proposion</div>
          <textarea rows={3} cols={10} className="form-control">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget fermentum tortor.
          </textarea>
        </div>
        <div className="card card-body">
          <div className="card-title tx-semibold">Objections</div>
          <textarea rows={3} cols={10} className="form-control"></textarea>
        </div>
      </div>
      <Button color="link" icon="Plus" iconClass="mg-r-5" name="Add a line" />

      <div className="view-content-section">
        <div className="view-content-section-header">
          <span className="view-aside-section-header-title">Perception Analysis</span>
        </div>
        <div className="card card-body">
          <div className="d-flex align-item-center">
            <span className="tx-semibold">Reviewer</span>
            <Button
              color="link"
              size="sm"
              icon="Plus"
              className="btn-icon mg-l-auto"
              data-toggle="modal"
              data-target="#addReviewer"
            />
          </div>
          <div className="d-flex align-item-center mg-y-10">
            <div className="media align-items-center">
              <Avatar width={26} height={26} />
              <div className="media-body mg-l-10">Jane Doe</div>
            </div>
            <span className="mg-l-auto tx-color-03">
              <Calendar size={14} className="mg-r-5" />
              2022-05-27
            </span>
          </div>
          <textarea rows={3} cols={10} className="form-control"></textarea>
          <div className="d-flex align-item-center mg-y-10">
            <div className="media align-items-center">
              <Avatar width={26} height={26} />
              <div className="media-body mg-l-10">John Doe</div>
            </div>
            <span className="mg-l-auto tx-color-03">
              <Calendar size={14} className="mg-r-5" />
              2022-05-27
            </span>
          </div>
          <textarea rows={3} cols={10} className="form-control"></textarea>
        </div>
      </div>
    </div>
  );
};
export default OpportunityDevelop;
