import { useState } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, ArrowLeft, CornerDownRight, Plus } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import Attachments from '../../../../../component/desktop/attachments';

const TABS = [
  {
    value: 1,
    label: 'Usage',
  },
  {
    value: 2,
    label: 'Configuration',
  },
  {
    value: 3,
    label: 'Related',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewItem = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Marketing Collateral
            <ChevronDown className="mg-l-5" />
          </button>
        </div>

        <h1 className="view-title mg-l-10 lh-1">Item 1</h1>

        <div className="button-group d-flex flex-shrink-0 mg-l-auto">
          <div className="process-buttons mg-r-15">
            <div className="dropdown">
              <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                More
                <ChevronDown className="mg-l-5" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <button type="button" className="btn btn-primary mg-l-5 rounded-30">
            <Plus size="14" />
            New
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner">
            {/* collapse left aside */}
            {!hideAsideLeft && (
              <button
                type="button"
                className="btn btn-hide-aside-left"
                onClick={() => setHideAsideLeft(true)}
              >
                <LeftCollapse />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>
            )}
            <div className="view-aside-section pd-20">
              <div className="d-flex align-items-center justify-content-center">
                <figure className="img-thumbnail">
                  <img src="https://via.placeholder.com/150x150" alt="" className="img-fit-cover" />
                </figure>
              </div>
              <div className="form-group">
                <label className="form-item-title">Stage</label>
                <div>Active</div>
              </div>
            </div>

            {/* Summary */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Summary</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#basicInfoSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="basicInfoSection" className="view-aside-section-body collapse show">
                <div className="form-group">
                  <label className="form-item-title">ITem Type</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Owner</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>MSR</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Attachment */}
            <Attachments />
          </div>
        </div>

        <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('nav-item', {
                      active: item.value == tab.value,
                    })}
                  >
                    <button
                      type="button"
                      className="btn btn-xs btn-move-tab"
                      aria-label="Drag-and-drop to reorder"
                    >
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <button
                      type="button"
                      className="btn nav-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="view-body">
            {/* show left aside */}
            {hideAsideLeft && (
              <button
                type="button"
                className="btn btn-show-aside-left"
                onClick={() => setHideAsideLeft(false)}
              >
                <RightCollapse />
                <span className="sr-only">좌측 사이드 패널 열기</span>
              </button>
            )}
            {/* Usage */}
            {tab.value == 1 && (
              <div className="pd-20">
                <div className="d-flex align-items-center justify-content-end mg-b-10">
                  Date Range{' '}
                  <SelectHook
                    className="wd-200-f mg-l-10"
                    options={[{ value: 1, label: 'This month' }]}
                  />
                </div>
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Usage by source</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#usageSection"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="usageSection" className="card card-body collapse show">
                    <div className="row no-gutters">
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Usage and Click</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#usageClickSection"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="usageClickSection" className="card card-body collapse show">
                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                      <div className="d-flex align-items-center mg-b-10">
                        Per{' '}
                        <SelectHook
                          className="wd-200-f mg-l-10"
                          options={[{ value: 1, label: 'Campaign' }]}
                        />
                      </div>
                      <div className="d-flex align-items-center mg-l-15 mg-b-10">
                        Frequency{' '}
                        <SelectHook
                          className="wd-150-f mg-l-10"
                          options={[{ value: 1, label: 'Daily' }]}
                        />
                      </div>
                    </div>
                    <div className="mg-t-10 mg-b-20"></div>
                    <div className="table-responsive bg-white bd rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Source
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Usage
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              CTA Clicks
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Click through rate
                            </th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th scope="row" className="bd-b-0-f">
                              All
                            </th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr>
                            <td>Campaign 1</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>10%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Configuration */}
            {tab.value == 2 && (
              <div className="pd-20">
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Links</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#BCLinks"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="BCLinks" className="card card-body collapse show">
                    <div className="form-group">
                      <label className="form-item-title">CTA Name</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">URL</label>
                      <div>https://hanbiro.com</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">QR Code</label>
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsip6Dsj3eSrw44sOjmaU1eMpLQNb0MfQXgw&usqp=CAU"
                        alt=""
                        className="img-thumbnail wd-150"
                      />
                    </div>
                    <div className="d-flex">
                      <CornerDownRight strokeWidth={1} />
                      <div className="wd-100p">
                        <div className="card bg-light mg-b-15">
                          <div className="card-header pd-y-10">Link</div>
                          <div className="card-body">
                            <div className="form-group">
                              <label className="form-item-title">Landing Page/Site</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Type</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Page Title</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group-title mg-t-0">Embedded CTA on this page</div>
                            <div className="form-group">
                              <label className="form-item-title">Type</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Name</label>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <CornerDownRight strokeWidth={1} />
                          <div className="wd-100p">
                            <div className="card bg-light">
                              <div className="card-header pd-y-10">Link</div>
                              <div className="card-body">
                                <div className="form-group">
                                  <label className="form-item-title">Offer Site</label>
                                  <div>&nbsp;</div>
                                </div>
                                <div className="form-group">
                                  <label className="form-item-title">Type</label>
                                  <div>&nbsp;</div>
                                </div>
                                <div className="form-group">
                                  <label className="form-item-title">Page Title</label>
                                  <div>&nbsp;</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Implementing Cost</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto pd-0"
                      data-toggle="collapse"
                      data-target="#implementingCostSection"
                    >
                      <ChevronDown />
                    </button>
                  </div>
                  <div id="implementingCostSection" className="card collapse show">
                    <div className="table-responsive">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Cost Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Amount
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th scope="row" className="bd-b-0-f">
                              Total Cost
                            </th>
                            <td colSpan={2}>&nbsp;</td>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Related */}
            {tab.value == 3 && (
              <div className="pd-20">
                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Click to Action Printed</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#promotionalItemRelated1"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="promotionalItemRelated1" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              CTA Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              CTA Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Link Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Created on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Stage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Campaign</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#promotionalItemRelated2"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="promotionalItemRelated2" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Campaign Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Activity
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Assign to
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Used on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Quantity
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="view-content-section">
                  <div className="view-content-section-header">
                    <button type="button" className="view-aside-section-move mg-r-5">
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <span className="view-aside-section-header-title">Activity</span>
                    <button
                      type="button"
                      className="btn btn-link link-03 mg-l-auto"
                      data-toggle="collapse"
                      aria-label="collapse"
                      data-target="#promotionalItemRelated3"
                    >
                      <ChevronDown />
                      <span className="sr-only">내용 접기</span>
                    </button>
                  </div>
                  <div id="promotionalItemRelated3" className="card collapse show">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Activity Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Activity Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Assign to
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Used on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Quantity
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewItem;
