import { Button, Input } from '@base/components/form';
import { Mail, Phone } from 'react-feather';
import Comment from '../component/desktop/comment';
import ModalSign from './modal/ModalSign';
import noImg from '@base/assets/img/notfound.png';
import './site.scss';

const idModal = 'modalSign';

const VendorSite = () => {
  return (
    <div className="content pd-y-30-f overflow-auto">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="form-group text-center">
              <label className="form-item-title">Total Amount</label>
              <div className="tx-24 tx-spacing--1">₩ 1,078,000</div>
            </div>
            <Button
              icon="Check"
              color="primary"
              block
              data-toggle="modal"
              data-target={`#${idModal}`}
            >
              <span className="mg-l-5">Sign &amp; Send</span>
            </Button>
            <ModalSign nameButton="Add" idModal={idModal} ariaLabelledby="idaria"></ModalSign>
            <div className="mg-y-10 text-center">
              <Button
                tag="a"
                href="#comment"
                icon="MessageSquare"
                color=""
                className="pd-y-0 tx-purple"
              >
                <span className="mg-l-5 tx-purple">Feedback</span>
              </Button>
              <Button icon="X" color="" className="pd-y-0 tx-danger bd-l">
                <span className="mg-l-5 tx-danger">Reject</span>
              </Button>
            </div>
            <div className="d-flex mg-t-10">
              <Button icon="Download" color="info" className="flex-fill">
                <span className="mg-l-5">Download</span>
              </Button>
              <Button icon="Printer" color="info" className="flex-fill mg-l-10">
                <span className="mg-l-5">Print</span>
              </Button>
            </div>
            <div className="mg-t-40">
              <label className="tx-sans tx-12 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 mg-b-15">
                Purchase Representative
              </label>
              <div className="d-flex">
                <span className="avatar flex-shrink-0">
                  <img
                    src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                    className="rounded-circle"
                  />
                </span>
                <div className="mg-l-10" style={{ maxWidth: 'calc(100% - 48px)' }}>
                  MSR
                  <ul className="list-unstyled mg-b-0">
                    <li className="mg-b-5 text-truncate">
                      <Phone size={16} className="tx-primary mg-r-10" />
                      <a href="tel:1 518-445-569">+1 518-445-5696</a>
                    </li>
                    <li className="mg-b-5 text-truncate">
                      <Mail size={16} className="tx-teal mg-r-10" />
                      <a href="mailto:sales@hanbiro.com">sales@hanbiro.com</a>
                    </li>
                  </ul>
                  <Button icon="MessageCircle" iconSize="16" color="" className="pd-0 tx-indigo">
                    <span className="mg-l-10 tx-indigo">Chat &amp; Voice Talk</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-9">
            <div className="card">
              <div className="card-header tx-24">RFQ-001</div>
              <div className="card-body">
                <div className="table-responsive bd rounded">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Image
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Item
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Quantity
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Unit Price
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="avatar avatar-sm bg-gray-200 rounded">
                            <img src={noImg} alt="" />
                          </div>
                        </td>
                        <td>AAA</td>
                        <td>
                          <input type="number" className="form-control" defaultValue="100" />
                        </td>
                        <td>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text bd-gray-400 bd-r-0 lh-1">￦</span>
                            </div>
                            <input
                              type="number"
                              className="form-control bd-l-0"
                              defaultValue="9800"
                            />
                          </div>
                        </td>
                        <td>￦980,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row mg-x-0 mg-y-10 pd-y-15 pd-x-5 bg-light rounded-5">
                  <div className="col-lg-6 offset-lg-6">
                    <ul className="list-unstyled lh-10 pd-r-10">
                      <li className="d-flex justify-content-between">
                        <span>Sub-Total</span>
                        <span>￦980,000</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <span>Tax</span>
                        <span>￦98,000</span>
                      </li>
                      <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                        <strong>Total</strong>
                        <strong>￦1,078,000</strong>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-item-title">Condition</label>
                  <textarea cols={10} rows={3} className="form-control"></textarea>
                </div>

                <div className="form-group">
                  <label className="form-item-title">Selection Criteria</label>
                  <ul className="list-unstyled">
                    <li>
                      Criteria 1
                      <Input className="mg-y-10" />
                    </li>
                    <li>
                      Criteria 2
                      <Input className="mg-y-10" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mg-t-30 pd-20 bd-b tx-20" id="comment">
              History
            </div>
            <Comment />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorSite;
