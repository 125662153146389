import type { PluginContext, PluginInfo, HTMLMdNode, I18n } from '@toast-ui/editor';
export interface PluginOptions {}
export default function siteBuilderPlugin(
  context: PluginContext,
  options: PluginOptions = {},
): PluginInfo {
  console.log('siteBuilderPlugin', context, options);
  return {
    toHTMLRenderers: {
      htmlInline: {
        sitebuilder(node, { entering }) {
          console.log('htmlBlock => bigg', entering);
          return {
            type: entering ? 'openTag' : 'closeTag',
            tagName: 'div',
            classNames: [`heading`],
          };
        },
      },
    },
  };
}
