import React, { useState } from 'react';
import classnames from 'classnames';

import { ArrowLeft, ArrowRight, ChevronUp, X } from 'react-feather';

import ProductWriteItemStep1 from '../write/writeItemStep1';
import ProductWriteItemStep2 from '../write/writeItemStep2';

const TABS = [
  {
    value: 1,
    label: 'Summary',
  },
  {
    value: 2,
    label: 'Basic Info',
  },
];

const NewItem = () => {
  const [step, setStep] = useState(TABS[0]);

  return (
    <div id="newItem" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content rounded-0">
          <div className="modal-header flex-column pd-0">
            <div className="d-flex wd-100p pd-15">
              <h1 className="modal-title">Create Item</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <ul role="tablist" className="steps steps-tab steps-justified wd-100p bd-t">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  role="tab"
                  className={classnames('step-item', {
                    active: item.value == step.value,
                    complete: item.value < step.value,
                  })}
                >
                  <a
                    href=""
                    className="step-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setStep(item);
                    }}
                  >
                    {item.value == step.value && <span className="sr-only">현재 단계</span>}
                    <span className="step-number">{item.value}</span>
                    <span className="step-title">{item.label}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-body pd-0" style={{ overflowX: 'hidden' }}>
            {/* step 1 */}
            {step.value == 1 && <ProductWriteItemStep1 />}

            {/* step 2 */}
            {step.value == 2 && <ProductWriteItemStep2 />}
          </div>
          <div className="modal-footer">
            {step.value == 2 && (
              <button type="button" className="btn btn-white">
                <ArrowLeft className="mg-r-5" />
                Previous
              </button>
            )}
            <button type="button" className="btn btn-light mg-l-auto" data-dismiss="modal">
              Close
            </button>
            {step.value == 1 && (
              <button type="button" className="btn btn-primary">
                Next
                <ArrowRight className="mg-l-5" />
              </button>
            )}
            {step.value == 2 && (
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewItem;
