import { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Settings,
  Plus,
  List,
  Grid,
  Menu,
  RotateCcw,
  X,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "MarketingCss/marketing.scss"

import NewCTA from './modal/newCTA';
import NewLandingPage from './modal/newLandingPage';
import NewForm from './modal/newForm';
import NewOfferSite from './modal/newOfferSite';
import NewEventSite from './modal/newEventSite';
import NewOfferDownlodFiles from './modal/newOfferDownloadFiles';
import NewImage from './modal/newImage';
import SelSample from './modal/selSample';

import Pagination from '../../../../../component/desktop/pagination-new';

const TABS = [
  {
    value: 1,
    label: 'CTA',
  },
  {
    value: 2,
    label: 'Landing Pages',
  },
  {
    value: 3,
    label: 'Forms',
  },
  {
    value: 4,
    label: 'Offer Site',
  },
  {
    value: 5,
    label: 'Referral Reward Site',
  },
  {
    value: 6,
    label: 'Event Site',
  },
  {
    value: 7,
    label: 'Offer Download Files',
  },
  {
    value: 8,
    label: 'Files',
  },
];

const OnlineList = () => {
  const [tab, setTab] = useState(TABS[0]);

  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState(false);

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Online Digital Contents</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <button type="button" className="dropdown-item">
                Dashboard
              </button>
              <button type="button" className="dropdown-item">
                Campaign
              </button>
              <button type="button" className="dropdown-item">
                Lead
              </button>
              <button type="button" className="dropdown-item">
                Loyalty
              </button>
              <button type="button" className="dropdown-item">
                Marketing Resource
              </button>
            </div>
          </div>
          <div className="button-group mg-l-auto">
            <div className="dropdown">
              <button type="button" className="btn btn-primary btn-icon" data-toggle="dropdown">
                <Plus />
                <ChevronDown />
                <span className="sr-only">Add</span>
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newCTAModal"
                >
                  CTA
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newLandingPageModal"
                >
                  Landing Pages
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newFormModal"
                >
                  Forms
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newOfferSiteModal"
                >
                  Offer Site
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newEventSiteModal"
                >
                  Event Site
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newOfferDownloadFileModal"
                >
                  Offer Download Files
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newImageModal"
                >
                  Files
                </button>
              </div>
            </div>

            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="list-filter-items">
              {/* for grid */}
              {listType.grid && (
                <div className="dropdown with-sort list-filter-item">
                  <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                    {/* Add 'current-sorting' class when sorting is applied */}
                    {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                    <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
                  </button>
                  <button
                    type="button"
                    className="btn btn-filter pd-l-0"
                    data-toggle="dropdown"
                    aria-label="정렬 옵션 열기"
                  >
                    Sorty by: Created on
                    <ChevronDown />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a role="button" className="dropdown-item">
                      Created on
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* for table list column settings */}
          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {/* tabs */}
          <ul
            className={classnames('nav nav-tabs list-tabs', {
              'bd-b-0': listType.list,
              'bd-1': listType.grid,
            })}
          >
            {TABS.map((item, index) => (
              <li className="nav-item" key={index}>
                <button
                  type="button"
                  className={classnames('btn nav-link', {
                    active: item.value == tab.value,
                    'no-bg': listType.grid,
                  })}
                  onClick={(e) => {
                    setTab(item);
                  }}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>

          {tab.value == 1 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="ctaCheckAll"
                            />
                            <label className="custom-control-label" htmlFor="ctaCheckAll"></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Type
                        </th>
                        <th scope="col" className="sorting">
                          Preview
                        </th>
                        <th scope="col" className="sorting">
                          Name
                        </th>
                        <th scope="col" className="sorting">
                          Content Type
                        </th>
                        <th scope="col" className="sorting">
                          Language
                        </th>
                        <th scope="col" className="sorting">
                          View
                        </th>
                        <th scope="col" className="sorting">
                          Click
                        </th>
                        <th scope="col" className="sorting">
                          Click rate
                        </th>
                        <th scope="col" className="sorting">
                          A/B Test
                        </th>
                        <th scope="col" className="sorting">
                          Status
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="ctaCheck1"
                            />
                            <label className="custom-control-label" htmlFor="ctaCheck1"></label>
                          </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <a href="/online-ditigal-contents/desktop/theme1/cta-view">CTA 1</a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="ctaCheck1" />
                          <label className="custom-control-label" htmlFor="ctaCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/online-ditigal-contents/desktop/theme1/cta-view"
                            className="grid-list-item-title link-02"
                          >
                            CTA 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Preview:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Content Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Language:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">View:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Click:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Click rate:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">A/B Test:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Status:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 2 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="lpCheckAll"
                            />
                            <label className="custom-control-label" htmlFor="lpCheckAll"></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Type
                        </th>
                        <th scope="col" className="sorting">
                          Preview
                        </th>
                        <th scope="col" className="sorting">
                          Name
                        </th>
                        <th scope="col" className="sorting">
                          Content Type
                        </th>
                        <th scope="col" className="sorting">
                          Language
                        </th>
                        <th scope="col" className="sorting">
                          View
                        </th>
                        <th scope="col" className="sorting">
                          Click
                        </th>
                        <th scope="col" className="sorting">
                          Click rate
                        </th>
                        <th scope="col" className="sorting">
                          A/B Test
                        </th>
                        <th scope="col" className="sorting">
                          Status
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="lpCheckAll"
                            />
                            <label className="custom-control-label" htmlFor="lpCheckAll"></label>
                          </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <a href="/online-ditigal-contents/desktop/theme1/landing-page-view">
                            Landing Page 1
                          </a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="lpCheck1" />
                          <label className="custom-control-label" htmlFor="lpCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/online-ditigal-contents/desktop/theme1/landing-page-view"
                            className="grid-list-item-title link-02"
                          >
                            Landing Page 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Preview:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Content Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Language:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">View:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Click:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Click rate:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">A/B Test:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Status:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 3 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="formCheckAll"
                            />
                            <label className="custom-control-label" htmlFor="formCheckAll"></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Name
                        </th>
                        <th scope="col" className="sorting">
                          Form Type
                        </th>
                        <th scope="col" className="sorting">
                          Content Type
                        </th>
                        <th scope="col" className="sorting">
                          Language
                        </th>
                        <th scope="col" className="sorting">
                          View
                        </th>
                        <th scope="col" className="sorting">
                          Submissions
                        </th>
                        <th scope="col" className="sorting">
                          Submission rate
                        </th>
                        <th scope="col" className="sorting">
                          Status
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="formCheck1"
                            />
                            <label className="custom-control-label" htmlFor="formCheck1"></label>
                          </div>
                        </td>
                        <td>
                          <a href="/online-ditigal-contents/desktop/theme1/form-view">Form 1</a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="siteCheck1" />
                          <label className="custom-control-label" htmlFor="siteCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/online-ditigal-contents/desktop/theme1/form-view"
                            className="grid-list-item-title link-02"
                          >
                            Form 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Form Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Content Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Language:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">View:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Submissions:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Submission rate:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Status:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 4 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="siteCheckAll"
                            />
                            <label className="custom-control-label" htmlFor="siteCheckAll"></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Offer Site Name
                        </th>
                        <th scope="col" className="sorting">
                          Offer Type
                        </th>
                        <th scope="col" className="sorting">
                          Language
                        </th>
                        <th scope="col" className="sorting">
                          View
                        </th>
                        <th scope="col" className="sorting">
                          Stage
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="siteCheck1"
                            />
                            <label className="custom-control-label" htmlFor="siteCheck1"></label>
                          </div>
                        </td>
                        <td>
                          <a href="/online-ditigal-contents/desktop/theme1/offer-site-view">
                            Site 1
                          </a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="siteCheck1" />
                          <label className="custom-control-label" htmlFor="siteCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/online-ditigal-contents/desktop/theme1/view"
                            className="grid-list-item-title link-02"
                          >
                            Site 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Offer Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Language:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">View:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Stage:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 5 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col">Site</th>
                        <th scope="col" className="wd-100">
                          Stage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan={6}>
                          <a href="/online-ditigal-contents/desktop/theme1/referral-site-view">
                            Site
                          </a>
                        </td>
                        <td>Published</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/online-ditigal-contents/desktop/theme1/referral-site-view"
                            className="grid-list-item-title link-02"
                          >
                            Site 1
                          </a>
                          <span className="badge badge-success tx-13 tx-normal mg-l-auto">
                            Published
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 6 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="eventsiteCheckAll"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="eventsiteCheckAll"
                            ></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Event Site Name
                        </th>
                        <th scope="col" className="sorting">
                          Event Site Type
                        </th>
                        <th scope="col" className="sorting">
                          Views
                        </th>
                        <th scope="col" className="sorting">
                          Stage
                        </th>
                        <th scope="col" className="sorting">
                          Campaign
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="eventsiteCheck1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="eventsiteCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a href="/online-ditigal-contents/desktop/theme1/event-site-view">
                            Site 1
                          </a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="eventsiteCheck1"
                          />
                          <label className="custom-control-label" htmlFor="eventsiteCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/online-ditigal-contents/desktop/theme1/event-site-view"
                            className="grid-list-item-title link-02"
                          >
                            Site 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Event Site Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">View:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Stage:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Campaign:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 7 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="fileCheckAll"
                            />
                            <label className="custom-control-label" htmlFor="fileCheckAll"></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Folder
                        </th>
                        <th scope="col" className="sorting">
                          File Name
                        </th>
                        <th scope="col" className="sorting">
                          Content Type
                        </th>
                        <th scope="col" className="sorting">
                          Size
                        </th>
                        <th scope="col" className="sorting">
                          Version
                        </th>
                        <th scope="col" className="sorting">
                          Last Modified
                        </th>
                        <th scope="col" className="sorting">
                          Download
                        </th>
                        <th scope="col" className="sorting">
                          Owner
                        </th>
                        <th scope="col" className="sorting">
                          Stage
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="fileCheck1"
                            />
                            <label className="custom-control-label" htmlFor="fileCheck1"></label>
                          </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                          <a href="/online-ditigal-contents/desktop/theme1/offer-file-view">
                            File 1
                          </a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="fileCheck1" />
                          <label className="custom-control-label" htmlFor="fileCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/online-ditigal-contents/desktop/theme1/offer-file-view"
                            className="grid-list-item-title link-02"
                          >
                            File 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Folder:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Content Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Size:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Last Modified:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Download:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Owner:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Stage:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 8 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="imgCheckAll"
                            />
                            <label className="custom-control-label" htmlFor="imgCheckAll"></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Type
                        </th>
                        <th scope="col" className="sorting">
                          Folder
                        </th>
                        <th scope="col" className="sorting">
                          Name
                        </th>
                        <th scope="col" className="sorting">
                          Size
                        </th>
                        <th scope="col" className="sorting">
                          Version
                        </th>
                        <th scope="col" className="sorting">
                          Last Modified
                        </th>
                        <th scope="col" className="sorting">
                          Owner
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="imgCheck1"
                            />
                            <label className="custom-control-label" htmlFor="imgCheck1"></label>
                          </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <a href="/online-ditigal-contents/desktop/theme1/image-view">Image 1</a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="imgCheck1" />
                          <label className="custom-control-label" htmlFor="imgCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/online-ditigal-contents/desktop/theme1/image-view"
                            className="grid-list-item-title link-02"
                          >
                            Image 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Folder:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Size:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Version:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Last Modified:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Owner:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {/* pagination */}
          {tab.value != 5 && <Pagination />}
        </div>
      </div>

      <NewCTA />
      <NewLandingPage />
      <NewForm />
      <NewOfferSite />
      <NewEventSite />
      <NewOfferDownlodFiles />
      <NewImage />
      <SelSample />
    </>
  );
};

export default OnlineList;
