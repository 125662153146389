import { ChevronLeft, ChevronRight } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Pricing = () => {
  return (
    <div className="pd-15">
      <div className="card">
        <div className="table-responsive">
          <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f sorting">
                  Item Name
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Base Price
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Price List 1
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Price List 2
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Price List 3
                </th>
                <th scope="col" className="bd-t-0-f">
                  Calendar
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                    Juice-Mint/S
                  </a>
                </td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>
                  <button type="button" className="btn btn-link">
                    View
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                    Juice-Mint/M
                  </a>
                </td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>
                  <button type="button" className="btn btn-link">
                    View
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                    Juice-Mint/L
                  </a>
                </td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>￦100,000</td>
                <td>
                  <button type="button" className="btn btn-link">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer pd-y-6-f pagination">
          <button
            type="button"
            className="btn btn-link link-02 btn-icon"
            aria-label="move to first"
          >
            {Icon('move_first')}
            <span className="sr-only">처음으로</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
            <ChevronLeft />
            <span className="sr-only">이전</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
            <ChevronRight />
            <span className="sr-only">다음</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to last">
            {Icon('move_last')}
            <span className="sr-only">마지막으로</span>
          </button>
          <input type="number" className="paging-input" defaultValue="1" />/ 1
        </div>
      </div>
    </div>
  );
};

export default Pricing;
