import { ChevronLeft, ChevronRight, ExternalLink, Plus, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Item = () => {
  return (
    <div className="pd-15">
      <div className="mg-b-10 text-right">
        <button
          type="button"
          className="btn btn-primary rounded-30"
          data-toggle="modal"
          data-target="#newProductItemModal"
        >
          <Plus className="mg-r-5" />
          New
        </button>
      </div>
      <div className="card">
        <div className="table-responsive">
          <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f sorting">
                  Item Type
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Item Name{' '}
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Unit Name
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Unit Quantity
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Attribute
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Rate
                </th>
                <th scope="col" className="bd-t-0-f wd-70">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>General</td>
                <td>
                  <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                    Juice-Mint/S
                  </a>
                </td>
                <td>Bottle</td>
                <td>1</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td align="center">
                  <button type="button" className="btn btn-link btn-icon pd-y-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Bundle</td>
                <td>
                  <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                    Juice-Mint/M
                  </a>
                </td>
                <td>Set</td>
                <td>1</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td align="center">
                  <button type="button" className="btn btn-link btn-icon pd-y-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Prepaid</td>
                <td>
                  <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                    Juice-Mint/L
                  </a>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td align="center">
                  <button type="button" className="btn btn-link btn-icon pd-y-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer pd-y-6-f pagination">
          <button
            type="button"
            className="btn btn-link link-02 btn-icon"
            aria-label="move to first"
          >
            {Icon('move_first')}
            <span className="sr-only">처음으로</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
            <ChevronLeft />
            <span className="sr-only">이전</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
            <ChevronRight />
            <span className="sr-only">다음</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to last">
            {Icon('move_last')}
            <span className="sr-only">마지막으로</span>
          </button>
          <input type="number" className="paging-input" defaultValue="1" />/ 1
        </div>
      </div>
    </div>
  );
};

export default Item;
