import React from 'react';
import { ChevronDown } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const PriceList = () => {
  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <button type="button" className="view-aside-section-move mg-r-5">
          {Icon('move')}
          <span className="sr-only">드래그앤드롭으로 이동</span>
        </button>
        <span className="view-aside-section-header-title">Pricing</span>
        <button
          type="button"
          className="btn btn-link link-03 mg-l-auto pd-0"
          data-toggle="collapse"
          data-target="#pricingSection"
        >
          <ChevronDown />
        </button>
      </div>
      <div id="pricingSection" className="view-aside-section-body collapse show">
        <div className="d-flex pd-10">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-orange">Base Price</div>
          <div className="mg-l-auto tx-semibold">￦4,000</div>
        </div>
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-color-03">Price List 1</div>
          <div className="mg-l-auto">￦3,500</div>
        </div>
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-color-03">Price List 2</div>
          <div className="mg-l-auto">￦4,500</div>
        </div>
      </div>
    </div>
  );
};

export default PriceList;
