import React, { useState } from 'react';

// import { PopoverBody, Popover, Button } from 'Crm/components/Bootstraps';
import { ChevronDown, HelpCircle, ArrowDown, ArrowUp } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import { Select as SelectHook } from '@base/components/form';

const Feed = () => {
  const [popoverOpen, setPopoverOpen] = useState<any>(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div className="ht-100p pd-x-20">
      <div className="row row-xs ht-100p">
        <div className="col-8 ht-100p pd-y-20 scroll-box">
          <div className="view-content-section">
            <div className="view-content-section-header">
              <button type="button" className="view-aside-section-move mg-r-5">
                {Icon('move')}
                <span className="sr-only">드래그앤드롭으로 이동</span>
              </button>
              <span className="view-aside-section-header-title">Social Account</span>
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto"
                data-toggle="collapse"
                aria-label="collapse"
                data-target="#socialAccount"
              >
                <ChevronDown />
                <span className="sr-only">내용 접기</span>
              </button>
            </div>
            <div id="socialAccount" className="card collapse show">
              <div className="table-responsive">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Networks
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Total Audience
                        <button
                          type="button"
                          id="ta"
                          className="mg-l-5 btn btn-sm btn-link link-03 pd-0"
                        >
                          <HelpCircle />
                        </button>
                        {/* <Popover placement="top" isOpen={popoverOpen} target="ta" toggle={toggle}>
                          <PopoverBody>
                            <div className="wd-200 pd-10">No. of Followers or Fans</div>
                          </PopoverBody>
                        </Popover> */}
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Active Audience
                        <button
                          type="button"
                          id="aa"
                          className="mg-l-5 btn btn-sm btn-link link-03 pd-0"
                        >
                          <HelpCircle />
                        </button>
                        {/* <Popover placement="bottom" isOpen={popoverOpen} target="aa" toggle={toggle}><PopoverBody>
                                                <div className="wd-200 pd-10">People who have engaged with your channel over the past 30days</div>    
                                            </PopoverBody></Popover>  */}
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Engagement
                        <button
                          type="button"
                          id="en"
                          className="mg-l-5 btn btn-sm btn-link link-03 pd-0"
                        >
                          <HelpCircle />
                        </button>
                        {/* <Popover placement="bottom" isOpen={popoverOpen} target="en" toggle={toggle}><PopoverBody>
                                                <div className="wd-200 pd-10">No. of times(Clicks, likes, comments, shares...)</div>    
                                            </PopoverBody></Popover> */}
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Stories Created
                        <button
                          type="button"
                          id="en"
                          className="mg-l-5 btn btn-sm btn-link link-03 pd-0"
                        >
                          <HelpCircle />
                        </button>
                        {/* <Popover placement="bottom" isOpen={popoverOpen} target="en" toggle={toggle}><PopoverBody>
                                                <div className="wd-200 pd-10">No. of times people who have engage with your channel have created stories on their friend's or follower's feed(Shares, retweets...)</div>    
                                            </PopoverBody></Popover> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="social-icon">{Icon('facebook')}</span>Hanbiro
                      </td>
                      <td>
                        <strong className="tx-rubik">80</strong>
                        <span className="tx-12 tx-danger mg-l-3">
                          <ArrowDown size={12} />
                          1%
                        </span>
                      </td>
                      <td>
                        <strong className="tx-rubik">50</strong>
                        <span className="tx-12 tx-success mg-l-3">
                          <ArrowUp size={12} />
                          1%
                        </span>
                      </td>
                      <td>
                        <strong className="tx-rubik">50</strong>
                        <span className="tx-12 tx-success mg-l-3">
                          <ArrowUp size={12} />
                          1%
                        </span>
                      </td>
                      <td>
                        <strong className="tx-rubik">50</strong>
                        <span className="tx-12 tx-success mg-l-3">
                          <ArrowUp size={12} />
                          1%
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="social-icon">{Icon('twitter')}</span>Hanbiro
                      </td>
                      <td>
                        <strong className="tx-rubik">80</strong>
                        <span className="tx-12 tx-danger mg-l-3">
                          <ArrowDown size={12} />
                          1%
                        </span>
                      </td>
                      <td>
                        <strong className="tx-rubik">50</strong>
                        <span className="tx-12 tx-success mg-l-3">
                          <ArrowUp size={12} />
                          1%
                        </span>
                      </td>
                      <td>
                        <strong className="tx-rubik">50</strong>
                        <span className="tx-12 tx-success mg-l-3">
                          <ArrowUp size={12} />
                          1%
                        </span>
                      </td>
                      <td>
                        <strong className="tx-rubik">50</strong>
                        <span className="tx-12 tx-success mg-l-3">
                          <ArrowUp size={12} />
                          1%
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="view-content-section">
            <div className="view-content-section-header">
              <button type="button" className="view-aside-section-move mg-r-5">
                {Icon('move')}
                <span className="sr-only">드래그앤드롭으로 이동</span>
              </button>
              <span className="view-aside-section-header-title">Feed</span>
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto"
                data-toggle="collapse"
                aria-label="collapse"
                data-target="#feed"
              >
                <ChevronDown />
                <span className="sr-only">내용 접기</span>
              </button>
            </div>
            <div id="feed" className="collapse show">
              <SelectHook className="wd-200-f mg-b-10" options={[{ value: 1, label: 'All' }]} />
              <div className="card-group social-card-group">
                <div
                  className="card btn-white cursor-pointer"
                  data-toggle="modal"
                  data-target="#postDetails"
                >
                  <div className="card-header align-items-start">
                    <span className="social-icon">{Icon('facebook')}</span>
                    <div className="mg-l-5 lh-1">
                      <strong>Hanbiro</strong>
                      <div className="mg-t-5 tx-12 tx-color-03">2022-02-10 10:00</div>
                    </div>
                  </div>
                  <div className="card-body">
                    Message
                    <img
                      src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                      className="img-fluid"
                      alt=""
                    />
                    <ul className="list-unstyled mg-t-10 mg-b-0">
                      <li className="d-flex pd-y-5">
                        Likes
                        <span className="mg-l-auto">30</span>
                      </li>
                      <li className="d-flex pd-y-5">
                        Link clicks
                        <span className="mg-l-auto">10</span>
                      </li>
                      <li className="d-flex pd-y-5">
                        Comments
                        <span className="mg-l-auto">20</span>
                      </li>
                      <li className="d-flex pd-y-5">
                        People Reached
                        <span className="mg-l-auto">20</span>
                      </li>
                      <li className="d-flex pd-y-5">
                        Engagement
                        <span className="mg-l-auto">2</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="card btn-white cursor-pointer"
                  data-toggle="modal"
                  data-target="#postDetails"
                >
                  <div className="card-header align-items-start">
                    <span className="social-icon">{Icon('twitter')}</span>
                    <div className="mg-l-5 lh-1">
                      <strong>Hanbiro</strong>
                      <div className="mg-t-5 tx-12 tx-color-03">2022-02-10 10:00</div>
                    </div>
                  </div>
                  <div className="card-body">
                    Message
                    <img
                      src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                      className="img-fluid"
                      alt=""
                    />
                    <ul className="list-unstyled mg-t-10 mg-b-0">
                      <li className="d-flex pd-y-5">
                        Likes
                        <span className="mg-l-auto">30</span>
                      </li>
                      <li className="d-flex pd-y-5">
                        Retweets
                        <span className="mg-l-auto">10</span>
                      </li>
                      <li className="d-flex pd-y-5">
                        Comments
                        <span className="mg-l-auto">20</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 ht-100p pd-y-20 overflow-auto">
          <div className="card">
            <div className="card-header bd-b-0-f tx-bold">Live Stream</div>
            <div className="media pos-relative btn-white pd-15">
              <div className="avatar avatar-sm">
                <img
                  src="http://themepixels.me/demo/dashforge1.1/assets/img/img8.jpg"
                  alt=""
                  className="rounded-circle"
                />
              </div>
              <div className="media-body mg-l-10">
                <span className="social-icon">{Icon('twitter')}</span>
                <strong>Angeline</strong>
                <span className="tx-color-03">@angeline</span> replied.
                <div className="tx-13 tx-color-03">2022-02-10 10:00</div>
              </div>
              <button
                type="button"
                className="btn live-stream-detal-view"
                data-toggle="modal"
                data-target="#streamDetails"
              >
                <span className="sr-only">자세히 보기</span>
              </button>
            </div>
            <div className="media pos-relative btn-white pd-15">
              <div className="avatar avatar-sm">
                <img
                  src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                  alt=""
                  className="rounded-circle"
                />
              </div>
              <div className="media-body mg-l-10">
                <span className="social-icon">{Icon('facebook')}</span>
                <strong>Roy</strong> liked your post.
                <div className="tx-13 tx-color-03">2022-02-10 10:00</div>
              </div>
              <button
                type="button"
                className="btn live-stream-detal-view"
                data-toggle="modal"
                data-target="#streamDetails"
              >
                <span className="sr-only">자세히 보기</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feed;
