import { X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const Sample = () => {
  return (
    <div
      id="selSampleModal"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Select a Sample</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body d-flex flex-column overflow-auto pd-0-f">
            <div className="row row-xs mg-0 ht-100p">
              <div className="col-lg-6 d-flex flex-column ht-100p">
                <ul className="nav nav-line bd-1">
                  <li className="nav-item">
                    <button
                      type="button"
                      data-target="#myFoem"
                      className="btn nav-link pd-x-10-f active"
                      data-toggle="tab"
                    >
                      My
                    </button>
                  </li>
                  <li className="nav-item mg-l-0-f">
                    <button
                      type="button"
                      data-target="#sampleForm"
                      className="btn nav-link pd-x-10-f"
                      data-toggle="tab"
                    >
                      Sample
                    </button>
                  </li>
                </ul>
                <div className="tab-content pd-x-10 overflow-auto">
                  <div id="myFoem" className="tab-pane fade show active">
                    <div className="card-columns temp-columns">
                      <div className="card shadow-sm">
                        <div className="card-body ht-150 overflow-hidden tx-center"></div>
                        <div className="card-footer">Blank</div>
                        <button type="button" className="btn btn-select">
                          <span className="sr-only">선택</span>
                        </button>
                      </div>
                      <div className="card shadow-sm">
                        <div className="card-body ht-150 overflow-hidden tx-center"></div>
                        <div className="card-footer">My 1</div>
                        <button type="button" className="btn btn-select">
                          <span className="sr-only">선택</span>
                        </button>
                      </div>
                      <div className="card shadow-sm">
                        <div className="card-body ht-150 overflow-hidden tx-center"></div>
                        <div className="card-footer">My 2</div>
                        <button type="button" className="btn btn-select active">
                          <span className="sr-only">선택</span>
                        </button>
                      </div>
                      <div className="card shadow-sm">
                        <div className="card-body ht-150 overflow-hidden tx-center"></div>
                        <div className="card-footer">My 3</div>
                        <button type="button" className="btn btn-select">
                          <span className="sr-only">선택</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="sampleForm" className="tab-pane fade">
                    <SelectHook
                      className="wd-200-f mg-t-10"
                      options={[{ value: 1, label: 'All(20)' }]}
                    />
                    <div className="card-columns temp-columns">
                      <div className="card shadow-sm">
                        <div className="card-body ht-150 overflow-hidden tx-center"></div>
                        <div className="card-footer">AAA</div>
                        <button type="button" className="btn btn-select">
                          <span className="sr-only">선택</span>
                        </button>
                      </div>
                      <div className="card shadow-sm">
                        <div className="card-body ht-150 overflow-hidden tx-center"></div>
                        <div className="card-footer">BBB</div>
                        <button type="button" className="btn btn-select">
                          <span className="sr-only">선택</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column ht-100p">
                <div className="pd-y-9">Preview</div>
                <div className="flex-grow-1 overflow-auto mg-b-10 pd-10 bd"></div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sample;
