import { ChevronLeft, ChevronRight, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const RatingCustomerList = () => {
  return (
    <div id="ratingCustomerListModal" className="modal fade right">
      <div className="modal-dialog wd-auto">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Customers and Reps</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-0-f">
            <table className="table mg-b-0">
              <thead>
                <tr>
                  <th scope="col">Customers</th>
                  <th scope="col">Assigned Rep</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="assets/images/notfound.png"
                          alt=""
                          className="bg-gray-200 rounded"
                        />
                      </div>
                      <div className="media-body mg-l-10">A Datum Corporat</div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap">
                      <div className="media align-items-center mg-y-1 mg-r-5">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                      <div className="media align-items-center mg-y-1 mg-r-5">
                        <div className="avatar avatar-xs">
                          <img
                            src="https://n.hanbiro.com/ncrm/user/photo/10/1"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="media-body mg-l-10">KDH</div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                          className="rounded"
                          alt=""
                        />
                      </div>
                      <div className="media-body mg-l-10">Adventure Works</div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap">
                      <div className="media align-items-center mg-y-1 mg-r-5">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-end pd-10 bd-t">
              <button
                type="button"
                className="btn btn-link link-02 btn-icon"
                aria-label="move to first"
              >
                {Icon('move_first')}
                <span className="sr-only">처음으로</span>
              </button>
              <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
                <ChevronLeft />
                <span className="sr-only">이전</span>
              </button>
              <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
                <ChevronRight />
                <span className="sr-only">다음</span>
              </button>
              <button
                type="button"
                className="btn btn-link link-02 btn-icon"
                aria-label="move to last"
              >
                {Icon('move_last')}
                <span className="sr-only">마지막으로</span>
              </button>
              <input type="number" className="form-control wd-70 mg-x-10" defaultValue="1" />/ 1
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingCustomerList;
