import { X } from 'react-feather';

const TicketList = () => {
  return (
    <div
      id="ticketList"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Tickets</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto pd-0-f">
            <div className="table-responsive">
              <table className="table table-bordered mg-b-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f text-nowrap">
                      Subject
                    </th>
                    <th scope="col" className="bd-t-0-f text-nowrap">
                      Category
                    </th>
                    <th scope="col" className="bd-t-0-f text-nowrap">
                      Customer
                    </th>
                    <th scope="col" className="wd-100 bd-t-0-f text-nowrap">
                      Priority
                    </th>
                    <th scope="col" className="wd-100 bd-t-0-f text-nowrap">
                      Due Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="" target="_blank" title="새창으로 열림">
                        Ticket 1
                      </a>
                    </td>
                    <td>Question</td>
                    <td>
                      <span className="d-inline-block avatar avatar-sm mg-r-5">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                          className="rounded-circle"
                          alt=""
                        />
                      </span>
                      Adventure Works
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn bg-danger pd-x-10 pd-t-2 pd-b-4 rounded-30 lh-1 text-nowrap"
                        data-toggle="dropdown"
                      >
                        <span className="tx-white">Urgent</span>
                      </button>
                      <ul className="dropdown-menu pd-10">
                        <li className="mg-b-5">
                          <button type="button" className="btn pd-0">
                            <span className="pd-x-10 pd-t-2 pd-b-4 rounded-30 lh-1 text-nowrap tx-white bg-orange">
                              High
                            </span>
                          </button>
                        </li>
                        <li className="mg-b-5">
                          <button type="button" className="btn pd-0">
                            <span className="pd-x-10 pd-t-2 pd-b-4 rounded-30 lh-1 text-nowrap tx-white bg-info">
                              Normal
                            </span>
                          </button>
                        </li>
                        <li>
                          <button type="button" className="btn pd-0">
                            <span className="pd-x-10 pd-t-2 pd-b-4 rounded-30 lh-1 text-nowrap tx-white bg-secondary">
                              Low
                            </span>
                          </button>
                        </li>
                      </ul>
                    </td>
                    <td>2022-01-28</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketList;
