import { Settings, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import { Select as SelectHook } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';

const Adjustment = () => {
  return (
    <div id="adjustStockModal" className="modal fade right" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Adjust Stock</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label className="form-item-title">Adjusted ID</label>
                <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
                  <input type="text" className="form-control" />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open generator of Order ID modal"
                    >
                      <Settings />
                      <span className="sr-only">ID 생성 설정 열기</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Adjusted Date</label>
                <DatePicker
                  selected={new Date()}
                  onChange={() => {
                    console.log();
                  }}
                />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Reason</label>
                <SelectHook />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Warehouse</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type or click to select a warehouse"
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select warehouse"
                      data-toggle="modal"
                      data-target="#selWarehouseModal"
                    >
                      {Icon('warehouse')}
                      <span className="sr-only">창고 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-12">
                <label className="form-item-title">Items to Adjustment </label>
                <div className="table-responsive bg-white bd rounded-top">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Image
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Item
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Location
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          SKU
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Traceability No
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Stock on Hand
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Qty Adjusted
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          New Stock on Hand
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="avatar avatar-sm bg-gray-200 rounded">
                            <img src="assets/images/notfound.png" alt="" />
                          </div>
                        </td>
                        <td>Item A</td>
                        <td>
                          <SelectHook className="wd-200-f" />
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <input type="text" className="form-control" />
                        </td>
                        <td>&nbsp;</td>
                        <td>
                          <button type="button" className="btn btn-link btn-icon pd-y-0">
                            <X className="tx-danger" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mg-b-10 pd-12 bd bd-t-0 rounded-bottom">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type or click to select a item"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn" aria-label="open selecting item modal">
                        {Icon('product')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-lg btn-success">
              Adjust
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adjustment;
