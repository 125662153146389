import { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Settings,
  Plus,
  List,
  Grid,
  Menu,
  RotateCcw,
  X,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "MarketingCss/marketing.scss"

import NewPrintAds from './modal/newPrintAds';
import NewPrintedOOH from './modal/newPrintedOOH';
import NewPromotionalItem from './modal/newPromotionalItem';
import CheckGuidance from './modal/checkGuidance';
import Pagination from '../../../../../component/desktop/pagination-new';

const TABS = [
  {
    value: 1,
    label: 'Print Ads',
  },
  {
    value: 2,
    label: 'Printed OOH',
  },
  {
    value: 3,
    label: 'Promotional Items',
  },
];

const ColList = () => {
  const [tab, setTab] = useState(TABS[0]);

  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState(false);

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Marketing Collateral</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <button type="button" className="dropdown-item">
                Dashboard
              </button>
              <button type="button" className="dropdown-item">
                Campaign
              </button>
              <button type="button" className="dropdown-item">
                Lead
              </button>
              <button type="button" className="dropdown-item">
                Loyalty
              </button>
              <button type="button" className="dropdown-item">
                Marketing Resouce
              </button>
            </div>
          </div>
          <div className="button-group mg-l-auto">
            <div className="dropdown">
              <button type="button" className="btn btn-primary btn-icon" data-toggle="dropdown">
                <Plus />
                <ChevronDown />
                <span className="sr-only">Add</span>
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newPrintAdsModal"
                >
                  Print Ads
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newPrintedOOHModal"
                >
                  Printed OOH
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newPromotionalItemModal"
                >
                  Promotional Items
                </button>
              </div>
            </div>

            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="list-filter-items">
              {/* for grid */}
              {listType.grid && (
                <div className="dropdown with-sort list-filter-item">
                  <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                    {/* Add 'current-sorting' class when sorting is applied */}
                    {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                    <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
                  </button>
                  <button
                    type="button"
                    className="btn btn-filter pd-l-0"
                    data-toggle="dropdown"
                    aria-label="정렬 옵션 열기"
                  >
                    Sorty by: Created on
                    <ChevronDown />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a role="button" className="dropdown-item">
                      Created on
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* for table list column settings */}
          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {/* tabs */}
          <ul
            className={classnames('nav nav-tabs list-tabs', {
              'bd-b-0': listType.list,
              'bd-1': listType.grid,
            })}
          >
            {TABS.map((item, index) => (
              <li className="nav-item" key={index}>
                <button
                  type="button"
                  className={classnames('btn nav-link', {
                    active: item.value == tab.value,
                    'no-bg': listType.grid,
                  })}
                  onClick={(e) => {
                    setTab(item);
                  }}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>

          {tab.value == 1 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="printAdsCheckAll"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="printAdsCheckAll"
                            ></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Print Ads Type
                        </th>
                        <th scope="col" className="sorting">
                          Name
                        </th>
                        <th scope="col" className="sorting">
                          Content Type
                        </th>
                        <th scope="col" className="sorting">
                          Product
                        </th>
                        <th scope="col" className="sorting">
                          Download
                        </th>
                        <th scope="col" className="sorting">
                          Qty left
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                        <th scope="col" className="sorting">
                          Stage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="printAdsCheck2"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="printAdsCheck2"
                            ></label>
                          </div>
                        </td>
                        <td>Brochure</td>
                        <td>
                          <a href="/marketing-collateral/desktop/theme1/print-ads-view">
                            Content 1
                          </a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>13</td>
                        <td>12</td>
                        <td className="sorting-cell">&nbsp;</td>
                        <td>Active</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="printAdsCheck1"
                          />
                          <label className="custom-control-label" htmlFor="printAdsCheck1"></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/marketing-collateral/desktop/theme1/print-ads-view"
                            className="grid-list-item-title link-02"
                          >
                            Content 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Print Ads Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Content Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Product:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Download:</span>
                            <span className="grid-list-item-col-content">13</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Qty left:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Stage:</span>
                            <span className="grid-list-item-col-content">Active</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 2 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="printedOOHCheckAll"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="printedOOHCheckAll"
                            ></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          OOH Type
                        </th>
                        <th scope="col" className="sorting">
                          Name
                        </th>
                        <th scope="col" className="sorting">
                          Content Type
                        </th>
                        <th scope="col" className="sorting">
                          Product
                        </th>
                        <th scope="col" className="sorting">
                          Qty left
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                        <th scope="col" className="sorting">
                          Stage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="printedOOHCheck1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="printedOOHCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                          <a href="/marketing-collateral/desktop/theme1/printed-ooh-view">
                            Content 1
                          </a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="printedOOHCheck1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="printedOOHCheck1"
                          ></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/marketing-collateral/desktop/theme1/printed-ooh-view"
                            className="grid-list-item-title link-02"
                          >
                            Content 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">OOH Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Content Type:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Product:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Qty left:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Stage:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {tab.value == 3 && (
            <>
              {listType.list && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th scope="col" className="wd-30">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="promotionalItemCheckAll"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="promotionalItemCheckAll"
                            ></label>
                          </div>
                        </th>
                        <th scope="col" className="sorting">
                          Name
                        </th>
                        <th scope="col" className="sorting">
                          Vendor
                        </th>
                        <th scope="col" className="sorting">
                          Quantity
                        </th>
                        <th scope="col" className="sorting">
                          Total Amount
                        </th>
                        <th scope="col" className="sorting">
                          Unit Price
                        </th>
                        <th scope="col" className="sorting">
                          Qty left
                        </th>
                        <th scope="col" className="sorting_desc">
                          Created on
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="promotionalItemCheck1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="promotionalItemCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a href="/marketing-collateral/desktop/theme1/promotional-item-view">
                            Item 1
                          </a>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className="sorting-cell">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {listType.grid && (
                <div className="row row-xs pd-y-15">
                  <div className="col-lg-6 d-flex">
                    <div className="grid-list-item without-pic">
                      <div className="grid-list-item-sel">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="promotionalItemCheck1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="promotionalItemCheck1"
                          ></label>
                        </div>
                      </div>
                      <div className="grid-list-item-detail">
                        <div className="grid-list-item-detail-header">
                          <a
                            href="/marketing-collateral/desktop/theme1/promotional-item-view"
                            className="grid-list-item-title link-02"
                          >
                            ITem 1
                          </a>
                        </div>
                        <div className="grid-list-item-detail-body">
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Vendor:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Quantity:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Total Amount:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Unit Price:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Qty left:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                          <div className="grid-list-item-col">
                            <span className="grid-list-item-col-title">Created on:</span>
                            <span className="grid-list-item-col-content">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>

      <NewPrintAds />
      <NewPrintedOOH />
      <NewPromotionalItem />
      <CheckGuidance />
    </>
  );
};

export default ColList;
