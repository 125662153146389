import { Checkbox } from '@base/components/form';
import { X } from 'react-feather';

const NewProcessStage = () => {
  return (
    <div
      id="addProcessStage"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Stage</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="d-block form-item-title">Stage Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Description</label>
              <input type="text" className="form-control" />
            </div>
            <Checkbox label="Closed Status" />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProcessStage;
