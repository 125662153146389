import { Avatar } from '@base/components';
import { useState } from 'react';
import { Edit2, Clock, Paperclip, Image, X, CornerUpLeft } from 'react-feather';

const Comment = () => {
  const [showReply, setShowReply] = useState<boolean>();

  return (
    <div className="pd-20">
      <div className="message-input-container">
        <div className="message-input_inner">
          <div className="message-input" contentEditable="true"></div>
          <div className="message-input-placeholder" tabIndex={-1}>
            Enter a message
          </div>
        </div>
        <div className="message-input-buttons">
          <button className="btn btn-link link-03">
            <Paperclip />
            <span className="sr-only">첨부파일 / Attachment</span>
          </button>
          <button className="btn btn-link link-03">
            <Image />
            <span className="sr-only">이미지 삽입 / Insert image</span>
          </button>
          <button type="button" className="btn btn-primary mg-l-auto">
            Post
          </button>
        </div>
      </div>
      <div className="timeline-group note-timeline mg-t-20">
        <div className="timeline-label">2021-03-17</div>
        <div className="timeline-item">
          <div className="timeline-icon">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
              className="rounded-circle"
              alt="Vendor 2"
            />
          </div>
          <div className="timeline-body">
            <div className="card">
              <div className="d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bd-b">
                <span>
                  Vendor 2
                  <span className="mg-l-10 tx-color-03">
                    <Clock size="16" className="mg-r-5" />
                    13:00
                  </span>
                </span>
                <div className="mg-l-auto">
                  <button
                    type="button"
                    className="btn btn-link btn-icon"
                    data-han-tooltip="Reply"
                    onClick={() => setShowReply(true)}
                  >
                    <CornerUpLeft />
                    <span className="sr-only">답글</span>
                  </button>
                  <button type="button" className="btn btn-link btn-icon" data-han-tooltip="Edit">
                    <Edit2 />
                    <span className="sr-only">수정</span>
                  </button>
                  <button type="button" className="btn btn-link btn-icon" data-han-tooltip="Delete">
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
              <div className="pd-15">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <ul className="list-unstyled mg-b-0">
                  <li>
                    <Paperclip size="16" className="tx-color-03" />
                    <button type="button" className="btn btn-link pd-y-2">
                      abc.doc
                    </button>
                  </li>
                  <li>
                    <Paperclip size="16" className="tx-color-03" />
                    <button type="button" className="btn btn-link pd-y-2">
                      def.pdf
                    </button>
                  </li>
                </ul>
                <div className="media mg-t-10 pd-t-10 bd-t">
                  <Avatar
                    width={35}
                    height={35}
                    url="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                    alt="KDH"
                  />
                  <div className="media-body mg-l-10">
                    <span>
                      KDH
                      <span className="mg-l-10 tx-color-03">
                        <Clock size="16" className="mg-r-5" />
                        14:00
                      </span>
                    </span>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                  </div>
                </div>
                {showReply && (
                  <div className="message-input-container mg-t-20">
                    <div className="message-input_inner">
                      <div className="message-input" contentEditable="true"></div>
                      <div className="message-input-placeholder" tabIndex={-1}>
                        Enter a message
                      </div>
                    </div>
                    <div className="message-input-buttons">
                      <button className="btn btn-link link-03">
                        <Paperclip />
                        <span className="sr-only">첨부파일 / Attachment</span>
                      </button>
                      <button className="btn btn-link link-03">
                        <Image />
                        <span className="sr-only">이미지 삽입 / Insert image</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-light mg-l-auto"
                        onClick={() => setShowReply(false)}
                      >
                        Cancel
                      </button>
                      <button type="button" className="btn btn-primary mg-l-5">
                        Post
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-icon">
            <img
              src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
              className="rounded-circle"
              alt="MSR"
            />
          </div>
          <div className="timeline-body">
            <div className="card">
              <div className="d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bd-b">
                <span>
                  KDH
                  <span className="mg-l-10 tx-color-03">
                    <Clock size="16" className="mg-r-5" />
                    10:00
                  </span>
                </span>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link btn-icon" data-han-tooltip="Edit">
                    <Edit2 />
                    <span className="sr-only">수정</span>
                  </button>
                  <button type="button" className="btn btn-link btn-icon" data-han-tooltip="Delete">
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
              <div className="pd-15">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam arcu ligula,
                  ornare sit amet porttitor quis, placerat quis lacus. Mauris lectus turpis, rutrum
                  non placerat id, placerat et nisl.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
