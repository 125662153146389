import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  CheckCircle,
  Circle,
  ArrowLeft,
  Printer,
  Plus,
  MoreHorizontal,
  Truck,
  Download,
  X,
  Settings,
  Minus,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import noImg from '@base/assets/img/notfound.png';

import '../style.scss';

import { COLORS } from '@base/config/constant';

import { Select as SelectHook } from '@base/components/form';

import Attachments from '../../../../component/desktop/attachments';
import Notelist from '../../../../component/desktop/notelist';
import AssignedRep from '../../../../component/desktop/assigned-rep';
import Userlist from '../../../../component/desktop/userlist';

import Timeline from '../timeline';
import PrintPackingSlip from './modal/printPackingSlip';

const TABS = [
  {
    value: 1,
    label: 'Items to Ship',
  },
  {
    value: 2,
    label: 'Pick',
  },
  {
    value: 3,
    label: 'Pack',
  },
  {
    value: 4,
    label: 'Ship',
  },
  {
    value: 5,
    label: 'Timeline',
  },
  {
    value: 6,
    label: 'Note',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLORS[0]);

  const [showAddPackageType, setShowAddPackageType] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/shipment/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Shipment
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/warehouse/desktop/theme1/list">Warehouse</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/inventory-items/desktop/theme1/list">Inventory Items</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/adjustment/desktop/theme1/list">Adjustment</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/transfer-order/desktop/theme1/list">Stock Transfer</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/receipt/desktop/theme1/list">Receipt</a>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/shipment/desktop/theme1/list">Shipment</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
            </div>
          </div>
          <h1 className="view-title">SHI-00001</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">{Icon('export_pdf')}</span>Export to PDF
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Printer />
                    </span>
                    Print
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Stage 1</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>New</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 2</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 3</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 4</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Stage 5</div>
            </li>
          </ol>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Customer</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                          className="rounded"
                          alt="Adventure Works"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <a href="" target="_blank" title="새창으로 열림">
                          Adventure Works
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Reference</label>
                    <a href="" target="_blank" title="새창으로 열림">
                      AA
                    </a>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Reference ID</label>
                    <a href="" target="_blank" title="새창으로 열림">
                      SO-000001
                    </a>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Source Warehouse</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Scheduled on</label>
                    <div>2021/06/04</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Tracking No</label>
                    <div>30012233450</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Courier</label>
                    <div>Postal Service</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Shiped on</label>
                    <div>2021/06/04</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Shipped by</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">MSR</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {/* Items to ship */}
              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="table-responsive mg-b-10 bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f wd-60">
                            Image
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Item
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Unit Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Unit Qty
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Ordered
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Picked
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Packed
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            QTY Shipped
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="avatar avatar-sm bg-gray-200 rounded">
                              <img src={noImg} alt="" />
                            </div>
                          </td>
                          <td>
                            <a href="" target="_blank" title="새창으로 열림">
                              Item 1
                            </a>
                          </td>
                          <td>Box</td>
                          <td>12</td>
                          <td>100</td>
                          <td>100</td>
                          <td>100</td>
                          <td>100</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* Pick */}
              {tab.value == 2 && (
                <div className="pd-20">
                  <div className="d-flex justify-content-end mg-b-20">
                    <SelectHook className="wd-300-f" options={[{ value: 1, label: 'New' }]} />
                  </div>

                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Picked on</label>
                      <div>2021/06/04</div>
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Picked by</label>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">
                          <span className="user-name">MSR</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="view-content-section mg-b-20">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Items to Picked</span>
                      <button type="button" className="btn btn-info mg-l-auto">
                        <span className="mg-r-5">{Icon('scan_barcode')}</span>Scan Barcode
                      </button>
                    </div>
                    <div className="table-responsive bg-white bd rounded mg-t-10">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f wd-60">
                              Image
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Items
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Qty
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty to Pick
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              SKU
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Traceability No
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Pick Location
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              QTY Picked
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Pick Cart No
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="avatar avatar-sm bg-gray-200 rounded">
                                <img src={noImg} alt="" />
                              </div>
                            </td>
                            <td>
                              <a
                                href=""
                                target="_blank"
                                title="새창으로 열림"
                                className="align-middle"
                              >
                                Item 1
                              </a>
                            </td>
                            <td>Bottle</td>
                            <td>1</td>
                            <td>100</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <SelectHook
                                  className="wd-200-f"
                                  options={[
                                    { value: 1, label: 'Ite-red-sma' },
                                    { value: 2, label: 'Ite-red-smb' },
                                  ]}
                                />
                                <button type="button" className="btn btn-link btn-icon">
                                  <Plus />
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <SelectHook
                                  className="wd-200-f"
                                  options={[
                                    { value: 1, label: '5-5-5-5' },
                                    { value: 2, label: '5-5-5-4' },
                                    { value: 2, label: '5-5-5-3' },
                                  ]}
                                />
                                <button type="button" className="btn btn-link btn-icon">
                                  <Plus />
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <SelectHook
                                  className="wd-200-f"
                                  options={[{ value: 1, label: 'B1-F2-SA-01-A-03' }]}
                                />
                                <button type="button" className="btn btn-link btn-icon">
                                  <Plus />
                                </button>
                              </div>
                            </td>
                            <td>
                              <input type="number" className="form-control" />
                            </td>
                            <td>
                              <SelectHook options={[{ value: 1, label: 'C01' }]} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between">
                      220 Items to Pick
                      <span>
                        <strong className="tx-danger">0</strong> Items left to Pick
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Check List</label>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="shipmentItemCheck1"
                      />
                      <label className="custom-control-label" htmlFor="shipmentItemCheck1">
                        Put the items in the Packing Zone
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* Pack */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="d-flex justify-content-end mg-b-20">
                    <div className="btn-group">
                      <button type="button" className="btn btn-primary">
                        <span className="mg-r-5">
                          <Truck />
                        </span>
                        Get Tracking No
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle-split"
                        data-toggle="dropdown"
                      >
                        <ChevronDown />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right wd-100p pd-y-0">
                        <div className="dropdown-header pd-x-10 bg-light bd-b">
                          <Printer size={16} className="mg-r-10 tx-color-03" />
                          Print
                        </div>
                        <button type="button" className="dropdown-item">
                          Picking Slip
                        </button>
                        <button type="button" className="dropdown-item">
                          Shipping Label
                        </button>
                        <div className="dropdown-header pd-x-10 bg-light bd-y">
                          <Download size={16} className="mg-r-10 tx-color-03" />
                          Download
                        </div>
                        <button type="button" className="dropdown-item">
                          Picking Slip
                        </button>
                        <button type="button" className="dropdown-item">
                          Shipping Label
                        </button>
                      </div>
                    </div>
                    <SelectHook
                      className="wd-300-f mg-l-5"
                      options={[{ value: 1, label: 'New' }]}
                    />
                  </div>

                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Packed by</label>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">
                          <span className="user-name">MSR</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Packed on</label>
                      <div>2021/06/04</div>
                    </div>
                  </div>

                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Packaging</span>
                    </div>
                    <div className="table-responsive bg-white bd rounded-top">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Package Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Package ID
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty Packed Items
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Packed Weight
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Delivery
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Courier
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Tracking No
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <SelectHook
                                className="wd-150-f"
                                options={[{ value: 1, label: 'Small Boxes' }]}
                              />
                            </td>
                            <td>
                              <div className="input-group wd-200">
                                <input type="text" className="form-control" />
                                <div className="input-group-append">
                                  <button type="button" className="btn">
                                    <Settings />
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td>100</td>
                            <td>
                              <div className="input-group wd-150">
                                <input type="number" className="form-control" />
                                <div className="input-group-append">
                                  <span className="input-group-text lh-1">kg</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <SelectHook
                                className="wd-200-f"
                                options={[{ value: 1, label: 'Delivery service' }]}
                              />
                            </td>
                            <td>
                              <SelectHook
                                className="wd-150-f"
                                options={[{ value: 1, label: 'DHL' }]}
                              />
                            </td>
                            <td>
                              <input type="text" className="form-control" />
                            </td>
                            <td>
                              <button type="button" className="btn btn-icon">
                                <X className="tx-danger" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="bg-white pd-10 bd bd-t-0 rounded-bottom">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type or click to select a package type"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <ChevronDown />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="view-content-section mg-b-20">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Items to Pack</span>
                      <button type="button" className="btn btn-info mg-l-auto">
                        <span className="mg-r-5">{Icon('scan_barcode')}</span>Scan Barcode
                      </button>
                    </div>
                    <div className="table-responsive bg-white bd rounded-top mg-t-10">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f wd-60">
                              Image
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Items
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Quantity
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              SKU
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Traceability No
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Qty to pack
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Pick Cart No
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Packed Qty
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Package Type
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td rowSpan={showAddPackageType ? 2 : 1}>
                              <div className="avatar avatar-sm bg-gray-200 rounded">
                                <img src={noImg} alt="" />
                              </div>
                            </td>
                            <td rowSpan={showAddPackageType ? 2 : 1}>
                              <a href="" target="_blank" title="새창으로 열림">
                                Item 1
                              </a>
                            </td>
                            <td rowSpan={showAddPackageType ? 2 : 1}>Box</td>
                            <td rowSpan={showAddPackageType ? 2 : 1}>12</td>
                            <td rowSpan={showAddPackageType ? 2 : 1}>Jui-Bot-Min</td>
                            <td rowSpan={showAddPackageType ? 2 : 1}>3-20211231</td>
                            <td rowSpan={showAddPackageType ? 2 : 1}>12</td>
                            <td rowSpan={showAddPackageType ? 2 : 1}>C01</td>
                            <td>
                              <input type="number" className="form-control wd-80" />
                            </td>
                            <td>
                              <div className="d-flex">
                                <SelectHook
                                  className="wd-200-f"
                                  options={[{ value: 1, label: 'Small Boxes' }]}
                                />
                                <button
                                  type="button"
                                  className="btn btn-link btn-icon"
                                  onClick={() => setShowAddPackageType(true)}
                                >
                                  <Plus />
                                  <span className="sr-only">추가</span>
                                </button>
                                <button type="button" className="btn btn-icon">
                                  <Minus className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                            </td>
                          </tr>
                          {showAddPackageType && (
                            <tr>
                              <td>
                                <input type="number" className="form-control wd-80" />
                              </td>
                              <td>
                                <div className="d-flex">
                                  <SelectHook
                                    className="wd-200-f"
                                    options={[{ value: 1, label: 'Small Boxes' }]}
                                  />
                                  <button type="button" className="btn btn-link btn-icon">
                                    <Plus />
                                    <span className="sr-only">추가</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-icon"
                                    onClick={() => setShowAddPackageType(false)}
                                  >
                                    <Minus className="tx-danger" />
                                    <span className="sr-only">삭제</span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between">
                      40 Items to Pick
                      <span>
                        <strong className="tx-danger">40</strong> Items left to Pick
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-item-title">Check List</label>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="packingItemCheck1"
                      />
                      <label className="custom-control-label" htmlFor="packingItemCheck1">
                        Print and put a Packing Slip
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="packingItemCheck2"
                      />
                      <label className="custom-control-label" htmlFor="packingItemCheck2">
                        Inspect Item Condition
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="packingItemCheck3"
                      />
                      <label className="custom-control-label" htmlFor="packingItemCheck3">
                        Sticks the fragile label on
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="packingItemCheck4"
                      />
                      <label className="custom-control-label" htmlFor="packingItemCheck4">
                        Sticks the shipping label on
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="packingItemCheck5"
                      />
                      <label className="custom-control-label" htmlFor="packingItemCheck5">
                        Puts the package in the ‘Awaiting Pickup’ Zone
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* Ship */}
              {tab.value == 4 && (
                <div className="pd-20">
                  <div className="d-flex justify-content-end mg-b-20">
                    <div className="btn-group">
                      <button type="button" className="btn btn-primary">
                        <span className="mg-r-5">
                          <Truck />
                        </span>
                        Get Gracking No
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle-split"
                        data-toggle="dropdown"
                      >
                        <ChevronDown />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right wd-100p pd-y-0">
                        <div className="dropdown-header pd-x-10 bg-light bd-b">
                          <Printer size={16} className="mg-r-10 tx-color-03" />
                          Print
                        </div>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#printPackingSlipModal"
                        >
                          Packing Slip
                        </button>
                        <button type="button" className="dropdown-item">
                          Shipping Label
                        </button>
                        <div className="dropdown-header pd-x-10 bg-light bd-y">
                          <Download size={16} className="mg-r-10 tx-color-03" />
                          Download
                        </div>
                        <button type="button" className="dropdown-item">
                          Packing Slip
                        </button>
                        <button type="button" className="dropdown-item">
                          Shipping Label
                        </button>
                      </div>
                    </div>
                    <SelectHook
                      className="wd-300-f mg-l-5"
                      options={[{ value: 1, label: 'New' }]}
                    />
                  </div>

                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Shiped by</label>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">
                          <span className="user-name">MSR</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Shiped on</label>
                      <div>2021/06/04</div>
                    </div>
                  </div>

                  <div className="view-content-section mg-b-20">
                    <div className="view-content-section-header">
                      <span className="view-aside-section-header-title">Packages to Ship</span>
                    </div>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header">
                          <span className="tx-bold">Package 1</span>
                          <div className="custom-control custom-checkbox mg-l-auto">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="shipingItemConfirmation1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="shipingItemConfirmation1"
                            >
                              Confirmation
                            </label>
                          </div>
                        </div>
                        <div className="card-body pd-0">
                          <div className="table-responsive bd-y mg-b-15">
                            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                              <thead>
                                <tr>
                                  <th scope="col" className="bd-t-0-f wd-60">
                                    Image
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Items
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Unit Name
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Unit Quantity
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    SKU
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Traceability No
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    QTY to Ship
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="avatar avatar-sm bg-gray-200 rounded">
                                      <img src={noImg} alt="" />
                                    </div>
                                  </td>
                                  <td>
                                    <a href="" target="_blank" title="새창으로 열림">
                                      Item 1
                                    </a>
                                  </td>
                                  <td>Box</td>
                                  <td>12</td>
                                  <td>Jui-Bot-CheMin</td>
                                  <td>3-20211231</td>
                                  <td>12</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="form-row mg-x-10">
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Package ID</label>
                              <div>p220116</div>
                            </div>
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Weight</label>
                              <div>10kg</div>
                            </div>
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Delivery Type</label>
                              <div>Delivery service</div>
                            </div>
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Courier</label>
                              <div>DHL</div>
                            </div>
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Tracking No</label>
                              <div>t-20211231</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <span className="tx-bold">Package 2</span>
                          <div className="custom-control custom-checkbox mg-l-auto">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="shipingItemConfirmation2"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="shipingItemConfirmation2"
                            >
                              Confirmation
                            </label>
                          </div>
                        </div>
                        <div className="card-body pd-0">
                          <div className="table-responsive bd-y mg-b-15">
                            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                              <thead>
                                <tr>
                                  <th scope="col" className="bd-t-0-f wd-60">
                                    Image
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Items
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Unit Name
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Unit Quantity
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    SKU
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Traceability No
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    QTY to Ship
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="avatar avatar-sm bg-gray-200 rounded">
                                      <img src={noImg} alt="" />
                                    </div>
                                  </td>
                                  <td>
                                    <a href="" target="_blank" title="새창으로 열림">
                                      Item 3
                                    </a>
                                  </td>
                                  <td>Box</td>
                                  <td>20</td>
                                  <td>Jui-Bot-CheMin</td>
                                  <td>3-20211231</td>
                                  <td>10</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="form-row mg-x-10">
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Package ID</label>
                              <div>p220116</div>
                            </div>
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Weight</label>
                              <div>25kg</div>
                            </div>
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Delivery Type</label>
                              <div>Delivery service</div>
                            </div>
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Courier</label>
                              <div>DHL</div>
                            </div>
                            <div className="form-group col-4 col-lg-3">
                              <label className="form-item-title">Tracking No</label>
                              <div>t-20211231</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      2 Packages to Ship
                      <span>
                        <strong className="tx-danger">30</strong> Packages left to ship
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-item-title">Check List</label>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="shipingItemCheck1"
                      />
                      <label className="custom-control-label" htmlFor="shipingItemCheck1">
                        Check the Pacakges and order details
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="shipingItemCheck1"
                      />
                      <label className="custom-control-label" htmlFor="shipingItemCheck1">
                        Shipping label is sticked on
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {tab.value == 5 && <Timeline />}
              {tab.value == 6 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Contact */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Contact</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#activityCustomer"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="activityCustomer" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <PrintPackingSlip />
    </>
  );
};

export default ViewNew;
