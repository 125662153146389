import { useState } from 'react';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';
import { ChevronUp, Clock, User, X } from 'react-feather';
import SelectHook from '@base/components/form/select-hook';

const EmailWrite = () => {
  const [sendSchedule, setSendSchedule] = useState('now');
  const handleSendScheduleChange = (e: any) => {
    const { value } = e.target;
    setSendSchedule(value);
  };

  return (
    <>
      <div id="newEmailModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Create Email</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-item-title d-block">Send Schedule</label>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="sendScheduleRadio1"
                    name="sendScheduleRadio"
                    className="custom-control-input"
                    value="now"
                    checked={sendSchedule === 'now'}
                    onChange={(e) => handleSendScheduleChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="sendScheduleRadio1">
                    Send Now
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="sendScheduleRadio2"
                    name="sendScheduleRadio"
                    className="custom-control-input"
                    value="schedule"
                    checked={sendSchedule === 'schedule'}
                    onChange={(e) => handleSendScheduleChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="sendScheduleRadio2">
                    Schedule
                  </label>
                </div>
                <div className="d-flex align-items-center mg-t-10">
                  <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" id="sendBatch" />
                    <label className="custom-control-label" htmlFor="sendBatch">
                      Send in Batches
                    </label>
                  </div>
                  : interval <input type="number" className="form-control wd-100 mg-x-10" />
                  Minute(s)
                </div>
              </div>
              {sendSchedule === 'schedule' && (
                <div className="form-group">
                  <label className="form-item-title">Send Date</label>
                  <div className="input-group wd-300">
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Clock />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group">
                <label className="form-item-title">Subject</label>
                <input type="text" className="form-control form-control-lg" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Priority</label>
                <SelectHook className="wd-300-f" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Purpose</label>
                <div className="wd-50p">
                  <SelectHook />
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Template</label>
                <div className="wd-50p">
                  <SelectHook
                    options={[
                      { value: 1, label: 'No Template' },
                      { value: 2, label: 'Template 1' },
                      { value: 3, label: 'Template 2' },
                    ]}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Content</label>
                <div>Editor</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">From</label>
                <div className="dropdown">
                  <div className="input-group" data-toggle="dropdown">
                    {/*  before selecting an user 
										<input type="text" className="form-control" placeholder="Type or click to select an user /> 
									*/}
                    <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="text-truncate">John&lt;sales@hanbiro.com&gt;</span>
                      </div>
                      <button type="button" className="btn" aria-label="delete">
                        <X />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select an user"
                      >
                        <User />
                        <span className="sr-only">담당자 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                  <div className="dropdown-menu wd-100p">
                    <button type="button" className="dropdown-item">
                      Assign to me
                    </button>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-item-title">Description</label>
                <input type="text" className="form-control form-control-lg" />
              </div>

              <div className="form-group">
                <label className="form-item-title">To</label>
                <div className="input-group">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="d-block avatar avatar-xs mg-r-10">
                        <span className="avatar-initial rounded-circle">S</span>
                      </span>
                      <span className="form-control-tag-name">
                        Socrates&lt;sales@domain.com&gt;
                      </span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250 rounded"
                      placeholder="Type or click to select a receiver"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a customer"
                    >
                      {Icons('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-item-title">CC</label>
                <div className="input-group">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="d-block avatar avatar-xs mg-r-10">
                        <span className="avatar-initial rounded-circle">K</span>
                      </span>
                      <span className="form-control-tag-name">
                        Kirby Avendula&lt;kirby@domain.com&gt;
                      </span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <div className="form-control-tag">
                      <span className="d-block avatar avatar-xs mg-r-10">
                        <img
                          src="https://global3.hanbiro.com/ncrm/user/photo/3/1"
                          className="rounded-circle"
                        />
                      </span>
                      <span className="form-control-tag-name">
                        Angeline Mercado&lt;angeline@domain.com&gt;
                      </span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250 rounded"
                      placeholder="Type or click to select a receiver"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a customer"
                    >
                      {Icons('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-item-title">BCC</label>
                <div className="input-group">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="d-block avatar avatar-xs mg-r-10">
                        <span className="avatar-initial rounded-circle">I</span>
                      </span>
                      <span className="form-control-tag-name">
                        Isidore Dilao&lt;dilao@domain.com&gt;
                      </span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250 rounded"
                      placeholder="Type or click to select a receiver"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a customer"
                    >
                      {Icons('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-item-title">Attachments</label>
                <div></div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Cancel
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailWrite;
