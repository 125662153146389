import { useState } from 'react';
import { Select as SelectHook } from '@base/components/form';

const Subscription = () => {
  const [checked, setChecked] = useState<any>(false);

  return (
    <div className="pd-20">
      <div className="form-group">
        <label className="form-item-title">Enable Subscription</label>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="subscriptionSwitch"
            onChange={(e) => setChecked(e.target.checked)}
          />
          <label className="custom-control-label" htmlFor="subscriptionSwitch"></label>
        </div>
      </div>
      {checked && (
        <>
          <div className="form-group">
            <label className="form-item-title">Subscription Template</label>
            <div className="d-flex align-items-center">
              <SelectHook className="wd-200-f" options={[{ value: 1, label: 'Monthly Plan A' }]} />
              <button type="button" className="btn btn-link">
                Preview
              </button>
            </div>
          </div>
          <div className="card">
            <div className="card-header tx-bold">Monthly Plan</div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-item-title d-block">Related Item</label>
                <div className="d-inline-block mg-r-10">
                  <div className="avatar avatar-sm d-inline-block mg-r-10 bg-gray-200 rounded">
                    <img src="assets/images/notfound.png" alt="" />
                  </div>
                  Item 1
                </div>
                <div className="d-inline-block mg-r-10">
                  <div className="avatar avatar-sm d-inline-block mg-r-10 bg-gray-200 rounded">
                    <img src="assets/images/notfound.png" alt="" />
                  </div>
                  Item 2
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Closable by Customer</label>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="closableSwitch" />
                  <label className="custom-control-label" htmlFor="closableSwitch"></label>
                </div>
              </div>
              <div className="form-group-title">Billing Plan</div>
              <div className="form-group">
                <label className="form-item-title">Default Term</label>
                <div>Monthly</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Terms&amp;Discount</label>
                <ul className="list-unstyled">
                  <li>1 Month(s): 0%</li>
                  <li>6 Month(s): 5%</li>
                  <li>12 Month(s): 10%</li>
                </ul>
              </div>
              <div className="form-group">
                <label className="form-item-title">Billing Cycle</label>
                <div>Expires after 3 Cycles</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Payment Term </label>
                <div>Before 7 days of the Start Date</div>
                <div className="custom-control custom-switch mg-t-10">
                  <input type="checkbox" className="custom-control-input" id="paymentSwitch" />
                  <label className="custom-control-label" htmlFor="paymentSwitch">
                    Immediately for 1st Billing
                  </label>
                </div>
              </div>
              <div className="form-group-title">Order Plan</div>
              <div className="form-group">
                <label className="form-item-title">Default Term</label>
                <div>Monthly</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Ordering Cycle</label>
                <div>1 Time(s) per 1 Month(s)</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Ordering Date</label>
                <div className="d-flex align-items-center">Every Monday</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Shipment Date</label>
                <div className="d-flex align-items-center">Every Tuesday</div>
                <div className="custom-control custom-switch mg-t-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="createSalesOrderSwitch"
                  />
                  <label className="custom-control-label" htmlFor="createSalesOrderSwitch">
                    Create Sales Order when invoice status is paid
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Subscription;
