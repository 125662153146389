import { ChevronRight, ChevronLeft } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Visitors = () => {
  return (
    <div className="pd-20">
      <div className="card">
        <div className="table-responsive">
          <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f sorting">
                  IP
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Media
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  First Page
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Visited Pages
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Last Visited on
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Lead Capture
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Opportunity
                </th>
                <th scope="col" className="bd-t-0-f sorting_desc">
                  Sales
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>10</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td className="sorting-cell">＄500</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer pd-y-6-f pagination">
          <button
            type="button"
            className="btn btn-link link-02 btn-icon"
            aria-label="move to first"
          >
            {Icon('move_first')}
            <span className="sr-only">처음으로</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
            <ChevronLeft />
            <span className="sr-only">이전</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
            <ChevronRight />
            <span className="sr-only">다음</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to last">
            {Icon('move_last')}
            <span className="sr-only">마지막으로</span>
          </button>
          <input type="number" className="paging-input" defaultValue="1" />/ 1
        </div>
      </div>
    </div>
  );
};

export default Visitors;
