import { useState } from 'react';
import { Check, Edit2, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const AssItems = () => {
  const [showEdit, SetShowEdit] = useState<any>();

  return (
    <div className="pd-20">
      {!showEdit && (
        <div className="text-right">
          <button type="button" className="btn btn-link" onClick={() => SetShowEdit(true)}>
            <Edit2 className="mg-r-5" />
            Edit
          </button>
        </div>
      )}
      <div className="table-responsive bg-white bd rounded">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr className="bg-light">
              <th scope="col" className="bd-t-0-f">
                Image{' '}
              </th>
              <th scope="col" className="bd-t-0-f">
                Item Name{' '}
              </th>
              <th scope="col" className="bd-t-0-f">
                SKU
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit Name
              </th>
              <th scope="col" className="bd-t-0-f">
                Unit Quantity
              </th>
              <th scope="col" className="bd-t-0-f">
                Attributes
              </th>
              <th scope="col" className="bd-t-0-f">
                Contained Qty
              </th>
              <th scope="col" className="bd-t-0-f">
                Base Price
              </th>
              <th scope="col" className="bd-t-0-f">
                Base Cost Price
              </th>
              <th scope="col" className="bd-t-0-f">
                Best Price
              </th>
              {showEdit && (
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              )}
            </tr>
          </thead>
          <tfoot>
            <tr className="bg-light">
              <td colSpan={showEdit ? 8 : 7} className="text-right">
                Total
              </td>
              <td>￦6,000</td>
              <td>￦3,000</td>
              <td>￦4,500</td>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td>
                <div className="avatar avatar-sm d-inline-block mg-r-10 bg-gray-200 rounded">
                  <img src="assets/images/notfound.png" alt="" />
                </div>
              </td>
              <td>Big Bottle</td>
              <td>Big-Bot- Lar</td>
              <td>Bottle</td>
              <td>1</td>
              <td>L</td>
              <td>{showEdit ? <input type="number" className="form-control" /> : '2'}</td>
              <td>￦4,000</td>
              <td>￦2,000</td>
              <td>￦3,000</td>
              {showEdit && (
                <td>
                  <button type="button" className="btn btn-link btn-icon pd-y-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              )}
            </tr>
            <tr>
              <td>
                <div className="avatar avatar-sm d-inline-block mg-r-10 bg-gray-200 rounded">
                  <img src="assets/images/notfound.png" alt="" />
                </div>
              </td>
              <td>Snack Small</td>
              <td>SNA-PAC-S</td>
              <td>Pack</td>
              <td>1</td>
              <td>S</td>
              <td>{showEdit ? <input type="number" className="form-control" /> : '1'}</td>
              <td>￦2,000</td>
              <td>￦1,000</td>
              <td>￦1,500</td>
              {showEdit && (
                <td>
                  <button type="button" className="btn btn-link btn-icon pd-y-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      {showEdit && (
        <div className="mg-b-10 pd-12 bg-white bd bd-t-0 rounded-bottom">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select an item"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                {Icon('product')}
              </button>
            </div>
          </div>
        </div>
      )}
      {showEdit && (
        <div className="mg-t-10 text-right">
          <button
            type="button"
            className="btn btn-light mg-r-10"
            onClick={() => SetShowEdit(false)}
          >
            <X className="ma-r-5" /> Cancel
          </button>
          <button
            type="button"
            className="btn btn-success mg-r-10"
            onClick={() => SetShowEdit(false)}
          >
            <Check className="ma-r-5" /> Edit
          </button>
        </div>
      )}
    </div>
  );
};

export default AssItems;
