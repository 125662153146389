import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Printer,
  Trash,
  ChevronDown,
  Plus,
  X,
  Mail,
  Menu,
  ExternalLink,
  ChevronsLeft,
  ChevronsRight,
  ArrowLeft,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

//import { PopoverBody, Popover, Button } from 'Crm/components/Bootstraps';

//import 'CrmCss/desktop/theme1/theme1.scss';
//import 'CustomerCss/customer.scss';

import CustomerViewActivity from './view/timeline';
import CustomerViewTransaction from './view/transaction';
import CustomerViewProfile from './view/profile';
import Attachments from '../../../component/desktop/attachments';
import AssignedRep from '../../../component/desktop/assigned-rep';
import Userlist from '../../../component/desktop/userlist';
import Notelist from '../../../component/desktop/notelist';
import Chat from '../../../component/desktop/chat';
import CustomerProfile from './modal/newCustomerProfile';

const TABS = [
  {
    value: 1,
    label: 'Activities',
  },
  {
    value: 2,
    label: 'Note',
  },
  {
    value: 3,
    label: 'Transaction',
  },
  {
    value: 4,
    label: 'Statement',
  },
  {
    value: 5,
    label: 'Trade Agreement',
  },
  {
    value: 6,
    label: 'Team Channel',
  },
  {
    value: 7,
    label: 'Profile',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];
const ViewNew = () => {
  const [tab, setTab] = useState<any>(TABS[6]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  const [popoverOpen, setPopoverOpen] = useState<any>(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const [headerColor, setHeaderColor] = useState<any>(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/customer/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Account
            <ChevronDown className="mg-l-5" />
          </button>
          <div className="dropdown-menu nav-sub">
            <div className="dropdown-item active">
              <a role="button">Account</a>
              <button
                type="button"
                className="btn btn-xs btn-set-default btn-icon"
                aria-label="Set as default"
              >
                {Icon('set_default')}
                <span className="sr-only">기본 메뉴로 설정</span>
              </button>
            </div>
            <div className="dropdown-item">
              <a role="button">Contact</a>
            </div>
            <div className="dropdown-item">
              <a role="button">Segment</a>
            </div>
            <div className="dropdown-item">
              <a href="/crm/demo-page/ui/marketing-list/desktop/theme1/list">Marketing List</a>
            </div>
            <div className="dropdown-item">
              <a href="/crm/demo-page/ui/buyer-persona/desktop/theme1/list">Buyer Persona</a>
            </div>
            <div className="dropdown-item">
              <a href="/crm/demo-page/ui/icp/desktop/theme1/list">Ideal Customer Profile</a>
            </div>
            <div className="dropdown-item">
              <a href="/crm/demo-page/ui/blocklist/desktop/theme1/list">Blocklist</a>
            </div>
          </div>
        </div>

        <div
          className="d-inline-block flex-shrink-0 wd-30 ht-30 mg-x-10 pd-0 bd-0 rounded-circle text-center lh-12 han-tooltip"
          data-han-tooltip="Gold"
          style={{ backgroundColor: '#bc9546' }}
        >
          <span className="tx-white tx-semibold">G</span>
        </div>
        <h1 className="view-title">Adventure Works</h1>

        <div className="button-group flex-shrink-0 mg-l-auto">
          <button
            type="button"
            data-han-tooltip="Export to PDF"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">{Icon('export_pdf')}</span>
            <span className="sr-only">PDF로 내려받기</span>
          </button>
          <button
            type="button"
            data-han-tooltip="Print"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">
              <Printer />
            </span>
            <span className="sr-only">인쇄</span>
          </button>
          <button
            type="button"
            data-han-tooltip="Delete"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">
              <Trash />
            </span>
            <span className="sr-only">삭제</span>
          </button>
          <button
            type="button"
            data-han-tooltip="More"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">
              <Menu />
            </span>
            <span className="sr-only">더보기</span>
          </button>
          <button type="button" className="btn btn-primary mg-l-5 rounded-30">
            <Plus size="14" />
            New
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-section pd-15">
            <div className="d-flex align-items-center justify-content-center">
              <div className="view-customer-pic">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&usqp=CAU"
                  className="rounded"
                  alt=""
                />
              </div>
            </div>
            <div className="mg-t-15">
              <div className="d-flex pd-10">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">
                  Outstanding Receivable
                </div>
                <div className="mg-l-auto  tx-15 tx-semibold">￦1,000,000</div>
              </div>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">
                  Unused Credits
                </div>
                <div className="mg-l-auto  tx-15 tx-semibold">￦1,000,000</div>
              </div>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">Income</div>
                <div className="mg-l-auto  tx-15 tx-semibold">￦100,000</div>
              </div>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-orange">Debt</div>
                <div className="mg-l-auto  tx-15 tx-semibold">￦100,000</div>
              </div>
            </div>
          </div>

          {/* Loyalty */}
          <div className="view-aside-section">
            <div className="view-aside-section-header">
              <button type="button" className="view-aside-section-move mg-r-5">
                {Icon('move')}
                <span className="sr-only">드래그앤드롭으로 이동</span>
              </button>
              <span className="view-aside-section-header-title ">Loyalty</span>
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto pd-0"
                data-toggle="collapse"
                data-target="#loyaltySection"
              >
                <ChevronDown />
              </button>
            </div>
            <div id="loyaltySection" className="view-aside-section-body collapse show">
              <div className="media mg-b-5">
                <div className="wd-40 ht-40 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                  {Icon('point')}
                </div>
                <div className="media-body">
                  <div className="tx-uppercase tx-12 tx-spacing-1 tx-info tx-nowrap">Point(s)</div>
                  <div className="tx-18 ">0</div>
                </div>
              </div>
              <div className="media mg-b-5">
                <div className="wd-40 ht-40 bg-purple tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                  {Icon('stamp')}
                </div>
                <div className="media-body">
                  <div className="tx-uppercase tx-12 tx-spacing-1 tx-purple tx-nowrap">
                    Stamp(s)
                  </div>
                  <div className="tx-18 ">0</div>
                </div>
              </div>
              <div className="media">
                <div className="wd-40 ht-40 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                  {Icon('coupon')}
                </div>
                <div className="media-body">
                  <div className="tx-uppercase tx-12 tx-spacing-1 tx-pink tx-nowrap">Coupon(s)</div>
                  <div className="tx-18 tx-obotot"></div>
                </div>
              </div>
            </div>
          </div>

          {/* Basic Info */}
          <div className="view-aside-section">
            <div className="view-aside-section-header">
              <button type="button" className="view-aside-section-move mg-r-5">
                {Icon('move')}
                <span className="sr-only">드래그앤드롭으로 이동</span>
              </button>
              <span className="view-aside-section-header-title">Basic Info</span>
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto pd-0"
                data-toggle="collapse"
                data-target="#basicInfoSection"
              >
                <ChevronDown />
              </button>
            </div>
            <div id="basicInfoSection" className="view-aside-section-body collapse show">
              <div className="form-group">
                <label className="form-item-title">ID</label>
                <div>adventureworks</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Email</label>
                <div>
                  <span className="badge badge-dark mg-r-5">Company</span>adventureworks@domain.com
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Phone</label>
                <div>
                  <span className="badge badge-dark mg-r-5">Company</span>+1 518-445-5696
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Industry</label>
                <div>Leports</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Number of Employee</label>
                <div>50</div>
              </div>
              <div className="form-group">
                <label className="form-item-title d-block">Tags</label>
                <div className="tag-item">
                  Tag 1
                  <button type="button" className="btn-tag-remove">
                    <X />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
                <div className="tag-item">
                  Tag 2
                  <button type="button" className="btn-tag-remove">
                    <X />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Detail Info */}
          <div className="view-aside-section">
            <div className="view-aside-section-header">
              <button type="button" className="view-aside-section-move mg-r-5">
                {Icon('move')}
                <span className="sr-only">드래그앤드롭으로 이동</span>
              </button>
              <span className="view-aside-section-header-title">Detail Info</span>
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto pd-0 collapsed"
                data-toggle="collapse"
                data-target="#detailInfoSection"
              >
                <ChevronDown />
              </button>
            </div>
            <div id="detailInfoSection" className="view-aside-section-body collapse">
              <div className="form-group">
                <label className="form-item-title">Address</label>
                <div>
                  <span className="badge badge-dark mg-r-5">Company</span>Park Way Cupertino, CA
                  95014
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Ranking</label>
                <div className="d-flex align-items-center">
                  <div
                    className="wd-30 ht-30 mg-r-5 pd-0 bd-0 rounded-circle text-center lh-12"
                    style={{ backgroundColor: '#bc9546' }}
                  >
                    <span className="tx-white tx-semibold">G</span>
                  </div>
                  Gold
                  <button type="button" id="rankingScore" className="mg-l-auto btn btn-link pd-0">
                    (Score: 50)
                  </button>
                  {/* <Popover
                                        placement="bottom"
                                        isOpen={popoverOpen}
                                        target="rankingScore"
                                        toggle={toggle}
                                    >
                                        <PopoverBody>
                                            <ul className="list-unstyled mg-y-10">
                                                <li className="d-flex align-items-center justify-content-between pd-x-10">
                                                    Monthly Payment: <span className="mg-l-10">20</span>
                                                </li>
                                                <li className="d-flex align-items-center justify-content-between pd-x-10 mg-t-5">
                                                    Length of Business Relationship: <span className="mg-l-10">15</span>
                                                </li>
                                                <li className="d-flex align-items-center justify-content-between pd-x-10 mg-t-5">
                                                    Total Payments (Past): <span className="mg-l-10">15</span>
                                                </li>
                                            </ul>
                                        </PopoverBody>
                                    </Popover> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames('view-content', {
            'col-6': !hideAsideLeft && !hideAsideRight,
            'col-9': (hideAsideLeft && !hideAsideRight) || (hideAsideRight && !hideAsideLeft),
            'col-12': hideAsideLeft && hideAsideRight,
          })}
        >
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* collapse button */}
            <button
              type="button"
              className={classnames(
                'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                { hidden: hideAsideLeft },
              )}
              onClick={() => setHideAsideLeft(!hideAsideLeft)}
            >
              <ChevronsLeft />
              <span className="sr-only">좌측 사이드 패널 접기</span>
            </button>

            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map(
                  (item) =>
                    item.value < 4 && (
                      <li
                        className={classnames('nav-item', {
                          active: item.value == tab.value,
                        })}
                      >
                        <button
                          type="button"
                          className="btn btn-xs btn-move-tab"
                          aria-label="Drag-and-drop to reorder"
                        >
                          {Icon('move')}
                          <span className="sr-only">드래그앤드롭으로 이동</span>
                        </button>
                        <button
                          type="button"
                          className="btn nav-link"
                          onClick={(e) => {
                            setTab(item);
                          }}
                        >
                          {item.label}
                        </button>
                      </li>
                    ),
                )}
                <li
                  className={classnames('nav-item dropdown pd-0-f', {
                    active: tab.value > 3,
                  })}
                >
                  <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                    <Plus className="mg-r-5" />
                    {tab.value > 3 ? tab.label : 'More'}
                  </button>
                  <div className="dropdown-menu dropdown-menu-right bg-white">
                    {TABS.map(
                      (item) =>
                        item.value > 3 && (
                          <div className="dropdown-item">
                            <button
                              type="button"
                              className="btn btn-xs btn-move-tab"
                              aria-label="Drag-and-drop to reorder"
                            >
                              {Icon('move')}
                              <span className="sr-only">드래그앤드롭으로 이동</span>
                            </button>
                            <button
                              type="button"
                              className={classnames('btn', {
                                active: item.value == tab.value,
                              })}
                              onClick={(e) => {
                                setTab(item);
                              }}
                            >
                              {item.label}
                            </button>
                          </div>
                        ),
                    )}
                  </div>
                </li>
              </ul>
            </div>

            {/* collapse button */}
            <button
              type="button"
              className={classnames(
                'btn btn-purple rounded-0 mg-l-10 bd-r-0 btn-collapse-aside-right',
                { hidden: hideAsideRight },
              )}
              onClick={() => setHideAsideRight(!hideAsideRight)}
            >
              <ChevronsRight />
              <span className="sr-only">우측 사이드 패널 접기</span>
            </button>
          </div>

          <div className="view-body">
            {tab.value == 1 && <CustomerViewActivity />}
            {tab.value == 2 && <Notelist />}
            {tab.value == 3 && <CustomerViewTransaction />}
            {tab.value == 6 && <Chat />}
            {tab.value == 7 && <CustomerViewProfile />}
          </div>
        </div>

        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
          style={{ transformOrigin: 'left' }}
        >
          {/* Company Info */}
          <div className="view-aside-section">
            <div className="view-aside-section-header">
              <button type="button" className="view-aside-section-move mg-r-5">
                {Icon('move')}
                <span className="sr-only">드래그앤드롭으로 이동</span>
              </button>
              <span className="view-aside-section-header-title">Company Info</span>
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto pd-0"
                data-toggle="collapse"
                data-target="#associateInfoSection"
              >
                <ChevronDown />
              </button>
            </div>
            <div id="associateInfoSection" className="view-aside-section-body collapse show">
              <div className="form-group">
                <label className="form-item-title d-block">Name</label>
                <a href="">
                  Adventure Works
                  <ExternalLink size="14" className="mg-l-5" />
                </a>
              </div>
              <div className="form-group">
                <label className="form-item-title">Website</label>
                <div>http://adventureworks.com</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Phone</label>
                <div>+1 518-445-5696</div>
              </div>
            </div>
          </div>

          {/* Contact */}
          <div className="view-aside-section">
            <div className="view-aside-section-header">
              <button type="button" className="view-aside-section-move mg-r-5">
                {Icon('move')}
                <span className="sr-only">드래그앤드롭으로 이동</span>
              </button>
              <span className="view-aside-section-header-title">Contact</span>
              <button
                type="button"
                className="btn btn-link link-03 mg-l-auto pd-0"
                data-toggle="collapse"
                data-target="#customerContact"
              >
                <ChevronDown />
              </button>
            </div>
            <div id="customerContact" className="view-aside-section-body collapse show">
              <Userlist />
            </div>
          </div>

          {/* Assigned Rep. */}
          <AssignedRep />

          {/* Attachment */}
          <Attachments />
        </div>
      </div>

      <CustomerProfile />
    </div>
  );
};

export default ViewNew;
