import { Button, Checkbox } from '@base/components/form';

const PreviewAgreement = ({ showFullConsent }: any) => {
  return (
    <div id="previewAgreement" className="modal fade">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title h6">Preview</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body bg-light">
            <div className="pd-15 bg-white bd rounded">
              {showFullConsent && (
                <>
                  <Checkbox label="Full Consent(including Optional)" />
                  <hr className="mg-y-10" />
                </>
              )}
              <div className="d-flex align-items-center pd-y-5">
                <Checkbox label="Agreement Name 1 (Required)" />
                <a href="" className="mg-l-auto" style={{ textDecoration: 'underline' }}>
                  more detail
                </a>
              </div>
              <div className="d-flex align-items-center pd-y-5">
                <Checkbox label="Agreement Name 2 (Required)" />
              </div>
              <div className="d-flex align-items-center pd-y-5">
                <Checkbox label="Agreement Name 3 (Optional)" />
                <a href="" className="mg-l-auto" style={{ textDecoration: 'underline' }}>
                  more detail
                </a>
              </div>
              <div className="mg-t-10 pd-t-10 bd-t text-right">
                <Button color="success" name="Submit" />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PreviewAgreement;
