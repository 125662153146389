import { useState } from 'react';
import { User, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';

const Reminder = () => {
  const [sendMode, setSendMode] = useState('email');
  const handleSendModeChange = (e: any) => {
    const { value } = e.target;
    setSendMode(value);
  };

  return (
    <div
      id="sendReminderModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog full-modal">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Send Payment Reminder</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group d-flex align-items-center">
              <label className="form-item-title wd-150">Send Mode</label>
              <div className="flex-fill">
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="sendModeRadio1"
                    name="sendModeRadio"
                    className="custom-control-input"
                    value="email"
                    checked={sendMode === 'email'}
                    onChange={(e) => handleSendModeChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="sendModeRadio1">
                    Email
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="sendModeRadio2"
                    name="sendModeRadio"
                    className="custom-control-input"
                    value="sms"
                    checked={sendMode === 'sms'}
                    onChange={(e) => handleSendModeChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="sendModeRadio2">
                    SMS
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group d-flex align-items-center">
              <label className="form-item-title wd-150">
                {sendMode === 'email' ? 'From' : 'Sender'}
              </label>
              <div className="flex-fill">
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="form-group d-flex align-items-center">
              <label className="form-item-title wd-150">
                {sendMode === 'email' ? 'To' : 'Receiver'}
              </label>
              <div className="dropdown flex-fill">
                <div className="input-group">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <span className="form-control-tag">
                      <span className="form-control-tag-name">
                        Employee 1 {sendMode === 'email' ? '<sales@mail.com>' : '<0102221111>'}
                      </span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </span>
                    <input
                      type="text"
                      className="wd-200 bd-0"
                      placeholder="Type or click to select an user"
                    />
                  </div>
                  <span className="input-group-append">
                    <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                      <User />
                    </button>
                  </span>
                </div>
                <div className="dropdown-menu wd-100p"></div>
              </div>
            </div>
            {sendMode === 'email' && (
              <div className="form-group d-flex align-items-center">
                <label className="form-item-title wd-150">CC</label>
                <div className="dropdown flex-fill">
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <span className="form-control-tag">
                        <span className="form-control-tag-name">
                          Employee 1 &lt;sales@mail.com&gt;
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </span>
                      <input
                        type="text"
                        className="wd-200 bd-0"
                        placeholder="Type or click to select an user"
                      />
                    </div>
                    <span className="input-group-append">
                      <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                        <User />
                      </button>
                    </span>
                  </div>
                  <div className="dropdown-menu wd-100p"></div>
                </div>
              </div>
            )}
            <div className="form-group d-flex align-items-center">
              <label className="form-item-title wd-150">Template</label>
              <div className="flex-fill d-flex">
                <SelectHook className="wd-200-f" />
                <button type="button" className="btn btn-link">
                  Preview
                </button>
              </div>
            </div>
            <div className="form-group d-flex align-items-center">
              <label className="form-item-title wd-150">Subject</label>
              <div className="flex-fill">
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="form-group d-flex align-items-center">
              <label className="form-item-title wd-150">Message</label>
              <div className="flex-fill"></div>
            </div>
            {sendMode === 'email' && (
              <div className="form-group d-flex align-items-center">
                <label className="form-item-title wd-150">Attach Invoice PDF</label>
                <div className="flex-fill">
                  <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                    <label className="custom-control-label" htmlFor="customSwitch1"></label>
                  </div>
                </div>
              </div>
            )}
            <div className="form-group d-flex align-items-center">
              <label className="form-item-title wd-150">Attached File</label>
              <div className="flex-fill"></div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-light mg-r-5" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reminder;
