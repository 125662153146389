/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import { Plus, Trash, Database } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import '@base/components/sidebar-menu/styles.scss';
import './billing.scss';

import ImageUploader from '@base/components/form/image-uploader';

import TerminationComfirm from './modal/terminationConfirm';
import UserPurchasing from './modal/userPurchasing';
import StoragePurchasing from './modal/storagePurchasing';
import LicensePurchasing from './modal/licensePurchasing';
import ReduceUser from './modal/reduceUser';
import NewBillingPlan from './modal/newBillingPlan';
import { Button } from '@base/components/form';

const NAVS = [
  {
    value: 'information',
    label: 'Billing Information',
  },
  {
    value: 'licenses',
    label: 'Licenses',
  },
  {
    value: 'payment',
    label: 'Bills & Payment',
  },
  {
    value: 'history',
    label: 'History',
  },
];

const BillingLicenses = () => {
  const [nav, setNav] = useState(NAVS[0]);
  const [hideSideNav, setSideNav] = useState(false);
  const [showNewContact, setShowNewContact] = useState(false);

  const [listType, setListType] = useState<any>({
    list: false,
    period: true,
  });

  return (
    <div className={classnames('page-container-wrap', { 'hide-sidebar': hideSideNav })}>
      <div className="page-menu-wrap">
        <div className="page-menu-nav-sidebar">
          <ul className="page-menu-sidebar-nav">
            {NAVS.map((item, index) => (
              <li className="page-menu-nav-item" key={index}>
                <button
                  className={classnames('btn page-menu-nav-link', {
                    active: item.value == nav.value,
                  })}
                  onClick={(e) => {
                    setNav(item);
                  }}
                >
                  {Icon(item.value)}
                  <span className="mg-l-5">{item.label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="page-container-content">
        <div className="page-container-content-header">
          <Button
            color=""
            className="btn-toggle-sidebar tx-gray-400"
            icon="ChevronLeft"
            onClick={() => setSideNav(!hideSideNav)}
          />
          <h1 className="mg-x-10 mg-b-0 tx-16 tx-uppercase tx-normal">
            Bill &amp; License &gt; {nav.label}
          </h1>
        </div>
        <div className="scroll-box pd-20 tab-content">
          {nav.value === 'information' && (
            <div className="card-columns settings-columns">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label className="form-item-title">Account Holder Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Your Logo</label>
                    <ImageUploader isSingle={true} type={'img'} />
                    <div className="mg-t-5 tx-info">
                      This logo will apeear on the many documents.
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact */}
              <div className="card">
                <div className="card-header h6 bg-light">Contact</div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Role</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showNewContact && (
                        <tr className="bd-b">
                          <td>
                            <input type="text" className="form-control" />
                          </td>
                          <td>
                            <input type="text" className="form-control" />
                          </td>
                          <td>
                            <input type="emal" className="form-control" />
                          </td>
                          <td>
                            <input type="tel" className="form-control" />
                          </td>
                          <td>
                            <input type="tel" className="form-control" />
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-link link-03 btn-icon pd-y-0"
                              onClick={() => setShowNewContact(false)}
                            >
                              <Trash />
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pd-12">
                  <div className="dropdown">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to select a contact"
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open modal to select a contact"
                        >
                          {Icon('m_customer')}
                          <span className="sr-only">선택 팝업 열기</span>
                        </button>
                      </div>
                    </div>
                    <div className="dropdown-menu wd-100p">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setShowNewContact(true)}
                      >
                        <Plus className="mg-r-5" />
                        Add new contact
                      </button>
                      <div className="dropdown-divider"></div>
                      <button type="button" className="dropdown-item">
                        Marianne Audrey
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Address */}
              <div className="card">
                <div className="card-header bg-light with-tabs">
                  <nav className="nav nav-line">
                    <button type="button" className="btn nav-link active" data-toggle="tab">
                      Billing Address
                    </button>
                    <button type="button" className="btn nav-link" data-toggle="tab">
                      Email Billing Address
                    </button>
                    <button type="button" className="btn nav-link" data-toggle="tab">
                      Ship To
                    </button>
                  </nav>
                </div>
                <div className="card-body"></div>
              </div>

              <div className="card">
                <div className="card-header h6 bg-light">사업자 등록증</div>
                <div className="card-body">
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label className="form-item-title">등록번호</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-item-title">법인명</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-item-title">대표자</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group col-12">
                      <label className="form-item-title">사업장 소재지</label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="customCheck1">
                          청구 주소와 동기화
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-item-title">업태</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-item-title">종목</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group col-12">
                      <label className="form-item-title">Your Logo</label>
                      <ImageUploader isSingle={true} type={'img'} />
                      <div className="mg-t-5 tx-info">
                        This logo will apeear on the many documents.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Payment Method */}
              <div className="card">
                <div className="card-header h6 bg-light">Payment Method</div>
                <div className="card-body">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="billingPayMethod"
                      id="billingPayMethodRadio1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="billingPayMethodRadio1">
                      청구서와 가상계좌를 보내주면 결제합니다.
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="billingPayMethod"
                      id="billingPayMethodRadio2"
                    />
                    <label className="custom-control-label" htmlFor="billingPayMethodRadio2">
                      신용카드로 기한 내 온라인 결제합니다.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {nav.value === 'licenses' && (
            <>
              {/* Licenses Used */}
              <div className="card">
                <div className="card-header d-flex align-items-center bg-light">
                  <h2 className="card-title h6 mg-b-0">Licenses Used</h2>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger mg-l-auto"
                    data-toggle="modal"
                    data-target="#terminationConfirm"
                  >
                    Terminate all
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Item</th>
                        <th scope="col">Description</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Unit</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Billing</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          CRM
                        </td>
                        <td>CRM 10 users</td>
                        <td>&nbsp;</td>
                        <td>10</td>
                        <td>Free</td>
                        <td>User</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            data-toggle="modal"
                            data-target="#terminationConfirm"
                          >
                            Terminate
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>CRM Add a user</td>
                        <td>&nbsp;</td>
                        <td>5</td>
                        <td>3,000</td>
                        <td>User</td>
                        <td>15,000</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            data-toggle="modal"
                            data-target="#reduceUser"
                          >
                            Reduce
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">Online Storage</td>
                        <td>10G Storage</td>
                        <td>&nbsp;</td>
                        <td>1</td>
                        <td>10,000</td>
                        <td>10G</td>
                        <td>10,000</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            data-toggle="modal"
                            data-target="#terminationConfirm"
                          >
                            Terminate
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">HR</td>
                        <td>HR 10 users</td>
                        <td>&nbsp;</td>
                        <td>10</td>
                        <td>Free</td>
                        <td>User</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            data-toggle="modal"
                            data-target="#terminationConfirm"
                          >
                            Terminate
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row row-sm mg-y-10">
                <div className="col-xl-4">
                  {/* Online Storage Used */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <h2 className="card-title h6 mg-b-0">Online Storage Used</h2>
                    </div>
                    <div className="card-body">
                      <div className="media mg-y-9">
                        <Database className="wd-35 ht-35 stroke-wd-1" />
                        <div className="media-body mg-l-10">
                          <div className="mg-b-4">5GB used of 11GB(/a Domain)</div>
                          <div className="progress ht-5 op-7">
                            <div
                              className="progress-bar bg-orange wd-45p"
                              role="progressbar"
                              aria-valuenow={45}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#addStoragePurchasing"
                      >
                        Purchase Online Storage
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  {/* Current Recharge Status */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <h2 className="card-title h6 mg-b-0">Current Recharge Status</h2>
                    </div>
                    <div className="card-body">
                      <label className="d-block tx-color-03">Allow to use Services</label>
                      <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="rechargeStatusCheck1"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="rechargeStatusCheck1">
                          Sending Mail (Free: 100/month)
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="rechargeStatusCheck2"
                        />
                        <label className="custom-control-label" htmlFor="rechargeStatusCheck2">
                          Bulk Email
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="rechargeStatusCheck3"
                        />
                        <label className="custom-control-label" htmlFor="rechargeStatusCheck3">
                          SMS
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="rechargeStatusCheck4"
                        />
                        <label className="custom-control-label" htmlFor="rechargeStatusCheck4">
                          Digital Tax Invoice
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="rechargeStatusCheck5"
                        />
                        <label className="custom-control-label" htmlFor="rechargeStatusCheck5">
                          Virtual Billing Account
                        </label>
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <div>
                        <label className="mg-r-10 tx-color-03">The Balance Left</label>
                        <strong className="tx-16 tx-danger">￦0</strong>
                      </div>
                      <div>
                        <label className="mg-r-10 tx-color-03">Recharging</label>
                        <button type="button" className="btn btn-white">
                          View History
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Licensing Guide */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">Licensing Guide</h2>
                </div>
                <nav className="nav nav-line bd-1">
                  <a href="#licenseAllItems" className="nav-link active pd-15-f" data-toggle="tab">
                    All Items
                  </a>
                  <a href="#licenseFreeItems" className="nav-link pd-15-f" data-toggle="tab">
                    Free Items
                  </a>
                  <a href="#licensePaidItems" className="nav-link pd-15-f" data-toggle="tab">
                    Paid Items
                  </a>
                  <a href="#licenseRechargeItems" className="nav-link pd-15-f" data-toggle="tab">
                    Recharge Items
                  </a>
                </nav>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Item</th>
                        <th scope="col">Description</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Unit</th>
                        <th scope="col">Billing</th>
                        <th scope="col">Purchasing</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          CRM
                        </td>
                        <td>CRM 10 users</td>
                        <td>&nbsp;</td>
                        <td>Free</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addUserPurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>CRM Add a user</td>
                        <td>&nbsp;</td>
                        <td>3,000</td>
                        <td>User</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addUserPurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          CRM Call
                        </td>
                        <td>Call Setting</td>
                        <td>&nbsp;</td>
                        <td>180,000</td>
                        <td>Set</td>
                        <td>One time</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Call Add a user</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>User</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addUserPurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          HR
                        </td>
                        <td>HR 10 users</td>
                        <td>&nbsp;</td>
                        <td>Free</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addUserPurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>HR Add a user</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addUserPurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3} className="align-middle">
                          Online Storage
                        </td>
                        <td>1G Storage</td>
                        <td>&nbsp;</td>
                        <td>Free</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addStoragePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>10G Storage</td>
                        <td>&nbsp;</td>
                        <td>10,000</td>
                        <td>10G</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addStoragePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>30G Storage</td>
                        <td>&nbsp;</td>
                        <td>20,000</td>
                        <td>30G</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addStoragePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3} className="align-middle">
                          Email
                        </td>
                        <td>Email</td>
                        <td>&nbsp;</td>
                        <td>1,000</td>
                        <td>10G</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addStoragePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Sending Email</td>
                        <td>&nbsp;</td>
                        <td>5,000</td>
                        <td>1,000 Messages</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addStoragePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Recharge sending Email</td>
                        <td>&nbsp;</td>
                        <td>0.5</td>
                        <td>&nbsp;</td>
                        <td>Recharge</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          Project
                        </td>
                        <td>Project 10 users</td>
                        <td>&nbsp;</td>
                        <td>Free</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addStoragePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Project Add a user</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addUserPurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          Team Channel
                        </td>
                        <td>Team Channel 10 users</td>
                        <td>&nbsp;</td>
                        <td>Free</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Team Channel Add a user</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addUserPurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          SMS
                        </td>
                        <td>SMS</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Message</td>
                        <td>Recharge</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>LMS</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Message</td>
                        <td>Recharge</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>Digital Tax Invoice</td>
                        <td>&nbsp;</td>
                        <td>150</td>
                        <td>&nbsp;</td>
                        <td>Recharge</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>Virtual Billing Account</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Billing Account</td>
                        <td>Recharge</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3} className="align-middle bd-b-0-f">
                          Fax
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Fax number</td>
                        <td>Monthly</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#addStoragePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Document</td>
                        <td>Recharge</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link btn-icon"
                            data-toggle="modal"
                            data-target="#licensePurchasing"
                          >
                            {Icon('cart_plus')}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* /card-body */}
              </div>
            </>
          )}
          {nav.value === 'payment' && (
            <div className="card-columns settings-columns">
              {/* Billing Plan */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">Billing Plan</h2>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mg-l-auto"
                    data-toggle="modal"
                    data-target="#newBillingPlan"
                  >
                    <Plus />
                    Add new plan
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Plan Name</th>
                        <th scope="col">Billing cycle</th>
                        <th scope="col">Start on</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a href="#newBillingPlan" data-toggle="modal" className="link-02">
                            Plan AAA
                          </a>
                        </td>
                        <td>6 Monthly</td>
                        <td>01/27/2021</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Payment Schedule per a Item Purchased  */}
              <div className="card">
                <div className="card-header d-flex align-items-center bg-light">
                  <h2 className="card-title h6 mg-b-0">Payment Schedule per a Item Purchased</h2>
                  <div className="btn-group mg-l-auto">
                    <button
                      type="button"
                      className={classnames('btn btn-white btn-icon', { active: listType.period })}
                      onClick={() => setListType({ period: true })}
                    >
                      {Icon('bar_chart')}
                    </button>
                    <button
                      type="button"
                      className={classnames('btn btn-white btn-icon', { active: listType.list })}
                      onClick={() => setListType({ list: true })}
                    >
                      {Icon('table')}
                    </button>
                  </div>
                </div>
                {listType.period && (
                  <div className="table-responsive">
                    <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                      <thead>
                        <tr>
                          <th rowSpan={2} scope="col" className="align-middle">
                            Item
                          </th>
                          <th colSpan={2} scope="colgroup" className="pd-y-10-f bd-1-f text-center">
                            Billing Period
                          </th>
                        </tr>
                        <tr>
                          <th scope="col" className="pd-y-10-f text-center">
                            2020
                          </th>
                          <th scope="col" className="pd-y-10-f text-center">
                            2021
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <td>CRM Add a user</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </table>
                  </div>
                )}

                {listType.list && (
                  <div className="table-responsive">
                    <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                      <thead>
                        <tr>
                          <th scope="col">Item</th>
                          <th scope="col" className="text-nowrap">
                            Purchased on
                          </th>
                          <th scope="col" className="text-nowrap">
                            Billing Date
                          </th>
                          <th scope="col" className="text-nowrap">
                            Start on
                          </th>
                          <th scope="col" className="text-nowrap">
                            End on
                          </th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>CRM Add a user</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="card-footer">
                  <button type="button" className="btn btn-outline-primary mg-r-5">
                    Edit
                  </button>
                  <button type="button" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>

              {/* Invoice */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">Invoices</h2>
                </div>
                <div className="card-body">
                  <p>
                    Your Next Payment of <strong className="tx-16">$5,000</strong> is due on{' '}
                    <span className="tx-primary">Feb 26, 2020</span>.
                  </p>
                  <div>
                    Current Balance <strong className="tx-16 tx-danger">$5,000</strong>
                    <button type="button" className="btn btn-sm btn-white mg-l-10">
                      View
                    </button>
                  </div>
                  <hr />
                  <label className="tx-color-03">Order Detail</label>
                  <div className="table-responsive rounded bd">
                    <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                      <thead>
                        <tr>
                          <th scope="col">Item</th>
                          <th scope="col">Qty</th>
                          <th scope="col" className="text-nowrap">
                            Unit Price
                          </th>
                          <th scope="col">Unit</th>
                          <th scope="col">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end mg-t-10">
                    <ul className="list-unstyled lh-10 wd-lg-40p mg-0 pd-x-20 pd-y-10">
                      <li className="d-flex justify-content-between">
                        <span>Sub-Total</span>
                        <span></span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <span>Balance</span>
                        <span>-5,000</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <span>Tax</span>
                        <span></span>
                      </li>
                      <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-semibold">
                        <span>Total</span>
                        <span></span>
                      </li>
                    </ul>
                  </div>
                  <div className="mg-t-10 text-right">
                    <button type="button" className="btn btn-outline-primary mg-r-5">
                      Request Virtual Billing Account
                    </button>
                    <button type="button" className="btn btn-primary">
                      Make Payment
                    </button>
                  </div>
                  <hr />
                  <label className="tx-color-03">Invoice Issued</label>
                  <div className="table-responsive rounded bd">
                    <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col" className="text-nowrap">
                            Invoice Name
                          </th>
                          <th scope="col" className="text-nowrap">
                            Total Amount
                          </th>
                          <th scope="col">Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer tx-16 font-weight-bold">Total Revenue :</div>
              </div>
            </div>
          )}
          {nav.value === 'history' && (
            <div className="card-columns settings-columns">
              {/* Transaction History */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">Transaction History</h2>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col" className="text-nowrap">
                          Activity Type
                        </th>
                        <th scope="col">Item</th>
                        <th scope="col">Note</th>
                        <th scope="col">Amount</th>
                        <th scope="col" className="text-nowrap">
                          TRansaction ID
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2020/11/16</td>
                        <td>Sent Invoice</td>
                        <td>&nbsp;</td>
                        <td>Add 100 user(s) of ITem AAA</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* SMS Usage */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">SMS Usage</h2>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">User</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Mail Sending Usage */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">Mail Sending Usage</h2>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">User</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Fax Usage */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">Fax Usage</h2>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">User</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Virtual Billing Account Usage */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">Virtual Billing Account Usage</h2>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">User</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Digital Tax Invoice Usage */}
              <div className="card">
                <div className="card-header bg-light">
                  <h2 className="card-title h6 mg-b-0">Digital Tax Invoice Usage</h2>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">User</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <TerminationComfirm />
      <UserPurchasing />
      <StoragePurchasing />
      <LicensePurchasing />
      <ReduceUser />
      <NewBillingPlan />
    </div>
  );
};

export default BillingLicenses;
