import { useState } from 'react';
import classnames from 'classnames';

import { Plus, CheckCircle, Circle } from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
import './style.scss';

import PurchaseHeader from './view/header';
import RFT from './view/rft';
import PurchaseViewTimeline from './timeline';
import Attachments from '../../../component/desktop/attachments';
import NewPR from './modal/newPR';
import NewPO from './modal/newPO';
import NewRFQ from './modal/newRFQ';
import NewRFT from './modal/newRFT';
import AddVendor from './modal/addVendor';
import NewVendor from './modal/newVendor';
import NewPurchaseReturn from './modal/newPurchaseReturn';
import AssignedRep from '@demo-page/component/desktop/assigned-rep';
import PR from './view/pr';
import Notelist from './view/comment';
import { Button } from '@base/components/form';
import RFQ from './view/rfq';
import PO from './view/po';
import PurchaseReturn from './view/purchase-return';
import DeliveryView from './containers/delivery/view';
import UserList from '@demo-page/component/desktop/userlist';

const TABS = [
  {
    value: 1,
    label: 'PR',
  },
  {
    value: 2,
    label: 'RFT',
  },
  {
    value: 3,
    label: 'RFQ',
  },
  {
    value: 4,
    label: 'PO',
  },
  {
    value: 5,
    label: 'Purchase Return',
  },
  {
    value: 6,
    label: 'Timeline',
  },
  {
    value: 7,
    label: 'Note',
  },
];

const PuvchaseView = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  const [openRight, setOpenRight] = useState<boolean>(false);

  return (
    <>
      <div className="view-wrap overflow-y-hidden">
        <PurchaseHeader title="P-0000001" setOpenRight={setOpenRight} />

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Purchase Request</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>New</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Request for Quote</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Purchase Order</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Receive Goods</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Closed</div>
            </li>
          </ol>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  {tab.value !== 2 && tab.value != 3 && tab.value !== 4 && tab.value !== 5 && (
                    <>
                      <div className="form-group">
                        <label className="form-item-title">PR ID</label>
                        <div>PR-000001</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">From</label>
                        <div>Manual</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Reason</label>
                        <div>Lorem ipsum dolor sit amet</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Request by</label>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">
                            <span className="user-name">MSR</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Requested on</label>
                        <div>2021-06-24</div>
                      </div>
                    </>
                  )}
                  {(tab.value == 2 || tab.value == 3 || tab.value == 4) && (
                    <>
                      {tab.value == 4 && (
                        <div className="form-group">
                          <label className="form-item-title">Subject</label>
                          <div>Subject 1</div>
                        </div>
                      )}
                      <div className="form-group">
                        <label className="form-item-title">
                          {tab.value == 2 && 'RFT'}
                          {tab.value == 3 && 'RFQ'}
                          {tab.value == 4 && 'PO'} ID
                        </label>
                        <div>
                          {tab.value == 2 && 'RFT'}
                          {tab.value == 3 && 'RFQ'}
                          {tab.value == 4 && 'PO'}-000001
                        </div>
                      </div>
                      {tab.value == 3 && (
                        <>
                          <div className="form-group">
                            <label className="form-item-title">Responded on</label>
                            <div>2022-04-29</div>
                          </div>
                          <div className="form-group">
                            <label className="form-item-title">Accepted</label>
                            <div className="tx-success">Y</div>
                            {/* <div className='tx-danger'>N</div> */}
                          </div>
                        </>
                      )}
                      {tab.value == 4 && (
                        <div className="form-group">
                          <label className="form-item-title">PO Date</label>
                          <div>2022-04-29</div>
                        </div>
                      )}
                      <div className="form-group">
                        <label className="form-item-title">Owner</label>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">
                            <span className="user-name">MSR</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Created on</label>
                        <div>2022-04-29</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Vendor Name</label>
                        <div>
                          <a href="" target="_blank">
                            Vendor 1
                          </a>
                          {tab.value == 2 && (
                            <>
                              ,
                              <a href="" target="_blank">
                                Vendor 2
                              </a>
                              ,
                              <a href="" target="_blank">
                                Vendor 3
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                      {tab.value !== 4 && (
                        <>
                          <div className="form-group">
                            <label className="form-item-title">RFQ Date</label>
                            <div>2022-04-29</div>
                          </div>
                          <div className="form-group">
                            <label className="form-item-title">Valid Until</label>
                            <div>2022-05-04</div>
                          </div>
                        </>
                      )}
                      <div className="form-group">
                        <label className="form-item-title">Payment Terms</label>
                        <div>7 days</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Expected Delivery Date</label>
                        <div>2022-05-29</div>
                      </div>
                      <DeliveryView direction="to" />
                      {tab.value == 2 && (
                        <>
                          <div className="form-group">
                            <label className="form-item-title">Selection Criteria</label>
                            <ul className="list-unstyled">
                              <li>Criteria 1</li>
                              <li>Criteria 2</li>
                            </ul>
                          </div>
                          <div className="form-group">
                            <label className="form-item-title">
                              RFQ Time line &amp; Review Process
                            </label>
                            <div>&nbsp;</div>
                          </div>
                          <div className="form-group">
                            <label className="form-item-title">Submission Requirements</label>
                            <div>&nbsp;</div>
                          </div>
                        </>
                      )}
                      {tab.value == 4 && (
                        <>
                          <div className="form-group">
                            <label className="form-item-title">Courier</label>
                            <div>DHL</div>
                          </div>
                          <div className="form-group">
                            <label className="form-item-title">Scheduled on</label>
                            <div>2022-05-31</div>
                          </div>
                          <div className="form-group">
                            <label className="form-item-title">Tracking No</label>
                            <div>5583-1233-4531</div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {tab.value == 5 && (
                    <>
                      <div className="form-group">
                        <label className="form-item-title">Vendor Name</label>
                        <div>
                          <a href="" target="_blank">
                            Vendor 1
                          </a>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Return Id</label>
                        <div>RMA-000001</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Reference Id</label>
                        <div>
                          <a href="" target="_blank">
                            PO-000001
                          </a>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Shipment Id</label>
                        <div>
                          <a href="" target="_blank">
                            SHI-000001
                          </a>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Return Date</label>
                        <div>2022-05-16</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Returned Type</label>
                        <div>Refund-Credit Note</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Reason</label>
                        <div>Damaged Item</div>
                      </div>
                      <DeliveryView direction="from" />
                      <div className="form-group">
                        <label className="form-item-title">Courier</label>
                        <div>DHL</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Scheduled on</label>
                        <div>2022-05-31</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Tracking No</label>
                        <div>5583-1233-4531</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Return Process</label>
                        <div>Process 1</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Product Details</label>
                        <div>Tamplate 1</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Owner</label>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">
                            <span className="user-name">MSR</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item, index) =>
                      item.value < 3 && (
                        <li
                          key={index}
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item, index) =>
                          item.value > 2 && (
                            <div className="dropdown-item" key={index}>
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show right aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && <PR setOpenRight={setOpenRight} />}
              {tab.value == 2 && <RFT />}
              {tab.value == 3 && <RFQ />}
              {tab.value == 4 && <PO />}
              {tab.value == 5 && <PurchaseReturn />}
              {tab.value == 6 && <PurchaseViewTimeline />}
              {tab.value == 7 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {(tab.value == 4 || tab.value == 5) && (
                <>
                  {/* Purchase Bill */}
                  <div className="view-aside-section">
                    <div className="view-aside-section-header">
                      <Button
                        color=""
                        className="view-aside-section-move mg-r-5"
                        icon="move"
                        iconType="custom"
                        data-han-tooltip="Move"
                      />
                      <span className="view-aside-section-header-title">Purchase Bill</span>
                      <Button
                        color="link link-03"
                        className="mg-l-auto pd-0"
                        icon="ChevronDown"
                        data-toggle="collapse"
                        data-target="#purchaseBill"
                      />
                    </div>
                    <div id="purchaseBill" className="view-aside-section-body collapse show">
                      <div className="pd-10">
                        <div className="d-flex flex-wrap align-items-center">
                          <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                            PB-0000001
                          </a>
                          <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">
                            New
                          </span>
                          <span className="mg-l-auto">$26,430</span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                          <span className="mg-r-10 tx-color-03 tx-13">Balance Due : </span>
                          <strong className="mg-l-auto tx-danger">$26,430</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Purchase Return */}
                  <div className="view-aside-section">
                    <div className="view-aside-section-header">
                      <Button
                        color=""
                        className="view-aside-section-move mg-r-5"
                        icon="move"
                        iconType="custom"
                        data-han-tooltip="Move"
                      />
                      <span className="view-aside-section-header-title">Purchase Return</span>
                      <Button
                        color="link link-03"
                        className="mg-l-auto pd-0"
                        icon="ChevronDown"
                        data-toggle="collapse"
                        data-target="#purchaseReturn"
                      />
                    </div>
                    <div id="purchaseReturn" className="view-aside-section-body collapse show">
                      <div className="pd-10">
                        <div className="d-flex flex-wrap align-items-center">
                          <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                            RMA-0000001
                          </a>
                          <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">
                            New
                          </span>
                          <span className="mg-l-auto">QTY :10</span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                          <span className="mg-r-10 tx-color-03 tx-13">Return Type: </span>
                          <strong className="mg-l-auto">Refund</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {tab.value == 5 && (
                <>
                  {/* Vendor Credit */}
                  <div className="view-aside-section">
                    <div className="view-aside-section-header">
                      <Button
                        color=""
                        className="view-aside-section-move mg-r-5"
                        icon="move"
                        iconType="custom"
                        data-han-tooltip="Move"
                      />
                      <span className="view-aside-section-header-title">Vendor Credit</span>
                      <Button
                        color="link link-03"
                        className="mg-l-auto pd-0"
                        icon="ChevronDown"
                        data-toggle="collapse"
                        data-target="#vendorCredit"
                      />
                    </div>
                    <div id="vendorCredit" className="view-aside-section-body collapse show">
                      <div className="pd-10">
                        <div className="d-flex flex-wrap align-items-center">
                          <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                            VCN-0000001
                          </a>
                          <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">
                            New
                          </span>
                          <span className="mg-l-auto">$26,430</span>
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                          <span className="mg-r-10 tx-color-03 tx-13">Remaining Credits: </span>
                          <strong className="mg-l-auto tx-danger">$26,430 </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Contact */}
                  <div className="view-aside-section">
                    <div className="view-aside-section-header">
                      <Button
                        color=""
                        className="view-aside-section-move mg-r-5"
                        icon="move"
                        iconType="custom"
                        data-han-tooltip="Move"
                      />
                      <span className="view-aside-section-header-title">Contact</span>
                      <Button
                        color="link link-03"
                        className="mg-l-auto pd-0"
                        icon="ChevronDown"
                        data-toggle="collapse"
                        data-target="#contact"
                      />
                    </div>
                    <div id="contact" className="view-aside-section-body collapse show">
                      <UserList />
                    </div>
                  </div>
                </>
              )}

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <NewPR />
      <NewPO openRight={openRight} />
      <NewRFQ />
      <NewRFT />
      <AddVendor />
      <NewVendor />
      <NewPurchaseReturn />
    </>
  );
};

export default PuvchaseView;
