import { useState } from 'react';
import { Button, Checkbox, Input, RadioGroup, Select } from '@base/components/form';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';
import { ChevronDown } from 'react-feather';

const CommissionShare = () => {
  const SHARE_TYPES = [
    {
      label: 'Sales Owner only',
      value: 'owner',
    },
    {
      label: 'Share with other',
      value: 'share',
    },
  ];

  const [options, setOptions] = useState(SHARE_TYPES);
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
  };

  const [shareReferrer, setShareReferrer] = useState(false);
  const [shareCollaborator, setShareCollaborator] = useState(false);

  return (
    <div
      id="commissionShare"
      className="modal right fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header flex-column pd-0">
            <div className="d-flex wd-100p pd-15">
              <h1 className="modal-title">Commission Share</h1>
              <Button close data-dismiss="modal" />
            </div>
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <RadioGroup value={selectedValue} options={options} onChange={handleValueChange} />
            {selectedValue === 'share' && (
              <>
                <Checkbox
                  label="Referrer(External)"
                  onChange={() => setShareReferrer(!shareReferrer)}
                />
                {shareReferrer && (
                  <div className="mg-l-25 mg-y-10">
                    <div className="table-responsive bd rounded">
                      <table className="table table-bordered mg-b-0 bd-0">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Referrer
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Customer Type
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Commission
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              %
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Select
                                options={[
                                  { value: 'account', label: 'Account' },
                                  { value: 'contact', label: 'Contact' },
                                ]}
                                defaultValue={{ value: 'account', label: 'Account' }}
                                width={120}
                              />
                            </td>
                            <td>
                              <Select
                                options={[
                                  { value: 'agent', label: 'Sales Agent' },
                                  { value: 'customer', label: 'Customer' },
                                  { value: 'internal', label: 'Vendor' },
                                  { value: 'broker', label: 'Broker' },
                                  { value: 'affiliate', label: 'Affiliate' },
                                ]}
                                defaultValue={{ value: 'agent', label: 'Sales Agent' }}
                                width={200}
                              />
                            </td>
                            <td>
                              <CustomerAutoComplete
                                placement="right"
                                single={true}
                                showAvatar={true}
                                visible={true}
                                placeholder="Type or click to select a contact"
                                category=""
                                value={[]}
                                onChange={() => null}
                              />
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <Checkbox
                  label="Collaborator(Internal)"
                  onChange={() => setShareCollaborator(!shareCollaborator)}
                />
                {shareCollaborator && (
                  <div className="mg-l-25 mg-y-10">
                    <div className="table-responsive bd rounded">
                      <table className="table table-bordered mg-b-0 bd-0">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              Collaborator
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Commission
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              %
                            </th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <td colSpan={2} className="bg-light text-right">
                              Share Sum
                            </td>
                            <td className="bg-light">&nbsp;</td>
                            <td className="bg-light">&nbsp;</td>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr>
                            <td>
                              <Select
                                options={[
                                  { value: 'marketing', label: 'Marketing rep.' },
                                  { value: 'sales', label: 'Sales rep.' },
                                  { value: 'content', label: 'Content Producer' },
                                  { value: 'inside', label: 'Inside Sales' },
                                  { value: 'outside', label: 'Outside Sales' },
                                  { value: 'support', label: 'Sales Support' },
                                  { value: 'lead', label: 'Lead Development' },
                                  { value: 'develop', label: 'Sales Development rep.' },
                                  { value: 'tech', label: 'Technisal Support' },
                                ]}
                              />
                            </td>
                            <td>
                              <CustomerAutoComplete
                                placement="right"
                                single={true}
                                showAvatar={true}
                                visible={true}
                                placeholder="Type or click to select a contact"
                                category=""
                                value={[]}
                                onChange={() => null}
                              />
                            </td>
                            <td>
                              <Input type="number" className="wd-100" />
                            </td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                              <Select
                                options={[
                                  { value: 'marketing', label: 'Marketing rep.' },
                                  { value: 'sales', label: 'Sales rep.' },
                                  { value: 'content', label: 'Content Producer' },
                                  { value: 'inside', label: 'Inside Sales' },
                                  { value: 'outside', label: 'Outside Sales' },
                                  { value: 'support', label: 'Sales Support' },
                                  { value: 'lead', label: 'Lead Development' },
                                  { value: 'develop', label: 'Sales Development rep.' },
                                  { value: 'tech', label: 'Technisal Support' },
                                ]}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            <Button color="light" data-dismiss="modal" name="Close" />
            <Button color="success" name="Save" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionShare;
