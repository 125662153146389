import { Input, Select } from '@base/components/form';
import { useState } from 'react';

const CommissionRate = () => {
  const COMMISSION_RATE = [
    {
      label: '%',
      value: 'percentage',
    },
    {
      label: '$',
      value: 'amount',
    },
  ];

  const [rate, setRate] = useState(COMMISSION_RATE[0]);
  //change
  const handleRateChange = (newOption: any) => {
    setRate(newOption);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="wd-150 mg-r-10">
        <Input type="number" />
      </div>
      <Select
        value={rate}
        options={COMMISSION_RATE}
        onChange={handleRateChange}
        className="wd-80-f mg-r-10"
      />
      {rate.value === 'percentage' && (
        <>
          of
          <Select
            options={[
              { value: '1', label: 'Revenue' },
              { value: '2', label: 'Gross Profit' },
            ]}
            defaultValue={{ value: '1', label: 'Revenue' }}
            className="wd-150-f mg-l-10"
          />
        </>
      )}
      {rate.value === 'amount' && <>for each sale</>}
    </div>
  );
};
export default CommissionRate;
