import { useState } from 'react';
import { Button, Input, RadioGroup, Select } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import UserGroupSelect from '@base/containers/user-group-select';
import { BarChart } from 'react-feather';
import RightAside from '../write/rightAside';

const OPTIONS = [
  { value: 'all', label: 'All Groups' },
  { value: 'specific', label: 'Specific Group' },
];

const MODEOPTIONS = [
  { value: 'portrait', label: 'Portrait' },
  { value: 'landscape', label: 'Landscape' },
];

const GRIDOPTIONS = [
  { value: 'show', label: 'Show' },
  { value: 'never', label: 'Never' },
];

const NewReport = () => {
  const [type, setType] = useState(OPTIONS);
  const [selectedType, setSelectedType] = useState(type[0].value);
  //change
  const handleTypeChange = (newOption: any) => {
    setSelectedType(newOption.value);
  };

  const [mode, setMode] = useState(MODEOPTIONS);
  const [selectedMode, setSelectedMode] = useState(mode[0].value);
  //change
  const handleModeChange = (newOption: any) => {
    setSelectedMode(newOption.value);
  };

  const [grid, setGrid] = useState(GRIDOPTIONS);
  const [selectedGrid, setSelectedGrid] = useState(grid[0].value);
  //change
  const handleGridChange = (newOption: any) => {
    setSelectedGrid(newOption.value);
  };

  return (
    <>
      <div id="newReport" className="modal fade ">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title h6">Create Report</h1>
              <Button close data-dismiss="modal" />
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>Report Name</label>
                <Input type="text" />
              </div>
              <div className="form-group">
                <label>Recipients</label>
                <UserAutoComplete showAvatar />
              </div>
              <div className="form-group">
                <label>Subject</label>
                <Input type="text" />
              </div>
              <div className="form-group">
                <label>Content</label>
                <textarea
                  rows={3}
                  cols={10}
                  className="form-control"
                  placeholder="Editor"
                ></textarea>
              </div>
              <div className="form-group">
                <label className="d-block">Assignment Group</label>
                <div className="tx-orange">
                  The charts will be generated based on the selected group.
                </div>
                <div className="d-flex align-items-center">
                  <RadioGroup options={type} value={selectedType} onChange={handleTypeChange} />
                  {selectedType === 'specific' && <UserGroupSelect />}
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Date Rage</label>
                <div className="tx-orange">
                  The data will be synced based on the schedule to create report.
                </div>
                <Select width={300} defaultValue={{ value: 1, label: 'This Month' }} />
              </div>
              <div className="form-group">
                <label className="form-item-title">Reporting Cycle</label>
                <div className="tx-orange">
                  The report will be generated and sent based on the schedule.
                </div>
                <div className="d-flex align-items-center">
                  <Select width={150} defaultValue={{ value: 'm', label: 'monthly' }} />
                  <div className="input-group wd-150 mg-x-10">
                    <div className="input-group-prepend">
                      <span className="input-group-text bd-0">Every</span>
                    </div>
                    <Select width={135} defaultValue={{ value: 1, label: '1st' }} />
                  </div>
                  <Select width={150} defaultValue={{ value: 1, label: '08:00' }} />
                </div>
              </div>
              <div className="row row-xs">
                <div className="col-lg-8">
                  <div className="card ht-100p">
                    <div className="card-header">
                      Page 1
                      <Button
                        color="primary"
                        size="sm"
                        icon="Plus"
                        name="Add"
                        className="mg-l-auto"
                      />
                    </div>
                    <div className="card-body">
                      <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="mg-b-10 pd-10 bd bd-dashed rounded tx-gray-400">
                          <BarChart />
                        </div>
                        Drag and Drop charts to create your report!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <RightAside />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button color="white" name="Preview" className="mg-r-20" />
              <Button color="light" data-dismiss="modal" name="Close" />
              <Button color="success" name="Save" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewReport;
