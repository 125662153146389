import { ChevronLeft, ChevronRight } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Ticket = () => {
  return (
    <div className="pd-20">
      <div className="card">
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Subject
                </th>
                <th scope="col" className="bd-t-0-f wd-100">
                  Priority
                </th>
                <th scope="col" className="bd-t-0-f wd-100">
                  Status
                </th>
                <th scope="col" className="bd-t-0-f wd-100">
                  Assigned Rep
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href="" className="text-nowrap" target="_blank" title="새창으로 열림">
                    Ticket 1
                  </a>
                </td>
                <td>High</td>
                <td>New</td>
                <td>
                  <div className="media align-items-center">
                    <div className="avatar avatar-xs">
                      <img
                        src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                        className="rounded-circle"
                      />
                    </div>
                    <div className="media-body mg-l-10">MSR</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer pd-y-6-f pagination">
          <button
            type="button"
            className="btn btn-link link-02 btn-icon"
            aria-label="move to first"
          >
            {Icon('move_first')}
            <span className="sr-only">처음으로</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
            <ChevronLeft />
            <span className="sr-only">이전</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
            <ChevronRight />
            <span className="sr-only">다음</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to last">
            {Icon('move_last')}
            <span className="sr-only">마지막으로</span>
          </button>
          <input type="number" className="paging-input" defaultValue="1" />/ 1
        </div>
      </div>
    </div>
  );
};

export default Ticket;
