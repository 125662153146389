import { MapPin, Phone } from 'react-feather';

const DeliveryView = (props: any) => {
  const { direction = 'to' } = props;
  return (
    <div className="form-group">
      <label className="form-item-title">Delivery {direction === 'to' ? 'to' : 'from'}</label>
      <div className="d-flex flex-wrap align-items-center">
        <span className="badge badge-dark mg-r-5">Warehouse 1</span>
        <div>
          <MapPin size={14} className="mg-r-5 tx-color-03" />
          Street 52, Seoul,16812, South Korea
        </div>
        <div>
          <Phone size={14} className="mg-r-5 tx-color-03" />
          0701112232
        </div>
      </div>
    </div>
  );
};
export default DeliveryView;
