import {
  Bold,
  Italic,
  Link,
  Image,
  Paperclip,
  AtSign,
  Smile,
  Crop,
  Video,
  Download,
} from 'react-feather';
import Icons, { SvgIcons } from '@base/assets/icons/svg-icons';

const Chat = () => {
  return (
    <div className="chat-container">
      <div className="chat-body pd-10">
        <div className="chat-group">
          <div className="chat-group-divider tx-sans">2021-06-15</div>
          <div className="chat-wrap">
            <div className="avatar avatar-sm">
              <span className="avatar-initial rounded-circle">J</span>
            </div>
            <div className="chat-content-wrap">
              <div className="chat-content">
                <div className="chat-user-name">John</div>
                How Can I order the products?
              </div>
              <div className="chat-content">
                <div className="file-wrap">
                  <SvgIcons type="xls" />
                  <span className="file-name">list.xls</span>
                  <button
                    type="button"
                    className="btn btn-white mg-l-auto pd-x-10"
                    aria-label="download"
                  >
                    <Download />
                    <span className="sr-only">내려받기</span>
                  </button>
                </div>
              </div>
              <div className="chat-content">
                <div className="file-wrap">
                  <div className="file-img">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEnS77S41Wx_6jgcwIlYLY_j6ognq6gm6t-g&amp;usqp=CAU"
                      alt=""
                    />
                    <span className="file-name">sample.jpg</span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-white mg-l-auto pd-x-10"
                    aria-label="download"
                  >
                    <Download />
                    <span className="sr-only">내려받기</span>
                  </button>
                </div>
              </div>
              <span className="tx-color-03">15:13</span>
            </div>
          </div>
          <div className="chat-wrap staff-chat">
            <div className="avatar avatar-sm">
              <img
                src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                className="rounded-circle"
                alt="KDH"
              />
            </div>
            <div className="chat-content-wrap">
              <div className="chat-content">
                <div className="chat-user-name">KDH</div>
                Let me create a ticket for you.
              </div>
              <span className="tx-color-03">15:13</span>
            </div>
          </div>
        </div>
        <div className="chat-group">
          <div className="chat-group-divider tx-sans">Today</div>
          <div className="chat-wrap">
            <div className="avatar avatar-sm">
              <span className="avatar-initial rounded-circle">K</span>
            </div>
            <div className="chat-content-wrap">
              <div className="chat-content">
                <div className="chat-user-name">Katherine</div>
                Hello
              </div>
              <div className="chat-content">
                Im interested in hearing more about the new features.
              </div>
              <span className="tx-color-03">10:03</span>
            </div>
          </div>
          <div className="chat-wrap staff-chat">
            <div className="avatar avatar-sm">
              <img
                src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                className="rounded-circle"
                alt="MSR"
              />
            </div>
            <div className="chat-content-wrap">
              <div className="chat-content">
                <div className="chat-user-name">MSR</div>
                Hello, Katherine.
              </div>
              <span className="tx-color-03">10:04</span>
              <div className="chat-content">Would you be interested in a live demonstration?</div>
              <span className="tx-color-03">10:05</span>
            </div>
          </div>
        </div>
      </div>
      <div className="message-input-container mg-10">
        <div className="message-input_inner">
          <div className="message-input" contentEditable="true"></div>
          <div className="message-input-placeholder" tabIndex={-1}>
            Enter a message
          </div>
        </div>
        <div className="message-input-buttons">
          <button className="btn btn-link link-03">
            <Bold />
            <span className="sr-only">진하게 / Bold</span>
          </button>
          <button className="btn btn-link link-03">
            <Italic />
            <span className="sr-only">이탤릭체 / Italic</span>
          </button>
          <button className="btn btn-link link-03">
            <Link />
            <span className="sr-only">링크 삽입 / Insert link</span>
          </button>
          <button className="btn btn-link link-03">
            <Image />
            <span className="sr-only">이미지 삽입 / Insert image</span>
          </button>
          <button className="btn btn-link link-03">
            <Paperclip />
            <span className="sr-only">첨부파일 / Attachment</span>
          </button>
          <span className="divider"></span>
          <button className="btn btn-link link-03">
            <AtSign />
            <span className="sr-only">멘션 / Mention</span>
          </button>
          <button className="btn btn-link link-03">
            <Smile />
            <span className="sr-only">이모티콘 삽입 / Insert emoticon</span>
          </button>
          <button className="btn btn-link link-03">
            <Crop />
            <span className="sr-only">화면 캡쳐 / Screen capture</span>
          </button>
          <button className="btn btn-link link-03">
            <Video />
            <span className="sr-only">화상 회의 / Video conference</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
