import { useState } from 'react';

import { X, ChevronDown, Plus, UploadCloud, Check, PlayCircle, Image, Music } from 'react-feather';

const NewImage = () => {
  const [showFolderInput, setShowFolderInput] = useState(false);

  const [fileType, setFileType] = useState<any>({
    image: true,
    video: false,
    audio: false,
  });

  const [uploadOption, setUploadOption] = useState('upload');
  const handleUploadOption = (e: any) => {
    const { value } = e.target;
    setUploadOption(value);
  };

  return (
    <div id="newImageModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">New File</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">File Type</label>
              <div className="input-group wd-300" data-toggle="dropdown">
                <div className="form-control d-flex align-items-center bd-r-0">
                  {fileType.image && <Image size={16} className="mg-r-5" />}
                  {fileType.video && <PlayCircle size={16} className="mg-r-5" />}
                  {fileType.audio && <Music size={16} className="mg-r-5" />}
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 rounded"
                    placeholder="Select..."
                    value={
                      (fileType.image && 'Image') ||
                      (fileType.video && 'Video') ||
                      (fileType.audio && 'Audio')
                    }
                  />
                </div>
                <div className="input-group-append">
                  <button type="button" className="btn">
                    <ChevronDown />
                  </button>
                </div>
              </div>
              <div className="dropdown-menu wd-300">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => setFileType({ image: true })}
                >
                  <span className="dropdown-item-icon">
                    <Image />
                  </span>
                  Image
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => setFileType({ video: true })}
                >
                  <span className="dropdown-item-icon">
                    <PlayCircle />
                  </span>
                  Video
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => setFileType({ audio: true })}
                >
                  <span className="dropdown-item-icon">
                    <Music />
                  </span>
                  Audio
                </button>
              </div>
            </div>

            <div className="form-group">
              <label className="form-item-title d-block">File Upload</label>
              {fileType.video && (
                <>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="fileUploadRadio1"
                      name="fileUploadRadio"
                      className="custom-control-input"
                      value="upload"
                      checked={uploadOption === 'upload'}
                      onChange={(e) => handleUploadOption(e)}
                    />
                    <label className="custom-control-label" htmlFor="fileUploadRadio1">
                      Upload
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block">
                    <input
                      type="radio"
                      id="fileUploadRadio2"
                      name="fileUploadRadio"
                      className="custom-control-input"
                      value="url"
                      checked={uploadOption === 'url'}
                      onChange={(e) => handleUploadOption(e)}
                    />
                    <label className="custom-control-label" htmlFor="fileUploadRadio2">
                      URL
                    </label>
                  </div>
                  {uploadOption === 'upload' && (
                    <div className="d-flex align-items-center justify-content-center mg-t-5 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
                      <UploadCloud size="20" className="mg-r-10" />
                      Drag &amp; Drop Files
                    </div>
                  )}
                  {uploadOption === 'url' && <input type="text" className="form-control mg-t-5" />}
                </>
              )}
              {!fileType.video && (
                <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
                  <UploadCloud size="20" className="mg-r-10" />
                  Drag &amp; Drop Files
                </div>
              )}
            </div>

            {fileType.video && (
              <div className="form-group">
                <label className="form-item-title">Video Capture Image</label>
                <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
                  <UploadCloud size="20" className="mg-r-10" />
                  Drag &amp; Drop Files
                </div>
              </div>
            )}

            <div className="form-group">
              <label className="form-item-title">Folder</label>
              <div className="dropdown wd-300">
                <div className="input-group" data-toggle="dropdown">
                  <input type="text" className="form-control" placeholder="Select a folder" />
                  <div className="input-group-append">
                    <button type="button" className="btn">
                      <ChevronDown />
                    </button>
                  </div>
                </div>
                <div className="dropdown-menu wd-100p">
                  {!showFolderInput && (
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => setShowFolderInput(true)}
                    >
                      <Plus className="mg-r-5" />
                      Add new folder
                    </button>
                  )}
                  {showFolderInput && (
                    <div className="d-flex align-items-center pd-l-20 pd-r-10">
                      <input type="text" className="form-control" />
                      <button type="button" className="btn btn-sm btn-icon">
                        <Check className="tx-success" />
                        <span className="sr-only">추가</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-link link-03 btn-icon"
                        onClick={() => setShowFolderInput(false)}
                      >
                        <X />
                        <span className="sr-only">취소</span>
                      </button>
                    </div>
                  )}
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item">General</button>
                  <button className="dropdown-item">Offer</button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Size</label>
              <div>&nbsp;</div>
            </div>
            {!fileType.audio && (
              <div className="form-group">
                <label className="form-item-title">Dimension</label>
                <div>&nbsp;</div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewImage;
