import { Download, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';
import { useState } from 'react';

const SelectCTA = () => {
  const LINK_TYPE = [
    { value: 1, label: 'Owned Content' },
    { value: 2, label: 'In CRM' },
  ];
  const [linkType, setLinkType] = useState(LINK_TYPE);
  const [selectedLinkType, setSelectedLinkType] = useState(LINK_TYPE[0]);
  //change
  const handleTypeChange = (newOption: any) => {
    setSelectedLinkType(newOption);
  };

  return (
    <div
      id="selectCTA"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Select CTA</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto">
            <div className="form-group">
              <label className="form-item-title">CTA Name</label>
              <SelectHook />
            </div>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button type="button" className="nav-link" data-toggle="tab" data-target="#preview">
                  Preview
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className="nav-link active"
                  data-toggle="tab"
                  data-target="#type"
                >
                  Type
                </button>
              </li>
            </ul>
            <div className="tab-content bd bd-gray-300 bd-t-0 pd-20">
              <div id="preview" className="tab-pane fade"></div>
              <div id="type" className="tab-pane fade show active">
                <div className="form-group">
                  <label className="form-item-title">Link Type</label>
                  <SelectHook
                    options={linkType}
                    value={selectedLinkType}
                    onChange={handleTypeChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-item-title">
                    Link {selectedLinkType.value === 1 ? 'URL' : 'Page/Site'}
                  </label>
                  <input type="url" className="form-control" />
                </div>
                {selectedLinkType.value === 2 && (
                  <div className="form-group">
                    <label className="form-item-title">Type</label>
                    <input type="text" className="form-control" />
                  </div>
                )}
                <div className="form-group">
                  <label className="form-item-title">Page Title</label>
                  <input type="text" className="form-control" />
                </div>
                {selectedLinkType.value === 1 && (
                  <button type="button" className="btn btn-white">
                    <Download className="mg-r-5" />
                    Downlaod JS Snippet
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCTA;
