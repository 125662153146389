import { Checkbox } from '@base/components/form';

export const PRList = () => {
  return (
    <div className="table-responsive">
      <table className="table dataTable">
        <thead>
          <tr>
            <th scope="col" className="tb-sel-cell">
              <Checkbox />
            </th>
            <th scope="col" className="sorting_desc">
              PR ID
            </th>
            <th scope="col" className="sorting">
              Status
            </th>
            <th scope="col" className="sorting">
              Item
            </th>
            <th scope="col" className="sorting">
              Quantity
            </th>
            <th scope="col" className="sorting">
              Required Date
            </th>
            <th scope="col" className="sorting">
              Vendor
            </th>
            <th scope="col" className="sorting">
              Estimated Amount
            </th>
            <th scope="col" className="sorting">
              From
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tb-sel-cell">
              <Checkbox />
            </td>
            <td className="sorting-cell">
              <a href="/purchase/desktop/theme1/pr-view">P-000001</a>
            </td>
            <td>New</td>
            <td>Item 1</td>
            <td>100</td>
            <td>2022/04/27</td>
            <td>Vendor 1</td>
            <td>$ 2,000</td>
            <td>Manual</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const RFTList = () => {
  return (
    <div className="table-responsive">
      <table className="table dataTable">
        <thead>
          <tr>
            <th scope="col" className="tb-sel-cell">
              <Checkbox />
            </th>
            <th scope="col" className="sorting_desc">
              RFT ID
            </th>
            <th scope="col" className="sorting">
              Status
            </th>
            <th scope="col" className="sorting">
              Item
            </th>
            <th scope="col" className="sorting">
              Quntity
            </th>
            <th scope="col" className="sorting">
              Valid Until
            </th>
            <th scope="col" className="sorting">
              Vendor
            </th>
            <th scope="col" className="sorting">
              Total Amount
            </th>
            <th scope="col" className="sorting">
              Response
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tb-sel-cell">
              <Checkbox />
            </td>
            <td className="sorting-cell">
              <a href="/purchase/desktop/theme1/rft-view">RTF-000001</a>
            </td>
            <td>New</td>
            <td>Item 1</td>
            <td>100</td>
            <td>2022/04/27</td>
            <td>Vendor 1</td>
            <td>$ 2,000</td>
            <td>
              <span className="tx-success">0</span>/4
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const RFQList = () => {
  return (
    <div className="table-responsive">
      <table className="table dataTable">
        <thead>
          <tr>
            <th scope="col" className="tb-sel-cell">
              <Checkbox />
            </th>
            <th scope="col" className="sorting_desc">
              RFQ ID
            </th>
            <th scope="col" className="sorting">
              Status
            </th>
            <th scope="col" className="sorting">
              Item
            </th>
            <th scope="col" className="sorting">
              Quntity
            </th>
            <th scope="col" className="sorting">
              Total Amount
            </th>
            <th scope="col" className="sorting">
              Vendor
            </th>
            <th scope="col" className="sorting">
              Valid Until
            </th>
            <th scope="col" className="sorting">
              Related RFT
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tb-sel-cell">
              <Checkbox />
            </td>
            <td className="sorting-cell">
              <a href="/purchase/desktop/theme1/rfq-view">RFQ-000001</a>
            </td>
            <td>New</td>
            <td>Item 1</td>
            <td>100</td>
            <td>$ 2,000</td>
            <td>Vendor 1</td>
            <td>2022/04/27</td>
            <td>RFT-0000001</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const POList = () => {
  return (
    <div className="table-responsive">
      <table className="table dataTable">
        <thead>
          <tr>
            <th scope="col" className="tb-sel-cell">
              <Checkbox />
            </th>
            <th scope="col" className="sorting_desc">
              Subject
            </th>
            <th scope="col" className="sorting">
              Stage
            </th>
            <th scope="col" className="sorting">
              Status
            </th>
            <th scope="col" className="sorting">
              Vendor
            </th>
            <th scope="col" className="sorting">
              Item
            </th>
            <th scope="col" className="sorting">
              Quntity
            </th>
            <th scope="col" className="sorting">
              Total Amount
            </th>
            <th scope="col" className="sorting">
              PO Date
            </th>
            <th scope="col" className="sorting">
              Received Goods
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tb-sel-cell">
              <Checkbox />
            </td>
            <td className="sorting-cell">
              <a href="/purchase/desktop/theme1/po-view">Purchase Order 1</a>
            </td>
            <td>New</td>
            <td>New</td>
            <td>Vendor 1</td>
            <td>Item 1</td>
            <td>100</td>
            <td>$ 2,000</td>
            <td>2022/04/27</td>
            <td>100</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const PurchaseReturnList = () => {
  return (
    <div className="table-responsive">
      <table className="table dataTable">
        <thead>
          <tr>
            <th scope="col" className="tb-sel-cell">
              <Checkbox />
            </th>
            <th scope="col" className="sorting_desc">
              Subject
            </th>
            <th scope="col" className="sorting">
              Status
            </th>
            <th scope="col" className="sorting">
              Vendor
            </th>
            <th scope="col" className="sorting">
              Reference ID
            </th>
            <th scope="col" className="sorting">
              Return Date
            </th>
            <th scope="col" className="sorting">
              Return Type
            </th>
            <th scope="col" className="sorting">
              Reason
            </th>
            <th scope="col" className="sorting">
              Qty Returned
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tb-sel-cell">
              <Checkbox />
            </td>
            <td className="sorting-cell">
              <a href="/purchase/desktop/theme1/return-view">Purchase Return1</a>
            </td>
            <td>New</td>
            <td>Vendor 1</td>
            <td>PO-000001</td>
            <td>2022/05/15</td>
            <td>Refund</td>
            <td>Demaged Item</td>
            <td>10</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
