import { Button, Input } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { ChevronUp } from 'react-feather';

const NewAssignmentGroup = () => {
  return (
    <div id="newAssignmentGroup" className="modal fade right">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="h6 modal-title">Create Assignment Group</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body pd-b-15-f">
            <div className="form-group">
              <label className="form-item-title">Group Name</label>
              <Input type="text" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Descriptions</label>
              <Input type="text" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Users</label>
              <div className="table-responsive bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 basic-table">
                  <thead>
                    <tr>
                      <th scope="col">User Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Capacity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <UserAutoComplete showAvatar single />
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        <Input type="number" rightIcon="%" className="wd-120" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-between pd-10 bd bd-t-0 rounded-bottom">
                <span>Total 1</span>
                <span>100%</span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Cancel
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Ok
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Ok and add reps
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAssignmentGroup;
