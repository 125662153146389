import { Edit2, Plus, Zap, Link } from 'react-feather';

type propTypes = {
  showEdit: any;
  setShowEdit: Function;
  showDetail: any;
  setShowDetail: Function;
};

const PurchaseRequest = (props: propTypes) => {
  return (
    <div className="d-flex flex-column flex-grow-1 pd-20 overflow-auto">
      <div className="stage-wrap">
        <div className="stage-item stage-open">
          <div data-han-tooltip="Open">
            <div className="text-truncate">Open Purchase Request</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="stage-item">
          <div data-han-tooltip="Approval">
            <div className="text-truncate">Request for Quote</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="stage-item">
          <div data-han-tooltip="Send by Email">
            <div className="text-truncate">Send by Email</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="stage-item" style={{ width: '520px' }}>
          <div data-han-tooltip="Site">
            <div className="text-truncate">Site</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="stage-item stage-closed">
          <div data-han-tooltip="Create PO">
            <div className="text-truncate">Create PO</div>
            {props.showEdit && (
              <>
                <div className="stage-actions">
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    data-han-tooltip="Edit"
                    data-toggle="modal"
                    data-target="#addProcessStage"
                  >
                    <Edit2 />
                    <span className="sr-only">Edit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="pos-relative flex-grow-1">
        <div className="diagram-item-wrap" style={{ top: '5px' }}>
          <div className="diagram-item diagram-trigger">
            <div className="diagram-item-header">
              <span className="diagram-item-name">
                <Zap size={14} className="mg-r-5" />
                Record Created
              </span>
            </div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '50px' }}>
          <div className="diagram-item diagram-action han-tooltip--top" data-han-tooltip="New PR">
            <div className="diagram-item-name">New PR</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward"></div>
          <div className="diagram-direction-split-choice" style={{ height: '120px' }}>
            <div className="diagram-direction-text"></div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '50px', left: '260px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Create RFQ"
          >
            <div className="diagram-item-name">Create RFQ</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward"></div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '50px', left: '520px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Send by Email"
          >
            <div className="diagram-item-name">Send by Email</div>
            {!props.showEdit && (
              <>
                <div className="diagram-item-status-wrap">
                  <span className="diagram-item-status">Sent</span>
                  <button type="button" className="btn" data-toggle="dropdown">
                    <Plus />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right diagram-item-status-detail">
                    <div className="diagram-item-status">Opened</div>
                    <div className="diagram-item-status">Scheduled</div>
                  </div>
                </div>
              </>
            )}
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward">
            <div className="diagram-direction-text"></div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '50px', left: '780px' }}>
          <div className="diagram-item diagram-site han-tooltip--top" data-han-tooltip="RFQ Site">
            <div className="diagram-item-name">RFQ Site</div>
            {!props.showEdit && (
              <button
                type="button"
                className="btn btn-link site-link"
                data-toggle="modal"
                data-target="#siteLinksModal"
              >
                <Link />
              </button>
            )}
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward" style={{ width: '450px' }}>
            <div className="diagram-direction-text">Confirmed</div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '50px', left: '1360px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Create PO"
          >
            <div className="diagram-item-name">Create PO</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '170px', left: '260px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Create RFQ"
          >
            <div className="diagram-item-name">Create RFQ</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward"></div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '170px', left: '520px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Send by Email"
          >
            <div className="diagram-item-name">Send by Email</div>
            {!props.showEdit && (
              <>
                <div className="diagram-item-status-wrap">
                  <span className="diagram-item-status">Sent</span>
                  <button type="button" className="btn" data-toggle="dropdown">
                    <Plus />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right diagram-item-status-detail">
                    <div className="diagram-item-status">Opened</div>
                    <div className="diagram-item-status">Scheduled</div>
                  </div>
                </div>
              </>
            )}
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward">
            <div className="diagram-direction-text"></div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '170px', left: '780px' }}>
          <div className="diagram-item diagram-site han-tooltip--top" data-han-tooltip="RFT Site">
            <div className="diagram-item-name">RFT Site</div>
            {!props.showEdit && (
              <button
                type="button"
                className="btn btn-link site-link"
                data-toggle="modal"
                data-target="#siteLinksModal"
              >
                <Link />
              </button>
            )}
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward">
            <div className="diagram-direction-text"></div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '170px', left: '1040px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Select Vendor"
          >
            <div className="diagram-item-name">Select Vendor</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-join" style={{ top: '-80px', height: '120px' }}></div>
          <div className="diagram-direction-vertical diagram-direction-middle"></div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '300px', left: '1100px' }}>
          <div
            className="diagram-item diagram-wait han-tooltip--top"
            data-han-tooltip="Wait by Duration"
          >
            <div className="diagram-item-name">Wait by Duration</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
          <div className="diagram-direction-forward">
            <div className="diagram-direction-text"></div>
          </div>
        </div>

        <div className="diagram-item-wrap" style={{ top: '320px', left: '1360px' }}>
          <div
            className="diagram-item diagram-action han-tooltip--top"
            data-han-tooltip="Send by Email"
          >
            <div className="diagram-item-name">Send by Email</div>
            {props.showEdit && (
              <div className="diagram-item-actions">
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  data-han-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#editStepModal"
                >
                  <Edit2 />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseRequest;
