import { ArrowUp, ArrowDown } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Analytics = () => {
  return (
    <div className="pd-20">
      <div className="view-content-section">
        <div className="view-content-section-header">
          <span className="view-aside-section-header-title">
            Total Social Media Conversions (Last 30 days)
          </span>
        </div>
        <div className="card card-body">
          <div className="d-flex">
            <div className="flex-grow-1 pd-x-15">
              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                Web Visits from Social Media
              </div>
              <div className="h3 tx-normal">6,005</div>
              <div className="tx-12 tx-color-03">
                <span className="tx-success">
                  <ArrowUp size={12} />
                  252%
                </span>{' '}
                than prev 30 days
              </div>
            </div>
            <div className="flex-grow-1 pd-x-15 bd-l">
              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                Conversion from Social Media
              </div>
              <div className="h3 tx-normal">102</div>
              <div className="tx-12 tx-color-03">
                <span className="tx-danger">
                  <ArrowDown size={12} />
                  -2%
                </span>{' '}
                than prev 30 days
              </div>
            </div>
            <div className="flex-grow-1 pd-x-15 bd-l">
              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                Conversion Rate from Social Media
              </div>
              <div className="h3 tx-normal">2%</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-group social-card-group mg-t-20">
        <div className="card">
          <div className="card-header align-items-center">
            <span className="social-icon">{Icon('facebook')}</span>
            <div className="mg-l-5">
              <strong>Hanbiro</strong>
            </div>
          </div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex pd-y-5">
                Total Fans
                <span className="mg-l-auto">905,600</span>
              </li>
              <li className="d-flex pd-y-5">
                New
                <span className="mg-l-auto">600</span>
              </li>
              <li className="d-flex pd-y-5">
                Lost
                <span className="mg-l-auto">75</span>
              </li>
              <li className="d-flex pd-y-5">
                Fan Engagement%
                <span className="mg-l-auto">15%</span>
              </li>
              <li className="d-flex pd-y-5">
                Likes
                <span className="mg-l-auto">3,596</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header align-items-center">
            <span className="social-icon">{Icon('twitter')}</span>
            <div className="mg-l-5">
              <strong>Hanbiro</strong>
            </div>
          </div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex pd-y-5">
                Followers
                <span className="mg-l-auto">90,852</span>
              </li>
              <li className="d-flex pd-y-5">
                Mentions
                <span className="mg-l-auto">606</span>
              </li>
              <li className="d-flex pd-y-5">
                Retweets
                <span className="mg-l-auto">75</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header align-items-center">
            <span className="social-icon">{Icon('instgram')}</span>
            <div className="mg-l-5">
              <strong>Hanbiro</strong>
            </div>
          </div>
          <div className="card-body">
            <ul className="list-unstyled mg-b-0">
              <li className="d-flex pd-y-5">
                Posts
                <span className="mg-l-auto">49</span>
              </li>
              <li className="d-flex pd-y-5">
                Followers
                <span className="mg-l-auto">83</span>
              </li>
              <li className="d-flex pd-y-5">
                Following
                <span className="mg-l-auto">59</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
