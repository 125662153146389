import Icon from '@base/assets/icons/svg-icons';
import { ChevronDown, Mail, MessageCircle, User, UserCheck, X } from 'react-feather';

const AssignedRep = () => {
  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <button type="button" className="view-aside-section-move mg-r-5">
          {Icon('move')}
          <span className="sr-only">드래그앤드롭으로 이동</span>
        </button>
        <span className="view-aside-section-header-title">Assigned Rep.</span>
        <button
          type="button"
          className="btn btn-link link-03 mg-l-auto pd-0"
          data-toggle="collapse"
          data-target="#customerRep"
        >
          <ChevronDown />
        </button>
      </div>
      <div id="customerRep" className="view-aside-section-body collapse show">
        <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-3 bg-warning rounded-30">
          <div
            className="media align-items-center flex-grow-1"
            style={{ maxWidth: 'calc(100% - 36px' }}
          >
            <div className="avatar avatar-xs">
              <img
                src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                className="rounded-circle"
              />
            </div>
            <div className="media-body mg-l-5 text-truncate">MSR</div>
          </div>
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
          >
            <X className="tx-danger" />
            <span className="sr-only">삭제</span>
          </button>
        </div>
        <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-3">
          <div
            className="media align-items-center flex-grow-1"
            style={{ maxWidth: 'calc(100% - 108px' }}
          >
            <div className="avatar avatar-xs">
              <img
                src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                className="rounded-circle"
              />
            </div>
            <div className="media-body mg-l-5 text-truncate">KDH</div>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Send Email"
            >
              <Mail className="tx-teal" />
              <span className="sr-only">이메일 보내기</span>
            </button>
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Chat"
            >
              <MessageCircle className="tx-indigo" /> <span className="sr-only">채팅하기</span>
            </button>
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Delete"
            >
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </div>
        <div className="dropdown">
          <div className="input-group" data-toggle="dropdown">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to add an user"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                <User />
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p bg-white">
            <button type="button" className="dropdown-item">
              <UserCheck className="mg-r-5" /> Assign to me
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              <div className="media align-items-center">
                <div className="avatar avatar-xs">
                  <img
                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img14.jpg"
                    alt=""
                    className="rounded-circle"
                  />
                </div>
                <div className="media-body mg-l-5 text-truncate">John Doe</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedRep;
