import { Button, Checkbox } from '@base/components/form';

const RecConfirm = () => {
  return (
    <div
      id="recConfirm"
      className="modal over-modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Confirm</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Name</label>
              <div>Jane Doe</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Account</label>
              <div>Adventure Works</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Email</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Website</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Telephone</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Address</label>
              <div>&nbsp;</div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecConfirm;
