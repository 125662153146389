import React, { useState } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, Plus, ArrowLeft, Copy } from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "ProductCss/product.scss"

import Attachments from '../../../component/desktop/attachments';
import AssignedRep from '../../../component/desktop/assigned-rep';
import Userlist from '../../../component/desktop/userlist';
import Notelist from '../../../component/desktop/comment';
import Timeline from './timeline';

import ViewBasicInfo from './view-new/basicInfo';
import ViewItem from './view-new/item';
import ViewPricing from './view-new/pricing';
import ViewPurchase from './view-new/purchase';
import ViewTransaction from './view-new/transaction';
import ViewStockMovement from './view-new/stockMovement';
import ViewRelatedCustomer from './view-new/relatedCustomer';
import ViewLinkedProduct from './view-new/linkedProduct';
import ViewBOM from './view-new/bom';
import NewProductItem from './modal/newProductItem';
import NewVendor from './modal/newProductVendor';
import NewProductImg from './modal/newProductImage';
import NewBOM from './modal/newBOM';

import NewProduct from './modal/newProduct';
import NewItem from './modal/newItem';
import NewComponent from './modal/newComponent';
import NewUnit from './modal/newUnit';
import NewPriceList from './modal/newPriceList';
import NewDiscount from './modal/newDiscount';
import NewTradeAgreement from './modal/newTradeAgreement';
import NewAttribute from './modal/newProductItemAttribute';
import NewDetailCost from './modal/newProducDetailCost';

const TABS = [
  {
    value: 1,
    label: 'Timeline',
  },
  {
    value: 2,
    label: 'Item',
  },
  {
    value: 3,
    label: 'Pricing',
  },
  {
    value: 4,
    label: 'Purchase',
  },
  {
    value: 5,
    label: 'Transaction',
  },
  {
    value: 6,
    label: 'Stock Movement',
  },
  {
    value: 7,
    label: 'Linked Product',
  },
  {
    value: 8,
    label: 'Related Customers',
  },
  {
    value: 9,
    label: 'Bill of Materials',
  },
  {
    value: 10,
    label: 'Note',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a href="/product/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Products
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item active">
                <a role="button">Products</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a role="button">Price List</a>
              </div>
            </div>
          </div>
          <h1 className="view-title">Product 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Copy />
                    </span>
                    Clone
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="btn-group dropdown mg-l-5">
              <button
                type="button"
                className="btn btn-primary pd-x-10"
                data-toggle="modal"
                data-target="#newProduct"
              >
                <Plus />
                <span className="sr-only">추가</span>
              </button>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <ChevronDown />
                <span className="sr-only">추가 옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newProduct"
                >
                  Product
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newItem"
                >
                  Item
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newComponent"
                >
                  Component
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newUnit"
                >
                  Unit
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newPriceList"
                >
                  Price List
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newDiscount"
                >
                  Discount
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newTradeAgreement"
                >
                  Trade Agreement
                </button>
              </div>
            </div>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              {/* Summary */}
              <ViewBasicInfo />
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && <Timeline />}
              {tab.value == 2 && <ViewItem />}
              {tab.value == 3 && <ViewPricing />}
              {tab.value == 4 && <ViewPurchase />}
              {tab.value == 5 && <ViewTransaction />}
              {tab.value == 6 && <ViewStockMovement />}
              {tab.value == 7 && <ViewLinkedProduct />}
              {tab.value == 8 && <ViewRelatedCustomer />}
              {tab.value == 9 && <ViewBOM />}
              {tab.value == 10 && <Notelist />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}
              {/* Customer */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Related Customer</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerContact"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerContact" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Attachment */}
              <Attachments />
            </div>
          </div>
        </div>
      </div>

      <NewProductItem />
      <NewVendor />
      <NewProductImg />
      <NewProduct />
      <NewItem />
      <NewComponent />
      <NewUnit />
      <NewPriceList />
      <NewDiscount />
      <NewTradeAgreement />
      <NewAttribute />
      <NewDetailCost />
      <NewBOM />
    </>
  );
};

export default ViewNew;
