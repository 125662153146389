import { useState } from 'react';
import classnames from 'classnames';
import '@base/components/sidebar-menu/styles.scss';
import Format from './format';
import PriceDiscount from './price-discount';
import { FormIcon } from '@base/components/form';

const NAVS = [
  {
    value: 'Settings',
    label: 'Format Settings',
  },
  {
    value: 'CheckSquare',
    label: 'Selection Fields',
  },
  {
    value: 'ShoppingCart',
    label: 'Price & Discount',
  },
];

const General = () => {
  const [nav, setNav] = useState(NAVS[0]);
  const [hideSideNav, setSideNav] = useState(false);

  return (
    <div className={classnames('page-container-wrap', { 'hide-sidebar': hideSideNav })}>
      <div className="page-menu-wrap">
        <div className="page-menu-nav-sidebar">
          <ul className="page-menu-sidebar-nav">
            {NAVS.map((item, index) => (
              <li className="page-menu-nav-item" key={index}>
                <button
                  className={classnames('btn page-menu-nav-link', {
                    active: item.value == nav.value,
                  })}
                  onClick={(e) => {
                    setNav(item);
                  }}
                >
                  <FormIcon icon={item.value} />
                  <span className="mg-l-5">{item.label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="page-container-content">
        <div className="page-container-content-header">
          <h1 className="mg-b-0 tx-16 tx-uppercase tx-normal mg-l-10 mg-r-10">
            General Setting &gt; {nav.label}
          </h1>
        </div>
        <div className="scroll-box pd-20">
          {nav.value === 'Settings' && <Format />}
          {nav.value === 'ShoppingCart' && <PriceDiscount />}
        </div>
      </div>
    </div>
  );
};

export default General;
