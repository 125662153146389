import { ChevronDown, ChevronLeft, ChevronRight } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Related = () => {
  return (
    <>
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Leads</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#leadSection"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="leadSection" className="card collapse show">
          <div className="table-responsive rounded">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Name
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Account
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Owner
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" className="text-nowrap" target="_blank" title="새창으로 열림">
                      AAA
                    </a>
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="media-body mg-l-10">MSR</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Quote</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#customerQuote"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="customerQuote" className="card collapse show">
          <div className="table-responsive rounded">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Name
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Status
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Amount
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Created on
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Owner
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" className="text-nowrap" target="_blank" title="새창으로 열림">
                      AAA
                    </a>
                  </td>
                  <td>&nbsp;</td>
                  <td>￦100,000</td>
                  <td>2021-06-16</td>
                  <td>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="media-body mg-l-10">MSR</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Opportunity</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#opportunitySection"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="opportunitySection" className="card collapse show">
          &nbsp;
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Sales Order</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#salesOrderSection"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="salesOrderSection" className="card collapse show">
          &nbsp;
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Invoice</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#invoiceSection"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="invoiceSection" className="card collapse show">
          &nbsp;
        </div>
      </div>
    </>
  );
};

export default Related;
