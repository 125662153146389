import { ChevronDown, ChevronLeft, ChevronRight, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const LinkedProduct = () => {
  return (
    <div className="pd-15">
      {/* Cross Selling Products */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Cross Selling Products</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#crossSellingProducts"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="crossSellingProducts" className="card collapse show">
          <div className="table-responsive rounded">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Product Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Base Unit
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Attributes
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Items
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Linked Type
                  </th>
                  <th scope="col" className="wd-70 bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                      product name 3
                    </a>
                  </td>
                  <td>Box</td>
                  <td>Size, Color</td>
                  <td>Item 1</td>
                  <td>
                    <SelectHook
                      className="wd-150-f"
                      options={[
                        { value: 1, label: 'One Way' },
                        { value: 2, label: 'Both Way' },
                      ]}
                    />
                  </td>
                  <td align="center">
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                      product name 3
                    </a>
                  </td>
                  <td>Bottle</td>
                  <td>Size, Flavour</td>
                  <td>Item 1</td>
                  <td>
                    <SelectHook
                      className="wd-150-f"
                      options={[
                        { value: 1, label: 'One Way' },
                        { value: 2, label: 'Both Way' },
                      ]}
                    />
                  </td>
                  <td align="center">
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-12 bd-t">
            <div className="dropdown">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select a product"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open modal to select a product"
                    data-toggle="modal"
                  >
                    {Icon('product')}
                    <span className="sr-only">선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      {/* Up Selling Products */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Up Selling Products</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#upSellingProducts"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="upSellingProducts" className="card collapse show">
          <div className="table-responsive rounded">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Product Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Base Unit
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Attributes
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Items
                  </th>
                  <th scope="col" className="wd-70 bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                      product name 3
                    </a>
                  </td>
                  <td>Box</td>
                  <td>Size, Color</td>
                  <td>Item 1</td>
                  <td align="center">
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                      product name 3
                    </a>
                  </td>
                  <td>Bottle</td>
                  <td>Size, Flavour</td>
                  <td>Item 1</td>
                  <td align="center">
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-12 bd-t">
            <div className="dropdown">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select a product"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open modal to select a product"
                    data-toggle="modal"
                  >
                    {Icon('product')}
                    <span className="sr-only">선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      {/* Related Products */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Related Products</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#relatedProducts"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="relatedProducts" className="card collapse show">
          <div className="table-responsive rounded">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Product Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Base Unit
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Attributes
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Items
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Linked Type
                  </th>
                  <th scope="col" className="wd-70 bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                      product name 3
                    </a>
                  </td>
                  <td>Box</td>
                  <td>Size, Color</td>
                  <td>Item 1</td>
                  <td>
                    <SelectHook
                      className="wd-150-f"
                      options={[
                        { value: 1, label: 'One Way' },
                        { value: 2, label: 'Both Way' },
                      ]}
                    />
                  </td>
                  <td align="center">
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창 열림">
                      product name 3
                    </a>
                  </td>
                  <td>Bottle</td>
                  <td>Size, Flavour</td>
                  <td>Item 1</td>
                  <td>
                    <SelectHook
                      className="wd-150-f"
                      options={[
                        { value: 1, label: 'One Way' },
                        { value: 2, label: 'Both Way' },
                      ]}
                    />
                  </td>
                  <td align="center">
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-12 bd-t">
            <div className="dropdown">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select a product"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open modal to select a product"
                    data-toggle="modal"
                  >
                    {Icon('product')}
                    <span className="sr-only">선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedProduct;
