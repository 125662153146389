import { X } from 'react-feather';

const LicensePurchasing = () => {
  return (
    <div id="licensePurchasing" className="modal fade right">
      <div className="modal-dialog wd-auto">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Purchasing</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Order Detail</label>
              <div className="table-responsive rounded bd">
                <table className="table mg-b-0 bd-t-0">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Qty</th>
                      <th scope="col" className="text-nowrap">
                        Unit Price
                      </th>
                      <th scope="col">Unit</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mg-t-10">
                <ul className="list-unstyled lh-10 wd-50p mg-0">
                  <li className="d-flex justify-content-between">
                    <span>Sub-Total</span>
                    <span></span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>Tax</span>
                    <span></span>
                  </li>
                  <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-semibold">
                    <span>Total</span>
                    <span></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-outline-primary">
              Save and Purchasing other item
            </button>
            <button type="button" className="btn btn-primary">
              Make payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicensePurchasing;
