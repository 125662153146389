/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { ChevronDown, ArrowDown, ArrowUp, TrendingUp, TrendingDown } from 'react-feather';

const KPI = () => {
  const [discountMethod, setDiscountMethod] = useState<any>({
    percentage: true,
    amount: false,
  });

  return (
    <div className="page-container-wrap">
      <div className="page-container-content">
        <div className="page-container-content-header">
          <h1 className="mg-b-0 tx-16 tx-uppercase tx-normal mg-l-10 mg-r-10">
            Key Performance Indicator
          </h1>
        </div>
        <div className="scroll-box pd-20">
          <div className="table-responsive bg-white bd rounded-top">
            <table className="table table-bordered mg-b-0 bd-0 settings-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Available KPI
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Configuration
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    KPI View Style
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Email Open
                    <br />
                    Email Open Rate
                  </td>
                  <td>
                    <div className="form-group">
                      <label className="form-item-title">Value Source</label>
                      <div className="dropdown">
                        <div className="input-group wd-150 bg-white" data-toggle="dropdown">
                          <input
                            type="text"
                            className="form-control"
                            value={discountMethod.percentage ? 'Percentage' : 'Amount'}
                          />
                          <div className="input-group-append">
                            <button type="button" className="btn">
                              <ChevronDown />
                            </button>
                          </div>
                        </div>
                        <div className="dropdown-menu">
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => setDiscountMethod({ percentage: true })}
                          >
                            Percentage
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => setDiscountMethod({ amount: true })}
                          >
                            Amount
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Target Value</label>
                      <input type="number" className="form-control d-inline-block wd-150 mg-r-10" />
                      {discountMethod.percentage && '%'}
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Formula</label>
                      <div className="d-flex align-items-centerr">
                        <div className="text-center mg-r-10">
                          Opened
                          <hr className="mg-y-5" />
                          Sent - Bounced
                        </div>
                        <div>× 100</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="card card-body wd-200 mg-b-10">
                      <div className="tx-12 tx-spacing-1 tx-uppercase tx-semibold mg-b-5">
                        Email Open
                      </div>
                      <div className="media align-items-center">
                        <TrendingDown size={40} strokeWidth={1} className="tx-danger" />
                        <div className="media-body mg-l-10">
                          <div className="d-flex align-items-baseline tx-rubik">
                            <strong className="h3 tx-normal mg-b-0">203</strong>
                            <span className="tx-12 tx-danger mg-l-3">
                              <ArrowDown size={12} />
                              10%
                            </span>
                          </div>
                          <div className="tx-sans tx-color-03">Target : 230</div>
                        </div>
                      </div>
                    </div>
                    <div className="card card-body wd-200">
                      <div className="tx-12 tx-spacing-1 tx-uppercase tx-semibold mg-b-5">
                        Email Open Rate
                      </div>
                      <div className="media align-items-center">
                        <TrendingUp size={40} strokeWidth={1} className="tx-success" />
                        <div className="media-body mg-l-10">
                          <div className="d-flex align-items-baseline tx-rubik">
                            <strong className="h3 tx-normal mg-b-0">80%</strong>
                            <span className="tx-12 tx-success mg-l-3">
                              <ArrowUp size={12} />
                              10%
                            </span>
                          </div>
                          <div className="tx-sans tx-color-03">Target : 70%</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    Lead Capture
                    <br />
                    Page View
                    <br />
                    Visits
                  </td>
                  <td>
                    <div className="form-group">
                      <label className="form-item-title">Value Source</label>
                      <div>Number</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Target Value</label>
                      <input type="number" className="form-control d-inline-block wd-150" />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Formula</label>
                      <div>
                        No. of Lead Created
                        <br />
                        No. of entering the Page
                        <br />
                        No. of Unique ID
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center mg-b-10">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="customRadio1"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mg-b-5">
                          <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                            Lead Capture
                          </span>
                          <span className="tx-12 tx-color-04">55% goal reached</span>
                        </div>
                        <div className="d-flex justify-content-between mg-b-5">
                          <span className="h5 tx-semibold mg-b-0">50</span>
                          <span className="tx-rubik tx-color-03 mg-b-0">90</span>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={60}
                            style={{ width: '55%' }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="customRadio2"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="tx-12 tx-spacing-1 tx-uppercase tx-semibold mg-b-5">
                          Lead Capture
                        </div>
                        <div className="media">
                          <div className="wd-150">Donut Chart</div>
                          <div className="media-body mg-l-10">
                            <div className="d-flex align-items-baseline tx-rubik">
                              <strong className="h3 tx-normal mg-b-0">50</strong>
                              <span className="tx-12 tx-danger mg-l-3">
                                <ArrowDown size={12} />
                                45%
                              </span>
                            </div>
                            <div className="tx-sans tx-color-03">Target : 90</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Lead Qualification Rate</td>
                  <td>
                    <div className="form-group">
                      <label className="form-item-title">Value Source</label>
                      <div>Percentage</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Target Value</label>
                      <input type="number" className="form-control d-inline-block wd-150 mg-r-10" />
                      %
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Formula</label>
                      <div className="d-flex align-items-center">
                        <div className="text-center mg-r-10">
                          No. of Lead Qualification
                          <hr className="mg-y-5" />
                          No. of Lead Capture
                        </div>
                        <div>× 100</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center mg-b-10">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2-1"
                          name="customRadio2"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="customRadio2-1"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mg-b-5">
                          <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                            Lead Qualification Rate
                          </span>
                          <span className="tx-12 tx-color-04">55% goal reached</span>
                        </div>
                        <div className="d-flex justify-content-between mg-b-5">
                          <span className="h5 tx-semibold mg-b-0">50</span>
                          <span className="tx-rubik tx-color-03 mg-b-0">90</span>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={60}
                            style={{ width: '55%' }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2-2"
                          name="customRadio2"
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="customRadio2-2"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="tx-12 tx-spacing-1 tx-uppercase tx-semibold mg-b-5">
                          Lead Qualification Rate
                        </div>
                        <div className="media">
                          <div className="wd-150">Donut Chart</div>
                          <div className="media-body mg-l-10">
                            <div className="d-flex align-items-baseline tx-rubik">
                              <strong className="h3 tx-normal mg-b-0">50</strong>
                              <span className="tx-12 tx-danger mg-l-3">
                                <ArrowDown size={12} />
                                45%
                              </span>
                            </div>
                            <div className="tx-sans tx-color-03">Target : 90</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Cost per Lead</td>
                  <td>
                    <div className="form-group">
                      <label className="form-item-title">Value Source</label>
                      <div>Money</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Success Modal</label>
                      <div>Lower is better</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Target Value</label>
                      ＄
                      <input type="number" className="form-control d-inline-block wd-150 mg-r-10" />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Previous Average Cost </label>
                      <div>＄95</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Formula</label>
                      <div className="d-flex">
                        <div className="text-center">
                          No. of Lead Qualification
                          <hr className="mg-y-5" />
                          No. of Lead Capture
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="card wd-300 pd-20">
                      <div className="d-flex flex-wrap align-items-center justify-content-between mg-b-5">
                        <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                          Cost per Lead
                        </span>
                        <span className="tx-12 tx-color-04">55% goal reached</span>
                      </div>
                      <div className="d-flex justify-content-between mg-b-5">
                        <span className="h5 tx-semibold mg-b-0">50</span>
                        <span className="tx-rubik tx-color-03 mg-b-0">90</span>
                      </div>
                      <div className="progress ht-4 mg-b-0 op-5">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          aria-valuenow={50}
                          aria-valuemin={0}
                          aria-valuemax={60}
                          style={{ width: '55%' }}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Customer Lifetime Value</td>
                  <td>
                    <div className="form-group">
                      <label className="form-item-title">Value Source</label>
                      <div>Percentage</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Target Value</label>
                      <input type="number" className="form-control d-inline-block wd-150 mg-r-10" />
                      %
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Formula</label>
                      <div className="d-flex align-items-center">
                        <div className="text-center mg-r-10">
                          No. of Lead Qualification
                          <hr className="mg-y-5" />
                          No. of Lead Capture
                        </div>
                        <div>× 100</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center mg-b-10">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio3-1"
                          name="customRadio3"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="customRadio3-1"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mg-b-5">
                          <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                            Customer Lifetime Value(Revenue)
                          </span>
                          <span className="tx-12 tx-color-04">55% goal reached</span>
                        </div>
                        <div className="d-flex justify-content-between mg-b-5">
                          <span className="h5 tx-semibold mg-b-0">50</span>
                          <span className="tx-rubik tx-color-03 mg-b-0">90</span>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={60}
                            style={{ width: '55%' }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio3-2"
                          name="customRadio3"
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="customRadio3-2"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="tx-12 tx-spacing-1 tx-uppercase tx-semibold mg-b-5">
                          Customer Lifetime Value(Revenue)
                        </div>
                        <div className="media">
                          <div className="wd-150">Donut Chart</div>
                          <div className="media-body mg-l-10">
                            <div className="d-flex align-items-baseline tx-rubik">
                              <strong className="h3 tx-normal mg-b-0">50</strong>
                              <span className="tx-12 tx-danger mg-l-3">
                                <ArrowDown size={12} />
                                45%
                              </span>
                            </div>
                            <div className="tx-sans tx-color-03">Target : 90</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Conversion(Rate)</td>
                  <td>
                    <div className="form-group">
                      <label className="form-item-title">Value Source</label>
                      <div className="dropdown">
                        <div className="input-group wd-150 bg-white" data-toggle="dropdown">
                          <input
                            type="text"
                            className="form-control"
                            value={discountMethod.percentage ? 'Percentage' : 'Amount'}
                          />
                          <div className="input-group-append">
                            <button type="button" className="btn">
                              <ChevronDown />
                            </button>
                          </div>
                        </div>
                        <div className="dropdown-menu">
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => setDiscountMethod({ percentage: true })}
                          >
                            Percentage
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => setDiscountMethod({ amount: true })}
                          >
                            Amount
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Target Value</label>
                      <input type="number" className="form-control d-inline-block wd-150 mg-r-10" />
                      {discountMethod.percentage && '%'}
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Formula</label>
                      <div className="d-flex align-items-center">
                        <div className="text-center mg-r-10">
                          Total Sales Conversion
                          <hr className="mg-y-5" />
                          Total No. of Target Member / Web Visits / Lead Capture
                        </div>
                        <div>× 100</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center mg-b-10">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio4-1"
                          name="customRadio4"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="customRadio4-1"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mg-b-5">
                          <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                            Conversion Rate
                          </span>
                          <span className="tx-12 tx-color-04">55% goal reached</span>
                        </div>
                        <div className="d-flex justify-content-between mg-b-5">
                          <span className="h5 tx-semibold mg-b-0">50</span>
                          <span className="tx-rubik tx-color-03 mg-b-0">90</span>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={60}
                            style={{ width: '55%' }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio4-2"
                          name="customRadio4"
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="customRadio4-2"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="tx-12 tx-spacing-1 tx-uppercase tx-semibold mg-b-5">
                          Conversion Rate
                        </div>
                        <div className="media">
                          <div className="wd-150">Donut Chart</div>
                          <div className="media-body mg-l-10">
                            <div className="d-flex align-items-baseline tx-rubik">
                              <strong className="h3 tx-normal mg-b-0">50</strong>
                              <span className="tx-12 tx-danger mg-l-3">
                                <ArrowDown size={12} />
                                45%
                              </span>
                            </div>
                            <div className="tx-sans tx-color-03">Target : 90</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Sales Revenue</td>
                  <td>
                    <div className="form-group">
                      <label className="form-item-title">Value Source</label>
                      <div>Money</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Target Value</label>
                      ＄
                      <input type="number" className="form-control d-inline-block wd-150 mg-l-10" />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Formula</label>
                      <div>No. of units Sold × Average Sales Price per unit</div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center mg-b-10">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio5-1"
                          name="customRadio5"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="customRadio5-1"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mg-b-5">
                          <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                            Sales Revenue
                          </span>
                          <span className="tx-12 tx-color-04">55% goal reached</span>
                        </div>
                        <div className="d-flex justify-content-between mg-b-5">
                          <span className="h5 tx-semibold mg-b-0">50</span>
                          <span className="tx-rubik tx-color-03 mg-b-0">90</span>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={60}
                            style={{ width: '55%' }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio5-2"
                          name="customRadio5"
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="customRadio5-2"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="tx-12 tx-spacing-1 tx-uppercase tx-semibold mg-b-5">
                          Sales Revenue
                        </div>
                        <div className="media">
                          <div className="wd-150">Donut Chart</div>
                          <div className="media-body mg-l-10">
                            <div className="d-flex align-items-baseline tx-rubik">
                              <strong className="h3 tx-normal mg-b-0">50</strong>
                              <span className="tx-12 tx-danger mg-l-3">
                                <ArrowDown size={12} />
                                45%
                              </span>
                            </div>
                            <div className="tx-sans tx-color-03">Target : 90</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Sales Growth</td>
                  <td>
                    <div className="form-group">
                      <label className="form-item-title">Value Source</label>
                      <div>Number</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title d-block">Target Value</label>
                      <input type="number" className="form-control wd-150" />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Formula</label>
                      <div>&nbsp;</div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center mg-b-10">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio6-1"
                          name="customRadio6"
                          className="custom-control-input"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="customRadio6-1"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mg-b-5">
                          <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                            Sales Growth
                          </span>
                          <span className="tx-12 tx-color-04">55% goal reached</span>
                        </div>
                        <div className="d-flex justify-content-between mg-b-5">
                          <span className="h5 tx-semibold mg-b-0">50</span>
                          <span className="tx-rubik tx-color-03 mg-b-0">90</span>
                        </div>
                        <div className="progress ht-4 mg-b-0 op-5">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={60}
                            style={{ width: '55%' }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio6-2"
                          name="customRadio6"
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="customRadio6-2"></label>
                      </div>
                      <div className="card wd-300 pd-20">
                        <div className="tx-12 tx-spacing-1 tx-uppercase tx-semibold mg-b-5">
                          Sales Growth
                        </div>
                        <div className="media">
                          <div className="wd-150">Donut Chart</div>
                          <div className="media-body mg-l-10">
                            <div className="d-flex align-items-baseline tx-rubik">
                              <strong className="h3 tx-normal mg-b-0">50</strong>
                              <span className="tx-12 tx-danger mg-l-3">
                                <ArrowDown size={12} />
                                45%
                              </span>
                            </div>
                            <div className="tx-sans tx-color-03">Target : 90</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KPI;
