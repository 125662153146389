import { Plus, X } from 'react-feather';

const PromotionalItem = () => {
  return (
    <div
      id="promotionalItemModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Promotional Item</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="promotionalItemSwitch"
                />
                <label className="custom-control-label" htmlFor="promotionalItemSwitch"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing</label>
              <div className="table-responsive bg-white bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Item Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Number Implemented
                      </th>
                      <th scope="col" className="wd-70 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <input type="number" className="form-control" defaultValue="5" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <input type="number" className="form-control" defaultValue="5" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                <input type="text" className="form-control mg-r-5" placeholder="Enter the type" />
                <button type="button" className="btn btn-primary flex-shrink-0">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </div>
              <div className="mg-t-10">File Location: marketing Resources/Marketing Collateral</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Notification</label>
              <div className="d-flex align-items-center">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="printedOOHNotiCheck"
                  />
                  <label className="custom-control-label" htmlFor="printedOOHNotiCheck"></label>
                </div>
                남은 수량이 <input type="number" className="form-control wd-100 mg-x-10" />
                이하일 때 알림
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-lg btn-success">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionalItem;
