import { useState } from 'react';
import { ChevronDown, Minus, Plus, Settings, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "InventoryCss/inventory.scss";

const Write = () => {
  const [transferType, setTransferType] = useState<any>('external');
  const handleTransferTypeChange = (e: any) => {
    const { value } = e.target;
    setTransferType(value);
  };

  return (
    <>
      <div className="tx-right">
        <span className="tx-danger">*</span> is required.
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Transfer Name<span className="tx-danger">*</span>
        </label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Transfer Order ID<span className="tx-danger">*</span>
        </label>
        <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <input type="text" className="form-control" defaultValue="TO-00001" />
          <div className="input-group-append">
            <button
              type="button"
              className="btn"
              aria-label="open generator of Request for Transfer Order ID modal"
            >
              <Settings />
              <span className="sr-only">ID 생성 설정 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Scheduled on</label>
        <div className="wd-300">
          <DatePicker
            selected={new Date()}
            onChange={() => {
              console.log();
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Reason<span className="tx-danger">*</span>
        </label>
        <SelectHook className="wd-200-f" options={[{ value: 1, label: 'Inventory Revaluation' }]} />
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">
          Transfer Type<span className="tx-danger">*</span>
        </label>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="transferTypeRadio2"
            name="transferTypeRadio"
            className="custom-control-input"
            value="external"
            checked={transferType === 'external'}
            onChange={(e) => handleTransferTypeChange(e)}
          />
          <label className="custom-control-label" htmlFor="transferTypeRadio2">
            External
          </label>
        </div>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="transferTypeRadio1"
            name="transferTypeRadio"
            className="custom-control-input"
            value="internal"
            checked={transferType === 'internal'}
            onChange={(e) => handleTransferTypeChange(e)}
          />
          <label className="custom-control-label" htmlFor="transferTypeRadio1">
            Internal
          </label>
        </div>
      </div>
      {transferType === 'external' && (
        <>
          <div className="form-group">
            <label className="form-item-title">
              From<span className="tx-danger">*</span>
            </label>
            <div className="input-group">
              {/*  before selecting a location
                        <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                    */}
              <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                <span className="text-truncate">Seoul 1</span>
                <button type="button" className="btn">
                  <X />
                  <span className="sr-only">삭제</span>
                </button>
              </div>
              <div className="input-group-append">
                <button type="button" className="btn" aria-label="open modal to select a location">
                  {Icon('warehouse')}
                  <span className="sr-only">선택 팝업 열기</span>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">
              To<span className="tx-danger">*</span>
            </label>
            <div className="input-group">
              {/*  before selecting a location
                        <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                    */}
              <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                <span className="text-truncate">Seoul 2</span>
                <button type="button" className="btn">
                  <X />
                  <span className="sr-only">삭제</span>
                </button>
              </div>
              <div className="input-group-append">
                <button type="button" className="btn" aria-label="open modal to select a location">
                  {Icon('warehouse')}
                  <span className="sr-only">선택 팝업 열기</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {transferType === 'internal' && (
        <div className="form-group">
          <label className="form-item-title">
            Warehouse<span className="tx-danger">*</span>
          </label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select a location"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                {Icon('warehouse')}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Items to Transfer<span className="tx-danger">*</span>
        </label>
        <div className="text-right">
          <button type="button" className="btn btn-info">
            Scan Barcode
          </button>
        </div>

        <div className="table-responsive bg-white bd rounded-top mg-t-10">
          {transferType === 'external' && (
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Image
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Item
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Location
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    SKU
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Traceability No
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Stock on Hand
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Qty Adjusted
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    New Stock on Hand
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}>
                    <div className="avatar avatar-sm bg-gray-200 rounded">
                      <img src="assets/images/notfound.png" alt="" />
                    </div>
                  </td>
                  <td rowSpan={3}>Item 1</td>
                  <td rowSpan={3}>
                    <SelectHook className="wd-200-f" options={[{ value: 1, label: ' ' }]} />
                  </td>
                  <td rowSpan={2}>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: 'Ite-red-sma' },
                          { value: 2, label: 'Ite-red-smb' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: '5-5-5-5' },
                          { value: 2, label: '5-5-5-4' },
                          { value: 2, label: '5-5-5-3' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>200</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>100</td>
                  <td rowSpan={3}>
                    <button type="button" className="btn btn-icon">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: '5-5-5-5' },
                          { value: 2, label: '5-5-5-4' },
                          { value: 2, label: '5-5-5-3' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>200</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: 'Ite-red-sma' },
                          { value: 2, label: 'Ite-red-smb' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: '5-5-5-5' },
                          { value: 2, label: '5-5-5-4' },
                          { value: 2, label: '5-5-5-3' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>200</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>100</td>
                </tr>
              </tbody>
            </table>
          )}

          {transferType === 'internal' && (
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Image
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Item
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    SKU
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Traceability No
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    From
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Stock on Hand
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Transfer Qty
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    To
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    New Stock on Hand
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={5}>
                    <div className="avatar avatar-sm bg-gray-200 rounded">
                      <img src="assets/images/notfound.png" alt="" />
                    </div>
                  </td>
                  <td rowSpan={5}>Item 1</td>
                  <td rowSpan={4}>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: 'Ite-red-sma' },
                          { value: 2, label: 'Ite-red-smb' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td rowSpan={3}>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: '5-5-5-5' },
                          { value: 2, label: '5-5-5-4' },
                          { value: 2, label: '5-5-5-3' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td rowSpan={2}>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">A-1</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td rowSpan={2}>0</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">B-1</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>0</td>
                  <td rowSpan={5}>
                    <button type="button" className="btn btn-icon">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">B-2</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">A-2</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>0</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">B-1</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: '5-5-5-5' },
                          { value: 2, label: '5-5-5-4' },
                          { value: 2, label: '5-5-5-3' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">A-1</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>0</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">B-1</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: 'Ite-red-sma' },
                          { value: 2, label: 'Ite-red-smb' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <SelectHook
                        className="wd-200-f"
                        options={[
                          { value: 1, label: '5-5-5-5' },
                          { value: 2, label: '5-5-5-4' },
                          { value: 2, label: '5-5-5-3' },
                        ]}
                      />
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">A-1</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>0</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="input-group wd-200">
                        {/*  before selecting a location
                                            <input type="text" className="form-control" placeholder="Type or click to select a location /> 
                                        */}
                        <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                          <span className="text-truncate">B-2</span>
                          <button type="button" className="btn">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn"
                            aria-label="open modal to select a location"
                          >
                            {Icon('warehouse')}
                            <span className="sr-only">선택 팝업 열기</span>
                          </button>
                        </div>
                      </div>
                      <button type="button" className="btn btn-link btn-icon">
                        <Plus />
                      </button>
                      <button type="button" className="btn btn-icon">
                        <Minus className="tx-danger" />
                      </button>
                    </div>
                  </td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className="pd-10 bd bd-t-0 rounded-bottom">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select an item"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                {Icon('product')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Write;
