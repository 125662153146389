import React, { useState } from 'react';

import { Trash, ChevronDown, ArrowLeft, ToggleRight, Edit2 } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import PagePreview from './modal/pagePreview';
import EditPage from './modal/editPage';

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewRewardUnp = () => {
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Online Digital Contents
            <ChevronDown className="mg-l-5" />
          </button>
        </div>

        <h1 className="view-title mg-l-10 lh-1">Referral Reward Site</h1>

        <div className="button-group flex-shrink-0 mg-l-auto">
          <button
            type="button"
            data-han-tooltip="Publish"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">{Icon('publish')}</span>
            <span className="sr-only">발행</span>
          </button>
          <button
            type="button"
            data-han-tooltip="Delete"
            className="btn btn-link link-01 btn-icon han-tooltip"
          >
            <span className="span-icon">
              <Trash />
            </span>
            <span className="sr-only">삭제</span>
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px)' }}>
        <div className="card-columns site-pages-columns">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              Referral
              <span className="mg-l-auto badge badge-primary tx-13 tx-normal">Active</span>
            </div>
            <figure className="pos-relative mg-b-0">
              <img src="https://via.placeholder.com/300x160" alt="" className="img-fit-cover" />
              <div className="pos-absolute b-0 l-0 wd-100p pd-20 d-flex justify-content-center img-toolbar">
                <div className="btn-group">
                  <button type="button" className="btn btn-dark">
                    {Icon('switch_off')}Inactive
                  </button>
                  <button
                    type="button"
                    className="btn btn-white"
                    data-toggle="modal"
                    data-target="#pagePreview"
                  >
                    {Icon('preview')}Preview
                  </button>
                </div>
              </div>
            </figure>
          </div>
          <div className="card">
            <div className="card-header d-flex align-items-center">
              Invitee Sign Up
              <span className="mg-l-auto badge badge-primary tx-13 tx-normal">Active</span>
            </div>
            <figure className="pos-relative mg-b-0">
              <img src="https://via.placeholder.com/300x160" alt="" className="img-fit-cover" />
              <div className="pos-absolute b-0 l-0 wd-100p pd-20 d-flex justify-content-center img-toolbar">
                <div className="btn-group">
                  <button type="button" className="btn btn-dark">
                    {Icon('switch_off')}Inactive
                  </button>
                  <button
                    type="button"
                    className="btn btn-white"
                    data-toggle="modal"
                    data-target="#pagePreview"
                  >
                    {Icon('preview')}Preview
                  </button>
                </div>
              </div>
            </figure>
          </div>
          <div className="card">
            <div className="card-header d-flex align-items-center">
              Invitee Reward
              <span className="mg-l-auto badge badge-light tx-13 tx-normal">Prepare</span>
            </div>
            <figure className="pos-relative mg-b-0">
              <img src="https://via.placeholder.com/300x160" alt="" className="img-fit-cover" />
              <div className="pos-absolute b-0 l-0 wd-100p pd-20 d-flex justify-content-center img-toolbar">
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-info"
                    data-toggle="modal"
                    data-target="#editPageModal"
                  >
                    <Edit2 />
                    Edit
                  </button>
                  <button type="button" className="btn btn-primary">
                    <ToggleRight />
                    Active
                  </button>
                  <button
                    type="button"
                    className="btn btn-white"
                    data-toggle="modal"
                    data-target="#pagePreview"
                  >
                    {Icon('preview')}Preview
                  </button>
                </div>
              </div>
            </figure>
          </div>
          <div className="card">
            <div className="card-header d-flex align-items-center">Your Referred Stats</div>
            <figure className="pos-relative mg-b-0">
              <img src="https://via.placeholder.com/300x160" alt="" className="img-fit-cover" />
              <div className="pos-absolute b-0 l-0 wd-100p pd-20 d-flex justify-content-center img-toolbar">
                <button
                  type="button"
                  className="btn btn-white"
                  data-toggle="modal"
                  data-target="#pagePreview"
                >
                  {Icon('preview')}Preview
                </button>
              </div>
            </figure>
          </div>
          <div className="card">
            <div className="card-header d-flex align-items-center">Transaction</div>
            <figure className="pos-relative mg-b-0">
              <img src="https://via.placeholder.com/300x160" alt="" className="img-fit-cover" />
              <div className="pos-absolute b-0 l-0 wd-100p pd-20 d-flex justify-content-center img-toolbar">
                <button
                  type="button"
                  className="btn btn-white"
                  data-toggle="modal"
                  data-target="#pagePreview"
                >
                  {Icon('preview')}Preview
                </button>
              </div>
            </figure>
          </div>
        </div>
      </div>

      <PagePreview />
      <EditPage />
    </div>
  );
};

export default ViewRewardUnp;
