import { ChevronLeft, ChevronRight } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import SelectHook from '@base/components/form/select-hook';

const Pagination = () => {
  return (
    <div className="list-pagination-wrap">
      {/* for table list column settings */}
      Total: 100
      <div className="list-pagination">
        <button type="button" className="btn btn-dark" disabled aria-label="move to first">
          {Icon('move_first')}
          <span className="sr-only">처음으로</span>
        </button>
        <button type="button" className="btn btn-dark" disabled aria-label="previous">
          <ChevronLeft />
          <span className="sr-only">이전</span>
        </button>
        <button type="button" className="btn btn-dark" aria-label="next">
          <ChevronRight />
          <span className="sr-only">다음</span>
        </button>
        <button type="button" className="btn btn-dark" aria-label="move to last">
          {Icon('move_last')}
          <span className="sr-only">마지막으로</span>
        </button>
        <input type="number" className="form-control" defaultValue="1" />/ 2
      </div>
      <div className="wd-80">
        <SelectHook
          options={[
            { value: 1, label: '5' },
            { value: 2, label: '10' },
            { value: 3, label: '20' },
          ]}
          defaultValue={{ value: 2, label: '10' }}
        />
      </div>
    </div>
  );
};

export default Pagination;
