import React, { useState, CSSProperties } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, Plus, X, ArrowLeft, ThumbsUp, ThumbsDown, Eye, Pause } from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "DeskCss/desk.scss"

import Attachments from '../../../../component/desktop/attachments';
import Comment from '../../../../component/desktop/comment';
import KnowledgeBaseTimeline from './view/timeline';
import NewArticle from './modal/newArticle';

const TABS = [
  {
    value: 1,
    label: 'Content',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Note',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const mediaBodyWidth: CSSProperties = {
  maxWidth: 'calc(100% - 40px)',
};

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/knowledge-base/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Knowledge Base
            <ChevronDown className="mg-l-5" />
          </button>
          <div className="dropdown-menu nav-sub">
            <div className="dropdown-item">
              <a href="/knowledge-base/desktop/theme1/list">Ticket</a>
            </div>
            <div className="dropdown-item active">
              <a href="/knowledge-base/desktop/theme1/list">Knowledge Base</a>
              <button
                type="button"
                className="btn btn-xs btn-set-default btn-icon mg-l-5"
                aria-label="Set as default"
              >
                {/* Add'active' class if this menu is currently set by default */}
                {Icon('set_default')}
                <span className="sr-only">기본 메뉴로 설정</span>
              </button>
            </div>
            <div className="dropdown-item">
              <a href="/desk/desktop/theme1/settings">Setting</a>
            </div>
          </div>
        </div>

        <h1 className="view-title">How to access the menus?</h1>

        <div className="button-group d-flex flex-shrink-0 mg-l-auto">
          <div className="process-buttons mg-r-15">
            <div className="dropdown">
              <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                More
                <ChevronDown className="mg-l-5" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#replyModal"
                >
                  <span className="dropdown-item-icon">
                    <Pause />
                  </span>
                  Oh Hold
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary mg-l-5 rounded-30"
            data-toggle="modal"
            data-target="#newArticleModal"
          >
            <Plus size="14" />
            New
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner">
            {/* collapse left aside */}
            {!hideAsideLeft && (
              <button
                type="button"
                className="btn btn-hide-aside-left"
                onClick={() => setHideAsideLeft(true)}
              >
                <LeftCollapse />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>
            )}
            <div className="view-aside-section pd-15">
              <div className="row row-xs">
                <div className="col-xl-6">
                  <div className="media mg-b-10">
                    <div className="wd-40 ht-40 rounded bg-gray-500 tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                      <Eye />
                    </div>
                    <div className="media-body mg-l-10" style={mediaBodyWidth}>
                      <div className="tx-12 tx-uppercase tx-gray-500 tx-spacing-1 tx-nowrap">
                        Views
                      </div>
                      <div className="tx-20 tx-semibold tx-spacing--2 lh-1 text-truncate">10</div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="media mg-b-10">
                    <div className="wd-40 ht-40 rounded bg-info tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                      {Icon('ticket')}
                    </div>
                    <div className="media-body mg-l-10" style={mediaBodyWidth}>
                      <div className="tx-12 tx-uppercase tx-info tx-spacing-1 tx-nowrap">
                        Inserted
                      </div>
                      <div className="tx-20 tx-semibold tx-spacing--2 lh-1 text-truncate">1</div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="media mg-b-10 mg-xl-b-0">
                    <div className="wd-40 ht-40 rounded bg-success tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                      <ThumbsUp />
                    </div>
                    <div className="media-body mg-l-10" style={mediaBodyWidth}>
                      <div className="tx-12 tx-uppercase tx-success tx-spacing-1 tx-nowrap">
                        Helpful
                      </div>
                      <div className="tx-20 tx-semibold tx-spacing--2 lh-1 text-truncate">1</div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="media">
                    <div className="wd-40 ht-40 rounded bg-danger tx-white d-flex flex-shrink-0 align-items-center justify-content-center op-6">
                      <ThumbsDown />
                    </div>
                    <div className="media-body mg-l-10" style={mediaBodyWidth}>
                      <div className="tx-12 tx-uppercase tx-danger tx-spacing-1 tx-nowrap">
                        Not Helpful
                      </div>
                      <div className="tx-20 tx-semibold tx-spacing--2 lh-1 text-truncate">3</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Summary */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <span className="view-aside-section-header-title">Summary</span>
              </div>
              <div id="basicInfoSection" className="view-aside-section-body collapse show">
                <div className="form-group">
                  <label className="form-item-title">Type</label>
                  <div>Article</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Category</label>
                  <div>Category 1</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Available for</label>
                  <div>All</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Display to</label>
                  <div>Public</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Active</label>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="kbArticleActiveSwitch"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="kbArticleActiveSwitch"></label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title d-block">Tags</label>
                  <div className="tag-item">
                    email
                    <button type="button" className="btn-tag-remove">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames('col-6 view-content', {
            'col-9': hideAsideLeft || hideAsideRight,
            'col-12': hideAsideLeft && hideAsideRight,
          })}
        >
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('nav-item', {
                      active: item.value == tab.value,
                    })}
                  >
                    <button
                      type="button"
                      className="btn btn-xs btn-move-tab"
                      aria-label="Drag-and-drop to reorder"
                    >
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <button
                      type="button"
                      className="btn nav-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="view-body">
            {/* show left aside */}
            {hideAsideLeft && (
              <button
                type="button"
                className="btn btn-show-aside-left"
                onClick={() => setHideAsideLeft(false)}
              >
                <RightCollapse />
                <span className="sr-only">좌측 사이드 패널 열기</span>
              </button>
            )}

            {/* show right aside */}
            {hideAsideRight && (
              <button
                type="button"
                className="btn btn-show-aside-right"
                onClick={() => setHideAsideRight(false)}
              >
                <LeftCollapse />
                <span className="sr-only">우측 사이드 패널 열기</span>
              </button>
            )}
            {tab.value == 1 && (
              <div className="pd-15">
                I want to purchase an assorted set of retail equipment.
                <br />
                I’m not able to build my bundle. Can you help me with this purchase? Looking forward
                to more information!
                <div className="mg-t-10">
                  <button type="button" className="btn btn-outline-success mg-r-5">
                    <ThumbsUp className="mg-r-5" />
                    Helpful
                  </button>
                  <button type="button" className="btn btn-outline-danger">
                    <ThumbsDown className="mg-r-5" />
                    Not Helpful
                  </button>
                </div>
              </div>
            )}
            {tab.value == 2 && <KnowledgeBaseTimeline />}
            {tab.value == 3 && <Comment />}
          </div>
        </div>

        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner">
            {/* collapse right aside */}
            {!hideAsideRight && (
              <button
                type="button"
                className="btn btn-hide-aside-right"
                onClick={() => setHideAsideRight(true)}
              >
                <RightCollapse />
                <span className="sr-only">우측 사이드 패널 접기</span>
              </button>
            )}
            {/* Attachment */}
            <Attachments />
          </div>
        </div>
      </div>
      <NewArticle />
    </div>
  );
};

export default ViewNew;
