import { Button, Input, Select } from '@base/components/form';
import FileViewUpload from '@base/components/form/upload/file-view';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';

const NewTicket = () => {
  return (
    <div className="modal fade right" id="newTicket">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Ticket</h1>
            <Button close data-dismiss="modal"></Button>
          </div>
          <div className="modal-body pd-b-15-f">
            <div className="form-group">
              <label className="form-item-title">Subject</label>
              <Input type="text" />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-item-title">Customer</label>
                  <div className="media">
                    <span className="avatar avatar-xs flex-shrink-0 mg-r-10">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                        className="rounded-circle"
                      />
                    </span>
                    Customer 1
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-item-title">Contact</label>
                  <div className="media">
                    <span className="avatar avatar-xs flex-shrink-0 mg-r-10">
                      <img
                        src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                        className="rounded-circle"
                      />
                    </span>
                    Contact 1
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Category</label>
              <div className="d-flex flex-wrap">
                <ProductAutoComplete className="mg-r-10" />
                <Select width={250} defaultValue={{ value: '', label: 'Select an issue.' }} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Content</label>
              <textarea rows={5} cols={10} className="form-control"></textarea>
            </div>
            <div className="form-group">
              <label className="form-item-title">Attachment</label>
              <FileViewUpload />
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" data-dismiss="modal" name="Close" />
            <Button color="success" name="Save" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTicket;
