import { Plus, X } from 'react-feather';

const CheckGuidance = () => {
  return (
    <div
      id="checkGuidanceModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Guidance for Success</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="guidanceCheck1" />
              <label className="custom-control-label align-middle" htmlFor="guidanceCheck1">
                Make the Unique Selling Proposition more concise
              </label>
              <button type="button" className="btn btn-icon mg-l-10 pd-0 lh-1">
                <X className="tx-danger" />
              </button>
            </div>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="guidanceCheck2" />
              <label className="custom-control-label align-middle" htmlFor="guidanceCheck2">
                Make the Value Proposition more obvious how your product differentiates from
                competitors
              </label>
              <button type="button" className="btn btn-icon mg-l-10 pd-0 lh-1">
                <X className="tx-danger" />
              </button>
            </div>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="guidanceCheck3" />
              <label className="custom-control-label align-middle" htmlFor="guidanceCheck3">
                Motivation action: like a limited time offer or a free trial
              </label>
              <button type="button" className="btn btn-icon mg-l-10 pd-0 lh-1">
                <X className="tx-danger" />
              </button>
            </div>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="guidanceCheck4" />
              <label className="custom-control-label align-middle" htmlFor="guidanceCheck4">
                Present a benefit or interest to your reader with important information
              </label>
              <button type="button" className="btn btn-icon mg-l-10 pd-0 lh-1">
                <X className="tx-danger" />
              </button>
            </div>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="guidanceCheck5" />
              <label className="custom-control-label align-middle" htmlFor="guidanceCheck5">
                Insert of a visual element or headline that will capture the reader s attention
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="guidanceCheck6" />
              <label className="custom-control-label align-middle" htmlFor="guidanceCheck6">
                Don t make it hard for your prospect to receive your Printed Ads
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="guidanceCheck7" />
              <label className="custom-control-label align-middle" htmlFor="guidanceCheck7">
                Show statistics or testimonials
              </label>
            </div>
            <div className="d-flex align-items-center mg-t-10 pd-t-10 bd-t">
              <input type="text" className="form-control mg-r-10" />
              <button type="button" className="btn btn-primary flex-shrink-0">
                <Plus className="mg-r-5" />
                Add
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckGuidance;
