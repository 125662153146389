import { Plus, ChevronsRight, ChevronLeft } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
const LeadActivities = () => {
  return (
    <div className="pd-20">
      <div className="text-right mg-b-10">
        <button type="button" className="btn btn-primary rounded-30">
          <Plus className="mg-r-5" />
          Activity
        </button>
      </div>
      <div className="card">
        <div className="table-responsive">
          <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f sorting">
                  Subject
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Type
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Distribution
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer pd-y-6-f pagination">
          <button
            type="button"
            className="btn btn-link link-02 btn-icon"
            aria-label="move to first"
          >
            {Icon('move_first')}
            <span className="sr-only">처음으로</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
            <ChevronLeft />
            <span className="sr-only">이전</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
            <ChevronsRight />
            <span className="sr-only">다음</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to last">
            {Icon('move_last')}
            <span className="sr-only">마지막으로</span>
          </button>
          <input type="number" className="paging-input" defaultValue="1" />/ 1
        </div>
      </div>
    </div>
  );
};
export default LeadActivities;
