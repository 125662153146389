import React, { useState } from 'react';
import classnames from 'classnames';

import { X, User, Calendar, ChevronDown } from 'react-feather';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "DeskCss/desk.scss";

const Report = () => {
  const [reportType, setReportType] = useState<any>({
    graph: true,
    data: false,
  });
  return (
    <>
      <div className="input-group mg-b-10">
        <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
          <div className="form-control-tag">
            {/* <span className="d-block avatar avatar-xs mg-r-10">
                        <img src="https://global3.hanbiro.com/ncrm/user/photo/1/1" alt="John" className="img rounded-circle" />
                    </span>
                    <span className="form-control-tag-name">John</span> */}
            <span className="form-control-tag-name">All</span>
            <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
              <X />
            </button>
          </div>
          <input
            type="text"
            className="bd-0 flex-grow-1 mn-wd-250"
            placeholder="Type or click to select an user"
          />
        </div>
        <div className="input-group-append">
          <button type="button" className="btn" aria-label="open modal to select a user">
            <User />
            <span className="sr-only">담당자 선택 팝업 열기</span>
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <ul className="nav nav-tabs list-tabs bd-b-0">
          <li className="nav-item">
            <button
              type="button"
              className={classnames('btn nav-link', { active: reportType.graph })}
              onClick={() => setReportType({ graph: true })}
            >
              Graph
            </button>
          </li>
          <li className="nav-item">
            <button
              type="button"
              className={classnames('btn nav-link', { active: reportType.data })}
              onClick={() => setReportType({ data: true })}
            >
              Data
            </button>
          </li>
        </ul>
        <div className="dropdown mg-l-auto">
          <button type="button" className="btn pd-0" data-toggle="dropdown">
            Today
            <ChevronDown className="mg-l-5" />
          </button>
          <div className="dropdown-menu dropdown-menu-right wd-280">
            <button type="button" className="dropdown-item">
              This Week
            </button>
            <button type="button" className="dropdown-item">
              This Month
            </button>
            <div className="dropdown-divider"></div>
            <div className="dropdown-header">Custom</div>
            <div className="input-group pd-x-25 pd-y-5">
              <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
              <div className="input-group-append">
                <span className="input-group-text">
                  <Calendar />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered dataTable">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                By Status / Assignee
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                Assigned
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                Replied
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                On Hold
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                Closed
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                Overdue
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                Avg. First Response Time
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                Avg. Response Time
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap sorting">
                Avg. Closed Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="d-inline-block avatar avatar-xs mg-r-5">
                  <img
                    src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                    className="rounded-circle"
                    alt=""
                  />
                </div>
                MSR
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div
                      className="progress wd-50 ht-15 rounded-0 mg-r-10"
                      style={{ width: '36.5px' }}
                    >
                      <div className="progress-bar wd-100p"></div>
                      {/* lowest */}
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    3h 15m
                  </div>
                ) : (
                  '3h 15m'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    3h 15m
                  </div>
                ) : (
                  '3h 15m'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress ht-15 rounded-0 mg-r-10" style={{ width: '36px' }}>
                      <div className="progress-bar wd-100p"></div>
                      {/* lowest */}
                    </div>
                    2h 15m
                  </div>
                ) : (
                  '2h 15m'
                )}
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-inline-block avatar avatar-xs mg-r-5">
                  <img
                    src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                    className="rounded-circle"
                    alt=""
                  />
                </div>
                KDH
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-danger"></div>
                      {/* highest : bg-danger */}
                    </div>
                    38
                  </div>
                ) : (
                  '38'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    28
                  </div>
                ) : (
                  '28'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    3h 15m
                  </div>
                ) : (
                  '3h 15m'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-gray-400"></div>
                    </div>
                    3h 15m
                  </div>
                ) : (
                  '3h 15m'
                )}
              </td>
              <td>
                {reportType.graph ? (
                  <div className="d-flex align-items-center">
                    <div className="progress wd-50 ht-15 rounded-0 mg-r-10">
                      <div className="progress-bar wd-100p bg-danger"></div>
                      {/* highest */}
                    </div>
                    3h 15m
                  </div>
                ) : (
                  '3h 15m'
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Report;
