import { useState } from 'react';
import { Search, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const MergeAccount = () => {
  const [mergeAll, setMergeAll] = useState<any>();
  const [mergeMaster, setMergeMaster] = useState('master1');
  const handleMergeMaster = (e: any) => {
    const { value } = e.target;
    setMergeMaster(value);
  };

  return (
    <div id="mergeAccount" className="modal fade">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="h6 modal-title">Merge Account</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body customer-profile-reg">
            <div className="form-group">
              <label className="form-item-title">Select Criteria</label>
              <div className="d-flex align-items-center">
                <SelectHook
                  className="wd-300-f"
                  options={[
                    { value: 1, label: 'Contact Name' },
                    { value: 2, label: 'Contact Email' },
                    { value: 3, label: 'Contact Telephone' },
                  ]}
                />
                <button type="button" className="btn btn-primary mg-l-10">
                  <Search className="mg-r-5" />
                  Search
                </button>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Duplicate Contacts</label>
              <div className="input-group">
                <input type="search" className="form-control" />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <Search />
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Select Master Contact</label>
              <div className="custom-control custom-checkbox mg-b-10">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="mergeAll"
                  checked={mergeAll}
                  onChange={() => setMergeAll(!mergeAll)}
                />
                <label className="custom-control-label" htmlFor="mergeAll">
                  Merge all of following
                </label>
              </div>
              <div className="table-responsive bd rounded">
                <table className="table table-bordered bd-0 mg-b-0">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Master
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Account Name
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Email
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Phone
                      </th>
                      <th scope="col" className="bd-t-0-f text-nowrap">
                        Address
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="mergeMasterRadio"
                            id="mergeMasterRadio1"
                            value="master1"
                            checked={mergeMaster === 'master1'}
                            onChange={(e) => handleMergeMaster(e)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="mergeMasterRadio1"
                          ></label>
                        </div>
                      </td>
                      <td className="text-nowrap">Account1</td>
                      <td className="text-nowrap">
                        {!(mergeAll || mergeMaster === 'master1') && (
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="mergeFieldCheck1"
                            />
                            <label className="custom-control-label" htmlFor="mergeFieldCheck1">
                              mail@sales.com
                            </label>
                          </div>
                        )}
                        {(mergeAll || mergeMaster === 'master1') && 'mail@sales.com'}
                      </td>
                      <td className="text-nowrap">
                        {!(mergeAll || mergeMaster === 'master1') && (
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="mergeFieldCheck2"
                            />
                            <label className="custom-control-label" htmlFor="mergeFieldCheck2">
                              02-0000-0000
                            </label>
                          </div>
                        )}
                        {(mergeAll || mergeMaster === 'master1') && '02-0000-0000'}
                      </td>
                      <td className="text-nowrap">
                        {!(mergeAll || mergeMaster === 'master1') && (
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="mergeFieldCheck3"
                            />
                            <label className="custom-control-label" htmlFor="mergeFieldCheck3">
                              Gangnam-gu, Seoul, 11th floor, 602, Seolleung-ro
                            </label>
                          </div>
                        )}
                        {(mergeAll || mergeMaster === 'master1') &&
                          'Gangnam-gu, Seoul, 11th floor, 602, Seolleung-ro'}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="mergeMasterRadio"
                            id="mergeMasterRadio2"
                            value="master2"
                            checked={mergeMaster === 'master2'}
                            onChange={(e) => handleMergeMaster(e)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="mergeMasterRadio2"
                          ></label>
                        </div>
                      </td>
                      <td className="text-nowrap">Account2</td>
                      <td className="text-nowrap">
                        {!(mergeAll || mergeMaster === 'master2') && (
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="mergeFieldCheck4"
                            />
                            <label className="custom-control-label" htmlFor="mergeFieldCheck4">
                              mail2@sales.com
                            </label>
                          </div>
                        )}
                        {(mergeAll || mergeMaster === 'master2') && 'mail2@sales.com'}
                      </td>
                      <td className="text-nowrap">
                        {!(mergeAll || mergeMaster === 'master2') && (
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="mergeFieldCheck5"
                            />
                            <label className="custom-control-label" htmlFor="mergeFieldCheck5">
                              02-1111-0000
                            </label>
                          </div>
                        )}
                        {(mergeAll || mergeMaster === 'master2') && '02-1111-0000'}
                      </td>
                      <td className="text-nowrap">
                        {!(mergeAll || mergeMaster === 'master2') && (
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="mergeFieldCheck6"
                            />
                            <label className="custom-control-label" htmlFor="mergeFieldCheck6">
                              Seoul, 11th floor, 602, Seolleung-ro
                            </label>
                          </div>
                        )}
                        {(mergeAll || mergeMaster === 'master2') &&
                          'Seoul, 11th floor, 602, Seolleung-ro'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ul className="list-unstyled mg-t-10 mg-b-0 pd-0">
                <li className="mg-b-5 text-muted">
                  - Select the master account to whom the account(s) should be merged and check the
                  records to merge to the master.
                </li>
                <li className="text-muted">
                  - Once merged, all the Activities, Notes, Attachments, Contact, Opportunities,
                  Tickets and transactions are transrered to the master account.
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Merge
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MergeAccount;
