import { useState } from 'react';
import classnames from 'classnames';

import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Button, Select, Select as SelectHook } from '@base/components/form';

import { ChevronDown, Star, RefreshCw } from 'react-feather';
import RevenueSettings from './modal/revenueSettings';

const TABS = [
  {
    value: 1,
    label: 'Sales Detail',
  },
  {
    value: 2,
    label: 'Sales Revenue',
  },
  {
    value: 3,
    label: 'Sales Growth',
  },
  {
    value: 4,
    label: 'Customer Lifetime Value',
  },
];

const List = () => {
  const [tab, setTab] = useState(TABS[0]);

  const [period, setPeriod] = useState<any>({
    fq: true,
    month: false,
    year: false,
    fy: false,
  });

  const [showFiscal, setShowFiscal] = useState<any>({
    first: false,
    second: false,
    third: false,
  });

  const tabInfo1 = () => {
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <Popover id="info1">
            <Popover.Body>Sales Revenue - COGS = Gross Profit</Popover.Body>
          </Popover>
        }
        rootClose={true}
      >
        <div className="pos-relative">
          <Button color="link link-03" icon="HelpCircle" className="bn-icon pd-0" />
        </div>
      </OverlayTrigger>
    );
  };
  const tabInfo2 = () => {
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <Popover id="info1">
            <Popover.Header>Sales Growth Formular</Popover.Header>
            <Popover.Body>
              <div className="text-center">
                Sales for the current period - the previous period
                <hr className="mg-y-5" />
                the previous period
              </div>
            </Popover.Body>
          </Popover>
        }
        rootClose={true}
      >
        <div className="pos-relative">
          <Button color="link link-03" icon="HelpCircle" className="bn-icon pd-0" />
        </div>
      </OverlayTrigger>
    );
  };
  const tabInfo3 = () => {
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <Popover id="info1">
            <Popover.Body>
              <div className="table mg-b-0">
                <tfoot>
                  <tr>
                    <td colSpan={2} align="right">
                      ＄5.00
                    </td>
                  </tr>
                </tfoot>
                <tbody>
                  <tr>
                    <td>
                      Average Value of a Purchase
                      <img
                        src="https://latex.codecogs.com/svg.image?%5Csmall%20%5Cfrac%7BTotal%20Gross%20Profit(Revenue)%7D%7BTotal%20No.%20of%20Purchase%7D"
                        alt="\frac{Total Gross Profit(Revenue)}{Total No. of Purchase}"
                        width={180}
                        className="mg-t-10"
                      />
                    </td>
                    <td>＄500 per year (/ 6 months / 3 months)</td>
                  </tr>
                  <tr>
                    <td>
                      Average Purchase Frequency
                      <img
                        src="https://latex.codecogs.com/svg.image?%5Csmall%20%5Cfrac%7BTotal%20No.%20of%20Purchase%7D%7BTotal%20No.%20of%20Unique%20Customers%7D"
                        alt="\frac{Total No. of Purchase}{Total No. of Unique Customers} "
                        width={180}
                        className="mg-t-10"
                      />
                    </td>
                    <td>2 times per year(/ 6 months / 3 months)</td>
                  </tr>
                  <tr>
                    <td>
                      Average Customer Lifetime
                      <img
                        src="https://latex.codecogs.com/svg.image?%5Csmall%20%5Cfrac%7BCustomer%20Lifetime%20Total%7D%7BTotal%20No.%20of%20Unique%20Customers%7D"
                        alt="\frac{Customer Lifetime Total}{Total No. of Unique Customers} "
                        width={180}
                        className="mg-t-10"
                      />
                    </td>
                    <td>5 year</td>
                  </tr>
                </tbody>
              </div>
            </Popover.Body>
          </Popover>
        }
        rootClose={true}
      >
        <div className="pos-relative">
          <Button color="link link-03" icon="HelpCircle" className="bn-icon pd-0" />
        </div>
      </OverlayTrigger>
    );
  };

  const renderDetail = () => {
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <Popover id="growth-detail">
            <Popover.Body>
              <table className="table table-bordered mg-b-0 bd-0">
                <tbody>
                  <tr>
                    <th scope="row" className="bg-success-light">
                      Period
                    </th>
                    <td>Quarter 1</td>
                    <td>Previous Quarter</td>
                  </tr>
                  <tr>
                    <th scope="row" className="bg-success-light">
                      Revenue
                    </th>
                    <td>＄</td>
                    <td>＄</td>
                  </tr>
                  <tr>
                    <th scope="row" className="bg-success-light">
                      Sales Growth
                    </th>
                    <td colSpan={2}>+＄(10%)</td>
                  </tr>
                </tbody>
              </table>
            </Popover.Body>
          </Popover>
        }
      >
        <Button color="link" className="pd-0">
          Detail
        </Button>
      </OverlayTrigger>
    );
  };

  const renderDetail2 = () => {
    return (
      <>
        <div className="pos-relative"></div>
        {/* <Popover
          isOpen={isOpenDetailPopover2}
          toggle={() => setIsOpenDetailPopover2(false)}
          trigger="legacy"
          placement="left"
          target={idDetailPop2}
        >
          <PopoverBody className="pd-0-f">
            <table className="table table-bordered mg-b-0 bd-0">
              <tbody>
                <tr>
                  <th scope="row" className="bg-success-light">
                    Period
                  </th>
                  <td>Quarter 1</td>
                  <td>Previous Quarter</td>
                  <td>Two Quarter ago</td>
                  <td>Three Quarter ago</td>
                </tr>
                <tr>
                  <th scope="row" className="bg-success-light">
                    Revenue
                  </th>
                  <td>＄</td>
                  <td>＄</td>
                  <td>＄</td>
                  <td>＄</td>
                </tr>
                <tr>
                  <th scope="row" className="bg-success-light">
                    Sales Growth
                  </th>
                  <td>+10%</td>
                  <td>+10%</td>
                  <td>+10%</td>
                  <td>+10%</td>
                </tr>
                <tr>
                  <th scope="row" className="bg-success-light">
                    Average Organic Sales Growth
                  </th>
                  <td colSpan={4}>+10%</td>
                </tr>
              </tbody>
            </table>
          </PopoverBody>
        </Popover> */}
      </>
    );
  };

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Sales Report</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
          </div>
          <div className="button-group mg-l-auto">
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
          </div>
        </div>

        <div className="d-flex flex-wrap align-items-center">
          {/* tabs */}
          <ul className="nav nav-tabs list-tabs mg-x-15 mg-t-10 bd-b-0">
            {TABS.map((item, index) => (
              <li className="nav-item" key={index}>
                <button
                  type="button"
                  className={classnames('btn nav-link', {
                    active: item.value == tab.value,
                  })}
                  onClick={(e) => {
                    setTab(item);
                  }}
                >
                  {item.label}
                  {/* {item.value != 1 && (
                    <button type="button" className="btn btn-link link-03 btn-icon mg-l-5">
                      <HelpCircle size={16} />
                    </button>
                  )} */}
                  {/* <Popover placement="top" isOpen={popoverOpen} target="revenue" toggle={toggle}><PopoverBody>
                                    <div className="wd-200 pd-10">Sales Revenue - COGS = Gross Profit</div>    
                                </PopoverBody></Popover> */}
                  {item.value == 2 && (
                    <>
                      {tabInfo1()}
                      <Button
                        color="link link-03"
                        className="mg-l-10 pd-l-10 pd-r-0 pd-y-0 bd-l"
                        icon="Settings"
                        data-han-tooltip="Settings"
                        data-toggle="modal"
                        data-target="#revenueSettings"
                      />
                    </>
                  )}
                  {item.value == 3 && <>{tabInfo2()}</>}
                  {item.value == 4 && <>{tabInfo3()}</>}
                </button>
              </li>
            ))}
          </ul>
          {tab.value == 4 && (
            <Select
              width={200}
              className="mg-l-auto mg-r-15"
              options={[
                { value: 1, label: 'General' },
                { value: 2, label: 'Event' },
                { value: 3, label: 'Referral Reward' },
                { value: 4, label: 'Ideal Customer Profile' },
                { value: 5, label: 'Buyer Persona' },
              ]}
              defaultValue={{ value: 1, label: 'General' }}
            />
          )}
        </div>

        <div className="list-body">
          {/* Sales Detail */}
          {tab.value == 1 && (
            <div className="table-responsive">
              <table className="table table-bordered dataTable bg-white mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">Customer</th>
                    <th scope="col">Ideal Customer</th>
                    <th scope="col">No of Purchase</th>
                    <th scope="col">Campaign</th>
                    <th scope="col">Date Purchased</th>
                    <th scope="col">Sales Rep.</th>
                    <th scope="col">Item</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Total Discount</th>
                    <th scope="col">Sub Total</th>
                    <th scope="col">COG</th>
                    <th scope="col">Commission</th>
                    <th scope="col">Gross Profit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={4}>Adventure Works</td>
                    <td rowSpan={4}>Startup Sam</td>
                    <td rowSpan={3}>1</td>
                    <td rowSpan={3}>&nbsp;</td>
                    <td rowSpan={3}>&nbsp;</td>
                    <td rowSpan={3}>&nbsp;</td>
                    <td>A</td>
                    <td>&nbsp;</td>
                    <td>600</td>
                    <td>60</td>
                    <td>540</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>&nbsp;</td>
                    <td>400</td>
                    <td>40</td>
                    <td>360</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>&nbsp;</td>
                    <td>1,000</td>
                    <td>100</td>
                    <td>900</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* Sales Revenue */}
          {tab.value == 2 && (
            <div className="rounded-bottom ht-100p list-tab-body card-group">
              <div
                className="card bg-transparent ht-100p"
                style={{ flex: 'unset', width: '33.33333333333%' }}
              >
                <div className="card-header tx-bold">Yearly</div>
                <div className="card-body overflow-auto">
                  <div className="tx-semibold">2019</div>
                  <div
                    className={`card mg-b-20 pd-15 ${
                      showFiscal.first ? 'bg-primary tx-white' : ''
                    }`}
                    role="button"
                    onClick={() => setShowFiscal({ first: true })}
                  >
                    <ul className="list-unstyled mg-0">
                      <li className="d-flex align-items-center">
                        Sales Revenue
                        <span className="mg-l-auto">0</span>
                      </li>
                      <li className="d-flex align-items-center">
                        Cost of Goods Sold
                        <span className="mg-l-auto">0</span>
                      </li>
                      <li className="d-flex align-items-center">
                        Gross Profit
                        <span className="mg-l-auto">0</span>
                      </li>
                    </ul>
                  </div>
                  <div className="tx-semibold">2020</div>
                  <div
                    className={`card mg-b-20 pd-15 ${
                      showFiscal.second ? 'bg-primary tx-white' : ''
                    }`}
                    role="button"
                    onClick={() => setShowFiscal({ second: true })}
                  >
                    <ul className="list-unstyled mg-0">
                      <li className="d-flex align-items-center">
                        Sales Revenue
                        <span className="mg-l-auto">0</span>
                      </li>
                      <li className="d-flex align-items-center">
                        Cost of Goods Sold
                        <span className="mg-l-auto">0</span>
                      </li>
                      <li className="d-flex align-items-center">
                        Gross Profit
                        <span className="mg-l-auto">0</span>
                      </li>
                    </ul>
                  </div>
                  <div className="tx-semibold">2021</div>
                  <div
                    className={`card mg-b-20 pd-15 ${
                      showFiscal.third ? 'bg-primary tx-white' : ''
                    }`}
                    role="button"
                    onClick={() => setShowFiscal({ third: true })}
                  >
                    <ul className="list-unstyled mg-0">
                      <li className="d-flex align-items-center">
                        Sales Revenue
                        <span className="mg-l-auto">0</span>
                      </li>
                      <li className="d-flex align-items-center">
                        Cost of Goods Sold
                        <span className="mg-l-auto">0</span>
                      </li>
                      <li className="d-flex align-items-center">
                        Gross Profit
                        <span className="mg-l-auto">0</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {(showFiscal.first || showFiscal.second || showFiscal.third) && (
                <div className="card bg-transparent ht-100p">
                  <div className="card-header">
                    <div className="row row-xs wd-100p">
                      <div className="col-md-6 tx-bold">Fiscal Quarter</div>
                      <div className="col-md-6 tx-bold">Month</div>
                    </div>
                  </div>
                  <div className="card-body overflow-auto">
                    <div className="row row-xs">
                      <div className="col-md-6">
                        <div className="pd-y-5 tx-semibold">Quarter 1</div>
                        <div className="card mg-b-20 pd-15">
                          <ul className="list-unstyled mg-0">
                            <li className="d-flex align-items-center">
                              Sales Revenue
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Cost of Goods Sold
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Gross Profit
                              <span className="mg-l-auto">0</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex align-items-center">
                          <span className="tx-semibold">January</span>
                          <button type="button" className="btn btn-xs btn-white mg-l-auto">
                            Detail
                          </button>
                          <button type="button" className="btn btn-xs btn-white mg-l-5">
                            Add Sold Item
                          </button>
                        </div>
                        <div className="card mg-b-20 pd-15">
                          <ul className="list-unstyled mg-0">
                            <li className="d-flex align-items-center">
                              Sales Revenue
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Cost of Goods Sold
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Gross Profit
                              <span className="mg-l-auto">0</span>
                            </li>
                          </ul>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="tx-semibold">February</span>
                          <button type="button" className="btn btn-xs btn-white mg-l-auto">
                            Detail
                          </button>
                          <button type="button" className="btn btn-xs btn-white mg-l-5">
                            Add Sold Item
                          </button>
                        </div>
                        <div className="card mg-b-20 pd-15">
                          <ul className="list-unstyled mg-0">
                            <li className="d-flex align-items-center">
                              Sales Revenue
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Cost of Goods Sold
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Gross Profit
                              <span className="mg-l-auto">0</span>
                            </li>
                          </ul>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="tx-semibold">March</span>
                          <button type="button" className="btn btn-xs btn-white mg-l-auto">
                            Detail
                          </button>
                          <button type="button" className="btn btn-xs btn-white mg-l-5">
                            Add Sold Item
                          </button>
                        </div>
                        <div className="card mg-b-20 pd-15">
                          <ul className="list-unstyled mg-0">
                            <li className="d-flex align-items-center">
                              Sales Revenue
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Cost of Goods Sold
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Gross Profit
                              <span className="mg-l-auto">0</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row row-xs">
                      <div className="col-md-6">
                        <div className="pd-y-5 tx-semibold">Quarter 2</div>
                        <div className="card mg-b-20 pd-15">
                          <ul className="list-unstyled mg-0">
                            <li className="d-flex align-items-center">
                              Sales Revenue
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Cost of Goods Sold
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Gross Profit
                              <span className="mg-l-auto">0</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex align-items-center">
                          <span className="tx-semibold">April</span>
                          <button type="button" className="btn btn-xs btn-white mg-l-auto">
                            Detail
                          </button>
                          <button type="button" className="btn btn-xs btn-white mg-l-5">
                            Add Sold Item
                          </button>
                        </div>
                        <div className="card mg-b-20 pd-15">
                          <ul className="list-unstyled mg-0">
                            <li className="d-flex align-items-center">
                              Sales Revenue
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Cost of Goods Sold
                              <span className="mg-l-auto">0</span>
                            </li>
                            <li className="d-flex align-items-center">
                              Gross Profit
                              <span className="mg-l-auto">0</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Sales Growth */}
          {tab.value == 3 && (
            <div className="rounded-bottom bd-t list-tab-body">
              <div className="d-flex flex-wrap align-items-center pd-10 bd-x">
                <span className="tx-color-03">Period</span>
                <div className="btn-group mg-x-10">
                  <button
                    type="button"
                    className={`btn ${period.fq ? 'btn-primary active' : 'btn-white'}`}
                    onClick={() => setPeriod({ fq: true })}
                  >
                    Fiscal Quarter
                  </button>
                  <button
                    type="button"
                    className={`btn ${period.month ? 'btn-primary active' : 'btn-white'}`}
                    onClick={() => setPeriod({ month: true })}
                  >
                    Month
                  </button>
                  <button
                    type="button"
                    className={`btn ${period.year ? 'btn-primary active' : 'btn-white'}`}
                    onClick={() => setPeriod({ year: true })}
                  >
                    Year
                  </button>
                  <button
                    type="button"
                    className={`btn ${period.fy ? 'btn-primary active' : 'btn-white'}`}
                    onClick={() => setPeriod({ fy: true })}
                  >
                    Fiscal Year
                  </button>
                </div>
                {period.fq && (
                  <SelectHook
                    className="wd-300-f"
                    options={[
                      { value: 1, label: 'Same Quarter of Previous Year' },
                      { value: 2, label: 'Previous Quarter' },
                    ]}
                    defaultValue={{ value: 2, label: 'Previous Quarter' }}
                  />
                )}
                {period.month && (
                  <SelectHook
                    className="wd-300-f"
                    options={[
                      { value: 1, label: 'Same Month of Previous Year' },
                      { value: 2, label: 'Previous Month' },
                    ]}
                    defaultValue={{ value: 2, label: 'Previous Month' }}
                  />
                )}
                {(period.year || period.fy) && (
                  <SelectHook
                    className="wd-300-f"
                    defaultValue={{ value: 2, label: 'Previous Year' }}
                  />
                )}
              </div>
              {period.fq && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable bg-white bd-0">
                    <thead>
                      <tr>
                        <th scope="col">Fiscal Year</th>
                        <th scope="col">Period</th>
                        <th scope="col">Revenue</th>
                        <th scope="col">Sales Growth</th>
                        <th scope="col">Average Organic Sales Growth</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>FY 2021</td>
                        <td>Quarter 1</td>
                        <td>&nbsp;</td>
                        <td>
                          Amount(%)
                          {/* <button
                            type="button"
                            className="btn btn-link pd-0"
                            onMouseOver={() => setIsOpenDetailPopover(true)}
                          >
                            Detail
                          </button> */}
                          {renderDetail()}
                        </td>
                        <td>
                          Amount(%)
                          <button type="button" className="btn btn-link pd-0">
                            Detail
                          </button>
                          {renderDetail2()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {period.month && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable bg-white bd-0">
                    <thead>
                      <tr>
                        <th scope="col">Year</th>
                        <th scope="col">Period</th>
                        <th scope="col">Revenue</th>
                        <th scope="col">Sales Growth</th>
                        <th scope="col">Average Sales Growth</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>FY 2021</td>
                        <td>January</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {period.year && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable bg-white bd-0">
                    <thead>
                      <tr>
                        <th scope="col">Year</th>
                        <th scope="col">Revenue</th>
                        <th scope="col">Sales Growth</th>
                        <th scope="col">Average Sales Growth</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2021</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {period.fy && (
                <div className="table-responsive">
                  <table className="table table-bordered dataTable bg-white bd-0">
                    <thead>
                      <tr>
                        <th scope="col">Fiscal Year</th>
                        <th scope="col">Revenue</th>
                        <th scope="col">Sales Growth</th>
                        <th scope="col">Average Sales Growth</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>FY 2021</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}

          {/* Customer Lifetime Value */}
          {tab.value == 4 && (
            <div className="rounded-bottom ht-100p list-tab-body card-group">
              <div
                className="card bg-transparent ht-100p"
                style={{ flex: 'unset', width: '33.33333333333%' }}
              >
                <div className="card-header tx-bold">Yearly/Fiscal Period</div>
                <div className="card-body overflow-auto">
                  <div className="tx-semibold">2021</div>
                  <div className="card mg-b-20 pd-15 bg-primary tx-white" role="button">
                    <ul className="list-unstyled mg-0">
                      <li className="d-flex align-items-center">
                        CLV (Revenue)
                        <span className="mg-l-auto">0</span>
                      </li>
                      <li className="d-flex align-items-center">
                        CLV (Profit)
                        <span className="mg-l-auto">0</span>
                      </li>
                      <li className="d-flex align-items-center">
                        No. of Customer
                        <span className="mg-l-auto">0</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card bg-transparent ht-100p">
                <div className="card-header">
                  <div className="row row-xs wd-100p">
                    <div className="col-md-6 tx-bold">6 Months</div>
                    <div className="col-md-6 tx-bold">Fiscal Quarter</div>
                  </div>
                </div>
                <div className="card-body overflow-auto">
                  <div className="row row-xs">
                    <div className="col-md-6">
                      <div className="pd-y-5 tx-semibold">First Half</div>
                      <div className="card mg-b-20 pd-15">
                        <ul className="list-unstyled mg-0">
                          <li className="d-flex align-items-center">
                            CLV (Revenue)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            CLV (Profit)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            No. of Customer
                            <span className="mg-l-auto">0</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pd-y-5 tx-semibold">Quarter 1</div>
                      <div className="card mg-b-20 pd-15">
                        <ul className="list-unstyled mg-0">
                          <li className="d-flex align-items-center">
                            CLV (Revenue)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            CLV (Profit)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            No. of Customer
                            <span className="mg-l-auto">0</span>
                          </li>
                        </ul>
                      </div>
                      <div className="pd-y-5 tx-semibold">Quarter 2</div>
                      <div className="card mg-b-20 pd-15">
                        <ul className="list-unstyled mg-0">
                          <li className="d-flex align-items-center">
                            CLV (Revenue)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            CLV (Profit)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            No. of Customer
                            <span className="mg-l-auto">0</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row row-xs">
                    <div className="col-md-6">
                      <div className="pd-y-5 tx-semibold">Second Half</div>
                      <div className="card mg-b-20 pd-15">
                        <ul className="list-unstyled mg-0">
                          <li className="d-flex align-items-center">
                            CLV (Revenue)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            CLV (Profit)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            No. of Customer
                            <span className="mg-l-auto">0</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pd-y-5 tx-semibold">Quarter 3</div>
                      <div className="card mg-b-20 pd-15">
                        <ul className="list-unstyled mg-0">
                          <li className="d-flex align-items-center">
                            CLV (Revenue)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            CLV (Profit)
                            <span className="mg-l-auto">0</span>
                          </li>
                          <li className="d-flex align-items-center">
                            No. of Customer
                            <span className="mg-l-auto">0</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <RevenueSettings />
    </>
  );
};

export default List;
