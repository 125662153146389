export const COUNT_TYPE = "count";
export const AMOUNT_TYPE = "amount";
export const RATIO_TYPE = "ratio";

export const metrictTypeOptions = [
  { value: COUNT_TYPE, label: "Count" },
  { value: AMOUNT_TYPE, label: "Amount" },
  { value: RATIO_TYPE, label: "Ratio" },
];
export const metrictTypeOptionsByValue = metrictTypeOptions?.reduce(
  (result, current) => {
    return { ...result, [current?.value]: current };
  },
  {}
);
export const countOptions = [
  { value: "create_lead", title: "Create Lead" },
  { value: "number_of_sales", title: "Number of Sales", className: "mg-b-10" },
  {
    value: "number_of_sales_per_product",
    title: "Number of Sales per Product",
    className: "mg-b-10",
  },
  {
    value: "create_opportunity",
    title: "Create Opportunity",
    className: "mg-b-10",
  },
  { value: "sent_quote", title: "Sent Quote", className: "mg-b-10" },
  {
    value: "number_of_referrals",
    title: "Number of Referrals",
    className: "mg-b-10",
  },
  {
    value: "repeat_purchase_volume",
    title: "Repeat Purchase Volume",
    tooltip: "Number of Sales coming from repeat purchase",
    className: "mg-b-10",
  },
  {
    value: "missed_sales_opportunities",
    title: "Missed Sales Opportunities",
    tooltip: "(Uncontacted Opportunities ÷ Total Opportunities) × 100",
    className: "mg-b-10",
  },
  {
    value: "number_of_knowledge_base_articles",
    title: "Number of Knowledge Base Articles",
    className: "mg-b-10",
  },
  {
    value: "ticket_closed_in_help_desk",
    title: "Ticket Closed in Help Desk",
    className: "mg-b-10",
  },
  { value: "activities", title: "Activities", className: "mg-b-10" },
];

export const countOptionsByValue = countOptions?.reduce((result, current) => {
  return { ...result, [current?.value]: current };
}, {});

export const amountOptions = [
  { title: "Sales Revenue", value: "sales_revenue" },
  {
    title: "Up / Cross Selling",
    value: "up_cross_selling",
    className: "mg-b-10",
  },
  {
    title: "Sales + Marketing Cost",
    value: "sales_marketing_cost",
    className: "mg-b-10",
  },
  { title: "Cost", value: "cost", className: "mg-b-10" },
  {
    title: "Repeat Purchase Customer",
    value: "repeat_purchase_customer",
    className: "mg-b-10",
  },
  {
    title: "Sales Cycle Length",
    value: "sales_cycle_length",
    className: "mg-b-10",
    tooltip: "Time between primary sales step to final sale",
  },
  {
    title: " Average Contract Length",
    value: "average_contract_length",
    className: "mg-b-10",
  },
  {
    title: "Customer Life Time",
    value: "customer_life_time",
    className: "mg-b-10",
  },
  { title: "Average Sale", value: "average_sale", className: "mg-b-10" },
];
export const amountOptionsByValue = amountOptions?.reduce((result, current) => {
  return { ...result, [current?.value]: current };
}, {});

export const ratioOptions = [
  {
    title: "Lead to Customer Conversion Ratio",
    value: "lead_to_customer_conversion_ratio",
    className: "mg-b-10",
    tooltip:
      "Number of Qualified Leads that Resulted in Sales ÷ Total Number of Qualified Leads",
  },
  {
    title: "Lead to Opportunity Conversion Ratio",
    value: "lead_to_opportunity_conversion_ratio",
    className: "mg-b-10",
    tooltip: "Number of Qualified Opportunities ÷ Number of Leads",
  },
  {
    title: "Opportunity to Win Rate",
    value: "opportunity_to_win_rate",
    className: "mg-b-10",
    tooltip: "Total Record ÷ Win Record",
  },
  {
    title: "Opportunity to Close Rate",
    value: "opportunity_to_close_rate",
    className: "mg-b-10",
    tooltip: "Closed Won ÷ (Closed Won + Lost)",
  },
  {
    title: "Customer Churn Rate",
    value: "customer_churn_rate",
    className: "mg-b-10",
    tooltip:
      "(Users at beginning of period - Users at the end of period) ÷ Users at beginning of period",
  },
  {
    title: "Customer Retention Rate",
    value: "customer_retention_rate",
    className: "mg-b-10",
    tooltip:
      "(Users at the end of period - Users of newly acquired user during the period) ÷ Users of existing at the start of the period",
  },
  {
    title: "Selling Costs to Sales Ratio",
    value: "selling_costs_to_sales_ratio",
    className: "mg-b-10",
    tooltip: "Cost ÷ Sales",
  },
  {
    title: "Fill out the Customer form",
    value: "fill_out_the_customer_form",
    className: "mg-b-10",
    tooltip: "",
  },
  {
    title: "Sales Velocity",
    value: "sales_velocity",
    className: "mg-b-10",
    tooltip:
      "(# of Active Opportunities × $ of Average Sale × Opportunity in decimals to Win Rate ) ÷ Sales Cycle Length (Time)",
  },
  {
    title: "Repeat Customer Rate",
    value: "repeat_customer_rate",
    className: "mg-b-10",
    tooltip:
      "(Sum total of Repeat Customers who Purchased 2 + times ) ÷ Sum total of Playing Customers who Purchased 1 + times",
  },
  {
    title: "Discount Rate",
    value: "discount_rate",
    className: "mg-b-10",
    tooltip: "(List Price - Selling Price ) ÷ List Price",
  },
];
export const ratioOptionsByValue = ratioOptions?.reduce((result, current) => {
  return { ...result, [current?.value]: current };
}, {});
export const metrictNameMapping = [
  ...countOptions,
  ...ratioOptions,
  ...amountOptions,
].reduce((result, current) => {
  return { ...result, [current.value]: current.title };
}, {});
