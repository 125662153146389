import { useState } from 'react';
import classnames from 'classnames';

import { ChevronDown, Plus, ArrowLeft } from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { COLORS } from '@base/config/constant';

import NewPost from './modal/newPost';
import PostDetails from './modal/postDetails';
import StreamDetails from './modal/streamDetails';
import SocialFeed from './view/social/feed';
import SocialPost from './view/social/post';
import SocialLinkMap from './view/social/linkMap';
import SocialVisitors from './view/social/visitors';
import SocialAnalytics from './view/social/analytics';

import '../marketing.scss';
import ReactApexChart from 'react-apexcharts';

// import "CrmCss/desktop/theme1/theme1.scss"

const TABS = [
  {
    value: 1,
    label: 'Feed',
  },
  {
    value: 2,
    label: 'Social Post',
  },
  {
    value: 3,
    label: 'Link Map',
  },
  {
    value: 4,
    label: 'Visitors',
  },
  {
    value: 5,
    label: 'Analytics',
  },
];

const SocialView = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/campaign/event/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Campaign
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">Social</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newPost"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="row no-gutters flex-nowrap flex-grow-1 overflow-auto">
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Insight</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#insightSection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="insightSection" className="view-aside-section-body collapse fade show">
                  <div className="card mg-b-15 pd-20">
                    <div className="d-flex mg-b-15">
                      <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                        Total visits
                      </div>
                      <div className="mg-l-auto tx-15 tx-semibold">400</div>
                    </div>
                    <ReactApexChart
                      type="pie"
                      height={150}
                      series={[100, 300]}
                      options={{
                        theme: {
                          mode: 'light',
                        },
                        labels: ['Twitter', 'Facebook'],
                        colors: ['rgba(29,161,242, 0.9)', 'rgba(72,103,170, 0.9)'],
                        dataLabels: {
                          enabled: true,
                          dropShadow: {
                            enabled: false,
                          },
                          formatter: (
                            val: string | number | number[],
                            opts?: any,
                          ): string | number => {
                            const name = opts.w.globals.labels[opts.seriesIndex];
                            const value = opts.w.config.series[opts.seriesIndex];
                            return [name, value] as unknown as string | number;
                          },
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -20,
                            endAngle: 340,
                            dataLabels: {
                              offset: -15,
                            },
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 1400,
                            options: {
                              legend: {
                                position: 'bottom',
                              },
                            },
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className="card pd-20">
                    <div className="d-flex mg-b-15">
                      <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">
                        Lead Capture
                      </div>
                      <div className="mg-l-auto tx-15 tx-semibold">10</div>
                    </div>
                    <ReactApexChart
                      type="pie"
                      height={150}
                      series={[3, 7]}
                      options={{
                        theme: {
                          mode: 'light',
                        },
                        labels: ['Twitter', 'Facebook'],
                        colors: ['rgba(29,161,242, 0.9)', 'rgba(72,103,170, 0.9)'],
                        dataLabels: {
                          enabled: true,
                          dropShadow: {
                            enabled: false,
                          },
                          formatter: (
                            val: string | number | number[],
                            opts?: any,
                          ): string | number => {
                            const name = opts.w.globals.labels[opts.seriesIndex];
                            const value = opts.w.config.series[opts.seriesIndex];
                            return [name, value] as unknown as string | number;
                          },
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -20,
                            endAngle: 340,
                            dataLabels: {
                              offset: -15,
                            },
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 1400,
                            options: {
                              legend: {
                                position: 'bottom',
                              },
                            },
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">KPI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="d-flex align-items-center justify-content-between mg-b-5">
                    <span className="tx-uppercase tx-13 tx-spacing-1 tx-color-02 mg-b-0">
                      Click through rate
                    </span>
                    <span className="tx-13 tx-color-04">83% goal reached</span>
                  </div>
                  <div className="d-flex justify-content-between mg-b-5">
                    <span className="h5 tx-semibold mg-b-0">50%</span>
                    <span className="tx-rubik tx-color-03 mg-b-0">60%</span>
                  </div>
                  <div className="progress ht-4 mg-b-0 op-5">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={60}
                      style={{ width: '83%' }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">ROI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="h3 mg-b-15 tx-center tx-primary">413%</div>
                  <div className="d-flex">
                    <div className="pd-x-15">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-13 tx-color-02 mg-b-2">
                        Recipients
                      </div>
                      <div>100</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-13 tx-color-02 mg-b-2">
                        Sales
                      </div>
                      <div>20</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-13 tx-color-02 mg-b-2">
                        Gross Profit
                      </div>
                      <div>＄500.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* Feed */}
              {tab.value == 1 && <SocialFeed />}

              {/* Social Post */}
              {tab.value == 2 && <SocialPost />}

              {/* Link Map */}
              {tab.value == 3 && <SocialLinkMap />}

              {tab.value == 4 && <SocialVisitors />}

              {tab.value == 5 && <SocialAnalytics />}
            </div>
          </div>
        </div>
      </div>

      <NewPost />
      <PostDetails />
      <StreamDetails />
    </>
  );
};

export default SocialView;
