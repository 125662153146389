import { RadioGroup } from '@base/components/form';
import { useState } from 'react';
import CheckOption from '../check-option';

interface QueueProps {
  className?: string; // Classname set size for image, if it have value, width and height prop will not working.
}

const Queue: React.FC<QueueProps> = (props: QueueProps) => {
  const {
    className, // Classname set size for image, if it have value, width and height prop will not working.
  } = props;

  const OPTIONS = [
    { value: 1, label: 'Queue' },
    { value: 2, label: 'Assign to a user with' },
  ];
  const [options, setOptions] = useState(OPTIONS);
  const [selectedOption, setSelectedOption] = useState(OPTIONS[0]);
  //change
  const handleOptionChange = (newOption: any) => {
    setSelectedOption(newOption);
  };

  const USER_OPTIONS = [
    { value: 1, label: 'Round Robin' },
    { value: 2, label: 'Load Balance by number' },
    { value: 3, label: 'Load Balance by capacity' },
  ];
  const [userOptions, setUserOptions] = useState(USER_OPTIONS);
  const [selectedUserOptions, setSelectedUserOptions] = useState(USER_OPTIONS[0]);
  //change
  const handleUserOptionsChange = (newOption: any) => {
    setSelectedUserOptions(newOption);
  };

  const CHECK_OPTIONS = [
    { value: 1, label: 'Yes' },
    { value: 2, label: 'No' },
  ];
  const [checkOptions, setCheckOptions] = useState(CHECK_OPTIONS);
  const [selectedCheckOptions, setSelectedCheckOptions] = useState(CHECK_OPTIONS[0]);
  //change
  const handleCheckOptionsChange = (newOption: any) => {
    setSelectedCheckOptions(newOption);
  };

  return (
    <>
      <div className={className}>
        <RadioGroup
          value={selectedOption.value}
          options={options}
          onChange={handleOptionChange}
          isVertical
        />
        {selectedOption.value == 2 && (
          <div className="mg-l-30">
            <RadioGroup
              value={selectedUserOptions.value}
              options={userOptions}
              onChange={handleUserOptionsChange}
              isVertical
            />
            <CheckOption />
          </div>
        )}
      </div>
    </>
  );
};
export default Queue;
