import classnames from 'classnames';
import { Button } from '@base/components/form';

interface propTypes {
  open: boolean;
  idModal: string;
  ariaLabelledby: string;
  nameButton: string;
  nameModal: string;
}

const ModalSign = (props: propTypes) => {
  const { open, idModal, ariaLabelledby, nameButton, nameModal } = props;
  return (
    <div
      key={idModal}
      className={classnames('modal fade', { show: open })}
      id={idModal}
      tabIndex={-1}
      role="dialog"
      aria-labelledby={ariaLabelledby}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={ariaLabelledby}>
              {nameModal}
            </h5>
            <Button close data-dismiss="modal"></Button>
          </div>
          <div className="modal-body">
            By signing this Quote, I agree to the following terms:
            <br />
            For an amount of : ₩ 9,548,500
            <div className="mg-t-15 text-right">
              <Button color="" className="btn-link">
                Clear
              </Button>
            </div>
            <div className="ht-150 bg-light bd rounded-10"></div>
          </div>
          <div className="modal-footer">
            <Button data-dismiss="modal">Close</Button>
            <Button icon="Check" color="success">
              <span className="mg-l-5">Accept &amp; Sign</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const defaultProps = {
  open: false,
  idModal: 'modalSign',
  ariaLabelledby: 'ModalAria',
  nameButton: 'Accept',
  nameModal: 'Validate Order',
};

ModalSign.defaultProps = defaultProps;

export default ModalSign;
