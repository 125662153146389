import { Button } from '@base/components/form';
import { Mail, Phone } from 'react-feather';
import Comment from '../component/desktop/comment';
import ModalSign from './modal/ModalSign';
import './site.scss';
import React, { useState } from 'react';
const idModal = 'modalSign';

const QuoteSite = () => {
  return (
    <div className="content pd-y-30-f overflow-auto">
      <div className="container quote-site-container">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="form-group text-center">
              <label className="form-item-title">Total Amount</label>
              <div className="tx-24 tx-spacing--1">₩ 9,548,500</div>
            </div>
            <Button
              icon="Check"
              color="primary"
              block
              data-toggle="modal"
              data-target={`#${idModal}`}
            >
              <span className="mg-l-5">Sign &amp; Pay</span>
            </Button>
            <ModalSign nameButton="Add" idModal={idModal} ariaLabelledby="idaria"></ModalSign>
            <div className="mg-y-10 text-center">
              <Button
                tag="a"
                href="#comment"
                icon="MessageSquare"
                color=""
                className="pd-y-0 tx-purple"
              >
                <span className="mg-l-5 tx-purple">Feedback</span>
              </Button>
              <Button icon="X" color="" className="pd-y-0 tx-danger bd-l">
                <span className="mg-l-5 tx-danger">Reject</span>
              </Button>
            </div>
            <div className="d-flex mg-t-10">
              <Button icon="Download" color="info" className="flex-fill">
                <span className="mg-l-5">Download</span>
              </Button>
              <Button icon="Printer" color="info" className="flex-fill mg-l-10">
                <span className="mg-l-5">Print</span>
              </Button>
              <Button icon="Printer" color="info" className="flex-fill mg-l-10">
                <span className="mg-l-5">Print</span>
              </Button>
            </div>
            <div className="mg-t-40">
              <label className="tx-sans tx-12 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 mg-b-15">
                Sales Person
              </label>
              <div className="d-flex">
                <span className="avatar flex-shrink-0">
                  <img
                    src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                    className="rounded-circle"
                  />
                </span>
                <div className="mg-l-10" style={{ maxWidth: 'calc(100% - 48px)' }}>
                  MSR
                  <ul className="list-unstyled mg-b-0">
                    <li className="mg-b-5 text-truncate">
                      <Phone size={16} className="tx-primary mg-r-10" />
                      <a href="tel:1 518-445-569">+1 518-445-5696</a>
                    </li>
                    <li className="mg-b-5 text-truncate">
                      <Mail size={16} className="tx-teal mg-r-10" />
                      <a href="mailto:sales@hanbiro.com">sales@hanbiro.com</a>
                    </li>
                  </ul>
                  <Button icon="MessageCircle" iconSize="16" color="" className="pd-0 tx-indigo">
                    <span className="mg-l-10 tx-indigo">Chat &amp; Voice Talk</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-9">
            <div className="card">
              <div className="card-header tx-24">Quote-1630977508</div>
              <div className="card-body">
                <div className="table-responsive bg-white bd rounded-top">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="colgroup" colSpan={9} className="bd-t-0-f bd-b-d-f">
                          Onetime Payment
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Image
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Item
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Unit
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Attribute
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Unit Price
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Qty Ordered
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Volume Discount
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="avatar avatar-sm bg-gray-200 rounded">
                            <img src="assets/images/notfound.png" alt="" />
                          </div>
                        </td>
                        <td>
                          <a href="" target="_blank" title="새창으로 열림">
                            AAA
                          </a>
                        </td>
                        <td>Box</td>
                        <td>&nbsp;</td>
                        <td>￦100,000</td>
                        <td>100</td>
                        <td>0</td>
                        <td>￦10,000,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="row row-xs mg-y-10">
                  <div className="col-md-6">
                    <ul className="list-unstyled lh-7 mg-b-0">
                      <li className="d-flex flex-wrap justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                        <span>Items : 100</span>
                        <span> ₩ 10,000,000</span>
                      </li>
                      <li className="d-flex flex-wrap justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                        <span>Total Discount</span>
                        <div className="mg-l-5 tx-danger">- ₩ 500,000</div>
                      </li>
                    </ul>
                    <div className="rounded bd bg-gray-100">
                      <div className="d-flex flex-wrap pd-x-15 pd-y-10">Loyalty</div>
                      <div className="mg-10 bd bd-dashed rounded">
                        <ul className="list-unstyled mg-b-0 lh-7 mg-b-0">
                          <li className="d-flex flex-wrap justify-content-between pd-10">
                            <label className="d-block mg-b-0 tx-color-03 tx-13">Point(s)</label>
                            <div className="tx-danger">- ₩ 10,000</div>
                          </li>
                          <li className="d-flex flex-wrap justify-content-between pd-10 bd bd-dashed bd-x-0 bd-b-0">
                            <label className="d-block mg-b-0 tx-color-03 tx-13">Coupon(s)</label>
                            <div className="tx-danger">- ₩ 100,000</div>
                          </li>
                          <li className="d-flex flex-wrap justify-content-between pd-10 bd bd-dashed bd-x-0 bd-b-0">
                            <div>
                              <label className="d-block mg-b-0 tx-color-03 tx-13">
                                Stamp(s) Reward
                              </label>
                            </div>
                            <div className="text-right">
                              <div className="text-right tx-danger">- ₩300,000</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
                      <li className="d-flex flex-wrap justify-content-between">
                        <span>Sub-Total</span>
                        <span>₩ 9,090,000</span>
                      </li>
                      <li className="d-flex flex-wrap justify-content-between">
                        <span>Shipping Charges</span>
                        <span>₩ 4,000</span>
                      </li>
                      <li className="d-flex flex-wrap justify-content-between">
                        <span>Tax (5%)</span>
                        <span>₩ 454,500</span>
                      </li>
                      <li className="d-flex flex-wrap justify-content-between">
                        <span>Round Off</span>
                        <span>₩ 0</span>
                      </li>
                      <li className="d-flex flex-wrap justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                        <strong>Total Amount</strong>
                        <strong>₩ 9,548,500</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mg-t-30 pd-20 bd-b tx-20" id="comment">
              History
            </div>
            <Comment />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteSite;
