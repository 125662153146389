import { ThumbsUp } from 'react-feather';

const PurchaseInfo = () => {
  return (
    <div className="pd-15">
      <div className="card mg-b-10">
        <div className="row mg-0">
          <div className="col-4 bd-r">
            <div className="form-group mg-b-0 pd-y-10">
              <label className="form-item-title">Reorder Point</label>
              <div>100</div>
            </div>
          </div>
          <div className="col-4 bd-r">
            <div className="form-group mg-b-0 pd-y-10">
              <label className="form-item-title">Lot Size</label>
              <div>1 Pallet (1 pallet =1,000 bottles)</div>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group mg-b-0 pd-y-10">
              <label className="form-item-title">Delivery Lead Time</label>
              <div>3 days</div>
            </div>
          </div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <span className="view-aside-section-header-title">Vendor Price List</span>
        </div>
        <div className="table-responsive bg-white bd rounded">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f sorting">
                  Vendor
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Purchase Price
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Lead Time
                </th>
                <th scope="col" className="bd-t-0-f sorting">
                  Purchase Agreement
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span
                    data-han-tooltip="Preferred"
                    className="preferred-vendor han-tooltip--right mg-r-5"
                  >
                    <ThumbsUp size="16" />
                    <span className="sr-only">선호 업체</span>
                  </span>
                  Vendor 1
                </td>
                <td>￦1,500</td>
                <td>5 days</td>
                <td>Agreement 1</td>
              </tr>
              <tr>
                <td>Vendor 4</td>
                <td>￦1,300</td>
                <td>14 days</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PurchaseInfo;
