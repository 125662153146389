import { ChevronUp, Edit2, Image, Minus, Plus, Settings, UploadCloud, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import { Select as SelectHook } from '@base/components/form';
// import { DateRangePicker } from "react-date-range";

const newVendorProduct = () => {
  return (
    <div id="newVendorProduct" className="modal right fade">
      <div className="modal-dialog">
        <div className="modal-content rounded-0 overflow-hidden">
          <div className="modal-header">
            <h1 className="h6 modal-title">Creat Vendor Product</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Product</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select a product"
                />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    {Icon('product')}
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Preferred</label>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="PreferredfCheck" />
                <label className="custom-control-label" htmlFor="PreferredfCheck"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Purchase Unit</label>
              <SelectHook className="wd-300-f" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Purchase Price</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text lh-1">￦</span>
                </div>
                <input type="number" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Minimum Qty </label>
              <input type="number" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Delivery Lead Time </label>
              <div className="d-flex align-items-center">
                <input type="number" className="form-control mg-r-10" />
                <SelectHook options={[{ value: 1, label: 'day(s)' }]} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Vendor Price List</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="vendorPriceListSwitch"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="vendorPriceListSwitch">
                  Active
                </label>
              </div>
              <div className="table-responsive bd rounded mg-t-10">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Quantity
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Discount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <input type="number" className="form-control wd-100" />
                          <span className="mg-x-5">~</span>
                          <input type="number" className="form-control wd-100" />
                          <button type="button" className="btn btn-link">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-100">
                          <input type="number" className="form-control" />
                          <div className="input-group-append">
                            <span className="input-group-text lh-1">%</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <input type="number" className="form-control wd-100" />
                          <span className="mg-x-5">~</span>
                          <input type="number" className="form-control wd-100" />
                          <button type="button" className="btn btn-link">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn tx-danger">
                            <Minus />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-100">
                          <input type="number" className="form-control" />
                          <div className="input-group-append">
                            <span className="input-group-text lh-1">%</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Validity</label>
              {/* <DateRangePicker/> */}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default newVendorProduct;
