import React, { useState } from 'react';
import classnames from 'classnames';

import { ArrowLeft, ArrowRight, ChevronUp, Plus, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'General Info',
  },
  {
    value: 2,
    label: 'Basic Info',
  },
];

const NewUnit = () => {
  const [step, setStep] = useState(TABS[0]);

  const [input, setInput] = useState<any>('');
  const [addNew, setAddNew] = useState<any>('');

  return (
    <div id="newUnit" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content rounded-0">
          <div className="modal-header flex-column pd-0">
            <div className="d-flex wd-100p pd-15">
              <h1 className="modal-title">Create Unit</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <ul role="tablist" className="steps steps-tab steps-justified wd-100p bd-t">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  role="tab"
                  className={classnames('step-item', {
                    active: item.value == step.value,
                    complete: item.value < step.value,
                  })}
                >
                  <a
                    href=""
                    className="step-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setStep(item);
                    }}
                  >
                    {item.value == step.value && <span className="sr-only">현재 단계</span>}
                    <span className="step-number">{item.value}</span>
                    <span className="step-title">{item.label}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-body pd-0" style={{ overflowX: 'hidden' }}>
            {/* step 1 */}
            {step.value == 1 && (
              <>
                <div className="mg-15 mg-sm-20 text-right">
                  <span className="tx-danger">*</span> is required field.
                </div>
                <hr className="mg-x-15 mg-sm-x-20" />
                <div className="form-row mg-15 mg-sm-20">
                  <div className="form-col col-lg-6 form-group">
                    <label className="form-item-title">
                      Base Unit<span className="tx-danger">*</span>
                    </label>
                    <input type="text" className="form-control wd-500 mx-wd-100p" />
                    <div className="mg-t-5 tx-orange">
                      When the base unit is created, it will be automatically set as the unit name,
                      quantity 1
                    </div>
                  </div>
                  <div className="form-col col-lg-6 form-group">
                    <label className="form-item-title">Active</label>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="unitStatusSwitch1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="unitStatusSwitch1"></label>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* step 2 */}
            {step.value == 2 && (
              <>
                <div className="table-responsive">
                  <table className="table dataTable table-bordered mg-b-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Base Unit
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Unit Name
                        </th>
                        <th scope="col" className="text-nowrap bd-t-0-f">
                          Unit Quantity
                        </th>
                        <th scope="col" className="wd-70 bd-t-0-f">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan={2}>Set</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-xs mg-r-5 pd-0 tx-color-03"
                            aria-label="Drag-and-drop to reorder"
                            style={{ cursor: 'grab' }}
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          Set
                        </td>
                        <td>1</td>
                        <td>&nbsp;</td>
                      </tr>
                      {addNew && (
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <button
                                type="button"
                                className="btn btn-xs mg-r-5 pd-0 tx-color-03"
                                aria-label="Drag-and-drop to reorder"
                                style={{ cursor: 'grab' }}
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <input type="text" className="form-control" defaultValue={input} />
                            </div>
                          </td>
                          <td>
                            <input type="number" className="form-control" />
                          </td>
                          <td align="center">
                            <button
                              type="button"
                              className="btn btn-icon pd-0"
                              onClick={() => setAddNew(false)}
                            >
                              <X className="tx-danger" />
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center pd-10">
                  <input
                    type="text"
                    className="form-control mg-r-5"
                    placeholder="Unit Name"
                    value={input}
                    onInput={(e: any) => setInput(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary flex-shrink-0"
                    onClick={() => setAddNew(true)}
                  >
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            {step.value == 2 && (
              <button type="button" className="btn btn-white">
                <ArrowLeft className="mg-r-5" />
                Previous
              </button>
            )}
            <button type="button" className="btn btn-light mg-l-auto" data-dismiss="modal">
              Close
            </button>
            {step.value == 1 && (
              <button type="button" className="btn btn-primary">
                Next
                <ArrowRight className="mg-l-5" />
              </button>
            )}
            {step.value == 2 && (
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUnit;
