import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  ArrowUp,
  ArrowDown,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import { COLORS } from '@base/config/constant';

// import "CrmCss/desktop/theme1/theme1.scss"

import { Select, Select as SelectHook } from '@base/components/form';

import ConfirmFactors from './modal/confirmFactors';
import NewCampaign from './modal/newCampaign';
import EventQuestion from './modal/newEventQuestion';
import ROI from './view/roi';
import Related from './view/related';
import ReactApexChart from 'react-apexcharts';

const TABS = [
  {
    value: 1,
    label: 'Overview',
  },
  {
    value: 2,
    label: 'Link Map',
  },
  {
    value: 3,
    label: 'Visits',
  },
  {
    value: 4,
    label: 'CTA Clicks',
  },
  {
    value: 5,
    label: 'ROI',
  },
  {
    value: 6,
    label: 'Related',
  },
  {
    value: 7,
    label: 'Configuration',
  },
  {
    value: 8,
    label: 'Analytics',
  },
];

const MediaView = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a href="/campaign/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Campaign
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">Campaign 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newCampaignModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="row no-gutters flex-nowrap flex-grow-1 overflow-auto">
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Insight</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#insightSection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="insightSection" className="view-aside-section-body collapse fade show">
                  <div className="card mg-b-15 pd-15">
                    <div className="d-flex mg-b-15">
                      <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                        Total visits
                      </div>
                      <div className="mg-l-auto tx-15 tx-semibold">400</div>
                    </div>
                    <ReactApexChart
                      type="pie"
                      height={150}
                      series={[100, 300]}
                      options={{
                        theme: {
                          mode: 'light',
                        },
                        labels: ['Google', 'Naver'],
                        colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                        dataLabels: {
                          enabled: true,
                          dropShadow: {
                            enabled: false,
                          },
                          formatter: (
                            val: string | number | number[],
                            opts?: any,
                          ): string | number => {
                            const name = opts.w.globals.labels[opts.seriesIndex];
                            const value = opts.w.config.series[opts.seriesIndex];
                            return [name, value] as unknown as string | number;
                          },
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -20,
                            endAngle: 340,
                            dataLabels: {
                              offset: -15,
                            },
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 1400,
                            options: {
                              legend: {
                                position: 'bottom',
                              },
                            },
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className="card pd-15">
                    <div className="d-flex mg-b-15">
                      <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">
                        Lead Capture
                      </div>
                      <div className="mg-l-auto tx-15 tx-semibold">10</div>
                    </div>
                    <ReactApexChart
                      type="pie"
                      height={150}
                      series={[3, 7]}
                      options={{
                        theme: {
                          mode: 'light',
                        },
                        labels: ['Google', 'Naver'],
                        colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                        dataLabels: {
                          enabled: true,
                          dropShadow: {
                            enabled: false,
                          },
                          formatter: (
                            val: string | number | number[],
                            opts?: any,
                          ): string | number => {
                            const name = opts.w.globals.labels[opts.seriesIndex];
                            const value = opts.w.config.series[opts.seriesIndex];
                            return [name, value] as unknown as string | number;
                          },
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -20,
                            endAngle: 340,
                            dataLabels: {
                              offset: -15,
                            },
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 1400,
                            options: {
                              legend: {
                                position: 'bottom',
                              },
                            },
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">KPI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="d-flex align-items-center justify-content-between mg-b-5">
                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 mg-b-0">
                      Click through rate
                    </span>
                    <span className="tx-12 tx-color-04">83% goal reached</span>
                  </div>
                  <div className="d-flex justify-content-between mg-b-5">
                    <span className="h5 tx-semibold mg-b-0">50%</span>
                    <span className="tx-rubik tx-color-03 mg-b-0">60%</span>
                  </div>
                  <div className="progress ht-4 mg-b-0 op-5">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={60}
                      style={{ width: '83%' }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">ROI</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="h3 mg-b-15 tx-center tx-primary">413%</div>
                  <div className="d-flex">
                    <div className="pd-x-15">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Recipients
                      </div>
                      <div>100</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Sales
                      </div>
                      <div>20</div>
                    </div>
                    <div className="pd-x-15 bd-l">
                      <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                        Gross Profit
                      </div>
                      <div>＄500.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* Overview */}
              {tab.value == 1 && (
                <div className="pd-20">
                  <div className="view-content-section mg-t-20">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Analytics</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#analyticsSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="analyticsSection" className="card card-body collapse show">
                      <div className="d-flex align-items-center justify-content-end mg-b-10">
                        Date Range{' '}
                        <SelectHook
                          className="wd-200-f mg-l-10"
                          defaultValue={[{ value: 1, label: 'Last 7days' }]}
                        />
                      </div>
                      <div className="row no-gutters">
                        <div className="col-sm-6 col-xl-3">
                          <ReactApexChart
                            type="donut"
                            height={200}
                            series={[7, 5, 8]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['CRM', 'Server', 'Groupware'],
                              plotOptions: {
                                pie: {
                                  donut: {
                                    size: '88%',
                                    labels: {
                                      show: true,
                                      value: {
                                        fontSize: '1.25rem',
                                        fontWeight: 600,
                                        offsetY: -1,
                                      },
                                      total: {
                                        show: true,
                                        fontSize: '0.875rem',
                                        label: 'Total',
                                      },
                                    },
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              legend: {
                                position: 'top',
                              },
                            }}
                          />
                          <div className="text-center">Paid Keywords</div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                          <ReactApexChart
                            type="donut"
                            height={200}
                            series={[7, 5, 8]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Desktop', 'Mobile', 'Tablet'],
                              plotOptions: {
                                pie: {
                                  donut: {
                                    size: '88%',
                                    labels: {
                                      show: true,
                                      value: {
                                        fontSize: '1.25rem',
                                        fontWeight: 600,
                                        offsetY: -1,
                                      },
                                      total: {
                                        show: true,
                                        fontSize: '0.875rem',
                                        label: 'Total',
                                      },
                                    },
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              legend: {
                                position: 'top',
                              },
                            }}
                          />
                          <div className="text-center">Device</div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                          <ReactApexChart
                            type="pie"
                            height={200}
                            series={[500, 300]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['A', 'B'],
                              dataLabels: {
                                enabled: true,
                                dropShadow: {
                                  enabled: false,
                                },
                                formatter: (
                                  val: string | number | number[],
                                  opts?: any,
                                ): string | number => {
                                  const name = opts.w.globals.labels[opts.seriesIndex];
                                  const value = opts.w.config.series[opts.seriesIndex];
                                  return [name, '$' + value] as unknown as string | number;
                                },
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -20,
                                  endAngle: 340,
                                  dataLabels: {
                                    offset: -15,
                                  },
                                },
                              },
                              legend: {
                                position: 'top',
                              },
                            }}
                          />
                          <div className="text-center">Spend</div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                          <ReactApexChart
                            type="pie"
                            height={200}
                            series={[40, 60]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              colors: ['#008FFB', '#FF4560'],
                              labels: ['Conversion', 'Non-Conversion'],
                              dataLabels: {
                                enabled: true,
                                dropShadow: {
                                  enabled: false,
                                },
                                formatter: (
                                  val: string | number | number[],
                                  opts?: any,
                                ): string | number => {
                                  const name = opts.w.globals.labels[opts.seriesIndex];
                                  const value = opts.w.config.series[opts.seriesIndex];
                                  return [name, value + '%'] as unknown as string | number;
                                },
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -20,
                                  endAngle: 340,
                                  dataLabels: {
                                    offset: -15,
                                  },
                                },
                              },
                              legend: {
                                position: 'top',
                              },
                            }}
                          />
                          <div className="text-center">Conversion Rate</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="view-content-section mg-t-20">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">
                        View &amp; Conversions
                      </span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#conversionSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="conversionSection" className="card card-body collapse show">
                      <div className="d-flex align-items-center justify-content-end mg-b-10">
                        Date Range{' '}
                        <SelectHook
                          className="wd-200-f mg-x-10"
                          defaultValue={[{ value: 1, label: 'This month' }]}
                        />
                        Frequency{' '}
                        <SelectHook
                          className="wd-200-f mg-l-10"
                          defaultValue={[{ value: 1, label: 'Daily' }]}
                        />
                      </div>
                      <div className="ht-300">
                        <ReactApexChart
                          type="line"
                          height={300}
                          series={[
                            {
                              name: 'Google',
                              data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                            },
                            {
                              name: 'Naver',
                              data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                            },
                          ]}
                          options={{
                            chart: {
                              zoom: {
                                enabled: false,
                              },
                            },
                            colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                            stroke: {
                              curve: 'straight',
                            },
                            xaxis: {
                              categories: [
                                '30m',
                                '1h',
                                '1h 30m',
                                '2h',
                                '2h 30m',
                                '3h',
                                '3h 30m',
                                '3h',
                                '3h 30m',
                              ],
                            },
                          }}
                        />
                      </div>
                      <div className="table-responsive bd rounded mg-t-20">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Source
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Views
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Lead Capture
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Opportunity
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Sales
                              </th>
                            </tr>
                          </thead>
                          <tfoot>
                            <tr>
                              <td>All</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tfoot>
                          <tbody>
                            <tr>
                              <td>Google</td>
                              <td>300</td>
                              <td>&nbsp;</td>
                              <td>100</td>
                              <td>＄500</td>
                            </tr>
                            <tr>
                              <td>Naver</td>
                              <td>200</td>
                              <td>50</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Link Map */}
              {tab.value == 2 && (
                <div className="pd-20">
                  <div className="table-responsive bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Media
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Visits
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Lead Capture
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            MQL
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Opportunity
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Sales
                          </th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td>Dropped</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>Google</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Naver</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* Visits */}
              {tab.value == 3 && (
                <div className="pd-20">
                  <div className="card">
                    <div className="table-responsive rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col" className="bd-t-0-f">
                              IP
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Channel
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Referrer
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              First Page
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Keyword
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Created on
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Lead Capture
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Revenue
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer pd-y-6-f pagination">
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to first"
                      >
                        {Icon('move_first')}
                        <span className="sr-only">처음으로</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="previous"
                      >
                        <ChevronLeft />
                        <span className="sr-only">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="next"
                      >
                        <ChevronRight />
                        <span className="sr-only">다음</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        aria-label="move to last"
                      >
                        {Icon('move_last')}
                        <span className="sr-only">마지막으로</span>
                      </button>
                      <input type="number" className="paging-input" defaultValue="1" />/ 1
                    </div>
                  </div>
                </div>
              )}

              {/* CTA Clicks */}
              {tab.value == 4 && (
                <div className="pd-20">
                  <div className="d-flex align-items-center justify-content-end mg-b-10">
                    Date Range{' '}
                    <SelectHook
                      className="wd-200-f mg-x-10"
                      options={[{ value: 1, label: 'This month' }]}
                    />
                    Frequency{' '}
                    <SelectHook
                      className="wd-200-f mg-l-10"
                      options={[{ value: 1, label: 'Daily' }]}
                    />
                  </div>
                  <div className="table-responsive bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Channels
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Visits
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            CTA 1 Clicks
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            CTA 2 Clicks
                          </th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td>Dropped</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>Google</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Naver</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* ROI */}
              {tab.value == 5 && (
                <div className="pd-20">
                  <ROI />
                </div>
              )}

              {/* Related */}
              {tab.value == 6 && (
                <div className="pd-20">
                  <Related />
                </div>
              )}

              {/* Analytics */}
              {tab.value == 8 && (
                <div className="pd-20">
                  {/* Paid Keyword */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Paid Keyword</span>
                      <button
                        type="button"
                        className="btn btn-link link-03"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#paidKeywordSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="paidKeywordSection" className="card collapse show">
                      <div className="row no-gutters">
                        <div className="col-xl-8 bd-b bd-xl-b-0 bd-xl-r">
                          <div className="table-responsive">
                            <table className="table table-dashboard table-bordered mg-b-0 bd-0">
                              <thead>
                                <tr>
                                  <th scope="col" className="bd-t-0-f">
                                    Channels
                                    <div className="drdopdown float-right">
                                      <button
                                        type="button"
                                        className="btn btn-icon pd-0 lh-1"
                                        data-toggle="dropdown"
                                      >
                                        {Icon('filter')}
                                      </button>
                                      <div className="dropdown-menu tx-normal">
                                        <div className="pd-x-15">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="channelCheckAll"
                                              defaultChecked
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="channelCheckAll"
                                            >
                                              All
                                            </label>
                                          </div>
                                          <div className="dropdown-divider"></div>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="channelCheck1"
                                              defaultChecked
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="channelCheck1"
                                            >
                                              Google
                                            </label>
                                          </div>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="channelCheck2"
                                              defaultChecked
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="channelCheck2"
                                            >
                                              Naver
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Paid Keywords
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Clicks
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th colSpan={2} className="bd-b-0-f">
                                    Total
                                  </th>
                                  <td>&nbsp;</td>
                                </tr>
                              </tfoot>
                              <tbody>
                                <tr>
                                  <td rowSpan={3}>Google</td>
                                  <td>CRM</td>
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>Server</td>
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>Groupware</td>
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td rowSpan={3}>Naver</td>
                                  <td>CRM</td>
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>Server</td>
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>Groupware</td>
                                  <td>&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-center justify-content-center pd-y-15">
                          <ReactApexChart
                            type="donut"
                            series={[7, 5, 8]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['CRM', 'Server', 'Groupware'],
                              plotOptions: {
                                pie: {
                                  donut: {
                                    size: '88%',
                                    labels: {
                                      show: true,
                                      value: {
                                        fontSize: '1.25rem',
                                        fontWeight: 600,
                                        offsetY: -1,
                                      },
                                      total: {
                                        show: true,
                                        fontSize: '0.875rem',
                                        label: 'Total',
                                      },
                                    },
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              responsive: [
                                {
                                  breakpoint: 1400,
                                  options: {
                                    legend: {
                                      position: 'top',
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Device */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Device</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#deviceSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="deviceSection" className="card collapse show">
                      <div className="row no-gutters">
                        <div className="col-xl-8 bd-b bd-xl-b-0 bd-xl-r">
                          <div className="table-responsive">
                            <table className="table table-dashboard table-bordered mg-b-0 bd-0">
                              <thead>
                                <tr>
                                  <th scope="col" className="bd-t-0-f">
                                    Devices
                                    <div className="drdopdown float-right">
                                      <button
                                        type="button"
                                        className="btn btn-icon pd-y-0 lh-1"
                                        data-toggle="dropdown"
                                      >
                                        {Icon('filter')}
                                      </button>
                                      <div className="dropdown-menu tx-normal">
                                        <div className="pd-x-15">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="deviceCheckAll"
                                              defaultChecked
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="deviceCheckAll"
                                            >
                                              All
                                            </label>
                                          </div>
                                          <div className="dropdown-divider"></div>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="deviceCheck1"
                                              defaultChecked
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="deviceCheck1"
                                            >
                                              Desktop
                                            </label>
                                          </div>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="deviceCheck2"
                                              defaultChecked
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="deviceCheck2"
                                            >
                                              Mobile
                                            </label>
                                          </div>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="deviceCheck3"
                                              defaultChecked
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="deviceCheck3"
                                            >
                                              Tablet
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th scope="col" className="bd-t-0-f">
                                    Users
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th className="bd-b-0-f">Total</th>
                                  <td>&nbsp;</td>
                                </tr>
                              </tfoot>
                              <tbody>
                                <tr>
                                  <td>Desktop</td>
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>Mobile</td>
                                  <td>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>Tablet</td>
                                  <td>&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-center justify-content-center pd-y-15">
                          <ReactApexChart
                            type="donut"
                            series={[7, 5, 8]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Desktop', 'Mobile', 'Tablet'],
                              plotOptions: {
                                pie: {
                                  donut: {
                                    size: '88%',
                                    labels: {
                                      show: true,
                                      value: {
                                        fontSize: '1.25rem',
                                        fontWeight: 600,
                                        offsetY: -1,
                                      },
                                      total: {
                                        show: true,
                                        fontSize: '0.875rem',
                                        label: 'Total',
                                      },
                                    },
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              responsive: [
                                {
                                  breakpoint: 1400,
                                  options: {
                                    legend: {
                                      position: 'top',
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* New Visitors */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">New Visitors</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#visitorsSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="visitorsSection" className="card card-body collapse show">
                      <ReactApexChart
                        type="line"
                        height={300}
                        series={[
                          {
                            name: 'All',
                            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                          },
                          {
                            name: 'New',
                            data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                          },
                        ]}
                        options={{
                          chart: {
                            zoom: {
                              enabled: false,
                            },
                          },
                          stroke: {
                            curve: 'straight',
                          },
                          xaxis: {
                            categories: [
                              '30m',
                              '1h',
                              '1h 30m',
                              '2h',
                              '2h 30m',
                              '3h',
                              '3h 30m',
                              '3h',
                              '3h 30m',
                            ],
                          },
                        }}
                      />
                    </div>
                  </div>

                  {/* Lead Capture */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Lead Capture</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#leadCaptureSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="leadCaptureSection" className="card card-body collapse show">
                      <div className="row">
                        <div className="col-xl-8">
                          <ReactApexChart
                            type="line"
                            height={300}
                            series={[
                              {
                                name: 'Google',
                                data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                              },
                              {
                                name: 'Naver',
                                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                              },
                            ]}
                            options={{
                              chart: {
                                zoom: {
                                  enabled: false,
                                },
                              },
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              stroke: {
                                curve: 'straight',
                              },
                              xaxis: {
                                categories: [
                                  '30m',
                                  '1h',
                                  '1h 30m',
                                  '2h',
                                  '2h 30m',
                                  '3h',
                                  '3h 30m',
                                  '3h',
                                  '3h 30m',
                                ],
                              },
                            }}
                          />
                          <div className="mg-t-10 pd-x-20">
                            <span className="h4">200</span>
                            <span className="mg-x-10 tx-success">
                              120%
                              <ArrowUp size={16} className="mg-l-5" />
                            </span>
                            <span className="tx-color-03">than last month(88)</span>
                          </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-center justify-content-center">
                          <ReactApexChart
                            type="pie"
                            height={150}
                            series={[80, 120]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Google', 'Naver'],
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              dataLabels: {
                                enabled: true,
                                dropShadow: {
                                  enabled: false,
                                },
                                formatter: (
                                  val: string | number | number[],
                                  opts?: any,
                                ): string | number => {
                                  const name = opts.w.globals.labels[opts.seriesIndex];
                                  const value = opts.w.config.series[opts.seriesIndex];
                                  return [name, value] as unknown as string | number;
                                },
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -20,
                                  endAngle: 340,
                                  dataLabels: {
                                    offset: -15,
                                  },
                                },
                              },
                              responsive: [
                                {
                                  breakpoint: 1400,
                                  options: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Spend */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Spend</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#spendSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="spendSection" className="card card-body collapse show">
                      <div className="d-flex align-items-center mg-b-10">
                        <Select
                          width={200}
                          defaultValue={{ value: 'all', label: 'All' }}
                          className="mg-l-auto"
                        />
                      </div>
                      <div className="row">
                        <div className="col-xl-8">
                          <ReactApexChart
                            type="line"
                            height={300}
                            series={[
                              {
                                name: 'Google',
                                data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                              },
                              {
                                name: 'Naver',
                                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                              },
                            ]}
                            options={{
                              chart: {
                                zoom: {
                                  enabled: false,
                                },
                              },
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              stroke: {
                                curve: 'straight',
                              },
                              xaxis: {
                                categories: [
                                  '30m',
                                  '1h',
                                  '1h 30m',
                                  '2h',
                                  '2h 30m',
                                  '3h',
                                  '3h 30m',
                                  '3h',
                                  '3h 30m',
                                ],
                              },
                            }}
                          />
                          <div className="mg-t-10 pd-x-20">
                            <span className="h4">＄2,000</span>
                            <span className="mg-x-10 tx-success">
                              120%
                              <ArrowUp size={16} className="mg-l-5" />
                            </span>
                            <span className="tx-color-03">than last month(＄880)</span>
                          </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-center justify-content-center">
                          <ReactApexChart
                            type="pie"
                            height={150}
                            series={[800, 1200]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Google', 'Naver'],
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              dataLabels: {
                                enabled: true,
                                dropShadow: {
                                  enabled: false,
                                },
                                formatter: (
                                  val: string | number | number[],
                                  opts?: any,
                                ): string | number => {
                                  const name = opts.w.globals.labels[opts.seriesIndex];
                                  const value = opts.w.config.series[opts.seriesIndex];
                                  return [name, '$' + value] as unknown as string | number;
                                },
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -20,
                                  endAngle: 340,
                                  dataLabels: {
                                    offset: -15,
                                  },
                                },
                              },
                              responsive: [
                                {
                                  breakpoint: 1400,
                                  options: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Avg. Cost per Click */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Avg. Cost per Click</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#costSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="costSection" className="card card-body collapse show">
                      <div className="d-flex align-items-center mg-b-10">
                        <Select
                          width={200}
                          defaultValue={{ value: 'all', label: 'All' }}
                          className="mg-l-auto"
                        />
                      </div>
                      <div className="row">
                        <div className="col-xl-8">
                          <ReactApexChart
                            type="line"
                            height={300}
                            series={[
                              {
                                name: 'Google',
                                data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                              },
                              {
                                name: 'Naver',
                                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                              },
                            ]}
                            options={{
                              chart: {
                                zoom: {
                                  enabled: false,
                                },
                              },
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              stroke: {
                                curve: 'straight',
                              },
                              xaxis: {
                                categories: [
                                  '30m',
                                  '1h',
                                  '1h 30m',
                                  '2h',
                                  '2h 30m',
                                  '3h',
                                  '3h 30m',
                                  '3h',
                                  '3h 30m',
                                ],
                              },
                            }}
                          />
                          <div className="mg-t-10 pd-x-20">
                            <span className="h4">＄2</span>
                            <span className="mg-x-10 tx-success">
                              120%
                              <ArrowUp size={16} className="mg-l-5" />
                            </span>
                            <span className="tx-color-03">than last month(＄1)</span>
                          </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-center justify-content-center">
                          <ReactApexChart
                            type="pie"
                            height={150}
                            series={[0.8, 1.2]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Google', 'Naver'],
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              dataLabels: {
                                enabled: true,
                                dropShadow: {
                                  enabled: false,
                                },
                                formatter: (
                                  val: string | number | number[],
                                  opts?: any,
                                ): string | number => {
                                  const name = opts.w.globals.labels[opts.seriesIndex];
                                  const value = opts.w.config.series[opts.seriesIndex];
                                  return [name, '$' + value] as unknown as string | number;
                                },
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -20,
                                  endAngle: 340,
                                  dataLabels: {
                                    offset: -15,
                                  },
                                },
                              },
                              responsive: [
                                {
                                  breakpoint: 1400,
                                  options: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Conversions */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Conversions</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#conversionSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="conversionSection" className="card card-body collapse show">
                      <div className="d-flex align-items-center mg-b-10">
                        <Select
                          width={200}
                          defaultValue={{ value: 'all', label: 'All' }}
                          className="mg-l-auto"
                        />
                      </div>
                      <div className="row">
                        <div className="col-xl-8">
                          <ReactApexChart
                            type="line"
                            height={300}
                            series={[
                              {
                                name: 'Google',
                                data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                              },
                              {
                                name: 'Naver',
                                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                              },
                            ]}
                            options={{
                              chart: {
                                zoom: {
                                  enabled: false,
                                },
                              },
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              stroke: {
                                curve: 'straight',
                              },
                              xaxis: {
                                categories: [
                                  '30m',
                                  '1h',
                                  '1h 30m',
                                  '2h',
                                  '2h 30m',
                                  '3h',
                                  '3h 30m',
                                  '3h',
                                  '3h 30m',
                                ],
                              },
                            }}
                          />
                          <div className="mg-t-10 pd-x-20">
                            <span className="h4">50</span>
                            <span className="mg-x-10 tx-success">
                              120%
                              <ArrowUp size={16} className="mg-l-5" />
                            </span>
                            <span className="tx-color-03">than last month(40)</span>
                          </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-center justify-content-center">
                          <ReactApexChart
                            type="pie"
                            height={150}
                            series={[15, 35]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Google', 'Naver'],
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              dataLabels: {
                                enabled: true,
                                dropShadow: {
                                  enabled: false,
                                },
                                formatter: (
                                  val: string | number | number[],
                                  opts?: any,
                                ): string | number => {
                                  const name = opts.w.globals.labels[opts.seriesIndex];
                                  const value = opts.w.config.series[opts.seriesIndex];
                                  return [name, value] as unknown as string | number;
                                },
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -20,
                                  endAngle: 340,
                                  dataLabels: {
                                    offset: -15,
                                  },
                                },
                              },
                              responsive: [
                                {
                                  breakpoint: 1400,
                                  options: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Conversion Rate */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Conversion Rate</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#conversionRateSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="conversionRateSection" className="card card-body collapse show">
                      <div className="d-flex align-items-center mg-b-10">
                        <Select
                          width={200}
                          defaultValue={{ value: 'all', label: 'All' }}
                          className="mg-l-auto"
                        />
                      </div>
                      <div className="row">
                        <div className="col-xl-8">
                          <ReactApexChart
                            type="line"
                            height={300}
                            series={[
                              {
                                name: 'Google',
                                data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                              },
                              {
                                name: 'Naver',
                                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                              },
                            ]}
                            options={{
                              chart: {
                                zoom: {
                                  enabled: false,
                                },
                              },
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              stroke: {
                                curve: 'straight',
                              },
                              xaxis: {
                                categories: [
                                  '30m',
                                  '1h',
                                  '1h 30m',
                                  '2h',
                                  '2h 30m',
                                  '3h',
                                  '3h 30m',
                                  '3h',
                                  '3h 30m',
                                ],
                              },
                            }}
                          />
                          <div className="mg-t-10 pd-x-20">
                            <span className="h4">2.1%</span>
                            <span className="mg-x-10 tx-danger">
                              20%
                              <ArrowDown size={16} className="mg-l-5" />
                            </span>
                            <span className="tx-color-03">than last month(3.2%)</span>
                          </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-center justify-content-center">
                          <ReactApexChart
                            type="pie"
                            height={150}
                            series={[0.8, 1.3]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Google', 'Naver'],
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              dataLabels: {
                                enabled: true,
                                dropShadow: {
                                  enabled: false,
                                },
                                formatter: (
                                  val: string | number | number[],
                                  opts?: any,
                                ): string | number => {
                                  const name = opts.w.globals.labels[opts.seriesIndex];
                                  const value = opts.w.config.series[opts.seriesIndex];
                                  return [name, value + '%'] as unknown as string | number;
                                },
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -20,
                                  endAngle: 340,
                                  dataLabels: {
                                    offset: -15,
                                  },
                                },
                              },
                              responsive: [
                                {
                                  breakpoint: 1400,
                                  options: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Cost per Conversion */}
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Cost per Conversion</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#costConversionSection"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="costConversionSection" className="card card-body collapse show">
                      <div className="d-flex align-items-center mg-b-10">
                        <Select
                          width={200}
                          defaultValue={{ value: 'all', label: 'All' }}
                          className="mg-l-auto"
                        />
                      </div>
                      <div className="row">
                        <div className="col-xl-8">
                          <ReactApexChart
                            type="line"
                            height={300}
                            series={[
                              {
                                name: 'Google',
                                data: [2, 30, 20, 36, 29, 52, 59, 71, 100],
                              },
                              {
                                name: 'Naver',
                                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                              },
                            ]}
                            options={{
                              chart: {
                                zoom: {
                                  enabled: false,
                                },
                              },
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              stroke: {
                                curve: 'straight',
                              },
                              xaxis: {
                                categories: [
                                  '30m',
                                  '1h',
                                  '1h 30m',
                                  '2h',
                                  '2h 30m',
                                  '3h',
                                  '3h 30m',
                                  '3h',
                                  '3h 30m',
                                ],
                              },
                            }}
                          />
                          <div className="mg-t-10 pd-x-20">
                            <span className="h4">＄2,000</span>
                            <span className="mg-x-10 tx-success">
                              120%
                              <ArrowUp size={16} className="mg-l-5" />
                            </span>
                            <span className="tx-color-03">than last month(＄880)</span>
                          </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-center justify-content-center">
                          <ReactApexChart
                            type="pie"
                            height={150}
                            series={[800, 1200]}
                            options={{
                              theme: {
                                mode: 'light',
                              },
                              labels: ['Google', 'Naver'],
                              colors: ['rgba(66,133,244, 0.9)', 'rgba(6,207,92, 0.9)'],
                              dataLabels: {
                                enabled: true,
                                dropShadow: {
                                  enabled: false,
                                },
                                formatter: (
                                  val: string | number | number[],
                                  opts?: any,
                                ): string | number => {
                                  const name = opts.w.globals.labels[opts.seriesIndex];
                                  const value = opts.w.config.series[opts.seriesIndex];
                                  return [name, '$' + value] as unknown as string | number;
                                },
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -20,
                                  endAngle: 340,
                                  dataLabels: {
                                    offset: -15,
                                  },
                                },
                              },
                              responsive: [
                                {
                                  breakpoint: 1400,
                                  options: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmFactors />
      <NewCampaign />
      <EventQuestion />
    </>
  );
};

export default MediaView;
