import { Button, Select } from '@base/components/form';

const MarketingContent = () => {
  return (
    <div className="pd-20">
      {/* step to do : templates and content */}
      <div className="text-right mg-b-10">
        <Button color="primary" icon="Plus" iconClass="mg-r-5" name="New" className="rounded-30" />
      </div>
      <div className="table-responsive bg-white bd rounded">
        <table className="table table-bordered mg-0 bd-0 basic-table">
          <thead>
            <tr>
              <th scope="col">Marketing Content Type</th>
              <th scope="col">Type</th>
              <th scope="col">Template/Content/File Name</th>
              <th scope="col">Description</th>
              <th scope="col" className="wd-70">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Video</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>
                <Button color="link" icon="X" iconClass="tx-danger" className="btn-icon pd-y-0" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* step to do : define pain paints */}
      <div className="form-group">
        <label className="form-item-title d-block">Define Pain Point</label>
        <div>Reduce Cost, Save Time, Streamline their existing process, Grow their Sales</div>
      </div>

      {/* step to do : content for pain point */}
      <div className="text-right mg-b-10">
        <Button color="primary" icon="Plus" iconClass="mg-r-5" name="New" className="rounded-30" />
      </div>
      <div className="table-responsive bg-white bd rounded">
        <table className="table table-bordered mg-0 bd-0 basic-table">
          <thead>
            <tr>
              <th scope="col">Marketing Content Type</th>
              <th scope="col">Type</th>
              <th scope="col">Template/Content/File Name</th>
              <th scope="col">Description</th>
              <th scope="col">Related Pain Point</th>
              <th scope="col" className="wd-70">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Video</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>
                <Select />
              </td>
              <td>
                <Button color="link" icon="X" iconClass="tx-danger" className="btn-icon pd-y-0" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MarketingContent;
