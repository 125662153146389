import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  Plus,
  ChevronDown,
  Edit2,
  X,
  Check,
  Minus,
  ArrowLeft,
  HelpCircle,
  Printer,
} from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "InventoryCss/inventory.scss";

import AssignedRep from '../../../../component/desktop/assigned-rep';
import Notelist from '../../../../component/desktop/comment';
import Timeline from '../timeline';
import NewWarehouse from './modal/newWarehouse';
import StorageMap from './view/storageMap';
import AllocatedItems from './view/allocatedItems';
import { COLORS } from '@base/config/constant';

const TABS = [
  {
    value: 1,
    label: 'Locations',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Allocated Items',
  },
  {
    value: 4,
    label: 'Storage Map',
  },
  {
    value: 5,
    label: 'Note',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [showEditLocation, SetShowEditLocation] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/warehouse/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Warehouse
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/warehouse/desktop/theme1/list">Warehouse</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/inventory-items/desktop/theme1/list">Inventory Items</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/adjustment/desktop/theme1/list">Adjustment</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/transfer-order/desktop/theme1/list">Transfer Order</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/receipt/desktop/theme1/list">Receipt</a>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/shipment/desktop/theme1/list">Shipment</a>
              </div>
            </div>
          </div>

          <h1 className="view-title mg-l-10">Seoul 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">{Icon('gen_barcode')}</span>Generate
                    Barcode
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Printer />
                    </span>
                    Print Label
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newWarehouse"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Summary</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#warehouseSummary"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="warehouseSummary" className="view-aside-section-body collapse show">
                  <div className="form-group">
                    <label className="form-item-title">Warehouse Simple Name</label>
                    <div>WH1</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Priority</label>
                    <div>1</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Active</label>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="active-01"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="active-01"></label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Address</label>
                    <div>남부순환로 333길, 서울시, 서초구 </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Phone</label>
                    <div>021113333</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Owner</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-sm">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <div>MSR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Assigned Rep */}
              <AssignedRep />
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* Locations */}
              {tab.value == 1 && (
                <div className="pd-20">
                  <div className={!showEditLocation ? 'text-right' : 'mg-b-10'}>
                    {!showEditLocation && (
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => SetShowEditLocation(true)}
                      >
                        <Edit2 className="mg-r-5" />
                        Edit
                      </button>
                    )}
                    {showEditLocation && (
                      <>
                        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="customCheck1">
                            Building
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck2"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="customCheck2">
                            Floor
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck3"
                            defaultChecked
                            disabled
                          />
                          <label className="custom-control-label" htmlFor="customCheck3">
                            Storage Zone<span className="tx-danger">*</span>
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck4"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="customCheck4">
                            Row
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck5"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="customCheck5">
                            Rack
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck6"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="customCheck6">
                            Level
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck7"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="customCheck7">
                            Bin
                          </label>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="table-responsive bg-white bd rounded">
                    <table className="table table-bordered mg-b-0 warehouse-locations-tb">
                      <thead>
                        <tr>
                          <th scope="colgroup" className="bd-t-0-f">
                            Building
                          </th>
                          <th scope="colgroup" className="bd-t-0-f">
                            Floor
                          </th>
                          <th scope="colgroup" colSpan={6} className="bd-t-0-f">
                            Storage Zone
                          </th>
                          <th scope="col" rowSpan={2} className="bd-t-0-f">
                            Location Simple Name
                          </th>
                          <th scope="col" rowSpan={2} className="bd-t-0-f">
                            Replen Only
                          </th>
                          <th scope="col" rowSpan={2} className="bd-t-0-f">
                            Item Allocate
                          </th>
                        </tr>
                        <tr>
                          <th scope="col">Name{!showEditLocation && '(Simple Name)'}</th>
                          <th scope="col">Name{!showEditLocation && '(Simple Name)'}</th>
                          <th scope="col">Name{!showEditLocation && '(Simple Name)'}</th>
                          <th scope="col">Priority</th>
                          <th scope="col" className="bd-t-0-f">
                            Row
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Rack
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Level
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Bin
                          </th>
                        </tr>
                      </thead>
                      {!showEditLocation && (
                        <tbody>
                          <tr>
                            <td rowSpan={2}>Building 1(B01)</td>
                            <td rowSpan={2}>Floor 1(F01)</td>
                            <td rowSpan={2}>Storage zone 1(S01)</td>
                            <td rowSpan={2}>1</td>
                            <td rowSpan={2}>A</td>
                            <td>01</td>
                            <td>A</td>
                            <td>01</td>
                            <td>B01-F01-S01-A-01-A-01</td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch2"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customSwitch2"
                                ></label>
                              </div>
                            </td>
                            <td>Allocated</td>
                          </tr>
                          <tr>
                            <td>02</td>
                            <td>A</td>
                            <td>01</td>
                            <td>B01-F01-S01-A-02-A-01</td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch3"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customSwitch3"
                                ></label>
                              </div>
                            </td>
                            <td>Allocated</td>
                          </tr>
                        </tbody>
                      )}
                      {showEditLocation && (
                        <tbody>
                          <tr>
                            <td rowSpan={2}>
                              <div className="d-flex flex-column wd-150">
                                <label className="mg-b-5 tx-color-03">Name</label>
                                <input
                                  type="text"
                                  className="form-control mg-b-10"
                                  defaultValue="Building 1"
                                />
                                <label className="mg-b-5 tx-color-03">
                                  Simple Name
                                  <span
                                    className="tx-color-03 mg-l-5 han-tooltip--right"
                                    data-han-tooltip="바코드 생성을 위해 알파벳 숫자 이용 e.g. B01, 중복 등록 불가"
                                  >
                                    <HelpCircle size="16" />
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="알파벳, 숫자만 가능"
                                  defaultValue="B01"
                                />
                              </div>
                              <button type="button" className="btn btn-link btn-icon mg-l-10">
                                <Plus />
                                <span className="sr-only">추가</span>
                              </button>
                              <button type="button" className="btn btn-icon">
                                <Minus className="tx-danger" />
                                <span className="sr-only">삭제</span>
                              </button>
                            </td>
                            <td rowSpan={2}>
                              <div className="d-flex flex-column wd-150">
                                <label className="mg-b-5 tx-color-03">Name</label>
                                <input
                                  type="text"
                                  className="form-control mg-b-10"
                                  defaultValue="Floor 1"
                                />
                                <label className="mg-b-5 tx-color-03">
                                  Simple Name
                                  <span
                                    className="tx-color-03 mg-l-5 han-tooltip--right"
                                    data-han-tooltip="바코드 생성을 위해 알파벳 숫자 이용 e.g. B01, 중복 등록 불가"
                                  >
                                    <HelpCircle size="16" />
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="알파벳, 숫자만 가능"
                                  defaultValue="F01"
                                />
                              </div>
                              <button type="button" className="btn btn-link btn-icon mg-l-10">
                                <Plus />
                                <span className="sr-only">추가</span>
                              </button>
                              <button type="button" className="btn btn-icon">
                                <Minus className="tx-danger" />
                                <span className="sr-only">삭제</span>
                              </button>
                            </td>
                            <td rowSpan={2}>
                              <div className="d-flex flex-column wd-150">
                                <label className="mg-b-5 tx-color-03">Name</label>
                                <input
                                  type="text"
                                  className="form-control mg-b-10"
                                  defaultValue="Storage zone 1"
                                />
                                <label className="mg-b-5 tx-color-03">
                                  Simple Name
                                  <span
                                    className="tx-color-03 mg-l-5 han-tooltip--right"
                                    data-han-tooltip="바코드 생성을 위해 알파벳 숫자 이용 e.g. B01, 중복 등록 불가"
                                  >
                                    <HelpCircle size="16" />
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="알파벳, 숫자만 가능"
                                  defaultValue="S01"
                                />
                              </div>
                              <button type="button" className="btn btn-link btn-icon mg-l-10">
                                <Plus />
                                <span className="sr-only">추가</span>
                              </button>
                              {/*  The minimum number of storage zones is one. Therefore, if there is only one storage zone, it cannot be deleted.
                                                <button type="button" className="btn btn-icon">
                                                    <Minus className="tx-danger" /><span className="sr-only">삭제</span>
                                                </button> 
                                                */}
                            </td>
                            <td rowSpan={2}>
                              <SelectHook
                                className="wd-100-f"
                                options={[{ value: 1, label: '1' }]}
                              />
                            </td>
                            <td rowSpan={2}>
                              <div className="d-flex align-items-center">
                                A
                                <button type="button" className="btn btn-icon">
                                  <Minus className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                01
                                <button type="button" className="btn btn-icon">
                                  <Minus className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                A
                                <button type="button" className="btn btn-icon">
                                  <Minus className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                01
                                <button type="button" className="btn btn-icon">
                                  <Minus className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                            </td>
                            <td>B01-F01-S01-A-01-A-01</td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch2"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customSwitch2"
                                ></label>
                              </div>
                            </td>
                            <td>Allocated</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                02
                                <button type="button" className="btn btn-icon">
                                  <Minus className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                A
                                <button type="button" className="btn btn-icon">
                                  <Minus className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                01
                                <button type="button" className="btn btn-icon">
                                  <Minus className="tx-danger" />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                            </td>
                            <td>B01-F01-S01-A-02-A-01</td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch3"
                                  defaultChecked
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customSwitch3"
                                ></label>
                              </div>
                            </td>
                            <td>Allocated</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  {showEditLocation && (
                    <div className="mg-t-10 text-right">
                      <button
                        type="button"
                        className="btn btn-light mg-r-10"
                        onClick={() => SetShowEditLocation(false)}
                      >
                        <X className="ma-r-5" /> Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => SetShowEditLocation(false)}
                      >
                        <Check className="ma-r-5" /> Edit
                      </button>
                    </div>
                  )}
                </div>
              )}

              {/* Timeline */}
              {tab.value == 2 && <Timeline />}

              {/* Allocated Items */}
              {tab.value == 3 && <AllocatedItems />}

              {/* StorageMap */}
              {tab.value == 4 && (
                <div className="ht-100p pd-20">
                  <div className="d-flex flex-column ht-100p bg-white bd rounded">
                    <StorageMap />
                  </div>
                </div>
              )}

              {tab.value == 5 && <Notelist />}
            </div>
          </div>
        </div>
      </div>

      <NewWarehouse />
    </>
  );
};

export default ViewNew;
