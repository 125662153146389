import Icon from '@base/assets/icons/svg-icons';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';
import { ChevronDown, X } from 'react-feather';

const Knowledgebase = () => {
  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <button type="button" className="view-aside-section-move mg-r-5">
          {Icon('move')}
          <span className="sr-only">드래그앤드롭으로 이동</span>
        </button>
        <span className="view-aside-section-header-title">KnowledgeBase</span>
        <button
          type="button"
          className="btn btn-link link-03 mg-l-auto pd-0"
          data-toggle="collapse"
          data-target="#knowledgeBase"
        >
          <ChevronDown />
        </button>
      </div>
      <div id="knowledgeBase" className="view-aside-section-body collapse show">
        <div className="d-flex flex-wrap align-items-center mg-b-3 pd-x-10 pd-y-3">
          <a href="" target="_blank" title="새창으로 열림" className="mg-r-10">
            How to access admin page?
          </a>
          <div className="d-flex align-items-center mg-l-auto">
            <div className="tags">
              <span className="tag-item">postmaster</span>
              <span className="tag-item">access</span>
            </div>
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Delete"
            >
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mg-b-3 pd-x-10 pd-y-3">
          <a href="" target="_blank" title="새창으로 열림" className="mg-r-10">
            How to add an user?
          </a>
          <div className="d-flex align-items-center mg-l-auto">
            <div className="tags">
              <span className="tag-item">postmaster</span>
            </div>
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Delete"
            >
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </div>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Type otr click to select an article"
          />
          <div className="input-group-append">
            <button type="button" className="btn">
              {Icons('helpdesk')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Knowledgebase;
