import {
  CornerUpLeft,
  CornerUpRight,
  ExternalLink,
  Mail,
  Phone,
  Plus,
  Download,
  PhoneCall,
  Check,
  Calendar,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Activity = () => {
  return (
    <div className="pd-20">
      <div className="new-timeline-group">
        <div className="d-flex justify-content-end">
          {/* Filters */}
          <div className="dropleft mg-r-5">
            <button type="button" className="btn btn-white rounded-30" data-toggle="dropdown">
              <span className="mg-r-5">{Icon('filter')}</span>Filters
            </button>
            <div className="dropdown-menu wd-400 pd-x-15 bg-white shadow-sm">
              <div className="form-group">
                <label className="d-block form-item-title">View type</label>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="btn btn-white active">
                    <input type="radio" name="filter-view-options" id="option1" defaultChecked />{' '}
                    Daily
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-options" id="option2" /> Monthly
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-options" id="option3" /> Yearly
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="d-block form-item-title">Date</label>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="btn btn-white active">
                    <input type="radio" name="filter-view-date" id="dateoption1" defaultChecked />{' '}
                    Today
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-date" id="dateoption2" /> This week
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-date" id="dateoption3" /> This month
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-date" id="dateoption4" /> This year
                  </label>
                </div>
                <div className="d-flex align-items-center mg-t-10">
                  <div className="input-group input-date-group dropdown">
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text bg-white tx-gray-300">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                  <span className="mg-x-10">~</span>
                  <div className="input-group input-date-group dropdown">
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text bg-white tx-gray-300">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Keyword</label>
                <input type="search" className="form-control" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Assigned to</label>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="assignedCheck1" />
                  <label className="custom-control-label" htmlFor="assignedCheck1">
                    Me
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="assignedCheck2" />
                  <label className="custom-control-label" htmlFor="assignedCheck2">
                    My team
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Activity</label>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="filterActivityCheck1"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="filterActivityCheck1">
                    Call
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="filterActivityCheck2"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="filterActivityCheck2">
                    Task
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="filterActivityCheck2"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="filterActivityCheck2">
                    Meeting
                  </label>
                </div>
              </div>
              <div className="d-flex">
                <button type="button" className="btn btn-light flex-grow-1 mg-r-5">
                  Clear
                </button>
                <button type="button" className="btn btn-primary flex-grow-1">
                  Apply
                </button>
              </div>
            </div>
          </div>
          <button type="button" className="btn btn-primary rounded-30">
            <Plus className="mg-r-5" />
            Activity
          </button>
        </div>
        <div className="new-timeline-header">
          <div className="new-timeline-label">2021-03-17</div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Mail />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <strong>Mail Subject</strong>
                <span className="mg-l-auto tx-color-03">13:06</span>
              </div>
              <div className="new-timeline-body-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ut nisl et justo
                tempor fringilla. Maecenas in sapien felis. Nulla tincidunt augue risus, ac
                consectetur leo tincidunt ut. Donec aliquam vehicula nunc quis ultricies. Donec
                consectetur efficitur nunc eu cursus. Nam convallis libero et ligula sodales
                accumsan. Aliquam tempus tortor lacus, id posuere magna pellentesque id.
              </div>
              <div className="new-timeline-footer">
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    <CornerUpLeft className="mg-r-5" />
                    Reply
                  </button>
                  <button type="button" className="btn btn-link">
                    <CornerUpRight className="mg-r-5" />
                    Foward
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">{Icon('quotes')}</div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  <strong>Quote Subject</strong> created.
                </span>
                <span className="mg-l-auto tx-color-03">13:05</span>
              </div>
              <div className="new-timeline-footer">
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <img
                      src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="media-body mg-l-10">KDH</div>
                </div>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    View detail
                    <ExternalLink className="mg-l-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Phone />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <strong>Incoming call</strong>
                <span className="mg-l-auto tx-color-03">09:42</span>
              </div>
              <div className="mg-y-5">
                <audio controls preload="metadata">
                  <source src="" />
                </audio>
              </div>
              <div className="new-timeline-footer">
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <img
                      src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="media-body mg-l-10">MSR</div>
                </div>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    <Download className="mg-r-5" />
                    Download
                  </button>
                  <button type="button" className="btn btn-link">
                    <PhoneCall className="mg-r-5" />
                    Call
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">{Icon('task')}</div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  <strong>Checklist subject</strong> created.
                </span>
                <span className="mg-l-auto tx-color-03">09:20</span>
              </div>
              <div className="new-timeline-body-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ut nisl et justo
                tempor fringilla. Maecenas in sapien felis. Nulla tincidunt augue risus, ac
                consectetur leo tincidunt ut. Donec aliquam vehicula nunc quis ultricies. Donec
                consectetur efficitur nunc eu cursus. Nam convallis libero et ligula sodales
                accumsan. Aliquam tempus tortor lacus, id posuere magna pellentesque id.
              </div>
              <div className="tx-color-03">
                Duration: 3hours(2021-06-16 11:00) | Worker: John Doe
              </div>
              <div className="new-timeline-footer">
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <img
                      src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="media-body mg-l-10">KDH</div>
                </div>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    <Check className="mg-r-5" />
                    Mark complete
                  </button>
                  <button type="button" className="btn btn-link">
                    View detail
                    <ExternalLink className="mg-l-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
