import React, { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Settings,
  Menu,
  RotateCcw,
  X,
  Trello,
  Calendar,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import Pagination from '../../../../component/desktop/pagination-new';
import NewLead from './modal/newLead';

import '../marketing.scss';
import { Checkbox } from '@base/components/form';

const LeadList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
    kanban: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Lead</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item">
                <a href="/">Dashboard</a>
              </div>
              <div className="dropdown-item">
                <a href="/campaign/desktop/theme1/list">Campaign</a>
              </div>
              <div className="dropdown-item active">
                <a href="/lead/desktop/theme1/list">Lead</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  data-han-tooltip="Set as default"
                >
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/loyalty/desktop/theme1/list">Loyalty</a>
              </div>
              <div className="dropdown-item">
                <a href="/online-ditigal-contents/desktop/theme1/list">Marketing Resource</a>
              </div>
            </div>
          </div>
          <div className="button-group mg-l-auto">
            <button
              type="button"
              className="btn btn-primary btn-icon"
              data-toggle="modal"
              data-target="#newLeadModal"
            >
              <Plus />
              <span className="sr-only">Add</span>
            </button>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.kanban && <Trello />}
                {listType.list && <List />}
                {listType.grid && <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.kanban })}
                  onClick={() => setListType({ kanban: true })}
                >
                  <Trello className="mg-r-5" />
                  Kanban
                </button>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="dropdown list-filter-item">
            <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
              All
              <ChevronDown />
            </button>
            <div className="dropdown-menu wd-300"></div>
          </div>
          <div className="dropdown list-filter-item">
            <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
              All
              <ChevronDown />
            </button>
            <div className="dropdown-menu wd-300"></div>
          </div>
          <div className="dropdown list-filter-item">
            <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
              Filters
              <ChevronDown />
            </button>
            <div className="dropdown-menu wd-300"></div>
          </div>
          <div className="list-filter-items">
            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_asc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Title
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item">
                    Created on
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Title" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* for table list column settings */}
          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {listType.list && (
            <div className="table-responsive">
              <table className="table dataTable mg-b-0">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <Checkbox />
                    </th>
                    <th scope="col" className="sorting_asc">
                      Title
                    </th>
                    <th scope="col" className="sorting">
                      Name
                    </th>
                    <th scope="col" className="sorting">
                      Source
                    </th>
                    <th scope="col" className="sorting">
                      Score
                    </th>
                    <th scope="col" className="sorting">
                      Product
                    </th>
                    <th scope="col" className="sorting">
                      Collection Method
                    </th>
                    <th scope="col" className="sorting">
                      Pipeline
                    </th>
                    <th scope="col" className="sorting">
                      Owner
                    </th>
                    <th scope="col" className="sorting">
                      Created on
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/lead/desktop/theme1/view">Lead 1</a>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="media-body mg-l-5 text-truncate">Situmay</div>
                      </div>
                    </td>
                    <td>Campaign 1</td>
                    <td>20</td>
                    <td>Product A</td>
                    <td>Lead Capture</td>
                    <td>&nbsp;</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2022/05/16</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <Checkbox />
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a href="/lead/desktop/theme1/view" className="grid-list-item-title link-02">
                        Lead 1
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Name:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                              className="rounded-circle"
                            />
                          </div>
                          <div className="media-body mg-l-5 text-truncate">Situmay</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Source:</span>
                        <span className="grid-list-item-col-content">Campaign 1</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Score:</span>
                        <span className="grid-list-item-col-content">20</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Product:</span>
                        <span className="grid-list-item-col-content">Product A</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Collection Method:</span>
                        <span className="grid-list-item-col-content">Lead Capture</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Pipeline:</span>
                        <span className="grid-list-item-col-content">&nbsp;</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Owner:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">MSR</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Created on:</span>
                        <span className="grid-list-item-col-content">2022/05/16</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {listType.kanban && (
            <div className="d-flex ht-100p">
              <div className="flex-shrink-0 wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="Raw Leads(1)" className="tx-bold tx-uppercase" />
                </div>
                <div className="d-flex mg-b-10 pd-10 bg-white rounded shadow-sm">
                  <Checkbox />
                  <div className="flex-grow-1" style={{ maxWidth: 'calc(100% - 24px)' }}>
                    <a
                      href="/purchase/desktop/theme1/view"
                      className="mg-r-10 link-02 tx-bold text-truncate"
                    >
                      Lead 1
                    </a>
                    <ul className="mg-t-10 list-unstyled mg-0">
                      <li>
                        <div className="d-flex align-items-center">
                          Name:
                          <div className="media align-items-center mg-l-5">
                            <div className="avatar avatar-xs">
                              <img
                                src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                                className="rounded-circle"
                              />
                            </div>
                            <div className="media-body mg-l-5 text-truncate">Situmay</div>
                          </div>
                        </div>
                      </li>
                      <li>Source : Campaign 1</li>
                      <li>Score : 20</li>
                      <li>Product: Product A</li>
                      <li>Collection Method: Lead Capture</li>
                      <li>Pipeline : </li>
                      <li className="mg-t-5">
                        <div className="d-flex align-items-center tx-13 tx-color-03">
                          <Calendar size={14} className="mg-r-5" />
                          2022/05/16
                          <div className="avatar avatar-xs mg-l-auto" data-han-tooltip="MSR">
                            <img
                              src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="Nurturing(0)" className="tx-bold tx-uppercase" />
                </div>
              </div>
              <div className="flex-shrink-0 wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="Marketing Qulified(0)" className="tx-bold tx-uppercase" />
                </div>
              </div>
              <div className="flex-shrink-0 wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="Sales Qulified(0)" className="tx-bold tx-uppercase" />
                </div>
              </div>
              <div className="flex-shrink-0 wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="Qulified(0)" className="tx-bold tx-uppercase" />
                </div>
              </div>
              <div className="flex-shrink-0 wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <Checkbox label="Closed(0)" className="tx-bold tx-uppercase" />
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          {!listType.kanban && <Pagination />}
        </div>
      </div>

      <NewLead />
    </>
  );
};

export default LeadList;
