import { Plus, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';

const ReferralReward = () => {
  return (
    <div
      id="referralRewardModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Referral Reward</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="referralRewardSwitch" />
                <label className="custom-control-label" htmlFor="referralRewardSwitch"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing Status</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="referralRewardSwitch2"
                />
                <label className="custom-control-label" htmlFor="referralRewardSwitch2">
                  Available for Sending emails
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="referralRewardSwitch3"
                />
                <label className="custom-control-label" htmlFor="referralRewardSwitch3">
                  Marketing Email Template(4)
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="referralRewardSwitch4"
                />
                <label className="custom-control-label" htmlFor="referralRewardSwitch4">
                  Available for Sending SMS
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="referralRewardSwitch5"
                />
                <label className="custom-control-label" htmlFor="referralRewardSwitch5">
                  Marketing SMS Template(4)
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="referralRewardSwitch6"
                />
                <label className="custom-control-label" htmlFor="referralRewardSwitch6">
                  Available for Referral Reward Site(2)
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="referralRewardSwitch7"
                />
                <label className="custom-control-label" htmlFor="referralRewardSwitch7">
                  Available for Invitee Reward Landing Page(2)
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing Referral Site</label>
              <div className="table-responsive bg-white bd rounded">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Pages
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Created on
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Referral</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Invitee Sign up</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Invitee Reward</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Your Referral Stats</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Transaction</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing Email Template Referral Reward</label>
              <div className="table-responsive bg-white bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Subject
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Type
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Created on
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Status
                      </th>
                      <th scope="col" className="wd-70 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Referral</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>Referral Reward</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Send to Invitee</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Thanks to Referrer</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Thanks to Invitee</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                <input type="text" className="form-control mg-r-5" placeholder="Enter the name" />
                <button type="button" className="btn btn-primary flex-shrink-0">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Implementing SMS Template Referral Reward</label>
              <div className="table-responsive bg-white bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Subject
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Type
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Created on
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Status
                      </th>
                      <th scope="col" className="wd-70 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Referral</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>Referral Reward</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Send to Invitee</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Thanks to Referrer</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Thanks to Invitee</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        <SelectHook
                          className="wd-150-f"
                          options={[
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Prepare' },
                            { value: 3, label: 'Inactive' },
                          ]}
                        />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                <input type="text" className="form-control mg-r-5" placeholder="Enter the name" />
                <button type="button" className="btn btn-primary flex-shrink-0">
                  <Plus className="mg-r-5" />
                  Add
                </button>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Expenses to include in Marketing Investment</label>
              <div className="table-responsive bg-white bd rounded">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Expense Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Avg. Expense
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Per unit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Email Sending</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>1 Message</td>
                    </tr>
                    <tr>
                      <td>SMS Sending</td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>1 Message</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-lg btn-success">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralReward;
