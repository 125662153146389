import { useState } from 'react';
import classnames from 'classnames';
import { COLORS } from '@base/config/constant';

import { ChevronDown, Plus, CheckCircle, Circle, ArrowLeft } from 'react-feather';
import { Button } from '@base/components/form';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';
import UserList from '@demo-page/component/desktop/userlist';
import NoteList from '@demo-page/component/desktop/comment';
import NewOpportunity from './modal/newOpportunity';
import ProductList from '@demo-page/component/desktop/productlist';
import { UserAutoComplete } from '@base/containers';
import CompetitorList from '@demo-page/marketing/desktop/theme1/lead/view/competitorlist';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';
import SalesCommission from '@demo-page/sales-order/desktop/theme1/view/sales-commission';
import NewCompetitor from '@demo-page/marketing/desktop/theme1/lead/modal/newCompetitor';
import CommissionShare from '@demo-page/sales-order/desktop/theme1/modal/commissionShare';
import Timeline from '@base/containers/timeline';
import Activity from '@demo-page/marketing/desktop/theme1/lead/view/activities';
import OpportunityDetail from './view/detail';
import OpportunityPropose from './view/propose';
import OpportunityDevelop from './view/develop';
import AddReviewer from './modal/addReviewer';
import CloseOpportunity from './modal/closeOpportunity';

const TABS = [
  {
    value: 1,
    label: 'Propose',
  },
  {
    value: 2,
    label: 'Develop',
  },
  {
    value: 3,
    label: 'Activities',
  },
  {
    value: 4,
    label: 'Timeline',
  },
  {
    value: 5,
    label: 'Note',
  },
  {
    value: 6,
    label: 'Detail',
  },
  {
    value: 7,
    label: 'Sales Commission',
  },
];

const SOViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLORS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/opportunity/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Opportunity
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title">Opportunity 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  Hold
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu">
                  <button type="button" className="dropdown-item">
                    Close as Won
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#closeOpportunity"
                  >
                    Close as Lost
                  </button>
                </div>
              </div>
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right"></div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary pd-x-10"
              data-toggle="modal"
              data-target="#newOpportunity"
            >
              <Plus />
              <span className="sr-only">추가</span>
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <div className="row row-xs">
            <div className="col-lg-8">
              <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
                <li className="process-item current">
                  <button type="button" className="btn process-item-btn">
                    <CheckCircle />
                  </button>
                  <div className="process-item-text mg-t-10">Qualify</div>
                  <div className="mg-t-5">
                    <span className="tx-color-03 mg-r-5">Status</span>
                    <span>New</span>
                  </div>
                </li>
                <li className="process-item">
                  <button type="button" className="btn process-item-btn">
                    <Circle />
                  </button>
                  <div className="process-item-text mg-t-10">Develop</div>
                </li>
                <li className="process-item">
                  <button type="button" className="btn process-item-btn">
                    <Circle />
                  </button>
                  <div className="process-item-text mg-t-10">Propose</div>
                </li>
                <li className="process-item">
                  <button type="button" className="btn process-item-btn">
                    <Circle />
                  </button>
                  <div className="process-item-text mg-t-10">Closed</div>
                </li>
              </ol>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="pd-x-20 pd-y-15 tx-bold">Opportunity Insights</div>
                <div className="pd-x-20 pd-b-15">
                  <div className="row">
                    <div className="col-6 d-flex flex-column tx-center">
                      <div className="progress ht-20">
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated wd-80p"
                          role="progressbar"
                          aria-valuenow={80}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        >
                          80%
                        </div>
                      </div>
                      <div className="mg-t-auto tx-12 tx-uppercase tx-color-03 tx-spacing-1 tx-nowrap">
                        Win Probability
                      </div>
                    </div>
                    <div className="col-6 bd-l tx-center">
                      <div className="tx-24 tx-normal tx-rubik tx-spacing--2 lh-1 mg-b-5">
                        ＄500
                      </div>
                      <div className="tx-12 tx-uppercase tx-color-03 tx-spacing-1 tx-nowrap">
                        Esti Revenue
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <Button
                    color=""
                    className="view-aside-section-move mg-r-5"
                    icon="move"
                    iconType="custom"
                    data-han-tooltip="Move"
                  />
                  <span className="view-aside-section-header-title">BANT</span>
                  <Button
                    color="link link-03"
                    className="mg-l-auto pd-0"
                    icon="ChevronDown"
                    data-toggle="collapse"
                    data-target="#BANT"
                  />
                </div>
                <div id="BANT" className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Budget</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Decision Maker</label>
                    <div>Unsure</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Purchase Process</label>
                    <div>Individual</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Customer Need</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Time Frame</label>
                    <div>Immediately</div>
                  </div>
                </div>
              </div>

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <Button
                    color=""
                    className="view-aside-section-move mg-r-5"
                    icon="move"
                    iconType="custom"
                    data-han-tooltip="Move"
                  />
                  <span className="view-aside-section-header-title">Summary</span>
                  <Button
                    color="link link-03"
                    className="mg-l-auto pd-0"
                    icon="ChevronDown"
                    data-toggle="collapse"
                    data-target="#opportunitySummary"
                  />
                </div>
                <div id="opportunitySummary" className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Account</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                          className="rounded-circle"
                          alt="Adventure Works"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <a href="" target="_blank" title="새창으로 열림">
                          Adventure Works
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Type</label>
                    <div>New</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Sales Owner</label>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">
                        <span className="user-name">MSR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body scroll-box">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show right aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && <OpportunityPropose />}
              {tab.value == 2 && <OpportunityDevelop />}
              {tab.value == 3 && <Activity />}
              {tab.value == 4 && <Timeline />}
              {tab.value == 5 && <NoteList />}
              {tab.value == 6 && <OpportunityDetail />}
              {tab.value == 7 && <SalesCommission />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner scroll-box">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}
              {/* Contact */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <Button
                    color=""
                    className="view-aside-section-move mg-r-5"
                    icon="move"
                    iconType="custom"
                    data-han-tooltip="Move"
                  />
                  <span className="view-aside-section-header-title">Contact</span>
                  <Button
                    color="link link-03"
                    className="mg-l-auto pd-0"
                    icon="ChevronDown"
                    data-toggle="collapse"
                    data-target="#opportunityContact"
                  />
                </div>
                <div id="opportunityContact" className="view-aside-section-body collapse show">
                  <UserList />
                </div>
              </div>

              {/* Identify Contact Properties */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <Button
                    color=""
                    className="view-aside-section-move mg-r-5"
                    icon="move"
                    iconType="custom"
                    data-han-tooltip="Move"
                  />
                  <span className="view-aside-section-header-title">
                    Identify Contact Properties
                  </span>
                  <Button
                    color="link link-03"
                    className="mg-l-auto pd-0"
                    icon="ChevronDown"
                    data-toggle="collapse"
                    data-target="#Identify"
                  />
                </div>
                <div id="Identify" className="view-aside-section-body collapse show">
                  <div className="d-flex flex-wrap align-items-center mg-b-3 pd-x-10 pd-y-3">
                    <div className="media align-items-center flex-grow-1">
                      <div className="avatar avatar-xs">
                        <img
                          src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-5 text-truncate">Allan Rey Palban</div>
                    </div>
                    <span className="mg-l-auto tx-info">Role Name</span>
                  </div>
                  <CustomerAutoComplete single showAvatar value={''} onChange />
                </div>
              </div>

              {/* Sales Team */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <Button
                    color=""
                    className="view-aside-section-move mg-r-5"
                    icon="move"
                    iconType="custom"
                    data-han-tooltip="Move"
                  />
                  <span className="view-aside-section-header-title">Sales Team</span>
                  <Button
                    color="link link-03"
                    className="mg-l-auto pd-0"
                    icon="ChevronDown"
                    data-toggle="collapse"
                    data-target="#salesTeam"
                  />
                </div>
                <div id="salesTeam" className="view-aside-section-body collapse show">
                  <div className="d-flex flex-wrap align-items-center mg-b-3 pd-x-10 pd-y-3">
                    <div className="media align-items-center flex-grow-1">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-5 text-truncate">MSR</div>
                    </div>
                    <span className="mg-l-auto tx-info">Role Name</span>
                  </div>
                  <UserAutoComplete single showAvatar />
                </div>
              </div>

              {/* Product List */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <Button
                    color=""
                    className="view-aside-section-move mg-r-5"
                    icon="move"
                    iconType="custom"
                    data-han-tooltip="Move"
                  />
                  <span className="view-aside-section-header-title">Product</span>
                  <Button
                    color="link link-03"
                    className="mg-l-auto pd-0"
                    icon="ChevronDown"
                    data-toggle="collapse"
                    data-target="#opportunityProduct"
                  />
                </div>
                <div id="opportunityProduct" className="view-aside-section-body collapse show">
                  <ProductList />
                </div>
              </div>

              <CompetitorList />
            </div>
          </div>
        </div>
      </div>
      <NewOpportunity />
      <NewCompetitor />
      <CommissionShare />
      <AddReviewer />
      <CloseOpportunity />
    </>
  );
};

export default SOViewNew;
