import React, { useState } from 'react';
import classnames from 'classnames';

import { ArrowRight, X, ArrowLeft } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import StepAction from './stepAction';
import StepWait from './stepWait';
import StepCriteria from './stepCriteria';
import StepSite from './stepSite';
import StepStatus from './stepStatus';
import StepChecklist from './stepChecklist';

const TYPES = [
  {
    value: 'action',
    label: 'Action',
  },
  {
    value: 'wait',
    label: 'Wait',
  },
  {
    value: 'criteria',
    label: 'Rule Criteria',
  },
  {
    value: 'site',
    label: 'Site',
  },
  {
    value: 'status',
    label: 'Status',
  },
  {
    value: 'checklist',
    label: 'Checklist',
  },
  {
    value: 'simple',
    label: 'Simple Action',
  },
];

const SelStepType = () => {
  const [step, setStep] = useState<any>({
    type: true,
    list: false,
  });

  const [stepType, setStepType] = useState(TYPES[0]);

  return (
    <div
      id="selStepTypeModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Step</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body d-flex flex-column overflow-hidden pd-0-f">
            <ul role="tablist" className="steps steps-justified steps-tab bd-b">
              <li
                role="tab"
                className={classnames('step-item first', {
                  active: step.type,
                  complete: step.list,
                })}
              >
                <div className="step-link">
                  {step.type && <span className="current-info sr-only">현재 단계</span>}
                  <span className="step-number">1</span>
                  <span className="step-title">Step Type</span>
                </div>
              </li>
              <li
                role="tab"
                className={classnames('step-item diabled', {
                  active: step.list,
                })}
              >
                <div className="step-link">
                  {step.list && <span className="current-info sr-only">현재 단계</span>}
                  <span className="step-number">2</span>
                  <span className="step-title">
                    {stepType.value == 'action' ? 'Select Action' : 'Settings'}
                  </span>
                </div>
              </li>
            </ul>
            <div className="ht-100p overflow-auto">
              {step.type && (
                <div className="list-group step-list-group">
                  {TYPES.map((item, index) => (
                    <button
                      key={index}
                      className={classnames(
                        `list-group-item list-group-item-action step-${item.value}`,
                        {
                          active: item.value == stepType.value,
                        },
                      )}
                      onClick={(e) => {
                        setStepType(item);
                      }}
                    >
                      <span className="step-type-icon">{Icon(`diagram_${item.value}`)}</span>
                      {item.label}
                    </button>
                  ))}
                </div>
              )}

              {step.list && (
                <div className="pd-15">
                  {/* Action */}
                  {stepType.value == 'action' && <StepAction />}

                  {/* Wait */}
                  {stepType.value == 'wait' && <StepWait />}

                  {/* Criteria */}
                  {stepType.value == 'criteria' && <StepCriteria />}

                  {/* Site */}
                  {stepType.value == 'site' && <StepSite />}

                  {/* Status */}
                  {stepType.value == 'status' && <StepStatus />}

                  {/* checklist */}
                  {stepType.value == 'checklist' && <StepChecklist />}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer d-flex">
            {step.type && (
              <button
                type="button"
                className="btn btn-primary mg-l-auto"
                onClick={() => setStep({ list: true })}
              >
                Next
                <ArrowRight className="mg-l-5" />
              </button>
            )}
            {step.list && (
              <>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => setStep({ type: true })}
                >
                  <ArrowLeft className="mg-r-5" />
                  Previous
                </button>
                <button type="button" className="btn btn-success mg-l-auto" data-dismiss="modal">
                  Save
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelStepType;
