/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  X,
  Plus,
  User,
  List,
  Grid,
  Columns,
  Menu,
  RotateCcw,
  MoreHorizontal,
  Upload,
  Download,
} from 'react-feather';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "ProductCss/product.scss"

import Pagination from '../../../component/desktop/pagination-new';
import NewProduct from './modal/newProduct';
import NewItem from './modal/newItem';
import NewComponent from './modal/newComponent';
import NewUnit from './modal/newUnit';
import NewPriceList from './modal/newPriceList';
import NewDiscount from './modal/newDiscount';
import NewTradeAgreement from './modal/newTradeAgreement';
import NewAttribute from './modal/newProductItemAttribute';
import NewDetailCost from './modal/newProducDetailCost';

const ProList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
    tree: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Product</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Products
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item active">
                <a href="/crm/demo-page/ui/product/desktop/theme1/list">Products</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {/* Add'active' class if this menu is currently set by default */}
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <button>Items</button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/unit/desktop/theme1/list">Units</a>
              </div>
              <div className="dropdown-item">
                <button>Recycle Bin</button>
              </div>
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Price List
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/pricelist/desktop/theme1/list">Price List</a>
              </div>
              <div className="dropdown-item">
                <button>Items</button>
              </div>
              <div className="dropdown-item">
                <a href="/crm/demo-page/ui/discount/desktop/theme1/list">Discount</a>
              </div>
              <div className="dropdown-item">
                <button>Trade Agreement</button>
              </div>
              <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                Inventory
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  aria-label="move to main menu"
                >
                  {Icon('main_menu')}
                  <span className="sr-only">메인 메뉴로 이동</span>
                </button>
              </div>
              <div className="dropdown-item">
                <button>Warehouse</button>
              </div>
              <div className="dropdown-item">
                <button>Stock Ajustment</button>
              </div>
            </div>
          </div>
          <div className="btn-group dropdown mg-l-auto">
            <button
              type="button"
              className="btn btn-primary pd-x-10"
              data-toggle="modal"
              data-target="#newProduct"
            >
              <Plus />
              <span className="sr-only">추가</span>
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle-split pd-x-5"
              data-toggle="dropdown"
            >
              <ChevronDown />
              <span className="sr-only">추가 옵션 열기</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newProduct"
              >
                Product
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newItem"
              >
                Item
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newComponent"
              >
                Component
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newUnit"
              >
                Unit
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newPriceList"
              >
                Price List
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newDiscount"
              >
                Discount
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newTradeAgreement"
              >
                Trade Agreement
              </button>
            </div>
          </div>
          <div className="button-group">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="More"
                data-toggle="dropdown"
              >
                <MoreHorizontal />
                <ChevronDown />
                <span className="sr-only">선택 옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item">
                  <Upload className="mg-r-5" />
                  Import
                </button>
                <button className="dropdown-item">
                  <Download className="mg-r-5" />
                  Export
                </button>
              </div>
            </div>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="List type"
                data-toggle="dropdown"
              >
                {listType.list && <List />} {listType.grid && <Grid />}{' '}
                {listType.tree && <>{Icon('tree_view')}</>}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <button
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <span className="dropdown-item-icon">
                    <List />
                  </span>
                  List
                </button>
                <button
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <span className="dropdown-item-icon">
                    <Grid />
                  </span>
                  Grid
                </button>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" data-han-tooltip="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" data-han-tooltip="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            {!listType.tree && (
              <>
                <div className="dropdown list-filter-item">
                  <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                    Group by: ?
                    <ChevronDown />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button className="dropdown-item">?</button>
                  </div>
                </div>

                <div className="dropdown list-filter-item">
                  <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                    Date by: ?
                    <ChevronDown />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button className="dropdown-item">?</button>
                  </div>
                </div>

                <div className="dropdown list-filter-item">
                  <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                    Filter: ?
                    <ChevronDown />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button className="dropdown-item">?</button>
                  </div>
                </div>
              </>
            )}

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_desc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">내림차순</span> {/* sort_asc  : 오름차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Sales Start Date
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item">Group</button>
                  <button className="dropdown-item">Name</button>
                  <button className="dropdown-item">Type</button>
                  <button className="dropdown-item">Sales Start Date</button>
                  <button className="dropdown-item">Sales End Date</button>
                  <button className="dropdown-item">Assigned Rep</button>
                </div>
              </div>
            )}
          </div>

          {/* Search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* for table list column settings */}
          {listType.list && (
            <>
              <div className="dropdown mg-l-5">
                <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                  {Icon('column_settings')}
                  <span className="sr-only">항목 설정 열기</span>
                </button>
                <div className="dropdown-menu column-settings">
                  <div className="column-settings-title">Column Settings</div>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="columnCheck2"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="columnCheck2">
                          Group
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="columnCheck3"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="columnCheck3">
                          Name
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="columnCheck4"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="columnCheck4">
                          Type
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="columnCheck5"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="columnCheck5">
                          Code
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="columnCheck6"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="columnCheck6">
                          Sales Start Date
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="columnCheck7"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="columnCheck7">
                          Sales End Date
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="columnCheck8"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="columnCheck8">
                          Status
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                    <li className="list-group-item">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="columnCheck9"
                          defaultChecked
                        />
                        <label className="custom-control-label" htmlFor="columnCheck9">
                          Assigned Rep
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon handler"
                        aria-label="change the order"
                      >
                        <Menu />
                        <span className="드래그하여 순서 변경"></span>
                      </button>
                    </li>
                  </ul>
                  <div className="column-settings-actions">
                    Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                    <button type="button" className="btn btn-light mg-l-auto">
                      Close
                    </button>
                    <button type="button" className="btn btn-primary mg-l-5">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="list-body">
          {/* table list */}
          {listType.list && (
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered dataTable mg-b-0 bd-0">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="productCheckAll"
                        />
                        <label className="custom-control-label" htmlFor="productCheckAll"></label>
                      </div>
                    </th>
                    <th scope="col" className="sorting">
                      Group
                    </th>
                    <th scope="col" className="sorting">
                      Name
                    </th>
                    <th scope="col" className="sorting">
                      Type
                    </th>
                    <th scope="col" className="sorting">
                      ID
                    </th>
                    <th scope="col" className="sorting_desc">
                      Sales Start Date
                    </th>
                    <th scope="col" className="sorting">
                      Sales End Date
                    </th>
                    <th scope="col" className="sorting">
                      Active
                    </th>
                    <th scope="col" className="sorting">
                      Assigned Rep
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="productCheck1"
                        />
                        <label className="custom-control-label" htmlFor="productCheck1"></label>
                      </div>
                    </td>
                    <td>SW/CRM/Public Sever</td>
                    <td>
                      <a href="/crm/demo-page/ui/product/desktop/theme1/view-new">Product 1</a>
                    </td>
                    <td>Sale</td>
                    <td>Prod-000001</td>
                    <td className="sorting-cell">2021/01/02</td>
                    <td>No End Date</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="productActiveSwitch1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="productActiveSwitch1"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-avatar pd-0"
                          data-toggle="dropdown"
                        >
                          <div className="avatar avatar-xs">
                            <span className="avatar-initial rounded-circle bg-gray-600">M</span>
                          </div>
                          <span className="mg-x-5">MSR</span>
                          <ChevronDown />
                        </button>
                        <div className="dropdown-menu">
                          <div className="d-flex align-items-center pd-10">
                            <div className="avatar avatar-xs">
                              <span className="avatar-initial rounded-circle">M</span>
                            </div>
                            <span className="mg-x-5">MSR</span>
                            <div className="mg-l-auto">
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-02 btn-icon"
                                aria-label="user info"
                              >
                                <User />
                                <span className="sr-only">사용자 정보 보기</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-02 btn-icon"
                                aria-label="delete"
                              >
                                <X />
                                <span className="sr-only">삭제</span>
                              </button>
                            </div>
                          </div>
                          <div className="dropdown-divider"></div>
                          <button type="button" className="dropdown-item">
                            <Plus className="mg-r-5" />
                            Add a user
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="productCheck2"
                        />
                        <label className="custom-control-label" htmlFor="productCheck2"></label>
                      </div>
                    </td>
                    <td>SW/CRM/Public Sever</td>
                    <td>
                      <a href="/crm/demo-page/ui/product/desktop/theme1/view-new">Product 2</a>
                    </td>
                    <td>Purchase</td>
                    <td>Prod-000002</td>
                    <td className="sorting-cell">2021/01/01</td>
                    <td>2021/12/31</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="productActiveSwitch2"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="productActiveSwitch2"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-avatar pd-0"
                          data-toggle="dropdown"
                        >
                          <div className="avatar avatar-xs">
                            <span className="avatar-initial rounded-circle bg-gray-600">M</span>
                          </div>
                          <span className="mg-x-5">MSR</span>
                          <ChevronDown />
                        </button>
                        <div className="dropdown-menu">
                          <div className="d-flex align-items-center pd-10">
                            <div className="avatar avatar-xs">
                              <span className="avatar-initial rounded-circle">M</span>
                            </div>
                            <span className="mg-x-5">MSR</span>
                            <div className="mg-l-auto">
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-02 btn-icon"
                                aria-label="user info"
                              >
                                <User />
                                <span className="sr-only">사용자 정보 보기</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-02 btn-icon"
                                aria-label="delete"
                              >
                                <X />
                                <span className="sr-only">삭제</span>
                              </button>
                            </div>
                          </div>
                          <div className="dropdown-divider"></div>
                          <button type="button" className="dropdown-item">
                            <Plus className="mg-r-5" />
                            Add a user
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* grid list */}
          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="productCheck1" />
                      <label className="custom-control-label" htmlFor="productCheck1"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/crm/demo-page/ui/product/desktop/theme1/view-new"
                        className="grid-list-item-title link-02"
                      >
                        Product 1
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Group:</span>
                        <span className="grid-list-item-col-content">SW/CRM/Public Sever</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Type:</span>
                        <span className="grid-list-item-col-content">Sale</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">ID:</span>
                        <span className="grid-list-item-col-content">Prod-000001</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Sales Start Date:</span>
                        <span className="grid-list-item-col-content">2021/01/02</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Sales End Date:</span>
                        <span className="grid-list-item-col-content">No End Date</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Active:</span>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="productActiveSwitch1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="productActiveSwitch1"
                          ></label>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Assigned Rep:</span>
                        <div className="grid-list-item-col-content">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn btn-avatar pd-0"
                              data-toggle="dropdown"
                            >
                              <div className="avatar avatar-xs">
                                <span className="avatar-initial rounded-circle bg-gray-600">M</span>
                              </div>
                              <span className="mg-x-5">MSR</span>
                              <ChevronDown />
                            </button>
                            <div className="dropdown-menu">
                              <div className="d-flex align-items-center pd-10">
                                <div className="avatar avatar-xs">
                                  <span className="avatar-initial rounded-circle">M</span>
                                </div>
                                <span className="mg-x-5">MSR</span>
                                <div className="mg-l-auto">
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-link link-02 btn-icon"
                                    aria-label="user info"
                                  >
                                    <User />
                                    <span className="sr-only">사용자 정보 보기</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-link link-02 btn-icon"
                                    aria-label="delete"
                                  >
                                    <X />
                                    <span className="sr-only">삭제</span>
                                  </button>
                                </div>
                              </div>
                              <div className="dropdown-divider"></div>
                              <button type="button" className="dropdown-item">
                                <Plus className="mg-r-5" />
                                Add a user
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="productCheck2" />
                      <label className="custom-control-label" htmlFor="productCheck2"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/crm/demo-page/ui/product/desktop/theme1/view-new"
                        className="grid-list-item-title link-02"
                      >
                        Product 2
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Group:</span>
                        <span className="grid-list-item-col-content">SW/CRM/Public Sever</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Type:</span>
                        <span className="grid-list-item-col-content">Sale</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">ID:</span>
                        <span className="grid-list-item-col-content">Prod-000002</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Sales Start Date:</span>
                        <span className="grid-list-item-col-content">2021/01/01</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Sales End Date:</span>
                        <span className="grid-list-item-col-content">2021/12/31</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Active:</span>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="productActiveSwitch2"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="productActiveSwitch2"
                          ></label>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Assigned Rep:</span>
                        <div className="grid-list-item-col-content">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn btn-avatar pd-0"
                              data-toggle="dropdown"
                            >
                              <div className="avatar avatar-xs">
                                <span className="avatar-initial rounded-circle bg-gray-600">M</span>
                              </div>
                              <span className="mg-x-5">MSR</span>
                              <ChevronDown />
                            </button>
                            <div className="dropdown-menu">
                              <div className="d-flex align-items-center pd-10">
                                <div className="avatar avatar-xs">
                                  <span className="avatar-initial rounded-circle">M</span>
                                </div>
                                <span className="mg-x-5">MSR</span>
                                <div className="mg-l-auto">
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-link link-02 btn-icon"
                                    aria-label="user info"
                                  >
                                    <User />
                                    <span className="sr-only">사용자 정보 보기</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-link link-02 btn-icon"
                                    aria-label="delete"
                                  >
                                    <X />
                                    <span className="sr-only">삭제</span>
                                  </button>
                                </div>
                              </div>
                              <div className="dropdown-divider"></div>
                              <button type="button" className="dropdown-item">
                                <Plus className="mg-r-5" />
                                Add a user
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          {!listType.tree && <Pagination />}
        </div>
      </div>

      <NewProduct />
      <NewItem />
      <NewComponent />
      <NewUnit />
      <NewPriceList />
      <NewDiscount />
      <NewTradeAgreement />
      <NewAttribute />
      <NewDetailCost />
    </>
  );
};

export default ProList;
