import { useState } from 'react';
import { ArrowRight, ChevronDown, Copy, Settings, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const NewProductItem = () => {
  const [showStep2, setShowStep2] = useState<any>();

  const [itemType, setItemType] = useState('general');
  const handleItemTypeChange = (e: any) => {
    const { value } = e.target;
    setItemType(value);
  };

  return (
    <div
      id="newProductItemModal"
      className="modal fade right product-right-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Item</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            {!showStep2 && (
              <>
                <div className="form-group">
                  <label className="form-item-title d-block">
                    Item Category<span className="tx-danger">*</span>
                  </label>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="itemCategory"
                      id="itemCategory1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="itemCategory1">
                      Inventory
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="itemCategory"
                      id="itemCategory2"
                    />
                    <label className="custom-control-label" htmlFor="itemCategory2">
                      Non Inventory
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title d-block">
                    Item Type<span className="tx-danger">*</span>
                  </label>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="itemTypeOption"
                      id="itemTypeOption1"
                      value="general"
                      checked={itemType === 'general'}
                      onChange={(e) => handleItemTypeChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="itemTypeOption1">
                      General
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="itemTypeOption"
                      id="itemTypeOption2"
                      value="bundle"
                      checked={itemType === 'bundle'}
                      onChange={(e) => handleItemTypeChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="itemTypeOption2">
                      Bundle
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="itemTypeOption"
                      id="itemTypeOption3"
                      value="prepaid"
                      checked={itemType === 'prepaid'}
                      onChange={(e) => handleItemTypeChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="itemTypeOption3">
                      Prepaid
                    </label>
                  </div>
                </div>
                {itemType != 'bundle' && (
                  <>
                    <div className="form-group">
                      <label className="d-block form-item-title">Base unit</label>
                      <div>Bottle</div>
                    </div>
                    <div className="form-group">
                      <label className="d-block form-item-title">
                        Unit Name<span className="tx-danger">*</span>
                      </label>
                      <div className="dropdown">
                        <div className="input-group mg-r-10">
                          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                            {/* <div className="form-control-tag">
                                                <span className="form-control-tag-name">Bottle</span>
                                                <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                                                    <X />
                                                </button>
                                            </div>
                                            <div className="form-control-tag">
                                                <span className="form-control-tag-name">Pack</span>
                                                <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                                                    <X />
                                                </button>
                                            </div> */}
                            <input
                              type="text"
                              className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                              placeholder="Type or click to select a unit name"
                            />
                          </div>
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open option to select a uint name"
                            >
                              <ChevronDown />
                              <span className="sr-only">단위명 선택 옵션 열기</span>
                            </button>
                          </div>
                        </div>
                        <div className="dropdown-menu wd-100p">
                          <button type="button" className="dropdown-item">
                            Box
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">
                        Attributes<span className="tx-danger">*</span>
                      </label>
                      <div className="bd rounded">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="text-nowrap bd-t-0-f">
                                Attribute
                              </th>
                              <th scope="col" className="text-nowrap bd-t-0-f">
                                Value
                              </th>
                              <th scope="col" className="wd-70 text-nowrap bd-t-0-f">
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Size</td>
                              <td>
                                <div className="dropdown">
                                  <div className="input-group mg-r-10">
                                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                                      {/* <div className="form-control-tag">
                                                                    <span className="form-control-tag-name">S</span>
                                                                    <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                                                                        <X />
                                                                    </button>
                                                                </div> */}
                                      <input
                                        type="text"
                                        className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                                        placeholder="Type or click to select a value"
                                      />
                                    </div>
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn"
                                        aria-label="open option to select a value"
                                      >
                                        <ChevronDown />
                                        <span className="sr-only">단위명 선택 옵션 열기</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="dropdown-menu wd-100p">
                                    <div className="dropdown-divider"></div>
                                    <button type="button" className="dropdown-item">
                                      M
                                    </button>
                                    <button type="button" className="dropdown-item">
                                      L
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <button type="button" className="btn btn-link btn-icon pd-y-0">
                                  <X className="tx-danger" />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>Color</td>
                              <td>
                                <div className="dropdown">
                                  <div className="input-group mg-r-10">
                                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                                      {/* <div className="form-control-tag">
                                                                    <span className="form-control-tag-name">Cherry</span>
                                                                    <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                                                                        <X />
                                                                    </button>
                                                                </div> */}
                                      <input
                                        type="text"
                                        className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                                        placeholder="Type or click to select a value"
                                      />
                                    </div>
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn"
                                        aria-label="open option to select a value"
                                      >
                                        <ChevronDown />
                                        <span className="sr-only">단위명 선택 옵션 열기</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="dropdown-menu wd-100p">
                                    <button type="button" className="dropdown-item">
                                      Mint
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <button type="button" className="btn btn-link btn-icon pd-y-0">
                                  <X className="tx-danger" />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
                {/* for bundle */}
                {itemType === 'bundle' && (
                  <div className="form-group">
                    <label className="form-item-title">
                      Assoiated Items<span className="tx-danger">*</span>
                    </label>
                    <div className="table-responsive bg-white bd rounded">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr className="bg-light">
                            <th scope="col" className="bd-t-0-f">
                              Item Name{' '}
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              SKU
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Name
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Unit Quantity
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Contained Qty
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Base Price
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Base Cost Price
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Best Price
                            </th>
                            <th scope="col" className="bd-t-0-f">
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr className="bg-light">
                            <td colSpan={5} className="text-right">
                              Total
                            </td>
                            <td>￦4,000</td>
                            <td>￦2,000</td>
                            <td>￦3,500</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr>
                            <td>Big Bottle</td>
                            <td>Big-Bot-Lar</td>
                            <td>Bottle</td>
                            <td>1</td>
                            <td>
                              <input type="number" className="form-control" />
                            </td>
                            <td>￦4,000</td>
                            <td>￦2,000</td>
                            <td>￦3,000</td>
                            <td>
                              <button type="button" className="btn btn-link btn-icon pd-y-0">
                                <X className="tx-danger" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mg-b-10 pd-12 bd bd-t-0 rounded-bottom">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type or click to select an item"
                        />
                        <div className="input-group-append">
                          <button type="button" className="btn">
                            {Icon('product')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {showStep2 && (
              <div className="table-responsive rounded bd">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Item Name
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Item Code
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Base Price
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Base Cost Price
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Best Price
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Opening Stock
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Replenish Point{' '}
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        SKU
                      </th>
                      <th scope="col" className="text-nowrap bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="form-control wd-200 mg-b-10"
                          defaultValue="Juice-Bottle-Cherry-S"
                        />
                        Unit : Bottle(1)
                        <br />
                        Attributes:Cherry,S
                      </td>
                      <td>
                        <div className="input-group wd-200">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open code generation settings"
                            >
                              <Settings />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                        <button type="button" className="btn btn-link text-nowrap">
                          <Copy className="mg-r-5" />
                          Copy All
                        </button>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                        <button type="button" className="btn btn-link text-nowrap">
                          <Copy className="mg-r-5" />
                          Copy All
                        </button>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                        <button type="button" className="btn btn-link text-nowrap">
                          <Copy className="mg-r-5" />
                          Copy All
                        </button>
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                        <button type="button" className="btn btn-link text-nowrap">
                          <Copy className="mg-r-5" />
                          Copy All
                        </button>
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                        <button type="button" className="btn btn-link text-nowrap">
                          <Copy className="mg-r-5" />
                          Copy All
                        </button>
                      </td>
                      <td>
                        <div className="input-group wd-200">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open code generation settings"
                            >
                              <Settings />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button type="button" className="btn btn-link btn-icon pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="form-control wd-200 mg-b-10"
                          defaultValue="Juice-Bottle-Cherry-M"
                        />
                        Unit : Bottle(1)
                        <br />
                        Attributes:Cherry,M
                      </td>
                      <td>
                        <div className="input-group wd-200">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open code generation settings"
                            >
                              <Settings />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <div className="input-group wd-200">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open code generation settings"
                            >
                              <Settings />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button type="button" className="btn btn-link btn-icon pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="form-control wd-200 mg-b-10"
                          defaultValue="Juice-Bottle-Cherry-L"
                        />
                        Unit : Bottle(1)
                        <br />
                        Attributes:Cherry,L
                      </td>
                      <td>
                        <div className="input-group wd-200">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open code generation settings"
                            >
                              <Settings />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                      </td>
                      <td>
                        <div className="input-group wd-150">
                          <div className="input-group-prepend">
                            <span className="input-group-text lh-1">￦</span>
                          </div>
                          <input type="number" className="form-control" />
                        </div>
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <input type="number" className="form-control" />
                      </td>
                      <td>
                        <div className="input-group wd-200">
                          <input type="text" className="form-control" />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open code generation settings"
                            >
                              <Settings />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button type="button" className="btn btn-link btn-icon pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            {showStep2 ? (
              <button type="button" className="btn btn-success">
                Save
              </button>
            ) : (
              <button type="button" className="btn btn-primary" onClick={() => setShowStep2(true)}>
                Next
                <ArrowRight className="mg-l-5" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductItem;
