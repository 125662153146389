import { useState } from 'react';
import { Folder } from 'react-feather';
import { metrictTypeOptions, AMOUNT_TYPE, RATIO_TYPE } from '../config/metric';
import { Button, Input, RadioGroup } from '@base/components/form';
import SelectMetricName from './selectMetricName';

const AddMetric = (props: any) => {
  const { goalType } = props;
  const [type, setType] = useState(metrictTypeOptions);
  const [selectedType, setSelectedType] = useState(type[0].value);
  //change
  const handleTypeChange = (newOption: any) => {
    setSelectedType(newOption.value);
  };

  const getTargetUnit = () => {
    switch (selectedType) {
      case AMOUNT_TYPE:
        return 'KRW';
      case RATIO_TYPE:
        return '%';
      default:
        return 'S';
    }
  };

  return (
    <>
      <div id="addMetric" className="modal fade right">
        <div className="modal-dialog wd-auto">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h1 className="modal-title h6">Add Goal Metric</h1>
              <Button close data-dismiss="modal" />
            </div>
            <div className="modal-body pd-b-15-f overflow-auto">
              <div className="form-group">
                <label className="d-block">Metric Type </label>
                <RadioGroup options={type} value={selectedType} onChange={handleTypeChange} />
              </div>
              <div className="form-group">
                <label>
                  Metric Name <span className="tx-danger">*</span>
                </label>
                <div className="input-group">
                  <Input disabled required />
                  <span className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-icon bd-gray-300 bd-l-0 tx-gray-300"
                      data-toggle="modal"
                      data-target="#selectMetricName"
                    >
                      <Folder />
                    </button>
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label>Target</label>
                {/* User Goal */}
                {goalType === 'user' && (
                  <div className="input-group">
                    <input type="number" className="form-control bd-r-0" />
                    <span className="input-group-append">
                      <span className="input-group-text bg-white bd-l-0 lh-1">
                        {getTargetUnit()}
                      </span>
                    </span>
                  </div>
                )}
                {/* Team Goals*/}
                {goalType === 'team' && (
                  <div className="bd rounded">
                    <table className="mg-b-0 bd-t-0 bd-b-0">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Team Member
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Target
                          </th>
                        </tr>
                      </thead>
                      <tr className="bg-light">
                        <td className="text-center">Team A</td>
                        <td className="text-center">0</td>
                      </tr>
                    </table>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>Description</label>
                <Input />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-success">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <SelectMetricName metricType={selectedType} />
    </>
  );
};

export default AddMetric;
