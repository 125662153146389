import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  X,
  Menu,
  ChevronsLeft,
  ChevronsRight,
  Edit2,
  CheckCircle,
  Circle,
  ArrowLeft,
  Bell,
  BellOff,
  Send,
  ChevronLeft,
  ChevronRight,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "InvoiceCss/invoice.scss"

import Attachments from '../../../../component/desktop/attachments';
import AssignedRep from '../../../../component/desktop/assigned-rep';
import Userlist from '../../../../component/desktop/userlist';
import Notelist from '../../../../component/desktop/notelist';

import InvoiceTimeline from '../timeline';
import SendReminder from './modal/reminder';
import ApplyLateFee from './modal/latefee';

const TABS = [
  {
    value: 1,
    label: 'Item',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Note',
  },
  {
    value: 4,
    label: 'Payment Received',
  },
  {
    value: 5,
    label: 'Credit Applied',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const View = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/unpaid-invoice/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Unpaid Invoices
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10">Inv-000001</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="dropdown">
              <button
                type="button"
                data-han-tooltip="Reminder"
                className="btn btn-link link-01 btn-icon han-tooltip"
                data-toggle="dropdown"
              >
                <span className="span-icon">
                  <Bell />
                </span>
                <span className="sr-only">알림</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right wd-350 pd-15">
                <p>Scheduled Unpaid Reminder: 2021/05/12</p>
                <button
                  type="button"
                  className="btn btn-sm btn-primary mg-r-5"
                  data-toggle="modal"
                  data-target="#sendReminderModal"
                >
                  <Send className="mg-r-5" />
                  Send Now
                </button>
                <button type="button" className="btn btn-sm btn-danger">
                  <BellOff className="mg-r-5" />
                  Turn off Auto Reminder
                </button>
              </div>
            </div>
            <button
              type="button"
              data-han-tooltip="Delete"
              className="btn btn-link link-01 btn-icon han-tooltip"
            >
              <span className="span-icon">
                <Trash />
              </span>
              <span className="sr-only">삭제</span>
            </button>
            <div className="dropdown">
              <button
                type="button"
                data-han-tooltip="More"
                className="btn btn-link link-01 btn-icon han-tooltip"
                data-toggle="dropdown"
              >
                <span className="span-icon">
                  <Menu />
                </span>
                <span className="sr-only">더보기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#applyLateFeeModal"
                >
                  Apply Late Fee
                </button>
              </div>
            </div>
            <button type="button" className="btn btn-primary mg-l-5 rounded-30">
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* process */}
        <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
          <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
            <li className="process-item current">
              <button type="button" className="btn process-item-btn">
                <CheckCircle />
              </button>
              <div className="process-item-text mg-t-10">Active</div>
              <div className="mg-t-5">
                <span className="tx-color-03 mg-r-5">Status</span>
                <span>New</span>
              </div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Sent</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Paid</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Past Due</div>
            </li>
            <li className="process-item">
              <button type="button" className="btn process-item-btn">
                <Circle />
              </button>
              <div className="process-item-text mg-t-10">Closed</div>
            </li>
          </ol>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-section pd-15">
              <div className="d-flex pd-10">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                  Invoice Amount
                </div>
                <div className="mg-l-auto  tx-15 tx-semibold">￦100,000</div>
              </div>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-orange">Balance Due</div>
                <div className="mg-l-auto  tx-15 tx-semibold">￦100,000</div>
              </div>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-warning">
                  Overdue Days
                </div>
                <div className="mg-l-auto  tx-15 tx-semibold">10 days</div>
              </div>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">
                  Outstanding Receivable
                </div>
                <div className="mg-l-auto  tx-15 tx-semibold">￦600,000</div>
              </div>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">
                  Remained Credits
                </div>
                <div className="mg-l-auto  tx-15 tx-semibold">0</div>
              </div>
            </div>

            {/* Basic Info */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <span className="view-aside-section-header-title">Basic Info</span>
              </div>
              <div className="view-aside-section-body">
                <div className="form-group">
                  <label className="form-item-title">Address</label>
                  <div className="mg-b-5">
                    <span className="badge badge-dark mg-r-5 tx-normal tx-13">Bill to</span>
                    Bay Area, San Francisco, CA
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Send Mode</label>
                  <div>by Email</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Receipt Tyoe</label>
                  <div>Cash receipt</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Invoice Process</label>
                  <div>Default</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Sales Order ID</label>
                  <div>SO-000001</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Invoice Date</label>
                  <div>2021-05-30</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Due Date</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Expected Payment Date</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Invoice Template</label>
                  <div>Template 1</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Email Template</label>
                  <div>Template 2</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Terms &amp; Conditions</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Customer Notes</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                  { hidden: hideAsideLeft },
                )}
                onClick={() => setHideAsideLeft(!hideAsideLeft)}
              >
                <ChevronsLeft />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>

              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 4 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 3,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 3 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 3 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>

              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-l-10 bd-r-0 btn-collapse-aside-right',
                  { hidden: hideAsideRight },
                )}
                onClick={() => setHideAsideRight(!hideAsideRight)}
              >
                <ChevronsRight />
                <span className="sr-only">우측 사이드 패널 접기</span>
              </button>
            </div>

            <div className="view-body">
              {tab.value == 1 && (
                <div className="pd-15">
                  <div className="mg-b-10 text-right">
                    <button type="button" className="btn btn-info rounded-30">
                      <span className="mg-r-5">{Icon('preview')}</span>Invoice Preview
                    </button>
                  </div>
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">One-Time Item</span>
                      <button type="button" className="btn btn-link mg-l-auto">
                        <Edit2 className="mg-r-5" />
                        Edit
                      </button>
                    </div>
                    <div className="bg-white bd rounded">
                      <div className="table-responsive mg-b-10 bd-b">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col" className="bd-t-0-f">
                                Image
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Item
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Unit
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Unit Price
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Quantity
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Amount
                              </th>
                              <th scope="col" className="bd-t-0-f">
                                Discount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="avatar avatar-sm bg-gray-200 rounded">
                                  <img src="assets/images/notfound.png" alt="" />
                                </div>
                              </td>
                              <td>AAA</td>
                              <td>Box</td>
                              <td>￦100,000</td>
                              <td>100</td>
                              <td>￦10,000,000</td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="row row-xs mg-x-0 mg-b-5">
                        <div className="col-md-6">
                          <ul className="list-unstyled lh-7 mg-b-0">
                            <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                              <span>Items : 100</span>
                              <span> ₩ 10,000,000</span>
                            </li>
                            <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                              <span>Total Discount</span>
                              <div className="mg-l-5 tx-danger">- ₩ 500,000</div>
                            </li>
                          </ul>
                          <div className="rounded bd bg-gray-100">
                            <div className="d-flex flex-wrap pd-x-15 pd-y-10">
                              Loyalty
                              <button
                                type="button"
                                className="btn btn-link link-03 mg-l-auto pd-0 collapsed"
                                data-toggle="collapse"
                                data-target="#loyaltyDetail"
                              >
                                <span className="tx-danger">-₩410,000</span>
                                <ChevronDown className="mg-l-5" />
                              </button>
                            </div>
                            <div id="loyaltyDetail" className="collapse mg-10 bd bd-dashed rounded">
                              <ul className="list-unstyled mg-b-0 lh-7 mg-b-0">
                                <li className="d-flex justify-content-between pd-10">
                                  <label className="tx-color-03 tx-13">Point(s)</label>
                                  <div className="tx-danger">- ₩10,000</div>
                                </li>
                                <li className="d-flex justify-content-between pd-10 bd bd-dashed bd-x-0 bd-b-0">
                                  <label className="tx-color-03 tx-13">Coupon(s)</label>
                                  <div className="tx-danger">- ₩ 100,000</div>
                                </li>
                                <li className="d-flex justify-content-between pd-10 bd bd-dashed bd-x-0 bd-b-0">
                                  <label className="tx-color-03 tx-13">Stamp(s) Reward</label>
                                  <div className="tx-danger">- ₩300,000</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <ul className="list-unstyled mg-b-10 lh-10 pd-15 rounded bg-white bd">
                            <li className="d-flex justify-content-between">
                              <span>Sub-Total</span>
                              <span>₩ 9,090,000</span>
                            </li>
                            <li className="d-flex justify-content-between">
                              <span>Shipping Charges</span>
                              <span>₩ 4,000</span>
                            </li>
                            <li className="d-flex justify-content-between">
                              <span>Tax (5%)</span>
                              <span>₩ 454,500</span>
                            </li>
                            <li className="d-flex justify-content-between">
                              <span>Round Off</span>
                              <span>₩ 0</span>
                            </li>
                            <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                              <strong>Total Amount</strong>
                              <strong>₩ 9,548,500</strong>
                            </li>
                          </ul>
                          <div className="d-flex justify-content-between mg-b-10 pd-x-10 tx-16 tx-danger">
                            <strong>Balance Due</strong>
                            <strong>₩ 9,548,500</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tab.value == 2 && <InvoiceTimeline />}
              {tab.value == 3 && <Notelist />}
              {tab.value == 4 && (
                <div className="pd-15">
                  <div className="table-responsive bg-white bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Payment ID
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Received Amount
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Payment Date
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Payment Mode
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pay-000001</td>
                          <td>￦385,000</td>
                          <td>2020/11/25</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex align-items-center justify-content-end pd-x-20 pd-y-6 bg-white rounded-bottom bd bd-t-0 pagination">
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="move to first"
                    >
                      {Icon('move_first')}
                      <span className="sr-only">처음으로</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="previous"
                    >
                      <ChevronLeft />
                      <span className="sr-only">이전</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="next"
                    >
                      <ChevronRight />
                      <span className="sr-only">다음</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="move to last"
                    >
                      {Icon('move_last')}
                      <span className="sr-only">마지막으로</span>
                    </button>
                    <input type="number" className="paging-input" defaultValue="1" />/ 1
                  </div>
                </div>
              )}
              {tab.value == 5 && (
                <div className="pd-15">
                  <div className="table-responsive bg-white bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Invoice ID
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Applied Amount
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Applied Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Inv-000001</td>
                          <td>￦385,000</td>
                          <td>2020/11/25</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex align-items-center justify-content-end pd-x-20 pd-y-6 bg-white rounded-bottom bd bd-t-0 pagination">
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="move to first"
                    >
                      {Icon('move_first')}
                      <span className="sr-only">처음으로</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="previous"
                    >
                      <ChevronLeft />
                      <span className="sr-only">이전</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="next"
                    >
                      <ChevronRight />
                      <span className="sr-only">다음</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="move to last"
                    >
                      {Icon('move_last')}
                      <span className="sr-only">마지막으로</span>
                    </button>
                    <input type="number" className="paging-input" defaultValue="1" />/ 1
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            {/* Customer */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Customer</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#customerContact"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="customerContact" className="view-aside-section-body collapse show">
                <Userlist />
              </div>
            </div>

            {/* Assigned Rep. */}
            <AssignedRep />

            {/* Attachment */}
            <Attachments />
          </div>
        </div>
      </div>

      <SendReminder />
      <ApplyLateFee />
    </>
  );
};

export default View;
