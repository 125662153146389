import { Button, Input, Select } from '@base/components/form';
import noImg from '@base/assets/img/notfound.png';
import Icon from '@base/assets/icons/svg-icons';

const ProductDetail = () => {
  return (
    <div className="col-lg-12 form-group">
      <label className="form-item-title">Product Details</label>
      <div className="d-flex align-items-center mg-b-10">
        <Select defaultValue={[{ value: 0, label: 'Select a template' }]} className="wd-200-f" />
        <Button color="link" name="Preview" />
      </div>
      <div className="table-responsive bd rounded-top">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Image
              </th>
              <th scope="col" className="bd-t-0-f">
                Item
              </th>
              <th scope="col" className="bd-t-0-f">
                Purchase Unit
              </th>
              <th scope="col" className="bd-t-0-f">
                Purchase Price
              </th>
              <th scope="col" className="bd-t-0-f">
                Quantity
              </th>
              <th scope="col" className="bd-t-0-f">
                Amount
              </th>
              <th scope="col" className="bd-t-0-f">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src={noImg} alt="" />
                </div>
              </td>
              <td>
                <div className="wd-200">
                  <Input rightIcon={Icon('product')} value="Item 1" />
                </div>
              </td>
              <td>
                <Select />
              </td>
              <td>
                <div className="wd-150">
                  <Input type="currency" currencySymbol="￦" />
                </div>
              </td>
              <td>
                <Input />
              </td>
              <td>￦0</td>
              <td>
                <Button
                  color="link"
                  icon="X"
                  iconClass="tx-danger"
                  className="btn btn-link link-03 btn-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pd-10 bd bd-t-0 rounded-bottom">
        <Input rightIcon={Icon('product')} placeholder="Type or click to select an item" />
      </div>
      <div className="row row-xs mg-y-10">
        <div className="col-md-6">
          <ul className="list-unstyled lh-7 mg-b-0">
            <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
              <span>Items : 0</span>
              <span> ₩ 0</span>
            </li>
            <li className="d-flex align-items-center justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
              <span>Discount</span>
              <div className="d-flex mg-l-5">
                <Input />
                <Select defaultValue={[{ value: 1, label: '%' }]} className="mg-l-5" />
              </div>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
            <li className="d-flex justify-content-between">
              <span>Sub-Total</span>
              <span>￦0</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>Tax</span>
              <span>￦0</span>
            </li>
            <li className="d-flex align-items-center">
              <span>Adjustment</span>
              <Input placeholder="Name" className="mg-l-5" />
              <Input className="mg-x-5" />
              <Button icon="Plus" color="link" className="btn-xs btn-icon" tooltip="Add" />
              <Button
                color="link"
                icon="Minus"
                iconClass="tx-danger"
                className="btn-icon"
                tooltip="Delete"
              />
            </li>
            <li className="d-flex justify-content-between">
              <span>Round Off</span>
              <span>&nbsp;</span>
            </li>
            <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
              <strong>Total</strong>
              <strong>￦1,078,000</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
