import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  ChevronsLeft,
  ArrowLeft,
  Plus,
  MoreHorizontal,
  Copy,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"

import Timeline from '../timeline';
import NewPriceList from '../modal/newPriceList';

const TABS = [
  {
    value: 1,
    label: 'Detail',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Apply Schedule',
  },
  {
    value: 4,
    label: 'Calendar',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const View = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Price List
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10 lh-1">Price List 1</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="dropdown">
              <button
                type="button"
                data-han-tooltip="More"
                className="btn btn-white btn-icon rounded-circle han-tooltip"
                data-toggle="dropdown"
              >
                <span className="span-icon">
                  <MoreHorizontal />
                </span>
                <span className="sr-only">더보기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Copy />
                  </span>
                  Clone
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>

            <div className="btn-group dropdown mg-l-5">
              <a href="#newPriceList" className="btn btn-primary pd-x-10" data-toggle="modal">
                <Plus />
                <span className="sr-only">추가</span>
              </a>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <ChevronDown />
                <span className="sr-only">추가 옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a href="#newPriceList" className="dropdown-item" data-toggle="modal">
                  Price List
                </a>
                <a href="" className="dropdown-item">
                  Discount
                </a>
                <a href="" className="dropdown-item">
                  Trade Agreement
                </a>
              </div>
            </div>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <span className="view-aside-section-header-title">Summary</span>
              </div>
              <div className="view-aside-section-body">
                <div className="form-group">
                  <label className="form-item-title">Price List Type</label>
                  <div>Static</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Active</label>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="pricelistActiveSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="pricelistActiveSwitch1"
                    ></label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Start date</label>
                  <div>2021/02/23</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">End date</label>
                  <div>2021/02/23</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                  { hidden: hideAsideLeft },
                )}
                onClick={() => setHideAsideLeft(!hideAsideLeft)}
              >
                <ChevronsLeft />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>

              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map((item, index) => (
                    <li
                      key={index}
                      className={classnames('nav-item', {
                        active: item.value == tab.value,
                      })}
                    >
                      <button
                        type="button"
                        className="btn btn-xs btn-move-tab"
                        aria-label="Drag-and-drop to reorder"
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <button
                        type="button"
                        className="btn nav-link"
                        onClick={(e) => {
                          setTab(item);
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="view-body">
              {tab.value == 1 && (
                <div className="pd-15">
                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">
                        Price Calculation Rule
                      </span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#calculationRule"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="calculationRule" className="card card-body collapse show">
                      <div className="form-row">
                        <div className="form-col col-lg-6 form-group">
                          <label className="form-item-title">Way to apply</label>
                          <div>Auto</div>
                        </div>
                        <div className="form-col col-lg-6 form-group">
                          <label className="form-item-title">Rate</label>
                          <div>5% Less than base price</div>
                        </div>
                        <div className="form-col col-lg-12 form-group">
                          <label className="form-item-title">Excluded Items</label>
                          <div>product name 3, product name 4</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">Related Sales Channel</span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#relatedSalesChannel"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="relatedSalesChannel" className="card card-body collapse show">
                      ???
                    </div>
                  </div>

                  <div className="view-content-section">
                    <div className="view-content-section-header">
                      <button type="button" className="view-aside-section-move mg-r-5">
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <span className="view-aside-section-header-title">
                        Related Trade Agreement/Catalog ???
                      </span>
                      <button
                        type="button"
                        className="btn btn-link link-03 mg-l-auto"
                        data-toggle="collapse"
                        aria-label="collapse"
                        data-target="#relatedTA"
                      >
                        <ChevronDown />
                        <span className="sr-only">내용 접기</span>
                      </button>
                    </div>
                    <div id="relatedTA" className="card card-body collapse show">
                      ???
                    </div>
                  </div>
                </div>
              )}

              {tab.value == 2 && <Timeline />}

              {tab.value == 3 && (
                <div className="pd-15">
                  <div className="form-row">
                    <div className="form-col col-lg-6 form-group">
                      <label className="form-item-title">Recurrence</label>
                      <div>Hourly</div>
                    </div>
                    <div className="form-col col-lg-6 form-group">
                      <label className="form-item-title">Start Time</label>
                      <div>09:00</div>
                    </div>
                    <div className="form-col col-lg-6 form-group">
                      <label className="form-item-title">End Time</label>
                      <div>10:00</div>
                    </div>
                    <div className="form-col col-lg-6 form-group">
                      <label className="form-item-title">Days</label>
                      <div>Every Monday</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewPriceList />
    </>
  );
};

export default View;
