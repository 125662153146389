/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import { ChevronLeft, ChevronRight, Plus } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import NewTaskTemp from './modal/newTaskTemp';
import NewCallTemp from './modal/newCallTemp';
import NewMeetingTemp from './modal/newMeetingTemp';
import NewDirectMailTemp from './modal/newDirectMailTemp';
import NewSMSTemp from './modal/newSMSTemp';
import PreviewTemp from './modal/previewTemp';

import '@base/components/sidebar-menu/styles.scss';
import { Button } from '@base/components/form';
import NewEmailTemp from './modal/newEmailTemp';
import Comment from '@demo-page/component/desktop/comment';
import Pagination from '@demo-page/component/desktop/pagination-new';

const NAVS = [
  {
    value: 'task',
    label: 'Task',
  },
  {
    value: 'call',
    label: 'Call',
  },
  {
    value: 'sms',
    label: 'SMS',
  },
  {
    value: 'meeting',
    label: 'Meeting',
  },
  {
    value: 'dm',
    label: 'Direct Mail',
  },
  {
    value: 'email',
    label: 'Email',
  },
];

const Templates = () => {
  const [nav, setNav] = useState(NAVS[0]);
  const [hideSideNav, setSideNav] = useState(false);

  const [showEmailDetail, setShowEmailDetail] = useState(false);

  return (
    <>
      <div className={classnames('page-container-wrap', { 'hide-sidebar': hideSideNav })}>
        <div className="page-menu-wrap">
          <div className="page-menu-nav-sidebar">
            <ul className="page-menu-sidebar-nav">
              {NAVS.map((item, index) => (
                <li className="page-menu-nav-item" key={index}>
                  <button
                    className={classnames('btn page-menu-nav-link', {
                      active: item.value == nav.value,
                    })}
                    onClick={(e) => {
                      setNav(item);
                    }}
                  >
                    {Icon(item.value)}
                    <span className="mg-l-5">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="page-container-content">
          <div className="page-container-content-header">
            <button
              type="button"
              className="btn btn-toggle-sidebar tx-gray-400"
              onClick={() => setSideNav(!hideSideNav)}
            >
              <ChevronLeft />
            </button>
            <h1 className="mg-x-10 mg-b-0 tx-16 tx-uppercase tx-normal">
              Templates &gt; {nav.label}
            </h1>
            <Button
              color="primary"
              icon="Plus"
              iconClass="mg-r-5"
              name="New"
              className="mg-l-auto"
              data-toggle="modal"
              data-target={`#new${nav.label.replace(/\s/g, '')}Temp`}
            />
          </div>
          <div className="scroll-box pd-20">
            {!showEmailDetail && (
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Template Name
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Subject
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Purposes
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Priority
                        </th>
                        <th scope="col" className="wd-100 bd-t-0-f">
                          Create Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {nav.value !== 'email' && (
                            <a href="" data-toggle="modal" data-target="#previewTemp">
                              Template 1
                            </a>
                          )}
                          {nav.value === 'email' && (
                            <Button
                              color="link"
                              name="Template 1"
                              className="pd-0"
                              onClick={() => setShowEmailDetail(true)}
                            />
                          )}
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>2022-01-10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card-footer pd-y-6-f pagination">
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="move to first"
                  >
                    {Icon('move_first')}
                    <span className="sr-only">처음으로</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="previous"
                  >
                    <ChevronLeft />
                    <span className="sr-only">이전</span>
                  </button>
                  <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
                    <ChevronRight />
                    <span className="sr-only">다음</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="move to last"
                  >
                    {Icon('move_last')}
                    <span className="sr-only">마지막으로</span>
                  </button>
                  <input type="number" className="paging-input" defaultValue="1" />/ 1
                </div>
              </div>
            )}
            {showEmailDetail && (
              <div className="card">
                <div className="card-header pd-10">
                  <Button color="link" icon="ArrowLeft" onClick={() => setShowEmailDetail(false)} />
                  <h2 className="h6 mg-0">Template 1</h2>
                </div>
                <div className="row no-gutters">
                  <div className="col-3">
                    <div className="view-aside-section">
                      <div className="view-aside-section-header">
                        <div className="view-aside-section-header-title">Summary</div>
                      </div>
                      <div className="view-aside-section-body">
                        <div className="form-group">
                          <label className="form-item-title">Name</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Language</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Product</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Description</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Owner</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Stage</label>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="pd-20">
                      <div className="view-content-section card">&nbsp;</div>
                      <div className="view-content-section">
                        <div className="view-content-section-header">
                          <div className="view-aside-section-header-title">Note</div>
                        </div>
                        <div style={{ margin: '-20px' }}>
                          <Comment />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <NewTaskTemp />
      <NewCallTemp />
      <NewMeetingTemp />
      <NewDirectMailTemp />
      <NewSMSTemp />
      <PreviewTemp />
      <NewEmailTemp />
    </>
  );
};

export default Templates;
