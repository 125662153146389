import { ChevronDown, Minus, Plus, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import { Select as SelectHook } from '@base/components/form';

const DigitalContents = () => {
  return (
    <div
      id="digitalContensModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Digital Contents</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="table-responsive bg-white bd rounded-top">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" colSpan={2} className="bd-t-0-f">
                      Content Source
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Active
                    </th>
                    <th scope="col" className="wd-70 bd-t-0-f">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th rowSpan={3} className="tx-normal">
                      Owned Content
                    </th>
                    <td>Blog</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="digitalContentsSwitch1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="digitalContentsSwitch1"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="digitalContentsSwitch2"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="digitalContentsSwitch2"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Video</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="digitalContentsSwitch3"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="digitalContentsSwitch3"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan={3} className="bd-b-0-f tx-normal">
                      In CRM
                    </th>
                    <td>Landing Page</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="digitalContentsSwitch4"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="digitalContentsSwitch4"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Offer Site</td>
                    <td>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="digitalContentsSwitch5"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="digitalContentsSwitch5"
                        ></label>
                      </div>
                    </td>
                    <td>
                      <button type="button" className="btn btn-icon pd-0">
                        <X className="tx-danger" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
              <SelectHook
                className="wd-150-f flex-shrink-0"
                options={[{ value: 1, label: 'External' }]}
              />
              <input
                type="text"
                className="form-control mg-x-5"
                placeholder="Enter the source name"
              />
              <button type="button" className="btn btn-primary flex-shrink-0">
                <Plus className="mg-r-5" />
                Add
              </button>
            </div>
            <div className="mg-t-10 mg-b-20">
              [In CRM]File Location: Marketing Resources/Online Digital Contents
            </div>

            <div className="form-group">
              <label className="form-item-title">Implementing Distribution/Platform</label>
              <div className="table-responsive bg-white bd rounded">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Media Type
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Platform
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Campaign Activity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Search Engine</td>
                      <td>
                        <div className="dropdown">
                          <div className="input-group">
                            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                              <div className="form-control-tag">
                                <span className="form-control-tag-icon">{Icon('google')}</span>
                                <span className="form-control-tag-name">Google</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <div className="form-control-tag">
                                <span className="form-control-tag-icon">{Icon('naver')}</span>
                                <span className="form-control-tag-name">Naver</span>
                                <button
                                  type="button"
                                  className="btn pd-0 form-control-tag-remove"
                                  aria-label="삭제"
                                >
                                  <X />
                                </button>
                              </div>
                              <input type="text" className="bd-0 flex-grow-1 mn-wd-250 rounded" />
                            </div>
                            <div className="input-group-append">
                              <button type="button" className="btn" data-toggle="dropdown">
                                <ChevronDown />
                                <span className="sr-only">선택 옵션 열기</span>
                              </button>
                            </div>
                          </div>
                          <div className="dropdown-menu wd-100p">
                            <button type="button" className="dropdown-item">
                              <span className="dropdown-item-icon">{Icon('duckduckgo')}</span>
                              DuckDuckGo
                            </button>
                            <button type="button" className="dropdown-item">
                              <span className="dropdown-item-icon">{Icon('daum')}</span>Daum
                            </button>
                            <button type="button" className="dropdown-item">
                              <span className="dropdown-item-icon">{Icon('nate')}</span>Nate
                            </button>
                            <button type="button" className="dropdown-item">
                              <span className="dropdown-item-icon">{Icon('yahoo')}</span>Yahoo
                            </button>
                            <button type="button" className="dropdown-item">
                              <span className="dropdown-item-icon">{Icon('zum')}</span>Zum
                            </button>
                            <button type="button" className="dropdown-item">
                              <span className="dropdown-item-icon">{Icon('bing')}</span>Bing
                            </button>
                            <button type="button" className="dropdown-item">
                              <span className="dropdown-item-icon">{Icon('baidu')}</span>Baidu
                            </button>
                            <div className="dropdown-divider"></div>
                            <div className="d-flex align-items-center pd-x-10">
                              <input type="text" className="form-control mg-r-5" />
                              <button type="button" className="btn btn-primary flex-shrink-0">
                                <Plus className="mg-r-5" />
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <ul className="list-unstyled mg-0">
                          <li className="text-nowrap">SEO</li>
                          <li className="text-nowrap">Paid Search Engine</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>External Site</td>
                      <td>
                        <div className="d-flex align-items-center mg-b-10">
                          <input
                            type="text"
                            className="form-control wd-300 mg-r-10"
                            placeholder="site name"
                            defaultValue="Site A"
                          />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                        </div>
                        <div className="d-flex align-items-center mg-b-10">
                          <input
                            type="text"
                            className="form-control wd-300 mg-r-10"
                            placeholder="site name"
                          />
                          <button type="button" className="btn btn-link btn-icon">
                            <Plus />
                            <span className="sr-only">추가</span>
                          </button>
                          <button type="button" className="btn  btn-icon">
                            <Minus className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </td>
                      <td>
                        <ul className="list-unstyled mg-0">
                          <li className="text-nowrap">Paid Influencer</li>
                          <li className="text-nowrap">Free Back Link</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-lg btn-success">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalContents;
