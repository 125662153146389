import React, { useState } from 'react';

import { Calendar, ChevronDown, Minus, Plus, Settings, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

// import "CrmCss/desktop/theme1/theme1.scss"

const Write = () => {
  const [discountMethod, setDiscountMethod] = useState<any>({
    percentage: true,
    amount: false,
  });

  const [allCustomer, setAllCustomer] = useState<any>(true);
  const [specificCustomer, setSpecificCustomer] = useState<any>();

  return (
    <div className="form-row">
      <div className="form-group col-12">
        <label className="form-item-title">Trade Agreement Name</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group col-6">
        <label className="d-block form-item-title">Trade Agreement ID</label>
        <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <input type="text" className="form-control" />
          <div className="input-group-append">
            <button
              type="button"
              className="btn"
              aria-label="open generator of code modal"
              data-toggle="modal"
              data-target="#productGenCodeModal"
            >
              <Settings />
              <span className="sr-only">코드 생성 설정 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Active</label>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="taActiveSwitch"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="taActiveSwitch"></label>
        </div>
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Price List</label>
        <SelectHook />
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Start Date</label>
        <DatePicker
          selected={new Date()}
          onChange={() => {
            console.log();
          }}
        />
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">End Date</label>
        <DatePicker
          selected={new Date()}
          onChange={() => {
            console.log();
          }}
        />
        <div className="custom-control custom-switch mg-t-5">
          <input type="checkbox" className="custom-control-input" id="salesEndDateSwitch" />
          <label className="custom-control-label" htmlFor="salesEndDateSwitch">
            No End Date
          </label>
        </div>
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Payment Terms</label>
        <SelectHook options={[{ value: 1, label: 'Due on Receipt' }]} />
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Tax</label>
        <SelectHook />
      </div>
      <div className="form-group col-6">
        <label className="form-item-title d-block">Delivery Lead Time</label>
        <div className="d-flex flex-wrap align-items-center">
          <input type="number" className="form-control wd-150 mg-r-5" />
          <SelectHook className="wd-100-f" options={[{ value: 1, label: 'day(s)' }]} />
        </div>
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Delivery Method</label>
        <SelectHook />
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Courier</label>
        <SelectHook />
      </div>
      <div className="form-group col-12">
        <label className="form-item-title">Terms and conditions</label>
        <textarea rows={3} cols={10} className="form-control"></textarea>
      </div>
      <div className="form-group col-12">
        <label className="form-item-title">Product Detail</label>
        <div className="table-responsive bd rounded-top">
          <table className="table table-bordered bd-0 mg-b-0 product-detail-tb">
            <thead className="text-center">
              <tr>
                <th rowSpan={2} scope="col" className="align-middle text-nowrap bd-t-0-f">
                  Product
                </th>
                <th rowSpan={2} scope="col" className="align-middle text-nowrap bd-t-0-f">
                  Item
                </th>
                <th rowSpan={2} scope="col" className="align-middle text-nowrap bd-t-0-f">
                  Unit
                </th>
                <th rowSpan={2} scope="col" className="align-middle text-nowrap bd-t-0-f">
                  Price
                </th>
                <th
                  colSpan={4}
                  scope="colgroup"
                  className="pd-y-10-f text-nowrap bd-t-0-f"
                  style={{ borderBottomWidth: '1px' }}
                >
                  Volume Discount
                </th>
                <th rowSpan={2} scope="col" className="align-middle text-nowrap bd-t-0-f">
                  Delete
                </th>
              </tr>
              <tr>
                <th scope="col" className="pd-y-10-f text-nowrap">
                  Quantity
                </th>
                <th scope="col" className="pd-y-10-f text-nowrap" colSpan={2}>
                  Discount
                </th>
                <th scope="col" className="pd-y-10-f text-nowrap">
                  Unit Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2} className="align-middle">
                  <div className="input-group mn-wd-200">
                    <div className="form-control form-control-lg pd-y-5 bd-r-0">
                      <input
                        type="text"
                        className="wd-100p bd-0"
                        placeholder="Type or click to select a product"
                      />
                    </div>
                    <span className="input-group-append">
                      <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                        {Icon('product')}
                      </button>
                    </span>
                  </div>
                </td>
                <td rowSpan={2} className="align-middle">
                  <div className="input-group mn-wd-200">
                    <div className="form-control form-control-lg pd-y-5 bd-r-0">
                      <input
                        type="text"
                        className="wd-100p bd-0"
                        placeholder="Type or click to select an item"
                      />
                    </div>
                    <span className="input-group-append">
                      <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                        {Icon('product')}
                      </button>
                    </span>
                  </div>
                </td>
                <td rowSpan={2} className="align-middle">
                  <input type="text" className="form-control wd-100" />
                </td>
                <td rowSpan={2} className="align-middle">
                  <input type="number" className="form-control wd-100" />
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <input type="number" className="form-control wd-100 mg-r-5" /> ~{' '}
                    <input type="number" className="form-control wd-100 mg-l-5" />
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <Plus />
                      <span className="sr-only">추가</span>
                    </button>
                  </div>
                </td>
                <td rowSpan={2} className="align-middle">
                  <div className="dropdown">
                    <div className="input-group wd-150" data-toggle="dropdown">
                      <input
                        type="text"
                        className="form-control"
                        value={discountMethod.percentage ? 'Percentage' : 'Amount'}
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn">
                          <ChevronDown />
                        </button>
                      </div>
                    </div>
                    <div className="dropdown-menu">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setDiscountMethod({ percentage: true })}
                      >
                        Percentage
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setDiscountMethod({ amount: true })}
                      >
                        Amount
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-group wd-150">
                    {discountMethod.amount && (
                      <div className="input-group-prepend">
                        <span className="input-group-text lh-1">＄</span>
                      </div>
                    )}
                    <input type="number" className="form-control" />
                    {discountMethod.percentage && (
                      <div className="input-group-append">
                        <span className="input-group-text lh-1">%</span>
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
                <td rowSpan={2}>
                  <button type="button" className="btn btn-icon pd-0">
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <input type="number" className="form-control wd-100 mg-r-5" /> ~{' '}
                    <input type="number" className="form-control wd-100 mg-l-5" />
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <Plus />
                      <span className="sr-only">추가</span>
                    </button>
                    <button type="button" className="btn btn-icon pd-y-0">
                      <Minus className="tx-danger " />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                </td>
                <td>
                  <div className="input-group wd-150">
                    {discountMethod.amount && (
                      <div className="input-group-prepend">
                        <span className="input-group-text lh-1">＄</span>
                      </div>
                    )}
                    <input type="number" className="form-control" />
                    {discountMethod.percentage && (
                      <div className="input-group-append">
                        <span className="input-group-text lh-1">%</span>
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <input type="number" className="form-control" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pd-10 bd bd-t-0 rounded-bottom">
          <div className="input-group">
            <div className="form-control form-control-lg pd-y-5 bd-r-0">
              <input
                type="text"
                className="wd-100p bd-0"
                placeholder="Type or click to select an item"
              />
            </div>
            <span className="input-group-append">
              <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                {Icon('product')}
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="form-col col-12 form-group">
        <label className="form-item-title d-block">Threshold Discount</label>
        <div className="table-responsive bd rounded">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Amount
                </th>
                <th scope="col" colSpan={2} className="bd-t-0-f">
                  Discount
                </th>
                <th scope="col" className="wd-70 bd-t-0-f">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="number" className="form-control" />
                </td>
                <td>
                  <div className="dropdown">
                    <div className="input-group" data-toggle="dropdown">
                      <input
                        type="text"
                        className="form-control"
                        value={discountMethod.percentage ? 'Percentage' : 'Amount'}
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn">
                          <ChevronDown />
                        </button>
                      </div>
                    </div>
                    <div className="dropdown-menu">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setDiscountMethod({ percentage: true })}
                      >
                        Percentage
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setDiscountMethod({ amount: true })}
                      >
                        Amount
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-group wd-150">
                    {discountMethod.amount && (
                      <div className="input-group-prepend">
                        <span className="input-group-text lh-1">＄</span>
                      </div>
                    )}
                    <input type="number" className="form-control" />
                    {discountMethod.percentage && (
                      <div className="input-group-append">
                        <span className="input-group-text lh-1">%</span>
                      </div>
                    )}
                  </div>
                </td>
                <td align="center">
                  <button type="button" className="btn btn-icon pd-0">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button type="button" className="btn btn-link">
          <Plus className="mg-r-5" />
          Add
        </button>
      </div>
      <div className="form-col col-12 form-group">
        <label className="form-item-title d-block">Customer</label>
        {allCustomer && (
          <div className="d-inline-block pd-x-15 pd-y-5 bg-gray-100 bd bd-gray-200 rounded-20">
            All Customers
            <button
              type="button"
              className="btn bt-icon mg-l-10 pd-0"
              onClick={() => setAllCustomer(false)}
            >
              <X />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        )}
        {!allCustomer && (
          <>
            <div className="table-responsive bd rounded-top">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Name
                    </th>
                    <th scope="col" className="wd-70 bd-t-0-f">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {specificCustomer && (
                    <tr>
                      <td>
                        <span className="d-inline-block avatar avatar-sm mg-r-5">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                            className="rounded-circle"
                            alt=""
                          />
                        </span>
                        Adventure Works
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-icon pd-0"
                          onClick={() => setSpecificCustomer(false)}
                        >
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pd-10 bd bd-t-0 rounded-bottom">
              <div className="dropdown">
                <div className="input-group" data-toggle="dropdown">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type or click to select a customer"
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a customer"
                    >
                      {Icon('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
                <ul className="dropdown-menu wd-100p">
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => setAllCustomer(true)}
                    >
                      All Customers
                    </button>
                  </li>
                  <li className="dropdown-divider"></li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => setSpecificCustomer(true)}
                    >
                      <span className="d-inline-block avatar avatar-sm mg-r-5">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                          className="rounded-circle"
                          alt=""
                        />
                      </span>
                      Adventure Works
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Write;
