import { Button, Input } from '@base/components/form';
import { X } from 'react-feather';
import AssignmentType from './containers/assignment-type';
import Due from './containers/due';
import Stage from './containers/stage';

const StepStatus = () => {
  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Step Type</label>
        <div>Checklist</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Step Name</label>
        <Input />
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Stage</label>
        <Stage className="wd-300-f" />
      </div>
      <AssignmentType />
      <Due />
      <div className="form-group">
        <label className="form-item-title">Checklist</label>
        <ul className="list-group">
          <li className="list-group-item bg-transparent d-flex align-items-center">
            Identify Decision Makers
            <button type="button" className="btn mg-l-auto pd-0">
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </li>
          <li className="list-group-item bg-transparent d-flex align-items-center">
            <div className="d-flex align-items-center wd-100p">
              <Input />
              <Button color="primary" icon="Plus" name="Add" className="flex-shrink-0 mg-l-10" />
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default StepStatus;
