import { useState } from 'react';
import { RadioGroup, Select } from '@base/components/form';

const MODEOPTIONS = [
  { value: 'portrait', label: 'Portrait' },
  { value: 'landscape', label: 'Landscape' },
];

const GRIDOPTIONS = [
  { value: 'show', label: 'Show' },
  { value: 'never', label: 'Never' },
];

const RightAside = () => {
  const [mode, setMode] = useState(MODEOPTIONS);
  const [selectedMode, setSelectedMode] = useState(mode[0].value);
  //change
  const handleModeChange = (newOption: any) => {
    setSelectedMode(newOption.value);
  };

  const [grid, setGrid] = useState(GRIDOPTIONS);
  const [selectedGrid, setSelectedGrid] = useState(grid[0].value);
  //change
  const handleGridChange = (newOption: any) => {
    setSelectedGrid(newOption.value);
  };

  return (
    <>
      <div className="card card-body ht-100p bg-light">
        <div className="form-group">
          <label className="form-item-title">Display Mode</label>
          <RadioGroup options={mode} value={selectedMode} onChange={handleModeChange} />
        </div>
        <div className="form-group">
          <label className="form-item-title">Display Grid</label>
          <RadioGroup options={grid} value={selectedGrid} onChange={handleGridChange} />
        </div>
        <div className="form-group">
          <label className="form-item-title">Section</label>
          <Select />
        </div>
        <div className="form-group">
          <label className="form-item-title">Chart</label>
          <Select />
        </div>
      </div>
    </>
  );
};

export default RightAside;
