import { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronUp,
  User,
  X,
  Monitor,
  ChevronDown,
  RotateCcw,
  RotateCw,
  Type,
  Image,
  PlayCircle,
  List,
  ArrowUp,
  Move,
  Copy,
  Trash,
  Plus,
  Download,
  MapPin,
  BookOpen,
  MessageCircle,
  Clock,
  Map,
} from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'Basic Info',
  },
  {
    value: 2,
    label: 'Design',
  },
];

const PAGES = [
  {
    value: 1,
    label: 'Home',
  },
  {
    value: 2,
    label: 'Agenda',
  },
  {
    value: 3,
    label: 'Speaker',
  },
  {
    value: 4,
    label: 'Ticket',
  },
  {
    value: 5,
    label: 'Registration',
  },
];

const SUBPAGES = [
  {
    value: 1,
    label: 'Sign up',
  },
  {
    value: 2,
    label: 'Question',
  },
  {
    value: 3,
    label: 'Purchase',
  },
  {
    value: 4,
    label: 'Submit',
  },
  {
    value: 5,
    label: 'Confirm and Check in',
  },
];

const DESIGNTABS = [
  {
    value: 1,
    label: 'Designer',
  },
  {
    value: 2,
    label: 'HTML',
  },
  {
    value: 3,
    label: 'Preview',
  },
];

const NewEventSite = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [designTab, setDesignTab] = useState(DESIGNTABS[0]);
  const [pages, setPages] = useState(PAGES[0]);
  const [subpages, setSubpages] = useState(SUBPAGES[0]);
  const [showApproval, setShowApproval] = useState(false);

  return (
    <div
      id="newEventSiteModal"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">New Event Site</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body d-flex flex-column overflow-hidden pd-0">
            {/* steps */}
            <ul className="steps steps-justified steps-tab">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  className={classnames('step-item', {
                    complete: item.value < tab.value,
                    active: item.value == tab.value,
                    disabed: item.value > tab.value,
                  })}
                >
                  <button
                    type="button"
                    className="btn step-link rounded-0"
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    <span className="step-number">{item.value}</span>
                    <span className="step-title">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>

            {tab.value == 1 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="form-group">
                  <label className="form-item-title">Event Site name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Event Site Type</label>
                  <SelectHook className="wd-300-f" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Language</label>
                  <SelectHook className="wd-200-f" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0xJV22ugqnu5lwu_NoaL1CeW2SZCx4YbuLQ&amp;usqp=CAU"
                            alt=""
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">Product 111</span>
                        <button type="button" className="btn pd-0 form-control-tag-remove">
                          <X />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250"
                        placeholder="Type or click to select a product"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a product"
                      >
                        {Icon('product')}
                        <span className="sr-only">상품 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <textarea rows={3} cols={10} className="form-control"></textarea>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Approval</label>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="eventSiteApprovalSwitch"
                      onClick={() => setShowApproval(!showApproval)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="eventSiteApprovalSwitch"
                    ></label>
                  </div>
                </div>
                {showApproval && (
                  <div className="form-group">
                    <label className="form-item-title">Assigned Rep</label>
                    <div className="input-group">
                      <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                        <div className="form-control-tag">
                          <span className="d-block avatar avatar-xs mg-r-10">
                            <img
                              src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                              alt="John"
                              className="img rounded-circle"
                            />
                          </span>
                          <span className="form-control-tag-name">John</span>
                          <button
                            type="button"
                            className="btn pd-0 form-control-tag-remove"
                            aria-label="삭제"
                          >
                            <X />
                          </button>
                        </div>
                        <input
                          type="text"
                          className="bd-0 flex-grow-1 mn-wd-250"
                          placeholder="Type or click to select an user"
                        />
                      </div>
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open modal to select an user"
                        >
                          <User />
                          <span className="sr-only">담당자 선택 팝업 열기</span>
                        </button>
                      </div>
                    </div>
                    <div className="custom-control custom-checkbox mg-t-5">
                      <input type="checkbox" className="custom-control-input" id="myworkCheck" />
                      <label className="custom-control-label" htmlFor="myworkCheck">
                        My work listing for assigned user/group
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
            {tab.value == 2 && (
              <div className="d-flex flex-column pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="row row-xs">
                  <div className="col-lg-6">
                    <div className="dropdown">
                      <div className="input-group mg-b-10" data-toggle="dropdown">
                        <div className="input-group-prepend">
                          <span className="input-group-text tx-13">Page:</span>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-lg bd-r-0-f"
                          value={pages.label}
                        />
                        <div className="input-group-append">
                          <button type="button" className="btn">
                            <ChevronDown />
                          </button>
                        </div>
                      </div>
                      <div className="dropdown-menu wd-100p">
                        {PAGES.map((item, index) => (
                          <button
                            key={index}
                            type="button"
                            className="dropdown-item"
                            onClick={(e) => setPages(item)}
                          >
                            {item.label}
                            <span className="float-right badge badge-light tx-13 tx-normal">
                              Prepare
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  {pages.value == 5 && (
                    <div className="col-lg-6">
                      <div className="dropdown">
                        <div className="input-group mg-b-10" data-toggle="dropdown">
                          <div className="input-group-prepend">
                            <span className="input-group-text tx-13">Page:</span>
                          </div>
                          <input
                            type="text"
                            className="form-control form-control-lg bd-r-0-f"
                            value={subpages.label}
                          />
                          <div className="input-group-append">
                            <button type="button" className="btn">
                              <ChevronDown />
                            </button>
                          </div>
                        </div>
                        <div className="dropdown-menu wd-100p">
                          {SUBPAGES.map((item, index) => (
                            <button
                              key={index}
                              type="button"
                              className="dropdown-item"
                              onClick={(e) => setSubpages(item)}
                            >
                              {item.label}
                              <span className="float-right badge badge-light tx-13 tx-normal">
                                Prepare
                              </span>
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex-grow-1 bd rounded overflow-auto">
                  <div className="row no-gutters ht-100p overflow-auto">
                    <div
                      className={classnames(
                        'd-flex flex-column ht-100p',
                        { 'col-lg-7 col-xl-8': designTab.value == 1 },
                        { 'col-12': designTab.value != 1 },
                      )}
                    >
                      <div className="d-flex align-items-center bd-b">
                        {/* tabs */}
                        <ul className="nav nav-line bd-0 flex-grow-1">
                          {DESIGNTABS.map((item, index) => (
                            <li className="nav-item" key={index}>
                              <button
                                type="button"
                                className={classnames('btn nav-link pd-x-10-f', {
                                  active: item.value == designTab.value,
                                })}
                                onClick={(e) => {
                                  setDesignTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </li>
                          ))}
                        </ul>
                        {designTab.value == 1 && (
                          <>
                            <button type="button" className="btn btn-sm btn-primary rounded-30">
                              Active
                            </button>
                            <div className="btn-group">
                              <button
                                type="button"
                                data-han-tooltip="Undo"
                                className="btn btn-icon han-tooltip"
                              >
                                <RotateCcw />
                                <span className="sr-only">실행취소</span>
                              </button>
                              <button
                                type="button"
                                data-han-tooltip="Redo"
                                className="btn btn-icon han-tooltip"
                              >
                                <RotateCw />
                                <span className="sr-only">재실행</span>
                              </button>
                              <button
                                type="button"
                                data-han-tooltip="Clear Canvas"
                                className="btn btn-icon han-tooltip"
                              >
                                <Trash />
                                <span className="sr-only">초기화</span>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="flex-grow-1 overflow-auto">
                        {designTab.value == 1 && <></>}
                        {designTab.value == 2 && <div></div>}
                        {designTab.value == 3 && (
                          <div className="d-flex ht-100p">
                            <div className="wd-80 flex-shrink-0 design-preview-btns overflow-auto">
                              <button type="button" className="btn wd-100p ht-80 bd-b">
                                <div>Desktop</div>
                                <Monitor />
                              </button>
                              <button type="button" className="btn wd-100p ht-80 bd-b active">
                                <div>Tablet</div>
                                {Icon('tablet')}
                              </button>
                              <button type="button" className="btn wd-100p ht-80">
                                <div>Mobile</div>
                                {Icon('smartphone')}
                              </button>
                            </div>
                            <div className="flex-grow-1 overflow-auto pd-20 bd-l">
                              {/* Tablet */}
                              <div
                                className="shadow"
                                style={{
                                  width: '828px',
                                  height: '1084px',
                                  margin: '0 auto',
                                  padding: '30px',
                                  borderRadius: '15px',
                                  border: '1px solid #cccccc',
                                }}
                              >
                                <div className="ht-100p overflow-auto bd"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {designTab.value == 1 && pages.value == 5 && (
                        <div className="pd-5 bd-t text-right">
                          <button type="button" className="btn btn-outline-primary">
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                    {designTab.value == 1 && (
                      <div className="col-lg-5 col-xl-4 d-flex flex-column ht-100p bd-l">
                        <ul className="nav nav-line bd-1">
                          <li className="nav-item">
                            <button
                              type="button"
                              data-target="#designToolbox"
                              className="btn nav-link pd-x-10-f active"
                              data-toggle="tab"
                            >
                              Toolbox
                            </button>
                          </li>
                          <li className="nav-item mg-l-0-f">
                            <button
                              type="button"
                              data-target="#designProperties"
                              className="btn nav-link pd-x-10-f"
                              data-toggle="tab"
                            >
                              Properties
                            </button>
                          </li>
                          <li className="nav-item mg-l-0-f">
                            <button
                              type="button"
                              data-target="#designStyle"
                              className="btn nav-link pd-x-10-f"
                              data-toggle="tab"
                            >
                              Styles
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content flex-grow-1 overflow-auto">
                          <div id="designToolbox" className="tab-pane fade show active">
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designExtra"
                            >
                              Extra
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designExtra" className="collapse fade show bd-b">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('slider')}
                                  <div className="ele-name">Slider</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('tab')}
                                  <div className="ele-name">Tabs</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('navigation_bar')}
                                  <div className="ele-name">Navigation</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('typed')}
                                  <div className="ele-name">Typed</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <MessageCircle />
                                  <div className="ele-name">Tooltip</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Clock />
                                  <div className="ele-name">Countdown</div>
                                </button>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designLayout"
                            >
                              Layout
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designLayout" className="collapse fade show bd-b">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column1')}
                                  <div className="ele-name">1 Column</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2')}
                                  <div className="ele-name">2 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column3')}
                                  <div className="ele-name">3 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2_2')}
                                  <div className="ele-name">3:7 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2_3')}
                                  <div className="ele-name">7:3 Columns</div>
                                </button>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designElement"
                            >
                              Elements
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designElement" className="collapse fade show">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('heading')}
                                  <div className="ele-name">Heading</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Type />
                                  <div className="ele-name">Text</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Image />
                                  <div className="ele-name">Image</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <PlayCircle />
                                  <div className="ele-name">Video</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Map />
                                  <div className="ele-name">Map</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('button')}
                                  <div className="ele-name">Button</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('divider')}
                                  <div className="ele-name">Divider</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('table')}
                                  <div className="ele-name">Table</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <List />
                                  <div className="ele-name">List</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('cta')}
                                  <div className="ele-name">CTA</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('personalize_code')}
                                  <div className="ele-name">Personalize</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('form')}
                                  <div className="ele-name">Form</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('event')}
                                  <div className="ele-name">Event</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('survey')}
                                  <div className="ele-name">Survey</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('option_set')}
                                  <div className="ele-name">Option Set</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('social_share')}
                                  <div className="ele-name">Social Share</div>
                                </button>

                                {/* Offer type : Download */}
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Download />
                                  <div className="ele-name">Download Link</div>
                                </button>

                                {/* Offer type : DM */}
                                <button type="button" className="btn btn-white shadow-sm">
                                  <MapPin />
                                  <div className="ele-name">Address</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <BookOpen />
                                  <div className="ele-name">Package</div>
                                </button>

                                {/* Offer type : Special Offer */}
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('coupon')}
                                  <div className="ele-name">Coupon</div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="designProperties" className="tab-pane fade">
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designCTA"
                            >
                              Coupon
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designCTA" className="collapse fade show pd-15 bd-b">
                              <div className="form-group">
                                <label className="form-item-title">Name</label>
                                <SelectHook options={[{ value: 1, label: 'Coupon 1' }]} />
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Image</label>
                                <div>&nbsp;</div>
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Barcode</label>
                                <div>&nbsp;</div>
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Spending Rule</label>
                                <div>&nbsp;</div>
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Other</label>
                                <div>&nbsp;</div>
                              </div>
                            </div>

                            {/* image type property */}
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designIMG"
                            >
                              Image
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designIMG" className="collapse fade show pd-15">
                              <div className="form-group">
                                <label className="form-item-title d-block">Type</label>
                                <div className="btn-group img-type-list">
                                  <button
                                    type="button"
                                    className="btn btn-white active han-tooltip"
                                    data-han-tooltip="Image only"
                                  >
                                    <Image />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image on top"
                                  >
                                    {Icon('img_on_top')}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image on bottom"
                                  >
                                    {Icon('img_on_bottom')}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image on left"
                                  >
                                    {Icon('img_on_left')}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-white han-tooltip"
                                    data-han-tooltip="Image on right"
                                  >
                                    {Icon('img_on_right')}
                                  </button>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Source</label>
                                <SelectHook />
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Alt Text</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div id="designStyle" className="tab-pane fade"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {designTab.value == 1 && (
                  <button
                    type="button"
                    className="btn btn-sm btn-link align-self-start pd-x-0"
                    data-toggle="modal"
                    data-target="#selSampleModal"
                  >
                    Change the sample
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {tab.value == 1 && (
                  <button type="button" className="dropdown-item">
                    Save and Next
                  </button>
                )}
                {tab.value == 2 && (
                  <>
                    <button type="button" className="dropdown-item">
                      Save and Finish
                    </button>
                    <button type="button" className="dropdown-item">
                      Save and Submit for Approval
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEventSite;
