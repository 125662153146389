/* eslint-disable prettier/prettier */
import { useState } from 'react';
import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  ChevronDown,
  Plus,
  Printer,
  Settings,
  User,
  X,
} from 'react-feather';
import { Button, Input, RadioGroup, Select, Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss";

const Inventory = () => {
  const [showRowNumeric, setShowRowNumeric] = useState('numeric');
  const [showRackNumeric, setShowRackNumeric] = useState('alphabetic');
  const [showLevelNumeric, setShowLevelNumeric] = useState('alphabetic');
  const [showBinNumeric, setShowBinNumeric] = useState('alphabetic');
  const [showBatchReserveMethod, setShowBatchReserveMethod] = useState('fefo');
  const [showPickingMethod, setShowPickingMethod] = useState('cluster');
  const [showitemLimit, setShowitemLimit] = useState('specific');

  const handleRowNumeric = (e: any) => {
    const { value } = e.target;
    setShowRowNumeric(value);
  };
  const handleRackNumeric = (e: any) => {
    const { value } = e.target;
    setShowRackNumeric(value);
  };
  const handleLevelNumeric = (e: any) => {
    const { value } = e.target;
    setShowLevelNumeric(value);
  };
  const handleBinNumeric = (e: any) => {
    const { value } = e.target;
    setShowBinNumeric(value);
  };
  const handleBatchReserveMethod = (e: any) => {
    const { value } = e.target;
    setShowBatchReserveMethod(value);
  };
  const handlePickingMethod = (e: any) => {
    const { value } = e.target;
    setShowPickingMethod(value);
  };
  const handleitemLimit = (e: any) => {
    const { value } = e.target;
    setShowitemLimit(value);
  };

  const SKU_OPTIONS = [
    { value: 1, label: 'Auto' },
    { value: 2, label: 'Manual' },
  ];
  const [SKUOptions, setSKUOptions] = useState(SKU_OPTIONS);
  const [selectedSKUOption, setSelectedSKUOption] = useState(SKU_OPTIONS[0]);
  //change
  const handleSKUOptionChange = (newOption: any) => {
    setSelectedSKUOption(newOption);
  };

  const SKU_VALUES = [
    { value: 1, label: 'Item/Cpmponent Name' },
    { value: 2, label: 'Unit Name' },
    { value: 3, label: 'Attribute-Value 1' },
    { value: 4, label: 'Attribute-Value 2' },
    { value: 5, label: 'Warehouse Name' },
    { value: 6, label: 'Custom Text' },
  ];
  const [SKUValue, setSKUValue] = useState(SKU_VALUES);
  const [selectedSKUValue, setSelectedSKUValue] = useState(SKU_VALUES[0]);
  const handleValueChange = (newOption: any) => {
    setSelectedSKUValue(newOption);
  };

  const BATCH_OPTIONS = [
    { value: 1, label: 'Auto' },
    { value: 2, label: 'Manual' },
  ];
  const [batchOptions, setBatchOptions] = useState(BATCH_OPTIONS);
  const [selectedBatchOption, setSelectedBatchOption] = useState(BATCH_OPTIONS[0]);
  //change
  const handleBatchOptionChange = (newOption: any) => {
    setSelectedBatchOption(newOption);
  };

  const BATCH_VALUES = [
    { value: 1, label: 'Item/Cpmponent Name' },
    { value: 2, label: 'Manufacture Date' },
    { value: 3, label: 'Experation Date' },
    { value: 4, label: 'Custom Text' },
  ];
  const [batchValue, setBatchValue] = useState(BATCH_VALUES);
  const [selectedBatchValue, setSelectedBatchValue] = useState(BATCH_VALUES[0]);
  const handleBatchValueChange = (newOption: any) => {
    setSelectedBatchValue(newOption);
  };

  const SERIAL_OPTIONS = [
    { value: 1, label: 'Auto' },
    { value: 2, label: 'Manual' },
  ];
  const [serialOptions, setSerialOptions] = useState(SERIAL_OPTIONS);
  const [selectedSerialOption, setSelectedSerialOption] = useState(SERIAL_OPTIONS[0]);
  //change
  const handleSerialOptionChange = (newOption: any) => {
    setSelectedSerialOption(newOption);
  };

  const SERIAL_VALUES = [
    { value: 1, label: 'Item/Cpmponent Name' },
    { value: 2, label: 'BOM Name' },
    { value: 3, label: 'Custom Text' },
  ];
  const [serialValue, setSerialValue] = useState(SERIAL_VALUES);
  const [selectedSerialValue, setSelectedSerialValue] = useState(SERIAL_VALUES[0]);
  const handleSerialValueChange = (newOption: any) => {
    setSelectedSerialValue(newOption);
  };

  return (
    <div className="card-columns settings-columns">
      {/* Notification */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Notification
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Send Notification to Assigned Reps.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryNotiActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="inventoryNotiActive"></label>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col">Notification</th>
                <th scope="col" className="wd-50">
                  Email
                </th>
                <th scope="col" className="wd-100">
                  SMS
                </th>
                <th scope="col" className="wd-100">
                  Notification
                </th>
                <th scope="col" className="wd-100">
                  Notify to
                </th>
                <th scope="col" className="wd-100">
                  Use
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Send notification if an item s quantity reaches below the reorder point</td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck1"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck1"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck2"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck2"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck3"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck3"></label>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryNotiUseSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryNotiUseSwitch1"
                    ></label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Send notification if an item s quantity reaches zero</td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck2-1"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck2-1"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck2-2"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck2-2"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck2-3"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck2-3"></label>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryNotiUseSwitch2"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryNotiUseSwitch2"
                    ></label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Send notification if an item s quantity reaches below the Inventory Threshold
                  <div className="mg-t-5 tx-orange">
                    Inventory Threshold 설정되어있지 않으면 알림이 발송되지 않습니다.
                  </div>
                  <div className="d-flex align-items-center mg-t-10">
                    <input type="number" className="form-control form-control-lg wd-100" />
                    <SelectHook
                      className="wd-100-f mg-l-10"
                      options={[
                        { value: 1, label: 'day(s)' },
                        { value: 2, label: 'month(s)' },
                        { value: 3, label: 'year(s)' },
                      ]}
                    />
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck3-1"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck3-1"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck3-2"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck3-2"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck3-3"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck3-3"></label>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryNotiUseSwitch3"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryNotiUseSwitch3"
                    ></label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Send notification if an item s Traceability No is not updated after the allocation
                  <div className="mg-t-5 tx-orange">
                    Traceability 설정되어있지 않으면 알림이 발송되지 않습니다.
                  </div>
                  <div className="d-flex align-items-center mg-t-10">
                    <input type="number" className="form-control form-control-lg wd-100" />
                    <SelectHook
                      className="wd-100-f mg-l-10"
                      options={[
                        { value: 1, label: 'day(s)' },
                        { value: 2, label: 'month(s)' },
                        { value: 3, label: 'year(s)' },
                      ]}
                    />
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck4-1"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck4-1"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck4-2"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck4-2"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck4-3"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck4-3"></label>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryNotiUseSwitch4"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryNotiUseSwitch4"
                    ></label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Send notification before the item s Experation Date/Best Before Date
                  <div className="mg-t-5 tx-orange">
                    아이템 Experation Date/Best Before Date 정보가 없으면 알림이 발송되지 않습니다.
                  </div>
                  <div className="d-flex align-items-center mg-t-10">
                    <input type="number" className="form-control form-control-lg wd-100" />
                    <SelectHook
                      className="wd-100-f mg-l-10"
                      options={[
                        { value: 1, label: 'day(s)' },
                        { value: 2, label: 'month(s)' },
                        { value: 3, label: 'year(s)' },
                      ]}
                    />
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck5-1"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck5-1"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck5-2"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck5-2"></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="inventoryNotiCheck5-3"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="inventoryNotiCheck5-3"></label>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryNotiUseSwitch5"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryNotiUseSwitch5"
                    ></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Warehouse and Location  */}
      <div className="card">
        <div className="card-header h6 bg-light">Warehouse and Location </div>
        <div className="card-body pd-0">
          <div className="pd-15 bd-b">
            <div className="d-flex alitn-items-center">
              Use Multi Warehouse
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="multiWarehouseUseSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="multiWarehouseUseSwitch1"></label>
              </div>
            </div>
            <div className="mg-t-5 tx-orange">
              여러 개의 창고를 생성하여 우선 순위가 높은 창고 아이템이 먼저 주문 및 배송됩니다.
            </div>
          </div>
          <div className="pd-15 bd-b">
            <div className="d-flex alitn-items-center">
              Use Multi Locations
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="multiLocationUseSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="multiLocationUseSwitch1"></label>
              </div>
            </div>
            <div className="mg-t-5 tx-orange">
              창고 내에 Item 재고를 보관을 위한 상세 영역을 생성합니다.
            </div>
          </div>
          <div className="pd-15 bd-b">
            <label>Location Simple Name-Letter Foramt</label>
            <div className="pd-x-20 pd-y-10 bg-light rounded">
              <div className="form-group">
                <label className="form-item-title d-block">Row</label>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="letterFormatRowRadio"
                      id="letterFormatRowRadio1"
                      value="alphabetic"
                      checked={showRowNumeric === 'alphabetic'}
                      onChange={(e) => handleRowNumeric(e)}
                    />
                    <label className="custom-control-label" htmlFor="letterFormatRowRadio1">
                      Alphabetic
                    </label>
                  </div>
                  <div className="d-flex align-items-center mg-r-10">
                    <div className="custom-control custom-radio mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterFormatRowRadio"
                        id="letterFormatRowRadio2"
                        value="numeric"
                        checked={showRowNumeric === 'numeric'}
                        onChange={(e) => handleRowNumeric(e)}
                      />
                      <label className="custom-control-label" htmlFor="letterFormatRowRadio2">
                        Numeric
                      </label>
                    </div>
                    {showRowNumeric === 'numeric' && (
                      <SelectHook
                        className="wd-150-f"
                        options={[
                          { value: 1, label: 'Select digit' },
                          { value: 2, label: '1' },
                          { value: 3, label: '01' },
                          { value: 4, label: '001' },
                          { value: 5, label: '0001' },
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-item-title d-block">Rack</label>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="letterFormatRackRadio"
                      id="letterFormatRackRadio1"
                      value="alphabetic"
                      checked={showRackNumeric === 'alphabetic'}
                      onChange={(e) => handleRackNumeric(e)}
                    />
                    <label className="custom-control-label" htmlFor="letterFormatRackRadio1">
                      Alphabetic
                    </label>
                  </div>
                  <div className="d-flex align-items-center mg-r-10">
                    <div className="custom-control custom-radio mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterFormatRackRadio"
                        id="letterFormatRackRadio2"
                        value="numeric"
                        checked={showRackNumeric === 'numeric'}
                        onChange={(e) => handleRackNumeric(e)}
                      />
                      <label className="custom-control-label" htmlFor="letterFormatRackRadio2">
                        Numeric
                      </label>
                    </div>
                    {showRackNumeric === 'numeric' && (
                      <SelectHook
                        className="wd-150-f"
                        options={[
                          { value: 1, label: 'Select digit' },
                          { value: 2, label: '1' },
                          { value: 3, label: '01' },
                          { value: 4, label: '001' },
                          { value: 5, label: '0001' },
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-item-title d-block">Level</label>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="letterFormatLevelRadio"
                      id="letterFormatLevelRadio1"
                      value="alphabetic"
                      checked={showLevelNumeric === 'alphabetic'}
                      onChange={(e) => handleLevelNumeric(e)}
                    />
                    <label className="custom-control-label" htmlFor="letterFormatLevelRadio1">
                      Alphabetic
                    </label>
                  </div>
                  <div className="d-flex align-items-center mg-r-10">
                    <div className="custom-control custom-radio mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterFormatLevelRadio"
                        id="letterFormatLevelRadio2"
                        value="numeric"
                        checked={showLevelNumeric === 'numeric'}
                        onChange={(e) => handleLevelNumeric(e)}
                      />
                      <label className="custom-control-label" htmlFor="letterFormatLevelRadio2">
                        Numeric
                      </label>
                    </div>
                    {showLevelNumeric === 'numeric' && (
                      <SelectHook
                        className="wd-150-f"
                        options={[
                          { value: 1, label: 'Select digit' },
                          { value: 2, label: '1' },
                          { value: 3, label: '01' },
                          { value: 4, label: '001' },
                          { value: 5, label: '0001' },
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-item-title d-block">Bin</label>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="letterFormatBinRadio"
                      id="letterFormatBinRadio1"
                      value="alphabetic"
                      checked={showBinNumeric === 'alphabetic'}
                      onChange={(e) => handleBinNumeric(e)}
                    />
                    <label className="custom-control-label" htmlFor="letterFormatBinRadio1">
                      Alphabetic
                    </label>
                  </div>
                  <div className="d-flex align-items-center mg-r-10">
                    <div className="custom-control custom-radio mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterFormatBinRadio"
                        id="letterFormatBinRadio2"
                        value="numeric"
                        checked={showBinNumeric === 'numeric'}
                        onChange={(e) => handleBinNumeric(e)}
                      />
                      <label className="custom-control-label" htmlFor="letterFormatBinRadio2">
                        Numeric
                      </label>
                    </div>
                    {showBinNumeric === 'numeric' && (
                      <SelectHook
                        className="wd-150-f"
                        options={[
                          { value: 1, label: 'Select digit' },
                          { value: 2, label: '1' },
                          { value: 3, label: '01' },
                          { value: 4, label: '001' },
                          { value: 5, label: '0001' },
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mg-t-5 tx-orange">
              설정에 따라 Storae Zone 내부를 - Row, Rack, Level, Bin으로 구분하고 알파벳/숫자로
              Location Simple Name을 자동 생성합니다.
              <br />
              E.g. Row=01 Rack = A Level= 01 Bin=A , Location ID =01-A-01-A
            </div>
          </div>
          <div className="pd-15">
            <div className="d-flex alitn-items-center">
              Use Layout
              <div className="custom-control custom-switch mg-l-auto">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="layoutUseSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="layoutUseSwitch1"></label>
              </div>
            </div>
            <div className="mg-t-5 tx-orange">
              창고 영역을 이미지로 출력할 수 있습니다. Multiple Location 설정 시에만 사용합니다.
            </div>
          </div>
        </div>
      </div>

      {/* Include Work Zone */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Include Work Zone
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              창고 내에 Work Type에 따른 기타 영역을 생성합니다.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryIncludeWorkZoneActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label
              className="custom-control-label"
              htmlFor="inventoryIncludeWorkZoneActive"
            ></label>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col">Zone Type</th>
                <th scope="col">Work Rep</th>
                <th scope="col">Use</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="Receiving Zone" />
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryZoneUseSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryZoneUseSwitch1"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Quality Assurance Zone"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryZoneUseSwitch2"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryZoneUseSwitch2"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="Put Awaty Zone" />
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryZoneUseSwitch3"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryZoneUseSwitch3"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="Packing Zone" />
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryZoneUseSwitch4"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryZoneUseSwitch4"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="Shipping Zone" />
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryZoneUseSwitch5"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryZoneUseSwitch5"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="Scraping Zone" />
                </td>
                <td>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">
                          John<span className="tx-gray-500">(Sales)</span>
                        </span>
                        <button
                          type="button"
                          className="btn pd-0 form-control-tag-remove"
                          aria-label="삭제"
                        >
                          <X />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a user"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a user"
                      >
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryZoneUseSwitch6"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryZoneUseSwitch6"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex">
          <input type="text" className="form-control mg-r-5" placeholder="Enter the Zone Type" />
          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* Multi Warehouse Shipment */}
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <span className="tx-16">Multi Warehouse Shipment</span>
            <div className="custom-control custom-switch mg-l-auto">
              <input
                id="inventoryMultiWarehouseShipmentActive"
                type="checkbox"
                className="custom-control-input"
                defaultChecked
              />
              <label
                className="custom-control-label"
                htmlFor="inventoryMultiWarehouseShipmentActive"
              ></label>
            </div>
          </div>
          <div className="mg-t-10 tx-orange">
            한 아이템이 두개 이상의 Warehouse에 위치하는 경우, 재고가 부족한 아이템은 자동으로 다른
            창고를 지정하여 Shipment Doc를 분리하여 생성합니다.
          </div>
          <div>
            e.g. 주문 수량 100개, Warehouse A(Prority 1) :사용가능 아이템 수량 70, Warehouse B(
            Prority 2): 사용 가능한 아이템 수량 100인 경우 Warehouse A에 70, Warehouse B에 30개에
            대한 Shipment Doc 자동 생성
          </div>
        </div>
      </div>

      {/* Batch Reserve Method */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Batch Reserve Method
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              하나의 아이템이 두개 이상의 Warehouse 나 Storage에 보관된 경우 Slaes Order 발생 시
              FIFO/FEFO 설정에 따라 자동으로 배정합니다.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryBatchReserveMethodActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label
              className="custom-control-label"
              htmlFor="inventoryBatchReserveMethodActive"
            ></label>
          </div>
        </div>
        <div className="card-body">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name="batchReserveMethodRadio"
              id="batchReserveMethodRadio1"
              value="fifo"
              checked={showBatchReserveMethod === 'fifo'}
              onChange={(e) => handleBatchReserveMethod(e)}
            />
            <label className="custom-control-label" htmlFor="batchReserveMethodRadio1">
              FIFO:Allocated Date
            </label>
          </div>
          <div className="mg-t-5 mg-l-25 tx-orange">
            Warehouse 입고일이 가종 오래된 아이템을 최우선 판매할 수 있도록 자동 배정
          </div>
          <div className="custom-control custom-radio mg-t-10">
            <input
              type="radio"
              className="custom-control-input"
              name="batchReserveMethodRadio"
              id="batchReserveMethodRadio2"
              value="fefo"
              checked={showBatchReserveMethod === 'fefo'}
              onChange={(e) => handleBatchReserveMethod(e)}
            />
            <label className="custom-control-label" htmlFor="batchReserveMethodRadio2">
              FEFO: Expiry Date or Best Before Date
            </label>
          </div>
          <div className="mg-t-5 mg-l-25 tx-orange">
            유통기한 또는 소비기한 만료일이 가장 짦은 아이템을 최우선 판매할 수 있도록 자동 배정
          </div>
          {showBatchReserveMethod === 'fefo' && (
            <div className="form-group mg-l-25">
              <label className="form-item-title">
                Reserve Disable before the Experation/Best Before Date
              </label>
              <div className="d-flex align-items-center">
                <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
                <SelectHook className="wd-100-f" options={[{ value: 1, label: 'day(s)' }]} />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Traceability */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Traceability
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              판매 아이템의 Lot(Batch) No 및 Serial No로 재고 추적이 가능합니다.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryTraceabilityActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="inventoryTraceabilityActive"></label>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label className="form-item-title">Tracking Type</label>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="inventoryTraceabilityCheck1"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="inventoryTraceabilityCheck1">
                Lot Batch Tracking Based on Expiry Date
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="inventoryTraceabilityCheck2"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="inventoryTraceabilityCheck2">
                Lot Batch Tracking Based on Best Before Date
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="inventoryTraceabilityCheck3"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="inventoryTraceabilityCheck3">
                Serial Number Tracking
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Picking  Method */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Picking Method
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              주문 아이템 준비할 수 있도록 피킹 방법을 설정합니다. 설정에 따라 담당자에 Picking
              List가 자동으로 생성됩니다.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryPickingMethodActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="inventoryPickingMethodActive"></label>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center flex-wrap">
            <div className="custom-control custom-radio mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="pickingMethodRadio"
                id="pickingMethodRadio1"
                value="single"
                checked={showPickingMethod === 'single'}
                onChange={(e) => handlePickingMethod(e)}
              />
              <label className="custom-control-label" htmlFor="pickingMethodRadio1">
                Single Order Picking
              </label>
            </div>
            <div className="d-flex align-items-center mg-r-10">
              <div className="custom-control custom-radio mg-r-10">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="pickingMethodRadio"
                  id="pickingMethodRadio2"
                  value="cluster"
                  checked={showPickingMethod === 'cluster'}
                  onChange={(e) => handlePickingMethod(e)}
                />
                <label className="custom-control-label" htmlFor="pickingMethodRadio2">
                  Cluster Picking
                </label>
              </div>
              {showPickingMethod === 'cluster' && (
                <div className="input-group wd-250">
                  <div className="input-group-prepend">
                    <span className="input-group-text bd-gray-400 lh-1">Number of Order</span>
                  </div>
                  <input type="number" className="form-control bd-l-0" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Pick Cart */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Pick Cart
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              정확한 아이템 피킹을 위해 픽카트를 사용합니다.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryPickCartActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="inventoryPickCartActive"></label>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col">Cart ID</th>
                <th scope="col">Warehosue</th>
                <th scope="col">Barcode</th>
                <th scope="col">Use</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="C01" />
                </td>
                <td>
                  <div className="dropdown wd-200">
                    <div className="input-group">
                      <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                        <span className="text-truncate">Seoul1</span>
                        <button type="button" className="btn" aria-label="delete">
                          <X />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open modal to select warehouse"
                        >
                          {Icon('warehouse')}
                          <span className="sr-only">선택 팝업 열기</span>
                        </button>
                      </div>
                    </div>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        <Plus className="mg-r-5" />
                        Add new warehouse
                      </button>
                      <div className="dropdown-divider"></div>
                      <button type="button" className="dropdown-item">
                        Seoul 1
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <input type="text" className="form-control" defaultValue="C01-Seoul1" />
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryPickCartUseSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryPickCartUseSwitch1"
                    ></label>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-link link-03 link-icon han-tooltip--top"
                      data-han-tooltip="Generate Label"
                    >
                      <Settings />
                      <span className="sr-only">라벨 생성</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-03 link-icon han-tooltip--top"
                      data-han-tooltip="Print Label"
                    >
                      <Printer />
                      <span className="sr-only">라벨 인쇄</span>
                    </button>
                    <Button
                      color="link"
                      size="sm"
                      icon="X"
                      iconClass="tx-danger"
                      className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                      data-han-tooltip="Delete"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="form-control" />
                </td>
                <td>
                  <div className="dropdown wd-200">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to select a warehouse"
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open modal to select warehouse"
                        >
                          {Icon('warehouse')}
                          <span className="sr-only">선택 팝업 열기</span>
                        </button>
                      </div>
                    </div>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        <Plus className="mg-r-5" />
                        Add new warehouse
                      </button>
                      <div className="dropdown-divider"></div>
                      <button type="button" className="dropdown-item">
                        Warehouse 1
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <input type="text" className="form-control" />
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryPickCartUseSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryPickCartUseSwitch1"
                    ></label>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-link link-03 link-icon han-tooltip--top"
                      data-han-tooltip="Generate Label"
                    >
                      <Settings />
                      <span className="sr-only">라벨 생성</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-03 link-icon han-tooltip--top"
                      data-han-tooltip="Print Label"
                    >
                      <Printer />
                      <span className="sr-only">라벨 인쇄</span>
                    </button>
                    <Button
                      color="link"
                      size="sm"
                      icon="X"
                      iconClass="tx-danger"
                      className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                      data-han-tooltip="Delete"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex">
          <input type="text" className="form-control mg-r-5" placeholder="Enter the Cart ID" />
          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* Generate Tracking No */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Generate Tracking No</div>
        </div>
        <div className="card-body">
          <div className="custom-control custom-radio d-inline-block mg-r-10">
            <input
              type="radio"
              className="custom-control-input"
              name="trackingNoRadio"
              id="trackingNoRadio1"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="trackingNoRadio1">
              Auto-Generating
            </label>
          </div>
          <div className="custom-control custom-radio d-inline-block">
            <input
              type="radio"
              className="custom-control-input"
              name="trackingNoRadio"
              id="trackingNoRadio2"
            />
            <label className="custom-control-label" htmlFor="trackingNoRadio2">
              Manualy Input
            </label>
          </div>
        </div>
      </div>

      {/* Location Barcode Format */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Location Barcode Format</div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label className="form-item-title">Letter Format</label>
            <div className="pd-x-20 pd-y-10 bg-light rounded">
              <div className="form-group">
                <label className="form-item-title d-block">Seperated by</label>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="letterSeperateRadio"
                    id="letterSeperateRadio1"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="letterSeperateRadio1">
                    Hyphen (-)
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="letterSeperateRadio"
                    id="letterSeperateRadio2"
                  />
                  <label className="custom-control-label" htmlFor="letterSeperateRadio2">
                    Slash (/)
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title d-block">Case used</label>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="letterCaseRadio"
                    id="letterCaseRadio1"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="letterCaseRadio1">
                    Upper Case
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="letterCaseRadio"
                    id="letterCaseRadio2"
                  />
                  <label className="custom-control-label" htmlFor="letterCaseRadio2">
                    Lower Case
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Letter Position</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="letterPositionRadio"
                id="letterPositionRadio1"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="letterPositionRadio1">
                Upper
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                className="custom-control-input"
                name="letterPositionRadio"
                id="letterPositionRadio2"
              />
              <label className="custom-control-label" htmlFor="letterPositionRadio2">
                Under
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Alignment</label>
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <label className="btn btn-white btn-icon active">
                <input type="radio" name="alignmentRadio" id="alignmentRadio1" defaultChecked />
                <AlignLeft />
                <span className="sr-only">왼쪽 맞춤</span>
              </label>
              <label className="btn btn-white btn-icon">
                <input type="radio" name="alignmentRadio" id="alignmentRadio2" />
                <AlignRight />
                <span className="sr-only">오른쪽 맞춤</span>
              </label>
              <label className="btn btn-white btn-icon">
                <input type="radio" name="alignmentRadio" id="alignmentRadio3" />
                <AlignCenter />
                <span className="sr-only">가운데 맞춤</span>
              </label>
              <label className="btn btn-white btn-icon">
                <input type="radio" name="alignmentRadio" id="alignmentRadio4" />
                <AlignJustify />
                <span className="sr-only">양쪽 맞춤</span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Barcoe Sample</label>
            <div className="d-inline-block wd-300 pd-15 bd rounded">&nbsp;</div>
            <div className="mg-t-5 tx-info">
              이 레이블은 Warehouse 1, Building A, 1층, Storage Zone A, Row 1, Rack A, Level 1
            </div>
          </div>
        </div>
      </div>

      {/* Backorder */}
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <span className="tx-16">Backorder</span>
            <div className="custom-control custom-switch mg-l-auto">
              <input
                id="inventoryBackorderActive"
                type="checkbox"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="inventoryBackorderActive"></label>
            </div>
          </div>
          <div className="mg-t-10 tx-orange">
            아이템 재고 여부와 관계없이 이월 주문을 자동으로 생성합니다.{' '}
          </div>
        </div>
      </div>

      {/* Inventory Threshold */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Inventory Threshold
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Backorder 비활성화 시에만 설정 가능합니다. 아이템이 특정 수량에 도달하면 자동으로
              품절처리하여 Sales Order 작성이 불가능하도록 합니다..{' '}
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryThresholdActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="inventoryThresholdActive"></label>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label className="form-item-title">Out of Stock Threshold</label>
            <input type="number" className="form-control" />
            <div className="custom-control custom-switch mg-t-10">
              <input
                id="hideStockActive"
                type="checkbox"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="hideStockActive">
                Hide out of stock item from the list
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Quantity Limitations */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Quantity Limitations
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              단일 주문 아이템 최소/최대수량 제한을 설정할 수 있습니다.{' '}
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryQuantityLimitationsActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label
              className="custom-control-label"
              htmlFor="inventoryQuantityLimitationsActive"
            ></label>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label className="form-item-title">Limit Quantity to Order</label>
            <div className="d-flex">
              <div className="input-group wd-300 mg-r-10">
                <div className="input-group-prepend">
                  <span className="input-group-text bd-gray-400 lh-1">Minimum</span>
                </div>
                <input type="number" className="form-control bd-l-0" />
              </div>
              <div className="input-group wd-300 mg-r-10">
                <div className="input-group-prepend">
                  <span className="input-group-text bd-gray-400 lh-1">Maximum</span>
                </div>
                <input type="number" className="form-control bd-l-0" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Item to Limit</label>
            <div className="d-flex align-items-center flex-wrap">
              <div className="custom-control custom-radio mg-r-10">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="itemLimitRadio"
                  id="itemLimitRadio1"
                  value="all"
                  checked={showitemLimit === 'all'}
                  onChange={(e) => handleitemLimit(e)}
                />
                <label className="custom-control-label" htmlFor="itemLimitRadio1">
                  All Item
                </label>
              </div>
              <div className="d-flex flex-fill align-items-center mg-r-10">
                <div className="custom-control custom-radio mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="itemLimitRadio"
                    id="itemLimitRadio2"
                    value="specific"
                    checked={showitemLimit === 'specific'}
                    onChange={(e) => handleitemLimit(e)}
                  />
                  <label className="custom-control-label text-nowrap" htmlFor="itemLimitRadio2">
                    Specific Item
                  </label>
                </div>
                {showitemLimit === 'specific' && (
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type or click to select an item"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn">
                        {Icon('product')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Experation  Limitations */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Experation Limitations
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Item Experation/ Best Before Date 기준으로 주문 제한을 설정할 수 있습니다.
            </small>
          </div>
          <div className="custom-control custom-switch mg-l-auto">
            <input
              id="inventoryExperationLimitationsActive"
              type="checkbox"
              className="custom-control-input"
              defaultChecked
            />
            <label
              className="custom-control-label"
              htmlFor="inventoryExperationLimitationsActive"
            ></label>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label className="form-item-title d-block">
              Generating the Sales Disable before the Experation/Best Before Date
            </label>
            <div className="d-flex align-items-center mg-t-10">
              <input type="number" className="form-control form-control-lg wd-100" />
              <SelectHook
                className="wd-100-f mg-l-10"
                options={[
                  { value: 1, label: 'day(s)' },
                  { value: 2, label: 'month(s)' },
                  { value: 3, label: 'year(s)' },
                ]}
              />
            </div>
            <div className="custom-control custom-switch mg-t-10">
              <input
                id="hideStockActive2"
                type="checkbox"
                className="custom-control-input"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="hideStockActive2">
                Hide out of stock item from the list
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Item to Limit</label>
            <div className="d-flex align-items-center flex-wrap">
              <div className="custom-control custom-radio mg-r-10">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="itemLimitRadio2"
                  id="itemLimitRadio2-1"
                />
                <label className="custom-control-label" htmlFor="itemLimitRadio2-1">
                  All Item
                </label>
              </div>
              <div className="d-flex flex-fill align-items-center mg-r-10">
                <div className="custom-control custom-radio mg-r-10">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="itemLimitRadio2"
                    id="itemLimitRadio2-2"
                  />
                  <label className="custom-control-label text-nowrap" htmlFor="itemLimitRadio2-2">
                    Specific Item
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Package Type */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Package Type
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Item 포장 시 사용할 Package Type을 생성합니다.
            </small>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col" className="text-nowrap">
                  Packge Name
                </th>
                <th scope="col" className="text-nowrap">
                  Capacity
                </th>
                <th scope="col" className="text-nowrap">
                  Package Weight
                </th>
                <th scope="col" className="text-nowrap">
                  Dimension
                </th>
                <th scope="col" className="text-nowrap">
                  Use
                </th>
                <th scope="col" className="text-nowrap">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="Small Box" />
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <input
                      type="number"
                      className="form-control form-control-lg wd-80 mg-r-5"
                      defaultValue="3"
                    />
                    <SelectHook
                      className="wd-80-f"
                      options={[
                        { value: 1, label: 'kg' },
                        { value: 2, label: 'lb' },
                      ]}
                    />
                    <span className="mg-x-10">~</span>
                    <input
                      type="number"
                      className="form-control form-control-lg wd-80 mg-r-5"
                      defaultValue="3"
                    />
                    <SelectHook
                      className="wd-80-f"
                      options={[
                        { value: 1, label: 'kg' },
                        { value: 2, label: 'lb' },
                      ]}
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <input
                      type="number"
                      className="form-control form-control-lg wd-80 mg-r-5"
                      defaultValue="0.3"
                    />
                    <SelectHook
                      className="wd-80-f"
                      options={[
                        { value: 1, label: 'kg' },
                        { value: 2, label: 'lb' },
                      ]}
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex">
                    <div className="form-control form-control-lg d-flex align-items-center wd-200 mg-r-5">
                      <input
                        type="number"
                        className="wd-100p bd-0 pd-0 text-center"
                        defaultValue="12"
                      />
                      <span className="mg-x-5 tx-color-03">x</span>
                      <input
                        type="number"
                        className="wd-100p bd-0 pd-0 text-center"
                        defaultValue="12.5"
                      />
                      <span className="mg-x-5 tx-color-03">x</span>
                      <input
                        type="number"
                        className="wd-100p bd-0 pd-0 text-center"
                        defaultValue="17"
                      />
                    </div>
                    <SelectHook
                      className="wd-80-f"
                      options={[
                        { value: 1, label: 'cm' },
                        { value: 2, label: 'in' },
                      ]}
                    />
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryPackageUseSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryPackageUseSwitch1"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="form-control" />
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <input type="number" className="form-control form-control-lg wd-80 mg-r-5" />
                    <SelectHook
                      className="wd-80-f"
                      options={[
                        { value: 1, label: 'kg' },
                        { value: 2, label: 'lb' },
                      ]}
                    />
                    <span className="mg-x-10">~</span>
                    <input type="number" className="form-control form-control-lg wd-80 mg-r-5" />
                    <SelectHook
                      className="wd-80-f"
                      options={[
                        { value: 1, label: 'kg' },
                        { value: 2, label: 'lb' },
                      ]}
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <input type="number" className="form-control form-control-lg wd-80 mg-r-5" />
                    <SelectHook
                      className="wd-80-f"
                      options={[
                        { value: 1, label: 'kg' },
                        { value: 2, label: 'lb' },
                      ]}
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex">
                    <div className="form-control form-control-lg d-flex align-items-center wd-200 mg-r-5">
                      <input type="number" className="wd-100p bd-0 pd-0 text-center" />
                      <span className="mg-x-5 tx-color-03">x</span>
                      <input type="number" className="wd-100p bd-0 pd-0 text-center" />
                      <span className="mg-x-5 tx-color-03">x</span>
                      <input type="number" className="wd-100p bd-0 pd-0 text-center" />
                    </div>
                    <SelectHook
                      className="wd-80-f"
                      options={[
                        { value: 1, label: 'cm' },
                        { value: 2, label: 'in' },
                      ]}
                    />
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch mg-l-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryPackageUseSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryPackageUseSwitch1"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex">
          <input type="text" className="form-control mg-r-5" placeholder="Enter the Packge Name" />
          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* Delivery Type */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Delivery Type
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              배송 방법 및 운송 업체를 선택합니다.
            </small>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col">Delivery Type</th>
                <th scope="col">Courier</th>
                <th scope="col">Use</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="국내배송" />
                </td>
                <td>
                  <div className="dropdown wd-200">
                    <div className="input-group">
                      <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                        <span className="text-truncate">Courier 1</span>
                        <button type="button" className="btn" aria-label="delete">
                          <X />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open modal to select courier"
                        >
                          <ChevronDown />
                          <span className="sr-only">선택 팝업 열기</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryDeliveryUseSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryDeliveryUseSwitch1"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="form-control" />
                </td>
                <td>
                  <div className="dropdown wd-200">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to select a courier"
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open modal to select courier"
                        >
                          <ChevronDown />
                          <span className="sr-only">선택 팝업 열기</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="inventoryDeliveryUseSwitch1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="inventoryDeliveryUseSwitch1"
                    ></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0 han-tooltip--top mg-l-auto"
                    data-han-tooltip="Delete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex">
          <input
            type="text"
            className="form-control mg-r-5"
            placeholder="Enter the Delivery Type"
          />
          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* SKU Gereration */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            SKU Gereration
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              Incorporate information on color, type, size and other similar attributes into the SKU
            </small>
          </div>
        </div>
        <div className="card-body">
          <RadioGroup
            value={selectedSKUOption.value}
            options={SKUOptions}
            onChange={handleSKUOptionChange}
          />
          {selectedSKUOption.value === 1 && (
            <>
              <div className="form-group">
                <label className="form-item-title">
                  SKU Format Settings
                  <br />
                  <span className="mg-t-10 tx-orange">
                    Select the SKU value, 8~12 letters recommended
                  </span>
                </label>
                <div className="table-responsive bd rounded-top">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Value</th>
                        <th scope="col">Show</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Select
                            width={230}
                            options={SKUValue}
                            defaultValue={selectedSKUValue}
                            onChange={handleValueChange}
                          />
                        </td>
                        <td>
                          {selectedSKUValue.value !== 6 ? (
                            <div className="d-flex">
                              <Select width={100} defaultValue={{ value: 1, label: 'First' }} />
                              <Input type="number" rightIcon="letters" className="mg-l-5 wd-150" />
                            </div>
                          ) : (
                            <Input type="text" placeholder="Enter the Cusom Text" />
                          )}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex pd-10 bd bd-t-0 rounded-bottom">
                  <input
                    type="text"
                    className="form-control mg-r-5"
                    placeholder="Enter the Value"
                  />
                  <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title d-block">Seperated by</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterSeperateRadio2"
                        id="letterSeperateRadio2-1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="letterSeperateRadio2-1">
                        Hyphen (-)
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterSeperateRadio2"
                        id="letterSeperateRadio2-2"
                      />
                      <label className="custom-control-label" htmlFor="letterSeperateRadio2-2">
                        Slash (/)
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Case used</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterCaseRadio2"
                        id="letterCaseRadio2-1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="letterCaseRadio2-1">
                        Upper Case
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterCaseRadio"
                        id="letterCaseRadio2-2"
                      />
                      <label className="custom-control-label" htmlFor="letterCaseRadio2-2">
                        Lower Case
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group d-flex flex-column ht-100p mg-b-0">
                    <label className="form-item-title">SKU Sample</label>
                    <div className="d-flex align-items-center justify-content-center flex-grow-1 pd-x-20 pd-y-10 bg-light rounded">
                      JUI-BOT-MIN-SMA
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Batch Gereration */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Batch Number Gereration
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">Select the batch number value.</small>
          </div>
        </div>
        <div className="card-body">
          <RadioGroup
            value={selectedBatchOption.value}
            options={batchOptions}
            onChange={handleBatchOptionChange}
          />
          {selectedBatchOption.value === 1 && (
            <>
              <div className="form-group">
                <label className="form-item-title">
                  Batch Number Format Settings
                  <br />
                  <span className="mg-t-10 tx-orange">
                    Select the attribute to construct the Batch Number for the items
                  </span>
                </label>
                <div className="table-responsive bd rounded-top">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col">Value</th>
                        <th scope="col">Show</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Select
                            width={230}
                            options={batchValue}
                            defaultValue={selectedBatchValue}
                            onChange={handleBatchValueChange}
                          />
                        </td>
                        <td>
                          {selectedBatchValue.value === 1 && (
                            <div className="d-flex">
                              <Select width={100} defaultValue={{ value: 1, label: 'First' }} />
                              <Input type="number" rightIcon="letters" className="mg-l-5 wd-150" />
                            </div>
                          )}
                          {(selectedBatchValue.value === 2 || selectedBatchValue.value === 3) && (
                            <Select
                              options={[
                                { value: 1, label: 'yyyymmdd' },
                                { value: 2, label: 'ddmmyyyy' },
                              ]}
                              defaultValue={{ value: 1, label: 'yyyymmdd' }}
                            />
                          )}
                          {selectedBatchValue.value === 4 && (
                            <Input type="text" placeholder="Enter the Cusom Text" />
                          )}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex pd-10 bd bd-t-0 rounded-bottom">
                  <input
                    type="text"
                    className="form-control mg-r-5"
                    placeholder="Enter the Value"
                  />
                  <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
                    <Plus className="mg-r-5" />
                    Add
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title d-block">Seperated by</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterSeperateRadio3"
                        id="letterSeperateRadio3-1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="letterSeperateRadio3-1">
                        Hyphen (-)
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterSeperateRadio3"
                        id="letterSeperateRadio3-2"
                      />
                      <label className="custom-control-label" htmlFor="letterSeperateRadio3-2">
                        Slash (/)
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Case used</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterCaseRadio3"
                        id="letterCaseRadio3-1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="letterCaseRadio3-1">
                        Upper Case
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterCaseRadio3"
                        id="letterCaseRadio3-2"
                      />
                      <label className="custom-control-label" htmlFor="letterCaseRadio3-2">
                        Lower Case
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group d-flex flex-column ht-100p mg-b-0">
                    <label className="form-item-title">Batch Number Sample</label>
                    <div className="d-flex align-items-center justify-content-center flex-grow-1 pd-x-20 pd-y-10 bg-light rounded">
                      ITE-20210331
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Serial Number Gereration */}
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Serial Number Gereration
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">Select the serial number value.</small>
          </div>
        </div>
        <div className="card-body">
          <RadioGroup
            value={selectedSerialOption.value}
            options={serialOptions}
            onChange={handleSerialOptionChange}
          />
          {selectedSerialOption.value === 1 && (
            <>
              <div className="form-group">
                <label className="form-item-title">
                  Serial Number Format Settings
                  <br />
                  <span className="mg-t-10 tx-orange">
                    Select the attribute to construct the Serial Number for the items
                  </span>
                </label>
              </div>
              <div className="row">
                <div className="col-lg-6 form-group">
                  <label className="form-item-title">Prefix</label>
                  <Select
                    options={serialValue}
                    defaultValue={selectedSerialValue}
                    onChange={handleSerialValueChange}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <label className="form-item-title">Show</label>
                  {selectedSerialValue.value !== 3 && (
                    <div className="d-flex">
                      <Select width={100} defaultValue={{ value: 1, label: 'First' }} />
                      <Input type="number" rightIcon="letters" className="mg-l-5 wd-150" />
                    </div>
                  )}
                  {selectedSerialValue.value === 3 && (
                    <Input type="text" placeholder="Enter the Cusom Text" />
                  )}
                </div>
                <div className="col-lg-12 form-group">
                  <label className="form-item-title">Starting Number</label>
                  <Input type="text" />
                </div>
                <div className="col-lg-6 form-group">
                  <label className="form-item-title">Suffix</label>
                  <Select
                    options={serialValue}
                    defaultValue={selectedSerialValue}
                    onChange={handleSerialValueChange}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <label className="form-item-title">Show</label>
                  {selectedSerialValue.value !== 3 && (
                    <div className="d-flex">
                      <Select width={100} defaultValue={{ value: 1, label: 'First' }} />
                      <Input type="number" rightIcon="letters" className="mg-l-5 wd-150" />
                    </div>
                  )}
                  {selectedSerialValue.value === 3 && (
                    <Input type="text" placeholder="Enter the Cusom Text" />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title d-block">Seperated by</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterSeperateRadio4"
                        id="letterSeperateRadio4-1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="letterSeperateRadio4-1">
                        Hyphen (-)
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterSeperateRadio4"
                        id="letterSeperateRadio4-2"
                      />
                      <label className="custom-control-label" htmlFor="letterSeperateRadio4-2">
                        Slash (/)
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Case used</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterCaseRadio4"
                        id="letterCaseRadio4-1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="letterCaseRadio4-1">
                        Upper Case
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="letterCaseRadio4"
                        id="letterCaseRadio4-2"
                      />
                      <label className="custom-control-label" htmlFor="letterCaseRadio4-2">
                        Lower Case
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group d-flex flex-column ht-100p mg-b-0">
                    <label className="form-item-title">Serial Number Sample</label>
                    <div className="d-flex align-items-center justify-content-center flex-grow-1 pd-x-20 pd-y-10 bg-light rounded">
                      ITE-1000-BO1
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inventory;
