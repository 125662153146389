import { Button, Select } from '@base/components/form';
import { useState } from 'react';

import { Download, X } from 'react-feather';

const NewShortenURLSocial = () => {
  const PAGE_NAMES = [
    { value: 1, label: 'Page 1' },
    { value: 2, label: 'Site 1' },
  ];
  const [pages, setPages] = useState(PAGE_NAMES);
  const [selectedPage, setSelectedPage] = useState<any>();
  const handlePageChange = (newOption: any) => {
    setSelectedPage(newOption);
  };
  console.log(selectedPage);

  return (
    <div
      id="newShortenUrlSocial"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Shorten URL</h1>
            <Button close data-dismiss="modal" aria-label="닫기" />
          </div>
          <div className="modal-body overflow-auto">
            <p className="tx-orange">URL displayed in Social Post</p>
            <div className="form-group">
              <label className="form-item-title">Landing Page / Site</label>
              <Select value={selectedPage} options={pages} onChange={handlePageChange} />
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" data-dismiss="modal" name="Close" />
            {selectedPage !== undefined && <Button color="success" name="Generate URL" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewShortenURLSocial;
