import { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronUp,
  X,
  Monitor,
  ChevronDown,
  RotateCcw,
  RotateCw,
  Image,
  Trash,
  CheckSquare,
  Check,
  Calendar,
  Paperclip,
  ArrowUp,
  Move,
  Type,
  Copy,
  AlertOctagon,
  UploadCloud,
} from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'Basic Info',
  },
  {
    value: 2,
    label: 'Select a Sample',
  },
  {
    value: 3,
    label: 'Design',
  },
  {
    value: 4,
    label: 'Submission Settings',
  },
  {
    value: 5,
    label: 'Pop-up Settings',
  },
];

const DESIGNTABS = [
  {
    value: 1,
    label: 'Designer',
  },
  {
    value: 2,
    label: 'HTML',
  },
  {
    value: 3,
    label: 'Preview',
  },
];

const NewForm = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [designTab, setDesignTab] = useState(DESIGNTABS[0]);

  const [popOption, setPopOption] = useState('cta');
  const handlePopOption = (e: any) => {
    const { value } = e.target;
    setPopOption(value);
  };

  return (
    <div id="newFormModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">New Form</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body d-flex flex-column overflow-hidden pd-0">
            {/* steps */}
            <ul className="steps steps-justified steps-tab">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  className={classnames('step-item', {
                    complete: item.value < tab.value,
                    active: item.value == tab.value,
                    disabed: item.value > tab.value,
                  })}
                >
                  <button
                    type="button"
                    className="btn step-link rounded-0"
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    <span className="step-number">{item.value}</span>
                    <span className="step-title">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
            {tab.value == 1 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="form-group">
                  <label className="form-item-title">Form Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Form Type</label>
                  <SelectHook
                    className="wd-300-f"
                    options={[
                      { value: 1, label: 'Regular' },
                      { value: 2, label: 'Pop-up' },
                    ]}
                  />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Pop-up Type</label>
                  <SelectHook
                    className="wd-300-f"
                    options={[
                      { value: 1, label: 'Pop-up' },
                      { value: 2, label: 'Slide-in left' },
                      { value: 3, label: 'Slide-in right' },
                    ]}
                  />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Content Type</label>
                  <SelectHook
                    className="wd-300-f"
                    options={[
                      { value: 1, label: 'Contact us' },
                      { value: 2, label: 'Registration' },
                      { value: 3, label: 'Support' },
                      { value: 4, label: 'Newsletter Sign-up' },
                      { value: 5, label: 'Sign-up' },
                      { value: 6, label: 'Subscription' },
                      { value: 7, label: 'E-book dodwnload' },
                    ]}
                  />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Language</label>
                  <SelectHook className="wd-200-f" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0xJV22ugqnu5lwu_NoaL1CeW2SZCx4YbuLQ&amp;usqp=CAU"
                            alt=""
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">Product 111</span>
                        <button type="button" className="btn pd-0 form-control-tag-remove">
                          <X />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250"
                        placeholder="Type or click to select a product"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a product"
                      >
                        {Icon('product')}
                        <span className="sr-only">상품 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <textarea rows={3} cols={10} className="form-control"></textarea>
                </div>
              </div>
            )}
            {tab.value == 2 && (
              <div
                className="row row-xs mg-0 overflow-auto"
                style={{ height: 'calc(100% - 50px)' }}
              >
                <div className="col-lg-6 d-flex flex-column ht-100p">
                  <ul className="nav nav-line bd-1">
                    <li className="nav-item">
                      <button
                        type="button"
                        data-target="#myFoem"
                        className="btn nav-link pd-x-10-f active"
                        data-toggle="tab"
                      >
                        My
                      </button>
                    </li>
                    <li className="nav-item mg-l-0-f">
                      <button
                        type="button"
                        data-target="#sampleForm"
                        className="btn nav-link pd-x-10-f"
                        data-toggle="tab"
                      >
                        Sample
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pd-x-10 overflow-auto">
                    <div id="myFoem" className="tab-pane fade show active">
                      <div className="card-columns temp-columns">
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">Blank</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 1</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 2</div>
                          <button type="button" className="btn btn-select active">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">My 3</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="sampleForm" className="tab-pane fade">
                      <SelectHook
                        className="wd-200-f mg-t-10"
                        options={[{ value: 1, label: 'All(20)' }]}
                      />
                      <div className="card-columns temp-columns">
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">AAA</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                        <div className="card shadow-sm">
                          <div className="card-body ht-150 overflow-hidden tx-center"></div>
                          <div className="card-footer">BBB</div>
                          <button type="button" className="btn btn-select">
                            <span className="sr-only">선택</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex flex-column ht-100p">
                  <div className="pd-y-9">Preview</div>
                  <div className="flex-grow-1 overflow-auto mg-b-10 pd-10 bd"></div>
                </div>
              </div>
            )}
            {tab.value == 3 && (
              <div className="d-flex flex-column pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="form-group">
                  <label className="form-item-title">Title</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="flex-grow-1 bd rounded overflow-auto">
                  <div className="row no-gutters ht-100p overflow-auto">
                    <div
                      className={classnames(
                        'd-flex flex-column ht-100p',
                        { 'col-lg-7 col-xl-8': designTab.value == 1 },
                        { 'col-12': designTab.value != 1 },
                      )}
                    >
                      <div className="d-flex">
                        {/* tabs */}
                        <ul className="nav nav-line bd-1 flex-grow-1">
                          {DESIGNTABS.map((item, index) => (
                            <li className="nav-item" key={index}>
                              <button
                                type="button"
                                className={classnames('btn nav-link pd-x-10-f', {
                                  active: item.value == designTab.value,
                                })}
                                onClick={(e) => {
                                  setDesignTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </li>
                          ))}
                        </ul>
                        {designTab.value == 1 && (
                          <div className="btn-group bd-b">
                            <button type="button" className="btn">
                              <AlertOctagon className="mg-r-5" />
                              Check for errors
                            </button>
                            <button
                              type="button"
                              data-han-tooltip="Undo"
                              className="btn btn-icon han-tooltip"
                            >
                              <RotateCcw />
                              <span className="sr-only">실행취소</span>
                            </button>
                            <button
                              type="button"
                              data-han-tooltip="Redo"
                              className="btn btn-icon han-tooltip"
                            >
                              <RotateCw />
                              <span className="sr-only">재실행</span>
                            </button>
                            <button
                              type="button"
                              data-han-tooltip="Clear Canvas"
                              className="btn btn-icon han-tooltip"
                            >
                              <Trash />
                              <span className="sr-only">초기화</span>
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="flex-grow-1 overflow-auto">
                        {designTab.value == 1 && (
                          <div className="design-ele" style={{ padding: '30px' }}>
                            <div
                              className="design-ele"
                              style={{
                                marginBottom: '15px',
                                fontSize: '18px',
                                textAlign: 'center',
                              }}
                            >
                              <div className="design-ele-label">Heading 1</div>
                              <div className="design-ele-toolbar">
                                <button
                                  type="button"
                                  data-han-tooltip="Parent"
                                  className="btn btn-sm btn-icon han-tooltip"
                                >
                                  <ArrowUp />
                                  <span className="sr-only">상위</span>
                                </button>
                                <button
                                  type="button"
                                  data-han-tooltip="Move"
                                  className="btn btn-sm btn-icon han-tooltip"
                                >
                                  <Move />
                                  <span className="sr-only">이동</span>
                                </button>
                                <button
                                  type="button"
                                  data-han-tooltip="Copy"
                                  className="btn btn-sm btn-icon han-tooltip"
                                >
                                  <Copy />
                                  <span className="sr-only">복사</span>
                                </button>
                                <button
                                  type="button"
                                  data-han-tooltip="Remove"
                                  className="btn btn-sm btn-icon han-tooltip"
                                >
                                  <Trash />
                                  <span className="sr-only">삭제</span>
                                </button>
                              </div>
                              Form 1
                            </div>
                            <div className="form-row">
                              <div className="form-col col-6 design-ele selected">
                                <div className="design-ele-label">Field 1</div>
                                <div className="design-ele-toolbar">
                                  <button
                                    type="button"
                                    data-han-tooltip="Parent"
                                    className="btn btn-sm btn-icon han-tooltip"
                                  >
                                    <ArrowUp />
                                    <span className="sr-only">상위</span>
                                  </button>
                                  <button
                                    type="button"
                                    data-han-tooltip="Move"
                                    className="btn btn-sm btn-icon han-tooltip"
                                  >
                                    <Move />
                                    <span className="sr-only">이동</span>
                                  </button>
                                  <button
                                    type="button"
                                    data-han-tooltip="Copy"
                                    className="btn btn-sm btn-icon han-tooltip"
                                  >
                                    <Copy />
                                    <span className="sr-only">복사</span>
                                  </button>
                                  <button
                                    type="button"
                                    data-han-tooltip="Remove"
                                    className="btn btn-sm btn-icon han-tooltip"
                                  >
                                    <Trash />
                                    <span className="sr-only">삭제</span>
                                  </button>
                                </div>
                                <div className="form-group">
                                  <label className="form-item-title">First Name</label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {designTab.value == 2 && <div></div>}
                        {designTab.value == 3 && (
                          <div className="d-flex ht-100p">
                            <div className="wd-80 flex-shrink-0 design-preview-btns overflow-auto">
                              <button type="button" className="btn wd-100p ht-80 bd-b">
                                <div>Desktop</div>
                                <Monitor />
                              </button>
                              <button type="button" className="btn wd-100p ht-80 bd-b active">
                                <div>Tablet</div>
                                {Icon('tablet')}
                              </button>
                              <button type="button" className="btn wd-100p ht-80">
                                <div>Mobile</div>
                                {Icon('smartphone')}
                              </button>
                            </div>
                            <div className="flex-grow-1 overflow-auto pd-20 bd-l">
                              {/* Tablet */}
                              <div
                                className="shadow"
                                style={{
                                  width: '828px',
                                  height: '1084px',
                                  margin: '0 auto',
                                  padding: '30px',
                                  borderRadius: '15px',
                                  border: '1px solid #cccccc',
                                }}
                              >
                                <div className="ht-100p overflow-auto bd"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {designTab.value == 1 && (
                      <div className="col-lg-5 col-xl-4 d-flex flex-column ht-100p bd-l">
                        <ul className="nav nav-line bd-1">
                          <li className="nav-item">
                            <button
                              type="button"
                              data-target="#designToolbox"
                              className="btn nav-link pd-x-10-f active"
                              data-toggle="tab"
                            >
                              Toolbox
                            </button>
                          </li>
                          <li className="nav-item mg-l-0-f">
                            <button
                              type="button"
                              data-target="#designProperties"
                              className="btn nav-link pd-x-10-f"
                              data-toggle="tab"
                            >
                              Properties
                            </button>
                          </li>
                          <li className="nav-item mg-l-0-f">
                            <button
                              type="button"
                              data-target="#designStyle"
                              className="btn nav-link pd-x-10-f"
                              data-toggle="tab"
                            >
                              Styles
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content flex-grow-1 overflow-auto">
                          <div id="designToolbox" className="tab-pane fade show active">
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designLayout"
                            >
                              Layout
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designLayout" className="collapse fade show bd-b">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column1')}
                                  <div className="ele-name">1 Column</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2')}
                                  <div className="ele-name">2 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column3')}
                                  <div className="ele-name">3 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2_2')}
                                  <div className="ele-name">3:7 Columns</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('column2_3')}
                                  <div className="ele-name">7:3 Columns</div>
                                </button>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designBasicElement"
                            >
                              Basic Elements
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designBasicElement" className="collapse fade show bd-b">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('heading')}
                                  <div className="ele-name">Heading</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Type />
                                  <div className="ele-name">Text</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Image />
                                  <div className="ele-name">Image</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('divider')}
                                  <div className="ele-name">Divider</div>
                                </button>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designFormElement"
                            >
                              Form Elements
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designFormElement" className="collapse fade show bd-b">
                              <div className="design-toolbox pd-5">
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('input_text')}
                                  <div className="ele-name">Single Text</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('input_number')}
                                  <div className="ele-name">Number</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('textarea')}
                                  <div className="ele-name">Textarea</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <CheckSquare />
                                  <div className="ele-name">Checkbox</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('radio')}
                                  <div className="ele-name">Radio</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  {Icon('select_option')}
                                  <div className="ele-name">Select</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Calendar />
                                  <div className="ele-name">Date</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Paperclip />
                                  <div className="ele-name">File</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <RotateCcw />
                                  <div className="ele-name">Reset Button</div>
                                </button>
                                <button type="button" className="btn btn-white shadow-sm">
                                  <Check />
                                  <div className="ele-name">Submit Button</div>
                                </button>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designFields"
                            >
                              Fields
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designFields" className="collapse fade show">
                              <div className="design-field-list pd-10">
                                <button type="button" className="btn btn-white btn-block shadow-sm">
                                  <CheckSquare />
                                  <span className="ele-name">Terms of use</span>
                                </button>
                                <button type="button" className="btn btn-white btn-block shadow-sm">
                                  <CheckSquare />
                                  <span className="ele-name">Privacy Policy</span>
                                </button>
                                <button type="button" className="btn btn-white btn-block shadow-sm">
                                  {Icon('input_text')}
                                  <span className="ele-name">First Name</span>
                                </button>
                                <button type="button" className="btn btn-white btn-block shadow-sm">
                                  {Icon('input_text')}
                                  <span className="ele-name">Last Name</span>
                                </button>
                                <button type="button" className="btn btn-white btn-block shadow-sm">
                                  {Icon('ticket')}
                                  <span className="ele-name">Ticket</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="designProperties" className="tab-pane fade">
                            <button
                              type="button"
                              className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                              data-toggle="collapse"
                              data-target="#designCTA"
                            >
                              Field Properties
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div id="designCTA" className="collapse fade show pd-15">
                              <div className="form-group">
                                <label className="form-item-title">Label</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue="First Name"
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Placeholder</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Default Value</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Required</label>
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="formRequiredSwitch"
                                    defaultChecked
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="formRequiredSwitch"
                                  ></label>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-item-title">Lable Position</label>
                                <SelectHook
                                  options={[
                                    { value: 1, label: 'Top' },
                                    { value: 2, label: 'Left' },
                                    { value: 3, label: 'Right' },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                          <div id="designStyle" className="tab-pane fade"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {designTab.value == 1 && (
                  <div className="card mg-t-10">
                    <div className="card-header d-flex align-items-center pd-y-5">
                      Field Property Type
                      <button
                        type="button"
                        className="btn mg-l-auto pd-5 collapsed"
                        data-toggle="collapse"
                        data-target="#infoFieldProperty"
                      >
                        <ChevronDown />
                      </button>
                    </div>
                    <div id="infoFieldProperty" className="card-body collapse fade">
                      <dl>
                        <dt className="float-left mg-r-10">Label:</dt>
                        <dd>The field name that will appear on the form.</dd>
                        <dt className="float-left mg-r-10">Help text:</dt>
                        <dd>
                          Information text below the field to help the visitor complete the field.
                        </dd>
                        <dt className="float-left mg-r-10">Placeholder text:</dt>
                        <dd>Text that appears in the input box.</dd>
                        <dt className="float-left mg-r-10">Default value:</dt>
                        <dd>
                          A value that will be submitted for the field by default unless changed by
                          the visitor.
                        </dd>
                        <dt className="float-left mg-r-10">Field required:</dt>
                        <dd>
                          When the checkbox is selected, the field must be completed to submit the
                          form.
                        </dd>
                        <dt className="float-left mg-r-10">Field Hidden</dt>
                      </dl>
                    </div>
                  </div>
                )}
              </div>
            )}
            {tab.value == 4 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="form-group">
                  <label className="form-item-title">
                    What should display after a visitor submits this form
                  </label>
                  <div className="custom-control custom-radio mg-b-10">
                    <input
                      type="radio"
                      id="displayOptionRadio1"
                      name="displayOptionRadio"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="displayOptionRadio1">
                      Thank you message
                    </label>
                    <textarea rows={3} cols={10} className="form-control"></textarea>
                  </div>
                  <div className="custom-control custom-radio mg-b-10">
                    <input
                      type="radio"
                      id="displayOptionRadio2"
                      name="displayOptionRadio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="displayOptionRadio2">
                      Redirect to another page.
                    </label>
                    <input type="url" className="form-control" />
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="displayOptionRadio2"
                      name="displayOptionRadio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="displayOptionRadio2">
                      Link
                    </label>
                    <SelectHook className="wd-300-f" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Submission behavior</label>
                  <div className="custom-control custom-checkbox mg-b-10">
                    <input type="checkbox" id="behavoirCheck1" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="behavoirCheck1">
                      Create Contact
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox mg-b-10">
                    <input type="checkbox" id="behavoirCheck2" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="behavoirCheck2">
                      Create Lead
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox mg-b-10">
                    <input type="checkbox" id="behavoirCheck3" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="behavoirCheck3">
                      Create Ticket in Desk
                    </label>
                    <div className="d-flex align-items-center">
                      Desk Name
                      <SelectHook className="wd-300-f mg-l-10" />
                    </div>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" id="behavoirCheck4" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="behavoirCheck4">
                      Create Trigger in Journey Process
                    </label>
                    <div className="d-flex align-items-center">
                      Process Name
                      <SelectHook className="wd-300-f mg-l-10" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {tab.value == 5 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="popupOption1"
                    name="popupOption"
                    className="custom-control-input"
                    value="cta"
                    checked={popOption === 'cta'}
                    onChange={(e) => handlePopOption(e)}
                  />
                  <label className="custom-control-label" htmlFor="popupOption1">
                    CTA on the Pop-up
                  </label>
                  <div className="mg-t-5">
                    <img src="assets/images/cta-popup.png" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="popupOption2"
                    name="popupOption"
                    className="custom-control-input"
                    value="form"
                    checked={popOption === 'form'}
                    onChange={(e) => handlePopOption(e)}
                  />
                  <label className="custom-control-label" htmlFor="popupOption2">
                    Form on the Pop-up
                  </label>
                  <div className="mg-t-5">
                    <img src="assets/images/form-popup.png" alt="" className="img-fluid" />
                  </div>
                </div>
                <hr />
                {popOption === 'cta' && (
                  <>
                    <div className="row row-xs">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-item-title">Image</label>
                          <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
                            <UploadCloud size="20" className="mg-r-10" />
                            Drag &amp; Drop Files
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="noPopupImg"
                            />
                            <label className="custom-control-label" htmlFor="noPopupImg">
                              No image
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Callout text in main header</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Callout body</label>
                          <textarea rows={3} cols={10} className="form-control" />
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Callout button text</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label className="form-item-title d-block">Callout button color</label>
                          <input type="color" value="#e66465" />
                        </div>
                        <div className="form-group">
                          <label className="form-item-title d-block">Callout text color</label>
                          <input type="color" value="#ffffff" />
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Callout button link</label>
                          <SelectHook />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group d-flex flex-column ht-100p">
                          <label className="form-item-title">Preview</label>
                          <div className="d-flex align-items-center justify-content-center flex-grow-1 pd-10 bg-light rounded overflow-auto">
                            <div className="d-flex mx-wd-100p">
                              <div className="wd-200 ht-200 flex-shrink-0 mg-r-15 bg-gray-300 rounded">
                                <img src="assets/images/notfound.png" className="img-fit-cover" />
                              </div>
                              <div className="flex-grow-1 d-flex flex-column align-items-start justify-content-between">
                                <div className="h1 tx-24">Main header</div>
                                <p className="text-break">Body</p>
                                <a
                                  href="#"
                                  style={{
                                    display: 'inline-block',
                                    padding: '10px',
                                    backgroundColor: '#e66465',
                                    color: '#fff',
                                    borderRadius: '3px',
                                  }}
                                >
                                  Button
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                <div className="form-group">
                  <label className="form-item-title">
                    Pop-up form should appear on the page when you select the following option.
                  </label>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="showPopupRadio1"
                      name="showPopupRadio"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="showPopupRadio1">
                      No option
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="showPopupRadio2"
                      name="showPopupRadio"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="showPopupRadio2">
                      On scrolled halfway down the page
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="showPopupRadio3"
                      name="showPopupRadio"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="showPopupRadio3">
                      On move the cursor to the top bar of the browser
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="showPopupRadio4"
                      name="showPopupRadio"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="showPopupRadio4">
                      After the specified amount of seconds{' '}
                    </label>
                    <input type="number" className="form-control wd-100" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            {tab.value == 2 && (
              <>
                <button type="button" className="btn btn-white">
                  Skip
                </button>
                <button type="button" className="btn btn-primary">
                  Select
                </button>
              </>
            )}
            {tab.value != 2 && (
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  {tab.value != 4 && (
                    <button type="button" className="dropdown-item">
                      Save and Next
                    </button>
                  )}
                  {tab.value == 4 && (
                    <>
                      <button type="button" className="dropdown-item">
                        Save and Finish
                      </button>
                      <button type="button" className="dropdown-item">
                        Save and Submit for Approval
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      ;
    </div>
  );
};

export default NewForm;
