import React, { useState } from 'react';
import classNames from 'classnames';
import { ChevronDown, Copy, X, Plus, Settings } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const WriteItemStep1 = () => {
  const [showMoreInfo, setShowMoreInfo] = useState<any>(false);

  const [itemType, setItemType] = useState<any>('general');
  const handleItemTypeChange = (e: any) => {
    const { value } = e.target;
    setItemType(value);
  };

  return (
    <div className="form-row mg-0 pd-15">
      <div className="form-col col-12 form-group">
        <label className="d-block form-item-title">
          Product<span className="tx-danger">*</span>
        </label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Type or click to add a product"
          />
          <div className="input-group-append">
            <button type="button" className="btn">
              {Icon('product')}
            </button>
          </div>
        </div>
      </div>
      <div className="form-col col-12 form-group">
        <label className="d-block form-item-title">
          Item Category<span className="tx-danger">*</span>
        </label>
        <div>Inventory</div>
      </div>
      <div className="form-col col-12 form-group">
        <label className="d-block form-item-title">
          Item Type<span className="tx-danger">*</span>
        </label>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="productItemTypeRadio1"
            name="productItemTypeRadio"
            className="custom-control-input"
            value="general"
            checked={itemType === 'general'}
            onChange={(e) => handleItemTypeChange(e)}
          />
          <label className="custom-control-label" htmlFor="productItemTypeRadio1">
            General
          </label>
        </div>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="productItemTypeRadio2"
            name="productItemTypeRadio"
            className="custom-control-input"
            value="bundle"
            checked={itemType === 'bundle'}
            onChange={(e) => handleItemTypeChange(e)}
          />
          <label className="custom-control-label" htmlFor="productItemTypeRadio2">
            Bundle
          </label>
        </div>
      </div>

      {/* for bundle */}
      {itemType === 'bundle' && (
        <div className="form-col col-12 form-group">
          <label className="form-item-title">
            Assoiated Items<span className="tx-danger">*</span>
          </label>
          <div className="table-responsive bd rounded-top">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Item Name{' '}
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    SKU
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Unit Name
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Unit Quantity
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Contained Qty
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Base Price
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Base Cost Price
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Best Price
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr className="bg-light">
                  <td colSpan={5} className="text-right">
                    Total
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td>
                    <div className="input-group wd-300">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to select an item"
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open selecting item modal"
                        >
                          {Icon('product')}
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>ZG011AQA</td>
                  <td>Bottle</td>
                  <td>1</td>
                  <td>
                    <input type="number" className="form-control" />
                  </td>
                  <td>1,000 KRW</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <button type="button" className="btn tx-danger">
                      <X />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-10 bd bd-t-0 rounded-bottom">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type or click to select an item"
              />
              <div className="input-group-append">
                <button type="button" className="btn" aria-label="open selecting item modal">
                  {Icon('product')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* for prepaid */}
      {itemType === 'prepaid' && (
        <div className="form-col col-12 form-group">
          <label className="form-item-title">
            Items<span className="tx-danger">*</span>
          </label>
          <div className="table-responsive bd rounded-top">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Item Name
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Item Code
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Prepaid Amount
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Discount
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    More Info
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="input-group wd-300">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to select an item"
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open selecting item modal"
                        >
                          {Icon('product')}
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="input-group wd-200">
                      <input type="text" className="form-control" />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open code generation settings"
                        >
                          <Settings />
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="input-group wd-200">
                      <div className="input-group-prepend">
                        <span className="input-group-text">＄</span>
                      </div>
                      <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
                    </div>
                    <button type="button" className="btn btn-link">
                      <Plus className="mg-r-5" />
                      Add amount
                    </button>
                  </td>
                  <td>
                    <div className="d-flex">
                      <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
                      <SelectHook
                        className="wd-100-f flex-shrink-0 mg-r-5"
                        options={[{ value: 1, label: '%' }]}
                      />
                    </div>
                    <button type="button" className="btn btn-link text-nowrap">
                      <Copy className="mg-r-5" />
                      Copy All
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className={classNames('btn btn-link tx-nowrap', showMoreInfo ? 'opened' : '')}
                      onClick={() => setShowMoreInfo(!showMoreInfo)}
                    >
                      <Plus className="mg-r-5" />
                      {showMoreInfo ? 'Cancel' : 'Add'}
                    </button>
                  </td>
                  <td>
                    <button type="button" className="btn btn-icon tx-danger">
                      <X />
                    </button>
                  </td>
                </tr>
                {showMoreInfo && (
                  <tr>
                    <td colSpan={6} className="more-info-cell">
                      <div id="itemMoreInfo1" className="product-item-more-info">
                        <div className="form-row mg-0">
                          <div className="form-col col-lg-6">
                            <div className="form-group">
                              <label className="form-item-title">Warranty Period</label>
                              <div className="d-flex align-items-center wd-300 mx-wd-100p">
                                <input type="number" className="form-control mg-r-5" autoFocus />
                                <SelectHook options={[{ value: 1, label: 'year(s)' }]} />
                              </div>
                            </div>
                          </div>
                          <div className="form-col col-lg-6">
                            <div className="form-group">
                              <label className="form-item-title">Returnable Item</label>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="productReturnableItemSwitch1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="productReturnableItemSwitch1"
                                >
                                  Returnable
                                </label>
                              </div>
                              <div className="mg-t-5 tx-orange">
                                반품 가능 상품인 경우 자동으로 체크됩니다.
                              </div>
                            </div>
                          </div>
                          <div className="form-col col-lg-6">
                            <div className="form-group">
                              <label className="form-item-title">Description</label>
                              <textarea cols={10} rows={3} className="form-control"></textarea>
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-link mg-l-auto"
                          onClick={() => setShowMoreInfo(false)}
                        >
                          <X className="mg-r-5" />
                          Close more info
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <div className="input-group wd-300">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type or click to select an item"
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open selecting item modal"
                        >
                          {Icon('product')}
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="input-group wd-200">
                      <input type="text" className="form-control" />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open code generation settings"
                        >
                          <Settings />
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="input-group wd-200">
                      <div className="input-group-prepend">
                        <span className="input-group-text">＄</span>
                      </div>
                      <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
                    </div>
                    <button type="button" className="btn btn-link">
                      <Plus className="mg-r-5" />
                      Add amount
                    </button>
                  </td>
                  <td>
                    <div className="d-flex">
                      <input type="number" className="form-control form-control-lg wd-100 mg-r-5" />
                      <SelectHook
                        className="wd-100-f flex-shrink-0 mg-r-5"
                        options={[{ value: 1, label: '%' }]}
                      />
                    </div>
                  </td>
                  <td>
                    <button type="button" className="btn btn-link tx-nowrap">
                      <Plus className="mg-r-5" />
                      Add
                    </button>
                  </td>
                  <td>
                    <button type="button" className="btn btn-icon tx-danger">
                      <X />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-10 bd bd-t-0 rounded-bottom">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type or click to select an item"
              />
              <div className="input-group-append">
                <button type="button" className="btn" aria-label="open selecting item modal">
                  {Icon('product')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="form-col col-lg-6 form-group">
        <label className="d-block form-item-title">
          Base Unit<span className="tx-danger">*</span>
        </label>
        <div>Bottle</div>
        <div className="mg-t-5 tx-orange">상품의 Base Unit이 자동으로 적용됩니다.</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="d-block form-item-title">
          Unit Name<span className="tx-danger">*</span>
        </label>
        <div className="dropdown">
          <div className="input-group mg-r-10">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
              <div className="form-control-tag">
                <span className="form-control-tag-name">Bottle</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              <div className="form-control-tag">
                <span className="form-control-tag-name">Pack</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                placeholder="Type or click to select an unit name"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open option to select a uint name">
                <ChevronDown />
                <span className="sr-only">단위명 선택 옵션 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#newUnitNameModal"
            >
              <Plus className="mg-r-5" />
              Add new unit name
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              Box
            </button>
          </div>
        </div>
      </div>
      <div className="form-col col-12 form-group">
        <div className="d-flex align-items-center mg-b-5">
          <label className="form-item-title">
            Attributes<span className="tx-danger">*</span>
          </label>
          <button
            type="button"
            className="btn btn-outline-primary btn-icon mg-l-auto"
            aria-label="add new attribute"
            data-toggle="modal"
            data-target="#productItemAttributeModal"
          >
            <Plus className="mg-r-5" />
            New
            <span className="sr-only">새 속성 추가</span>
          </button>
        </div>
        <div className="row row-xs pd-15 bd rounded">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Attribute Name</label>
              <div>Flavour</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Value</label>
              <div className="dropdown">
                <div className="input-group mg-r-10">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="form-control-tag-name">Cherry</span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                      placeholder="Type or click to select a value"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open option to select a value"
                    >
                      <ChevronDown />
                      <span className="sr-only">단위명 선택 옵션 열기</span>
                    </button>
                  </div>
                </div>
                <div className="dropdown-menu wd-100p">
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#productAttributeValueModal"
                  >
                    <Plus className="mg-r-5" />
                    Add new attirubute value
                  </button>
                  <div className="dropdown-divider"></div>
                  <button type="button" className="dropdown-item">
                    Mint
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Attribute Name</label>
              <div>Size</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Value</label>
              <div className="dropdown">
                <div className="input-group mg-r-10">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="form-control-tag-name">S</span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <div className="form-control-tag">
                      <span className="form-control-tag-name">M</span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                      placeholder="Type or click to select a value"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open option to select a value"
                    >
                      <ChevronDown />
                      <span className="sr-only">단위명 선택 옵션 열기</span>
                    </button>
                  </div>
                </div>
                <div className="dropdown-menu wd-100p">
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#productAttributeValueModal"
                  >
                    <Plus className="mg-r-5" />
                    Add new attirubute value
                  </button>
                  <div className="dropdown-divider"></div>
                  <button type="button" className="dropdown-item">
                    L
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Attribute Name</label>
              <div className="wd-200 mx-wd-100p">
                <SelectHook options={[{ value: 1, label: 'Color' }]} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Value</label>
              <div className="d-flex align-items-center">
                <div className="input-group mg-r-10">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                      placeholder="Type or click to select a value"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open option to select a value"
                    >
                      <ChevronDown />
                      <span className="sr-only">단위명 선택 옵션 열기</span>
                    </button>
                  </div>
                </div>
                <button type="button" className="btn btn-icon tx-danger" aria-label="delete">
                  <X />
                  <span className="sr-only">삭제</span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <button type="button" className="btn btn-link pd-0" aria-label="add">
              <Plus className="mg-r-5" />
              Add an attribute
            </button>
            <div className="mg-t-5 tx-orange">
              상품의 Attributes가 자동으로 적용됩니다. Attribute 추가 시 상품 정보에 자동 적용됩니다
            </div>
          </div>
        </div>
      </div>
      {/* <div className="form-col col-lg-6 col-xl-4">
        <div className="form-group">
            <label className="d-block form-item-title">Pricing Type<span className="tx-danger">*</span></label>
            <div class="custom-control custom-radio d-inline-block mg-r-10">
                <input type="radio" id="productPricingTypeRadio1" name="productPricingTypeRadio" class="custom-control-input" defaultChecked />
                <label class="custom-control-label" for="productPricingTypeRadio1">Static Type</label>
            </div>
            <div class="custom-control custom-radio d-inline-block mg-r-10">
                <input type="radio" id="productPricingTypeRadio2" name="productPricingTypeRadio" class="custom-control-input" />
                <label class="custom-control-label" for="productPricingTypeRadio2">Time-based Type</label>
            </div>
        </div>
    </div>
    <div className="form-col col-lg-6 col-xl-4">
        <div className="form-group">
            <label className="form-item-title">Subscription</label>
            <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="productItemSubscription" />
                <label className="custom-control-label" for="productItemSubscription"></label>
            </div>
            <div className="mg-t-5 tx-orange">Create recurring sales orders and invoices automatically.</div>
        </div>
    </div>
    <div className="form-col col-lg-6 col-xl-4">
        <div className="form-group">
            <label className="form-item-title">Prepaid Item</label>
            <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="productItemPrepaidItem" />
                <label className="custom-control-label" for="productItemPrepaidItem"></label>
            </div>
            <div className="mg-t-5 tx-orange">Create recurring sales orders and invoices automatically.</div>
        </div>
    </div> */}
    </div>
  );
};

export default WriteItemStep1;
