/* eslint-disable prettier/prettier */
import { Plus } from 'react-feather';

import '@base/components/sidebar-menu/styles.scss';
import '@base/assets/scss/desktop/theme1/theme1.scss';
import { Link } from 'react-router-dom';

const Templates = () => {
  return (
    <>
      <div className="page-container-wrap">
        <div className="page-menu-wrap"></div>
        <div className="page-container-content">
          <div className="page-container-content-header">
            <h1 className="mg-x-10 mg-b-0 tx-16 tx-uppercase tx-normal">Business Process</h1>
            <button
              type="button"
              className="btn btn-primary mg-l-auto"
              data-toggle="modal"
              data-target="#"
            >
              <Plus className="mg-r-5" />
              New
            </button>
          </div>
          <div className="pd-15">
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f sorting">
                      Module
                    </th>
                    <th scope="col" className="bd-t-0-f sorting">
                      Process Name
                    </th>
                    <th scope="col" className="bd-t-0-f sorting">
                      Step
                    </th>
                    <th scope="col" className="bd-t-0-f sorting">
                      Rule Criteria
                    </th>
                    <th scope="col" className="bd-t-0-f sorting">
                      Product
                    </th>
                    <th scope="col" className="wd-100 bd-t-0-f sorting">
                      Create on
                    </th>
                    <th scope="col" className="wd-100 bd-t-0-f sorting">
                      Mode
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <Link to="/settings/desktop/theme1/process-view" state={{ name: 'po' }}>
                        Purchase Order
                      </Link>
                    </td>
                    <td>1</td>
                    <td>1</td>
                    <td>&nbsp;</td>
                    <td>2022-01-10</td>
                    <td>Draft</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <Link to="/settings/desktop/theme1/process-view" state={{ name: 'pr' }}>
                        Purchase Request
                      </Link>
                    </td>
                    <td>1</td>
                    <td>1</td>
                    <td>&nbsp;</td>
                    <td>2022-01-10</td>
                    <td>Draft</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <Link to="/settings/desktop/theme1/process-view" state={{ name: 'desk' }}>
                        Desk
                      </Link>
                    </td>
                    <td>1</td>
                    <td>1</td>
                    <td>&nbsp;</td>
                    <td>2022-01-10</td>
                    <td>Draft</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Templates;
