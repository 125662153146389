import React, { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Upload,
  Download,
  MoreHorizontal,
  X,
  RotateCcw,
  Settings,
  Menu,
  Calendar,
  Trello,
  AlertTriangle,
  User,
  ChevronLeft,
  ChevronRight,
} from 'react-feather';

import Pagination from '../../../component/desktop/pagination-new';
import { Button, FormIcon } from '@base/components/form';

const ActivityComparison = () => {
  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            data-han-tooltip="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Activity Comparison</h1>
          <div className="dropdown mg-l-5">
            <button type="button" className="btn btn-xs btn-white btn-icon" data-toggle="dropdown">
              <ChevronDown />
              <span className="sr-only">변경할 메뉴 열기</span>
            </button>
            <div className="dropdown-menu nav-sub"></div>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <Button
                color=""
                icon="ChevronDown"
                name="All Users"
                className="btn-filter pd-l-0"
                data-toggle="dropdown"
              />
            </div>

            <div className="dropdown list-filter-item">
              <Button
                color=""
                icon="ChevronDown"
                name="This Month"
                className="btn-filter pd-l-0"
                data-toggle="dropdown"
              />
            </div>

            <div className="dropdown list-filter-item">
              <Button
                color=""
                icon="ChevronDown"
                name="Filters"
                className="btn-filter pd-l-0"
                data-toggle="dropdown"
              />
            </div>
          </div>
          <div className="dropdown mg-l-auto">
            <Button
              color="white"
              name="Export as"
              icon="ChevronDown"
              isIconsEnd
              data-toggle="dropdown"
            />
          </div>
        </div>

        <div className="list-body">
          <div className="table-responsive bg-white bd rounded">
            <table className="table table-bordered dataTable mg-b-0 bd-0">
              <thead>
                <tr>
                  <th scope="col" className="sorting">
                    User Name
                  </th>
                  <th scope="col" className="sorting">
                    Outgoing Call
                  </th>
                  <th scope="col" className="sorting">
                    Incoming Call
                  </th>
                  <th scope="col" className="sorting">
                    All Call Duration
                  </th>
                  <th scope="col" className="sorting">
                    Sent Email
                  </th>
                  <th scope="col" className="sorting">
                    Received Email
                  </th>
                  <th scope="col" className="sorting">
                    Sent SMS
                  </th>
                  <th scope="col" className="sorting">
                    Task
                  </th>
                </tr>
              </thead>
              <tfoot className="bd-t bd-2">
                <tr>
                  <th scope="row" className="tx-right">
                    Total
                  </th>
                  <td>4,046</td>
                  <td>480</td>
                  <td>62:25:36</td>
                  <td>1,917</td>
                  <td>992</td>
                  <td>1,236</td>
                  <td>650</td>
                </tr>
                <tr>
                  <th className="bd-b-0-f tx-right">Percentage Change</th>
                  <td className="tx-success">
                    12%
                    <FormIcon icon="ArrowUp" className="mg-l-5" />
                  </td>
                  <td className="tx-danger">
                    25.3%
                    <FormIcon icon="ArrowDown" className="mg-l-5" />
                  </td>
                  <td className="tx-success">
                    12%
                    <FormIcon icon="ArrowUp" className="mg-l-5" />
                  </td>
                  <td className="tx-danger">
                    25.3%
                    <FormIcon icon="ArrowDown" className="mg-l-5" />
                  </td>
                  <td className="tx-success">
                    12%
                    <FormIcon icon="ArrowUp" className="mg-l-5" />
                  </td>
                  <td className="tx-danger">
                    25.3%
                    <FormIcon icon="ArrowDown" className="mg-l-5" />
                  </td>
                  <td className="tx-success">
                    12%
                    <FormIcon icon="ArrowUp" className="mg-l-5" />
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td>User 1</td>
                  <td>
                    961
                    <span className="tx-success mg-l-5">
                      <FormIcon icon="ArrowUp" />
                    </span>
                  </td>
                  <td>
                    120
                    <span className="tx-danger mg-l-5">
                      <FormIcon icon="ArrowDown" />
                    </span>
                  </td>
                  <td>
                    10:55:00
                    <span className="tx-success mg-l-5">
                      <FormIcon icon="ArrowUp" />
                    </span>
                  </td>
                  <td>
                    340
                    <span className="tx-danger mg-l-5">
                      <FormIcon icon="ArrowDown" />
                    </span>
                  </td>
                  <td>
                    134
                    <span className="tx-success mg-l-5">
                      <FormIcon icon="ArrowUp" />
                    </span>
                  </td>
                  <td>
                    268
                    <span className="tx-danger mg-l-5">
                      <FormIcon icon="ArrowDown" />
                    </span>
                  </td>
                  <td>
                    198
                    <span className="tx-success mg-l-5">
                      <FormIcon icon="ArrowUp" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityComparison;
