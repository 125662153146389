import {
  CornerUpLeft,
  Calendar,
  CornerUpRight,
  Edit2,
  ExternalLink,
  Mail,
  Phone,
  Download,
  PhoneCall,
  Check,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Timeline = () => {
  return (
    <div className="pd-20">
      <div className="new-timeline-group">
        <div className="d-flex justify-content-end">
          {/* Filters */}
          <div className="dropleft mg-r-5">
            <button type="button" className="btn btn-white rounded-30" data-toggle="dropdown">
              <span className="mg-r-5">{Icon('filter')}</span>Filters
            </button>
            <div className="dropdown-menu wd-400 pd-x-15 bg-white shadow-sm">
              <div className="form-group">
                <label className="d-block form-item-title">View type</label>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="btn btn-white active">
                    <input type="radio" name="filter-view-options" id="option1" defaultChecked />{' '}
                    Daily
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-options" id="option2" /> Monthly
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-options" id="option3" /> Yearly
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="d-block form-item-title">Date</label>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="btn btn-white active">
                    <input type="radio" name="filter-view-date" id="dateoption1" defaultChecked />{' '}
                    Today
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-date" id="dateoption2" /> This week
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-date" id="dateoption3" /> This month
                  </label>
                  <label className="btn btn-white">
                    <input type="radio" name="filter-view-date" id="dateoption4" /> This year
                  </label>
                </div>
                <div className="d-flex align-items-center mg-t-10">
                  <div className="input-group input-date-group dropdown">
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text bg-white tx-gray-300">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                  <span className="mg-x-10">~</span>
                  <div className="input-group input-date-group dropdown">
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text bg-white tx-gray-300">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Keyword</label>
                <input type="search" className="form-control" />
              </div>
              <div className="d-flex">
                <button type="button" className="btn btn-light flex-grow-1 mg-r-5">
                  Clear
                </button>
                <button type="button" className="btn btn-primary flex-grow-1">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="new-timeline-header">
          <div className="new-timeline-label">2021-03-17</div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Edit2 />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  Number of Employee was updated from <strong className="mg-x-5">10</strong> to{' '}
                  <strong className="mg-x-5">50</strong> by MSR
                </span>
                <span className="mg-l-auto tx-color-03">15:28</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
