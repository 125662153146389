import { Button, Select } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { ChevronUp } from 'react-feather';

const AddReviewer = () => {
  return (
    <div
      id="addReviewer"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Add a Reviewer to analyze</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="d-flex align-items-center">
              <Select
                options={[
                  { value: 'user', label: 'CRM User' },
                  { value: 'account', label: 'Account' },
                  { value: 'customer', label: 'Customer' },
                ]}
                defaultValue={{ value: 'user', label: 'CRM User' }}
                width={200}
                className="mg-r-5"
              />
              <UserAutoComplete single showAvatar />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Cancel
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Add New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReviewer;
