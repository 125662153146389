import noImg from '@base/assets/img/notfound.png';

const ProductDetailView = () => {
  return (
    <>
      <div className="table-responsive bg-white bd rounded">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Image
              </th>
              <th scope="col" className="bd-t-0-f">
                Item
              </th>
              <th scope="col" className="bd-t-0-f">
                Purchase Unit
              </th>
              <th scope="col" className="bd-t-0-f">
                Purchase Price
              </th>
              <th scope="col" className="bd-t-0-f">
                Quantity
              </th>
              <th scope="col" className="bd-t-0-f">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="avatar avatar-sm bg-gray-200 rounded">
                  <img src={noImg} alt="" />
                </div>
              </td>
              <td>Item 1</td>
              <td>Pallet</td>
              <td>￦10,000</td>
              <td>100</td>
              <td>￦1,000,000</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row row-xs mg-y-10">
        <div className="col-md-6">
          <ul className="list-unstyled lh-7 mg-b-0">
            <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
              <span>Items : 100</span>
              <span> ￦1,000,000</span>
            </li>
            <li className="d-flex align-items-center justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
              <span>Discount</span>
              <span> ￦0</span>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
            <li className="d-flex justify-content-between">
              <span>Sub-Total</span>
              <span>￦1,000,000</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>Tax</span>
              <span>￦100,000</span>
            </li>
            <li className="d-flex align-items-center">
              <span>Adjustment</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>Round Off</span>
              <span>&nbsp;</span>
            </li>
            <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
              <strong>Total</strong>
              <strong>￦1,100,000</strong>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProductDetailView;
