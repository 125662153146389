import { Clock, Edit2, X } from 'react-feather';

const NoteList = () => {
  return (
    <div className="pd-15">
      <input type="text" className="form-control" placeholder="Enter a note" />

      <div className="timeline-group note-timeline mg-t-20">
        <div className="timeline-label">2021-03-17</div>
        <div className="timeline-item">
          <div className="timeline-icon">
            <img
              src="https://n.hanbiro.com/ncrm/user/photo/877/1"
              className="rounded-circle"
              alt="MSR"
            />
          </div>
          <div className="timeline-body">
            <div className="card">
              <div className="d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bd-b">
                <span>
                  MSR
                  <span className="mg-l-10 tx-color-03">
                    <Clock size="16" className="mg-r-5" />
                    13:00
                  </span>
                </span>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link btn-icon" data-han-tooltip="Edit">
                    <Edit2 />
                    <span className="sr-only">수정</span>
                  </button>
                  <button type="button" className="btn btn-link btn-icon" data-han-tooltip="Delete">
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
              <div className="pd-15">Follow up with KDH.</div>
            </div>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-icon">
            <img
              src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
              className="rounded-circle"
              alt="MSR"
            />
          </div>
          <div className="timeline-body">
            <div className="card">
              <div className="d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bd-b">
                <span>
                  KDH
                  <span className="mg-l-10 tx-color-03">
                    <Clock size="16" className="mg-r-5" />
                    10:00
                  </span>
                </span>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link btn-icon" data-han-tooltip="Edit">
                    <Edit2 />
                    <span className="sr-only">수정</span>
                  </button>
                  <button type="button" className="btn btn-link btn-icon" data-han-tooltip="Delete">
                    <X className="tx-danger" />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
              </div>
              <div className="pd-15">The demo location is changed.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteList;
