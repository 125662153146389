/* eslint-disable prettier/prettier */
import { ChevronDown, Plus, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';

const Criteria = () => {
	return (
		<div>
			<div className="pd-15 bg-white bd-3 bd-primary bd-l rounded shadow-sm">
				<div className="d-flex align-items-center mg-b-10">
					<SelectHook className="wd-200-f" />
					<button type="button" className="btn btn-icon mg-l-auto">
						<X className="tx-danger" />
						<span className="sr-only">삭제</span>
					</button>
				</div>
				<div className="d-flex flex-wrap">
					<div className="flex-grow-1">
						<ul className="criteria-list">
							<li>
								<div className="d-flex flex-wrap align-items-center flex-grow-1 pd-t-5 pd-b-10 bg-white bd-b bd-dashed bd-x-0 bd-t-0">
									<SelectHook className="wd-200-f mg-r-5" />
									<SelectHook
										className="wd-100-f mg-r-5"
										options={[{ value: 1, label: 'Equals' }]}
									/>
									<input type="text" className="form-control wd-300 mg-r-5" />
									<button type="button" className="btn btn-icon mg-l-auto">
										<X className="tx-danger" />
										<span className="sr-only">삭제</span>
									</button>
								</div>
							</li>
							<li>
								<div className="d-flex align-items-center flex-wrap">
									<div className="dropdown mg-r-5">
										<button
											type="button"
											className="btn btn-lg btn-white bg-light rounded-30"
											data-toggle="dropdown"
										>
											And
											<ChevronDown className="tx-color-03" />
										</button>
										<div className="dropdown-menu">
											<button type="button" className="dropdown-item">
												And
											</button>
											<button type="button" className="dropdown-item">
												OR
											</button>
										</div>
									</div>
									<div className="d-flex flex-wrap align-items-center flex-grow-1 pd-t-5 pd-b-10 bg-white bd-b bd-dashed bd-x-0 bd-t-0">
										<SelectHook className="wd-200-f mg-r-5" />
										<SelectHook
											className="wd-100-f mg-r-5"
											options={[{ value: 1, label: 'Equals' }]}
										/>
										<input type="text" className="form-control wd-300 mg-r-5" />
										<button type="button" className="btn btn-icon mg-l-auto">
											<X className="tx-danger" />
											<span className="sr-only">삭제</span>
										</button>
									</div>
								</div>
							</li>
							<li>
								<div className="d-flex align-items-center flex-wrap">
									<div className="dropdown mg-r-5">
										<button
											type="button"
											className="btn btn-lg btn-white bg-light rounded-30"
											data-toggle="dropdown"
										>
											And
											<ChevronDown className="tx-color-03" />
										</button>
										<div className="dropdown-menu">
											<button type="button" className="dropdown-item">
												And
											</button>
											<button type="button" className="dropdown-item">
												OR
											</button>
										</div>
									</div>
									<div className="d-flex flex-wrap align-items-center flex-grow-1 pd-t-5 pd-b-10 bg-white bd-b bd-dashed bd-x-0 bd-t-0">
										<SelectHook className="wd-200-f mg-r-5" />
										<SelectHook
											className="wd-150-f mg-r-5"
											options={[{ value: 1, label: 'Is grater than' }]}
										/>
										<input type="text" className="form-control wd-300 mg-r-5" />
										<button type="button" className="btn btn-icon mg-l-auto">
											<X className="tx-danger" />
											<span className="sr-only">삭제</span>
										</button>
									</div>
								</div>
							</li>
							<li>
								<div className="dropdown">
									<button type="button" className="btn btn-white rounded-30" data-toggle="dropdown">
										<Plus className="tx-primary" />
										<span className="mg-x-10">Add</span>
										<ChevronDown className="tx-color-03" />
									</button>
									<div className="dropdown-menu">
										<button type="button" className="dropdown-item">
											And
										</button>
										<button type="button" className="dropdown-item">
											OR
										</button>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div className="d-flex flex-column align-items-center justify-content-center wd-150 mg-l-10 bg-light rounded">
						<strong className="tx-20">150</strong>members
					</div>
				</div>
			</div>

			<div className="add-criteria-block">
				<button
					type="button"
					className="btn btn-lg btn-white bg-light rounded-30"
					data-toggle="dropdown"
				>
					And
					<ChevronDown className="tx-color-03" />
				</button>
				<div className="dropdown-menu">
					<button type="button" className="dropdown-item">
						And
					</button>
					<button type="button" className="dropdown-item">
						OR
					</button>
					<button type="button" className="dropdown-item">
						Exclude
					</button>
				</div>
			</div>

			<div className="pd-15 bg-white bd-3 bd-indigo bd-l rounded shadow-sm">
				<div className="d-flex align-items-center mg-b-10">
					<SelectHook className="wd-200-f" />
					<button type="button" className="btn btn-icon mg-l-auto">
						<X className="tx-danger" />
						<span className="sr-only">삭제</span>
					</button>
				</div>
				<div className="d-flex flex-wrap">
					<div className="flex-grow-1">
						<ul className="criteria-list">
							<li>
								<div className="d-flex flex-wrap align-items-center flex-grow-1 pd-t-5 pd-b-10 bg-white bd-b bd-dashed bd-x-0 bd-t-0">
									<SelectHook className="wd-200-f mg-r-5" />
									<SelectHook
										className="wd-100-f mg-r-5"
										options={[{ value: 1, label: 'Equals' }]}
									/>
									<input type="text" className="form-control wd-300 mg-r-5" />
									<button type="button" className="btn btn-icon mg-l-auto">
										<X className="tx-danger" />
										<span className="sr-only">삭제</span>
									</button>
								</div>
							</li>
							<li>
								<div className="d-flex align-items-center flex-wrap">
									<div className="dropdown mg-r-5">
										<button
											type="button"
											className="btn btn-lg btn-white bg-light rounded-30"
											data-toggle="dropdown"
										>
											And
											<ChevronDown className="tx-color-03" />
										</button>
										<div className="dropdown-menu">
											<button type="button" className="dropdown-item">
												And
											</button>
											<button type="button" className="dropdown-item">
												OR
											</button>
										</div>
									</div>
									<div className="d-flex flex-wrap align-items-center flex-grow-1 pd-t-5 pd-b-10 bg-white bd-b bd-dashed bd-x-0 bd-t-0">
										<SelectHook className="wd-200-f mg-r-5" />
										<SelectHook
											className="wd-150-f mg-r-5"
											options={[{ value: 1, label: 'Is grater than' }]}
										/>
										<input type="text" className="form-control wd-300 mg-r-5" />
										<button type="button" className="btn btn-icon mg-l-auto">
											<X className="tx-danger" />
											<span className="sr-only">삭제</span>
										</button>
									</div>
								</div>
							</li>
							<li>
								<div className="dropdown">
									<button type="button" className="btn btn-white rounded-30" data-toggle="dropdown">
										<Plus className="tx-primary" />
										<span className="mg-x-10">Add</span>
										<ChevronDown className="tx-color-03" />
									</button>
									<div className="dropdown-menu">
										<button type="button" className="dropdown-item">
											And
										</button>
										<button type="button" className="dropdown-item">
											OR
										</button>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div className="d-flex flex-column align-items-center justify-content-center wd-150 mg-l-10 bg-light rounded">
						<strong className="tx-20">200</strong>members
					</div>
				</div>
			</div>

			<div className="dropdown mg-t-15">
				<button type="button" className="btn btn-link" data-toggle="dropdown">
					<Plus className="mg-r-5" />
					Add a block
				</button>
				<div className="dropdown-menu">
					<button type="button" className="dropdown-item">
						And
					</button>
					<button type="button" className="dropdown-item">
						OR
					</button>
					<button type="button" className="dropdown-item">
						Exclude
					</button>
				</div>
			</div>

			<div className="d-flex">
				<div className="mg-l-auto pd-t-5 bd-2 bd-t bd-success tx-success">
					Final<strong className="mg-x-10 tx-24">100</strong>members
				</div>
			</div>
		</div>
	);
};

export default Criteria;
