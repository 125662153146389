import React from 'react';
import { ChevronDown, Folder, Plus, Settings, User, X } from 'react-feather';
import { DatePicker } from '@base/components/date-picker';

const WriteBasicInfo = () => {
  return (
    <>
      <div className="text-right">
        <span className="tx-danger">*</span> is required field.
      </div>
      <hr />
      <div className="form-group">
        <label className="d-block form-item-title">
          Supply Method<span className="tx-danger">*</span>
        </label>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="supplyMethodRadio1"
            name="supplyMethodRadio"
            className="custom-control-input"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="supplyMethodRadio1">
            Produced
          </label>
        </div>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="supplyMethodRadio2"
            name="supplyMethodRadio"
            className="custom-control-input"
          />
          <label className="custom-control-label" htmlFor="supplyMethodRadio2">
            Purchase
          </label>
        </div>
        <div className="custom-control custom-checkbox mg-t-5">
          <input type="checkbox" id="supplyMethodCheckbox" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="supplyMethodCheckbox">
            Can be sold
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">
          Product Name<span className="tx-danger">*</span>
        </label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">Product Group</label>
        <div className="dropdown wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select a product group"
            />

            {/*  after selecting a group : single value
                    <div className="form-control d-flex align-items-center justify-content-between">
                        <span className="text-truncate">SW/CRM/Public Sever</span>
                        <button type="button" className="btn" aria-label="delete">
                            <X />
                            <span className="sr-only">삭제</span>
                        </button>
                    </div>
                    */}

            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open selecting group modal">
                <Folder />
                <span className="sr-only">그룹 선택 팝업 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#productGroupModal"
            >
              <Plus className="mg-r-5" />
              Add new product group
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              SW/CRM/Public Sever
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">Product ID</label>
        <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <input type="text" className="form-control" />
          <div className="input-group-append">
            <button
              type="button"
              className="btn"
              aria-label="open generator of code modal"
              data-toggle="modal"
              data-target="#productGenCodeModal"
            >
              <Settings />
              <span className="sr-only">코드 생성 설정 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">
          Sales Start Date<span className="tx-danger">*</span>
        </label>
        <div className="wd-200">
          <DatePicker
            selected={new Date()}
            onChange={() => {
              console.log();
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">
          Sales End Date<span className="tx-danger">*</span>
        </label>
        <div className="d-flex flex-wrap align-items-center">
          <div className="wd-200">
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
          <div className="custom-control custom-switch mg-l-10">
            <input type="checkbox" className="custom-control-input" id="salesEndDateSwitch" />
            <label className="custom-control-label" htmlFor="salesEndDateSwitch">
              No End Date
            </label>
          </div>
        </div>
        <div className="mg-t-5 tx-orange">
          판매 종료일 지정 시 해당 날짜에 자동으로 판매 중단됩니다.
        </div>
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">Active</label>
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id="productActiveSwitch" />
          <label className="custom-control-label" htmlFor="productActiveSwitch"></label>
        </div>
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">
          Base unit<span className="tx-danger">*</span>
        </label>
        <div className="dropdown wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select a base unit"
            />
            {/*  after selecting a base unit : single value
                    <div className="form-control d-flex align-items-center justify-content-between">
                        <span className="text-truncate">Bottle</span>
                        <button type="button" className="btn" aria-label="delete">
                            <X />
                            <span className="sr-only">삭제</span>
                        </button>
                    </div>
                    */}
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open selecting unit modal">
                <Folder />
                <span className="sr-only">유닛 선택 팝업 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#newUnitModal"
            >
              <Plus className="mg-r-5" />
              Add new base unit
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              Bottle
            </button>
          </div>
        </div>
        <div className="mg-t-5 tx-orange">
          Subscription Item 생성하려면 Subscription 타입의 Base Unit을 선택하세요.
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Attributes</label>
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id="productAttribute" />
          <label className="custom-control-label" htmlFor="productAttribute">
            Create Item with Attributes
          </label>
        </div>
        <div className="dropdown mg-t-5">
          <div className="input-group mg-r-10">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
              <div className="form-control-tag">
                <span className="form-control-tag-name">Color</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              <div className="form-control-tag">
                <span className="form-control-tag-name">Size</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                placeholder="Type or click to select a attribute"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open option to select a attribute">
                <ChevronDown />
                <span className="sr-only">속성 선택 팝업 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#productAttributeModal"
            >
              <Plus className="mg-r-5" />
              Add new attribute
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              Flavour
            </button>
          </div>
        </div>
        <div className="mg-t-5 tx-orange">
          상품 속성을 입력합니다. General Type Item생성 사용할 수 있습니다.
        </div>
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">Cost of Goods</label>
        <div className="d-flex flex-wrap align-items-center">
          Approximatley
          <div className="input-group wd-150 mg-x-10">
            <input type="number" className="form-control" defaultValue={30} />
            <div className="input-group-append">
              <span className="input-group-text lh-1">%</span>
            </div>
          </div>
          of Base Price
        </div>
        <div className="mg-t-5 tx-orange">
          The Cost to physically produce/purchase/service/delivery
        </div>
      </div>
      <div className="form-group">
        <label className="d-block form-item-title">Assigned Rep</label>
        <div className="input-group">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="d-block avatar avatar-xs mg-r-10">
                <img
                  src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                  alt="John"
                  className="img rounded-circle"
                />
              </span>
              <span className="form-control-tag-name">John</span>
              <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                <X />
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250 rounded"
              placeholder="Type or click to select a user"
            />
          </div>
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open modal to select aㅜ user">
              <User />
              <span className="sr-only">담당자 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WriteBasicInfo;
