import { Settings } from 'react-feather';
import { Input, Select } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';
import ProductDetail from './containers/product-detail/write';
import Delivery from './containers/delivery/write';

// import "PurchaseCss/purchase.scss"
const POWrite = () => {
  return (
    <>
      <div className="text-right">
        <span className="tx-danger">*</span>is required.
      </div>
      <div className="form-row">
        <div className="col-lg-12 form-group">
          <label className="form-item-title">
            Subject<span className="tx-danger">*</span>
          </label>
          <Input />
        </div>
        <div className="col-lg-6 form-group">
          <label className="form-item-title">PO ID</label>
          <Input rightIcon={<Settings />} />
        </div>
        <div className="col-lg-6 form-group">
          <label className="form-item-title">PO Date</label>
          <div className="wd-150">
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
        </div>
        <div className="col-lg-6 form-group">
          <label className="form-item-title">
            Vendor Name
            <span className="tx-danger">*</span>
          </label>
          <CustomerAutoComplete
            placement="right"
            single={true}
            showAvatar={true}
            visible={false}
            placeholder="Type or click to select a vendor"
            category=""
            value={[]}
            onChange={() => null}
          />
        </div>
        <div className="col-lg-6 form-group">
          <label className="form-item-title">
            Contact<span className="tx-danger">*</span>
          </label>
          <CustomerAutoComplete
            placement="right"
            single={true}
            showAvatar={true}
            visible={false}
            placeholder="Type or click to select a contact"
            category=""
            value={[]}
            onChange={() => null}
          />
        </div>
        <div className="col-lg-6 form-group">
          <label className="form-item-title">Payment Term</label>
          <Select />
        </div>
        <div className="col-lg-6 form-group">
          <label className="form-item-title">Expected Delivery Date</label>
          <div className="wd-150">
            <DatePicker
              selected={new Date()}
              onChange={() => {
                console.log();
              }}
            />
          </div>
        </div>
        <Delivery />
        <div className="col-lg-6 form-group">
          <label className="form-item-title">Description</label>
          <Input />
        </div>
        <ProductDetail />
        <div className="col-6 form-group">
          <label className="form-item-title">Terms and conditions</label>
          <Select defaultValue={[{ value: 1, label: 'Due on Receipt' }]} />
        </div>
        <div className="col-6 form-group">
          <label className="form-item-title">Vendor Note</label>
          <Input />
        </div>
      </div>
    </>
  );
};

export default POWrite;
