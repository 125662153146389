import { Mail, Phone, X } from 'react-feather';

const AttendeeDetail = () => {
  return (
    <div
      id="attendeeDetail"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Attendee Detail</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="d-flex mg-b-20">
              <div className="avatar wd-100 mg-r-20" style={{ height: '100px' }}>
                <img
                  src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                  className="rounded-circle"
                />
              </div>
              <div>
                <div className="pd-y-10 text-truncate tx-semibold tx-16">Allan Rey Palban</div>
                <div className="mg-b-10 text-truncate">
                  <Mail className="tx-teal mg-r-10" size={16} />
                  <a href="mailto:sales@domain.com">sales@domain.com</a>
                </div>
                <div className="text-truncate">
                  <Phone className="tx-primary mg-r-10" size={16} />
                  <a href="tel:1 518-445-5696">+1 518-445-5696</a>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Company Name</label>
              <div>Adventure Works</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Status</label>
              <div>Attended</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Event Check-in</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Event Check-out</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Registered on</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">QR Code</label>
              <div>&nbsp;</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Timeline</label>
              <div className="timeline-group dropdown-timeline">
                <div className="timeline-label">2021/12/03</div>
                <div className="timeline-item">
                  <div className="timeline-time">10:30 am</div>
                  <div className="timeline-body">
                    <p>CTA 1 clicked</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-time">10:31 am</div>
                  <div className="timeline-body">
                    <p>Page 1 viewed</p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-time">10:35 am</div>
                  <div className="timeline-body">
                    <p>CTA 2 clicked</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeDetail;
