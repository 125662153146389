import { useState } from 'react';
import { COLORS } from '@base/config/constant';
import { Trash, ChevronDown, Plus, ArrowLeft } from 'react-feather';

interface PurchaseHeaderProps {
  title?: string;
  groupBy?: string;
  setOpenRight?: any;
}

const PurchaseHeader = ({ title, groupBy, setOpenRight }: PurchaseHeaderProps) => {
  const [headerColor, setHeaderColor] = useState<any>(COLORS[0]);
  return (
    <div className={`view-header bg-${headerColor.value}`}>
      <a href="/purchase/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
        <ArrowLeft />
        <span className="sr-only">목록으로</span>
      </a>

      {/* move to another submenu */}
      <div className="dropdown bd-x">
        <button
          type="button"
          className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
          data-toggle="dropdown"
        >
          Purchase
          <ChevronDown className="mg-l-5" />
        </button>
      </div>

      <h1 className="view-title mg-l-10 lh-1">
        <small className="d-block tx-12 tx-color-03">{groupBy}</small>
        {title}
      </h1>

      <div className="button-group d-flex flex-shrink-0 mg-l-auto">
        <div className="process-buttons mg-r-15">
          <div className="dropdown">
            <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
              More
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <button type="button" className="dropdown-item">
                <span className="dropdown-item-icon">
                  <Trash />
                </span>
                Delete
              </button>
            </div>
          </div>
        </div>

        <div className="btn-group dropdown">
          <button
            type="button"
            className="btn btn-primary mg-l-5 pd-x-10"
            data-toggle="modal"
            data-target="#newPR"
            onClick={() => setOpenRight(false)}
          >
            <Plus />
            <span className="sr-only">추가</span>
          </button>
          <button
            type="button"
            className="btn btn-primary dropdown-toggle-split pd-x-5"
            data-toggle="dropdown"
          >
            <ChevronDown />
            <span className="sr-only">추가 옵션 열기</span>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#newPR"
              onClick={() => setOpenRight(false)}
            >
              Purchase Request
            </button>
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#newPO"
              onClick={() => setOpenRight(false)}
            >
              Purchase Order
            </button>
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#newPurchaseReturn"
            >
              Purchase Return
            </button>
          </div>
        </div>
      </div>

      <div className="dropdown mg-l-10 pd-l-10 bd-l">
        <button
          type="button"
          data-han-tooltip="Change the background color"
          className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
          data-toggle="dropdown"
        >
          <span className="sr-only">배경색 변경</span>
        </button>
        <ul className="dropdown-menu header-bg-color-list">
          {COLORS.map((item, index) => (
            <li key={index}>
              <button
                className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                onClick={(e) => {
                  setHeaderColor(item);
                }}
              >
                &nbsp;
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PurchaseHeader;
