import { Switch } from '@base/components/form';

const DetailInfo = () => {
  return (
    <div className="form-row mg-0 pd-15">
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Linked Barcode</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Dimensisions</label>
        <div>10 x 20 x 13 cm</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Weight</label>
        <div>0.3 kg</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Warranty Period</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Country of Origin</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Brand</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Manufacture</label>
        <div>&nbsp;</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Retrunable Item</label>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="returnable-01"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="returnable-01"></label>
        </div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Sales Comission</label>
        <Switch title="Comissionable" />
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Sales Comission Type</label>
        <div>Residual(Subscription)</div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">Description</label>
        <div>&nbsp;</div>
      </div>
    </div>
  );
};

export default DetailInfo;
