/* eslint-disable prettier/prettier */
import { useState } from 'react';
import classnames from 'classnames';
import Icon from '@base/assets/icons/svg-icons';
import noImg from '@base/assets/img/notfound.png';
import { ChevronDown, Plus, X } from 'react-feather';
import { Button } from '@base/components/form';

// import "OrderCss/sales_order.scss"

const Order = () => {
  const [showLimit, setShowLimit] = useState<any>();

  const [specificItem, setSpecificItem] = useState<any>();
  const [addSpecificItem, setAddSpecificItem] = useState<any>();

  return (
    <div className="card-columns settings-columns">
      {/* Quantity Limitations */}
      <div className="card">
        <div className="card-header bg-light">
          <div className="card-header-title">
            Quantity Limitations
            <br />
            <span className="tx-13 tx-color-03">
              Set up the single order item s min/max quantity limit.
            </span>
          </div>
          <div className="card-header-actions">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="limitationSwitch"
                checked={showLimit}
                onChange={() => setShowLimit(!showLimit)}
              />
              <label className="custom-control-label" htmlFor="limitationSwitch"></label>
            </div>
            {showLimit && (
              <button
                type="button"
                className="btn btn-xs btn-link link-03 pd-5"
                data-toggle="collapse"
                data-target="#QtyLimitations"
              >
                <ChevronDown />
              </button>
            )}
          </div>
        </div>
        {showLimit && (
          <div id="QtyLimitations" className="card-body collapse show">
            <div className="form-group">
              <label className="form-item-title d-block">Limit Quantity to Order</label>
              <div className="d-flex flex-wrap align-items-center">
                <div className="input-group wd-250 mg-r-20">
                  <div className="input-group-prepend">
                    <span className="input-group-text lh-1">Minimum</span>
                  </div>
                  <input type="number" className="form-control" />
                </div>
                <div className="input-group wd-250">
                  <div className="input-group-prepend">
                    <span className="input-group-text lh-1">Maximum</span>
                  </div>
                  <input type="number" className="form-control" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title d-block">Item to Limit</label>
              {specificItem && addSpecificItem && (
                <div className="table-responsive bd rounded mg-b-10">
                  <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                    <thead>
                      <tr>
                        <th scope="col" className="bd-t-0-f">
                          Image
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Item
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Unit
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Unit Qty
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Attribute
                        </th>
                        <th scope="col" className="bd-t-0-f">
                          Rate
                        </th>
                        <th scope="col" className="wd-70 bd-t-0-f">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="avatar avatar-sm bg-gray-200 rounded">
                            <img src={noImg} alt="" />
                          </div>
                        </td>
                        <td>AAA</td>
                        <td>Bottle</td>
                        <td>1</td>
                        <td>S, Apple</td>
                        <td>&nbsp;</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-icon"
                            onClick={() => setAddSpecificItem(false)}
                          >
                            <X className="tx-danger" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              <div className="dropdown">
                <div className="input-group">
                  {specificItem ? (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type or click to select an item"
                    />
                  ) : (
                    <div className="form-control pd-y-1-f d-flex align-items-center">
                      <span className="text-truncate">All</span>
                      <button
                        type="button"
                        className="btn mg-l-auto"
                        onClick={() => setSpecificItem(true)}
                      >
                        <X />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                  )}
                  <div className="input-group-append">
                    <button type="button" className="btn">
                      {Icon('product')}
                      <span className="sr-only">선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
                <div
                  className={classnames('dropdown-menu wd-100p', {
                    show: specificItem && !addSpecificItem,
                  })}
                >
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => setSpecificItem(false)}
                  >
                    All
                  </button>
                  <div className="dropdown-divider"></div>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => setAddSpecificItem(true)}
                  >
                    AAA
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Return/Replace Reason */}
      <div className="card">
        <div className="card-header bg-light">
          <div className="card-header-title">Return/Replace Reason</div>
          <div className="card-header-actions">
            <button
              type="button"
              className="btn btn-xs btn-link link-03 pd-5"
              data-toggle="collapse"
              data-target="#reasonSection"
            >
              <ChevronDown />
            </button>
          </div>
        </div>
        <div id="reasonSection" className="collapse show table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col">Reason</th>
                <th scope="col" className="wd-100">
                  Set as Default{' '}
                </th>
                <th scope="col" className="wd-70">
                  Use
                </th>
                <th scope="col" className="wd-70">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="text" className="form-control" defaultValue="Damaged Items" />
                </td>
                <td className="text-center">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="reasonDefaultRadio1"
                      id="reasonDefaultRadio1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="reasonDefaultRadio1"></label>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="reasonUseSwitch1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="reasonUseSwitch1"></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex align-items-center">
          <input type="text" className="form-control mg-r-10" placeholder="Enter the reason" />
          <button type="button" className="btn btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* Terms and Conditions  */}
      <div className="card">
        <div className="card-header bg-light">
          <div className="card-header-title">Terms and Conditions</div>
          <div className="card-header-actions">
            <button
              type="button"
              className="btn btn-xs btn-link link-03 pd-5"
              data-toggle="collapse"
              data-target="#termsSection"
            >
              <ChevronDown />
            </button>
          </div>
        </div>
        <div id="termsSection" className="collapse show table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col" className="wd-200">
                  Name
                </th>
                <th scope="col">Terms and Conditions</th>
                <th scope="col" className="wd-100">
                  Set as Default{' '}
                </th>
                <th scope="col" className="wd-70">
                  Use
                </th>
                <th scope="col" className="wd-70">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Terms and Conditions 1"
                  />
                </td>
                <td>
                  <textarea cols={10} rows={3} className="form-control"></textarea>
                </td>
                <td className="text-center">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="termsDefaultRadio1"
                      id="termsDefaultRadio1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="termsDefaultRadio1"></label>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="termsUseSwitch1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="termsUseSwitch1"></label>
                  </div>
                </td>
                <td>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon pd-0"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex align-items-center">
          <input type="text" className="form-control mg-r-10" placeholder="Enter the name" />
          <button type="button" className="btn btn-primary flex-shrink-0">
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>

      {/* Customer Note */}
      <div className="card">
        <div className="card-header bg-light">
          <div className="card-header-title">Customer Note</div>
          <div className="card-header-actions">
            <button
              type="button"
              className="btn btn-xs btn-link link-03 pd-5"
              data-toggle="collapse"
              data-target="#customerNote"
            >
              <ChevronDown />
            </button>
          </div>
        </div>
        <div id="customerNote" className="card-body collapse show">
          <textarea cols={10} rows={3} className="form-control"></textarea>
        </div>
      </div>
    </div>
  );
};

export default Order;
