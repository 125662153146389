import { Button, Switch } from '@base/components/form';
import NewRuleEntry from './modal/newRuleEntry';
const AssignmentRuleDetail = (props: any) => {
  return (
    <div className="card">
      <div className="card-header d-flex align-items-center pd-10">
        <Button
          color="link"
          icon="ArrowLeft"
          onClick={() => props.setShowDetail(false)}
          className="btn-icon"
        />
        <h2 className="h6 mg-0">Rule 1</h2>
      </div>
      <div className="card-body">
        <div className="row row-xs">
          <div className="form-group col-lg-6">
            <label className="form-item-title mg-r-10 mg-b-0">Module</label>
            <span>Desk</span>
          </div>
          <div className="form-group col-lg-6 d-flex align-items-center">
            <label className="form-item-title mg-r-10 mg-b-0">Active</label>
            <Switch />
          </div>
          <div className="form-group col-12">
            <label className="form-item-title mg-r-10 mg-b-0">Description</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group col-lg-6 d-flex align-items-center">
            <label className="form-item-title mg-r-10 mg-b-0">Created by</label>
            <span className="d-inline-block avatar avatar-xs mg-r-10">
              <img
                src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                alt="MSR"
                className="rounded-circle"
              />
            </span>
            <span className="d-inline-block mg-r-10">MSR</span>
            <span>2022-08-29</span>
          </div>
          <div className="form-group col-lg-6 d-flex align-items-center">
            <label className="form-item-title mg-r-10 mg-b-0">Modified by</label>
            <span className="d-inline-block avatar avatar-xs mg-r-10">
              <img
                src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                alt="MSR"
                className="rounded-circle"
              />
            </span>
            <span className="d-inline-block mg-r-10">MSR</span>
            <span>2022-08-29</span>
          </div>
          <div className="form-group col-12">
            <div className="d-flex align-items-center mg-b-5">
              <label className="form-item-title mg-b-0">Rule Entries</label>
              <Button
                color="primary"
                size="xs"
                outline
                className="btn-icon rounded-0 mg-l-auto"
                icon="Plus"
                data-toggle="modal"
                data-target="#newRuleEntry"
              />
            </div>
            <div className="table-responsive bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                <thead>
                  <tr>
                    <th scope="col">Order</th>
                    <th scope="col">Criteria</th>
                    <th scope="col">Assign to</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <NewRuleEntry />
    </div>
  );
};

export default AssignmentRuleDetail;
