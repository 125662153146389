import Icon, { SvgIcons } from '@base/assets/icons/svg-icons';
import { ChevronDown, Download, UploadCloud, X } from 'react-feather';

const Attachments = () => {
  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <button type="button" className="view-aside-section-move mg-r-5">
          {Icon('move')}
          <span className="sr-only">드래그앤드롭으로 이동</span>
        </button>
        <span className="view-aside-section-header-title">Attachments</span>
        <button
          type="button"
          className="btn btn-link link-03 mg-l-auto pd-0"
          data-toggle="collapse"
          data-target="#customerAttachment"
        >
          <ChevronDown />
        </button>
      </div>
      <div id="customerAttachment" className="view-aside-section-body collapse show">
        <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-5 bg-light rounded">
          <div
            className="media align-items-center flex-grow-1"
            style={{ maxWidth: 'calc(100% - 72px' }}
          >
            <span className="file-type-icon">
              <SvgIcons type="doc" />
            </span>
            <div className="media-body mg-l-10 text-truncate">
              계약서계약서계약서계약서계약서계약서계약서계약서계약서계약서.doc
              <small className="d-block text-muted text-truncate">
                (18.94 KB) 2019/11/02 12:00:12
              </small>
            </div>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Download"
            >
              <Download /> <span className="sr-only">내려받기</span>
            </button>
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Delete"
            >
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-5 bg-light rounded">
          <div
            className="media align-items-center flex-grow-1"
            style={{ maxWidth: 'calc(100% - 72px' }}
          >
            <span className="file-type-icon">
              <SvgIcons type="txt" />
            </span>
            <div className="media-body mg-l-10 text-truncate">
              sample_list.txt
              <small className="d-block text-muted text-truncate">
                (18.94 KB) 2019/11/02 12:00:12
              </small>
            </div>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Download"
            >
              <Download /> <span className="sr-only">내려받기</span>
            </button>
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Delete"
            >
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
          <UploadCloud size="20" className="mg-r-10" />
          Drag &amp; Drop Files
        </div>
        <button type="button" className="btn btn-link">
          <span className="mg-r-5">{Icon('file_zip')}</span>Download all
        </button>
      </div>
    </div>
  );
};

export default Attachments;
