import { Button, Select } from '@base/components/form';

const LeadScoringRule = () => {
  return (
    <div className="pd-20">
      <div className="d-flex algin-items-center form-group">
        <Select />
        <Button color="link" name="View" className="mg-l-10" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Lead Classification</label>
        <div>Total</div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Behavioral Scroing</label>
        <div>
          Scoring in Journey Process
          <Button color="link" name="View" className="mg-l-10" />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title d-flex align-items-center">
          Scoring based on Lead's Profile
          <Button
            color="link"
            icon="Edit2"
            iconClass="mg-r-5"
            name="Edit"
            className="mg-l-auto pd-y-0"
          />
        </label>
        <div className="table-responsive bg-white bd rounded">
          <table className="table table-bordered mg-b-0 bd-0 basic-table">
            <thead>
              <tr>
                <th scope="col">Field</th>
                <th scope="col">Type</th>
                <th scope="col">Operator</th>
                <th scope="col">Value</th>
                <th scope="col">+/-</th>
                <th scope="col">Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LeadScoringRule;
