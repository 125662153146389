import { useState } from 'react';
import classnames from 'classnames';

import { CornerDownRight, UploadCloud, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';

const TABS = [
  {
    value: 1,
    label: 'Basic Info',
  },
  {
    value: 2,
    label: 'Links',
  },
  {
    value: 3,
    label: 'Upload Fax Image',
  },
];

const NewFax = () => {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <div id="newFaxModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">New Broadcast Contents</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body d-flex flex-column overflow-hidden pd-0">
            {/* steps */}
            <ul className="steps steps-justified steps-tab">
              {TABS.map((item, index) => (
                <li
                  key={index}
                  className={classnames('step-item', {
                    complete: item.value < tab.value,
                    active: item.value == tab.value,
                    disabed: item.value > tab.value,
                  })}
                >
                  <button
                    type="button"
                    className="btn step-link rounded-0"
                    onClick={(e) => {
                      setTab(item);
                    }}
                  >
                    <span className="step-number">{item.value}</span>
                    <span className="step-title">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
            {tab.value == 1 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px' }}>
                <div className="form-group">
                  <label className="form-item-title">Fax Image Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Content Type</label>
                  <SelectHook className="wd-200-f" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <div className="input-group">
                    <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                      <div className="form-control-tag">
                        <span className="d-block avatar avatar-xs mg-r-10">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0xJV22ugqnu5lwu_NoaL1CeW2SZCx4YbuLQ&amp;usqp=CAU"
                            alt=""
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="form-control-tag-name">Product 111</span>
                        <button type="button" className="btn pd-0 form-control-tag-remove">
                          <X />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <input
                        type="text"
                        className="bd-0 flex-grow-1 mn-wd-250 rounded"
                        placeholder="Type or click to select a product"
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select a product"
                      >
                        {Icon('product')}
                        <span className="sr-only">상품 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <textarea rows={3} cols={10} className="form-control"></textarea>
                </div>
              </div>
            )}
            {tab.value == 2 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px)' }}>
                <div className="form-group-title mg-t-0-f">CTA embedded</div>
                <div className="form-group">
                  <label className="form-item-title">CTA Name</label>
                  <SelectHook className="wd-300-f" />
                </div>
                <div className="form-group">
                  <label className="form-item-title">URL</label>
                  <div>https://hanbiro.com</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title d-block">QR Code</label>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsip6Dsj3eSrw44sOjmaU1eMpLQNb0MfQXgw&usqp=CAU"
                    alt=""
                    className="img-thumbnail wd-150"
                  />
                </div>
                <div className="form-group">
                  <label className="form-item-title">Shorten URL</label>
                  <div>shorturl.at/irJZ0</div>
                </div>
                <div className="d-flex">
                  <CornerDownRight strokeWidth={1} />
                  <div className="wd-100p">
                    <div className="card bg-light mg-b-15">
                      <div className="card-header pd-y-10">Link</div>
                      <div className="card-body">
                        <div className="form-group">
                          <label className="form-item-title">Landing Page/Site</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Page Title</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group-title mg-t-0">Embedded CTA on this page</div>
                        <div className="form-group">
                          <label className="form-item-title">Type</label>
                          <div>&nbsp;</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Name</label>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <CornerDownRight strokeWidth={1} />
                      <div className="wd-100p">
                        <div className="card bg-light">
                          <div className="card-header pd-y-10">Link</div>
                          <div className="card-body">
                            <div className="form-group">
                              <label className="form-item-title">Offer Site</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Type</label>
                              <div>&nbsp;</div>
                            </div>
                            <div className="form-group">
                              <label className="form-item-title">Page Title</label>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {tab.value == 3 && (
              <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px)' }}>
                <div className="d-flex align-items-center justify-content-center mg-b-3 pd-10 bg-light rounded bd bd-dashed tx-gray-400">
                  <UploadCloud size="20" className="mg-r-10" />
                  Drag &amp; Drop Files
                </div>
                <div className="d-flex align-items-center justify-content-center mg-t-15">
                  <figure className="img-thumbnail">
                    <img
                      src="https://via.placeholder.com/600x848"
                      className="img-fit-cover"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            {tab.value == 1 && (
              <button type="button" className="btn btn-primary">
                Next
              </button>
            )}
            {tab.value == 2 && (
              <>
                <button type="button" className="btn btn-white">
                  Skip
                </button>
                <button type="button" className="btn btn-primary">
                  Download
                </button>
              </>
            )}
            {tab.value == 3 && (
              <button type="button" className="btn btn-success">
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFax;
