import { useState } from 'react';
import classnames from 'classnames';

import { Trash, ChevronDown, ArrowLeft, Circle, CheckCircle, Plus } from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

import Notelist from '../../../../../component/desktop/notelist';

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewLanding = () => {
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <div className="view-wrap">
      <div className={`view-header bg-${headerColor.value}`}>
        <a
          href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Online Digital Contents
            <ChevronDown className="mg-l-5" />
          </button>
        </div>

        <h1 className="view-title mg-l-10 lh-1">Landing Page 1</h1>

        <div className="button-group d-flex flex-shrink-0 mg-l-auto">
          <div className="process-buttons mg-r-15">
            <div className="dropdown">
              <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                More
                <ChevronDown className="mg-l-5" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">{Icon('publish')}</span>
                  Publish
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <button type="button" className="btn btn-primary mg-l-5 rounded-30">
            <Plus size="14" />
            New
          </button>
        </div>

        <div className="dropdown mg-l-10 pd-l-10 bd-l">
          <button
            type="button"
            data-han-tooltip="Change the background color"
            className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
            data-toggle="dropdown"
          >
            <span className="sr-only">배경색 변경</span>
          </button>
          <ul className="dropdown-menu header-bg-color-list">
            {COLOS.map((item, index) => (
              <li key={index}>
                <button
                  className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                  onClick={(e) => {
                    setHeaderColor(item);
                  }}
                >
                  &nbsp;
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner">
            {/* collapse left aside */}
            {!hideAsideLeft && (
              <button
                type="button"
                className="btn btn-hide-aside-left"
                onClick={() => setHideAsideLeft(true)}
              >
                <LeftCollapse />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>
            )}

            {/* Summary Info */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Summary</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#basicInfoSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="basicInfoSection" className="view-aside-section-body collapse show">
                <div className="form-group">
                  <label className="form-item-title">Landing Page Type</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Language</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Product</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">A/B Test</label>
                  <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" id="abtest-active-01" />
                    <label className="custom-control-label" htmlFor="abtest-active-01">
                      Active
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Owner</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>MSR</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Approval</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm">
                      <img
                        src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                        className="rounded-circle"
                        alt="KDH"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>KDH</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Stage</label>
                  <div>Build</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Created on</label>
                  <div>2021/09/01</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Last updated</label>
                  <div>2021/09/01</div>
                </div>
              </div>
            </div>

            {/* Options */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Options</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0 collapsed"
                  data-toggle="collapse"
                  data-target="#optionsSection"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="optionsSection" className="view-aside-section-body collapse">
                <div className="form-group-title mg-t-0-f">Header Meta Tags</div>
                <div className="form-group">
                  <label className="form-item-title">Meta Keyword</label>
                  <div>2021/09/01</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Meta Description</label>
                  <div>2021/09/01</div>
                </div>
                <hr />
                <div className="form-group">
                  <label className="form-item-title">Publish or Schedlue</label>
                  <div>Unpublished</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Implementing Cost</label>
                  <div className="table-responsive bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Cost Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Amount
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th scope="row" className="bd-b-0-f">
                            Total Cost
                          </th>
                          <td colSpan={2}>&nbsp;</td>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
          <div className="d-flex align-items-start bg-white bd-b pd-y-10">
            {/* process */}
            <ol className="list-unstyled flex-grow-1 process-wrap text-nowrap">
              <li className="process-item done">
                <button type="button" className="btn process-item-btn">
                  <Circle />
                </button>
                <div className="process-item-text mg-t-10">Build</div>
              </li>
              <li className="process-item current">
                <button type="button" className="btn process-item-btn">
                  <CheckCircle />
                </button>
                <div className="process-item-text mg-t-10">Unpublished</div>
                <div className="mg-t-5">
                  <span className="tx-color-03 mg-r-5">Step</span>
                  <span>Finish</span>
                </div>
              </li>
            </ol>
          </div>
          <div className="view-body">
            {/* show left aside */}
            {hideAsideLeft && (
              <button
                type="button"
                className="btn btn-show-aside-left"
                onClick={() => setHideAsideLeft(false)}
              >
                <RightCollapse />
                <span className="sr-only">좌측 사이드 패널 열기</span>
              </button>
            )}

            <div className="pd-20">
              <div className="view-content-section">
                <div className="view-content-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Preview</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto"
                    data-toggle="collapse"
                    aria-label="collapse"
                    data-target="#LPPreview"
                  >
                    <ChevronDown />
                    <span className="sr-only">내용 접기</span>
                  </button>
                </div>
                <div id="LPPreview" className="card collapse show">
                  &nbsp;
                </div>
              </div>

              <div className="view-content-section">
                <div className="view-content-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Note</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto"
                    data-toggle="collapse"
                    aria-label="collapse"
                    data-target="#LPNote"
                  >
                    <ChevronDown />
                    <span className="sr-only">내용 접기</span>
                  </button>
                </div>
                <div id="LPNote" className="card collapse show">
                  <Notelist />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLanding;
