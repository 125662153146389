const Roi = () => {
  return (
    <>
      <div className="view-content-section">
        <div className="view-content-section-header">
          <span className="view-aside-section-header-title">
            Return on Marketing Investment Formulas
          </span>
          <button
            type="button"
            className="btn btn-link"
            data-toggle="modal"
            data-target="#confirmFactors"
          >
            Confirm your financial factors
          </button>
        </div>
        <div className="card card-body mg-y-10">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="text-center mg-r-10">
                Gross Profit - Marketing investment
                <hr className="mg-y-5" />
                Marketing Investment
              </div>
              <div>× 100</div>
            </div>
            <div className="col-lg-6 bd-l">
              <div className="h3 mg-b-15 tx-center tx-primary">413%</div>
              <div className="d-flex">
                <div className="flex-grow-1 pd-x-15">
                  <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                    Recipients
                  </div>
                  <div>100</div>
                </div>
                <div className="flex-grow-1 pd-x-15 bd-l">
                  <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                    Sales
                  </div>
                  <div>20</div>
                </div>
                <div className="flex-grow-1 pd-x-15 bd-l">
                  <div className="tx-uppercase tx-spacing-1 tx-semibold tx-12 tx-color-02 mg-b-2">
                    Gross Profit
                  </div>
                  <div>＄500.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="table-responsive rounded">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Customer
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Product
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Item
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Qty
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Amount
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Gross Profit
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th scope="row" colSpan={3} align="right" className="bd-b-0-f">
                    Total
                  </th>
                  <td>20</td>
                  <td>＄12,000</td>
                  <td>＄500</td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roi;
