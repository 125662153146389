import Icon from '@base/assets/icons/svg-icons';
import noImg from '@base/assets/img/notfound.png';
import { Button } from '@base/components/form';
import ProductDetailView from '../containers/product-detail/view';

const RFQ = () => {
  return (
    <div className="pd-20">
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button
            color=""
            className="view-aside-section-move mg-r-5"
            icon="move"
            iconType="custom"
            data-han-tooltip="move"
          />
          <span className="view-aside-section-header-title">To Vendor</span>
          <Button color="link" className="mg-l-auto" icon="Edit2" iconClass="mg-r-5" name="Edit" />
          <Button
            color="link"
            className="mg-l-5"
            icon="preview"
            iconType="custom"
            iconClass="mg-r-5"
            name="Preview"
          />
        </div>
        <ProductDetailView />
        <div className="form-group">
          <label className="form-item-title">Terms &amp; Conditions</label>
          <div>
            Please use the following communication for your payment : PB/2022/05/09 Payment terms: 7
            Days
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Vendor Note</label>
          <div>Please use the following communication for your payment : PB/2022/05/09</div>
        </div>
      </div>
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button
            color=""
            className="view-aside-section-move mg-r-5"
            icon="move"
            iconType="custom"
            data-han-tooltip="move"
          />
          <span className="view-aside-section-header-title">
            From Vendor
            <span className="badge badge-success mg-l-5">Acceepted</span>
            {/* <span className="badge badge-danger mg-l-5">Rejected</span> */}
          </span>
          <Button
            color="link"
            className="mg-l-auto"
            icon="preview"
            iconType="custom"
            iconClass="mg-r-5"
            name="Preview"
          />
        </div>
        <ProductDetailView />
        <div className="form-group">
          <label className="form-item-title">Terms &amp; Conditions</label>
          <div>
            Please use the following communication for your payment : PB/2022/05/09 Payment terms: 7
            Days
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Vendor Note</label>
          <div>Please use the following communication for your payment : PB/2022/05/09</div>
        </div>
      </div>
    </div>
  );
};

export default RFQ;
