import { useState } from 'react';
import classnames from 'classnames';

import { ChevronUp, UploadCloud, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const NewCTA = () => {
  const [showButtonSample, setShowButtonSample] = useState(false);
  const [showSetABTest, setShowSetABTest] = useState(false);

  return (
    <div id="newCTAModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">New CTA</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">CTA Type</label>
              <SelectHook
                className="wd-200-f"
                options={[
                  { value: 1, label: 'Image' },
                  { value: 2, label: 'Text' },
                  { value: 3, label: 'QR code' },
                  { value: 4, label: 'URL' },
                ]}
              />
            </div>

            <div className="row row-xs">
              <div className="col-lg-6">
                {/* CTA Type : Image */}
                <div className="form-group">
                  <label className="form-item-title d-block">Image Upload</label>
                  <button
                    //type="file"
                    className="btn btn-outline-primary mg-r-10"
                    onClick={() => setShowButtonSample(false)}
                  >
                    <UploadCloud className="mg-r-5" />
                    Upload
                  </button>
                  <button
                    type="button"
                    className={classnames('btn btn-outline-info', { active: showButtonSample })}
                    onClick={() => setShowButtonSample(true)}
                  >
                    Sample
                  </button>
                  {showButtonSample && (
                    <div className="cta-sample-list pd-15 ht-250 overflow-auto">
                      <div className="mg-b-10">Sample List</div>
                      <button type="button" className="btn">
                        <img src="assets/images/cta-sample-1.png" alt="" />
                      </button>
                      <button type="button" className="btn active">
                        <img src="assets/images/cta-sample-2.png" alt="" />
                      </button>
                      <button type="button" className="btn">
                        <img src="assets/images/cta-sample-3.png" alt="" />
                      </button>
                      <button type="button" className="btn">
                        <img src="assets/images/cta-sample-4.png" alt="" />
                      </button>
                      <button type="button" className="btn">
                        <img src="assets/images/cta-sample-5.png" alt="" />
                      </button>
                      <button type="button" className="btn">
                        <img src="assets/images/cta-sample-6.png" alt="" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-item-title d-block">Image Size</label>
                  <div className="row row-xs">
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text lh-1">Width</span>
                        </div>
                        <input type="number" className="form-control" defaultValue="300" />
                        <div className="input-group-append">
                          <span className="input-group-text lh-1">px</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text lh-1">Height</span>
                        </div>
                        <input type="number" className="form-control" />
                        <div className="input-group-append">
                          <span className="input-group-text lh-1">px</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Alt Text</label>
                  <input type="text" className="form-control" />
                </div>

                {/* CTA Type : Text
                            <div className="form-group">
                                <label className="form-item-title">Button Value</label>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-item-title d-block">Background Color</label>
                                <input type="color" value="#e66465" />
                            </div>
                            <div className="form-group">
                                <label className="form-item-title d-block">Text Color</label>
                                <input type="color" value="#ffffff" />
                            </div>
                            <div className="form-group">
                                <label className="form-item-title">Font Size</label>
                                <div className="input-group wd-200">
                                    <input type="number" className="form-control" />
                                    <div className="input-group-append">
                                        <span className="input-group-text lh-1">px</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-item-title">Font Weight</label>
                                <SelectHook 
                                    className="wd-200-f"
                                    options = {[
                                        {value: 1, label: "Light"},
                                        {value: 2, label: "Normal"},
                                        {value: 3, label: "Bold"}
                                    ]}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-item-title">Rounded</label>
                                <input type="range" className="form-control-range wd-80p" />
                            </div> */}
              </div>
              <div className="col-lg-6">
                <div className="form-group d-flex flex-column ht-100p">
                  <label className="form-item-title">Preview</label>

                  {/* CTA Type : Image }
                                {/* No img
                                <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 pd-10 bg-light rounded tx-gray-300">
                                    <img src="assets/images/notfound.png" alt="" style={{width: "100px"}} />
                                    <span className="tx-uppercase">no image</span>
                                </div> */}

                  {/* selected or uploaded img  */}
                  <div className="d-flex align-items-center flex-grow-1 pd-10 bg-light rounded overflow-auto">
                    <div className="wd-100p tx-center">
                      {/* CTA Type : Image */}
                      <img src="assets/images/cta-sample-2.png" alt="" style={{ width: '300px' }} />

                      {/* CTA Type : Text
                                        <a href="#" style={{display: "inline-block", padding: "10px", backgroundColor: "#e66465", color: "#fff", fontSize: "24px", fontWeight: "bold", borderRadius: "10px"}}>
                                            Click here!
                                        </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">CTA Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Language</label>
              <SelectHook className="wd-200-f" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Link Type</label>
              <SelectHook
                className="wd-200-f"
                options={[
                  { value: 1, label: 'External' },
                  { value: 2, label: 'Internal' },
                ]}
              />
            </div>

            {/* External Link */}
            <div className="form-group">
              <label className="form-item-title">External Site Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Link URL</label>
              <input type="url" className="form-control" />
              <div className="custom-control custom-checkbox mg-t-5">
                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Open page in new window
                </label>
              </div>
            </div>

            {/* Internal Link */}
            <div className="form-group">
              <label className="form-item-title">Landing Page/Site/Form</label>
              <SelectHook className="wd-300-f" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Content Type</label>
              <div></div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Page Title</label>
              <input type="text" className="form-control" />
            </div>

            <div className="form-group">
              <label className="form-item-title">Description</label>
              <textarea rows={3} cols={10} className="form-control"></textarea>
            </div>
            <div className="form-group">
              <label className="form-item-title">Set A/B Test</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                  checked={showSetABTest}
                  onChange={() => setShowSetABTest(!showSetABTest)}
                />
                <label className="custom-control-label" htmlFor="customSwitch1"></label>
              </div>
            </div>
            {showSetABTest && (
              <>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button
                      type="button"
                      className="nav-link active"
                      data-toggle="tab"
                      data-target="#a"
                    >
                      A
                    </button>
                  </li>
                  <li className="nav-item">
                    <button type="button" className="nav-link" data-toggle="tab" data-target="#b">
                      B
                    </button>
                  </li>
                </ul>
                <div className="tab-content bd bd-gray-300 bd-t-0 pd-20">
                  <div id="a" className="tab-pane fade show active">
                    <div className="row row-xs">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-item-title">CTA Type</label>
                          <div>Image</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Image Upload</label>
                          <div>cta-sample-2.png</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Image Size</label>
                          <div>Width: 300px</div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Alt Text</label>
                          <div>회원가입</div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group d-flex flex-column ht-100p">
                          <label className="form-item-title">Preview</label>
                          {/* selected or uploaded img */}
                          <div className="d-flex align-items-center flex-grow-1 pd-10 bg-light rounded overflow-auto">
                            <div className="wd-100p tx-center">
                              <img
                                src="assets/images/cta-sample-2.png"
                                alt=""
                                style={{ width: '300px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="b" className="tab-pane fade">
                    <div className="row row-xs">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-item-title">CTA Type</label>
                          <SelectHook
                            className="wd-200-f"
                            options={[
                              { value: 1, label: 'Image' },
                              { value: 2, label: 'Text' },
                            ]}
                          />
                        </div>
                        {/* CTA Type : Image */}
                        <div className="form-group">
                          <label className="form-item-title d-block">Image Upload</label>
                          <button
                            //type="file"
                            className="btn btn-outline-primary mg-r-10"
                            onClick={() => setShowButtonSample(false)}
                          >
                            <UploadCloud className="mg-r-5" />
                            Upload
                          </button>
                          <button
                            type="button"
                            className={classnames('btn btn-outline-info', {
                              active: showButtonSample,
                            })}
                            onClick={() => setShowButtonSample(true)}
                          >
                            Sample
                          </button>
                          {showButtonSample && (
                            <div className="cta-sample-list pd-15 ht-250 overflow-auto">
                              <div className="mg-b-10">Sample List</div>
                              <button type="button" className="btn">
                                <img src="assets/images/cta-sample-1.png" alt="" />
                              </button>
                              <button type="button" className="btn active">
                                <img src="assets/images/cta-sample-2.png" alt="" />
                              </button>
                              <button type="button" className="btn">
                                <img src="assets/images/cta-sample-3.png" alt="" />
                              </button>
                              <button type="button" className="btn">
                                <img src="assets/images/cta-sample-4.png" alt="" />
                              </button>
                              <button type="button" className="btn">
                                <img src="assets/images/cta-sample-5.png" alt="" />
                              </button>
                              <button type="button" className="btn">
                                <img src="assets/images/cta-sample-6.png" alt="" />
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="form-item-title d-block">Image Size</label>
                          <div className="row row-xs">
                            <div className="col-md-6">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text lh-1">Width</span>
                                </div>
                                <input type="number" className="form-control" defaultValue="300" />
                                <div className="input-group-append">
                                  <span className="input-group-text lh-1">px</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text lh-1">Height</span>
                                </div>
                                <input type="number" className="form-control" />
                                <div className="input-group-append">
                                  <span className="input-group-text lh-1">px</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-item-title">Alt Text</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group d-flex flex-column ht-100p">
                          <label className="form-item-title">Preview</label>
                          {/* selected or uploaded img */}
                          <div className="d-flex align-items-center flex-grow-1 pd-10 bg-light rounded overflow-auto">
                            <div className="wd-100p tx-center">
                              <img
                                src="assets/images/cta-sample-2.png"
                                alt=""
                                style={{ width: '300px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group">
              <button type="button" className="btn btn-success">
                Finish(Stage:Disable)
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Finish(Stage:Enable)
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCTA;
