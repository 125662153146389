import { ChevronDown, Plus, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const Write = () => {
  return (
    <div id="newMarketingList" className="modal fade">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content rounded-0 overflow-hidden">
          <div className="modal-header">
            <h1 className="h6 modal-title">Creat Marketing List</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Marketing Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Type</label>
              <SelectHook
                className="wd-200-f"
                options={[
                  { value: 1, label: 'Static' },
                  { value: 2, label: 'Active' },
                ]}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Purpose</label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="form-item-title">Cost</label>
              <div className="d-flex align-items-center">
                <SelectHook className="wd-100-f mg-r-10" options={[{ value: 1, label: '￦' }]} />
                <input type="number" className="form-control form-control-lg wd-100" />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <textarea rows={3} cols={10} className="form-control"></textarea>
            </div>
            <div className="form-group">
              <label className="form-item-title">Target</label>
              <div className="input-group">
                <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                  <div className="form-control-tag">
                    <span className="form-control-tag-name">Account</span>
                    <button
                      type="button"
                      className="btn pd-0 form-control-tag-remove"
                      aria-label="삭제"
                    >
                      <X />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="bd-0 flex-grow-1 mn-wd-250"
                    placeholder="Type or click to select a target"
                  />
                </div>
                <div className="input-group-append bg-white">
                  <button type="button" className="btn" aria-label="open modal to select a target">
                    <ChevronDown />
                    <span className="sr-only">선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="kbArticleActiveSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="kbArticleActiveSwitch1"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Related Campaign</label>
              <div className="table-resonsive bd rounded bg-white">
                <table className="table mg-b-0">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Name
                      </th>
                      <th scope="col" className="wd-150 bd-t-0-f">
                        Used on
                      </th>
                      <th scope="col" className="wd-80 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Campaign 111</td>
                      <td>2021-08-04</td>
                      <td>
                        <button type="button" className="btn btn-icon pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type or click to select a campaign"
                          />
                          <div className="input-group-append bg-white">
                            <button
                              type="button"
                              className="btn"
                              aria-label="open modal to select a target"
                            >
                              <ChevronDown />
                              <span className="sr-only">선택 팝업 열기</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Members</label>
              <div className="table-resonsive bd rounded bg-white">
                <table className="table mg-b-0">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Email
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Mobile
                      </th>
                      <th scope="col" className="wd-80 bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>
                        <input type="email" className="form-control" />
                      </td>
                      <td>
                        <input type="tel" className="form-control" />
                      </td>
                      <td>
                        <button type="button" className="btn btn-icon pd-y-0">
                          <X className="tx-danger" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control mg-r-5"
                            placeholder="input the member name"
                          />
                          <button type="button" className="btn btn-sm btn-primary flex-shrink-0">
                            <Plus className="mg-r-5" />
                            Add
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Write;
