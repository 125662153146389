import { X, Settings, User } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

// import "OrderCss/sales_order.scss"

const SOWrite = () => {
  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Order Name</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Customer</label>
        <div className="input-group">
          {/* before selecting a customer
                <input type="text" className="form-control" placeholder="Type or click to select a customer" /> 
                */}
          <div className="form-control pd-y-1-f d-flex align-items-center">
            <div className="avatar avatar-xs mg-r-10 flex-shrink-0">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                className="rounded"
                alt="Adventure Works"
              />
            </div>
            <span className="text-truncate">Adventure Works</span>
            <button type="button" className="btn mg-l-auto" aria-label="delete">
              <X />
              <span className="sr-only">삭제</span>
            </button>
          </div>
          <div className="input-group-append">
            <button
              type="button"
              className="btn mg-l-auto"
              aria-label="open modal to select a customer"
            >
              {Icon('m_customer')}
              <span className="sr-only">고객 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Order ID</label>
        <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <input type="text" className="form-control" defaultValue="SO-001" />
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open generator of Order ID modal">
              <Settings />
              <span className="sr-only">ID 생성 설정 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Reference ID</label>
        <div className="wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <SelectHook />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Ordered on</label>
        <div className="wd-300">
          <DatePicker
            selected={new Date()}
            onChange={() => {
              console.log();
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Payment Term</label>
        <div className="wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <SelectHook />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Expected Shipment Date</label>
        <div className="wd-300">
          <DatePicker
            selected={new Date()}
            onChange={() => {
              console.log();
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Assigned Rep.</label>
        <div className="input-group">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="d-block avatar avatar-xs mg-r-10">
                <img
                  src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                  alt="John"
                  className="img rounded-circle"
                />
              </span>
              <span className="form-control-tag-name">
                John<span className="tx-gray-500">(Sales)</span>
              </span>
              <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                <X />
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250"
              placeholder="Type or click to select a user"
            />
          </div>
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open modal to select a user">
              <User />
              <span className="sr-only">담당자 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Order Process</label>
        <div className="wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
          <SelectHook />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Description</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Order Template</label>
        <div className="d-flex align-items-center">
          <SelectHook className="wd-200-f" />
          <button type="button" className="btn btn-link">
            Preview
          </button>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Product Details</label>
        <div className="table-responsive bg-white bd rounded-top">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="colgroup" colSpan={9} className="bd-t-0-f bd-b-d-f">
                  Onetime Payment
                </th>
              </tr>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Image
                </th>
                <th scope="col" className="bd-t-0-f">
                  Item
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit
                </th>
                <th scope="col" className="bd-t-0-f">
                  Attribute
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit Price
                </th>
                <th scope="col" className="bd-t-0-f">
                  Qty Ordered
                </th>
                <th scope="col" className="bd-t-0-f">
                  Volume Discount
                </th>
                <th scope="col" className="bd-t-0-f">
                  Amount
                </th>
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="avatar avatar-sm bg-gray-200 rounded">
                    <img src="assets/images/notfound.png" alt="" />
                  </div>
                </td>
                <td>AAA</td>
                <td>Box</td>
                <td>&nbsp;</td>
                <td>￦100,000</td>
                <td>
                  <input type="number" className="form-control wd-150" defaultValue="100" />
                </td>
                <td>0</td>
                <td>￦10,000,000</td>
                <td>
                  <button type="button" className="btn btn-icon">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive bg-white bd">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="colgroup" colSpan={10} className="bd-t-0-f bd-b-d-f">
                  Subscription
                </th>
              </tr>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Image
                </th>
                <th scope="col" className="bd-t-0-f">
                  Item
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit
                </th>
                <th scope="col" className="bd-t-0-f">
                  Unit Price
                </th>
                <th scope="col" className="bd-t-0-f">
                  Term
                </th>
                <th scope="col" className="bd-t-0-f">
                  Term Discount
                </th>
                <th scope="col" className="bd-t-0-f">
                  Qty Ordered
                </th>
                <th scope="col" className="bd-t-0-f">
                  Amount
                </th>
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="avatar avatar-sm bg-gray-200 rounded">
                    <img src="assets/images/notfound.png" alt="" />
                  </div>
                </td>
                <td>AAA</td>
                <td>Box</td>
                <td>￦100,000</td>
                <td>
                  <SelectHook className="wd-150-f" />
                </td>
                <td>&nbsp;</td>
                <td>
                  <input type="number" className="form-control wd-150" defaultValue="100" />
                </td>
                <td>￦10,000,000</td>
                <td>
                  <button type="button" className="btn btn-icon">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive bg-white bd rounded-top">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="colgroup" colSpan={8} className="bd-t-0-f bd-b-d-f">
                  Prepaid
                </th>
              </tr>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Image
                </th>
                <th scope="col" className="bd-t-0-f">
                  Item
                </th>
                <th scope="col" className="bd-t-0-f">
                  Prepaid Type
                </th>
                <th scope="col" className="bd-t-0-f">
                  Prepaid Amount
                </th>
                <th scope="col" className="bd-t-0-f">
                  Prepaid Discount
                </th>
                <th scope="col" className="bd-t-0-f">
                  Qty Ordered
                </th>
                <th scope="col" className="bd-t-0-f">
                  Amount
                </th>
                <th scope="col" className="bd-t-0-f">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="avatar avatar-sm bg-gray-200 rounded">
                    <img src="assets/images/notfound.png" alt="" />
                  </div>
                </td>
                <td>AAA</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>￦100,000</td>
                <td>
                  <input type="number" className="form-control wd-150" defaultValue="100" />
                </td>
                <td>￦10,000,000</td>
                <td>
                  <button type="button" className="btn btn-icon">
                    <X className="tx-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pd-10 bd bd-t-0 rounded-bottom">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select an item"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                {Icon('product')}
              </button>
            </div>
          </div>
        </div>
        <div className="row row-xs mg-t-10">
          <div className="col-md-6">
            <ul className="list-unstyled lh-7 mg-b-0">
              <li className="d-flex justify-content-between mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                <span>Items : 100</span>
                <span> ₩ 10,000,000</span>
              </li>
              <li className="d-flex align-items-center mg-b-5 pd-x-15 pd-y-10 rounded bd bg-gray-100">
                <span>Total Discount</span>
                <button className="mg-l-auto btn btn-sm btn-link link-03 pd-y-0">Change</button>
                <div className="mg-l-5 tx-danger">- ₩ 500,000</div>
              </li>
            </ul>
            <div className="rounded bd bg-gray-100">
              <div className="d-flex flex-wrap pd-x-15 pd-t-10">
                Loyalty
                <div className="mg-l-auto text-right tx-danger">-₩410,000</div>
              </div>
              <div className="mg-10 bd bd-dashed rounded">
                <ul className="list-unstyled lh-7 mg-b-0">
                  <li className="d-flex justify-content-between pd-10">
                    <div>
                      <label className="d-block mg-b-0 tx-color-03 tx-13">Point(s)</label>
                      <div className="tx-info">100,000 (₩10,000)</div>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <div className="d-flex align-items-center">
                        <span className="tx-danger">- ₩</span>
                        <input
                          type="number"
                          className="form-control wd-150 mg-l-5 text-right tx-danger"
                          value="10000"
                        />
                      </div>
                    </div>
                  </li>
                  <li className="d-flex align-items-center pd-10 bd bd-dashed bd-x-0 bd-b-0">
                    <label className="d-block mg-b-0 tx-color-03 tx-13">Coupon(s)</label>
                    <button
                      type="button"
                      className="mg-l-auto btn btn-sm btn-link link-03 pd-y-0"
                      data-toggle="modal"
                    >
                      Change
                    </button>
                    <div className="mg-l-5 tx-danger">- ₩ 100,000</div>
                  </li>
                  <li className="d-flex justify-content-between pd-10 bd bd-dashed bd-x-0 bd-b-0">
                    <div>
                      <label className="d-block mg-b-0 tx-color-03 tx-13">Stamp(s) Reward</label>
                      <div className="tx-info">3% Off</div>
                    </div>
                    <div className="text-right">
                      <div className="text-right tx-danger">- ₩300,000</div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notUseStampRewardCheck"
                        />
                        <label className="custom-control-label" htmlFor="notUseStampRewardCheck">
                          Do not use.
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <ul className="list-unstyled lh-10 pd-15 rounded bg-white bd">
              <li className="d-flex justify-content-between">
                <span>Sub-Total</span>
                <span>₩ 9,090,000</span>
              </li>
              <li className="d-flex justify-content-between">
                <span>Shipping Charges</span>
                <span>₩ 4,000</span>
              </li>
              <li className="d-flex justify-content-between">
                <span>Tax (5%)</span>
                <span>₩ 454,500</span>
              </li>
              <li className="d-flex justify-content-between">
                <span>Round Off</span>
                <span>₩ 0</span>
              </li>
              <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                <strong>Total Amount</strong>
                <strong>₩ 9,548,500</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Terms &amp; Conditions</label>
        <SelectHook
          className="wd-300-f mg-b-5"
          options={[{ value: 1, label: 'Terms & Conditions 1' }]}
        />
        <textarea rows={3} cols={10} className="form-control">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </textarea>
      </div>
      <div className="form-group">
        <label className="form-item-title">Customer Notes</label>
        <textarea rows={3} cols={10} className="form-control"></textarea>
      </div>
    </>
  );
};

export default SOWrite;
