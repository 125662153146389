/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import classnames from 'classnames';

import { ArrowLeft, ArrowRight, Calendar, ChevronDown, Minus, Plus, User, X } from 'react-feather';

import BasicInfo from './components/basic-info';
import PickAvatar from './components/pick-avatar';
import TargetProfile from './components/target-profile';
import Overview from './components/overview';

//import "CrmCss/desktop/theme1/theme1.scss";
import './icp.scss';

const TABS = [
  {
    value: 1,
    label: 'Summary',
  },
  {
    value: 2,
    label: 'Pick a Avatar',
  },
  {
    value: 3,
    label: 'Target Profile',
  },
  {
    value: 4,
    label: 'Overview',
  },
];

const Write = () => {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <>
      <div id="newICPModal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header flex-column pd-0">
              <div className="d-flex wd-100p pd-15">
                <h1 className="modal-title">Create Ideal Customer Profile</h1>
                <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                  <X />
                </button>
              </div>
              <ul className="steps steps-tab steps-justified wd-100p bd-t">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('step-item', {
                      active: item.value == tab.value,
                      complete: item.value < tab.value,
                    })}
                  >
                    <div
                      className="step-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      <span className="step-number">{item.value}</span>
                      <span className="step-title tx-transform-none">{item.label}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-body pd-0">
              {tab.value == 1 && <BasicInfo />}
              {tab.value == 2 && <PickAvatar />}
              {tab.value == 3 && <TargetProfile />}
              {tab.value == 4 && <Overview />}
            </div>
            <div className="modal-footer">
              {tab.value > 1 && (
                <button type="button" className="btn btn-link link-03">
                  <ArrowLeft className="mg-r-5" />
                  Previous
                </button>
              )}
              <button type="button" className="btn btn-light mg-l-auto" data-dismiss="modal">
                Close
              </button>
              {tab.value < 4 && (
                <button type="button" className="btn btn-primary">
                  Next
                  <ArrowRight className="mg-l-5" />
                </button>
              )}
              {tab.value == 4 && (
                <button type="button" className="btn btn-success">
                  Complete
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Write;
