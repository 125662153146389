import { Button, Input } from '@base/components/form';
import { ChevronUp } from 'react-feather';

const NewChannel = () => {
  return (
    <div id="newChannel" className="modal fade right">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="h6 modal-title">Create Channel</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Type</label>
              <Input type="text" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Channel Name</label>
              <Input type="text" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Descriptions</label>
              <Input type="text" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" className="btn btn-success">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewChannel;
