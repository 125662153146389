import { Plus, X } from 'react-feather';

const Agenda = () => {
  return (
    <>
      <div className="text-right">
        <button type="button" className="btn btn-link" data-toggle="modal" data-target="#newAgenda">
          <Plus className="mg-r-5" />
          New
        </button>
      </div>
      <div className="mg-y-15 tx-color-03">9 SEP THU</div>
      <ul className="list-group">
        <li className="list-group-item bg-white">
          <div className="d-flex">
            <div className="wd-70 mg-r-20 pd-r-15 bd-r bd-3 bd-primary tx-15">
              08:00
              <br />
              09:00
            </div>
            <div className="flex-grow-1">
              <span className="d-block tx-13 tx-color-03">General</span>
              <strong className="tx-16">Title 1</strong>
              <div className="media align-items-center mg-t-5">
                <div className="d-inline-block avatar avatar-xs">
                  <img
                    src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                    className="rounded-circle"
                  />
                </div>
                <div className="media-body mg-l-10">MSR</div>
              </div>
            </div>
            <button type="button" className="btn btn-icon align-self-start">
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </li>
        <li className="list-group-item bg-white">
          <div className="d-flex">
            <div className="wd-70 mg-r-20 pd-r-15 bd-r bd-3 bd-indigo tx-15">
              09:00
              <br />
              10:00
            </div>
            <div className="flex-grow-1">
              <span className="d-block tx-13 tx-color-03">General</span>
              <strong className="tx-16">Day one Breakfast</strong>
            </div>
            <button type="button" className="btn btn-icon align-self-start">
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </li>
      </ul>
      <div className="mg-y-15 tx-color-03">10 SEP FRI</div>
      <ul className="list-group">
        <li className="list-group-item bg-white">
          <div className="d-flex">
            <div className="wd-70 mg-r-20 pd-r-15 bd-r bd-3 bd-warning tx-15">
              09:00
              <br />
              10:30
            </div>
            <div className="flex-grow-1">
              <span className="d-block tx-13 tx-color-03">Education</span>
              <strong className="tx-16">Title 2</strong>
              <div className="media align-items-center mg-t-5">
                <div className="d-inline-block avatar avatar-xs">
                  <img
                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
                    className="rounded-circle"
                  />
                </div>
                <div className="media-body mg-l-10">Allan Rey Palban</div>
              </div>
            </div>
            <button type="button" className="btn btn-icon align-self-start">
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </li>
        <li className="list-group-item bg-white">
          <div className="d-flex">
            <div className="wd-70 mg-r-20 pd-r-15 bd-r bd-3 bd-danger tx-15">
              10:30
              <br />
              12:00
            </div>
            <div className="flex-grow-1">
              <span className="d-block tx-13 tx-color-03">Education</span>
              <strong className="tx-16">Title 3</strong>
              <div className="media align-items-center mg-t-5">
                <div className="d-inline-block avatar avatar-xs">
                  <img
                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                    className="rounded-circle"
                  />
                </div>
                <div className="media-body mg-l-10">Situmay</div>
              </div>
            </div>
            <button type="button" className="btn btn-icon align-self-start">
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </li>
        <li className="list-group-item bg-white">
          <div className="d-flex">
            <div className="wd-70 mg-r-20 pd-r-15 bd-r bd-3 bd-info tx-15">
              12:00
              <br />
              13:00
            </div>
            <div className="flex-grow-1">
              <span className="d-block tx-13 tx-color-03">General</span>
              <strong className="tx-16">Day two Lunch</strong>
            </div>
            <button type="button" className="btn btn-icon align-self-start">
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </li>
      </ul>
    </>
  );
};

export default Agenda;
