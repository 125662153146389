const BasicInfo = () => {
  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <span className="view-aside-section-header-title">Summary</span>
      </div>
      <div className="view-aside-section-body">
        <div className="form-group">
          <label className="form-item-title">Item Category</label>
          <div>Inventory</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Item Type</label>
          <div>Bundle</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Item ID</label>
          <div>Ite-000001</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Active</label>
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="itemActiveSwitch1" />
            <label className="custom-control-label" htmlFor="itemActiveSwitch1"></label>
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Product Group</label>
          <div>Group 1</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Product Name</label>
          <div>Product 1</div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
