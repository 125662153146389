import { useState } from 'react';
import classnames from 'classnames';

import { UploadCloud } from 'react-feather';
import { Button, Checkbox, Input, RadioGroup, Select } from '@base/components/form';
import { CustomerAutoComplete } from '@customer/customer/config/write-field/components';

const TABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'List Collected',
  },
];

const CollectTargetLeadCapture = () => {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <>
      <div className="modal-body d-flex flex-column overflow-hidden pd-0">
        {/* steps */}
        <ul className="steps steps-justified steps-tab">
          {TABS.map((item, index) => (
            <li
              key={index}
              className={classnames('step-item', {
                complete: item.value < tab.value,
                active: item.value == tab.value,
              })}
            >
              <button
                type="button"
                className="btn step-link rounded-0"
                onClick={(e) => {
                  setTab(item);
                }}
              >
                <span className="step-number">{item.value}</span>
                <span className="step-title">{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="pd-15 overflow-auto" style={{ height: 'calc(100% - 50px' }}>
          {tab.value == 1 && (
            <>
              <div className="form-group">
                <div className="text-right tx-info">Total: 20</div>
                <div className="table-responsive bd rounded-top">
                  <table className="table table-bordered mg-0 bd-0 basic-table">
                    <thead>
                      <tr>
                        <th scope="col">Account Name</th>
                        <th scope="col">Form Name</th>
                        <th scope="col">Campaign</th>
                        <th scope="col">Assigned</th>
                        <th scope="col">Created on</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="form-group-title">Account Cleansing and Profile</div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <label className="form-item-title">Account Name</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-item-title">Website</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-item-title">Industry</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-item-title">Product</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-item-title">No. of Employee</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-item-title">Address</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-item-title">Telephone</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-item-title">Description</label>
                  <div>&nbsp;</div>
                </div>
              </div>
              <hr />

              <div className="form-group">
                <label className="form-item-title">Identify Contact Properties</label>
                <div className="table-responsive bd rounded-top">
                  <table className="table table-bordered mg-0 bd-0 basic-table">
                    <thead>
                      <tr>
                        <th scope="col">Contact Name</th>
                        <th scope="col">Buying Role</th>
                        <th scope="col">Telephone</th>
                        <th scope="col">Email</th>
                        <th scope="col">Job Position</th>
                        <th scope="col" className="wd-70">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>
                          <div className="dropdown">
                            <div className="input-group" data-toggle="dropdown">
                              <Input type="text" value="Decision Maker" />
                              <div className="input-group-append">
                                <Button color="" icon="ChevronDown" className="input-group-btn" />
                              </div>
                            </div>
                            <div className="dropdown-menu wd-100p">
                              <Button
                                color=""
                                icon="Plus"
                                className="dropdown-item"
                                name="Add new type"
                              />
                              <div className="dropdown-divider"></div>
                              <Button color="" className="dropdown-item" name="End user" />
                            </div>
                          </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <Button
                            color="link"
                            icon="X"
                            iconClass="tx-danger"
                            className="btn-icon pd-y-0"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="pd-10 bd bd-t-0 rounded-bottom">
                  <CustomerAutoComplete value={''} onChange={console.log()} showAvatar />
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Identify Pain Point</label>
                <Checkbox label="Reduce Cose" />
                <Checkbox label="Save Time" />
                <Checkbox label="Streamline their existing process" />
                <Checkbox label="Grow their Sales" />
                <div className="d-flex align-items-center mg-t-10">
                  <Input type="text" placeholder="Pain Point" />
                  <Button
                    color="primary"
                    icon="Plus"
                    iconClass="mg-r-5"
                    name="Add"
                    className="flex-shrink-0 mg-l-10"
                  />
                </div>
              </div>
            </>
          )}
          {tab.value == 2 && (
            <>
              <div className="table-responsive bd rounded mg-b-10">
                <table className="table table-bordered mg-0 bd-0 basic-table">
                  <thead>
                    <tr>
                      <th scope="col">Account</th>
                      <th scope="col">Industry</th>
                      <th scope="col">No. of Employee</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th scope="row" className="bd-b-0-f">
                        Total
                      </th>
                      <td colSpan={2} align="right">
                        20
                      </td>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button color="primary" name="Matching your ICP" className="mg-b-20" />

              <div className="form-group">
                <label className="form-item-title">Select Journey Process</label>
                <div className="custom-control custom-radio mg-b-10">
                  <input
                    type="radio"
                    id="JPRadio1"
                    name="JPRadio"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="JPRadio1">
                    Journey Process 1<div className="tx-color-03">Description</div>
                  </label>
                </div>
                <div className="custom-control custom-radio mg-b-10">
                  <input
                    type="radio"
                    id="JPRadio2"
                    name="JPRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="JPRadio2">
                    Journey Process 2<div className="tx-color-03">Description</div>
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="modal-footer">
        {tab.value == 2 && (
          <Button
            color="link link-03"
            icon="ArrowLeft"
            iconClass="mg-r-5"
            name="Previous"
            className="mg-r-auto"
          />
        )}
        <Button color="light" data-dismiss="modal" name="Close" />
        {tab.value == 1 && <Button color="primary" name="Next" icon="ArrowRight" isIconsEnd />}
        {tab.value == 2 && <Button color="success" name="Done" />}
      </div>
    </>
  );
};

export default CollectTargetLeadCapture;
