import { X } from 'react-feather';

const ApplyInvoice = () => {
  return (
    <div
      id="applyInvoiceModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Apply to Invoice</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="media align-items-center mg-b-15">
              <div className="avatar avatar-lg">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                  className="rounded"
                  alt=""
                />
              </div>
              <div className="media-body mg-l-10">
                <h2 className="tx-16">Adventure Works</h2>
                DN-000001{' '}
                <strong className="d-inline-block mg-l-10 pd-l-10 bd-l tx-info">
                  Debit Amount: ￦417,000
                </strong>
              </div>
            </div>
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      ID
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Invoice Date
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Due Date
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Status
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Invoice Amount
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Balane Due
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Amount to Credit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>IVN-000001</td>
                    <td>2020/11/23</td>
                    <td>2020/11/23</td>
                    <td>Received</td>
                    <td>￦100,500</td>
                    <td>￦50,000</td>
                    <td>
                      <div className="input-group wd-150">
                        <div className="input-group-prepend">
                          <span className="input-group-text lh-1">￦</span>
                        </div>
                        <input type="number" className="form-control" defaultValue="417000" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>IVN-000008</td>
                    <td>2020/12/04</td>
                    <td>2020/12/04</td>
                    <td>Overdue</td>
                    <td>￦40,000</td>
                    <td>￦40,000</td>
                    <td>
                      <div className="input-group wd-150">
                        <div className="input-group-prepend">
                          <span className="input-group-text lh-1">￦</span>
                        </div>
                        <input type="number" className="form-control" defaultValue="0" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row mg-t-10 mg-x-0 pd-y-15 pd-x-5 bg-light rounded-5">
              <div className="col-6 offset-6">
                <ul className="list-unstyled mg-b-0 lh-10 pd-r-10">
                  <li className="d-flex justify-content-between">
                    <span>Amount to Debit</span>
                    <span>￦417,0000</span>
                  </li>
                  <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-16">
                    <strong>Remaing Debit : </strong>
                    <strong>￦0</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-light mg-r-5" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyInvoice;
