import { X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import { Select as SelectHook } from '@base/components/form';
import { defaultInputRanges } from 'react-date-range';

const Overview = () => {
  return (
    <div className="row no-gutters">
      <div className="col-4 bd-r">
        <div className="d-flex flex-column align-items-center pd-x-15 pd-y-20 bd-b">
          <div className="avatar-persona avatar-bg-01 mg-b-10">{Icon('avatar01')}</div>
          <h1 className="h5 mg-b-0">Marketing Mary</h1>
        </div>
        <div className="pd-15">
          <div className="form-group">
            <label className="form-item-title">Age</label>
            <div>0 ~ 18</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Generational</label>
            <div>Gen.Z (0~21)</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Gener</label>
            <div>Female, Male</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Marital Status</label>
            <div>Single</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Educational Attainment</label>
            <div>High school degree</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Social Circles</label>
            <div>&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="col-8">
        <div className="pd-15">
          <div className="form-group">
            <label className="form-item-title">Family Life-stage</label>
            <div>Others</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Family size</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Annual Income</label>
            <div>￦</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Home Ownership</label>
            <div>Renting</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Nationality</label>
            <div>&nbsp;</div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Religion</label>
            <div>Others</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
