import { Button, Input } from '@base/components/form';

const AddVendor = () => {
  return (
    <div id="addVendor" className="modal fade right" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Add Vendor</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Vendor Name</label>
              <div className="dropdown">
                <div className="input-group" data-toggle="dropdown">
                  <Input placeholder="Select Vendor" />
                  <div className="input-group-append">
                    <Button
                      color=""
                      className="input-group-btn"
                      icon="m_customer"
                      iconType="main"
                    />
                  </div>
                </div>
                <div className="dropdown-menu wd-100p">
                  <Button
                    color=""
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#newVendor"
                    name="Add New Vendor"
                    icon="Plus"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" size="lg" name="Cancel" data-dismiss="modal" />
            <Button color="success" size="lg" name="Ok" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVendor;
