import { DatePicker } from '@base/components/date-picker';
import { Button, Input, Select } from '@base/components/form';
import { ChevronDown, Globe, X } from 'react-feather';

const CloseOpportunity = () => {
  return (
    <div
      id="closeOpportunity"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Close Opportunity</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Status Reason</label>
              <Select defaultValue={{ value: 'lost', label: 'Lost' }} />
            </div>
            <div className="form-group">
              <label className="form-item-title">Lost Reason</label>
              <div className="dropdown">
                <div className="input-group" data-toggle="dropdown">
                  <Input placeholder="Select..." />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <ChevronDown />
                    </span>
                  </div>
                </div>
                <div className="dropdown-menu wd-100p">
                  <Button
                    color=""
                    className="dropdown-item"
                    icon="Plus"
                    iconClass="mg-r-5"
                    name="Add new lost reason"
                  />
                  <div className="dropdown-divider"></div>
                  <Button color="" className="dropdown-item" name="Too expensive" />
                  <Button color="" className="dropdown-item" name="Not enough Stock" />
                  <Button color="" className="dropdown-item" name="We don't have people/skills" />
                  <Button color="" className="dropdown-item" name="Not interested" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Actual Revenue</label>
              <Input type="currency" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Close Date</label>
              <DatePicker
                selected={new Date()}
                onChange={() => {
                  console.log();
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Competitor</label>
              <div className="table-responsive bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Website
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input />
                      </td>
                      <td>
                        <Input type="url" />
                      </td>
                      <td>
                        <Button color="link" icon="X" iconClass="tx-danger" className="btn-icon" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                <Input placeholder="Competitor name" />
                <Button
                  color="primary"
                  icon="Plus"
                  iconClass="mg-r-5"
                  name="Add"
                  className="flex-shrink-0 mg-l-10"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <Input />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseOpportunity;
