import { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  ChevronsLeft,
  ArrowLeft,
  X,
  Plus,
  Edit2,
  Check,
  Copy,
  MoreHorizontal,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"

import Timeline from '../timeline';
import NewUnit from '../modal/newUnit';

const TABS = [
  {
    value: 1,
    label: 'Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
];

const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [headerColor, setHeaderColor] = useState(COLOS[0]);
  const [showEdit, setShowEdit] = useState<any>();

  const [input, setInput] = useState<any>('');
  const [addNew, setAddNew] = useState<any>('');

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/loaylty/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Unit
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title mg-l-10 lh-1">Bottle</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="dropdown">
              <button
                type="button"
                data-han-tooltip="More"
                className="btn btn-white btn-icon rounded-circle han-tooltip"
                data-toggle="dropdown"
              >
                <span className="span-icon">
                  <MoreHorizontal />
                </span>
                <span className="sr-only">더보기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Copy />
                  </span>
                  Clone
                </button>
                <button type="button" className="dropdown-item">
                  <span className="dropdown-item-icon">
                    <Trash />
                  </span>
                  Delete
                </button>
              </div>
            </div>
            <div className="btn-group dropdown mg-l-5">
              <a href="#newUnit" className="btn btn-primary pd-x-10" data-toggle="modal">
                <Plus />
                <span className="sr-only">추가</span>
              </a>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <ChevronDown />
                <span className="sr-only">추가 옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a href="/crm/demo-page/ui/product/desktop/theme1/write" className="dropdown-item">
                  Product
                </a>
                <a href="#newItem" className="dropdown-item" data-toggle="modal">
                  Item
                </a>
                <a href="" className="dropdown-item">
                  Component
                </a>
                <a href="#newUnit" className="dropdown-item" data-toggle="modal">
                  Unit
                </a>
                <a href="" className="dropdown-item">
                  Price List
                </a>
                <a href="" className="dropdown-item">
                  Discount
                </a>
                <a href="" className="dropdown-item">
                  Trade Agreement
                </a>
              </div>
            </div>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <span className="view-aside-section-header-title">Summary</span>
              </div>
              <div className="view-aside-section-body">
                <div className="form-group">
                  <label className="form-item-title">Base Unit</label>
                  <div>Bottle</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Active</label>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="unitStatusSwitch1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="unitStatusSwitch1"></label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Owner</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-sm">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>MSR</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* collapse button */}
              <button
                type="button"
                className={classnames(
                  'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                  { hidden: hideAsideLeft },
                )}
                onClick={() => setHideAsideLeft(!hideAsideLeft)}
              >
                <ChevronsLeft />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>

              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map((item, index) => (
                    <li
                      key={index}
                      className={classnames('nav-item', {
                        active: item.value == tab.value,
                      })}
                    >
                      <button
                        type="button"
                        className="btn btn-xs btn-move-tab"
                        aria-label="Drag-and-drop to reorder"
                      >
                        {Icon('move')}
                        <span className="sr-only">드래그앤드롭으로 이동</span>
                      </button>
                      <button
                        type="button"
                        className="btn nav-link"
                        onClick={(e) => {
                          setTab(item);
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="view-body">
              {tab.value == 1 && (
                <div className="pd-15">
                  {!showEdit && (
                    <div className="text-right">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => setShowEdit(true)}
                      >
                        <Edit2 className="mg-r-5" />
                        Edit
                      </button>
                    </div>
                  )}
                  <div className="table-responsive bg-white bd rounded-top">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Unit Name
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Unit Quantity
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Related Products
                          </th>
                          {showEdit && (
                            <th scope="col" className="wd-70 bd-t-0-f">
                              Delete
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Bottle</td>
                          <td>1</td>
                          <td>Product 1, Product 2</td>
                          {showEdit && <td>&nbsp;</td>}
                        </tr>
                        <tr>
                          <td>
                            {showEdit ? (
                              <div className="d-flex align-items-center">
                                <button
                                  type="button"
                                  className="btn btn-xs mg-r-5 pd-0 tx-color-03"
                                  aria-label="Drag-and-drop to reorder"
                                  style={{ cursor: 'grab' }}
                                >
                                  {Icon('move')}
                                  <span className="sr-only">드래그앤드롭으로 이동</span>
                                </button>
                                <input type="text" className="form-control" defaultValue="Pack" />
                              </div>
                            ) : (
                              'Pack'
                            )}
                          </td>
                          <td>
                            {showEdit ? (
                              <input type="text" className="form-control" defaultValue="8" />
                            ) : (
                              '8'
                            )}
                          </td>
                          <td>&nbsp;</td>
                          {showEdit && (
                            <td>
                              <button type="button" className="btn btn-link btn-icon pd-y-0">
                                <X className="tx-danger" />
                              </button>
                            </td>
                          )}
                        </tr>
                        {showEdit && addNew && (
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <button
                                  type="button"
                                  className="btn btn-xs mg-r-5 pd-0 tx-color-03"
                                  aria-label="Drag-and-drop to reorder"
                                  style={{ cursor: 'grab' }}
                                >
                                  {Icon('move')}
                                  <span className="sr-only">드래그앤드롭으로 이동</span>
                                </button>
                                <input type="text" className="form-control" defaultValue={input} />
                              </div>
                            </td>
                            <td>
                              <input type="text" className="form-control" />
                            </td>
                            <td>&nbsp;</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-link btn-icon pd-y-0"
                                onClick={() => setAddNew(false)}
                              >
                                <X className="tx-danger" />
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {showEdit && (
                    <>
                      <div className="d-flex mg-b-10 pd-12 bg-white bd bd-t-0 rounded-bottom">
                        <input
                          type="text"
                          className="form-control mg-r-5"
                          placeholder="Unit Name"
                          value={input}
                          onInput={(e: any) => setInput(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary flex-shrink-0"
                          onClick={() => setAddNew(true)}
                        >
                          <Plus className="mg-r-5" />
                          Add
                        </button>
                      </div>
                      <div className="mg-t-10 text-right">
                        <button
                          type="button"
                          className="btn btn-light mg-r-10"
                          onClick={() => setShowEdit(false)}
                        >
                          <X className="ma-r-5" /> Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => setShowEdit(false)}
                        >
                          <Check className="ma-r-5" /> Edit
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
              {tab.value == 2 && <Timeline />}
            </div>
          </div>
        </div>
      </div>

      <NewUnit />
    </>
  );
};

export default ViewNew;
