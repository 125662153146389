import { useState } from 'react';
import classnames from 'classnames';

import { ChevronDown, CheckCircle, Circle, Check, MapPin, Phone } from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

import PurchaseViewTimeline from './timeline';
import Attachments from '../../../component/desktop/attachments';
import Notelist from '../../../component/desktop/comment';
import Userlist from '../../../component/desktop/userlist';
import PurchaseHeader from './view/header';
import PO from './view/po';
import { Button } from '@base/components/form';

const TABS = [
  {
    value: 1,
    label: 'Product Detail',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Note',
  },
];

const POViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  return (
    <div className="view-wrap">
      <PurchaseHeader title="Subject 1" groupBy="Purchase Order" />

      {/* process */}
      <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
        <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
          <li className="process-item done">
            <button type="button" className="btn process-item-btn">
              <Check />
            </button>
            <div className="process-item-text mg-t-10">Purchase Request</div>
          </li>
          <li className="process-item done">
            <button type="button" className="btn process-item-btn">
              <Check />
            </button>
            <div className="process-item-text mg-t-10">Request for Quote</div>
          </li>
          <li className="process-item current">
            <button type="button" className="btn process-item-btn">
              <CheckCircle />
            </button>
            <div className="process-item-text mg-t-10">Purchase Order</div>
            <div className="mg-t-5">
              <span className="tx-color-03 mg-r-5">Status</span>
              <span>New</span>
            </div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Received</div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Payment</div>
          </li>
        </ol>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 190px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner scroll-box">
            {/* collapse left aside */}
            {!hideAsideLeft && (
              <button
                type="button"
                className="btn btn-hide-aside-left"
                onClick={() => setHideAsideLeft(true)}
              >
                <LeftCollapse />
                <span className="sr-only">좌측 사이드 패널 접기</span>
              </button>
            )}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <span className="view-aside-section-header-title">Summary</span>
              </div>
              <div className="view-aside-section-body">
                <div className="form-group">
                  <label className="form-item-title">PO ID</label>
                  <div>PO-000001</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">PO Date</label>
                  <div>2022-04-29</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Vendor Name</label>
                  <div>
                    <a href="" target="_blank">
                      Vendor 1
                    </a>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Owner</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-xs">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <span className="user-name">MSR</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Created on</label>
                  <div>2022-04-29</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Payment Terms</label>
                  <div>7 days</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Expected Delivery Date</label>
                  <div>2022-05-29</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Delivery to</label>
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="badge badge-dark mg-r-5">Warehouse 1</span>
                    <div>
                      <MapPin size={14} className="mg-r-5 tx-color-03" />
                      Street 52, Seoul,16812, South Korea
                    </div>
                    <div>
                      <Phone size={14} className="mg-r-5 tx-color-03" />
                      0701112232
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Courier</label>
                  <div>DHL</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Scheduled on</label>
                  <div>2022-05-31</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Tracking No</label>
                  <div>5583-1233-4531</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames('col-6 view-content', {
            'col-9': hideAsideLeft || hideAsideRight,
            'col-12': hideAsideLeft && hideAsideRight,
          })}
        >
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('nav-item', {
                      active: item.value == tab.value,
                    })}
                  >
                    <button
                      type="button"
                      className="btn btn-xs btn-move-tab"
                      aria-label="Drag-and-drop to reorder"
                    >
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <button
                      type="button"
                      className="btn nav-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="view-body scroll-box">
            {/* show left aside */}
            {hideAsideLeft && (
              <button
                type="button"
                className="btn btn-show-aside-left"
                onClick={() => setHideAsideLeft(false)}
              >
                <RightCollapse />
                <span className="sr-only">좌측 사이드 패널 열기</span>
              </button>
            )}

            {/* show right aside */}
            {hideAsideRight && (
              <button
                type="button"
                className="btn btn-show-aside-right"
                onClick={() => setHideAsideRight(false)}
              >
                <LeftCollapse />
                <span className="sr-only">우측 사이드 패널 열기</span>
              </button>
            )}
            {tab.value == 1 && <PO />}
            {tab.value == 2 && <PurchaseViewTimeline />}
            {tab.value == 3 && <Notelist />}
          </div>
        </div>

        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
          style={{ transformOrigin: 'left' }}
        >
          <div className="view-aside-inner scroll-box">
            {/* collapse right aside */}
            {!hideAsideRight && (
              <button
                type="button"
                className="btn btn-hide-aside-right"
                onClick={() => setHideAsideRight(true)}
              >
                <RightCollapse />
                <span className="sr-only">우측 사이드 패널 접기</span>
              </button>
            )}
            {/* Purchase Bill */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <Button
                  color=""
                  className="view-aside-section-move mg-r-5"
                  icon="move"
                  iconType="custom"
                  data-han-tooltip="Move"
                />
                <span className="view-aside-section-header-title">Purchase Bill</span>
                <Button
                  color="link link-03"
                  className="mg-l-auto pd-0"
                  icon="ChevronDown"
                  data-toggle="collapse"
                  data-target="#purchaseBill"
                />
              </div>
              <div id="purchaseBill" className="view-aside-section-body collapse show">
                <div className="pd-10">
                  <div className="d-flex flex-wrap align-items-center">
                    <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                      PB-0000001
                    </a>
                    <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                    <span className="mg-l-auto">$26,430</span>
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="mg-r-10 tx-color-03 tx-13">Balance Due : </span>
                    <strong className="mg-l-auto tx-danger">$26,430</strong>
                  </div>
                </div>
              </div>
            </div>

            {/* Purchase Return */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <Button
                  color=""
                  className="view-aside-section-move mg-r-5"
                  icon="move"
                  iconType="custom"
                  data-han-tooltip="Move"
                />
                <span className="view-aside-section-header-title">Purchase Return</span>
                <Button
                  color="link link-03"
                  className="mg-l-auto pd-0"
                  icon="ChevronDown"
                  data-toggle="collapse"
                  data-target="#purchaseReturn"
                />
              </div>
              <div id="purchaseReturn" className="view-aside-section-body collapse show">
                <div className="pd-10">
                  <div className="d-flex flex-wrap align-items-center">
                    <a href="" target="_blank" title="새창으로 열림" className="tx-semibold">
                      RMA-0000001
                    </a>
                    <span className="badge mg-x-5 bg-gray-700 tx-white tx-normal tx-12">New</span>
                    <span className="mg-l-auto">QTY :10</span>
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="mg-r-10 tx-color-03 tx-13">Return Type: </span>
                    <strong className="mg-l-auto">Refund</strong>
                  </div>
                </div>
              </div>
            </div>
            {/* Contact */}
            <div className="view-aside-section">
              <div className="view-aside-section-header">
                <button type="button" className="view-aside-section-move mg-r-5">
                  {Icon('move')}
                  <span className="sr-only">드래그앤드롭으로 이동</span>
                </button>
                <span className="view-aside-section-header-title">Contact</span>
                <button
                  type="button"
                  className="btn btn-link link-03 mg-l-auto pd-0"
                  data-toggle="collapse"
                  data-target="#customerContact"
                >
                  <ChevronDown />
                </button>
              </div>
              <div id="customerContact" className="view-aside-section-body collapse show">
                <Userlist />
              </div>
            </div>

            {/* Attachment */}
            <Attachments />
          </div>
        </div>
      </div>
    </div>
  );
};

export default POViewNew;
