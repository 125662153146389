import { ChevronLeft, ChevronRight } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import noImg from '@base/assets/img/notfound.png';

const AllocatedItems = () => {
  return (
    <div className="pd-20">
      <div className="card collapse show">
        <div className="table-responsive rounded">
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f">
                  Image
                </th>
                <th scope="col" className="bd-t-0-f">
                  Name
                </th>
                <th scope="col" className="bd-t-0-f">
                  SKU
                </th>
                <th scope="col" className="bd-t-0-f">
                  Traceability No
                </th>
                <th scope="col" className="bd-t-0-f">
                  Stock on Hand
                </th>
                <th scope="col" className="bd-t-0-f">
                  Reserved
                </th>
                <th scope="col" className="bd-t-0-f">
                  Backorder
                </th>
                <th scope="col" className="bd-t-0-f">
                  Awaiting
                </th>
                <th scope="col" className="bd-t-0-f">
                  Available
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="avatar avatar-sm d-inline-block mg-r-10 bg-gray-200 rounded">
                    <img src={noImg} alt="" />
                  </div>
                </td>
                <td>
                  <a href="" className="text-nowrap" target="_blank" title="새창으로 열림">
                    Item 1
                  </a>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer pd-y-6-f pagination">
          <button
            type="button"
            className="btn btn-link link-02 btn-icon"
            aria-label="move to first"
          >
            {Icon('move_first')}
            <span className="sr-only">처음으로</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
            <ChevronLeft />
            <span className="sr-only">이전</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
            <ChevronRight />
            <span className="sr-only">다음</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to last">
            {Icon('move_last')}
            <span className="sr-only">마지막으로</span>
          </button>
          <input type="number" className="paging-input" defaultValue="1" />/ 1
        </div>
      </div>
    </div>
  );
};

export default AllocatedItems;
