import Icon from '@base/assets/icons/svg-icons';
import { ChevronDown, ChevronLeft, ChevronRight, Plus, X } from 'react-feather';

const Purchase = () => {
  return (
    <div className="pd-20">
      <div className="form-row">
        <div className="form-group col-lg-6">
          <label className="form-item-title">Purchase Unit</label>
          <div>&nbsp;</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">Purchase Price</label>
          <div>&nbsp;</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">Minimum Qty</label>
          <div>&nbsp;</div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Vendor List</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#vendorList"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="vendorList" className="card  collapse show">
          <div className="table-responsive">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Category
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Vendor Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Purchase Agreement
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Purchase Unit Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Purchase Unit Price
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Minimum QTY
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Delivery Lead TIme
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Preferred
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="wd-16 ht-16 mg-r-5 tx-color-03">{Icon('m_customer')}</span>
                    Contact
                  </td>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                      Woman Peacoat
                    </a>
                  </td>
                  <td>Vendor 1 PA1</td>
                  <td>Pallet</td>
                  <td>￦100,000</td>
                  <td>1</td>
                  <td>3 days</td>
                  <td align="center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio1"
                        name="customRadio"
                        className="custom-control-input"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="customRadio1"></label>
                    </div>
                  </td>
                  <td align="center">
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="wd-16 ht-16 mg-r-5 tx-color-03">
                      {Icon('m_customer_account')}
                    </span>
                    Account
                  </td>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                      Woman Peacoat
                    </a>
                  </td>
                  <td>Vendor 1 PA1</td>
                  <td>BOX</td>
                  <td>￦100,000</td>
                  <td>100</td>
                  <td>7 days</td>
                  <td align="center">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio2"
                        name="customRadio"
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="customRadio2"></label>
                    </div>
                  </td>
                  <td align="center">
                    <button type="button" className="btn btn-link btn-icon pd-y-0">
                      <X className="tx-danger" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pd-12 bd-t">
            <div className="dropdown">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type or click to select a vendor"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                    aria-label="open modal to select vendor"
                    data-toggle="modal"
                  >
                    {Icon('vendor')}
                    <span className="sr-only">선택 팝업 열기</span>
                  </button>
                </div>
              </div>
              <div className="dropdown-menu wd-100p">
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#newVendorModal"
                >
                  <Plus className="mg-r-5" />
                  Add new vendor
                </button>
                <div className="dropdown-divider"></div>
                <button type="button" className="dropdown-item">
                  Woman Peacoat 2
                </button>
              </div>
            </div>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">History Purchased</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto pd-0"
            data-toggle="collapse"
            data-target="#purchasedHistory"
          >
            <ChevronDown />
          </button>
        </div>
        <div id="purchasedHistory" className="card collapse show">
          <div className="table-responsive">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    Purchase Order ID
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Vendor Name
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Item
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Quantity
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Total Price
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Ordered on
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Received on
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" target="_blank" className="text-nowrap" title="새창으로 열림">
                      PO-000001
                    </a>
                  </td>
                  <td>Woman Peacoat</td>
                  <td>Item 1</td>
                  <td>5 Pallet</td>
                  <td>￦500,000</td>
                  <td>2021/08/08</td>
                  <td>2021/08/10</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purchase;
