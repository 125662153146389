const BasicInfo = () => {
  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <span className="view-aside-section-header-title">Summary</span>
      </div>
      <div className="view-aside-section-body">
        <div className="form-group">
          <label className="form-item-title">Component Category</label>
          <div>Inventory</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Component Type</label>
          <div>Part</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Component ID</label>
          <div>COM-000001</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Component Group</label>
          <div>Part Small</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Unit Name</label>
          <div>EA</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Attributes</label>
          <div>S,Black</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Supply Method</label>
          <div>Purchased</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Active</label>
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="itemActiveSwitch1" />
            <label className="custom-control-label" htmlFor="itemActiveSwitch1"></label>
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Owner</label>
          <div className="media align-items-center">
            <div className="avatar avatar-xs">
              <img
                src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                className="rounded-circle"
                alt="MSR"
              />
            </div>
            <div className="media-body mg-l-10">
              <span className="user-name">MSR</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
