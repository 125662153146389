import { useState } from 'react';
import NewAssignmentRule from './modal/newAssignmentRule';
import { Button } from '@base/components/form';
import NewAssignTo from './modal/newAssignTo';
import AssignmentRuleList from './assignment-rule-list';
import AssignmentRuleDetail from './assignment-rule-detail';
import RunReport from './runReport';

const AssignmentRule = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [menu, setMenu] = useState<any>({
    rule: true,
    report: false,
  });

  return (
    <>
      <div className="page-container-wrap">
        <div className="page-container-content">
          <div className="page-container-content-header">
            <h1 className="mg-b-0 mg-l-10 mg-r-10 tx-16 tx-uppercase tx-normal">
              {`${menu.rule ? 'Assignment Rule' : 'Run Report'}`}
            </h1>
            <div className="dropdown">
              <Button
                color="white"
                size="xs"
                icon="ChevronDown"
                className="btn-icon"
                data-toggle="dropdown"
              />
              <div className="dropdown-menu">
                <Button
                  color=""
                  className={`dropdown-item ${menu.rule ? 'active' : ''}`}
                  name="Assignment Rule"
                  onClick={() => setMenu({ rule: true })}
                />
                <Button
                  color=""
                  className={`dropdown-item ${menu.report ? 'active' : ''}`}
                  name="Run Report"
                  onClick={() => setMenu({ report: true })}
                />
              </div>
            </div>
          </div>
          {menu.rule && (
            <div className="d-flex align-items-center pd-x-15 bg-white bd-b">
              <ul className="nav nav-line flex-grow-1 bd-0">
                <li className="nav-item">
                  <Button color="" name="Desk" className="btn nav-link pd-y-15-f active" />
                </li>
                <li className="nav-item">
                  <Button color="" name="Sales" className="btn nav-link pd-y-15-f" />
                </li>
                <li className="nav-item">
                  <Button color="" name="Lead" className="btn nav-link pd-y-15-f" />
                </li>
              </ul>
              <Button
                color="primary"
                className="mg-l-auto"
                icon="Plus"
                iconClass="mg-r-5"
                name="Add"
                data-toggle="modal"
                data-target="#newAssignmentRule"
              />
            </div>
          )}
          <div className="scroll-box pd-20">
            {menu.rule && (
              <>
                {!showDetail ? (
                  <AssignmentRuleList setShowDetail={setShowDetail} />
                ) : (
                  <AssignmentRuleDetail setShowDetail={setShowDetail} />
                )}
              </>
            )}

            {menu.report && <RunReport />}
          </div>
        </div>
      </div>
      <NewAssignmentRule />
      <NewAssignTo />
    </>
  );
};

export default AssignmentRule;
