import { ChevronDown, ChevronUp, Plus, X } from 'react-feather';

import { DatePicker } from '@base/components/date-picker';

const NewTicket = () => {
  return (
    <div
      id="newCampaignTicket"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Ticket</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="form-group">
              <label className="form-item-title">Ticket Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Sessions</label>
              <div className="dropdown">
                <div className="input-group mg-r-10">
                  <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                    <div className="form-control-tag">
                      <span className="form-control-tag-name">All</span>
                      <button
                        type="button"
                        className="btn pd-0 form-control-tag-remove"
                        aria-label="삭제"
                      >
                        <X />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="bd-0 flex-grow-1 mn-wd-250 pd-x-10 rounded"
                      placeholder="Type or click to select a session"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open option to select a session"
                    >
                      <ChevronDown />
                      <span className="sr-only">선택 옵션 열기</span>
                    </button>
                  </div>
                </div>
                <div className="dropdown-menu wd-100p">
                  <button
                    type="button"
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#newUnitNameModal"
                  >
                    <Plus className="mg-r-5" />
                    Add new
                  </button>
                  <div className="dropdown-divider"></div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Price</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text lh-1">＄</span>
                </div>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Sales end</label>
              <DatePicker
                selected={new Date()}
                onChange={() => {
                  console.log();
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">No. of ticket allocated</label>
              <input type="number" className="form-control" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-success">
                Save
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
                <span className="sr-only">옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button type="button" className="dropdown-item">
                  Save and Create New
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTicket;
