import { X } from 'react-feather';

const NewProductCode = () => {
  return (
    <div
      id="productGenCodeModal"
      className="modal fade right product-right-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Code Generation Settings</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <p>You can edit the prefix and number below to suit your needs.</p>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                className="custom-control-input"
                name="productCodeOption"
                id="productCodeOption1"
                defaultChecked
              />
              <label className="custom-control-label" htmlFor="productCodeOption1">
                Continue auto-generating a code with order numbering.
              </label>
            </div>
            <div className="row row-xs mg-l-20 mg-t-5">
              <div className="col-6">
                <div className="form-group">
                  <label className="form-item-title">Prefix</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="form-item-title">Next number</label>
                  <input type="number" className="form-control" />
                </div>
              </div>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                className="custom-control-input"
                name="productCodeOption"
                id="productCodeOption2"
              />
              <label className="custom-control-label" htmlFor="productCodeOption2">
                I will add them manually each time.
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductCode;
