import { ChevronDown, Plus, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const Social = () => {
  return (
    <div
      id="socialModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Social</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="socialSwitch"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="socialSwitch"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Social Account</label>
              <div className="table-responsive">
                <table className="table table-sm mg-b-0">
                  <tbody>
                    <tr>
                      <td className="align-middle">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="socialSwitch2"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="socialSwitch2">
                            <span className="social-icon">{Icon('facebook')}</span>Facebook
                          </label>
                        </div>
                      </td>
                      <td className="pd-x-10">
                        <input
                          type="url"
                          className="form-control"
                          defaultValue="hanbiro@hanbiro.com"
                        />
                      </td>
                      <td className="align-middle">
                        <button type="button" className="btn btn-icon mg-l-auto pd-0">
                          <X className="tx-danger" />
                          <span className="sr-only">삭제</span>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="socialSwitch3"
                            defaultChecked
                          />
                          <label className="custom-control-label" htmlFor="socialSwitch3">
                            <span className="social-icon">{Icon('twitter')}</span>Twitter
                          </label>
                        </div>
                      </td>
                      <td className="pd-x-10">
                        <input
                          type="url"
                          className="form-control"
                          defaultValue="hanbiro@hanbiro.com"
                        />
                      </td>
                      <td className="align-middle">
                        <button type="button" className="btn btn-icon mg-l-auto pd-0">
                          <X className="tx-danger" />
                          <span className="sr-only">삭제</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-t-10 bd-t">
                <div className="input-group mg-r-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type or click to select a social media"
                  />
                  <div className="input-group-append">
                    <button type="button" className="btn">
                      <ChevronDown />
                      <span className="sr-only">선택 옵션 열기</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-lg btn-success">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
