// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quote-site-container{height:auto !important}", "",{"version":3,"sources":["webpack://./src/demo-page/site/site.scss"],"names":[],"mappings":"AAEA,sBACE,sBAAA","sourcesContent":["@import '@base/assets/scss/_reset.scss';\n\n.quote-site-container {\n  height: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quote-site-container": "quote-site-container"
};
export default ___CSS_LOADER_EXPORT___;
