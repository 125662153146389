import { Button, Input, RadioGroup, Select } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import UserGroupSelect from '@base/containers/user-group-select';
import { useState } from 'react';
import CheckOption from '../container/check-option';
import Queue from '../container/queue';
const NewRuleEntry = () => {
  const [assign, setAssign] = useState('user');
  const handleAssign = (e: any) => {
    const { value } = e.target;
    setAssign(value);
  };

  const OPTIONS = [
    { value: 1, label: 'Round Robin' },
    { value: 2, label: 'Load Balance by number' },
  ];
  const [options, setOptions] = useState(OPTIONS);
  const [selectedOption, setSelectedOption] = useState(OPTIONS[0]);
  //change
  const handleOptionChange = (newOption: any) => {
    setSelectedOption(newOption);
  };

  return (
    <div id="newRuleEntry" className="modal right fade">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Enter the rule entry</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="d-flex">
              <span className="mg-r-10 pd-x-12 pd-y-5 bg-primary tx-white">1</span>
              <span className="flex-grow-1 pd-t-5">
                Set the Order in which this rule entry will be processed
              </span>
            </div>
            <div className="d-flex align-items-center mg-l-40 mg-b-20 pd-t-10 bd-t">
              Set Order <Input type="text" className="wd-100 mg-l-10" />
            </div>
            <div className="d-flex">
              <span className="mg-r-10 pd-x-12 pd-y-5 bg-primary tx-white">2</span>
              <span className="flex-grow-1 pd-t-5">Select the criteria</span>
            </div>
            <ul className="list-group mg-t-10 mg-b-20">
              <li className="list-group-item d-flex align-items-center justify-content-between mg-l-40 pd-y-10 bg-white">
                Module
                <Select width={200} className="mg-l-10" />
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between mg-l-40 pd-y-10 bg-white">
                Attribute
                <Select width={200} className="mg-l-10" />
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between mg-l-40 pd-y-10 bg-white">
                Channel
                <Select width={200} className="mg-l-10" />
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between mg-l-40 pd-y-10 bg-white">
                Tag
                <Select width={200} className="mg-l-10" />
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between mg-l-40 pd-y-10 bg-white">
                Ticket Classification
                <Select width={200} className="mg-l-10" />
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between mg-l-40 pd-y-10 bg-white">
                Categories
                <Select width={200} className="mg-l-10" />
              </li>
            </ul>
            <div className="d-flex">
              <span className="mg-r-10 pd-x-12 pd-y-5 bg-primary tx-white">3</span>
              <span className="flex-grow-1 pd-t-5">Select the user or queue to assign</span>
            </div>
            <div className="d-flex mg-l-45 mg-t-10">
              <span className="mg-t-10">Assign to</span>
              <div className="mg-l-10 flex-grow-1">
                {/* a user */}
                <div className="d-flex align-items-center mg-b-10">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="assignRadio"
                      id="assignRadio1"
                      value="user"
                      checked={assign === 'user'}
                      onChange={(e) => handleAssign(e)}
                    />
                    <label className="custom-control-label" htmlFor="assignRadio1">
                      a user
                    </label>
                  </div>
                  <UserAutoComplete single showAvatar />
                </div>

                {/* a assignment group */}
                <div className="d-flex align-items-center mg-b-10">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="assignRadio"
                      id="assignRadio2"
                      value="group"
                      checked={assign === 'group'}
                      onChange={(e) => handleAssign(e)}
                    />
                    <label className="custom-control-label tx-nowrap" htmlFor="assignRadio2">
                      a Assignment Group
                    </label>
                  </div>
                  <UserGroupSelect showAvatar single />
                </div>
                {assign === 'group' && <Queue className="mg-l-30" />}

                {/* a user of several users */}
                <div className="d-flex align-items-center mg-b-10">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="assignRadio"
                      id="assignRadio3"
                      value="users"
                      checked={assign === 'users'}
                      onChange={(e) => handleAssign(e)}
                    />
                    <label className="custom-control-label" htmlFor="assignRadio3">
                      a user of several users
                    </label>
                  </div>
                </div>

                {assign === 'users' && (
                  <div className="mg-l-30">
                    <div className="form-group">
                      <label className="form-item-title">Add Users</label>
                      <UserAutoComplete showAvatar />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Assign to user with</label>
                      <RadioGroup
                        value={selectedOption.value}
                        options={options}
                        onChange={handleOptionChange}
                        isVertical
                      />
                      <CheckOption />
                    </div>
                  </div>
                )}

                {/* a Assignment Group of several Group */}
                <div className="d-flex align-items-center mg-b-10">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="assignRadio"
                      id="assignRadio4"
                      value="groups"
                      checked={assign === 'groups'}
                      onChange={(e) => handleAssign(e)}
                    />
                    <label className="custom-control-label" htmlFor="assignRadio4">
                      a Assignment Group of several Group
                    </label>
                  </div>
                </div>

                {assign === 'groups' && (
                  <div className="mg-l-30">
                    <div className="form-group">
                      <label className="form-item-title">Add Groups</label>
                      <UserGroupSelect showAvatar />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Assign to a Group with</label>
                      <RadioGroup
                        value={selectedOption.value}
                        options={options}
                        onChange={handleOptionChange}
                        isVertical
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">In a Group Selected</label>
                      <Queue />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" name="Close" data-dismiss="modal" />
            <Button color="success" name="Add" data-dismiss="modal" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewRuleEntry;
