import { Checkbox, RadioGroup } from '@base/components/form';
import { useState } from 'react';

const CheckOption = () => {
  const CHECK_OPTIONS = [
    { value: 1, label: 'Yes' },
    { value: 2, label: 'No' },
  ];
  const [checkOptions, setCheckOptions] = useState(CHECK_OPTIONS);
  const [selectedCheckOptions, setSelectedCheckOptions] = useState(CHECK_OPTIONS[0]);
  //change
  const handleCheckOptionsChange = (newOption: any) => {
    setSelectedCheckOptions(newOption);
  };

  return (
    <div className="form-group">
      <label className="form-item-title">Check user availability before assigning?</label>
      <RadioGroup
        value={selectedCheckOptions.value}
        options={checkOptions}
        onChange={handleCheckOptionsChange}
        isVertical
      />
      <div className="d-flex align-items-center">
        Check availability based on
        <Checkbox label="Online Status" className="mg-l-10" />
      </div>
    </div>
  );
};
export default CheckOption;
