import { Button } from '@base/components/form';

const OpportunityDetail = () => {
  return (
    <div className="pd-20">
      <div className="form-row">
        <div className="form-group col-lg-6">
          <label className="form-item-title">Est. Revenue</label>
          <div>&nbsp;</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">Est. Close Date</label>
          <div>&nbsp;</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">Opportunity Process</label>
          <div>&nbsp;</div>
        </div>
        <div className="form-group col-lg-6">
          <label className="form-item-title">Quote Process</label>
          <div>&nbsp;</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Description</label>
          <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
        </div>
      </div>

      {/* Related */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button
            color=""
            className="view-aside-section-move mg-r-5"
            icon="move"
            iconType="custom"
            data-han-tooltip="Move"
          />
          <span className="view-aside-section-header-title">Related</span>
          <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#opportunityRelated"
          />
        </div>
        <div id="opportunityRelated" className="card collapse show">
          &nbsp;
        </div>
      </div>

      {/* Lead Info */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button
            color=""
            className="view-aside-section-move mg-r-5"
            icon="move"
            iconType="custom"
            data-han-tooltip="Move"
          />
          <span className="view-aside-section-header-title">Lead Info</span>
          <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#leadInfo"
          />
        </div>
        <div id="leadInfo" className="card collapse show">
          &nbsp;
        </div>
      </div>
    </div>
  );
};
export default OpportunityDetail;
