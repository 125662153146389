import React, { useState } from 'react';
import classnames from 'classnames';

import Icon from '@base/assets/icons/svg-icons';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Upload,
  Download,
  MoreHorizontal,
  X,
  RotateCcw,
  Settings,
  Menu,
  Calendar,
  Trello,
  AlertTriangle,
  User,
  ChevronLeft,
  ChevronRight,
} from 'react-feather';

import './style.scss';

import Pagination from '../../../component/desktop/pagination-new';

import TaskWrite from './task-write';
import MeetingWrite from './meeting-write';
import EmailWrite from './email-write';
import CallWrite from './call-write';
import SMSWrite from './sms-write';
import FaxWrite from './fax-write';
import DMWrite from './dm-write';
import Instruction from './instrunction';
import KnowledgeBaseList from './kb-list';

const ActivityList = () => {
  const [listType, setListType] = useState<any>({
    kanban: true,
    list: false,
    grid: false,
    calendar: false,
    timeline: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState(false);

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            data-han-tooltip="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Activity</h1>
          <div className="dropdown mg-l-5">
            <button type="button" className="btn btn-xs btn-white btn-icon" data-toggle="dropdown">
              <ChevronDown />
              <span className="sr-only">변경할 메뉴 열기</span>
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item">
                <a href="/mywork/desktop/theme1/dashboard">Dashboard</a>
              </div>
              <div className="dropdown-item active">
                <a href="/activity/desktop/theme1/list">Activity</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  data-han-tooltip="Set as default"
                >
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
            </div>
          </div>
          <div className="dropdown mg-l-auto">
            <button type="button" className="btn btn-primary btn-icon" data-toggle="dropdown">
              <Plus />
              <ChevronDown />
              <span className="sr-only">추가 옵션 열기</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newTaskModal"
              >
                <span className="dropdown-item-icon">{Icon('task')}</span>Task
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newMeetingModal"
              >
                <span className="dropdown-item-icon">{Icon('meeting')}</span>Meeting
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newCallModal"
              >
                <span className="dropdown-item-icon">{Icon('call')}</span>Call
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newEmailModal"
              >
                <span className="dropdown-item-icon">{Icon('email')}</span>Email
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newSMSModal"
              >
                <span className="dropdown-item-icon">{Icon('sms')}</span>SMS
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newFaxModal"
              >
                <span className="dropdown-item-icon">{Icon('fax')}</span>Fax
              </button>
              <button
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#newDMModal"
              >
                <span className="dropdown-item-icon">{Icon('dm')}</span>Direct Mail
              </button>
            </div>
          </div>
          <div className="button-group">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="More"
                data-toggle="dropdown"
              >
                <MoreHorizontal />
                <ChevronDown />
                <span className="sr-only">선택 옵션 열기</span>
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item">
                  <Upload className="mg-r-5" />
                  Import
                </button>
                <button className="dropdown-item">
                  <Download className="mg-r-5" />
                  Export
                </button>
              </div>
            </div>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="List type"
                data-toggle="dropdown"
              >
                {listType.kanban && <Trello />}
                {listType.list && <List />}
                {listType.grid && <Grid />}
                {listType.calendar && <Calendar />}
                {listType.timeline && <>{Icon('timeline')}</>}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.kanban })}
                  onClick={() => setListType({ kanban: true })}
                >
                  <Trello className="mg-r-5" />
                  Kanban
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.calendar })}
                  onClick={() => setListType({ calendar: true })}
                >
                  <Calendar className="mg-r-5" />
                  Calendar
                </button>
                <button
                  type="button"
                  className={classnames('dropdown-item', { active: listType.timeline })}
                  onClick={() => setListType({ timeline: true })}
                >
                  <span className="dropdown-item-icon">{Icon('timeline')}</span>Timeline
                </button>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" data-han-tooltip="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" data-han-tooltip="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                All Activities
                <ChevronDown />
              </button>
              <div className="dropdown-menu">
                <button className="dropdown-item active">All Activities</button>
                <button className="dropdown-item">My Activities</button>
                <button className="dropdown-item">My Group Activities</button>
                <button className="dropdown-item">Overdue Activities</button>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Created This Month
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300">
                <button className="dropdown-item">All</button>
                <div className="dropdown-divider"></div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold"
                  data-toggle="collapse"
                  data-target="#dateBy1"
                >
                  <span className="mg-r-5">Created This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <div id="dateBy1" className="collapse show">
                  <button type="button" className="dropdown-item">
                    This Year
                  </button>
                  <button type="button" className="dropdown-item">
                    This Quarter
                  </button>
                  <button type="button" className="dropdown-item active">
                    This Month
                  </button>
                  <button type="button" className="dropdown-item">
                    This Week
                  </button>
                  <button type="button" className="dropdown-item">
                    Today
                  </button>
                  <button
                    type="button"
                    className="dropdown-item btn d-flex align-items-center"
                    data-toggle="collapse"
                    data-target="#dateBy1-custom"
                  >
                    <span className="mg-r-5">Custom</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                    <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy2"
                >
                  <span className="mg-r-5">Started This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <div id="dateBy2" className="collapse">
                  <button type="button" className="dropdown-item">
                    This Year
                  </button>
                  <button type="button" className="dropdown-item">
                    This Quarter
                  </button>
                  <button type="button" className="dropdown-item">
                    This Month
                  </button>
                  <button type="button" className="dropdown-item">
                    This Week
                  </button>
                  <button type="button" className="dropdown-item">
                    Today
                  </button>
                  <button
                    type="button"
                    className="dropdown-item btn d-flex align-items-center"
                    data-toggle="collapse"
                    data-target="#dateBy1-custom"
                  >
                    <span className="mg-r-5">Custom</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                    <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#dateBy3"
                >
                  <span className="mg-r-5">Due Date This Month</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <div id="dateBy3" className="collapse">
                  <button type="button" className="dropdown-item">
                    This Year
                  </button>
                  <button type="button" className="dropdown-item">
                    This Quarter
                  </button>
                  <button type="button" className="dropdown-item">
                    This Month
                  </button>
                  <button type="button" className="dropdown-item">
                    This Week
                  </button>
                  <button type="button" className="dropdown-item">
                    Today
                  </button>
                  <button
                    type="button"
                    className="dropdown-item btn d-flex align-items-center"
                    data-toggle="collapse"
                    data-target="#dateBy1-custom"
                  >
                    <span className="mg-r-5">Custom</span>
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
                    <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Calendar />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dropdown list-filter-item">
              <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                Filters
                <ChevronDown />
              </button>
              <div className="dropdown-menu wd-300">
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter1"
                >
                  <span className="mg-r-5">Assigned Rep</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter2"
                >
                  <span className="mg-r-5">Priority</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter3"
                >
                  <span className="mg-r-5">Status</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
                <button
                  type="button"
                  className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                  data-toggle="collapse"
                  data-target="#filter4"
                >
                  <span className="mg-r-5">Type</span>
                  <ChevronDown className="mg-l-auto" />
                </button>
              </div>
            </div>

            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_asc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
                </button>
                <button type="button" className="btn btn-filter pd-l-0" data-toggle="dropdown">
                  Sorty by: Subject
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item">Type</button>
                </div>
              </div>
            )}
          </div>

          {/* search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Subject" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Subject
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {/* kanban */}
          {listType.kanban && (
            <div className="d-flex ht-100p">
              <div className="wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="activityCheckAll1"
                    />
                    <label
                      className="custom-control-label tx-bold tx-14 tx-uppercase"
                      htmlFor="activityCheckAll1"
                    >
                      Over Due(1)
                    </label>
                  </div>
                </div>
                <div className="d-flex pd-10 bg-white rounded shadow-sm">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="activityCheck1" />
                    <label className="custom-control-label" htmlFor="activityCheck1"></label>
                  </div>
                  <div className="flex-grow-1" style={{ maxWidth: 'calc(100% - 24px)' }}>
                    <div className="d-flex align-items-center wd-100p">
                      <a
                        href="/activity/desktop/theme1/task-view-new"
                        className="mg-r-10 link-02 tx-bold text-truncate"
                      >
                        <span className="activity-list-icon" data-han-tooltip="Task">
                          {Icon('task')}
                        </span>
                        Activity Subject 1 Activity Subject 1 Activity Subject 1
                      </a>
                      <span className="flex-shrink-0 d-inline-block mg-l-auto pd-x-10 pd-t-2 pd-b-4 bg-danger rounded-30 lh-1 tx-white">
                        Urgent
                      </span>
                    </div>
                    <div className="d-flex align-items-center wd-100p mg-t-10">
                      <span className="d-flex align-items-center tx-13 tx-danger">
                        <Calendar size={14} className="mg-r-5" />
                        2021/08/12
                      </span>
                      <span className="avatar avatar-xs mg-l-auto">
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                          className="rounded-circle"
                          alt="MSM"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wd-25p mg-5 pd-x-10 bg-gray-100 rounded">
                <div className="pd-10">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="activityCheckAll2"
                    />
                    <label
                      className="custom-control-label tx-bold tx-14 tx-uppercase"
                      htmlFor="activityCheckAll2"
                    >
                      Today(1)
                    </label>
                  </div>
                </div>
                <div className="d-flex pd-10 bg-white rounded shadow-sm">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="activityCheck2" />
                    <label className="custom-control-label" htmlFor="activityCheck2"></label>
                  </div>
                  <div className="flex-grow-1" style={{ maxWidth: 'calc(100% - 24px)' }}>
                    <div className="d-flex align-items-center wd-100p">
                      <a href="" className="mg-r-10 link-02 tx-bold text-truncate">
                        <span className="activity-list-icon" data-han-tooltip="Fax">
                          {Icon('fax')}
                        </span>
                        Activity Subject 2
                      </a>
                      <span className="flex-shrink-0 d-inline-block mg-l-auto pd-x-10 pd-t-2 pd-b-4 bg-danger rounded-30 lh-1 tx-white">
                        Urgent
                      </span>
                    </div>
                    <div className="d-flex align-items-center wd-100p mg-t-10">
                      <span className="d-flex align-items-center tx-13 tx-color-03">
                        <Calendar size={14} className="mg-r-5" />
                        2021/08/13
                        <AlertTriangle
                          size={14}
                          className="mg-l-5 tx-danger"
                          data-han-tooltip="Due date is approaching"
                        />
                      </span>
                      <span className="avatar avatar-xs mg-l-auto">
                        <img
                          src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                          className="rounded-circle"
                          alt="MSM"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* list */}
          {listType.list && (
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered dataTable mg-b-0 bd-0">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="activityCheckAll"
                        />
                        <label className="custom-control-label" htmlFor="activityCheckAll"></label>
                      </div>
                    </th>
                    <th scope="col" className="sorting_asc">
                      Subject
                    </th>
                    <th scope="col" className="sorting">
                      Type
                    </th>
                    <th scope="col" className="sorting">
                      Priority
                    </th>
                    <th scope="col" className="sorting">
                      Assigned Rep
                    </th>
                    <th scope="col" className="sorting">
                      Start Date
                    </th>
                    <th scope="col" className="sorting">
                      Due Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="activityCheck1"
                        />
                        <label className="custom-control-label" htmlFor="activityCheck1"></label>
                      </div>
                    </td>
                    <td className="sorting-cell">
                      <a href="/activity/desktop/theme1/task-view-new">
                        Activity Subject 1 Activity Subject 1 Activity Subject 1
                      </a>
                    </td>
                    <td>
                      <span className="activity-list-icon">{Icon('task')}</span>Task
                    </td>
                    <td>
                      <span className="d-inline-block pd-x-10 pd-t-2 pd-b-4 bg-danger rounded-30 lh-1 tx-white">
                        Urgent
                      </span>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-avatar pd-0"
                          data-toggle="dropdown"
                        >
                          <div className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                              className="rounded-circle"
                              alt="MSM"
                            />
                          </div>
                          <span className="mg-x-5">MSM</span>
                          <ChevronDown />
                        </button>
                        <div className="dropdown-menu">
                          <div className="d-flex align-items-center pd-10">
                            <div className="avatar avatar-xs">
                              <img
                                src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                                className="rounded-circle"
                                alt="MSM"
                              />
                            </div>
                            <span className="mg-x-5">MSM</span>
                            <div className="mg-l-auto">
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-02 btn-icon"
                                aria-label="user info"
                              >
                                <User />
                                <span className="sr-only">사용자 정보 보기</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-02 btn-icon"
                                aria-label="delete"
                              >
                                <X />
                                <span className="sr-only">삭제</span>
                              </button>
                            </div>
                          </div>
                          <div className="dropdown-divider"></div>
                          <button type="button" className="dropdown-item">
                            <Plus className="mg-r-5" />
                            Add a user
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>2021/07/12</td>
                    <td>
                      <span className="tx-danger">2021/08/12</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="activityCheck2"
                        />
                        <label className="custom-control-label" htmlFor="activityCheck2"></label>
                      </div>
                    </td>
                    <td className="sorting-cell">
                      <a href="/activity/desktop/theme1/task-view-new">Activity Subject 2</a>
                    </td>
                    <td>
                      <span className="activity-list-icon">{Icon('fax')}</span>Fax
                    </td>
                    <td>
                      <span className="d-inline-block pd-x-10 pd-t-2 pd-b-4 bg-danger rounded-30 lh-1 tx-white">
                        Urgent
                      </span>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-avatar pd-0"
                          data-toggle="dropdown"
                        >
                          <div className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                              className="rounded-circle"
                              alt="MSM"
                            />
                          </div>
                          <span className="mg-x-5">MSM</span>
                          <ChevronDown />
                        </button>
                        <div className="dropdown-menu">
                          <div className="d-flex align-items-center pd-10">
                            <div className="avatar avatar-xs">
                              <img
                                src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                                className="rounded-circle"
                                alt="MSM"
                              />
                            </div>
                            <span className="mg-x-5">MSM</span>
                            <div className="mg-l-auto">
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-02 btn-icon"
                                aria-label="user info"
                              >
                                <User />
                                <span className="sr-only">사용자 정보 보기</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-xs btn-link link-02 btn-icon"
                                aria-label="delete"
                              >
                                <X />
                                <span className="sr-only">삭제</span>
                              </button>
                            </div>
                          </div>
                          <div className="dropdown-divider"></div>
                          <button type="button" className="dropdown-item">
                            <Plus className="mg-r-5" />
                            Add an user
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>2021/08/01</td>
                    <td>
                      2021/08/13
                      <AlertTriangle
                        size={14}
                        className="mg-l-5 tx-danger"
                        data-han-tooltip="Due date is approaching"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* grid */}
          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="activityCheck1" />
                      <label className="custom-control-label" htmlFor="activityCheck1"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/activity/desktop/theme1/task-view-new"
                        className="grid-list-item-title link-02"
                      >
                        Activity Subject 1 Activity Subject 1 Activity Subject 1
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Type:</span>
                        <span className="grid-list-item-col-content">
                          <span className="activity-list-icon">{Icon('task')}</span>Task
                        </span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Priority:</span>
                        <span className="grid-list-item-col-content">
                          <span className="d-inline-block pd-x-10 pd-t-2 pd-b-4 bg-danger rounded-30 lh-1 tx-white">
                            Urgent
                          </span>
                        </span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Assigned Rep:</span>
                        <div className="grid-list-item-col-content">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn btn-avatar pd-0"
                              data-toggle="dropdown"
                            >
                              <div className="avatar avatar-xs">
                                <img
                                  src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                                  className="rounded-circle"
                                  alt="MSM"
                                />
                              </div>
                              <span className="mg-x-5">MSM</span>
                              <ChevronDown />
                            </button>
                            <div className="dropdown-menu">
                              <div className="d-flex align-items-center pd-10">
                                <div className="avatar avatar-xs">
                                  <img
                                    src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                                    className="rounded-circle"
                                    alt="MSM"
                                  />
                                </div>
                                <span className="mg-x-5">MSM</span>
                                <div className="mg-l-auto">
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-link link-02 btn-icon"
                                    aria-label="user info"
                                  >
                                    <User />
                                    <span className="sr-only">사용자 정보 보기</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-link link-02 btn-icon"
                                    aria-label="delete"
                                  >
                                    <X />
                                    <span className="sr-only">삭제</span>
                                  </button>
                                </div>
                              </div>
                              <div className="dropdown-divider"></div>
                              <button type="button" className="dropdown-item">
                                <Plus className="mg-r-5" />
                                Add an user
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Start Date:</span>
                        <span className="grid-list-item-col-content">2021/07/12</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Due Date:</span>
                        <span className="grid-list-item-col-content tx-danger">2021/08/12</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="activityCheck2" />
                      <label className="custom-control-label" htmlFor="activityCheck2"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/customer/desktop/theme1/account-view"
                        className="grid-list-item-title link-02"
                      >
                        Activity Subject 2
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Type:</span>
                        <span className="grid-list-item-col-content">
                          <span className="activity-list-icon">{Icon('fax')}</span>Fax
                        </span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Priority:</span>
                        <span className="grid-list-item-col-content">
                          <span className="d-inline-block pd-x-10 pd-t-2 pd-b-4 bg-danger rounded-30 lh-1 tx-white">
                            Urgent
                          </span>
                        </span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Assigned Rep:</span>
                        <div className="grid-list-item-col-content">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn btn-avatar pd-0"
                              data-toggle="dropdown"
                            >
                              <div className="avatar avatar-xs">
                                <img
                                  src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                                  className="rounded-circle"
                                  alt="MSM"
                                />
                              </div>
                              <span className="mg-x-5">MSM</span>
                              <ChevronDown />
                            </button>
                            <div className="dropdown-menu">
                              <div className="d-flex align-items-center pd-10">
                                <div className="avatar avatar-xs">
                                  <img
                                    src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                                    className="rounded-circle"
                                    alt="MSM"
                                  />
                                </div>
                                <span className="mg-x-5">MSM</span>
                                <div className="mg-l-auto">
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-link link-02 btn-icon"
                                    aria-label="user info"
                                  >
                                    <User />
                                    <span className="sr-only">사용자 정보 보기</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-link link-02 btn-icon"
                                    aria-label="delete"
                                  >
                                    <X />
                                    <span className="sr-only">삭제</span>
                                  </button>
                                </div>
                              </div>
                              <div className="dropdown-divider"></div>
                              <button type="button" className="dropdown-item">
                                <Plus className="mg-r-5" />
                                Add an user
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Start Date:</span>
                        <span className="grid-list-item-col-content">2021/08/01</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Due Date:</span>
                        <span className="grid-list-item-col-content">
                          2021/08/13
                          <AlertTriangle
                            size={14}
                            className="mg-l-5 tx-danger"
                            data-han-tooltip="Due date is approaching"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* calendar */}
          {listType.calendar && (
            <div className="calendar-header">
              <h2 className="mg-b-0 mg-r-10 tx-20">2022-03</h2>
              <button className="btn btn-sm btn-white btn-icon mg-l-5" data-han-tooltip="previous">
                <ChevronLeft />
              </button>
              <button className="btn btn-sm btn-white btn-icon mg-l-5" data-han-tooltip="next">
                <ChevronRight />
              </button>
              <button className="btn btn-sm btn-white mg-l-5">Today</button>
              <div className="btn-group mg-l-auto">
                <button className="btn btn-sm btn-white active">Month</button>
                <button className="btn btn-sm btn-white">Week</button>
                <button className="btn btn-sm btn-white">Day</button>
              </div>
            </div>
          )}

          {(listType.list || listType.grid) && <Pagination />}
        </div>
      </div>

      <TaskWrite />
      <MeetingWrite />
      <EmailWrite />
      <CallWrite />
      <SMSWrite />
      <FaxWrite />
      <DMWrite />
      <Instruction />
      <KnowledgeBaseList />
    </>
  );
};

export default ActivityList;
