import { useState } from 'react';

import { Download, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const NewLeadCapture = () => {
  const [formType, setFormType] = useState('owned');
  const handleFormTypeChange = (e: any) => {
    const { value } = e.target;
    setFormType(value);
  };

  return (
    <div
      id="newLeadCapture"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Lead Capture</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto">
            <div className="form-group">
              <label className="form-item-title d-block">Sign up Form</label>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="formTypeRadio1"
                  name="formTypeRadio"
                  className="custom-control-input"
                  value="owned"
                  checked={formType === 'owned'}
                  onChange={(e) => handleFormTypeChange(e)}
                />
                <label className="custom-control-label" htmlFor="formTypeRadio1">
                  Owned Contend
                </label>
              </div>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  id="formTypeRadio2"
                  name="formTypeRadio"
                  className="custom-control-input"
                  value="incrm"
                  checked={formType === 'incrm'}
                  onChange={(e) => handleFormTypeChange(e)}
                />
                <label className="custom-control-label" htmlFor="formTypeRadio2">
                  In CRM
                </label>
              </div>
            </div>
            {formType === 'owned' && (
              <button type="button" className="btn btn-white">
                <Download className="mg-r-5" />
                Download API and Guide
              </button>
            )}
            {formType === 'incrm' && (
              <>
                <SelectHook className="mg-b-20" options={[{ value: 1, label: 'Landing Page 1' }]} />
                <div className="form-group">
                  <label className="form-item-title">Type</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Form Name</label>
                  <div>&nbsp;</div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Submission behavior</label>
                  <div>&nbsp;</div>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLeadCapture;
