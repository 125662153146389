import { X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const PostDetails = () => {
  return (
    <div
      id="postDetails"
      className="modal over-modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Post Details</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body overflow-auto">
            <div className="media">
              <div className="avatar avatar-sm">
                <img
                  src="https://blogpfthumb-phinf.pstatic.net/MjAxODExMjNfMjA3/MDAxNTQyOTU0MzY1NzY2.GQ8NTKgoKFs7MRX9Ivnm2Vfhh89zRbVwVD_JLlWGXpcg.gk8JiijHQUgjkRz6yYzKjVyWooGf0dd1973Hkt_rP4og.PNG.hanbiro1999/%25B7%25CE%25B0%25ED.png?type=w161"
                  alt=""
                  className="rounded-circle"
                />
              </div>
              <div className="media-body mg-l-10">
                <span className="social-icon">{Icon('facebook')}</span>
                <strong>Hanbiro</strong>
                <div className="tx-12 tx-color-03">2022-02-10 10:00</div>
                <div className="pd-y-15">
                  Message
                  <img
                    src="https://cdn.one.org/international/media/international/2021/06/04191846/GA_share_1200x628.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="mg-l-40 pd-20 bg-light rounded">
              <div className="media">
                <span className="avatar avatar-sm">
                  <img
                    src="https://blogpfthumb-phinf.pstatic.net/MjAxODExMjNfMjA3/MDAxNTQyOTU0MzY1NzY2.GQ8NTKgoKFs7MRX9Ivnm2Vfhh89zRbVwVD_JLlWGXpcg.gk8JiijHQUgjkRz6yYzKjVyWooGf0dd1973Hkt_rP4og.PNG.hanbiro1999/%25B7%25CE%25B0%25ED.png?type=w161"
                    alt=""
                    className="rounded-circle"
                  />
                </span>
                <div className="media-body mg-l-10">
                  <input type="text" className="form-control" placeholder="Write a comment" />
                </div>
              </div>
              <div className="media mg-t-20 pd-y-20 bd-t">
                <span className="avatar avatar-sm">
                  <img
                    src="http://themepixels.me/demo/dashforge1.1/assets/img/img8.jpg"
                    alt=""
                    className="rounded-circle"
                  />
                </span>
                <div className="media-body mg-l-10">
                  <strong className="mg-r-10">Angeline</strong>
                  <span className="tx-color-03">1 hour</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis dignissim nibh.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetails;
