import { Button, Checkbox, Input, Select } from '@base/components/form';
import { useState } from 'react';

const NewLeadScoringRule = () => {
  const [showActivities, setShowActivities] = useState(false);
  return (
    <div id="newLeadScoringRule" className="modal fade right" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Lead Scoring Rule</h1>
            <Button close data-dismiss="modal" aria-label="닫기" />
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="form-group">
              <label className="d-block form-item-title">Scoring Rule Name</label>
              <Input />
            </div>
            <div className="form-group">
              <label className="d-block form-item-title">Description</label>
              <Input />
            </div>
            {/* Lead Classification */}
            <div className="form-group">
              <label className="d-block form-item-title">Lead Classification</label>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="leadClassification1"
                  name="leadClassification"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="leadClassification1">
                  Total
                </label>
              </div>
              <div className="custom-control custom-radio d-flex align-items-center">
                <input
                  type="radio"
                  id="leadClassification2"
                  name="leadClassification"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="leadClassification2">
                  Score in last
                </label>
                <Input className="wd-100 mg-x-5" />
                <Select
                  className="wd-150-f"
                  defaultValue={{ value: 'w', label: 'weeks' }}
                  options={[
                    { value: 'w', label: 'weeks' },
                    { value: 'm', label: 'months' },
                  ]}
                />
              </div>
            </div>
            {/* Behavioral Scoring */}
            <div className="form-group">
              <label className="form-item-title">Behavioral Scoring</label>
              <Checkbox label="Scoring in Journey Process" />
              <Checkbox
                label="Scoring for Activities"
                onChange={() => setShowActivities(!showActivities)}
              />
              {showActivities && (
                <>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="Email"
                      data-toggle="collapse"
                      data-target="#emailScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="emailScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Open
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Bounce
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Incoming
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Reply
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="Call"
                      data-toggle="collapse"
                      data-target="#callScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="callScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Incoming
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Outgoing
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Miss call in outgoing
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Positive
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Negative
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="Event"
                      data-toggle="collapse"
                      data-target="#eventScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="eventScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Registration
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Attendance
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="Meeting"
                      data-toggle="collapse"
                      data-target="#meetingScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="meetingScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Request
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Done
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="Site/Landing Page"
                      data-toggle="collapse"
                      data-target="#siteScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="siteScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Visit
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Form Submission
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Download
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Video Play
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="Social"
                      data-toggle="collapse"
                      data-target="#socialScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="socialScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Engagement
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          Stories
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="Survey"
                      data-toggle="collapse"
                      data-target="#surveyScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="surveyScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Responded
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="TeamChannel(Chat)"
                      data-toggle="collapse"
                      data-target="#chatScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="chatScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Interacted with Chat
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pd-y-5 mg-l-30 bd-b">
                    <Button
                      color="link link-03"
                      icon="ChevronDown"
                      iconClass="mg-r-5"
                      block
                      name="Click to Action"
                      data-toggle="collapse"
                      data-target="#ctaScoring"
                      className="collapsed pd-x-0 text-left"
                    />
                    <div id="ctaScoring" className="collapse">
                      <ul className="list-group">
                        <li className="list-group-item d-flex align-items-center">
                          Shorten URL Click
                          <Select
                            width="80px"
                            options={[
                              { value: '+', label: '+' },
                              { value: '-', label: '-' },
                            ]}
                            defaultValue={{ value: '+', label: '+' }}
                            className="mg-l-auto"
                          />
                          <Input type="number" className="wd-80 mg-l-5" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* Scoring based on Lead's Profile */}
            <div className="form-group">
              <label className="form-item-title">Scoring based on Lead's Profile</label>
              <div className="table-responsive bd rounded-top">
                <table className="table table-bordered bd-0 mg-b-0">
                  <thead>
                    <tr>
                      <th scope="col">Field</th>
                      <th scope="col">Type</th>
                      <th scope="col">Operator</th>
                      <th scope="col">Value</th>
                      <th scope="col">+/-</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input value="Field 1" />
                      </td>
                      <td>
                        <Input />
                      </td>
                      <td>
                        <Input />
                      </td>
                      <td>
                        <Input />
                      </td>
                      <td>
                        <Select
                          width="80px"
                          options={[
                            { value: '+', label: '+' },
                            { value: '-', label: '-' },
                          ]}
                          defaultValue={{ value: '+', label: '+' }}
                        />
                      </td>
                      <td>
                        <Input />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center pd-10 bd bd-t-0 rounded-bottom">
                <Input placeholder="Field" />
                <Button color="primary" icon="Plus" className="flex-shrink-0 mg-l-10" name="Add" />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" data-dismiss="modal" name="Close" />
            <Button color="success" name="Add" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLeadScoringRule;
