import { useState } from 'react';
import { Button, Checkbox, Input, Select } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
const NewAssignTo = () => {
  const [optionsSelected, setOptionsSelected] = useState<any>({
    user: true,
    round: false,
    capacity: false,
  });

  const groupOptions = [
    { value: 1, label: 'Users' },
    { value: 2, label: 'Groups' },
  ];
  const [groupOption, setGroupOption] = useState(groupOptions[0]);

  return (
    <div id="newAssignmentTo" className="modal right fade">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Assign To</h1>
            <Button close data-dismiss="modal" />
          </div>
          <div className="modal-body overflow-auto pd-b-15-f">
            <div className="d-flex flex-wrap align-items-center mg-b-10">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="assignTo1"
                  name="assignTo"
                  className="custom-control-input"
                  defaultChecked
                  onChange={() => setOptionsSelected({ user: true })}
                />
                <label className="custom-control-label" htmlFor="assignTo1">
                  Assign to owner
                </label>
              </div>
              {optionsSelected.user && <UserAutoComplete className="mg-l-20 mg-t-5" />}
            </div>

            {/* Assign to round-robin list */}
            <div className="custom-control custom-radio mg-b-10">
              <input
                type="radio"
                id="assignTo2"
                name="assignTo"
                className="custom-control-input"
                onChange={() => setOptionsSelected({ round: true })}
              />
              <label className="custom-control-label" htmlFor="assignTo2">
                Assign to users (groups) with a round-robin pattern.
              </label>
            </div>
            {optionsSelected.round && (
              <div className="mg-b-10 pd-l-25">
                <div className="d-flex align-items-center mg-b-10">
                  Choose a round-robin list from
                  <Select
                    className="flex-shrink-0 mg-l-10"
                    options={groupOptions}
                    defaultValue={groupOption}
                    onChange={setGroupOption}
                    width={110}
                  />
                </div>
                {/* Users list */}
                {groupOption.value === 1 && <UserAutoComplete />}
                {/* Group list */}
                {groupOption.value === 2 && (
                  <div className="input-group">
                    <Input placeholder="Type or click to select a group" />
                    <div className="input-group-append">
                      <Button color="" className="input-group-btn" icon="User" />
                    </div>
                  </div>
                )}
                <div className="mg-t-10">
                  <label className="tx-color-03">Check availability based on</label>
                  <Checkbox label="Work Day" />
                </div>
              </div>
            )}

            {/* Load Balance by Capacity */}
            <div className="d-flex align-items-center mg-b-10">
              <div className="custom-control custom-radio mg-r-10">
                <input
                  type="radio"
                  id="assignTo3"
                  name="assignTo"
                  className="custom-control-input"
                  onChange={() => setOptionsSelected({ capacity: true })}
                />
                <label className="custom-control-label" htmlFor="assignTo3">
                  Load Balance by Capacity
                </label>
              </div>
              {optionsSelected.capacity && (
                <Select
                  options={groupOptions}
                  defaultValue={groupOption}
                  onChange={setGroupOption}
                  width={110}
                />
              )}
            </div>
            {optionsSelected.capacity && (
              <div className="mg-l-25">
                <ul className="list-group mg-b-10">
                  <li className="list-group-item d-flex align-items-center justify-content-between bg-white pd-10">
                    {groupOption.value === 1 ? 'John Doe' : 'Group 1'}
                    <Input className="wd-100" placeholder="Capacity" />
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between bg-white pd-10">
                    <div className="input-group">
                      <Input
                        placeholder={`Type or click to select ${
                          groupOption.value === 1 ? 'an user' : 'a group'
                        }`}
                      />
                      <div className="input-group-append">
                        <Button color="" className="input-group-btn" icon="User" />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item tx-right tx-semibold">Total Capacity : 100</li>
                </ul>
                <div>
                  <label className="tx-color-03">Check availability based on</label>
                  <Checkbox label="Work Day" />
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <Button color="light" name="Close" data-dismiss="modal" />
            <Button color="success" name="Add" data-dismiss="modal" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewAssignTo;
