import { X } from 'react-feather';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';

const ProductList = () => {
  return (
    <>
      <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-3">
        <div
          className="media align-items-center flex-grow-1"
          style={{ maxWidth: 'calc(100% - 36px' }}
        >
          <a href="#" target="_blank" className="text-truncate" title="새창으로 열림">
            Woman Peacoat
          </a>
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
          >
            <X className="tx-danger" />
            <span className="sr-only">삭제</span>
          </button>
        </div>
      </div>
      <div className="input-group">
        <input type="text" className="form-control" placeholder="Type or click to add a product" />
        <div className="input-group-append">
          <button type="button" className="btn">
            {Icons('product')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductList;
