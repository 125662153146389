import { Select } from '@base/components/form';
import React, { useEffect, useState } from 'react';

const OPTIONS = [
  { value: 'quote', label: 'Quote' },
  { value: 'customer', label: 'Customer' },
  { value: 'product', label: 'Product' },
  { value: 'vendor', label: 'Vendor' },
  { value: 'offer', label: 'Offer' },
  { value: 'reward', label: 'Referral Reward' },
  { value: 'event', label: 'Event' },
];

const SiteType: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  //state
  const [selectedValue, setSelectedValue] = useState<any>(OPTIONS[0]);

  //init data
  useEffect(() => {
    if (value) {
      //single object
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(OPTIONS[0]);
    }
  }, [value]);

  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption);
    //callback
    onChange && onChange(newOption.value);
  };

  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Site Type</label>
        <Select
          options={OPTIONS}
          value={selectedValue}
          onChange={handleValueChange}
          className="wd-300-f"
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">{selectedValue.label} Site Type</label>
        {selectedValue.value === 'vendor' ? (
          <Select
            options={[
              { value: 'rfq', label: 'Request for Quote' },
              { value: 'rft', label: 'Request for Tendor' },
              { value: 'po', label: 'Purchase Order' },
            ]}
            className="wd-300-f"
          />
        ) : (
          <Select />
        )}
      </div>
    </>
  );
};

export default SiteType;
