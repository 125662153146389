import { ChevronDown, Minus, Plus, X } from 'react-feather';
import Icons from '@base/assets/icons/svg-icons';

//import {SelectHook, CalendarHook} from "HanBase/components/Form";
import { Select as SelectHook } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';

const TargetProfile = () => {
  return (
    <div className="pd-20">
      <div className="form-group">
        <label className="form-item-title">Industry</label>
        <SelectHook
          className="wd-200-f"
          options={[{ value: 1, label: 'Administrative Services' }]}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Subsidiary</label>
        <SelectHook
          className="wd-200-f"
          options={[
            { value: 1, label: 'Wholly - owned subsidiaries' },
            { value: 2, label: 'Partly - owned subsidiaries' },
          ]}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Company Size</label>
        <SelectHook className="wd-200-f" options={[{ value: 1, label: 'Micro' }]} />
      </div>
      <div className="form-group">
        <label className="form-item-title">Annual Revenue</label>
        <div className="input-group wd-300">
          <div className="input-group-prepend">
            <span className="input-group-text lh-1">￦</span>
          </div>
          <input type="number" className="form-control" />
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Age(Years in Business)</label>
        <DatePicker
          selected={new Date()}
          onChange={() => {
            console.log();
          }}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Related Account</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Type or click to select an account"
          />
          <div className="input-group-append">
            <button type="button" className="btn">
              {Icons('m_customer_account')}
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Location</label>
        <SelectHook className="wd-200-f" options={[{ value: 1, label: 'Global' }]} />
      </div>
      <div className="form-group">
        <label className="form-item-title">Purchasing Process</label>
        <SelectHook className="wd-200-f" options={[{ value: 1, label: 'Automation' }]} />
      </div>
      <div className="form-group">
        <label className="form-item-title">Website Visits</label>
        <SelectHook className="wd-200-f" options={[{ value: 1, label: 'Direct' }]} />
      </div>
      <div className="form-group">
        <label className="form-item-title">Response to Marketing</label>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="responseRadio1"
            name="responseRadio"
            className="custom-control-input"
          />
          <label className="custom-control-label" htmlFor="responseRadio1">
            Yes
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="responseRadio2"
            name="responseRadio"
            className="custom-control-input"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="responseRadio1">
            No
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Usage</label>
        <div className="custom-control custom-radio">
          <input type="radio" id="usageRadio1" name="usageRadio" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="usageRadio1">
            Heavy
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input type="radio" id="usageRadio2" name="usageRadio" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="usageRadio2">
            Medium
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input type="radio" id="usageRadio3" name="usageRadio" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="usageRadio3">
            Light
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input type="radio" id="usageRadio4" name="usageRadio" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="usageRadio4">
            Non-user
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input type="radio" id="usageRadio5" name="usageRadio" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="usageRadio5">
            Targeted post-purchase
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input type="radio" id="usageRadio6" name="usageRadio" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="usageRadio6">
            Notice when upgraded or phased out
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input type="radio" id="usageRadio7" name="usageRadio" className="custom-control-input" />
          <label className="custom-control-label" htmlFor="usageRadio7">
            Aiding in retention
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="usageRadio8"
            name="usageRadio"
            className="custom-control-input"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="usageRadio8">
            None
          </label>
        </div>
      </div>
    </div>
  );
};

export default TargetProfile;
