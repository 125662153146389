import { useState } from 'react';
import classnames from 'classnames';

import { ChevronDown, ChevronUp, Search, User, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';
import '../../marketing.scss';

import ActivityEmail from './activityEmail';
import ActivityEvent from './activityEvent';
import ActivityPaidMedia from './activityPaidMedia';
import ActivityPaidSocial from './activityPaidSocial';
import ActivityReferralReward from './activityReferralReward';
import ActivitySMS from './activitySMS';
import NewShortenURL from './newShortenURL';
import NewLeadCapture from './newLeadCapture';
import SelectCTA from './selectCTA';
import ActivityDM from './activityDM';
import SelectCollateral from './selectCollateral';
import ActivityPAD from './activityPAD';
import ActOrganicLink from './activityOrganicLink';
import DestinationPage from './destinationPage';
import NewShortenURLSocial from './newShortenURLSocial';

const TABS = [
  {
    value: 1,
    label: 'Summary',
  },
  {
    value: 2,
    label: 'Target',
  },
  {
    value: 3,
    label: 'Campaign Activity Coordination',
  },
];

const ACTIVITY = [
  {
    value: 1,
    label: 'Email',
  },
  {
    value: 2,
    label: 'Event',
  },
  {
    value: 3,
    label: 'Paid Search/Site',
  },
  {
    value: 4,
    label: 'Paid Social',
  },
  {
    value: 5,
    label: 'Referral Reward',
  },
  {
    value: 6,
    label: 'SMS',
  },
  {
    value: 7,
    label: 'Social',
  },
  {
    value: 8,
    label: 'DM',
  },
  {
    value: 9,
    label: 'Print Ads Distribution',
  },
  {
    value: 10,
    label: 'Organic Link',
  },
];

const EMAILTABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'Properties',
  },
  {
    value: 3,
    label: 'Select a Template',
  },
  {
    value: 4,
    label: 'Customization',
  },
  {
    value: 5,
    label: 'Links',
  },
  {
    value: 6,
    label: 'Conversion Process',
  },
  {
    value: 7,
    label: 'Edit Process',
  },
];

const EVENTTABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'Properties',
  },
];

const MEDIATABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'Cost',
  },
];

const SOCIALTABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'Post',
  },
  {
    value: 3,
    label: 'Cost',
  },
];

const REWARDTABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'Journey',
  },
];

const SMSTABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'Select a Template',
  },
  {
    value: 3,
    label: 'Customization',
  },
  {
    value: 4,
    label: 'Links',
  },
];

const DMTABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: '?',
  },
];

const PADTABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'Choose the Print Ads',
  },
  {
    value: 3,
    label: 'Generate QR Code',
  },
  {
    value: 4,
    label: '?',
  },
];

const ORGANICLINKTABS = [
  {
    value: 1,
    label: 'Configuration',
  },
  {
    value: 2,
    label: 'Cost',
  },
];

const NewCampaign = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [emailTab, setEmailTab] = useState(EMAILTABS[0]);
  const [eventTab, setEventTab] = useState(EVENTTABS[0]);
  const [mediaTab, setMediaTab] = useState(MEDIATABS[0]);
  const [socialTab, setSocialTab] = useState(SOCIALTABS[0]);
  const [rewardTab, setRewardTab] = useState(REWARDTABS[0]);
  const [smsTab, setSmsTab] = useState(SMSTABS[0]);
  const [dmTab, setDmTab] = useState(DMTABS[0]);
  const [padTab, setPadTab] = useState(PADTABS[0]);
  const [linkTab, setLinkTab] = useState(ORGANICLINKTABS[0]);
  const [companyActivityType, setCompanyActivityType] = useState(ACTIVITY[0]);

  const [campaignType, setCampaignType] = useState<any>('single');
  const handleCampaignTypeChange = (e: any) => {
    const { value } = e.target;
    setCampaignType(value);
  };

  return (
    <>
      <div
        id="newCampaignModal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">New Campaign</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body d-flex flex-column overflow-hidden pd-0">
              {/* steps */}
              <ul className="steps steps-justified steps-tab">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('step-item', {
                      complete: item.value < tab.value,
                      active: item.value == tab.value,
                      // disabled: item.value > tab.value
                    })}
                  >
                    <button
                      type="button"
                      className="btn step-link rounded-0"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      <span className="step-number">{item.value}</span>
                      <span className="step-title">{item.label}</span>
                    </button>
                  </li>
                ))}
              </ul>
              {tab.value == 1 && (
                <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px' }}>
                  <div className="form-group">
                    <label className="form-item-title d-block">Type</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="campaignTypeOption"
                        id="campaignTypeOption1"
                        value="single"
                        checked={campaignType === 'single'}
                        onChange={(e) => handleCampaignTypeChange(e)}
                      />
                      <label className="custom-control-label" htmlFor="campaignTypeOption1">
                        Single
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="campaignTypeOption"
                        id="campaignTypeOption2"
                        value="multiple"
                        checked={campaignType === 'multiple'}
                        onChange={(e) => handleCampaignTypeChange(e)}
                      />
                      <label className="custom-control-label" htmlFor="campaignTypeOption2">
                        Multiple
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="campaignTypeOption"
                        id="campaignTypeOption3"
                        value="event"
                        checked={campaignType === 'event'}
                        onChange={(e) => handleCampaignTypeChange(e)}
                      />
                      <label className="custom-control-label" htmlFor="campaignTypeOption3">
                        Event
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-item-title">Campaign Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Objective</label>
                    <SelectHook
                      className="wd-300-f"
                      options={[{ value: 1, label: 'Sales Promotion' }]}
                    />
                  </div>
                  {campaignType == 'single' && (
                    <>
                      <div className="form-group">
                        <label className="form-item-title">Campaign Activity</label>
                        <div className="input-group wd-300" data-toggle="dropdown">
                          <div className="form-control d-flex align-items-center bd-r-0">
                            <input
                              type="text"
                              className="bd-0 flex-grow-1 rounded"
                              placeholder="Select..."
                              value={companyActivityType.label}
                            />
                          </div>
                          <div className="input-group-append">
                            <button type="button" className="btn">
                              <ChevronDown />
                            </button>
                          </div>
                        </div>
                        <div className="dropdown-menu wd-300">
                          {ACTIVITY.map((item, index) => (
                            <button
                              key={index}
                              className={classnames('dropdown-item', {
                                active: item.value == companyActivityType.value,
                              })}
                              onClick={(e) => {
                                setCompanyActivityType(item);
                              }}
                            >
                              {item.label}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Marketing Resource</label>
                        <div className="input-group">
                          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                            <div className="form-control-tag">
                              <span className="form-control-tag-name">Email</span>
                              <button type="button" className="btn pd-0 form-control-tag-remove">
                                <X />
                                <span className="sr-only">삭제</span>
                              </button>
                            </div>
                            <input
                              type="text"
                              className="bd-0 flex-grow-1 mn-wd-250"
                              placeholder="Type or click to select a marketing source"
                            />
                          </div>
                          <div className="input-group-append">
                            <button type="button" className="btn">
                              <ChevronDown />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {campaignType == 'multiple' && (
                    <div className="form-group">
                      <label className="form-item-title">Date</label>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Start</label>
                          <DatePicker
                            selected={new Date()}
                            onChange={() => {
                              console.log();
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>End</label>
                          <SelectHook options={[{ value: 1, label: 'When I cancel' }]} />
                        </div>
                      </div>
                    </div>
                  )}
                  {campaignType == 'event' && (
                    <>
                      <div className="form-group">
                        <label className="form-item-title">Campaign Activity</label>
                        <div>Event</div>
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Event Type</label>
                        <SelectHook />
                      </div>
                      <div className="form-group">
                        <label className="form-item-title">Event Date</label>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Start</label>
                            <DatePicker
                              selected={new Date()}
                              onChange={() => {
                                console.log();
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>End</label>
                            <DatePicker
                              selected={new Date()}
                              onChange={() => {
                                console.log();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <label className="form-item-title">Product</label>
                    <div className="input-group">
                      <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
                        <div className="form-control-tag">
                          <span className="d-block avatar avatar-xs mg-r-10">
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0xJV22ugqnu5lwu_NoaL1CeW2SZCx4YbuLQ&amp;usqp=CAU"
                              alt=""
                              className="img rounded-circle"
                            />
                          </span>
                          <span className="form-control-tag-name">Product 111</span>
                          <button type="button" className="btn pd-0 form-control-tag-remove">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <input
                          type="text"
                          className="bd-0 flex-grow-1 mn-wd-250"
                          placeholder="Type or click to select a product"
                        />
                      </div>
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn"
                          aria-label="open modal to select a product"
                        >
                          {Icon('product')}
                          <span className="sr-only">상품 선택 팝업 열기</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Business Process</label>
                    <SelectHook className="wd-300-f" />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <textarea rows={3} cols={10} className="form-control"></textarea>
                  </div>
                </div>
              )}

              {tab.value == 2 && (
                <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px)' }}>
                  <div className="mg-b-10 tx-16 tx-semibold">
                    Select the target {campaignType == 'event' ? 'audiences' : 'members'}
                  </div>
                  <div className="pd-15 bd rounded-top bg-light">
                    {campaignType == 'event' && (
                      <>
                        <div className="d-inline-flex mg-r-5 mg-b-5 pd-x-10 pd-y-5 bg-white bd rounded-30">
                          <div className="media align-items-center">
                            <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                              {Icon('avatar01')}
                            </div>
                            <div className="media-body mg-l-10">Marketing Mary</div>
                          </div>
                          <button type="button" className="btn btn-icon mg-l-10 pd-0">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="d-inline-flex mg-r-5 mg-b-5 pd-x-10 pd-y-5 bg-white bd rounded-30">
                          <div className="media align-items-center">
                            <div className="avatar-persona wd-30-f ht-30-f avatar-bg-06">
                              {Icon('avatar06')}
                            </div>
                            <div className="media-body mg-l-10">Startup Scott</div>
                          </div>
                          <button type="button" className="btn btn-icon mg-l-10 pd-0">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </>
                    )}
                    {campaignType != 'event' && (
                      <>
                        <div className="d-inline-flex ht-40 mg-r-5 mg-b-5 pd-x-10 pd-y-5 bg-white bd rounded-30">
                          <div className="media align-items-center">
                            <div className="media-body mg-l-10">Marketing List 1</div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-link btn-icon mg-l-10 pd-0 han-tooltip"
                            data-han-tooltip="View the members"
                            data-toggle="modal"
                            data-target="#targetMemberListModal"
                          >
                            <Search />
                            <span className="sr-only">목록 보기</span>
                          </button>
                          <button type="button" className="btn btn-icon mg-l-5 pd-0">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                        <div className="d-inline-flex mg-r-5 mg-b-5 pd-x-10 pd-y-5 bg-white bd rounded-30">
                          <div className="media align-items-center">
                            <div className="avatar wd-30-f ht-30-f">
                              <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFKsWzfw2i6z3PI3NlTMPtW_KdRttz_nvqLQ&amp;usqp=CAU"
                                alt="Adventure Works"
                              />
                            </div>
                            <div className="media-body mg-l-10">Adventure Works</div>
                          </div>
                          <button type="button" className="btn btn-icon mg-l-10 pd-0">
                            <X />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </>
                    )}
                    <hr />
                    <div className="d-flex ">
                      <strong className="mg-l-auto pd-x-10 bd-r bd-2 tx-uppercase">Total</strong>
                      <ul className="list-unstyled mg-l-10 mg-b-0">
                        <li className="tx-right">Contact: 100</li>
                        <li className="tx-right">Account: 100</li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group bd bd-t-0 rounded-bottom">
                    <input
                      type="text"
                      className="form-control form-control-lg bd-0"
                      placeholder={
                        campaignType == 'event'
                          ? 'Type or click to select a persona'
                          : 'Type or click to select a member'
                      }
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn bd-0">
                        <User />
                        <span className="sr-only">선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {tab.value == 3 && (
                <>
                  {/* Email */}
                  {companyActivityType.value == 1 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {EMAILTABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < emailTab.value,
                              active: item.value == emailTab.value,
                              // disabled: item.value > emailTab.value
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setEmailTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActivityEmail value={emailTab.value} />
                    </div>
                  )}

                  {/* Event */}
                  {companyActivityType.value == 2 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {EVENTTABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < eventTab.value,
                              active: item.value == eventTab.value,
                              // disabled: item.value > eventTab.value
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setEventTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActivityEvent value={eventTab.value} />
                    </div>
                  )}

                  {/* Paid Media */}
                  {companyActivityType.value == 3 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {MEDIATABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < mediaTab.value,
                              active: item.value == mediaTab.value,
                              // disabled: item.value > emailTab.value
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setMediaTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActivityPaidMedia value={mediaTab.value} />
                    </div>
                  )}

                  {/* Paid Social */}
                  {companyActivityType.value == 4 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {SOCIALTABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < socialTab.value,
                              active: item.value == socialTab.value,
                              // disabled: item.value > emailTab.value
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setSocialTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActivityPaidSocial value={socialTab.value} />
                    </div>
                  )}

                  {/* Referral Reward */}
                  {companyActivityType.value == 5 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {REWARDTABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < rewardTab.value,
                              active: item.value == rewardTab.value,
                              // disabled: item.value > emailTab.value
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setRewardTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActivityReferralReward value={rewardTab.value} />
                    </div>
                  )}

                  {/* SMS */}
                  {companyActivityType.value == 6 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {SMSTABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < smsTab.value,
                              active: item.value == smsTab.value,
                              // disabled: item.value > emailTab.value
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setSmsTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActivitySMS value={smsTab.value} />
                    </div>
                  )}

                  {/* DM */}
                  {companyActivityType.value == 8 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {DMTABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < dmTab.value,
                              active: item.value == dmTab.value,
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setDmTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActivityDM value={dmTab.value} />
                    </div>
                  )}

                  {/* Print Ads Distrubution */}
                  {companyActivityType.value == 9 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {PADTABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < padTab.value,
                              active: item.value == padTab.value,
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setPadTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActivityPAD value={padTab.value} />
                    </div>
                  )}

                  {/* Organic Link */}
                  {companyActivityType.value == 10 && (
                    <div className="d-flex flex-column mg-15 bd rounded overflow-hidden">
                      {/* steps */}
                      <ul className="steps bd-b sub-steps bg-light">
                        {ORGANICLINKTABS.map((item, index) => (
                          <li
                            key={index}
                            className={classnames('step-item', {
                              complete: item.value < linkTab.value,
                              active: item.value == linkTab.value,
                              // disabled: item.value > emailTab.value
                            })}
                          >
                            <button
                              type="button"
                              className="btn step-link rounded-0"
                              onClick={(e) => {
                                setLinkTab(item);
                              }}
                            >
                              <span className="step-number">{item.value}</span>
                              <span className="step-title">{item.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ActOrganicLink value={linkTab.value} />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
                Close
              </button>
              {/* Email */}
              {companyActivityType.value == 1 && (
                <>
                  {emailTab.value == 3 && (
                    <>
                      <button type="button" className="btn btn-white">
                        Skip
                      </button>
                      <button type="button" className="btn btn-primary">
                        Select
                      </button>
                    </>
                  )}
                  {emailTab.value != 3 && (
                    <div className="btn-group dropup">
                      <button type="button" className="btn btn-success">
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-success dropdown-toggle-split"
                        data-toggle="dropdown"
                      >
                        <ChevronUp />
                        <span className="sr-only">옵션 열기</span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        {emailTab.value != 7 && (
                          <button type="button" className="dropdown-item">
                            Save and Next
                          </button>
                        )}
                        {emailTab.value == 7 && (
                          <button type="button" className="dropdown-item">
                            Save and Finish
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* Event */}
              {companyActivityType.value == 2 && (
                <>
                  <div className="btn-group dropup">
                    <button type="button" className="btn btn-success">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-success dropdown-toggle-split"
                      data-toggle="dropdown"
                    >
                      <ChevronUp />
                      <span className="sr-only">옵션 열기</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      {eventTab.value == 1 && (
                        <button type="button" className="dropdown-item">
                          Save and Next
                        </button>
                      )}
                      {eventTab.value == 2 && (
                        <button type="button" className="dropdown-item">
                          Save and Finish
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* Paid Social */}
              {companyActivityType.value == 4 && (
                <>
                  <div className="btn-group dropup">
                    <button type="button" className="btn btn-success">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-success dropdown-toggle-split"
                      data-toggle="dropdown"
                    >
                      <ChevronUp />
                      <span className="sr-only">옵션 열기</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      {socialTab.value == 1 && (
                        <button type="button" className="dropdown-item">
                          Save and Next
                        </button>
                      )}
                      {socialTab.value == 2 && (
                        <button type="button" className="dropdown-item">
                          Save and Finish
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* Referral Reward */}
              {companyActivityType.value == 5 && (
                <>
                  <div className="btn-group dropup">
                    <button type="button" className="btn btn-success">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-success dropdown-toggle-split"
                      data-toggle="dropdown"
                    >
                      <ChevronUp />
                      <span className="sr-only">옵션 열기</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      {rewardTab.value == 1 && (
                        <button type="button" className="dropdown-item">
                          Save and Next
                        </button>
                      )}
                      {rewardTab.value == 2 && (
                        <button type="button" className="dropdown-item">
                          Save and Finish
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* DM */}
              {companyActivityType.value == 8 && (
                <>
                  <div className="btn-group dropup">
                    <button type="button" className="btn btn-success">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-success dropdown-toggle-split"
                      data-toggle="dropdown"
                    >
                      <ChevronUp />
                      <span className="sr-only">옵션 열기</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      {dmTab.value == 1 && (
                        <button type="button" className="dropdown-item">
                          Save and Next
                        </button>
                      )}
                      {dmTab.value == 2 && (
                        <button type="button" className="dropdown-item">
                          Save and Finish
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* Print Ads Distribution */}
              {companyActivityType.value == 9 && (
                <>
                  <div className="btn-group dropup">
                    <button type="button" className="btn btn-success">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-success dropdown-toggle-split"
                      data-toggle="dropdown"
                    >
                      <ChevronUp />
                      <span className="sr-only">옵션 열기</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      {padTab.value !== 4 && (
                        <button type="button" className="dropdown-item">
                          Save and Next
                        </button>
                      )}
                      {padTab.value == 4 && (
                        <button type="button" className="dropdown-item">
                          Save and Finish
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <NewShortenURL />
      <NewShortenURLSocial />
      <DestinationPage />
      <NewLeadCapture />
      <SelectCTA />
      <SelectCollateral />
    </>
  );
};

export default NewCampaign;
