import React, { useState } from 'react';
import {
  ChevronDown,
  Columns,
  Plus,
  RefreshCw,
  Star,
  X,
  Search,
  ChevronRight,
  ChevronLeft,
  Calendar,
  MoreHorizontal,
  Upload,
  Download,
  Mail,
  Phone,
  Check,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

//import "CrmCss/desktop/theme1/theme1.scss";
import './styles.scss';

import { Select as SelectHook } from '@base/components/form';

import NewAccount from '../modal/newAccount';
import NewContact from '../modal/newContact';
//import NewSegment from '../segment/write';
import NewMarketingList from '../marketing-list/write';
import NewPersona from '../buyer-persona/write';
import NewICP from '../icp/write';
import MergeAccount from '../modal/mergeAccount';

import View from './View';

const SideBar = () => {
  const [showSearch, setShowSearch] = useState<any>(false);
  const [selected, setSelected] = useState<any>();

  return (
    <>
      <div className="row">
        <div className="col-2">
          <div className="pane ht-100p">
            <div className="list-wrap split-mode">
              {/* header */}
              <div className="list-header">
                <div className="d-flex align-items-center mg-r-10 mg-b-5">
                  <button
                    type="button"
                    className="btn btn-xs btn-icon btn-favorite"
                    aria-label="즐겨찾기 추가"
                  >
                    <Star size="18" />
                  </button>
                  <h1 className="list-header-title">ACCOUNT</h1>
                  <div className="dropdown mg-l-5">
                    <button
                      type="button"
                      className="btn btn-xs btn-white btn-icon"
                      aria-label="변경할 메뉴 열기"
                      data-toggle="dropdown"
                    >
                      <ChevronDown />
                    </button>
                    <div className="dropdown-menu nav-sub"></div>
                  </div>
                </div>
                <div className="d-flex align-items-center mg-l-auto mg-b-5">
                  <div className="btn-group dropdown">
                    <button
                      type="button"
                      className="btn btn-primary pd-x-10"
                      data-toggle="modal"
                      data-target="#newAccount"
                    >
                      <Plus />
                      <span className="sr-only">추가</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle-split pd-x-5"
                      data-toggle="dropdown"
                    >
                      <ChevronDown />
                      <span className="sr-only">추가 옵션 열기</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <button
                        type="button"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#newAccount"
                      >
                        Account
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#newContact"
                      >
                        Contact
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#newSegment"
                      >
                        Segment
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#newMarketingList"
                      >
                        Marketing List
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#newBuyerPersonaModal"
                      >
                        Buyer Persona
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#newICPModal"
                      >
                        Ideal Customer Profile
                      </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-white btn-icon mg-l-5 active"
                    aria-label="split view"
                  >
                    <Columns />
                    <span className="sr-only">분할 보기</span>
                  </button>
                </div>
              </div>

              {/* filter bar */}
              <div className="list-filter-bar">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="productCheckAll" />
                  <label className="custom-control-label" htmlFor="productCheckAll">
                    All
                  </label>
                </div>

                {/* filters */}
                <div className="dropdown mg-l-auto">
                  <button type="button" className="btn" data-toggle="dropdown">
                    {Icon('filter')}
                  </button>
                  <div className="dropdown-menu filter-settings">
                    <div className="filter-settings-title">Filters</div>
                    <div className="filter-settings-body">
                      <div className="form-group">
                        <label className="form-item-title">
                          Group by
                          <button
                            type="button"
                            className="btn btn-link link-03 pd-0 float-right"
                            data-toggle="collapse"
                            data-target="#groupBy"
                          >
                            <ChevronDown />
                          </button>
                        </label>
                        <div id="groupBy" className="collapse show">
                          <button type="button" className="dropdown-item active">
                            All Accounts
                          </button>
                        </div>
                      </div>
                      <div className="dropdown-divider"></div>
                      <div className="form-group">
                        <label className="form-item-title">
                          Date by
                          <button
                            type="button"
                            className="btn btn-link link-03 pd-0 float-right"
                            data-toggle="collapse"
                            data-target="#dateBy"
                          >
                            <ChevronDown />
                          </button>
                        </label>
                        <div id="dateBy" className="collapse show">
                          <button className="dropdown-item">All</button>
                          <button
                            type="button"
                            className="btn d-flex wd-100p align-items-center tx-12 tx-semibold"
                            data-toggle="collapse"
                            data-target="#dateBy1"
                          >
                            <span className="mg-r-5">Created This Month</span>
                            <ChevronDown className="mg-l-auto" />
                          </button>
                          <div id="dateBy1" className="collapse show">
                            <button type="button" className="dropdown-item">
                              This Year
                            </button>
                            <button type="button" className="dropdown-item">
                              This Quarter
                            </button>
                            <button type="button" className="dropdown-item active">
                              This Month
                            </button>
                            <button type="button" className="dropdown-item">
                              This Week
                            </button>
                            <button type="button" className="dropdown-item">
                              Today
                            </button>
                            <button
                              type="button"
                              className="dropdown-item btn d-flex align-items-center"
                              data-toggle="collapse"
                              data-target="#dateBy1-custom"
                            >
                              <span className="mg-r-5">Custom</span>
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div
                              id="dateBy1-custom"
                              className="collapse show input-group pd-x-25 pd-y-5"
                            >
                              <input
                                className="form-control"
                                defaultValue={'2021-12-20 ~ 2021-12-21'}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <Calendar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                            data-toggle="collapse"
                            data-target="#dateBy2"
                          >
                            <span className="mg-r-5">Updated This Month</span>
                            <ChevronDown className="mg-l-auto" />
                          </button>
                          <div id="dateBy2" className="collapse">
                            <button type="button" className="dropdown-item">
                              This Year
                            </button>
                            <button type="button" className="dropdown-item">
                              This Quarter
                            </button>
                            <button type="button" className="dropdown-item">
                              This Month
                            </button>
                            <button type="button" className="dropdown-item">
                              This Week
                            </button>
                            <button type="button" className="dropdown-item">
                              Today
                            </button>
                            <button
                              type="button"
                              className="dropdown-item btn d-flex align-items-center"
                              data-toggle="collapse"
                              data-target="#dateBy1-custom"
                            >
                              <span className="mg-r-5">Custom</span>
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div
                              id="dateBy1-custom"
                              className="collapse show input-group pd-x-25 pd-y-5"
                            >
                              <input
                                className="form-control"
                                defaultValue={'2021-12-20 ~ 2021-12-21'}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <Calendar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn d-flex wd-100p align-items-center tx-12 tx-semibold collapsed"
                            data-toggle="collapse"
                            data-target="#dateBy3"
                          >
                            <span className="mg-r-5">Converted This Month</span>
                            <ChevronDown className="mg-l-auto" />
                          </button>
                          <div id="dateBy3" className="collapse">
                            <button type="button" className="dropdown-item">
                              This Year
                            </button>
                            <button type="button" className="dropdown-item">
                              This Quarter
                            </button>
                            <button type="button" className="dropdown-item">
                              This Month
                            </button>
                            <button type="button" className="dropdown-item">
                              This Week
                            </button>
                            <button type="button" className="dropdown-item">
                              Today
                            </button>
                            <button
                              type="button"
                              className="dropdown-item btn d-flex align-items-center"
                              data-toggle="collapse"
                              data-target="#dateBy1-custom"
                            >
                              <span className="mg-r-5">Custom</span>
                              <ChevronDown className="mg-l-auto" />
                            </button>
                            <div
                              id="dateBy1-custom"
                              className="collapse show input-group pd-x-25 pd-y-5"
                            >
                              <input
                                className="form-control"
                                defaultValue={'2021-12-20 ~ 2021-12-21'}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <Calendar />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-divider"></div>
                      <div className="form-group">
                        <label className="form-item-title">
                          Filters
                          <button
                            type="button"
                            className="btn btn-link link-03 pd-0 float-right"
                            data-toggle="collapse"
                            data-target="#filters"
                          >
                            <ChevronDown />
                          </button>
                        </label>
                        <div id="filters" className="collapse show"></div>
                      </div>
                      <div className="dropdown-divider"></div>
                      <div className="form-group">
                        <label className="form-item-title">
                          <button type="button" className="btn btn-sort pd-0 current-sorting">
                            {/* Add 'current-sorting' class when sorting is applied */}
                            {Icon('sort_asc')} {/* Icon : sort / sort_desc / sort_asc */}
                            <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
                          </button>
                          Sort by
                          <button
                            type="button"
                            className="btn btn-link link-03 pd-0 float-right"
                            data-toggle="collapse"
                            data-target="#sortBy"
                          >
                            <ChevronDown />
                          </button>
                        </label>
                        <div id="sortBy" className="collapse show">
                          <button type="button" className="dropdown-item active">
                            Name
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* more action */}
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-icon"
                    data-han-tooltip="More"
                    data-toggle="dropdown"
                  >
                    <MoreHorizontal />
                    <ChevronDown />
                    <span className="sr-only">선택 옵션 열기</span>
                  </button>
                  <div className="dropdown-menu">
                    <button
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#mergeAccount"
                    >
                      <span className="dropdown-item-icon">{Icon('merge')}</span>Merge
                    </button>
                    <button className="dropdown-item">
                      <Upload className="mg-r-5" />
                      Import
                    </button>
                    <button className="dropdown-item">
                      <Download className="mg-r-5" />
                      Export
                    </button>
                  </div>
                </div>

                <button type="button" className="btn btn-icon" aria-label="reload">
                  <RefreshCw />
                  <span className="sr-only">새로고침</span>
                </button>
                <button
                  type="button"
                  className="btn btn-icon"
                  aria-label="open search input"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <Search />
                  <span className="sr-only">검색창 열기</span>
                </button>

                {showSearch && (
                  <div className="input-group split-search">
                    <input type="search" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Search />
                      </span>
                      <button
                        type="button"
                        className="btn btn-link link-02 btn-icon"
                        onClick={() => setShowSearch(false)}
                      >
                        <X />
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* list body */}
              <div className="list-body">
                <div
                  className="grid-list-item"
                  role="button"
                  onClick={() => setSelected(!selected)}
                >
                  <div className={`avatar mg-r-10 ${selected ? 'selected-item' : ''}`}>
                    {selected ? (
                      <Check size={20} />
                    ) : (
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                        alt=""
                        className="rounded-circle"
                      />
                    )}
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <div
                        className="flex-shrink-0 wd-25 ht-25 mg-r-5 pd-0 bd-0 rounded-circle text-center lh-7 han-tooltip"
                        data-han-tooltip="Gold"
                        style={{ backgroundColor: '#bc9546' }}
                      >
                        <span className="tx-white tx-semibold">G</span>
                      </div>
                      <a
                        href="/crm/demo-page/ui/product/desktop/theme1/view"
                        className="grid-list-item-title link-02"
                      >
                        Adventure Works
                      </a>
                    </div>
                    <ul className="list-unstyled mg-b-0">
                      <li className="d-flex align-items-center mg-t-5">
                        <span className="split-list-icon">
                          <Mail />
                        </span>
                        <a href="mailto:adventureworks@domain.com" className="text-truncate">
                          adventureworks@domain.com
                        </a>
                      </li>
                      <li className="d-flex align-items-center mg-t-5">
                        <span className="split-list-icon">
                          <Phone />
                        </span>
                        <a href="tel:1-518-445-5696" className="text-truncate">
                          +1 518-445-5696
                        </a>
                      </li>
                      <li className="d-flex flex-wrap mg-t-5">
                        <span className="bg-primary op-5 mg-y-5 mg-r-5 pd-x-10 tx-white tx-13 text-truncate tx-normal rounded-30">
                          Manufacturing
                        </span>
                        <span className="bg-indigo op-5 mg-y-5 pd-x-10 tx-white tx-13 text-truncate tx-normal rounded-30">
                          Customer
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* pagination */}
              <div className="list-pagination-wrap">
                <div className="list-pagination">
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="move to first"
                  >
                    {Icon('move_first')}
                    <span className="sr-only">처음으로</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="previous"
                  >
                    <ChevronLeft />
                    <span className="sr-only">이전</span>
                  </button>
                  <strong className="mg-x-10">1</strong>
                  <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
                    <ChevronRight />
                    <span className="sr-only">다음</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-link link-02 btn-icon"
                    aria-label="move to last"
                  >
                    {Icon('move_last')}
                    <span className="sr-only">마지막으로</span>
                  </button>
                </div>
                <div className="wd-80">
                  <SelectHook
                    options={[
                      { value: 1, label: '5' },
                      { value: 2, label: '10' },
                      { value: 3, label: '20' },
                    ]}
                  />
                </div>
              </div>

              {/* <button type="button" className="btn btn-xs btn-block btn-light tx-uppercase tx-12 tx-spacing-1 mn-ht-0">
                        Load more
                    </button> */}
            </div>
          </div>

          <NewAccount />
          <NewContact />
          {/* <NewSegment /> */}
          <NewMarketingList />
          <NewPersona />
          <NewICP />
          <MergeAccount />
        </div>
        <div className="col-10">
          <View />
        </div>
      </div>
    </>
  );
};

export default SideBar;
