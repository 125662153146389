import { useState } from 'react';

import { Clock, Image, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const NewPost = () => {
  const [sendSchedule, setSendSchedule] = useState('now');
  const handleSendScheduleChange = (e: any) => {
    const { value } = e.target;
    setSendSchedule(value);
  };

  return (
    <div id="newPost" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Create Post</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-0">
            <div className="d-flex flex-wrap overflow-auto overflow-lg-hidden">
              <div className="wd-100p wd-lg-50p ht-lg-100p overflow-lg-auto pd-15 bd-r">
                <div className="form-group">
                  <label className="form-item-title d-block">Post on</label>
                  <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="socialCheck1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="socialCheck1">
                      <span className="social-icon">{Icon('facebook')}</span>Hanbiro
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox d-inline-block">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="socialCheck2"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="socialCheck2">
                      <span className="social-icon">{Icon('twitter')}</span>Hanbiro
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title">Message</label>
                  <div className="card">
                    <div contentEditable="true" className="social-content-editor pd-10"></div>
                    <div className="pd-x-10 bd-t">
                      <button
                        type="button"
                        className="btn btn-icon"
                        data-han-tooltip="Insert image"
                      >
                        <Image />
                        <span className="sr-only">Insert image</span>
                      </button>
                      <button type="button" className="btn btn-icon" data-han-tooltip="Insert CTA">
                        {Icon('cta')}
                        <span className="sr-only">Insert CTA</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-item-title d-block">Send Schedule</label>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="sendScheduleRadio1"
                      name="sendScheduleRadio"
                      className="custom-control-input"
                      value="now"
                      checked={sendSchedule === 'now'}
                      onChange={(e) => handleSendScheduleChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="sendScheduleRadio1">
                      Send Now
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="sendScheduleRadio2"
                      name="sendScheduleRadio"
                      className="custom-control-input"
                      value="schedule"
                      checked={sendSchedule === 'schedule'}
                      onChange={(e) => handleSendScheduleChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="sendScheduleRadio2">
                      Schedule
                    </label>
                  </div>
                </div>
                {sendSchedule === 'schedule' && (
                  <div className="form-group">
                    <label className="form-item-title">Send Date</label>
                    <div className="input-group wd-300">
                      <input type="text" className="form-control" />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <Clock />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="wd-100p wd-lg-50p ht-lg-100p overflow-lg-auto pd-15">
                <div className="mg-b-10">Preview</div>
                <div className="card mg-b-10">
                  <div className="card-header pd-10">
                    <span className="social-icon">{Icon('facebook')}</span>Facebook
                  </div>
                  <div className="card-body pd-10 social-content"></div>
                </div>
                <div className="card">
                  <div className="card-header pd-10">
                    <span className="social-icon">{Icon('twitter')}</span>Twitter
                  </div>
                  <div className="card-body pd-10 social-content"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPost;
