import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Printer,
  Trash,
  ChevronDown,
  Plus,
  ArrowLeft,
  Copy,
  CreditCard,
  Search,
} from 'react-feather';
import Icon, { LeftCollapse, RightCollapse } from '@base/assets/icons/svg-icons';

//import '@customer/main/assets/scss/customer.scss';

import AccountDetail from './view/account-detail';
import CustomerViewTimeline from './view/timeline';
import CustomerViewActivity from './view/activity';
import CustomerViewTransaction from './view/transaction';
import CustomerViewProfile from './view/profile';
import AccountStatement from './view/statement';
import Ticket from './view/ticket';
import TradeAgreement from './view/trade-agreement';
import Purchase from './view/purchase';
import Attachments from '../../../component/desktop/attachments';
import AssignedRep from '../../../component/desktop/assigned-rep';
import Userlist from '../../../component/desktop/userlist';
import Notelist from '../../../component/desktop/comment';
import ProductList from '../../../component/desktop/productlist';
import Chat from '../../../component/desktop/chat';
import CustomerProfile from './modal/newCustomerProfile';
import NewAccount from './modal/newAccount';
import NewVendorProduct from './modal/newVendorProduct';
import DebitList from './modal/debitList';
import RegisterPayment from './modal/registerPayment';

const TABS = [
  {
    value: 1,
    label: 'Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Activity',
  },
  {
    value: 4,
    label: 'Note',
  },
  {
    value: 5,
    label: 'Transaction',
  },
  {
    value: 6,
    label: 'Statement',
  },
  {
    value: 7,
    label: 'Trade Agreement',
  },
  {
    value: 8,
    label: 'TeamChannel',
  },
  {
    value: 9,
    label: 'Ticket',
  },
  {
    value: 10,
    label: 'Profile',
  },
  {
    value: 11,
    label: 'Purchase',
  },
];

const COLORS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const AccountView = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();
  const [hideAsideRight, setHideAsideRight] = useState<any>();

  const [headerColor, setHeaderColor] = useState<any>(COLORS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a href="/customer/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Account
              <ChevronDown className="mg-l-5" />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-item active">
                <a role="button">Account</a>
                <button
                  type="button"
                  className="btn btn-xs btn-set-default btn-icon"
                  aria-label="Set as default"
                >
                  {Icon('set_default')}
                  <span className="sr-only">기본 메뉴로 설정</span>
                </button>
              </div>
              <div className="dropdown-item">
                <a role="button">Contact</a>
              </div>
              <div className="dropdown-item">
                <a role="button">Segment</a>
              </div>
              <div className="dropdown-item">
                <a href="/marketing-list/desktop/theme1/list">Marketing List</a>
              </div>
              <div className="dropdown-item">
                <a href="/buyer-persona/desktop/theme1/list">Buyer Persona</a>
              </div>
              <div className="dropdown-item">
                <a href="/icp/desktop/theme1/list">Ideal Customer Profile</a>
              </div>
              <div className="dropdown-item">
                <a href="/blocklist/desktop/theme1/list">Blocklist</a>
              </div>
            </div>
          </div>

          <div
            className="d-inline-block flex-shrink-0 wd-30 ht-30 mg-x-10 pd-0 bd-0 rounded-circle text-center lh-12 han-tooltip"
            data-han-tooltip="Gold"
            style={{ backgroundColor: '#bc9546' }}
          >
            <span className="tx-white tx-semibold">G</span>
          </div>
          <h1 className="view-title">Adventure Works</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">{Icon('export_pdf')}</span>Export to PDF
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Printer />
                    </span>
                    Print
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Copy />
                    </span>
                    Clone
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newAccount"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLORS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  <LeftCollapse />
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section pd-20">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="view-customer-pic">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&usqp=CAU"
                      className="rounded"
                      alt=""
                    />
                  </div>
                </div>
                <div className="mg-t-15">
                  <div className="d-flex flex-column pd-10">
                    <div className="d-flex">
                      <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">
                        Curent Debit
                      </div>
                      <strong className="mg-l-auto tx-15">￦1,000,000</strong>
                    </div>
                    <div className="mg-t-5 text-right">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary rounded-30 mg-r-5"
                        data-toggle="modal"
                        data-target="#debitList"
                      >
                        <Search className="mg-r-5" />
                        Detail
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-info rounded-30"
                        data-toggle="modal"
                        data-target="#registerPayment"
                      >
                        <CreditCard className="mg-r-5" />
                        Pay
                      </button>
                    </div>
                  </div>
                  <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                    <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">
                      Unused Credits
                    </div>
                    <div className="mg-l-auto tx-15 tx-semibold">￦1,000,000</div>
                  </div>
                  <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                    <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                      Amount Recevied
                    </div>
                    <div className="mg-l-auto tx-15 tx-semibold">￦100,000</div>
                  </div>
                </div>
              </div>

              {/* Summary */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">ID</label>
                    <div>ACCT-0000001</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Customer Type</label>
                    <div>Customer</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Email</label>
                    <span className="badge badge-dark mg-r-5">Company</span>
                    <a href="mailto:sales@domain.com">adventureworks@domain.com</a>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Telephone</label>
                    <span className="badge badge-dark mg-r-5">Company</span>
                    <a href="tel:1 518-445-5696">+1 518-445-5696</a>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Industry</label>
                    <div>Leports</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Process</label>
                    <div>Process 1</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('view-content', {
              'col-6': !hideAsideLeft && !hideAsideRight,
              'col-9': (hideAsideLeft && !hideAsideRight) || (hideAsideRight && !hideAsideLeft),
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item, index) =>
                      item.value < 3 && (
                        <li
                          key={index}
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  <RightCollapse />
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show right aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  <LeftCollapse />
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && <AccountDetail />}
              {tab.value == 2 && <CustomerViewTimeline />}
              {tab.value == 3 && <CustomerViewActivity />}
              {tab.value == 4 && <Notelist />}
              {tab.value == 5 && <CustomerViewTransaction />}
              {tab.value == 6 && <AccountStatement />}
              {tab.value == 7 && <TradeAgreement />}
              {tab.value == 8 && <Chat />}
              {tab.value == 9 && <Ticket />}
              {tab.value == 10 && <CustomerViewProfile />}
              {tab.value == 11 && <Purchase />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  <RightCollapse />
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Contact */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Contacts</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerContact"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerContact" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Attachment */}
              <Attachments />

              {/* Product List */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Product</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerProduct"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerProduct" className="view-aside-section-body collapse show">
                  <ProductList />
                </div>
              </div>

              {/* Loyalty */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title ">Loyalty</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#loyaltySection"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="loyaltySection" className="view-aside-section-body collapse show">
                  <div className="media mg-b-5">
                    <div className="wd-40 ht-40 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                      {Icon('point')}
                    </div>
                    <div className="media-body">
                      <div className="tx-uppercase tx-12 tx-spacing-1 tx-info tx-nowrap">
                        Point(s)
                      </div>
                      <div className="tx-18 ">0</div>
                    </div>
                  </div>
                  <div className="media mg-b-5">
                    <div className="wd-40 ht-40 bg-purple tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                      {Icon('stamp')}
                    </div>
                    <div className="media-body">
                      <div className="tx-uppercase tx-12 tx-spacing-1 tx-purple tx-nowrap">
                        Stamp(s)
                      </div>
                      <div className="tx-18 ">0</div>
                    </div>
                  </div>
                  <div className="media">
                    <div className="wd-40 ht-40 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                      {Icon('coupon')}
                    </div>
                    <div className="media-body">
                      <div className="tx-uppercase tx-12 tx-spacing-1 tx-pink tx-nowrap">
                        Coupon(s)
                      </div>
                      <div className="tx-18 tx-obotot"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomerProfile />
      <NewAccount />
      <NewVendorProduct />
      <DebitList />
      <RegisterPayment />
    </>
  );
};

export default AccountView;
