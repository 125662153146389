import { useState } from 'react';
import { Printer, X } from 'react-feather';

import { Select as SelectHook } from '@base/components/form';

const PrintPackingSlip = () => {
  const [packingBy, setPackingBy] = useState<any>('package');
  const handlePackingByChange = (e: any) => {
    const { value } = e.target;
    setPackingBy(value);
  };

  return (
    <div
      id="printPackingSlipModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Print Packing Slip</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body pd-b-15-f overflow-auto">
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label className="form-item-title d-block">Print Option</label>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="packingByRadio1"
                    name="packingByRadio"
                    className="custom-control-input"
                    value="package"
                    checked={packingBy === 'package'}
                    onChange={(e) => handlePackingByChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="packingByRadio1">
                    Print by Package
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="packingByRadio2"
                    name="packingByRadio"
                    className="custom-control-input"
                    value="salesOrder"
                    checked={packingBy === 'salesOrder'}
                    onChange={(e) => handlePackingByChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="packingByRadio2">
                    Print all as one
                  </label>
                </div>
              </div>
              <div className="form-group col-lg-6">
                <label className="form-item-title">Template</label>
                <div className="d-flex align-items-center">
                  <SelectHook
                    className="wd-200-f"
                    options={[{ value: 1, label: 'Template AAA' }]}
                  />
                  <button type="button" className="btn btn-link">
                    Preview
                  </button>
                </div>
              </div>
            </div>
            {packingBy === 'package' && (
              <>
                <div className="custom-control custom-checkbox mg-b-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="slipCheckAll"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="slipCheckAll">
                    All
                  </label>
                </div>
                <div className="d-flex mg-b-10">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="slipCheck1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="slipCheck1"></label>
                  </div>
                  <div className="card card-body shadow-sm" style={{ width: '210mm' }}>
                    <div className="d-flex align-items-center mg-b-20">
                      <img
                        src="https://www.hanbiro.com/images/logo.png"
                        className="wd-150"
                        alt="hanbiro"
                      />
                      <div className="mg-l-auto">
                        137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층
                        <br />
                        Tel 1544-4755
                      </div>
                    </div>
                    <div className="form-row">
                      <h1 className="col-7">Packing Slip</h1>
                      <ul className="list-unstyled col-5">
                        <li>Order ID: 2021/07/05</li>
                        <li>Order Date: 2021/07/05</li>
                        <li>Shipping Via : FedEx</li>
                      </ul>
                    </div>
                    <div className="mg-b-20">
                      <strong className="tx-primary">Customer 1</strong>
                      <br />
                      Contact person name
                    </div>
                    <div className="form-row">
                      <div className="form-group col-7">
                        <label className="form-item-title">Ship To </label>
                        <div>137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층</div>
                      </div>
                      <div className="form-group col-5">
                        <label className="form-item-title">Bill To </label>
                        <div>137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층</div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header tx-bold">Package 1</div>
                      <div className="card-body pd-0">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col">Items</th>
                              <th scope="col">Unit Name</th>
                              <th scope="col">Unit Qty</th>
                              <th scope="col">Unit Price</th>
                              <th scope="col">Qty Ordered</th>
                              <th scope="col">Qty Packed</th>
                            </tr>
                          </thead>
                          <tfoot>
                            <tr>
                              <td colSpan={6} className="text-right tx-bold">
                                Total : 100
                              </td>
                            </tr>
                          </tfoot>
                          <tbody>
                            <tr>
                              <td>Item 1</td>
                              <td>Box</td>
                              <td>12</td>
                              <td>￦10,000</td>
                              <td>100</td>
                              <td>100</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="slipCheck2"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="slipCheck2"></label>
                  </div>
                  <div className="card card-body shadow-sm" style={{ width: '210mm' }}>
                    <div className="d-flex align-items-center mg-b-20">
                      <img
                        src="https://www.hanbiro.com/images/logo.png"
                        className="wd-150"
                        alt="hanbiro"
                      />
                      <div className="mg-l-auto">
                        137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층
                        <br />
                        Tel 1544-4755
                      </div>
                    </div>
                    <div className="form-row">
                      <h1 className="col-7">Packing Slip</h1>
                      <ul className="list-unstyled col-5">
                        <li>Order ID: 2021/07/05</li>
                        <li>Order Date: 2021/07/05</li>
                        <li>Shipping Via : FedEx</li>
                      </ul>
                    </div>
                    <div className="mg-b-20 ">
                      <strong className="tx-primary">Customer 1</strong>
                      <br />
                      Contact person name
                    </div>
                    <div className="form-row">
                      <div className="form-group col-7">
                        <label className="form-item-title">Ship To </label>
                        <div>137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층</div>
                      </div>
                      <div className="form-group col-5">
                        <label className="form-item-title">Bill To </label>
                        <div>137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층</div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header tx-bold">Package 1</div>
                      <div className="card-body pd-0">
                        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                          <thead>
                            <tr>
                              <th scope="col">Items</th>
                              <th scope="col">Unit Name</th>
                              <th scope="col">Unit Qty</th>
                              <th scope="col">Unit Price</th>
                              <th scope="col">Qty Ordered</th>
                              <th scope="col">Qty Packed</th>
                            </tr>
                          </thead>
                          <tfoot>
                            <tr>
                              <td colSpan={6} className="text-right tx-bold">
                                Total : 100
                              </td>
                            </tr>
                          </tfoot>
                          <tbody>
                            <tr>
                              <td>Item 1</td>
                              <td>Box</td>
                              <td>12</td>
                              <td>￦10,000</td>
                              <td>100</td>
                              <td>100</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {packingBy === 'salesOrder' && (
              <>
                <div
                  className="card card-body shadow-sm"
                  style={{ width: '210mm', maxWidth: '100%' }}
                >
                  <div className="d-flex align-items-center mg-b-20">
                    <img
                      src="https://www.hanbiro.com/images/logo.png"
                      className="wd-150"
                      alt="hanbiro"
                    />
                    <div className="mg-l-auto">
                      137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층
                      <br />
                      Tel 1544-4755
                    </div>
                  </div>
                  <div className="form-row">
                    <h1 className="col-7">Packing Slip</h1>
                    <ul className="list-unstyled col-5">
                      <li>Sales Order ID: 2021/07/05</li>
                      <li>Order Date: 2021/07/05</li>
                      <li>Shipping Via : FedEx</li>
                    </ul>
                  </div>
                  <div className="mg-b-20 ">
                    <strong className="tx-primary">Customer 1</strong>
                    <br />
                    Contact person name
                  </div>
                  <div className="form-row">
                    <div className="form-group col-7">
                      <label className="form-item-title">Ship To </label>
                      <div>137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층</div>
                    </div>
                    <div className="form-group col-5">
                      <label className="form-item-title">Bill To </label>
                      <div>137-070 서울시 서초구 서초동 1425-1 보성빌딩 5층</div>
                    </div>
                  </div>
                  <div className="card mg-b-10">
                    <div className="card-header tx-bold">Package 1</div>
                    <div className="card-body pd-0">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col">Items</th>
                            <th scope="col">Unit Name</th>
                            <th scope="col">Unit Qty</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Qty Ordered</th>
                            <th scope="col">Qty Packed</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <td colSpan={6} className="text-right tx-bold">
                              Total : 100
                            </td>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr>
                            <td>Item 1</td>
                            <td>Box</td>
                            <td>12</td>
                            <td>￦10,000</td>
                            <td>100</td>
                            <td>100</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card mg-b-10">
                    <div className="card-header tx-bold">Package 1</div>
                    <div className="card-body pd-0">
                      <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                        <thead>
                          <tr>
                            <th scope="col">Items</th>
                            <th scope="col">Unit Name</th>
                            <th scope="col">Unit Qty</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Qty Ordered</th>
                            <th scope="col">Qty Packed</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <td colSpan={6} className="text-right tx-bold">
                              Total : 100
                            </td>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr>
                            <td>Item 1</td>
                            <td>Box</td>
                            <td>12</td>
                            <td>￦10,000</td>
                            <td>100</td>
                            <td>100</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success" data-dismiss="modal">
              <Printer className="mg-r-5" />
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintPackingSlip;
