import { X } from 'react-feather';

const RealDue = () => {
  return (
    <div id="realDuration" className="modal fade">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="h6 modal-title">Real Duration</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center">
              <div className="input-group wd-150">
                <input type="number" className="form-control" />
                <div className="input-group-append">
                  <span className="input-group-text lh-1">Day</span>
                </div>
              </div>
              <div className="input-group wd-150 mg-l-10">
                <input type="number" className="form-control" />
                <div className="input-group-append">
                  <span className="input-group-text lh-1">Hour</span>
                </div>
              </div>
              <div className="input-group wd-150 mg-l-10">
                <input type="number" className="form-control" />
                <div className="input-group-append">
                  <span className="input-group-text lh-1">Min</span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-light mg-r-5" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealDue;
