import { useState } from 'react';

import {
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  Edit2,
  ExternalLink,
  Mail,
  Plus,
} from 'react-feather';

const Purchase = () => {
  const [showProductDetail, setShowProductDetail] = useState<any>();

  return (
    <div className="pd-20">
      {!showProductDetail && (
        <>
          <div className="mg-b-10 text-right">
            <button
              type="button"
              className="btn btn-primary rounded-30"
              data-toggle="modal"
              data-target="#newVendorProduct"
            >
              <Plus className="mg-r-5" />
              New
            </button>
          </div>
          <div className="table-responsive bg-white bd rounded">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    Vendor Product
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Purchase Unit
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Purchase Price
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Minimum QTY
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Delivery Lead TIme
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Preferred
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link pd-x-0"
                      onClick={() => setShowProductDetail(true)}
                    >
                      Woman Peacoat
                    </button>
                  </td>
                  <td>Vendor 1 PA1</td>
                  <td>Pallet(1,000 Bottle)</td>
                  <td>￦100,000</td>
                  <td>1</td>
                  <td align="center">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="PreferredfCheck1"
                      />
                      <label className="custom-control-label" htmlFor="PreferredfCheck1"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      {showProductDetail && (
        <div className="card">
          <div className="card-header pd-x-5 pd-y-10">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setShowProductDetail(false)}
            >
              <ArrowLeft />
            </button>
            <span className="tx-semibold">Woman Peacoat</span>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label className="form-item-title">Preferred</label>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="PreferredfCheck1" />
                <label className="custom-control-label" htmlFor="PreferredfCheck1"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Purchase Unit</label>
              <div>Box(1,000 Bottle)</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Purchase Price</label>
              <div>￦100,000</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Minimum Qty </label>
              <div>10</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Delivery Lead Time</label>
              <div>5 days</div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Vendor Price List</label>
              <div className="d-flex align-items-center">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="vendorPriceListSwitch"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="vendorPriceListSwitch">
                    Active
                  </label>
                </div>
                <button type="button" className="btn btn-link mg-l-auto">
                  <Edit2 className="mg-r-5" />
                  Edit
                </button>
              </div>
              <div className="table-responsive bd rounded mg-t-10">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Quantity
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Discount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>15 ~ 20</td>
                      <td>5% </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Validity</label>
              <div>2021-12-03 ~ 2022-12-02</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Purchase;
