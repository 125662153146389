import React, { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronDown,
  ChevronsLeft,
  CheckCircle,
  Circle,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

// import "CrmCss/desktop/theme1/theme1.scss"
// import "InvoiceCss/invoice.scss"

const TABS = [
  {
    value: 1,
    label: 'Detail',
  },
];

const ViewNew = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [hideAsideLeft, setHideAsideLeft] = useState<any>();

  return (
    <div className="view-wrap">
      <div className="d-flex align-items-center mn-ht-50 pd-x-10 bg-white bd-b">
        <a
          href="/sales-commission/desktop/theme1/list"
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
        >
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <button
            type="button"
            className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
            data-toggle="dropdown"
          >
            Sales Commission
            <ChevronDown className="mg-l-5" />
          </button>
          <div className="dropdown-menu nav-sub">
            <div className="dropdown-item">
              <a href="/invoice/desktop/theme1/list">Invoices</a>
            </div>
            <div className="dropdown-item">
              <a role="button">Unpaid Invoices</a>
            </div>
            <div className="dropdown-item">
              <a role="button">Credit Note</a>
            </div>
            <div className="dropdown-item">
              <a role="button">Debit Note</a>
            </div>
            <div className="dropdown-item">
              <a role="button">Payments Received</a>
            </div>
            <div className="dropdown-item active">
              <a href="/sales-commission/desktop/theme1/list">
                Sales Commission Expense
              </a>
              <button
                type="button"
                className="btn btn-xs btn-set-default btn-icon"
                aria-label="Set as default"
              >
                {/* Add'active' class if this menu is currently set by default */}
                {Icon('set_default')}
                <span className="sr-only">기본 메뉴로 설정</span>
              </button>
            </div>
          </div>
        </div>

        <h1 className="view-title mg-l-10">Agent A</h1>
      </div>

      {/* process */}
      <div className="pd-10 bg-white bd-b" style={{ height: '140px' }}>
        <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowra">
          <li className="process-item current">
            <button type="button" className="btn process-item-btn">
              <CheckCircle />
            </button>
            <div className="process-item-text mg-t-10">Open</div>
            <div className="mg-t-5">
              <span className="tx-color-03 mg-r-5">Status</span>
              <span>New</span>
            </div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Submitted</div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Approving</div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Sent</div>
          </li>
          <li className="process-item">
            <button type="button" className="btn process-item-btn">
              <Circle />
            </button>
            <div className="process-item-text mg-t-10">Paid</div>
          </li>
        </ol>
      </div>

      <div
        className="row no-gutters flex-nowrap flex-grow-1"
        style={{ height: 'calc(100% - 190px)' }}
      >
        <div
          className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
          style={{ transformOrigin: 'left' }}
        >
          {/* Basic Info */}
          <div className="view-aside-section">
            <div className="view-aside-section-header">
              <span className="view-aside-section-header-title">Basic Info</span>
            </div>
            <div className="view-aside-section-body">
              <div className="form-group">
                <label className="form-item-title">Period</label>
                <div>2021-01-01 ~ 2021-01-30</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Commission Quantity</label>
                <div>300,000</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Commission Amount</label>
                <div>$300</div>
              </div>
            </div>
          </div>
        </div>

        <div className={classnames('col-9 view-content', { 'col-12': hideAsideLeft })}>
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* collapse button */}
            <button
              type="button"
              className={classnames(
                'btn btn-purple rounded-0 mg-r-10 bd-l-0 btn-collapse-aside-left',
                { hidden: hideAsideLeft },
              )}
              onClick={() => setHideAsideLeft(!hideAsideLeft)}
            >
              <ChevronsLeft />
              <span className="sr-only">좌측 사이드 패널 접기</span>
            </button>

            {/* tabs */}
            <div className="view-tabs flex-grow-1">
              <ul className="nav nav-tabs flex-grow-1 bd-0">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('nav-item', {
                      active: item.value == tab.value,
                    })}
                  >
                    <button
                      type="button"
                      className="btn btn-xs btn-move-tab"
                      aria-label="Drag-and-drop to reorder"
                    >
                      {Icon('move')}
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <button
                      type="button"
                      className="btn nav-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="view-body">
            {tab.value == 1 && (
              <div className="pd-15">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                      <thead>
                        <tr>
                          <th scope="col" className="bd-t-0-f">
                            Items
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Quantity
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Commission
                          </th>
                          <th scope="col" className="bd-t-0-f">
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Item 1</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>2021-01-30</td>
                        </tr>
                        <tr>
                          <td>Item 2</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>2021-01-29</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer pd-y-6-f pagination">
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="move to first"
                    >
                      {Icon('move_first')}
                      <span className="sr-only">처음으로</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="previous"
                    >
                      <ChevronLeft />
                      <span className="sr-only">이전</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="next"
                    >
                      <ChevronRight />
                      <span className="sr-only">다음</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon"
                      aria-label="move to last"
                    >
                      {Icon('move_last')}
                      <span className="sr-only">마지막으로</span>
                    </button>
                    <input type="number" className="paging-input" defaultValue="1" />/ 1
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNew;
