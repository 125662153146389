import React, { useState } from 'react';
import classnames from 'classnames';

import {
  ChevronDown,
  Star,
  RefreshCw,
  Search,
  Plus,
  List,
  Grid,
  Columns,
  Settings,
  Menu,
  RotateCcw,
  X,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

import NewCampaign from './modal/newCampaign';
import EventQuestion from './modal/newEventQuestion';
import TargetMemberList from './modal/targetMemberList';
import Pagination from '../../../../component/desktop/pagination-new';

import '../marketing.scss';
import { Checkbox } from '@base/components/form';

const CampList = () => {
  const [listType, setListType] = useState<any>({
    list: true,
    grid: false,
  });

  const [showAdvSearch, setShowAdvSearch] = useState<any>();

  return (
    <>
      <div className="list-wrap">
        <div className="list-header">
          <button
            type="button"
            className="btn btn-xs btn-icon btn-favorite"
            aria-label="즐겨찾기 추가"
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title">Campaign</h1>
          <div className="dropdown mg-l-5">
            <button
              type="button"
              className="btn btn-xs btn-white btn-icon"
              aria-label="변경할 메뉴 열기"
              data-toggle="dropdown"
            >
              <ChevronDown />
            </button>
            <div className="dropdown-menu nav-sub">
              <div className="dropdown-menu nav-sub">
                <div className="dropdown-item">
                  <a href="/">Dashboard</a>
                </div>
                <div className="dropdown-item active">
                  <a href="/campaign/desktop/theme1/list">Campaign</a>
                  <button
                    type="button"
                    className="btn btn-xs btn-set-default btn-icon"
                    data-han-tooltip="Set as default"
                  >
                    {Icon('set_default')}
                    <span className="sr-only">기본 메뉴로 설정</span>
                  </button>
                </div>
                <div className="dropdown-item">
                  <a href="/lead/desktop/theme1/list">Lead</a>
                </div>
                <div className="dropdown-item">
                  <a href="/loyalty/desktop/theme1/list">Loyalty</a>
                </div>
                <div className="dropdown-item">
                  <a href="/online-ditigal-contents/desktop/theme1/list">Marketing Resource</a>
                </div>
              </div>
            </div>
          </div>
          <div className="button-group mg-l-auto">
            <button
              type="button"
              className="btn btn-primary btn-icon"
              data-toggle="modal"
              data-target="#newCampaignModal"
            >
              <Plus />
              <span className="sr-only">Add</span>
            </button>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-white btn-icon"
                aria-label="select a list type"
                data-toggle="dropdown"
              >
                {listType.list ? <List /> : <Grid />}
                <ChevronDown />
                <span className="sr-only">목록 타입 변경</span>
              </button>
              <div className="dropdown-menu">
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.list })}
                  onClick={() => setListType({ list: true })}
                >
                  <List className="mg-r-5" />
                  List
                </a>
                <a
                  role="button"
                  className={classnames('dropdown-item', { active: listType.grid })}
                  onClick={() => setListType({ grid: true })}
                >
                  <Grid className="mg-r-5" />
                  Grid
                </a>
              </div>
            </div>
            <button type="button" className="btn btn-white btn-icon" aria-label="reload">
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
            <button type="button" className="btn btn-white btn-icon" aria-label="split view">
              <Columns />
              <span className="sr-only">분할 보기</span>
            </button>
          </div>
        </div>

        <div className="list-filter-bar">
          <div className="list-filter-items">
            {/* for grid */}
            {listType.grid && (
              <div className="dropdown with-sort list-filter-item">
                <button type="button" className="btn btn-sort pd-r-0 current-sorting">
                  {/* Add 'current-sorting' class when sorting is applied */}
                  {Icon('sort_asc')} {/* Icon : sort / sort_desc / sort_asc */}
                  <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
                </button>
                <button
                  type="button"
                  className="btn btn-filter pd-l-0"
                  data-toggle="dropdown"
                  aria-label="정렬 옵션 열기"
                >
                  Sorty by: Name
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a role="button" className="dropdown-item">
                    Created on
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* search */}
          <div className="input-group input-group-flush list-search">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Search />
              </span>
            </div>
            <div className="d-flex form-control pd-0">
              <input type="search" className="wd-100p bd-0" placeholder="Name" />
              <button
                type="button"
                className="btn btn-link link-03 btn-icon han-tooltip--left"
                data-han-tooltip="Advanced Search"
                onClick={() => setShowAdvSearch(!showAdvSearch)}
              >
                {showAdvSearch ? <X /> : <ChevronDown />}
              </button>
            </div>
            {showAdvSearch && (
              <div className="dropdown-menu show wd-100p pd-0">
                <div className="pd-10 bg-light bd-b">Advanced Search</div>
                <div className="pd-10"></div>
                <div className="pd-10  bd-t text-center">
                  <button type="button" className="btn btn-secondary mg-r-10">
                    <RotateCcw className="mg-r-5" />
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    <Search className="mg-r-5" />
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* for table list column settings */}
          {listType.list && (
            <div className="dropup mg-l-5">
              <button type="button" className="btn btn-white btn-icon" data-toggle="dropdown">
                {Icon('column_settings')}
                <span className="sr-only">항목 설정 열기</span>
              </button>
              <div className="dropdown-menu column-settings">
                <div className="column-settings-title">Column Settings</div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="columnCheck1"
                        defaultChecked
                      />
                      <label className="custom-control-label" htmlFor="columnCheck1">
                        Name
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link link-02 btn-icon handler"
                      aria-label="change the order"
                    >
                      <Menu />
                      <span className="드래그하여 순서 변경"></span>
                    </button>
                  </li>
                </ul>
                <div className="column-settings-actions">
                  Max : <strong className="mg-x-5 tx-primary">9</strong> / 9
                  <button type="button" className="btn btn-light mg-l-auto">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary mg-l-5">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="list-body">
          {listType.list && (
            <div className="table-responsive bd rounded">
              <table className="table table-bordered dataTable mg-b-0 bd-0">
                <thead>
                  <tr>
                    <th scope="col" className="tb-sel-cell">
                      <Checkbox />
                    </th>
                    <th scope="col" className="sorting_asc">
                      Name
                    </th>
                    <th scope="col" className="sorting">
                      Objective
                    </th>
                    <th scope="col" className="sorting">
                      Campaign Activity
                    </th>
                    <th scope="col" className="sorting">
                      Target
                    </th>
                    <th scope="col" className="sorting">
                      Owner
                    </th>
                    <th scope="col" className="sorting">
                      Created on
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/email-view">Email Campaign</a>
                    </td>
                    <td>Anniversary Send</td>
                    <td>Email</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="media-body mg-l-5 text-truncate">Situmay</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/event-view">Event Campaign</a>
                    </td>
                    <td>Lead Generation</td>
                    <td>Event</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                          {Icon('avatar01')}
                        </div>
                        <div className="media-body mg-l-10">Marketing Mary</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/paid-media-view">
                        Paid Search/Site Campaign
                      </a>
                    </td>
                    <td>Lead Generation</td>
                    <td>Paid Search/Site</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                          {Icon('avatar01')}
                        </div>
                        <div className="media-body mg-l-10">Marketing Mary</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/paid-social-view">Paid Social Campaign</a>
                    </td>
                    <td>Lead Generation</td>
                    <td>Paid Social</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                          {Icon('avatar01')}
                        </div>
                        <div className="media-body mg-l-10">Marketing Mary</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/social-view">Social Campaign</a>
                    </td>
                    <td>Lead Generation</td>
                    <td>Social</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                          {Icon('avatar01')}
                        </div>
                        <div className="media-body mg-l-10">Marketing Mary</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/referral-reward-view">Referral Reward</a>
                    </td>
                    <td>Lead Generation</td>
                    <td>Referral Reward</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                          {Icon('avatar01')}
                        </div>
                        <div className="media-body mg-l-10">Marketing Mary</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/dm-view">DM</a>
                    </td>
                    <td>Lead Generation</td>
                    <td>DM</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                          {Icon('avatar01')}
                        </div>
                        <div className="media-body mg-l-10">Marketing Mary</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/print-ads-view">Print Ads Distribute</a>
                    </td>
                    <td>Lead Generation</td>
                    <td>Print Ads Distribute</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                          {Icon('avatar01')}
                        </div>
                        <div className="media-body mg-l-10">Marketing Mary</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                  <tr>
                    <td className="tb-sel-cell">
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <a href="/campaign/desktop/theme1/organic-link-view">Organic Link</a>
                    </td>
                    <td>Lead Generation</td>
                    <td>Organic Link</td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                          {Icon('avatar01')}
                        </div>
                        <div className="media-body mg-l-10">Marketing Mary</div>
                      </div>
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>2021/11/18</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {listType.grid && (
            <div className="row row-xs">
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="campaignCheck1" />
                      <label className="custom-control-label" htmlFor="campaignCheck1"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/campaign/desktop/theme1/email-view"
                        className="grid-list-item-title link-02"
                      >
                        Email Campaign
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Objective:</span>
                        <span className="grid-list-item-col-content">Anniversary Send</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Campaign Activity:</span>
                        <span className="grid-list-item-col-content">Email</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Target:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://themepixels.me/demo/dashforge1.1/assets/img/img6.jpg"
                              className="rounded-circle"
                            />
                          </div>
                          <div className="media-body mg-l-5 text-truncate">Situmay</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Owner:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">MSR</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Created on:</span>
                        <span className="grid-list-item-col-content">2021/11/18</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="grid-list-item without-pic">
                  <div className="grid-list-item-sel">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="campaignCheck2" />
                      <label className="custom-control-label" htmlFor="campaignCheck2"></label>
                    </div>
                  </div>
                  <div className="grid-list-item-detail">
                    <div className="grid-list-item-detail-header">
                      <a
                        href="/campaign/desktop/theme1/event-view"
                        className="grid-list-item-title link-02"
                      >
                        Event Campaign
                      </a>
                    </div>
                    <div className="grid-list-item-detail-body">
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Objective:</span>
                        <span className="grid-list-item-col-content">Lead Generation</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Campaign Activity:</span>
                        <span className="grid-list-item-col-content">Organic Social</span>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Target:</span>
                        <div className="media align-items-center">
                          <div className="avatar-persona wd-30-f ht-30-f avatar-bg-01">
                            {Icon('avatar01')}
                          </div>
                          <div className="media-body mg-l-10">Marketing Mary</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Owner:</span>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">MSR</div>
                        </div>
                      </div>
                      <div className="grid-list-item-col">
                        <span className="grid-list-item-col-title">Created on:</span>
                        <span className="grid-list-item-col-content">2021/11/18</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* pagination */}
          <Pagination />
        </div>
      </div>

      <NewCampaign />
      <TargetMemberList />
      <EventQuestion />
    </>
  );
};

export default CampList;
