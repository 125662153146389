import React, { useState } from 'react';

import { X, Plus, Check, ChevronDown } from 'react-feather';
import { RadioGroup, Select as SelectHook } from '@base/components/form';

import NewCategory from './modal/newCategory';
import NewFolder from './modal/newFolder';

// import "CrmCss/desktop/theme1/theme1.scss";
// import "DeskCss/desk.scss"

const WriteKB = () => {
  const [showTagInput, setShowTagInput] = useState<any>();

  return (
    <>
      <div className="tx-right">
        <span className="tx-danger">*</span> is required.
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Type<span className="tx-danger">*</span>
        </label>
        <RadioGroup
          value="article"
          options={[
            { value: 'article', label: 'Article' },
            { value: 'canned', label: 'Canned Response' },
          ]}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Category<span className="tx-danger">*</span>
        </label>
        <div className="dropdown wd-300">
          <div className="input-group" data-toggle="dropdown">
            <div className="form-control pd-y-1-f d-flex align-items-center">
              <input
                type="text"
                className="bd-0 flex-grow-1 rounded"
                placeholder="Type or click to select a category"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="toggle dropdown to select a tag">
                <ChevronDown />
                <span className="sr-only">태그 선택 옵션 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#newCategoryModal"
            >
              <Plus className="mg-r-5" />
              Add new category
            </button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item text-truncate">
              Category 11111111111111111111111111111111
            </button>
            <button type="button" className="dropdown-item text-truncate">
              Category 2
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Display to</label>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="kbArticleDisplayRadio1"
            name="kbArticleDisplayRadio"
            className="custom-control-input"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="kbArticleDisplayRadio1">
            Public
          </label>
        </div>
        <div className="custom-control custom-radio d-inline-block">
          <input
            type="radio"
            id="kbArticleDisplayRadio2"
            name="kbArticleDisplayRadio"
            className="custom-control-input"
          />
          <label className="custom-control-label" htmlFor="kbArticleDisplayRadio2">
            Private
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Active</label>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="kbArticleActiveSwitch1"
            defaultChecked
          />
          <label className="custom-control-label" htmlFor="kbArticleActiveSwitch1"></label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Tag</label>
        <div className="dropdown">
          <div className="input-group">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f form-control-tags">
              <span className="form-control-tag">
                <span className="form-control-tag-name">email</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </span>
              <input
                type="text"
                className="bd-0 flex-grow-1 mn-wd-250 rounded"
                placeholder="Type or click to select a tag"
              />
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="toggle dropdown to select a tag">
                <ChevronDown />
                <span className="sr-only">태그 선택 옵션 열기</span>
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            {!showTagInput && (
              <button type="button" className="dropdown-item" onClick={() => setShowTagInput(true)}>
                <Plus className="mg-r-5" />
                Add new Tag
              </button>
            )}
            {showTagInput && (
              <div className="d-flex align-items-center pd-l-20 pd-r-10">
                <input type="text" className="form-control" />
                <button type="button" className="btn btn-sm btn-icon">
                  <Check className="tx-success" />
                  <span className="sr-only">추가</span>
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-link link-03 btn-icon"
                  onClick={() => setShowTagInput(false)}
                >
                  <X />
                  <span className="sr-only">취소</span>
                </button>
              </div>
            )}
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              Problem
            </button>
            <button type="button" className="dropdown-item">
              Refund
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Subject<span className="tx-danger">*</span>
        </label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Content<span className="tx-danger">*</span>
        </label>
        <div className="d-flex align-items-center mg-b-10">
          <SelectHook
            className="wd-200-f mg-r-5"
            options={[{ value: 1, label: 'Select a template' }]}
          />
          <button type="button" className="btn btn-link">
            Preview
          </button>
        </div>
        <div>Editor area</div>
      </div>

      <NewCategory />
      <NewFolder />
    </>
  );
};

export default WriteKB;
