import React, { useState } from 'react';
import classnames from 'classnames';

import { Calendar, ChevronDown, X, Clock } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import { Select as SelectHook } from '@base/components/form';

const Write = () => {
  const [listType, setListType] = useState('static');

  const handleListTypeChange = (e: any) => {
    const { value } = e.target;
    setListType(value);
  };

  const [recurrence, setRecurrence] = useState('hourly');
  const handleRecurrenceChange = (e: any) => {
    const { value } = e.target;
    setRecurrence(value);
  };

  const [applyType, setApplyType] = useState('auto');
  const handleApplyTypeChange = (e: any) => {
    const { value } = e.target;
    setApplyType(value);
  };

  return (
    <>
      <div className="tx-right">
        <span className="tx-danger">*</span> is required.
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">
          Price List Type<span className="tx-danger">*</span>
        </label>
        <div className="custom-control custom-radio d-inline-block mg-r-10">
          <input
            type="radio"
            id="listTypeRadio1"
            name="listTypeRadio"
            className="custom-control-input"
            value="static"
            checked={listType === 'static'}
            onChange={(e) => handleListTypeChange(e)}
          />
          <label className="custom-control-label" htmlFor="listTypeRadio1">
            Static
          </label>
        </div>
        <div className="custom-control custom-radio d-inline-block">
          <input
            type="radio"
            id="listTypeRadio2"
            name="listTypeRadio"
            className="custom-control-input"
            value="timebased"
            checked={listType === 'timebased'}
            onChange={(e) => handleListTypeChange(e)}
          />
          <label className="custom-control-label" htmlFor="listTypeRadio2">
            Time Based
          </label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Price List Name<span className="tx-danger">*</span>
        </label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label className="form-item-title">Active</label>
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id="pricelist-active-01" />
          <label className="custom-control-label" htmlFor="pricelist-active-01"></label>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">
          Start Date<span className="tx-danger">*</span>
        </label>
        <div className="input-group input-date-group wd-300">
          <input type="text" className="form-control" />
          <div className="input-group-append">
            <span className="input-group-text">
              <Calendar />
            </span>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">
          End Date<span className="tx-danger">*</span>
        </label>
        <div className="d-flex flex-wrap align-items-center">
          <div className="input-group input-date-group wd-300 mg-r-10">
            <input type="text" className="form-control" />
            <div className="input-group-append">
              <span className="input-group-text">
                <Calendar />
              </span>
            </div>
          </div>
          <div className="custom-control custom-switch mg-t-5">
            <input type="checkbox" className="custom-control-input" id="no-end-date" />
            <label className="custom-control-label" htmlFor="no-end-date">
              No End Date
            </label>
          </div>
        </div>
      </div>

      {listType === 'timebased' && (
        <>
          <div className="form-group-title">
            Apply Schedule<span className="tx-danger">*</span>
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Recurrence</label>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="recurrenceRadio1"
                name="recurrenceRadio"
                className="custom-control-input"
                value="hourly"
                checked={recurrence === 'hourly'}
                onChange={(e) => handleRecurrenceChange(e)}
              />
              <label className="custom-control-label" htmlFor="recurrenceRadio1">
                Hourly
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="recurrenceRadio2"
                name="recurrenceRadio"
                className="custom-control-input"
                value="daily"
                checked={recurrence === 'daily'}
                onChange={(e) => handleRecurrenceChange(e)}
              />
              <label className="custom-control-label" htmlFor="recurrenceRadio2">
                Daily
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="recurrenceRadio3"
                name="recurrenceRadio"
                className="custom-control-input"
                value="weekly"
                checked={recurrence === 'weekly'}
                onChange={(e) => handleRecurrenceChange(e)}
              />
              <label className="custom-control-label" htmlFor="recurrenceRadio3">
                Weekly
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block mg-r-10">
              <input
                type="radio"
                id="recurrenceRadio4"
                name="recurrenceRadio"
                className="custom-control-input"
                value="monthly"
                checked={recurrence === 'monthly'}
                onChange={(e) => handleRecurrenceChange(e)}
              />
              <label className="custom-control-label" htmlFor="recurrenceRadio4">
                Monthly
              </label>
            </div>
            <div className="custom-control custom-radio d-inline-block">
              <input
                type="radio"
                id="recurrenceRadio5"
                name="recurrenceRadio"
                className="custom-control-input"
                value="yearly"
                checked={recurrence === 'yearly'}
                onChange={(e) => handleRecurrenceChange(e)}
              />
              <label className="custom-control-label" htmlFor="recurrenceRadio5">
                Yearly
              </label>
            </div>
          </div>

          {recurrence === 'hourly' && (
            <>
              <div className="form-group">
                <label className="form-item-title">Start Time</label>
                <div className="input-group input-date-group wd-300 mg-r-10">
                  <input type="text" className="form-control" />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Clock />
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">End Time</label>
                <div className="input-group input-date-group wd-300 mg-r-10">
                  <input type="text" className="form-control" />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <Clock />
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          {(recurrence === 'hourly' || recurrence === 'daily' || recurrence === 'weekly') && (
            <div className="form-group">
              <label className="form-item-title d-block">Days</label>
              <div className="d-flex align-items-center">
                <span className="mg-r-10">Every</span>
                {recurrence === 'weekly' && (
                  <div className="input-group wd-150 mg-r-10">
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      defaultValue="1"
                      min="0"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">week(s)</span>
                    </div>
                  </div>
                )}
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck1"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck1">
                    Mon
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck2"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck2">
                    Tue
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck3"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck3">
                    Wed
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck4"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck4">
                    Thu
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck5"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck5">
                    Fri
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck6"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck6">
                    Sat
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck7"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck7">
                    Sun
                  </label>
                </div>
              </div>
            </div>
          )}

          {recurrence === 'monthly' && (
            <div className="form-group">
              <label className="form-item-title d-block">Days</label>
              <div className="d-flex align-items-center">
                <span className="mg-r-10">Every</span>
                <div className="input-group wd-150 mg-r-10">
                  <input
                    type="number"
                    className="form-control form-control-lg"
                    defaultValue="1"
                    min="0"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">month(s)</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="recurrenceDayRadio1"
                    name="recurrenceDayRadio"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayRadio1">
                    the
                  </label>
                </div>
                <SelectHook
                  className="wd-150-f mg-x-10"
                  options={[
                    { value: 1, label: '1st' },
                    { value: 2, label: '2nd' },
                  ]}
                />
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck1"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck1">
                    Mon
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck2"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck2">
                    Tue
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck3"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck3">
                    Wed
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck4"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck4">
                    Thu
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck5"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck5">
                    Fri
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck6"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck6">
                    Sat
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck7"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck7">
                    Sun
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="recurrenceDayRadio2"
                    name="recurrenceDayRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayRadio2">
                    the
                  </label>
                </div>
                <SelectHook className="wd-150-f mg-x-10" options={[{ value: 18, label: '18th' }]} />
              </div>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="recurrenceDayRadio3"
                    name="recurrenceDayRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayRadio3">
                    the last day
                  </label>
                </div>
              </div>
            </div>
          )}

          {recurrence === 'yearly' && (
            <div className="form-group">
              <label className="form-item-title d-block">Days</label>
              <div className="d-flex align-items-center">
                <span className="mg-r-10">Every</span>
                <div className="input-group wd-150 mg-r-10">
                  <input
                    type="number"
                    className="form-control form-control-lg"
                    defaultValue="1"
                    min="0"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">year(s)</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="recurrenceYearRadio1"
                    name="recurrenceYearRadio"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="recurrenceYearRadio1">
                    the
                  </label>
                </div>
                <SelectHook
                  className="wd-150-f mg-x-10"
                  options={[{ value: 1, label: 'December' }]}
                />
                <SelectHook className="wd-150-f mg-x-10" options={[{ value: 1, label: '3rd' }]} />
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck1"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck1">
                    Mon
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck2"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck2">
                    Tue
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck3"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck3">
                    Wed
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck4"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck4">
                    Thu
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck5"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck5">
                    Fri
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck6"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck6">
                    Sat
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    id="recurrenceDayCheck7"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceDayCheck7">
                    Sun
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center mg-t-10">
                <div className="custom-control custom-radio d-inline-block">
                  <input
                    type="radio"
                    id="recurrenceYearRadio2"
                    name="recurrenceYearRadio"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="recurrenceYearRadio2">
                    the
                  </label>
                </div>
                <SelectHook
                  className="wd-150-f mg-x-10"
                  options={[{ value: 18, label: 'December' }]}
                />
                <SelectHook className="wd-150-f mg-x-10" options={[{ value: 18, label: '25th' }]} />
              </div>
            </div>
          )}
        </>
      )}
      <hr />
      <div className="form-group">
        <label className="form-item-title">Related Sales Channel</label>
        <div className="input-group">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="form-control-tag-name">Channel 1</span>
              <button
                type="button"
                className="btn pd-0 form-control-tag-remove"
                aria-label="delete"
              >
                <X />
                <span className="sr-only">삭제</span>
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250 rounded"
              placeholder="Type or click to select a channel"
            />
          </div>
          <div className="input-group-append">
            <button type="button" className="btn" aria-label="open modal to select a channel">
              <ChevronDown />
              <span className="sr-only">채널 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-item-title">Related Trade Agreement/Catalog</label>
        <div className="input-group">
          <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
            <div className="form-control-tag">
              <span className="form-control-tag-name">Agreement 1</span>
              <button type="button" className="btn pd-0 form-control-tag-remove" aria-label="삭제">
                <X />
                <span className="sr-only">삭제</span>
              </button>
            </div>
            <input
              type="text"
              className="bd-0 flex-grow-1 mn-wd-250 rounded"
              placeholder="Type or click to select a agreement/catalog"
            />
          </div>
          <div className="input-group-append">
            <button
              type="button"
              className="btn"
              aria-label="open modal to select a agreement/catalog"
            >
              <ChevronDown />
              <span className="sr-only">계약 및 카탈로그 선택 팝업 열기</span>
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="form-group-title">Price Calculation Rule</div>
      {listType === 'static' && (
        <div className="form-group">
          <label className="form-item-title d-block">Way to apply</label>
          <div className="custom-control custom-radio d-inline-block mg-r-10">
            <input
              type="radio"
              id="calculationRuleRadio1"
              name="calculationRuleRadio"
              className="custom-control-input"
              value="auto"
              checked={applyType === 'auto'}
              onChange={(e) => handleApplyTypeChange(e)}
            />
            <label className="custom-control-label" htmlFor="calculationRuleRadio1">
              Auto
            </label>
          </div>
          <div className="custom-control custom-radio d-inline-block mg-r-10">
            <input
              type="radio"
              id="calculationRuleRadio2"
              name="calculationRuleRadio"
              className="custom-control-input"
              value="manual"
              checked={applyType === 'manual'}
              onChange={(e) => handleApplyTypeChange(e)}
            />
            <label className="custom-control-label" htmlFor="calculationRuleRadio2">
              Manual
            </label>
          </div>
        </div>
      )}

      <div className="form-group">
        <label className="form-item-title">Rate</label>
        <div className="d-flex flex-wrap align-items-center">
          <div className="input-group wd-150 mg-r-5">
            <input type="number" className="form-control" />
            <div className="input-group-append">
              <span className="input-group-text lh-1">%</span>
            </div>
          </div>
          <SelectHook
            className="wd-100-f mg-r-5"
            options={[
              { value: 1, label: 'more' },
              { value: 2, label: 'less' },
            ]}
          />
          than the best price.
        </div>
      </div>

      {applyType === 'auto' && (
        <div className="form-group">
          <label className="form-item-title">
            {listType === 'timebased' ? 'Select' : 'Excluded'} Items
          </label>
          <div className="input-group">
            <div className="form-control form-control-lg d-flex flex-wrap pd-0-f bd-r-0 form-control-tags">
              <div className="form-control-tag">
                <span className="form-control-tag-name">Item 1</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
              {/* <input type="text" className="bd-0 flex-grow-1 mn-wd-250" placeholder="Type or click to select an item" /> */}
            </div>
            <div className="input-group-append">
              <button type="button" className="btn" aria-label="open modal to select an item">
                {Icon('product')}
                <span className="sr-only">아이템 선택 팝업 열기</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Write;
