import { ChevronDown, Plus, X } from 'react-feather';
import { DatePicker } from '@base/components/date-picker';

const UserPurchasing = () => {
  return (
    <div id="addUserPurchasing" className="modal fade right">
      <div className="modal-dialog wd-auto">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title h6">Purchasing</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Item Name</label>
              <div>AAA</div>
            </div>
            <div className="form-group">
              <label>Billing Plan</label>
              <div className="dropdown">
                <div className="input-group">
                  <div className="form-control form-control-lg bd-r-0">
                    <input
                      type="text"
                      className="wd-100p bd-0"
                      placeholder="Type or click to select a billing plan"
                    />
                  </div>
                  <span className="input-group-append">
                    <button type="button" className="btn bd-gray-300 tx-gray-300 bd-l-0">
                      <ChevronDown />
                    </button>
                  </span>
                </div>
                <div className="dropdown-menu wd-100p">
                  <a href="#newBillingPlanCenter" data-toggle="modal" className="dropdown-item">
                    <Plus className="mg-r-5" />
                    New Billing Plan
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="javascript:;" className="dropdown-item">
                    Plan AAA
                  </a>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Start on</label>
              <DatePicker
                selected={new Date()}
                onChange={() => {
                  console.log();
                }}
              />
            </div>
            <div className="form-group">
              <label>End on</label>
              <div>2021/07/27</div>
            </div>
            <div className="table-responsive bd rounded">
              <table className="table mg-b-0 bd-t-0">
                <thead>
                  <tr>
                    <th scope="col" className="wd-150">
                      User
                    </th>
                    <th scope="col">Term</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input type="number" className="form-control" />
                    </td>
                    <td>6 months 10 days</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-outline-primary">
              Save and Purchasing other item
            </button>
            <button type="button" className="btn btn-primary">
              {' '}
              {/* Move to Invoice of Bills & Payment  */}
              Make payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPurchasing;
