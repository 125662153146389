import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Trash,
  ChevronDown,
  Plus,
  ChevronsRight,
  ChevronsLeft,
  ArrowLeft,
  MoreHorizontal,
  Copy,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import ActivityTimeline from './timeline';
import DMWrite from './dm-write';

import Attachments from '../../../component/desktop/attachments';
import AssignedRep from '../../../component/desktop/assigned-rep';
import Userlist from '../../../component/desktop/userlist';
import Comment from '../../../component/desktop/comment';
import Chat from '../../../component/desktop/chat';
import ProductList from '../../../component/desktop/productlist';

const TABS = [
  {
    value: 1,
    label: 'Insctuction',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'Note',
  },
  {
    value: 4,
    label: 'TeamChannel',
  },
];
const COLOS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

const DmView = () => {
  const [tab, setTab] = useState(TABS[0]);

  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [hideAsideRight, setHideAsideRight] = useState(false);
  const [headerColor, setHeaderColor] = useState(COLOS[0]);

  return (
    <>
      <div className="view-wrap">
        <div className={`view-header bg-${headerColor.value}`}>
          <a
            href="/crm/demo-page/ui/activity/desktop/theme1/list"
            className="btn btn-link pd-l-0 pd-y-0 btn-back"
          >
            <ArrowLeft />
            <span className="sr-only">목록으로</span>
          </a>

          {/* move to another submenu */}
          <div className="dropdown bd-x">
            <button
              type="button"
              className="btn btn-link link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
              data-toggle="dropdown"
            >
              Activity
              <ChevronDown className="mg-l-5" />
            </button>
          </div>

          <h1 className="view-title">Direct Mail Subject</h1>

          <div className="button-group d-flex flex-shrink-0 mg-l-auto">
            <div className="process-buttons mg-r-15">
              <button type="button" className="btn btn-info">
                Done
              </button>
              <div className="dropdown">
                <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
                  More
                  <ChevronDown className="mg-l-5" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Copy />
                    </span>
                    Clone
                  </button>
                  <button type="button" className="dropdown-item">
                    <span className="dropdown-item-icon">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mg-l-5 rounded-30"
              data-toggle="modal"
              data-target="#newDMModal"
            >
              <Plus size="14" />
              New
            </button>
          </div>

          <div className="dropdown mg-l-10 pd-l-10 bd-l">
            <button
              type="button"
              data-han-tooltip="Change the background color"
              className={`btn btn-change-header-bg shadow-sm han-tooltip--left bg-${headerColor.value}`}
              data-toggle="dropdown"
            >
              <span className="sr-only">배경색 변경</span>
            </button>
            <ul className="dropdown-menu header-bg-color-list">
              {COLOS.map((item, index) => (
                <li key={index}>
                  <button
                    className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                    onClick={(e) => {
                      setHeaderColor(item);
                    }}
                  >
                    &nbsp;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row no-gutters flex-nowrap flex-grow-1"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideLeft })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse left aside */}
              {!hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-hide-aside-left"
                  onClick={() => setHideAsideLeft(true)}
                >
                  {Icon('btn_pull_left')}
                  <span className="sr-only">좌측 사이드 패널 접기</span>
                </button>
              )}

              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <span className="view-aside-section-header-title">Summary</span>
                </div>
                <div className="view-aside-section-body">
                  <div className="form-group">
                    <label className="form-item-title">Due Date</label>
                    <div>2022-02-01 11:00</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Expected Duration</label>
                    <div>7 days</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title d-block">Priority</label>
                    <span className="d-inline-block pd-x-15 pd-y-3 bg-info tx-white rounded-30">
                      Normal
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Purpose</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <div>&nbsp;</div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Regarding</label>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames('col-6 view-content', {
              'col-9': hideAsideLeft || hideAsideRight,
              'col-12': hideAsideLeft && hideAsideRight,
            })}
          >
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* tabs */}
              <div className="view-tabs flex-grow-1">
                <ul className="nav nav-tabs flex-grow-1 bd-0">
                  {TABS.map(
                    (item) =>
                      item.value < 3 && (
                        <li
                          className={classnames('nav-item', {
                            active: item.value == tab.value,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            {Icon('move')}
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <button
                            type="button"
                            className="btn nav-link"
                            onClick={(e) => {
                              setTab(item);
                            }}
                          >
                            {item.label}
                          </button>
                        </li>
                      ),
                  )}
                  <li
                    className={classnames('nav-item dropdown pd-0-f', {
                      active: tab.value > 2,
                    })}
                  >
                    <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                      <Plus className="mg-r-5" />
                      {tab.value > 2 ? tab.label : 'More'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right bg-white">
                      {TABS.map(
                        (item) =>
                          item.value > 2 && (
                            <div className="dropdown-item">
                              <button
                                type="button"
                                className="btn btn-xs btn-move-tab"
                                aria-label="Drag-and-drop to reorder"
                              >
                                {Icon('move')}
                                <span className="sr-only">드래그앤드롭으로 이동</span>
                              </button>
                              <button
                                type="button"
                                className={classnames('btn', {
                                  active: item.value == tab.value,
                                })}
                                onClick={(e) => {
                                  setTab(item);
                                }}
                              >
                                {item.label}
                              </button>
                            </div>
                          ),
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="view-body">
              {/* show left aside */}
              {hideAsideLeft && (
                <button
                  type="button"
                  className="btn btn-show-aside-left"
                  onClick={() => setHideAsideLeft(false)}
                >
                  {Icon('btn_pull_right')}
                  <span className="sr-only">좌측 사이드 패널 열기</span>
                </button>
              )}

              {/* show left aside */}
              {hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-show-aside-right"
                  onClick={() => setHideAsideRight(false)}
                >
                  {Icon('btn_pull_left')}
                  <span className="sr-only">우측 사이드 패널 열기</span>
                </button>
              )}

              {tab.value == 1 && (
                <div className="pd-20">
                  <ul className="task-stage mg-b-15-f">
                    <li className="task-stage-item done">Todo</li>
                    <li className="task-stage-item current">Doing</li>
                    <li className="task-stage-item">Done</li>
                  </ul>

                  <div className="card card-body overflow-x-auto mg-b-20">
                    <ul className="list-unstyled mg-0 pd-0">
                      <li className="d-flex align-items-center mg-b-10">
                        <span className="mg-r-10 tx-color-03">Direction:</span>Outgoing
                      </li>
                      <li className="d-flex align-items-center mg-b-10">
                        <span className="mg-r-10 tx-color-03">Type:</span>Postcard
                      </li>
                      <li className="d-flex align-items-center mg-b-10">
                        <span className="mg-r-10 tx-color-03">Marketing Collateral:</span>Envelope
                      </li>
                      <li className="d-flex align-items-center mg-b-10">
                        <span className="mg-r-10 tx-color-03">From:</span>
                        <div className="avatar avatar-xs mg-r-10">
                          <img
                            src="https://n.hanbiro.com/ngw/org/user/photo/no/1043/cn/1/"
                            className="rounded-circle"
                            alt=""
                          />
                        </div>
                        MSM
                      </li>
                      <li className="d-flex align-items-center mg-b-10">
                        <span className="mg-r-10 tx-color-03">To:</span>
                        Allan Rey Palban &lt;Park Way Cupertino, CA 95014&gt;
                      </li>
                    </ul>
                    <div className="mg-t-15 pd-10 bd rounded">Preview</div>
                  </div>
                </div>
              )}
              {tab.value == 2 && <ActivityTimeline />}
              {tab.value == 3 && <Comment />}
              {tab.value == 4 && <Chat />}
            </div>
          </div>

          <div
            className={classnames('col-3 view-aside', { 'hidden-aside': hideAsideRight })}
            style={{ transformOrigin: 'left' }}
          >
            <div className="view-aside-inner">
              {/* collapse right aside */}
              {!hideAsideRight && (
                <button
                  type="button"
                  className="btn btn-hide-aside-right"
                  onClick={() => setHideAsideRight(true)}
                >
                  {Icon('btn_pull_right')}
                  <span className="sr-only">우측 사이드 패널 접기</span>
                </button>
              )}

              {/* Customer */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Customer</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#activityCustomer"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="activityCustomer" className="view-aside-section-body collapse show">
                  <Userlist />
                </div>
              </div>

              {/* Assigned Rep. */}
              <AssignedRep />

              {/* Attachment */}
              <Attachments />

              {/* Product List */}
              <div className="view-aside-section">
                <div className="view-aside-section-header">
                  <button type="button" className="view-aside-section-move mg-r-5">
                    {Icon('move')}
                    <span className="sr-only">드래그앤드롭으로 이동</span>
                  </button>
                  <span className="view-aside-section-header-title">Product</span>
                  <button
                    type="button"
                    className="btn btn-link link-03 mg-l-auto pd-0"
                    data-toggle="collapse"
                    data-target="#customerProduct"
                  >
                    <ChevronDown />
                  </button>
                </div>
                <div id="customerProduct" className="view-aside-section-body collapse show">
                  <ProductList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DMWrite />
    </>
  );
};

export default DmView;
