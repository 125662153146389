import { Calendar, ChevronDown, ChevronLeft, ChevronRight, Search } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const StockMovement = () => {
  return (
    <div className="pd-20">
      <div className="d-flex justify-content-end mg-b-10">
        <div className="dropdown list-filter-item">
          <button type="button" className="btn btn-white mg-r-10" data-toggle="dropdown">
            All
            <ChevronDown />
          </button>
          <div className="dropdown-menu wd-300">
            <button className="dropdown-item">All</button>
            <div className="dropdown-divider"></div>
            <button type="button" className="dropdown-item">
              This Year
            </button>
            <button type="button" className="dropdown-item">
              This Quarter
            </button>
            <button type="button" className="dropdown-item active">
              This Month
            </button>
            <button type="button" className="dropdown-item">
              This Week
            </button>
            <button type="button" className="dropdown-item">
              Today
            </button>
            <button
              type="button"
              className="dropdown-item btn d-flex align-items-center"
              data-toggle="collapse"
              data-target="#dateBy-custom"
            >
              <span className="mg-r-5">Custom</span>
              <ChevronDown className="mg-l-auto" />
            </button>
            <div id="dateBy-custom" className="collapse show input-group pd-x-25 pd-y-5">
              <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
              <div className="input-group-append">
                <span className="input-group-text">
                  <Calendar />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Adjustment (50)</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto collapsed"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#itemAdjustment"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="itemAdjustment" className="card collapse">
          <div className="table-responsive rounded">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    ID
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Item
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Adjusted on
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Reason
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Qty Adjusted
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Adjusted by
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" className="text-nowrap" target="_blank" title="새창으로 열림">
                      AD-000001
                    </a>
                  </td>
                  <td>Item 1</td>
                  <td>2020/09/22</td>
                  <td>Inventory revaluation</td>
                  <td>-200</td>
                  <td>
                    <div className="media align-items-center">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">MSR</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Transfer Order (50)</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto collapsed"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#itemTransferOrder"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="itemTransferOrder" className="card collapse">
          <div className="table-responsive rounded">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    ID
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Item
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Transfered Type
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Scheduled on
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Reason
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" className="text-nowrap" target="_blank" title="새창으로 열림">
                      TO-000001
                    </a>
                  </td>
                  <td>Item 1</td>
                  <td>Internal</td>
                  <td>2020/09/22</td>
                  <td>Inventory revaluation</td>
                  <td>Picked</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>

      <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Receipt (50)</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto collapsed"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#itemReceipt"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="itemReceipt" className="card collapse">
          <div className="table-responsive rounded">
            <table className="table dataTable table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f sorting">
                    ID
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Reference ID
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Destination Location
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Scheduled on
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Status
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Received on
                  </th>
                  <th scope="col" className="bd-t-0-f sorting">
                    Received by
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="" className="text-nowrap" target="_blank" title="새창으로 열림">
                      REC-000001
                    </a>
                  </td>
                  <td>PO-000001</td>
                  <td>Seoul 2</td>
                  <td>2020/09/22</td>
                  <td>New</td>
                  <td>2020/09/22</td>
                  <td>
                    <div className="media">
                      <div className="avatar avatar-xs">
                        <img
                          src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                          className="rounded-circle"
                          alt="MSR"
                        />
                      </div>
                      <div className="media-body mg-l-10">MSR</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer pd-y-6-f pagination">
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input type="number" className="paging-input" defaultValue="1" />/ 1
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockMovement;
