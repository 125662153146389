import Icon from '@base/assets/icons/svg-icons';
import { Button, Input } from '@base/components/form';
import { useState } from 'react';

const SalesCommission = () => {
  const [showEdit, setShowEdit] = useState<boolean>();

  return (
    <>
      <div className="pd-20">
        <div className="view-content-section">
          <div className="view-content-section-header">
            <button type="button" className="view-aside-section-move mg-r-5">
              {Icon('move')}
              <span className="sr-only">드래그앤드롭으로 이동</span>
            </button>
            <span className="view-aside-section-header-title">Draft</span>
            <Button
              color="link"
              icon="Edit2"
              iconClass="mg-r-5"
              className="mg-l-auto"
              name="Edit"
              onClick={() => setShowEdit(true)}
            />
          </div>
          <div className="table-responsive bg-white bd roundedd">
            <table className="table table-bordered mg-0 bd-0">
              <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Sub Total</th>
                  <th scope="col">Commission</th>
                  <th scope="col">Share</th>
                  {showEdit && (
                    <th scope="col" className="wd-30">
                      Delete
                    </th>
                  )}
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th scope="row" colSpan={3} className="tx-right bd-b-0-f">
                    Total
                  </th>
                  <td>＄700</td>
                  <td colSpan={showEdit ? 2 : 0}>&nbsp;</td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <Button
                      color="link"
                      className="pd-0"
                      name="Sales Owner Only"
                      data-toggle="modal"
                      data-target="#commissionShare"
                    />
                  </td>
                  {showEdit && (
                    <td>
                      <Button color="link" icon="X" iconClass="tx-danger" />
                    </td>
                  )}
                </tr>
                {showEdit && (
                  <tr>
                    <td colSpan={6}>
                      <Input
                        rightIcon={Icon('product')}
                        placeholder="Type or click to select an item"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {showEdit && (
            <div className="mg-t-10 text-right">
              <Button
                color="light"
                icon="X"
                name="Cancel"
                onClick={() => setShowEdit(false)}
                className="mg-r-5"
              />
              <Button color="success" icon="Check" name="Save" />
            </div>
          )}
        </div>

        <div className="view-content-section">
          <div className="view-content-section-header">
            <button type="button" className="view-aside-section-move mg-r-5">
              {Icon('move')}
              <span className="sr-only">드래그앤드롭으로 이동</span>
            </button>
            <span className="view-aside-section-header-title">Commission Expense</span>
          </div>
          <div className="table-responsive bg-white bd roundedd">
            <table className="table table-bordered mg-0 bd-0">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Expense Payable</th>
                  <th scope="col">%</th>
                  <th scope="col">Payment</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th scope="row" colSpan={2} className="tx-right bd-b-0-f">
                    Total
                  </th>
                  <td>＄700</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mg-t-10 text-right">
            <Button color="primary" name="Confirm" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesCommission;
