import { Button } from '@base/components/form';
import ItemAutoComplete from '@product/item/containers/item-auto-complete';
import { useState } from 'react';
import CommissionRate from '../../component/commission-rate';

const PlanPerItem = () => {
  const [showAdd, setShowAdd] = useState(false);
  return (
    <div className="form-group">
      <label className="form-item-title">Plan Per Item</label>
      <ItemAutoComplete className="wd-80p mg-b-10" />
      <CommissionRate />
      {showAdd && (
        <>
          <hr />
          <div className="d-flex align-items-center mg-b-10">
            <ItemAutoComplete className="wd-80p" />
            <Button
              color="link"
              icon="Minus"
              className="tx-danger"
              name="Delete"
              onClick={() => setShowAdd(false)}
            />
          </div>
          <CommissionRate />
        </>
      )}
      <Button
        color="link"
        icon="Plus"
        name="Add"
        className="pd-x-0"
        onClick={() => setShowAdd(true)}
      />
    </div>
  );
};
export default PlanPerItem;
