import { Button } from '@base/components/form';
import { Calendar } from 'react-feather';

const OpportunityPropose = () => {
  return (
    <div className="pd-20">
      {/* Proposal */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button
            color=""
            className="view-aside-section-move mg-r-5"
            icon="move"
            iconType="custom"
            data-han-tooltip="Move"
          />
          <span className="view-aside-section-header-title">Proposal</span>
          <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#proposal"
          />
        </div>
        <div id="proposal" className="card collapse show">
          <div className="pd-10 tx-right">
            <Button
              color="outline-primary"
              size="sm"
              className="mg-r-10"
              name="View&amp;Edit Email"
            />
            <Button color="outline-primary" size="sm" name="View&amp;Edit Quote" />
          </div>
          <div className="table-responsive bd-t">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="colgroup" colSpan={3}>
                    <div className="d-flex align-items-center">
                      Quote
                      <Button
                        color="link"
                        size="sm"
                        icon="Plus"
                        className="mg-l-auto pd-y-0"
                        data-han-tooltip="Add"
                      />
                    </div>
                  </th>
                  <th scope="colgroup" colSpan={3}>
                    <div className="d-flex align-items-center">
                      Document Proposed
                      <Button
                        color="link"
                        size="sm"
                        icon="Plus"
                        className="mg-l-auto pd-y-0"
                        data-han-tooltip="Add"
                      />
                    </div>
                  </th>
                </tr>
                <tr>
                  <th scope="col">Quote Name</th>
                  <th scope="col">Created by</th>
                  <th scope="col">Created on</th>
                  <th scope="col">Document Name</th>
                  <th scope="col">Created by</th>
                  <th scope="col">Created on</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer text-right">
            <Button color="primary" name="Submit for Approval" />
          </div>
        </div>
      </div>

      {/* Proposal */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <Button
            color=""
            className="view-aside-section-move mg-r-5"
            icon="move"
            iconType="custom"
            data-han-tooltip="Move"
          />
          <span className="view-aside-section-header-title">Feedback</span>
          <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#feedback"
          />
        </div>
        <div id="feedback" className="card collapse show">
          <div className="pd-x-15 pd-y-10 tx-right tx-color-03">
            <Calendar size={14} className="mg-r-5" />
            2022-05-27
          </div>
          &nbsp;
          <div className="card-footer tx-right">
            <Button color="primary" name="Won" className="mg-r-5" />
            <Button color="outline-primary" name="Lost" className="mg-r-5" />
            <Button color="outline-primary" name="Negotiation" className="mg-r-5" />
            <Button color="outline-primary" name="Review" className="mg-r-5" />
            <Button color="outline-primary" name="Reply" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default OpportunityPropose;
