import { Button, Checkbox } from '@base/components/form';
import NewSalesTeam from './modal/newSalesTeam';
const SalesTeam = () => {
  return (
    <>
      <div className="page-container-wrap">
        <div className="page-container-content">
          <div className="page-container-content-header">
            <h1 className="mg-b-0 tx-16 tx-uppercase tx-normal mg-l-10 mg-r-10">Sales Team</h1>
            <Button
              color="primary"
              className="mg-l-auto"
              icon="Plus"
              iconClass="mg-r-5"
              name="Add"
              data-toggle="modal"
              data-target="#newSalesTeam"
            />
          </div>
          <div className="scroll-box pd-20">
            <div className="table-responsive bg-white bd rounded">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                <thead>
                  <tr>
                    <th scope="col" className="wd-30">
                      <Checkbox />
                    </th>
                    <th scope="col" className="sorting_asc">
                      Team Name
                    </th>
                    <th scope="col" className="sorting">
                      Leader
                    </th>
                    <th scope="col" className="sorting">
                      Email Alias
                    </th>
                    <th scope="col" className="wd-80">
                      Product
                    </th>
                    <th scope="col" className="wd-80">
                      Process
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Checkbox />
                    </td>
                    <td className="sorting-cell">
                      <Button
                        color="link"
                        name="Team A"
                        className="pd-0"
                        data-toggle="modal"
                        data-target="#newSalesTeam"
                      />
                    </td>
                    <td>
                      <div className="media align-items-center">
                        <div className="avatar avatar-xs">
                          <img
                            src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                            className="rounded-circle"
                            alt="MSR"
                          />
                        </div>
                        <div className="media-body mg-l-10">MSR</div>
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NewSalesTeam />
    </>
  );
};

export default SalesTeam;
