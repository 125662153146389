import { Globe, X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import { Button } from '@base/components/form';

const CompetitorList = () => {
  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <button type="button" className="view-aside-section-move mg-r-5">
          {Icon('move')}
          <span className="sr-only">드래그앤드롭으로 이동</span>
        </button>
        <span className="view-aside-section-header-title">Competitor</span>
        <Button
          color="link"
          icon="ChevronDown"
          className="link-03 mg-l-auto btn-icon"
          data-toggle="collapse"
          data-target="#competitor"
        />
      </div>
      <div id="competitor" className="view-aside-section-body collapse show">
        <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-3">
          <div className="media align-items-center flex-grow-1">AAA</div>
          <div className="flex-shrink-0">
            <a
              href="https://hanbiro.com"
              target="_blank"
              className="btn btn-link btn-icon mg-l-auto han-tooltip--top"
              data-han-tooltip="Website"
            >
              <Globe className="tx-info" />
              <span className="sr-only">Website</span>
            </a>
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Delete"
            >
              <X className="tx-danger" />
              <span className="sr-only">삭제</span>
            </button>
          </div>
        </div>
        <Button
          color="link"
          size="sm"
          icon="Plus"
          iconClass="mg-r-5"
          name="Add new competitor"
          data-toggle="modal"
          data-target="#newCompetitorModal"
        />
      </div>
    </div>
  );
};

export default CompetitorList;
