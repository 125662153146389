import { useState } from 'react';
import classnames from 'classnames';

import { ChevronUp } from 'react-feather';
import { Button, Checkbox, Input, Select, Switch } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import { UserAutoComplete } from '@base/containers';
const TABS = [
  {
    value: 1,
    label: 'Summary',
  },
  {
    value: 2,
    label: 'Configuration',
  },
  {
    value: 3,
    label: 'Build a Team',
  },
  {
    value: 4,
    label: 'Create Ideal Customer Profile',
  },
];

const NewABM = () => {
  const [tab, setTab] = useState(TABS[0]);

  const OBJECTIVES = [
    { value: 1, label: 'Land and expand' },
    { value: 2, label: 'Wake the deal' },
    { value: 3, label: 'Targeting best Accounts that fit your ICP' },
    { value: 4, label: 'Upsell, cross-sell or renewal' },
    { value: 5, label: 'Account Lead Nurturing' },
  ];
  const [selectedObjective, setSelectedObjective] = useState(OBJECTIVES[0]);
  //change
  const handleObjectiveChange = (newOption: any) => {
    setSelectedObjective(newOption);
  };

  return (
    <>
      <div id="newABMModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">New Account Based Marketing</h1>
              <Button close data-dismiss="modal" />
            </div>
            <div className="modal-body d-flex flex-column overflow-hidden pd-0">
              {/* steps */}
              <ul className="steps steps-justified steps-tab">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('step-item', {
                      complete: item.value < tab.value,
                      active: item.value == tab.value,
                    })}
                  >
                    <button
                      type="button"
                      className="btn step-link rounded-0"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      <span className="step-number">{item.value}</span>
                      <span className="step-title">{item.label}</span>
                    </button>
                  </li>
                ))}
              </ul>
              {tab.value == 1 && (
                <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px' }}>
                  <div className="form-group">
                    <label className="form-item-title">ABM Name</label>
                    <Input type="text" />
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Objective</label>
                      <Select
                        options={OBJECTIVES}
                        defaultValue={selectedObjective}
                        onChange={handleObjectiveChange}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-item-title d-block">Account Collection Methods</label>
                      {(selectedObjective.value === 1 || selectedObjective.value === 3) && (
                        <>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="methodRadio1"
                              name="methodRadio"
                              className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="methodRadio1">
                              Manual Task
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="methodRadio2"
                              name="methodRadio"
                              className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="methodRadio2">
                              Bulk Data
                            </label>
                          </div>
                        </>
                      )}
                      {selectedObjective.value !== 5 && (
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="methodRadio3"
                            name="methodRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="methodRadio3">
                            Customer in CRM
                          </label>
                        </div>
                      )}
                      {selectedObjective.value !== 4 && selectedObjective.value !== 5 && (
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="methodRadio4"
                            name="methodRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="methodRadio4">
                            Lead in CRM
                          </label>
                        </div>
                      )}
                      {selectedObjective.value !== 2 && selectedObjective.value !== 4 && (
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="methodRadio5"
                            name="methodRadio"
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="methodRadio5">
                            Lead Capture in CRM
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Product</label>
                    <ProductAutoComplete />
                  </div>
                  <div className="form-group-title">Campaign Date</div>
                  <div className="row">
                    <div className="form-group col-lg-4">
                      <label className="form-item-title">Start Date</label>
                      <DatePicker
                        selected={new Date()}
                        onChange={() => {
                          console.log();
                        }}
                      />
                    </div>
                    <div className="form-group col-lg-4">
                      <label className="form-item-title">End Date</label>
                      <DatePicker
                        selected={new Date()}
                        onChange={() => {
                          console.log();
                        }}
                      />
                    </div>
                    <div className="form-group col-lg-4">
                      <label className="form-item-title">Time Zone</label>
                      <Select />
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Campaign Process</label>
                      <Select />
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-item-title">Target Account Process</label>
                      <Select />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <Input type="text" />
                  </div>
                </div>
              )}

              {tab.value == 2 && (
                <div className="overflow-auto pd-15" style={{ height: 'calc(100% - 50px)' }}>
                  <div className="form-group">
                    <label className="form-item-title">
                      Expenses to include in Marketing Investment
                    </label>
                    <div className="d-flex align-items-center">
                      <Input type="text" className="mg-r-10" placeholder="Expense Name" />
                      <Input type="currency" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Return on Marketing Investment</label>
                    <Select
                      width={300}
                      options={[
                        { value: 1, label: 'Gross Profit' },
                        { value: 2, label: 'Customer Lifetime Value' },
                      ]}
                      defaultValue={{ value: 1, label: 'Gross Profit' }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Key Performance Indicator</label>
                    <div className="bd rounded-top">
                      <div className="d-flex align-items-center pd-10">
                        Click through rate
                        <input type="number" className="form-control wd-100 mg-x-10" />%
                        <Button
                          color="link"
                          icon="X"
                          iconClass="tx-danger"
                          className="bbtn-icon mg-l-auto pd-y-0"
                        />
                      </div>
                    </div>
                    <div className="input-group bd bd-t-0 rounded-bottom">
                      <Input
                        type="text"
                        className="form-control-lg bd-0"
                        placeholder="Type or click to select a indicator"
                      />
                      <div className="input-group-append">
                        <Button color="" icon="ChevronDown" className="bd-0" />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {tab.value == 3 && (
                <div className="pd-15 overflow-auto">
                  <div className="form-group">
                    <label className="form-item-title">Assemble ABM Team</label>
                    <div className="table-responsive bd rounded-top">
                      <table className="table table-bordered mg-0 bd-0 basic-table">
                        <thead>
                          <tr>
                            <th scope="col" rowSpan={2}>
                              Role
                            </th>
                            <th scope="col" rowSpan={2}>
                              Team Member
                            </th>
                            <th scope="colgroup" colSpan={3}>
                              Task
                            </th>
                            <th scope="col" rowSpan={2} className="wd-70">
                              Delete
                            </th>
                          </tr>
                          <tr>
                            <th scope="col" className="wd-10p">
                              Collect and Profile
                            </th>
                            <th scope="col" className="wd-10p">
                              Produce
                            </th>
                            <th scope="col" className="wd-10p">
                              Sales
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="dropdown">
                                <div className="input-group mn-wd-200" data-toggle="dropdown">
                                  <Input type="text" value="Marketing Rep." />
                                  <div className="input-group-append">
                                    <Button
                                      color=""
                                      className="input-group-btn"
                                      icon="ChevronDown"
                                    />
                                  </div>
                                </div>
                                <div className="dropdown-menu wd-100p">
                                  <Button
                                    color=""
                                    className="dropdown-item"
                                    icon="Plus"
                                    name="Add a Role"
                                  />
                                  <div className="dropdown-divider"></div>
                                  <Button
                                    color=""
                                    className="dropdown-item"
                                    name="Marketing Rep."
                                  />
                                  <Button color="" className="dropdown-item" name="Sales Rep." />
                                  <Button
                                    color=""
                                    className="dropdown-item"
                                    name="Content Producer Rep."
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <UserAutoComplete showAvatar />
                            </td>
                            <td className="tx-center">
                              <Checkbox />
                            </td>
                            <td className="tx-center">
                              <Checkbox />
                            </td>
                            <td className="tx-center">
                              <Checkbox />
                            </td>
                            <td>
                              <Button
                                color="link"
                                icon="X"
                                iconClass="tx-danger"
                                className="btn-icon pd-y-0"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="pd-10 bd bd-t-0 rounded-top">
                      <div className="input-group" data-toggle="dropdown">
                        <Input type="text" placeholder="Type or click to add a role" />
                        <div className="input-group-append">
                          <Button color="" className="input-group-btn" icon="ChevronDown" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Team Email</label>
                    <div className="d-flex align-items-center">
                      <Input type="emal" />
                      <span className="mg-x-10">@aaa.vora.net</span>
                      <Button color="primary" name="Create" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">
                      Task assignment to collect and Profile Target Accounts
                    </label>
                    <div className="table-responsive bd rounded-top">
                      <table className="table table-bordered mg-0 bd-0 basic-table">
                        <thead>
                          <tr>
                            <th scope="col">Team Member</th>
                            <th scope="col">No. allocated</th>
                            <th scope="col">Due</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>
                              <Input type="number" />
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                Due until <Input className="mg-x-10 wd-100" />
                                day(s) after listing My Work ToDo
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">
                      Task assignment to collect Produce Personalized Content
                    </label>
                    <div className="d-flex align-items-center pd-10 bd rounded">
                      Due until <Input className="mg-x-10 wd-100" />
                      day(s) after listing My Work ToDo
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-item-title">Assign to</label>
                    <UserAutoComplete showAvatar />
                  </div>
                </div>
              )}

              {tab.value == 4 && (
                <div className="table-responsive">
                  <table className="table table-bordered bd-0 mg-0 basic-table">
                    <thead>
                      <tr>
                        <th scope="col">ABM Tier</th>
                        <th scope="col">ABM Type</th>
                        <th scope="col">Ideal Customer Profile</th>
                        <th scope="col">Follow up</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Switch title="Tier 1" />
                        </td>
                        <td>One-to-one</td>
                        <td>
                          <div className="dropdown">
                            <div className="input-group" data-toggle="dropdown">
                              <Input type="text" placeholder="Type or click to select an ICP" />
                              <div className="input-group-append">
                                <Button
                                  color=""
                                  className="input-group-btn"
                                  icon="m_customer"
                                  iconType="main"
                                />
                              </div>
                            </div>
                            <div className="dropdown-menu wd-100p">
                              <Button
                                color=""
                                className="dropdown-item"
                                icon="Plus"
                                name="Add new ICP"
                              />
                              <div className="dropdown-divider"></div>
                              <Button color="" className="dropdown-item" name="Startup Sam" />
                            </div>
                          </div>
                        </td>
                        <td>Manual</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-lg btn-light" data-dismiss="modal">
                Close
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and {tab.value == 4 ? 'Finish' : 'Next'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewABM;
