import React, { useState } from 'react';
import classnames from 'classnames';

import { ArrowLeft, ArrowRight, ChevronUp, X } from 'react-feather';

//import '@customer/assets/scss/customer.scss';

import Summary from '../segment/components/summary';
import Criteria from '../segment/components/criteria';
import Members from '../segment/components/members';

const TABS = [
  {
    value: 1,
    label: 'Summary',
  },
  {
    value: 2,
    label: 'Criteria',
  },
  {
    value: 3,
    label: 'Preview Members',
  },
];

const WriteSeg = () => {
  const [tab, setTab] = useState(TABS[0]);

  return (
    <div>
      <div id="newSegment" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header flex-column pd-0">
              <div className="d-flex wd-100p pd-15">
                <h1 className="modal-title">Create Segment</h1>
                <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                  <X />
                </button>
              </div>
              <ul className="steps steps-tab steps-justified wd-100p bd-t">
                {TABS.map((item, index) => (
                  <li
                    key={index}
                    className={classnames('step-item', {
                      active: item.value == tab.value,
                      complete: item.value < tab.value,
                    })}
                  >
                    <div
                      className="step-link"
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      <span className="step-number">{item.value}</span>
                      <span className="step-title tx-transform-none">{item.label}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-body">
              {tab.value == 1 && <Summary />}
              {tab.value == 2 && <Criteria />}
              {tab.value == 3 && <Members />}
            </div>
            <div className="modal-footer">
              {tab.value > 1 && (
                <button type="button" className="btn btn-link link-03">
                  <ArrowLeft className="mg-r-5" />
                  Previous
                </button>
              )}
              {tab.value < 3 && (
                <button type="button" className="btn btn-primary mg-l-auto">
                  Next
                  <ArrowRight className="mg-l-5" />
                </button>
              )}
              {tab.value == 3 && (
                <div className="btn-group dropup mg-l-auto">
                  <button type="button" className="btn btn-success">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-success dropdown-toggle-split"
                    data-toggle="dropdown"
                  >
                    <ChevronUp />
                    <span className="sr-only">옵션 열기</span>
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button type="button" className="dropdown-item">
                      Save and Create New
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteSeg;
