import { Settings, X } from 'react-feather';
import { Select as SelectHook } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { DatePicker } from '@base/components/date-picker';

const RegisterPayment = () => {
  return (
    <div
      id="registerPaymentModal"
      className="modal fade right"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="modal-title">Register Payment</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">Payment ID</label>
              <div className="input-group wd-250 wd-lg-350 wd-xl-450 mx-wd-100p">
                <input type="text" className="form-control" />
                <div className="input-group-append">
                  <button type="button" className="btn">
                    <Settings />
                    <span className="sr-only">ID 생성 설정 열기</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Amount Received</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text lh-1">￦</span>
                </div>
                <input type="number" className="form-control" />
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Payment Mode</label>
              <SelectHook />
            </div>
            <div className="form-group">
              <label className="form-item-title">Payment Date</label>
              <DatePicker
                selected={new Date()}
                onChange={() => {
                  console.log();
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <textarea rows={3} cols={10} className="form-control"></textarea>
            </div>
            <div className="form-group">
              <label className="form-item-title">Send Refund Complete Email</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="reminderSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="reminderSwitch1"></label>
              </div>
              <ul className="list-unstyled">
                <li className="mg-t-10">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                      defaultChecked
                    />
                    <label className="custom-control-label" htmlFor="customCheck1">
                      sales@mail.com
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-light mg-r-5" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPayment;
