import { useState } from 'react';

import { ArrowDown, ArrowLeft, ArrowUp } from 'react-feather';
import { Button, FormIcon, Switch } from '@base/components/form';
import RightAside from './write/rightAside';
import Icon from '@base/assets/icons/svg-icons';

import './report.scss';
import ReactApexChart from 'react-apexcharts';

const TABS = [
  {
    value: 1,
    label: 'Page 1',
  },
  {
    value: 2,
    label: 'Page 2',
  },
];

const ViewCTA = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <div className="view-wrap">
      <div className="view-header bg-white">
        <a href="/repot/desktop/theme1/list" className="btn btn-link pd-l-0 pd-y-0 btn-back">
          <ArrowLeft />
          <span className="sr-only">목록으로</span>
        </a>

        {/* move to another submenu */}
        <div className="dropdown bd-x">
          <Button
            color="link link-02"
            className="pd-x-15 pd-y-0 tx-16 tx-bold"
            icon="ChevronDown"
            isIconsEnd
            name="Report"
            data-toggle="dropdown"
          />
        </div>

        <h1 className="view-title mg-l-10 lh-1">Report Name 1</h1>

        <div className="button-group d-flex flex-shrink-0 mg-l-auto">
          <div className="process-buttons mg-r-15">
            <div className="dropdown">
              <Button
                color="info"
                icon="ChevronDown"
                name="More"
                data-toggle="dropdown"
                isIconsEnd
              />
              <div className="dropdown-menu dropdown-menu-right">
                <Button
                  color=""
                  className="dropdown-item"
                  icon="Copy"
                  iconClass="dropdown-item-icon"
                  name="Clone"
                />
                <Button
                  color=""
                  className="dropdown-item"
                  icon="Trash"
                  iconClass="dropdown-item-icon"
                  name="Delete"
                />
              </div>
            </div>
          </div>
          <Button color="primary" icon="Plus" className="mg-l-5 rounded-30" name="New" />
        </div>
      </div>

      <div className="flex-grow-1 scroll-box pd-20">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Owner</label>
              <div className="media align-items-center">
                <div className="avatar avatar-xs">
                  <img
                    src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                    className="rounded-circle"
                    alt="MSR"
                  />
                </div>
                <div className="media-body mg-l-10">
                  <span className="user-name">MSR</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Active</label>
              <Switch />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Recipients</label>
          <div className="d-flex flex-wrap align-items-center">
            <div className="media align-items-center pd-x-10 pd-y-5 bg-white bd rounded-30 mg-r-10">
              <div className="avatar avatar-xs">
                <img
                  src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                  className="rounded-circle"
                  alt="MSR"
                />
              </div>
              <div className="media-body mg-l-10">
                <span className="user-name">MSR</span>
              </div>
              <Button
                color="link"
                icon="X"
                iconClass="tx-danger"
                className="btn-icon mg-l-10 pd-0 lh-1"
                data-han-tooltip="삭제"
              />
            </div>
            <div className="media align-items-center pd-x-10 pd-y-5 bg-white bd rounded-30 mg-r-10">
              <div className="avatar avatar-xs">
                <img
                  src="https://n.hanbiro.com/ngw/org/user/photo/no/10/cn/1"
                  className="rounded-circle"
                  alt="KDH"
                />
              </div>
              <div className="media-body mg-l-10">
                <span className="user-name">KDH</span>
              </div>
              <Button
                color="link"
                icon="X"
                iconClass="tx-danger"
                className="btn-icon mg-l-10 pd-0 lh-1"
                data-han-tooltip="삭제"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Subject</label>
          <div>Subject Subject Subject</div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Content</label>
          <div>
            Content Content Content Content Content Content Content Content Content Content Content
            Content Content Content Content Content Content Content Content Content Content Content
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">Assignment Group</label>
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center pd-x-10 pd-y-5 bg-white bd rounded-30 mg-r-10">
              Group 1
              <Button
                color="link"
                icon="X"
                iconClass="tx-danger"
                className="btn-icon mg-l-10 pd-0 lh-1"
                data-han-tooltip="삭제"
              />
            </div>
            <div className="d-flex align-items-center pd-x-10 pd-y-5 bg-white bd rounded-30 mg-r-10">
              Group 2
              <Button
                color="link"
                icon="X"
                iconClass="tx-danger"
                className="btn-icon mg-l-10 pd-0 lh-1"
                data-han-tooltip="삭제"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Reporting Cycle</label>
              <div>Monthly Every 1st 08:00</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Date Rage</label>
              <div>This Month</div>
            </div>
          </div>
        </div>

        <div className="row row-xs">
          <div className={`${showEdit ? 'col-xl-8' : 'col-12'}`}>
            <div className="card">
              <div className="card-header pd-0">
                <ul className="nav nav-line mg-t-10 pd-x-15 bd-0">
                  {TABS.map((item, index) => (
                    <li className="nav-item d-flex">
                      <Button
                        color=""
                        key={index}
                        className={`nav-link ${item.value == tab.value ? 'active' : ''}`}
                        onClick={() => {
                          setTab(item);
                        }}
                        name={item.label}
                      />
                      {showEdit && (
                        <Button
                          color="link"
                          icon="X"
                          iconClass="tx-danger"
                          className="btn-icon mg-l-10 pd-0 lh-1"
                        />
                      )}
                    </li>
                  ))}
                </ul>
                {!showEdit && (
                  <Button
                    color="link"
                    icon="Edit2"
                    iconClass="mg-r-5"
                    name="Edit"
                    className="mg-l-auto"
                    onClick={() => setShowEdit(true)}
                  />
                )}
              </div>
              <div className="pd-10 bg-light">
                {/* Customer Analytics */}
                <div className="card">
                  <div className="card-header tx-semibold">
                    Customer Analytics
                    {showEdit && (
                      <Button
                        color="link"
                        icon="X"
                        iconClass="tx-danger"
                        className="btn-icon mg-l-auto pd-0 lh-1"
                      />
                    )}
                  </div>
                  <div className="pd-10 bg-light">
                    <div className="row row-xs">
                      <div className="col-lg-3">
                        <div className="card ht-100p">
                          <div className="card-header tx-semibold">
                            Customer Trends
                            {showEdit && (
                              <Button
                                color="link"
                                icon="X"
                                iconClass="tx-danger"
                                className="btn-icon mg-l-auto pd-0 lh-1"
                              />
                            )}
                          </div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex pd-sm-x-20 bg-white">
                              <div className="media align-items-center">
                                <div className="wd-40 wd-md-50 ht-40 ht-md-50 tx-primary mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-7 customer-trend-icon">
                                  <FormIcon icon="customer" iconType="main" size={40} />
                                </div>
                                <div className="media-body">
                                  <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                    Total
                                  </div>
                                  <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                    <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">102</div>
                                    <span className="tx-medium tx-success">
                                      4% <ArrowUp size={14} />
                                    </span>
                                  </div>
                                  <span className="tx-12 tx-color-03">Last Month : 98 </span>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item d-flex pd-sm-x-20 bg-white">
                              <div className="media align-items-center">
                                <div className="wd-40 wd-md-50 ht-40 ht-md-50 tx-indigo mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-7 customer-trend-icon">
                                  <FormIcon icon="m_customer_account" iconType="main" size={40} />
                                </div>
                                <div className="media-body">
                                  <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                    Accounts
                                  </div>
                                  <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                    <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">52</div>
                                    <span className="tx-medium tx-success">
                                      10% <ArrowUp size={14} />
                                    </span>
                                  </div>
                                  <span className="tx-12 tx-color-03">Last Month : 47</span>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item d-flex pd-sm-x-20 bg-white">
                              <div className="media align-items-center">
                                <div className="wd-40 wd-md-50 ht-40 ht-md-50 tx-info mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-7 customer-trend-icon">
                                  <FormIcon icon="m_customer" iconType="main" size={40} />
                                </div>
                                <div className="media-body">
                                  <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                    Contact
                                  </div>
                                  <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                    <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">87</div>
                                    <span className="tx-medium tx-danger">
                                      3% <ArrowDown size={14} />
                                    </span>
                                  </div>
                                  <span className="tx-12 tx-color-03">Last Month : 90 </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 mg-t-10 mg-lg-t-0">
                        <div className="card ht-100p">
                          <div className="card-header tx-semibold">
                            Customers by Rating
                            {showEdit && (
                              <Button
                                color="link"
                                icon="X"
                                iconClass="tx-danger"
                                className="btn-icon mg-l-auto pd-0 lh-1"
                              />
                            )}
                          </div>
                          <ReactApexChart
                            type="bar"
                            height={270}
                            series={[
                              {
                                name: 'Bronze',
                                data: [21, 7, 25, 13, 22, 8],
                              },
                              {
                                name: 'Silver',
                                data: [21, 7, 25, 13, 22, 8],
                              },
                              {
                                name: 'Gold',
                                data: [11, 17, 15, 15, 21, 14],
                              },
                              {
                                name: 'Diamond',
                                data: [13, 23, 20, 8, 13, 27],
                              },
                              {
                                name: 'Vip',
                                data: [44, 55, 41, 67, 22, 43],
                              },
                            ]}
                            options={{
                              chart: {
                                stacked: true,
                                toolbar: {
                                  show: false,
                                },
                              },
                              xaxis: {
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
                              },
                              plotOptions: {
                                bar: {
                                  borderRadius: 4,
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                        <div className="card ht-100p">
                          <div className="card-header tx-semibold">
                            Customers by Industry
                            {showEdit && (
                              <Button
                                color="link"
                                icon="X"
                                iconClass="tx-danger"
                                className="btn-icon mg-l-auto pd-0 lh-1"
                              />
                            )}
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <ReactApexChart
                              type="treemap"
                              height={270}
                              series={[
                                {
                                  data: [
                                    {
                                      x: 'Bangaluru',
                                      y: 84,
                                    },
                                    {
                                      x: 'Pune',
                                      y: 31,
                                    },
                                    {
                                      x: 'Chennai',
                                      y: 70,
                                    },
                                    {
                                      x: 'Jaipur',
                                      y: 30,
                                    },
                                    {
                                      x: 'Surat',
                                      y: 44,
                                    },
                                    {
                                      x: 'Hyderabad',
                                      y: 68,
                                    },
                                    {
                                      x: 'Lucknow',
                                      y: 28,
                                    },
                                    {
                                      x: 'Indore',
                                      y: 19,
                                    },
                                    {
                                      x: 'Kanpur',
                                      y: 29,
                                    },
                                  ],
                                },
                              ]}
                              options={{
                                chart: {
                                  toolbar: {
                                    show: false,
                                  },
                                },
                                legend: {
                                  show: false,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Activities Analytics */}
                <div className="card mg-t-10">
                  <div className="card-header tx-semibold">
                    Activities Analytics
                    {showEdit && (
                      <Button
                        color="link"
                        icon="X"
                        iconClass="tx-danger"
                        className="btn-icon mg-l-auto pd-0 lh-1"
                      />
                    )}
                  </div>
                  <div className="pd-10 bg-light">
                    <div className="card">
                      <div className="card-header tx-semibold">
                        Activity Trends
                        {showEdit && (
                          <Button
                            color="link"
                            icon="X"
                            iconClass="tx-danger"
                            className="btn-icon mg-l-auto pd-0 lh-1"
                          />
                        )}
                      </div>
                      <div
                        className={`row no-gutters activity-trends-list ${
                          showEdit ? 'edit-mode' : ''
                        }`}
                      >
                        <div className="col-6 col-md-4 col-lg-3">
                          <div className="media mg-15">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 flex-shrink-0 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5">
                              <FormIcon icon="activity" iconType="main" />
                            </div>
                            <div className="media-body mg-l-10">
                              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                Total
                              </div>
                              <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">411</div>
                                <span className="tx-medium tx-success">
                                  319% <ArrowUp size={14} />
                                </span>
                              </div>
                              <span className="tx-12 tx-color-03">Last Month : 98 </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 bd-l">
                          <div className="media mg-15">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 flex-shrink-0 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                              <FormIcon icon="Mail" size={24} />
                            </div>
                            <div className="media-body mg-l-10">
                              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                Email
                              </div>
                              <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">52</div>
                                <span className="tx-medium tx-success">
                                  10% <ArrowUp size={14} />
                                </span>
                              </div>
                              <span className="tx-12 tx-color-03">Last Month : 47 </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 bd-md-l bd-t bd-md-t-0">
                          <div className="media mg-15">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 flex-shrink-0 bg-info tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                              <FormIcon icon="Phone" size={24} />
                            </div>
                            <div className="media-body mg-l-10">
                              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                Call
                              </div>
                              <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">87</div>
                                <span className="tx-medium tx-danger">
                                  3% <ArrowDown size={14} />
                                </span>
                              </div>
                              <span className="tx-12 tx-color-03">Last Month : 90 </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 bd-t bd-lg-t-0 bd-l">
                          <div className="media mg-15">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 flex-shrink-0 bg-indigo tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                              <FormIcon icon="task" iconType="custom" size={24} />
                            </div>
                            <div className="media-body mg-l-10">
                              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                Task
                              </div>
                              <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">87</div>
                                <span className="tx-medium tx-danger">
                                  3% <ArrowDown size={14} />
                                </span>
                              </div>
                              <span className="tx-12 tx-color-03">Last Month : 90 </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 bd-md-l bd-t">
                          <div className="media mg-15">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 flex-shrink-0 bg-orange tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                              <FormIcon icon="sms" iconType="custom" size={24} />
                            </div>
                            <div className="media-body mg-l-10">
                              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                SMS
                              </div>
                              <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">107</div>
                                <span className="tx-medium tx-success">
                                  18% <ArrowUp size={14} />
                                </span>
                              </div>
                              <span className="tx-12 tx-color-03">Last Month : 90 </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 bd-l bd-t">
                          <div className="media mg-15">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 flex-shrink-0 bg-danger tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                              <FormIcon icon="Clock" size={24} />
                            </div>
                            <div className="media-body mg-l-10">
                              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                Avg. Duration
                              </div>
                              <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">29:12:45</div>
                                <span className="tx-medium tx-success">
                                  18% <ArrowUp size={14} />
                                </span>
                              </div>
                              <span className="tx-12 tx-color-03">Last Month : 24:55:20</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl bd-lg-l bd-t">
                          <div className="media mg-15">
                            <div className="wd-40 wd-md-50 ht-40 ht-md-50 flex-shrink-0 bg-success tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                              <FormIcon icon="Smile" size={24} />
                            </div>
                            <div className="media-body mg-l-10">
                              <div className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold">
                                Avg. Customer Satisfaction
                              </div>
                              <div className="d-flex flex-wrap d-lg-block d-xl-flex align-items-end">
                                <div className="h3 tx-rubik mg-b-0 mg-r-5 lh-1">80%</div>
                                <span className="tx-medium tx-success">
                                  10% <ArrowUp size={14} />
                                </span>
                              </div>
                              <span className="tx-12 tx-color-03">Last Month : 72%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mg-t-10">
                      <div className="card-header tx-semibold">
                        Group Performance
                        {showEdit && (
                          <Button
                            color="link"
                            icon="X"
                            iconClass="tx-danger"
                            className="btn-icon mg-l-auto pd-0 lh-1"
                          />
                        )}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-bordered dataTable mg-0 bd-0">
                          <thead>
                            <tr>
                              <th scope="col" className="sorting">
                                Group Name
                              </th>
                              <th scope="col" className="sorting">
                                Total
                              </th>
                              <th scope="col" className="sorting">
                                Email
                              </th>
                              <th scope="col" className="sorting">
                                Call
                              </th>
                              <th scope="col" className="sorting">
                                Task
                              </th>
                              <th scope="col" className="sorting">
                                SMS
                              </th>
                              <th scope="col" className="sorting">
                                Avg. Duration
                              </th>
                              <th scope="col" className="sorting">
                                Avg. Customer Satisfaction
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>CS</td>
                              <td>100</td>
                              <td>34</td>
                              <td>34</td>
                              <td>34</td>
                              <td>34</td>
                              <td>01:45:20 </td>
                              <td>70%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showEdit && (
            <div className="col-xl-4 mg-t-10 mg-xl-t-0">
              <RightAside />
              <div className="pd-y-10 text-right">
                <Button color="white" name="Preview" className="mg-r-20" />
                <Button
                  color="light"
                  name="Cancel"
                  onClick={() => {
                    setShowEdit(false);
                  }}
                  className="mg-r-5"
                />
                <Button color="success" name="Save" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCTA;
