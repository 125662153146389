import { ChevronDown, Plus, X } from 'react-feather';

const Ticket = () => {
  return (
    <>
      <div className="table-responsive bg-white bd rounded-top">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f">
                Name
              </th>
              <th scope="col" className="bd-t-0-f">
                Price
              </th>
              <th scope="col" className="bd-t-0-f">
                Sales end
              </th>
              <th scope="col" className="bd-t-0-f">
                Reserved
              </th>
              <th scope="col" className="bd-t-0-f">
                Unconfirmed
              </th>
              <th scope="col" className="bd-t-0-f">
                left
              </th>
              <th scope="col" className="bd-t-0-f wd-70">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td align="center">
                <button type="button" className="btn btn-icon pd-0">
                  <X className="tx-danger" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="bg-white bd bd-t-0 rounded-bottom pd-10">
        <div className="dropdown">
          <div className="input-group" data-toggle="dropdown">
            <input
              type="text"
              className="form-control"
              placeholder="Type or click to select a ticket"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                <ChevronDown />
              </button>
            </div>
          </div>
          <div className="dropdown-menu wd-100p">
            <button
              type="button"
              className="dropdown-item"
              data-toggle="modal"
              data-target="#newCampaignTicket"
            >
              <span className="dropdown-item-icon">
                <Plus />
              </span>
              Add new
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ticket;
