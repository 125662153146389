import { useState } from 'react';
import { Icons } from '@base/assets/icons/svg-icons/menuIcons';
import { ChevronUp, Clock, User, X } from 'react-feather';
import SelectHook from '@base/components/form/select-hook';

const FaxWrite = () => {
  const [faxDirection, setFaxDirection] = useState('incoming');
  const handleFaxDirectionChange = (e: any) => {
    const { value } = e.target;
    setFaxDirection(value);
  };

  const [faxSchedule, setFaxSchedule] = useState('now');
  const handleFaxScheduleChange = (e: any) => {
    const { value } = e.target;
    setFaxSchedule(value);
  };

  return (
    <>
      <div id="newFaxModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Create Fax</h1>
              <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
                <X />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-item-title d-block">Fax Direction</label>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="faxDirectionRadio1"
                    name="faxDirectionRadio"
                    className="custom-control-input"
                    value="incoming"
                    checked={faxDirection === 'incoming'}
                    onChange={(e) => handleFaxDirectionChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="faxDirectionRadio1">
                    Incoming
                  </label>
                </div>
                <div className="custom-control custom-radio d-inline-block mg-r-10">
                  <input
                    type="radio"
                    id="faxDirectionRadio2"
                    name="faxDirectionRadio"
                    className="custom-control-input"
                    value="outgoing"
                    checked={faxDirection === 'outgoing'}
                    onChange={(e) => handleFaxDirectionChange(e)}
                  />
                  <label className="custom-control-label" htmlFor="faxDirectionRadio2">
                    Outgoing
                  </label>
                </div>
              </div>
              {faxDirection === 'outgoing' && (
                <div className="form-group">
                  <label className="form-item-title d-block">Fax Schedule</label>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="faxScheduleRadio1"
                      name="faxScheduleRadio"
                      className="custom-control-input"
                      value="now"
                      checked={faxSchedule === 'now'}
                      onChange={(e) => handleFaxScheduleChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="faxScheduleRadio1">
                      Send Now
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-inline-block mg-r-10">
                    <input
                      type="radio"
                      id="faxScheduleRadio2"
                      name="faxScheduleRadio"
                      className="custom-control-input"
                      value="schedule"
                      checked={faxSchedule === 'schedule'}
                      onChange={(e) => handleFaxScheduleChange(e)}
                    />
                    <label className="custom-control-label" htmlFor="faxScheduleRadio2">
                      Schedule
                    </label>
                  </div>
                </div>
              )}
              {(faxDirection === 'incoming' || faxSchedule === 'schedule') && (
                <div className="form-group">
                  <label className="form-item-title">Date</label>
                  <div className="input-group wd-300">
                    <input type="text" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Clock />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group">
                <label className="form-item-title">Subject</label>
                <input type="text" className="form-control form-control-lg" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Priority</label>
                <SelectHook className="wd-300-f" />
              </div>
              <div className="form-group">
                <label className="form-item-title">Purpose</label>
                <div className="wd-50p">
                  <SelectHook />
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Fax Image</label>
                <div>&nbsp;</div>
              </div>
              <div className="form-group">
                <label className="form-item-title">From</label>
                <div className="dropdown">
                  <div className="input-group" data-toggle="dropdown">
                    {/*  before selecting an user 
										<input type="text" className="form-control" placeholder="Type or click to select an user /> 
									*/}
                    <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-xs mg-r-10">
                          <img
                            src="https://global3.hanbiro.com/ncrm/user/photo/1/1"
                            alt="John"
                            className="img rounded-circle"
                          />
                        </span>
                        <span className="text-truncate">John</span>
                      </div>
                      <button type="button" className="btn" aria-label="delete">
                        <X />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn"
                        aria-label="open modal to select an user"
                      >
                        <User />
                        <span className="sr-only">담당자 선택 팝업 열기</span>
                      </button>
                    </div>
                  </div>
                  <div className="dropdown-menu wd-100p">
                    <button type="button" className="dropdown-item">
                      Assign to me
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">To</label>
                <div className="input-group">
                  {/*  before selecting an user 
									<input type="text" className="form-control" placeholder="Type or click to select a customer /> 
								*/}
                  <div className="form-control pd-y-1-f d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="avatar avatar-xs mg-r-10">
                        <span className="avatar-initial rounded-circle">S</span>
                      </span>
                      <span className="text-truncate">Socrates</span>
                    </div>
                    <button type="button" className="btn">
                      <X />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn"
                      aria-label="open modal to select a customer"
                    >
                      {Icons('m_customer')}
                      <span className="sr-only">고객 선택 팝업 열기</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <input type="text" className="form-control form-control-lg" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">
                Cancel
              </button>
              <div className="btn-group dropup">
                <button type="button" className="btn btn-success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success dropdown-toggle-split"
                  data-toggle="dropdown"
                >
                  <ChevronUp />
                  <span className="sr-only">옵션 열기</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button type="button" className="dropdown-item">
                    Save and Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaxWrite;
