import { Button, Input } from '@base/components/form';
import { CheckCircle } from 'react-feather';

const TargetAccountCollected = () => {
  return (
    <div className="pd-20">
      <div className="text-right mg-b-10">
        <Button
          color="primary"
          icon="Plus"
          iconClass="mg-r-5"
          name="Collect Target Account"
          className="rounded-30"
          data-toggle="modal"
          data-target="#collectTargetAccounts"
        />
      </div>
      <div className="card">
        <div className="table-responsive">
          <table className="table table-bordered mg-0 bd-0 basic-table">
            <thead>
              <tr>
                <th scope="col">Account Name</th>
                <th scope="col">Contact</th>
                <th scope="col">ABM Tier</th>
                <th scope="col">Industry</th>
                <th scope="col">Collection Method</th>
                <th scope="col">Employee</th>
                <th scope="col">Owner</th>
                <th scope="col">Created on</th>
                <th scope="col">Lauched on</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>2</td>
                <td>Tier 1</td>
                <td>&nbsp;</td>
                <td>Internet Seatch</td>
                <td>300</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer pd-y-6-f pagination">
          <Button
            color="link link-02"
            className="btn-icon"
            icon="move_first"
            iconType="custom"
            data-han-tooltip="move to first"
          />
          <Button
            color="link link-02"
            className="btn-icon"
            icon="ChevronLeft"
            data-han-tooltip="previous"
          />
          <Button
            color="link link-02"
            className="btn-icon"
            icon="ChevronRight"
            data-han-tooltip="next"
          />
          <Button
            color="link link-02"
            className="btn-icon"
            icon="move_last"
            iconType="custom"
            data-han-tooltip="move to last"
          />
          <Input type="number" className="paging-input" defaultValue="1" />/ 1
        </div>
      </div>

      {/* Manual Task */}
      <div className="view-content-section">
        <div className="view-content-section-header">
          <span className="view-aside-section-header-title">
            Task assignment to collected Target Accounts
          </span>
          <div className="d-flex align-items-center mg-l-auto pd-x-20 pd-y-5 bg-success-light rounded tx-success">
            <CheckCircle size={18} className="mg-r-10" />
            90% Identified
          </div>
        </div>
        <div className="table-responsive bg-white bd rounded mg-t-5">
          <table className="table table-bordered mg-0 bd-0 basic-table">
            <thead>
              <tr>
                <th scope="col">Team Member</th>
                <th scope="col">No. collected</th>
                <th scope="col">No. of Target Account</th>
                <th scope="col">No. allocated</th>
                <th scope="col">Identified</th>
                <th scope="col">Due</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th scope="row" className="bd-b-0-f">
                  Total
                </th>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>Name 1</td>
                <td>15</td>
                <td>All(12/20): T1(5), T2(2), T3(5)</td>
                <td>20</td>
                <td>0%</td>
                <td>2022/06/20</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TargetAccountCollected;
