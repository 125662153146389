import Icon from '@base/assets/icons/svg-icons';
import { Input } from '@base/components/form';
import Timeline from '@base/containers/timeline';
import { useState } from 'react';

import { ArrowLeft, Plus, X } from 'react-feather';

const Purchase = () => {
  const [showBOMDetail, setShowBOMDetail] = useState<any>();

  return (
    <div className="pd-20">
      {!showBOMDetail && (
        <>
          <div className="mg-b-10 text-right">
            <button
              type="button"
              className="btn btn-primary rounded-30"
              data-toggle="modal"
              data-target="#newBOM"
            >
              <Plus className="mg-r-5" />
              New
            </button>
          </div>
          <div className="table-responsive bg-white bd rounded">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                <tr>
                  <th scope="col" className="bd-t-0-f">
                    BoM Name
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Related Item
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Created on
                  </th>
                  <th scope="col" className="bd-t-0-f">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link pd-x-0"
                      onClick={() => setShowBOMDetail(true)}
                    >
                      BoM Name 1
                    </button>
                  </td>
                  <td>
                    Item 1<span className="badge badge-secondary mg-l-5">+2</span>
                  </td>
                  <td>2022-03-16</td>
                  <td>Prepare</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      {showBOMDetail && (
        <div className="card">
          <div className="card-header pd-x-5 pd-y-10">
            <button type="button" className="btn btn-link" onClick={() => setShowBOMDetail(false)}>
              <ArrowLeft />
            </button>
            <span className="tx-semibold">BoM Name 1</span>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label className="form-item-title">Components</label>
              <div className="table-responsive bg-white bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Component Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Type
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Sypply Method
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Unit
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a href="" target="_blank" title="새창으로 열림">
                          Component Name 1
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pd-10 bd bd-t-0 rounded-bottom">
                <Input
                  type="text"
                  rightIcon={Icon('product')}
                  placeholder="Type or click to select a component"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-item-title">Created by</label>
                  <div className="media align-items-center">
                    <div className="avatar avatar-xs">
                      <img
                        src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                        className="rounded-circle"
                        alt="MSR"
                      />
                    </div>
                    <div className="media-body mg-l-10">
                      <div>MSR</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-item-title">Description</label>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title d-block">Related Item</label>
              <div className="form-control-tag">
                <span className="d-block avatar avatar-xs mg-r-10">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxk47d99qf_o3faJPuqsQ7sFrwfCIBhrd-VA&amp;usqp=CAU"
                    alt="Item 1"
                    className="img rounded"
                  />
                </span>
                <span className="form-control-tag-name">Item 1</span>
                <button
                  type="button"
                  className="btn pd-0 form-control-tag-remove"
                  aria-label="삭제"
                >
                  <X />
                </button>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Result Item</label>
              <div className="table-responsive bg-white bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
                  <thead>
                    <tr>
                      <th scope="col" className="bd-t-0-f">
                        Item Name
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Finished on
                      </th>
                      <th scope="col" className="bd-t-0-f">
                        Assigned Rep
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a href="" target="_blank" title="새창으로 열림">
                          Item Name 1
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        <div className="media align-items-center">
                          <div className="avatar avatar-xs">
                            <img
                              src="https://n.hanbiro.com/ncrm/user/photo/877/1"
                              className="rounded-circle"
                              alt="MSR"
                            />
                          </div>
                          <div className="media-body mg-l-10">
                            <div>MSR</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">Titmeline</label>
              <Timeline />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Purchase;
