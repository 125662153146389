import { Edit2, Plus } from 'react-feather';

const Timeline = () => {
  return (
    <>
      <div className="new-timeline-group pd-20">
        <div className="new-timeline-header mg-t-0-f">
          <div className="new-timeline-label">2021-03-17</div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Edit2 />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  Assigned to <strong className="mg-x-5">ITM</strong> by MSR
                </span>
                <span className="mg-l-auto tx-color-03">15:29</span>
              </div>
            </div>
          </div>
        </div>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Plus />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>
                  Ticket <strong className="mg-x-5">created</strong> by MSR
                </span>
                <span className="mg-l-auto tx-color-03">15:28</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;
