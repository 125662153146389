import { User, X } from 'react-feather';

const NewFolder = () => {
  return (
    <div id="newFolderModal" className="modal fade right">
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h1 className="h6 modal-title">New Folder</h1>
            <button type="button" className="close" data-dismiss="modal" aria-label="닫기">
              <X />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-item-title">
                Name
                <span className="tx-danger" title="필수 입력">
                  *
                </span>
              </label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label className="form-item-title">Owner</label>
              <div className="input-group">
                <div className="form-control pd-y-1-f d-flex align-items-center">
                  <span className="d-block avatar avatar-xs mg-r-10">
                    <img
                      src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                      alt="MSR"
                      className="img rounded-circle"
                    />
                  </span>
                  <span className="text-truncate">MSR</span>
                  <button type="button" className="btn mg-l-auto" aria-label="delete">
                    <X />
                    <span className="sr-only">삭제</span>
                  </button>
                </div>
                <div className="input-group-append">
                  <button type="button" className="btn" aria-label="open modal to select a user">
                    <User />
                    <span className="sr-only">사용자 선택 팝업 열기</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Display to
                <span className="tx-danger" title="필수 입력">
                  *
                </span>
              </label>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="folderRadio1"
                  name="folderRadio"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="folderRadio1">
                  Public
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="folderRadio2"
                  name="folderRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="folderRadio2">
                  Private
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Active
                <span className="tx-danger" title="필수 입력">
                  *
                </span>
              </label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="kbFolderActiveSwitch1"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="kbFolderActiveSwitch1"></label>
              </div>
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Description
                <span className="tx-danger" title="필수 입력">
                  *
                </span>
              </label>
              <textarea className="form-control"></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-success">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFolder;
